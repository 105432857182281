import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

import { SEARCH_RESULT_TYPE_TITLES, SEARCH_RESULT_TYPES } from '../../constants'
import { FilterItem, FilterItemDropdownRadios } from '../ui/filter'

const SearchFilterResultType = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter !== null

    const values = SEARCH_RESULT_TYPES.map((type) => ({
        value: type,
        label: SEARCH_RESULT_TYPE_TITLES[type],
        checked: activeFilter === type,
    }))

    const activeValue = _get(_find(values, { value: activeFilter }), ['label'], null)

    const name = 'resultType'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Result type"
            activeValues={[activeValue]}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownRadios
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterResultType.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    activeFilter: PropTypes.string.isRequired,
}

export default SearchFilterResultType
