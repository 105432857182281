export const REASSIGNMENT_REASON_ACCOUNTANT_EMERGENCY = 'AccountantEmergency'
export const REASSIGNMENT_REASON_ACCOUNTANT_MISTAKE = 'AccountantMistake'
export const REASSIGNMENT_REASON_ACCOUNTANT_PAUSED = 'AccountantPaused'
export const REASSIGNMENT_REASON_ACCOUNTANT_REQUESTED_REASSIGNMENT = 'AccountantRequestedReassignment'
export const REASSIGNMENT_REASON_CUSTOMER_CANCELLED = 'CustomerCancelled'
export const REASSIGNMENT_REASON_CUSTOMER_REQUESTED_REASSIGNMENT = 'CustomerRequestedReassignment'
export const REASSIGNMENT_REASON_MISMATCH = 'Mismatch'
export const REASSIGNMENT_REASON_SERVICES_CHANGED = 'ServicesChanged'
export const REASSIGNMENT_REASON_ACCOUNTANT_OFFBOARDED = 'AccountantOffboarded'
export const REASSIGNMENT_REASON_OPERATIONS_ACTION = 'OperationsAction'
export const REASSIGNMENT_REASON_ACCOUNTANT_UNRESPONSIVE = 'AccountantUnresponsive'

export const REASSIGNMENT_REASONS_TITLES = {
    [REASSIGNMENT_REASON_ACCOUNTANT_EMERGENCY]: 'Accountant emergency',
    [REASSIGNMENT_REASON_ACCOUNTANT_MISTAKE]: 'Accountant mistake',
    [REASSIGNMENT_REASON_ACCOUNTANT_PAUSED]: 'Accountant paused',
    [REASSIGNMENT_REASON_ACCOUNTANT_REQUESTED_REASSIGNMENT]: 'Accountant requested reassignment',
    [REASSIGNMENT_REASON_CUSTOMER_CANCELLED]: 'Customer cancelled',
    [REASSIGNMENT_REASON_CUSTOMER_REQUESTED_REASSIGNMENT]: 'Customer requested reassignment',
    [REASSIGNMENT_REASON_MISMATCH]: 'Accountant mismatch between user and product',
    [REASSIGNMENT_REASON_SERVICES_CHANGED]: 'Accountant no longer offers required service',
    [REASSIGNMENT_REASON_ACCOUNTANT_OFFBOARDED]: 'Accountant offboarded',
    [REASSIGNMENT_REASON_OPERATIONS_ACTION]: 'Operations action',
    [REASSIGNMENT_REASON_ACCOUNTANT_UNRESPONSIVE]: 'Accountant unresponsive',
} as const
