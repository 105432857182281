import { useState } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { useToggle } from '@scouts/helpers'
import { colors, font, radius, ConfirmationModal, Container, File, Flexer, Line, Spacer } from '@scouts/ui'

import { PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM } from '@/constants'
import { FileType, FileTypeTitles, TaxConsultationDTO, TaxConsultationFileDTO } from '@/models'
import {
    useDeleteTaxConsultationFile,
    useDownloadTaxConsultationFile,
    useTaxConsultationFiles,
} from '@/store/tax-consultation-files/hooks'

import { UploadButton } from './UploadButton'

interface TaxConsultationAccountantFiles {
    taxConsultation: TaxConsultationDTO
}

interface DeletableFileState {
    fileId: TaxConsultationFileDTO['id']
    fileName: TaxConsultationFileDTO['fileName']
}

export const TaxConsultationAccountantFiles = ({ taxConsultation }: TaxConsultationAccountantFiles) => {
    const { id, customerId } = taxConsultation

    const [isDeleteConfirmationModalOpen, openDeleteConfirmationModal, closeDeleteConfirmationModal] = useToggle()

    const { taxConsultationAccountantFiles } = useTaxConsultationFiles({
        taxConsultationId: id,
    })

    const { downloadTaxConsultationFile } = useDownloadTaxConsultationFile()
    const { deleteTaxConsultationFile, isLoading: isLoadingDelete } = useDeleteTaxConsultationFile()

    const [deletableFile, setDeletableFile] = useState<DeletableFileState | null>()

    const handleOpenDeleteConfirmation = ({ fileId, fileName }: DeletableFileState) => {
        setDeletableFile({ fileId, fileName })
        openDeleteConfirmationModal()
    }

    const handleConfirmDeleteFile = () => {
        if (deletableFile?.fileId) {
            deleteTaxConsultationFile({ taxConsultationFileId: deletableFile.fileId })
            closeDeleteConfirmationModal()
        }
    }

    const hasFiles = taxConsultationAccountantFiles && taxConsultationAccountantFiles.length > 0

    return (
        <Container>
            {hasFiles ? (
                <Container radius={radius.large} padding="24px" background={colors.blueLightest}>
                    <Flexer column gap="12px">
                        {taxConsultationAccountantFiles.map(({ id, fileType, fileName }) => (
                            <Spacer key={id}>
                                <Flexer column gap="6px">
                                    <Line weight={font.weight.medium} size={font.small}>
                                        {FileTypeTitles[fileType]}
                                    </Line>

                                    <File
                                        background={colors.blueLighter}
                                        onClick={() => downloadTaxConsultationFile({ taxConsultationFileId: id })}
                                        onDelete={() =>
                                            handleOpenDeleteConfirmation({
                                                fileId: id,
                                                fileName,
                                            })
                                        }
                                    >
                                        {fileName}
                                    </File>
                                </Flexer>
                            </Spacer>
                        ))}
                    </Flexer>
                </Container>
            ) : (
                <Container radius={radius.large} padding="24px" background={colors.redLighter}>
                    <Line size={font.small} textAlign="center">
                        Written consultation summary to the client is required to let them confirm that the job is
                        finished.{' '}
                        <Link
                            to={generatePath(PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM, {
                                customerId,
                                taxConsultationId: id,
                            })}
                        >
                            <br />
                            Click here to write one.
                        </Link>
                    </Line>
                </Container>
            )}

            <Spacer marginTop="18px">
                <Flexer justifyEnd>
                    <UploadButton taxConsultationId={id} fileTypes={[FileType.TaxConsultationSummary]} />
                </Flexer>
            </Spacer>

            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal
                    onConfirm={handleConfirmDeleteFile}
                    onCancel={closeDeleteConfirmationModal}
                    isLoading={isLoadingDelete}
                >
                    Are you sure you want to delete {deletableFile?.fileName} ?
                </ConfirmationModal>
            )}
        </Container>
    )
}
