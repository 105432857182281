import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'

import { RECEIVE_CUSTOMER_FILES, REMOVE_CUSTOMER_FILE } from '../actions'

export default (state = { items: [] }, action) => {
    const { type, data } = action

    switch (type) {
        case RECEIVE_CUSTOMER_FILES: {
            const items = _get(state, ['items'], [])

            data.forEach((item) => {
                const key = _findIndex(items, ['id', item.id])

                if (key > -1 && items[key]) {
                    items[key] = item
                } else {
                    items.push(item)
                }
            })

            return { ...state, items: Object.assign([], items) }
        }

        case REMOVE_CUSTOMER_FILE: {
            const items = _get(state, ['items'], [])

            const key = _findIndex(items, ['id', data.id])

            if (key > -1 && items[key]) {
                delete items[key]
            }

            return { ...state, items: Object.assign([], items) }
        }

        default:
            return state
    }
}
