import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Container, Flexer, Grid, GridItem } from '@scouts/ui'

import { requestAccountants, requestEscalations } from '@/actions'
import { getEscalationsBySupportUserId } from '@/selectors'
import { useAppDispatch } from '@/store'

import { useUser } from '@/hooks/use-user'

import { SupportAccountantStatistics } from './SupportAccountantStatistics'
import { SupportDashboardEscalations } from './SupportDashboardEscalations'
import { SupportDashboardOffPlatformServices } from './SupportDashboardOffPlatformServices'
import { SupportDashboardProfile } from './SupportDashboardProfile'
import { SupportDashboardTaxReliefClaims } from './SupportDashboardTaxReliefClaims'

export const SupportDashboard = () => {
    const dispatch = useAppDispatch()
    const user = useUser()

    const { id: supportUserId, isAdminUser } = user

    const escalations = useSelector((state) => getEscalationsBySupportUserId(state, { supportUserId }))

    useEffect(() => {
        dispatch(requestAccountants())
        dispatch(requestEscalations({ filter: { supportUserId } }))
    }, [dispatch, supportUserId])

    if (!isAdminUser) return null

    return (
        <>
            <Container tabletPadding="36px 48px 192px" padding="24px 24px 192px">
                <Grid columns={1} tabletColumns="1fr 300px" tabletGap="48px" gap="24px">
                    <GridItem tabletColumn="1" tabletRow="1" row="2">
                        <Flexer column gap="24px" tabletGap="48px">
                            <SupportDashboardEscalations escalations={escalations} />
                            <SupportDashboardTaxReliefClaims />
                            <SupportDashboardOffPlatformServices />
                            <SupportAccountantStatistics />
                        </Flexer>
                    </GridItem>
                    <GridItem tabletColumn="2" row="1">
                        <SupportDashboardProfile />
                    </GridItem>
                </Grid>
            </Container>
        </>
    )
}
