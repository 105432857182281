import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
    colors,
    ActionButton,
    Button,
    Logo,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { MESSAGE_USER_TYPE_ADMIN_SIGNATURE } from '@/constants'
import { getCustomerById } from '@/selectors/customers'
import { getUser } from '@/selectors/user'

import { MobilePreviewTextMessage } from './MobilePreviewTextMessage'

export const MessagePreviewModal = ({ customerId, messageContent, isSms, onClose }) => {
    const user = useSelector(getUser)
    const customer = useSelector((state) => getCustomerById(state, { customerId }))

    const { fullName: userFullName, isAdminUser } = user
    const { firstName: customerFirstName } = customer

    return (
        <Modal isWide onClose={onClose}>
            <ModalHeader>
                <ModalTitle>Client will receive an {isSms ? 'SMS' : 'email'} looking like this</ModalTitle>
            </ModalHeader>

            <ModalBody>
                {isSms ? (
                    <MessageContainer>
                        <MobilePreviewTextMessage>
                            {messageContent || 'This will be your message to the client'}
                        </MobilePreviewTextMessage>
                    </MessageContainer>
                ) : (
                    <MessageContainer>
                        <LogoWrapper>
                            <Logo />
                        </LogoWrapper>

                        <MessageBody>
                            {`Hi ${customerFirstName},

${messageContent}`}
                            <MessageAction>
                                <ActionButton>Go to TaxScouts</ActionButton>
                            </MessageAction>

                            {`— ${isAdminUser ? MESSAGE_USER_TYPE_ADMIN_SIGNATURE : userFullName}`}
                        </MessageBody>
                    </MessageContainer>
                )}
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button isSecondary onClick={onClose}>
                        Close
                    </Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}

MessagePreviewModal.propTypes = {
    customerId: PropTypes.number,
    messageContent: PropTypes.string.isRequired,
    isSms: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}

MessagePreviewModal.defaultProps = {
    customerId: undefined,
    isSms: false,
}

const MessageContainer = styled.div`
    padding: 24px 0;
    background: ${colors.neutralLightest};
`

const LogoWrapper = styled.div`
    width: 96px;
    margin: 0 auto 24px;
`

const MessageBody = styled.div`
    line-height: 1.5;
    white-space: pre-wrap;
    width: 600px;
    margin: 24px auto 0;
    padding: 36px 24px;
    background: ${colors.white};
`

const MessageAction = styled.div`
    text-align: center;
    margin: 30px 0;
    padding: 30px;
    background: ${colors.mintLighter};
    pointer-events: none;
`
