import { Table } from '@scouts/ui'

import { AccountantMeetingSummaryDTO } from '@/models'

import { AccountantMeetingsTableRow } from './AccountantMeetingsTableRow'

interface AccountantMeetingsTableProps {
    accountantMeetings: AccountantMeetingSummaryDTO[] | undefined
}

export const AccountantMeetingsTable = ({ accountantMeetings }: AccountantMeetingsTableProps) => (
    <Table>
        <Table.Header>
            <Table.Header.Row>
                <Table.Header.Cell width="30%">Client</Table.Header.Cell>
                <Table.Header.Cell width="10%">Status</Table.Header.Cell>
                <Table.Header.Cell width="20%">Contact preference</Table.Header.Cell>
                <Table.Header.Cell width="20%">Time</Table.Header.Cell>
                <Table.Header.Cell width="20%" alignRight>
                    Updated
                </Table.Header.Cell>
            </Table.Header.Row>
        </Table.Header>
        <Table.Body>
            {accountantMeetings?.map((accountantMeeting) => (
                <AccountantMeetingsTableRow key={accountantMeeting.id} accountantMeeting={accountantMeeting} />
            ))}
        </Table.Body>
    </Table>
)
