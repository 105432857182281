import { useGetTaxRegistrationsStatisticsQuery, GetTaxRegistrationsStatisticsParams } from '../api'

export const useTaxRegistrationsStatistics = ({ options }: GetTaxRegistrationsStatisticsParams) => {
    const { data, isError, isFetching } = useGetTaxRegistrationsStatisticsQuery(
        { options },
        { refetchOnMountOrArgChange: true }
    )

    return {
        taxRegistrationsStatistics: data,
        isError,
        isFetching,
    }
}
