import { useToggle } from '@scouts/helpers'
import { Button } from '@scouts/ui'

import { CustomerDTO, IdentityVerificationDTO, IdentityVerificationStatus } from '@/models'
import { useCreateIdentityVerification, useUpdateIdentityVerification } from '@/store/identity-verifications'

import { Request } from './Request'

interface RequestContainerProps {
    customer: CustomerDTO
    buttonLabel: string
    identityVerification: IdentityVerificationDTO | null
}

export const RequestContainer = ({ customer, buttonLabel, identityVerification }: RequestContainerProps) => {
    const [isModalOpen, openModal, closeModal] = useToggle()

    const { createIdentityVerification, isLoading: isLoadingCreate } = useCreateIdentityVerification()
    const { updateIdentityVerification, isLoading: isLoadingUpdate } = useUpdateIdentityVerification()

    const handleRequest = () => {
        if (identityVerification?.id) {
            updateIdentityVerification({
                identityVerificationId: identityVerification.id,
                data: { ...identityVerification, status: IdentityVerificationStatus.Requested },
            }).then(closeModal)
        } else {
            createIdentityVerification({ customerId: customer.id }).then(closeModal)
        }
    }

    return (
        <>
            <Button onClick={openModal}>{buttonLabel}</Button>

            {isModalOpen && (
                <Request
                    customer={customer}
                    handleCancel={closeModal}
                    handleRequest={handleRequest}
                    isLoading={isLoadingCreate || isLoadingUpdate}
                />
            )}
        </>
    )
}
