import { colors, Notice } from '@scouts/ui'

import { AgentAuthorisationStatus, CustomerDTO } from '@/models'

export const AgentAuthReminderNotice = ({ status }: { status: CustomerDTO['agentAuthorisationStatus'] }) => {
    if (!status || status === AgentAuthorisationStatus.ClientAuthorised) return null

    return (
        <Notice background={colors.white}>
            <p>
                <span aria-label="Waving hand sign emoji" role="img">
                    👋
                </span>{' '}
                A friendly reminder – you can file their tax return even if the authorisation is not yet completed.
            </p>
            <p>
                We include the{' '}
                <a
                    href="https://www.gov.uk/government/publications/tax-agents-and-advisers-authorising-your-agent-64-8"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    HMRC 64-8 form
                </a>{' '}
                in the signed letter of engagement enabling you to file their return without needing to wait for
                authorisation.
            </p>
        </Notice>
    )
}
