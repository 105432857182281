import * as Sentry from '@sentry/browser'
import _get from 'lodash/get'

import config from '../config'

export const initSentry = () => {
    Sentry.init({
        dsn: config.isProduction || config.isStaging ? config.sentryDsn : null,
        environment: config.environment,
        beforeBreadcrumb(breadcrumb, hint) {
            let { message } = breadcrumb
            if (breadcrumb.category === 'ui.click') {
                const { target } = hint.event
                if (target.textContent) {
                    message = `${message} ("${target.textContent.substring(0, 20)}${
                        target.textContent.length > 20 ? '...' : ''
                    }")`
                }
            }
            return { ...breadcrumb, message }
        },
    })
}

export const initSentryUser = (profile) => {
    Sentry.configureScope((scope) => {
        scope.setUser({
            id: profile.id,
            isOnTrial: profile.isOnTrial,
            isTaxScoutsSupport: profile.isTaxScoutsSupport,
        })
    })
}

export const sendDecodingErrorsToSentry = (exception, string, componentName = 'a component') => {
    Sentry.captureMessage(`Decoding failed in ${componentName}`, {
        contexts: {
            decoding: { value: string, componentName, exception },
        },
        fingerprint: ['{{ default }}', 'DecodingError', componentName],
    })
}

const regexDigits = /\d+/g
const regexUUID = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i
const cleanPathnames = ['/api/payment/paymentconfirmation', '/api/magiclink']
const formatApiEndpoint = (urlString) => {
    if (!urlString) return urlString

    const { pathname: apiEndpoint } = new URL(urlString)

    const newPathnameIndex = cleanPathnames.findIndex((cleanerPathname) => apiEndpoint.includes(cleanerPathname))
    if (newPathnameIndex >= 0) return cleanPathnames[newPathnameIndex]

    return apiEndpoint
        .split('/')
        .map((pathnamePart) => pathnamePart.replace(regexUUID, '{uuid}').replace(regexDigits, '{id}'))
        .join('/')
}

/**
 * Used in Sagas. Helps parse Axios error and send it to Sentry.
 *
 * Does not work with RTK-Query errors.
 *
 * @param {import('axios').AxiosError} axiosError
 * @deprecated Prefer using RTK-Query and `/store/error-handling/hooks/use-request-error-handler.ts` instead because RTK-Query has built-in error logging to Sentry, thanks to middleware: `rtkQuerySentryMiddleware()`
 */
export const sendApiErrorsToSentry = (axiosError) => {
    Sentry.withScope((scope) => {
        scope.setContext('apiCall', {
            requestData: JSON.stringify(_get(axiosError, ['response', 'config', 'data'], {})),
            responseData: JSON.stringify(_get(axiosError, ['response', 'data'], {})),
            sentryXhr: JSON.stringify(_get(axiosError, ['response', 'request'], {})),
        })

        // Try to parse more info from Axios. If fails, report as exception.
        try {
            const apiStatus = _get(axiosError, ['response', 'status'], '')
            const apiMethod = _get(axiosError, ['response', 'config', 'method'], '')
            const apiEndpoint = formatApiEndpoint(_get(axiosError, ['request', 'responseURL'], ''))

            Sentry.captureMessage(`${apiMethod.toUpperCase()} ${apiEndpoint} [${apiStatus}]`, {
                level: 'info', // https://github.com/getsentry/sentry-javascript/blob/7.0.0/MIGRATION.md#severity-severitylevel-and-severitylevels
                fingerprint: ['{{ default }}', String(apiStatus), String(apiMethod), String(apiEndpoint)],
            })
        } catch (error) {
            Sentry.captureException(axiosError)
        }
    })
}

export const sendExceptionToSentry = (error) => {
    Sentry.captureException(error)
}
