import styled from 'styled-components'

import { colors, font, media, Container, Label, Line, Spacer } from '@scouts/ui'

import { PAYMENT_STATUS_REFUNDED, REASONS_TITLES, REFUND_REASONS_TITLES } from '@/constants'
import { formatDate, formatTimeFromNow } from '@/helpers'
import {
    CustomerAddressDTO,
    CustomerDTO,
    TaxRegistrationDTO,
    TaxRegistrationRegistrationType,
    TaxRegistrationRegistrationTypeTitles,
} from '@/models'

import { useUser } from '@/hooks/use-user'

export const TaxRegistrationFields = ({
    taxRegistration,
    address,
    customer,
}: {
    taxRegistration: TaxRegistrationDTO
    address?: CustomerAddressDTO
    customer: CustomerDTO
}) => {
    const { isAdminUser } = useUser()
    const { previousLastName, changeNameDate } = customer
    const {
        registrationType,
        reasons,
        selfEmploymentProfile,
        refundDateUTC,
        paymentStatus,
        price,
        hasPaid,
        refundReason,
        revenueDateUTC,
        statusChangedDateUTC,
    } = taxRegistration
    const {
        selfEmployedStartDate,
        businessName,
        businessDescription,
        businessAddressStreetAddress,
        businessAddressCity,
        businessAddressPostcode,
        businessAddressCountry,
    } = selfEmploymentProfile || {}
    const { refundReasonType, refundReasonDescription, supportAccountantFullName } = refundReason || {}
    const { streetAddress, city, moveInDate, postcode, country } = address || {}

    const isRegisteringAsNonSelfEmployed = registrationType === TaxRegistrationRegistrationType.NonSelfEmployed
    const isRegisteringAsSelfEmployed = [
        TaxRegistrationRegistrationType.SelfEmployed,
        TaxRegistrationRegistrationType.Cis,
    ].includes(registrationType)

    const getPaymentStatus = () => {
        switch (true) {
            case paymentStatus === PAYMENT_STATUS_REFUNDED && price === 0:
                return (
                    <>
                        <Label background={colors.red}>Refunded</Label>{' '}
                        <Label background={colors.mintLighter}>Free</Label>
                    </>
                )
            case price === 0:
                return 'Free'
            case paymentStatus === PAYMENT_STATUS_REFUNDED:
                return <Label background={colors.red}>Refunded</Label>
            case hasPaid:
                return 'Yes'
            default:
                return 'No'
        }
    }

    return (
        <Container>
            <Field background={colors.neutralLightest}>
                <FieldTitle>Type</FieldTitle>
                <FieldValue>{TaxRegistrationRegistrationTypeTitles[registrationType]}</FieldValue>
            </Field>

            {isRegisteringAsNonSelfEmployed && (
                <Field>
                    <FieldTitle>Reasons</FieldTitle>
                    <FieldValue>
                        {reasons.map(({ id: reasonId, reason, reasonText, reasonDate }) => (
                            <Spacer key={reasonId}>
                                {REASONS_TITLES[reason]} {reasonText && ` (${reasonText})`}{' '}
                                {reasonDate && (
                                    <Label background={colors.neutralLightest}>{formatDate(reasonDate)}</Label>
                                )}
                            </Spacer>
                        ))}
                    </FieldValue>
                </Field>
            )}

            {isRegisteringAsSelfEmployed && (
                <>
                    <Field>
                        <FieldTitle>Self-employment start date</FieldTitle>
                        <FieldValue>{formatDate(selfEmployedStartDate)}</FieldValue>
                    </Field>

                    <Field>
                        <FieldTitle>Business name</FieldTitle>
                        <FieldValue>{businessName ?? '-'}</FieldValue>
                    </Field>

                    <Field>
                        <FieldTitle>Business description</FieldTitle>
                        <FieldValue>{businessDescription ?? '-'}</FieldValue>
                    </Field>
                </>
            )}

            {(previousLastName || changeNameDate) && (
                <>
                    {previousLastName && (
                        <Field>
                            <FieldTitle>Previous last name</FieldTitle>
                            <FieldValue>{previousLastName}</FieldValue>
                        </Field>
                    )}

                    {changeNameDate && (
                        <Field>
                            <FieldTitle>Date the name was changed</FieldTitle>
                            <FieldValue>{formatDate(changeNameDate)}</FieldValue>
                        </Field>
                    )}
                </>
            )}

            {isRegisteringAsSelfEmployed && (
                <Field>
                    <FieldTitle>Business address</FieldTitle>
                    <FieldValue>
                        <div>{businessAddressStreetAddress}</div>
                        <div>
                            {businessAddressCity} {businessAddressPostcode} {businessAddressCountry}
                        </div>
                    </FieldValue>
                </Field>
            )}

            {(streetAddress || city || moveInDate || postcode) && (
                <>
                    {(streetAddress || city || moveInDate) && (
                        <Field>
                            <FieldTitle>Previous home address</FieldTitle>
                            <FieldValue>
                                {streetAddress && <div>{streetAddress}</div>}
                                <div>
                                    {city && city} {postcode && postcode} {country && country}
                                </div>
                            </FieldValue>
                        </Field>
                    )}
                    {moveInDate && (
                        <Field>
                            <FieldTitle>Moved in to previous address</FieldTitle>
                            <FieldValue>{formatDate(moveInDate)}</FieldValue>
                        </Field>
                    )}
                </>
            )}

            <Field>
                <FieldTitle>Has paid</FieldTitle>
                <FieldValue>
                    {getPaymentStatus()} {refundDateUTC && formatDate(refundDateUTC)}
                    {paymentStatus === PAYMENT_STATUS_REFUNDED && (
                        <>
                            {refundReasonType && (
                                <Spacer marginTop="6px">
                                    <Line weight={font.weight.medium} size={font.small}>
                                        {REFUND_REASONS_TITLES[refundReasonType]}
                                    </Line>
                                </Spacer>
                            )}
                            {refundReasonDescription && <Spacer marginTop="6px">{refundReasonDescription}</Spacer>}
                            {supportAccountantFullName && (
                                <Spacer marginTop="6px">Refunded by {supportAccountantFullName}</Spacer>
                            )}
                        </>
                    )}
                </FieldValue>
            </Field>

            {isAdminUser && revenueDateUTC && !!price && (
                <Field>
                    <FieldTitle>Revenue recognised on</FieldTitle>
                    <FieldValue>
                        <Label color={colors.black} background={colors.blueLighter}>
                            {formatDate(revenueDateUTC)}
                        </Label>
                    </FieldValue>
                </Field>
            )}

            <Field>
                <FieldTitle>Status changed</FieldTitle>
                <FieldValue>{formatTimeFromNow(statusChangedDateUTC)}</FieldValue>
            </Field>
        </Container>
    )
}

const Field = styled.div<{ background?: string }>`
    padding: 12px 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${colors.neutralLightest};
    }

    ${media.tablet} {
        background: ${({ background }) => background || 'none'};
        display: flex;
    }
`

const FieldTitle = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    line-height: 1.5;
    white-space: nowrap;

    ${media.tablet} {
        line-height: 1.3;
        padding-right: 18px;
        text-align: right;
        flex: 0 0 50%;
    }
`

const FieldValue = styled.div<{ isFlex?: boolean }>`
    font-size: ${font.small};
    line-height: 1.5;
    display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};

    ${media.tablet} {
        line-height: 1.3;
        padding-left: 18px;
        flex: 0 0 50%;
    }
`
