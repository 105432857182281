import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { matchErrorResponse, matchSuccessResponse, useToggle } from '@scouts/helpers'
import {
    colors,
    font,
    radius,
    AccountantPortalChrome,
    ConfirmationModal,
    Container,
    DropdownButton,
    DropdownButtonProps,
    DropdownSelect,
    DropdownSelectProps,
    Flexer,
    Grid,
    GridItem,
    Line,
    Spacer,
} from '@scouts/ui'

import {
    PATH_CUSTOMER,
    PATH_CUSTOMER_CONSULTATIONS_AGREEMENT,
    PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM,
    PATH_CUSTOMER_TAX_CONSULTATION,
} from '@/constants'
import { formatTimeFromNow } from '@/helpers'
import { CustomerDTO, RefundCreateDTO, TaxConsultationDTO, TaxConsultationTopicTitles } from '@/models'
import { getUser } from '@/selectors'
import {
    useCustomersTaxConsultations,
    useDeleteTaxConsultation,
    useRefundTaxConsultation,
} from '@/store/tax-consultations'

import { RefundModal } from '../_shared/RefundModal'
import CustomerServicesInProgressNotice from '../CustomerServicesInProgressNotice'
import { TaxConsultationAccountantFiles } from './TaxConsultationAccountantFiles'
import { TaxConsultationCustomerFiles } from './TaxConsultationCustomerFiles'
import { TaxConsultationSummary } from './TaxConsultationSummary'

interface TaxConsultationProps {
    customer: CustomerDTO
    taxConsultation: TaxConsultationDTO
}

export const TaxConsultation = ({ customer, taxConsultation }: TaxConsultationProps) => {
    const history = useHistory()

    const { deleteTaxConsultation, isLoading: isLoadingDelete } = useDeleteTaxConsultation()
    const { refundTaxConsultation, isLoading: isLoadingRefund } = useRefundTaxConsultation()
    const [isRefundModalOpen, openRefundModal, closeRefundModal] = useToggle()
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useToggle()

    const { isAdminUser } = useSelector(getUser)

    const { id: customerId } = customer
    const { additionalNotes, hasPaid, id: taxConsultationId, statusChangedDateUTC, topics } = taxConsultation

    const { taxConsultations: customerTaxConsultations } = useCustomersTaxConsultations({ customerId })

    const handleChangeConsultation: DropdownSelectProps['onChange'] = ({ value: updatedTaxConsultationId }) => {
        history.push(
            generatePath(PATH_CUSTOMER_TAX_CONSULTATION, {
                customerId,
                taxConsultationId: updatedTaxConsultationId,
            })
        )
    }

    // @TODO: getting a 404 toast after delete
    const handleDeleteTaxConsultation = () => {
        deleteTaxConsultation({ taxConsultationId })
            .then(matchSuccessResponse(() => history.replace(generatePath(PATH_CUSTOMER, { customerId }))))
            .then(matchErrorResponse(closeDeleteModal))
    }

    const handleRefundTaxConsultation = (payload: RefundCreateDTO) => {
        refundTaxConsultation({ taxConsultationId, data: payload }).then(matchSuccessResponse(closeRefundModal))
    }

    if (!taxConsultation) return null

    const hasTopics = topics.length > 0

    const statusLastChanged = formatTimeFromNow(statusChangedDateUTC)

    const actions: DropdownButtonProps['actions'] = [
        {
            title: 'View letter of engagement',
            onClick: () => {
                history.push(generatePath(PATH_CUSTOMER_CONSULTATIONS_AGREEMENT, { customerId, taxConsultationId }))
            },
        },
        {
            title: 'Write a summary',
            onClick: () => {
                history.push(generatePath(PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM, { customerId, taxConsultationId }))
            },
        },
    ]

    if (isAdminUser) {
        actions.push({
            title: 'Refund',
            onClick: () => openRefundModal(),
        })

        actions.push({
            title: 'Delete',
            onClick: () => openDeleteModal(),
            disabled: hasPaid,
        })
    }

    return (
        <>
            <AccountantPortalChrome.Customer.Page.Container
                padding="24px"
                tabletPadding="0 36px 48px"
                desktopLargePadding="0 48px 48px"
            >
                <AccountantPortalChrome.Customer.Page.Header>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <AccountantPortalChrome.Customer.Page.Title>
                            Consultation
                        </AccountantPortalChrome.Customer.Page.Title>

                        <DropdownSelect
                            name="taxConsultationId"
                            value={taxConsultationId}
                            options={customerTaxConsultations.map(({ id: optionTaxConsultationId }) => ({
                                value: optionTaxConsultationId,
                                title: String(optionTaxConsultationId),
                            }))}
                            onChange={handleChangeConsultation}
                        />

                        <LastUpdated>
                            <span style={{ whiteSpace: 'nowrap' }}>Status changed {statusLastChanged}</span>
                        </LastUpdated>
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <DropdownButton alignRight actions={actions} />
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                </AccountantPortalChrome.Customer.Page.Header>

                <Flexer column gap="30px">
                    <CustomerServicesInProgressNotice />

                    <Container>
                        <Flexer column spaceBetween desktopRow desktopGap="48px" gap="30px">
                            <Spacer desktopWidth="50%">
                                <Container
                                    border={`1px solid ${colors.neutralLightest}`}
                                    radius={radius.large}
                                    padding="24px"
                                >
                                    <TaxConsultationSummary taxConsultation={taxConsultation} />
                                </Container>
                            </Spacer>
                            <Spacer desktopWidth="50%">
                                <TaxConsultationAccountantFiles taxConsultation={taxConsultation} />
                            </Spacer>
                        </Flexer>
                    </Container>

                    {(hasTopics || additionalNotes) && (
                        <Container border={`1px solid ${colors.neutralLightest}`} radius={radius.large} padding="24px">
                            <Grid desktopLargeColumns={3} desktopColumns={2} gap="18px">
                                {hasTopics && (
                                    <GridItem>
                                        <Flexer column gap="12px">
                                            <Line size={font.small} weight={font.weight.medium}>
                                                Topics
                                            </Line>

                                            <Line size={font.small}>
                                                {topics.map(({ topicType }) => (
                                                    <Fragment key={topicType}>
                                                        {TaxConsultationTopicTitles[topicType]}
                                                        <br />
                                                    </Fragment>
                                                ))}
                                            </Line>
                                        </Flexer>
                                    </GridItem>
                                )}
                                {additionalNotes && (
                                    <GridItem>
                                        <Flexer column gap="12px">
                                            <Line size={font.small} weight={font.weight.medium}>
                                                Summary
                                            </Line>
                                            <Line size={font.small}>{additionalNotes}</Line>
                                        </Flexer>
                                    </GridItem>
                                )}
                            </Grid>
                        </Container>
                    )}
                </Flexer>
            </AccountantPortalChrome.Customer.Page.Container>

            <AccountantPortalChrome.Customer.Page.Container
                backgroundColor={colors.neutralLightest}
                radius="12px 0 0 0"
                tabletPadding="24px 36px 40vh"
                desktopLargePadding="24px 48px 40vh"
            >
                <AccountantPortalChrome.Customer.Page.Header>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <AccountantPortalChrome.Customer.Page.SubTitle>
                            Documents
                        </AccountantPortalChrome.Customer.Page.SubTitle>
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup />
                </AccountantPortalChrome.Customer.Page.Header>

                <TaxConsultationCustomerFiles title="From customer" taxConsultationId={taxConsultationId} />
            </AccountantPortalChrome.Customer.Page.Container>

            {isRefundModalOpen && (
                <RefundModal
                    isLoading={isLoadingRefund}
                    itemName="tax consultation"
                    itemPrice={taxConsultation.price}
                    onClose={closeRefundModal}
                    onRefundItem={handleRefundTaxConsultation}
                    title={`Refund ${customer.fullName}'s tax consultation`}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmationModal
                    onCancel={closeDeleteModal}
                    onConfirm={handleDeleteTaxConsultation}
                    isLoading={isLoadingDelete}
                >
                    Are you sure you want to delete this consultation?
                </ConfirmationModal>
            )}
        </>
    )
}

const LastUpdated = styled.div`
    font-size: ${font.smaller};
    line-height: 1.3;
    flex: 1 1 auto;
    overflow: hidden;
    max-width: 240px;
`
