import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { useSetTitle } from '@scouts/helpers'
import { Container, Flexer, Grid, GridItem } from '@scouts/ui'

import { requestAccountant, resetNotesNotifications } from '@/actions'
import { EXTERNAL_API_HMRC } from '@/constants'
import { featureFlags } from '@/feature-flags'
import { getAccountantById, getDraftedReturnsCount } from '@/selectors'
import { useAppDispatch } from '@/store'

import BannerDraftedReturns from '../dashboard/banners/BannerDraftedReturns'
import { BannerHoliday } from '../dashboard/banners/BannerHoliday'
import { BannerTrialWelcome } from '../dashboard/banners/BannerTrialWelcome'
import { PayeApiNotice } from '../dashboard/banners/PayeApiNotice'
import { DashboardEscalations } from '../dashboard/DashboardEscalations'
import { DashboardDownloadsRow } from '../dashboard/downloads/DashboardDownloadsRow'
import { DashboardAccountantMeetings } from '../dashboard/products/DashboardAccountantMeetings'
import { DashboardConsultations } from '../dashboard/products/DashboardConsultations'
import { DashboardStatsRow } from '../dashboard/stats/DashboardStatsRow'
import { DashboardStatsSelfAssessments } from '../dashboard/stats/DashboardStatsSelfAssessments'
import { DashboardStatsTaxConsultations } from '../dashboard/stats/DashboardStatsTaxConsultations'
import { DashboardStatsTaxRegistrations } from '../dashboard/stats/DashboardStatsTaxRegistrations'
import { AccountantDetailsProfile } from './AccountantDetailsProfile'

export const AccountantDetails = () => {
    const dispatch = useAppDispatch()
    const match = useRouteMatch<{ accountantId?: string }>()
    const accountantId = Number(match?.params?.accountantId)

    const accountant = useSelector((state) => getAccountantById(state, { accountantId }))

    const { allowTaxConsultation, allowSubscription, isOnTrial, firstName, lastName, externalApis } = accountant || {}

    const draftedReturnsCount = useSelector(getDraftedReturnsCount)

    // isOnTrial user has less items, so its better to
    // add stat items than display all and then remove
    const isOnTrialUser = isOnTrial ?? true

    const hasHmrcPayeApiConnection = (externalApis || []).some((e: string) => e === EXTERNAL_API_HMRC)
    const shouldDisplayPayeApiNotice = !hasHmrcPayeApiConnection && !isOnTrialUser
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : ''

    useSetTitle(fullName, 'TaxScouts Accountant')

    useEffect(() => {
        dispatch(requestAccountant(accountantId))

        return () => {
            dispatch(resetNotesNotifications())

            document.title = 'TaxScouts Accountant'
        }
    }, [accountantId, dispatch])

    const renderBanner = () => {
        const shouldShowDraftedCustomerBanner =
            featureFlags.featureReturningCustomersBanner &&
            draftedReturnsCount > 0 &&
            BannerDraftedReturns.isBannerHidden() === false
        if (shouldShowDraftedCustomerBanner) return <BannerDraftedReturns draftedReturnsCount={draftedReturnsCount} />

        const shouldShowTrialBanner = featureFlags.featureTourFirstTaxReturn && !!isOnTrialUser
        if (shouldShowTrialBanner) return <BannerTrialWelcome />

        return null
    }

    if (!accountant) return null

    return (
        <>
            {shouldDisplayPayeApiNotice && <PayeApiNotice />}

            <Container tabletPadding="36px 48px 192px" padding="24px 24px 192px">
                <Grid columns={1} tabletColumns="1fr 300px" tabletGap="48px" gap="24px">
                    <GridItem tabletColumn="1" tabletRow="1" row="2">
                        <Flexer column gap="24px" tabletGap="48px">
                            <BannerHoliday />

                            {renderBanner()}

                            <DashboardEscalations accountantId={accountantId} />

                            {allowTaxConsultation && <DashboardConsultations accountantId={accountantId} />}

                            {allowSubscription && <DashboardAccountantMeetings accountantId={accountantId} />}

                            <DashboardStatsSelfAssessments accountantId={accountantId} isOnTrial={isOnTrial} />

                            {!isOnTrial && <DashboardStatsTaxRegistrations accountantId={accountantId} />}

                            {allowTaxConsultation && <DashboardStatsTaxConsultations accountantId={accountantId} />}

                            <DashboardStatsRow accountantId={accountantId} isOnTrial={isOnTrial} />
                            <DashboardDownloadsRow accountantId={accountantId} />
                        </Flexer>
                    </GridItem>
                    <GridItem tabletColumn="2" row="1">
                        <AccountantDetailsProfile accountant={accountant} />
                    </GridItem>
                </Grid>
            </Container>
        </>
    )
}
