import { createSelector } from '@reduxjs/toolkit'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

const getNotes = (state) => _get(state, ['notes', 'data'], [])

export const getNoteById = (state, { noteId }) => _find(getNotes(state), { id: noteId })

const getNotesByCustomerId = (state, { customerId }) => _filter(getNotes(state), ['customerId', customerId])

const filterExcludeInternalEscalationNotes = (note) => {
    const isInternalEscalationNote = !!note.escalationId && !note.isPriority
    if (isInternalEscalationNote) return false
    return true
}
const filterIncludePriorityEscalationNotes = (note) => {
    const isExternalEscalationNote = !!note.escalationId && note.isPriority
    if (isExternalEscalationNote && !note.isResolved) return true
    return false
}

export const getCustomerNotes = (state, { customerId, shouldIgnoreResolved = false }) => {
    const customerNotes = getNotesByCustomerId(state, { customerId })
    const filteredNotes = _filter(customerNotes, filterExcludeInternalEscalationNotes)

    const highPriorityNotes = _orderBy(
        _filter(filteredNotes, { isPriority: true, isResolved: false }),
        'createdDateUTC',
        'desc'
    )
    const unresolvedNotes = _orderBy(
        _filter(filteredNotes, { isPriority: false, isResolved: false }),
        'createdDateUTC',
        'desc'
    )
    const resolvedNotes = _orderBy(_filter(filteredNotes, { isResolved: true }), 'updatedDateUTC', 'desc')

    if (shouldIgnoreResolved) {
        return [...highPriorityNotes, ...unresolvedNotes]
    }

    return [...highPriorityNotes, ...unresolvedNotes, ...resolvedNotes]
}

export const getUnresolvedNoteCount = (state, { customerId }) => {
    const customerNotes = getNotesByCustomerId(state, { customerId })

    return _filter(customerNotes, (note) => filterExcludeInternalEscalationNotes(note) && !note.isResolved).length
}

export const getCustomerUnresolvedPriorityEscalationNotes = (state, { customerId }) => {
    const customerNotes = getNotesByCustomerId(state, { customerId })
    const filteredNotes = _filter(customerNotes, filterIncludePriorityEscalationNotes)

    const priorityEscalationNotes = _orderBy(filteredNotes, 'createdDateUTC', 'desc')

    return priorityEscalationNotes
}

export const getHighPriorityEscalationNotesByEscalationId = (state, { escalationId }) => {
    const allNotes = _filter(getNotes(state), ['escalationId', escalationId])

    return _orderBy(_filter(allNotes, { isPriority: true }), 'createdDateUTC', 'desc')
}

export const getHasPriorityEscalationNotesByEscalationId = createSelector(
    [getHighPriorityEscalationNotesByEscalationId],
    (escalations) => escalations.length > 0
)

export const getRegularEscalationNotesByEscalationId = (state, { escalationId }) => {
    const allNotes = _filter(getNotes(state), ['escalationId', escalationId])

    return _orderBy(_filter(allNotes, { isPriority: false }), 'createdDateUTC', 'desc')
}

const getNotesNotificationsItems = (state) => _get(state, ['notes', 'notifications'], [])

export const getNotesNotificationById = (state, { noteId }) => _find(getNotesNotificationsItems(state), { id: noteId })

export const getNotesNotificationsCount = (state) =>
    _filter(getNotesNotificationsItems(state), { isResolved: false }).length

export const getEscalationNotesNotifications = createSelector([getNotesNotificationsItems], (notesNotifications) =>
    _orderBy(
        _filter(notesNotifications, (note) => !!note.escalationId),
        'createdDateUTC',
        'desc'
    )
)
export const isFetchingNotes = (state) => _get(state, ['notes', 'isFetching'], false)
export const shouldFetchCustomerNotes = (state, { customerId }) => !_find(getNotes(state), { customerId })
