import { PAGINATION_DEFAULT_PAGE } from '@/constants'

import SearchFilterAccountant from '@/components/search/SearchFilterAccountant'

import { useFilters } from '../hooks'

export const OffPlatformServicesRequestSearchFilterAccountantId = () => {
    const { applyFilter, activeFilters, removeFilter } = useFilters()

    return (
        <SearchFilterAccountant
            isOpenByDefault={false}
            activeFilter={activeFilters?.accountantId || 0}
            applyFilter={(updatedFilter) => applyFilter({ updatedFilter })}
            removeFilter={() => removeFilter({ updatedFilter: { accountantId: null, page: PAGINATION_DEFAULT_PAGE } })}
        />
    )
}
