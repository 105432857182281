import { useMemo } from 'react'

import { openFileDialog } from '@scouts/helpers'
import { DropdownButton } from '@scouts/ui'

import { getFileTypeTitle } from '@/domain'
import { FileTypeTypes } from '@/models'

import { useCompanyFileHandlers } from '../hooks/use-company-file-handlers'

export const UploadButton = ({ fileTypes }: { fileTypes?: FileTypeTypes[] }) => {
    const { handleUploadFile, isLoadingUpload } = useCompanyFileHandlers()

    const actions = useMemo(() => {
        return (fileTypes || []).map((type) => ({
            title: getFileTypeTitle(type),
            onClick: () =>
                openFileDialog({
                    onChange: (files) =>
                        handleUploadFile({
                            files,
                            fileType: type,
                        }),
                }),
        }))
    }, [fileTypes, handleUploadFile])

    const hasActions = actions.length > 0

    if (!hasActions) return null

    return (
        <DropdownButton alignRight actions={actions} isLoading={isLoadingUpload}>
            Upload
        </DropdownButton>
    )
}
