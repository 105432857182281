import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import styled from 'styled-components'

import { colors, font, Button } from '@scouts/ui'

import SearchFilterAccountant from '../../search/SearchFilterAccountant'
import SearchFilterDateRange from '../../search/SearchFilterDateRange'
import SearchFilterSupportUser from '../../search/SearchFilterSupportUser'
import EscalationsSearchFilterAdd from './EscalationsSearchFilterAdd'
import EscalationsSearchFilterProductType from './EscalationsSearchFilterProductType'
import EscalationsSearchFilterReasonType from './EscalationsSearchFilterReasonType'
import EscalationsSearchFilterReassigned from './EscalationsSearchFilterReassigned'
import EscalationsSearchFilterSet from './EscalationsSearchFilterSet'
import EscalationsSearchFilterStatus from './EscalationsSearchFilterStatus'
import EscalationsSearchFilterType from './EscalationsSearchFilterType'

const Container = styled.div`
    display: flex;
`

const Filters = styled.div`
    display: flex;
    flex: 0 0 auto;
    margin-right: 12px;
`

const Actions = styled.div`
    display: flex;
    flex: 1 0 auto;
    align-items: center;
`

const ActionsWrap = styled.div`
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    margin-right: 12px;
`

const Action = styled.div`
    flex: 0 0 auto;

    &:not(:last-child) {
        margin-right: 12px;
    }
`

const Total = styled.div`
    color: ${colors.black};
    font-size: ${font.small};
    &:not(:last-child) {
        margin-right: 24px;
    }
`

const filterFields = {
    resolvedDateStart: 'resolvedDate',
    resolvedDateEnd: 'resolvedDate',
    occurredDateStart: 'occurredDate',
    occurredDateEnd: 'occurredDate',
}

const parseFilterFields = (filters = []) => {
    const result = []

    filters.forEach((field) => {
        const name = filterFields[field] ? filterFields[field] : field

        if (!result.includes(name)) {
            result.push(name)
        }
    })

    return result
}

class EscalationsSearchFilter extends Component {
    constructor() {
        super()

        this.state = { activeFilters: [], hasActiveFilters: null, recentFilters: [] }
        _bindAll(this, ['addFilter'])
    }

    static getDerivedStateFromProps({ filter }) {
        const activeFilters = parseFilterFields(Object.keys(filter))
        const hasActiveFilters = activeFilters.length > 0

        return {
            activeFilters,
            hasActiveFilters,
        }
    }

    addFilter(updatedFilter, replace) {
        const recentFilters = parseFilterFields(Object.keys(updatedFilter))
        this.setState({ recentFilters })

        const { applyFilter } = this.props
        applyFilter(updatedFilter, replace)
    }

    render() {
        const { applyFilter, clearFilter, filter, itemsCount, onExport, pagination, removeFilter, replaceFilters } =
            this.props

        const { activeFilters, hasActiveFilters, recentFilters } = this.state

        const { currentPage, perPage, totalCount } = pagination

        const currentPageStarts = perPage * (currentPage - 1) + 1
        const currentPageEnds = currentPageStarts + itemsCount - 1

        const hasResults = totalCount > 0

        const filters = [
            {
                name: 'accountantId',
                component: (isOpenByDefault) => (
                    <SearchFilterAccountant
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['accountantId'], 0)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'supportUserId',
                component: (isOpenByDefault) => (
                    <SearchFilterSupportUser
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['supportUserId'], 0)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'isResolved',
                component: (isOpenByDefault) => (
                    <EscalationsSearchFilterStatus
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['isResolved'])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'type',
                component: (isOpenByDefault) => (
                    <EscalationsSearchFilterType
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['type'])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'reasonType',
                component: (isOpenByDefault) => (
                    <EscalationsSearchFilterReasonType
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['reasonType'])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'productType',
                component: (isOpenByDefault) => (
                    <EscalationsSearchFilterProductType
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['productType'])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'isReassigned',
                component: (isOpenByDefault) => (
                    <EscalationsSearchFilterReassigned
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['isReassigned'])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'occurredDate',
                component: (isOpenByDefault) => (
                    <SearchFilterDateRange
                        applyFilter={applyFilter}
                        dateFromName="occurredDateStart"
                        dateFromValue={_get(filter, ['occurredDateStart'], '')}
                        dateToName="occurredDateEnd"
                        dateToValue={_get(filter, ['occurredDateEnd'], '')}
                        label="Occurred date"
                        isOpenByDefault={isOpenByDefault}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'resolvedDate',
                component: (isOpenByDefault) => (
                    <SearchFilterDateRange
                        applyFilter={applyFilter}
                        dateFromName="resolvedDateStart"
                        dateFromValue={_get(filter, ['resolvedDateStart'], '')}
                        dateToName="resolvedDateEnd"
                        dateToValue={_get(filter, ['resolvedDateEnd'], '')}
                        label="Resolved date"
                        isOpenByDefault={isOpenByDefault}
                        removeFilter={removeFilter}
                    />
                ),
            },
        ]

        return (
            <Container>
                {hasActiveFilters && (
                    <Filters>
                        {activeFilters.map((name) => {
                            const getComponent = _get(_find(filters, { name }), ['component'], () => null)
                            const isOpenByDefault = recentFilters.includes(name)
                            return <div key={name}>{getComponent(isOpenByDefault)}</div>
                        })}
                    </Filters>
                )}
                <Actions>
                    <ActionsWrap>
                        <Action>
                            <EscalationsSearchFilterAdd applyFilter={this.addFilter} activeFilters={activeFilters} />
                        </Action>

                        <Action>
                            <EscalationsSearchFilterSet replaceFilters={replaceFilters} />
                        </Action>
                    </ActionsWrap>

                    {hasResults && (
                        <Total>
                            {currentPageStarts}–{currentPageEnds} of {totalCount}{' '}
                            {totalCount === 1 ? 'result' : 'results'}
                        </Total>
                    )}

                    {!hasResults && <Total>No results found</Total>}

                    {hasActiveFilters && (
                        <Action>
                            <Button isSecondary size="small" onClick={clearFilter}>
                                Clear
                            </Button>
                        </Action>
                    )}

                    {hasResults && (
                        <Action>
                            <Button size="small" onClick={onExport}>
                                Export
                            </Button>
                        </Action>
                    )}
                </Actions>
            </Container>
        )
    }
}

EscalationsSearchFilter.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    itemsCount: PropTypes.number.isRequired,
    onExport: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    removeFilter: PropTypes.func.isRequired,
    replaceFilters: PropTypes.func.isRequired,
}

export default EscalationsSearchFilter
