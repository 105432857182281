import _get from 'lodash/get'

import {
    RECEIVE_ACCOUNTANT_STATISTICS,
    RECEIVE_ACCOUNTANT_STATUS_STATISTICS,
    RECEIVE_CUSTOMERS_LAST_YEAR_STATISTICS,
    RECEIVE_CUSTOMERS_STATISTICS,
    RECEIVE_REVENUE_STATS,
    RECEIVE_SELF_ASSESSMENTS_STATISTICS,
    RECEIVE_TAX_CONSULTATIONS_STATISTICS,
    RECEIVE_TAX_REGISTRATIONS_STATISTICS,
    REQUEST_ACCOUNTANT_STATISTICS,
} from '../actions'

const defaultState = {
    accountantStatistics: {
        accountants: [],
        pagination: {
            currentPage: null,
            pageCount: null,
            perPage: null,
            totalCount: null,
        },
        isFetching: false,
        isEmptySearchResults: false,
    },
    accountantStatusStatistics: [],
    customerLastYearStatistics: {},
    customersStatistics: {},
    selfAssessmentStatistics: [],
    taxRegistrationsStatistics: [],
    revenueStats: {},
}

export default (state = defaultState, action) => {
    const { type, data, meta } = action

    switch (type) {
        case RECEIVE_ACCOUNTANT_STATUS_STATISTICS: {
            return { ...state, accountantStatusStatistics: data }
        }

        case RECEIVE_CUSTOMERS_LAST_YEAR_STATISTICS: {
            return { ...state, customerLastYearStatistics: data }
        }

        case RECEIVE_CUSTOMERS_STATISTICS: {
            return { ...state, customersStatistics: data }
        }

        case RECEIVE_SELF_ASSESSMENTS_STATISTICS: {
            return { ...state, selfAssessmentStatistics: data }
        }

        case RECEIVE_TAX_REGISTRATIONS_STATISTICS: {
            return { ...state, taxRegistrationsStatistics: data }
        }

        case RECEIVE_TAX_CONSULTATIONS_STATISTICS: {
            return { ...state, taxConsultationsStatistics: data }
        }

        case REQUEST_ACCOUNTANT_STATISTICS: {
            const { accountantStatistics } = state
            return {
                ...state,
                accountantStatistics: {
                    ...accountantStatistics,
                    isFetching: true,
                    isEmptySearchResults: false,
                },
            }
        }
        case RECEIVE_ACCOUNTANT_STATISTICS: {
            const pagination = _get(meta, ['pagination'], {})
            const isEmptySearchResults = _get(meta, ['isEmptySearchResults'], false)
            return {
                ...state,
                accountantStatistics: {
                    accountants: data,
                    pagination,
                    isFetching: false,
                    isEmptySearchResults,
                },
            }
        }

        case RECEIVE_REVENUE_STATS:
            return { ...state, revenueStats: data }

        default:
            return state
    }
}
