import { useHistory } from 'react-router-dom'

import { useToggle } from '@scouts/helpers'
import {
    Button,
    ConfirmationModal,
    ModalActionsLegacy as ModalActions,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { deleteSelfAssessment } from '@/actions'
import { PATH_CUSTOMERS, TAX_YEARS } from '@/constants'
import { SelfAssessmentDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface SelfAssessmentDeleteModalProps {
    onClose: () => void
    selfAssessment: SelfAssessmentDTO
}

export const SelfAssessmentDeleteModal = ({ selfAssessment, onClose }: SelfAssessmentDeleteModalProps) => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useToggle()

    const { id, customerId, taxYear } = selfAssessment

    const handleDelete = () => {
        dispatch(deleteSelfAssessment({ id, onSuccess: redirectOnSuccess }))
    }

    const redirectOnSuccess = () => {
        if (customerId) {
            history.push(`${PATH_CUSTOMERS}/${customerId}`)
        }
    }

    return (
        <>
            <Modal onClose={onClose}>
                <ModalHeader>
                    <ModalTitle>Delete {TAX_YEARS[Number(taxYear) as keyof typeof TAX_YEARS]} tax return</ModalTitle>
                </ModalHeader>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={onClose}>
                            Cancel
                        </Button>

                        <Button onClick={openConfirmationModal}>Delete tax return</Button>
                    </ModalActions>
                </ModalFooter>
            </Modal>
            {isConfirmationModalOpen && (
                <ConfirmationModal onConfirm={handleDelete} onCancel={closeConfirmationModal}>
                    Are you sure you want to delete this tax return?
                </ConfirmationModal>
            )}
        </>
    )
}
