import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
    font,
    Button,
    ButtonLink,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { PATH_CUSTOMERS, PRODUCT_BUNDLE_REASONS_TITLES, REFUND_REASONS_TITLES, TAX_YEARS } from '@/constants'
import { formatCurrency, formatDate } from '@/helpers'
import { CustomerDTO, ProductBundleDetailsDTO } from '@/models'
import { getProductBundleById, getSelfAssessmentById } from '@/selectors'
import { RootState } from '@/store'

interface ProductBundleDetailsModelProps {
    customerId: CustomerDTO['id']
    handleClose: () => void
    productBundleId: ProductBundleDetailsDTO['id']
}

export const ProductBundleDetailsModal = ({
    customerId,
    handleClose,
    productBundleId,
}: ProductBundleDetailsModelProps) => {
    const history = useHistory()
    const {
        hasPaid,
        paymentDateUTC,
        price,
        productBundleReasons,
        refundDateUTC,
        refundReason,
        selfAssessmentId,
        taxConsultationId,
    } = useSelector((state) => getProductBundleById(state, { productBundleId }))

    const { refundReasonType, refundReasonDescription, supportAccountantFullName } = refundReason || {}

    const { taxYear: selfAssessmentTaxYear } = useSelector(
        (state: RootState) => getSelfAssessmentById(state, { selfAssessmentId }) || {}
    )

    const selfAssessmentUrl = `${PATH_CUSTOMERS}/${customerId}/assessment/${selfAssessmentId}`
    const taxConsultationUrl = `${PATH_CUSTOMERS}/${customerId}/consultations/${taxConsultationId}`

    return (
        <Modal onClose={handleClose}>
            <ModalHeader>
                <ModalTitle>Bundle purchase details</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Title>Purchase date</Title>
                    {hasPaid && <Details>{paymentDateUTC ? formatDate(paymentDateUTC) : 'Free / No date'}</Details>}
                    {!hasPaid && <Details>Not paid</Details>}
                </Row>
                {productBundleReasons?.length > 0 && (
                    <Row>
                        <Title>Reasons</Title>
                        <Details>
                            {productBundleReasons
                                .map(
                                    ({ type }: { type: keyof typeof PRODUCT_BUNDLE_REASONS_TITLES }) =>
                                        PRODUCT_BUNDLE_REASONS_TITLES[type]
                                )
                                .join(', ')}
                        </Details>
                    </Row>
                )}
                <Row>
                    <Title>Price</Title>
                    <Details>{price > 0 ? formatCurrency(price) : 'Free'}</Details>
                </Row>
                {!!refundDateUTC && (
                    <Row>
                        <Title>Refund date</Title>
                        <Details>{formatDate(refundDateUTC)}</Details>
                    </Row>
                )}
                {!!refundReasonType && (
                    <Row>
                        <Title>Refund reason</Title>
                        <Details>
                            {REFUND_REASONS_TITLES[refundReasonType as keyof typeof REFUND_REASONS_TITLES]}
                        </Details>
                    </Row>
                )}
                {!!refundReasonDescription && (
                    <Row>
                        <Title>Refund details</Title>
                        <Details>{refundReasonDescription}</Details>
                    </Row>
                )}
                {!!supportAccountantFullName && (
                    <Row>
                        <Title>Refunded by</Title>
                        <Details>{supportAccountantFullName}</Details>
                    </Row>
                )}
                <Row>
                    <Title>Linked tax return</Title>
                    <Details>
                        {selfAssessmentId ? (
                            <ButtonLink onClick={() => history.push(selfAssessmentUrl)}>
                                Tax year {TAX_YEARS[selfAssessmentTaxYear as keyof typeof TAX_YEARS]}
                            </ButtonLink>
                        ) : (
                            'None'
                        )}
                    </Details>
                </Row>
                <Row>
                    <Title>Linked consultation</Title>
                    <Details>
                        {taxConsultationId ? (
                            <ButtonLink onClick={() => history.push(taxConsultationUrl)}>
                                #{taxConsultationId}
                            </ButtonLink>
                        ) : (
                            'None'
                        )}
                    </Details>
                </Row>
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button onClick={handleClose}>Close</Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}

const Row = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 18px;
    }
`

const Title = styled.div`
    font-weight: ${font.weight.medium};
    flex: 0 0 calc(35% - 12px);
`

const Details = styled.div`
    flex: 0 0 calc(65% - 12px);
`
