import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { colors, ActionButton, ButtonLink, ConfirmationModal, Form, Heading, List, Loadable, Page } from '@scouts/ui'

import { deletePayeAccessToken, receiveErrorAlerts, requestPayeAccessToken, requestPayeAuthorisation } from '@/actions'
import { formatDateWithTime } from '@/helpers'

import HmrcPreviewData from '../../HmrcPreviewData'

class IntegrationsPaye extends Component {
    constructor(props) {
        super(props)

        this.state = {
            externalAccessTokenId: null,
            isCreatingIntegration: false,
            isDeleteConfirmationOpen: false,
            isLoading: true,
            refreshTokenExpiresOnUTC: null,
        }

        _bindAll(this, ['confirmDeleteIntegration', 'createIntegration', 'deleteIntegration'])
    }

    componentDidMount() {
        this.syncIntegration()
    }

    syncIntegration() {
        const { dispatch } = this.props

        dispatch(
            requestPayeAccessToken({
                onSuccess: ({ externalAccessTokenId, refreshTokenExpiresOnUTC }) => {
                    this.setState({
                        isLoading: false,
                        externalAccessTokenId,
                        refreshTokenExpiresOnUTC,
                    })
                },
                onError: (error) => {
                    this.setState({ isLoading: false })
                    if (error.response && error.response.status !== 404) {
                        dispatch(receiveErrorAlerts('An error occurred while authenticating with HMRC PAYE API'))
                    }
                },
            })
        )
    }

    createIntegration() {
        const { dispatch } = this.props
        const { isCreatingIntegration } = this.state

        if (isCreatingIntegration) return

        this.setState({ isCreatingIntegration: true })

        dispatch(
            requestPayeAuthorisation({
                onSuccess: ({ url }) => {
                    window.location.assign(url)
                },
                onError: () => {
                    this.setState({ isCreatingIntegration: false })
                    dispatch(receiveErrorAlerts('An error occurred while connecting to HMRC PAYE API'))
                },
            })
        )
    }

    confirmDeleteIntegration() {
        this.setState({ isDeleteConfirmationOpen: true })
    }

    deleteIntegration() {
        this.setState({ isDeleteConfirmationOpen: false })

        const { dispatch } = this.props
        const { externalAccessTokenId } = this.state

        this.setState({ isLoading: true })

        dispatch(
            deletePayeAccessToken({
                externalAccessTokenId,
                onSuccess: () => {
                    this.setState({
                        isLoading: false,
                        externalAccessTokenId: null,
                        refreshTokenExpiresOnUTC: null,
                    })
                },
                onError: () => {
                    this.setState({ isLoading: false })
                    dispatch(receiveErrorAlerts('An error occurred while disconnecting from HMRC PAYE API'))
                },
            })
        )
    }

    renderLoading() {
        return (
            <Page>
                <Loadable isLoading />
            </Page>
        )
    }

    renderStartIntegration() {
        const { isCreatingIntegration } = this.state
        return (
            <Page>
                <Page.Heading>Connect to HMRC PAYE API</Page.Heading>
                <Page.Section>
                    <List>
                        <List.Item>
                            Using this API you can pull the PAYE information on your clients direct from HMRC.
                        </List.Item>
                        <List.Item>
                            Information includes employment earnings, tax paid, taxable benefits and also state and
                            private pension income.
                        </List.Item>
                        <List.Item>
                            You can pull this information for any clients where you have been granted agent
                            authorisation (who has been registered for Self Assessment).
                        </List.Item>
                        <List.Item>
                            Additionally this enables the platform to automatically sync the information for the latest
                            tax year, and for us to pre-fill some of the information for client's tax returns in the
                            background, providing you have Agent Authorisation. The result is a quicker, cleaner
                            experience where customers can complete their returns in a few clicks while reducing your
                            workload.
                        </List.Item>
                    </List>
                </Page.Section>

                <Form.Actions background={colors.blueLighter}>
                    <ActionButton onClick={this.createIntegration} isLoading={isCreatingIntegration}>
                        Start here
                    </ActionButton>
                </Form.Actions>
            </Page>
        )
    }

    renderExistingIntegration() {
        const { refreshTokenExpiresOnUTC, isDeleteConfirmationOpen } = this.state

        return (
            <Page>
                <Page.Heading>You are connected to HMRC PAYE API</Page.Heading>

                <Page.Section>
                    Your connection to HMRC expires on {formatDateWithTime(refreshTokenExpiresOnUTC)}
                    <br />
                    <br />
                    <ButtonLink onClick={this.confirmDeleteIntegration}>Disconnect from HMRC</ButtonLink>
                </Page.Section>

                <Page.Section>
                    <Heading>Preview HMRC data</Heading>
                    <HmrcPreviewData />
                </Page.Section>

                {isDeleteConfirmationOpen && (
                    <ConfirmationModal
                        onCancel={() => this.setState({ isDeleteConfirmationOpen: false })}
                        onConfirm={this.deleteIntegration}
                    >
                        Please confirm that you wish to disconnect from HMRC PAYE API
                    </ConfirmationModal>
                )}
            </Page>
        )
    }

    render() {
        const { isLoading, externalAccessTokenId } = this.state

        if (isLoading) return this.renderLoading()
        if (!externalAccessTokenId) return this.renderStartIntegration()
        return this.renderExistingIntegration()
    }
}

IntegrationsPaye.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

export default connect()(IntegrationsPaye)
