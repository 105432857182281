import { useHistory } from 'react-router-dom'

import { colors, font, Button, Container, Flexer, Line, Page, Paragraph, Spacer } from '@scouts/ui'

import { PATH_TOOLS_CGT } from '@/constants'

export const ToolsContainer = () => {
    const history = useHistory()

    return (
        <Page>
            <Page.Heading>Tools</Page.Heading>

            <Page.Section>
                <Container padding="24px" background={colors.neutralLightest}>
                    <Flexer column gap="18px">
                        <Line>Capital Gains Calculator</Line>
                        <Paragraph size={font.small}>
                            This calculator can be used to work out the capital gain from a series of share or crypto
                            transactions. It applies HMRC rules to work out the gain or loss from the disposals.
                        </Paragraph>
                        <Spacer>
                            <Button onClick={() => history.push(PATH_TOOLS_CGT)}>View calculator</Button>
                        </Spacer>
                    </Flexer>
                </Container>
            </Page.Section>
        </Page>
    )
}
