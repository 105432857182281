import _orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { useGetCompaniesQuery, GetCompaniesParams } from '../api'

export const useCompanies = ({ customerId }: GetCompaniesParams) => {
    const { data, isLoading } = useGetCompaniesQuery({ customerId })

    const orderedCompanies = useMemo(() => _orderBy(data, 'id', 'asc'), [data])

    return {
        companies: orderedCompanies,
        isLoading,
    }
}
