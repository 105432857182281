import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Table } from '@scouts/ui'

import {
    ESCALATION_SORT_TYPE_ACCOUNTANT_NAME,
    ESCALATION_SORT_TYPE_OCCURRED_DATE,
    ESCALATION_SORT_TYPE_RESOLVED_DATE,
    ESCALATION_SORT_TYPE_SUPPORT_USER_NAME,
    PATH_ESCALATIONS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from '../../constants'
import { SortOrderArrow } from '../ui/SortOrderArrow'
import EscalationsTableRow from './EscalationsTableRow'

const Container = styled.div`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const updateSortOrder = (currentSortOrder, hasSortTypeChanged) => {
    if (hasSortTypeChanged) return SORT_ORDER_DESC

    if (currentSortOrder === SORT_ORDER_DESC) return SORT_ORDER_ASC

    return SORT_ORDER_DESC
}

const parseFilterFromToken = (filterToken) => {
    if (!filterToken) return {}

    try {
        return JSON.parse(atob(filterToken))
    } catch (exception) {
        return {}
    }
}

const EscalationsTable = ({ isFetching, escalations }) => {
    const history = useHistory()

    const currentQuery = queryString.parse(history.location.search)
    const currentFilterString = currentQuery?.filter
    const currentFilter = parseFilterFromToken(currentFilterString)

    const handleSortTypeChange = (sortType) => {
        const hasSortTypeChanged = currentFilter?.sortType !== sortType
        const currentSortOrder = currentFilter?.sortOrder
        const newSortOrder = updateSortOrder(currentSortOrder, hasSortTypeChanged)

        const newFilter = { ...currentFilter, sortType, sortOrder: newSortOrder }
        const newFilterString = btoa(JSON.stringify(newFilter))
        const newQuery = queryString.stringify({ ...currentQuery, filter: newFilterString })

        const location = {
            pathname: PATH_ESCALATIONS,
            search: newQuery,
        }

        history.push(location)
    }

    const isSortingType = (givenSortType) => currentFilter?.sortType === givenSortType

    return (
        <Container isFetching={isFetching}>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell width="24%">Client</Table.Header.Cell>
                        <Table.Header.Cell
                            width="10%"
                            action={() => handleSortTypeChange(ESCALATION_SORT_TYPE_OCCURRED_DATE)}
                        >
                            Occurred{' '}
                            {isSortingType(ESCALATION_SORT_TYPE_OCCURRED_DATE) && (
                                <SortOrderArrow sortOrder={currentFilter?.sortOrder} />
                            )}
                        </Table.Header.Cell>
                        <Table.Header.Cell
                            width="15%"
                            action={() => handleSortTypeChange(ESCALATION_SORT_TYPE_ACCOUNTANT_NAME)}
                        >
                            Accountant{' '}
                            {isSortingType(ESCALATION_SORT_TYPE_ACCOUNTANT_NAME) && (
                                <SortOrderArrow sortOrder={currentFilter?.sortOrder} />
                            )}
                        </Table.Header.Cell>
                        <Table.Header.Cell width="11%">Type</Table.Header.Cell>
                        <Table.Header.Cell width="calc(14% - 12px)">Reason</Table.Header.Cell>
                        <Table.Header.Cell width="12px" />
                        <Table.Header.Cell
                            width="10%"
                            action={() => handleSortTypeChange(ESCALATION_SORT_TYPE_SUPPORT_USER_NAME)}
                        >
                            Assigned{' '}
                            {isSortingType(ESCALATION_SORT_TYPE_SUPPORT_USER_NAME) && (
                                <SortOrderArrow sortOrder={currentFilter?.sortOrder} />
                            )}
                        </Table.Header.Cell>
                        <Table.Header.Cell width="6%">Status</Table.Header.Cell>
                        <Table.Header.Cell
                            width="10%"
                            action={() => handleSortTypeChange(ESCALATION_SORT_TYPE_RESOLVED_DATE)}
                        >
                            Resolved{' '}
                            {isSortingType(ESCALATION_SORT_TYPE_RESOLVED_DATE) && (
                                <SortOrderArrow sortOrder={currentFilter?.sortOrder} />
                            )}
                        </Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>
                <Table.Body>
                    {escalations.map((escalation) => {
                        const { id } = escalation
                        return <EscalationsTableRow key={id} escalation={escalation} />
                    })}
                </Table.Body>
            </Table>
        </Container>
    )
}

EscalationsTable.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    escalations: PropTypes.array.isRequired,
}

export default EscalationsTable
