import { useState } from 'react'
import styled from 'styled-components'

import { colors, Flexer, Label, Tooltip } from '@scouts/ui'

import { AccountantDetailsDTO, AccountantStatus } from '@/models'

import { formatDate } from '../../helpers'
import AccountantQuickSettingsModal from './AccountantQuickSettingsModal'

const getStatusLabel = (status?: AccountantDetailsDTO['status']) => {
    switch (true) {
        case status === AccountantStatus.Draft:
            return `Draft`
        case status === AccountantStatus.Active:
            return `Active`
        case status === AccountantStatus.Paused:
            return `Paused`
        case status === AccountantStatus.Terminated:
            return `Terminated`
        default:
            return '?'
    }
}

const getFontColor = (status?: AccountantDetailsDTO['status']) => {
    switch (true) {
        case status === AccountantStatus.Draft:
            return colors.white
        default:
            return colors.black
    }
}

const getCapacityBackground = (
    status?: AccountantDetailsDTO['status'],
    capacity?: AccountantDetailsDTO['capacity']
) => {
    switch (true) {
        case status === AccountantStatus.Draft:
            return colors.black
        case status === AccountantStatus.Active && !!capacity && capacity > 0:
            return colors.mintLighter
        case status === AccountantStatus.Active && (!capacity || capacity === 0):
            return colors.yellow
        case status === AccountantStatus.Paused:
            return colors.yellow
        case status === AccountantStatus.Terminated:
            return colors.red
        default:
            return colors.neutralLightest
    }
}

const getStatusBackground = (status?: AccountantDetailsDTO['status']) => {
    switch (true) {
        case status === AccountantStatus.Draft:
            return colors.black
        case status === AccountantStatus.Active:
            return colors.mintLighter
        case status === AccountantStatus.Paused:
            return colors.yellow
        case status === AccountantStatus.Terminated:
            return colors.red
        default:
            return colors.neutralLightest
    }
}

interface AccountantStatusLabelProps {
    accountantId: AccountantDetailsDTO['id']
    allowCompany?: AccountantDetailsDTO['allowCompany']
    allowTaxConsultation: AccountantDetailsDTO['allowTaxConsultation']
    capacity: AccountantDetailsDTO['capacity']
    companyStatus?: AccountantDetailsDTO['companyStatus']
    currentPausedDescription: AccountantDetailsDTO['currentPausedDescription']
    currentPausedScheduledEndDate: AccountantDetailsDTO['currentPausedScheduledEndDate']
    onCloseQuickSettings?: (hasChanged: boolean) => void
    status: AccountantDetailsDTO['status']
    taxConsultationStatus: AccountantDetailsDTO['taxConsultationStatus']
}

export const AccountantStatusLabel = ({
    accountantId,
    allowCompany,
    allowTaxConsultation,
    capacity,
    companyStatus,
    currentPausedDescription,
    currentPausedScheduledEndDate,
    onCloseQuickSettings,
    status,
    taxConsultationStatus,
}: AccountantStatusLabelProps) => {
    const isWithCapacityLabel =
        [
            AccountantStatus.Active as AccountantDetailsDTO['status'],
            AccountantStatus.Paused as AccountantDetailsDTO['status'],
        ].includes(status) && capacity >= 0

    const [isQuickSettingsModalOpen, setQuickSettingsModalOpen] = useState(false)

    const handleCloseQuickSettings = (hasChanged: boolean) => {
        setQuickSettingsModalOpen(false)

        if (onCloseQuickSettings) {
            onCloseQuickSettings(hasChanged)
        }
    }

    const pausedDate = currentPausedScheduledEndDate
        ? `\n Paused until ${formatDate(currentPausedScheduledEndDate)}`
        : ''
    const pausedInfo = currentPausedDescription ? `\n ${currentPausedDescription}` : ''
    const pausedTooltipInfo = `Unavailable ${pausedDate} ${pausedInfo}`

    return (
        <>
            <Container title="Click to edit" onClick={() => setQuickSettingsModalOpen(true)}>
                <Flexer gap="2px">
                    <Label background={getStatusBackground(status)} color={getFontColor(status)}>
                        {getStatusLabel(status)}
                    </Label>
                    {!!pausedDate && (
                        <Tooltip content={pausedTooltipInfo} position="right">
                            <Label background={colors.orange} color={colors.black}>
                                !
                            </Label>
                        </Tooltip>
                    )}
                    {isWithCapacityLabel && (
                        <Label
                            title="Capacity"
                            background={getCapacityBackground(status, capacity)}
                            color={getFontColor(status)}
                        >
                            {capacity}
                        </Label>
                    )}
                    {allowTaxConsultation && (
                        <Label
                            title="Tax consultations"
                            background={getStatusBackground(taxConsultationStatus)}
                            color={colors.black}
                        >
                            TC
                        </Label>
                    )}
                    {allowCompany && (
                        <Label
                            title="Company returns"
                            background={getStatusBackground(companyStatus)}
                            color={colors.black}
                        >
                            CR
                        </Label>
                    )}
                </Flexer>
            </Container>

            {isQuickSettingsModalOpen && (
                <AccountantQuickSettingsModal accountantId={accountantId} onClose={handleCloseQuickSettings} />
            )}
        </>
    )
}

const Container = styled.div`
    display: inline-block;
    cursor: pointer;
`
