import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery } from 'redux-saga/effects'

import {
    receiveCustomerFiles,
    receiveSuccessAlerts,
    removeCustomerFile,
    requestCustomerFiles,
    DELETE_CUSTOMER_FILE,
    DOWNLOAD_CUSTOMER_FILE,
    REQUEST_CUSTOMER_FILES,
    UPLOAD_CUSTOMER_FILE,
} from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* customerFilesSagas() {
    yield takeEvery(DELETE_CUSTOMER_FILE, deleteCustomerFile)
    yield takeEvery(DOWNLOAD_CUSTOMER_FILE, downloadCustomerFile)
    yield takeEvery(REQUEST_CUSTOMER_FILES, fetchCustomerFiles)
    yield takeEvery(UPLOAD_CUSTOMER_FILE, uploadCustomerFile)
}

function* fetchCustomerFiles({ data }) {
    const customerId = _get(data, ['customerId'], null)

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${customerId}/files`)
        yield put(receiveCustomerFiles(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* uploadCustomerFile({ data, options }) {
    const { onSuccess } = options

    const customerId = _get(data, ['customerId'], null)
    const fileType = _get(data, ['fileType'], null)
    const file = _get(data, ['file'], null)
    const identityVerificationId = _get(data, ['identityVerificationId'], null)

    const url = `customerfiles?customerId=${customerId}&fileType=${fileType}${
        identityVerificationId ? `&identityVerificationId=${identityVerificationId}` : ''
    }`

    const formData = new FormData()
    formData.append('file', file)

    try {
        yield put(showLoading())
        yield apiCall(url, 'post', formData)

        if (onSuccess) onSuccess()

        yield put(receiveSuccessAlerts('File successfully uploaded'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* deleteCustomerFile({ data }) {
    const id = _get(data, ['file', 'id'], null)
    const customerId = _get(data, ['file', 'customerId'], null)

    try {
        yield put(showLoading())
        yield apiCall(`customerfiles/${id}`, 'delete')
        yield put(removeCustomerFile({ id }))
        yield put(requestCustomerFiles({ customerId }))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* downloadCustomerFile({ data }) {
    const id = _get(data, ['id'], null)

    try {
        yield put(showLoading())
        const response = yield apiCall(`customerfiles/${id}/downloadlink`)
        if (response.data) {
            window.location.assign(response.data)
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
