import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import _flatten from 'lodash/flatten'

import { addErrorToast } from '@scouts/ui'

import { ERROR_MESSAGE_GENERIC } from '@/constants'

import { ErrorHandlerOptions, ErrorResponseData, ErrorResponseDataModelState } from '../types'

const getIsSerializedError = (error: FetchBaseQueryError | SerializedError): error is SerializedError => {
    return !('status' in error)
}

/** In case of 404 error, response data may be a string, not object */
const getIsStringResponse = (data: unknown): data is string => {
    return typeof data === 'string' && !!data
}

/** If error contains ModelState, it means that there are validation errors to render */
const getIsModelStateErrorData = (data: unknown): data is ErrorResponseData => {
    return typeof data === 'object' && !!data && 'modelState' in data && typeof data.modelState === 'object'
}

/** File uploads may return { message: 'Unsupported file extension' } */
const getIsGenericMessageResponse = (data: unknown): data is { message: string } => {
    return typeof data === 'object' && !!data && 'message' in data && typeof data.message === 'string'
}

/**
 * Parse error response from ModelState sent by our server, and render them as toasts.
 */
export const renderErrorToasts = (
    errorResponse: FetchBaseQueryError | SerializedError,
    { customMessage }: ErrorHandlerOptions
) => {
    if (getIsSerializedError(errorResponse)) {
        addErrorToast({ body: ERROR_MESSAGE_GENERIC })
        return
    }

    const data = errorResponse?.data || null
    const status = errorResponse?.status || null
    const errorMessages: string[] = []

    const isServerError5XX = String(status)[0] === '5'

    if (customMessage) {
        errorMessages.push(customMessage)
    } else if (data) {
        const isStringResponse = getIsStringResponse(data)
        const isModelStateResponse = getIsModelStateErrorData(data)
        const isGenericMessageResponse = getIsGenericMessageResponse(data)

        if (isStringResponse) {
            errorMessages.push(data)
        } else if (isModelStateResponse) {
            const modelStateMessages = getModelStateMessages(data.modelState)
            errorMessages.push(...modelStateMessages)
        } else if (isGenericMessageResponse) {
            errorMessages.push(data.message)
        }
    } else if (isServerError5XX) {
        errorMessages.push(`An error occurred (${status})`)
    }

    if (errorMessages.length) {
        errorMessages.forEach((body) => addErrorToast({ body }))
    } else {
        addErrorToast({ body: ERROR_MESSAGE_GENERIC })
    }
}

/**
 * Parse server error messages from ModelState. Avoids duplicate messages.
 */
const getModelStateMessages = (modelState: ErrorResponseDataModelState) => {
    const messagesArray = Array.from(new Set(_flatten(Object.values(modelState))))

    if (messagesArray.length <= 3) {
        return messagesArray
    } else {
        const firstTwoMessages = messagesArray.slice(0, 2)
        const otherMessagesMergedIntoOne = messagesArray.slice(2).join('. ') // TODO: This might create a long error toast, that covers the whole screen
        return [...firstTwoMessages, otherMessagesMergedIntoOne]
    }
}
