import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { colors, Notice, TypeSelect } from '@scouts/ui'

import {
    exportCustomers,
    requestAccountants,
    requestCustomersLastYearStatistics,
    requestReturningCustomers,
} from '../../actions'
import {
    CUSTOMER_VIEW_DRAFTED,
    CUSTOMER_VIEW_NOT_STARTED,
    LINK_NOTION_DRAFTED_RETURNS,
    PAGINATION_DEFAULT_PAGE,
    PATH_RETURNING_CUSTOMERS,
} from '../../constants'
import { isAdmin } from '../../helpers'
import { getAccountantsWithFullNameAndStatus } from '../../selectors'
import BulkActions from '../BulkActions'
import PaginationContainer from '../PaginationContainer'
import ReturningCustomersFilter from './ReturningCustomersFilter'
import ReturningCustomersTable from './ReturningCustomersTable'

const Container = styled.div`
    padding: 24px 24px 192px;
`

const AccountantFilterContainer = styled.div`
    margin-bottom: 12px;
    max-width: 320px;

    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        height: 35px;
        border: 1px solid ${colors.black};
        pointer-events: none;
    `};
`

const FilterContainer = styled.div`
    margin-bottom: 12px;

    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const PaginationContainerWrap = styled.div`
    margin-top: 24px;
`

const BulkActionsContainer = styled.div`
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 9000;
    width: calc(100% - 36px);
    padding: 18px;
    background: ${colors.blueLighter};
`

const CleanLink = styled.a`
    text-decoration: none;
`

class ReturningCustomers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterAccountant: null,
            filterView: null,
            selectedItems: [],
            page: null,
            pageSize: null,
        }

        _bindAll(this, [
            'deselectAll',
            'download',
            'handleAccountantChange',
            'selectAll',
            'toggleSelection',
            'triggerTableUpdate',
        ])
    }

    componentDidMount() {
        const { dispatch, location, history } = this.props

        const { filterView, filterAccountant } = this.parseFilterAndPaginationFromUrl(location)

        if (!filterView && !filterAccountant) {
            history.replace({
                pathname: PATH_RETURNING_CUSTOMERS,
                search: queryString.stringify({ view: CUSTOMER_VIEW_NOT_STARTED }),
            })
        } else {
            this.update()
        }

        dispatch(requestAccountants())
    }

    componentDidUpdate() {
        this.update()
    }

    handleAccountantChange({ value: accountantId }) {
        const { history, location } = this.props

        const { filterView } = this.parseFilterAndPaginationFromUrl(location)
        const query = { view: filterView }

        if (accountantId) {
            query.accountantId = accountantId
        }

        history.push({
            pathname: PATH_RETURNING_CUSTOMERS,
            search: queryString.stringify(query),
        })
    }

    update() {
        const { dispatch, location } = this.props
        const {
            filterAccountant: prevFilterAccountant,
            filterView: prevFilterView,
            page: prevPage,
            pageSize: prevPageSize,
        } = this.state

        const { filterView, filterAccountant, page, pageSize } = this.parseFilterAndPaginationFromUrl(location)

        const hasUpdated =
            prevFilterView !== filterView ||
            prevFilterAccountant !== filterAccountant ||
            page !== prevPage ||
            pageSize !== prevPageSize

        if (hasUpdated) {
            this.fetch({ view: filterView, accountantId: filterAccountant, page, pageSize })
            this.deselectAll()

            this.setState({ filterView, filterAccountant, page, pageSize })
        }

        if (prevFilterAccountant !== filterAccountant) {
            dispatch(requestCustomersLastYearStatistics({ accountantId: filterAccountant }))
        }
    }

    triggerTableUpdate() {
        const { location } = this.props
        const { filterView, filterAccountant, page, pageSize } = this.parseFilterAndPaginationFromUrl(location)

        this.fetch({ view: filterView, accountantId: filterAccountant, page, pageSize })
    }

    parseFilterAndPaginationFromUrl(location) {
        const { search } = location

        const query = queryString.parse(search)

        const filterView = query?.view || ''
        const filterAccountant = query?.accountantId || ''
        const page = Number(query?.page || PAGINATION_DEFAULT_PAGE)
        const pageSize = Number(query?.pageSize || 25)

        return { filterView, filterAccountant, page, pageSize }
    }

    fetch({ view, accountantId, page, pageSize }) {
        const { dispatch } = this.props

        dispatch(requestReturningCustomers({ view, accountantId, page, pageSize }))
    }

    toggleSelection({ id, currentSelfAssessmentOneClickTaxReturn, name, status, unsubscribeFromBulkEmails }) {
        const { selectedItems } = this.state

        const isSelected = selectedItems.findIndex((o) => o.customerId === id) > -1

        let updatedSelectedItems = [...selectedItems]

        if (isSelected) {
            updatedSelectedItems = selectedItems.filter((o) => !(o.customerId === id))
        } else {
            updatedSelectedItems.push({
                customerId: id,
                fullName: name,
                currentSelfAssessmentOneClickTaxReturn,
                unsubscribeFromBulkEmails,
                status,
            })
        }

        this.setState({
            selectedItems: updatedSelectedItems,
        })
    }

    selectAll() {
        const { returningCustomers } = this.props
        const selectedItems = []

        returningCustomers.forEach(
            ({ id, currentSelfAssessmentOneClickTaxReturn, fullName, status, unsubscribeFromBulkEmails }) => {
                if (selectedItems.findIndex((o) => o.customerId === id) > -1) return

                selectedItems.push({
                    customerId: id,
                    fullName,
                    currentSelfAssessmentOneClickTaxReturn,
                    unsubscribeFromBulkEmails,
                    status,
                })
            }
        )

        this.setState({ selectedItems })
    }

    deselectAll() {
        this.setState({ selectedItems: [] })
    }

    download() {
        const { filterView, filterAccountant } = this.state
        const { dispatch, isAdminUser, user } = this.props

        const accountantId = isAdminUser ? filterAccountant : user.id

        dispatch(exportCustomers({ filters: filterView, accountantId }))
    }

    renderDraftedCustomerNotice() {
        const { filterView } = this.state
        const { customerStatistics } = this.props

        const isInDraftedView = filterView === CUSTOMER_VIEW_DRAFTED
        const draftedClientCount = customerStatistics?.totalDrafted || 0

        if (!isInDraftedView || !draftedClientCount) return null

        const stringThisThese = draftedClientCount === 1 ? 'this' : 'these'
        const stringClientClients = draftedClientCount === 1 ? 'client' : 'clients'

        return (
            <Notice background={colors.redLighter}>
                For {stringThisThese}{' '}
                <strong>
                    {draftedClientCount} {stringClientClients}
                </strong>{' '}
                we already have the information from HMRC and now just need them to review the data. It would make sense
                to let them know about it.{' '}
                <CleanLink href={LINK_NOTION_DRAFTED_RETURNS} target="_blank" rel="noreferrer noopener">
                    Learn more
                </CleanLink>
            </Notice>
        )
    }

    render() {
        const {
            returningCustomers,
            customerStatistics,
            history,
            isAdminUser,
            isFetching,
            location,
            accountantsWithFullName,
            pagination,
        } = this.props
        const { filterAccountant, filterView, selectedItems } = this.state

        const hasCustomers = returningCustomers.length > 0
        const hasSelectedItems = selectedItems.length > 0
        const hasLoadedAccountantDropdown = accountantsWithFullName.length > 0

        return (
            <Container>
                {isAdminUser && (
                    <AccountantFilterContainer isFetching={isFetching && !hasLoadedAccountantDropdown}>
                        {!isFetching && hasLoadedAccountantDropdown && (
                            <TypeSelect
                                name="filterAccountant"
                                value={Number(filterAccountant) || ''}
                                options={[{ value: '', title: 'All accountants' }, ...accountantsWithFullName]}
                                onChange={this.handleAccountantChange}
                                autoClear
                                isRequired
                                showOptions
                            />
                        )}
                    </AccountantFilterContainer>
                )}

                <FilterContainer isFetching={isFetching}>
                    <ReturningCustomersFilter
                        download={this.download}
                        filterAccountant={filterAccountant}
                        filterView={filterView}
                        customerStatistics={customerStatistics}
                        history={history}
                        isAdminUser={isAdminUser}
                    />
                </FilterContainer>

                {this.renderDraftedCustomerNotice()}

                {hasCustomers && (
                    <>
                        <ReturningCustomersTable
                            customers={returningCustomers}
                            deselectAll={this.deselectAll}
                            handleCustomerSelection={this.toggleSelection}
                            selectAll={this.selectAll}
                            selectedItems={selectedItems}
                            isFetching={isFetching}
                            triggerTableUpdate={this.triggerTableUpdate}
                        />

                        <PaginationContainerWrap>
                            <PaginationContainer
                                pagination={pagination}
                                pathname={location.pathname}
                                search={location.search}
                            />
                        </PaginationContainerWrap>
                    </>
                )}

                {hasSelectedItems && (
                    <BulkActionsContainer>
                        <BulkActions selectedItems={selectedItems} deselectAll={this.deselectAll} />
                    </BulkActionsContainer>
                )}
            </Container>
        )
    }
}

ReturningCustomers.propTypes = {
    returningCustomers: PropTypes.array.isRequired,
    customerStatistics: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    accountantsWithFullName: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    const { returningCustomers, statistics, user } = state

    const { isFetching, data, pagination } = returningCustomers

    return {
        returningCustomers: data,
        customerStatistics: statistics?.customerLastYearStatistics || {},
        isAdminUser: isAdmin(user),
        isFetching,
        accountantsWithFullName: getAccountantsWithFullNameAndStatus(state),
        pagination,
        user,
    }
}

export default connect(mapStateToProps)(withRouter(ReturningCustomers))
