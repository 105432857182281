import { AccountantPortalChrome, Spacer } from '@scouts/ui'

import { MessagesContextProvider } from './context/MessagesContextProvider'
import { CustomerMessagesList } from './CustomerMessagesList'
import { MessagesHeader } from './MessagesHeader'

interface CustomerMessagesProps {
    customerId: number
}

export const CustomerMessages = ({ customerId }: CustomerMessagesProps) => {
    return (
        <MessagesContextProvider customerId={customerId}>
            <AccountantPortalChrome.Customer.Page.Container>
                <AccountantPortalChrome.Customer.Page.Header isSticky>
                    <MessagesHeader />
                </AccountantPortalChrome.Customer.Page.Header>

                <Spacer margin="30px 0">
                    <CustomerMessagesList customerId={customerId} />
                </Spacer>
            </AccountantPortalChrome.Customer.Page.Container>
        </MessagesContextProvider>
    )
}
