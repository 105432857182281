import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Radio,
    Select,
    TextArea,
    TypeSelect,
} from '@scouts/ui'

import { requestAccountantsSuitableFor, saveCustomerAccountant } from '../../actions'
import { handleBooleanStringValue } from '../../helpers'
import { getReassignableAccountantsWithFullNameAndStatus } from '../../selectors/accountants'
import { getReassignmentReasonsOptions } from './helpers'

class CustomerReassignModal extends Component {
    constructor() {
        super()

        this.state = {
            accountantId: '',
            shouldSendMessage: false,
            reassignmentReasonType: null,
            reassignmentReasonDescription: '',
        }

        _bindAll(this, [
            'handleAccountantChange',
            'handleReassign',
            'handleRadioChange',
            'handleReassignmentReasonTypeChange',
            'handleReassignmentReasonDescriptionChange',
        ])
    }

    componentDidMount() {
        const { dispatch, customer } = this.props
        const { id: customerId } = customer

        dispatch(requestAccountantsSuitableFor({ customerId }))
    }

    handleAccountantChange({ value }) {
        this.setState({ accountantId: Number(value) })
    }

    handleRadioChange({ name, value }) {
        this.setState({ [name]: handleBooleanStringValue(value) })
    }

    handleReassignmentReasonTypeChange({ value: reassignmentReasonType }) {
        this.setState({ reassignmentReasonType })
    }

    handleReassignmentReasonDescriptionChange({ value: reassignmentReasonDescription }) {
        this.setState({ reassignmentReasonDescription })
    }

    handleReassign() {
        const { closeModal, customer, dispatch, onReassignmentSuccess } = this.props
        const { accountantId, shouldSendMessage, reassignmentReasonType, reassignmentReasonDescription } = this.state

        const onSuccess = shouldSendMessage ? onReassignmentSuccess : null

        dispatch(
            saveCustomerAccountant(
                { customerId: customer.id, accountantId, reassignmentReasonType, reassignmentReasonDescription },
                { onSuccess }
            )
        )

        closeModal()
    }

    render() {
        const { accountantsWithFullName, customer, closeModal } = this.props
        const { accountantId, shouldSendMessage, reassignmentReasonType, reassignmentReasonDescription } = this.state

        const options = getReassignmentReasonsOptions(reassignmentReasonType)

        return (
            <Modal onClose={closeModal}>
                <ModalHeader>
                    <ModalTitle>Reassign {customer.fullName} to a new accountant</ModalTitle>
                    <ModalSubtitle>
                        Incomplete tax registrations, tax returns and consultations will be transferred to the new
                        accountant. All that's completed (or awaiting approval) will stay with the previous accountant.
                    </ModalSubtitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>New accountant</Form.Row.Title>
                        <Form.Row.Content>
                            <TypeSelect
                                name="accountant"
                                value={accountantId}
                                onChange={this.handleAccountantChange}
                                options={[{ title: 'Choose…', value: '' }, ...accountantsWithFullName]}
                                autoClear
                                isRequired
                                showOptions
                            />
                        </Form.Row.Content>
                        <Form.Row.Description>
                            Displaying only the accountants who are available and provide the required services for this
                            customer
                        </Form.Row.Description>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Reason for the reassignment</Form.Row.Title>
                        <Form.Row.Content>
                            <Select
                                name="reassignmentReasonType"
                                onChange={this.handleReassignmentReasonTypeChange}
                                required
                                options={options}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Details</Form.Row.Title>
                        <Form.Row.Content>
                            <TextArea
                                name="reassignmentReasonDescription"
                                maxLength={255}
                                required
                                onChange={this.handleReassignmentReasonDescriptionChange}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Do you also want to send a message to the client?</Form.Row.Title>
                        <Form.Row.Content>
                            <Form.InlineControl>
                                <Radio
                                    checked={shouldSendMessage}
                                    label="Yes, please"
                                    name="shouldSendMessage"
                                    onChange={this.handleRadioChange}
                                    value="true"
                                />
                            </Form.InlineControl>
                            <Form.InlineControl>
                                <Radio
                                    checked={!shouldSendMessage}
                                    label="Not needed"
                                    name="shouldSendMessage"
                                    onChange={this.handleRadioChange}
                                    value="false"
                                />
                            </Form.InlineControl>
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>
                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={!accountantId || !reassignmentReasonType || !reassignmentReasonDescription}
                            onClick={this.handleReassign}
                        >
                            Reassign
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Modal>
        )
    }
}

CustomerReassignModal.propTypes = {
    accountantsWithFullName: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onReassignmentSuccess: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    currentAccountantId: PropTypes.number.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const { currentAccountantId, customer } = ownProps

    return {
        accountantsWithFullName: getReassignableAccountantsWithFullNameAndStatus(state, {
            currentAccountantId,
            customerId: customer.id,
        }),
    }
}

export default connect(mapStateToProps)(CustomerReassignModal)
