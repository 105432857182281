import _get from 'lodash/get'

import {
    RECEIVE_NOTES,
    RECEIVE_NOTES_NOTIFICATIONS,
    REMOVE_NOTE,
    REQUEST_CUSTOMER_NOTES,
    RESET_NOTES_NOTIFICATIONS,
} from '../actions'
import { updateOrAddItemsById } from '../helpers'

const defaultState = { data: [], notifications: [], isFetching: false }

export default (state = defaultState, action) => {
    const { type, data } = action

    switch (type) {
        case REQUEST_CUSTOMER_NOTES: {
            return { ...state, isFetching: true }
        }

        case RECEIVE_NOTES: {
            const items = updateOrAddItemsById({ currentItems: state.data, receivedItems: data })

            return { ...state, data: items, isFetching: false }
        }

        case REMOVE_NOTE: {
            const items = [..._get(state, ['data'], [])]

            const key = items.findIndex((o) => o.id === data.noteId)

            if (key > -1 && items[key]) {
                items.splice(key, 1)
            }

            return { ...state, data: items }
        }

        case RECEIVE_NOTES_NOTIFICATIONS: {
            const { notifications: currentItems } = state
            const receivedItems = data

            const notifications = updateOrAddItemsById({ currentItems, receivedItems })

            return { ...state, notifications }
        }

        case RESET_NOTES_NOTIFICATIONS: {
            return { ...state, notifications: [] }
        }

        default:
            return state
    }
}
