import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { localStorageGet } from '@scouts/helpers'
import { font, ButtonLink } from '@scouts/ui'

import { CUSTOMER_VIEW_DRAFTED, LINK_NOTION_DRAFTED_RETURNS, PATH_RETURNING_CUSTOMERS } from '@/constants'

import { Banner, BannerHeading } from './Banner'

class BannerDraftedReturns extends PureComponent {
    static storageKey = 'BannerDraftedReturns'

    static isBannerHidden = () => {
        const timestamp = localStorageGet(BannerDraftedReturns.storageKey)
        return !!timestamp && timestamp > Date.now()
    }

    constructor(props) {
        super(props)

        this.state = {
            isHidden: BannerDraftedReturns.isBannerHidden(),
        }
    }

    hideBanner = () => {
        const oneMonthLaterTimestamp = moment().add(30, 'days').valueOf()
        localStorage.setItem(BannerDraftedReturns.storageKey, oneMonthLaterTimestamp)

        this.setState({ isHidden: true })
    }

    render() {
        const { isHidden } = this.state
        if (isHidden) return null

        const { draftedReturnsCount } = this.props

        const stringClientClients = draftedReturnsCount === 1 ? 'client' : 'clients'
        const stringIsAre = draftedReturnsCount === 1 ? 'is' : 'are'

        return (
            <Banner onClose={this.hideBanner}>
                <BannerHeading>
                    <span aria-label="Waving hand sign emoji" role="img">
                        👋
                    </span>{' '}
                    Quick wins for the off-season
                </BannerHeading>
                <p>
                    There {stringIsAre}{' '}
                    <strong>
                        {draftedReturnsCount} {stringClientClients}
                    </strong>{' '}
                    from last year that we could offer a streamlined experience. We already have the information from
                    HMRC and now we just need them to review the data. It would make sense to let them know about it.
                </p>
                <p>
                    → Navigate to “
                    <Link to={`${PATH_RETURNING_CUSTOMERS}?view=${CUSTOMER_VIEW_DRAFTED}`}>
                        <ButtonLink as="span">Returning clients</ButtonLink>
                    </Link>
                    ”
                </p>
                <p>→ There you can send them a bulk email and ask them to review the data</p>
                <p>
                    <CleanLink href={LINK_NOTION_DRAFTED_RETURNS} target="_blank" rel="noreferrer noopener">
                        Learn more
                    </CleanLink>
                </p>
            </Banner>
        )
    }
}

BannerDraftedReturns.propTypes = {
    draftedReturnsCount: PropTypes.number.isRequired,
}

export default BannerDraftedReturns

const CleanLink = styled.a`
    text-decoration: none;
    font-weight: ${font.weight.medium};
`
