import { useSelector } from 'react-redux'

import { useIsTablet } from '@scouts/ui'

import {
    PATH_ACCOUNTANT_MEETINGS,
    PATH_ACCOUNTANTS,
    PATH_COMPANY_RETURNS,
    PATH_CUSTOMERS,
    PATH_DASHBOARD,
    PATH_DISCOUNT_SCHEMES,
    PATH_INBOX,
    PATH_INTEGRATIONS,
    PATH_LOGOUT,
    PATH_OFF_PLATFORM_SERVICES_REQUESTS,
    PATH_PROFILE,
    PATH_REWARDS,
    PATH_SEARCH,
    PATH_SECURITY,
    PATH_SELF_ASSESSMENTS,
    PATH_SUBSCRIPTIONS,
    PATH_TAX_CONSULTATIONS,
    PATH_TAX_REGISTRATION,
    PATH_TAX_RELIEF_CLAIMS,
    PATH_TEMPLATES,
    PATH_TOOLS,
    PATH_USERS,
} from '@/constants'
import { featureFlags } from '@/feature-flags'
import { getUser } from '@/selectors'

const getDesktopMenuItems = (isAdminUser: boolean) => [
    {
        title: 'My profile',
        path: PATH_PROFILE,
    },
    {
        title: 'My integrations',
        path: PATH_INTEGRATIONS,
        shouldHide: isAdminUser,
    },
    {
        title: 'Users',
        path: PATH_USERS,
        shouldHide: !isAdminUser,
    },
    {
        title: 'Message templates',
        path: PATH_TEMPLATES,
    },
    {
        title: 'Tools',
        path: PATH_TOOLS,
    },
    {
        title: 'Security',
        path: PATH_SECURITY,
    },
    {
        title: 'Log out',
        path: PATH_LOGOUT,
    },
]

const getMobileMenuItems = (
    isAdminUser: boolean,
    {
        allowTaxConsultation,
        hasTaxConsultations,
        allowSubscription,
        allowCompany,
    }: {
        allowTaxConsultation?: boolean
        hasTaxConsultations?: boolean
        allowSubscription?: boolean
        allowCompany?: boolean
    }
) => [
    {
        title: 'My profile',
        path: PATH_PROFILE,
    },
    {
        title: 'My integrations',
        path: PATH_INTEGRATIONS,
        shouldHide: isAdminUser,
    },
    {
        title: 'Inbox',
        path: PATH_INBOX,
        shouldHide: isAdminUser,
    },
    {
        title: 'Dashboard',
        path: PATH_DASHBOARD,
        shouldHide: isAdminUser,
    },
    {
        title: 'Accountants',
        path: PATH_ACCOUNTANTS,
        shouldHide: !isAdminUser,
    },
    {
        title: 'Clients',
        path: PATH_CUSTOMERS,
    },
    {
        title: 'UTR registrations',
        path: PATH_TAX_REGISTRATION,
    },
    {
        title: 'Tax returns',
        path: PATH_SELF_ASSESSMENTS,
    },
    {
        title: 'Company returns',
        path: PATH_COMPANY_RETURNS,
        shouldHide: !featureFlags.featureCompanyTaxReturns || (!allowCompany && !isAdminUser),
    },
    {
        title: 'Consultations',
        path: PATH_TAX_CONSULTATIONS,
        shouldHide: !isAdminUser && !allowTaxConsultation && !hasTaxConsultations,
    },
    {
        title: 'Meetings',
        path: PATH_ACCOUNTANT_MEETINGS,
        shouldHide: !isAdminUser && !allowSubscription,
    },
    {
        title: 'Additional services',
        path: PATH_OFF_PLATFORM_SERVICES_REQUESTS,
        shouldHide: !isAdminUser || !featureFlags.featureOffPlatformServices,
    },
    {
        title: 'Subscriptions',
        path: PATH_SUBSCRIPTIONS,
        shouldHide: !isAdminUser,
    },
    {
        title: 'EIS',
        path: PATH_TAX_RELIEF_CLAIMS,
        shouldHide: !isAdminUser,
    },
    {
        title: 'Search',
        path: PATH_SEARCH,
    },
    {
        title: 'Rewards',
        path: PATH_REWARDS,
        shouldHide: !isAdminUser,
    },
    {
        title: 'Users',
        path: PATH_USERS,
        shouldHide: !isAdminUser,
    },
    {
        title: 'Discount codes',
        path: PATH_DISCOUNT_SCHEMES,
        shouldHide: !isAdminUser,
    },
    {
        title: 'Message templates',
        path: PATH_TEMPLATES,
    },
    {
        title: 'Tools',
        path: PATH_TOOLS,
    },
    {
        title: 'Security',
        path: PATH_SECURITY,
    },
    {
        title: 'Log out',
        path: PATH_LOGOUT,
    },
]

export const useUserMenuItems = () => {
    const isTablet = useIsTablet()

    const { isAdminUser, allowTaxConsultation, hasTaxConsultations, allowSubscription, allowCompany } =
        useSelector(getUser)

    // @TODO: Memoize this
    return isTablet
        ? getDesktopMenuItems(isAdminUser)
        : getMobileMenuItems(isAdminUser, {
              allowTaxConsultation,
              hasTaxConsultations,
              allowSubscription,
              allowCompany,
          })
}
