import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'

import { Button, Flexer, Tabs } from '@scouts/ui'

import {
    CUSTOMER_VIEW_COMPLETED,
    CUSTOMER_VIEW_DRAFTED,
    CUSTOMER_VIEW_IN_PROGRESS,
    CUSTOMER_VIEW_NOT_STARTED,
    CUSTOMER_VIEW_TOTAL,
    PATH_RETURNING_CUSTOMERS,
} from '../../constants'

class ReturningCustomersFilter extends Component {
    toQueryString(value) {
        const { filterAccountant } = this.props

        const values = { ...value }

        if (filterAccountant) {
            values.accountantId = filterAccountant
        }

        return queryString.stringify(values)
    }

    countPerFilter(filterView) {
        const { customerStatistics } = this.props

        let count

        if (filterView === CUSTOMER_VIEW_TOTAL) {
            count = customerStatistics?.totalLastYearCustomers || 0
        }

        if (filterView === CUSTOMER_VIEW_NOT_STARTED) {
            count = customerStatistics?.totalNotInProgress || 0
        }

        if (filterView === CUSTOMER_VIEW_DRAFTED) {
            count = customerStatistics?.totalDrafted || 0
        }

        if (filterView === CUSTOMER_VIEW_IN_PROGRESS) {
            count = customerStatistics?.totalInProgress || 0
        }

        if (filterView === CUSTOMER_VIEW_COMPLETED) {
            count = customerStatistics?.totalCompleted || 0
        }

        return count
    }

    updateFilter(view) {
        const { history } = this.props

        history.push({
            pathname: PATH_RETURNING_CUSTOMERS,
            search: this.toQueryString({
                view,
            }),
        })
    }

    render() {
        const { filterView, filterAccountant, download, isAdminUser } = this.props

        const shouldAllowExport = isAdminUser ? !!filterAccountant : true

        return (
            <Flexer spaceBetween alignCenter>
                <Tabs>
                    <Tabs.Item
                        onClick={() => this.updateFilter(CUSTOMER_VIEW_TOTAL)}
                        isActive={filterView === CUSTOMER_VIEW_TOTAL}
                    >
                        All
                        <Tabs.Item.Counter>{this.countPerFilter(CUSTOMER_VIEW_TOTAL)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateFilter(CUSTOMER_VIEW_NOT_STARTED)}
                        isActive={filterView === CUSTOMER_VIEW_NOT_STARTED}
                    >
                        Not Started
                        <Tabs.Item.Counter>{this.countPerFilter(CUSTOMER_VIEW_NOT_STARTED)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateFilter(CUSTOMER_VIEW_DRAFTED)}
                        isActive={filterView === CUSTOMER_VIEW_DRAFTED}
                    >
                        Drafted
                        <Tabs.Item.Counter>{this.countPerFilter(CUSTOMER_VIEW_DRAFTED)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateFilter(CUSTOMER_VIEW_IN_PROGRESS)}
                        isActive={filterView === CUSTOMER_VIEW_IN_PROGRESS}
                    >
                        In Progress
                        <Tabs.Item.Counter>{this.countPerFilter(CUSTOMER_VIEW_IN_PROGRESS)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateFilter(CUSTOMER_VIEW_COMPLETED)}
                        isActive={filterView === CUSTOMER_VIEW_COMPLETED}
                    >
                        Completed
                        <Tabs.Item.Counter>{this.countPerFilter(CUSTOMER_VIEW_COMPLETED)}</Tabs.Item.Counter>
                    </Tabs.Item>
                </Tabs>

                {shouldAllowExport && (
                    <Button size="small" onClick={download}>
                        Export
                    </Button>
                )}
            </Flexer>
        )
    }
}

ReturningCustomersFilter.propTypes = {
    history: PropTypes.object.isRequired,
    filterAccountant: PropTypes.string,
    filterView: PropTypes.string,
    customerStatistics: PropTypes.object.isRequired,
    download: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
}

ReturningCustomersFilter.defaultProps = {
    filterAccountant: null,
    filterView: CUSTOMER_VIEW_NOT_STARTED,
}

export default ReturningCustomersFilter
