import { useState } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import {
    Button,
    Checkbox,
    Flexer,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { useAddDiscountScheme } from '@/store/discount-schemes/hooks/use-add-discount-scheme'

interface DiscountSchemesAddModalProps {
    onClose: () => void
}

export const DiscountSchemesAddModal = ({ onClose }: DiscountSchemesAddModalProps) => {
    const { addDiscountScheme, isLoading } = useAddDiscountScheme()

    const [active, setActive] = useState(true)
    const [appliesToSelfAssessments, setAppliesToSelfAssessments] = useState(true)
    const [appliesToProductBundles, setAppliesToProductBundles] = useState(true)
    const [appliesToSubscriptions, setAppliesToSubscriptions] = useState(true)
    const [appliesToCompanyReturns, setAppliesToCompanyReturns] = useState(true)
    const [appliesToTaxConsultations, setAppliesToTaxConsultations] = useState(true)
    const [discountCode, setDiscountCode] = useState('')
    const [label, setLabel] = useState('')
    const [durationMonths, setDurationMonths] = useState<number | null>(null)
    const [hasUniqueCodeVariants, setHasUniqueCodeVariants] = useState(false)
    const [name, setName] = useState('')
    const [percentageDiscount, setPercentageDiscount] = useState(0)
    const [redemptionLimit, setRedemptionLimit] = useState<number | null>(null)

    const handleSubmit = () => {
        const nameTrimmed = name.trim()
        const discountCodeTrimmed = discountCode.trim()
        const labelTrimmed = label.trim()

        const data = {
            active,
            appliesToSelfAssessments,
            appliesToSubscriptions,
            appliesToCompanyReturns,
            appliesToTaxConsultations,
            appliesToProductBundles,
            discountCode: discountCodeTrimmed,
            label: labelTrimmed,
            durationMonths: durationMonths ? Math.round(durationMonths) : null,
            hasUniqueCodeVariants,
            name: nameTrimmed,
            percentageDiscount,
            redemptionLimit: redemptionLimit ? Math.round(redemptionLimit) : null,
        }

        addDiscountScheme({ data }).then(matchSuccessResponse(onClose))
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Add discount code</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Name</Form.Row.Title>
                        <Form.Row.Content>
                            <Input name="name" value={name} onChange={({ value }) => setName(value)} required />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Discount percentage</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                type="number"
                                name="percentageDiscount"
                                value={percentageDiscount || ''}
                                onChange={({ value }) => setPercentageDiscount(Number(value))}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Discount code</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="discountCode"
                                value={discountCode}
                                maxLength={25}
                                onChange={({ value }) => setDiscountCode(value)}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Discount label (shown for customer)</Form.Row.Title>
                        <Form.Row.Content>
                            <Input name="label" value={label || ''} onChange={({ value }) => setLabel(value)} />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Usage limit (optional)</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                type="number"
                                name="redemptionLimit"
                                value={redemptionLimit || ''}
                                onChange={({ value }) => setRedemptionLimit(Number(value))}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Duration in months (optional)</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                type="number"
                                name="durationMonths"
                                value={durationMonths || ''}
                                onChange={({ value }) => setDurationMonths(Number(value))}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Applies to</Form.Row.Title>
                        <Form.Row.Content>
                            <Flexer column gap="12px">
                                <Checkbox
                                    label="Tax returns"
                                    name="appliesToSelfAssessments"
                                    onChange={({ checked }) => setAppliesToSelfAssessments(checked)}
                                    checked={!!appliesToSelfAssessments}
                                />
                                <Checkbox
                                    label="Consultations"
                                    name="appliesToTaxConsultations"
                                    onChange={({ checked }) => setAppliesToTaxConsultations(checked)}
                                    checked={!!appliesToTaxConsultations}
                                />
                                <Checkbox
                                    label="Subscriptions"
                                    name="appliesToSubscriptions"
                                    onChange={({ checked }) => setAppliesToSubscriptions(checked)}
                                    checked={!!appliesToSubscriptions}
                                />
                                <Checkbox
                                    label="Company returns"
                                    name="appliesToCompanyReturns"
                                    onChange={({ checked }) => setAppliesToCompanyReturns(checked)}
                                    checked={!!appliesToCompanyReturns}
                                />
                                <Checkbox
                                    label="Product bundles"
                                    name="appliesToProductBundles"
                                    onChange={({ checked }) => setAppliesToProductBundles(checked)}
                                    checked={!!appliesToProductBundles}
                                />
                            </Flexer>
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Content>
                            <Checkbox
                                label="Is active"
                                name="active"
                                onChange={({ checked }) => setActive(checked)}
                                checked={!!active}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Content>
                            <Checkbox
                                label="Enable unique codes"
                                name="hasUniqueCodeVariants"
                                onChange={({ checked }) => setHasUniqueCodeVariants(checked)}
                                checked={!!hasUniqueCodeVariants}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>
                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onClose} isSecondary>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={isLoading}>
                            Save
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
