import { generatePath, useHistory } from 'react-router-dom'

import { useToggle } from '@scouts/helpers'
import { colors, font, DropdownButton, Flexer, Line, Spacer } from '@scouts/ui'

import { PATH_CUSTOMER_TAX_REGISTRATION_AGREEMENT } from '@/constants'
import { CustomerDTO, FileType, RefundCreateDTO, TaxRegistrationDTO, TaxRegistrationStatus } from '@/models'
import { useRefundTaxRegistration } from '@/store/tax-registrations'

import { useUser } from '@/hooks/use-user'

import { TourUTR } from '@/components/feature-tour/TourFirstTaxReturn'
import { useCustomerTaxRegistrationFiles } from '@/components/tax-registrations/hooks/use-customer-tax-registration-files'

import { RefundModal } from '../_shared/RefundModal'
import FileWithActions from '../FileWithActions'
import UploadFilesCustomer from '../UploadFilesCustomer'
import { TaxRegistrationStatusDropdown } from './TaxRegistrationStatusDropdown'

interface TaxRegistrationActionsProps {
    taxRegistration: TaxRegistrationDTO
    customer: CustomerDTO
}

export const TaxRegistrationActions = ({ taxRegistration, customer }: TaxRegistrationActionsProps) => {
    const history = useHistory()

    const [isRefundModalOpen, openRefundModal, closeRefundModal] = useToggle()
    const { refundTaxRegistration, isLoading } = useRefundTaxRegistration()
    const { isAdminUser } = useUser()

    const { isDemoCustomer, id: customerId } = customer
    const { price, hasPaid, status } = taxRegistration

    const { taxRegistrationFiles, hasFiles } = useCustomerTaxRegistrationFiles({ customerId })

    const shouldDisplayRefund = isAdminUser && hasPaid && price > 0

    const shouldDisplayUpload =
        (status === TaxRegistrationStatus.Submitted ||
            status === TaxRegistrationStatus.InformationRequested ||
            status === TaxRegistrationStatus.Complete) &&
        !hasFiles

    const actions = [
        {
            title: 'View letter of engagement',
            onClick: () => history.push(generatePath(PATH_CUSTOMER_TAX_REGISTRATION_AGREEMENT, { customerId })),
        },
    ]

    if (shouldDisplayRefund) {
        actions.push({
            title: 'Refund',
            onClick: openRefundModal,
        })
    }

    const handleRefundTaxRegistration = (payload: RefundCreateDTO) => {
        refundTaxRegistration({ customerId, data: payload }).then(() => {
            closeRefundModal()
        })
    }

    if (!taxRegistration) return null

    return (
        <>
            <Flexer column justifyStart alignStart tabletRow tabletSpaceBetween tabletAlignCenter gap="18px">
                <Flexer alignCenter gap="18px">
                    <Line size={font.small}>Status</Line>
                    <Spacer>
                        {isDemoCustomer && <TourUTR />}
                        <TaxRegistrationStatusDropdown taxRegistration={taxRegistration} />
                    </Spacer>
                </Flexer>

                <Flexer column justifyStart alignStart tabletRow tabletSpaceBetween tabletAlignCenter gap="18px">
                    <Flexer
                        column
                        justifyStart
                        alignStart
                        tabletRow
                        tabletSpaceBetween
                        tabletAlignCenter
                        gap="18px"
                        className="tour-utr-receipt"
                    >
                        {hasFiles && (
                            <Flexer column gap="6px" className="tour-utr-receipt-highlight">
                                {taxRegistrationFiles.map((file) => (
                                    <Spacer key={file.id}>
                                        <FileWithActions
                                            background={colors.mintLighter}
                                            file={file}
                                            isCustomerFile
                                            isDeletable
                                            label={file.fileName}
                                        />
                                    </Spacer>
                                ))}
                            </Flexer>
                        )}

                        {shouldDisplayUpload && (
                            <>
                                <Line size={font.small}>UTR submission receipt not yet uploaded</Line>
                                <Spacer className="tour-utr-receipt-highlight">
                                    <UploadFilesCustomer
                                        customerId={customer.id}
                                        uploadTypes={[
                                            {
                                                fileType: FileType.TaxRegistrationSubmission,
                                                filingArea: null,
                                            },
                                        ]}
                                    />
                                </Spacer>
                            </>
                        )}
                    </Flexer>

                    <Spacer>
                        <DropdownButton alignRight actions={actions}>
                            Actions
                        </DropdownButton>
                    </Spacer>
                </Flexer>
            </Flexer>

            {isRefundModalOpen && (
                <RefundModal
                    isLoading={isLoading}
                    itemName="tax registration"
                    itemPrice={price}
                    onClose={closeRefundModal}
                    onRefundItem={(value) => handleRefundTaxRegistration(value)}
                    title={`Refund ${customer.fullName}'s UTR registration`}
                />
            )}
        </>
    )
}
