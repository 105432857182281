import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownDateRange } from '../ui/filter'

const SearchFilterSubmittedDate = (props) => {
    const { submittedDateStart, submittedDateEnd, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = submittedDateStart || submittedDateEnd

    const activeValues = [
        submittedDateStart ? `From ${submittedDateStart}` : '',
        submittedDateEnd ? `To ${submittedDateEnd}` : '',
    ]

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Submitted date"
            activeValues={activeValues}
            removeFilter={() => removeFilter(['submittedDateStart', 'submittedDateEnd'])}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownDateRange
                dateFromName="submittedDateStart"
                dateToName="submittedDateEnd"
                applyFilter={applyFilter}
                removeFilter={() => removeFilter(['submittedDateStart', 'submittedDateEnd'])}
                dateFrom={submittedDateStart}
                dateTo={submittedDateEnd}
            />
        </FilterItem>
    )
}

SearchFilterSubmittedDate.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
    submittedDateEnd: PropTypes.string.isRequired,
    submittedDateStart: PropTypes.string.isRequired,
}

export default SearchFilterSubmittedDate
