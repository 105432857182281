import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const WorkFromHomeAllowance = ({
    workFromHomeAllowance,
}: {
    workFromHomeAllowance: SelfAssessmentDTO['workFromHomeAllowance']
}) => {
    if (!workFromHomeAllowance) return null

    const { hoursPerWeek, monthsPerYear, additionalNotes } = workFromHomeAllowance

    const hoursPerMonthComment = hoursPerWeek ? `${Math.round(hoursPerWeek * 4.33)} hours per month` : ''

    return (
        <SectionData>
            <SectionDataRow
                unit="hours per week"
                label="Hours worked from home"
                value={hoursPerWeek}
                comment={hoursPerMonthComment}
            />
            <SectionDataRow unit="months" label="Months worked from home this tax year" value={monthsPerYear} />
            {additionalNotes && <SectionDataRow label="Notes" value={additionalNotes} />}
        </SectionData>
    )
}
