import { CustomerDTO } from '@/models'
import { useCompanyReturns } from '@/store/company-returns/hooks'

import { useCustomersCompany } from './use-customers-company'

export const useCustomersCompanyReturns = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const { company, isLoading: isLoadingCompanies } = useCustomersCompany({ customerId })

    const { companyReturns, isLoading: isLoadingCompanyReturns } = useCompanyReturns({
        customerId,
        companyId: company?.id || 0,
        skip: !company,
    })

    const hasCompanyReturns = !!companyReturns && companyReturns?.length > 0

    const companyId = company?.id || null
    const defaultCompanyReturnId = companyReturns?.[0]?.id || null

    return {
        company,
        companyId,
        companyReturns,
        defaultCompanyReturnId,
        hasCompanyReturns,
        isLoading: isLoadingCompanies || isLoadingCompanyReturns,
    }
}
