import { useToggle } from '@scouts/helpers'
import { ButtonLink } from '@scouts/ui'

import { AddReviewModal } from './AddReviewModal'

export const AddReview = ({ accountantId }: { accountantId: number }) => {
    const [isModalOpen, openModal, closeModal] = useToggle()

    return (
        <>
            <ButtonLink icon="plus" onClick={openModal}>
                Add review
            </ButtonLink>

            {isModalOpen && <AddReviewModal onClose={closeModal} accountantId={accountantId} />}
        </>
    )
}
