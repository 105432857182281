import { useCustomersTaxReliefClaims } from '@/store/tax-relief-claims'

export const useCustomerTaxReliefClaims = ({ customerId, skip }: { customerId: number; skip?: boolean }) => {
    const { taxReliefClaims, isLoading } = useCustomersTaxReliefClaims({ customerId, skip })

    if (!taxReliefClaims) return {}

    const hasTaxReliefClaims = !!taxReliefClaims && taxReliefClaims?.length > 0

    const defaultTaxReliefClaimId = taxReliefClaims?.[0]?.id || null

    return { taxReliefClaims, defaultTaxReliefClaimId, hasTaxReliefClaims, isLoading }
}
