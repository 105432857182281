import { useUpdateCompanyReturnStatusMutation } from '../api'

export const useUpdateCompanyReturnStatus = () => {
    const [updateCompanyReturnStatus, { error, isLoading }] = useUpdateCompanyReturnStatusMutation()

    return {
        updateCompanyReturnStatus,
        error,
        isLoading,
    }
}
