import styled from 'styled-components'

import {
    colors,
    font,
    media,
    Button,
    List,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Spacer,
} from '@scouts/ui'

import { formatDate } from '@/helpers'
import { CustomerDTO } from '@/models'

interface RequestProps {
    customer: CustomerDTO
    handleCancel: () => void
    handleRequest: () => void
    isLoading: boolean
}

export const Request = ({ handleCancel, handleRequest, customer, isLoading }: RequestProps) => {
    const { fullName, dateOfBirth, addressStreetAddress, addressPostcode, addressCity, addressCountry } = customer

    return (
        <Modal isWide onClose={handleCancel}>
            <ModalHeader>
                <ModalTitle>Request identity verification</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Customer>
                    <CustomerColumn>
                        <CustomerRow>{fullName}</CustomerRow>
                        {dateOfBirth ? (
                            <CustomerRow>Born {formatDate(dateOfBirth)}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>Date of birth missing</CustomerRow>
                        )}
                    </CustomerColumn>
                    <CustomerColumn>
                        {addressStreetAddress ? (
                            <CustomerRow>{addressStreetAddress}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>Address missing</CustomerRow>
                        )}
                        {addressPostcode ? (
                            <CustomerRow>{addressPostcode}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>Postcode missing</CustomerRow>
                        )}
                        {addressCity ? (
                            <CustomerRow>{addressCity}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>City missing</CustomerRow>
                        )}
                        {addressCountry && <CustomerRow isMissing>{addressCountry}</CustomerRow>}
                    </CustomerColumn>
                </Customer>
                <Spacer margin="24px 0 0">
                    <List isNumbered size={font.small} itemsBackground={colors.surface.neutral}>
                        <List.Item>Client will be asked to verify their identity</List.Item>
                        <List.Item>TaxScouts will let you know when the verification is complete</List.Item>
                        <List.Item>You’ll receive the copies of the documents</List.Item>
                    </List>
                </Spacer>
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button isSecondary onClick={handleCancel} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button onClick={handleRequest} isLoading={isLoading}>
                        Send request
                    </Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}

const Customer = styled.div`
    padding: 24px 0;
    border-top: 1px solid ${colors.neutralLightest};
    border-bottom: 1px solid ${colors.neutralLightest};

    ${media.tablet} {
        display: flex;
    }
`

const CustomerColumn = styled.div`
    ${media.tablet} {
        flex: 0 0 50%;
    }

    p:not(:last-child) {
        margin-bottom: 6px;
    }
`

const CustomerRow = styled.div<{ isMissing?: boolean }>`
    color: ${({ isMissing }) => (isMissing ? colors.neutralDarker : 'inherit')};
    font-size: ${font.small};

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`
