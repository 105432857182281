import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { MessageTemplateDTO } from '@/models/message-template'

import { useDeleteMessageTemplateMutation } from '../api'

export const useDeleteMessageTemplate = () => {
    const [action, { isLoading }] = useDeleteMessageTemplateMutation()

    const deleteMessageTemplate = (params: MessageTemplateDTO) =>
        action({ id: params.id }).then(
            matchSuccessResponse(() => {
                addSuccessToast({ body: `Deleted message template${params.name ? ` "${params.name}"` : ''}` })
            })
        )

    return { isLoading, deleteMessageTemplate }
}
