import PropTypes from 'prop-types'

import { TaxRegistrationStatus, TaxRegistrationStatusTitles } from '@/models'

import { FilterItem, FilterItemDropdownCheckboxes } from '../ui/filter'

const SearchFilterTaxRegistrationStatuses = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter.length > 0

    const activeValues = hasActiveFilter ? activeFilter.map((status) => TaxRegistrationStatusTitles[status]) : ['Any']

    const valuesArray = Object.values(TaxRegistrationStatus)
    const values = valuesArray.map((status) => ({
        checked: activeFilter.findIndex((o) => o === status) > -1,
        label: TaxRegistrationStatusTitles[status],
        value: status,
    }))

    const name = 'taxRegistrationStatuses'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="UTR registration status"
            activeValues={activeValues}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownCheckboxes
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterTaxRegistrationStatuses.propTypes = {
    activeFilter: PropTypes.array.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterTaxRegistrationStatuses
