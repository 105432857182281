export const UserReferralPaymentStatus = {
    Created: 'Created',
    Submitted: 'Submitted',
    Processing: 'Processing',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
    Refunded: 'Refunded',
    ChargedBack: 'ChargedBack',
    Unknown: 'Unknown',
} as const

export type UserReferralPaymentStatusTypes = (typeof UserReferralPaymentStatus)[keyof typeof UserReferralPaymentStatus]

export const UserReferralPaymentStatusTitles: Record<UserReferralPaymentStatusTypes, string> = {
    Created: 'Created',
    Submitted: 'Submitted',
    Processing: 'Processing',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
    Refunded: 'Refunded',
    ChargedBack: 'Charged back',
    Unknown: 'Unknown',
}

// @TODO
export interface RewardDTO {
    paymentStatus: UserReferralPaymentStatusTypes
}
