import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Table } from '@scouts/ui'

import SearchResultsRow from './SearchResultsRow'

const Container = styled.div`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const SearchResults = (props) => {
    const { items, isFetching, selectedItems, toggleSelection, selectAll, deselectAll } = props

    const hasSelectedItems = selectedItems.length > 0

    return (
        <Container isFetching={isFetching}>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell width="30%">Client</Table.Header.Cell>
                        <Table.Header.Cell width="20%">Phone</Table.Header.Cell>
                        <Table.Header.Cell width="20%">Address</Table.Header.Cell>
                        <Table.Header.Cell width="20%">Product</Table.Header.Cell>
                        <Table.Header.Cell alignRight action={hasSelectedItems ? deselectAll : selectAll} width="10%">
                            {hasSelectedItems ? 'Deselect all' : 'Select all'}
                        </Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>
                <Table.Body>
                    {items.map((item) => {
                        const { customerId, selfAssessmentId, taxConsultationId } = item
                        const isSelected =
                            selectedItems.findIndex(
                                (o) =>
                                    o.customerId === customerId &&
                                    o.selfAssessmentId === selfAssessmentId &&
                                    o.taxConsultationId === taxConsultationId
                            ) > -1
                        return (
                            <SearchResultsRow
                                key={`${customerId}${selfAssessmentId || taxConsultationId || ''}`}
                                data={item}
                                isSelected={isSelected}
                                handleSelect={toggleSelection}
                            />
                        )
                    })}
                </Table.Body>
            </Table>
        </Container>
    )
}

SearchResults.propTypes = {
    deselectAll: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    selectAll: PropTypes.func.isRequired,
    selectedItems: PropTypes.array.isRequired,
    toggleSelection: PropTypes.func.isRequired,
}

export default SearchResults
