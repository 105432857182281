import { colors } from '@scouts/ui'

import {
    PATH_ACCOUNTANT_MEETINGS,
    PATH_ACCOUNTANTS,
    PATH_COMPANY_RETURNS,
    PATH_CUSTOMERS,
    PATH_DASHBOARD,
    PATH_DISCOUNT_SCHEMES,
    PATH_ESCALATIONS,
    PATH_INBOX,
    PATH_OFF_PLATFORM_SERVICES_REQUESTS,
    PATH_REWARDS,
    PATH_SEARCH,
    PATH_SELF_ASSESSMENTS,
    PATH_SUBSCRIPTIONS,
    PATH_TAX_CONSULTATIONS,
    PATH_TAX_REGISTRATION,
    PATH_TAX_RELIEF_CLAIMS,
} from '@/constants'
import { featureFlags } from '@/feature-flags'

import { useUser } from './use-user'

export const useMainMenuSecondaryItems = () => {
    const { isAdminUser, allowTaxConsultation, hasTaxConsultations, allowCompany, allowSubscription } = useUser()

    return [
        {
            title: 'Dashboard',
            path: PATH_DASHBOARD,
            shouldHide: isAdminUser,
        },
        {
            title: 'Inbox',
            shouldHide: isAdminUser,
            path: PATH_INBOX,
        },
        {
            title: 'Accountants',
            path: PATH_ACCOUNTANTS,
            shouldHide: !isAdminUser,
        },
        {
            title: 'UTR',
            path: PATH_TAX_REGISTRATION,
            className: 'tour-utr-tab',
        },
        {
            title: 'Tax returns',
            path: PATH_SELF_ASSESSMENTS,
        },
        {
            title: 'Company returns',
            path: PATH_COMPANY_RETURNS,
            shouldHide: !featureFlags.featureCompanyTaxReturns || (!allowCompany && !isAdminUser),
        },
        {
            title: 'Consultations',
            path: PATH_TAX_CONSULTATIONS,
            shouldHide: !isAdminUser && !allowTaxConsultation && !hasTaxConsultations,
        },
        {
            title: 'Escalations',
            path: PATH_ESCALATIONS,
            shouldHide: !isAdminUser,
        },
        {
            title: 'Search',
            path: PATH_SEARCH,
        },
        {
            title: 'Clients',
            path: PATH_CUSTOMERS,
        },
        {
            title: 'Meetings',
            path: PATH_ACCOUNTANT_MEETINGS,
            shouldHide: !isAdminUser && !allowSubscription,
        },
        {
            title: 'Subscriptions',
            path: PATH_SUBSCRIPTIONS,
            shouldHide: !isAdminUser && !allowSubscription,
        },
        {
            title: 'Additional services',
            path: PATH_OFF_PLATFORM_SERVICES_REQUESTS,
            shouldHide: !isAdminUser || !featureFlags.featureOffPlatformServices,
        },
        {
            title: 'EIS',
            path: PATH_TAX_RELIEF_CLAIMS,
            shouldHide: !isAdminUser,
        },
        {
            title: 'Rewards',
            path: PATH_REWARDS,
            shouldHide: !isAdminUser,
        },
        {
            title: 'Discount codes',
            path: PATH_DISCOUNT_SCHEMES,
            shouldHide: !isAdminUser,
        },
        {
            title: 'Help',
            url: 'https://www.notion.so/taxscouts/Product-Help-Centre-0d02e6dc46f7476d80866f7306a2dd6e',
            shouldHide: isAdminUser,
            color: colors.blue,
        },
        {
            title: "What's new",
            url: 'https://www.notion.so/taxscouts/What-s-New-cfd13db7fde94cc088ca1046ac98dfea',
            shouldHide: isAdminUser,
            color: colors.blue,
        },
    ].filter((o) => !o.shouldHide)
}
