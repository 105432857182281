import { colors, AccountantPortalChrome, Flexer, Skeleton, SkeletonLine } from '@scouts/ui'

export const CompanyReturnSkeleton = () => {
    return (
        <AccountantPortalChrome.Customer.Page.Container
            padding="24px"
            tabletPadding="0 36px 48px"
            desktopLargePadding="0 48px 48px"
        >
            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.Title>
                        Company return
                    </AccountantPortalChrome.Customer.Page.Title>
                </AccountantPortalChrome.Customer.Page.HeaderGroup>

                <AccountantPortalChrome.Customer.Page.HeaderGroup />
            </AccountantPortalChrome.Customer.Page.Header>

            <Skeleton padding="0" gap="24px" background={colors.white}>
                <SkeletonLine height="42px" width="calc(50% - 24px)" />
                <Flexer gap="48px" tabletRow>
                    <SkeletonLine height="240px" repeat={2} />
                </Flexer>
                <SkeletonLine height="220px" />
                <SkeletonLine height="240px" />
            </Skeleton>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
