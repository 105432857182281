import { useGetCompanyReturnsQuery, GetCompanyReturnsParams } from '../api'

export const useCompanyReturns = ({ customerId, companyId, skip }: GetCompanyReturnsParams & { skip?: boolean }) => {
    const { data, isError, isLoading, isFetching } = useGetCompanyReturnsQuery({ customerId, companyId }, { skip })

    return {
        companyReturns: data,
        isError,
        isLoading,
        isFetching,
    }
}
