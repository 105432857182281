import { useEffect } from 'react'

import { useQueryParams } from '@scouts/helpers'
import { colors, Container, Flexer, Line, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { requestAccountants } from '@/actions'
import { useAppDispatch } from '@/store'
import { invalidateTaxConsultations, useTaxConsultations } from '@/store/tax-consultations'

import { useUser } from '@/hooks/use-user'

import { useFilters } from './hooks'

import { usePagination } from '../pagination'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { TaxConsultationsFilters } from './TaxConsultationsFilters'
import { TaxConsultationsSupportFilters } from './TaxConsultationsSupportFilters'
import { TaxConsultationsTable } from './TaxConsultationsTable'

export const TaxConsultations = () => {
    const dispatch = useAppDispatch()
    const { queryParams } = useQueryParams()
    const { activeFilters, applyFilter, DEFAULT_FILTER } = useFilters()
    const { activeFilters: paginationFilters } = usePagination()
    const { isAdminUser } = useUser()

    useEffect(() => {
        const { filter } = queryParams

        if (!filter) {
            applyFilter({ updatedFilter: { ...DEFAULT_FILTER } })
        }
    }, [DEFAULT_FILTER, applyFilter, queryParams])

    const { taxConsultations, isLoading, isError, pagination, isFetching } = useTaxConsultations({
        options: { ...activeFilters, ...paginationFilters },
    })

    const itemsCount = taxConsultations?.length || 0
    const hasItems = itemsCount > 0

    const triggerTableUpdate = () => {
        dispatch(invalidateTaxConsultations())
    }

    useEffect(() => {
        if (isAdminUser) dispatch(requestAccountants())
    }, [dispatch, isAdminUser])

    if (isError)
        return (
            <Flexer alignCenter justifyCenter>
                <Line color={colors.neutralDarker}>Error getting tax registrations</Line>
            </Flexer>
        )

    if (isLoading && !hasItems)
        return (
            <Skeleton gap="24px" padding="24px">
                <Flexer column gap="12px">
                    {isAdminUser && <SkeletonLine height="35px" width="50%" />}
                    <SkeletonLine height="35px" width="80%" />
                </Flexer>
                <Flexer column gap="1px">
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="87px" repeat={10} />
                </Flexer>
            </Skeleton>
        )

    return (
        <Container padding="24px 24px 192px">
            <Spacer marginBottom="24px">
                <Flexer column gap="12px">
                    {isAdminUser && <TaxConsultationsSupportFilters />}
                    <TaxConsultationsFilters />
                </Flexer>
            </Spacer>

            {!isLoading && !hasItems && <Line color={colors.neutralDarker}>No tax consultations found</Line>}

            {taxConsultations && taxConsultations?.length > 0 && (
                <>
                    <TaxConsultationsTable
                        taxConsultations={taxConsultations}
                        isFetching={isFetching}
                        triggerTableUpdate={triggerTableUpdate}
                    />

                    <Spacer marginTop="24px">
                        <PaginationContainer pagination={pagination} />
                    </Spacer>
                </>
            )}
        </Container>
    )
}
