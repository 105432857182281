import axios from 'axios'
import FileSaver from 'file-saver'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { call, put, takeLatest } from 'redux-saga/effects'

import { REQUEST_CAPITAL_GAINS_CALCULATION } from '../actions'
import config from '../config'
import { handleError } from '../handlers'

export default function* toolsSagas() {
    yield takeLatest(REQUEST_CAPITAL_GAINS_CALCULATION, requestCapitalGainsCalculation)
}

function* requestCapitalGainsCalculation(action) {
    const { data, options } = action
    const { onSuccess, onError } = options || {}

    const url = `${config.cgtApiUrl}calculations/template`

    try {
        yield put(showLoading())

        const response = yield call(axios, {
            url,
            method: 'POST',
            data,
            headers: {
                Accept: 'text/csv',
            },
        })

        if (response.data) {
            const file = new Blob([response.data], {})
            FileSaver.saveAs(file, `capital_gains_calculation.csv`)
        }

        if (onSuccess) onSuccess()
    } catch (error) {
        yield put(handleError(error))

        if (onError) onError()
    } finally {
        yield put(hideLoading())
    }
}
