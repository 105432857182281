import Inbox from '@scouts/inbox'

import { CustomerLoader } from '../customer/CustomerLoader'
import { MessageComposerContainer } from '../customer/messages/composer/MessageComposerContainer'
import { CustomerMessagesList } from '../customer/messages/CustomerMessagesList'

export const AccountantInbox = () => (
    <Inbox.Main
        CustomerLoaderComponent={CustomerLoader}
        MessagesListComponent={CustomerMessagesList}
        MessageComposerComponent={MessageComposerContainer}
    />
)
