import { matchPath, useLocation, useRouteMatch } from 'react-router-dom'

import { PATH_CUSTOMER_COMPANY_PROFILE, PATH_CUSTOMER_COMPANY_RETURN } from '@/constants'

const routesPotentiallyWithCompanyReturnId = [PATH_CUSTOMER_COMPANY_PROFILE, PATH_CUSTOMER_COMPANY_RETURN]

export const useCompanyReturnIdFromPath = () => {
    const { pathname } = useLocation()

    const currentPathMatch = routesPotentiallyWithCompanyReturnId.find((potentialPath) =>
        matchPath(pathname, { path: potentialPath })
    )

    const potentialParams = useRouteMatch<{ companyReturnId?: string }>(currentPathMatch || '')?.params

    return Number(potentialParams?.companyReturnId)
}
