import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useQueryParams } from '@scouts/helpers'

import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE } from '@/constants'
import { TaxRegistrationsRequestOptions, TaxRegistrationStatus } from '@/models'

const DEFAULT_FILTER = {
    status: TaxRegistrationStatus.Submitted,
    includeUnpaid: false,
    page: PAGINATION_DEFAULT_PAGE,
    pageSize: PAGINATION_DEFAULT_PAGE_SIZE,
}

export const useFilters = () => {
    const history = useHistory()
    const { queryParams, updateQueryParams } = useQueryParams()

    const activeFilters = useMemo(() => {
        const { filter } = queryParams

        if (!filter) {
            return DEFAULT_FILTER
        }

        try {
            const parsedFilter = JSON.parse(atob(filter as string))
            return parsedFilter
        } catch {
            return DEFAULT_FILTER
        }
    }, [queryParams])

    const applyFilter = ({ updatedFilter }: { updatedFilter: TaxRegistrationsRequestOptions }) => {
        const filter = btoa(JSON.stringify({ ...activeFilters, ...updatedFilter }))
        history.replace(updateQueryParams({ filter }))
    }

    const clearFilters = () => {
        const filter = btoa(JSON.stringify(DEFAULT_FILTER))
        history.replace(updateQueryParams({ filter }))
    }

    const removeFilter = ({ updatedFilter }: { updatedFilter: TaxRegistrationsRequestOptions }) => {
        const currentlyActiveFilters = { ...activeFilters }
        Object.keys(updatedFilter).forEach((filter) => delete currentlyActiveFilters[filter])
        const filter = btoa(JSON.stringify({ ...currentlyActiveFilters }))

        history.replace(updateQueryParams({ filter }))
    }

    return {
        DEFAULT_FILTER,
        activeFilters,
        applyFilter,
        clearFilters,
        removeFilter,
    }
}
