import { useEffect, useState, ReactNode } from 'react'

import Bookkeeping from '@scouts/bookkeeping'

import { SubscriptionStatus } from '@/models'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import {
    BookkeepingConfigurationContext,
    BookkeepingConfigurationContextProps,
    DEFAULT_CONTEXT,
} from './BookkeepingConfigurationContext'
import { useFilters } from './use-filters'

interface BookkeepingConfigurationProviderProps {
    children: ReactNode
}

export const BookkeepingConfigurationProvider = ({ children }: BookkeepingConfigurationProviderProps) => {
    const contextData = useState<BookkeepingConfigurationContextProps>(DEFAULT_CONTEXT)
    const [state, setState] = contextData
    const { filters: availableDateRangeFilterShortcuts } = useFilters()
    const { customer } = useCustomerFromPath()
    const { subscriptionStatus } = customer || {}
    const isBookkeepingDoneByAccountant = subscriptionStatus?.includes(SubscriptionStatus.Active)
    const { config, data } = state

    useEffect(() => {
        setState((currentState) => ({
            ...currentState,
            config: {
                ...currentState.config,
                isBookkeepingDoneByAccountant,
                availableDateRangeFilterShortcuts,
            },
        }))
    }, [isBookkeepingDoneByAccountant, availableDateRangeFilterShortcuts, setState])

    return (
        <BookkeepingConfigurationContext.Provider value={contextData}>
            <Bookkeeping.StoreProvider config={config} data={data}>
                {children}
            </Bookkeeping.StoreProvider>
        </BookkeepingConfigurationContext.Provider>
    )
}
