import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useDeleteCompanyReturnMutation, DeleteCompanyReturnParams } from '../api'

export const useDeleteCompanyReturn = () => {
    const [action, { isLoading }] = useDeleteCompanyReturnMutation()

    const deleteCompanyReturn = useCallback(
        (params: DeleteCompanyReturnParams) =>
            action(params).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Company return deleted successfully' })
                })
            ),
        [action]
    )

    return {
        deleteCompanyReturn,
        isLoading,
    }
}
