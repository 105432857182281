import { useCustomer } from './use-customer'
import { useCustomerIdFromPath } from './use-customer-id-from-path'

export const useCustomerFromPath = () => {
    const customerId = useCustomerIdFromPath()

    const { customer } = useCustomer(customerId)

    return { customer }
}
