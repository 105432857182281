import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { generateApiPath } from '@scouts/helpers'

import { ERROR_MESSAGE_DOWNLOADING } from '@/constants'
import {
    CustomerDTO,
    RefundCreateDTO,
    ResponseWithPagination,
    TaxReliefClaimDTO,
    TaxReliefClaimEditDTO,
    TaxReliefClaimFileDTO,
    TaxReliefClaimOptions,
} from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { ResponseTransformers } from '../error-handling/responseTransformers'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'
import { getTaxReliefClaimQueryParams } from './queryParams'

interface GetTaxReliefClaimParams {
    taxReliefClaimId: TaxReliefClaimDTO['id']
}

interface GetCustomersTaxReliefClaimsParams {
    customerId: CustomerDTO['id']
}
interface GetTaxReliefClaimsParams {
    options?: TaxReliefClaimOptions
}

export interface UpdateTaxReliefClaimParams {
    taxReliefClaimId: TaxReliefClaimDTO['id']
    data: TaxReliefClaimEditDTO
}

export interface GetTaxReliefClaimFilesParams {
    taxReliefClaimId: TaxReliefClaimDTO['id']
}
export interface DownloadTaxReliefClaimFileParams {
    taxReliefClaimId: TaxReliefClaimDTO['id']
    taxReliefClaimFileId: TaxReliefClaimFileDTO['id']
}

export interface UploadTaxReliefClaimFileParams {
    fileType: TaxReliefClaimFileDTO['fileType']
    taxReliefClaimId: TaxReliefClaimDTO['id']
}

export interface DeleteTaxReliefClaimFileParams {
    taxReliefClaimId: TaxReliefClaimDTO['id']
    taxReliefClaimFileId: TaxReliefClaimFileDTO['id']
}

export interface RefundTaxReliefClaimParams extends RefundCreateDTO {
    taxReliefClaimId: TaxReliefClaimDTO['id']
}

export interface DeleteTaxReliefClaimParams {
    taxReliefClaimId: TaxReliefClaimDTO['id']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTaxReliefClaim: builder.query<TaxReliefClaimDTO, GetTaxReliefClaimParams>({
            query: ({ taxReliefClaimId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.GET.SINGLE, { taxReliefClaimId }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.TaxReliefClaims, id: arg.taxReliefClaimId }],
        }),
        getTaxReliefClaims: builder.query<ResponseWithPagination<TaxReliefClaimDTO[]>, GetTaxReliefClaimsParams>({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.GET.LIST, undefined, {
                    queryParameters: getTaxReliefClaimQueryParams(options),
                }),
            }),
            providesTags: (result: ResponseWithPagination<TaxReliefClaimDTO[]> | undefined) =>
                result
                    ? [...result.data.map(({ id }) => ({ type: Tags.TaxReliefClaims, id })), Tags.TaxReliefClaims]
                    : [Tags.TaxReliefClaims],
            transformResponse: (response: TaxReliefClaimDTO[], meta: FetchBaseQueryMeta) =>
                mapPaginatedResponse(response, meta),
        }),
        getCustomersTaxReliefClaims: builder.query<TaxReliefClaimDTO[], GetCustomersTaxReliefClaimsParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.GET.CUSTOMER, { customerId }),
            }),
            providesTags: [Tags.TaxReliefClaims],
        }),
        updateTaxReliefClaim: builder.mutation<TaxReliefClaimDTO, UpdateTaxReliefClaimParams>({
            query: ({ taxReliefClaimId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.PUT, { taxReliefClaimId }),
                body: data,
            }),
            invalidatesTags: (_1, _2, { taxReliefClaimId }) => [{ type: Tags.TaxReliefClaims, id: taxReliefClaimId }],
        }),
        // Files
        getTaxReliefClaimFiles: builder.query<TaxReliefClaimFileDTO[], GetTaxReliefClaimFilesParams>({
            query: ({ taxReliefClaimId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.GET.FILES, { taxReliefClaimId }),
            }),
            providesTags: (result: TaxReliefClaimFileDTO[] | undefined) =>
                result
                    ? [...result.map(({ id }) => ({ type: Tags.TaxReliefClaimFiles, id })), Tags.TaxReliefClaimFiles]
                    : [Tags.TaxReliefClaimFiles],
        }),
        downloadTaxReliefClaimFile: builder.query<string, DownloadTaxReliefClaimFileParams>({
            query: ({ taxReliefClaimId, taxReliefClaimFileId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.GET.DOWNLOAD_FILE, {
                    taxReliefClaimId,
                    taxReliefClaimFileId,
                }),
                responseHandler: 'text',
            }),
            transformErrorResponse: ResponseTransformers.customMessage(ERROR_MESSAGE_DOWNLOADING),
        }),
        uploadTaxReliefClaimFile: builder.mutation<void, { file: File; params: UploadTaxReliefClaimFileParams }>({
            query: ({ file, params }) => {
                const { fileType, taxReliefClaimId } = params
                const formData = new FormData()
                formData.append('file', file)

                return {
                    method: 'POST',
                    url: generateApiPath(
                        ENDPOINTS.TAX_RELIEF_CLAIMS.POST.UPLOAD_FILE,
                        { taxReliefClaimId },
                        { queryParameters: { fileType } }
                    ),
                    body: formData,
                    formData: true,
                }
            },
            invalidatesTags: [Tags.TaxReliefClaimFiles],
        }),
        deleteTaxReliefClaimFile: builder.mutation<void, DeleteTaxReliefClaimFileParams>({
            query: ({ taxReliefClaimId, taxReliefClaimFileId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.DELETE.FILE, {
                    taxReliefClaimId,
                    taxReliefClaimFileId,
                }),
            }),
            invalidatesTags: [Tags.TaxReliefClaimFiles],
        }),
        refundTaxReliefClaim: builder.mutation<TaxReliefClaimDTO, RefundTaxReliefClaimParams>({
            query: ({ taxReliefClaimId, ...params }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.POST.REFUND, { taxReliefClaimId }),
                body: params,
            }),
            invalidatesTags: (_1, _2, { taxReliefClaimId }) => [{ type: Tags.TaxReliefClaims, id: taxReliefClaimId }],
        }),
        deleteTaxReliefClaim: builder.mutation<void, DeleteTaxReliefClaimParams>({
            query: ({ taxReliefClaimId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.DELETE.SINGLE, { taxReliefClaimId }),
            }),
            invalidatesTags: [Tags.TaxReliefClaims],
        }),
    }),
})

export const {
    useGetTaxReliefClaimQuery,
    useGetTaxReliefClaimsQuery,
    useGetCustomersTaxReliefClaimsQuery,
    useUpdateTaxReliefClaimMutation,
    useGetTaxReliefClaimFilesQuery,
    useLazyDownloadTaxReliefClaimFileQuery,
    useUploadTaxReliefClaimFileMutation,
    useDeleteTaxReliefClaimFileMutation,
    useRefundTaxReliefClaimMutation,
    useDeleteTaxReliefClaimMutation,
} = extendedApi
