import { useDeleteTaxConsultationFileMutation } from '../api'

export const useDeleteTaxConsultationFile = () => {
    const [deleteTaxConsultationFile, { isLoading }] = useDeleteTaxConsultationFileMutation()

    return {
        deleteTaxConsultationFile,
        isLoading,
    }
}
