import { colors, Notice } from '@scouts/ui'

export const TaxRegistrationUploadNotice = () => {
    return (
        <Notice background={colors.redLighter}>
            Uploading the UTR registration submission receipt is now required. Please see our{' '}
            <a
                href="https://www.notion.so/taxscouts/UTR-Registration-using-TaxScouts-263a6d3511b64b99998f97f1aa3c2bc9"
                rel="noopener noreferrer"
                target="_blank"
            >
                UTR registration guide
            </a>{' '}
            for more information.
        </Notice>
    )
}
