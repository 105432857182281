import { useGetCompanyReturnsStatisticsQuery, GeCompanyReturnsStatisticsParams } from '../api'

export const useCompanyReturnsSearchStatistics = ({ options }: GeCompanyReturnsStatisticsParams) => {
    const { data, isError, isFetching } = useGetCompanyReturnsStatisticsQuery(
        { options },
        { refetchOnMountOrArgChange: true }
    )

    return {
        companyReturnsStatistics: data,
        isError,
        isFetching,
    }
}
