export const REQUEST_ACCOUNTANTS = 'REQUEST_ACCOUNTANTS'
export const RECEIVE_ACCOUNTANTS = 'RECEIVE_ACCOUNTANTS'
export const REQUEST_ACCOUNTANT = 'REQUEST_ACCOUNTANT'
export const RECEIVE_ACCOUNTANT = 'RECEIVE_ACCOUNTANT'
export const SAVE_ACCOUNTANT = 'SAVE_ACCOUNTANT'
export const CREATE_ACCOUNTANT = 'CREATE_ACCOUNTANT'
export const SAVE_ACCOUNTANTS_PAUSED_HISTORY = 'SAVE_ACCOUNTANTS_PAUSED_HISTORY'
export const REQUEST_ACCOUNTANTS_SUITABLE_FOR = 'REQUEST_ACCOUNTANTS_SUITABLE_FOR'
export const RECEIVE_ACCOUNTANTS_SUITABLE_FOR = 'RECEIVE_ACCOUNTANTS_SUITABLE_FOR'

export const requestAccountants = (data) => ({ type: REQUEST_ACCOUNTANTS, data })

export const receiveAccountants = (data) => ({ type: RECEIVE_ACCOUNTANTS, data })

export const requestAccountant = (accountantId, meta = {}) => ({
    type: REQUEST_ACCOUNTANT,
    data: { accountantId },
    meta,
})

export const receiveAccountant = (data) => ({ type: RECEIVE_ACCOUNTANT, data })

export const saveAccountant = (accountantId, accountant, meta = {}) => ({
    type: SAVE_ACCOUNTANT,
    data: { accountantId, accountant },
    meta: { hideMessage: false, ...meta },
})

export const createAccountant = ({ accountant, onSuccess = null, onError }) => ({
    type: CREATE_ACCOUNTANT,
    data: { accountant },
    meta: { onSuccess, onError },
})

export const saveAccountantPausedHistory = (data, meta = {}) => ({ type: SAVE_ACCOUNTANTS_PAUSED_HISTORY, data, meta })

export const requestAccountantsSuitableFor = ({ customerId }, meta = {}) => ({
    type: REQUEST_ACCOUNTANTS_SUITABLE_FOR,
    data: { customerId },
    meta,
})

export const receiveAccountantsSuitableFor = ({ customerId, items }) => ({
    type: RECEIVE_ACCOUNTANTS_SUITABLE_FOR,
    data: { customerId, items },
})
