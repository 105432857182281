import { useEffect } from 'react'

import { useQueryParams } from '@scouts/helpers'
import { colors, Container, Flexer, Line, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { useOffPlatformServicesRequests } from '@/store/off-platform-services-requests'

import { useFilters } from './hooks'

import { usePagination } from '../pagination'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { OffPlatformServicesRequestsTable } from './OffPlatformServicesRequestsTable'
import { OffPlatformServicesRequestSearchFilter } from './search/OffPlatformServicesRequestSearchFilter'

export const OffPlatformServicesRequests = () => {
    const { activeFilters: activePagination } = usePagination()
    const { activeFilters, applyFilter, DEFAULT_FILTER } = useFilters()
    const { queryParams } = useQueryParams()

    useEffect(() => {
        const { filter } = queryParams

        if (!filter) {
            applyFilter({ updatedFilter: { ...DEFAULT_FILTER } })
        }
    }, [DEFAULT_FILTER, applyFilter, queryParams])

    const { offPlatformServicesRequests, isLoading, isError, pagination } = useOffPlatformServicesRequests({
        options: { ...activeFilters, ...activePagination },
    })

    const itemsCount = offPlatformServicesRequests?.length || 0
    const hasOffPlatformServicesRequests = itemsCount > 0

    if (isError)
        return (
            <Flexer alignCenter justifyCenter>
                <Line color={colors.neutralDarker}>Error getting additional services</Line>
            </Flexer>
        )

    if (isLoading && !hasOffPlatformServicesRequests)
        return (
            <Skeleton gap="24px" padding="24px">
                <SkeletonLine height="77px" width="80%" />

                <Flexer column gap="1px">
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="87px" repeat={10} />
                </Flexer>
            </Skeleton>
        )

    return (
        <Container padding="24px 24px 192px">
            <Spacer marginBottom="24px">
                <OffPlatformServicesRequestSearchFilter pagination={pagination} itemsCount={itemsCount} />
            </Spacer>

            {!isLoading && !hasOffPlatformServicesRequests && (
                <Line color={colors.neutralDarker}>No additional services found</Line>
            )}

            {hasOffPlatformServicesRequests && (
                <>
                    <OffPlatformServicesRequestsTable offPlatformServicesRequests={offPlatformServicesRequests} />
                    <Spacer marginTop="24px">
                        <PaginationContainer pagination={pagination} />
                    </Spacer>
                </>
            )}
        </Container>
    )
}
