import { useCallback, useEffect, useState } from 'react'

import { useDraftMessage } from '@/store/draft-messages'

export const useMessageComposer = ({ customerId }: { customerId: number }) => {
    const { hasDraftMessage } = useDraftMessage({ customerId })

    const [isComposerOpen, setIsComposerOpen] = useState(hasDraftMessage)

    useEffect(() => {
        setIsComposerOpen(false)
    }, [customerId])

    useEffect(() => {
        if (hasDraftMessage) setIsComposerOpen(true)
    }, [hasDraftMessage])

    const openComposer = useCallback(() => setIsComposerOpen(true), [])
    const closeComposer = useCallback(() => setIsComposerOpen(false), [])

    return {
        openComposer,
        closeComposer,
        isComposerOpen,
    }
}
