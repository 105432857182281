export const AccountantMeetingStatus = {
    Started: 'Started',
    Booked: 'Booked',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
} as const

export const ContactType = {
    Phone: 'Phone',
    Video: 'Video',
}

export interface AccountantMeetingSummaryDTO {
    accountantFullName: string | null
    accountantStatus: string
    accountantId: number | null
    additionalNotes: string | null
    completedDateUTC: string | null
    contactPreferenceType: keyof typeof ContactType | null
    customerEmail: string | null
    customerFullName: string | null
    customerId: number
    editToken: string | null
    externalId: string | null
    hasPaid: boolean
    id: number
    meetingLink: string | null
    price: number
    scheduledDateUTC: string | null
    status: keyof typeof AccountantMeetingStatus
    statusChangedDateUTC: string
}
