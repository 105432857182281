import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { addSuccessToast, AccountantPortalChrome, Button, Flexer } from '@scouts/ui'

import { EmailProvider, PATH_INTEGRATIONS } from '@/constants'
import { getUser } from '@/selectors'

import { useExternalMessages } from './hooks/use-external-messages'

import { useMessagesContext } from './context/messages-context'

export const MessagesHeader = () => {
    const { customerId } = useMessagesContext()
    const history = useHistory()

    const { isLoading, fetchExternalMessages } = useExternalMessages({ customerId, shouldSkipInitialFetch: true })

    const { hasGoogleConnection, hasMicrosoftConnection, isAdminUser } = useSelector(getUser)

    const handleSyncExternalMessages = (emailProvider: EmailProvider) => {
        fetchExternalMessages(emailProvider).then((response) => {
            if (typeof response === 'number')
                addSuccessToast({
                    body: `Found ${response} new email ${response === 1 ? 'message' : 'messages'}`,
                })
        })
    }

    const shouldDisplaySyncOutlook = !!hasMicrosoftConnection
    const shouldDisplaySyncGmail = !!hasGoogleConnection
    const shouldDisplaySetUpEmailIntegration = !shouldDisplaySyncOutlook && !shouldDisplaySyncGmail && !isAdminUser

    return (
        <>
            <AccountantPortalChrome.Customer.Page.HeaderGroup>
                <AccountantPortalChrome.Customer.Page.Title>Messages</AccountantPortalChrome.Customer.Page.Title>
            </AccountantPortalChrome.Customer.Page.HeaderGroup>

            <AccountantPortalChrome.Customer.Page.HeaderGroup>
                <Flexer gap="12px" alignCenter>
                    {shouldDisplaySyncOutlook && (
                        <Button
                            isLoading={isLoading}
                            isSecondary
                            size="small"
                            onClick={() => handleSyncExternalMessages(EmailProvider.Outlook)}
                        >
                            Sync with Outlook
                        </Button>
                    )}

                    {shouldDisplaySyncGmail && (
                        <Button
                            isLoading={isLoading}
                            isSecondary
                            size="small"
                            onClick={() => handleSyncExternalMessages(EmailProvider.Gmail)}
                        >
                            Sync with Gmail
                        </Button>
                    )}

                    {shouldDisplaySetUpEmailIntegration && (
                        <Button size="small" isOutlined onClick={() => history.push(PATH_INTEGRATIONS)}>
                            Set up email integration
                        </Button>
                    )}
                </Flexer>
            </AccountantPortalChrome.Customer.Page.HeaderGroup>
        </>
    )
}
