import React from 'react'

import { font, Button, Flexer, Line, Spacer } from '@scouts/ui'

import { PAGINATION_DEFAULT_PAGE } from '@/constants'
import { OffPlatformServicesRequestOptionsFilter, OffPlatformServicesRequestOptionsFilters, Pagination } from '@/models'

import SearchFilterDateRange from '@/components/search/SearchFilterDateRange'

// import SearchFilterDateRange from '@/components/search/SearchFilterDateRange'
import { useFilters } from '../hooks'

import { OffPlatformServicesRequestSearchFilterAccountantId } from './OffPlatformServicesRequestSearchFilterAccountantId'
import { OffPlatformServicesRequestSearchFilterAdd } from './OffPlatformServicesRequestSearchFilterAdd'
import { OffPlatformServicesRequestSearchFilterStatus } from './OffPlatformServicesRequestSearchFilterStatus'

export const OffPlatformServicesRequestSearchFilter = ({
    pagination,
    itemsCount,
}: {
    pagination?: Pagination
    itemsCount: number
}) => {
    const { activeFilters, clearFilters, applyFilter, removeFilter } = useFilters()

    const { perPage, currentPage, totalCount } = pagination || {}

    const currentPageStarts = perPage && currentPage ? perPage * (currentPage - 1) + 1 : 1
    const currentPageEnds = currentPageStarts + itemsCount - 1
    const hasMatches = !!totalCount && totalCount > 0

    const FilterComponent = {
        [OffPlatformServicesRequestOptionsFilter.STATUS]: <OffPlatformServicesRequestSearchFilterStatus />,
        [OffPlatformServicesRequestOptionsFilter.ACCOUNTANT_ID]: <OffPlatformServicesRequestSearchFilterAccountantId />,
        [OffPlatformServicesRequestOptionsFilter.CANCELLED_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { cancelledDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.cancelledDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.cancelledDate?.end || ''}
                label="Cancelled date"
                removeFilter={() => removeFilter({ updatedFilter: { cancelledDate: undefined } })}
            />
        ),
        [OffPlatformServicesRequestOptionsFilter.SUBMITTED_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { submittedDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.submittedDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.submittedDate?.end || ''}
                label="Submitted date"
                removeFilter={() => removeFilter({ updatedFilter: { submittedDate: undefined } })}
            />
        ),
        [OffPlatformServicesRequestOptionsFilter.PAID_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { paidDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.paidDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.paidDate?.end || ''}
                label="Paid date"
                removeFilter={() => removeFilter({ updatedFilter: { paidDate: undefined } })}
            />
        ),
        [OffPlatformServicesRequestOptionsFilter.REFERRED_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { referredDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.referredDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.referredDate?.end || ''}
                label="Referred date"
                removeFilter={() => removeFilter({ updatedFilter: { referredDate: undefined } })}
            />
        ),
        [OffPlatformServicesRequestOptionsFilter.CLAIMED_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { claimedDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.claimedDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.claimedDate?.end || ''}
                label="Claimed date"
                removeFilter={() => removeFilter({ updatedFilter: { claimedDate: undefined } })}
            />
        ),
    }

    return (
        <Flexer spaceBetween alignCenter>
            <Spacer marginRight="12px">
                <Flexer gap="18px" alignCenter>
                    <Flexer gap="6px" alignStart flexWrap>
                        {OffPlatformServicesRequestOptionsFilters.map((filter) => {
                            if (filter in activeFilters)
                                return <React.Fragment key={filter}>{FilterComponent[filter]}</React.Fragment>
                            return null
                        })}
                    </Flexer>
                    <OffPlatformServicesRequestSearchFilterAdd />
                </Flexer>
            </Spacer>
            <Spacer>
                <Flexer gap="12px" alignCenter>
                    {hasMatches && (
                        <Line size={font.smaller}>
                            {currentPageStarts}–{currentPageEnds} of {totalCount}{' '}
                            {totalCount === 1 ? 'match' : 'matches'}
                        </Line>
                    )}

                    {!hasMatches && <Line size={font.smaller}>No matches found</Line>}

                    <Button isSecondary size="small" onClick={clearFilters}>
                        Clear
                    </Button>
                </Flexer>
            </Spacer>
        </Flexer>
    )
}
