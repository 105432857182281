import { colors, Label } from '@scouts/ui'

export const BooleanLabel = ({ value }: { value: null | boolean }) => {
    if (value === null) return <>-</>

    return value ? (
        <Label background={colors.mintLighter}>Yes</Label>
    ) : (
        <Label background={colors.neutralLightest}>No</Label>
    )
}
