import { Link } from 'react-router-dom'

import { generateApiPath } from '@scouts/helpers'
import { colors, radius, Notice, NoticeAction } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { CustomerDTO, TaxRegistrationStatus } from '@/models'

export const SelfAssessmentNoticeTaxRegistration = ({ customer }: { customer: CustomerDTO }) => {
    const { id: customerId, taxRegistrationStatus, uniqueTaxpayerReference } = customer

    if (uniqueTaxpayerReference) return null

    const customerUrl = generateApiPath(PATH_CUSTOMERS, { customerId })

    if (taxRegistrationStatus === TaxRegistrationStatus.Submitted)
        return (
            <Notice background={colors.blueLighter} borderRadius={radius.medium}>
                Pending UTR registration (ready to start){' '}
                <NoticeAction>
                    <Link to={customerUrl}>View details</Link>
                </NoticeAction>
            </Notice>
        )

    if (taxRegistrationStatus === TaxRegistrationStatus.Complete)
        return (
            <Notice background={colors.yellow} borderRadius={radius.medium}>
                Pending UTR registration (submitted to HMRC, waiting for UTR)
                <NoticeAction>
                    <Link to={customerUrl}>View details</Link>
                </NoticeAction>
            </Notice>
        )

    return null
}
