import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownDateRange } from '../ui/filter'

const SearchFilterSignedUpDate = (props) => {
    const { signedUpDateStart, signedUpDateEnd, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = signedUpDateStart || signedUpDateEnd

    const activeValues = [
        signedUpDateStart ? `From ${signedUpDateStart}` : '',
        signedUpDateEnd ? `To ${signedUpDateEnd}` : '',
    ]

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Signed up date"
            activeValues={activeValues}
            removeFilter={() => removeFilter(['signedUpDateStart', 'signedUpDateEnd'])}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownDateRange
                dateFromName="signedUpDateStart"
                dateToName="signedUpDateEnd"
                applyFilter={applyFilter}
                removeFilter={() => removeFilter(['signedUpDateStart', 'signedUpDateEnd'])}
                dateFrom={signedUpDateStart}
                dateTo={signedUpDateEnd}
            />
        </FilterItem>
    )
}

SearchFilterSignedUpDate.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    signedUpDateEnd: PropTypes.string.isRequired,
    signedUpDateStart: PropTypes.string.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterSignedUpDate
