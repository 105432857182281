import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Checkbox, IconCommentSlash, Label, Spacer, Table } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { SelectedCustomer, TaxRegistrationDTO, TaxRegistrationRegistrationTypeTitles } from '@/models'

import { useUser } from '@/hooks/use-user'

import { AccountantStatusBubble } from '../accountants/AccountantStatusBubble'
import { CustomerIndicators } from '../customer-indicators'
import NotesIndicator from '../NotesIndicator'
import { TaxRegistrationStatusLabel } from './TaxRegistrationStatusLabel'

interface TaxRegistrationsTableRowProps {
    handleSelect: ({ customerId, fullName, unsubscribeFromBulkEmails }: SelectedCustomer) => void
    isSelected: boolean
    taxRegistration: TaxRegistrationDTO
    triggerTableUpdate: () => void
}

export const TaxRegistrationsTableRow = ({
    taxRegistration,
    isSelected,
    handleSelect,
    triggerTableUpdate,
}: TaxRegistrationsTableRowProps) => {
    const { isAdminUser } = useUser()
    const {
        accountantFullName,
        accountantStatus,
        customerEmail,
        customerFirstName,
        customerId,
        customerIdentityVerificationStatus,
        customerLastName,
        customerUnresolvedNoteCount,
        customerPriorityUnresolvedNotes,
        customerUniqueTaxpayerReference,
        hasPaid,
        registrationType,
        status,
        statusChangedDateUTC,
        unsubscribeFromBulkEmails,
    } = taxRegistration

    const customerFullName = `${customerFirstName} ${customerLastName}`
    const statusUpdatedAgo = moment.utc(statusChangedDateUTC).fromNow()

    const customerUrl = `${PATH_CUSTOMERS}/${customerId}`

    return (
        <Table.Body.Row key={customerId}>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <Customer>
                        <Name>{customerFullName}</Name>
                        <Email>{customerEmail}</Email>
                        {isAdminUser && accountantFullName && (
                            <AccountantName>
                                {accountantFullName}{' '}
                                <Spacer marginLeft="6px">
                                    <AccountantStatusBubble status={accountantStatus} />
                                </Spacer>
                            </AccountantName>
                        )}
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <CustomerIndicators
                        uniqueTaxpayerReference={customerUniqueTaxpayerReference}
                        identityVerificationStatus={customerIdentityVerificationStatus}
                        hideAgentAuthorisation
                        hideTaxRegistration
                        hidePayThroughTaxCodeRequested
                    />
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <Type>{TaxRegistrationRegistrationTypeTitles[registrationType]}</Type>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <Labels>
                        <TaxRegistrationStatusLabel status={status} />

                        {isAdminUser && hasPaid && (
                            <>
                                {' '}
                                <Label background={colors.mintLighter}>Paid</Label>
                            </>
                        )}
                    </Labels>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <StatusChanged>{statusUpdatedAgo}</StatusChanged>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(25% - 96px)">
                <Table.Body.Cell.Content>
                    <NotesIndicator
                        notePreviews={customerPriorityUnresolvedNotes}
                        numberOfNotes={customerUnresolvedNoteCount || 0}
                        customerId={customerId}
                        customerFullName={customerFullName}
                        triggerTableUpdate={triggerTableUpdate}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="96px">
                <Table.Body.Cell.Content alignRight>
                    {unsubscribeFromBulkEmails && (
                        <IconUnsubscribed title="Unsubscribed from bulk emails">
                            <IconCommentSlash color={colors.neutralDarker} />
                        </IconUnsubscribed>
                    )}

                    <Checkbox
                        isLabelless
                        label="Select"
                        name={String(customerId)}
                        value={String(customerId)}
                        onChange={() =>
                            handleSelect({
                                customerId,
                                fullName: customerFullName,
                                unsubscribeFromBulkEmails,
                            })
                        }
                        checked={isSelected}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Customer = styled.div``

const AccountantName = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    line-height: 1.3;
    display: flex;
    align-items: center;
    margin-top: 6px;
`

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
`

const StatusChanged = styled.div`
    font-size: ${font.small};
`

const Type = styled.div`
    font-size: ${font.small};
`

const Labels = styled.div``

const IconUnsubscribed = styled.div`
    margin-right: 18px;
`
