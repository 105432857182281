import { colors, Button, Page, Paragraph } from '@scouts/ui'

export const ChunkLoadError = () => (
    <Page>
        <Page.Section background={colors.blueLighter}>
            <Paragraph>This page seems to be updated to a newer version. Please click 'Reload' to continue.</Paragraph>
            <Button onClick={() => window.location.reload()}>Reload</Button>
        </Page.Section>
    </Page>
)
