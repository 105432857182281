import React from 'react'

import { font, Button, Flexer, Line, Spacer } from '@scouts/ui'

import { PAGINATION_DEFAULT_PAGE } from '@/constants'
import { Pagination, TaxReliefClaimOptionsFilter, TaxReliefClaimOptionsFilters } from '@/models'

import SearchFilterDateRange from '@/components/search/SearchFilterDateRange'

import { useFilters } from '../hooks'

import { TaxReliefClaimsSearchFilterAdd } from './TaxReliefClaimsSearchFilterAdd'
import { TaxReliefClaimsSearchFilterStatus } from './TaxReliefClaimsSearchFilterStatus'
import { TaxReliefClaimsSearchFilterSupportUserId } from './TaxReliefClaimsSearchFilterSupportUserId'

export const TaxReliefClaimsSearchFilter = ({
    pagination,
    itemsCount,
}: {
    pagination?: Pagination
    itemsCount: number
}) => {
    const { activeFilters, clearFilters, applyFilter, removeFilter } = useFilters()

    const { perPage, currentPage, totalCount } = pagination || {}

    const currentPageStarts = perPage && currentPage ? perPage * (currentPage - 1) + 1 : 1
    const currentPageEnds = currentPageStarts + itemsCount - 1
    const hasMatches = !!totalCount && totalCount > 0

    const FilterComponent = {
        [TaxReliefClaimOptionsFilter.STATUS]: <TaxReliefClaimsSearchFilterStatus />,
        [TaxReliefClaimOptionsFilter.SUPPORT_USER_ID]: <TaxReliefClaimsSearchFilterSupportUserId />,
        [TaxReliefClaimOptionsFilter.SUBMITTED_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { submittedDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.submittedDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.submittedDate?.end || ''}
                label="Submitted date"
                removeFilter={() => removeFilter({ updatedFilter: { submittedDate: undefined } })}
            />
        ),
        [TaxReliefClaimOptionsFilter.AWAITING_SIGNATURE_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({
                        updatedFilter: { awaitingSignatureDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE },
                    })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.awaitingSignatureDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.awaitingSignatureDate?.end || ''}
                label="Awaiting signature date"
                removeFilter={() => removeFilter({ updatedFilter: { awaitingSignatureDate: undefined } })}
            />
        ),
        [TaxReliefClaimOptionsFilter.SIGNATURE_RECEIVED_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({
                        updatedFilter: { signatureReceivedDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE },
                    })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.signatureReceivedDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.signatureReceivedDate?.end || ''}
                label="Signature received date"
                removeFilter={() => removeFilter({ updatedFilter: { signatureReceivedDate: undefined } })}
            />
        ),
        [TaxReliefClaimOptionsFilter.READY_TO_SEND_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { readyToSendDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.readyToSendDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.readyToSendDate?.end || ''}
                label="Ready to send date"
                removeFilter={() => removeFilter({ updatedFilter: { readyToSendDate: undefined } })}
            />
        ),
        [TaxReliefClaimOptionsFilter.SENT_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { sentDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.sentDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.sentDate?.end || ''}
                label="Sent date"
                removeFilter={() => removeFilter({ updatedFilter: { sentDate: undefined } })}
            />
        ),
        [TaxReliefClaimOptionsFilter.CANCELLED_DATE]: (
            <SearchFilterDateRange
                isOpenByDefault={false}
                applyFilter={(updatedFilters) =>
                    applyFilter({ updatedFilter: { cancelledDate: updatedFilters, page: PAGINATION_DEFAULT_PAGE } })
                }
                dateFromName="start"
                dateFromValue={activeFilters?.cancelledDate?.start || ''}
                dateToName="end"
                dateToValue={activeFilters?.cancelledDate?.end || ''}
                label="Cancelled date"
                removeFilter={() => removeFilter({ updatedFilter: { cancelledDate: undefined } })}
            />
        ),
    }

    return (
        <Flexer spaceBetween alignCenter>
            <Spacer marginRight="12px">
                <Flexer gap="18px" alignCenter>
                    <Flexer gap="6px" alignStart>
                        {TaxReliefClaimOptionsFilters.map((filter) => {
                            if (filter in activeFilters)
                                return <React.Fragment key={filter}>{FilterComponent[filter]}</React.Fragment>
                            return null
                        })}
                    </Flexer>
                    <TaxReliefClaimsSearchFilterAdd />
                </Flexer>
            </Spacer>
            <Spacer>
                <Flexer gap="12px" alignCenter>
                    {hasMatches && (
                        <Line size={font.smaller}>
                            {currentPageStarts}–{currentPageEnds} of {totalCount}{' '}
                            {totalCount === 1 ? 'match' : 'matches'}
                        </Line>
                    )}

                    {!hasMatches && <Line size={font.smaller}>No matches found</Line>}

                    <Button isSecondary size="small" onClick={clearFilters}>
                        Clear
                    </Button>
                </Flexer>
            </Spacer>
        </Flexer>
    )
}
