export const SEARCH_RESULT_TYPE_SELF_ASSESSMENTS = 'SelfAssessments'
export const SEARCH_RESULT_TYPE_TAX_CONSULTATIONS = 'TaxConsultations'
export const SEARCH_RESULT_TYPE_USERS = 'Users'
export const SEARCH_RESULT_TYPE_PRODUCT_BUNDLES = 'ProductBundles'

export const SEARCH_RESULT_TYPES = [
    SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
    SEARCH_RESULT_TYPE_TAX_CONSULTATIONS,
    SEARCH_RESULT_TYPE_USERS,
    SEARCH_RESULT_TYPE_PRODUCT_BUNDLES,
]

export const SEARCH_RESULT_TYPE_TITLES = {
    [SEARCH_RESULT_TYPE_SELF_ASSESSMENTS]: 'Tax returns',
    [SEARCH_RESULT_TYPE_TAX_CONSULTATIONS]: 'Consultations',
    [SEARCH_RESULT_TYPE_USERS]: 'Clients',
    [SEARCH_RESULT_TYPE_PRODUCT_BUNDLES]: 'Bundle purchases',
}
