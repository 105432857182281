import PropTypes from 'prop-types'

import { TAX_CONSULTATION_TOPICS, TAX_CONSULTATION_TOPICS_TITLES } from '../../constants'
import { FilterItem, FilterItemDropdownCheckboxes } from '../ui/filter'

const SearchFilterTaxConsultationTopics = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter.length > 0

    const activeValues = hasActiveFilter ? activeFilter.map((topic) => TAX_CONSULTATION_TOPICS_TITLES[topic]) : ['Any']

    const values = TAX_CONSULTATION_TOPICS.map((topic) => ({
        checked: activeFilter.includes(topic),
        label: TAX_CONSULTATION_TOPICS_TITLES[topic],
        value: topic,
    }))

    const name = 'taxConsultationTopics'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Consultation topics"
            activeValues={activeValues}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownCheckboxes
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterTaxConsultationTopics.propTypes = {
    activeFilter: PropTypes.array.isRequired,
    applyFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
}

export default SearchFilterTaxConsultationTopics
