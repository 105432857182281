import { Skeleton, SkeletonLine } from '@scouts/ui'

import { CustomerDTO } from '@/models'
import { useCustomersIdentityVerifications } from '@/store/identity-verifications'

import { VerificationCreate } from './VerificationCreate'
import { VerificationStatus } from './VerificationStatus'

interface VerificationProps {
    customer: CustomerDTO
}

export const Verification = ({ customer }: VerificationProps) => {
    const { identityVerifications, isLoading } = useCustomersIdentityVerifications({ customerId: customer.id })
    const latestIdentityVerification = identityVerifications ? identityVerifications[0] : null

    if (isLoading)
        return (
            <Skeleton padding="24px">
                <SkeletonLine height="86px" />
            </Skeleton>
        )

    if (!latestIdentityVerification) return <VerificationCreate customer={customer} />

    return <VerificationStatus customer={customer} identityVerification={latestIdentityVerification} />
}
