import { matchSuccessResponse } from '@scouts/helpers'
import { ConfirmationModal } from '@scouts/ui'

import { DiscountSchemeDTO } from '@/models'
import { useDeleteDiscountScheme } from '@/store/discount-schemes/hooks/use-delete-discount-scheme'

interface DiscountSchemesDeleteModalProps {
    discountScheme: DiscountSchemeDTO
    onClose: () => void
}

export const DiscountSchemesDeleteModal = ({ discountScheme, onClose }: DiscountSchemesDeleteModalProps) => {
    const { discountCode, id: discountSchemeId } = discountScheme

    const { deleteDiscountScheme, isLoading: isLoadingDelete } = useDeleteDiscountScheme()

    const handleDeleteDiscountCode = () => {
        deleteDiscountScheme({ discountSchemeId }).then(matchSuccessResponse(onClose))
    }

    return (
        <ConfirmationModal onConfirm={handleDeleteDiscountCode} onCancel={onClose} isLoading={isLoadingDelete}>
            Are you sure you want to delete <strong>{discountCode}</strong>?
        </ConfirmationModal>
    )
}
