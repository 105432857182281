import { useMemo } from 'react'

import { compareByMessageDate } from '@/domain/message'

import { useGetCustomerMessagesQuery } from '../api'

export const useCustomerMessages = ({ customerId }: { customerId: number }) => {
    const { data, isLoading, isFetching } = useGetCustomerMessagesQuery(
        { customerId },
        { refetchOnFocus: true, refetchOnMountOrArgChange: true }
    )

    const orderedCustomerMessages = useMemo(() => {
        return [...(data ?? [])].sort(compareByMessageDate)
    }, [data])

    const numberOfMessages = data?.length ?? 0
    const hasMessages = numberOfMessages > 0

    return { orderedCustomerMessages, hasMessages, numberOfMessages, isLoading, isFetching }
}
