import { useToggle } from '@scouts/helpers'
import { colors, font, radius, Container, DropdownSelect, DropdownSelectProps, Line, Spacer } from '@scouts/ui'

import { saveAccountantPausedHistory, saveUser } from '@/actions'
import { AccountantDomain } from '@/domain'
import { formatDate } from '@/helpers'
import { AccountantDetailsDTO, AccountantPausedHistoryCreateDTO, AccountantStatus, UserType } from '@/models'
import { useAppDispatch } from '@/store'

import { AccountantStatusModal } from './AccountantStatusModal'

export const AccountantStatusDropdown = ({ accountant }: { accountant: AccountantDetailsDTO }) => {
    const dispatch = useAppDispatch()
    const [isAccountantStatusModalOpen, openModal, closeModal] = useToggle()

    const { status, currentPausedScheduledEndDate } = accountant

    const handleStatusChange: DropdownSelectProps['onChange'] = ({ value: updatedStatus }) => {
        if (updatedStatus === status) return

        if (updatedStatus === AccountantStatus.Paused) {
            openModal()
        } else {
            dispatch(saveUser({ status: updatedStatus }))
        }
    }

    const handleStatusPaused = ({
        pausedStatusType,
        scheduledEndDate,
        pausedDescription,
    }: {
        pausedStatusType: AccountantPausedHistoryCreateDTO['pausedStatusType']
        scheduledEndDate: AccountantPausedHistoryCreateDTO['scheduledEndDate']
        pausedDescription: AccountantPausedHistoryCreateDTO['pausedDescription']
    }) => {
        const onSuccess = () =>
            dispatch(
                saveAccountantPausedHistory(
                    { pausedStatusType, scheduledEndDate, pausedDescription },
                    { userType: UserType.Accountant }
                )
            )

        dispatch(saveUser({ status: AccountantStatus.Paused }, { onSuccess }))

        closeModal()
    }

    return (
        <>
            <Container>
                <Spacer marginBottom="6px">
                    <Line weight={font.weight.medium} size={font.small}>
                        Your status
                    </Line>
                </Spacer>

                <DropdownSelect
                    name="status"
                    value={status}
                    options={AccountantDomain.accountantStatusDropdownOptionsBinary}
                    onChange={handleStatusChange}
                    width="100%"
                />

                {!!currentPausedScheduledEndDate && (
                    <Container
                        background={colors.neutralLightest}
                        padding="9px 18px"
                        radius={`0 0 ${radius.medium} ${radius.medium}`}
                    >
                        <Line size={font.small}>Paused until {formatDate(currentPausedScheduledEndDate)}</Line>
                    </Container>
                )}
            </Container>
            {isAccountantStatusModalOpen && (
                <AccountantStatusModal onSubmit={handleStatusPaused} onCancel={closeModal} />
            )}
        </>
    )
}
