import { RECEIVE_RETURNING_CUSTOMERS, REQUEST_RETURNING_CUSTOMERS } from '../actions'

const defaultState = {
    isFetching: false,
    shouldFetch: true,
    data: [],
    pagination: {
        currentPage: null,
        pageCount: null,
        perPage: null,
        totalCount: null,
    },
}

export default (state = defaultState, action) => {
    const { type, data, meta } = action

    switch (type) {
        case REQUEST_RETURNING_CUSTOMERS: {
            return { ...state, isFetching: true }
        }

        case RECEIVE_RETURNING_CUSTOMERS: {
            const items = Object.assign([], data)
            const pagination = meta?.pagination || {}

            return { ...state, isFetching: false, shouldFetch: false, data: items, pagination }
        }

        default:
            return state
    }
}
