import styled from 'styled-components'

import { colors, font, media, ButtonLink } from '@scouts/ui'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import CustomerNotesCreate from '../CustomerNotesCreate'
import CustomerNotesList from '../CustomerNotesList'

export const CustomerSidebarNotes = () => {
    const { customer } = useCustomerFromPath()
    const { id: customerId } = customer

    return (
        <Container>
            <Header>
                <Title>Notes</Title>
                <Action>
                    <CustomerNotesCreate customerId={customerId}>
                        <ButtonLink icon="plus">New note</ButtonLink>
                    </CustomerNotesCreate>
                </Action>
            </Header>
            <CustomerNotesList customerId={customerId} />
        </Container>
    )
}

const Container = styled.div`
    padding: 21px 18px;
    background: ${colors.redLighter};

    ${media.tablet} {
        min-height: 100%;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`

const Action = styled.div``

const Title = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    align-self: center;
`
