import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import {
    colors,
    Button,
    ButtonLink,
    Checkbox,
    DropdownSelect,
    Flexer,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    TextArea,
} from '@scouts/ui'

import { createSelfAssessment, requestCustomer, requestCustomerSelfAssessments } from '../../actions'
import { NEXT_TAX_YEAR, TAX_YEARS } from '../../constants'
import { isAdmin } from '../../helpers'
import { getCustomerSelfAssessments } from '../../selectors'

const DropdownWrapper = styled.div`
    margin-left: 26px;
    margin-top: 12px;
`

const NEXT_TAX_YEAR_SELF_ASSESSMENT_ID = 'NEXT_TAX_YEAR_SELF_ASSESSMENT_ID'
const NEXT_TAX_YEAR_CONFIRMATION_MESSAGE = `
This client does not have a ${TAX_YEARS[NEXT_TAX_YEAR]} tax return yet.

Do you want to create it and attach this note to it?`

class CustomerNotesItemModal extends Component {
    constructor(props) {
        super(props)

        const { note, defaultSelfAssessmentId, isPriorityInitially } = this.props

        this.state = {
            noteText: note.noteText || '',
            isPriority: note.isPriority || isPriorityInitially,
            isTaggedWithSelfAssessment: !!note.selfAssessmentId,
            selfAssessmentId: note.selfAssessmentId || defaultSelfAssessmentId,
        }

        _bindAll(this, [
            'handleChange',
            'handleCheckbox',
            'handleSubmit',
            'handleSubmitNextYearSelfAssessmentNote',
            'handleSaveNote',
            'toggleSelfAssessment',
        ])
    }

    handleCheckbox({ name, checked }) {
        this.setState({ [name]: checked })
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value })
    }

    handleSubmit() {
        const { isTaggedWithSelfAssessment, selfAssessmentId } = this.state

        const isNextYearSelfAssessmentNote = selfAssessmentId === NEXT_TAX_YEAR_SELF_ASSESSMENT_ID
        if (isTaggedWithSelfAssessment && isNextYearSelfAssessmentNote) {
            this.handleSubmitNextYearSelfAssessmentNote()
        } else {
            this.handleSaveNote(isTaggedWithSelfAssessment ? selfAssessmentId : null)
        }
    }

    handleSubmitNextYearSelfAssessmentNote() {
        const { dispatch, customerId } = this.props

        if (!window.confirm(NEXT_TAX_YEAR_CONFIRMATION_MESSAGE)) return

        const saveNote = (createdSelfAssessment) => {
            dispatch(requestCustomer(customerId))
            dispatch(requestCustomerSelfAssessments(customerId))
            this.handleSaveNote(createdSelfAssessment.id)
        }

        const newSelfAssessment = { customerId, taxYear: NEXT_TAX_YEAR, requestForTaxDetails: false }
        dispatch(createSelfAssessment(newSelfAssessment, { onSuccess: saveNote }))
    }

    handleSaveNote(selfAssessmentId) {
        const { handleSave, note, escalationId } = this.props
        const { noteText, isPriority } = this.state
        handleSave({ ...note, noteText, isPriority, selfAssessmentId, escalationId })
    }

    toggleSelfAssessment({ name, checked }) {
        const { defaultSelfAssessmentId } = this.props
        const { selfAssessmentId: currentSelfAssessmentId } = this.state

        this.setState({ [name]: checked, selfAssessmentId: currentSelfAssessmentId || defaultSelfAssessmentId })
    }

    render() {
        const { note: originalNote, handleCancel, handleDelete, isAdminUser, taxYearOptions, escalationId } = this.props
        const { noteText, isPriority, selfAssessmentId, isTaggedWithSelfAssessment } = this.state

        const isCreatingNewNote = !originalNote.id
        const isEditingNote = !!originalNote.id
        const isEscalationNote = !!escalationId
        const isNoteDeleteAllowed = originalNote.isPriority ? isAdminUser : isEditingNote

        return (
            <Modal onClose={handleCancel}>
                <Form onSubmit={this.handleSubmit}>
                    <ModalHeader>
                        <ModalTitle>{isCreatingNewNote ? 'Add new note' : 'Edit note'}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Form.Row.Content>
                                <TextArea
                                    name="noteText"
                                    value={noteText}
                                    onChange={this.handleChange}
                                    height="168px"
                                    required
                                    autoFocus={isCreatingNewNote}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                        {isAdminUser && (
                            <Form.Row>
                                <Form.Row.Content>
                                    <Checkbox
                                        isSmall
                                        checked={isPriority}
                                        label={
                                            isEscalationNote
                                                ? 'Show to accountant'
                                                : 'High priority, surface in dashboard'
                                        }
                                        name="isPriority"
                                        onChange={this.handleCheckbox}
                                    />
                                </Form.Row.Content>
                            </Form.Row>
                        )}
                        {!isEscalationNote && (
                            <Form.Row>
                                <Form.Row.Content>
                                    <Checkbox
                                        isSmall
                                        checked={isTaggedWithSelfAssessment}
                                        label="Related to a tax return"
                                        name="isTaggedWithSelfAssessment"
                                        onChange={this.toggleSelfAssessment}
                                    />
                                </Form.Row.Content>
                                {isTaggedWithSelfAssessment && (
                                    <DropdownWrapper>
                                        <DropdownSelect
                                            name="selfAssessmentId"
                                            value={selfAssessmentId}
                                            options={taxYearOptions}
                                            onChange={this.handleChange}
                                        />
                                    </DropdownWrapper>
                                )}
                            </Form.Row>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Flexer>
                            {isNoteDeleteAllowed && (
                                <ModalActions>
                                    <ButtonLink color={colors.red} onClick={handleDelete}>
                                        Delete
                                    </ButtonLink>
                                </ModalActions>
                            )}
                            <ModalActions>
                                <Button isSecondary onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button type="submit" disabled={!noteText}>
                                    {isCreatingNewNote ? 'Add' : 'Save'}
                                </Button>
                            </ModalActions>
                        </Flexer>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

CustomerNotesItemModal.propTypes = {
    customerId: PropTypes.number.isRequired,
    escalationId: PropTypes.number,
    defaultSelfAssessmentId: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,
    handleSave: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
    isPriorityInitially: PropTypes.bool,
    note: PropTypes.object,
    taxYearOptions: PropTypes.array.isRequired,
}

CustomerNotesItemModal.defaultProps = {
    defaultSelfAssessmentId: null,
    isPriorityInitially: false,
    escalationId: null,
    note: {},
    handleDelete: () => {},
}

const getTaxYearOptions = (customerSelfAssessments) => {
    const options = customerSelfAssessments.map(({ id, taxYear }) => ({ value: id, title: TAX_YEARS[taxYear] }))

    const nextYearSelfAssessment = _find(customerSelfAssessments, { taxYear: String(NEXT_TAX_YEAR) })
    if (!nextYearSelfAssessment) {
        const nextYearSelfAssessmentOption = {
            value: NEXT_TAX_YEAR_SELF_ASSESSMENT_ID,
            title: TAX_YEARS[NEXT_TAX_YEAR],
        }
        return [nextYearSelfAssessmentOption, ...options]
    }

    return options
}

const mapStateToProps = (state, ownProps) => {
    const { user } = state
    const { customerId, match } = ownProps

    const customerSelfAssessments = getCustomerSelfAssessments(state, { customerId })
    const firstSelfAssessmentId = _get(customerSelfAssessments, [0, 'id'], null)
    const taxYearOptions = getTaxYearOptions(customerSelfAssessments)

    const defaultSelfAssessmentId = match.params.selfAssessmentId
        ? Number(match.params.selfAssessmentId)
        : firstSelfAssessmentId

    return {
        defaultSelfAssessmentId,
        taxYearOptions,
        isAdminUser: isAdmin(user),
    }
}

export default withRouter(connect(mapStateToProps)(CustomerNotesItemModal))
