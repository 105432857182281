import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, media, ButtonLink, Checkbox, Label, Table } from '@scouts/ui'

import {
    PATH_CUSTOMERS,
    REWARD_PAYMENT_STATUS_CANCELLED,
    REWARD_PAYMENT_STATUS_CHARGEDBACK,
    REWARD_PAYMENT_STATUS_REFUNDED,
    REWARD_PAYMENT_STATUS_UNKNOWN,
} from '../../constants'
import { formatCurrency, formatDate } from '../../helpers'
import { RewardsPaymentStatusLabel } from './RewardsPaymentStatusLabel'

const Customer = styled.div``

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    font-size: ${font.small};
    margin-top: 6px;
`

const ReferredCustomer = styled.div`
    font-size: ${font.small};
`
const ReferredCustomerName = styled.div``

const ReferredCustomerEmail = styled.div`
    margin-top: 6px;
`

const Amount = styled.div`
    font-size: ${font.small};
`

const Date = styled.div`
    font-size: ${font.small};
`

const Status = styled.div`
    font-size: ${font.small};
`

const StatusWithLink = styled.div`
    font-size: ${font.small};

    ${media.pointer} {
        &:hover {
            color: inherit;
        }
    }
`

const ActionList = styled.div`
    margin-top: 12px;
`

const Action = styled.div`
    margin-bottom: 12px;
`

const RewardsRow = (props) => {
    const { data, markAsPaid, toggleIgnored, triggerPayment } = props

    const {
        referredByUserId,
        referredByUserEmail,
        referredUserId,
        referredUserHasPaid,
        referredByHasBankDetails,
        updatedDateUTC,
    } = data

    const referredFirstName = _get(data, ['referredUserFirstName'], '')
    const referredLastName = _get(data, ['referredUserLastName'], '')
    const referredFullName = `${referredFirstName} ${referredLastName}`
    const referredEmail = _get(data, ['referredUserEmail'], '')

    const referredByfirstName = _get(data, ['referredByUserFirstName'], '')
    const referredBylastName = _get(data, ['referredByUserLastName'], '')
    const referredByFullName = `${referredByfirstName} ${referredBylastName}`

    const rewardPaid = _get(data, ['referredByUserRewarded'])
    const rewardAmount = _get(data, ['referredByUserRewardAmount'], '')
    const rewardPaymentStatus = _get(data, ['paymentStatus'])

    const failedPaymentStatuses = [
        REWARD_PAYMENT_STATUS_CANCELLED,
        REWARD_PAYMENT_STATUS_REFUNDED,
        REWARD_PAYMENT_STATUS_CHARGEDBACK,
        REWARD_PAYMENT_STATUS_UNKNOWN,
    ]

    const isFailedPaymentStatus = failedPaymentStatuses.includes(rewardPaymentStatus)
    const isRewardPayable = referredUserHasPaid && rewardAmount

    const referralId = _get(data, ['id'])
    const ignored = _get(data, ['ignored'])

    const referredUserUrl = `${PATH_CUSTOMERS}/${referredUserId}`
    const referredByUserUrl = `${PATH_CUSTOMERS}/${referredByUserId}`

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={referredByUserUrl}>
                    <Customer>
                        <Name>{referredByFullName}</Name>
                        <Email>{referredByUserEmail}</Email>
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="7%">
                <Table.Body.Cell.Content>
                    <Amount>{rewardAmount ? formatCurrency(rewardAmount) : '-'}</Amount>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink
                    Link={Link}
                    to={referredByHasBankDetails ? `${referredByUserUrl}/bankdetails` : referredByUserUrl}
                >
                    <StatusWithLink>
                        {referredByHasBankDetails ? <Label background={colors.mintLighter}>Yes</Label> : 'No'}
                    </StatusWithLink>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={referredUserUrl}>
                    <ReferredCustomer>
                        <ReferredCustomerName>{referredFullName}</ReferredCustomerName>
                        <ReferredCustomerEmail>{referredEmail}</ReferredCustomerEmail>
                    </ReferredCustomer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.Content>
                    <Status>{referredUserHasPaid ? <Label background={colors.mintLighter}>Yes</Label> : 'No'}</Status>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.Content>
                    <Date>{formatDate(updatedDateUTC)}</Date>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="calc(18% - 96px)">
                {!rewardPaid && !rewardPaymentStatus && isRewardPayable && (
                    <Table.Body.Cell.Content>
                        <ActionList>
                            <Action>
                                <ButtonLink type="button" onClick={() => markAsPaid(data)}>
                                    Mark as paid
                                </ButtonLink>
                            </Action>
                            {referredByHasBankDetails && (
                                <Action>
                                    <ButtonLink type="button" onClick={() => triggerPayment(data)}>
                                        Trigger payment
                                    </ButtonLink>
                                </Action>
                            )}
                        </ActionList>
                    </Table.Body.Cell.Content>
                )}
                {!rewardPaid && rewardPaymentStatus && (
                    <Table.Body.Cell.Content>
                        <ActionList>
                            <Action>
                                <Status>
                                    <RewardsPaymentStatusLabel status={rewardPaymentStatus} />
                                </Status>
                            </Action>
                            {isFailedPaymentStatus && (
                                <Action>
                                    <ButtonLink type="button" onClick={() => markAsPaid(data)}>
                                        Mark as paid
                                    </ButtonLink>
                                </Action>
                            )}
                        </ActionList>
                    </Table.Body.Cell.Content>
                )}
                {rewardPaid && (
                    <Table.Body.Cell.Content>
                        <Status>
                            <Label background={colors.mintLighter}>Paid</Label>
                        </Status>
                    </Table.Body.Cell.Content>
                )}
            </Table.Body.Cell>
            <Table.Body.Cell width="96px">
                <Table.Body.Cell.Content alignRight>
                    <Checkbox
                        isLabelless
                        label="Ignore"
                        name={`${referralId}`}
                        checked={ignored}
                        onChange={toggleIgnored}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

RewardsRow.propTypes = {
    data: PropTypes.object.isRequired,
    markAsPaid: PropTypes.func.isRequired,
    toggleIgnored: PropTypes.func.isRequired,
    triggerPayment: PropTypes.func.isRequired,
}

export default RewardsRow
