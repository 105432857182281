import { colors, DropdownSelect, DropdownSelectProps, Flexer, Spinner } from '@scouts/ui'

import {
    TaxRegistrationDTO,
    TaxRegistrationStatus,
    TaxRegistrationStatusTitles,
    TaxRegistrationStatusTypes,
} from '@/models'
import { useUpdateTaxRegistrationStatus } from '@/store/tax-registrations'

const STATUSES = [
    {
        value: TaxRegistrationStatus.Started,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Started],
    },
    {
        value: TaxRegistrationStatus.Submitted,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Submitted],
        color: colors.blueLighter,
    },
    {
        value: TaxRegistrationStatus.InformationRequested,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.InformationRequested],
        color: colors.yellow,
    },
    {
        value: TaxRegistrationStatus.Complete,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Complete],
        color: colors.yellow,
    },
    {
        value: TaxRegistrationStatus.Cancelled,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Cancelled],
        color: colors.red,
    },
]

interface TaxRegistrationStatusProps {
    taxRegistration: TaxRegistrationDTO
}

export const TaxRegistrationStatusDropdown = ({ taxRegistration }: TaxRegistrationStatusProps) => {
    const { updateTaxRegistrationStatus, isLoading } = useUpdateTaxRegistrationStatus()
    const { status, customerId } = taxRegistration

    const handleChange: DropdownSelectProps['onChange'] = ({ value }) => {
        updateTaxRegistrationStatus({ customerId, status: value as TaxRegistrationStatusTypes })
    }

    return (
        <span className="tour-utr-status">
            <Flexer alignCenter gap="12px">
                <DropdownSelect name="status" value={status} options={STATUSES} onChange={handleChange} width="100%" />
                {isLoading && <Spinner isSmall />}
            </Flexer>
        </span>
    )
}
