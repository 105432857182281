import { colors, font, Flexer, Label, Line } from '@scouts/ui'

import { PAYMENT_STATUS_REFUNDED, REFUND_REASONS_TITLES } from '@/constants'
import { formatCurrency, formatDate } from '@/helpers'
import { TaxReliefClaimDTO } from '@/models'

interface TaxReliefClaimPaymentStatusProps
    extends Pick<TaxReliefClaimDTO, 'paymentDateUTC' | 'paymentStatus' | 'price' | 'refundDateUTC' | 'refundReason'> {}

export const TaxReliefClaimPaymentStatus = ({
    paymentDateUTC,
    paymentStatus,
    price,
    refundDateUTC,
    refundReason,
}: TaxReliefClaimPaymentStatusProps) => {
    if (paymentStatus !== PAYMENT_STATUS_REFUNDED) {
        if (paymentDateUTC) {
            return (
                <Flexer alignCenter gap="6px">
                    <Line>{paymentDateUTC && formatDate(paymentDateUTC)}</Line>
                    <Label background={colors.neutralLightest}>{formatCurrency(price)}</Label>
                </Flexer>
            )
        }
        return <Flexer alignCenter>Not paid</Flexer>
    }

    return (
        <>
            <Flexer alignStart column gap="6px">
                {paymentDateUTC && formatDate(paymentDateUTC)}
                <Label background={colors.red}>Refunded</Label>
                {!!refundReason.refundReasonType && (
                    <Line color={colors.neutralDarker} size={font.small}>
                        {REFUND_REASONS_TITLES[refundReason.refundReasonType]}
                    </Line>
                )}
                {!!refundReason.refundReasonDescription && (
                    <Line color={colors.neutralDarker} size={font.small}>
                        {refundReason.refundReasonDescription}
                    </Line>
                )}
            </Flexer>
            <Line size={font.small}>Refunded on {formatDate(refundDateUTC)}</Line>
        </>
    )
}
