import _get from 'lodash/get'

import {
    RECEIVE_CUSTOMER_SELF_ASSESSMENTS,
    RECEIVE_SELF_ASSESSMENT,
    RECEIVE_SELF_ASSESSMENTS,
    REMOVE_CUSTOMER_SELF_ASSESSMENTS,
    REMOVE_SELF_ASSESSMENT,
    REQUEST_SELF_ASSESSMENTS,
    UPDATE_SELF_ASSESSMENT,
} from '../actions'

const defaultState = {
    isFetching: false,
    shouldFetch: true,
    list: [],
    details: [],
    pagination: {
        currentPage: null,
        pageCount: null,
        perPage: null,
        totalCount: null,
    },
    hasSelectedToUpgradeToBundle: false,
}

export default (state = defaultState, action) => {
    const { type, data, meta } = action

    switch (type) {
        case REQUEST_SELF_ASSESSMENTS: {
            return { ...state, isFetching: true }
        }

        case RECEIVE_SELF_ASSESSMENTS: {
            const items = Object.assign([], data)
            const pagination = _get(meta, ['pagination'], {})

            return { ...state, isFetching: false, shouldFetch: false, list: items, pagination }
        }

        case RECEIVE_CUSTOMER_SELF_ASSESSMENTS: {
            const items = Object.assign([], state.list)

            data.forEach((selfAssessment) => {
                const key = items.findIndex((o) => o.id === selfAssessment.id)

                if (key > -1 && items[key]) {
                    items[key] = selfAssessment
                } else {
                    items.push(selfAssessment)
                }
            })

            return { ...state, list: items }
        }

        case RECEIVE_SELF_ASSESSMENT: {
            const items = Object.assign([], state.details)

            const key = items.findIndex((o) => o.id === data.id)

            if (key > -1 && items[key]) {
                items[key] = data
            } else {
                items.push(data)
            }

            return { ...state, details: Object.assign([], items) }
        }

        case UPDATE_SELF_ASSESSMENT: {
            const list = Object.assign([], state.list)

            const listKey = list.findIndex((o) => o.id === data.id)

            if (listKey > -1 && list[listKey]) {
                list[listKey] = { ...list[listKey], ...data }
            } else {
                list.push(data)
            }

            const details = Object.assign([], state.details)

            const detailsKey = details.findIndex((o) => o.id === data.id)

            if (detailsKey > -1 && details[detailsKey]) {
                details[detailsKey] = { ...details[detailsKey], ...data }
            } else {
                details.push(data)
            }

            return { ...state, list: Object.assign([], list), details: Object.assign([], details) }
        }

        case REMOVE_SELF_ASSESSMENT: {
            const list = Object.assign([], state.list)
            const listKey = list.findIndex((o) => o.id === data.id)

            if (listKey > -1 && list[listKey]) {
                list.splice(listKey, 1)
            }

            const details = Object.assign([], state.details)
            const detailsKey = details.findIndex((o) => o.id === data.id)

            if (detailsKey > -1 && details[detailsKey]) {
                details.splice(detailsKey, 1)
            }

            return { ...state, list: Object.assign([], list), details: Object.assign([], details) }
        }

        case REMOVE_CUSTOMER_SELF_ASSESSMENTS: {
            const list = Object.assign([], state.list).filter((o) => o.customerId !== data.customerId)
            const details = Object.assign([], state.details).filter((o) => o.customerId !== data.customerId)

            return { ...state, list: Object.assign([], list), details: Object.assign([], details) }
        }

        default:
            return state
    }
}
