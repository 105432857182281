import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Flexer } from '@scouts/ui'

import { requestNotesNotifications } from '@/actions'
import { isAdmin } from '@/helpers'
import { getEscalationNotesNotifications, getUser } from '@/selectors'
import { useAppDispatch } from '@/store'

import DashboardEscalationsItem from './DashboardEscalationsItem'

export const DashboardEscalations = ({ accountantId }: { accountantId: number }) => {
    const dispatch = useAppDispatch()
    const escalationNotes = useSelector(getEscalationNotesNotifications)
    const user = useSelector(getUser)
    const isAdminUser = isAdmin(user)

    useEffect(() => {
        dispatch(requestNotesNotifications({ accountantId }))
    }, [dispatch, accountantId])

    if (!escalationNotes.length) return null

    return (
        <Flexer column gap="12px">
            {escalationNotes.map((note) => (
                <DashboardEscalationsItem key={note.id} note={note} isAdminUser={isAdminUser} />
            ))}
        </Flexer>
    )
}
