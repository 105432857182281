import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { colors, radius, Notice } from '@scouts/ui'

import {
    PATH_CUSTOMERS,
    SELF_ASSESSMENT_STATUS_CANCELLED,
    SELF_ASSESSMENT_STATUS_FILED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    TAX_YEARS,
} from '@/constants'
import { isSelfAssessmentStatusAtLeast } from '@/helpers'
import { TaxRegistrationStatus } from '@/models'
import { getCustomerById, getCustomerSelfAssessments } from '@/selectors'

const getHasInProgressTaxRegistration = ({ hasTaxRegistration, taxRegistrationStatus }) => {
    const shouldIgnoreTaxRegistration =
        taxRegistrationStatus === TaxRegistrationStatus.Cancelled ||
        taxRegistrationStatus == TaxRegistrationStatus.Complete

    return hasTaxRegistration && !shouldIgnoreTaxRegistration
}

const getInProgressSelfAssessments = (selfAssessments) =>
    selfAssessments.filter((assessment) => {
        const isPaidFor = assessment.hasPaid || assessment.payAfterFiling
        const isSubmitted = isSelfAssessmentStatusAtLeast(assessment.status, SELF_ASSESSMENT_STATUS_SUBMITTED)
        const shouldIgnoreSelfAssessment = [
            SELF_ASSESSMENT_STATUS_CANCELLED,
            SELF_ASSESSMENT_STATUS_FILED,
            SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
        ].includes(assessment.status)

        return isPaidFor && isSubmitted && !shouldIgnoreSelfAssessment
    })

const CustomerServicesInProgressNotice = () => {
    const params = useParams()
    const customerId = Number(params.customerId)

    const customer = useSelector((state) => getCustomerById(state, { customerId }))
    const selfAssessments = useSelector((state) => getCustomerSelfAssessments(state, { customerId }))
    const inProgressSelfAssessments = getInProgressSelfAssessments(selfAssessments)

    const hasInProgressTaxRegistration = getHasInProgressTaxRegistration(customer)

    const serviceNames = inProgressSelfAssessments.map((assessment) => `Tax return ${TAX_YEARS[assessment.taxYear]}`)
    if (hasInProgressTaxRegistration) serviceNames.push('UTR registration')

    if (serviceNames.length > 0) {
        return (
            <Notice background={colors.blueLighter} borderRadius={radius.medium}>
                This client has also paid for the following services: {serviceNames.join(', ')}.{' '}
                <Link to={`${PATH_CUSTOMERS}/${customerId}`}>View here</Link>
            </Notice>
        )
    }

    return null
}

export default CustomerServicesInProgressNotice
