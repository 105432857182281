export const EXPORT_CUSTOMERS = 'EXPORT_CUSTOMERS'
export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS'
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS'
export const REQUEST_CUSTOMER = 'REQUEST_CUSTOMER'
export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER'
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER'
export const SAVE_CUSTOMER_EMAIL = 'SAVE_CUSTOMER_EMAIL'
export const SAVE_CUSTOMER_ACCOUNTANT = 'SAVE_CUSTOMER_ACCOUNTANT'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
export const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS'

export const deleteCustomer = (data) => ({ type: DELETE_CUSTOMER, data })

export const removeCustomer = (data) => ({ type: REMOVE_CUSTOMER, data })

export const requestCustomers = (data) => ({ type: REQUEST_CUSTOMERS, data })

export const receiveCustomers = (data, meta) => ({ type: RECEIVE_CUSTOMERS, data, meta })

export const requestCustomer = (customerId) => ({ type: REQUEST_CUSTOMER, data: { customerId } })

export const receiveCustomer = (data) => ({ type: RECEIVE_CUSTOMER, data })

export const saveCustomer = (customerId, customer, options = {}) => ({
    type: SAVE_CUSTOMER,
    data: { customerId, customer },
    options,
})

export const saveCustomerEmail = (data, meta) => ({ type: SAVE_CUSTOMER_EMAIL, data, meta })

export const saveCustomerAccountant = (data, meta) => ({ type: SAVE_CUSTOMER_ACCOUNTANT, data, meta })

export const createCustomer = ({ customer, redirect = false }) => ({
    type: CREATE_CUSTOMER,
    data: { customer, redirect },
})

export const searchCustomers = (data, options = {}) => ({ type: SEARCH_CUSTOMERS, data, options })

export const exportCustomers = (data) => ({ type: EXPORT_CUSTOMERS, data })
