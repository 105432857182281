import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { generateApiPath } from '@scouts/helpers'

import {
    CustomerDTO,
    RefundCreateDTO,
    ResponseWithPagination,
    StatisticsDTO,
    TaxConsultationDTO,
    TaxConsultationOptions,
    TaxConsultationShortDTO,
    TaxConsultationStatusTypes,
    TaxConsultationSummaryDTO,
    UserAgreementDTO,
} from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'
import { getTaxConsultationQueryParams } from './queryParams'

type SummaryContentDTO = {
    summaryContent: string
}

type GetSummaryContentParams = {
    taxConsultationId: number
}

type UpdateSummaryContentParams = {
    taxConsultationId: number
    summaryContent: string
}

export interface GetTaxConsultationsParams {
    options?: TaxConsultationOptions
}
export interface GetCustomerTaxConsultationsParams {
    customerId: CustomerDTO['id']
}
export interface CreateTaxConsultationParams {
    customerId: CustomerDTO['id']
}

export interface GetTaxConsultationAgreementParams {
    taxConsultationId: TaxConsultationDTO['id']
}

export interface GetTaxConsultationParams {
    taxConsultationId: TaxConsultationDTO['id']
}

export interface DeleteTaxConsultationParams {
    taxConsultationId: TaxConsultationDTO['id']
}

export interface RefundTaxConsultationParams {
    taxConsultationId: TaxConsultationDTO['id']
    data: RefundCreateDTO
}
export interface UpdateTaxConsultationStatusParams {
    taxConsultationId: TaxConsultationDTO['id']
    status: TaxConsultationStatusTypes
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTaxConsultations: builder.query<
            ResponseWithPagination<TaxConsultationSummaryDTO[]>,
            GetTaxConsultationsParams
        >({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.GET.LIST, undefined, {
                    queryParameters: getTaxConsultationQueryParams(options),
                }),
            }),
            providesTags: (result: ResponseWithPagination<TaxConsultationSummaryDTO[]> | undefined) =>
                result
                    ? [...result.data.map(({ id }) => ({ type: Tags.TaxConsultations, id })), Tags.TaxConsultations]
                    : [Tags.TaxConsultations],
            transformResponse: (response: TaxConsultationSummaryDTO[], meta: FetchBaseQueryMeta) =>
                mapPaginatedResponse(response, meta),
        }),
        getCustomerTaxConsultations: builder.query<TaxConsultationShortDTO[], GetCustomerTaxConsultationsParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.GET.CUSTOMER, { customerId }),
            }),
            providesTags: [Tags.TaxConsultations],
        }),
        getTaxConsultation: builder.query<TaxConsultationDTO, GetTaxConsultationParams>({
            query: ({ taxConsultationId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.GET.SINGLE, { taxConsultationId }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.TaxConsultations, id: arg.taxConsultationId }],
        }),
        getTaxConsultationsStatistics: builder.query<StatisticsDTO[], GetTaxConsultationsParams>({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.GET.STATISTICS, undefined, {
                    queryParameters: getTaxConsultationQueryParams(options),
                }),
            }),
            providesTags: [Tags.TaxConsultationsStatistics],
        }),
        getTaxConsultationAgreement: builder.query<UserAgreementDTO, GetTaxConsultationAgreementParams>({
            query: ({ taxConsultationId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.GET.AGREEMENT, { taxConsultationId }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.TaxConsultationUserAgreement, id: arg.taxConsultationId }],
        }),
        createTaxConsultation: builder.mutation<TaxConsultationSummaryDTO, CreateTaxConsultationParams>({
            query: ({ customerId }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.POST.CREATE, { customerId }),
                body: {
                    customerId,
                },
            }),
            invalidatesTags: (_result, _error, { customerId }) => [
                { type: Tags.TaxConsultations, customerId: customerId },
            ],
        }),
        deleteTaxConsultation: builder.mutation<void, DeleteTaxConsultationParams>({
            query: ({ taxConsultationId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.DELETE, { taxConsultationId }),
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.TaxConsultations, id: arg.taxConsultationId }],
        }),
        refundTaxConsultation: builder.mutation<TaxConsultationDTO, RefundTaxConsultationParams>({
            query: ({ taxConsultationId, data }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.POST.REFUND, { taxConsultationId }),
                body: data,
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.TaxConsultations, id: arg.taxConsultationId }],
        }),
        updateTaxConsultationStatus: builder.mutation<TaxConsultationDTO, UpdateTaxConsultationStatusParams>({
            query: ({ taxConsultationId, status }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.PUT.UPDATE_STATUS, { taxConsultationId, status }),
            }),
            invalidatesTags: (_result, _error, { taxConsultationId }) => [
                { type: Tags.TaxConsultations, id: taxConsultationId },
            ],
        }),
        // Summary
        getSummaryContent: builder.query<SummaryContentDTO, GetSummaryContentParams>({
            query: ({ taxConsultationId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.GET.SUMMARY_CONTENT, { taxConsultationId }),
            }),
            providesTags: (_result, _error, { taxConsultationId }) => [
                { type: Tags.TaxConsultationSummaryContent, id: taxConsultationId },
            ],
        }),
        updateSummaryContent: builder.mutation<SummaryContentDTO, UpdateSummaryContentParams>({
            query: ({ taxConsultationId, summaryContent }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATIONS.PUT.SUMMARY_CONTENT, { taxConsultationId }),
                body: {
                    summaryContent,
                },
            }),
            invalidatesTags: (_result, _error, { taxConsultationId }) => [
                { type: Tags.TaxConsultationSummaryContent, id: taxConsultationId },
            ],
        }),
    }),
})

export const {
    useGetSummaryContentQuery,
    useGetCustomerTaxConsultationsQuery,
    useGetTaxConsultationQuery,
    useUpdateSummaryContentMutation,
    useGetTaxConsultationsQuery,
    useGetTaxConsultationsStatisticsQuery,
    useCreateTaxConsultationMutation,
    useGetTaxConsultationAgreementQuery,
    useDeleteTaxConsultationMutation,
    useRefundTaxConsultationMutation,
    useUpdateTaxConsultationStatusMutation,
} = extendedApi
