import { colors } from '@scouts/ui'

import { AccountantMeetingStatus } from '@/models'

const statuses = {
    [AccountantMeetingStatus.Started]: {
        title: 'Not yet booked',
        color: colors.neutralLightest,
    },
    [AccountantMeetingStatus.Booked]: {
        title: AccountantMeetingStatus.Booked,
        color: colors.blueLighter,
    },
    [AccountantMeetingStatus.Completed]: {
        title: AccountantMeetingStatus.Completed,
        color: colors.mintLighter,
    },
    [AccountantMeetingStatus.Cancelled]: {
        title: AccountantMeetingStatus.Cancelled,
        color: colors.red,
    },
}

export const AccountantMeetingDomain = {
    statuses,
}
