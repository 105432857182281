import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Paragraph,
} from '@scouts/ui'

import { requestCustomerNotes, requestSelfAssessment, setSelfAssessmentUploadsCompleted } from '@/actions'
import { SelfAssessmentDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface SelfAssessmentMarkDocumentsUploadedModalProps {
    onClose: () => void
    selfAssessment: SelfAssessmentDTO
}

export const SelfAssessmentMarkDocumentsUploadedModal = ({
    onClose,
    selfAssessment,
}: SelfAssessmentMarkDocumentsUploadedModalProps) => {
    const dispatch = useAppDispatch()
    const { id: selfAssessmentId, customerId } = selfAssessment

    const handleSubmitSuccess = () => {
        dispatch(requestSelfAssessment(selfAssessmentId))
        dispatch(requestCustomerNotes(customerId))
    }

    const handleSubmit = () => {
        dispatch(setSelfAssessmentUploadsCompleted({ selfAssessmentId }, { onSuccess: handleSubmitSuccess }))
        onClose()
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Mark documents as uploaded</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <Paragraph>
                        By marking documents as uploaded, the client can progress to the next step, and submit their
                        Self Assessment to the accountant.
                    </Paragraph>
                    <Paragraph>
                        This is useful for customers who are struggling to progress through the funnel and for
                        non-tech-savvy customers who need a little extra help when they give us a call.
                    </Paragraph>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit">Mark as uploaded</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
