import PropTypes from 'prop-types'

import { SORT_ORDER_ASC, SORT_ORDER_DESC } from '../../constants'

export const SortOrderArrow = ({ sortOrder }) => {
    if (sortOrder === SORT_ORDER_ASC) return '↑'
    if (sortOrder === SORT_ORDER_DESC) return '↓'
    return null
}

SortOrderArrow.propTypes = {
    sortOrder: PropTypes.string.isRequired,
}
