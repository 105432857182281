import { colors, radius, Notice } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

export const SelfAssessmentNoticePayLaterPaid = ({ selfAssessment }: { selfAssessment: SelfAssessmentDTO }) => {
    const { hasPaid, payAfterFiling, paymentDateUTC } = selfAssessment

    if (!hasPaid || !payAfterFiling || !paymentDateUTC) return null

    return (
        <Notice background={colors.mintLighter} borderRadius={radius.medium}>
            Client chose “Pay later” option for this tax return (marked as paid since {formatDate(paymentDateUTC)})
        </Notice>
    )
}
