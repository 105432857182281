import { useState } from 'react'

import { useToggle } from '@scouts/helpers'
import { colors, DropdownSelect, DropdownSelectProps } from '@scouts/ui'

import {
    CompanyReturnStatus,
    CompanyReturnStatusDTO,
    CompanyReturnStatusTitles,
    CompanyReturnStatusTypes,
} from '@/models'
import { useUpdateCompanyReturnStatus } from '@/store/company-returns/hooks'

import { useGetCompanyReturnParams } from './hooks/use-get-params'

import { CompanyReturnStatusMessage } from './CompanyReturnStatusMessage'

interface CompanyReturnStatusDropdownProps {
    status: CompanyReturnStatusDTO
}

const statuses: CompanyReturnStatusTypes[] = [
    CompanyReturnStatus.Created,
    CompanyReturnStatus.Started,
    CompanyReturnStatus.UploadingDocuments,
    CompanyReturnStatus.Submitted,
    CompanyReturnStatus.AccountantReview,
    CompanyReturnStatus.InformationRequired,
    CompanyReturnStatus.AwaitingCustomerApproval,
    CompanyReturnStatus.ReadyToFile,
    CompanyReturnStatus.Filed,
    CompanyReturnStatus.Cancelled,
    CompanyReturnStatus.CompletedNotFiled,
]

const getColor = (status: CompanyReturnStatusDTO['current']) => {
    switch (status) {
        case CompanyReturnStatus.Submitted:
        case CompanyReturnStatus.AccountantReview:
        case CompanyReturnStatus.ReadyToFile:
            return colors.blueLighter
        case CompanyReturnStatus.InformationRequired:
        case CompanyReturnStatus.AwaitingCustomerApproval:
            return colors.yellow
        case CompanyReturnStatus.Filed:
        case CompanyReturnStatus.CompletedNotFiled:
            return colors.mintLighter
        case CompanyReturnStatus.Cancelled:
            return colors.redLighter
        default:
            return colors.neutralLightest
    }
}

export const CompanyReturnStatusDropdown = ({ status }: CompanyReturnStatusDropdownProps) => {
    const { current } = status
    const { customerId, companyId, companyReturnId, hasAllCompanyReturnParams } = useGetCompanyReturnParams()
    const { updateCompanyReturnStatus } = useUpdateCompanyReturnStatus()

    const [isMessageModalOpen, openMessageModal, closeMessageModal] = useToggle()

    const [updatedStatus, setUpdatedStatus] = useState<CompanyReturnStatusTypes | null>(null)

    const availableStatuses = statuses.filter((item) => item !== current)

    const options = [
        {
            value: current,
            title: CompanyReturnStatusTitles[current],
            color: getColor(current),
            disabled: true,
        },
        ...availableStatuses.map((statusOption) => ({
            value: statusOption,
            title: CompanyReturnStatusTitles[statusOption],
            color: getColor(statusOption),
        })),
    ]

    const handleChange: DropdownSelectProps['onChange'] = ({ value }) => {
        if (
            value === CompanyReturnStatus.InformationRequired ||
            value === CompanyReturnStatus.AwaitingCustomerApproval
        ) {
            setUpdatedStatus(value)
            openMessageModal()
        } else if (hasAllCompanyReturnParams)
            updateCompanyReturnStatus({
                customerId,
                companyId,
                companyReturnId,
                status: value as CompanyReturnStatusTypes,
            })
    }

    return (
        <>
            <DropdownSelect
                name="status"
                value={current}
                options={options}
                onChange={handleChange}
                width="100%"
                isSmall
            />

            {isMessageModalOpen && <CompanyReturnStatusMessage onClose={closeMessageModal} status={updatedStatus} />}
        </>
    )
}
