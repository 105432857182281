import {
    SELF_ASSESSMENT_SECTION_STATUS_QUESTIONS_COMPLETED,
    SELF_ASSESSMENT_SECTION_STATUS_UPLOADS_COMPLETED,
} from '../constants'

export const REQUEST_SELF_ASSESSMENTS = 'REQUEST_SELF_ASSESSMENTS'
export const RECEIVE_SELF_ASSESSMENTS = 'RECEIVE_SELF_ASSESSMENTS'
export const REQUEST_CUSTOMER_SELF_ASSESSMENTS = 'REQUEST_CUSTOMER_SELF_ASSESSMENTS'
export const RECEIVE_CUSTOMER_SELF_ASSESSMENTS = 'RECEIVE_CUSTOMER_SELF_ASSESSMENTS'
export const REQUEST_SELF_ASSESSMENT = 'REQUEST_SELF_ASSESSMENT'
export const RECEIVE_SELF_ASSESSMENT = 'RECEIVE_SELF_ASSESSMENT'
export const SAVE_SELF_ASSESSMENT_FINAL_RETURN = 'SAVE_SELF_ASSESSMENT_FINAL_RETURN'
export const SAVE_SELF_ASSESSMENT_STATUS = 'SAVE_SELF_ASSESSMENT_STATUS'
export const SAVE_SELF_ASSESSMENT = 'SAVE_SELF_ASSESSMENT'
export const UPDATE_SELF_ASSESSMENT = 'UPDATE_SELF_ASSESSMENT'
export const ADD_SELF_ASSESSMENT_PAYMENT = 'ADD_SELF_ASSESSMENT_PAYMENT'
export const ADD_SELF_ASSESSMENT_REFUND = 'ADD_SELF_ASSESSMENT_REFUND'
export const DELETE_SELF_ASSESSMENT = 'DELETE_SELF_ASSESSMENT'
export const REMOVE_SELF_ASSESSMENT = 'REMOVE_SELF_ASSESSMENT'
export const REMOVE_CUSTOMER_SELF_ASSESSMENTS = 'REMOVE_CUSTOMER_SELF_ASSESSMENTS'
export const DOWNLOAD_SELF_ASSESSMENT_FILES = 'DOWNLOAD_SELF_ASSESSMENT_FILES'
export const CREATE_SELF_ASSESSMENT = 'CREATE_SELF_ASSESSMENT'
export const MIGRATE_SELF_ASSESSMENTS = 'MIGRATE_SELF_ASSESSMENTS'
export const SAVE_SELF_ASSESSMENT_SECTION_STATUS = 'SAVE_SELF_ASSESSMENT_SECTION_STATUS'
export const SAVE_SELF_ASSESSMENT_REFILING_STATUS = 'SAVE_SELF_ASSESSMENT_REFILING_STATUS'

export const requestSelfAssessments = (data, meta = {}) => ({ type: REQUEST_SELF_ASSESSMENTS, data, meta })

export const receiveSelfAssessments = (data, meta) => ({ type: RECEIVE_SELF_ASSESSMENTS, data, meta })

export const requestCustomerSelfAssessments = (customerId) => ({
    type: REQUEST_CUSTOMER_SELF_ASSESSMENTS,
    data: { customerId },
})

export const receiveCustomerSelfAssessments = (data) => ({ type: RECEIVE_CUSTOMER_SELF_ASSESSMENTS, data })

export const requestSelfAssessment = (selfAssessmentId) => ({
    type: REQUEST_SELF_ASSESSMENT,
    data: { selfAssessmentId },
})

export const receiveSelfAssessment = (data) => ({ type: RECEIVE_SELF_ASSESSMENT, data })

export const saveSelfAssessmentFinalReturn = (selfAssessmentId, finalReturn) => ({
    type: SAVE_SELF_ASSESSMENT_FINAL_RETURN,
    data: { selfAssessmentId, finalReturn },
})

export const saveSelfAssessmentStatus = ({
    selfAssessmentId,
    status,
    onSuccess = null,
    onError = null,
    customMessage = null,
}) => ({
    type: SAVE_SELF_ASSESSMENT_STATUS,
    data: { selfAssessmentId, status, onSuccess, onError, customMessage },
})

export const saveSelfAssessmentRefilingStatus = (selfAssessmentId, refilingStatus) => ({
    type: SAVE_SELF_ASSESSMENT_REFILING_STATUS,
    data: { selfAssessmentId, refilingStatus },
})

export const saveSelfAssessment = (data) => ({ type: SAVE_SELF_ASSESSMENT, data })

export const updateSelfAssessment = (data) => ({ type: UPDATE_SELF_ASSESSMENT, data })

export const addSelfAssessmentPayment = ({ selfAssessmentId, onSuccess }) => ({
    type: ADD_SELF_ASSESSMENT_PAYMENT,
    data: { selfAssessmentId, onSuccess },
})

export const addSelfAssessmentRefund = (data) => ({ type: ADD_SELF_ASSESSMENT_REFUND, data })

export const deleteSelfAssessment = (data) => ({ type: DELETE_SELF_ASSESSMENT, data })

export const removeSelfAssessment = (data) => ({ type: REMOVE_SELF_ASSESSMENT, data })

export const removeCustomerSelfAssessments = ({ customerId }) => ({
    type: REMOVE_CUSTOMER_SELF_ASSESSMENTS,
    data: { customerId },
})

export const downloadSelfAssessmentFiles = (data) => ({ type: DOWNLOAD_SELF_ASSESSMENT_FILES, data })

export const createSelfAssessment = (data, options = {}) => ({ type: CREATE_SELF_ASSESSMENT, data, options })

export const migrateSelfAssessments = (data, options = {}) => ({ type: MIGRATE_SELF_ASSESSMENTS, data, options })

export const setSelfAssessmentQuestionsCompleted = ({ selfAssessmentId }, meta = {}) => ({
    type: SAVE_SELF_ASSESSMENT_SECTION_STATUS,
    data: {
        selfAssessmentId,
        sectionStatus: SELF_ASSESSMENT_SECTION_STATUS_QUESTIONS_COMPLETED,
    },
    meta,
})

export const setSelfAssessmentUploadsCompleted = ({ selfAssessmentId }, meta = {}) => ({
    type: SAVE_SELF_ASSESSMENT_SECTION_STATUS,
    data: {
        selfAssessmentId,
        sectionStatus: SELF_ASSESSMENT_SECTION_STATUS_UPLOADS_COMPLETED,
    },
    meta,
})
