import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, ButtonLink, ConfirmationModal, Container, Grid, GridItem, Label } from '@scouts/ui'

import { addSelfAssessmentPayment } from '@/actions'
import { REASONS_TITLES } from '@/constants'
import { formatDate } from '@/helpers'
import { CustomerDiscountDTO, PaymentStatus, SelfAssessmentDTO, SelfAssessmentStatus } from '@/models'
import { useAppDispatch } from '@/store'

import { useUser } from '@/hooks/use-user'

import { SelfAssessmentDetailsPrice } from './SelfAssessmentDetailsPrice'

interface SelfAssessmentDetailsProps {
    selfAssessment: SelfAssessmentDTO
    discount?: CustomerDiscountDTO
}

export const SelfAssessmentDetails = ({ selfAssessment, discount }: SelfAssessmentDetailsProps) => {
    const dispatch = useAppDispatch()
    const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useToggle()
    const { isAdminUser } = useUser()
    const {
        id: selfAssessmentId,
        hasPaid,
        payAfterFiling,
        status,
        paymentStatus,
        paymentDateUTC,
        revenueDateUTC,
        accountantFullName,
        filedDateUTC,
        firstTimeSelfAssessment,
        reasons,
        selfAssessmentReasonDescription,
        submittedDateUTC,
    } = selfAssessment

    const handleMarkAsPaid = () => {
        dispatch(addSelfAssessmentPayment({ selfAssessmentId, onSuccess: closeConfirmationModal }))
    }

    const canMarkPaid =
        !hasPaid &&
        payAfterFiling &&
        (status === SelfAssessmentStatus.Filed || status === SelfAssessmentStatus.FiledAwaitingRefund)

    const shouldDisplayPaidDate = (hasPaid || paymentStatus === PaymentStatus.Refunded) && paymentDateUTC

    return (
        <Container>
            <Grid gap="12px" tabletColumns={3} tabletGap="24px">
                <GridItem>
                    <FieldLabel>Paid</FieldLabel>
                    <Field>
                        {shouldDisplayPaidDate ? formatDate(paymentDateUTC) : 'Not paid'}{' '}
                        <SelfAssessmentDetailsPrice selfAssessment={selfAssessment} discount={discount} />
                    </Field>

                    {isAdminUser && revenueDateUTC && (
                        <>
                            <FieldLabel>Revenue recognised on</FieldLabel>
                            <Field>
                                <Label color={colors.black} background={colors.blueLighter}>
                                    {formatDate(revenueDateUTC)}
                                </Label>
                            </Field>
                        </>
                    )}

                    <Field>
                        {canMarkPaid && <ButtonLink onClick={openConfirmationModal}>Mark as paid</ButtonLink>}
                    </Field>
                </GridItem>

                <GridItem>
                    <FieldLabel>Submitted</FieldLabel>
                    <Field>{submittedDateUTC ? formatDate(submittedDateUTC) : '-'}</Field>
                </GridItem>

                <GridItem>
                    <FieldLabel>Filed</FieldLabel>
                    <Field>{filedDateUTC ? formatDate(filedDateUTC) : '-'}</Field>
                </GridItem>

                <GridItem>
                    <FieldLabel>First time Self Assessment</FieldLabel>
                    <Field>
                        {firstTimeSelfAssessment === true && 'Yes'}
                        {firstTimeSelfAssessment === false && 'No'}
                        {firstTimeSelfAssessment === null && '?'}
                    </Field>
                </GridItem>

                <GridItem>
                    <FieldLabel>Reasons</FieldLabel>
                    {(!reasons || reasons.length === 0) && !selfAssessmentReasonDescription && <Field>-</Field>}
                    {reasons && reasons.length > 0 && (
                        <Field>{reasons.map((reason) => REASONS_TITLES[reason]).join(', ')}</Field>
                    )}
                    {selfAssessmentReasonDescription && <Field>{selfAssessmentReasonDescription}</Field>}
                </GridItem>

                <GridItem>
                    {isAdminUser && accountantFullName && (
                        <>
                            <FieldLabel>Accountant</FieldLabel>
                            <Field>{accountantFullName}</Field>
                        </>
                    )}
                </GridItem>
            </Grid>

            {isConfirmationModalOpen && (
                <ConfirmationModal
                    title="Are you sure you want to mark this tax return as paid?"
                    onCancel={closeConfirmationModal}
                    onConfirm={handleMarkAsPaid}
                />
            )}
        </Container>
    )
}

const FieldLabel = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    line-height: 1.3;
    margin-bottom: 6px;
`

const Field = styled.div`
    font-size: ${font.small};
    line-height: 1.3;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`
