import { PAGINATION_DEFAULT_PAGE } from '@/constants'

import SearchFilterSupportUser from '@/components/search/SearchFilterSupportUser'

import { useFilters } from '../hooks'

export const TaxReliefClaimsSearchFilterSupportUserId = () => {
    const { applyFilter, activeFilters, removeFilter } = useFilters()

    return (
        <SearchFilterSupportUser
            isOpenByDefault={false}
            activeFilter={activeFilters?.supportUserId || 0}
            applyFilter={(updatedFilter) => applyFilter({ updatedFilter })}
            removeFilter={() => removeFilter({ updatedFilter: { supportUserId: null, page: PAGINATION_DEFAULT_PAGE } })}
        />
    )
}
