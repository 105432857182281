import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
    addErrorToast,
    colors,
    ActionButton,
    ButtonLink,
    Checkbox,
    Form,
    Input,
    InputAffix,
    InputOnChangeParams,
    Loadable,
    Page,
} from '@scouts/ui'

import { PATH_INTEGRATIONS_NYLAS } from '@/constants'
import {
    getRemainingDaysOfNylasWeek,
    validateNylasMeetingLink,
    validateNylasOpeningHours,
    NYLAS_WEEK_DAYS,
    NYLAS_WEEK_DAYS_TITLES,
} from '@/domain/nylas'
import {
    AccountantSchedulingPageDTO,
    AccountantSchedulingPageEditDTO,
    AccountantSchedulingPageOpeningHoursDTO,
} from '@/models'
import { useNylasSchedulingPages, useUpdateNylasSchedulingPage } from '@/store/nylas'

import ProfilePageWithSidebar from '../../ProfilePageWithSidebar'
import PageBackButton from '../../ui/PageBackButton'
import IntegrationsNylasCalendarSelect from './IntegrationsNylasCalendarSelect'

const OpeningHours = styled.div``

const OpeningHourRow = styled.div`
    background: ${colors.neutralLightest};
    margin-bottom: 2px;
    padding: 12px;
`

const OpeningHoursFooter = styled.div`
    text-align: right;
    margin-top: 6px;
`

const parseMinutesToHours = (minutes = 0) => parseFloat((minutes / 60).toFixed(2))
const parseHoursToMinutes = (hours = 0) => Math.floor(hours * 60)

const IntegrationsNylasEditSchedule = () => {
    const { isFetching, latestSchedulingPage, isLoading } = useNylasSchedulingPages()
    const { updateNylasSchedulingPage, isLoading: isUpdateLoading } = useUpdateNylasSchedulingPage()

    const [schedulingPage, setSchedulingPage] = useState<AccountantSchedulingPageDTO>()
    const [openingHours, setOpeningHours] = useState<AccountantSchedulingPageOpeningHoursDTO[]>([])
    const [minimumHoursBookingNotice, setMinimumHoursBookingNotice] = useState(0)

    const remainingDays = getRemainingDaysOfNylasWeek(openingHours)

    useEffect(() => {
        if (isFetching || !latestSchedulingPage) return
        setSchedulingPage({ ...latestSchedulingPage, location: '' })
        setOpeningHours(latestSchedulingPage?.openingHours ? cloneDeep(latestSchedulingPage.openingHours) : [])
        setMinimumHoursBookingNotice(parseMinutesToHours(latestSchedulingPage?.minimumMinutesBookingNotice))
    }, [latestSchedulingPage, isFetching])

    const handleMinimumHoursBookingNoticeChange = ({ value }: InputOnChangeParams) => {
        if (!schedulingPage) return

        const sanetizedValue = Number(value)
        setMinimumHoursBookingNotice(sanetizedValue)

        const updatedSchedulingPage = {
            ...schedulingPage,
            minimumMinutesBookingNotice: parseHoursToMinutes(sanetizedValue),
        }
        setSchedulingPage(updatedSchedulingPage)
    }

    const handleChange = ({ name, value }: InputOnChangeParams) => {
        if (!schedulingPage) return
        const updatedSchedulingPage = { ...schedulingPage, [name]: value }

        setSchedulingPage(updatedSchedulingPage)
    }

    const handleOpeningHours = (i: number, name: 'start' | 'end', value: string) => {
        const updatedOpeningHours = [...openingHours]
        updatedOpeningHours[i][name] = value

        setOpeningHours(updatedOpeningHours)
    }

    const handleOpeningHoursDays = (value: string, i: number) => {
        const updatedOpeningHours = [...openingHours]

        const index = updatedOpeningHours[i].days?.indexOf(value) ?? -1

        if (index > -1) {
            updatedOpeningHours[i].days?.splice(index, 1)
        } else {
            updatedOpeningHours[i].days?.push(value)
        }

        setOpeningHours(updatedOpeningHours)
    }

    const addOpeningHoursRow = () => {
        setOpeningHours([...openingHours, { days: [], start: '09:00', end: '17:00' }])
    }

    const removeOpeningHoursRow = (i: number) => {
        const updatedOpeningHours = [...openingHours]
        updatedOpeningHours.splice(i, 1)

        setOpeningHours(updatedOpeningHours)
    }

    const handleSave = () => {
        if (!schedulingPage) return
        const {
            calendarId,
            daysBookableInAdvance,
            id,
            location,
            minimumMinutesBookingNotice,
            minimumMinutesBufferBetweenBookings,
            minimumMinutesCancellationNotice,
        } = schedulingPage

        const sanitizedOpeningHours = openingHours.filter((row) => (row.days?.length ?? 0) > 0)

        if (!validateNylasMeetingLink(location ?? '')) {
            addErrorToast({
                body: 'Please make sure the video call meeting link is a valid Zoom, Microsoft Teams or Google Meet link',
            })

            return
        }

        if (!validateNylasOpeningHours(sanitizedOpeningHours)) {
            addErrorToast({ body: 'Please make sure the start time is before end time for your availability' })
            return
        }

        const updatedSchedule: AccountantSchedulingPageEditDTO = {
            calendarId,
            daysBookableInAdvance,
            location,
            minimumMinutesBookingNotice,
            minimumMinutesBufferBetweenBookings,
            minimumMinutesCancellationNotice,
            openingHours: sanitizedOpeningHours,
        }

        updateNylasSchedulingPage({ id, ...updatedSchedule })
    }

    const renderDaySelection = (days: string[], index: number) => (
        <Form.Row>
            <Form.Row.Title>
                Days of the week
                <Form.Row.Action>
                    {index > 0 && (
                        <ButtonLink onClick={() => removeOpeningHoursRow(index)} color={colors.red}>
                            Remove
                        </ButtonLink>
                    )}
                </Form.Row.Action>
            </Form.Row.Title>
            {NYLAS_WEEK_DAYS.map((day) => (
                <Form.InlineControl key={day}>
                    <Checkbox
                        id={`${day}${index}`}
                        name="day"
                        value={day}
                        checked={days.includes(day)}
                        onChange={({ value }) => handleOpeningHoursDays(value, index)}
                        label={NYLAS_WEEK_DAYS_TITLES[day]}
                    />
                </Form.InlineControl>
            ))}
        </Form.Row>
    )

    if (isLoading) {
        return <Loadable isLoading />
    }

    return (
        <ProfilePageWithSidebar>
            <PageBackButton to={PATH_INTEGRATIONS_NYLAS}>Back to scheduling</PageBackButton>

            <Page.Heading>Edit your schedule</Page.Heading>

            <Page.Section>
                <Form onSubmit={handleSave}>
                    <Form.Row>
                        <Form.Row.Title>Your scheduling link</Form.Row.Title>

                        <Form.Row.Content>
                            <Input
                                name="schedulingLink"
                                value={schedulingPage?.schedulingLink || ''}
                                onChange={() => {}}
                                disabled
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>
                            Video call meeting link (you can add a Zoom, Microsoft Teams or Google Meet link here)
                        </Form.Row.Title>
                        <Form.Row.Content>
                            <Input name="location" value={schedulingPage?.location || ''} onChange={handleChange} />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Calendar used for booking and availability</Form.Row.Title>
                        <Form.Row.Content>
                            {schedulingPage && (
                                <IntegrationsNylasCalendarSelect
                                    schedulingPageId={schedulingPage.id}
                                    calendarId={schedulingPage.calendarId ?? ''}
                                    onChange={handleChange}
                                />
                            )}
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Bookable for how many days into the future</Form.Row.Title>
                        <Form.Row.Content>
                            <InputAffix label="days">
                                <Input
                                    name="daysBookableInAdvance"
                                    value={schedulingPage?.daysBookableInAdvance ?? 0}
                                    onChange={handleChange}
                                    type="number"
                                    step="1"
                                    min="1"
                                    max="365"
                                    required
                                />
                            </InputAffix>
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Minimum booking notice before event</Form.Row.Title>
                        <Form.Row.Content>
                            <InputAffix label="hours">
                                <Input
                                    name="minimumHoursBookingNotice"
                                    value={minimumHoursBookingNotice}
                                    onChange={handleMinimumHoursBookingNoticeChange}
                                    type="number"
                                    step="0.25"
                                    min="0"
                                    required
                                />
                            </InputAffix>
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Minimum buffer between bookings</Form.Row.Title>
                        <Form.Row.Content>
                            <InputAffix label="minutes">
                                <Input
                                    name="minimumMinutesBufferBetweenBookings"
                                    value={schedulingPage?.minimumMinutesBufferBetweenBookings ?? 0}
                                    onChange={handleChange}
                                    type="number"
                                    step="1"
                                    min="0"
                                    required
                                />
                            </InputAffix>
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Minimum cancellation notice</Form.Row.Title>
                        <Form.Row.Content>
                            <InputAffix label="minutes">
                                <Input
                                    name="minimumMinutesCancellationNotice"
                                    value={schedulingPage?.minimumMinutesCancellationNotice ?? 0}
                                    onChange={handleChange}
                                    type="number"
                                    step="1"
                                    min="0"
                                    required
                                />
                            </InputAffix>
                        </Form.Row.Content>
                    </Form.Row>

                    <OpeningHours>
                        <Form.Row.Title>Availability</Form.Row.Title>
                        {(openingHours || []).map((row, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <OpeningHourRow key={`openingHours${index}`}>
                                {renderDaySelection(row?.days ?? [], index)}

                                <Form.Columns>
                                    <Form.Column width="49%">
                                        <Form.Row.Title>Start</Form.Row.Title>
                                        <Form.Row.Content>
                                            <Input
                                                type="time"
                                                name="start"
                                                value={row?.start || ''}
                                                onChange={({ value }) => handleOpeningHours(index, 'start', value)}
                                                required
                                            />
                                        </Form.Row.Content>
                                    </Form.Column>
                                    <Form.Column width="49%">
                                        <Form.Row.Title>End</Form.Row.Title>
                                        <Form.Row.Content>
                                            <Input
                                                type="time"
                                                name="end"
                                                value={row?.end || ''}
                                                onChange={({ value }) => handleOpeningHours(index, 'end', value)}
                                                required
                                            />
                                        </Form.Row.Content>
                                    </Form.Column>
                                </Form.Columns>
                            </OpeningHourRow>
                        ))}

                        <OpeningHoursFooter>
                            <ButtonLink icon="plus" onClick={addOpeningHoursRow} disabled={remainingDays.length === 0}>
                                Add
                            </ButtonLink>
                        </OpeningHoursFooter>
                    </OpeningHours>

                    <Form.Actions background={colors.blueLighter} alignRight>
                        <ActionButton isLoading={isUpdateLoading} type="submit">
                            Save schedule
                        </ActionButton>
                    </Form.Actions>
                </Form>
            </Page.Section>
        </ProfilePageWithSidebar>
    )
}

export default IntegrationsNylasEditSchedule
