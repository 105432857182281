import { InputOnChangeParams, Select } from '@scouts/ui'

import { useNylasCalendars } from '@/store/nylas'

const IntegrationsNylasCalendarSelect = ({
    calendarId,
    schedulingPageId,
    onChange,
}: {
    calendarId: string
    schedulingPageId: number
    onChange: (params: InputOnChangeParams) => void
}) => {
    const { calendars } = useNylasCalendars({ id: schedulingPageId })

    const options =
        calendars?.map((calendarItem) => ({
            value: calendarItem.id ?? undefined,
            title: calendarItem.name ?? undefined,
        })) ?? []

    return <Select name="calendarId" value={calendarId || ''} onChange={onChange} width="100%" options={options} />
}

export default IntegrationsNylasCalendarSelect
