import PropTypes from 'prop-types'

import { colors, Notice } from '@scouts/ui'

import {
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
} from '@/constants'
import { isSelfAssessmentStatusAtLeast } from '@/helpers'

export const SelfAssessmentTaxCode = ({ selfAssessment }) => {
    const { payThroughTaxCodeAllowed, payThroughTaxCodeRequested, status } = selfAssessment

    if (!payThroughTaxCodeAllowed) return null

    if (status === SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL)
        return (
            <Notice background={colors.blueLighter}>
                Looks like this client is eligible to pay through tax code. They can opt in on their approval screen.
            </Notice>
        )

    if (status === SELF_ASSESSMENT_STATUS_READY_TO_FILE) {
        if (payThroughTaxCodeRequested === null || payThroughTaxCodeRequested === undefined) return null

        if (payThroughTaxCodeRequested)
            return (
                <Notice background={colors.yellow}>
                    Client has <strong>opted in</strong> ✅ to paying through tax code. Please mark this on their tax
                    return.
                </Notice>
            )

        return (
            <Notice background={colors.yellow}>
                Client has <strong>opted out</strong> 🛑 from paying through tax code. Please mark this on their tax
                return.
            </Notice>
        )
    }

    if (isSelfAssessmentStatusAtLeast(status, SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED)) {
        if (payThroughTaxCodeRequested === null || payThroughTaxCodeRequested === undefined) return null

        if (payThroughTaxCodeRequested)
            return (
                <Notice background={colors.neutralLightest}>
                    Client has <strong>opted in</strong> ✅ to paying through tax code
                </Notice>
            )

        return (
            <Notice background={colors.neutralLightest}>
                Client has <strong>opted out</strong> 🛑 from paying through tax code
            </Notice>
        )
    }

    return null
}

SelfAssessmentTaxCode.propTypes = {
    selfAssessment: PropTypes.object.isRequired,
}
