import _find from 'lodash/find'

import { RefundReasonTypes } from '@/models'

import {
    REFUND_REASON_AGAINST,
    REFUND_REASON_CLIENT_MISTAKE,
    REFUND_REASON_CLIENT_UNRESPONSIVE,
    REFUND_REASON_COMMUNICATION,
    REFUND_REASON_CONFUSED,
    REFUND_REASON_FILED_BY_THEMSELVES,
    REFUND_REASON_ISSUES_WITH_HMRC,
    REFUND_REASON_MISTAKE,
    REFUND_REASON_OTHER,
    REFUND_REASON_REQUIRED,
    REFUND_REASON_TOO_COMPLEX,
    REFUND_REASON_UNABLE_PROVIDE_DOCUMENTS,
    REFUND_REASONS_TITLES,
} from '../constants'

const defaultOptions = [
    {
        value: REFUND_REASON_MISTAKE,
        title: REFUND_REASONS_TITLES[REFUND_REASON_MISTAKE],
    },
    {
        value: REFUND_REASON_COMMUNICATION,
        title: REFUND_REASONS_TITLES[REFUND_REASON_COMMUNICATION],
    },
    {
        value: REFUND_REASON_CLIENT_MISTAKE,
        title: REFUND_REASONS_TITLES[REFUND_REASON_CLIENT_MISTAKE],
    },
    {
        value: REFUND_REASON_CLIENT_UNRESPONSIVE,
        title: REFUND_REASONS_TITLES[REFUND_REASON_CLIENT_UNRESPONSIVE],
    },
    {
        value: REFUND_REASON_CONFUSED,
        title: REFUND_REASONS_TITLES[REFUND_REASON_CONFUSED],
    },
    {
        value: REFUND_REASON_AGAINST,
        title: REFUND_REASONS_TITLES[REFUND_REASON_AGAINST],
    },
    {
        value: REFUND_REASON_FILED_BY_THEMSELVES,
        title: REFUND_REASONS_TITLES[REFUND_REASON_FILED_BY_THEMSELVES],
    },
    {
        value: REFUND_REASON_ISSUES_WITH_HMRC,
        title: REFUND_REASONS_TITLES[REFUND_REASON_ISSUES_WITH_HMRC],
    },
    {
        value: REFUND_REASON_REQUIRED,
        title: REFUND_REASONS_TITLES[REFUND_REASON_REQUIRED],
    },
    {
        value: REFUND_REASON_TOO_COMPLEX,
        title: REFUND_REASONS_TITLES[REFUND_REASON_TOO_COMPLEX],
    },

    {
        value: REFUND_REASON_UNABLE_PROVIDE_DOCUMENTS,
        title: REFUND_REASONS_TITLES[REFUND_REASON_UNABLE_PROVIDE_DOCUMENTS],
    },
]

export const useRefundReasonsOptions = (refundReasonType: RefundReasonTypes | null) => {
    const isOptionListed = _find(defaultOptions, { value: refundReasonType })
    const isOptionValueOther = refundReasonType === REFUND_REASON_OTHER
    const shouldDisplayUnlistedOption = !isOptionListed && !isOptionValueOther && !!refundReasonType

    return [
        {
            value: '',
            title: 'Choose…',
        },
        ...defaultOptions,
        ...(shouldDisplayUnlistedOption
            ? [
                  {
                      value: refundReasonType,
                      title: REFUND_REASONS_TITLES[refundReasonType] || refundReasonType,
                  },
              ]
            : []),
        {
            value: REFUND_REASON_OTHER,
            title: REFUND_REASONS_TITLES[REFUND_REASON_OTHER],
        },
    ] as {
        value: RefundReasonTypes | ''
        title: 'Choose…' | (typeof REFUND_REASONS_TITLES)[RefundReasonTypes] | RefundReasonTypes
    }[]
}
