import { ReactNode } from 'react'
import styled from 'styled-components'

import { colors, font, radius, BadgeButton, Container } from '@scouts/ui'

interface BannerProps {
    background?: string
    children: ReactNode
    onClose?: () => void
}

export const Banner = ({ background, children, onClose }: BannerProps) => {
    const hasCloseButton = typeof onClose === 'function'

    return (
        <Container
            background={background || colors.redLighter}
            padding="18px 24px"
            tabletPadding="30px 36px"
            radius={radius.large}
            relative
        >
            {hasCloseButton && (
                <CloseWrapper>
                    <BadgeButton onClick={onClose} variant="inverted">
                        ✕
                    </BadgeButton>
                </CloseWrapper>
            )}
            <Body>{children}</Body>
        </Container>
    )
}

const Body = styled.div`
    p {
        font-size: ${font.small};
        line-height: 1.5;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
`

export const BannerHeading = styled.div`
    font-size: ${font.large};
    font-weight: ${font.weight.medium};
    margin-bottom: 18px;

    span[role='img'] {
        margin-right: 6px;
    }
`

const CloseWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
`
