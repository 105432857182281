import { lazy, Suspense } from 'react'

import { colors, AccountantPortalChrome, Loadable } from '@scouts/ui'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useTaxConsultationIdFromPath } from '../hooks/use-tax-consultation-id-from-path'

const TaxConsultationAgreement = lazy(() => import('./TaxConsultationAgreement'))

export const TaxConsultationAgreementContainer = () => {
    const customerId = useCustomerIdFromPath()
    const taxConsultationId = useTaxConsultationIdFromPath()

    return (
        <AccountantPortalChrome.Customer.Page.Container backgroundColor={colors.neutralLightest}>
            <Suspense fallback={<Loadable isLoading />}>
                <TaxConsultationAgreement taxConsultationId={taxConsultationId} customerId={customerId} />
            </Suspense>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
