import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useQueryParams } from '@scouts/helpers'
import { colors, ButtonLink } from '@scouts/ui'

import useIsMounted from '../../hooks/is-mounted'

import { createNote, deleteNote, requestEscalationNotes, saveNote } from '../../actions'
import { QUERY_PARAM_NOTE_CREATE, QUERY_PARAM_NOTE_EDIT } from '../../constants'
import {
    getHighPriorityEscalationNotesByEscalationId,
    getRegularEscalationNotesByEscalationId,
} from '../../selectors/notes'
import CustomerEscalationsModalNotesItem from './CustomerEscalationsModalNotesItem'
import CustomerNotesItemModal from './CustomerNotesItemModal'

const NotesWrapper = styled.div`
    padding: 24px 30px;
    background: ${({ background }) => background};

    &:not(:last-child) {
        margin-bottom: 18px;
    }
`

const NotesHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

const NotesTitle = styled.div``

const NotesList = styled.div``

const CustomerEscalationsModalNotesWrapper = styled.div`
    margin-top: 24px;
    background: ${({ background }) => background};

    & + & {
        margin-top: 32px;
    }
`

const CustomerEscalationsModalNotes = ({ customerId, escalationId }) => {
    const isMounted = useIsMounted()

    const highPriorityEscalationNotes = useSelector((state) =>
        getHighPriorityEscalationNotesByEscalationId(state, { escalationId })
    )
    const regularEscalationNotes = useSelector((state) =>
        getRegularEscalationNotesByEscalationId(state, { escalationId })
    )

    const history = useHistory()
    const { queryParams, updateQueryParams, removeQueryParams } = useQueryParams()
    const isNoteCreateModalOpen = queryParams.subview === QUERY_PARAM_NOTE_CREATE && isMounted
    const isNoteCreateModalPriorityInitially = queryParams.isPriority === 'true'

    const dispatch = useDispatch()
    const [editableNote, setEditableNote] = useState({})
    const isNoteEditModalOpen = queryParams.subview === QUERY_PARAM_NOTE_EDIT

    useEffect(() => {
        if (escalationId) {
            dispatch(requestEscalationNotes(escalationId))
        }
    }, [dispatch, escalationId])

    const hasNotes = highPriorityEscalationNotes.length > 0 || regularEscalationNotes.length > 0

    const closeNoteEditModal = () => {
        setEditableNote(null)
        const newLocation = removeQueryParams(['subview'])
        history.push(newLocation)
    }

    const openNoteEditModal = (note) => {
        setEditableNote(note)
        const newLocation = updateQueryParams({ subview: QUERY_PARAM_NOTE_EDIT })
        history.push(newLocation)
    }

    const handleDeleteNote = () => {
        if (!window.confirm('Are you sure?')) return

        dispatch(deleteNote({ noteId: editableNote.id }))

        closeNoteEditModal()
    }

    const handleSaveNote = (updatedNote) => {
        dispatch(saveNote(updatedNote))

        closeNoteEditModal()
    }

    const openNoteCreateModal = ({ isPriority } = {}) => {
        const newLocation = updateQueryParams({ subview: QUERY_PARAM_NOTE_CREATE, isPriority })
        history.push(newLocation)
    }

    const closeNoteCreateModal = () => {
        const newLocation = removeQueryParams(['subview', 'isPriority'])
        history.push(newLocation)
    }

    const handleCreateNote = (note) => {
        dispatch(createNote({ ...note, customerId }))
        closeNoteCreateModal()
    }

    return (
        <>
            {!hasNotes && (
                <NotesWrapper background={colors.blueLighter}>
                    <NotesHeader>
                        <NotesTitle>Notes and actions</NotesTitle>
                        <ButtonLink icon="plus" onClick={() => openNoteCreateModal()}>
                            Add notes
                        </ButtonLink>
                    </NotesHeader>
                </NotesWrapper>
            )}

            {hasNotes && (
                <NotesWrapper background={colors.yellow}>
                    <NotesHeader>
                        <NotesTitle>Notes to accountant</NotesTitle>
                        <ButtonLink icon="plus" onClick={() => openNoteCreateModal({ isPriority: true })}>
                            Add notes
                        </ButtonLink>
                    </NotesHeader>
                    <NotesList>
                        {highPriorityEscalationNotes.map((note) => (
                            <CustomerEscalationsModalNotesWrapper key={note.id}>
                                <CustomerEscalationsModalNotesItem note={note} openEditModal={openNoteEditModal} />
                            </CustomerEscalationsModalNotesWrapper>
                        ))}
                    </NotesList>
                </NotesWrapper>
            )}

            {hasNotes && (
                <NotesWrapper background={colors.redLighter}>
                    <NotesHeader>
                        <NotesTitle>Internal notes</NotesTitle>
                        <ButtonLink icon="plus" onClick={() => openNoteCreateModal()}>
                            Add notes
                        </ButtonLink>
                    </NotesHeader>
                    <NotesList>
                        {regularEscalationNotes.map((note) => (
                            <CustomerEscalationsModalNotesWrapper key={note.id}>
                                <CustomerEscalationsModalNotesItem note={note} openEditModal={openNoteEditModal} />
                            </CustomerEscalationsModalNotesWrapper>
                        ))}
                    </NotesList>
                </NotesWrapper>
            )}

            {isNoteEditModalOpen && (
                <CustomerNotesItemModal
                    customerId={customerId}
                    escalationId={escalationId}
                    note={editableNote}
                    handleSave={handleSaveNote}
                    handleDelete={handleDeleteNote}
                    handleCancel={closeNoteEditModal}
                />
            )}

            {isNoteCreateModalOpen && (
                <CustomerNotesItemModal
                    customerId={customerId}
                    escalationId={escalationId}
                    handleSave={handleCreateNote}
                    handleCancel={closeNoteCreateModal}
                    isPriorityInitially={isNoteCreateModalPriorityInitially}
                />
            )}
        </>
    )
}

CustomerEscalationsModalNotes.propTypes = {
    customerId: PropTypes.number.isRequired,
    escalationId: PropTypes.number,
}

CustomerEscalationsModalNotes.defaultProps = {
    escalationId: null,
}

export default CustomerEscalationsModalNotes
