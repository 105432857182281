import { useToggle } from '@scouts/helpers'
import {
    Button,
    ConfirmationModal,
    ModalActionsLegacy as ModalActions,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { saveSelfAssessment } from '@/actions'
import { SelfAssessmentDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface SelfAssessmentRevertPayLaterModalProps {
    selfAssessment: SelfAssessmentDTO
    onClose: () => void
}

export const SelfAssessmentRevertPayLaterModal = ({
    selfAssessment,
    onClose,
}: SelfAssessmentRevertPayLaterModalProps) => {
    const dispatch = useAppDispatch()
    const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useToggle()

    const handleRevert = () => {
        const newSelfAssessment = { ...selfAssessment, payAfterFiling: false }

        dispatch(saveSelfAssessment(newSelfAssessment))

        onClose()
    }

    return (
        <>
            <Modal onClose={onClose}>
                <ModalHeader>
                    <ModalTitle>Revert pay later</ModalTitle>
                </ModalHeader>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={onClose}>
                            Cancel
                        </Button>

                        <Button onClick={openConfirmationModal}>Revert to pay in advance</Button>
                    </ModalActions>
                </ModalFooter>
            </Modal>

            {isConfirmationModalOpen && (
                <ConfirmationModal onConfirm={handleRevert} onCancel={closeConfirmationModal}>
                    Are you sure you want to revert to pay in advance?
                </ConfirmationModal>
            )}
        </>
    )
}
