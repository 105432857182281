import { useCreateAccountantReviewMutation } from '../api'

export const useCreateAccountantReview = () => {
    const [createAccountantReview, { isLoading }] = useCreateAccountantReviewMutation()

    return {
        createAccountantReview,
        isLoading,
    }
}
