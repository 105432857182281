import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { createRef, PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { colors, shadows, ButtonLink, Form, Spinner } from '@scouts/ui'

import { requestCustomerNotes, resolveNote } from '../../actions'
import { isAdmin, shouldAllowEditingEscalationNote } from '../../helpers'
import { getCustomerNotes, isFetchingNotes, shouldFetchCustomerNotes } from '../../selectors'
import CustomerNotesListItem from './CustomerNotesListItem'

const POSITION_ABOVE_CURSOR = 'above'
const POSITION_BELOW_CURSOR = 'below'

const Container = styled.div`
    width: 400px;
    z-index: 1000;
    padding: 18px;
    position: absolute;
    right: -18px;
    ${({ position }) => position === POSITION_ABOVE_CURSOR && 'bottom: 0;'}
    ${({ position }) => position === POSITION_BELOW_CURSOR && 'top: 0;'}
`

const Content = styled.div`
    cursor: default;
    background: ${colors.redLighter};
    box-shadow: ${shadows.medium};
`

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 18px;
`

const NotesList = styled.div`
    padding: 18px;
    max-height: 380px;
    overflow: auto;
`
const NotesListItemWrapper = styled.div`
    & + & {
        margin-top: 32px;
    }
`

const Footer = styled.div`
    padding: 12px;
`

class CustomerNotesListPopup extends PureComponent {
    constructor(props) {
        super(props)

        this.popupElement = createRef()
        this.state = {
            popupPosition: POSITION_ABOVE_CURSOR,
        }

        _bindAll(this, ['fetchNotes', 'repositionPopup', 'handleResolveNote'])
    }

    componentDidMount() {
        this.fetchNotes()
        this.repositionPopup()
    }

    componentDidUpdate(prevProps) {
        const { isFetching: wasFetching } = prevProps
        const { isFetching } = this.props
        const isSpinnerReplacedWithContent = wasFetching && !isFetching

        if (isSpinnerReplacedWithContent) {
            this.repositionPopup()
        }
    }

    handleResolveNote(note) {
        const { dispatch } = this.props
        const updatedNote = { ...note, isResolved: !note.isResolved }

        dispatch(
            resolveNote(updatedNote, {
                onSuccess: () => {
                    const { onNotesUpdate, onClosePopup, unresolvedNotes } = this.props
                    onNotesUpdate()

                    if (!unresolvedNotes.length) {
                        onClosePopup()
                    }
                },
            })
        )
    }

    repositionPopup() {
        const { current: element } = this.popupElement
        if (element) {
            const coords = element.getBoundingClientRect()
            const isOutOfScreen = coords.y < 0
            if (isOutOfScreen) {
                this.setState({ popupPosition: POSITION_BELOW_CURSOR })
            }
        }
    }

    fetchNotes() {
        const { customerId, dispatch, shouldFetch } = this.props
        if (shouldFetch) {
            dispatch(requestCustomerNotes(customerId))
        }
    }

    renderNotes() {
        const { isAdminUser, unresolvedNotes } = this.props

        return (
            <NotesList>
                {unresolvedNotes.map((note) => {
                    const isNoteEditAllowed = shouldAllowEditingEscalationNote({ note, isAdminUser })
                    return (
                        <NotesListItemWrapper key={note.id}>
                            <CustomerNotesListItem
                                note={note}
                                handleResolve={isNoteEditAllowed ? this.handleResolveNote : null}
                            />
                        </NotesListItemWrapper>
                    )
                })}
            </NotesList>
        )
    }

    render() {
        const { isFetching, shouldFetch, onClosePopup, onOpenModal } = this.props
        const { popupPosition } = this.state

        const hasAnyNotes = !shouldFetch
        const shouldRenderList = hasAnyNotes && !isFetching

        return (
            <Container position={popupPosition} ref={this.popupElement} onMouseLeave={onClosePopup}>
                <Content>
                    {isFetching && (
                        <SpinnerWrapper>
                            <Spinner isSmall color={colors.black} />
                        </SpinnerWrapper>
                    )}
                    {shouldRenderList && (
                        <>
                            {this.renderNotes()}
                            <Footer>
                                <Form.Actions alignRight>
                                    <ButtonLink onClick={onOpenModal}>View all</ButtonLink>
                                </Form.Actions>
                            </Footer>
                        </>
                    )}
                </Content>
            </Container>
        )
    }
}

CustomerNotesListPopup.propTypes = {
    customerId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onClosePopup: PropTypes.func.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    onNotesUpdate: PropTypes.func.isRequired,
    shouldFetch: PropTypes.bool.isRequired,
    unresolvedNotes: PropTypes.array.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const { user } = state
    const { customerId } = ownProps
    return {
        isAdminUser: isAdmin(user),
        isFetching: isFetchingNotes(state),
        shouldFetch: shouldFetchCustomerNotes(state, ownProps),
        unresolvedNotes: getCustomerNotes(state, { customerId, shouldIgnoreResolved: true }),
    }
}

export default connect(mapStateToProps)(CustomerNotesListPopup)
