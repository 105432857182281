import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { MESSAGE_SMS_LENGTH_CAP, MESSAGE_TYPE_SMS, SELF_ASSESSMENT_STATUS_LIST_FOR_SMS } from '@/constants'
import { DraftMessage } from '@/models/draft-message'
import { getCustomerById, getCustomerSelfAssessments, getUser } from '@/selectors'
import { RootState } from '@/store'

export const useDraftMessageValidation = ({
    customerId,
    draftMessage,
}: {
    draftMessage: DraftMessage
    customerId: number
}) => {
    const { isAdminUser } = useSelector(getUser)
    const customer = useSelector((state) => getCustomerById(state, { customerId }))
    const customerSelfAssessments = useSelector((state: RootState) => getCustomerSelfAssessments(state, { customerId }))

    const canSendCustomerSms = customerSelfAssessments.some((selfAssessment) =>
        SELF_ASSESSMENT_STATUS_LIST_FOR_SMS.includes(selfAssessment?.status)
    )

    const { messageType: draftMessageType, content: draftMessageContent, files: draftMessageFiles } = draftMessage

    const isSms = draftMessageType === MESSAGE_TYPE_SMS
    const hasCustomerPhoneNumber = customer?.phoneNumber?.length > 3
    const hasExceededSmsCharacterLimit = draftMessageContent.length > MESSAGE_SMS_LENGTH_CAP

    const shouldDisplaySmsOption = isAdminUser || canSendCustomerSms
    const shouldDisplayPhoneNumberNotice = isSms && !hasCustomerPhoneNumber
    const shouldDisplaySmsCharacterLimit = isSms && hasCustomerPhoneNumber && hasExceededSmsCharacterLimit
    const shouldDisplaySmsCharactersRemaining = isSms && hasCustomerPhoneNumber && !hasExceededSmsCharacterLimit
    const shouldDisplayFiles = !isSms && draftMessageFiles.length > 0
    const isFileUploadDisabled = isSms

    const isSendDisabled = useMemo(() => {
        if (!draftMessageContent.trim()) return true
        if (isSms && shouldDisplayPhoneNumberNotice) return true
        if (isSms && hasExceededSmsCharacterLimit) return true
        return false
    }, [draftMessageContent, isSms, shouldDisplayPhoneNumberNotice, hasExceededSmsCharacterLimit])

    return {
        isFileUploadDisabled,
        isSendDisabled,
        isSms,
        shouldDisplayFiles,
        shouldDisplayPhoneNumberNotice,
        shouldDisplaySmsCharacterLimit,
        shouldDisplaySmsCharactersRemaining,
        shouldDisplaySmsOption,
    }
}
