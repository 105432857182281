import { useState } from 'react'

import { useToggle } from '@scouts/helpers'
import { colors, Button, ConfirmationModal, Container, DropdownButton, File, Flexer } from '@scouts/ui'

import { deleteFile, downloadFile } from '@/actions'
import { FileType, FileTypeTitles, SelfAssessmentFileDTO } from '@/models'
import { useAppDispatch } from '@/store'

import { SelfAssessmentUploadsFileModal } from './SelfAssessmentUploadsFileModal'

export const SelfAssessmentUploadsFile = ({ file }: { file: SelfAssessmentFileDTO }) => {
    const dispatch = useAppDispatch()
    const [fileUrl, setFileUrl] = useState<string | null>(null)
    const [isConfirmDeleteModalOpen, openConfirmDeleteModal, closeConfirmDeleteModal] = useToggle()

    const handleDownload = () => {
        dispatch(downloadFile({ id: file.id }))
    }

    const handleDelete = () => {
        dispatch(deleteFile({ file }))
    }

    const handleOpen = () => {
        dispatch(
            downloadFile({
                id: file.id,
                inlineContentDisposition: true,
                meta: {
                    onSuccess: (url: string) => {
                        setFileUrl(url)
                    },
                },
            })
        )
    }

    const fileBackground = file.fileType === FileType.FinalReturn ? colors.mintLighter : colors.blueLighter

    const isImage = !!file.contentType?.includes('image')

    return (
        <>
            <Flexer gap="9px">
                <div style={{ minWidth: 0 }}>
                    <File onClick={handleOpen} background={fileBackground}>
                        {file.fileName}
                    </File>
                </div>

                <DropdownButton
                    alignRight
                    actions={[
                        { title: 'Download', onClick: handleDownload },
                        { title: 'Preview', onClick: handleOpen },
                        { title: 'Delete', onClick: openConfirmDeleteModal, color: colors.red },
                    ]}
                />
            </Flexer>

            {fileUrl && (
                <SelfAssessmentUploadsFileModal
                    title={
                        <Flexer spaceBetween alignCenter>
                            <Container>
                                {FileTypeTitles[file.fileType]} - {file.fileName}
                            </Container>
                            <Button onClick={handleDownload} isSecondary>
                                Download
                            </Button>
                        </Flexer>
                    }
                    fileUrl={fileUrl}
                    onClose={() => setFileUrl(null)}
                    isImage={isImage}
                />
            )}

            {isConfirmDeleteModalOpen && (
                <ConfirmationModal
                    onConfirm={handleDelete}
                    onCancel={closeConfirmDeleteModal}
                    title="Are you sure you want to delete the file?"
                    labelConfirm="Delete"
                />
            )}
        </>
    )
}
