export const REFUND_REASON_AGAINST = 'DecidedAgainstUsingTaxScouts'
export const REFUND_REASON_CLIENT_MISTAKE = 'ClientMistake'
export const REFUND_REASON_CLIENT_UNRESPONSIVE = 'ClientUnresponsive'
export const REFUND_REASON_COMMUNICATION = 'AccountantUnresponsive'
export const REFUND_REASON_CONFUSED = 'ConfusedByPlatformOrService'
export const REFUND_REASON_FILED_BY_THEMSELVES = 'FiledByThemselves'
export const REFUND_REASON_ISSUES_WITH_HMRC = 'IssuesWithHmrc'
export const REFUND_REASON_MISTAKE = 'AccountantMistake'
export const REFUND_REASON_OTHER = 'Other'
export const REFUND_REASON_REQUIRED = 'RequiresServicesNotProvidedByTaxScouts'
export const REFUND_REASON_TOO_COMPLEX = 'TooComplex'
export const REFUND_REASON_UNABLE_PROVIDE_DOCUMENTS = 'UnableToProvideRequiredDocuments'

const REFUND_REASON_NOT_REQUIRED = 'ServicesNotRequired' // deprecated - keeping for search
const REFUND_REASON_NOT_MEET_EXPECTATION = 'ServiceDidNotMeetExpectations' // deprecated - keeping for search

export const REFUND_REASONS = [
    REFUND_REASON_MISTAKE,
    REFUND_REASON_COMMUNICATION,
    REFUND_REASON_CLIENT_MISTAKE,
    REFUND_REASON_CLIENT_UNRESPONSIVE,
    REFUND_REASON_CONFUSED,
    REFUND_REASON_AGAINST,
    REFUND_REASON_FILED_BY_THEMSELVES,
    REFUND_REASON_ISSUES_WITH_HMRC,
    REFUND_REASON_REQUIRED,
    REFUND_REASON_TOO_COMPLEX,
    REFUND_REASON_UNABLE_PROVIDE_DOCUMENTS,
    REFUND_REASON_NOT_MEET_EXPECTATION,
    REFUND_REASON_NOT_REQUIRED,
    REFUND_REASON_OTHER,
]

export const REFUND_REASONS_TITLES = {
    [REFUND_REASON_CLIENT_MISTAKE]: 'Client mistake',
    [REFUND_REASON_CLIENT_UNRESPONSIVE]: 'Client unresponsive',
    [REFUND_REASON_ISSUES_WITH_HMRC]: 'Issues with HMRC',
    [REFUND_REASON_TOO_COMPLEX]: 'Too complex',
    [REFUND_REASON_FILED_BY_THEMSELVES]: 'Filed by themselves',
    [REFUND_REASON_NOT_REQUIRED]: 'Does not require our services',
    [REFUND_REASON_COMMUNICATION]: 'Accountant unresponsive',
    [REFUND_REASON_REQUIRED]: "Requires services we don't provide",
    [REFUND_REASON_CONFUSED]: 'Confused by platform or service',
    [REFUND_REASON_MISTAKE]: 'Accountant mistake',
    [REFUND_REASON_AGAINST]: 'Decided against using us',
    [REFUND_REASON_OTHER]: 'Other',
    [REFUND_REASON_NOT_MEET_EXPECTATION]: 'Service did not meet expectations',
    [REFUND_REASON_UNABLE_PROVIDE_DOCUMENTS]: "Unable/doesn't want to upload documents",
} as const
