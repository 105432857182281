import FileSaver from 'file-saver'
import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import { receiveSearchResults, DOWNLOAD_SEARCH_RESULTS, REQUEST_SEARCH_RESULTS } from '../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE } from '../constants'
import { handleError } from '../handlers'
import { apiCall, getPaginationHeaders } from '../helpers'

export default function* searchSagas() {
    yield takeLatest(REQUEST_SEARCH_RESULTS, fetchSearchResults)
    yield takeLatest(DOWNLOAD_SEARCH_RESULTS, downloadSearchResults)
}

function* fetchSearchResults({ data }) {
    const filter = _get(data, ['filter'], {})
    const page = _get(data, ['page'], PAGINATION_DEFAULT_PAGE)
    const pageSize = _get(data, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE)

    const url = 'search'
    const body = { ...filter, page, pageSize }

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'post', body)
        const pagination = getPaginationHeaders(response.headers)
        yield put(receiveSearchResults(response.data, { pagination }))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* downloadSearchResults({ data }) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`search/export`, 'post', data)
        if (response.data) {
            const file = new Blob([response.data], {})
            FileSaver.saveAs(file, `search-results.csv`)
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
