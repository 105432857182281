import { useGetSummaryContentQuery } from '@/store/tax-consultations/api'

export const useGetTaxConsultationSummaryContent = (taxConsultationId: number) => {
    const { data, error, isLoading } = useGetSummaryContentQuery({ taxConsultationId })

    const summaryContent = data?.summaryContent ?? ''

    return {
        summaryContent,
        error,
        isLoading,
    }
}
