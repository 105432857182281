import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownInput } from '../ui/filter'

const RewardsFilterNameOrEmail = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter.length > 0

    const activeValues = [hasActiveFilter ? activeFilter : 'Any']

    const value = activeFilter

    const name = 'nameOrEmail'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Name or email"
            activeValues={activeValues}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownInput
                name={name}
                applyFilter={applyFilter}
                removeFilter={() => removeFilter(name)}
                value={value}
            />
        </FilterItem>
    )
}

RewardsFilterNameOrEmail.propTypes = {
    activeFilter: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
}

export default RewardsFilterNameOrEmail
