import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { TaxRegistrationStatus } from '@/models'

import {
    getQuickSearchActiveProductBundles,
    getQuickSearchActiveSelfAssessments,
    getQuickSearchActiveTaxConsultations,
    getQuickSearchCompletedSelfAssessments,
    getQuickSearchCompletedTaxConsultations,
} from '../../selectors/customer-services-quicksearch'
import CustomerServices from '../customer/customer-services/CustomerServices'

const Container = styled.div`
    padding: 9px 18px;
    border-left: 2px solid transparent;
`

const QuickSearchResultsServices = ({ customer }) => {
    const { id: customerId, taxRegistrationStatus, taxRegistrationHasPaid } = customer

    const activeProductBundles = useSelector((state) => getQuickSearchActiveProductBundles(state, { customerId }))

    const activeSelfAssessments = useSelector((state) => getQuickSearchActiveSelfAssessments(state, { customerId }))

    const completedSelfAssessments = useSelector((state) =>
        getQuickSearchCompletedSelfAssessments(state, { customerId })
    )

    // @TODO: use a hook
    const activeTaxRegistrations =
        !!taxRegistrationHasPaid &&
        taxRegistrationStatus !== TaxRegistrationStatus.Complete &&
        taxRegistrationStatus !== TaxRegistrationStatus.Cancelled
            ? [{ id: customerId, status: taxRegistrationStatus }]
            : []
    const completedTaxRegistrations =
        taxRegistrationHasPaid && taxRegistrationStatus === TaxRegistrationStatus.Complete
            ? [{ id: customerId, status: taxRegistrationStatus }]
            : []

    const activeTaxConsultations = useSelector((state) => getQuickSearchActiveTaxConsultations(state, { customerId }))
    const completedTaxConsultations = useSelector((state) =>
        getQuickSearchCompletedTaxConsultations(state, { customerId })
    )

    const activeCount =
        activeSelfAssessments.length +
        activeProductBundles.length +
        activeTaxConsultations.length +
        activeTaxRegistrations.length

    const completedCount =
        completedSelfAssessments.length + completedTaxConsultations.length + completedTaxRegistrations.length

    const hasActiveItems = activeCount > 0
    const hasCompletedItems = completedCount > 0

    if (!hasActiveItems && !hasCompletedItems) return null

    return (
        <Container>
            <CustomerServices
                activeProductBundles={activeProductBundles}
                activeSelfAssessments={activeSelfAssessments}
                activeTaxConsultations={activeTaxConsultations}
                activeTaxRegistrations={activeTaxRegistrations}
                completedSelfAssessments={completedSelfAssessments}
                completedTaxConsultations={completedTaxConsultations}
                completedTaxRegistrations={completedTaxRegistrations}
                customerId={customerId}
                shouldHideEmptySections
            />
        </Container>
    )
}

QuickSearchResultsServices.propTypes = {
    customer: PropTypes.object.isRequired,
}

export default QuickSearchResultsServices
