import { useMemo } from 'react'

import { useGetSupportUsersQuery } from '../api'

interface UseSupportUsersProps {
    activeFilter?: number
}

export const useSupportUsers = ({ activeFilter }: UseSupportUsersProps = {}) => {
    const { data, isError, isLoading } = useGetSupportUsersQuery()

    const activeSupportUsers = useMemo(() => {
        return data?.filter(({ isBlocked }) => !isBlocked) || []
    }, [data])

    const activeSupportUsersWithFullName = useMemo(() => {
        return activeSupportUsers.map(({ id, firstName, lastName }) => {
            const fullName = `${`${firstName || ''} ${lastName || ''}`.trim()}`
            return { value: id, title: fullName }
        })
    }, [activeSupportUsers])

    const activeSupportUsersForSearchRadios = useMemo(() => {
        return activeSupportUsers.map(({ id, firstName, lastName }) => ({
            value: id,
            label: `${firstName || ''} ${lastName || ''}`.trim(),
            checked: activeFilter === id,
        }))
    }, [activeFilter, activeSupportUsers])

    return {
        supportUsers: data,
        activeSupportUsers,
        activeSupportUsersWithFullName,
        activeSupportUsersForSearchRadios,
        isError,
        isLoading,
    }
}
