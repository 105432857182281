import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { colors, radius, Flexer, Page, Skeleton, SkeletonLine } from '@scouts/ui'

import { requestAccountant } from '@/actions'
import { PATH_USERS } from '@/constants'
import { getAccountantById } from '@/selectors'
import { useAppDispatch } from '@/store'
import { useAccountantReviews } from '@/store/accountant-reviews/hooks'

import PageBackButton from '@/components/ui/PageBackButton'

import { AddReview } from './AddReview'
import { UserReviewsList } from './UserReviewsList'

export const UserReviews = () => {
    const dispatch = useAppDispatch()

    const match = useRouteMatch<{ accountantId: string }>()
    const accountantId = Number(match?.params?.accountantId)

    const { orderedAccountantReviews, isLoading } = useAccountantReviews({ accountantId, skip: !accountantId })

    const activeAccountant = useSelector((state) => getAccountantById(state, { accountantId }))
    const accountantName = activeAccountant?.firstName || ''

    useEffect(() => {
        if (accountantId) {
            dispatch(requestAccountant(accountantId))
        }
    }, [accountantId, dispatch])

    return (
        <Page>
            <PageBackButton to={`${PATH_USERS}/${accountantId}`}>Back to profile</PageBackButton>
            <Flexer spaceBetween>
                <Page.Heading>{accountantName}'s reviews</Page.Heading>
                <AddReview accountantId={accountantId} />
            </Flexer>

            <Page.Section>
                {isLoading ? (
                    <Skeleton background={colors.white} padding="0">
                        <SkeletonLine height="119px" radius={radius.large} repeat={4} />
                    </Skeleton>
                ) : (
                    <UserReviewsList reviews={orderedAccountantReviews} />
                )}
            </Page.Section>
        </Page>
    )
}
