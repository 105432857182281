import _sumBy from 'lodash/sumBy'

import {
    DATA_SOURCE_PORTIFY,
    OWNERSHIP_OWNS,
    SELF_EMPLOYMENT_CATEGORY_CONSTRUCTION,
    SELF_EMPLOYMENT_EXPENSE_TITLES,
} from '@/constants'
import { formatCurrency, formatCurrencyNotSure, formatDate } from '@/helpers'
import { SelfAssessmentDTO, SelfEmploymentProfileDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

interface SelfEmploymentAllowances {
    selfAssessment: SelfAssessmentDTO
    selfEmploymentProfile: SelfEmploymentProfileDTO
}

export const SelfEmploymentAllowances = ({ selfAssessment, selfEmploymentProfile }: SelfEmploymentAllowances) => {
    const { selfEmploymentExpenses, selfEmployment, uberData } = selfAssessment

    const {
        approximateExpenses,
        businessMiles,
        businessMilesLogged,
        businessVehicle,
        businessVehicleExpenses,
        businessVehicleHasReceipts,
        businessVehicleMonthlyLeaseCosts,
        businessVehicleOwnership,
        businessVehiclePurchasePrice,
        businessVehiclePurchaseYear,
        businessVehicleType,
        businessVehicleUsingMileage,
        dataSource,
        expenseAmountConfirmed,
        hasExpenseDocumentation,
        hasNonDrivingExpenses,
        lowExpensesUseTradingAllowance,
        nonDrivingExpensesAmount,
        selfEmploymentCategory,
    } = selfEmployment || {}

    const selfEmployedStartDate = selfEmploymentProfile?.selfEmployedStartDate

    const isConstruction = selfEmploymentCategory === SELF_EMPLOYMENT_CATEGORY_CONSTRUCTION

    const hasSelfEmploymentExpenses = selfEmploymentExpenses && selfEmploymentExpenses.length > 0

    const getTotalSelfEmploymentExpenses = () => {
        if (approximateExpenses && approximateExpenses > 0) return approximateExpenses

        if (hasSelfEmploymentExpenses) {
            return _sumBy(selfEmploymentExpenses, 'amount')
        }

        return null
    }

    const totalSelfEmploymentExpenses = getTotalSelfEmploymentExpenses()

    return (
        <>
            <SectionData>
                <SectionDataRow
                    label={expenseAmountConfirmed ? 'Expenses' : 'Approximate expenses'}
                    value={formatCurrencyNotSure(totalSelfEmploymentExpenses)}
                    highlight
                    isVerified={dataSource === DATA_SOURCE_PORTIFY && expenseAmountConfirmed}
                />

                <SectionDataRow
                    label="Using data from Bookkeeping"
                    value={selfEmployment?.useBookkeepingDataAsSource}
                    isVerified={selfEmployment?.useBookkeepingDataAsSource}
                />

                {hasSelfEmploymentExpenses &&
                    selfEmploymentExpenses.map((expense) => (
                        <SectionDataRow
                            key={expense.id}
                            label={SELF_EMPLOYMENT_EXPENSE_TITLES[expense.expenseType]}
                            value={formatCurrencyNotSure(expense.amount)}
                        />
                    ))}

                {expenseAmountConfirmed && (
                    <>
                        {dataSource && <SectionDataRow label="Expenses synchronised from" value={dataSource} />}
                        <SectionDataRow label="Expense amount confirmed" value={expenseAmountConfirmed} boolean />
                    </>
                )}

                {!!totalSelfEmploymentExpenses &&
                    totalSelfEmploymentExpenses > 0 &&
                    hasExpenseDocumentation !== null && (
                        <SectionDataRow label="Has expense documentation" value={hasExpenseDocumentation} boolean />
                    )}
            </SectionData>

            <SectionData>
                <SectionDataRow
                    label="Use trading allowance"
                    value={lowExpensesUseTradingAllowance}
                    boolean
                    highlight
                />
            </SectionData>

            <SectionData>
                <SectionDataRow label="CIS" value={isConstruction} boolean highlight />
                {isConstruction && (
                    <>
                        <SectionDataRow
                            label="Registered for CIS"
                            value={selfEmployment?.cisRegisteredSubcontractor}
                            boolean
                        />
                        <SectionDataRow
                            label="Start of self-employment"
                            value={selfEmployedStartDate ? formatDate(selfEmployedStartDate) : '?'}
                        />
                        <SectionDataRow label="Has unpaid tax" value={selfEmployment?.cisHasUnpaidTax} boolean />
                    </>
                )}
            </SectionData>

            <SectionData>
                <SectionDataRow label="Used vehicle" value={businessVehicle} boolean highlight />
                {businessVehicle && (
                    <>
                        <SectionDataRow label="Type of vehicle" value={businessVehicleType || '?'} />
                        <SectionDataRow label="Logged miles" value={businessMilesLogged} boolean />
                        <SectionDataRow label="Miles driven" value={businessMiles} unit="miles" />
                        {uberData && (
                            <SectionDataRow
                                label="Mileage from Uber"
                                value={uberData.totalMileage}
                                unit="miles"
                                isVerified={businessVehicleUsingMileage}
                            />
                        )}
                        <SectionDataRow label="Using mileage" value={businessVehicleUsingMileage} boolean />
                        <SectionDataRow
                            label="Has business vehicle receipts"
                            value={businessVehicleHasReceipts}
                            boolean
                        />
                        <SectionDataRow label="Vehicle expenses" value={formatCurrency(businessVehicleExpenses)} />

                        <SectionDataRow label="Vehicle ownership" value={businessVehicleOwnership || '-'} />

                        {businessVehicleOwnership === OWNERSHIP_OWNS && (
                            <>
                                <SectionDataRow label="Purchase year" value={businessVehiclePurchaseYear} />
                                <SectionDataRow
                                    label="Purchase price"
                                    value={formatCurrency(businessVehiclePurchasePrice)}
                                />
                            </>
                        )}

                        {businessVehicleOwnership !== OWNERSHIP_OWNS && (
                            <SectionDataRow
                                label="Monthly vehicle lease / rent costs"
                                value={formatCurrency(businessVehicleMonthlyLeaseCosts)}
                            />
                        )}

                        <SectionDataRow label="Has non driving expenses" value={hasNonDrivingExpenses} boolean />

                        {hasNonDrivingExpenses && (
                            <SectionDataRow
                                label="Non driving expenses"
                                value={formatCurrency(nonDrivingExpensesAmount)}
                            />
                        )}
                    </>
                )}
            </SectionData>
        </>
    )
}
