import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, ButtonLink, ConfirmationModal, Label, Table } from '@scouts/ui'

import { PATH_CUSTOMERS, REFERRAL_DISCOUNT_NAME } from '@/constants'
import { CustomerDiscountDTO } from '@/models'

import { useUser } from '@/hooks/use-user'

interface CustomerDiscountsTableRowProps {
    discount: CustomerDiscountDTO
    handleDeleteDiscount: (id: CustomerDiscountDTO['id']) => void
}

export const CustomerDiscountsTableRow = ({ discount, handleDeleteDiscount }: CustomerDiscountsTableRowProps) => {
    const [isConfirmationModalOpen, open, close] = useToggle()
    const { isAdminUser } = useUser()
    const {
        customerId,
        customerReferralSource,
        discountSchemeDiscountCode,
        discountSchemeName,
        discountSchemePercentageDiscount,
        id,
        referredByCustomerFullName,
        referredByCustomerId,
        selfAssessmentId,
        taxConsultationId,
    } = discount

    const hasAppliedToSelfAssessment = !!selfAssessmentId
    const hasAppliedToTaxConsultation = !!taxConsultationId
    const hasNotApplied = !hasAppliedToSelfAssessment && !hasAppliedToTaxConsultation

    return (
        <>
            <Table.Body.Row key={id}>
                <Table.Body.Cell width="25%">
                    <Table.Body.Cell.Content>
                        <Row>{discountSchemeName}</Row>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>
                <Table.Body.Cell width="25%">
                    <Table.Body.Cell.Content>
                        <TableCellRow>
                            <Row>
                                {discountSchemeDiscountCode === REFERRAL_DISCOUNT_NAME
                                    ? customerReferralSource
                                    : discountSchemeDiscountCode}
                            </Row>
                        </TableCellRow>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>
                <Table.Body.Cell width="15%">
                    <Table.Body.Cell.Content>
                        <Row>{discountSchemePercentageDiscount}%</Row>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>
                <Table.Body.Cell width="15%">
                    {hasAppliedToSelfAssessment && (
                        <Table.Body.Cell.ContentLink
                            Link={Link}
                            to={`${PATH_CUSTOMERS}/${customerId}/assessment/${selfAssessmentId}`}
                        >
                            <Row>Tax return #{selfAssessmentId}</Row>
                        </Table.Body.Cell.ContentLink>
                    )}
                    {hasAppliedToTaxConsultation && (
                        <Table.Body.Cell.ContentLink
                            Link={Link}
                            to={`${PATH_CUSTOMERS}/${customerId}/consultations/${taxConsultationId}`}
                        >
                            <Row>Consultation #{taxConsultationId}</Row>
                        </Table.Body.Cell.ContentLink>
                    )}

                    {hasNotApplied && (
                        <Table.Body.Cell.Content>
                            <Row>
                                Unused&nbsp;&nbsp;
                                {isAdminUser && (
                                    <ButtonLink color={colors.red} onClick={open}>
                                        Delete
                                    </ButtonLink>
                                )}
                            </Row>
                        </Table.Body.Cell.Content>
                    )}
                </Table.Body.Cell>
                <Table.Body.Cell width="20%">
                    {referredByCustomerId && isAdminUser && (
                        <Table.Body.Cell.ContentLink Link={Link} to={`${PATH_CUSTOMERS}/${referredByCustomerId}`}>
                            <Row>{referredByCustomerFullName || referredByCustomerId}</Row>
                        </Table.Body.Cell.ContentLink>
                    )}
                    {referredByCustomerId && !isAdminUser && (
                        <Table.Body.Cell.Content>
                            <TableCellRow>
                                <Row>{referredByCustomerFullName || referredByCustomerId}</Row>
                            </TableCellRow>
                        </Table.Body.Cell.Content>
                    )}
                </Table.Body.Cell>
            </Table.Body.Row>

            {isConfirmationModalOpen && (
                <ConfirmationModal onCancel={close} onConfirm={() => handleDeleteDiscount(id)}>
                    Are you sure you want to remove discount{' '}
                    <Label background={colors.neutralLightest}>{discountSchemeName}</Label> from this customer?`
                </ConfirmationModal>
            )}
        </>
    )
}

const TableCellRow = styled.div`
    display: block;
`

const Row = styled.div`
    font-size: ${font.small};
    padding: 6px 0;
`
