import { useToggle } from '@scouts/helpers'
import { addSuccessToast, ButtonLink } from '@scouts/ui'

import { addIssue } from '@/actions'
import { ISSUE_TYPE_BANK_DETAILS_MISSING, SELF_ASSESSMENT_ISSUE_TYPE_TITLES } from '@/constants'
import { CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'

import SendMessagesContainer from '@/components/SendMessagesContainer'

import { BankDetailsRequestModal } from './BankDetailsRequestModal'

interface BankDetailsRequestProps {
    customer: CustomerDTO
    hasBankDetailsRequested: boolean
}

export const BankDetailsRequest = ({ customer, hasBankDetailsRequested }: BankDetailsRequestProps) => {
    const dispatch = useAppDispatch()
    const [isModalOpen, openModal, closeModal] = useToggle()
    const [isSendMessageModalOpen, openSendMessageModal] = useToggle()

    const { id: customerId, hasBankAccountDetails, fullName, phoneNumber } = customer

    if (hasBankAccountDetails) return null

    const handleRequestWithMessage = () => {
        closeModal()
        openSendMessageModal()
    }

    const handleRequestDetails = () => {
        dispatch(
            addIssue(
                {
                    customerId,
                    issueType: ISSUE_TYPE_BANK_DETAILS_MISSING,
                    customIssueText: SELF_ASSESSMENT_ISSUE_TYPE_TITLES[ISSUE_TYPE_BANK_DETAILS_MISSING],
                },
                {
                    onSuccess: () => {
                        addSuccessToast({ body: 'Bank details requested' })
                        closeModal()
                    },
                }
            )
        )
    }

    return (
        <>
            <ButtonLink disabled={hasBankDetailsRequested} isSmall onClick={openModal}>
                {hasBankDetailsRequested ? 'Requested' : 'Request'}
            </ButtonLink>

            {isModalOpen && (
                <BankDetailsRequestModal
                    onClose={closeModal}
                    requestDetails={handleRequestDetails}
                    requestWithMessage={handleRequestWithMessage}
                />
            )}

            {isSendMessageModalOpen && (
                <SendMessagesContainer
                    customers={[{ customerId: String(customerId), fullName, phoneNumber }]}
                    onSuccess={handleRequestDetails}
                    openByDefault={isSendMessageModalOpen}
                />
            )}
        </>
    )
}
