import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'

import { colors, Page } from '@scouts/ui'

import { PATH_INTEGRATIONS, PATH_PROFILE, PATH_TEMPLATES, PATH_USERS } from '../constants'
import { isAdmin } from '../helpers'

const Item = ({ children, to, pathname }) => (
    <Page.Sidebar.Item isActive={to === pathname}>
        <Page.Sidebar.Item.Link as={Link} to={to}>
            {children}
        </Page.Sidebar.Item.Link>
    </Page.Sidebar.Item>
)

Item.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
}

const ProfileSidebar = () => {
    const { pathname } = useLocation()
    const isAdminUser = isAdmin(useSelector((state) => state.user))

    return (
        <Page.SidebarColumn background={colors.white}>
            <Page.Sidebar>
                {isAdminUser && (
                    <>
                        <Item to={PATH_PROFILE} pathname={pathname}>
                            My profile
                        </Item>
                        <Item to={PATH_USERS} pathname={pathname}>
                            Users
                        </Item>
                        <Item to={PATH_TEMPLATES} pathname={pathname}>
                            Message templates
                        </Item>
                    </>
                )}
                {!isAdminUser && (
                    <>
                        <Item to={PATH_PROFILE} pathname={pathname}>
                            My profile
                        </Item>
                        <Item to={PATH_INTEGRATIONS} pathname={pathname}>
                            My integrations
                        </Item>
                        <Item to={PATH_TEMPLATES} pathname={pathname}>
                            Message templates
                        </Item>
                    </>
                )}
            </Page.Sidebar>
        </Page.SidebarColumn>
    )
}

export default ProfileSidebar
