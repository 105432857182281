import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import { receiveConversations, REQUEST_CUSTOMER_CONVERSATIONS } from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* conversationsSagas() {
    yield takeLatest(REQUEST_CUSTOMER_CONVERSATIONS, fetchCustomerConversations)
}

function* fetchCustomerConversations({ data }) {
    const { customerId } = data

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${customerId}/conversations`)
        yield put(receiveConversations(response.data, customerId))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
