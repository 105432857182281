import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Checkbox, IconCommentSlash, Table } from '@scouts/ui'

import { CustomerProductSearchDTO, CustomerProductSearchSelectedItem, TaxRegistrationStatus } from '@/models'

import { PATH_CUSTOMERS } from '../../constants'
import { formatDate } from '../../helpers'
import {
    getCustomerTableActiveProductBundles,
    getCustomerTableAllSelfAssessments,
    getCustomerTableAllTaxConsultations,
    getCustomerTableCompletedCount,
} from '../../selectors/customer-services-table'
import { getUser } from '../../selectors/user'
import { CustomerIndicators } from '../customer-indicators'
import NotesIndicator from '../NotesIndicator'
import { CompletedServicesIndicator } from './CompletedServicesIndicator'
import { CustomersTableServices } from './CustomersTableServices'

interface CustomersTableRowProps {
    customer: CustomerProductSearchDTO
    isSelected: boolean
    onSelect: (params: CustomerProductSearchSelectedItem) => void
    onTriggerTableUpdate: VoidFunction
}

export const CustomersTableRow = ({ customer, isSelected, onSelect, onTriggerTableUpdate }: CustomersTableRowProps) => {
    const { isAdminUser } = useSelector(getUser)

    const {
        accountantAssignedDateUTC,
        accountantFullName,
        customerAgentAuthorisationStatus,
        customerIdentityVerificationStatus,
        customerLatestPurchaseDateUTC,
        customerUniqueTaxpayerReference,
        email,
        fullName,
        id,
        priorityUnresolvedNotes,
        unresolvedNoteCount,
        unsubscribeFromBulkEmails,
        taxRegistrationStatus,
        taxRegistrationHasPaid,
    } = customer

    // Fake the state to make the selectors work
    const state = { customers: { data: [customer] } }

    const { activeSelfAssessments, cancelledSelfAssessments, completedSelfAssessments } =
        // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
        getCustomerTableAllSelfAssessments(state, { customerId: id })

    const activeAndCancelledSelfAssessments = activeSelfAssessments.concat(cancelledSelfAssessments)

    const { activeTaxConsultations, cancelledTaxConsultations, completedTaxConsultations } =
        // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
        getCustomerTableAllTaxConsultations(state, { customerId: id })

    const activeAndCancelledTaxConsultations = activeTaxConsultations.concat(cancelledTaxConsultations)

    // @TODO: use a hook
    const activeTaxRegistrations =
        !!taxRegistrationHasPaid &&
        taxRegistrationStatus !== TaxRegistrationStatus.Complete &&
        taxRegistrationStatus !== TaxRegistrationStatus.Cancelled
            ? [{ id, status: taxRegistrationStatus }]
            : []
    const completedTaxRegistrations =
        taxRegistrationHasPaid && taxRegistrationStatus === TaxRegistrationStatus.Complete
            ? [{ id, status: taxRegistrationStatus }]
            : []

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    const activeProductBundles = getCustomerTableActiveProductBundles(state, { customerId: id })

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    const completedServicesCount = getCustomerTableCompletedCount(state, { customerId: id })

    const customerUrl = `${PATH_CUSTOMERS}/${id}`

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <Customer>
                        <Name>{fullName}</Name>
                        <Email>{email}</Email>
                        {isAdminUser && accountantFullName && <AccountantName>{accountantFullName} </AccountantName>}
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="168px">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <CustomerIndicators
                        agentAuthorisationStatus={customerAgentAuthorisationStatus}
                        uniqueTaxpayerReference={customerUniqueTaxpayerReference}
                        identityVerificationStatus={customerIdentityVerificationStatus}
                        hideTaxRegistration
                        hidePayThroughTaxCodeRequested
                    />
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(60% - 600px)">
                <Table.Body.Cell.Content>
                    <CustomersTableServices
                        productBundles={activeProductBundles}
                        selfAssessments={activeAndCancelledSelfAssessments}
                        taxConsultations={activeAndCancelledTaxConsultations}
                        taxRegistrations={activeTaxRegistrations}
                        hideCancelledServices={!isAdminUser}
                        customerId={id}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="96px">
                <Table.Body.Cell.Content>
                    <CompletedServicesIndicator
                        numberOfCompletedServices={completedServicesCount}
                        selfAssessments={completedSelfAssessments}
                        taxConsultations={completedTaxConsultations}
                        taxRegistrations={completedTaxRegistrations}
                        customerId={id}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="120px">
                <Table.Body.Cell.Content>
                    <DateWrapper>{formatDate(accountantAssignedDateUTC)}</DateWrapper>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="120px">
                <Table.Body.Cell.Content>
                    <DateWrapper>{formatDate(customerLatestPurchaseDateUTC)}</DateWrapper>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="20%">
                <Table.Body.Cell.Content>
                    <NotesIndicator
                        notePreviews={priorityUnresolvedNotes}
                        numberOfNotes={unresolvedNoteCount || priorityUnresolvedNotes?.length || 0}
                        customerId={id}
                        customerFullName={fullName}
                        triggerTableUpdate={onTriggerTableUpdate}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="96px">
                <Table.Body.Cell.Content alignRight>
                    {unsubscribeFromBulkEmails && (
                        <IconUnsubscribed title="Unsubscribed from bulk emails">
                            <IconCommentSlash color={colors.neutralDarker} />
                        </IconUnsubscribed>
                    )}
                    <Checkbox
                        name={String(id) + fullName}
                        label="Select"
                        isLabelless
                        value={String(id)}
                        onChange={() =>
                            onSelect({
                                customerId: id,
                                fullName,
                                unsubscribeFromBulkEmails,
                            })
                        }
                        checked={!!isSelected}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Customer = styled.div``

const AccountantName = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    line-height: 1.3;
    display: flex;
    align-items: center;
    margin-top: 6px;
`

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
`

const DateWrapper = styled.div`
    font-size: ${font.small};
`

const IconUnsubscribed = styled.div`
    margin-right: 18px;
`
