import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Chip, Label, Table, Tooltip } from '@scouts/ui'

import {
    ESCALATIONS_PRODUCT_TYPE_OTHER,
    ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT,
    ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION,
    ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION,
    ESCALATIONS_REASON_TYPE_SHORT_TITLES,
    ESCALATIONS_TYPE_COMPLAINT,
    ESCALATIONS_TYPE_ESCALATION,
    ESCALATIONS_TYPE_TITLES,
    PATH_CUSTOMERS,
    PATH_HASH_ESCALATIONS,
    QUERY_PARAM_ESCALATION_EDIT,
} from '../../constants'
import { formatDate, formatDateGap, formatDateWithTime, formatTimeFromNow, getFirstName } from '../../helpers'
import { SupportAvatar } from '../ui/SupportAvatar'

const Customer = styled.div`
    overflow: hidden;
`

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 3px;
`

const Email = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    padding-top: 3px;
    padding-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const Date = styled.time`
    font-size: ${font.small};
    margin: -12px 0;
    padding: 12px 0;
`

const AccountantName = styled.div`
    font-size: ${font.small};
`

const ProductType = styled.div`
    text-align: center;
`

const SupportContainer = styled.div`
    display: flex;
    align-items: center;
`

const SupportName = styled.div`
    font-size: ${font.small};
`

const getBackgroundColorByType = (type) => {
    switch (type) {
        case ESCALATIONS_TYPE_ESCALATION:
            return colors.blueLighter
        case ESCALATIONS_TYPE_COMPLAINT:
            return colors.redLighter
        default:
            return colors.neutralLightest
    }
}

const EscalationsTableRow = ({ escalation }) => {
    const {
        accountantFullName,
        customerEmail,
        customerFullName,
        customerId,
        id,
        newAccountantFullName,
        occurredDateUTC,
        productType,
        reasonType,
        resolvedDateUTC,
        supportUserFullName = '',
        supportUserId,
        type,
    } = escalation

    const escalationModalLocation = {
        pathname: `${PATH_CUSTOMERS}/${customerId}`,
        search: queryString.stringify({ view: QUERY_PARAM_ESCALATION_EDIT, escalationId: id }),
        hash: PATH_HASH_ESCALATIONS,
    }

    const isClosed = !!resolvedDateUTC
    const status = isClosed ? 'Closed' : 'Open'

    const supportUserFirstName = getFirstName(supportUserFullName)

    const statusTooltip = isClosed
        ? `Closed in ${formatDateGap(occurredDateUTC, resolvedDateUTC)}`
        : `Open for ${formatTimeFromNow(occurredDateUTC, true)}`

    const renderProductType = () => {
        switch (true) {
            case productType === ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT:
                return (
                    <Tooltip content="Tax return">
                        <Chip background={colors.mintLighter} color={colors.black}>
                            SA
                        </Chip>
                    </Tooltip>
                )
            case productType === ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION:
                return (
                    <Tooltip content="Tax consultation">
                        <Chip background={colors.blueLighter} color={colors.black}>
                            TC
                        </Chip>
                    </Tooltip>
                )
            case productType === ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION:
                return (
                    <Tooltip content="Tax registration">
                        <Chip background={colors.yellow} color={colors.black}>
                            UTR
                        </Chip>
                    </Tooltip>
                )
            case productType === ESCALATIONS_PRODUCT_TYPE_OTHER:
                return (
                    <Chip background={colors.neutralLightest} color={colors.black}>
                        Other
                    </Chip>
                )
            default:
                return ''
        }
    }

    return (
        <Table.Body.Row key={id}>
            <Table.Body.Cell width="calc(24% - 96px)">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    <Customer>
                        <Name>{customerFullName}</Name>
                        <Email>{customerEmail}</Email>
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="96px">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    <ProductType>{renderProductType()}</ProductType>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    <Tooltip content={formatDateWithTime(occurredDateUTC)}>
                        <Date>{formatDate(occurredDateUTC)}</Date>
                    </Tooltip>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    {newAccountantFullName ? (
                        <Tooltip content={`${accountantFullName}\nReassigned to ${newAccountantFullName}`}>
                            <AccountantName>{accountantFullName}</AccountantName>
                        </Tooltip>
                    ) : (
                        <AccountantName>{accountantFullName}</AccountantName>
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="11%">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    <Label background={getBackgroundColorByType(type)}>{ESCALATIONS_TYPE_TITLES[type]}</Label>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="calc(14% - 12px)">
                {reasonType && (
                    <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                        <Label background={colors.neutralLightest} hasEllipsis>
                            {ESCALATIONS_REASON_TYPE_SHORT_TITLES[reasonType] || reasonType}
                        </Label>
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>
            <Table.Body.Cell width="12px">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    &nbsp;
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    <Tooltip content={supportUserFullName}>
                        <SupportContainer>
                            <SupportAvatar supportUserId={supportUserId} />
                            <SupportName>{supportUserFirstName}</SupportName>
                        </SupportContainer>
                    </Tooltip>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="6%">
                <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                    <Tooltip content={statusTooltip}>
                        <Label background={isClosed ? colors.mintLighter : colors.neutralLightest}>{status}</Label>
                    </Tooltip>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                {resolvedDateUTC && (
                    <Table.Body.Cell.ContentLink Link={Link} to={escalationModalLocation}>
                        <Tooltip content={formatDateWithTime(resolvedDateUTC)}>
                            <Date>{formatDate(resolvedDateUTC)}</Date>
                        </Tooltip>
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

EscalationsTableRow.propTypes = {
    escalation: PropTypes.object.isRequired,
}

export default EscalationsTableRow
