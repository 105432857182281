import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, DatePicker, Form } from '@scouts/ui'

const Actions = styled.div`
    display: flex;
    margin-top: 12px;
`

const Action = styled.div`
    margin-right: 6px;
`

const Fields = styled.div``

const Field = styled.div`
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const propTypes = {
    applyFilter: PropTypes.func.isRequired,
    closeDropdown: PropTypes.func,
    dateFrom: PropTypes.string.isRequired,
    dateFromName: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    dateToName: PropTypes.string.isRequired,
    removeFilter: PropTypes.func.isRequired,
}

const defaultProps = {
    closeDropdown: null,
}

const FilterItemDropdownDateRange = (props) => {
    const { dateFromName, dateToName, dateFrom, dateTo, applyFilter, removeFilter, closeDropdown } = props

    const [localDateFrom, setLocalDateFrom] = useState('')
    const [localDateTo, setLocalDateTo] = useState('')

    useEffect(() => {
        setLocalDateFrom(dateFrom)
        setLocalDateTo(dateTo)
    }, [dateFrom, dateTo])

    const handleSubmit = () => {
        const filter = {
            [dateFromName]: localDateFrom,
            [dateToName]: localDateTo,
        }

        applyFilter(filter)

        if (closeDropdown) closeDropdown()
    }

    const handleChange = (date, set) => {
        if (date) {
            set(moment(date).format('YYYY-MM-DD'))
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Fields>
                <Field>
                    <DatePicker
                        isSecondary
                        name="dateFrom"
                        value={localDateFrom}
                        onChange={({ value }) => handleChange(value, setLocalDateFrom)}
                    />
                </Field>

                <Field>
                    <DatePicker
                        isSecondary
                        name="dateTo"
                        value={localDateTo}
                        onChange={({ value }) => handleChange(value, setLocalDateTo)}
                    />
                </Field>
            </Fields>

            <Actions>
                <Action>
                    <Button type="submit" size="small">
                        Apply
                    </Button>
                </Action>
                <Action>
                    <Button isSecondary size="small" onClick={removeFilter}>
                        Remove
                    </Button>
                </Action>
            </Actions>
        </Form>
    )
}

FilterItemDropdownDateRange.propTypes = propTypes

FilterItemDropdownDateRange.defaultProps = defaultProps

export default FilterItemDropdownDateRange
