import { useMemo } from 'react'

import { CompanyReturnFileDTO } from '@/models'

import { useGetCompanyReturnFilesQuery, GetCompanyReturnFilesParams } from '../api'

type CompanyFilesGroupedByType = Partial<Record<CompanyReturnFileDTO['fileType'], CompanyReturnFileDTO[]>>

export const useCompanyReturnFiles = ({ companyReturnId, customerId, companyId }: GetCompanyReturnFilesParams) => {
    const { data, isError, isLoading } = useGetCompanyReturnFilesQuery({ companyReturnId, customerId, companyId })

    const companyReturnFilesByType = useMemo(() => {
        const filteredArrays: CompanyFilesGroupedByType = {}
        data?.forEach((item) => {
            if (!filteredArrays[item.fileType]) {
                filteredArrays[item.fileType] = [item]
            } else {
                filteredArrays[item.fileType]?.push(item)
            }
        })

        return filteredArrays
    }, [data])

    return {
        companyReturnFiles: data,
        hasCompanyReturnFiles: !!data?.length,
        companyReturnFilesByType,
        isError,
        isLoading,
    }
}
