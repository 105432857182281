import { lazy, Suspense } from 'react'

import { colors, AccountantPortalChrome, Loadable } from '@scouts/ui'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useCompanyIdFromPath } from '../hooks/use-company-id-from-path'
import { useCompanyReturnIdFromPath } from '../hooks/use-company-return-id-from-path'

const CompanyReturnAgreement = lazy(() => import('./CompanyReturnAgreement'))

export const CompanyReturnAgreementContainer = () => {
    const customerId = useCustomerIdFromPath()
    const companyId = useCompanyIdFromPath()
    const companyReturnId = useCompanyReturnIdFromPath()

    if (!companyId || !companyReturnId) return null

    return (
        <AccountantPortalChrome.Customer.Page.Container backgroundColor={colors.neutralLightest}>
            <Suspense fallback={<Loadable isLoading />}>
                <CompanyReturnAgreement
                    customerId={customerId}
                    companyId={companyId}
                    companyReturnId={companyReturnId}
                />
            </Suspense>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
