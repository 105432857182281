import { isRejectedWithValue, AnyAction, Middleware } from '@reduxjs/toolkit'

import { triggerLogout } from '@scouts/auth/src/helpers/trigger-logout'

import { apiSlice } from '@/store/api-slice'

import { reportRejectedApiCallToSentry } from '../sentry/report-query-to-sentry'
import { renderErrorToasts } from '../toaster/render-error-toasts'
import { ErrorHandlerOptions } from '../types'

const logoutStatusCodes = [401, 403]

/**
 * Check if the action is from inside of the main app, by the api-slice.
 * Other slices may dispatch actions with different structure, thus Bookkeeping API and Inbox API should be ignored for now.
 *
 * For example, in Bookkeeping:
 * * Responses don't have ModelState. Error response structure may vary.
 * * Axios is used. But our custom middleware (currently) only supports FetchBaseQuery and its structure.
 */
const getIsActionFromApiSlice = (action: AnyAction) => {
    const actionType = (action?.type || '') as string

    return actionType.startsWith(apiSlice.reducerPath)
}

const getShouldHandleRejectedAction = (action: AnyAction) => {
    return getIsActionFromApiSlice(action) && isRejectedWithValue(action)
}

const rtkQueryErrorHandlingMiddleware: Middleware = () => (next) => (action: AnyAction) => {
    if (getShouldHandleRejectedAction(action)) {
        const { hideAlert, customMessage }: ErrorHandlerOptions = action?.payload?.errorHandlerOptions || {}

        if (hideAlert !== true) {
            renderErrorToasts(action.payload, { customMessage })
        }
    }

    return next(action)
}

const rtkQueryLogoutMiddleware: Middleware = () => (next) => (action) => {
    if (getShouldHandleRejectedAction(action)) {
        const { meta } = action
        const { response } = meta?.baseQueryMeta || {}
        const statusCode = response?.status // 400
        const isCancelledApiCall = meta?.aborted

        const shouldLogOut = !isCancelledApiCall && statusCode && logoutStatusCodes.includes(statusCode)
        if (shouldLogOut) {
            triggerLogout()

            // Prevent more actions from being dispatched by avoiding `next(action)`,
            // and log the user out without bombarding our server
            return { logout: true } // TODO: Who receives this return value?
        }
    }

    return next(action)
}

const rtkQuerySentryMiddleware: Middleware = () => (next) => (action) => {
    if (getShouldHandleRejectedAction(action)) {
        reportRejectedApiCallToSentry(action)
    }

    return next(action)
}

export const CustomMiddleware = {
    ErrorToaster: rtkQueryErrorHandlingMiddleware,
    LogoutDetector: rtkQueryLogoutMiddleware,
    SentryReporter: rtkQuerySentryMiddleware,
}
