import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Label } from '@scouts/ui'

import { PATH_CUSTOMERS, REFERRAL_DISCOUNT_NAME, REFUND_REASONS_TITLES } from '@/constants'
import { formatCurrency, formatDate } from '@/helpers'
import { CustomerDiscountDTO, PaymentStatus, SelfAssessmentDTO } from '@/models'
import { getIsSelfAssessmentPartOfProductBundle } from '@/selectors'

import { useUser } from '@/hooks/use-user'

interface SelfAssessmentDetailsPriceProps {
    selfAssessment: SelfAssessmentDTO
    discount?: CustomerDiscountDTO
}

export const SelfAssessmentDetailsPrice = ({ selfAssessment, discount }: SelfAssessmentDetailsPriceProps) => {
    const { isAdminUser } = useUser()
    const { id: selfAssessmentId, paymentStatus, price, refundReason, refundDateUTC, hasPaid } = selfAssessment

    const {
        refundReasonDescription = null,
        refundReasonType = null,
        supportAccountantFullName = null,
    } = refundReason || {}

    const {
        discountSchemeDiscountCode = '',
        customerReferralSource = '',
        discountSchemePercentageDiscount = '',
        referredByCustomerId = '',
        referredByCustomerFullName = '',
    } = discount || {}

    const isPartOfProductBundle = useSelector((state) =>
        getIsSelfAssessmentPartOfProductBundle(state, { selfAssessmentId })
    )

    if (paymentStatus === PaymentStatus.Refunded)
        return (
            <>
                <Label background={colors.red}>Refunded</Label>
                {price === 0 && (
                    <>
                        {' '}
                        <Label background={colors.mintLighter}>Free</Label>
                        {isPartOfProductBundle && <FieldDescription>Bundled</FieldDescription>}
                    </>
                )}
                {!!refundReasonType && (
                    <FieldDescription>
                        <strong>{REFUND_REASONS_TITLES[refundReasonType]}</strong>
                    </FieldDescription>
                )}
                {!!refundReasonDescription && <FieldDescription>{refundReasonDescription}</FieldDescription>}
                {!!refundDateUTC && <FieldDescription>Refunded on {formatDate(refundDateUTC)}</FieldDescription>}
                {!!supportAccountantFullName && (
                    <FieldDescription>Refunded by {supportAccountantFullName}</FieldDescription>
                )}
            </>
        )

    if (hasPaid && price === 0)
        return (
            <>
                <Label background={colors.mintLighter}>Free</Label>
                {isPartOfProductBundle && <FieldDescription>Bundled</FieldDescription>}
            </>
        )

    return (
        <>
            <Label background={colors.neutralLightest}>{formatCurrency(price)}</Label>
            {discount && (
                <>
                    <FieldDescription>
                        Used{' '}
                        <strong>
                            {discountSchemeDiscountCode === REFERRAL_DISCOUNT_NAME
                                ? customerReferralSource
                                : discountSchemeDiscountCode}
                        </strong>{' '}
                        for {discountSchemePercentageDiscount}% discount
                    </FieldDescription>
                    {referredByCustomerFullName && referredByCustomerId && (
                        <FieldDescription>
                            Referred by{' '}
                            {isAdminUser && (
                                <Link to={`${PATH_CUSTOMERS}/${referredByCustomerId}`}>
                                    {referredByCustomerFullName}
                                </Link>
                            )}
                            {!isAdminUser && <>{referredByCustomerFullName}</>}
                        </FieldDescription>
                    )}
                </>
            )}
        </>
    )
}

const FieldDescription = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
    margin-top: 6px;
    padding-right: 18px;
`
