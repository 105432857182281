import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    TextArea,
} from '@scouts/ui'

import { createNote, saveSelfAssessmentStatus } from '../actions'
import { SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED, TAX_YEARS } from '../constants'

class SelfAssessmentStatusCompletedNote extends Component {
    constructor() {
        super()

        this.state = { noteText: '' }

        _bindAll(this, ['handleCancel', 'handleSend', 'handleChange'])
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value })
    }

    handleCancel() {
        const { closeModal } = this.props

        closeModal()
    }

    handleSend() {
        const { closeModal } = this.props
        const { noteText } = this.state

        this.save(noteText)

        closeModal()
    }

    save(noteText = null) {
        const { dispatch, selfAssessment } = this.props
        const { id: selfAssessmentId, customerId } = selfAssessment

        dispatch(
            saveSelfAssessmentStatus({
                selfAssessmentId,
                status: SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
            })
        )

        dispatch(createNote({ noteText, customerId, selfAssessmentId }))
    }

    render() {
        const { noteText } = this.state
        const { customerName, selfAssessment } = this.props
        const { taxYear } = selfAssessment

        const hasAddedNote = noteText.length > 0

        return (
            <Modal onClose={this.handleCancel}>
                <Form onSubmit={this.handleSend}>
                    <ModalHeader>
                        <ModalTitle>
                            Set {customerName}'s tax return for {TAX_YEARS[taxYear]} as 'Completed, not filed'
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Form.Row.Title>Please provide details and correspondence from the client</Form.Row.Title>
                            <Form.Row.Content>
                                <TextArea
                                    name="noteText"
                                    value={noteText}
                                    placeholder="Notes…"
                                    onChange={this.handleChange}
                                    height="144px"
                                />
                            </Form.Row.Content>
                        </Form.Row>
                    </ModalBody>
                    <ModalFooter>
                        <ModalActions>
                            <Button onClick={this.handleCancel} isSecondary>
                                Cancel
                            </Button>
                            <Button type="submit" disabled={!hasAddedNote}>
                                Set as 'Completed, not filed'
                            </Button>
                        </ModalActions>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

SelfAssessmentStatusCompletedNote.propTypes = {
    closeModal: PropTypes.func.isRequired,
    customerName: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    selfAssessment: PropTypes.object.isRequired,
}

export default connect()(SelfAssessmentStatusCompletedNote)
