import { updateOrAddItemsById } from '@/helpers'

import {
    RECEIVE_CUSTOMER,
    RECEIVE_CUSTOMERS,
    RECEIVE_RETURNING_CUSTOMERS,
    REMOVE_CUSTOMER,
    REQUEST_CUSTOMERS,
} from '../actions'

const defaultStatePagination = {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
}

const defaultState = {
    isFetching: false,
    shouldFetch: true,
    data: [],
    details: [],
    pagination: defaultStatePagination,
}

export default (state = defaultState, action) => {
    const { type, data, meta } = action

    switch (type) {
        case REQUEST_CUSTOMERS: {
            return { ...state, isFetching: true }
        }

        case RECEIVE_CUSTOMERS: {
            const items = [...data]
            const pagination = meta?.pagination || defaultStatePagination

            return { ...state, isFetching: false, shouldFetch: false, data: Object.assign([], items), pagination }
        }

        case RECEIVE_RETURNING_CUSTOMERS: {
            const items = [...data]
            const pagination = meta?.pagination || defaultStatePagination

            return { ...state, isFetching: false, shouldFetch: false, data: Object.assign([], items), pagination }
        }

        case RECEIVE_CUSTOMER: {
            const id = data?.id

            if (!id) return { ...state, isFetching: false }

            const items = updateOrAddItemsById({ currentItems: state.data, receivedItems: [data] })

            return { ...state, isFetching: false, data: Object.assign([], items) }
        }

        case REMOVE_CUSTOMER: {
            const updatedDetails = [...state.details]
            const detailsKey = updatedDetails.findIndex((o) => o.id === data.id)

            delete updatedDetails[detailsKey]

            const updatedList = [...state.list]
            const listKey = updatedList.findIndex((o) => o.id === data.id)

            delete updatedList[listKey]

            return {
                ...state,
                details: Object.assign([], updatedDetails),
                data: Object.assign([], updatedList),
            }
        }

        default:
            return state
    }
}
