import { useSelector } from 'react-redux'

import { getAccountantsWithFullNameAndStatus, getUser } from '@/selectors'

import { useAccountants } from '@/hooks/use-accountants'

import { Customers } from './Customers'

export const CustomersStatistics = () => {
    useAccountants()

    const { isAdminUser } = useSelector(getUser)
    const accountantsWithFullName = useSelector(getAccountantsWithFullNameAndStatus)

    return <Customers isAdminUser={isAdminUser} accountantsWithFullName={accountantsWithFullName} />
}
