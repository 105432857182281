import { getMinutesToDate } from '@scouts/helpers'
import { radius, ButtonLink, Notice, NoticeAction } from '@scouts/ui'

import { saveSelfAssessment } from '@/actions'
import { formatDate } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface SelfAssessmentNoticeOnHoldProps {
    selfAssessment: SelfAssessmentDTO
}

export const SelfAssessmentNoticeOnHold = ({ selfAssessment }: SelfAssessmentNoticeOnHoldProps) => {
    const dispatch = useAppDispatch()
    const { onHoldUntilDate } = selfAssessment

    const isOnHold = onHoldUntilDate ? getMinutesToDate(onHoldUntilDate) / 60 > 0 : false

    const removeOnHold = () => {
        const updatedSelfAssessment = { ...selfAssessment, onHoldUntilDate: null }

        dispatch(saveSelfAssessment(updatedSelfAssessment))
    }

    if (!isOnHold) return null

    return (
        <Notice borderRadius={radius.medium}>
            Set as 'On hold' until {formatDate(selfAssessment.onHoldUntilDate)}
            <NoticeAction>
                <ButtonLink onClick={removeOnHold}>Remove</ButtonLink>
            </NoticeAction>
        </Notice>
    )
}
