import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useCompanyIdFromPath } from '../../hooks/use-company-id-from-path'
import { useCompanyReturnIdFromPath } from '../../hooks/use-company-return-id-from-path'

export const useGetCompanyReturnParams = () => {
    const customerId = useCustomerIdFromPath() || 0
    const companyId = useCompanyIdFromPath() || 0
    const companyReturnId = useCompanyReturnIdFromPath() || 0

    const hasAllCompanyReturnParams = customerId !== 0 && companyId !== 0 && companyReturnId !== 0

    return {
        hasAllCompanyReturnParams,
        customerId,
        companyId,
        companyReturnId,
    }
}
