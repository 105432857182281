import _orderBy from 'lodash/orderBy'

import { apiSlice } from '@/store/api-slice'
import { Tags } from '@/store/tags'

import { useGetCustomersAgentAuthorisationsQuery, GetCustomersAgentAuthorisationsParams } from '../api'

export const useCustomersAgentAuthorisations = ({ customerId }: GetCustomersAgentAuthorisationsParams) => {
    const { data, isError, isLoading } = useGetCustomersAgentAuthorisationsQuery({
        customerId,
    })

    const agentAuthorisations = _orderBy(data, ['id'], ['desc'])

    return {
        agentAuthorisations,
        isError,
        isLoading,
    }
}

export const invalidateAgentAuthorisations = () => apiSlice.util.invalidateTags([Tags.AgentAuthorisations])
