import { useState } from 'react'

import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Radio,
} from '@scouts/ui'

interface BankDetailsRequestModalProps {
    onClose: () => void
    requestDetails: () => void
    requestWithMessage: () => void
}

export const BankDetailsRequestModal = ({
    onClose,
    requestDetails,
    requestWithMessage,
}: BankDetailsRequestModalProps) => {
    const [shouldRequestWithoutMessage, setShouldRequestWithoutMessage] = useState(true)

    const handleSubmit = () => {
        if (shouldRequestWithoutMessage) {
            requestDetails()
        } else {
            requestWithMessage()
        }
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Request bank details</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Form.Row.Content>
                            <Form.Control>
                                <Radio
                                    checked={shouldRequestWithoutMessage}
                                    label="Request without sending a message"
                                    name="shouldRequestWithoutMessage"
                                    onChange={() => setShouldRequestWithoutMessage(true)}
                                    value="true"
                                />
                            </Form.Control>
                            <Form.Control>
                                <Radio
                                    checked={!shouldRequestWithoutMessage}
                                    label="Request and send a message"
                                    name="shouldRequestWithoutMessage"
                                    onChange={() => setShouldRequestWithoutMessage(false)}
                                    value="false"
                                />
                            </Form.Control>
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>
                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onClose} isSecondary>
                            Cancel
                        </Button>
                        <Button type="submit">{shouldRequestWithoutMessage ? 'Continue' : 'Request'}</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
