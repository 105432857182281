import _throttle from 'lodash/throttle'
import { useMemo } from 'react'

import { useLazyGetMessageSuggestionQuery } from '../api'

export const useMessageSuggestion = () => {
    const [triggerGetMessageSuggestion, { isError, isFetching, isLoading }] = useLazyGetMessageSuggestionQuery()

    const getMessageSuggestion = useMemo(
        () => _throttle(triggerGetMessageSuggestion, 1000),
        [triggerGetMessageSuggestion]
    )

    return {
        getMessageSuggestion,
        isError,
        isFetching,
        isLoading,
    }
}
