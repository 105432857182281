import { useSelector } from 'react-redux'

import { getCustomerSelfAssessments } from '@/selectors'

export const useCustomerSelfAssessments = ({ customerId }) => {
    const selfAssessments = useSelector((state) => getCustomerSelfAssessments(state, { customerId }))

    const hasSelfAssessments = selfAssessments.length > 0

    const defaultSelfAssessmentId = selfAssessments?.[0]?.id || null

    return { selfAssessments, defaultSelfAssessmentId, hasSelfAssessments }
}
