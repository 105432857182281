import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, media, Table } from '@scouts/ui'

import { PATH_TEMPLATES_TEMPLATE } from '@/constants'
import { formatDate, getFirstName } from '@/helpers'
import { MessageTemplateDTO } from '@/models/message-template'

import { useUser } from '@/hooks/use-user'

const getLastUpdatedString = (template: MessageTemplateDTO, userId: number) => {
    const { lastUpdatedByAccountantId, updatedDateUTC, lastUpdatedByAccountantFullName } = template
    const isUpdatedByYou = lastUpdatedByAccountantId === userId
    const byWho = isUpdatedByYou ? 'you' : getFirstName(lastUpdatedByAccountantFullName ?? '')
    return `${formatDate(updatedDateUTC)} by ${byWho}`
}

export const MessageTemplateListItem = ({ template }: { template: MessageTemplateDTO }) => {
    const user = useUser()
    const history = useHistory()

    const { id: templateId, name: templateName, content: templateContent } = template
    const lastUpdatedString = getLastUpdatedString(template, user.id)

    const handleItemClick = () => {
        const templateItemLocation = generatePath(PATH_TEMPLATES_TEMPLATE, { id: templateId })
        history.replace(templateItemLocation)
    }

    return (
        <Table.Body.Row onClick={handleItemClick}>
            <Table.Body.Cell width="70%">
                <Table.Body.Cell.Content>
                    <Body>
                        <Name>{templateName}</Name>
                        <Content>{templateContent}</Content>
                    </Body>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="30%">
                <Table.Body.Cell.Content>
                    <LastUpdated>{lastUpdatedString}</LastUpdated>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Body = styled.div`
    display: block;

    ${media.tablet} {
        padding-right: 72px;
    }
`

const Name = styled.div`
    color: ${colors.blue};
    font-size: ${font.normal};
    font-weight: ${font.weight.medium};
    margin-bottom: 12px;
`

const LastUpdated = styled.div`
    color: ${colors.black};
    font-size: ${font.small};
    height: 100%;
    vertical-align: top;
    margin-top: 12px;

    ${media.tablet} {
        margin-top: 0;
    }
`

const Content = styled.div`
    color: ${colors.black};
    font-size: ${font.small};
    line-height: 1.5;
    overflow-wrap: anywhere;
    white-space: pre-wrap;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
`
