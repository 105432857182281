import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
    colors,
    Button,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Notice,
} from '@scouts/ui'

import { saveCustomerEmail } from '../../actions'

class CustomerProfileEditEmailModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            newEmail: '',
            newEmailSecond: '',
            errorMessage: '',
            isSubmitting: false,
        }

        _bindAll(this, ['handleSubmit', 'handleChange'])
    }

    handleSubmit() {
        const { dispatch, handleClose, customerId } = this.props
        const { newEmail, newEmailSecond } = this.state

        if (newEmail !== newEmailSecond) {
            this.setState({ errorMessage: "Please check the emails entered, because they don't match" })

            return
        }

        const onSuccess = () => handleClose()
        const onError = () => this.setState({ isSubmitting: false })

        this.setState({ isSubmitting: true })
        dispatch(saveCustomerEmail({ customerId, email: newEmail }, { onSuccess, onError }))
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value, errorMessage: '' })
    }

    render() {
        const { newEmail, newEmailSecond, errorMessage, isSubmitting } = this.state
        const { handleClose } = this.props

        return (
            <Modal onClose={handleClose}>
                <Form onSubmit={this.handleSubmit}>
                    <ModalHeader>
                        <ModalTitle>Change email</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        {errorMessage && <Notice background={colors.redLighter}>{errorMessage}</Notice>}

                        <Form.Row>
                            <Form.Row.Title>New email</Form.Row.Title>
                            <Form.Row.Content>
                                <Input
                                    name="newEmail"
                                    onChange={this.handleChange}
                                    required
                                    type="email"
                                    value={newEmail}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row.Title>New email again</Form.Row.Title>
                            <Form.Row.Content>
                                <Input
                                    name="newEmailSecond"
                                    onChange={this.handleChange}
                                    required
                                    type="email"
                                    value={newEmailSecond}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                    </ModalBody>

                    <ModalFooter>
                        <ModalActions>
                            <Button isSecondary onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type="submit" isLoading={isSubmitting}>
                                Change
                            </Button>
                        </ModalActions>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

CustomerProfileEditEmailModal.propTypes = {
    customerId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
}

export default connect()(CustomerProfileEditEmailModal)
