import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import {
    colors,
    font,
    AccountantPortalChrome,
    ButtonLink,
    Checkbox,
    ConfirmationModal,
    DropdownButton,
    DropdownSelect,
    Flexer,
    InputWithSave,
    Spacer,
} from '@scouts/ui'

import {
    addSelfAssessmentRefund,
    receiveSuccessAlerts,
    saveSelfAssessmentFinalReturn,
    saveSelfAssessmentRefilingStatus,
} from '@/actions'
import {
    PATH_CUSTOMERS,
    PAYMENT_STATUS_SUCCEEDED,
    SECTION_PAYMENT_ON_ACCOUNT,
    SELF_ASSESSMENT_REFILING_STATUS_NEEDS_REFILING,
    SELF_ASSESSMENT_REFILING_STATUS_NONE,
    SELF_ASSESSMENT_REFILING_STATUS_REFILED,
    SELF_ASSESSMENT_REFILING_STATUS_TITLES,
    SELF_ASSESSMENT_STATUS_FILED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_STARTED,
    SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS,
    TAX_YEARS,
} from '@/constants'
import {
    formatCurrency,
    isAdmin,
    isNumeric,
    removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue,
} from '@/helpers'
import { FileType } from '@/models'

import SelfAssessmentStatus from '../../SelfAssessmentStatus'
import { RefundModal } from '../_shared/RefundModal'
import RequestDocumentsContainer from '../RequestDocumentsContainer'
import { SelfAssessmentDeleteModal } from './modals/SelfAssessmentDeleteModal'
import { SelfAssessmentEditIncomeModal } from './modals/SelfAssessmentEditIncomeModal'
import { SelfAssessmentEditTaxYearModal } from './modals/SelfAssessmentEditTaxYearModal'
import { SelfAssessmentMarkDocumentsUploadedModal } from './modals/SelfAssessmentMarkDocumentsUploadedModal'
import { SelfAssessmentMarkQuestionsCompletedModal } from './modals/SelfAssessmentMarkQuestionsCompletedModal'
import { SelfAssessmentOnHoldModal } from './modals/SelfAssessmentOnHoldModal'
import { SelfAssessmentRevertPayLaterModal } from './modals/SelfAssessmentRevertPayLaterModal'
import { SelfAssessmentNoticeOnHold } from './notices/SelfAssessmentNoticeOnHold'
import { SelfAssessmentNoticeOutstandingPayments } from './notices/SelfAssessmentNoticeOutstandingPayments'
import { SelfAssessmentNoticePayLater } from './notices/SelfAssessmentNoticePayLater'
import { SelfAssessmentNoticePayLaterPaid } from './notices/SelfAssessmentNoticePayLaterPaid'
import { SelfAssessmentNoticeTaxRegistration } from './notices/SelfAssessmentNoticeTaxRegistration'
import { SelfAssessmentDetails } from './SelfAssessmentDetails'
import SelfAssessmentRecentlyUploadedFiles from './SelfAssessmentRecentlyUploadedFiles'
import { SelfAssessmentSections } from './SelfAssessmentSections'
import { SelfAssessmentTaxCode } from './SelfAssessmentTaxCode'
import SelfAssessmentUploads from './SelfAssessmentUploads'

const HIGHLIGHTED_FIELD_TITLES = {
    taxDue: 'Tax due',
    totalIncome: 'Total income',
    totalTaxableIncome: 'Taxable income',
}

class SelfAssessment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayPaymentOnAccount: false,
            highlightedChangedFields: [],
            hasUploadedAFile: false,
            isDeleteModalOpen: false,
            isEditIncomeModalOpen: false,
            isEditTaxYearModalOpen: false,
            isInfoRequestedModalOpen: false,
            isMarkDocumentsUploadedModalOpen: false,
            isMarkQuestionsCompletedModalOpen: false,
            isOnHoldModalOpen: false,
            isRefundModalOpen: false,
            isRevertPayLaterModalOpen: false,
            refilingStatus: null,
        }

        this.fieldHighlightTimeout = null

        _bindAll(this, [
            'handleChangeTaxYear',
            'handleSave',
            'handleStatusChange',
            'handleTogglePaymentOnAccount',
            'handleRefilingStatusChange',
            'scrollToSection',
        ])
    }

    componentDidUpdate(prevProps) {
        this.animateTaxCalculationChanges(prevProps)
    }

    componentWillUnmount() {
        if (this.fieldHighlightTimeout) clearTimeout(this.fieldHighlightTimeout)
    }

    handleChangeTaxYear({ value: selfAssessmentId }) {
        const { history, customer } = this.props

        const { id: customerId } = customer

        const url = `${PATH_CUSTOMERS}/${customerId}/assessment/${selfAssessmentId}`

        history.push(url)
    }

    handleSave({ name, value }) {
        const { dispatch, selfAssessment } = this.props

        const {
            hasLastYearPaymentsOnAccount,
            id,
            lastYearPaymentsOnAccountAmount,
            paymentOnAccount,
            taxDue,
            taxLiability,
            totalIncome,
            totalTaxableIncome,
        } = selfAssessment

        const data = {
            hasLastYearPaymentsOnAccount: hasLastYearPaymentsOnAccount || lastYearPaymentsOnAccountAmount > 0,
            id,
            lastYearPaymentsOnAccountAmount,
            paymentOnAccount,
            taxDue,
            taxLiability,
            totalIncome,
            totalTaxableIncome,
            ...{ [name]: value },
        }

        this.setState({
            highlightedChangedFields: [],
            hasUploadedAFile: false,
        })

        dispatch(saveSelfAssessmentFinalReturn(id, data))
    }

    handleTogglePaymentOnAccount({ checked }) {
        this.setState({ displayPaymentOnAccount: checked })
    }

    handleRefilingStatusChange({ value: refilingStatus }) {
        const { dispatch, selfAssessment } = this.props

        const { id } = selfAssessment

        dispatch(saveSelfAssessmentRefilingStatus(id, refilingStatus))
    }

    handleStatusChange(status) {
        if (status === SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED) {
            this.setState({ isInfoRequestedModalOpen: true })
        }
    }

    animateTaxCalculationChanges(prevProps) {
        const { selfAssessmentFiles: prevSelfAssessmentFiles, selfAssessment: prevSelfAssessment } = prevProps
        const { selfAssessment, selfAssessmentFiles } = this.props
        const { taxDue, totalIncome, totalTaxableIncome } = selfAssessment
        const {
            taxDue: prevTaxDue,
            totalIncome: prevTotalIncome,
            totalTaxableIncome: prevTotalTaxableIncome,
        } = prevSelfAssessment

        const { hasUploadedAFile } = this.state

        const hasAnyFiles = prevSelfAssessmentFiles?.length >= 0 && selfAssessmentFiles?.length > 0
        const haveFilesChanged = !_isEqual(prevSelfAssessmentFiles, selfAssessmentFiles)
        const hasUploadedTaxCalculation =
            selfAssessmentFiles.findIndex(({ fileType }) => fileType === FileType.TaxCalculation) >= 0

        if (!hasUploadedAFile && hasAnyFiles && hasUploadedTaxCalculation && haveFilesChanged) {
            this.setState({
                hasUploadedAFile: true,
            })
        }

        const highlightedChangedFields = []

        if (taxDue !== prevTaxDue) highlightedChangedFields.push('taxDue')
        if (totalIncome !== prevTotalIncome) highlightedChangedFields.push('totalIncome')
        if (totalTaxableIncome !== prevTotalTaxableIncome) highlightedChangedFields.push('totalTaxableIncome')

        if (hasUploadedAFile && highlightedChangedFields.length > 0) {
            this.highlightChangedFields(highlightedChangedFields)
        }
    }

    highlightChangedFields(highlightedChangedFields) {
        if (this.fieldHighlightTimeout) clearTimeout(this.fieldHighlightTimeout)
        const { dispatch } = this.props

        const highlightedFieldTitles = highlightedChangedFields
            .map((field) => HIGHLIGHTED_FIELD_TITLES[field])
            .join(', ')

        this.setState({
            highlightedChangedFields,
        })

        dispatch(
            receiveSuccessAlerts(
                `${highlightedFieldTitles} ${
                    highlightedChangedFields.length === 1 ? 'field has' : 'fields have'
                } been updated by the Tax Calculation`
            )
        )

        this.fieldHighlightTimeout = setTimeout(() => {
            this.setState({
                highlightedChangedFields: [],
                hasUploadedAFile: false,
            })
        }, 2000)
    }

    scrollToSection(section) {
        document.getElementById(section).scrollIntoView(true)
    }

    paymentOnAccountCalculation() {
        const { selfAssessment } = this.props
        const { taxDue, paymentOnAccount, taxYear } = selfAssessment

        const totalTaxDue = formatCurrency(taxDue + paymentOnAccount)
        const nextYear = Number(taxYear) + 1
        return `Due 31st Jan ${nextYear} ${totalTaxDue}, due 31st July ${nextYear} ${formatCurrency(paymentOnAccount)}`
    }

    handleRefundSelfAssessment(payload) {
        const { selfAssessment, dispatch } = this.props
        dispatch(
            addSelfAssessmentRefund({
                selfAssessmentId: selfAssessment.id,
                ...payload,
            })
        )
        this.setState({ isRefundModalOpen: false })
    }

    render() {
        const {
            customer,
            customerDiscounts,
            customerSelfAssessments,
            history,
            selfAssessment,
            selfAssessmentFiles,
            user,
        } = this.props
        const {
            displayPaymentOnAccount,
            highlightedChangedFields,
            isDeleteModalOpen,
            isEditIncomeModalOpen,
            isEditTaxYearModalOpen,
            isInfoRequestedModalOpen,
            isMarkDocumentsUploadedModalOpen,
            isMarkQuestionsCompletedModalOpen,
            isOnHoldModalOpen,
            isRefundModalOpen,
            isRevertPayLaterModalOpen,
            shouldOpenRequestDocumentsModal,
        } = this.state

        const { hasOutstandingPayments } = customer

        const {
            customerId,
            hasLastYearPaymentsOnAccount,
            hasPaid,
            id: selfAssessmentId,
            lastYearPaymentsOnAccountAmount,
            latestEventDateUTC,
            payAfterFiling,
            paymentOnAccount,
            paymentStatus,
            status,
            statusChangedDateUTC,
            taxDue,
            taxLiability,
            totalIncome,
            totalTaxableIncome,
            refilingStatus,
        } = selfAssessment

        const discount = _get(customerDiscounts, ['items'], []).find(
            (o) => o.customerId === customer.id && o.selfAssessmentId === selfAssessmentId
        )

        const statusLastChanged = moment.utc(statusChangedDateUTC).fromNow()
        const lastUpdated = moment.utc(latestEventDateUTC).fromNow()

        const shouldDisplayPaymentOnAccount = !!paymentOnAccount || displayPaymentOnAccount
        const shouldDisplayPreviousPaymentOnAccount =
            shouldDisplayPaymentOnAccount || hasLastYearPaymentsOnAccount || lastYearPaymentsOnAccountAmount > 0

        const shouldDisplayTaxLiability = !!shouldDisplayPreviousPaymentOnAccount || !!taxLiability

        const shouldAllowMarkingQuestionsCompleted = status === SELF_ASSESSMENT_STATUS_STARTED
        const shouldAllowMarkingDocumentsUploaded = status === SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS

        const shouldDisplayNeedsRefiling = isAdmin(user)

        const refilingStatusOptions = [
            {
                value: SELF_ASSESSMENT_REFILING_STATUS_NONE,
                title: SELF_ASSESSMENT_REFILING_STATUS_TITLES[SELF_ASSESSMENT_REFILING_STATUS_NONE],
            },
            {
                value: SELF_ASSESSMENT_REFILING_STATUS_NEEDS_REFILING,
                title: SELF_ASSESSMENT_REFILING_STATUS_TITLES[SELF_ASSESSMENT_REFILING_STATUS_NEEDS_REFILING],
            },
            {
                value: SELF_ASSESSMENT_REFILING_STATUS_REFILED,
                title: SELF_ASSESSMENT_REFILING_STATUS_TITLES[SELF_ASSESSMENT_REFILING_STATUS_REFILED],
            },
        ]

        const actions = [
            {
                title: 'Edit income',
                onClick: () => {
                    this.setState({ isEditIncomeModalOpen: true })
                },
            },
            {
                title: 'Edit tax year',
                onClick: () => {
                    this.setState({ isEditTaxYearModalOpen: true })
                },
            },
            {
                title: "Set as 'On hold'",
                onClick: () => {
                    this.setState({ isOnHoldModalOpen: true })
                },
            },
            {
                title: 'View letter of engagement',
                onClick: () => {
                    history.push(`${PATH_CUSTOMERS}/${customerId}/assessment/${selfAssessmentId}/agreement`)
                },
            },
        ]

        if (isAdmin(user)) {
            actions.push({
                title: 'Mark questions completed',
                onClick: () => {
                    this.setState({ isMarkQuestionsCompletedModalOpen: true })
                },
                disabled: !shouldAllowMarkingQuestionsCompleted,
            })

            actions.push({
                title: 'Mark documents uploaded',
                onClick: () => {
                    this.setState({ isMarkDocumentsUploadedModalOpen: true })
                },
                disabled: !shouldAllowMarkingDocumentsUploaded,
            })

            actions.push({
                title: 'Refund',
                onClick: () => {
                    this.setState({ isRefundModalOpen: true })
                },
                disabled: paymentStatus !== PAYMENT_STATUS_SUCCEEDED || payAfterFiling,
            })

            if (!hasPaid && payAfterFiling) {
                actions.push({
                    title: 'Revert pay later',
                    onClick: () => {
                        this.setState({ isRevertPayLaterModalOpen: true })
                    },
                })
            }

            if (
                !hasPaid &&
                !(
                    status === SELF_ASSESSMENT_STATUS_FILED ||
                    status === SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND ||
                    status === SELF_ASSESSMENT_STATUS_READY_TO_FILE
                )
            ) {
                actions.push({
                    title: 'Delete',
                    color: colors.red,
                    onClick: () => {
                        this.setState({ isDeleteModalOpen: true })
                    },
                })
            }
        }

        return (
            <AccountantPortalChrome.Customer.Page.Container>
                <AccountantPortalChrome.Customer.Page.Header>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <AccountantPortalChrome.Customer.Page.Title>
                            Tax return
                        </AccountantPortalChrome.Customer.Page.Title>

                        <DropdownSelect
                            name="selfAssessmentId"
                            value={selfAssessmentId}
                            options={customerSelfAssessments.map(
                                ({ id: optionSelfAssessmentId, taxYear: optionTaxYear }) => ({
                                    value: optionSelfAssessmentId,
                                    title: TAX_YEARS[optionTaxYear],
                                })
                            )}
                            onChange={this.handleChangeTaxYear}
                        />

                        <LastUpdated>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                Status changed {statusLastChanged} <wbr /> (last updated {lastUpdated})
                            </span>
                        </LastUpdated>
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <Flexer gap="12px" alignCenter>
                            <Spacer>
                                <RequestDocumentsContainer
                                    customer={customer}
                                    customerSelfAssessments={customerSelfAssessments}
                                    onModalOpen={() => {
                                        this.setState({ shouldOpenRequestDocumentsModal: false })
                                    }}
                                    selfAssessmentId={selfAssessmentId}
                                    shouldOpenModal={shouldOpenRequestDocumentsModal}
                                />
                            </Spacer>
                            <Spacer>
                                <DropdownButton alignRight actions={actions}>
                                    Actions
                                </DropdownButton>
                            </Spacer>
                        </Flexer>
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                </AccountantPortalChrome.Customer.Page.Header>

                <Flexer column gap="12px">
                    <SelfAssessmentNoticePayLater selfAssessment={selfAssessment} discount={discount} />
                    <SelfAssessmentNoticeOutstandingPayments shouldDisplay={hasOutstandingPayments} />
                    <SelfAssessmentNoticePayLaterPaid selfAssessment={selfAssessment} />
                    <SelfAssessmentNoticeTaxRegistration customer={customer} />
                    <SelfAssessmentNoticeOnHold selfAssessment={selfAssessment} />
                    <SelfAssessmentRecentlyUploadedFiles selfAssessment={selfAssessment} />
                </Flexer>

                <Main>
                    <SummaryContainer>
                        <Summary>
                            <SummaryRow>
                                <SummaryRowLabel>Status</SummaryRowLabel>
                                <SummaryRowField>
                                    <SelfAssessmentStatus
                                        customer={customer}
                                        onChange={this.handleStatusChange}
                                        selfAssessment={selfAssessment}
                                    />
                                </SummaryRowField>
                            </SummaryRow>

                            <SummaryRow
                                background={highlightedChangedFields.includes('totalIncome') ? colors.mintLighter : ''}
                            >
                                <SummaryRowLabel>Total income</SummaryRowLabel>
                                <SummaryRowField>
                                    <InputWithSave
                                        name="totalIncome"
                                        value={isNumeric(totalIncome) ? totalIncome : ''}
                                        placeholder="Add…"
                                        onSave={this.handleSave}
                                        formatter={removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue}
                                    />
                                </SummaryRowField>
                            </SummaryRow>

                            <SummaryRow
                                background={
                                    highlightedChangedFields.includes('totalTaxableIncome') ? colors.mintLighter : ''
                                }
                            >
                                <SummaryRowLabel>Taxable income</SummaryRowLabel>
                                <SummaryRowField>
                                    <InputWithSave
                                        name="totalTaxableIncome"
                                        value={isNumeric(totalTaxableIncome) ? totalTaxableIncome : ''}
                                        placeholder="Add…"
                                        onSave={this.handleSave}
                                        formatter={removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue}
                                    />
                                </SummaryRowField>
                            </SummaryRow>

                            <AppearingSummaryRow hasContent={shouldDisplayTaxLiability}>
                                <SummaryRow>
                                    <SummaryRowLabel>Tax liability</SummaryRowLabel>
                                    <SummaryRowField>
                                        <InputWithSave
                                            name="taxLiability"
                                            value={isNumeric(taxLiability) ? taxLiability : ''}
                                            placeholder="Add…"
                                            onSave={this.handleSave}
                                            formatter={removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue}
                                        />
                                    </SummaryRowField>
                                </SummaryRow>
                            </AppearingSummaryRow>

                            <AppearingSummaryRow hasContent={shouldDisplayPreviousPaymentOnAccount}>
                                <SummaryRow>
                                    <SummaryRowLabel>
                                        Total paid Payment on Account
                                        <br />
                                        <ButtonLink
                                            isSmall
                                            onClick={() => this.scrollToSection(SECTION_PAYMENT_ON_ACCOUNT)}
                                        >
                                            View details
                                        </ButtonLink>
                                    </SummaryRowLabel>
                                    <SummaryRowField>
                                        <InputWithSave
                                            name="lastYearPaymentsOnAccountAmount"
                                            value={
                                                isNumeric(lastYearPaymentsOnAccountAmount)
                                                    ? lastYearPaymentsOnAccountAmount
                                                    : ''
                                            }
                                            placeholder="Add…"
                                            onSave={this.handleSave}
                                            formatter={removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue}
                                        />
                                    </SummaryRowField>
                                </SummaryRow>
                            </AppearingSummaryRow>

                            <SummaryRow
                                background={highlightedChangedFields.includes('taxDue') ? colors.mintLighter : ''}
                            >
                                <SummaryRowLabel>
                                    {shouldDisplayPaymentOnAccount ? (
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            Tax due <wbr /> (balancing payment)
                                        </span>
                                    ) : (
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            Tax due <wbr /> (use negative for refund)
                                        </span>
                                    )}
                                </SummaryRowLabel>
                                <SummaryRowField>
                                    <InputWithSave
                                        name="taxDue"
                                        value={isNumeric(taxDue) ? taxDue : ''}
                                        placeholder="Add…"
                                        onSave={this.handleSave}
                                        formatter={removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue}
                                    />
                                </SummaryRowField>
                            </SummaryRow>

                            {shouldDisplayNeedsRefiling && (
                                <SummaryRow>
                                    <SummaryRowLabel>Refiling status</SummaryRowLabel>
                                    <SummaryRowField>
                                        <DropdownSelect
                                            name="refilingStatus"
                                            value={refilingStatus}
                                            options={refilingStatusOptions}
                                            onChange={this.handleRefilingStatusChange}
                                        />
                                    </SummaryRowField>
                                </SummaryRow>
                            )}

                            <SummaryRow>
                                <SummaryRowLabel>
                                    <Checkbox
                                        label="Payment on Account"
                                        name="shouldDisplayPaymentOnAccount"
                                        onChange={this.handleTogglePaymentOnAccount}
                                        checked={shouldDisplayPaymentOnAccount}
                                        isSmall
                                    />
                                </SummaryRowLabel>
                                <SummaryRowField>
                                    {shouldDisplayPaymentOnAccount && (
                                        <InputWithSave
                                            name="paymentOnAccount"
                                            value={isNumeric(paymentOnAccount) ? paymentOnAccount : ''}
                                            placeholder="Add…"
                                            onSave={this.handleSave}
                                            formatter={removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue}
                                        />
                                    )}
                                </SummaryRowField>
                            </SummaryRow>

                            {shouldDisplayPaymentOnAccount && (
                                <SummaryRow>
                                    <PaymentOnAccountCalculation>
                                        {this.paymentOnAccountCalculation()}
                                    </PaymentOnAccountCalculation>
                                </SummaryRow>
                            )}
                        </Summary>
                    </SummaryContainer>

                    <UploadsContainer>
                        <SelfAssessmentUploads
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                        />
                    </UploadsContainer>
                </Main>

                <SelfAssessmentTaxCode selfAssessment={selfAssessment} />

                <SectionsContainer>
                    <SelfAssessmentSections selfAssessment={selfAssessment} selfAssessmentFiles={selfAssessmentFiles} />
                </SectionsContainer>

                <DetailsContainer>
                    <SelfAssessmentDetails customer={customer} selfAssessment={selfAssessment} discount={discount} />
                </DetailsContainer>

                {isEditTaxYearModalOpen && (
                    <SelfAssessmentEditTaxYearModal
                        customerSelfAssessments={customerSelfAssessments}
                        onClose={() => {
                            this.setState({ isEditTaxYearModalOpen: false })
                        }}
                        selfAssessment={selfAssessment}
                    />
                )}

                {isEditIncomeModalOpen && (
                    <SelfAssessmentEditIncomeModal
                        onClose={() => {
                            this.setState({ isEditIncomeModalOpen: false })
                        }}
                        selfAssessment={selfAssessment}
                    />
                )}

                {isMarkQuestionsCompletedModalOpen && (
                    <SelfAssessmentMarkQuestionsCompletedModal
                        onClose={() => {
                            this.setState({ isMarkQuestionsCompletedModalOpen: false })
                        }}
                        selfAssessment={selfAssessment}
                    />
                )}

                {isMarkDocumentsUploadedModalOpen && (
                    <SelfAssessmentMarkDocumentsUploadedModal
                        onClose={() => {
                            this.setState({ isMarkDocumentsUploadedModalOpen: false })
                        }}
                        selfAssessment={selfAssessment}
                    />
                )}

                {isRefundModalOpen && (
                    <RefundModal
                        itemName="return"
                        itemPrice={selfAssessment.price}
                        onClose={() => this.setState({ isRefundModalOpen: false })}
                        onRefundItem={(value) => this.handleRefundSelfAssessment(value)}
                        title={`Refund ${customer.fullName}'s ${TAX_YEARS[selfAssessment.taxYear]} tax return`}
                    />
                )}

                {isRevertPayLaterModalOpen && (
                    <SelfAssessmentRevertPayLaterModal
                        selfAssessment={selfAssessment}
                        onClose={() => {
                            this.setState({ isRevertPayLaterModalOpen: false })
                        }}
                    />
                )}

                {isDeleteModalOpen && (
                    <SelfAssessmentDeleteModal
                        selfAssessment={selfAssessment}
                        onClose={() => {
                            this.setState({ isDeleteModalOpen: false })
                        }}
                    />
                )}

                {isOnHoldModalOpen && (
                    <SelfAssessmentOnHoldModal
                        onClose={() => {
                            this.setState({ isOnHoldModalOpen: false })
                        }}
                        customer={customer}
                        selfAssessment={selfAssessment}
                    />
                )}

                {isInfoRequestedModalOpen && (
                    <ConfirmationModal
                        onCancel={() => {
                            this.setState({ isInfoRequestedModalOpen: false })
                        }}
                        onConfirm={() => {
                            this.setState({ isInfoRequestedModalOpen: false, shouldOpenRequestDocumentsModal: true })
                        }}
                        labelCancel="No, already sent"
                        labelConfirm="Yes, please"
                        title="Status set to 'Info requested'"
                    >
                        Do you also want to send the client a message to clarify the information that's needed?
                    </ConfirmationModal>
                )}
            </AccountantPortalChrome.Customer.Page.Container>
        )
    }
}

SelfAssessment.propTypes = {
    customer: PropTypes.object.isRequired,
    customerDiscounts: PropTypes.object.isRequired,
    customerSelfAssessments: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    selfAssessment: PropTypes.object.isRequired,
    selfAssessmentFiles: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(withRouter(SelfAssessment))

const LastUpdated = styled.div`
    font-size: ${font.smaller};
    line-height: 1.3;
    flex: 1 1 auto;
    max-width: 240px;
    overflow: hidden;
`

const Main = styled.div`
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
`

const SummaryContainer = styled.div`
    flex: 0 0 66.66%;
`

const Summary = styled.div``

const SummaryRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 37px;
    margin: 0 0 0 -12px;
    padding-left: 12px;
    transition: background 2s ease-in 1s;

    ${({ background }) =>
        background &&
        `
            background: ${background};
            transition: none;
        `}

    &:not(:last-child) {
        margin-bottom: 9px;
    }
`

const SummaryRowLabel = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    line-height: 1.3;
    flex: 0 1 50%;
    padding-right: 18px;
`

const SummaryRowField = styled.div`
    flex: 0 0 50%;
    min-width: 192px;
    padding-right: 48px;
    background: ${colors.white};
`

const AppearingSummaryRow = styled.div`
    visibility: hidden;
    margin: 0 0 0 -12px;
    padding-left: 12px;
    transition: opacity 0.3s linear, max-height 0.1s linear;
    opacity: 0;
    max-height: 0;
    background: ${colors.blueLighter};

    ${({ hasContent }) =>
        hasContent &&
        `
            visibility: visible;
            margin: 0 0 9px -12px;
            transition: opacity .8s ease-out, max-height .8s ease-out, background 2s ease-in 1s;
            opacity: 1;
            max-height: 100px;
            background: transparent;
        `}

    ${SummaryRowField} {
        background: ${colors.white};
    }
`

const PaymentOnAccountCalculation = styled.div`
    color: ${colors.black};
    font-size: ${font.small};
    display: inline-block;
    margin-top: 6px;
    padding: 6px 12px;
    background: ${colors.mintLighter};
`

const UploadsContainer = styled.div`
    flex: 0 0 33.33%;
    min-width: 0; /* Flex overflow fix */
    max-width: 386px; /* 386 = Parent maxWidth - 2x padding * 33.33% */
`

const DetailsContainer = styled.div`
    margin: 48px 0;
`

const SectionsContainer = styled.div`
    margin: 48px 0;
`
