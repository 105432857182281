import { AccountantPortalChrome } from '@scouts/ui'

import { CustomerDTO } from '@/models'
import { useCustomerLoggedEvents } from '@/store/logged-events'

import { CustomerHistoryLogItem } from './CustomerHistoryLogItem'

export const CustomerHistoryLog = ({ customer }: { customer: CustomerDTO }) => {
    const { loggedEvents } = useCustomerLoggedEvents({ customerId: customer.id })

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.Title>History log</AccountantPortalChrome.Customer.Page.Title>
            </AccountantPortalChrome.Customer.Page.Header>

            {loggedEvents.map((event) => (
                <CustomerHistoryLogItem key={event.id} event={event} />
            ))}
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
