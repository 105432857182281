import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { Pagination, Select } from '@scouts/ui'

const toQueryString = ({ search, page = 1, pageSize = null }) => {
    const parts = []

    if (pageSize) {
        parts.push(`pageSize=${pageSize}`)
    }

    if (page) {
        parts.push(`page=${page}`)
    }

    if (search) {
        const currentSearch = queryString.parse(search)

        delete currentSearch.page

        delete currentSearch.pageSize

        parts.push(queryString.stringify(currentSearch))
    }

    return parts.reverse().join('&')
}

const shouldShowPaginationItem = (page, currentPage, size) => {
    if (
        page === 0 ||
        page === 1 ||
        page === 2 ||
        (page === 3 && currentPage === 1) ||
        (page === 4 && currentPage === 1) ||
        page === size - 1 ||
        page === size - 2 ||
        page === size - 3 ||
        page === currentPage - 3 ||
        page === currentPage - 2 ||
        page === currentPage - 1 ||
        page === currentPage ||
        page === currentPage + 1
    ) {
        return true
    }

    return false
}

const shouldShowSeparator = (page, currentPage, size) => {
    if ((page === 4 && currentPage > 4) || (page === size - 4 && currentPage < size)) {
        return true
    }

    return false
}

const PaginationContainer = ({ pathname, search, pagination }) => {
    const history = useHistory()

    const { pageCount, currentPage, totalCount, perPage } = pagination || {}

    if (!pageCount) return null

    const pages = Array.from(Array(pageCount).keys())

    if (!pages.length || !totalCount) return null

    const handlePerPageChange = ({ value }) => {
        history.push({ pathname, search: toQueryString({ search, page: 1, pageSize: value }) })
    }

    const handleNavigation = ({ page }) => {
        history.push({ pathname, search: toQueryString({ search, page, pageSize: perPage }) })
    }

    return (
        <Pagination>
            {pages.length > 1 && (
                <Pagination.Items>
                    {pages.map((page) => {
                        if (shouldShowPaginationItem(page, currentPage, pages.length)) {
                            return (
                                <Pagination.Item
                                    key={page}
                                    isActive={currentPage === page + 1}
                                    onClick={() => {
                                        handleNavigation({ page: page + 1 })
                                    }}
                                >
                                    {page + 1}
                                </Pagination.Item>
                            )
                        }

                        if (shouldShowSeparator(page, currentPage, pages.length)) {
                            return <Pagination.Separator key={page}>…</Pagination.Separator>
                        }

                        return null
                    })}
                </Pagination.Items>
            )}
            {(pages.length > 1 || perPage === totalCount) && (
                <Pagination.Actions>
                    <Pagination.Actions.Label>Per page</Pagination.Actions.Label>
                    <Select
                        name="perPage"
                        onChange={handlePerPageChange}
                        value={perPage}
                        options={[
                            { value: 25 },
                            { value: 50 },
                            { value: 100 },
                            { value: totalCount, title: 'View all' },
                        ]}
                    />
                </Pagination.Actions>
            )}
        </Pagination>
    )
}

PaginationContainer.propTypes = {
    pagination: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
}

PaginationContainer.defaultProps = {
    search: null,
}

export default PaginationContainer
