import { useState } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import {
    Button,
    Checkbox,
    Flexer,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { DiscountSchemeDTO } from '@/models'
import { useUpdateDiscountScheme } from '@/store/discount-schemes/hooks/use-update-discount-scheme'

interface DiscountSchemesEditModalProps {
    discountScheme: DiscountSchemeDTO
    onClose: () => void
}

export const DiscountSchemesEditModal = ({ onClose, discountScheme }: DiscountSchemesEditModalProps) => {
    const {
        active: initialActive,
        appliesToSelfAssessments: initialAppliesToSelfAssessments,
        appliesToSubscriptions: initialAppliesToSubscriptions,
        appliesToTaxConsultations: initialAppliesToTaxConsultations,
        appliesToCompanyReturns: initialAppliesToCompanyReturns,
        appliesToProductBundles: initialAppliesToProductBundles,
        discountCode: initialDiscountCode,
        label: initialLabel,
        durationMonths: initialDurationMonths,
        hasUniqueCodeVariants: initialHasUniqueCodeVariants,
        id: discountSchemeId,
        name: initialName,
        percentageDiscount: initialPercentageDiscount,
        redemptionLimit: initialRedemptionLimit,
    } = discountScheme

    const { updateDiscountScheme, isLoading: isLoadingUpdate } = useUpdateDiscountScheme()

    const [active, setActive] = useState(initialActive)
    const [appliesToSelfAssessments, setAppliesToSelfAssessments] = useState(initialAppliesToSelfAssessments)
    const [appliesToSubscriptions, setAppliesToSubscriptions] = useState(initialAppliesToSubscriptions)
    const [appliesToTaxConsultations, setAppliesToTaxConsultations] = useState(initialAppliesToTaxConsultations)
    const [appliesToCompanyReturns, setAppliesToCompanyReturns] = useState(initialAppliesToCompanyReturns)
    const [appliesToProductBundles, setAppliesToProductBundles] = useState(initialAppliesToProductBundles)
    const [discountCode, setDiscountCode] = useState(initialDiscountCode)
    const [label, setLabel] = useState(initialLabel)
    const [durationMonths, setDurationMonths] = useState(initialDurationMonths)
    const [hasUniqueCodeVariants, setHasUniqueCodeVariants] = useState(initialHasUniqueCodeVariants)
    const [name, setName] = useState(initialName)
    const [percentageDiscount, setPercentageDiscount] = useState(initialPercentageDiscount)
    const [redemptionLimit, setRedemptionLimit] = useState(initialRedemptionLimit)

    const nameTrimmed = name.trim()
    const discountCodeTrimmed = discountCode.trim()
    const labelTrimmed = label?.trim()

    const handleSubmit = () => {
        const data = {
            ...discountScheme,
            active,
            appliesToSelfAssessments,
            appliesToSubscriptions,
            appliesToTaxConsultations,
            appliesToCompanyReturns,
            appliesToProductBundles,
            discountCode: discountCodeTrimmed,
            label: labelTrimmed || null,
            durationMonths: durationMonths ? Math.round(durationMonths) : null,
            hasUniqueCodeVariants,
            uniqueCodeVariants: hasUniqueCodeVariants ? discountScheme.uniqueCodeVariants : null,
            name: nameTrimmed,
            percentageDiscount,
            redemptionLimit: redemptionLimit ? Math.round(redemptionLimit) : null,
        }

        updateDiscountScheme({ discountSchemeId, data }).then(matchSuccessResponse(onClose))
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Edit {initialName}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Name</Form.Row.Title>
                        <Form.Row.Content>
                            <Input name="name" value={name} onChange={({ value }) => setName(value)} required />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Discount percentage</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="percentageDiscount"
                                value={percentageDiscount}
                                type="number"
                                onChange={({ value }) => setPercentageDiscount(Number(value))}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Discount code</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="discountCode"
                                value={discountCode}
                                maxLength={25}
                                onChange={({ value }) => setDiscountCode(value)}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Discount label (shown for customer)</Form.Row.Title>
                        <Form.Row.Content>
                            <Input name="label" value={label || ''} onChange={({ value }) => setLabel(value)} />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Usage limit (optional)</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="redemptionLimit"
                                value={redemptionLimit || ''}
                                type="number"
                                onChange={({ value }) => setRedemptionLimit(Number(value))}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Duration in months (optional)</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="durationMonths"
                                value={durationMonths || ''}
                                type="number"
                                onChange={({ value }) => setDurationMonths(Number(value))}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Applies to</Form.Row.Title>
                        <Form.Row.Content>
                            <Flexer column gap="12px">
                                <Checkbox
                                    label="Tax returns"
                                    name="appliesToSelfAssessments"
                                    onChange={({ checked }) => setAppliesToSelfAssessments(checked)}
                                    checked={!!appliesToSelfAssessments}
                                />
                                <Checkbox
                                    label="Consultations"
                                    name="appliesToTaxConsultations"
                                    onChange={({ checked }) => setAppliesToTaxConsultations(checked)}
                                    checked={!!appliesToTaxConsultations}
                                />
                                <Checkbox
                                    label="Subscriptions"
                                    name="appliesToSubscriptions"
                                    onChange={({ checked }) => setAppliesToSubscriptions(checked)}
                                    checked={!!appliesToSubscriptions}
                                />
                                <Checkbox
                                    label="Company returns"
                                    name="appliesToCompanyReturns"
                                    onChange={({ checked }) => setAppliesToCompanyReturns(checked)}
                                    checked={!!appliesToCompanyReturns}
                                />
                                <Checkbox
                                    label="Product bundles"
                                    name="appliesToProductBundles"
                                    onChange={({ checked }) => setAppliesToProductBundles(checked)}
                                    checked={!!appliesToProductBundles}
                                />
                            </Flexer>
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Content>
                            <Checkbox
                                label="Is active"
                                name="active"
                                onChange={({ checked }) => setActive(checked)}
                                checked={!!active}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Content>
                            <Checkbox
                                label="Enable unique codes"
                                name="hasUniqueCodeVariants"
                                onChange={({ checked }) => setHasUniqueCodeVariants(checked)}
                                checked={!!hasUniqueCodeVariants}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>
                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onClose} isSecondary>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={isLoadingUpdate}>
                            Save
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
