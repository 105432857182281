import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import { Button } from '@scouts/ui'

import { receiveSuccessAlerts, requestCustomer, requestCustomerIssues, saveSelfAssessmentStatus } from '@/actions'
import { handleError } from '@/handlers'
import { apiRequest } from '@/helpers/axios'
import { useAppDispatch } from '@/store'
import { useRestartIdentityVerification } from '@/store/identity-verifications'

import { TourRequestDocs } from '../feature-tour/TourFirstTaxReturn'
import RequestDocuments from './RequestDocuments'

const RequestDocumentsContainer = ({
    shouldOpenModal,
    customer,
    customerSelfAssessments,
    onModalOpen,
    selfAssessmentId,
}) => {
    const dispatch = useAppDispatch()
    const [state, setState] = useState({
        isFeatureTourCompleted: false,
        isModalOpen: false,
        isSubmitPending: false,
        isSuccess: false,
        sendResponse: {},
    })

    const { restartIdentityVerification } = useRestartIdentityVerification({ customerId: customer.id })

    const openModal = useCallback(() => {
        if (onModalOpen) {
            onModalOpen()
        }

        setState((current) => ({ ...current, isModalOpen: true, isFeatureTourCompleted: false }))
    }, [onModalOpen])

    useEffect(() => {
        if (shouldOpenModal) openModal()
    }, [shouldOpenModal, openModal])

    const closeModal = () => {
        setState((current) => ({ ...current, isModalOpen: false, isSuccess: false, isFeatureTourCompleted: true }))
    }

    const submit = ({
        hideLinkToTaxscouts,
        isIdentityVerification,
        issues,
        messageContent,
        messageType,
        selfAssessmentId,
        selfAssessmentStatus,
    }) => {
        const { isSubmitPending } = state

        if (isSubmitPending) return

        setState((current) => ({ ...current, isSubmitPending: true, isFeatureTourCompleted: true }))

        const issuesWithMeta = issues.map((issue) => ({
            ...issue,
            selfAssessmentId: Number(selfAssessmentId),
            customerId: customer.id,
        }))

        apiRequest('documentrequests', 'post', {
            hideLinkToTaxscouts,
            issues: issuesWithMeta,
            messageContent,
            messageType,
            selfAssessmentId: Number(selfAssessmentId),
        })
            .then((response) => {
                setState((current) => ({
                    ...current,
                    isSuccess: true,
                    isSubmitPending: false,
                    sendResponse: response.data,
                }))

                receiveSuccessAlerts('Request successfully sent')
                requestCustomer(customer.id)
                requestCustomerIssues(customer.id)

                if (isIdentityVerification) {
                    restartIdentityVerification()
                }

                if (selfAssessmentStatus) {
                    saveStatus({ selfAssessmentId, selfAssessmentStatus })
                }
            })
            .catch((error) => {
                dispatch(handleError(error))

                setState((current) => ({ ...current, isSubmitPending: false }))
            })
    }

    const saveStatus = ({ selfAssessmentId, selfAssessmentStatus }) => {
        dispatch(saveSelfAssessmentStatus({ selfAssessmentId, status: selfAssessmentStatus }))
    }

    const { isSubmitPending, isModalOpen, sendResponse, isSuccess, isFeatureTourCompleted } = state

    return (
        <>
            {!isFeatureTourCompleted && customer.isDemoCustomer && <TourRequestDocs onBeaconClick={openModal} />}
            <span className="tour-request-docs">
                <Button onClick={openModal} isOutlined>
                    Request info
                </Button>
            </span>
            <span className="tour-request-docs-observe">
                {isModalOpen && (
                    <RequestDocuments
                        customer={customer}
                        customerSelfAssessments={customerSelfAssessments}
                        isSubmitPending={isSubmitPending}
                        isSuccess={isSuccess}
                        onCancel={closeModal}
                        onSubmit={submit}
                        selfAssessmentId={selfAssessmentId}
                        sendResponse={sendResponse}
                    />
                )}
            </span>
        </>
    )
}

RequestDocumentsContainer.propTypes = {
    customer: PropTypes.object.isRequired,
    customerSelfAssessments: PropTypes.array.isRequired,
    onModalOpen: PropTypes.func,
    selfAssessmentId: PropTypes.number.isRequired,
    shouldOpenModal: PropTypes.bool,
}

RequestDocumentsContainer.defaultProps = {
    shouldOpenModal: false,
    onModalOpen: undefined,
}

export default RequestDocumentsContainer
