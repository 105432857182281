import PropTypes from 'prop-types'
import styled from 'styled-components'

import { hexToRgbA } from '@scouts/helpers'
import { colors, font, shadows, Badge, ButtonLink, Label } from '@scouts/ui'

import { TAX_YEARS } from '../../constants'
import { formatDateWithTime, formatTimeFromNow, formatTimeTodayAndDateIfLater } from '../../helpers'

const Container = styled.div`
    position: relative;
`
const IconPriority = styled.div`
    cursor: default;
    display: inline-block;
    position: absolute;
    top: -11px;
    left: -11px;
    font-weight: ${font.weight.medium};
`

const TaxYear = styled.div`
    cursor: default;
    margin-left: 6px;
    margin-bottom: 6px;
    float: right;
    line-height: 1;
`

const Content = styled.div`
    background: ${colors.white};
    padding: 18px;
    box-shadow: ${shadows.low};

    ${({ isPriority, isResolved }) => isPriority && !isResolved && `border: 2px solid ${colors.blue};`}
    ${({ isResolved }) => isResolved && `background: ${hexToRgbA(colors.white, 0.25)};`}
`

const Body = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    white-space: pre-line;
    overflow-wrap: anywhere;
`

const ResolvedText = styled.div`
    color: ${colors.mint};
    font-size: ${font.smaller};
    margin-top: 8px;
    margin-bottom: -4px;
`

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${font.smaller};
    margin-top: 12px;
    padding: 0 2px;
`

const FooterAction = styled.div``

const FooterText = styled.div``

const CustomerNotesListItem = ({ note, handleResolve, openEditModal }) => {
    const {
        createdByAccountantFullName,
        createdDateUTC,
        isPriority,
        isResolved,
        noteText,
        resolvedByAccountantFullName,
        resolvedDateUTC,
        selfAssessmentTaxYear,
    } = note

    return (
        <Container>
            {isPriority && !isResolved && (
                <IconPriority>
                    <Badge>!</Badge>
                </IconPriority>
            )}

            <Content isPriority={isPriority} isResolved={isResolved}>
                {selfAssessmentTaxYear && (
                    <TaxYear>
                        <Label background={colors.black} color={colors.white}>
                            {TAX_YEARS[selfAssessmentTaxYear]}
                        </Label>
                    </TaxYear>
                )}

                <Body>{noteText}</Body>

                {isResolved && (
                    <ResolvedText title={formatDateWithTime(resolvedDateUTC)}>
                        ✓ Resolved {formatTimeFromNow(resolvedDateUTC)} by {resolvedByAccountantFullName}
                    </ResolvedText>
                )}
            </Content>

            <Footer>
                <FooterAction>
                    {handleResolve && (
                        <ButtonLink isSmall onClick={() => handleResolve(note)}>
                            {isResolved ? 'Unresolve' : 'Resolve'}
                        </ButtonLink>
                    )}
                    {openEditModal && (
                        <>
                            &nbsp;&nbsp;·&nbsp;&nbsp;
                            <ButtonLink isSmall onClick={() => openEditModal(note)}>
                                Edit
                            </ButtonLink>
                        </>
                    )}
                </FooterAction>

                <FooterText title={formatDateWithTime(createdDateUTC)}>
                    {formatTimeTodayAndDateIfLater(createdDateUTC, ' ')} · {createdByAccountantFullName}
                </FooterText>
            </Footer>
        </Container>
    )
}

CustomerNotesListItem.defaultProps = {
    openEditModal: null,
    handleResolve: null,
}

CustomerNotesListItem.propTypes = {
    note: PropTypes.object.isRequired,
    handleResolve: PropTypes.func,
    openEditModal: PropTypes.func,
}

export default CustomerNotesListItem
