import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { formatDate } from '@/helpers'

import { colors, font, ButtonLink, Label } from '@scouts/ui'

import { ISSUE_TYPE_BANK_DETAILS_MISSING, PATH_CUSTOMERS } from '@/constants'
import { useCustomersBankAccount } from '@/store/bank-account'

import { ClickToCopyProvider } from '@/components/ClickToCopyProvider'

import { BankDetailsRequest } from '../bank-details/BankDetailsRequest'

const CustomerProfileBankDetails = ({ customer, hasBankDetailsRequested }) => {
    const history = useHistory()

    const { id: customerId, hasBankAccountDetails } = customer

    const { bankAccount } = useCustomersBankAccount({ customerId })

    const { accountNumber, bankName, sortCode, updatedDateUTC } = bankAccount || {}

    return (
        <>
            <Container>
                <Title>Bank details</Title>
                <Actions>
                    <Action>
                        <ButtonLink isSmall onClick={() => history.push(`${PATH_CUSTOMERS}/${customerId}/bankdetails`)}>
                            {hasBankAccountDetails ? 'Edit' : 'Add'}
                        </ButtonLink>
                    </Action>

                    <Action>
                        <BankDetailsRequest customer={customer} hasBankDetailsRequested={hasBankDetailsRequested} />
                    </Action>
                </Actions>
            </Container>
            {bankName && (
                <Field>
                    <ClickToCopyProvider>{bankName}</ClickToCopyProvider>
                </Field>
            )}
            {sortCode && (
                <Field>
                    <ClickToCopyProvider>{sortCode}</ClickToCopyProvider>
                </Field>
            )}
            {accountNumber && (
                <Field>
                    <ClickToCopyProvider>{accountNumber}</ClickToCopyProvider>
                </Field>
            )}
            {updatedDateUTC && (
                <Field>
                    <Label background={colors.neutralLightest} color={colors.neutralDarker}>
                        Updated {formatDate(updatedDateUTC)}
                    </Label>
                </Field>
            )}
        </>
    )
}

CustomerProfileBankDetails.propTypes = {
    customer: PropTypes.object.isRequired,
    hasBankDetailsRequested: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ issues }, ownProps) => {
    const { customer } = ownProps

    const hasBankDetailsRequested = _find(_get(issues, ['data']), {
        issueType: ISSUE_TYPE_BANK_DETAILS_MISSING,
        resolved: false,
        customerId: customer.id,
    })

    return {
        hasBankDetailsRequested: !!hasBankDetailsRequested,
    }
}

export default connect(mapStateToProps)(withRouter(CustomerProfileBankDetails))

const Container = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`

const Title = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.smaller};
`

const Actions = styled.div`
    display: flex;
`

const Action = styled.div`
    &:not(:first-child) {
        margin-left: 12px;
    }
`

const Field = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 6px;
`
