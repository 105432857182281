import { generateApiPath } from '@scouts/helpers'

import { ERROR_MESSAGE_DOWNLOADING } from '@/constants'
import { generateMessageFormData } from '@/domain/message'
import { MessageDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { ResponseTransformers } from '../error-handling/responseTransformers'
import { store } from '../store'
import { Tags } from '../tags'

export interface SendCustomerMessageParams {
    customerId: number
    messageContent: string
    messageType: string
    subject: string
    attachments?: File[]
}

export interface SendCustomersMessageParams {
    subject: string
    messageContent: string
    customerIds: number[]
    messageType: string
}

export interface SendCustomersMessageResponse {
    success: number
    fail: number
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerMessages: builder.query<MessageDTO[], { customerId: number }>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.MESSAGES.GET, { customerId }),
            }),
            providesTags: (_0, _1, { customerId }) => [Tags.Messages, { type: Tags.Messages, id: customerId }],
        }),
        sendCustomerMessage: builder.mutation<MessageDTO, SendCustomerMessageParams>({
            query: (params) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.MESSAGES.POST),
                body: generateMessageFormData(params),
            }),
            invalidatesTags: (_0, _1, { customerId }) => [{ type: Tags.Messages, id: customerId }],
        }),
        sendCustomersMessage: builder.mutation<SendCustomersMessageResponse, SendCustomersMessageParams>({
            query: (params) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.MESSAGES.POST_BULK),
                body: params,
            }),
            invalidatesTags: (_0, _1, { customerIds }) => customerIds.map((id) => ({ type: Tags.Messages, id })),
        }),
        getMessageAttachmentLink: builder.query<string, { fileId: number }>({
            query: ({ fileId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.MESSAGES.GET_ATTACHMENT_LINK, { fileId }),
                responseHandler: 'text',
            }),
            transformErrorResponse: ResponseTransformers.customMessage(ERROR_MESSAGE_DOWNLOADING),
        }),
    }),
})

export const {
    useGetCustomerMessagesQuery,
    useSendCustomerMessageMutation,
    useSendCustomersMessageMutation,
    useLazyGetMessageAttachmentLinkQuery,
} = extendedApi

export const invalidateCustomerMessages = (customerId: number) =>
    store.dispatch(apiSlice.util.invalidateTags([Tags.Messages, { type: Tags.Messages, id: customerId }]))
