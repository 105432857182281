import { all } from 'redux-saga/effects'

import accountantsSagas from './accountants'
import authSagas from './auth'
import conversationsSagas from './conversations'
import customerDiscountsSagas from './customer-discounts'
import customerFilesSagas from './customer-files'
import customersSagas from './customers'
import employmentsSagas from './employments'
import escalationsSagas from './escalations'
import filesSagas from './files'
import integrationsSagas from './integrations'
import issuesSagas from './issues'
import notesSagas from './notes'
import productBundlesSagas from './product-bundles'
import quickSearchSagas from './quick-search'
import returningCustomersSagas from './returning-customers'
import rewardsSagas from './rewards'
import searchSagas from './search'
import selfAssessmentsSagas from './self-assessments'
import selfEmploymentSagas from './self-employment'
import statisticsSagas from './statistics'
import toolsSagas from './tools'
import userSagas from './user'

export default function* sagas() {
    yield all([
        accountantsSagas(),
        authSagas(),
        conversationsSagas(),
        customerDiscountsSagas(),
        customerFilesSagas(),
        customersSagas(),
        employmentsSagas(),
        escalationsSagas(),
        filesSagas(),
        integrationsSagas(),
        issuesSagas(),
        notesSagas(),
        productBundlesSagas(),
        quickSearchSagas(),
        returningCustomersSagas(),
        rewardsSagas(),
        searchSagas(),
        selfAssessmentsSagas(),
        selfEmploymentSagas(),
        statisticsSagas(),
        toolsSagas(),
        userSagas(),
    ])
}
