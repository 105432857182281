import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, media, radius, shadows, Container } from '@scouts/ui'

interface CardProps {
    background?: string
    linkTo?: string
    title: string
    value?: string | number
    valueSecondary?: string | number
}

export const Card = ({ background, linkTo, title, value, valueSecondary }: CardProps) => {
    if (linkTo) {
        return (
            <CardLink to={linkTo} background={background}>
                <Title>{title}</Title>
                <Value>
                    {value !== undefined ? value : '-'}
                    {valueSecondary !== undefined && <ValueSecondary>({valueSecondary})</ValueSecondary>}
                </Value>
            </CardLink>
        )
    }

    return (
        <Container padding="18px 24px" background={background || colors.neutralLightest} radius={radius.medium}>
            <Title>{title}</Title>
            <Value>
                {value !== undefined ? value : '-'}
                {valueSecondary !== undefined && <ValueSecondary>({valueSecondary})</ValueSecondary>}
            </Value>
        </Container>
    )
}

const CardLink = styled(Link)<{ background?: string }>`
    background: ${({ background }) => background || colors.neutralLightest};
    color: ${colors.black};
    border-radius: ${radius.medium};
    text-decoration: none;
    display: block;
    padding: 18px 24px;

    ${media.pointer} {
        cursor: pointer;

        &:hover {
            box-shadow: ${shadows.medium};
        }

        &:active {
            box-shadow: none;
        }
    }
`

const Title = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    margin-bottom: 8px;
`

const Value = styled.div`
    font-size: ${font.normal};
    font-weight: ${font.weight.medium};
    line-height: 1.3;
`

const ValueSecondary = styled.span`
    font-size: ${font.small};
    font-weight: normal;
    margin-left: 9px;
`
