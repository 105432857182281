import { featureFlags } from '@/feature-flags'

import { Banner, BannerHeading } from './Banner'

export const BannerHoliday = () => {
    const shouldDisplayHolidayBanner = featureFlags.featureHolidayBanner

    if (!shouldDisplayHolidayBanner) return null

    return (
        <Banner>
            <BannerHeading>
                <span aria-label="Tree emoji" role="img">
                    🎄
                </span>{' '}
                We wish you a happy holiday period
            </BannerHeading>
            <p>The year is almost over and we all deserve a little breather before January.</p>
            <p>Just make sure you set your status as 'Paused' if you plan to take the time off.</p>
            <p>Happy holidays from everyone at TaxScouts.</p>
        </Banner>
    )
}
