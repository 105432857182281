import _orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { Table } from '@scouts/ui'

import { getCompanyNameNumberedPlaceholder } from '@/domain'
import { useCompanies } from '@/store/companies/hooks'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'
import { useUser } from '@/hooks/use-user'

import { CreateCompanyReturn } from './CreateCompanyReturn'
import { CustomerOverviewCompany } from './CustomerOverviewCompany'

export const CustomerOverviewCompanyReturns = () => {
    const { isAdminUser } = useUser()
    const customerId = useCustomerIdFromPath()

    const { companies, isLoading } = useCompanies({ customerId })

    const orderedCompanies = useMemo(() => _orderBy(companies, 'id', 'desc'), [companies])

    const hasCompanies = companies && companies.length > 0
    const shouldDisplayCreateButton = isAdminUser

    if (!hasCompanies && isLoading) return null

    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="75%">
                        {hasCompanies ? 'Company returns' : "This client hasn't started any company returns yet"}
                    </Table.Header.Cell>
                    <Table.Header.Cell width="25%" alignRight>
                        {shouldDisplayCreateButton && <CreateCompanyReturn />}
                    </Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>

            {hasCompanies && (
                <Table.Body>
                    {/* @TODO: Order company returns by status updated date. Avoid grouping by company. */}
                    {orderedCompanies.map((company) => (
                        <CustomerOverviewCompany
                            key={company.id}
                            company={company}
                            companyNamePlaceholder={getCompanyNameNumberedPlaceholder(company, companies)}
                        />
                    ))}
                </Table.Body>
            )}
        </Table>
    )
}
