import { font, Button, Line, Spacer } from '@scouts/ui'

import { CustomerDTO } from '@/models'
import { useCreateIdentityVerification } from '@/store/identity-verifications'

import { RequestContainer } from './actions/RequestContainer'
import { VerificationWidget } from './VerificationWidget'

interface VerificationCreateProps {
    customer: CustomerDTO
}

export const VerificationCreate = ({ customer }: VerificationCreateProps) => {
    const { createIdentityVerification, isLoading } = useCreateIdentityVerification()

    const create = () => {
        createIdentityVerification({ customerId: customer.id })
    }

    return (
        <VerificationWidget
            actions={
                <>
                    <RequestContainer buttonLabel="Send request" customer={customer} identityVerification={null} />
                    <Button onClick={create} isLoading={isLoading}>
                        Mark as verified
                    </Button>
                </>
            }
        >
            <Spacer marginBottom="9px">
                <Line weight={font.weight.medium}>Identity not yet verified</Line>
            </Spacer>
            <Line size={font.small}>Start the KYC process by sending an automatic verification request</Line>
        </VerificationWidget>
    )
}
