import { useToggle } from '@scouts/helpers'
import { colors, font, ButtonLink, Flexer, Indicators, Label, Line, Spacer, Table } from '@scouts/ui'

import { DiscountSchemeDTO, ProductType } from '@/models'

import { DiscountSchemesDeleteModal } from './DiscountSchemesDeleteModal'
import { DiscountSchemesEditModal } from './DiscountSchemesEditModal'
import { DiscountSchemesIndicator } from './DiscountSchemesIndicator'
import { DiscountSchemesUniqueCodesModal } from './DiscountSchemesUniqueCodesModal'

interface DiscountSchemesTableRowProps {
    discountScheme: DiscountSchemeDTO
}

export const DiscountSchemesTableRow = ({ discountScheme }: DiscountSchemesTableRowProps) => {
    const {
        active,
        appliesToSelfAssessments,
        appliesToCompanyReturns,
        appliesToSubscriptions,
        appliesToTaxConsultations,
        appliesToProductBundles,
        discountCode,
        durationMonths,
        hasUniqueCodeVariants,
        name,
        percentageDiscount,
        redemptionLimit,
        userDiscountsCount,
    } = discountScheme

    const [isUniqueCodesModalOpen, openUniqueCodesModalModal, closeUniqueCodesModalModal] = useToggle()
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useToggle()
    const [isEditModalOpen, openEditModal, closeEditModal] = useToggle()

    const isDeletionEnabled = userDiscountsCount === 0

    return (
        <>
            <Table.Body.Row>
                <Table.Body.Cell width="20%">
                    <Table.Body.Cell.Content>
                        <Spacer marginRight="36px">
                            <Line size={font.small}>{name}</Line>
                        </Spacer>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="8%">
                    <Table.Body.Cell.Content>
                        <Line size={font.small}>{percentageDiscount}%</Line>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="8%">
                    <Table.Body.Cell.Content>
                        <Line size={font.small}>{durationMonths}</Line>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="10%">
                    <Table.Body.Cell.Content>
                        {hasUniqueCodeVariants ? (
                            <ButtonLink onClick={openUniqueCodesModalModal}>Unique codes</ButtonLink>
                        ) : (
                            <Label background={colors.neutralLightest} color={colors.black}>
                                {discountCode}
                            </Label>
                        )}
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="16%">
                    <Table.Body.Cell.Content>
                        <Indicators>
                            <DiscountSchemesIndicator
                                productType={ProductType.SelfAssessment}
                                isApplicable={appliesToSelfAssessments}
                            />
                            <DiscountSchemesIndicator
                                productType={ProductType.TaxConsultation}
                                isApplicable={appliesToTaxConsultations}
                            />
                            <DiscountSchemesIndicator
                                productType={ProductType.Subscription}
                                isApplicable={appliesToSubscriptions}
                            />
                            <DiscountSchemesIndicator
                                productType={ProductType.CompanyReturn}
                                isApplicable={appliesToCompanyReturns}
                            />
                            <DiscountSchemesIndicator
                                productType={ProductType.ProductBundle}
                                isApplicable={appliesToProductBundles}
                            />
                        </Indicators>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="8%">
                    <Table.Body.Cell.Content>
                        <Line size={font.small}>{userDiscountsCount}</Line>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="10%">
                    <Table.Body.Cell.Content>
                        {redemptionLimit ? <Line size={font.small}>{redemptionLimit}</Line> : '-'}
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="10%">
                    <Table.Body.Cell.Content>
                        <Label background={active ? colors.mintLighter : colors.red} color={colors.black}>
                            {active ? 'Active' : 'Disabled'}
                        </Label>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>

                <Table.Body.Cell width="10%">
                    <Table.Body.Cell.Content alignRight>
                        <Flexer gap="24px">
                            {isDeletionEnabled && (
                                <ButtonLink color={colors.red} onClick={openDeleteModal}>
                                    Delete
                                </ButtonLink>
                            )}
                            <ButtonLink onClick={openEditModal}>Edit</ButtonLink>
                        </Flexer>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>
            </Table.Body.Row>

            {isDeleteModalOpen && (
                <DiscountSchemesDeleteModal discountScheme={discountScheme} onClose={closeDeleteModal} />
            )}

            {isEditModalOpen && <DiscountSchemesEditModal discountScheme={discountScheme} onClose={closeEditModal} />}

            {isUniqueCodesModalOpen && (
                <DiscountSchemesUniqueCodesModal discountScheme={discountScheme} onClose={closeUniqueCodesModalModal} />
            )}
        </>
    )
}
