import FileSaver from 'file-saver'
import _get from 'lodash/get'
import queryString from 'query-string'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    receiveAccountantStatistics,
    receiveAccountantStatusStatistics,
    receiveCustomersLastYearStatistics,
    receiveCustomersStatistics,
    receiveRevenueStats,
    receiveSelfAssessmentsStatistics,
    receiveTaxConsultationsStatistics,
    receiveTaxRegistrationsStatistics,
    DOWNLOAD_KYC_REPORT,
    DOWNLOAD_REVENUE_REPORT,
    REQUEST_ACCOUNTANT_STATISTICS,
    REQUEST_ACCOUNTANT_STATUS_STATISTICS,
    REQUEST_CUSTOMERS_LAST_YEAR_STATISTICS,
    REQUEST_CUSTOMERS_STATISTICS,
    REQUEST_REVENUE_STATS,
    REQUEST_SELF_ASSESSMENTS_STATISTICS,
    REQUEST_TAX_CONSULTATIONS_STATISTICS,
    REQUEST_TAX_REGISTRATIONS_STATISTICS,
} from '../actions'
import { handleError } from '../handlers'
import { apiCall, getPaginationHeaders } from '../helpers'

export default function* statisticsSagas() {
    yield takeEvery(REQUEST_ACCOUNTANT_STATUS_STATISTICS, fetchAccountantStatusStatistics)
    yield takeEvery(REQUEST_ACCOUNTANT_STATISTICS, fetchAccountantStatistics)
    yield takeEvery(REQUEST_SELF_ASSESSMENTS_STATISTICS, fetchSelfAssessmentsStatistics)
    yield takeEvery(REQUEST_TAX_REGISTRATIONS_STATISTICS, fetchTaxRegistrationsStatistics)
    yield takeEvery(REQUEST_TAX_CONSULTATIONS_STATISTICS, fetchTaxConsultationsStatistics)
    yield takeLatest(REQUEST_CUSTOMERS_LAST_YEAR_STATISTICS, fetchCustomersLastYearStatistics)
    yield takeLatest(REQUEST_CUSTOMERS_STATISTICS, fetchCustomersStatistics)
    yield takeLatest(REQUEST_REVENUE_STATS, fetchRevenueStats)
    yield takeLatest(DOWNLOAD_REVENUE_REPORT, downloadRevenueReport)
    yield takeLatest(DOWNLOAD_KYC_REPORT, downloadKYCReport)
}

function* fetchCustomersLastYearStatistics(action) {
    const accountantId = _get(action, ['data', 'accountantId'], null)

    const url = `customers/returningcustomersstatistics${accountantId ? `?accountantId=${accountantId}` : ''}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url)
        yield put(receiveCustomersLastYearStatistics(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchCustomersStatistics(action) {
    const productStatus = _get(action, ['data', 'productStatus'], '')
    const accountantId = _get(action, ['data', 'accountantId'], '')

    const url = `customers/customerstatistics?productStatus=${productStatus || ''}&accountantId=${accountantId || ''}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url)
        yield put(receiveCustomersStatistics(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchSelfAssessmentsStatistics(action) {
    const accountantId = _get(action, ['data', 'accountantId'], null)
    const daysOverdue = _get(action, ['data', 'daysOverdue'], null)
    const returnOnHold = _get(action, ['data', 'returnOnHold'], false)
    const includeUnpaid = _get(action, ['data', 'includeUnpaid'], false)

    const urlComponents = queryString.stringify({
        accountantId,
        daysOverdue,
        returnOnHold,
        ...(includeUnpaid === 'true' ? { includeUnpaid: true } : { includeUnpaid: false }),
    })

    const url = `selfassessments/statistics?${urlComponents}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url)
        yield put(receiveSelfAssessmentsStatistics(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchTaxRegistrationsStatistics(action) {
    const accountantId = _get(action, ['data', 'accountantId'], null)
    const includeUnpaid = _get(action, ['data', 'includeUnpaid'], false)

    const urlComponents = queryString.stringify({
        ...(includeUnpaid === 'true' ? { includeUnpaid: true } : { includeUnpaid: false }),
        ...(accountantId && { accountantId }),
    })

    const url = `taxregistrations/statistics?${urlComponents}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url)
        yield put(receiveTaxRegistrationsStatistics(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchTaxConsultationsStatistics(action) {
    const accountantId = _get(action, ['data', 'accountantId'], null)

    const urlComponents = queryString.stringify({
        ...(accountantId && { accountantId }),
    })

    const url = `taxconsultations/statistics?${urlComponents}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url)
        yield put(receiveTaxConsultationsStatistics(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchAccountantStatusStatistics() {
    try {
        yield put(showLoading())
        const response = yield apiCall('accountantstatistics/status')
        yield put(receiveAccountantStatusStatistics(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchAccountantStatistics(action) {
    const status = _get(action, ['data', 'status'], '')
    const isOnTrial = _get(action, ['data', 'isOnTrial'], '')
    const allowTaxConsultation = _get(action, ['data', 'allowTaxConsultation'], '')
    const textFilter = _get(action, ['data', 'textFilter'], '')

    const page = _get(action, ['meta', 'page'], '')
    const pageSize = _get(action, ['meta', 'pageSize'], '')

    const urlComponents = queryString.stringify({
        ...(status && { status }),
        ...(isOnTrial && { isOnTrial }),
        ...(allowTaxConsultation && { allowTaxConsultation }),
        ...(textFilter && { textFilter }),
        ...(page && { page }),
        ...(pageSize && { pageSize }),
    })

    try {
        yield put(showLoading())
        const response = yield apiCall(`accountantstatistics?${urlComponents}`)
        const pagination = getPaginationHeaders(response.headers)
        const isEmptySearchResults = !!response.data && response.data.length === 0
        yield put(receiveAccountantStatistics(response.data, { pagination, isEmptySearchResults }))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchRevenueStats(action) {
    try {
        yield put(showLoading())
        const { accountantId } = action.data
        const response = yield apiCall(`accountants/${accountantId}/revenuestats`)
        yield put(receiveRevenueStats(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* downloadRevenueReport(action) {
    const { accountantId, year, month } = action.data

    try {
        yield put(showLoading())
        const response = yield apiCall(`accountants/${accountantId}/revenuereport?year=${year}&month=${month}`)

        if (response.data) {
            const file = new Blob([response.data], {})
            FileSaver.saveAs(file, `revenue-report-${year}-${month}.csv`)
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* downloadKYCReport(action) {
    const { accountantId, year, month } = action.data

    try {
        yield put(showLoading())
        const response = yield apiCall(`accountants/${accountantId}/kycreport?year=${year}&month=${month}`)

        if (response.data) {
            const file = new Blob([response.data], {})
            const fileName = year === '' ? 'kyc-report-all-time.csv' : `kyc-report-${year}-${month}.csv`
            FileSaver.saveAs(file, fileName)
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
