import { colors, font, Flexer, Line } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { CustomerDTO, IdentityVerificationDTO } from '@/models'

import { ReviewContainer } from '../actions/ReviewContainer'
import { VerificationAccountant } from '../VerificationAccountant'
import { VerificationWidget } from '../VerificationWidget'

interface VerifiedProps {
    customer: CustomerDTO
    identityVerification: IdentityVerificationDTO
}

export const Verified = ({ identityVerification, customer }: VerifiedProps) => {
    const { accountantId, accountantFullName, updatedDateUTC } = identityVerification

    return (
        <VerificationWidget
            background={colors.mintLighter}
            actions={
                <>
                    <ReviewContainer
                        buttonLabel="View docs"
                        customer={customer}
                        identityVerification={identityVerification}
                    />

                    <ReviewContainer
                        buttonLabel="Start again"
                        customer={customer}
                        identityVerification={identityVerification}
                    />
                </>
            }
        >
            <Flexer column gap="9px" justifyStart>
                <Line weight={font.weight.medium}>Identity successfully verified - {formatDate(updatedDateUTC)}</Line>

                <Line size={font.small}>Client identity is marked as verified</Line>

                <VerificationAccountant accountantId={accountantId} accountantFullName={accountantFullName} />
            </Flexer>
        </VerificationWidget>
    )
}
