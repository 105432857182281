import { generateApiPath } from '@scouts/helpers'

import { ERROR_MESSAGE_DOWNLOADING } from '@/constants'
import { FileTypeTypes, TaxConsultationDTO, TaxConsultationFileDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { ResponseTransformers } from '../error-handling/responseTransformers'
import { Tags } from '../tags'

export interface GetTaxConsultationFilesParams {
    taxConsultationId: TaxConsultationDTO['id']
}

export interface UploadTaxConsultationFileParams {
    taxConsultationId: TaxConsultationDTO['id']
    fileType: FileTypeTypes
}

export interface DeleteTaxConsultationFileParams {
    taxConsultationFileId: TaxConsultationFileDTO['id']
}

export interface DownloadTaxConsultationFileParams {
    taxConsultationFileId: TaxConsultationFileDTO['id']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTaxConsultationFiles: builder.query<TaxConsultationFileDTO[], GetTaxConsultationFilesParams>({
            query: ({ taxConsultationId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATION_FILES.GET.ALL, {
                    taxConsultationId,
                }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.TaxConsultationFiles, id: arg.taxConsultationId }],
        }),
        uploadTaxConsultationFile: builder.mutation<void, { file: File; params: UploadTaxConsultationFileParams }>({
            query: ({ file, params }) => {
                const { fileType, taxConsultationId } = params
                const formData = new FormData()
                formData.append('file', file)

                return {
                    method: 'POST',
                    url: generateApiPath(ENDPOINTS.TAX_CONSULTATION_FILES.POST, undefined, {
                        queryParameters: { fileType, taxConsultationId },
                    }),
                    body: formData,
                    formData: true,
                }
            },
            invalidatesTags: [Tags.TaxConsultationFiles],
        }),
        deleteTaxConsultationFile: builder.mutation<void, DeleteTaxConsultationFileParams>({
            query: ({ taxConsultationFileId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATION_FILES.DELETE, {
                    taxConsultationFileId,
                }),
            }),
            invalidatesTags: [Tags.TaxConsultationFiles],
        }),
        downloadTaxConsultationFile: builder.query<string, DownloadTaxConsultationFileParams>({
            query: ({ taxConsultationFileId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_CONSULTATION_FILES.GET.DOWNLOAD_FILE, {
                    taxConsultationFileId,
                }),
                responseHandler: 'text',
            }),
            transformErrorResponse: ResponseTransformers.customMessage(ERROR_MESSAGE_DOWNLOADING),
        }),
    }),
})

export const {
    useGetTaxConsultationFilesQuery,
    useUploadTaxConsultationFileMutation,
    useDeleteTaxConsultationFileMutation,
    useLazyDownloadTaxConsultationFileQuery,
} = extendedApi
