import { useHistory } from 'react-router-dom'

import { PATH_INTEGRATIONS } from '@/constants'

import { GlobalNotice, GlobalNoticeProps } from './GlobalNotice'

type NoticePayeIntegrationProps = {
    onClose: GlobalNoticeProps['onClose']
}

const NoticePayeIntegration = ({ onClose }: NoticePayeIntegrationProps) => {
    const history = useHistory()

    return (
        <GlobalNotice
            onClose={onClose}
            buttonText="Start here"
            onButtonClick={() => {
                history.push(PATH_INTEGRATIONS)
            }}
        >
            To automatically pull PAYE income information from HMRC connect your TaxScouts account to PAYE API.
        </GlobalNotice>
    )
}

export default NoticePayeIntegration
