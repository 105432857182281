import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { colors, font, ButtonLink, Spinner } from '@scouts/ui'

import { getCustomerConversations, getIsFetchingConversations } from '@/selectors'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { ConversationCard } from './ConversationCard'
import { ConversationMessages } from './ConversationMessages'

export const Conversations = () => {
    const { customer } = useCustomerFromPath()
    const { id: customerId } = customer

    const [activeConversationId, setActiveConversationId] = useState(null)

    const conversations = useSelector((state) => getCustomerConversations(state, { customerId }))
    const isLoading = useSelector(getIsFetchingConversations)

    const handleNavigateToList = () => {
        setActiveConversationId(null)
    }

    const navigateToConversation = (id) => {
        setActiveConversationId(id)
    }

    const hasConversations = conversations.length > 0

    return (
        <Container>
            {!hasConversations && (
                <>
                    {isLoading && <Spinner isSmall color={colors.blue} />}
                    {!isLoading && <Empty>No chats found</Empty>}
                </>
            )}

            {!activeConversationId &&
                conversations.map((conversation) => (
                    <ConversationCard
                        key={conversation.id}
                        conversation={conversation}
                        navigateToConversation={navigateToConversation}
                    />
                ))}

            {activeConversationId && (
                <>
                    <Actions>
                        <ButtonLink onClick={handleNavigateToList}>Back</ButtonLink>
                    </Actions>
                    <ConversationMessages conversationId={activeConversationId} />
                    <Actions>
                        <ButtonLink onClick={handleNavigateToList}>Back</ButtonLink>
                    </Actions>
                </>
            )}
        </Container>
    )
}

const Container = styled.div`
    position: relative;
`

const Actions = styled.div`
    &:not(:first-child) {
        margin-top: 18px;
    }
    &:not(:last-child) {
        margin-bottom: 18px;
    }
`

const Empty = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
`
