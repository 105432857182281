import { useEffect } from 'react'

import { useQueryParams } from '@scouts/helpers'
import { colors, Container, Flexer, Line, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { useTaxReliefClaims } from '@/store/tax-relief-claims'

import { useFilters } from './hooks'

import { usePagination } from '../pagination'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { TaxReliefClaimsSearchFilter } from './search/TaxReliefClaimsSearchFilter'
import { TaxReliefClaimsTable } from './TaxReliefClaimsTable'

export const TaxReliefClaims = () => {
    const { activeFilters: activePagination } = usePagination()
    const { activeFilters, applyFilter, DEFAULT_FILTER } = useFilters()
    const { queryParams } = useQueryParams()

    useEffect(() => {
        const { filter } = queryParams

        if (!filter) {
            applyFilter({ updatedFilter: { ...DEFAULT_FILTER } })
        }
    }, [DEFAULT_FILTER, applyFilter, queryParams])

    const { taxReliefClaims, isLoading, isError, pagination } = useTaxReliefClaims({
        options: { ...activeFilters, ...activePagination },
    })

    const itemsCount = taxReliefClaims?.length || 0
    const hasTaxReliefClaims = itemsCount > 0

    if (isError)
        return (
            <Flexer alignCenter justifyCenter>
                <Line color={colors.neutralDarker}>Error getting tax relief claims</Line>
            </Flexer>
        )

    if (isLoading && !hasTaxReliefClaims)
        return (
            <Skeleton gap="24px" padding="24px">
                <SkeletonLine height="77px" width="80%" />
                <Flexer column gap="1px">
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="60px" repeat={10} />
                </Flexer>
            </Skeleton>
        )

    return (
        <Container padding="24px 24px 192px">
            <Spacer marginBottom="24px">
                <TaxReliefClaimsSearchFilter pagination={pagination} itemsCount={taxReliefClaims?.length || 0} />
            </Spacer>

            {!isLoading && !hasTaxReliefClaims && <Line color={colors.neutralDarker}>No tax relief claims found</Line>}

            {hasTaxReliefClaims && (
                <>
                    <TaxReliefClaimsTable taxReliefClaims={taxReliefClaims} />
                    <Spacer marginTop="24px">
                        <PaginationContainer pagination={pagination} />
                    </Spacer>
                </>
            )}
        </Container>
    )
}
