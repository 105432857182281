import { Flexer } from '@scouts/ui'

import { CompanyDTO } from '@/models'
import { useCompanyReturns } from '@/store/company-returns/hooks'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { CustomerOverviewCompanyReturn } from './CustomerOverviewCompanyReturn'

export const CustomerOverviewCompany = ({
    company,
    companyNamePlaceholder,
}: {
    company: CompanyDTO
    companyNamePlaceholder: string
}) => {
    const customerId = useCustomerIdFromPath()

    const { companyReturns } = useCompanyReturns({ customerId, companyId: company.id, skip: !company || !company.id })

    const hasCompanyReturns = companyReturns && companyReturns.length > 0

    if (!hasCompanyReturns) return null

    return (
        <Flexer gap="6px" column>
            {companyReturns.map((companyReturn) => (
                <CustomerOverviewCompanyReturn
                    key={companyReturn.id}
                    companyReturn={companyReturn}
                    companyNamePlaceholder={companyNamePlaceholder}
                />
            ))}
        </Flexer>
    )
}
