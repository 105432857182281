import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Grid, Line, Spacer } from '@scouts/ui'

import { requestSelfAssessmentsStatistics } from '@/actions'
import {
    PATH_SELF_ASSESSMENTS,
    SELF_ASSESSMENT_STATUS_FILTER_HOLD,
    SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW,
    SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED,
} from '@/constants'
import { AccountantDetailsDTO } from '@/models'
import { getFullStatistics } from '@/selectors'
import { useAppDispatch } from '@/store'

import { Card } from './Card'

export const DashboardStatsSelfAssessments = ({
    accountantId,
    isOnTrial,
}: {
    accountantId: AccountantDetailsDTO['id']
    isOnTrial: AccountantDetailsDTO['isOnTrial']
}) => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const statistics = useSelector(getFullStatistics)

    useEffect(() => {
        dispatch(requestSelfAssessmentsStatistics({ accountantId }))
    }, [accountantId, dispatch])

    const { selfAssessmentsInProgress, selfAssessmentsOnHold, selfAssessmentsSubmitted, selfAssessmentsReadyToFile } =
        statistics

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Tax returns
                    </Line>
                    <ButtonLink
                        color={colors.neutralDarker}
                        onClick={() => history.push(`${PATH_SELF_ASSESSMENTS}?accountantId=${accountantId}`)}
                    >
                        View all
                    </ButtonLink>
                </Flexer>
            </Spacer>

            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                <Card
                    background={colors.blueLighter}
                    linkTo={`${PATH_SELF_ASSESSMENTS}?accountantId=${accountantId}&status=${SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED}`}
                    title="Ready to start"
                    value={selfAssessmentsSubmitted}
                />

                <Card
                    linkTo={`${PATH_SELF_ASSESSMENTS}?accountantId=${accountantId}&status=${SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW}`}
                    title="In progress"
                    background={colors.yellow}
                    value={selfAssessmentsInProgress}
                />
                {!isOnTrial && (
                    <Card
                        linkTo={`${PATH_SELF_ASSESSMENTS}?accountantId=${accountantId}&status=${SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE}`}
                        title="Ready to file"
                        background={colors.mintLighter}
                        value={selfAssessmentsReadyToFile}
                    />
                )}
                {!isOnTrial && (
                    <Card
                        linkTo={`${PATH_SELF_ASSESSMENTS}?accountantId=${accountantId}&status=${SELF_ASSESSMENT_STATUS_FILTER_HOLD}`}
                        title="On hold"
                        value={selfAssessmentsOnHold}
                    />
                )}
            </Grid>
        </Container>
    )
}
