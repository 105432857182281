import { useHistory } from 'react-router-dom'

import { ButtonLink } from '@scouts/ui'

import { PATH_SELF_ASSESSMENTS } from '@/constants'

import { Banner, BannerHeading } from './Banner'

export const BannerTrialWelcome = () => {
    const history = useHistory()

    return (
        <Banner>
            <BannerHeading>
                <span aria-label="Waving hand sign emoji" role="img">
                    👋
                </span>{' '}
                Welcome to TaxScouts
            </BannerHeading>
            <p>
                For a quick overview of the TaxScouts portal we’ve prepared a simple product tour to introduce some of
                the features:
            </p>
            <p>
                → Navigate to “<ButtonLink onClick={() => history.push(PATH_SELF_ASSESSMENTS)}>Tax returns</ButtonLink>”
                list from the main menu above
            </p>
            <p>→ Open up the “Demo Customer”</p>
        </Banner>
    )
}
