import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TypeSelect } from '@scouts/ui'

import SelfAssessmentsFilterUnpaid from './SelfAssessmentsFilterUnpaid'

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const Accountants = styled.div`
    flex: 0 1 320px;
    height: 35px;
    margin-right: 24px;
`

const Filter = styled.div``

const SelfAssessmentsSupportFilters = (props) => {
    const { accountants, hasLoaded, filterAccountant, filterIncludeUnpaid, handleAccountantChange, toggleUnpaid } =
        props

    return (
        <Container>
            <Accountants>
                <TypeSelect
                    name="filterAccountant"
                    value={Number(filterAccountant) || ''}
                    options={[{ value: '', title: 'All accountants' }, ...accountants]}
                    onChange={handleAccountantChange}
                    autoClear
                    isRequired
                    showOptions
                    disabled={!hasLoaded}
                />
            </Accountants>
            <Filter>
                <SelfAssessmentsFilterUnpaid
                    filterIncludeUnpaid={filterIncludeUnpaid}
                    toggleUnpaid={toggleUnpaid}
                    disabled={!hasLoaded}
                />
            </Filter>
        </Container>
    )
}

SelfAssessmentsSupportFilters.propTypes = {
    accountants: PropTypes.array.isRequired,
    handleAccountantChange: PropTypes.func.isRequired,
    filterAccountant: PropTypes.string,
    filterIncludeUnpaid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    toggleUnpaid: PropTypes.func.isRequired,
    hasLoaded: PropTypes.bool.isRequired,
}

SelfAssessmentsSupportFilters.defaultProps = {
    filterAccountant: null,
    filterIncludeUnpaid: false,
}

export default SelfAssessmentsSupportFilters
