export const REQUEST_ESCALATIONS = 'REQUEST_ESCALATIONS'
export const RECEIVE_ESCALATIONS = 'RECEIVE_ESCALATIONS'
export const EXPORT_ESCALATIONS = 'EXPORT_ESCALATIONS'

export const CREATE_ESCALATION = 'CREATE_ESCALATION'
export const DELETE_ESCALATION = 'DELETE_ESCALATION'
export const UPDATE_ESCALATION = 'UPDATE_ESCALATION'

export const REQUEST_CUSTOMER_ESCALATIONS = 'REQUEST_CUSTOMER_ESCALATIONS'
export const RECEIVE_CUSTOMER_ESCALATIONS = 'RECEIVE_CUSTOMER_ESCALATIONS'
export const REMOVE_CUSTOMER_ESCALATION = 'REMOVE_CUSTOMER_ESCALATION'

export const requestEscalations = (data, meta = {}) => ({ type: REQUEST_ESCALATIONS, data, meta })

export const receiveEscalations = (data, meta = {}) => ({ type: RECEIVE_ESCALATIONS, data, meta })

export const exportEscalations = (data) => ({ type: EXPORT_ESCALATIONS, data })

export const createEscalation = (data, meta = {}) => ({ type: CREATE_ESCALATION, data, meta })

export const updateEscalation = (data, meta = {}) => ({ type: UPDATE_ESCALATION, data, meta })

export const deleteEscalation = (data, meta = {}) => ({ type: DELETE_ESCALATION, data, meta })

export const requestCustomerEscalations = (data) => ({ type: REQUEST_CUSTOMER_ESCALATIONS, data })

export const receiveCustomerEscalations = (data) => ({ type: RECEIVE_CUSTOMER_ESCALATIONS, data })

export const removeCustomerEscalation = (data) => ({ type: REMOVE_CUSTOMER_ESCALATION, data })
