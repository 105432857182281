import config from '@/config'

export const authConfig = {
    firebaseApiKey: config.firebaseApiKey,
    firebaseAuthDomain: config.firebaseAuthDomain,
    firebaseAuthTenantId: config.firebaseAuthTenantId,
    privacyUrl: '',
    termsUrl: '',
    totpLabel: 'TaxScouts Accountant',
}
