import { createSelector } from '@reduxjs/toolkit'
import _find from 'lodash/find'
import _get from 'lodash/get'

import {
    SELF_ASSESSMENT_STATUS_FILTER_HOLD,
    SELF_ASSESSMENT_STATUS_FILTER_IN_PROGRESS,
    SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED,
    SELF_ASSESSMENT_STATUS_FILTERS,
    TAX_CONSULTATION_STATUS_AWAITING_SUMMARY,
    TAX_CONSULTATION_STATUS_BOOKED,
    TAX_YEARS,
} from '@/constants'
import { TaxRegistrationStatus } from '@/models'

const getStatistics = (state) => state.statistics

export const isFetchingDashboardAllItems = (state) => !!state.events.isFetching

export const hasSyncedDashboardAllItems = (state) => !!state.events.fetchSuccessful

const getTaxRegistrationCountPerFilter = (statistics, filterStatus) => {
    const taxRegistrationsStatistics = _get(statistics, ['taxRegistrationsStatistics'], {})

    return _get(_find(taxRegistrationsStatistics, { status: filterStatus }), ['count'], 0)
}

const getTaxConsultationCountPerFilter = (statistics, filterStatus) => {
    const taxConsultationsStatistics = _get(statistics, ['taxConsultationsStatistics'], {})

    return _get(_find(taxConsultationsStatistics, { status: filterStatus }), ['count'], 0)
}

const getSelfAssessmentCountPerFilter = (statistics, filterStatus) => {
    const selfAssessmentStatistics = _get(statistics, ['selfAssessmentStatistics'], {})

    return SELF_ASSESSMENT_STATUS_FILTERS[filterStatus].reduce(
        (total, status) => total + _get(_find(selfAssessmentStatistics, { status }), ['count'], 0),
        0
    )
}

export const getFullStatistics = createSelector([getStatistics], (statistics) => ({
    inProcessEarnings: _get(statistics, ['revenueStats', 'inProcessEarnings']),
    inProcessReturns: _get(statistics, ['revenueStats', 'inProcessReturns']),
    lastMonthsEarnings: _get(statistics, ['revenueStats', 'lastMonthsEarnings']),
    lastMonthsFiledReturns: _get(statistics, ['revenueStats', 'lastMonthsFiledReturns']),
    thisMonthsEarnings: _get(statistics, ['revenueStats', 'thisMonthsEarnings']),
    thisMonthsFiledReturns: _get(statistics, ['revenueStats', 'thisMonthsFiledReturns']),

    taxYear: TAX_YEARS[_get(statistics, ['customerLastYearStatistics', 'yearTaxReturn'])],
    totalCompleted: _get(statistics, ['customerLastYearStatistics', 'totalCompleted']),
    totalCompletedPercentage: _get(statistics, ['customerLastYearStatistics', 'totalCompletedPercentage']),

    totalDrafted: _get(statistics, ['customerLastYearStatistics', 'totalDrafted']),
    totalInProgress: _get(statistics, ['customerLastYearStatistics', 'totalInProgress']),
    totalLastYearClients: _get(statistics, ['customerLastYearStatistics', 'totalLastYearCustomers']),
    totalNotInProgress: _get(statistics, ['customerLastYearStatistics', 'totalNotInProgress']),

    taxConsultationsBooked: getTaxConsultationCountPerFilter(statistics, TAX_CONSULTATION_STATUS_BOOKED),
    taxConsultationsInProgress: getTaxConsultationCountPerFilter(statistics, TAX_CONSULTATION_STATUS_AWAITING_SUMMARY),

    taxRegistrationsReadyToStart: getTaxRegistrationCountPerFilter(statistics, TaxRegistrationStatus.Submitted),

    selfAssessmentsOnHold: getSelfAssessmentCountPerFilter(statistics, SELF_ASSESSMENT_STATUS_FILTER_HOLD),
    selfAssessmentsSubmitted: getSelfAssessmentCountPerFilter(statistics, SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED),
    selfAssessmentsInProgress: getSelfAssessmentCountPerFilter(statistics, SELF_ASSESSMENT_STATUS_FILTER_IN_PROGRESS),
    selfAssessmentsReadyToFile: getSelfAssessmentCountPerFilter(
        statistics,
        SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE
    ),
}))

export const getDraftedReturnsCount = createSelector(
    [getStatistics],
    (statistics) => _get(statistics, ['customerLastYearStatistics', 'totalDrafted']) || 0
)
