import _orderBy from 'lodash/orderBy'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, ButtonLink } from '@scouts/ui'

import {
    FILING_AREA_TITLES,
    PATH_CUSTOMERS,
    SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    SELF_ASSESSMENT_STATUS_TITLES,
} from '@/constants'
import { formatTimeFromNow } from '@/helpers'
import { FileTypeTitles, UserType } from '@/models'
import { getCustomerFilesByCustomer } from '@/selectors/customer-files'
import { getSelfAssessmentFilesBySelfAssessmentId } from '@/selectors/self-assessment-files'

import FileWithActions from '../FileWithActions'

const Container = styled.div`
    padding: 18px 21px;
    background: ${colors.blueLighter};
    border-radius: 4px;
`

const Heading = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
`

const Body = styled.div`
    margin: 18px 0;
`

const FileContainer = styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 9px;
    }
`

const Date = styled.div`
    font-size: ${font.smaller};
    margin-left: 9px;
`

const SelfAssessmentRecentlyUploadedFiles = ({
    customerAndSelfAssessmentFilesUploadedAfterStatusChange,
    history,
    selfAssessment,
    shouldDisplayRecentlyUploadedFilesNotice,
}) => {
    if (!shouldDisplayRecentlyUploadedFilesNotice) return null

    const { id: selfAssessmentId, customerId, status } = selfAssessment

    const numberOfFiles = customerAndSelfAssessmentFilesUploadedAfterStatusChange.length

    return (
        <Container>
            <Heading>
                {numberOfFiles} new file{numberOfFiles === 1 ? '' : 's'} uploaded since status was set to{' '}
                {SELF_ASSESSMENT_STATUS_TITLES[status]}
            </Heading>

            <Body>
                {customerAndSelfAssessmentFilesUploadedAfterStatusChange.map((file) => (
                    <FileContainer key={file.id}>
                        <FileWithActions
                            background={colors.white}
                            file={file}
                            label={`${
                                FILING_AREA_TITLES[file.filingArea] ? `${FILING_AREA_TITLES[file.filingArea]} - ` : ''
                            }${FileTypeTitles[file.fileType]} - ${file.fileName}`}
                            isCustomerFile={!!file.customerId}
                            isDeletable={false}
                        />{' '}
                        <Date>{formatTimeFromNow(file.uploadedDateUTC)}</Date>
                    </FileContainer>
                ))}
            </Body>

            <ButtonLink
                type="button"
                onClick={() => {
                    history.push(`${PATH_CUSTOMERS}/${customerId}/files/${selfAssessmentId}`)
                }}
            >
                View all files
            </ButtonLink>
        </Container>
    )
}

SelfAssessmentRecentlyUploadedFiles.propTypes = {
    customerAndSelfAssessmentFilesUploadedAfterStatusChange: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    selfAssessment: PropTypes.object.isRequired,
    shouldDisplayRecentlyUploadedFilesNotice: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, { selfAssessment }) => {
    const { id: selfAssessmentId, customerId, status, statusChangedDateUTC } = selfAssessment

    const customerFiles = getCustomerFilesByCustomer(state, { customerId })
    const selfAssessmentFiles = getSelfAssessmentFilesBySelfAssessmentId(state, { selfAssessmentId })

    const customerFilesNotUploadedByAccountant = customerFiles.filter((o) => o.fileOwnerType !== UserType.Accountant)
    const selfAssessmentFilesNotUploadedByAccountant = selfAssessmentFiles.filter(
        (o) => o.fileOwnerType !== UserType.Accountant
    )

    const customerAndSelfAssessmentFiles = [
        ...customerFilesNotUploadedByAccountant,
        ...selfAssessmentFilesNotUploadedByAccountant,
    ]
    const customerAndSelfAssessmentFilesUploadedAfterStatusChange = _orderBy(
        customerAndSelfAssessmentFiles,
        ['uploadedDateUTC'],
        ['desc']
    ).filter(({ uploadedDateUTC }) => moment(uploadedDateUTC).isAfter(statusChangedDateUTC))

    const shouldDisplayRecentlyUploadedFilesNotice =
        customerAndSelfAssessmentFilesUploadedAfterStatusChange.length > 0 &&
        [
            SELF_ASSESSMENT_STATUS_SUBMITTED,
            SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
            SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
            SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
            SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
            SELF_ASSESSMENT_STATUS_READY_TO_FILE,
        ].includes(status)

    return { customerAndSelfAssessmentFilesUploadedAfterStatusChange, shouldDisplayRecentlyUploadedFilesNotice }
}

export default connect(mapStateToProps)(withRouter(SelfAssessmentRecentlyUploadedFiles))
