import { ReactNode } from 'react'

import { Auth } from '@scouts/auth'

import { featureFlags } from '@/feature-flags'

export const MfaEnforcer = ({ children }: { children: ReactNode }) => {
    if (!featureFlags.featureFirebaseEnforcedMFA) return <>{children}</>

    return <Auth.MfaEnforcer>{children}</Auth.MfaEnforcer>
}
