import _findIndex from 'lodash/findIndex'

export const updateOrAddItemsById = ({ currentItems, receivedItems, idKey = 'id', isExtendingItems = false }) => {
    const items = Object.assign([], currentItems)

    if (Array.isArray(receivedItems)) {
        receivedItems.forEach((item) => {
            const key = _findIndex(items, [idKey, item[idKey]])

            if (key > -1 && items[key]) {
                if (isExtendingItems) {
                    items[key] = { ...items[key], ...item }
                } else {
                    items[key] = item
                }
            } else {
                items.push(item)
            }
        })
    }

    return items
}

export const removeItemsById = ({ currentItems, id, idKey = 'id' }) => {
    const items = Object.assign([], currentItems)

    if (id) {
        const key = items.findIndex((item) => item[idKey] === id)

        if (key > -1 && items[key]) {
            items.splice(key, 1)
        }
    }

    return items
}
