import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { generateApiPath } from '@scouts/helpers'

import { PAGINATION_DEFAULT_PAGE, PAGINATION_VIEW_ALL_PAGE_SIZE } from '@/constants'
import { PaginationQuery, ResponseWithPagination, SubscriptionDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSubscriptions: builder.query<ResponseWithPagination<SubscriptionDTO[]>, { options: PaginationQuery }>({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.SUBSCRIPTIONS.GET.LIST, undefined, {
                    queryParameters: {
                        pageSize: options?.pageSize || PAGINATION_VIEW_ALL_PAGE_SIZE,
                        page: options?.page || PAGINATION_DEFAULT_PAGE,
                    },
                }),
            }),
            providesTags: [Tags.Subscriptions],
            transformResponse: (response: SubscriptionDTO[], meta: FetchBaseQueryMeta) =>
                mapPaginatedResponse(response, meta),
        }),
    }),
})

export const { useGetSubscriptionsQuery } = extendedApi
