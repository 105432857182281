import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, media, radius, shadows, ButtonLink } from '@scouts/ui'

import { resolveNoteNotification } from '@/actions'
import { PATH_CUSTOMERS, PATH_HASH_ESCALATIONS, PATH_HASH_NOTES, QUERY_PARAM_ESCALATION_EDIT } from '@/constants'
import { formatDateWithTime, formatTimeTodayAndDateIfLater } from '@/helpers'

const DashboardEscalationsItem = ({ note, isAdminUser }) => {
    const {
        createdByAccountantFullName,
        createdDateUTC,
        customerFullName,
        customerId,
        escalationId,
        id: noteId,
        noteText,
        isResolved,
    } = note

    const dispatch = useDispatch()
    const [isResolvePending, setIsResolvePending] = useState(false)
    const handleResolve = () => {
        const data = { noteId }
        const meta = { onSuccess: () => setIsResolvePending(false), onError: () => setIsResolvePending(false) }
        setIsResolvePending(true)
        dispatch(resolveNoteNotification(data, meta))
    }

    const [isHidden, setIsHidden] = useState(false)
    const handleHide = () => setIsHidden(true)
    const handleShow = () => setIsHidden(false)

    const customerNameLink = `${PATH_CUSTOMERS}/${customerId}${PATH_HASH_NOTES}`

    const history = useHistory()
    const escalationModalLocation = {
        pathname: `${PATH_CUSTOMERS}/${customerId}`,
        search: queryString.stringify({ view: QUERY_PARAM_ESCALATION_EDIT, escalationId }),
        hash: PATH_HASH_ESCALATIONS,
    }

    return (
        <Container isHidden={isHidden} isResolved={isResolved}>
            <Heading>
                <Name>
                    <Link to={customerNameLink}>{customerFullName}</Link> needs attention
                </Name>
                {!isHidden && (
                    <Time title={formatDateWithTime(createdDateUTC)}>
                        {formatTimeTodayAndDateIfLater(createdDateUTC, ' ')} · by {createdByAccountantFullName}
                    </Time>
                )}
                {isHidden && <ButtonLink onClick={handleShow}>Show more</ButtonLink>}
            </Heading>
            {!isHidden && (
                <Content>
                    <NoteText>{noteText}</NoteText>
                    <Actions>
                        <ButtonLink onClick={handleHide}>Hide</ButtonLink>
                        {isAdminUser && (
                            <div>
                                <ButtonLink disabled={isResolvePending} onClick={handleResolve}>
                                    {isResolved ? 'Mark as unresolved' : 'Mark as resolved'}
                                </ButtonLink>
                                &nbsp;&nbsp;·&nbsp;&nbsp;
                                <ButtonLink onClick={() => history.push(escalationModalLocation)}>
                                    Show details
                                </ButtonLink>
                            </div>
                        )}
                    </Actions>
                </Content>
            )}
        </Container>
    )
}

DashboardEscalationsItem.propTypes = {
    note: PropTypes.object.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
}

export default DashboardEscalationsItem

const Container = styled.div`
    background: ${({ isResolved }) => (isResolved ? colors.mintLighter : colors.yellow)};
    padding: ${({ isHidden }) => (isHidden ? '12px 24px;' : '24px;')};
    border-radius: ${radius.large};
`

const Heading = styled.div`
    font-size: ${font.small};
    display: flex;
    flex-direction: column;
    gap: 6px;

    ${media.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }
`

const Name = styled.div`
    font-weight: ${font.weight.medium};

    a {
        text-decoration: none;
    }
`

const Time = styled.div``

const Content = styled.div`
    padding: 18px 30px;
    margin-top: 12px;
    background: ${colors.white};
    border-radius: ${radius.small};
    box-shadow: ${shadows.low};
`
const NoteText = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    white-space: pre-line;
`
const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`
