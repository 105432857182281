import { PAGINATION_DEFAULT_PAGE, PAGINATION_VIEW_ALL_PAGE_SIZE } from '@/constants'
import { CompanyReturnSearchOptions, CompanyReturnStatusFilters } from '@/models'

export const getCompanyReturnsSearchQueryParams = (options?: CompanyReturnSearchOptions) => {
    const shouldProvideStatus = !options?.returnOnHold
    const statuses = options?.status ? CompanyReturnStatusFilters[options?.status] : []
    const statusesList = statuses.map((value) => value)

    return {
        pageSize: options?.pageSize || PAGINATION_VIEW_ALL_PAGE_SIZE,
        page: options?.page || PAGINATION_DEFAULT_PAGE,
        ...(options?.accountantId && { accountantId: options.accountantId }),
        ...(shouldProvideStatus && { status: statusesList }),
        ...((options?.includeUnpaid !== null || options?.includeUnpaid !== undefined) && {
            includeUnpaid: options?.includeUnpaid,
        }),
        ...((options?.returnOnHold !== null || options?.returnOnHold !== undefined) && {
            returnOnHold: options?.returnOnHold,
        }),
    }
}
