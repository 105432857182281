import { getDaysToDate } from '@scouts/helpers'
import { colors, Label, Tooltip } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { CompanyReturnDTO, CompanyReturnStatus, CompanyReturnStatusTypes } from '@/models'

const getBackground = (daysUntilAccountsDue: number) => {
    if (daysUntilAccountsDue <= 20) return colors.surface.attention
    return colors.surface.highlight
}

const getTitle = (daysUntilAccountsDue: number) => {
    if (daysUntilAccountsDue < -1) return `Overdue ${Math.abs(daysUntilAccountsDue)} days`
    if (daysUntilAccountsDue < 0) return `Overdue ${Math.abs(daysUntilAccountsDue)} day`
    if (daysUntilAccountsDue > 1) return `${daysUntilAccountsDue} days to file`
    if (daysUntilAccountsDue === 1) return `${daysUntilAccountsDue} day to file`
    return 'Today is filing day'
}

export const CompanyReturnFilingLabel = ({
    accountsDueDate,
    status,
}: {
    accountsDueDate: CompanyReturnDTO['accountsDueDate']
    status: CompanyReturnStatusTypes
}) => {
    if (
        !accountsDueDate ||
        status === CompanyReturnStatus.Cancelled ||
        status === CompanyReturnStatus.Filed ||
        status === CompanyReturnStatus.CompletedNotFiled
    )
        return null

    const daysToFile = getDaysToDate(accountsDueDate)
    const background = getBackground(daysToFile)

    return (
        <Tooltip content={`Accounts due ${formatDate(accountsDueDate)}`}>
            <Label background={background}>{getTitle(daysToFile)}</Label>
        </Tooltip>
    )
}
