import { PaymentStatuses } from '@/constants'

import { AccountantStatusTypes } from './accountant'
import { IdentityVerificationStatusTypes } from './identity-verification'
import { PriorityUnresolvedNoteDTO } from './note'
import { RefundReasonDTO } from './refunds'
import { SelfEmploymentProfileDTO } from './self-employment-profile'

export const TaxRegistrationStatus = {
    Started: 'Started',
    Submitted: 'Submitted',
    Complete: 'Complete',
    Cancelled: 'Cancelled',
    InformationRequested: 'InformationRequested',
} as const

export type TaxRegistrationStatusTypes = (typeof TaxRegistrationStatus)[keyof typeof TaxRegistrationStatus]

export const TaxRegistrationStatusTitles: Record<TaxRegistrationStatusTypes, string> = {
    Started: 'Assigned',
    Submitted: 'Ready to start',
    InformationRequested: 'Info requested',
    Complete: 'Submitted to HMRC',
    Cancelled: 'Cancelled',
}

export const TaxRegistrationRegistrationType = {
    SelfEmployed: 'SelfEmployed',
    Cis: 'Cis',
    LostUtr: 'LostUtr',
    NonSelfEmployed: 'NonSelfEmployed',
}

export type TaxRegistrationRegistrationTypeTypes =
    (typeof TaxRegistrationRegistrationType)[keyof typeof TaxRegistrationRegistrationType]

export const TaxRegistrationRegistrationTypeTitles: Record<TaxRegistrationRegistrationTypeTypes, string> = {
    SelfEmployed: 'Self-employed',
    Cis: 'CIS',
    LostUtr: 'Lost UTR',
    NonSelfEmployed: 'Non self-employed',
}

type TaxRegistrationReasonTypes =
    | 'CapitalGains'
    | 'CompanyDirector'
    | 'EarningsLimit'
    | 'ForeignIncome'
    | 'Other'
    | 'PropertyIncome'

type TaxRegistrationReasonDTO = {
    id: number
    reason: TaxRegistrationReasonTypes
    reasonDate: string | null
    reasonText: string | null
    taxRegistrationId: number
}

export type TaxRegistrationDTO = {
    id: number
    accountantId: number | null
    accountantFullName: string | null
    accountantStatus: AccountantStatusTypes
    customerIdentityVerificationStatus: IdentityVerificationStatusTypes
    customerUnresolvedNoteCount: number
    customerPriorityUnresolvedNotes: PriorityUnresolvedNoteDTO[]
    customerUniqueTaxpayerReference: string | null
    customerEmail: string
    customerFirstName: string
    customerId: number
    customerLastName: string
    hasPaid: boolean
    paymentStatus: PaymentStatuses
    price: number
    reasons: TaxRegistrationReasonDTO[]
    refundDateUTC: string | null
    refundReason: RefundReasonDTO | null
    registrationType: keyof typeof TaxRegistrationRegistrationType
    revenueDateUTC: string | null
    selfEmploymentProfile: SelfEmploymentProfileDTO
    status: TaxRegistrationStatusTypes
    statusChangedDateUTC: string
    unsubscribeFromBulkEmails: boolean
}

export interface TaxRegistrationsRequestOptions {
    accountantId?: number
    status?: TaxRegistrationStatusFiltersTypes | null
    page?: number
    pageSize?: number
    includeUnpaid?: boolean
}

export const TaxRegistrationStatusFilter = {
    Started: 'Started',
    Submitted: 'Submitted',
    Complete: 'Complete',
    Cancelled: 'Cancelled',
    InformationRequested: 'InformationRequested',
    All: 'All',
} as const

export const TaxRegistrationStatusFilters = {
    Started: [TaxRegistrationStatus.Started],
    Submitted: [TaxRegistrationStatus.Submitted],
    InformationRequested: [TaxRegistrationStatus.InformationRequested],
    Complete: [TaxRegistrationStatus.Complete],
    Cancelled: [TaxRegistrationStatus.Cancelled],
    All: [
        TaxRegistrationStatus.Started,
        TaxRegistrationStatus.Submitted,
        TaxRegistrationStatus.InformationRequested,
        TaxRegistrationStatus.Complete,
        TaxRegistrationStatus.Cancelled,
    ],
} as const

export type TaxRegistrationStatusFiltersTypes = keyof typeof TaxRegistrationStatusFilter

export const TaxRegistrationStatusFiltersTitles: Record<TaxRegistrationStatusFiltersTypes, string> = {
    Started: 'Assigned',
    Submitted: 'Ready to start',
    InformationRequested: 'Info requested',
    Complete: 'Submitted to HMRC',
    Cancelled: 'Cancelled',
    All: 'All',
}
