import { useState } from 'react'
import styled from 'styled-components'

import { colors } from '@scouts/ui'

import { getAccountantImageUrl } from '../../helpers'

export const SupportAvatar = ({ supportUserId }: { supportUserId: number | null }) => {
    const supportAvatarImageSrc = supportUserId ? getAccountantImageUrl(supportUserId) : ''

    const [shouldDisplayAvatar, setShouldDisplayAvatar] = useState(true)
    const handleAvatarNotFound = () => {
        setShouldDisplayAvatar(false)
    }

    if (!supportUserId || !supportAvatarImageSrc) return null

    return (
        <AvatarContainer>
            {shouldDisplayAvatar && <AvatarImage src={supportAvatarImageSrc} onError={handleAvatarNotFound} />}
        </AvatarContainer>
    )
}

const AvatarContainer = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 9999px;
    background: ${colors.neutralLightest};
    margin-right: 4px;
`

const AvatarImage = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 9999px;
`
