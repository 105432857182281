import { colors, font, Flexer, Line } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { CustomerDTO, IdentityVerificationDTO } from '@/models'

import { RequestContainer } from '../actions/RequestContainer'
import { ReviewContainer } from '../actions/ReviewContainer'
import { VerificationNotes } from '../VerificationNotes'
import { VerificationWidget } from '../VerificationWidget'

interface FailedProps {
    customer: CustomerDTO
    identityVerification: IdentityVerificationDTO
}

export const Failed = ({ identityVerification, customer }: FailedProps) => {
    const { notes, updatedDateUTC } = identityVerification

    return (
        <VerificationWidget
            background={colors.redLighter}
            actions={
                <>
                    <ReviewContainer
                        buttonLabel="Review"
                        customer={customer}
                        identityVerification={identityVerification}
                        isNeutral
                    />
                    <RequestContainer
                        buttonLabel="Start again"
                        customer={customer}
                        identityVerification={identityVerification}
                    />
                </>
            }
        >
            <Flexer column gap="9px" justifyStart>
                <Line weight={font.weight.medium}>Identity verification failed - {formatDate(updatedDateUTC)}</Line>

                <Line size={font.small}>Client identity verification is marked as failed</Line>

                <VerificationNotes notes={notes} />
            </Flexer>
        </VerificationWidget>
    )
}
