import styled from 'styled-components'

import { Table } from '@scouts/ui'

import { CustomerProductSearchDTO, CustomerProductSearchSelectedItem } from '@/models'

import { CustomersTableRow } from './CustomersTableRow'

interface CustomersTableProps {
    customers: CustomerProductSearchDTO[]
    onDeselectAll: VoidFunction
    handleCustomerSelection: (params: CustomerProductSearchSelectedItem) => void
    isFetching: boolean
    onSelectAll: VoidFunction
    selectedItems: CustomerProductSearchSelectedItem[]
    onTableUpdate: VoidFunction
}

export const CustomersTable = ({
    customers,
    onDeselectAll,
    handleCustomerSelection,
    isFetching,
    onSelectAll,
    selectedItems,
    onTableUpdate,
}: CustomersTableProps) => {
    const hasSelectedItems = selectedItems.length > 0

    return (
        <Container isFetching={isFetching}>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell width="20%">Client</Table.Header.Cell>
                        <Table.Header.Cell width="168px" />
                        <Table.Header.Cell width="calc(60% - 600px)">Not complete</Table.Header.Cell>
                        <Table.Header.Cell width="96px">Completed</Table.Header.Cell>
                        <Table.Header.Cell width="120px">Assigned at</Table.Header.Cell>
                        <Table.Header.Cell width="120px">Last purchase</Table.Header.Cell>
                        <Table.Header.Cell width="20%" alignRight>
                            Notes
                        </Table.Header.Cell>
                        <Table.Header.Cell
                            width="96px"
                            alignRight
                            action={hasSelectedItems ? onDeselectAll : onSelectAll}
                        >
                            {hasSelectedItems ? 'Deselect all' : 'Select all'}
                        </Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>
                <Table.Body>
                    {customers.map((customer) => {
                        const { id } = customer
                        const isSelected = selectedItems.findIndex((o) => o.customerId === id) > -1
                        return (
                            <CustomersTableRow
                                key={id}
                                onSelect={handleCustomerSelection}
                                isSelected={isSelected}
                                customer={customer}
                                onTriggerTableUpdate={onTableUpdate}
                            />
                        )
                    })}
                </Table.Body>
            </Table>
        </Container>
    )
}

const Container = styled.div<{ isFetching?: boolean }>`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`
