import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

import { REFUND_REASONS, REFUND_REASONS_TITLES } from '../../constants'
import { FilterItem, FilterItemDropdownRadios } from '../ui/filter'

const SearchFilterRefundReason = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter !== null

    const values = REFUND_REASONS.map((reason) => ({
        value: reason,
        label: REFUND_REASONS_TITLES[reason],
        checked: activeFilter === reason,
    }))

    const activeValue = _get(_find(values, { value: activeFilter }), ['label'], null)

    const name = 'refundReasonType'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Tax return refund reason"
            activeValues={[activeValue]}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownRadios
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterRefundReason.propTypes = {
    activeFilter: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
}

export default SearchFilterRefundReason
