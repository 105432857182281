import { useState } from 'react'

import {
    Button,
    DatePicker,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Notice,
    TextArea,
} from '@scouts/ui'

import { createNote, saveSelfAssessment } from '@/actions'
import { CURRENT_TAX_YEAR, TAX_YEARS } from '@/constants'
import { isDatePast } from '@/helpers'
import { CustomerDTO, SelfAssessmentDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface SelfAssessmentOnHoldModalProps {
    onClose: () => void
    customer: CustomerDTO
    selfAssessment: SelfAssessmentDTO
}

export const SelfAssessmentOnHoldModal = ({ customer, onClose, selfAssessment }: SelfAssessmentOnHoldModalProps) => {
    const dispatch = useAppDispatch()
    const { fullName } = customer
    const { taxYear } = selfAssessment

    const [errorMessage, setErrorMessage] = useState('')
    const [onHoldUntilDate, setOnHoldUntilDate] = useState<Date | string>(selfAssessment.onHoldUntilDate || '')
    const [noteText, setNoteText] = useState('')

    const handleSubmit = () => {
        if (!isDatePast(onHoldUntilDate)) {
            if (noteText && noteText.trim().length > 0) {
                dispatch(
                    createNote({
                        noteText,
                        customerId: customer.id,
                        selfAssessmentId: selfAssessment.id,
                    })
                )
            }

            const updatedSelfAssessment = { ...selfAssessment, onHoldUntilDate }

            dispatch(saveSelfAssessment(updatedSelfAssessment))

            setErrorMessage('')

            onClose()
        } else {
            setErrorMessage('Please choose a date in the future')
        }
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Set as 'On hold'</ModalTitle>
                    <ModalSubtitle>
                        {fullName}'s tax return for {TAX_YEARS[Number(taxYear) as keyof typeof TAX_YEARS]}
                    </ModalSubtitle>
                </ModalHeader>

                {errorMessage && <Notice>{errorMessage}</Notice>}

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Until</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker
                                name="onHoldUntilDate"
                                value={onHoldUntilDate}
                                onChange={({ value }) => setOnHoldUntilDate(value)}
                                minDate={`${CURRENT_TAX_YEAR}-01-01`}
                                maxDate={`${CURRENT_TAX_YEAR + 1}-12-31`}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Add a note (optional)</Form.Row.Title>
                        <Form.Row.Content>
                            <TextArea name="noteText" value={noteText} onChange={({ value }) => setNoteText(value)} />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onClose} isSecondary>
                            Cancel
                        </Button>
                        <Button type="submit">Set as 'On hold'</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
