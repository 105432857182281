import { useHistory } from 'react-router-dom'

import { Auth } from '@scouts/auth'
import { ButtonLink, Heading, Spacer } from '@scouts/ui'

import { PATH_RESET_PASSWORD } from '@/constants'

const Header = () => {
    return <Heading>Log in</Heading>
}

const Footer = () => {
    const history = useHistory()

    return (
        <Spacer>
            <ButtonLink onClick={() => history.push(PATH_RESET_PASSWORD)}>Forgot password? Reset password</ButtonLink>
        </Spacer>
    )
}

export const Login = () => {
    return (
        <Auth.LockscreenLayout header={<Header />} footer={<Footer />}>
            <Auth.LoginForm />
        </Auth.LockscreenLayout>
    )
}
