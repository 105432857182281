import { generatePath, Link } from 'react-router-dom'

import { formatTimeFromNow } from '@scouts/helpers'
import { font, Container, Line, Table } from '@scouts/ui'

import { PATH_CUSTOMER_TAX_CONSULTATION } from '@/constants'
import { TaxConsultationShortDTO, TaxConsultationTopicTitles } from '@/models'

import { TaxConsultationStatusLabel } from '@/components/tax-consultations/TaxConsultationStatusLabel'

export const CustomerOverviewTaxConsultation = ({ taxConsultation }: { taxConsultation: TaxConsultationShortDTO }) => {
    const { id: taxConsultationId, status, topics, customerId, statusChangedDateUTC } = taxConsultation

    const taxConsultationPath = generatePath(PATH_CUSTOMER_TAX_CONSULTATION, { customerId, taxConsultationId })
    const statusLastChanged = formatTimeFromNow(statusChangedDateUTC)
    const hasTopics = topics && topics.length > 0

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={taxConsultationPath}>
                    <TaxConsultationStatusLabel status={status} />
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="50%">
                {hasTopics && (
                    <Table.Body.Cell.ContentLink Link={Link} to={taxConsultationPath}>
                        <Container paddingRight="18px">
                            <Line size={font.small}>
                                {topics.map(({ topicType }) => TaxConsultationTopicTitles[topicType]).join(', ')}
                            </Line>
                        </Container>
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={taxConsultationPath} alignRight>
                    <Line size={font.small}>{statusLastChanged}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}
