import { Container } from '@scouts/ui'

import { formatCurrencyNotSure, formatDate } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const PensionsAndBenefits = ({
    pensionsAndBenefits,
}: {
    pensionsAndBenefits: SelfAssessmentDTO['pensionsAndBenefits']
}) => {
    if (!pensionsAndBenefits) return null

    const {
        additionalNotes,
        childBenefitAmount,
        childBenefitNumberOfChildren,
        hasChildBenefitStopped,
        incapacityBenefitAmount,
        incapacityBenefitTaxPaid,
        jobseekerAllowanceAmount,
        privatePensionAmount,
        privatePensionTaxPaid,
        receivedChildBenefit,
        receivedIncapacityBenefit,
        receivedJobseekerAllowance,
        receivedPension,
        receivedPrivatePension,
        receivedStatePension,
        receivedStatePensionLumpSum,
        statePensionLumpSumAmount,
        statePensionLumpSumTaxPaid,
        statePensionRegularPaymentsAmount,
        stoppedReceivingChildBenefitDate,
    } = pensionsAndBenefits

    return (
        <Container>
            <SectionData>
                <SectionDataRow label="Received pension" value={receivedPension} boolean highlight />
                {receivedPension && (
                    <>
                        <SectionDataRow label="Received state pension" value={receivedStatePension} boolean />
                        <SectionDataRow label="State pension lump sum" value={receivedStatePensionLumpSum} boolean />
                        {receivedStatePensionLumpSum ? (
                            <>
                                <SectionDataRow
                                    label="State pension lump sum amount"
                                    value={formatCurrencyNotSure(statePensionLumpSumAmount)}
                                />
                                <SectionDataRow
                                    label="State pension lump sum tax paid"
                                    value={formatCurrencyNotSure(statePensionLumpSumTaxPaid)}
                                />
                            </>
                        ) : (
                            <SectionDataRow
                                label="Regular state pension"
                                value={formatCurrencyNotSure(statePensionRegularPaymentsAmount)}
                            />
                        )}
                        <SectionDataRow label="Received private pension" value={receivedPrivatePension} boolean />
                        {receivedPrivatePension && (
                            <>
                                <SectionDataRow
                                    label="Private pension amount"
                                    value={formatCurrencyNotSure(privatePensionAmount)}
                                />
                                <SectionDataRow
                                    label="Private pension tax paid"
                                    value={formatCurrencyNotSure(privatePensionTaxPaid)}
                                />
                            </>
                        )}
                    </>
                )}
            </SectionData>
            <SectionData>
                <SectionDataRow
                    label="Received jobseeker allowance"
                    value={receivedJobseekerAllowance}
                    boolean
                    highlight
                />
                {receivedJobseekerAllowance && (
                    <SectionDataRow
                        label="Jobseeker allowance amount"
                        value={formatCurrencyNotSure(jobseekerAllowanceAmount)}
                    />
                )}
            </SectionData>
            <SectionData>
                <SectionDataRow
                    label="Received incapacity benefits"
                    value={receivedIncapacityBenefit}
                    boolean
                    highlight
                />
                {receivedIncapacityBenefit && (
                    <>
                        <SectionDataRow
                            label="Incapacity benefit amount"
                            value={formatCurrencyNotSure(incapacityBenefitAmount)}
                        />
                        <SectionDataRow
                            label="Incapacity benefit tax taken"
                            value={formatCurrencyNotSure(incapacityBenefitTaxPaid)}
                        />
                    </>
                )}
            </SectionData>
            <SectionData>
                <SectionDataRow label="Received child benefit" value={receivedChildBenefit} boolean highlight />
                {receivedChildBenefit && (
                    <>
                        <SectionDataRow
                            label="Child benefit amount"
                            value={formatCurrencyNotSure(childBenefitAmount)}
                        />
                        <SectionDataRow label="Number of children" value={childBenefitNumberOfChildren} />
                        <SectionDataRow label="Still receiving" value={!hasChildBenefitStopped} boolean />
                        {hasChildBenefitStopped && (
                            <SectionDataRow
                                label="Date stopped receiving"
                                value={formatDate(stoppedReceivingChildBenefitDate)}
                            />
                        )}
                    </>
                )}
            </SectionData>

            {additionalNotes && (
                <SectionData>
                    <SectionDataRow label="Notes" value={additionalNotes} />
                </SectionData>
            )}
        </Container>
    )
}
