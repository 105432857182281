import _get from 'lodash/get'

import {
    RECEIVE_ACCOUNTANT,
    RECEIVE_ACCOUNTANTS,
    RECEIVE_ACCOUNTANTS_SUITABLE_FOR,
    REQUEST_ACCOUNTANT,
    REQUEST_ACCOUNTANTS,
} from '../actions'
import { updateOrAddItemsById } from '../helpers'

const defaultState = {
    shouldFetch: true,
    isFetching: false,
    items: [],
    suitableAccountantsByCustomerId: {},
}

export default (state = defaultState, action) => {
    const { type, data: payload } = action

    switch (type) {
        case REQUEST_ACCOUNTANT:
        case REQUEST_ACCOUNTANTS:
            return { ...state, isFetching: true }

        case RECEIVE_ACCOUNTANTS:
            return { ...state, shouldFetch: false, isFetching: false, items: payload }

        case RECEIVE_ACCOUNTANT: {
            const id = _get(payload, ['id'])
            if (!id) return { ...state, isFetching: false }

            const items = updateOrAddItemsById({ currentItems: state.items, receivedItems: [payload] })

            return { ...state, items, isFetching: false }
        }

        case RECEIVE_ACCOUNTANTS_SUITABLE_FOR: {
            const { items: receivedItems, customerId } = payload
            const currentItems = state.suitableAccountantsByCustomerId[customerId] || []

            const items = updateOrAddItemsById({ currentItems, receivedItems })

            const suitableAccountantsByCustomerId = {
                ...state.suitableAccountantsByCustomerId,
                [customerId]: items,
            }

            return { ...state, suitableAccountantsByCustomerId }
        }

        default:
            return state
    }
}
