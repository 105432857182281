import { MESSAGE_ATTACHMENT_FILE_MIME_TYPES, MESSAGE_ATTACHMENT_FILE_SIZE_SUM_LIMIT } from '@/constants'

export const getDraftMessageValidatedFiles = (files = [] as File[]) => {
    const filesValidated: File[] = []
    const filesRejectedBySize: File[] = []
    const filesRejectedByType: File[] = []

    let combinedFileSizes = 0

    files.forEach((file) => {
        const isInvalidType = !MESSAGE_ATTACHMENT_FILE_MIME_TYPES.includes(file.type) || file.size === 0
        const hasReachedSizeLimit = combinedFileSizes + file.size > MESSAGE_ATTACHMENT_FILE_SIZE_SUM_LIMIT

        if (isInvalidType) {
            filesRejectedByType.push(file)
        } else if (hasReachedSizeLimit) {
            filesRejectedBySize.push(file)
        } else {
            filesValidated.push(file)
            combinedFileSizes += file.size
        }
    })

    return {
        filesValidated,
        filesRejectedBySize,
        filesRejectedByType,
    }
}
