import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
    colors,
    font,
    media,
    Button,
    Flexer,
    Label,
    Line,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Spacer,
} from '@scouts/ui'

import { formatDate } from '@/helpers'
import { CustomerDTO, CustomerFileDTO, IdentityVerificationDTO } from '@/models'
import {
    getCustomerAddressVerificationFiles,
    getCustomerIdentityVerificationFiles,
    getCustomerIdentityVerificationVideoFiles,
} from '@/selectors'

import { VerificationFile } from '../VerificationFile'

interface ReviewProps {
    customer: CustomerDTO
    handleCancel: () => void
    handleMarkAsVerified?: () => void
    handleRequestNewDocuments: () => void
    identityVerification: IdentityVerificationDTO
}

export const Review = ({
    customer,
    handleCancel,
    handleMarkAsVerified,
    handleRequestNewDocuments,
    identityVerification,
}: ReviewProps) => {
    const { fullName, dateOfBirth, addressStreetAddress, addressPostcode, addressCity, addressCountry } = customer
    const { hasSignificantCashTransactions, hasHighRiskActivity, id: identityVerificationId } = identityVerification

    const identityVerificationFiles = useSelector((state) =>
        getCustomerIdentityVerificationFiles(state, { identityVerificationId })
    )
    const identityVerificationVideoFiles = useSelector((state) =>
        getCustomerIdentityVerificationVideoFiles(state, { identityVerificationId })
    )
    const addressVerificationFiles = useSelector((state) =>
        getCustomerAddressVerificationFiles(state, { identityVerificationId })
    )

    const hasAddressVerificationFiles = addressVerificationFiles.length > 0
    const hasIdentityVerificationFiles = identityVerificationFiles.length > 0
    const hasIdentityVerificationVideoFiles = identityVerificationVideoFiles.length > 0

    return (
        <Modal isWide onClose={handleCancel}>
            <ModalHeader>
                <ModalTitle>Review identity verification</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Customer>
                    <CustomerColumn>
                        <CustomerRow>{fullName}</CustomerRow>
                        {dateOfBirth ? (
                            <CustomerRow>Born {formatDate(dateOfBirth)}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>Date of birth missing</CustomerRow>
                        )}
                    </CustomerColumn>
                    <CustomerColumn>
                        {addressStreetAddress ? (
                            <CustomerRow>{addressStreetAddress}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>Address missing</CustomerRow>
                        )}
                        {addressPostcode ? (
                            <CustomerRow>{addressPostcode}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>Postcode missing</CustomerRow>
                        )}
                        {addressCity ? (
                            <CustomerRow>{addressCity}</CustomerRow>
                        ) : (
                            <CustomerRow isMissing>City missing</CustomerRow>
                        )}
                        {addressCountry && <CustomerRow isMissing>{addressCountry}</CustomerRow>}
                    </CustomerColumn>
                </Customer>

                <Files>
                    <FilesColumn columnCount={hasIdentityVerificationVideoFiles ? 3 : 2}>
                        <FilesHeading>
                            {hasIdentityVerificationFiles ? 'Identity documents' : 'No identity documents'}
                        </FilesHeading>
                        {identityVerificationFiles.map((file: CustomerFileDTO) => (
                            <FileContainer key={file.id}>
                                <VerificationFile file={file} />
                            </FileContainer>
                        ))}
                    </FilesColumn>
                    <FilesColumn columnCount={hasIdentityVerificationVideoFiles ? 3 : 2}>
                        <FilesHeading>
                            {hasAddressVerificationFiles
                                ? 'Proof of address documents'
                                : 'No proof of address documents'}
                        </FilesHeading>
                        {addressVerificationFiles.map((file: CustomerFileDTO) => (
                            <FileContainer key={file.id}>
                                <VerificationFile file={file} />
                            </FileContainer>
                        ))}
                    </FilesColumn>
                    {hasIdentityVerificationVideoFiles && (
                        <FilesColumn columnCount={3}>
                            <FilesHeading>Identity videos</FilesHeading>
                            {identityVerificationVideoFiles.map((file: CustomerFileDTO) => (
                                <FileContainer key={file.id}>
                                    <VerificationFile file={file} />
                                </FileContainer>
                            ))}
                        </FilesColumn>
                    )}
                </Files>

                {(hasSignificantCashTransactions !== null || hasHighRiskActivity !== null) && (
                    <RiskAssessment>
                        <Flexer column gap="18px">
                            {hasSignificantCashTransactions !== null && (
                                <Flexer spaceBetween gap="24px">
                                    <Line size={font.small}>
                                        Are you involved in transactions concerning any of these: Oil, arms, precious
                                        metals, tobacco, protected species, banking/loans, or any goods or services
                                        which are illegal in Europe or the USA?
                                    </Line>
                                    <Spacer>
                                        {hasSignificantCashTransactions ? (
                                            <Label background={colors.red}>YES</Label>
                                        ) : (
                                            <Label background={colors.mintLighter}>NO</Label>
                                        )}
                                    </Spacer>
                                </Flexer>
                            )}
                            {hasHighRiskActivity !== null && (
                                <Flexer spaceBetween gap="24px">
                                    <Line size={font.small}>
                                        Do you get a high proportion of your income, or buy a large proportion of your
                                        supplies, using cash?
                                    </Line>
                                    <Spacer>
                                        {hasHighRiskActivity ? (
                                            <Label background={colors.red}>YES</Label>
                                        ) : (
                                            <Label background={colors.mintLighter}>NO</Label>
                                        )}
                                    </Spacer>
                                </Flexer>
                            )}
                        </Flexer>
                    </RiskAssessment>
                )}
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button isSecondary onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleRequestNewDocuments}>Request new docs</Button>
                    {handleMarkAsVerified && <Button onClick={handleMarkAsVerified}>Mark as verified</Button>}
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}

const Customer = styled.div`
    padding: 24px 0;
    border-top: 1px solid ${colors.neutralLightest};
    border-bottom: 1px solid ${colors.neutralLightest};

    ${media.tablet} {
        display: flex;
        justify-content: space-between;
    }
`

const CustomerColumn = styled.div`
    ${media.tablet} {
        flex: 0 0 calc(50% - 12px);
    }
`

const CustomerRow = styled.div<{ isMissing?: boolean }>`
    color: ${({ isMissing }) => (isMissing ? colors.neutralDarker : 'inherit')};
    font-size: ${font.small};

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Files = styled.div`
    padding: 24px 0;
    border-bottom: 1px solid ${colors.neutralLightest};

    ${media.tablet} {
        display: flex;
        justify-content: space-between;
    }
`

const FilesColumn = styled.div<{ columnCount: number }>`
    &:not(:last-child) {
        margin-bottom: 18px;

        ${media.tablet} {
            margin-bottom: 0;
        }
    }

    ${media.tablet} {
        flex: 0 0 calc(100% / ${({ columnCount }) => columnCount} - 12px);
        width: calc(100% / ${({ columnCount }) => columnCount} - 12px);
    }

    p:not(:last-child) {
        margin-bottom: 6px;
    }
`

const FilesHeading = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    margin-bottom: 18px;
`

const FileContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const RiskAssessment = styled.div`
    padding: 24px 0;
    border-bottom: 1px solid ${colors.neutralLightest};
`
