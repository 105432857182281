import {
    RECEIVE_CUSTOMER_ESCALATIONS,
    RECEIVE_ESCALATIONS,
    REMOVE_CUSTOMER_ESCALATION,
    REQUEST_CUSTOMER_ESCALATIONS,
    REQUEST_ESCALATIONS,
} from '../actions'
import { removeItemsById, updateOrAddItemsById } from '../helpers'

const defaultStatePagination = {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
}

const defaultState = {
    isFetching: false,
    isFetchingCustomerEscalations: false,
    shouldFetch: true,
    list: [],
    details: [],
    pagination: defaultStatePagination,
}

export default (state = defaultState, action) => {
    const { type, data, meta } = action

    switch (type) {
        case REQUEST_ESCALATIONS: {
            return { ...state, isFetching: true }
        }

        case REQUEST_CUSTOMER_ESCALATIONS: {
            return { ...state, isFetchingCustomerEscalations: true }
        }

        case RECEIVE_ESCALATIONS: {
            const items = [...data]
            const pagination = meta?.pagination || defaultStatePagination

            return { ...state, isFetching: false, shouldFetch: false, list: Object.assign([], items), pagination }
        }

        case RECEIVE_CUSTOMER_ESCALATIONS: {
            const details = updateOrAddItemsById({ currentItems: state.details, receivedItems: data })

            return { ...state, isFetchingCustomerEscalations: false, details }
        }

        case REMOVE_CUSTOMER_ESCALATION: {
            const { escalationId } = data
            const details = removeItemsById({ currentItems: state.details, id: escalationId })
            return { ...state, details }
        }

        default:
            return state
    }
}
