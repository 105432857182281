import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { requestRewards } from '../../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE, PATH_REWARDS } from '../../constants'
import { sendDecodingErrorsToSentry } from '../../helpers/sentry'
import PaginationContainer from '../PaginationContainer'
import Rewards from './Rewards'
import RewardsFilter from './RewardsFilter'

const Container = styled.div`
    padding: 24px 24px 192px;
`

const FilterContainer = styled.div`
    margin-bottom: 24px;
`

const PaginationContainerWrap = styled.div`
    margin-top: 24px;
`

class RewardsContainer extends Component {
    constructor() {
        super()

        this.state = {
            filter: {},
            filterToken: null,
            page: 1,
            pageSize: 100,
        }

        _bindAll(this, ['applyFilter', 'clearFilter', 'removeFilter'])
    }

    componentDidMount() {
        this.update()
    }

    componentDidUpdate() {
        this.update()
    }

    applyFilter = (updatedFilter, replace = false) => {
        const { history } = this.props
        const { filter } = this.state

        const string = btoa(JSON.stringify({ ...filter, ...updatedFilter }))

        const query = queryString.stringify({ filter: string })

        const location = {
            pathname: PATH_REWARDS,
            search: query,
        }

        if (replace) {
            history.replace(location)
        } else {
            history.push(location)
        }
    }

    replaceFilters = (updatedFilter) => {
        const { history } = this.props

        const string = btoa(JSON.stringify(updatedFilter))

        const query = queryString.stringify({ filter: string })

        const location = {
            pathname: PATH_REWARDS,
            search: query,
        }

        history.push(location)
    }

    removeFilter(filterName) {
        const { history } = this.props
        const { filter } = this.state

        const updatedFilter = { ...filter }

        let filterNames = []
        if (!Array.isArray(filterName)) {
            filterNames.push(filterName)
        } else {
            filterNames = filterName
        }

        filterNames.forEach((name) => {
            if (updatedFilter[name] !== undefined) {
                delete updatedFilter[name]
            }
        })

        if (filter !== updatedFilter) {
            const string = btoa(JSON.stringify(updatedFilter))

            const query = queryString.stringify({ filter: string })

            history.push({
                pathname: PATH_REWARDS,
                search: query,
            })
        }
    }

    clearFilter() {
        const { history } = this.props

        history.push({
            pathname: PATH_REWARDS,
            search: '',
        })
    }

    update() {
        const { location } = this.props
        const { filterToken: prevFilterToken, page: prevPage, pageSize: prevPageSize } = this.state

        const { filterToken, page, pageSize } = this.parseFilterSettingsFromUrl(location)

        const hasUpdated = filterToken !== prevFilterToken || page !== prevPage || pageSize !== prevPageSize

        if (hasUpdated) {
            const filter = this.parseFilterFromToken(filterToken)

            this.fetch({ filter, page, pageSize })

            this.setState({ filterToken, filter, page, pageSize })
        }
    }

    parseFilterSettingsFromUrl(location) {
        const { search } = location

        const query = queryString.parse(search)

        const filterToken = _get(query, ['filter'], '')
        const page = Number(_get(query, ['page'], PAGINATION_DEFAULT_PAGE))
        const pageSize = Number(_get(query, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE))

        return { filterToken, page, pageSize }
    }

    parseFilterFromToken(filterToken) {
        if (!filterToken) return {}

        try {
            return JSON.parse(atob(filterToken))
        } catch (exception) {
            sendDecodingErrorsToSentry(exception, filterToken, 'RewardsContainer')
            return {}
        }
    }

    fetch({ filter, page, pageSize }) {
        const { dispatch } = this.props
        dispatch(requestRewards({ filter, page, pageSize }))
    }

    render() {
        const { items, isFetching, pagination, location } = this.props
        const { filter } = this.state

        const itemsCount = items.length
        const hasItems = itemsCount > 0

        return (
            <Container>
                <FilterContainer>
                    <RewardsFilter
                        applyFilter={this.applyFilter}
                        clearFilter={this.clearFilter}
                        filter={filter}
                        itemsCount={itemsCount}
                        pagination={pagination}
                        removeFilter={this.removeFilter}
                        replaceFilters={this.replaceFilters}
                    />
                </FilterContainer>

                {hasItems && (
                    <>
                        <Rewards items={items} isFetching={isFetching} />

                        <PaginationContainerWrap>
                            <PaginationContainer
                                pathname={location.pathname}
                                search={location.search}
                                pagination={pagination}
                            />
                        </PaginationContainerWrap>
                    </>
                )}
            </Container>
        )
    }
}

RewardsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
}

const mapStateToProps = ({ rewards }) => {
    const { isFetching, items, pagination } = rewards
    return { items, pagination, isFetching }
}

export default withRouter(connect(mapStateToProps)(RewardsContainer))
