import { createContext, useContext } from 'react'

export interface IMessageComposerContext {
    openComposer: () => void
    closeComposer: () => void
    isComposerOpen: boolean
}

export const MessageComposerContext = createContext<IMessageComposerContext | null>(null)

export const useMessageComposerContext = () => {
    const context = useContext(MessageComposerContext)

    if (context === null) throw new Error('`useMessageComposer` should be used within a `MessageComposerProvider`')

    return context
}
