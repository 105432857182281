import { generateApiPath } from '@scouts/helpers'

import { LoggedEventDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerLoggedEvents: builder.query<LoggedEventDTO[], { customerId: number }>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.LOGGED_EVENTS.GET, { customerId }),
            }),
            providesTags: (_0, _1, { customerId }) => [{ type: Tags.LoggedEvents, id: customerId }],
        }),
        dismissSelfAssessmentEventsNotifications: builder.mutation<
            LoggedEventDTO[],
            { customerId: number; selfAssessmentId: number }
        >({
            query: ({ selfAssessmentId }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.LOGGED_EVENTS.PUT, { selfAssessmentId }),
            }),
            invalidatesTags: (_0, _1, { customerId }) => [{ type: Tags.LoggedEvents, id: customerId }],
        }),
    }),
})

export const { useGetCustomerLoggedEventsQuery, useDismissSelfAssessmentEventsNotificationsMutation } = extendedApi
