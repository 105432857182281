import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getAssignableAccountantsWithFullNameAndStatus } from '@/selectors'

import { useAccountants } from '@/hooks/use-accountants'

export const useAssignableAccountants = () => {
    useAccountants()

    const assignableAccountants = useSelector(getAssignableAccountantsWithFullNameAndStatus)

    const assignableAccountantOptions = useMemo(
        () => [{ title: 'Choose…', value: '' }, ...assignableAccountants],
        [assignableAccountants]
    )

    return assignableAccountantOptions as { value: string | number; title: string }[]
}
