import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    receiveCustomerProductBundles,
    receiveSuccessAlerts,
    removeProductBundle,
    ADD_PRODUCT_BUNDLE_REFUND,
    DELETE_PRODUCT_BUNDLE,
    REQUEST_CUSTOMER_PRODUCT_BUNDLES,
    SAVE_PRODUCT_BUNDLE,
} from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* productBundlesSagas() {
    yield takeEvery(REQUEST_CUSTOMER_PRODUCT_BUNDLES, fetchCustomerProductBundles)
    yield takeLatest(ADD_PRODUCT_BUNDLE_REFUND, addProductBundleRefund)
    yield takeLatest(SAVE_PRODUCT_BUNDLE, saveProductBundle)
    yield takeLatest(DELETE_PRODUCT_BUNDLE, deleteProductBundle)
}

function* fetchCustomerProductBundles({ data }) {
    const customerId = data?.customerId || ''

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${customerId}/productbundles`)
        yield put(receiveCustomerProductBundles(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* addProductBundleRefund({ data }) {
    const { productBundleId } = data

    const url = `productbundles/${productBundleId}/refund`

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'post', data)
        yield put(receiveCustomerProductBundles([response.data]))
        yield put(receiveSuccessAlerts(`Product bundle ${productBundleId} refunded`))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* deleteProductBundle({ data, meta }) {
    const { id: productBundleId } = data
    const { onSuccess } = meta

    const url = `productbundles/${productBundleId}`

    try {
        yield put(showLoading())
        yield apiCall(url, 'delete')
        yield put(removeProductBundle({ id: productBundleId }))
        yield put(receiveSuccessAlerts(`Product bundle ${productBundleId} deleted`))

        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveProductBundle({ productBundleId, data, meta }) {
    const { onSuccess } = meta

    const url = `productbundles/${productBundleId}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'put', data)
        yield put(receiveCustomerProductBundles([response.data]))
        yield put(receiveSuccessAlerts(`Tax return linked to product bundle changed`))

        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
