import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, shadows, ButtonLink } from '@scouts/ui'

import { resolveNote } from '@/actions'
import { PATH_HASH_ESCALATIONS, PATH_HASH_NOTES, QUERY_PARAM_ESCALATION_EDIT } from '@/constants'
import { formatDateWithTime, formatTimeTodayAndDateIfLater } from '@/helpers'
import { getUser } from '@/selectors'

export const CustomerEscalationsBannerNote = ({ note }) => {
    const { noteText, createdDateUTC, createdByAccountantFullName, customerFullName, escalationId } = note

    const dispatch = useDispatch()
    const history = useHistory()

    const { isAdminUser } = useSelector(getUser)

    const [isResolvePending, setIsResolvePending] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(false)

    const handleResolve = () => {
        const data = { ...note, isResolved: true }
        const meta = { onError: () => setIsResolvePending(false) }
        setIsResolvePending(true)
        dispatch(resolveNote(data, meta))
    }

    const escalationModalLocation = {
        search: queryString.stringify({ view: QUERY_PARAM_ESCALATION_EDIT, escalationId }),
        hash: PATH_HASH_ESCALATIONS,
    }

    return (
        <Container>
            <Heading>
                <Name>
                    <Link to={{ hash: PATH_HASH_NOTES }}>{customerFullName}</Link> needs attention
                </Name>

                {!isCollapsed && (
                    <Time title={formatDateWithTime(createdDateUTC)}>
                        {formatTimeTodayAndDateIfLater(createdDateUTC, ' ')} · by {createdByAccountantFullName}
                    </Time>
                )}

                {isCollapsed && <ButtonLink onClick={() => setIsCollapsed(false)}>Show</ButtonLink>}
            </Heading>

            {!isCollapsed && (
                <Content>
                    <NoteText>{noteText}</NoteText>
                    <Actions>
                        <ButtonLink onClick={() => setIsCollapsed(true)}>Hide</ButtonLink>
                        {isAdminUser && (
                            <div>
                                <ButtonLink disabled={isResolvePending} onClick={handleResolve}>
                                    Mark as resolved
                                </ButtonLink>
                                &nbsp;&nbsp;·&nbsp;&nbsp;
                                <ButtonLink onClick={() => history.push(escalationModalLocation)}>
                                    Show details
                                </ButtonLink>
                            </div>
                        )}
                    </Actions>
                </Content>
            )}
        </Container>
    )
}

CustomerEscalationsBannerNote.propTypes = {
    note: PropTypes.object.isRequired,
}

const Container = styled.div`
    padding: 18px 48px;
    background: ${colors.yellow};
    border-radius: 4px;
`

const Heading = styled.div`
    font-size: ${font.small};
    display: flex;
    justify-content: space-between;
`

const Name = styled.div`
    font-weight: ${font.weight.medium};

    a {
        text-decoration: none;
    }
`

const Time = styled.div``

const Content = styled.div`
    margin-top: 12px;
    padding: 18px 32px;
    background: ${colors.white};
    border-radius: 2px;
    box-shadow: ${shadows.low};
`

const NoteText = styled.div`
    line-height: 1.3;
    white-space: pre-line;
`

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`
