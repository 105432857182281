import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from '@scouts/ui'

const Container = styled.div`
    position: absolute;
    top: 6px;
    left: 0;
    z-index: 1000;
    min-width: 240px;
    padding: 18px;
    background: ${colors.neutralLightest};
`

const propTypes = {
    children: PropTypes.node.isRequired,
}

const FilterDropdown = (props) => {
    const { children } = props

    return <Container>{children}</Container>
}

FilterDropdown.propTypes = propTypes

export default FilterDropdown
