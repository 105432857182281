import {
    colors,
    font,
    radius,
    Container,
    Flexer,
    Grid,
    GridItem,
    InputWithSave,
    InputWithSaveProps,
    Line,
} from '@scouts/ui'

import { CompanyReturnDTO } from '@/models'
import { useUpdateCompanyReturn } from '@/store/company-returns/hooks'

import { useGetCompanyReturnParams } from '../hooks/use-get-params'

import { CompanyReturnInactivityStatusDropdown } from '../CompanyReturnInactivityStatusDropdown'
import { CompanyReturnStatusDropdown } from '../CompanyReturnStatusDropdown'

// @TODO: update fields with real data
export const TaxSummary = ({ companyReturn }: { companyReturn: CompanyReturnDTO }) => {
    const { customerId, companyId, companyReturnId, hasAllCompanyReturnParams } = useGetCompanyReturnParams()
    const { inactivityStatus, status, totalTaxDue, totalProfit, totalTaxableProfit, turnoverCategory } = companyReturn

    const { updateCompanyReturn, isLoading } = useUpdateCompanyReturn()

    const handleSave: InputWithSaveProps['onSave'] = ({ name, value }) => {
        if (hasAllCompanyReturnParams)
            updateCompanyReturn({
                customerId,
                companyId,
                companyReturnId,
                data: { ...companyReturn, [name]: Number(value) },
            })
    }

    const handleInactivityStatusChange = (updatedInactivityStatus: CompanyReturnDTO['inactivityStatus']) => {
        if (hasAllCompanyReturnParams)
            updateCompanyReturn({
                customerId,
                companyId,
                companyReturnId,
                data: { ...companyReturn, inactivityStatus: updatedInactivityStatus },
            })
    }

    return (
        <Container border={`1px solid ${colors.neutralLightest}`} radius={radius.large} padding="24px">
            <Flexer gap="12px" column>
                <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                    <GridItem>
                        <Line size={font.small}>Status</Line>
                    </GridItem>
                    <GridItem>
                        <CompanyReturnStatusDropdown status={status} />
                    </GridItem>
                </Grid>

                {turnoverCategory === 'NoTurnover' && (
                    <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                        <GridItem>
                            <Line size={font.small}>Inactivity status</Line>
                        </GridItem>
                        <GridItem>
                            <CompanyReturnInactivityStatusDropdown
                                onChange={handleInactivityStatusChange}
                                inactivityStatus={inactivityStatus}
                            />
                        </GridItem>
                    </Grid>
                )}

                <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                    <GridItem>
                        <Line size={font.small}>Total profit</Line>
                    </GridItem>
                    <GridItem>
                        <InputWithSave
                            name="totalProfit"
                            value={totalProfit !== null ? totalProfit : ''}
                            onSave={handleSave}
                            placeholder="Add..."
                            disabled={isLoading}
                        />
                    </GridItem>
                </Grid>

                <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                    <GridItem>
                        <Line size={font.small}>Total taxable profit</Line>
                    </GridItem>
                    <GridItem>
                        <InputWithSave
                            name="totalTaxableProfit"
                            value={totalTaxableProfit !== null ? totalTaxableProfit : ''}
                            onSave={handleSave}
                            placeholder="Add..."
                            disabled={isLoading}
                        />
                    </GridItem>
                </Grid>

                <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                    <GridItem>
                        <Line size={font.small}>Total tax due</Line>
                    </GridItem>
                    <GridItem>
                        <InputWithSave
                            name="totalTaxDue"
                            value={totalTaxDue !== null ? totalTaxDue : ''}
                            onSave={handleSave}
                            placeholder="Add..."
                            disabled={isLoading}
                        />
                    </GridItem>
                </Grid>
            </Flexer>
        </Container>
    )
}
