export const Tags = {
    AccountantMeetings: 'AccountantMeetings',
    AccountantReviews: 'AccountantReviews',
    AccountantStatistics: 'AccountantStatistics',
    AccountantStatusStatistics: 'AccountantStatusStatistics',
    AgentAuthorisations: 'AgentAuthorisations',
    BankAccount: 'BankAccount',
    Company: 'Company',
    CompanyReturns: 'CompanyReturns',
    CompanyReturnsStatistics: 'CompanyReturnsStatistics',
    CompanyReturnFiles: 'CompanyReturnFiles',
    CustomerProductSearch: 'CustomerProductSearch',
    CustomerProductStatistics: 'CustomerProductStatistics',
    CustomerUnresolvedEscalationsCount: 'CustomerUnresolvedEscalationsCount',
    DiscountSchemes: 'DiscountSchemes',
    DraftMessages: 'DraftMessages',
    IdentityVerifications: 'IdentityVerifications',
    LoggedEvents: 'LoggedEvents',
    Messages: 'Messages',
    MessageTemplates: 'MessageTemplates',
    Nylas: 'Nylas',
    OffPlatformServicesRequests: 'OffPlatformServicesRequests',
    Partner: 'Partner',
    Subscriptions: 'Subscriptions',
    SupportUsers: 'SupportUsers',
    TaxConsultations: 'TaxConsultations',
    TaxConsultationFiles: 'TaxConsultationFiles',
    TaxConsultationsStatistics: 'TaxConsultationsStatistics',
    TaxConsultationSummaryContent: 'TaxConsultationSummaryContent',
    TaxReliefClaimFiles: 'TaxReliefClaimFiles',
    TaxReliefClaims: 'TaxReliefClaims',
    TaxRegistrations: 'TaxRegistrations',
    TaxRegistrationsStatistics: 'TaxRegistrationsStatistics',
    UserAgreement: 'UserAgreement',
    TaxConsultationUserAgreement: 'TaxConsultationUserAgreement',
    SelfAssessmentUserAgreement: 'SelfAssessmentUserAgreement',
} as const
