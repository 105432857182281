import axios from 'axios'
import { useCallback, useState } from 'react'

import { generateApiPath, saveBlobAsFile } from '@scouts/helpers'
import { addErrorToast } from '@scouts/ui'

import { ERROR_MESSAGE_SAVING } from '@/constants'
import { getRequestConfig } from '@/helpers/api'
import { ENDPOINTS } from '@/store/endpoints'

import { GetCompanyReturnFilesParams } from '../api'

export const useDownloadCompanyReturnFilesZip = () => {
    const [isLoading, setIsLoading] = useState(false)
    const downloadCompanyReturnFilesZip = useCallback(
        async ({ customerId, companyReturnId, companyId }: GetCompanyReturnFilesParams) => {
            setIsLoading(true)
            const config = await getRequestConfig({
                url: generateApiPath(ENDPOINTS.COMPANY_RETURN_FILES.GET.DOWNLOAD_ZIP, {
                    customerId,
                    companyReturnId,
                    companyId,
                }),
                responseType: 'blob',
            })

            return axios(config)
                .then(({ data: blob }) => {
                    saveBlobAsFile({ blob, fileName: 'company-return-files.zip' })
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                    addErrorToast({ body: ERROR_MESSAGE_SAVING })
                })
        },
        []
    )

    return {
        isLoading,
        downloadCompanyReturnFilesZip,
    }
}
