import _orderBy from 'lodash/orderBy'
import _sortBy from 'lodash/sortBy'

import { MessageTemplateTypeTypes } from '@/models/message-template'

import { useGetMessageTemplatesQuery } from '../api'

export const useMessageTemplates = () => {
    const { data, isLoading } = useGetMessageTemplatesQuery()

    const getMessageTemplatesSortedByUpdateTime = (searchType: MessageTemplateTypeTypes) => {
        const filtered = data?.filter((template) => template.type === searchType)
        const ordered = _orderBy(filtered, ['updatedDateUTC'], ['desc'])
        return ordered
    }
    const getMessageTemplatesSortedByName = () => _sortBy(data, (template) => template.name?.toLowerCase() ?? '')
    const getMessageTemplateById = (id: number) => data?.find((template) => template.id === id)
    const getHasTemplateWithName = (name: string) => data?.some((template) => template.name === name)

    return {
        isLoading,
        messageTemplates: data ?? [],
        getMessageTemplatesSortedByUpdateTime,
        getMessageTemplatesSortedByName,
        getMessageTemplateById,
        getHasTemplateWithName,
    }
}
