import { useCallback } from 'react'

import { useIsTablet, Flexer, Select, SelectOnChangeParams, Tabs } from '@scouts/ui'

import { CompanyReturnDomain } from '@/domain'
import {
    CompanyReturnStatusFilter,
    CompanyReturnStatusFilters,
    CompanyReturnStatusFilterTitles,
    CompanyReturnStatusFilterTypes,
} from '@/models'
import { useCompanyReturnsSearchStatistics } from '@/store/company-returns/hooks/use-company-returns-search-statistics'

import { useFilters } from './hooks/use-filters'

import { usePagination } from '../pagination'

export const CompanyReturnsFilters = () => {
    const { activeFilters: paginationFilters } = usePagination()
    const { activeFilters, applyFilter } = useFilters()
    const isTablet = useIsTablet()

    const { companyReturnsStatistics } = useCompanyReturnsSearchStatistics({
        options: { ...activeFilters, ...paginationFilters },
    })

    const { status: filterStatus } = activeFilters

    const handleChangeStatus = useCallback(
        (status: CompanyReturnStatusFilterTypes) => {
            if (status === CompanyReturnStatusFilter.OnHold) {
                applyFilter({
                    updatedFilter: { status: CompanyReturnStatusFilter.OnHold, page: 1, returnOnHold: true },
                })
            } else if (status === CompanyReturnStatusFilter.All) {
                applyFilter({ updatedFilter: { status: null, page: 1, returnOnHold: false } })
            } else {
                applyFilter({ updatedFilter: { status, page: 1, returnOnHold: false } })
            }
        },
        [applyFilter]
    )

    const getCountPerFilter = (status: CompanyReturnStatusFilterTypes | null) => {
        if (status === null) {
            return companyReturnsStatistics?.reduce((sum, item) => sum + item.count, 0) || 0
        }

        let count = 0

        CompanyReturnStatusFilters[status]?.forEach((item) => {
            const itemValue = companyReturnsStatistics?.find(({ status }) => status === item)?.count || 0

            return (count += itemValue)
        })

        return count
    }

    if (!isTablet)
        return (
            <Select
                name="filterStatus"
                options={CompanyReturnDomain.filterOptionsMobile}
                value={filterStatus || ''}
                onChange={({ value }: SelectOnChangeParams) =>
                    handleChangeStatus(value as CompanyReturnStatusFilterTypes)
                }
            />
        )

    return (
        <Flexer spaceBetween column tabletRow gap="12px">
            <Tabs>
                {CompanyReturnDomain.filterOptions.map(({ value, title }) => (
                    <Tabs.Item key={value} onClick={() => handleChangeStatus(value)} isActive={filterStatus === value}>
                        {title}
                        <Tabs.Item.Counter>{getCountPerFilter(value)}</Tabs.Item.Counter>
                    </Tabs.Item>
                ))}
            </Tabs>

            <Tabs>
                <Tabs.Item
                    onClick={() => handleChangeStatus(CompanyReturnStatusFilter.OnHold)}
                    isActive={filterStatus === CompanyReturnStatusFilter.OnHold}
                >
                    {CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.OnHold]}
                    <Tabs.Item.Counter>{getCountPerFilter(CompanyReturnStatusFilter.OnHold)}</Tabs.Item.Counter>
                </Tabs.Item>
                <Tabs.Item
                    onClick={() => handleChangeStatus(CompanyReturnStatusFilter.All)}
                    isActive={filterStatus === null}
                >
                    {CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.All]}
                    <Tabs.Item.Counter>{getCountPerFilter(null)}</Tabs.Item.Counter>
                </Tabs.Item>
            </Tabs>
        </Flexer>
    )
}
