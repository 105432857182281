import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { matchErrorResponse, matchSuccessResponse, useToggle } from '@scouts/helpers'
import { Button, ButtonLink } from '@scouts/ui'

import { MESSAGE_TEXT_TYPE_GENERAL, MESSAGE_TEXT_TYPE_START_RETURN, MESSAGE_TYPE_SMS } from '@/constants'
import { getUser } from '@/selectors'
import { useSendCustomersMessage, useSendCustomerMessage } from '@/store/messages'

import SendMessages from './SendMessages'

const SendMessagesContainer = ({
    openByDefault,
    onSuccess,
    customers,
    label,
    availableTypes,
    useButtonLink,
    isDisabled,
}) => {
    const user = useSelector(getUser)
    const { sendCustomerMessage } = useSendCustomerMessage()
    const { sendCustomersMessage } = useSendCustomersMessage()
    const [isMessageModalOpen, openMessageModal, closeMessageModal] = useToggle(openByDefault)
    const [isSubmitPending, setIsSubmitPending] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [successResponse, setSuccessResponse] = useState([])

    const [subscribedCustomers, unsubscribedCustomers] = useMemo(() => {
        const subscribed = customers.filter((customer) => !customer.unsubscribeFromBulkEmails)
        const unsubscribed = customers.filter((customer) => customer.unsubscribeFromBulkEmails)

        return [subscribed, unsubscribed]
    }, [customers])

    const onSendSuccess = (data) => {
        if (isMessageModalOpen) {
            setIsSuccess(true)
            setSuccessResponse(data)
            setIsSubmitPending(false)
        }
    }

    const onSendError = () => {
        setIsSubmitPending(false)
    }

    const sendMessage = (data) => {
        const { messageContent, subject, messageType } = data

        const customerIds = subscribedCustomers.map((item) => Number(item.customerId))

        const customerCount = customerIds.length

        setIsSubmitPending(true)

        if (customerCount > 1 || (customerCount === 1 && messageType === MESSAGE_TYPE_SMS)) {
            sendCustomersMessage({ subject, messageContent, customerIds, messageType })
                .then(
                    matchSuccessResponse((response) => {
                        onSendSuccess([response])
                    })
                )
                .then(matchErrorResponse(onSendError))
        } else if (customerCount > 0) {
            sendCustomerMessage({
                attachments: data.attachments,
                customerId: customerIds[0],
                messageContent,
                subject,
            })
                .then(matchErrorResponse(onSendError))
                .then(matchSuccessResponse((response) => onSendSuccess([response])))
        }
    }

    const closeModal = () => {
        setIsSuccess(false)
        closeMessageModal()

        if (onSuccess && isSuccess) {
            onSuccess()
        }
    }

    return (
        <>
            {!openByDefault && !useButtonLink && (
                <Button disabled={customers.length === 0 || isDisabled} onClick={openMessageModal}>
                    {label}
                </Button>
            )}
            {!openByDefault && useButtonLink && (
                <ButtonLink isSmall disabled={customers.length === 0 || isDisabled} onClick={openMessageModal}>
                    {label}
                </ButtonLink>
            )}
            {isMessageModalOpen && (
                <SendMessages
                    availableTypes={availableTypes}
                    customers={subscribedCustomers}
                    isSubmitPending={isSubmitPending}
                    isSuccess={isSuccess}
                    onCancel={closeModal}
                    onSubmit={sendMessage}
                    successResponse={successResponse}
                    unsubscribedCustomers={unsubscribedCustomers}
                    user={user}
                />
            )}
        </>
    )
}

SendMessagesContainer.defaultProps = {
    availableTypes: [MESSAGE_TEXT_TYPE_GENERAL, MESSAGE_TEXT_TYPE_START_RETURN],
    isDisabled: false,
    label: 'Send message',
    onSuccess: null,
    openByDefault: false,
    useButtonLink: false,
}

SendMessagesContainer.propTypes = {
    availableTypes: PropTypes.array,
    customers: PropTypes.array.isRequired,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    onSuccess: PropTypes.func,
    openByDefault: PropTypes.bool,
    useButtonLink: PropTypes.bool,
}

export default SendMessagesContainer
