import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { colors, font, Button, Form, Input, Loadable, Select } from '@scouts/ui'

import { receiveErrorAlerts } from '../actions'
import { CURRENT_TAX_YEAR } from '../constants'
import { formatCurrency } from '../helpers'
import { apiRequest } from '../helpers/axios'

const Container = styled.div``

const EmploymentsContainer = styled.div`
    margin: 36px 0;
`

const Employments = styled.div`
    padding: 21px;
    background: ${colors.mintLighter};
`

const EmploymentHeading = styled.div`
    color: ${colors.black};
    font-size: ${font.small};
    display: flex;
    margin-bottom: 9px;
    padding-bottom: 9px;
    border-bottom: 1px solid ${colors.black};
`

const Employment = styled.div`
    font-size: ${font.small};
    display: flex;

    &:not(:last-child) {
        margin-bottom: 9px;
    }
`
const EmploymentColumn = styled.div`
    flex: 0 0 ${({ width }) => width || 'auto'};
`

class HmrcPreviewData extends Component {
    constructor(props) {
        super(props)

        const { uniqueTaxpayerReference } = this.props

        this.state = {
            isLoading: null,
            uniqueTaxpayerReference: uniqueTaxpayerReference || null,
            taxYear: CURRENT_TAX_YEAR - 1,
        }

        _bindAll(this, ['handleChange', 'requestEmployments'])
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value })
    }

    apiEndpoint() {
        const { uniqueTaxpayerReference, taxYear } = this.state

        return `hmrc/selfassessment/${uniqueTaxpayerReference}/employments/${taxYear}`
    }

    requestEmployments() {
        const { dispatch } = this.props
        const { taxYear } = this.state

        this.setState({ isLoading: true })

        const url = this.apiEndpoint()

        apiRequest(url)
            .then((response) => {
                this.setState({
                    employments: _get(response, ['data'], []),
                    isLoading: false,
                })
            })
            .catch((error) => {
                this.setState({ isLoading: false })

                dispatch(receiveErrorAlerts('There was a problem getting the information from HMRC'))

                if (error && error.response) {
                    if (error.response.status !== 404) {
                        if (error.response.data) {
                            if (
                                error.response.data.code === 'SA_UTR_INVALID' ||
                                error.response.data.exceptionMessage === 'Invalid UTR'
                            ) {
                                dispatch(receiveErrorAlerts('Invalid UTR number'))
                            }

                            if (error.response.data.code === 'UNAUTHORIZED') {
                                dispatch(
                                    receiveErrorAlerts(
                                        'HMRC thinks this was an unauthorised request. Maybe the NI/UTR number is wrong or you have not been authorised.'
                                    )
                                )
                            }

                            if (error.response.data.message) {
                                dispatch(receiveErrorAlerts(error.response.data.message))
                            }
                        }
                    } else {
                        dispatch(receiveErrorAlerts(`No data from HMRC for ${taxYear}`))
                    }
                }

                this.setState({
                    employments: null,
                })
            })
    }

    render() {
        const { employments, uniqueTaxpayerReference, taxYear, isLoading } = this.state

        return (
            <Container>
                <EmploymentsContainer>
                    <Loadable isLoading={isLoading}>
                        {employments && (
                            <Employments>
                                <EmploymentHeading>
                                    <EmploymentColumn width="40%">Employer</EmploymentColumn>
                                    <EmploymentColumn width="26%">Reference</EmploymentColumn>
                                    <EmploymentColumn width="17%">Income</EmploymentColumn>
                                    <EmploymentColumn width="17%">Tax paid</EmploymentColumn>
                                </EmploymentHeading>
                                {employments.map((employment) => (
                                    <Employment key={employment.id || employment.employerName}>
                                        <EmploymentColumn width="40%">{employment.employerName}</EmploymentColumn>
                                        <EmploymentColumn width="26%">{employment.employerReference}</EmploymentColumn>
                                        <EmploymentColumn width="17%">
                                            {formatCurrency(employment.payAmountFromHmrc)}
                                        </EmploymentColumn>
                                        <EmploymentColumn width="17%">
                                            {formatCurrency(employment.taxPaid)}
                                        </EmploymentColumn>
                                    </Employment>
                                ))}
                            </Employments>
                        )}
                    </Loadable>
                </EmploymentsContainer>

                <Form onSubmit={this.requestEmployments}>
                    <Form.Row>
                        <Form.Row.Title>Client's UTR number</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="uniqueTaxpayerReference"
                                value={uniqueTaxpayerReference || ''}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>Tax year</Form.Row.Title>
                        <Form.Row.Content>
                            <Select
                                name="taxYear"
                                value={taxYear}
                                onChange={this.handleChange}
                                options={[
                                    { value: 2017, title: '2016/17' },
                                    { value: 2018, title: '2017/18' },
                                    { value: 2019, title: '2018/19' },
                                    { value: 2020, title: '2019/20' },
                                    { value: 2021, title: '2020/21' },
                                    { value: 2022, title: '2021/22' },
                                ]}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Button type="submit">Preview employment data</Button>
                    </Form.Row>
                </Form>
            </Container>
        )
    }
}

HmrcPreviewData.propTypes = {
    dispatch: PropTypes.func.isRequired,
    uniqueTaxpayerReference: PropTypes.string,
}

HmrcPreviewData.defaultProps = {
    uniqueTaxpayerReference: undefined,
}

export default connect()(HmrcPreviewData)
