import _find from 'lodash/find'
import _get from 'lodash/get'
import _sumBy from 'lodash/sumBy'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import styled from 'styled-components'

import { hexToRgbA } from '@scouts/helpers'
import { colors, Tabs } from '@scouts/ui'

import {
    PATH_SELF_ASSESSMENTS,
    SELF_ASSESSMENT_STATUS_FILTER_ALL,
    SELF_ASSESSMENT_STATUS_FILTER_ASSIGNED,
    SELF_ASSESSMENT_STATUS_FILTER_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_FILTER_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_FILTER_FILED,
    SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_FILTER_HOLD,
    SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW,
    SELF_ASSESSMENT_STATUS_FILTER_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED,
    SELF_ASSESSMENT_STATUS_FILTER_TITLES,
    SELF_ASSESSMENT_STATUS_FILTERS,
    SELF_ASSESSMENT_STATUS_ON_HOLD,
} from '../../constants'

const Container = styled.div`
    display: flex;
    justify-content: space-between;

    button {
        position: relative;
    }
`

const Column = styled.div``

const Count = styled.div`
    display: inline-block;
    margin-left: 6px;

    @media (max-width: 1500px) {
        ${({ isActive, isItemLong }) =>
            !isActive &&
            isItemLong &&
            `
                position: absolute;
                z-index: 1;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;
                padding: 0 12px 0 24px;
                height: 100%;
                background: linear-gradient(to right, ${hexToRgbA(colors.neutralLightest, 0)}, ${hexToRgbA(
                colors.neutralLightest,
                1
            )} 25%);
        `}
    }
`

class SelfAssessmentsFilters extends Component {
    leftColumnFilters = [
        SELF_ASSESSMENT_STATUS_FILTER_ASSIGNED,
        SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED,
        SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW,
        SELF_ASSESSMENT_STATUS_FILTER_INFORMATION_REQUIRED,
        SELF_ASSESSMENT_STATUS_FILTER_CUSTOMER_CORRECTIONS,
        SELF_ASSESSMENT_STATUS_FILTER_AWAITING_CUSTOMER_APPROVAL,
        SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE,
        SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND,
        SELF_ASSESSMENT_STATUS_FILTER_FILED,
    ]

    rightColumnFilters = [SELF_ASSESSMENT_STATUS_FILTER_HOLD, SELF_ASSESSMENT_STATUS_FILTER_ALL]

    shouldIgnoreFilter(givenFilter) {
        const { filterStatus: activeFilter, shouldHideAwaitingRefundStatus } = this.props

        switch (givenFilter) {
            case SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND:
                return (
                    shouldHideAwaitingRefundStatus && !this.countPerFilter(givenFilter) && activeFilter !== givenFilter
                )
            default:
                return false
        }
    }

    toQueryString(value) {
        const { filterAccountant } = this.props

        const values = { ...value }

        if (filterAccountant) {
            values.accountantId = filterAccountant
        }

        return queryString.stringify(values)
    }

    countPerFilter(filterStatus) {
        const { selfAssessmentStatistics } = this.props

        if (filterStatus === SELF_ASSESSMENT_STATUS_FILTER_ALL) {
            return _sumBy(selfAssessmentStatistics, 'count')
        }

        if (filterStatus === SELF_ASSESSMENT_STATUS_FILTER_HOLD) {
            return _get(_find(selfAssessmentStatistics, { status: SELF_ASSESSMENT_STATUS_ON_HOLD }), ['count'], 0)
        }

        if (!SELF_ASSESSMENT_STATUS_FILTERS[filterStatus]) {
            return '?'
        }

        return SELF_ASSESSMENT_STATUS_FILTERS[filterStatus].reduce(
            (total, status) => total + _get(_find(selfAssessmentStatistics, { status }), ['count'], 0),
            0
        )
    }

    updateFilter(status) {
        const { history, filterIncludeUnpaid, isAdminUser } = this.props

        let query = { status }

        if (isAdminUser && filterIncludeUnpaid) {
            query = {
                status,
                ...(filterIncludeUnpaid === 'true' ? { includeUnpaid: 'true' } : { includeUnpaid: 'false' }),
            }
        }

        history.push({
            pathname: PATH_SELF_ASSESSMENTS,
            search: this.toQueryString(query),
        })
    }

    renderFilters(filterIds) {
        const { filterStatus: activeFilter } = this.props

        return filterIds.map((filterId) => {
            const itemLength = SELF_ASSESSMENT_STATUS_FILTER_TITLES[filterId].length
            const isItemLong = itemLength > 12

            if (this.shouldIgnoreFilter(filterId)) {
                return null
            }
            return (
                <Tabs.Item
                    onClick={() => this.updateFilter(filterId)}
                    key={filterId}
                    isActive={activeFilter === filterId}
                >
                    {SELF_ASSESSMENT_STATUS_FILTER_TITLES[filterId]}
                    <Count isActive={activeFilter === filterId} isItemLong={isItemLong}>
                        {this.countPerFilter(filterId)}
                    </Count>
                </Tabs.Item>
            )
        })
    }

    render() {
        return (
            <Container>
                <Column>
                    <Tabs>{this.renderFilters(this.leftColumnFilters)}</Tabs>
                </Column>
                <Column>
                    <Tabs>{this.renderFilters(this.rightColumnFilters)}</Tabs>
                </Column>
            </Container>
        )
    }
}

SelfAssessmentsFilters.propTypes = {
    history: PropTypes.object.isRequired,
    filterAccountant: PropTypes.string,
    filterIncludeUnpaid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    filterStatus: PropTypes.string,
    isAdminUser: PropTypes.bool.isRequired,
    selfAssessmentStatistics: PropTypes.array.isRequired,
    shouldHideAwaitingRefundStatus: PropTypes.bool.isRequired,
}

SelfAssessmentsFilters.defaultProps = {
    filterAccountant: null,
    filterIncludeUnpaid: null,
    filterStatus: SELF_ASSESSMENT_STATUS_FILTER_ALL,
}

export default SelfAssessmentsFilters
