import { useCallback } from 'react'

import { isResponseError } from '@scouts/helpers'

import { AgentAuthorisationStatus } from '@/models'
import { useAppDispatch } from '@/store/store'

import { usePollHmrcAgentAuthorisationMutation, PollHmrcAgentAuthorisationParams } from '../api'
import { invalidateAgentAuthorisations } from './use-customers-agent-authorisations'
import { useUpdateAgentAuthorisationWithoutValidation } from './use-update-agent-authorisation-without-validation'

interface PollOptions {
    onError: () => void
}

export const usePollHmrcAgentAuthorisation = () => {
    const dispatch = useAppDispatch()
    const [action, { isLoading }] = usePollHmrcAgentAuthorisationMutation()
    const { updateAgentAuthorisation } = useUpdateAgentAuthorisationWithoutValidation()

    const pollHmrcAgentAuthorisation = useCallback(
        async ({
            agentAuthorisation,
            pollOptions,
        }: PollHmrcAgentAuthorisationParams & { pollOptions: PollOptions }) => {
            const response = await action({ agentAuthorisation })

            if (isResponseError(response)) {
                const { onError } = pollOptions

                const failedAuthorisation = {
                    ...agentAuthorisation,
                    correlationId: null,
                    pollInterval: null,
                    status: AgentAuthorisationStatus.RequestFailed,
                }

                updateAgentAuthorisation({
                    agentAuthorisationId: agentAuthorisation.id,
                    data: failedAuthorisation,
                })

                onError()
            } else {
                dispatch(invalidateAgentAuthorisations())
            }

            return response
        },
        [action, updateAgentAuthorisation, dispatch]
    )

    return { pollHmrcAgentAuthorisation, isLoading }
}
