import { colors } from '@scouts/ui'

import { TaxRegistrationStatus, TaxRegistrationStatusTitles } from '@/models'

const statuses = {
    [TaxRegistrationStatus.Started]: {
        color: colors.neutralLightest,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Started],
    },
    [TaxRegistrationStatus.Submitted]: {
        color: colors.blueLighter,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Submitted],
    },
    [TaxRegistrationStatus.InformationRequested]: {
        color: colors.yellow,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.InformationRequested],
    },
    [TaxRegistrationStatus.Complete]: {
        color: colors.mintLighter,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Complete],
    },
    [TaxRegistrationStatus.Cancelled]: {
        color: colors.red,
        title: TaxRegistrationStatusTitles[TaxRegistrationStatus.Cancelled],
    },
}

export const TaxRegistrationDomain = {
    statuses,
}
