import { PAGINATION_DEFAULT_PAGE } from '@/constants'
import { OffPlatformServicesRequestDomain } from '@/domain'
import { OffPlatformServicesRequestDTO } from '@/models'

import { FilterItem, FilterItemDropdownRadios } from '@/components/ui/filter'

import { useFilters } from '../hooks'

export const OffPlatformServicesRequestSearchFilterStatus = () => {
    const { activeFilters, removeFilter, applyFilter } = useFilters()
    const { status } = activeFilters
    const hasActiveFilter = !!status

    const handleFilterChange = ({ status }: { status: OffPlatformServicesRequestDTO['status'] }) => {
        applyFilter({ updatedFilter: { status, page: PAGINATION_DEFAULT_PAGE } })
    }

    const values = Object.entries(OffPlatformServicesRequestDomain.statuses).map(([key, value]) => ({
        value: key,
        label: value.title,
        checked: status === key,
    }))

    const activeValue = values.find((item) => item.value === status)?.label || null

    const name = 'status'

    return (
        <FilterItem
            label="Status"
            activeValues={[activeValue]}
            removeFilter={() => removeFilter({ updatedFilter: { status: null } })}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownRadios
                applyFilter={handleFilterChange}
                name={name}
                removeFilter={() => removeFilter({ updatedFilter: { status: null } })}
                values={values}
            />
        </FilterItem>
    )
}
