import { Auth } from '@scouts/auth'
import { Page } from '@scouts/ui'

export const Security = () => (
    <Page>
        <Page.Heading>Security</Page.Heading>
        <Page.Section>
            <Auth.AuthManagement />
        </Page.Section>
    </Page>
)
