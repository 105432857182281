import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    Button,
    Form,
    Input,
    InputOnChangeParams,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { getUser } from '@/selectors/user'

const IntegrationsNylasEmailHintModal = ({
    onClose,
    onSubmit,
}: {
    onClose: () => void
    onSubmit: (email: string) => void
}) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const { email: initialEmail } = useSelector(getUser)

    const [email, setEmail] = useState(initialEmail)
    const handleChange = ({ value }: InputOnChangeParams) => setEmail(value)

    const handleSubmit = () => {
        if (inputRef.current?.checkValidity()) onSubmit(email)
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Which email address would you like to integrate with?</ModalTitle>
                    <ModalSubtitle>
                        This email should be the one you use with your calendar, and will be where tax consultation
                        meetings are scheduled
                    </ModalSubtitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Email address</Form.Row.Title>
                        <Form.Row.Content>
                            <Input ref={inputRef} type="email" name="email" value={email} onChange={handleChange} />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={onClose}>
                            Cancel
                        </Button>

                        <Button type="submit" onClick={handleSubmit}>
                            Continue
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

IntegrationsNylasEmailHintModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default IntegrationsNylasEmailHintModal
