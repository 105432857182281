import { useState } from 'react'
import styled from 'styled-components'

import {
    colors,
    font,
    media,
    radius,
    ConfirmationModal,
    Container,
    File,
    Flexer,
    Line,
    Skeleton,
    SkeletonLine,
    Spacer,
} from '@scouts/ui'

import { TaxReliefClaimDomain } from '@/domain'
import { TaxReliefClaimDTO, TaxReliefClaimFileDTO } from '@/models'
import {
    useDeleteTaxReliefClaimFile,
    useDownloadTaxReliefClaimFile,
    useTaxReliefClaimFiles,
} from '@/store/tax-relief-claims'

import { TaxReliefClaimUploadFiles } from './TaxReliefClaimUploadFiles'

interface TaxReliefClaimAccountantUploadsProps {
    taxReliefClaimId: TaxReliefClaimDTO['id']
}

export const TaxReliefClaimAccountantUploads = ({ taxReliefClaimId }: TaxReliefClaimAccountantUploadsProps) => {
    const { taxReliefClaimAccountantFiles, isLoading } = useTaxReliefClaimFiles(taxReliefClaimId)
    const { deleteTaxReliefClaimFile } = useDeleteTaxReliefClaimFile()
    const { downloadTaxReliefClaimFile } = useDownloadTaxReliefClaimFile()
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
    const [deletableFileId, setDeletableFileId] = useState<number | null>(null)

    const hasFiles = taxReliefClaimAccountantFiles && taxReliefClaimAccountantFiles.length > 0

    const handleFileDownload = (taxReliefClaimFileId: TaxReliefClaimFileDTO['id']) => {
        downloadTaxReliefClaimFile({ taxReliefClaimId, taxReliefClaimFileId })
    }

    const confirmDeleteFile = (taxReliefClaimFileId: TaxReliefClaimFileDTO['id']) => {
        setDeletableFileId(taxReliefClaimFileId)
        setIsDeleteConfirmationModalOpen(true)
    }

    const handleCloseModal = () => {
        setDeletableFileId(null)
        setIsDeleteConfirmationModalOpen(false)
    }

    const handleDeleteFile = () => {
        if (deletableFileId)
            deleteTaxReliefClaimFile({ taxReliefClaimId, taxReliefClaimFileId: deletableFileId }).then(() =>
                handleCloseModal()
            )
    }

    if (isLoading)
        return (
            <Skeleton gap="12px">
                <SkeletonLine width="60%" height="21px" />
                <SkeletonLine height="30px" />
                <SkeletonLine width="60%" height="21px" />
                <SkeletonLine height="30px" />
                <Flexer justifyEnd>
                    <SkeletonLine width="120px" height="38px" />
                </Flexer>
            </Skeleton>
        )

    return (
        <>
            <Container>
                <Spacer marginBottom="18px">
                    <Flexer justifyEnd>
                        <TaxReliefClaimUploadFiles taxReliefClaimId={taxReliefClaimId} />
                    </Flexer>
                </Spacer>

                {!hasFiles && (
                    <Container
                        background={colors.redLighter}
                        desktopPadding="24px 48px"
                        padding="24px"
                        radius={radius.small}
                    >
                        <Line textAlign="center">Proof of postage and completed form needed</Line>
                    </Container>
                )}

                {hasFiles && (
                    <FilesContainer>
                        <Flexer column gap="12px">
                            {taxReliefClaimAccountantFiles.map((file) => (
                                <Spacer key={file.id}>
                                    <Spacer marginBottom="6px">
                                        <Line weight={font.weight.medium} size={font.small}>
                                            {TaxReliefClaimDomain.fileTypeTitles[file.fileType].title}
                                        </Line>
                                    </Spacer>
                                    <File
                                        background={colors.mintLighter}
                                        onClick={() => handleFileDownload(file.id)}
                                        onDelete={() => confirmDeleteFile(file.id)}
                                    >
                                        {file.fileName}
                                    </File>
                                </Spacer>
                            ))}
                        </Flexer>
                    </FilesContainer>
                )}
            </Container>

            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal onCancel={handleCloseModal} onConfirm={handleDeleteFile}>
                    Are you sure you want to delete this file?
                </ConfirmationModal>
            )}
        </>
    )
}

const FilesContainer = styled.div`
    overflow: hidden;
    margin-bottom: 12px;

    ${media.tablet} {
        margin-bottom: 0;
    }
`
