import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const PensionsContributions = ({
    pensionsContributions,
}: {
    pensionsContributions: SelfAssessmentDTO['pensionsContributions']
}) => {
    if (!pensionsContributions) return null

    const {
        additionalNotes,
        amountOfPensionsContributions,
        contributionsThroughSalary,
        employerAddedContributions,
        personalContributions,
    } = pensionsContributions

    return (
        <>
            <SectionData>
                <SectionDataRow label="Contributions through salary" value={contributionsThroughSalary} boolean />
                <SectionDataRow label="Personal contributions" value={personalContributions} boolean />
                <SectionDataRow
                    label="Amount of pensions contributions"
                    value={formatCurrencyNotSure(amountOfPensionsContributions)}
                />
                <SectionDataRow label="Employer added contributions" value={employerAddedContributions} boolean />
                {additionalNotes && <SectionDataRow label="Notes" value={additionalNotes} />}
            </SectionData>
        </>
    )
}
