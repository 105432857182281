import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, NavLink } from 'react-router-dom'

import {
    colors,
    AccountantPortalChrome,
    Badge,
    Icon,
    IconAlertTriangle,
    IconComments2,
    IconNoteText,
    IconUser,
} from '@scouts/ui'

import { PATH_HASH_CHATS, PATH_HASH_ESCALATIONS, PATH_HASH_NOTES } from '@/constants'
import { getUser } from '@/selectors'

import { useConversationsCount } from './hooks/use-conversations-count'
import { useEscalationsCount } from './hooks/use-escalations-count'
import { useNotesCount } from './hooks/use-notes-count'

import { CustomerProfile } from './CustomerProfile'
import { CustomerSidebarChats } from './CustomerSidebarChats'
import { CustomerSidebarEscalations } from './CustomerSidebarEscalations'
import { CustomerSidebarNotes } from './CustomerSidebarNotes'

export const CustomerSidebar = () => {
    const { pathname, hash } = useLocation()

    const { isAdminUser } = useSelector(getUser)

    const { conversationsCount, hasUnreadConversations } = useConversationsCount()
    const { unresolvedNotesCount, hasUnresolvedNotes } = useNotesCount()
    const { unresolvedEscalationsCount, hasUnresolvedEscalations } = useEscalationsCount()

    const getPath = useCallback((itemHash) => `${pathname}${itemHash === hash ? '' : itemHash}`, [hash, pathname])

    const isChatsActive = hash === PATH_HASH_CHATS
    const isNotesActive = hash === PATH_HASH_NOTES
    const isEscalationsActive = hash === PATH_HASH_ESCALATIONS
    const isProfileActive = !isChatsActive && !isNotesActive && !isEscalationsActive

    const shouldDisplayEscalations = isAdminUser

    return (
        <>
            <AccountantPortalChrome.Customer.Sidebar.Nav>
                <AccountantPortalChrome.Customer.Sidebar.NavItem
                    as={NavLink}
                    to={pathname}
                    className={isProfileActive ? 'is-active' : ''}
                >
                    <Icon
                        Component={IconUser}
                        color={isProfileActive ? colors.black : colors.neutralDarker}
                        hoverColor={colors.blue}
                        width="100%"
                        height="100%"
                    />
                </AccountantPortalChrome.Customer.Sidebar.NavItem>

                <AccountantPortalChrome.Customer.Sidebar.NavItem
                    as={NavLink}
                    to={getPath(PATH_HASH_CHATS)}
                    className={isChatsActive ? 'is-active' : ''}
                >
                    {conversationsCount > 0 && (
                        <AccountantPortalChrome.Customer.Sidebar.NavItemBadge>
                            <Badge
                                background={hasUnreadConversations ? colors.blue : colors.neutralDarker}
                                color={colors.white}
                                isSmall
                            >
                                {conversationsCount}
                            </Badge>
                        </AccountantPortalChrome.Customer.Sidebar.NavItemBadge>
                    )}
                    <Icon
                        Component={IconComments2}
                        color={isChatsActive ? colors.black : colors.neutralDarker}
                        hoverColor={colors.blue}
                        width="100%"
                        height="100%"
                    />
                </AccountantPortalChrome.Customer.Sidebar.NavItem>

                <AccountantPortalChrome.Customer.Sidebar.NavItem
                    as={NavLink}
                    to={getPath(PATH_HASH_NOTES)}
                    className={isNotesActive ? 'is-active' : ''}
                >
                    {hasUnresolvedNotes && (
                        <AccountantPortalChrome.Customer.Sidebar.NavItemBadge>
                            <Badge color={colors.black} background={colors.orange} isSmall>
                                {unresolvedNotesCount}
                            </Badge>
                        </AccountantPortalChrome.Customer.Sidebar.NavItemBadge>
                    )}
                    <Icon
                        Component={IconNoteText}
                        color={isNotesActive ? colors.black : colors.neutralDarker}
                        hoverColor={colors.blue}
                        width="100%"
                        height="100%"
                    />
                </AccountantPortalChrome.Customer.Sidebar.NavItem>

                {shouldDisplayEscalations && (
                    <AccountantPortalChrome.Customer.Sidebar.NavItem
                        as={NavLink}
                        to={getPath(PATH_HASH_ESCALATIONS)}
                        className={isEscalationsActive ? 'is-active' : ''}
                    >
                        {hasUnresolvedEscalations && (
                            <AccountantPortalChrome.Customer.Sidebar.NavItemBadge>
                                <Badge color={colors.black} background={colors.yellow} isSmall>
                                    {unresolvedEscalationsCount}
                                </Badge>
                            </AccountantPortalChrome.Customer.Sidebar.NavItemBadge>
                        )}
                        <Icon
                            Component={IconAlertTriangle}
                            color={isEscalationsActive ? colors.black : colors.neutralDarker}
                            hoverColor={colors.blue}
                            width="100%"
                            height="100%"
                        />
                    </AccountantPortalChrome.Customer.Sidebar.NavItem>
                )}
            </AccountantPortalChrome.Customer.Sidebar.Nav>
            <AccountantPortalChrome.Customer.Sidebar.Content>
                {isProfileActive && <CustomerProfile />}
                {isChatsActive && <CustomerSidebarChats />}
                {isNotesActive && <CustomerSidebarNotes />}
                {isEscalationsActive && shouldDisplayEscalations && <CustomerSidebarEscalations />}
            </AccountantPortalChrome.Customer.Sidebar.Content>
        </>
    )
}
