import styled from 'styled-components'

import { colors, font, Label } from '@scouts/ui'

import { formatDateWithTime, formatTaxYear, splitEventText } from '@/helpers'
import { LoggedEventDTO, LoggedEventType, LoggedEventTypeTitles, LoggedEventTypeTypes, UserType } from '@/models'

const eventColor = (eventType: LoggedEventTypeTypes) => {
    switch (eventType) {
        case LoggedEventType.TemplateEmailSent:
        case LoggedEventType.MessageSent:
            return colors.neutralLightest
        case LoggedEventType.CompanyReturnStatusChange:
        case LoggedEventType.SelfAssessmentSectionStatusForce:
        case LoggedEventType.SelfAssessmentStatusChange:
        case LoggedEventType.TaxConsultationStatusChange:
            return colors.purpleLighter
        case LoggedEventType.FileUploaded:
            return colors.blueLighter
        case LoggedEventType.CompanyReturnPayment:
        case LoggedEventType.TaxRegistrationPayment:
        case LoggedEventType.SelfAssessmentPayment:
        case LoggedEventType.IssueResolved:
        case LoggedEventType.ProfileUpdate:
        case LoggedEventType.TaxConsultationPayment:
        case LoggedEventType.ProductBundlePayment:
            return colors.mintLighter
        case LoggedEventType.TaxRegistrationStatusChange:
        case LoggedEventType.AgentAuthorisation:
        case LoggedEventType.IdentityVerification:
        case LoggedEventType.PriorityNote:
            return colors.redLighter
        case LoggedEventType.Delete:
            return colors.red
        default:
            return colors.neutralLightest
    }
}

const eventItemBackground = (eventType: LoggedEventTypeTypes) => {
    switch (eventType) {
        case LoggedEventType.MessageSent:
        case LoggedEventType.TemplateEmailSent:
            return colors.neutralLightest
        case LoggedEventType.PriorityNote:
            return colors.redLighter
        default:
            return colors.neutralLightest
    }
}

export const CustomerHistoryLogItem = ({ event }: { event: LoggedEventDTO }) => {
    const { customerFullName, eventDateUTC, eventText, loggedEventType, selfAssessmentTaxYear, userType } = event

    const isCustomer = userType === UserType.Customer
    const isMessage = (
        [LoggedEventType.MessageSent, LoggedEventType.TemplateEmailSent] as LoggedEventTypeTypes[]
    ).includes(loggedEventType)
    const isPaymentEvent = ([LoggedEventType.SelfAssessmentPayment] as LoggedEventTypeTypes[]).includes(loggedEventType)
    const isNoteEvent = ([LoggedEventType.PriorityNote] as LoggedEventTypeTypes[]).includes(loggedEventType)

    const isRefund = isPaymentEvent && eventText?.includes('refund')

    const labelBackground = isRefund ? colors.red : eventColor(loggedEventType)
    const messageBackground = eventItemBackground(loggedEventType)

    return (
        <Container>
            <Line />
            <Dot />
            <Event isCustomer={isCustomer}>
                <EventMeta isCustomer={isCustomer}>
                    {formatDateWithTime(eventDateUTC)} {isCustomer && <EventUser>by {customerFullName}</EventUser>}
                </EventMeta>
                <EventHeading>
                    <EventType>
                        <Label background={labelBackground}>
                            {LoggedEventTypeTitles[loggedEventType] ?? loggedEventType}
                        </Label>
                        {selfAssessmentTaxYear && (
                            <EventTaxYear> · {formatTaxYear(Number(selfAssessmentTaxYear))}</EventTaxYear>
                        )}
                    </EventType>
                </EventHeading>

                {isMessage || isNoteEvent ? (
                    <EventMessage background={messageBackground}>{eventText}</EventMessage>
                ) : (
                    <EventBody>{splitEventText(eventText)}</EventBody>
                )}
            </Event>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    padding: 24px 0 48px;
`

const Line = styled.div`
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background: ${colors.neutralLightest};
`

const Dot = styled.div`
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -3px;
    width: 7px;
    height: 7px;
    border-radius: 9999px;
    background: ${colors.neutralLightest};
`

const Event = styled.div<{ isCustomer?: boolean }>`
    position: relative;
    font-size: ${font.small};
    width: calc(50% - 24px);
    margin-left: ${({ isCustomer }) => (isCustomer ? 'calc(50% + 24px)' : 0)};
`

const EventMeta = styled.div<{ isCustomer?: boolean }>`
    color: ${colors.neutralDarker};
    display: flex;
    justify-content: ${({ isCustomer }) => (isCustomer ? 'space-between' : 'flex-end')};
    position: absolute;
    top: -28px;
    right: 0;
    width: 100%;
`

const EventUser = styled.div`
    color: ${colors.neutralDarker};
`

const EventHeading = styled.div`
    display: flex;
    align-items: center;
`

const EventType = styled.div`
    color: ${({ color }) => color};
    white-space: nowrap;
`

const EventTaxYear = styled.span``

const EventBody = styled.div`
    line-height: 1.5;
    margin-top: 9px;
`

const EventMessage = styled.div<{ background: string }>`
    line-height: 1.5;
    white-space: pre-line;
    overflow-wrap: anywhere;
    margin: 12px 0 18px;
    padding: 15px 18px;
    background: ${(props) => props.background};
    border-radius: 4px;
`
