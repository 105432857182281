import { useUploadTaxConsultationFileMutation } from '../api'

export const useUploadTaxConsultationFile = () => {
    const [uploadTaxConsultationFile, { isError, isLoading }] = useUploadTaxConsultationFileMutation()

    return {
        uploadTaxConsultationFile,
        isError,
        isLoading,
    }
}
