import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Checkbox, IconCommentSlash, Label, Spacer, Table } from '@scouts/ui'

import { PATH_CUSTOMERS, TAX_YEARS } from '../../constants'
import { isAdmin } from '../../helpers'
import { AccountantStatusBubble } from '../accountants/AccountantStatusBubble'
import { CustomerIndicators } from '../customer-indicators'
import NotesIndicator from '../NotesIndicator'
import { SelfAssessmentStatusLabel } from '../self-assessments/SelfAssessmentStatusLabel'
import ReturningCustomersTableMessagesCounter from './ReturningCustomersTableMessagesCounter'

const Customer = styled.div``

const AccountantName = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    line-height: 1.3;
    display: flex;
    align-items: center;
    margin-top: 6px;
`

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
`

const SelfAssessment = styled.div``

const TaxYear = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const LastUpdated = styled.div`
    font-size: ${font.small};
`

const IconUnsubscribed = styled.div`
    margin-right: 18px;
`

const ReturningCustomersTableRow = (props) => {
    const { customer, isAdminUser, isSelected, handleSelect, triggerTableUpdate } = props

    const {
        accountantFullName,
        accountantStatus,
        agentAuthorisationStatus,
        currentSelfAssessmentId,
        currentSelfAssessmentOneClickTaxReturn,
        currentSelfAssessmentPayAfterFiling,
        currentSelfAssessmentTaxYear,
        email,
        fullName,
        id,
        identityVerificationStatus,
        latestEventDateUTC,
        numberOfMessages,
        unresolvedNoteCount,
        priorityUnresolvedNotes,
        status,
        taxRegistrationStatus,
        uniqueTaxpayerReference,
        unsubscribeFromBulkEmails,
    } = customer

    const customerUrl = `${PATH_CUSTOMERS}/${id}`

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <Customer>
                        <Name>{fullName}</Name>
                        <Email>{email}</Email>
                        {isAdminUser && accountantFullName && (
                            <AccountantName>
                                {accountantFullName}{' '}
                                <Spacer marginLeft="6px">
                                    <AccountantStatusBubble status={accountantStatus} />
                                </Spacer>
                            </AccountantName>
                        )}
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="16%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <CustomerIndicators
                        agentAuthorisationStatus={agentAuthorisationStatus}
                        taxRegistrationStatus={taxRegistrationStatus}
                        uniqueTaxpayerReference={uniqueTaxpayerReference}
                        identityVerificationStatus={identityVerificationStatus}
                        hidePayThroughTaxCodeRequested
                    />
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="17%">
                {currentSelfAssessmentId && (
                    <Table.Body.Cell.ContentLink Link={Link} to={`${customerUrl}/${currentSelfAssessmentId}`}>
                        <SelfAssessment>
                            <TaxYear>{TAX_YEARS[currentSelfAssessmentTaxYear]}</TaxYear>
                            <SelfAssessmentStatusLabel status={status || ''} />
                            {currentSelfAssessmentPayAfterFiling && (
                                <>
                                    {' '}
                                    <Label color={colors.black} background={colors.purpleLighter}>
                                        Pay later
                                    </Label>
                                </>
                            )}
                        </SelfAssessment>
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>

            <Table.Body.Cell width="12%">
                {latestEventDateUTC && (
                    <Table.Body.Cell.ContentLink
                        Link={Link}
                        to={`${customerUrl}/history/${currentSelfAssessmentId || ''}`}
                    >
                        <LastUpdated title={latestEventDateUTC}>{moment.utc(latestEventDateUTC).fromNow()}</LastUpdated>
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(15%)">
                <Table.Body.Cell.Content>
                    <NotesIndicator
                        notePreviews={priorityUnresolvedNotes}
                        numberOfNotes={unresolvedNoteCount || 0}
                        customerId={id}
                        customerFullName={fullName}
                        triggerTableUpdate={triggerTableUpdate}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(15% - 96px)">
                {numberOfMessages > 0 && (
                    <Table.Body.Cell.ContentLink alignRight Link={Link} to={`${customerUrl}/messages`}>
                        <ReturningCustomersTableMessagesCounter
                            customerId={id}
                            numberOfSentMessages={numberOfMessages}
                        />
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>

            <Table.Body.Cell width="96px">
                <Table.Body.Cell.Content alignRight>
                    {unsubscribeFromBulkEmails && (
                        <IconUnsubscribed title="Unsubscribed from bulk emails">
                            <IconCommentSlash color={colors.neutralDarker} />
                        </IconUnsubscribed>
                    )}

                    <Checkbox
                        name={`${id}${currentSelfAssessmentId || ''}`}
                        label="Select"
                        isLabelless
                        value={String(id)}
                        onChange={() =>
                            handleSelect({
                                id,
                                currentSelfAssessmentOneClickTaxReturn,
                                name: fullName,
                                status,
                                unsubscribeFromBulkEmails,
                            })
                        }
                        checked={isSelected}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

ReturningCustomersTableRow.propTypes = {
    triggerTableUpdate: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    customer: PropTypes.object.isRequired,
}

const mapStateToProps = ({ user }) => ({
    isAdminUser: isAdmin(user),
})

export default connect(mapStateToProps)(ReturningCustomersTableRow)
