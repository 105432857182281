import { createSelector } from '@reduxjs/toolkit'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

const addUTCSuffix = (date) => {
    if (!date) return null
    return date[date.length - 1] === 'Z' ? date : `${date}Z`
}

const getConversations = (state) => _get(state, ['conversations', 'data'], [])
const getCustomerId = (_state, props) => props.customerId

export const getIsFetchingConversations = (state) => _get(state, ['conversations', 'isFetching'])

export const getCustomerConversations = createSelector(
    [getConversations, getCustomerId],
    (conversations, customerId) => {
        const customerConversations = _filter(conversations, { customerId })
        return _orderBy(customerConversations, 'lastMessageDateUTC', 'desc')
    }
)

export const getCustomerConversationsById = (state, { conversationId }) => {
    const customerConversations = getConversations(state)
    return _find(customerConversations, ['id', conversationId])
}

export const getSidebarConversationsCount = createSelector(
    [getCustomerConversations],
    (conversations) => conversations.length
)

export const getHasUnreadCustomerConversations = (state, { customerId, accountantLastViewedConversationsUTC }) => {
    const customerConversations = getCustomerConversations(state, { customerId })

    const conversationsLastMessageDateUTC = _get(
        _orderBy(customerConversations, ['lastMessageDateUTC'], ['desc']),
        [0, 'lastMessageDateUTC'],
        null
    )

    if (!conversationsLastMessageDateUTC) return false

    const dateUpdated = new Date(addUTCSuffix(conversationsLastMessageDateUTC))
    const dateViewed = new Date(addUTCSuffix(accountantLastViewedConversationsUTC))

    return dateUpdated.getTime() > dateViewed.getTime()
}
