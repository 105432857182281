import { useMemo } from 'react'

import { formatDateQuery, getDaysInCurrentMonth, getMonth, getYear, padWithZeroes } from '@scouts/helpers'

import {
    CURRENT_TAX_YEAR,
    TAX_YEAR_END_DAY,
    TAX_YEAR_END_MONTH,
    TAX_YEAR_START_DAY,
    TAX_YEAR_START_MONTH,
} from '@/constants'
import { formatTaxYear } from '@/helpers'

const FILTER_CURRENT_MONTH = 'CurrentMonth'
const FILTER_CURRENT_TAX_YEAR = 'CurrentTaxYear'
const FILTER_CURRENT_YEAR = 'CurrentYear'
const FILTER_PREVIOUS_TAX_YEAR = 'PreviousTaxYear'

const currentYear = getYear()
const currentMonth = getMonth()
const formattedMonth = padWithZeroes(currentMonth)
const currentMonthDays = getDaysInCurrentMonth()

export const useFilters = () => {
    const filters = useMemo(
        () => [
            {
                startDate: formatDateQuery(`${currentYear}-${formattedMonth}-01`),
                endDate: formatDateQuery(`${currentYear}-${formattedMonth}-${currentMonthDays}`),
                label: 'This month',
                key: FILTER_CURRENT_MONTH,
            },
            {
                startDate: formatDateQuery(`${currentYear}-01-01`),
                endDate: formatDateQuery(`${currentYear}-12-31`),
                label: 'This year',
                key: FILTER_CURRENT_YEAR,
            },
            {
                startDate: formatDateQuery(`${CURRENT_TAX_YEAR}-${TAX_YEAR_START_MONTH}-${TAX_YEAR_START_DAY}`),
                endDate: formatDateQuery(`${CURRENT_TAX_YEAR + 1}-${TAX_YEAR_END_MONTH}-${TAX_YEAR_END_DAY}`),
                label: `${formatTaxYear(CURRENT_TAX_YEAR + 1)} tax year`,
                key: FILTER_CURRENT_TAX_YEAR,
            },
            {
                startDate: formatDateQuery(`${CURRENT_TAX_YEAR - 1}-${TAX_YEAR_START_MONTH}-${TAX_YEAR_START_DAY}`),
                endDate: formatDateQuery(`${CURRENT_TAX_YEAR}-${TAX_YEAR_END_MONTH}-${TAX_YEAR_END_DAY}`),
                label: `${formatTaxYear(CURRENT_TAX_YEAR)} tax year`,
                key: FILTER_PREVIOUS_TAX_YEAR,
            },
        ],
        []
    )

    return { filters }
}
