import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Loadable } from '@scouts/ui'

import { createPayeAccessToken, receiveErrorAlerts } from '../../../actions'
import { PATH_INTEGRATIONS_PAYE } from '../../../constants'

const Container = styled.div`
    max-width: 720px;
    margin: 0 auto;
    padding: 48px 36px 192px;
`

class IntegrationsPayeCallback extends Component {
    componentDidMount() {
        const { dispatch, location, history } = this.props

        const parsed = queryString.parse(location.search)

        if (!parsed || !parsed.code) {
            // console.error('HMRC authentication code not found')
            history.replace(PATH_INTEGRATIONS_PAYE)
            return
        }

        dispatch(
            createPayeAccessToken({
                code: parsed.code,
                onSuccess: () => {
                    history.replace(PATH_INTEGRATIONS_PAYE)
                },
                onError: (error) => {
                    // console.error('HMRC authentication failed')
                    if (error.response && error.response.status !== 404) {
                        dispatch(receiveErrorAlerts('Authentication with HMRC failed'))
                    }
                },
            })
        )
    }

    render() {
        return (
            <Container>
                <Loadable isLoading />
            </Container>
        )
    }
}

IntegrationsPayeCallback.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

export default connect()(IntegrationsPayeCallback)
