import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { font, AccountantPortalChrome, ChipButton, DropdownSelect, DropdownSelectProps } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { formatDateWithTime } from '@/helpers'
import { AccountantMeetingSummaryDTO, ContactType } from '@/models'
import { useCustomersAccountantMeetings } from '@/store/accountant-meetings/hooks/use-customers-accountant-meetings'

import { AccountantMeetingStatusSelect } from '@/components/accountant-meetings/AccountantMeetingStatusSelect'

export const AccountantMeeting = ({
    accountantMeeting,
    customerId,
}: {
    accountantMeeting: AccountantMeetingSummaryDTO
    customerId: number
}) => {
    const history = useHistory()
    const {
        id: accountantMeetingId,
        scheduledDateUTC,
        contactPreferenceType,
        meetingLink,
        statusChangedDateUTC,
    } = accountantMeeting

    const { accountantMeetings } = useCustomersAccountantMeetings({ customerId })

    const handleChangeAccountantMeeting: DropdownSelectProps['onChange'] = ({ value: updatedAccountantMeetingId }) => {
        const url = `${PATH_CUSTOMERS}/${customerId}/meetings/${updatedAccountantMeetingId}`

        history.push(url)
    }

    const contactTypeLabel = useMemo(() => {
        if (contactPreferenceType === ContactType.Video) return 'Video meeting'
        if (contactPreferenceType === ContactType.Phone) return 'Phone call'
        return 'Unspecified'
    }, [contactPreferenceType])

    if (!accountantMeeting) return null

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Header isSticky={false}>
                <AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.Title>Meeting</AccountantPortalChrome.Customer.Page.Title>

                    <DropdownSelect
                        name="accountantMeetingId"
                        value={accountantMeetingId}
                        options={(accountantMeetings || []).map(
                            ({ id: optionAccountantMeetingId, scheduledDateUTC: optionScheduledDateUTC }) => ({
                                value: optionAccountantMeetingId,
                                title: optionScheduledDateUTC
                                    ? formatDateWithTime(optionScheduledDateUTC)
                                    : String(optionAccountantMeetingId),
                            })
                        )}
                        onChange={handleChangeAccountantMeeting}
                    />

                    <LastUpdated>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Status changed {formatDateWithTime(statusChangedDateUTC)}
                        </span>
                    </LastUpdated>
                </AccountantPortalChrome.Customer.Page.HeaderGroup>
                <AccountantPortalChrome.Customer.Page.HeaderGroup />
            </AccountantPortalChrome.Customer.Page.Header>

            <Main>
                <SummaryContainer>
                    <Summary>
                        <SummaryRow>
                            <SummaryRowLabel>Status</SummaryRowLabel>
                            <SummaryRowField>
                                <AccountantMeetingStatusSelect accountantMeeting={accountantMeeting} />
                            </SummaryRowField>
                        </SummaryRow>
                        <SummaryRow>
                            <SummaryRowLabel>Time</SummaryRowLabel>
                            <SummaryRowField>{formatDateWithTime(scheduledDateUTC)}</SummaryRowField>
                        </SummaryRow>

                        <SummaryRow>
                            <SummaryRowLabel>Contact preference</SummaryRowLabel>
                            <SummaryRowField>
                                {contactTypeLabel}

                                {!!meetingLink && contactPreferenceType === ContactType.Video && (
                                    <>
                                        {' '}
                                        <MeetingLinkWrapper>
                                            <ChipButton
                                                size="small"
                                                variant="highlightSoft"
                                                onClick={() => {
                                                    window.open(meetingLink, '_blank', 'noopener noreferrer')
                                                }}
                                            >
                                                Join meeting
                                            </ChipButton>
                                        </MeetingLinkWrapper>
                                    </>
                                )}
                            </SummaryRowField>
                        </SummaryRow>
                    </Summary>
                </SummaryContainer>
            </Main>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}

const Main = styled.div`
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
`

const SummaryContainer = styled.div`
    flex: 0 0 calc(50% - 24px);
`

const Summary = styled.div``

const SummaryRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 37px;

    &:not(:last-child) {
        margin-bottom: 9px;
    }
`

const SummaryRowLabel = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    line-height: 1.3;
    flex: 0 1 45%;
    padding-right: 18px;
`

const SummaryRowField = styled.div`
    font-size: ${font.small};
    flex: 0 0 55%;
    min-width: 192px;
`

const MeetingLinkWrapper = styled.div`
    display: inline-block;
    margin-top: 6px;
`

const LastUpdated = styled.div`
    font-size: ${font.smaller};
    line-height: 1.3;
    flex: 1 1 auto;
    max-width: 240px;
    overflow: hidden;
`
