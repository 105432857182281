import axios from 'axios'

import { getRequestConfig } from './api'

export const apiRequest = (url, method, data, headers, cancelToken) =>
    getRequestConfig({
        url,
        method,
        data,
        headers,
        cancelToken,
    }).then(axios)
