import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useRefundTaxReliefClaimMutation, RefundTaxReliefClaimParams } from '../api'

export const useRefundTaxReliefClaim = () => {
    const [refundTaxReliefClaimAction, { error, isLoading }] = useRefundTaxReliefClaimMutation()

    const refundTaxReliefClaim = useCallback(
        (params: RefundTaxReliefClaimParams) =>
            refundTaxReliefClaimAction(params).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Refund was successfully submitted' })
                })
            ),
        [refundTaxReliefClaimAction]
    )

    return {
        refundTaxReliefClaim,
        error,
        isLoading,
    }
}
