import { ReactNode } from 'react'

import { IMessageComposerContext, MessageComposerContext } from './message-composer-context'

interface MessageComposerProviderProps {
    children: ReactNode
    value: IMessageComposerContext
}

export const MessageComposerProvider = ({ children, value }: MessageComposerProviderProps) => {
    return <MessageComposerContext.Provider value={value}>{children}</MessageComposerContext.Provider>
}
