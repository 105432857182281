import styled from 'styled-components'

import { colors, File } from '@scouts/ui'

import { MessageDTO } from '@/models'
import { useDownloadMessageAttachment } from '@/store/messages'

const MessageAttachments = ({ attachments }: { attachments: MessageDTO['attachments'] }) => {
    const { downloadMessageAttachment } = useDownloadMessageAttachment()

    return (
        <>
            {attachments.map((file) => (
                <FileWrapper key={file.id}>
                    <File background={colors.white} onClick={() => downloadMessageAttachment({ fileId: file.id })}>
                        {file.fileName}
                    </File>
                </FileWrapper>
            ))}
        </>
    )
}

const FileWrapper = styled.div`
    & + & {
        margin-top: 6px;
    }
`

export default MessageAttachments
