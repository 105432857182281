import { useSelector } from 'react-redux'

import { getUnresolvedNoteCount } from '@/selectors'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

export const useNotesCount = () => {
    const customerId = useCustomerIdFromPath()

    const unresolvedNotesCount = useSelector((state) => getUnresolvedNoteCount(state, { customerId }))

    return { unresolvedNotesCount, hasUnresolvedNotes: unresolvedNotesCount > 0 }
}
