import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { ProductBundleDomain, TaxConsultationDomain } from '@/domain'
import { getCustomerProductBundles } from '@/selectors'
import {
    getCustomerActiveSelfAssessments,
    getCustomerCompletedSelfAssessments,
} from '@/selectors/customer-services-sidebar'
import { useCustomersTaxConsultations } from '@/store/tax-consultations'
import { useTaxRegistration } from '@/store/tax-registrations'

import CustomerServices from '../customer-services/CustomerServices'

const CustomerProfileServices = ({ customerId, hasTaxRegistration }) => {
    const productBundles = useSelector((state) => getCustomerProductBundles(state, { customerId }))

    const { taxRegistration, isActive, isCompleted } = useTaxRegistration({ customerId, skip: !hasTaxRegistration })

    const activeSelfAssessments = useSelector((state) => getCustomerActiveSelfAssessments(state, { customerId }))
    const completedSelfAssessments = useSelector((state) => getCustomerCompletedSelfAssessments(state, { customerId }))

    const { taxConsultations } = useCustomersTaxConsultations({ customerId })
    const activeTaxConsultations = TaxConsultationDomain.getActiveTaxConsultations(taxConsultations)
    const completedTaxConsultations = TaxConsultationDomain.getCompletedTaxConsultations(taxConsultations)

    const activeProductBundles = ProductBundleDomain.getActiveProductBundles({
        productBundles,
        activeTaxConsultations,
        activeSelfAssessments,
    })

    return (
        <CustomerServices
            customerId={customerId}
            activeProductBundles={activeProductBundles}
            activeSelfAssessments={activeSelfAssessments}
            activeTaxConsultations={activeTaxConsultations}
            activeTaxRegistrations={isActive ? [taxRegistration] : []}
            completedSelfAssessments={completedSelfAssessments}
            completedTaxConsultations={completedTaxConsultations}
            completedTaxRegistrations={isCompleted ? [taxRegistration] : []}
        />
    )
}

CustomerProfileServices.propTypes = {
    customerId: PropTypes.number.isRequired,
    hasTaxRegistration: PropTypes.bool.isRequired,
}

export default CustomerProfileServices
