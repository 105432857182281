import { Redirect, Route, Switch } from 'react-router-dom'

import {
    PATH_ACCOUNTANT,
    PATH_ACCOUNTANT_MEETINGS,
    PATH_ACCOUNTANTS,
    PATH_COMPANY_RETURNS,
    PATH_CUSTOMER,
    PATH_CUSTOMERS,
    PATH_DASHBOARD,
    PATH_DISCOUNT_SCHEMES,
    PATH_ESCALATIONS,
    PATH_EXTERNAL_EMAIL_AUTHENTICATED,
    PATH_INBOX,
    PATH_INTEGRATIONS,
    PATH_INTEGRATIONS_NYLAS,
    PATH_INTEGRATIONS_NYLAS_CALLBACK,
    PATH_INTEGRATIONS_NYLAS_CREATE_SCHEDULE,
    PATH_INTEGRATIONS_NYLAS_EDIT_SCHEDULE,
    PATH_INTEGRATIONS_PAYE,
    PATH_INTEGRATIONS_PAYE_CALLBACK,
    PATH_OFF_PLATFORM_SERVICES_REQUESTS,
    PATH_PROFILE,
    PATH_RETURNING_CUSTOMERS,
    PATH_REWARDS,
    PATH_SEARCH,
    PATH_SECURITY,
    PATH_SELF_ASSESSMENTS,
    PATH_SUBSCRIPTIONS,
    PATH_SUPPORT_DASHBOARD,
    PATH_TAX_CONSULTATIONS,
    PATH_TAX_REGISTRATION,
    PATH_TAX_RELIEF_CLAIMS,
    PATH_TEMPLATES,
    PATH_TOOLS,
    PATH_TOOLS_CGT,
    PATH_USER,
    PATH_USER_REVIEWS,
    PATH_USERS,
    PATH_USERS_CREATE,
} from '@/constants'

import { useUser } from '@/hooks/use-user'

import { AccountantMeetings } from './accountant-meetings/AccountantMeetings'
import { AccountantDetails } from './accountants/AccountantDetails'
import { AccountantStatistics } from './accountants/AccountantStatistics'
import { CompanyReturns } from './company-returns/CompanyReturns'
import { CustomerContainer } from './customer/CustomerContainer'
import { CustomersStatistics } from './customers/CustomersStatistics'
import { Dashboard } from './dashboard/Dashboard'
import { DiscountSchemes } from './discounts/DiscountSchemes'
import Escalations from './escalations/Escalations'
import ExternalEmailAuthenticated from './ExternalEmailAuthenticated'
import { AccountantInbox } from './inbox/AccountantInbox'
import { OffPlatformServicesRequests } from './off-platform-services-requests/OffPlatformServicesRequests'
import ReturningCustomers from './returning-customers/ReturningCustomers'
import RewardsContainer from './rewards/RewardsContainer'
import SearchContainer from './search/SearchContainer'
import { Security } from './security/Security'
import SelfAssessments from './self-assessments/SelfAssessments'
import { Subscriptions } from './subscriptions/Subscriptions'
import { SupportDashboard } from './support-dashboard/SupportDashboard'
import { TaxConsultations } from './tax-consultations/TaxConsultations'
import { TaxRegistrations } from './tax-registrations/TaxRegistrations'
import { TaxReliefClaims } from './tax-relief-claims/TaxReliefClaims'
import { ToolsCGT } from './tools/ToolsCGT'
import { ToolsContainer } from './tools/ToolsContainer'
import IntegrationsNylas from './users/integrations/IntegrationsNylas'
import IntegrationsNylasCallback from './users/integrations/IntegrationsNylasCallback'
import IntegrationsNylasCreateSchedule from './users/integrations/IntegrationsNylasCreateSchedule'
import IntegrationsNylasEditSchedule from './users/integrations/IntegrationsNylasEditSchedule'
import IntegrationsPaye from './users/integrations/IntegrationsPaye'
import IntegrationsPayeCallback from './users/integrations/IntegrationsPayeCallback'
import { MessageTemplates } from './users/message-templates/MessageTemplates'
import MyIntegrationsContainer from './users/MyIntegrationsContainer'
import MyProfile from './users/MyProfile'
import { UserReviews } from './users/reviews/UserReviews'
import UserCreate from './users/UserCreate'
import UserProfile from './users/UserProfile'
import { UsersContainer } from './users/UsersContainer'

// @TODO:
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteType = [string, React.ComponentType<any>, boolean?]

const ROUTES: RouteType[] = [
    [PATH_CUSTOMER, CustomerContainer, false],
    [PATH_CUSTOMERS, CustomersStatistics],
    [PATH_EXTERNAL_EMAIL_AUTHENTICATED, ExternalEmailAuthenticated],
    [PATH_SECURITY, Security, false],
    [PATH_PROFILE, MyProfile],
    [PATH_RETURNING_CUSTOMERS, ReturningCustomers],
    [PATH_SEARCH, SearchContainer],
    [PATH_SELF_ASSESSMENTS, SelfAssessments],
    [PATH_ACCOUNTANT_MEETINGS, AccountantMeetings],
    [PATH_SUBSCRIPTIONS, Subscriptions],
    [PATH_TAX_CONSULTATIONS, TaxConsultations],
    [PATH_TAX_REGISTRATION, TaxRegistrations],
    [PATH_TEMPLATES, MessageTemplates, false],
    [PATH_TOOLS_CGT, ToolsCGT],
    [PATH_TOOLS, ToolsContainer],
    [PATH_COMPANY_RETURNS, CompanyReturns],
]

const ADMIN_ONLY_ROUTES: RouteType[] = [
    [PATH_ACCOUNTANT, AccountantDetails],
    [PATH_ACCOUNTANTS, AccountantStatistics],
    [PATH_DISCOUNT_SCHEMES, DiscountSchemes],
    [PATH_ESCALATIONS, Escalations],
    [PATH_REWARDS, RewardsContainer],
    [PATH_USER_REVIEWS, UserReviews],
    [PATH_USER, UserProfile],
    [PATH_USERS_CREATE, UserCreate],
    [PATH_USERS, UsersContainer],
    [PATH_TAX_RELIEF_CLAIMS, TaxReliefClaims],
    [PATH_OFF_PLATFORM_SERVICES_REQUESTS, OffPlatformServicesRequests],
    [PATH_SUPPORT_DASHBOARD, SupportDashboard],
]

const USER_ONLY_ROUTES: RouteType[] = [
    [PATH_DASHBOARD, Dashboard],
    [PATH_INTEGRATIONS_NYLAS_CALLBACK, IntegrationsNylasCallback],
    [PATH_INTEGRATIONS_NYLAS_CREATE_SCHEDULE, IntegrationsNylasCreateSchedule],
    [PATH_INTEGRATIONS_NYLAS_EDIT_SCHEDULE, IntegrationsNylasEditSchedule],
    [PATH_INTEGRATIONS_NYLAS, IntegrationsNylas],
    [PATH_INTEGRATIONS_PAYE_CALLBACK, IntegrationsPayeCallback],
    [PATH_INTEGRATIONS_PAYE, IntegrationsPaye],
    [PATH_INTEGRATIONS, MyIntegrationsContainer],
    [PATH_INBOX, AccountantInbox],
]

const renderRoutes = (routes: RouteType[]) =>
    routes.map(([path, component, isExact = true]: RouteType) => (
        <Route key={path} path={path} component={component} exact={isExact} />
    ))

const Routes = () => {
    const { isAdminUser } = useUser()

    const defaultRoute = isAdminUser ? PATH_SELF_ASSESSMENTS : PATH_DASHBOARD

    return (
        <Switch>
            {renderRoutes(ROUTES)}

            {!isAdminUser && renderRoutes(USER_ONLY_ROUTES)}

            {isAdminUser && renderRoutes(ADMIN_ONLY_ROUTES)}

            <Redirect to={defaultRoute} />
        </Switch>
    )
}

export default Routes
