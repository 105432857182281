import { useCreateTaxConsultationMutation } from '../api'

export const useCreateTaxConsultation = () => {
    const [createTaxConsultation, { isLoading }] = useCreateTaxConsultationMutation()

    return {
        createTaxConsultation,
        isLoading,
    }
}
