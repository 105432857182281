import { Locale } from '@scouts/helpers'
import { Checkbox, Flexer, Spacer, TypeSelect, TypeSelectProps } from '@scouts/ui'

import { useTypeSelectAccountantOptions } from '@/hooks/use-type-select-accountant-options'

import { useFilters } from './hooks/use-filters'

export const CompanyReturnsSupportFilters = () => {
    const accountants = useTypeSelectAccountantOptions()
    const hasAccountants = accountants.length > 0
    const { activeFilters, applyFilter } = useFilters()

    const { accountantId, includeUnpaid } = activeFilters

    const handleAccountantChange: TypeSelectProps['onChange'] = ({ value }) => {
        applyFilter({ updatedFilter: { accountantId: Number(value), page: 1 } })
    }

    const handleIncludeUnpaidChange = ({ checked }: { checked: boolean }) => {
        applyFilter({ updatedFilter: { includeUnpaid: checked, page: 1 } })
    }

    return (
        <Flexer column gap="18px" tabletGap="12px" tabletAlignCenter tabletRow>
            {hasAccountants && (
                <Spacer tabletWidth="320px" width="100%">
                    <TypeSelect
                        locale={Locale.GB}
                        name="filterAccountant"
                        value={Number(accountantId) || ''}
                        options={accountants}
                        onChange={handleAccountantChange}
                        autoClear
                        isRequired
                        showOptions
                    />
                </Spacer>
            )}

            <Checkbox
                isSmall
                name="includeUnpaid"
                label="Include unpaid"
                value={includeUnpaid || ''}
                checked={includeUnpaid}
                onChange={handleIncludeUnpaidChange}
            />
        </Flexer>
    )
}
