import { Container, Grid } from '@scouts/ui'

import { AccountantDetailsDTO } from '@/models'

import { DashboardEarnings } from './DashboardEarnings'
import { DashboardReturningCustomers } from './DashboardReturningCustomers'

export const DashboardStatsRow = ({
    accountantId,
    isOnTrial,
}: {
    accountantId: AccountantDetailsDTO['id']
    isOnTrial: AccountantDetailsDTO['isOnTrial']
}) => {
    return (
        <Container>
            <Grid tabletColumns={2} columns={1} gap="12px">
                {!isOnTrial && <DashboardReturningCustomers accountantId={accountantId} />}

                <DashboardEarnings accountantId={accountantId} />
            </Grid>
        </Container>
    )
}
