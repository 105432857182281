import { Container } from '@scouts/ui'

import { formatCurrency } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const DividendsAndInterest = ({
    dividendsAndInterest,
}: {
    dividendsAndInterest: SelfAssessmentDTO['dividendsAndInterest']
}) => {
    if (!dividendsAndInterest) return null

    const {
        ukDividendAmount,
        foreignDividendAmount,
        foreignDividendTaxAmount,
        taxedUkInterestAmount,
        untaxedUkInterestAmount,
        foreignInterestAmount,
        additionalNotes,
    } = dividendsAndInterest

    return (
        <Container>
            <SectionData>
                <SectionDataRow label="UK dividends" value={formatCurrency(ukDividendAmount)} />

                <SectionDataRow label="Foreign dividends" value={formatCurrency(foreignDividendAmount)} />

                <SectionDataRow label="Foreign dividend tax" value={formatCurrency(foreignDividendTaxAmount)} />
            </SectionData>
            <SectionData>
                <SectionDataRow label="Taxed UK interest" value={formatCurrency(taxedUkInterestAmount)} />

                <SectionDataRow label="Untaxed UK interest" value={formatCurrency(untaxedUkInterestAmount)} />

                <SectionDataRow label="Untaxed foreign interest" value={formatCurrency(foreignInterestAmount)} />
            </SectionData>

            {additionalNotes && (
                <SectionData>
                    <SectionDataRow label="Notes" value={additionalNotes} />
                </SectionData>
            )}
        </Container>
    )
}
