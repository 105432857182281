import { colors, Indicator, Tooltip } from '@scouts/ui'

import { TaxRegistrationStatus, TaxRegistrationStatusTypes } from '@/models'

export const CustomerIndicatorsTaxRegistration = ({
    taxRegistrationStatus,
}: {
    taxRegistrationStatus?: TaxRegistrationStatusTypes
}) => {
    if (!taxRegistrationStatus) {
        return (
            <Indicator color={colors.neutralDarker} background={colors.white} border={colors.neutralLightest}>
                <Tooltip content="UTR registration not started">REG</Tooltip>
            </Indicator>
        )
    }

    const background = () => {
        switch (taxRegistrationStatus) {
            case TaxRegistrationStatus.Started:
                return colors.neutralLightest
            case TaxRegistrationStatus.InformationRequested:
                return colors.yellow
            case TaxRegistrationStatus.Complete:
                return colors.mintLighter
            case TaxRegistrationStatus.Submitted:
                return colors.blueLighter
            case TaxRegistrationStatus.Cancelled:
                return colors.red
            default:
                return ''
        }
    }

    const title = () => {
        switch (taxRegistrationStatus) {
            case TaxRegistrationStatus.Started:
                return 'UTR registration is in process by client'
            case TaxRegistrationStatus.Submitted:
                return 'UTR registration is ready to be started'
            case TaxRegistrationStatus.InformationRequested:
                return 'UTR registration is in information requested'
            case TaxRegistrationStatus.Complete:
                return 'UTR registration is completed'
            case TaxRegistrationStatus.Cancelled:
                return 'UTR registration is cancelled'
            default:
                return ''
        }
    }

    return (
        <Indicator background={background()}>
            <Tooltip content={title()}>REG</Tooltip>
        </Indicator>
    )
}
