import { useToggle } from '@scouts/helpers'
import { ButtonLink, Container } from '@scouts/ui'

import { CustomerDTO, IdentityVerificationDTO } from '@/models'

import { VerificationFileUploadModal } from './VerificationFileUploadModal'

interface VerificationUploadModalLinkProps {
    customerId: CustomerDTO['id']
    identityVerification: IdentityVerificationDTO
}

export const VerificationUploadModalLink = ({ customerId, identityVerification }: VerificationUploadModalLinkProps) => {
    const [isModalOpen, open, close] = useToggle()

    return (
        <Container>
            <ButtonLink onClick={open}>Upload manually</ButtonLink>

            {isModalOpen && (
                <VerificationFileUploadModal
                    closeModal={close}
                    customerId={customerId}
                    identityVerification={identityVerification}
                />
            )}
        </Container>
    )
}
