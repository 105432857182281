import { useGetCompanyReturnQuery, GetCompanyReturnParams } from '../api'

export const useCompanyReturn = ({
    customerId,
    companyId,
    companyReturnId,
    skip,
}: GetCompanyReturnParams & { skip?: boolean }) => {
    const { data, isError, isLoading, isFetching } = useGetCompanyReturnQuery(
        { customerId, companyId, companyReturnId },
        { skip }
    )

    return {
        companyReturn: data,
        isError,
        isLoading,
        isFetching,
    }
}
