import { Container } from '@scouts/ui'

import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const CapitalGainsAllowances = ({ capitalGains }: { capitalGains: SelfAssessmentDTO['capitalGains'] }) => {
    if (!capitalGains) return null

    const {
        propertyLosses,
        shareLosses,
        otherLosses,
        cryptoLosses,
        currentYearLossesAmount,
        previousYearsLossesAmount,
        hasEmploymentOptionSchemeAllowance,
        hasHmrcInvestmentSchemeAllowance,
    } = capitalGains

    const anyLoss = !!(propertyLosses || shareLosses || otherLosses || cryptoLosses)

    return (
        <Container>
            <SectionData>
                <SectionDataRow label="Incurred a loss" value={anyLoss} boolean highlight />
                {anyLoss && (
                    <>
                        <SectionDataRow label="Property losses" value={propertyLosses} boolean />
                        <SectionDataRow label="Share losses" value={shareLosses} boolean />
                        <SectionDataRow label="Cryptocurrency losses" value={cryptoLosses} boolean />
                        <SectionDataRow label="Other losses" value={otherLosses} boolean />
                        <SectionDataRow
                            label="Current year losses"
                            value={formatCurrencyNotSure(currentYearLossesAmount)}
                        />
                        <SectionDataRow
                            label="Previous year losses"
                            value={formatCurrencyNotSure(previousYearsLossesAmount)}
                        />
                    </>
                )}
            </SectionData>

            <SectionData>
                <SectionDataRow
                    label="Sold assets under employee share option scheme (EMI, CSOP)"
                    value={hasEmploymentOptionSchemeAllowance}
                    boolean
                    highlight
                />
            </SectionData>

            <SectionData>
                <SectionDataRow
                    label="Sold assets under HMRC investment schemes (EIS, SEIS, SITR, VCT)"
                    value={hasHmrcInvestmentSchemeAllowance}
                    boolean
                    highlight
                />
            </SectionData>
        </Container>
    )
}
