export const TAX_CONSULTATION_STATUS_STARTED = 'Started'
export const TAX_CONSULTATION_STATUS_BOOKED = 'Booked'
export const TAX_CONSULTATION_STATUS_AWAITING_SUMMARY = 'AwaitingSummary'
export const TAX_CONSULTATION_STATUS_AWAITING_APPROVAL = 'AwaitingApproval'
export const TAX_CONSULTATION_STATUS_COMPLETED = 'Completed'
export const TAX_CONSULTATION_STATUS_CANCELLED = 'Cancelled'

export const TAX_CONSULTATION_STATUSES = [
    TAX_CONSULTATION_STATUS_STARTED,
    TAX_CONSULTATION_STATUS_BOOKED,
    TAX_CONSULTATION_STATUS_AWAITING_SUMMARY,
    TAX_CONSULTATION_STATUS_AWAITING_APPROVAL,
    TAX_CONSULTATION_STATUS_COMPLETED,
    TAX_CONSULTATION_STATUS_CANCELLED,
]

export const TAX_CONSULTATION_STATUS_TITLES = {
    [TAX_CONSULTATION_STATUS_STARTED]: 'Started',
    [TAX_CONSULTATION_STATUS_BOOKED]: 'Booked',
    [TAX_CONSULTATION_STATUS_AWAITING_SUMMARY]: 'Awaiting summary',
    [TAX_CONSULTATION_STATUS_AWAITING_APPROVAL]: 'Awaiting approval',
    [TAX_CONSULTATION_STATUS_COMPLETED]: 'Completed',
    [TAX_CONSULTATION_STATUS_CANCELLED]: 'Cancelled',
}

export const TAX_CONSULTATION_STATUS_FILTER_ALL = 'all'
export const TAX_CONSULTATION_STATUS_FILTER_STARTED = 'started'
export const TAX_CONSULTATION_STATUS_FILTER_BOOKED = 'booked'
export const TAX_CONSULTATION_STATUS_FILTER_AWAITING_APPROVAL = 'awaitingApproval'
export const TAX_CONSULTATION_STATUS_FILTER_AWAITING_SUMMARY = 'awaitingSummary'
export const TAX_CONSULTATION_STATUS_FILTER_COMPLETED = 'completed'
export const TAX_CONSULTATION_STATUS_FILTER_CANCELLED = 'cancelled'

const TAX_CONSULTATION_TOPIC_SELF_ASSESSMENT = 'SelfAssessment'
const TAX_CONSULTATION_TOPIC_FOREIGN_INCOME = 'ForeignIncomeOrTaxes'
const TAX_CONSULTATION_TOPIC_PROPERTY = 'PropertyCapitalGains'
const TAX_CONSULTATION_TOPIC_SHARES = 'SharesOrCryptoCapitalGains'
const TAX_CONSULTATION_TOPIC_TAX_PLANNING = 'TaxPlanning'
const TAX_CONSULTATION_TOPIC_LIMITED_COMPANY_TAXES = 'LimitedCompanyTaxes'
const TAX_CONSULTATION_TOPIC_COMPANY_SETUP = 'CompanySetup'
const TAX_CONSULTATION_TOPIC_INHERITANCE_TAX = 'InheritanceTax'
const TAX_CONSULTATION_TOPIC_OTHER = 'Other'

export const TAX_CONSULTATION_TOPICS = [
    TAX_CONSULTATION_TOPIC_SELF_ASSESSMENT,
    TAX_CONSULTATION_TOPIC_FOREIGN_INCOME,
    TAX_CONSULTATION_TOPIC_PROPERTY,
    TAX_CONSULTATION_TOPIC_SHARES,
    TAX_CONSULTATION_TOPIC_TAX_PLANNING,
    TAX_CONSULTATION_TOPIC_LIMITED_COMPANY_TAXES,
    TAX_CONSULTATION_TOPIC_COMPANY_SETUP,
    TAX_CONSULTATION_TOPIC_INHERITANCE_TAX,
    TAX_CONSULTATION_TOPIC_OTHER,
]

export const TAX_CONSULTATION_TOPICS_TITLES = {
    [TAX_CONSULTATION_TOPIC_SELF_ASSESSMENT]: 'Self Assessment',
    [TAX_CONSULTATION_TOPIC_FOREIGN_INCOME]: 'Foreign income or taxes',
    [TAX_CONSULTATION_TOPIC_PROPERTY]: 'Capital gains - property',
    [TAX_CONSULTATION_TOPIC_SHARES]: 'Capital gains - shares, options, crypto',
    [TAX_CONSULTATION_TOPIC_TAX_PLANNING]: 'General tax planning',
    [TAX_CONSULTATION_TOPIC_LIMITED_COMPANY_TAXES]: 'Limited company taxes',
    [TAX_CONSULTATION_TOPIC_COMPANY_SETUP]: 'Setting up a company',
    [TAX_CONSULTATION_TOPIC_INHERITANCE_TAX]: 'Inheritance tax',
    [TAX_CONSULTATION_TOPIC_OTHER]: 'Other',
}
