import _orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, font, Badge } from '@scouts/ui'

import { formatTimeTodayAndDateIfLater } from '../helpers'

const BadgeWrapper = styled.div`
    float: right;
    ${({ hasItems }) => hasItems && `position: relative; top: 14px;`}
`

const BadgeBorder = styled.div`
    display: inline-block;
    border-radius: 9999px;
    border: 2px solid ${colors.white};
`

const NotesList = styled.div``

const NotesListItem = styled.div`
    font-size: ${font.small};
    background: ${({ isEscalation }) => (isEscalation ? colors.yellow : colors.redLighter)};
    padding: 8px 18px 8px 10px;
    margin-right: 12px;
    margin-top: 6px;
    &:first-child {
        margin-top: 0;
    }
`

const Body = styled.div`
    line-height: 1.5;
    font-weight: ${font.weight.medium};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Footer = styled.div`
    font-size: ${font.smaller};
    line-height: 1.5;
`

const NotePreviews = ({ items, numberOfNotes, onOpenNoteListPopup }) => {
    const orderedItems = _orderBy(items, 'createdDateUTC', 'desc')

    const hasItems = orderedItems && orderedItems.length > 0

    return (
        <>
            <BadgeWrapper hasItems={hasItems} onMouseEnter={onOpenNoteListPopup}>
                <BadgeBorder>
                    <Badge background={colors.orange} color={colors.black}>
                        {numberOfNotes}
                    </Badge>
                </BadgeBorder>
            </BadgeWrapper>
            <NotesList>
                {hasItems &&
                    orderedItems.map((note) => (
                        <NotesListItem key={note.id} isEscalation={!!note.escalationId}>
                            <Body>{note.noteText}</Body>
                            <Footer>
                                {formatTimeTodayAndDateIfLater(note.createdDateUTC, ' ')} · by{' '}
                                {note.createdByAccountantFullName}
                            </Footer>
                        </NotesListItem>
                    ))}
            </NotesList>
        </>
    )
}

NotePreviews.propTypes = {
    numberOfNotes: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    onOpenNoteListPopup: PropTypes.func.isRequired,
}

export default NotePreviews
