import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { MessageDTO } from '@/models'
import { getCustomerById } from '@/selectors'

import { useMessagesContext } from './context/messages-context'
import { MessagesListItem } from './MessagesListItem'

export const MessagesList = ({ messages }: { messages: MessageDTO[] }) => {
    const { customerId } = useMessagesContext()

    const { accountantId: customerAccountantId } = useSelector((state) => getCustomerById(state, { customerId }))

    const messageListRef = useRef<HTMLDivElement[]>([])

    useEffect(() => {
        const lastMessage = messages[messages.length - 1]
        messageListRef.current[lastMessage?.id]?.scrollIntoView()
    }, [messages])

    return (
        <>
            {messages.map((message) => (
                <MessagesListItem
                    messageListRef={messageListRef}
                    key={message.id}
                    message={message}
                    customerAccountantId={customerAccountantId}
                />
            ))}
        </>
    )
}
