import { Flexer } from '@scouts/ui'

import { AccountantReviewDTO } from '@/models'

import { UserReviewsItem } from './UserReviewsItem'

export const UserReviewsList = ({ reviews }: { reviews: AccountantReviewDTO[] }) => {
    if (!reviews.length) return null

    return (
        <Flexer column gap="12px">
            {reviews.map((item) => (
                <UserReviewsItem key={item.id} review={item} />
            ))}
        </Flexer>
    )
}
