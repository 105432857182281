import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Line, Spacer, Table } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { formatDate, formatDateWithTime } from '@/helpers'
import { AccountantMeetingSummaryDTO, ContactType } from '@/models'

import { useUser } from '@/hooks/use-user'

import { AccountantStatusBubble } from '../accountants/AccountantStatusBubble'
import { AccountantMeetingsStatusLabel } from './AccountantMeetingsStatusLabel'

export const AccountantMeetingsTableRow = ({
    accountantMeeting,
}: {
    accountantMeeting: AccountantMeetingSummaryDTO
}) => {
    const { isAdminUser } = useUser()
    const {
        accountantFullName,
        accountantStatus,
        customerId,
        customerFullName,
        customerEmail,
        id: accountantMeetingId,
        status,
        scheduledDateUTC,
        statusChangedDateUTC,
        contactPreferenceType,
    } = accountantMeeting

    const meetingPath = `${PATH_CUSTOMERS}/${customerId}/meetings/${accountantMeetingId}`

    const contactTypeLabel = useMemo(() => {
        if (contactPreferenceType === ContactType.Video) return 'Video meeting'
        if (contactPreferenceType === ContactType.Phone) return 'Phone call'
        return 'Unspecified'
    }, [contactPreferenceType])

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="30%">
                <Table.Body.Cell.ContentLink Link={Link} to={meetingPath}>
                    <Customer>
                        <Name>{customerFullName}</Name>
                        <Email>{customerEmail}</Email>

                        {isAdminUser && accountantFullName && (
                            <AccountantName>
                                {accountantFullName}{' '}
                                <Spacer marginLeft="6px">
                                    <AccountantStatusBubble status={accountantStatus} />
                                </Spacer>
                            </AccountantName>
                        )}
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={meetingPath}>
                    <AccountantMeetingsStatusLabel status={status} />
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={meetingPath}>
                    <Line size={font.small}>{contactTypeLabel}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={meetingPath}>
                    {!!scheduledDateUTC && <Line size={font.small}>{formatDateWithTime(scheduledDateUTC)}</Line>}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={meetingPath} alignRight>
                    {!!statusChangedDateUTC && <Line size={font.small}>{formatDate(statusChangedDateUTC)}</Line>}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Customer = styled.div``

const AccountantName = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    line-height: 1.3;
    display: flex;
    align-items: center;
    margin-top: 6px;
`

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
`
