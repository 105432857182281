import { colors, radius, Notice } from '@scouts/ui'

import { REFERRAL_DISCOUNT_NAME } from '@/constants'
import { formatCurrency } from '@/helpers'
import { CustomerDiscountDTO, SelfAssessmentDTO } from '@/models'

export const SelfAssessmentNoticePayLater = ({
    discount,
    selfAssessment,
}: {
    discount: CustomerDiscountDTO
    selfAssessment: SelfAssessmentDTO
}) => {
    const { hasPaid, payAfterFiling, price } = selfAssessment

    if (hasPaid || !payAfterFiling) return null

    return (
        <Notice background={colors.redLighter} borderRadius={radius.medium}>
            <p>
                Client has chosen “Pay later” option for this tax return
                {discount && '.'}
            </p>
            {discount && (
                <p>
                    A referral code "
                    {discount.discountSchemeDiscountCode === REFERRAL_DISCOUNT_NAME
                        ? discount.customerReferralSource
                        : discount.discountSchemeDiscountCode}
                    " has been used - TaxScouts fee reduced to {formatCurrency(price)}.
                </p>
            )}
        </Notice>
    )
}
