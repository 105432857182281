import { useState } from 'react'
import styled from 'styled-components'

import { colors, font, media, Button, Container } from '@scouts/ui'

import { formatDateYYYYMMDD } from '@/helpers'
import { TaxReliefClaimOptions, TaxReliefClaimStatus } from '@/models'

import { FilterDropdown } from '@/components/ui/filter'

import { useFilters } from '../hooks'

export const TaxReliefClaimsSearchFilterAdd = () => {
    const { applyFilter, activeFilters } = useFilters()
    const {
        status,
        submittedDate,
        awaitingSignatureDate,
        signatureReceivedDate,
        readyToSendDate,
        sentDate,
        cancelledDate,
        supportUserId,
    } = activeFilters
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const today = formatDateYYYYMMDD()

    const openDropdown = () => {
        setIsDropdownOpen(true)
    }

    const closeDropdown = () => {
        setIsDropdownOpen(false)
    }

    const addFilter = (filter: TaxReliefClaimOptions) => {
        applyFilter({ updatedFilter: filter })
        closeDropdown()
    }

    const filters = [
        {
            isActive: status,
            defaultFilter: {
                status: TaxReliefClaimStatus.Submitted,
            },
            label: 'Status',
        },
        {
            isActive: supportUserId,
            defaultFilter: {
                supportUserId: null,
            },
            label: 'Support user',
        },
        {
            isActive: submittedDate,
            defaultFilter: {
                submittedDate: {
                    start: today,
                    end: today,
                },
            },
            label: 'Submitted date',
        },
        {
            isActive: awaitingSignatureDate,
            defaultFilter: {
                awaitingSignatureDate: {
                    start: today,
                    end: today,
                },
            },
            label: 'Awaiting signature date',
        },
        {
            isActive: signatureReceivedDate,
            defaultFilter: {
                signatureReceivedDate: {
                    start: today,
                    end: today,
                },
            },
            label: 'Signature received date',
        },
        {
            isActive: readyToSendDate,
            defaultFilter: {
                readyToSendDate: {
                    start: today,
                    end: today,
                },
            },
            label: 'Ready to send date',
        },
        {
            isActive: sentDate,
            defaultFilter: {
                sentDate: {
                    start: today,
                    end: today,
                },
            },
            label: 'Sent date',
        },
        {
            isActive: cancelledDate,
            defaultFilter: {
                cancelledDate: {
                    start: today,
                    end: today,
                },
            },
            label: 'Cancelled date',
        },
    ]

    return (
        <>
            <Button isSecondary size="small" onClick={openDropdown}>
                Add filter
            </Button>

            <Container relative>
                {isDropdownOpen && (
                    <FilterDropdown>
                        {filters.map(({ isActive, defaultFilter, label }) => (
                            <Item key={label} isActive={isActive} onClick={() => addFilter(defaultFilter)}>
                                {label}
                            </Item>
                        ))}
                    </FilterDropdown>
                )}
            </Container>
        </>
    )
}

const Item = styled.button<{ isActive: boolean }>`
    ${({ isActive }) =>
        isActive &&
        `
        color: ${colors.neutralDarker};
        pointer-events: none;
        &:after {
            content: '\\002713';
            margin-left: 6px;
        }
    `}

    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: left;
    display: block;
    width: 100%;
    padding: 9px 0;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }

    &:first-child {
        margin-top: -9px;
    }

    &:last-child {
        margin-bottom: -9px;
    }
`
