import { colors, Indicator, Tooltip } from '@scouts/ui'

import { AgentAuthorisationStatus, AgentAuthorisationStatusTypes } from '@/models'

export const CustomerIndicatorsAgentAuth = ({
    agentAuthorisationStatus,
}: {
    agentAuthorisationStatus?: AgentAuthorisationStatusTypes
}) => {
    if (!agentAuthorisationStatus) {
        return (
            <Indicator color={colors.neutralDarker} background={colors.white} border={colors.neutralLightest}>
                <Tooltip content="Agent authorisation not started">AA</Tooltip>
            </Indicator>
        )
    }

    const background = () => {
        switch (agentAuthorisationStatus) {
            case AgentAuthorisationStatus.CodeReceived:
            case AgentAuthorisationStatus.CodeSubmitted:
                return colors.blueLighter
            case AgentAuthorisationStatus.ClientAuthorised:
                return colors.mintLighter
            case AgentAuthorisationStatus.AwaitingCode:
            case AgentAuthorisationStatus.CodeSentToCustomer:
            case AgentAuthorisationStatus.RequestExpiring:
            case AgentAuthorisationStatus.Submitted:
                return colors.yellow
            case AgentAuthorisationStatus.IncorrectCode:
            case AgentAuthorisationStatus.RequestExpired:
            case AgentAuthorisationStatus.RequestFailed:
            case AgentAuthorisationStatus.UnableToIssue:
                return colors.red
            default:
                return ''
        }
    }

    const title = () => {
        switch (agentAuthorisationStatus) {
            case AgentAuthorisationStatus.CodeReceived:
            case AgentAuthorisationStatus.CodeSubmitted:
                return 'Agent authorisation in process'
            case AgentAuthorisationStatus.ClientAuthorised:
                return 'Agent authorisation is completed'
            case AgentAuthorisationStatus.AwaitingCode:
            case AgentAuthorisationStatus.CodeSentToCustomer:
            case AgentAuthorisationStatus.RequestExpiring:
            case AgentAuthorisationStatus.Submitted:
                return 'Agent authorisation in process'
            case AgentAuthorisationStatus.IncorrectCode:
            case AgentAuthorisationStatus.RequestExpired:
            case AgentAuthorisationStatus.RequestFailed:
            case AgentAuthorisationStatus.UnableToIssue:
                return 'Agent authorisation has failed'
            default:
                return ''
        }
    }

    const tooltip = title()

    return (
        <Indicator background={background()}>
            <Tooltip shouldDisplay={!!tooltip} content={tooltip}>
                AA
            </Tooltip>
        </Indicator>
    )
}
