import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownDateRange } from '../ui/filter'

const SearchFilterTaxConsultationCompletedDate = (props) => {
    const {
        taxConsultationCompletedDateStart,
        taxConsultationCompletedDateEnd,
        applyFilter,
        removeFilter,
        isOpenByDefault,
    } = props

    const hasActiveFilter = taxConsultationCompletedDateStart || taxConsultationCompletedDateEnd

    const activeValues = [
        taxConsultationCompletedDateStart ? `From ${taxConsultationCompletedDateStart}` : '',
        taxConsultationCompletedDateEnd ? `To ${taxConsultationCompletedDateEnd}` : '',
    ]

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Consultation completed date"
            activeValues={activeValues}
            removeFilter={() => removeFilter(['taxConsultationCompletedDateStart', 'taxConsultationCompletedDateEnd'])}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownDateRange
                dateFromName="taxConsultationCompletedDateStart"
                dateToName="taxConsultationCompletedDateEnd"
                applyFilter={applyFilter}
                removeFilter={() =>
                    removeFilter(['taxConsultationCompletedDateStart', 'taxConsultationCompletedDateEnd'])
                }
                dateFrom={taxConsultationCompletedDateStart}
                dateTo={taxConsultationCompletedDateEnd}
            />
        </FilterItem>
    )
}

SearchFilterTaxConsultationCompletedDate.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
    taxConsultationCompletedDateEnd: PropTypes.string.isRequired,
    taxConsultationCompletedDateStart: PropTypes.string.isRequired,
}

export default SearchFilterTaxConsultationCompletedDate
