import { CompanyReturnSearchOptions } from '@/models'

import { useGetCompanyReturnSearchQuery } from '../api'

export const useCompanyReturnsSearch = ({ options }: { options?: CompanyReturnSearchOptions }) => {
    const { data: fetchedData, isLoading, isFetching, isError } = useGetCompanyReturnSearchQuery({ options })
    const { data, pagination } = fetchedData || {}

    return {
        companyReturns: data,
        isError,
        isFetching,
        isLoading,
        pagination,
    }
}
