import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownRadios } from '../ui/filter'

const RewardsFilterPaidStatus = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter !== null

    const values = [
        {
            value: true,
            label: 'Yes',
            checked: activeFilter,
        },
        {
            value: false,
            label: 'No',
            checked: !activeFilter,
        },
    ]

    const activeValue = _get(_find(values, { value: activeFilter }), ['label'], null)

    const name = 'referredUserHasPaid'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Client has paid"
            activeValues={[activeValue]}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownRadios
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

RewardsFilterPaidStatus.propTypes = {
    activeFilter: PropTypes.bool.isRequired,
    applyFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
}

export default RewardsFilterPaidStatus
