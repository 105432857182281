export const REQUEST_CUSTOMERS_LAST_YEAR_STATISTICS = 'REQUEST_CUSTOMERS_LAST_YEAR_STATS'
export const RECEIVE_CUSTOMERS_LAST_YEAR_STATISTICS = 'RECEIVE_CUSTOMERS_LAST_YEAR_STATS'
export const REQUEST_SELF_ASSESSMENTS_STATISTICS = 'REQUEST_SELF_ASSESSMENTS_STATISTICS'
export const RECEIVE_SELF_ASSESSMENTS_STATISTICS = 'RECEIVE_SELF_ASSESSMENTS_STATISTICS'
export const REQUEST_TAX_REGISTRATIONS_STATISTICS = 'REQUEST_TAX_REGISTRATIONS_STATISTICS'
export const RECEIVE_TAX_REGISTRATIONS_STATISTICS = 'RECEIVE_TAX_REGISTRATIONS_STATISTICS'
export const REQUEST_TAX_CONSULTATIONS_STATISTICS = 'REQUEST_TAX_CONSULTATIONS_STATISTICS'
export const RECEIVE_TAX_CONSULTATIONS_STATISTICS = 'RECEIVE_TAX_CONSULTATIONS_STATISTICS'
export const REQUEST_ACCOUNTANT_STATUS_STATISTICS = 'REQUEST_ACCOUNTANT_STATUS_STATISTICS'
export const RECEIVE_ACCOUNTANT_STATUS_STATISTICS = 'RECEIVE_ACCOUNTANT_STATUS_STATISTICS'
export const REQUEST_ACCOUNTANT_STATISTICS = 'REQUEST_ACCOUNTANT_STATISTICS'
export const RECEIVE_ACCOUNTANT_STATISTICS = 'RECEIVE_ACCOUNTANT_STATISTICS'
export const REQUEST_REVENUE_STATS = 'REQUEST_REVENUE_STATS'
export const RECEIVE_REVENUE_STATS = 'RECEIVE_REVENUE_STATS'
export const DOWNLOAD_REVENUE_REPORT = 'DOWNLOAD_REVENUE_REPORT'
export const DOWNLOAD_KYC_REPORT = 'DOWNLOAD_KYC_REPORT'
export const REQUEST_CUSTOMERS_STATISTICS = 'REQUEST_CUSTOMERS_STATISTICS'
export const RECEIVE_CUSTOMERS_STATISTICS = 'RECEIVE_CUSTOMERS_STATISTICS'

export const requestCustomersLastYearStatistics = (data) => ({ type: REQUEST_CUSTOMERS_LAST_YEAR_STATISTICS, data })

export const receiveCustomersLastYearStatistics = (data) => ({ type: RECEIVE_CUSTOMERS_LAST_YEAR_STATISTICS, data })

export const requestCustomersStatistics = (data) => ({ type: REQUEST_CUSTOMERS_STATISTICS, data })

export const receiveCustomersStatistics = (data) => ({ type: RECEIVE_CUSTOMERS_STATISTICS, data })

export const requestSelfAssessmentsStatistics = (data) => ({ type: REQUEST_SELF_ASSESSMENTS_STATISTICS, data })

export const receiveSelfAssessmentsStatistics = (data) => ({ type: RECEIVE_SELF_ASSESSMENTS_STATISTICS, data })

export const requestTaxRegistrationsStatistics = (data) => ({ type: REQUEST_TAX_REGISTRATIONS_STATISTICS, data })

export const receiveTaxRegistrationsStatistics = (data) => ({ type: RECEIVE_TAX_REGISTRATIONS_STATISTICS, data })

export const requestTaxConsultationsStatistics = (data) => ({ type: REQUEST_TAX_CONSULTATIONS_STATISTICS, data })

export const receiveTaxConsultationsStatistics = (data) => ({ type: RECEIVE_TAX_CONSULTATIONS_STATISTICS, data })

export const requestAccountantStatusStatistics = () => ({ type: REQUEST_ACCOUNTANT_STATUS_STATISTICS })

export const receiveAccountantStatusStatistics = (data = []) => ({ type: RECEIVE_ACCOUNTANT_STATUS_STATISTICS, data })

export const requestAccountantStatistics = (data = {}, meta = {}) => ({
    type: REQUEST_ACCOUNTANT_STATISTICS,
    data,
    meta,
})

export const receiveAccountantStatistics = (data, meta = {}) => ({ type: RECEIVE_ACCOUNTANT_STATISTICS, data, meta })

export const requestRevenueStats = (data) => ({ type: REQUEST_REVENUE_STATS, data })

export const receiveRevenueStats = (data) => ({ type: RECEIVE_REVENUE_STATS, data })

export const downloadRevenueReport = (data) => ({ type: DOWNLOAD_REVENUE_REPORT, data })

export const downloadKYCReport = (data) => ({ type: DOWNLOAD_KYC_REPORT, data })
