import { colors, font, radius, Container, Heading } from '@scouts/ui'

import { SubscriptionDTO, SubscriptionStatus } from '@/models'

export const CustomerProfileSubscription = ({ subscription }: { subscription: SubscriptionDTO }) => {
    const hasSubscription =
        subscription.status !== SubscriptionStatus.Started && subscription.status !== SubscriptionStatus.Pending

    if (!hasSubscription) return null

    const isActive = subscription.status === SubscriptionStatus.Active

    const backgroundColor = isActive ? colors.mintLighter : colors.yellow

    return (
        <Container background={backgroundColor} padding="18px" radius={radius.medium}>
            <Heading size={font.small}>
                {isActive && <>Active subscription</>}
                {!isActive && <>Subscription status: {subscription.status}</>}
            </Heading>
        </Container>
    )
}
