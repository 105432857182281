import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Grid, Line, Spacer } from '@scouts/ui'

import { requestTaxConsultationsStatistics } from '@/actions'
import { PATH_TAX_CONSULTATIONS } from '@/constants'
import { generatePathWithFilter } from '@/helpers/generate-path-with-filter'
import { AccountantDetailsDTO, TaxConsultationStatusFilter } from '@/models'
import { getFullStatistics } from '@/selectors'
import { useAppDispatch } from '@/store'

import { Card } from './Card'

export const DashboardStatsTaxConsultations = ({ accountantId }: { accountantId: AccountantDetailsDTO['id'] }) => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const statistics = useSelector(getFullStatistics)

    useEffect(() => {
        dispatch(requestTaxConsultationsStatistics({ accountantId }))
    }, [accountantId, dispatch])

    const { taxConsultationsBooked, taxConsultationsInProgress } = statistics

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Tax consultations
                    </Line>
                    <ButtonLink
                        color={colors.neutralDarker}
                        onClick={() =>
                            history.push(
                                generatePathWithFilter(PATH_TAX_CONSULTATIONS, {
                                    accountantId,
                                    status: null,
                                })
                            )
                        }
                    >
                        View all
                    </ButtonLink>
                </Flexer>
            </Spacer>

            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                <Card
                    background={colors.blueLighter}
                    linkTo={generatePathWithFilter(PATH_TAX_CONSULTATIONS, {
                        accountantId,
                        status: TaxConsultationStatusFilter.Booked,
                    })}
                    title="Booked"
                    value={taxConsultationsBooked}
                />
                <Card
                    background={colors.yellow}
                    linkTo={generatePathWithFilter(PATH_TAX_CONSULTATIONS, {
                        accountantId,
                        status: TaxConsultationStatusFilter.AwaitingSummary,
                    })}
                    title="Awaiting summary"
                    value={taxConsultationsInProgress}
                />
            </Grid>
        </Container>
    )
}
