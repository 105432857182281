import { lazy, Suspense } from 'react'

import { colors, AccountantPortalChrome, Loadable } from '@scouts/ui'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useSelfAssessmentIdFromPath } from '../hooks/use-self-assessment-id-from-path'

const SelfAssessmentAgreement = lazy(() => import('./SelfAssessmentAgreement'))

export const SelfAssessmentAgreementContainer = () => {
    const customerId = useCustomerIdFromPath()
    const selfAssessmentId = useSelfAssessmentIdFromPath()

    return (
        <AccountantPortalChrome.Customer.Page.Container backgroundColor={colors.neutralLightest}>
            <Suspense fallback={<Loadable isLoading />}>
                <SelfAssessmentAgreement selfAssessmentId={selfAssessmentId} customerId={customerId} />
            </Suspense>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
