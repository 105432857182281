import PropTypes from 'prop-types'
import styled from 'styled-components'

import { addSuccessToast, colors, font, media, Label } from '@scouts/ui'

import { DEFAULT_CURRENCY_SIGN } from '@/constants'
import { copyTextToClipboard } from '@/helpers'

export const SectionDataRow = ({ highlight, value, label, boolean, unit, comment, isVerified, rawValue }) => {
    const realValue = () => {
        switch (true) {
            case boolean && value:
                return 'Yes'
            case boolean && value === false:
                return 'No'
            case boolean && !value:
                return '-'
            default:
                return value
        }
    }

    const color = () => {
        switch (true) {
            case boolean && value:
                return colors.mint
            case boolean && !value:
                return colors.red
            default:
                return null
        }
    }

    const shouldCopy = () => {
        switch (true) {
            case boolean && value:
            case boolean && value === false:
            case boolean && !value:
            case value === '-':
            case !value && !boolean:
                return false
            default:
                return true
        }
    }

    const copyToClipboard = (valueToCopy) => {
        copyTextToClipboard(valueToCopy, () => addSuccessToast({ body: 'Copied to clipboard' }))
    }

    const formatValueToCopy = (valueToCopy) => {
        if (valueToCopy && valueToCopy[0] === DEFAULT_CURRENCY_SIGN) {
            return value.replace(DEFAULT_CURRENCY_SIGN, '').replace(',', '')
        }
        return valueToCopy
    }

    return (
        <Container highlight={highlight}>
            <FieldTitle>{label}</FieldTitle>

            {!shouldCopy() && (
                <Value color={color()}>
                    {realValue()}
                    {unit ? ` ${unit}` : ''} &nbsp;
                    {comment ? ` (${comment}) ` : ''}
                    {isVerified && <Label background={colors.mintLighter}>✓ Verified</Label>}
                </Value>
            )}

            {shouldCopy(value, boolean) && (
                <ValueButton
                    type="button"
                    color={color()}
                    onClick={() => copyToClipboard(formatValueToCopy(rawValue || realValue()))}
                >
                    {realValue()}
                    {unit ? ` ${unit}` : ''} &nbsp;
                    {comment ? `(${comment}) ` : ''}
                    {isVerified && <Label background={colors.mintLighter}>✓ Verified</Label>}
                    <CopyButton>Copy</CopyButton>
                </ValueButton>
            )}
        </Container>
    )
}

SectionDataRow.propTypes = {
    boolean: PropTypes.bool,
    comment: PropTypes.string,
    highlight: PropTypes.bool,
    isVerified: PropTypes.bool,
    label: PropTypes.string.isRequired,
    rawValue: PropTypes.any,
    unit: PropTypes.string,
    value: PropTypes.any,
}

SectionDataRow.defaultProps = {
    boolean: undefined,
    comment: undefined,
    highlight: undefined,
    isVerified: false,
    rawValue: undefined,
    unit: undefined,
    value: undefined,
}

const Container = styled.div`
    display: flex;
    align-items: center;
    background: ${({ highlight }) => (highlight ? colors.blueLighter : colors.white)};

    &:not(:last-child) {
        border-bottom: 1px solid ${colors.neutralLightest};
    }
`

const FieldTitle = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
    text-align: right;
    flex: 0 0 40%;
    padding: 12px 48px 12px 15px;
`

const CopyButton = styled.span`
    color: ${colors.neutralDarker};
    font-size: ${font.smaller};
    position: absolute;
    top: 0;
    right: 12px;
    line-height: 1;
    height: 100%;
    display: none;
    align-items: center;
`

const Value = styled.div`
    color: ${({ color }) => color || 'inherit'};
    font-size: ${font.small};
    text-align: left;
    line-height: 1.3;
    flex: 0 0 60%;
    padding: 12px 15px;
    width: 60%;
`

const ValueButton = styled.button`
    color: ${({ color }) => color || 'inherit'};
    font-size: ${font.small};
    text-align: left;
    line-height: 1.3;
    flex: 0 0 60%;
    padding: 12px 15px;
    width: 60%;
    position: relative;

    ${media.pointer} {
        &:hover ${CopyButton} {
            display: flex;
        }
    }
`
