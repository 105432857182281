import { useState } from 'react'

import { getIsAfterDate, matchSuccessResponse } from '@scouts/helpers'
import {
    addErrorToast,
    Button,
    DatePicker,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { CURRENT_TAX_YEAR } from '@/constants'
import { CompanyReturnDTO, CustomerDTO } from '@/models'
import { useUpdateCompanyReturn } from '@/store/company-returns/hooks'

interface CompanyReturnAccountingPeriodModalProps {
    onClose: () => void
    customer: CustomerDTO
    companyReturn: CompanyReturnDTO
}

export const CompanyReturnAccountingPeriodModal = ({
    customer,
    companyReturn,
    onClose,
}: CompanyReturnAccountingPeriodModalProps) => {
    const [accountingPeriodStart, setAccountingPeriodStart] = useState(companyReturn.accountingPeriodStart || '')
    const [accountingPeriodEnd, setAccountingPeriodEnd] = useState(companyReturn.accountingPeriodEnd || '')

    const { updateCompanyReturn, isLoading: isLoadingUpdateCompany } = useUpdateCompanyReturn()

    const handleSubmit = () => {
        if (getIsAfterDate(accountingPeriodStart, accountingPeriodEnd)) {
            addErrorToast({ body: "Start date can't be after end date" })
            return
        }

        if (
            (companyReturn.accountingPeriodStart && !accountingPeriodStart) ||
            (accountingPeriodEnd && !accountingPeriodStart)
        ) {
            addErrorToast({ body: "Accounting period start date can't be empty" })
            return
        }

        if (
            (companyReturn.accountingPeriodEnd && !accountingPeriodEnd) ||
            (accountingPeriodStart && !accountingPeriodEnd)
        ) {
            addErrorToast({ body: "Accounting period end date can't be empty" })
            return
        }

        updateCompanyReturn({
            companyReturnId: companyReturn.id,
            companyId: companyReturn.companyId,
            customerId: customer.id,
            data: { ...companyReturn, accountingPeriodStart, accountingPeriodEnd },
        }).then(matchSuccessResponse(() => onClose()))
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Edit accounting period</ModalTitle>
                    <ModalSubtitle>{customer?.fullName || 'This customer'}'s company tax return</ModalSubtitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>From</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker
                                name="accountingPeriodStart"
                                value={accountingPeriodStart}
                                onChange={({ value }) => setAccountingPeriodStart(value)}
                                minDate="2020-01-01"
                                maxDate={`${CURRENT_TAX_YEAR + 2}-01-01`}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>To</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker
                                name="accountingPeriodEnd"
                                value={accountingPeriodEnd}
                                onChange={({ value }) => setAccountingPeriodEnd(value)}
                                minDate="2020-01-01"
                                maxDate={`${CURRENT_TAX_YEAR + 2}-01-01`}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onClose} isSecondary>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={isLoadingUpdateCompany}>
                            Save
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
