import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { colors, font, media } from '@scouts/ui'

import { saveCustomer } from '@/actions'
import { getUser } from '@/selectors'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { Conversations } from './Conversations'

export const CustomerSidebarChats = () => {
    const dispatch = useDispatch()

    const { customer } = useCustomerFromPath()

    const [isFirstRender, setIsFirstRender] = useState(true)

    const { id: customerId, accountantEmail } = customer
    const { email: userEmail } = useSelector(getUser)

    useEffect(() => {
        const shouldUpdateLastViewedTime = isFirstRender && accountantEmail === userEmail
        setIsFirstRender(false)

        if (!shouldUpdateLastViewedTime) return

        const updatedCustomer = { ...customer, accountantLastViewedConversationsUTC: new Date().toISOString() }
        dispatch(saveCustomer(customerId, updatedCustomer, { hideMessage: true }))
    }, [accountantEmail, customer, customerId, dispatch, isFirstRender, userEmail])

    return (
        <Container>
            <Title>Chats with TaxScouts support</Title>
            <Conversations />
        </Container>
    )
}

const Container = styled.div`
    padding: 21px 18px;
    background: ${colors.blueLighter};

    ${media.tablet} {
        min-height: 100%;
    }
`

const Title = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    margin-bottom: 18px;
`
