import { generatePath, Link } from 'react-router-dom'

import { colors, font, Container, Flexer, Line, Table } from '@scouts/ui'

import { PATH_CUSTOMER_COMPANY_RETURN } from '@/constants'
import { getCompanyReturnAccountingPeriodString } from '@/domain'
import { formatTimeFromNow } from '@/helpers'
import { CompanyReturnDTO } from '@/models'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { CompanyReturnStatusLabel } from '@/components/company-returns/CompanyReturnStatusLabel'

export const CustomerOverviewCompanyReturn = ({
    companyReturn,
    companyNamePlaceholder,
}: {
    companyReturn: CompanyReturnDTO
    companyNamePlaceholder: string
}) => {
    const customerId = useCustomerIdFromPath()
    const companyReturnPath = generatePath(PATH_CUSTOMER_COMPANY_RETURN, {
        customerId,
        companyId: companyReturn.companyId,
        companyReturnId: companyReturn.id,
    })

    const accountingPeriod = getCompanyReturnAccountingPeriodString(companyReturn)
    const hasAccountingPeriod = !!accountingPeriod

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={companyReturnPath}>
                    <Flexer gap="6px" column>
                        {hasAccountingPeriod ? (
                            <Line weight={font.weight.medium}>{accountingPeriod}</Line>
                        ) : (
                            <Line size={font.body.small} color={colors.text.neutral}>
                                Accounting period not set
                            </Line>
                        )}

                        <Container>
                            <CompanyReturnStatusLabel status={companyReturn.status.current} />
                        </Container>
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="50%">
                <Table.Body.Cell.ContentLink Link={Link} to={companyReturnPath}>
                    <Line size={font.body.medium}>{companyReturn.companyName || companyNamePlaceholder}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={companyReturnPath} alignRight>
                    <Line size={font.body.medium}>{formatTimeFromNow(companyReturn.status.updatedDateUTC)}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}
