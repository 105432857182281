import { Container, Skeleton, SkeletonLine } from '@scouts/ui'

import { CustomerDTO } from '@/models'
import { useTaxReliefClaim } from '@/store/tax-relief-claims'

import { useCustomerTaxReliefClaims } from '../hooks/use-customer-tax-relief-claims'
import { useTaxReliefClaimIdFromPath } from '../hooks/use-tax-relief-claim-id-from-path'

import { TaxReliefClaim } from './TaxReliefClaim'

export const TaxReliefClaimContainer = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const activeTaxReliefClaimId = useTaxReliefClaimIdFromPath()
    const { defaultTaxReliefClaimId, isLoading: isLoadingCustomerTaxReliefClaims } = useCustomerTaxReliefClaims({
        customerId,
    })

    const taxReliefClaimId = activeTaxReliefClaimId || defaultTaxReliefClaimId || 0

    const { taxReliefClaim, isLoading, isFetching } = useTaxReliefClaim({ taxReliefClaimId })

    // @TODO: improve skeleton
    if (taxReliefClaimId === 0 || !taxReliefClaim || isLoading || isFetching || isLoadingCustomerTaxReliefClaims)
        return (
            <Container padding="0">
                <Skeleton padding="24px 48px 48px" gap="18px">
                    <SkeletonLine height="60px" />
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="38px" />
                </Skeleton>
            </Container>
        )

    return <TaxReliefClaim taxReliefClaim={taxReliefClaim} customerId={customerId} />
}
