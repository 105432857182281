import { useState } from 'react'

import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Radio,
    Select,
    TextArea,
} from '@scouts/ui'

import { formatCurrency } from '@/helpers'
import { RefundCreateDTO, RefundReasonTypes } from '@/models'

import { useRefundReasonsOptions } from '@/hooks/use-refund-reasons'

interface RefundModalProps {
    isLoading?: boolean
    itemName: string
    itemPrice: number
    onClose: () => void
    onRefundItem: (refundDetails: RefundCreateDTO) => void
    title: string
}

export const RefundModal = ({ isLoading, itemName, itemPrice, onClose, onRefundItem, title }: RefundModalProps) => {
    const [refundReasonType, setRefundReasonType] = useState<RefundReasonTypes | null>(null)
    const [refundReasonDescription, setRefundReasonDescription] = useState('')
    const [isFreeItem, setIsFreeItem] = useState(false)

    const options = useRefundReasonsOptions(refundReasonType)

    const isButtonDisabled = !refundReasonType || !refundReasonDescription

    const handleRefundItem = () => {
        onRefundItem({
            free: isFreeItem,
            refundReasonType,
            refundReasonDescription,
        })
    }

    return (
        <Modal onClose={onClose}>
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Form.Row>
                    <Form.Row.Title>Reason for the refund</Form.Row.Title>
                    <Form.Row.Content>
                        <Select
                            name="refundReasonType"
                            value={refundReasonType || ''}
                            onChange={({ value }) => setRefundReasonType(value as RefundReasonTypes)}
                            options={options}
                        />
                    </Form.Row.Content>
                </Form.Row>

                <Form.Row>
                    <Form.Row.Title>Details</Form.Row.Title>
                    <Form.Row.Content>
                        <TextArea
                            name="refundReasonDescription"
                            value={refundReasonDescription}
                            maxLength={2000}
                            onChange={({ value }) => setRefundReasonDescription(value)}
                        />
                    </Form.Row.Content>
                </Form.Row>

                <Form.Row>
                    <Form.Control>
                        <Radio
                            checked={!isFreeItem}
                            label={`Refund ${formatCurrency(itemPrice)} & cancel ${itemName.toLowerCase()}`}
                            name="isFreeItem"
                            onChange={() => setIsFreeItem(false)}
                            value="false"
                        />
                    </Form.Control>
                    <Form.Control>
                        <Radio
                            checked={isFreeItem}
                            label={`Refund ${formatCurrency(itemPrice)} & give free ${itemName.toLowerCase()}`}
                            name="isFreeItem"
                            onChange={() => setIsFreeItem(true)}
                            value="true"
                        />
                    </Form.Control>
                </Form.Row>
            </ModalBody>

            <ModalFooter>
                <ModalActions>
                    <Button isSecondary onClick={onClose}>
                        Back
                    </Button>

                    <Button disabled={isButtonDisabled} isLoading={isLoading} onClick={handleRefundItem}>
                        Refund
                    </Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}
