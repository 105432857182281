import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
    colors,
    font,
    Button,
    Form,
    Label,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { PATH_TEMPLATES } from '@/constants'
import { MessageTemplateDTO } from '@/models/message-template'

import { useUser } from '@/hooks/use-user'

export const MessageTemplateViewModal = ({ template }: { template: MessageTemplateDTO }) => {
    const history = useHistory()
    const { fullName } = useUser()

    const goToTemplatesView = () => {
        history.replace(PATH_TEMPLATES, { search: new URLSearchParams({ type: template.type }).toString() })
    }

    return (
        <Modal isWide onClose={goToTemplatesView}>
            <ModalHeader>
                <ModalTitle>
                    {template.name}&nbsp;&nbsp;&nbsp;
                    <Label background={colors.neutralLightest}>TaxScouts template</Label>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Form.Row>
                    <Form.Row.Content>
                        <MessageContainer>
                            <MessageLine>Hi [client name],</MessageLine>
                            <Content>{template.content}</Content>
                            <MessageLine>&mdash; {fullName}</MessageLine>
                        </MessageContainer>
                    </Form.Row.Content>
                </Form.Row>
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button onClick={goToTemplatesView}>Close</Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}

const MessageContainer = styled.div`
    position: relative;
    background: ${colors.redLighter};
    padding: 0 36px;
`

const MessageLine = styled.div`
    display: block;
    padding: 18px 0;
    font-size: ${font.normal};
`

const Content = styled.div`
    line-height: 1.5;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    margin-bottom: 12px;
`
