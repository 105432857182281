import FileSaver from 'file-saver'
import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import {
    receiveCustomer,
    receiveCustomers,
    receiveSuccessAlerts,
    removeCustomer,
    CREATE_CUSTOMER,
    DELETE_CUSTOMER,
    EXPORT_CUSTOMERS,
    REQUEST_CUSTOMER,
    REQUEST_CUSTOMERS,
    SAVE_CUSTOMER,
    SAVE_CUSTOMER_ACCOUNTANT,
    SAVE_CUSTOMER_EMAIL,
    SEARCH_CUSTOMERS,
} from '../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE, PATH_CUSTOMERS } from '../constants'
import { handleError } from '../handlers'
import { apiCall, getPaginationHeaders } from '../helpers'

export default function* customersSagas() {
    yield takeLatest(CREATE_CUSTOMER, createCustomer)
    yield takeLatest(DELETE_CUSTOMER, deleteCustomer)
    yield takeLatest(REQUEST_CUSTOMER, fetchCustomer)
    yield takeLatest(REQUEST_CUSTOMERS, fetchCustomers)
    yield takeLatest(SAVE_CUSTOMER_ACCOUNTANT, saveCustomerAccountant)
    yield takeLatest(SAVE_CUSTOMER_EMAIL, saveCustomerEmail)
    yield takeLatest(SAVE_CUSTOMER, saveCustomer)
    yield takeLatest(SEARCH_CUSTOMERS, searchCustomers)
    yield takeLatest(EXPORT_CUSTOMERS, exportCustomers)
}

function* fetchCustomers({ data }) {
    const productType = _get(data, ['productType'], '')
    const productStatus = _get(data, ['productStatus'], '')
    const accountantId = _get(data, ['accountantId'], '')
    const page = _get(data, ['page'], PAGINATION_DEFAULT_PAGE)
    const pageSize = _get(data, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE)

    const query = `productType=${productType || ''}&productStatus=${productStatus || ''}&accountantId=${
        accountantId || ''
    }&page=${page}&pageSize=${pageSize}`

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/productsearch?${query}`)
        const pagination = getPaginationHeaders(response.headers)
        yield put(receiveCustomers(response.data, { pagination }))
    } catch (e) {
        yield put(handleError(e))
        yield put(receiveCustomers([]))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchCustomer(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${action.data.customerId}`)
        yield put(receiveCustomer(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveCustomer(action) {
    const { customerId, customer } = action.data

    const options = { hideMessage: false, onSuccess: null, ..._get(action, ['options'], {}) }
    const { hideMessage, onSuccess } = options

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${customerId}`, 'put', customer)
        yield put(receiveCustomer(response.data))
        if (onSuccess) {
            onSuccess()
        }
        if (!hideMessage) {
            yield put(receiveSuccessAlerts('Client profile saved'))
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveCustomerEmail({ data, meta = {} }) {
    const customerId = _get(data, ['customerId'])
    const email = _get(data, ['email'])

    const onSuccess = _get(meta, ['onSuccess'])
    const onError = _get(meta, ['onError'])

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${customerId}/email`, 'post', `"${email}"`, {
            'Content-Type': 'application/json',
        })
        yield put(receiveCustomer(response.data))
        yield put(receiveSuccessAlerts('Client email updated'))

        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError()
    } finally {
        yield put(hideLoading())
    }
}

function* saveCustomerAccountant({ data, meta = {} }) {
    const customerId = _get(data, ['customerId'])
    const accountantId = _get(data, ['accountantId'])
    const reassignmentReasonType = data?.reassignmentReasonType
    const reassignmentReasonDescription = _get(data, ['reassignmentReasonDescription'])
    const onSuccess = _get(meta, ['onSuccess'])

    try {
        yield put(showLoading())
        const response = yield apiCall(
            `customers/${customerId}/accountant`,
            'post',
            { customerId, accountantId, reassignmentReasonType, reassignmentReasonDescription },
            {
                'Content-Type': 'application/json',
            }
        )

        yield put(receiveCustomer(response.data))
        yield put(receiveSuccessAlerts('Client accountant saved'))

        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* createCustomer(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall('customers', 'post', action.data.customer)
        yield put(receiveCustomer(response.data))

        if (action.data.redirect) {
            window.location.assign(`${PATH_CUSTOMERS}/${response.data.id}`)
        }

        yield put(receiveSuccessAlerts('Client created'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* deleteCustomer(action) {
    const id = _get(action, ['data', 'id'], null)

    const { onSuccess } = action.data

    try {
        yield put(showLoading())
        yield apiCall(`customers/${id}`, 'delete')
        yield put(removeCustomer({ id }))

        if (onSuccess) onSuccess()

        yield put(receiveSuccessAlerts('Client deleted'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* searchCustomers(action) {
    const { filter } = action.data
    const { onSuccess, onError } = action.options

    try {
        const { data: customers } = yield apiCall(`customers/textsearch?filter=${encodeURIComponent(filter)}`)

        if (onSuccess) onSuccess({ customers })
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError(e)
    }
}

function* exportCustomers(action) {
    const { filters, accountantId } = action.data

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/export?filters=${filters}&accountantId=${accountantId}`, 'post')
        if (response.data) {
            const file = new Blob([response.data], {})
            FileSaver.saveAs(file, `returning-clients-${filters}.csv`)
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
