import { generateApiPath } from '@scouts/helpers'

import { AgentAuthorisationDTO, AgentAuthorisationSubmitDTO, CustomerDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

export interface GetAgentAuthorisationParams {
    agentAuthorisationId: AgentAuthorisationDTO['id']
}

export interface GetCustomersAgentAuthorisationsParams {
    customerId: CustomerDTO['id']
}

export interface UpdateAgentAuthorisationParams {
    agentAuthorisationId: AgentAuthorisationDTO['id']
    data: AgentAuthorisationDTO
}

interface AgentAuthorisationCreateData {
    customerId: AgentAuthorisationDTO['customerId']
    accountantId: AgentAuthorisationDTO['accountantId']
    status: AgentAuthorisationDTO['status']
    requestedDateUTC: AgentAuthorisationDTO['requestedDateUTC']
}

export interface CreateAgentAuthorisationParams {
    data: AgentAuthorisationCreateData
}

export interface RequestHmrcAgentAuthorisationParams {
    data: AgentAuthorisationSubmitDTO
}

export interface PollHmrcAgentAuthorisationParams {
    agentAuthorisation: AgentAuthorisationDTO
}

export interface RequestStatusHmrcAgentAuthorisationParams {
    agentAuthorisationId: AgentAuthorisationDTO['id']
}

export interface SubmitCodeHmrcAgentAuthorisationParams {
    agentAuthorisationId: AgentAuthorisationDTO['id']
    authorisationCode: AgentAuthorisationDTO['authorisationCode']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAgentAuthorisation: builder.query<AgentAuthorisationDTO, GetAgentAuthorisationParams>({
            query: ({ agentAuthorisationId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.AGENT_AUTHORISATION.GET.SINGLE, {
                    agentAuthorisationId,
                }),
            }),
            providesTags: [Tags.AgentAuthorisations],
        }),
        getCustomersAgentAuthorisations: builder.query<AgentAuthorisationDTO[], GetCustomersAgentAuthorisationsParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.AGENT_AUTHORISATION.GET.CUSTOMERS, { customerId }),
            }),
            providesTags: [Tags.AgentAuthorisations],
        }),
        createAgentAuthorisation: builder.mutation<AgentAuthorisationDTO, CreateAgentAuthorisationParams>({
            query: ({ data }) => ({
                method: 'POST',
                url: ENDPOINTS.AGENT_AUTHORISATION.POST.CREATE,
                body: data,
            }),
            invalidatesTags: [Tags.AgentAuthorisations],
        }),
        updateAgentAuthorisation: builder.mutation<AgentAuthorisationDTO, UpdateAgentAuthorisationParams>({
            query: ({ agentAuthorisationId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.AGENT_AUTHORISATION.PUT, { agentAuthorisationId }),
                body: data,
            }),
            invalidatesTags: [Tags.AgentAuthorisations],
        }),
        updateAgentAuthorisationWithoutValidation: builder.mutation<
            AgentAuthorisationDTO,
            UpdateAgentAuthorisationParams
        >({
            query: ({ agentAuthorisationId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.AGENT_AUTHORISATION.PUT, { agentAuthorisationId }),
                body: data,
            }),
        }),
        requestHmrcAgentAuthorisation: builder.mutation<AgentAuthorisationDTO, RequestHmrcAgentAuthorisationParams>({
            query: ({ data }) => ({
                method: 'POST',
                url: ENDPOINTS.AGENT_AUTHORISATION.POST.REQUEST_HMRC,
                body: data,
            }),
            invalidatesTags: [Tags.AgentAuthorisations],
        }),
        pollHmrcAgentAuthorisation: builder.mutation<AgentAuthorisationDTO, PollHmrcAgentAuthorisationParams>({
            query: ({ agentAuthorisation }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.AGENT_AUTHORISATION.POST.POLL_HMRC, {
                    agentAuthorisationId: agentAuthorisation.id,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                body: `"${agentAuthorisation.correlationId}"`,
            }),
        }),
        requestStatusHmrcAgentAuthorisation: builder.mutation<
            AgentAuthorisationDTO,
            RequestStatusHmrcAgentAuthorisationParams
        >({
            query: ({ agentAuthorisationId }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.AGENT_AUTHORISATION.POST.STATUS_HMRC, { agentAuthorisationId }),
            }),
            invalidatesTags: [Tags.AgentAuthorisations],
        }),
        submitCodeHmrcAgentAuthorisation: builder.mutation<
            AgentAuthorisationDTO,
            SubmitCodeHmrcAgentAuthorisationParams
        >({
            query: ({ agentAuthorisationId, authorisationCode }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.AGENT_AUTHORISATION.POST.CODE_HMRC, { agentAuthorisationId }),
                headers: {
                    'Content-Type': 'application/json',
                },
                body: `"${authorisationCode}"`,
            }),
            invalidatesTags: [Tags.AgentAuthorisations],
        }),
    }),
})

export const {
    useGetAgentAuthorisationQuery,
    useGetCustomersAgentAuthorisationsQuery,
    useUpdateAgentAuthorisationMutation,
    useCreateAgentAuthorisationMutation,
    useRequestHmrcAgentAuthorisationMutation,
    usePollHmrcAgentAuthorisationMutation,
    useRequestStatusHmrcAgentAuthorisationMutation,
    useSubmitCodeHmrcAgentAuthorisationMutation,
    useUpdateAgentAuthorisationWithoutValidationMutation,
} = extendedApi
