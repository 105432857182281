import { CustomerDTO } from '@/models'
import { useCompanyReturn } from '@/store/company-returns/hooks'

import { useCompanyIdFromPath } from '../hooks/use-company-id-from-path'
import { useCompanyReturnIdFromPath } from '../hooks/use-company-return-id-from-path'

import { CompanyReturn } from './CompanyReturn'
import { CompanyReturnRouter } from './CompanyReturnRouter'
import { CompanyReturnSkeleton } from './CompanyReturnSkeleton'

export const CompanyReturnContainer = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const companyId = useCompanyIdFromPath()
    const companyReturnId = useCompanyReturnIdFromPath()

    const { companyReturn, isLoading } = useCompanyReturn({
        customerId,
        companyId: companyId || 0,
        companyReturnId: companyReturnId || 0,
        skip: !companyId || !companyReturnId,
    })

    if (!companyReturn && isLoading) return <CompanyReturnSkeleton />

    if (!companyReturnId) return <CompanyReturnRouter />

    if (!companyReturn) return null

    return <CompanyReturn companyReturn={companyReturn} />
}
