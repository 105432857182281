import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Badge, IconCalendarNumber, Label, Table, Tooltip } from '@scouts/ui'

import {
    CUSTOMER_VIEW_COMPLETED,
    PATH_ACCOUNTANTS,
    PATH_RETURNING_CUSTOMERS,
    PATH_SELF_ASSESSMENTS,
    SELF_ASSESSMENT_STATUS_FILTER_ALL,
    SELF_ASSESSMENT_STATUS_FILTER_FILED,
} from '@/constants'
import { formatCurrency } from '@/helpers'
import { AccountantStatisticsDTO } from '@/models'

import { AccountantStatusLabel } from './AccountantStatusLabel'
import { IntegrationIndicators } from './IntegrationIndicators'

export const AccountantsRow = ({
    accountant,
    onCloseQuickSettings,
}: {
    accountant: AccountantStatisticsDTO
    onCloseQuickSettings?: (hasChanged: boolean) => void
}) => {
    const {
        accountantCompanyName,
        accountantCompanyStatus,
        accountantFullName,
        accountantId,
        accountantProfileImageUrl,
        accountantStatus,
        accountantTaxConsultationStatus,
        allowCompany,
        allowTaxConsultation,
        currentPausedDescription,
        currentPausedScheduledEndDate,
        isOnTrial,
        lastMonthsEarnings,
        lastMonthsFiledReturns,
        lastYearsTotalCustomers,
        priorityNotesCount,
        returningCustomersPercentage,
        accountantSchedulingLink,
        thisMonthsEarnings,
        thisMonthsFiledReturns,
        thisYearsReturningCustomers,
        totalAssignedReturns,
        totalCustomers,
        totalFiledReturns,
        totalInProgressReturns,
        totalSubmittedReturns,
    } = accountant

    const capacity = accountant?.capacity || 0

    const accountantDetailsUrl = `${PATH_ACCOUNTANTS}/${accountantId}`
    const accountantSelfAssessmentsUrl = `${PATH_SELF_ASSESSMENTS}?accountantId=${accountantId}`
    const accountantSelfAssessmentsFiledUrl = `${PATH_SELF_ASSESSMENTS}?status=${SELF_ASSESSMENT_STATUS_FILTER_FILED}&accountantId=${accountantId}`
    const accountantSelfAssessmentsAllUrl = `${PATH_SELF_ASSESSMENTS}?status=${SELF_ASSESSMENT_STATUS_FILTER_ALL}&accountantId=${accountantId}`
    const accountantReturningCustomersCompletedUrl = `${PATH_RETURNING_CUSTOMERS}?view=${CUSTOMER_VIEW_COMPLETED}&accountantId=${accountantId}`

    return (
        <Table.Body.Row key={accountantId}>
            <Table.Body.Cell width="66px">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantDetailsUrl}>
                    <ImageContainer>
                        {accountantProfileImageUrl && <Image src={accountantProfileImageUrl} alt="" />}
                    </ImageContainer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(23% - 66px)">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantDetailsUrl}>
                    <Accountant>
                        <FullName>
                            {accountantFullName}{' '}
                            {isOnTrial && <OnTrial background={colors.blueLighter}>On trial</OnTrial>}
                        </FullName>
                        <CompanyName>{accountantCompanyName}</CompanyName>
                    </Accountant>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="11%">
                <Table.Body.Cell.Content>
                    <AccountantStatusLabel
                        accountantId={accountantId}
                        allowTaxConsultation={allowTaxConsultation}
                        taxConsultationStatus={accountantTaxConsultationStatus}
                        capacity={capacity}
                        currentPausedDescription={currentPausedDescription}
                        currentPausedScheduledEndDate={currentPausedScheduledEndDate}
                        status={accountantStatus}
                        onCloseQuickSettings={onCloseQuickSettings}
                        companyStatus={accountantCompanyStatus}
                        allowCompany={allowCompany}
                    />
                    {allowTaxConsultation && accountantSchedulingLink && (
                        <AccountantCalendarLink
                            href={accountantSchedulingLink}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <IconCalendarNumber color={colors.neutralDarker} />
                        </AccountantCalendarLink>
                    )}
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantSelfAssessmentsUrl}>
                    {(totalAssignedReturns > 0 || totalSubmittedReturns > 0 || totalInProgressReturns > 0) && (
                        <Figures>
                            <div>
                                <Tooltip content={`${totalAssignedReturns} in 'Preparing docs'`}>
                                    <Badge background={colors.neutralLightest} color={colors.black}>
                                        {totalAssignedReturns || '0'}
                                    </Badge>
                                </Tooltip>{' '}
                                <Tooltip content={`${totalSubmittedReturns} in 'Ready to start'`}>
                                    <Badge background={colors.blueLighter} color={colors.black}>
                                        {totalSubmittedReturns || '0'}
                                    </Badge>
                                </Tooltip>{' '}
                                <Tooltip content={`${totalInProgressReturns} in progress`}>
                                    <Badge background={colors.yellow} color={colors.black}>
                                        {totalInProgressReturns || '0'}
                                    </Badge>
                                </Tooltip>
                            </div>
                        </Figures>
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="9%">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantDetailsUrl}>
                    <div>
                        {priorityNotesCount > 0 && (
                            <Tooltip content={`${priorityNotesCount} unresolved priority notes`}>
                                <Badge background={colors.orange} color={colors.black}>
                                    {priorityNotesCount}
                                </Badge>
                            </Tooltip>
                        )}
                    </div>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantReturningCustomersCompletedUrl}>
                    {(lastYearsTotalCustomers > 0 || thisYearsReturningCustomers > 0) && (
                        <Figures>
                            <Figure title={`Clients last year: ${lastYearsTotalCustomers}`}>
                                {lastYearsTotalCustomers || '0'}
                            </Figure>
                            <Figure
                                checked
                                title={`Returning clients this year: ${thisYearsReturningCustomers} (${returningCustomersPercentage}%)`}
                            >
                                {thisYearsReturningCustomers || '0'} ({returningCustomersPercentage || '0'}%)
                            </Figure>
                        </Figures>
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantSelfAssessmentsFiledUrl}>
                    {(thisMonthsEarnings > 0 || thisMonthsFiledReturns > 0) && (
                        <Figures>
                            <Figure title={`Earnings this month: ${thisMonthsEarnings}`}>
                                {formatCurrency(thisMonthsEarnings)}
                            </Figure>
                            <Figure checked title={`Tax returns filed this month: ${thisMonthsFiledReturns}`}>
                                {thisMonthsFiledReturns}
                            </Figure>
                        </Figures>
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantSelfAssessmentsFiledUrl}>
                    {(lastMonthsEarnings > 0 || lastMonthsFiledReturns > 0) && (
                        <Figures>
                            <Figure color={colors.neutralDarker} title={`Earnings last month: ${lastMonthsEarnings}`}>
                                {formatCurrency(lastMonthsEarnings)}
                            </Figure>
                            <Figure
                                checked
                                color={colors.neutralDarker}
                                title={`Tax returns filed last month: ${lastMonthsFiledReturns}`}
                            >
                                {lastMonthsFiledReturns}
                            </Figure>
                        </Figures>
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(17% - 130px)">
                <Table.Body.Cell.ContentLink Link={Link} to={accountantSelfAssessmentsAllUrl}>
                    {(totalCustomers > 0 || totalFiledReturns > 0) && (
                        <Figures>
                            <Figure title={`Total clients: ${totalCustomers}`}>{totalCustomers || '0'}</Figure>
                            <Figure checked title={`Total tax returns filed: ${totalFiledReturns}`}>
                                {totalFiledReturns || '0'}
                            </Figure>
                        </Figures>
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="130px">
                <Table.Body.Cell.ContentLink alignRight Link={Link} to={accountantDetailsUrl}>
                    <IntegrationIndicators
                        externalApis={accountant.externalApis}
                        hasAgentAuthorisationCredentials={accountant.hasAgentAuthorisationCredentials}
                    />
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Figure = styled.div<{ color?: string; checked?: boolean }>`
    color: ${({ color }) => color || 'inherit'};
    font-size: ${font.small};
    line-height: 1.5;

    ${({ checked, color }) =>
        checked &&
        `
        &:before {
            content: '✓';
            color: ${color || colors.mint};
            margin-right: 4px;
    }`}
`

const Figures = styled.div`
    display: flex;
    flex-direction: column;

    ${Figure} + ${Figure} {
        margin-top: 2px;
    }

    ${Figure}:first-child {
        font-size: ${font.normal};
        font-weight: ${font.weight.medium};
    }
`
const ImageContainer = styled.div`
    width: 48px;
    height: 48px;
    border: 1px solid ${colors.neutralLightest};
    border-radius: 9999px;
`

const Image = styled.img`
    width: 48px;
    height: 48px;
    background: ${colors.neutralLightest};
    border-radius: 9999px;
`
const Accountant = styled.div``

const FullName = styled.div`
    font-weight: ${font.weight.medium};
`
const CompanyName = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    margin-top: 6px;
`

const OnTrial = styled(Label)`
    margin-left: 4px;
`

const AccountantCalendarLink = styled.a`
    text-decoration: none;
    display: inline-block;
    margin-top: 1px;
    margin-left: 6px;
`
