import { useGetTaxConsultationQuery, GetTaxConsultationParams } from '../api'

export const useTaxConsultation = ({ taxConsultationId }: GetTaxConsultationParams) => {
    const { data, isLoading, isError, isFetching } = useGetTaxConsultationQuery({ taxConsultationId })

    return {
        taxConsultation: data,
        isError,
        isLoading,
        isFetching,
    }
}
