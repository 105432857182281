import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import { receiveSuccessAlerts, SAVE_SELF_EMPLOYMENT } from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* selfEmploymentSagas() {
    yield takeLatest(SAVE_SELF_EMPLOYMENT, saveSelfEmployment)
}

function* saveSelfEmployment(action) {
    try {
        yield put(showLoading())
        yield apiCall(`selfemployments/${action.data.selfAssessmentId}`, 'put', action.data)
        yield put(receiveSuccessAlerts('Self-employment information added'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
