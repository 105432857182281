import { matchPath, useLocation, useRouteMatch } from 'react-router-dom'

import {
    PATH_CUSTOMER_CONSULTATIONS,
    PATH_CUSTOMER_CONSULTATIONS_AGREEMENT,
    PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM,
} from '@/constants'

const routesPotentiallyWithTaxConsultationId = [
    PATH_CUSTOMER_CONSULTATIONS,
    PATH_CUSTOMER_CONSULTATIONS_AGREEMENT,
    PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM,
]

export const useTaxConsultationIdFromPath = () => {
    const { pathname } = useLocation()

    const currentPathMatch = routesPotentiallyWithTaxConsultationId.find((potentialPath) =>
        matchPath(pathname, { path: potentialPath })
    )
    const potentialParams = useRouteMatch(currentPathMatch)?.params

    const taxConsultationId = Number(potentialParams?.taxConsultationId)

    return taxConsultationId
}
