import { Container } from '@scouts/ui'

import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const OtherIncome = ({ otherIncome }: { otherIncome: SelfAssessmentDTO['otherIncome'] }) => {
    if (!otherIncome) return null

    const { incomeAmount, incomeDescription } = otherIncome

    return (
        <Container>
            <SectionData>
                <SectionDataRow label="Amount" value={formatCurrencyNotSure(incomeAmount)} highlight />
                <SectionDataRow label="Description" value={incomeDescription} />
            </SectionData>
        </Container>
    )
}
