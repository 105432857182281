import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { requestCustomerEscalations } from '@/actions'
import { CustomerDTO } from '@/models'
import { getCustomerEscalations, isFetchingCustomerEscalations } from '@/selectors'
import { useAppDispatch, RootState } from '@/store'

export const useCustomerEscalations = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    useFetchCustomerEscalations({ customerId })

    const escalations = useSelector((state: RootState) => getCustomerEscalations(state, { customerId }))

    const isFetching = useSelector(isFetchingCustomerEscalations)
    const isLoading = isFetching && escalations.length === 0

    return {
        escalations,
        isLoading,
    }
}

const useFetchCustomerEscalations = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(requestCustomerEscalations({ customerId }))
    }, [dispatch, customerId])
}
