import _orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { CustomerDTO } from '@/models'

import { useGetCustomerTaxConsultationsQuery } from '../api'

interface UseCustomersTaxConsultationsProps {
    customerId: CustomerDTO['id']
    skip?: boolean
}

export const useCustomersTaxConsultations = ({ customerId, skip }: UseCustomersTaxConsultationsProps) => {
    const { data, isError, isLoading } = useGetCustomerTaxConsultationsQuery({ customerId }, { skip })

    const hasTaxConsultations = data && data.length > 0

    const defaultTaxConsultationId = data?.[0]?.id || null

    const orderedCustomerTaxConsultations = useMemo(() => _orderBy(data, ['id'], ['desc']), [data])

    return {
        taxConsultations: orderedCustomerTaxConsultations,
        hasTaxConsultations,
        defaultTaxConsultationId,
        isError,
        isLoading,
    }
}
