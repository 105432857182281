export const IdentityVerificationStatus = {
    Requested: 'Requested',
    Started: 'Started',
    Submitted: 'Submitted',
    Verified: 'Verified',
    Cancelled: 'Cancelled',
    Failed: 'Failed',
    Retry: 'Retry',
} as const

export type IdentityVerificationStatusTypes =
    (typeof IdentityVerificationStatus)[keyof typeof IdentityVerificationStatus]

export interface IdentityVerificationDTO {
    accountantFullName: string
    accountantId: number
    approvedDateUTC: string | null
    createdDateUTC: string
    customerId: number
    hasHighRiskActivity: boolean | null
    hasSignificantCashTransactions: boolean | null
    id: number
    notes: string | null
    status: IdentityVerificationStatusTypes
    submittedDateUTC: string | null
    updatedDateUTC: string
}
