import { matchSuccessResponse } from '@scouts/helpers'
import {
    addErrorToast,
    addSuccessToast,
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { isValidAuthorisationCode } from '@/helpers'
import { AgentAuthorisationDTO, CustomerDTO } from '@/models'
import { useSubmitCodeHmrcAgentAuthorisation } from '@/store/agent-authorisations'

interface AgentAuthCodeSubmitModalProps {
    closeModal: () => void
    code: AgentAuthorisationDTO['authorisationCode']
    customer: CustomerDTO
    agentAuthorisationId: AgentAuthorisationDTO['id']
}

export const AgentAuthCodeSubmitModal = ({
    closeModal,
    code,
    customer,
    agentAuthorisationId,
}: AgentAuthCodeSubmitModalProps) => {
    const { submitCodeHmrcAgentAuthorisation } = useSubmitCodeHmrcAgentAuthorisation()

    const handleSubmit = () => {
        if (!isValidAuthorisationCode(code)) {
            addErrorToast({
                body: 'Please double check the code format. It should start with SA and be followed by 8 numbers.',
            })
            return
        }

        submitCodeHmrcAgentAuthorisation({
            agentAuthorisationId,
            authorisationCode: code,
        }).then(
            matchSuccessResponse(() => {
                addSuccessToast({ body: 'HMRC agent authorisation code sent' })
                closeModal()
            })
        )
    }

    return (
        <Modal onClose={closeModal}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Send the agent authorisation code to HMRC</ModalTitle>
                    <ModalSubtitle>Review and submit</ModalSubtitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Client name</Form.Row.Title>
                        <Form.Row.Content>{customer.fullName}</Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Authorisation code</Form.Row.Title>
                        <Form.Row.Content>{code || 'Not provided'}</Form.Row.Content>
                    </Form.Row>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={!code}>
                            Continue
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
