import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const PaymentOnAccount = ({ selfAssessment }: { selfAssessment: SelfAssessmentDTO }) => {
    const {
        hasLastYearPaymentsOnAccount,
        lastYearFirstPaymentOnAccount,
        lastYearSecondPaymentOnAccount,
        lastYearTotalPaymentOnAccount,
        taxYear,
    } = selfAssessment

    return (
        <SectionData>
            <SectionDataRow label="Has last years payments on account" value={hasLastYearPaymentsOnAccount} boolean />

            <SectionDataRow
                label="Amount of payments on account"
                value={formatCurrencyNotSure(lastYearTotalPaymentOnAccount)}
            />
            <SectionDataRow label={`January ${taxYear}`} value={formatCurrencyNotSure(lastYearFirstPaymentOnAccount)} />
            <SectionDataRow label={`July ${taxYear}`} value={formatCurrencyNotSure(lastYearSecondPaymentOnAccount)} />
        </SectionData>
    )
}
