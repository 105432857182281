import { ReactNode } from 'react'

import { colors, font, radius, Container, Flexer, Line, Spacer } from '@scouts/ui'

import { TaxRegistrationDTO, TaxRegistrationStatus } from '@/models'

const getTitle = (isCompleted: boolean, isCancelled: boolean, hasFiles: boolean, hasUTR: boolean) => {
    if (isCancelled) {
        return `UTR registration cancelled`
    }

    if (isCompleted) {
        if (!hasFiles) {
            return 'UTR registration completed, please upload submission receipt (PDF)'
        }

        if (!hasUTR) {
            return 'UTR registration completed, waiting for client to receive and enter the UTR'
        }

        return `UTR registration completed`
    }

    return 'UTR registration'
}

const getHeaderBackground = (status: TaxRegistrationDTO['status'], hasUTR: boolean, hasFiles: boolean) => {
    switch (status) {
        case TaxRegistrationStatus.Submitted: {
            return colors.blueLighter
        }
        case TaxRegistrationStatus.Complete: {
            return hasUTR && hasFiles ? colors.mintLighter : colors.yellow
        }
        case TaxRegistrationStatus.Cancelled: {
            return colors.red
        }
        default:
            return colors.neutralLightest
    }
}

interface TaxRegistrationHeaderProps {
    actions: ReactNode
    status: TaxRegistrationDTO['status']
    hasFiles: boolean
    hasUTR: boolean
}

export const TaxRegistrationHeader = ({ actions, status, hasFiles, hasUTR }: TaxRegistrationHeaderProps) => {
    const isCompleted = status === TaxRegistrationStatus.Complete
    const isCancelled = status === TaxRegistrationStatus.Cancelled

    return (
        <Container radius={radius.medium} padding="24px" background={getHeaderBackground(status, hasUTR, hasFiles)}>
            <Flexer spaceBetween alignCenter>
                <Spacer>
                    <Line weight={font.weight.medium}>{getTitle(isCompleted, isCancelled, hasFiles, hasUTR)}</Line>
                </Spacer>
                <Spacer>{actions}</Spacer>
            </Flexer>
        </Container>
    )
}
