import { useToggle } from '@scouts/helpers'
import { Button } from '@scouts/ui'

import { CustomerDTO } from '@/models'

import { AgentAuthManualAddModal } from './AgentAuthManualAddModal'

interface AgentAuthManualAddProps {
    customer: CustomerDTO
}

export const AgentAuthManualAdd = ({ customer }: AgentAuthManualAddProps) => {
    const [isModalOpen, openModal, closeModal] = useToggle()

    return (
        <>
            <Button onClick={openModal}>I've already requested</Button>

            {isModalOpen && <AgentAuthManualAddModal closeModal={closeModal} customer={customer} />}
        </>
    )
}
