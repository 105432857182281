import { colors, Indicator, Tooltip } from '@scouts/ui'

import { IdentityVerificationStatus, IdentityVerificationStatusTypes } from '@/models'

export const CustomerIndicatorsIdentityVerification = ({
    identityVerificationStatus,
}: {
    identityVerificationStatus?: IdentityVerificationStatusTypes
}) => {
    if (!identityVerificationStatus) {
        return (
            <Indicator
                title="Identity verification not started"
                color={colors.neutralDarker}
                background={colors.white}
                border={colors.neutralLightest}
            >
                <Tooltip content="Identity verification not started">KYC</Tooltip>
            </Indicator>
        )
    }

    const background = () => {
        switch (identityVerificationStatus) {
            case IdentityVerificationStatus.Started:
                return colors.neutralLightest
            case IdentityVerificationStatus.Verified:
                return colors.mintLighter
            case IdentityVerificationStatus.Submitted:
                return colors.blueLighter
            case IdentityVerificationStatus.Requested:
            case IdentityVerificationStatus.Retry:
                return colors.yellow
            case IdentityVerificationStatus.Failed:
            case IdentityVerificationStatus.Cancelled:
                return colors.red
            default:
                return ''
        }
    }

    const title = () => {
        switch (identityVerificationStatus) {
            case IdentityVerificationStatus.Started:
                return 'Identity verification started'
            case IdentityVerificationStatus.Verified:
                return 'Identity marked as verified'
            case IdentityVerificationStatus.Submitted:
                return 'Identity documents need reviewing'
            case IdentityVerificationStatus.Requested:
            case IdentityVerificationStatus.Retry:
                return 'Identity documents requested'
            case IdentityVerificationStatus.Failed:
            case IdentityVerificationStatus.Cancelled:
                return 'Identity verification marked as failed'
            default:
                return ''
        }
    }

    const tooltip = title()

    return (
        <Indicator background={background()}>
            <Tooltip shouldDisplay={!!tooltip} content={tooltip}>
                KYC
            </Tooltip>
        </Indicator>
    )
}
