const REASON_EARNINGS_LIMIT = 'EarningsLimit'
const REASON_SELF_EMPLOYMENT = 'SelfEmployment'
const REASON_PROPERTY_INCOME = 'PropertyIncome'
const REASON_DIVIDENDS_AND_INTEREST = 'DividendsAndInterest'
const REASON_CAPITAL_GAINS = 'CapitalGains'
const REASON_COMPANY_DIRECTOR = 'CompanyDirector'
const REASON_HMRC_LETTER = 'HmrcLetter'
const REASON_TAX_REFUND = 'TaxRefund'
const REASON_OTHER = 'Other'
const REASON_FOREIGN_INCOME = 'ForeignIncome'

export const REASONS_TITLES = {
    [REASON_EARNINGS_LIMIT]: 'Earnings limit',
    [REASON_SELF_EMPLOYMENT]: 'Self employment',
    [REASON_PROPERTY_INCOME]: 'Property income',
    [REASON_DIVIDENDS_AND_INTEREST]: 'Dividends and interest',
    [REASON_CAPITAL_GAINS]: 'Capital gains',
    [REASON_COMPANY_DIRECTOR]: 'Company director',
    [REASON_HMRC_LETTER]: 'HMRC Letter',
    [REASON_TAX_REFUND]: 'Tax refund',
    [REASON_OTHER]: 'Other',
    [REASON_FOREIGN_INCOME]: 'Foreign income',
}
