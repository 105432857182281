import { CompanyReturnFileDTO, FileTypeTypes } from '@/models'
import {
    useDeleteCompanyReturnFile,
    useDownloadCompanyReturnFile,
    useUploadCompanyReturnFile,
} from '@/store/company-return-files/hooks'

import { useGetCompanyReturnParams } from './use-get-params'

export interface HandleDeleteFileParams {
    fileId: CompanyReturnFileDTO['id']
}

export interface HandleUploadFileParams {
    files: File[]
    fileType: FileTypeTypes
}

export interface HandleDownloadFileParams {
    fileId: CompanyReturnFileDTO['id']
}

export const useCompanyFileHandlers = () => {
    const { companyId, companyReturnId, customerId, hasAllCompanyReturnParams } = useGetCompanyReturnParams()
    const { deleteCompanyReturnFile, isLoading: isLoadingDelete } = useDeleteCompanyReturnFile()
    const { uploadCompanyReturnFile, isLoading: isLoadingUpload } = useUploadCompanyReturnFile()
    const { downloadCompanyReturnFile, isLoading: isLoadingDownload } = useDownloadCompanyReturnFile()

    const handleDeleteFile = ({ fileId }: HandleDeleteFileParams) => {
        if (hasAllCompanyReturnParams) {
            deleteCompanyReturnFile({ companyId, companyReturnId, customerId, fileId })
        }
    }

    const handleUploadFile = ({ files, fileType }: HandleUploadFileParams) => {
        if (hasAllCompanyReturnParams)
            uploadCompanyReturnFile({
                file: files[0],
                params: { companyReturnId, companyId, customerId, fileType },
            })
    }

    const handleDownload = ({ fileId }: HandleDownloadFileParams) => {
        if (hasAllCompanyReturnParams)
            downloadCompanyReturnFile({
                companyReturnId,
                companyId,
                customerId,
                fileId,
            })
    }

    return { handleDeleteFile, handleDownload, handleUploadFile, isLoadingDelete, isLoadingUpload, isLoadingDownload }
}
