import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Button, Form, Input, Page, Radio } from '@scouts/ui'

import { createAccountant } from '@/actions'
import { PATH_ACCOUNTANTS, PATH_USERS } from '@/constants'
import { UserType } from '@/models'

import ProfilePageWithSidebar from '../ProfilePageWithSidebar'
import PageBackButton from '../ui/PageBackButton'

const HASH_SUPPORT = '#support'

class UserCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            userType: UserType.Accountant,
            isSubmitPending: false,
        }

        _bindAll(this, ['handleChange', 'handleSubmit', 'allowSubmit', 'redirectOnSuccess', 'setUserTypeByHash'])
    }

    componentDidMount() {
        this.setUserTypeByHash()
    }

    handleChange({ name, value }) {
        this.setState({
            [name]: value,
        })
    }

    handleSubmit() {
        const { dispatch } = this.props
        const { firstName, lastName, email, userType } = this.state

        const isTaxScoutsSupport = userType === UserType.System

        const data = {
            firstName,
            lastName,
            email,
            isTaxScoutsSupport,
        }

        this.setState({ isSubmitPending: true })

        dispatch(createAccountant({ accountant: data, onSuccess: this.redirectOnSuccess, onError: this.allowSubmit }))
    }

    setUserTypeByHash() {
        const { history } = this.props
        const hash = _get(history, ['location', 'hash'])
        const userType = hash === HASH_SUPPORT ? UserType.System : UserType.Accountant

        this.setState({
            userType,
        })
    }

    redirectOnSuccess(newUser) {
        let redirectPath

        if (newUser && newUser.id) {
            const { id: userId } = newUser
            redirectPath = `${PATH_USERS}/${userId}`
        } else {
            const { userType } = this.state
            const isTaxScoutsSupport = userType === UserType.System
            redirectPath = isTaxScoutsSupport ? PATH_USERS : PATH_ACCOUNTANTS
        }

        const { history } = this.props
        history.push(redirectPath)
    }

    allowSubmit() {
        this.setState({ isSubmitPending: false })
    }

    render() {
        const { history } = this.props
        const { firstName, lastName, email, userType, isSubmitPending } = this.state
        const hash = _get(history, ['location', 'hash'])

        const backPath = hash === HASH_SUPPORT ? PATH_USERS : PATH_ACCOUNTANTS
        const backTitle = `Back to ${hash === HASH_SUPPORT ? 'users' : 'accountants'}`

        const title = userType === UserType.System ? 'Add new support user' : 'Add new accountant'

        return (
            <ProfilePageWithSidebar>
                <PageBackButton to={backPath}>{backTitle}</PageBackButton>

                <Page.Heading>{title}</Page.Heading>

                <Page.Section>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                            <Form.Row.Title>First name</Form.Row.Title>
                            <Form.Row.Content>
                                <Input name="firstName" value={firstName} onChange={this.handleChange} required />
                            </Form.Row.Content>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row.Title>Last name</Form.Row.Title>
                            <Form.Row.Content>
                                <Input name="lastName" value={lastName} onChange={this.handleChange} required />
                            </Form.Row.Content>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row.Title>Email</Form.Row.Title>
                            <Form.Row.Content>
                                <Input type="email" name="email" value={email} onChange={this.handleChange} required />
                            </Form.Row.Content>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control>
                                <Radio
                                    checked={userType === UserType.Accountant}
                                    label="Accountant"
                                    name="userType"
                                    onChange={this.handleChange}
                                    value={UserType.Accountant}
                                />
                            </Form.Control>
                            <Form.Control>
                                <Radio
                                    checked={userType === UserType.System}
                                    label="Support user"
                                    name="userType"
                                    onChange={this.handleChange}
                                    value={UserType.System}
                                />
                            </Form.Control>
                        </Form.Row>

                        <Form.Actions>
                            <Button type="submit" disabled={isSubmitPending}>
                                {title}
                            </Button>
                        </Form.Actions>
                    </Form>
                </Page.Section>
            </ProfilePageWithSidebar>
        )
    }
}

UserCreate.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default withRouter(connect()(UserCreate))
