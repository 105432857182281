import { font, Line, Pagination, Select } from '@scouts/ui'

import { Pagination as PaginationModel, PaginationQuery } from '@/models'

import { usePagination } from './hooks'

interface PaginationContainerPropsWithCount {
    pagination: PaginationModel | undefined
    shouldDisplayCount?: never
    itemsCount?: never
}

interface PaginationContainerPropsWithoutCount {
    pagination: PaginationModel | undefined
    shouldDisplayCount: boolean
    itemsCount: number
}

const shouldShowPaginationItem = ({ page, currentPage, size }: { page: number; currentPage: number; size: number }) => {
    if (
        page === 0 ||
        page === 1 ||
        page === 2 ||
        (page === 3 && currentPage === 1) ||
        (page === 4 && currentPage === 1) ||
        page === size - 1 ||
        page === size - 2 ||
        page === size - 3 ||
        page === currentPage - 3 ||
        page === currentPage - 2 ||
        page === currentPage - 1 ||
        page === currentPage ||
        page === currentPage + 1
    ) {
        return true
    }

    return false
}

const shouldShowSeparator = ({ page, currentPage, size }: { page: number; currentPage: number; size: number }) => {
    if ((page === 4 && currentPage > 4) || (page === size - 4 && currentPage < size)) {
        return true
    }

    return false
}

export const PaginationContainer = ({
    pagination,
    shouldDisplayCount,
    itemsCount,
}: PaginationContainerPropsWithCount | PaginationContainerPropsWithoutCount) => {
    const { applyFilter } = usePagination()

    if (!pagination) return null

    const { pageCount, currentPage, totalCount, perPage } = pagination

    const currentPageStarts = perPage && currentPage ? perPage * (currentPage - 1) + 1 : 1
    const currentPageEnds = currentPageStarts + (itemsCount || 0) - 1
    const hasMatches = !!totalCount && totalCount > 0

    const handlePageChange = ({
        page,
        pageSize,
    }: {
        page: PaginationQuery['page']
        pageSize: PaginationQuery['pageSize']
    }) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        applyFilter({ updatedFilter: { page, pageSize } })
    }

    if (!pageCount) return null

    const pages = Array.from(Array(pageCount).keys())

    if (!pages.length || !totalCount) return null

    return (
        <Pagination>
            {pages.length > 1 && (
                <Pagination.Items>
                    {pages.map((page) => {
                        if (shouldShowPaginationItem({ page, currentPage, size: pages.length })) {
                            return (
                                <Pagination.Item
                                    key={page}
                                    isActive={currentPage === page + 1}
                                    onClick={() => {
                                        handlePageChange({ page: page + 1, pageSize: perPage })
                                    }}
                                >
                                    {page + 1}
                                </Pagination.Item>
                            )
                        }

                        if (shouldShowSeparator({ page, currentPage, size: pages.length })) {
                            return <Pagination.Separator key={page}>…</Pagination.Separator>
                        }

                        return null
                    })}
                </Pagination.Items>
            )}
            {(pages.length > 1 || perPage === totalCount) && (
                <Pagination.Actions>
                    <Pagination.Actions.Label>Per page</Pagination.Actions.Label>
                    <Select
                        name="perPage"
                        onChange={({ value }) => {
                            handlePageChange({ page: 1, pageSize: Number(value) })
                        }}
                        value={perPage}
                        options={[
                            { value: 25 },
                            { value: 50 },
                            { value: 100 },
                            { value: totalCount, title: 'View all' },
                        ]}
                    />
                </Pagination.Actions>
            )}
            {shouldDisplayCount && hasMatches && (
                <Line size={font.small}>
                    {currentPageStarts}-{currentPageEnds} of {totalCount} {totalCount === 1 ? 'match' : 'matches'}
                </Line>
            )}
        </Pagination>
    )
}
