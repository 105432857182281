import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { matchSuccessResponse } from '@scouts/helpers'
import { colors, font, shadows, Button, ButtonLink, Spinner } from '@scouts/ui'

import { updateSelfAssessment } from '@/actions'
import { PATH_CUSTOMERS } from '@/constants'
import { formatDateWithTime } from '@/helpers'
import { LoggedEventTypeTitles, SelfAssessmentSummaryDTO } from '@/models'
import { useAppDispatch } from '@/store'
import { useCustomerLoggedEvents, useDismissSelfAssessmentEventsNotifications } from '@/store/logged-events'

export const SelfAssessmentsTableNotificationsPopup = ({
    selfAssessment,
    onClose,
}: {
    selfAssessment: SelfAssessmentSummaryDTO
    onClose: () => void
}) => {
    const dispatch = useAppDispatch()
    const history = useHistory()

    const { getSelfAssessmentLoggedEvents, isLoading } = useCustomerLoggedEvents({
        customerId: selfAssessment.customerId,
    })
    const { dismissSelfAssessmentEventsNotifications } = useDismissSelfAssessmentEventsNotifications()

    const handleDismiss = () => {
        const { id: selfAssessmentId, customerId } = selfAssessment

        dismissSelfAssessmentEventsNotifications({
            customerId,
            selfAssessmentId,
        }).then(
            matchSuccessResponse(() => {
                dispatch(updateSelfAssessment({ ...selfAssessment, numberOfNotificationEvents: 0 }))
            })
        )
    }

    const selfAssessmentEvents = getSelfAssessmentLoggedEvents({ selfAssessmentId: selfAssessment.id })
    const hasSelfAssessmentEvents = selfAssessmentEvents.length > 0

    return (
        <Popup onMouseLeave={onClose}>
            {!hasSelfAssessmentEvents && isLoading && <Spinner isSmall />}
            {hasSelfAssessmentEvents && (
                <>
                    {selfAssessmentEvents.map((event) => (
                        <Event key={event.id}>
                            <EventBody>{event.eventText}</EventBody>
                            <EventDetails>
                                <EventType>
                                    {LoggedEventTypeTitles[event.loggedEventType] ?? event.loggedEventType}
                                </EventType>
                                <EventTime>{formatDateWithTime(event.eventDateUTC)}</EventTime>
                            </EventDetails>
                        </Event>
                    ))}
                    <Actions>
                        <ActionsItem>
                            <ButtonLink
                                type="button"
                                onClick={() =>
                                    history.push(
                                        `${PATH_CUSTOMERS}/${selfAssessment.customerId}/history/${selfAssessment.id}`
                                    )
                                }
                            >
                                View all
                            </ButtonLink>
                        </ActionsItem>
                        <ActionsItem>
                            <Button size="small" onClick={handleDismiss}>
                                Mark as read
                            </Button>
                        </ActionsItem>
                    </Actions>
                </>
            )}
        </Popup>
    )
}

const Popup = styled.div`
    position: absolute;
    bottom: -12px;
    right: 7px;
    z-index: 1000;
    width: 480px;
    padding: 6px;
    background: ${colors.neutralLightest};
    box-shadow: ${shadows.medium};
`

const Event = styled.div`
    white-space: pre-line;
    margin-bottom: 1px;
    padding: 12px;
    background: ${colors.white};
    border-radius: 2px;
`

const EventDetails = styled.div`
    color: ${colors.neutralDarker};
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
`

const EventType = styled.div`
    font-size: ${font.smaller};
`

const EventTime = styled.div`
    font-size: ${font.smaller};
    text-align: right;
`

const EventBody = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
`

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 6px;
`

const ActionsItem = styled.div`
    margin-left: 18px;
`
