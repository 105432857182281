import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useHistory, Link } from 'react-router-dom'

import { colors, AccountantPortalChrome, Button, DropdownSelect, Flexer, Notice, Spacer } from '@scouts/ui'

import { downloadSelfAssessmentFiles } from '@/actions'
import {
    FILING_AREA_CAPITAL_GAINS,
    FILING_AREA_CHARITABLE_GIVING,
    FILING_AREA_DIVIDENDS_AND_INTEREST,
    FILING_AREA_EMPLOYMENT,
    FILING_AREA_FOREIGN_INCOME,
    FILING_AREA_GENERAL,
    FILING_AREA_INVESTMENT_SCHEME,
    FILING_AREA_OTHER_INCOME,
    FILING_AREA_PENSIONS_AND_BENEFITS,
    FILING_AREA_PENSIONS_CONTRIBUTIONS,
    FILING_AREA_PROPERTY,
    FILING_AREA_SELF_EMPLOYMENT,
    FILING_AREA_STUDENT_LOANS,
    FILING_AREA_TITLES,
    PATH_CUSTOMERS,
    PATH_TOOLS_CGT,
    TAX_YEARS,
} from '@/constants'
import { formatDate } from '@/helpers'
import { FileType } from '@/models'
import { useAppDispatch } from '@/store'
import { useCustomersIdentityVerifications } from '@/store/identity-verifications'

import FilesSection from './FilesSection'
import RequestDocumentsContainer from './RequestDocumentsContainer'

const sections = [
    {
        title: FILING_AREA_TITLES[FILING_AREA_GENERAL],
        filingArea: FILING_AREA_GENERAL,
        fileTypes: [
            FileType.FinalReturn,
            FileType.TaxCalculation,
            FileType.SubmissionResponse,
            FileType.PreviousReturn,
            FileType.TaxSchedule,
            FileType.TaxSummary,
            FileType.Other,
        ],
        allowMultipleUploads: false,
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_EMPLOYMENT],
        filingArea: FILING_AREA_EMPLOYMENT,
        fileTypes: [FileType.P60, FileType.P45, FileType.P11D, FileType.Payslip, FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_SELF_EMPLOYMENT],
        filingArea: FILING_AREA_SELF_EMPLOYMENT,
        fileTypes: [
            FileType.IncomeRecord,
            FileType.IncomePlatformReport,
            FileType.ExpenseRecord,
            FileType.Spreadsheet,
            FileType.BankStatement,
            FileType.CisPaymentStatement,
            FileType.Other,
        ],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_PROPERTY],
        filingArea: FILING_AREA_PROPERTY,
        fileTypes: [
            FileType.IncomeRecord,
            FileType.ExpenseRecord,
            FileType.Spreadsheet,
            FileType.BankStatement,
            FileType.PaymentSchedule,
            FileType.Other,
        ],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_CAPITAL_GAINS],
        filingArea: FILING_AREA_CAPITAL_GAINS,
        fileTypes: [FileType.Certificate, FileType.InvestmentSchemeCertificate, FileType.Spreadsheet, FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_DIVIDENDS_AND_INTEREST],
        filingArea: FILING_AREA_DIVIDENDS_AND_INTEREST,
        fileTypes: [FileType.InterestCertificate, FileType.Spreadsheet, FileType.DividendVoucher, FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_PENSIONS_AND_BENEFITS],
        filingArea: FILING_AREA_PENSIONS_AND_BENEFITS,
        fileTypes: [FileType.AnnualSummary, FileType.PaymentSchedule, FileType.PensionsDocument, FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_FOREIGN_INCOME],
        filingArea: FILING_AREA_FOREIGN_INCOME,
        fileTypes: [FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_OTHER_INCOME],
        filingArea: FILING_AREA_OTHER_INCOME,
        fileTypes: [FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_CHARITABLE_GIVING],
        filingArea: FILING_AREA_CHARITABLE_GIVING,
        fileTypes: [FileType.Certificate, FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_PENSIONS_CONTRIBUTIONS],
        filingArea: FILING_AREA_PENSIONS_CONTRIBUTIONS,
        fileTypes: [FileType.PensionsDocument, FileType.Payslip, FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_STUDENT_LOANS],
        filingArea: FILING_AREA_STUDENT_LOANS,
        fileTypes: [FileType.AnnualSummary, FileType.Payslip, FileType.Other],
    },
    {
        title: FILING_AREA_TITLES[FILING_AREA_INVESTMENT_SCHEME],
        filingArea: FILING_AREA_INVESTMENT_SCHEME,
        fileTypes: [FileType.InvestmentSchemeCertificate, FileType.Certificate, FileType.Other],
    },
]

const Files = ({ customer, customerFiles, customerSelfAssessments, selfAssessment, selfAssessmentFiles }) => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { identityVerifications } = useCustomersIdentityVerifications({ customerId: customer.id })

    const handleChangeTaxYear = ({ value: selfAssessmentId }) => {
        const { id: customerId } = customer

        const url = `${PATH_CUSTOMERS}/${customerId}/files/${selfAssessmentId}`

        history.push(url)
    }

    const handleDownloadAll = () => {
        const { id: selfAssessmentId } = selfAssessment

        dispatch(downloadSelfAssessmentFiles({ selfAssessmentId }))
    }

    const isFilingAreaOpenByDefault = (filingAreaId) => {
        const hash = _get(history, ['location', 'hash'])
        return hash === `#${filingAreaId}`
    }

    const renderIdentityVerificationFiles = () => {
        if (!identityVerifications.length) return null

        return (
            <>
                {identityVerifications.map((identityVerification, i) => {
                    const { status, updatedDateUTC } = identityVerification

                    const files = _get(customerFiles, ['items'], []).filter(
                        ({ identityVerificationId }) => identityVerificationId === identityVerification.id
                    )

                    const hasFiles = files.length > 0
                    const isLatest = i === 0

                    if (!isLatest && !hasFiles) return null // should display only ones with files and/or the latest

                    const title = `Identity verification (${status}, last updated ${formatDate(updatedDateUTC)})`

                    return (
                        <FilesSection
                            key={identityVerification.id}
                            customer={customer}
                            files={files}
                            identityVerification={identityVerification}
                            title={title}
                            uploadTypes={[
                                { fileType: FileType.IdDocument, filingArea: null },
                                { fileType: FileType.ProofOfAddress, filingArea: null },
                            ]}
                        />
                    )
                })}
            </>
        )
    }

    const renderTaxRegistrationFiles = () => {
        const files = _get(customerFiles, ['items'], []).filter(
            ({ fileType, customerId }) => fileType === FileType.TaxRegistrationSubmission && customerId === customer.id
        )

        return (
            <FilesSection
                customer={customer}
                files={files}
                title="UTR registration"
                uploadTypes={[{ fileType: FileType.TaxRegistrationSubmission, filingArea: null }]}
            />
        )
    }

    const renderEmploymentSections = (section) => {
        const filingArea = _get(section, ['filingArea'], null)
        const title = _get(section, ['title'], null)

        const { id: selfAssessmentId } = selfAssessment

        return _get(selfAssessment, ['employments'], []).map((employment) => {
            const { id: employmentId, employerName } = employment

            const files = selfAssessmentFiles.filter((file) => {
                if (!file.employmentId) return false
                if (file.employmentId !== employmentId) return false
                return file.selfAssessmentId === selfAssessmentId && file.filingArea === filingArea
            })

            return (
                <FilesSection
                    key={employment.id}
                    employment={employment}
                    files={files}
                    isOpenByDefault={isFilingAreaOpenByDefault(filingArea)}
                    selfAssessment={selfAssessment}
                    title={`${title} (${employerName})`}
                    uploadTypes={_get(section, ['fileTypes'], []).map((type) => ({
                        filingArea: section.filingArea,
                        allowMultipleUploads: section.allowMultipleUploads,
                        fileType: type,
                    }))}
                />
            )
        })
    }

    const renderSection = (section) => {
        const { id: selfAssessmentId } = selfAssessment

        const filingArea = _get(section, ['filingArea'], null)
        const title = _get(section, ['title'], null)

        const files = selfAssessmentFiles.filter((file) => {
            if (file.employmentId) return false
            return file.selfAssessmentId === selfAssessmentId && file.filingArea === filingArea
        })

        return (
            <FilesSection
                files={files}
                isOpenByDefault={isFilingAreaOpenByDefault(filingArea)}
                selfAssessment={selfAssessment}
                title={title}
                uploadTypes={_get(section, ['fileTypes'], []).map((type) => ({
                    filingArea: section.filingArea,
                    allowMultipleUploads: section.allowMultipleUploads,
                    fileType: type,
                }))}
            />
        )
    }

    const selfAssessmentId = selfAssessment?.id

    const shouldDisplayCGTTool =
        selfAssessment?.capitalGains?.shareGains ||
        selfAssessment?.capitalGains?.shareLosses ||
        selfAssessment?.capitalGains?.cryptoGains ||
        selfAssessment?.capitalGains?.cryptoLosses

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            {selfAssessmentId && (
                <>
                    <AccountantPortalChrome.Customer.Page.Header>
                        <AccountantPortalChrome.Customer.Page.HeaderGroup>
                            <AccountantPortalChrome.Customer.Page.Title>
                                Files
                            </AccountantPortalChrome.Customer.Page.Title>
                            <DropdownSelect
                                name="selfAssessmentId"
                                value={selfAssessmentId}
                                options={customerSelfAssessments.map(
                                    ({ id: optionSelfAssessmentId, taxYear: optionTaxYear }) => ({
                                        value: optionSelfAssessmentId,
                                        title: TAX_YEARS[optionTaxYear],
                                    })
                                )}
                                onChange={handleChangeTaxYear}
                            />
                        </AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <AccountantPortalChrome.Customer.Page.HeaderGroup>
                            <Flexer gap="12px" alignCenter>
                                <Spacer>
                                    <RequestDocumentsContainer
                                        customer={customer}
                                        customerSelfAssessments={customerSelfAssessments}
                                        selfAssessmentId={selfAssessmentId}
                                    />
                                </Spacer>
                                <Spacer>
                                    <Button onClick={handleDownloadAll}>Download all</Button>
                                </Spacer>
                            </Flexer>
                        </AccountantPortalChrome.Customer.Page.HeaderGroup>
                    </AccountantPortalChrome.Customer.Page.Header>

                    {shouldDisplayCGTTool && (
                        <Spacer margin="30px 0">
                            <Notice background={colors.blueLighter}>
                                To calculate Capital Gains from a summary of transactions try our{' '}
                                <Link to={PATH_TOOLS_CGT}>Capital Gains Calculator (beta)</Link>. Share your feedback in
                                Slack.
                            </Notice>
                        </Spacer>
                    )}

                    <Spacer margin="30px 0">
                        {sections.map((section) => (
                            <Fragment key={section.filingArea}>
                                {section.filingArea === FILING_AREA_EMPLOYMENT && renderEmploymentSections(section)}

                                {renderSection(section)}
                            </Fragment>
                        ))}
                    </Spacer>
                </>
            )}

            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.Title>General</AccountantPortalChrome.Customer.Page.Title>
            </AccountantPortalChrome.Customer.Page.Header>

            <Spacer margin="30px 0">
                {renderIdentityVerificationFiles()}
                {renderTaxRegistrationFiles()}
            </Spacer>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}

Files.propTypes = {
    customer: PropTypes.object.isRequired,
    customerFiles: PropTypes.object.isRequired,
    customerSelfAssessments: PropTypes.array.isRequired,
    selfAssessment: PropTypes.object,
    selfAssessmentFiles: PropTypes.array.isRequired,
}

Files.defaultProps = {
    selfAssessment: undefined,
}

export default Files
