import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useUpdateCompanyMutation, UpdateCompanyParams } from '../api'

export const useUpdateCompany = () => {
    const [action, { isLoading }] = useUpdateCompanyMutation()

    const updateCompany = useCallback(
        (params: UpdateCompanyParams) =>
            action(params).then(matchSuccessResponse(() => addSuccessToast({ body: 'Company updated successfully' }))),
        [action]
    )

    return {
        updateCompany,
        isLoading,
    }
}
