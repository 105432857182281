import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Label } from '@scouts/ui'

import { PATH_CUSTOMERS, SELF_ASSESSMENT_STATUS_TITLES, TAX_CONSULTATION_STATUS_TITLES, TAX_YEARS } from '@/constants'
import { backgroundConsultation, backgroundSelfAssessment, backgroundTaxRegistration } from '@/helpers/colors'
import { TaxRegistrationStatusTitles } from '@/models'

const Container = styled.div`
    font-size: ${font.smaller};
`

const Processes = styled.div`
    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

const ProcessRow = styled.button`
    text-align: left;
    display: block;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const ProcessRowText = styled.span`
    margin-left: 12px;
`

const ProcessesCompleted = styled.div`
    display: flex;
    align-items: center;
`

const ProcessesCompletedList = styled.div`
    display: flex;

    ${ProcessRow} {
        margin: 0 6px 0 0;
    }

    > :nth-child(n + 3) {
        display: none;
    }
`

const MoreButton = styled.button`
    color: ${colors.neutralDarker};
    margin-left: 6px;
`

const Title = styled.div`
    color: ${({ color }) => color || colors.black};
    font-size: ${font.smaller};

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

const CustomerServices = ({
    activeProductBundles,
    activeSelfAssessments,
    activeTaxConsultations,
    activeTaxRegistrations,
    completedSelfAssessments,
    completedTaxConsultations,
    completedTaxRegistrations,
    customerId,
    shouldHideEmptySections,
}) => {
    const history = useHistory()

    const activeCount =
        activeSelfAssessments.length +
        activeProductBundles.length +
        activeTaxConsultations.length +
        activeTaxRegistrations.length
    const completedCount =
        completedSelfAssessments.length + completedTaxConsultations.length + completedTaxRegistrations.length

    const hasActiveProcesses = activeCount > 0
    const hasCompletedProcesses = completedCount > 0
    const hasMoreThanTwoCompleted = completedCount > 2

    const customerOverviewUrl = `${PATH_CUSTOMERS}/${customerId}`

    const renderSelfAssesment = ({ item, isCompleted = false }) => {
        const { id, status, taxYear } = item
        const selfAssessmentUrl = `${PATH_CUSTOMERS}/${customerId}/assessment/${id}`
        return (
            <ProcessRow key={`selfAssessment-${id}`} onClick={() => history.push(selfAssessmentUrl)}>
                <Label
                    background={isCompleted ? colors.neutralLightest : backgroundSelfAssessment(status)}
                    color={isCompleted ? colors.neutralDarker : colors.black}
                >
                    {TAX_YEARS[taxYear]}
                </Label>
                {!isCompleted && <ProcessRowText>{SELF_ASSESSMENT_STATUS_TITLES[status]}</ProcessRowText>}
            </ProcessRow>
        )
    }

    const renderProductBundle = ({ item }) => {
        const { id } = item
        return (
            <ProcessRow key={`bundle-${id}`} onClick={() => history.push(customerOverviewUrl)}>
                <Label background={colors.mintLighter}>Bundle</Label>
                <ProcessRowText>Purchased a bundle</ProcessRowText>
            </ProcessRow>
        )
    }

    const renderTaxConsultation = ({ item, isCompleted = false }) => {
        const { id, status } = item
        const taxConsultationUrl = `${PATH_CUSTOMERS}/${customerId}/consultations/${id}`
        return (
            <ProcessRow key={`taxConsultation-${id}`} onClick={() => history.push(taxConsultationUrl)}>
                <Label
                    background={isCompleted ? colors.neutralLightest : backgroundConsultation(status)}
                    color={isCompleted ? colors.neutralDarker : colors.black}
                >
                    Consultation
                </Label>
                {!isCompleted && <ProcessRowText>{TAX_CONSULTATION_STATUS_TITLES[status]}</ProcessRowText>}
            </ProcessRow>
        )
    }

    const renderTaxRegistration = ({ item, isCompleted = false }) => {
        const { id, status } = item
        return (
            <ProcessRow key={`taxRegistration-${id}`} onClick={() => history.push(customerOverviewUrl)}>
                <Label
                    background={isCompleted ? colors.neutralLightest : backgroundTaxRegistration(status)}
                    color={isCompleted ? colors.neutralDarker : colors.black}
                >
                    UTR
                </Label>
                {!isCompleted && <ProcessRowText>{TaxRegistrationStatusTitles[status]}</ProcessRowText>}
            </ProcessRow>
        )
    }

    const renderActiveItemsTitle = () => {
        if (!hasActiveProcesses && shouldHideEmptySections) return null

        return (
            <Title color={!hasActiveProcesses ? colors.neutralDarker : colors.black}>
                {hasActiveProcesses ? activeCount : 'None'} active
            </Title>
        )
    }
    const renderCompletedItemsTitle = () => {
        if (!hasCompletedProcesses && shouldHideEmptySections) return null

        return <Title color={colors.neutralDarker}>{hasCompletedProcesses ? completedCount : 'None'} completed</Title>
    }

    return (
        <Container>
            {renderActiveItemsTitle()}
            {hasActiveProcesses && (
                <Processes>
                    {activeProductBundles.map((item) => renderProductBundle({ item }))}
                    {activeSelfAssessments.map((item) => renderSelfAssesment({ item }))}
                    {activeTaxConsultations.map((item) => renderTaxConsultation({ item }))}
                    {activeTaxRegistrations.map((item) => renderTaxRegistration({ item }))}
                </Processes>
            )}

            {renderCompletedItemsTitle()}
            {hasCompletedProcesses && (
                <ProcessesCompleted>
                    <ProcessesCompletedList>
                        {completedSelfAssessments.map((item) => renderSelfAssesment({ item, isCompleted: true }))}
                        {completedTaxConsultations.map((item) => renderTaxConsultation({ item, isCompleted: true }))}
                        {completedTaxRegistrations.map((item) => renderTaxRegistration({ item, isCompleted: true }))}
                    </ProcessesCompletedList>

                    {hasMoreThanTwoCompleted && (
                        <MoreButton onClick={() => history.push(customerOverviewUrl)}>
                            {completedCount - 2} more
                        </MoreButton>
                    )}
                </ProcessesCompleted>
            )}
        </Container>
    )
}

CustomerServices.propTypes = {
    activeProductBundles: PropTypes.array.isRequired,
    activeSelfAssessments: PropTypes.array.isRequired,
    activeTaxConsultations: PropTypes.array.isRequired,
    activeTaxRegistrations: PropTypes.array.isRequired,
    completedSelfAssessments: PropTypes.array.isRequired,
    completedTaxConsultations: PropTypes.array.isRequired,
    completedTaxRegistrations: PropTypes.array.isRequired,
    customerId: PropTypes.number,
    shouldHideEmptySections: PropTypes.bool,
}

CustomerServices.defaultProps = {
    customerId: undefined,
    shouldHideEmptySections: false,
}

export default CustomerServices
