import { generateApiPath } from '@scouts/helpers'

import { CustomerDTO, IdentityVerificationDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

export interface GetCustomersIdentityVerificationsParams {
    customerId: CustomerDTO['id']
}

export interface GetIdentityVerificationParams {
    identityVerificationId: IdentityVerificationDTO['id']
}

export interface CreateIdentityVerificationParams {
    customerId: CustomerDTO['id']
    disableNotifications?: false
}

export interface UpdateIdentityVerificationParams {
    identityVerificationId: IdentityVerificationDTO['id']
    data: IdentityVerificationDTO
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomersIdentityVerifications: builder.query<
            IdentityVerificationDTO[],
            GetCustomersIdentityVerificationsParams
        >({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.IDENTITY_VERIFICATIONS.GET.CUSTOMERS, { customerId }),
            }),
            providesTags: [Tags.IdentityVerifications],
        }),
        getIdentityVerification: builder.query<IdentityVerificationDTO, GetIdentityVerificationParams>({
            query: ({ identityVerificationId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.IDENTITY_VERIFICATIONS.GET.SINGLE, { identityVerificationId }),
            }),
            providesTags: [Tags.IdentityVerifications],
        }),
        createIdentityVerification: builder.mutation<IdentityVerificationDTO, CreateIdentityVerificationParams>({
            query: ({ customerId, disableNotifications }) => ({
                method: 'POST',
                url: ENDPOINTS.IDENTITY_VERIFICATIONS.POST,
                body: {
                    customerId,
                    disableNotifications: disableNotifications || false,
                },
            }),
            invalidatesTags: [Tags.IdentityVerifications],
        }),
        updateIdentityVerification: builder.mutation<IdentityVerificationDTO, UpdateIdentityVerificationParams>({
            query: ({ identityVerificationId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.IDENTITY_VERIFICATIONS.PUT, { identityVerificationId }),
                body: data,
            }),
            invalidatesTags: [Tags.IdentityVerifications],
        }),
    }),
})

export const {
    useGetCustomersIdentityVerificationsQuery,
    useCreateIdentityVerificationMutation,
    useGetIdentityVerificationQuery,
    useUpdateIdentityVerificationMutation,
} = extendedApi
