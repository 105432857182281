import { useRef, useState, ChangeEvent } from 'react'

import { DropdownButton } from '@scouts/ui'

import { TaxReliefClaimDomain } from '@/domain'
import { TaxReliefClaimDTO, TaxReliefClaimFileDTO } from '@/models'
import { useUploadTaxReliefClaimFile } from '@/store/tax-relief-claims'

interface TaxReliefClaimUploadFilesProps {
    taxReliefClaimId: TaxReliefClaimDTO['id']
}

export const TaxReliefClaimUploadFiles = ({ taxReliefClaimId }: TaxReliefClaimUploadFilesProps) => {
    const formInput = useRef<HTMLInputElement>(null)
    const { uploadTaxReliefFile, isLoading } = useUploadTaxReliefClaimFile()
    const [uploadableFileType, setUploadableFileType] = useState<string | null>(null)

    const selectFile = (fileType: string) => {
        setUploadableFileType(fileType)

        formInput.current?.click()
    }

    const handleUploadFiles = (filesToUpload: File[]) => {
        filesToUpload.forEach((file) => {
            uploadTaxReliefFile({
                file,
                params: { taxReliefClaimId, fileType: uploadableFileType as TaxReliefClaimFileDTO['fileType'] },
            })
        })
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleUploadFiles(Array.from(event.target.files ?? []))
        if (formInput && formInput.current) formInput.current.value = ''
    }

    const actions = Object.entries(TaxReliefClaimDomain.fileTypeTitles).map(([key, value]) => ({
        title: value.title,
        onClick: () => selectFile(key),
    }))

    return (
        <>
            <DropdownButton alignRight actions={actions} isLoading={isLoading}>
                Upload
            </DropdownButton>

            <input ref={formInput} type="file" onChange={handleChange} hidden multiple />
        </>
    )
}
