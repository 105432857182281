import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { font, ButtonLink, Table } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { formatTimeFromNow } from '@/helpers'
import { useCustomersAccountantMeetings } from '@/store/accountant-meetings/hooks/use-customers-accountant-meetings'

import { AccountantMeetingsStatusLabel } from '../../accountant-meetings/AccountantMeetingsStatusLabel'

export const CustomerOverviewAccountantMeetings = ({ customerId }: { customerId: number }) => {
    const [shouldShowAllItems, setShouldShowAllItems] = useState<boolean>(false)

    const { accountantMeetings } = useCustomersAccountantMeetings({ customerId })

    const toggleShowAllItems = () => {
        setShouldShowAllItems(!shouldShowAllItems)
    }

    const hasAccountantMeetings = accountantMeetings && accountantMeetings?.length > 0

    if (!hasAccountantMeetings) return null

    const shouldLimitItems = hasAccountantMeetings && accountantMeetings.length > 3

    const renderableAccountantMeetings =
        shouldLimitItems && !shouldShowAllItems ? accountantMeetings.slice(0, 1) : accountantMeetings

    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="100%">Meetings</Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>

            <Table.Body>
                {renderableAccountantMeetings.map((accountantMeeting) => {
                    const { id: accountantMeetingId, status, statusChangedDateUTC } = accountantMeeting

                    const accountantMeetingnUrl = `${PATH_CUSTOMERS}/${customerId}/meetings/${accountantMeetingId}`
                    const statusLastChanged = formatTimeFromNow(statusChangedDateUTC)

                    return (
                        <Table.Body.Row key={accountantMeetingId}>
                            <Table.Body.Cell width="50%">
                                <Table.Body.Cell.ContentLink Link={Link} to={accountantMeetingnUrl}>
                                    <AccountantMeetingsStatusLabel status={status} />
                                </Table.Body.Cell.ContentLink>
                            </Table.Body.Cell>

                            <Table.Body.Cell width="50%">
                                <Table.Body.Cell.ContentLink Link={Link} to={accountantMeetingnUrl} alignRight>
                                    <LastUpdated>{statusLastChanged}</LastUpdated>
                                </Table.Body.Cell.ContentLink>
                            </Table.Body.Cell>
                        </Table.Body.Row>
                    )
                })}
            </Table.Body>

            {shouldLimitItems && (
                <Table.Footer>
                    <Table.Footer.Row>
                        <Table.Footer.Cell width="100%" alignRight>
                            <ButtonLink onClick={toggleShowAllItems}>
                                {shouldShowAllItems ? 'View less' : `View more (${accountantMeetings.length})`}
                            </ButtonLink>
                        </Table.Footer.Cell>
                    </Table.Footer.Row>
                </Table.Footer>
            )}
        </Table>
    )
}

const LastUpdated = styled.div`
    font-size: ${font.small};
`
