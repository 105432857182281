import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import { receiveSuccessAlerts, CREATE_EMPLOYMENT, PULL_EMPLOYMENTS_FROM_HMRC } from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* employmentsSagas() {
    yield takeEvery(CREATE_EMPLOYMENT, createEmployment)
    yield takeLatest(PULL_EMPLOYMENTS_FROM_HMRC, pullEmploymentsFromHMRC)
}

function* createEmployment(action) {
    try {
        yield put(showLoading())
        yield apiCall('employments', 'post', action.data)
        yield put(receiveSuccessAlerts('Employment information added'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* pullEmploymentsFromHMRC(action) {
    try {
        yield put(showLoading())
        yield apiCall(`hmrc/employments?selfAssessmentId=${action.data.selfAssessmentId}`, 'post', action.data)
        yield put(receiveSuccessAlerts('Employments imported from HMRC'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
