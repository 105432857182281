import styled from 'styled-components'

import { colors, font, ButtonLink, DropdownButton, Label, Table } from '@scouts/ui'

import { REFUND_REASONS_TITLES } from '@/constants'
import { formatCurrency, formatDate } from '@/helpers'
import { PaymentStatus, ProductBundleDetailsDTO } from '@/models'

import { useUser } from '@/hooks/use-user'

interface CustomerOverviewProductBundleProps {
    productBundle: ProductBundleDetailsDTO
    openRefundModal: (id: ProductBundleDetailsDTO['id']) => void
    openLinkedSelfAssessmentModal: (id: ProductBundleDetailsDTO['id']) => void
    openDeleteModal: (id: ProductBundleDetailsDTO['id']) => void
    openDetailsModal: (id: ProductBundleDetailsDTO['id']) => void
}

const getPrice = ({
    paymentStatus,
    hasPaid,
    price,
}: {
    paymentStatus: ProductBundleDetailsDTO['paymentStatus']
    hasPaid: ProductBundleDetailsDTO['hasPaid']
    price: ProductBundleDetailsDTO['price']
}) => {
    switch (true) {
        case hasPaid && price === 0:
            return <Label background={colors.mintLighter}>Free</Label>
        case hasPaid && paymentStatus !== PaymentStatus.Refunded:
            return <Label background={colors.mintLighter}>{formatCurrency(price)}</Label>
        case hasPaid && paymentStatus === PaymentStatus.Refunded:
            return <Label background={colors.yellow}>Free</Label>
        case !hasPaid && paymentStatus === PaymentStatus.Refunded:
            return <Label background={colors.red}>Cancelled</Label>
        default:
            return <Label background={colors.neutralLightest}>{formatCurrency(price)}</Label>
    }
}

const getPaymentStatus = ({
    paymentStatus,
    hasPaid,
    paymentDateUTC,
}: {
    paymentStatus: ProductBundleDetailsDTO['paymentStatus']
    hasPaid: ProductBundleDetailsDTO['hasPaid']
    paymentDateUTC: ProductBundleDetailsDTO['paymentDateUTC']
}) => {
    if (hasPaid || paymentStatus === PaymentStatus.Refunded) {
        return paymentDateUTC ? `Paid ${formatDate(paymentDateUTC)}` : 'Paid'
    }

    return 'Not paid'
}

export const CustomerOverviewProductBundle = ({
    productBundle,
    openRefundModal,
    openDeleteModal,
    openDetailsModal,
    openLinkedSelfAssessmentModal,
}: CustomerOverviewProductBundleProps) => {
    const { isAdminUser } = useUser()
    const {
        hasPaid,
        id: productBundleId,
        paymentStatus,
        price,
        refundDateUTC,
        refundReason,
        paymentDateUTC,
    } = productBundle

    const { refundReasonType } = refundReason || {}

    const isRefunded = paymentStatus === PaymentStatus.Refunded
    const isRefundActionDisabled = (!hasPaid && price > 0) || isRefunded

    const actions = [
        {
            title: 'Refund',
            onClick: () => {
                openRefundModal(productBundleId)
            },
            disabled: isRefundActionDisabled,
        },
        {
            title: 'Edit linked tax return',
            onClick: () => {
                openLinkedSelfAssessmentModal(productBundleId)
            },
        },
        {
            title: 'Delete',
            onClick: () => {
                openDeleteModal(productBundleId)
            },
        },
    ]

    const shouldDisplayActions = isAdminUser

    return (
        <Table.Body.Row key={productBundleId}>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.Content>
                    <Status>
                        <Description>{getPaymentStatus({ hasPaid, paymentStatus, paymentDateUTC })}</Description>
                        {getPrice({ hasPaid, price, paymentStatus })}
                    </Status>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            {isRefunded ? (
                <Table.Body.Cell width="50%">
                    <Table.Body.Cell.Content>
                        <Status>
                            <Label background={colors.red}>Refunded</Label>
                            <Description>{formatDate(refundDateUTC)}</Description>
                            {!!refundReasonType && (
                                <Description color={colors.neutralDarker}>
                                    {REFUND_REASONS_TITLES[refundReasonType]}
                                </Description>
                            )}
                        </Status>
                    </Table.Body.Cell.Content>
                </Table.Body.Cell>
            ) : (
                <Table.Body.Cell width="50%" />
            )}

            <Table.Body.Cell width="25%">
                <Table.Body.Cell.Content alignRight>
                    <ActionsWrapper>
                        <ButtonLink onClick={() => openDetailsModal(productBundleId)}>Details</ButtonLink>
                        {shouldDisplayActions && (
                            <DropdownButton alignRight actions={actions}>
                                Actions
                            </DropdownButton>
                        )}
                    </ActionsWrapper>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Status = styled.div`
    display: flex;
    align-items: center;

    > :not(:last-child) {
        margin-right: 12px;
    }
`

const ActionsWrapper = styled.div`
    > :first-child:not(:only-child) {
        margin-right: 12px;
    }
`

const Description = styled.div<{ color?: string }>`
    color: ${({ color }) => color || colors.black};
    font-size: ${font.small};
    line-height: 1.3;
`
