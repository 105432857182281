import { useToggle } from '@scouts/helpers'
import { ButtonLink } from '@scouts/ui'

import { CustomerDTO } from '@/models'

import { CustomerDiscountsAddModal } from './CustomerDiscountsAddModal'

interface CustomerDiscountsAddProps {
    customerId: CustomerDTO['id']
}

export const CustomerDiscountsAdd = ({ customerId }: CustomerDiscountsAddProps) => {
    const [isModalOpen, open, close] = useToggle()

    return (
        <>
            <ButtonLink icon="plus" onClick={open}>
                Apply discount
            </ButtonLink>

            {isModalOpen && <CustomerDiscountsAddModal closeModal={close} customerId={customerId} />}
        </>
    )
}
