import { RECEIVE_CUSTOMER_PRODUCT_BUNDLES, REMOVE_PRODUCT_BUNDLE, UPDATE_PRODUCT_BUNDLE } from '../actions'
import { removeItemsById, updateOrAddItemsById } from '../helpers'

const defaultState = {
    isFetching: false,
    shouldFetch: true,
    list: [],
    details: [],
}

export default (state = defaultState, action) => {
    const { type, data } = action

    switch (type) {
        case RECEIVE_CUSTOMER_PRODUCT_BUNDLES: {
            const items = updateOrAddItemsById({ currentItems: state.list, receivedItems: data })

            return { ...state, list: items }
        }

        case UPDATE_PRODUCT_BUNDLE: {
            const list = updateOrAddItemsById({ currentItems: state.list, receivedItems: data, isExtendingItems: true })
            const details = updateOrAddItemsById({
                currentItems: state.details,
                receivedItems: data,
                isExtendingItems: true,
            })

            return { ...state, list, details }
        }

        case REMOVE_PRODUCT_BUNDLE: {
            const { id } = data

            const list = removeItemsById({ currentItems: state.list, id })
            const details = removeItemsById({ currentItems: state.details, id })

            return { ...state, list, details }
        }

        default:
            return state
    }
}
