import styled from 'styled-components'

import { colors, font, media, radius, shadows, Flexer, Icon, IconFileDownload } from '@scouts/ui'

interface ReportCardProps {
    onReportClick: ({ month, year }: { month: number | string; year: number | string }) => void
    dates: { month: number | string; year: number | string; title: string }[]
}

export const ReportCard = ({ onReportClick, dates }: ReportCardProps) => {
    return (
        <Flexer column gap="12px">
            {dates.map((date, index) => (
                <Action key={index} onClick={() => onReportClick({ month: date.month, year: date.year })}>
                    {date.title}
                    <Icon Component={IconFileDownload} color={colors.blue} />
                </Action>
            ))}
        </Flexer>
    )
}

const Action = styled.button`
    color: ${colors.blue};
    line-height: 1.5;
    text-align: left;
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 21px 24px 22px;
    background: ${colors.blueLighter};
    border-radius: ${radius.medium};

    ${media.pointer} {
        cursor: pointer;

        &:hover {
            box-shadow: ${shadows.medium};
        }

        &:active {
            box-shadow: none;
        }
    }
`
