import { font, Line } from '@scouts/ui'

import { useUser } from '@/hooks/use-user'

// @TODO: AccountantDTO
interface VerificationAccountantProps {
    accountantId: number
    accountantFullName: string | null
}

export const VerificationAccountant = ({ accountantId, accountantFullName }: VerificationAccountantProps) => {
    const { isAdminUser, id: userId } = useUser()

    const isVerifiedByAnother = accountantId !== userId

    if (!isVerifiedByAnother || !accountantFullName) return null

    return (
        <Line size={font.small}>
            Verified by {isAdminUser ? '' : 'previous accountant '}
            {`${accountantFullName}`}
        </Line>
    )
}
