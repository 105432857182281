import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, Checkbox } from '@scouts/ui'

const Form = styled.form``

const Item = styled.div`
    margin: 3px 0;
`

const Actions = styled.div`
    display: flex;
    margin-top: 12px;
`

const Action = styled.div`
    margin-right: 6px;
`

const propTypes = {
    applyFilter: PropTypes.func.isRequired,
    closeDropdown: PropTypes.func,
    name: PropTypes.string.isRequired,
    removeFilter: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
}

const defaultProps = {
    closeDropdown: null,
}

const FilterItemDropdownCheckboxes = (props) => {
    const { name, values, applyFilter, removeFilter, closeDropdown } = props

    const [localValues, setLocalValues] = useState({})

    useEffect(() => {
        const activeItems = {}

        values
            .filter((o) => o.checked)
            .forEach(({ value, checked }) => {
                activeItems[value] = checked
            })

        setLocalValues(activeItems)
    }, [values])

    const handleSubmit = (e) => {
        e.preventDefault()

        const filter = {
            [name]: Object.keys(localValues).filter((key) => localValues[key]),
        }

        applyFilter(filter)

        if (closeDropdown) closeDropdown()
    }

    const handleChange = ({ value, checked }) => {
        setLocalValues({ ...localValues, ...{ [value]: checked } })
    }

    return (
        <Form onSubmit={handleSubmit}>
            {values.map(({ label, value }) => (
                <Item key={value}>
                    <Checkbox
                        isSmall
                        name={name}
                        value={value}
                        label={label}
                        checked={_get(localValues, [value], false)}
                        onChange={handleChange}
                    />
                </Item>
            ))}

            <Actions>
                <Action>
                    <Button type="submit" size="small">
                        Apply
                    </Button>
                </Action>
                <Action>
                    <Button isSecondary size="small" onClick={removeFilter}>
                        Remove
                    </Button>
                </Action>
            </Actions>
        </Form>
    )
}

FilterItemDropdownCheckboxes.propTypes = propTypes

FilterItemDropdownCheckboxes.defaultProps = defaultProps

export default FilterItemDropdownCheckboxes
