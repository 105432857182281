import {
    Button,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { CustomerDTO } from '@/models'

import HmrcPreviewData from '@/components/HmrcPreviewData'

interface AgentAuthHmrcDataPreviewModalProps {
    onClose: () => void
    uniqueTaxpayerReference: CustomerDTO['uniqueTaxpayerReference']
}

export const AgentAuthHmrcDataPreviewModal = ({
    onClose,
    uniqueTaxpayerReference,
}: AgentAuthHmrcDataPreviewModalProps) => (
    <Modal onClose={onClose} isWide>
        <ModalHeader>
            <ModalTitle>Preview HMRC data</ModalTitle>
        </ModalHeader>

        <ModalBody>
            <HmrcPreviewData uniqueTaxpayerReference={uniqueTaxpayerReference} />
        </ModalBody>

        <ModalFooter>
            <ModalActions>
                <Button isSecondary onClick={onClose}>
                    Close
                </Button>
            </ModalActions>
        </ModalFooter>
    </Modal>
)
