import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import Tour from 'reactour'
import { createGlobalStyle } from 'styled-components'

import { colors, Button } from '@scouts/ui'

import Beacon from './Beacon'
import { hasSeenTour, storeSeenTour } from './helpers'

const GlobalTourStyles = createGlobalStyle`
    button[data-tour-elem='right-arrow'] {
        margin-left: auto;
    }

    .global-tour-mask {
        opacity: 0.4;
    }
`

const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden'
}

const enableBodyScroll = () => {
    document.body.style.overflow = ''
}

class FeatureTour extends Component {
    constructor() {
        super()

        this.state = {
            isOpen: false,
            hasSeen: false,
            stepIndex: 0,
        }
    }

    static getDerivedStateFromError() {
        enableBodyScroll()

        return { stepIndex: 0, isOpen: false }
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setContext('catchingComponent', 'FeatureTour')
                scope.setContext(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
    }

    componentWillUnmount() {
        enableBodyScroll()
    }

    toggleOpen = (isOpen) => {
        this.setState({ isOpen })
    }

    toggleSeen = (hasSeen) => {
        const { id } = this.props
        storeSeenTour(id)
        this.setState({ hasSeen })
    }

    handleBeaconClick = (event) => {
        const { onBeaconClick } = this.props

        this.toggleOpen(true)
        onBeaconClick(event)
    }

    handleStepChange = (currentStep) => {
        this.setState({ stepIndex: currentStep })
    }

    handleTourClose = () => {
        const { steps } = this.props
        const { stepIndex } = this.state

        const isLastStep = stepIndex === steps.length - 1
        if (isLastStep) {
            this.toggleSeen(true)
        }

        this.toggleOpen(false)
    }

    render() {
        const { steps, maskSpace, shouldShowFeatureTour, hasBeaconTooltip } = this.props
        const { isOpen, hasSeen, stepIndex } = this.state

        if (!shouldShowFeatureTour) return null

        return (
            <>
                <GlobalTourStyles />
                {!isOpen && !hasSeen && <Beacon hasTooltip={hasBeaconTooltip} onClick={this.handleBeaconClick} />}
                <Tour
                    accentColor={colors.blue}
                    goToStep={stepIndex}
                    getCurrentStep={this.handleStepChange}
                    isOpen={isOpen}
                    lastStepNextButton={<Button as="a">Got it</Button>}
                    maskSpace={maskSpace}
                    nextButton={<Button as="a">Next</Button>}
                    onAfterOpen={disableBodyScroll}
                    onBeforeClose={enableBodyScroll}
                    onRequestClose={this.handleTourClose}
                    prevButton={steps.length === 1 ? <></> : undefined}
                    scrollDuration={50}
                    showNavigation={steps.length > 1}
                    showNavigationNumber={false}
                    showNumber={false}
                    steps={steps}
                    disableFocusLock
                    maskClassName="global-tour-mask"
                />
            </>
        )
    }
}
FeatureTour.propTypes = {
    hasBeaconTooltip: PropTypes.bool,
    id: PropTypes.string.isRequired,
    maskSpace: PropTypes.number,
    onBeaconClick: PropTypes.func,
    shouldShowFeatureTour: PropTypes.bool.isRequired,
    steps: PropTypes.array.isRequired,
}
FeatureTour.defaultProps = {
    hasBeaconTooltip: false,
    maskSpace: 10,
    onBeaconClick: () => {},
}

const mapStateToProps = (state, ownProps) => {
    const { user } = state
    const { id } = ownProps

    return {
        shouldShowFeatureTour: !!user.isOnTrial && !hasSeenTour(id),
    }
}

export default connect(mapStateToProps)(FeatureTour)
