export const AddressType = {
    Default: 'Default',
    Previous: 'Previous',
    Business: 'Business',
    Home: 'Home',
} as const

export type AddressTypeTypes = (typeof AddressType)[keyof typeof AddressType]

export interface CustomerAddressDTO {
    id: number
    customerId: number
    addressType: AddressTypeTypes
    streetAddress: string | null
    city: string | null
    postcode: string | null
    country: string | null
    moveInDate: string | null
}
