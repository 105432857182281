import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from '@scouts/ui'

import { featureFlags } from '@/feature-flags'

import FeatureBeacon from './FeatureBeacon'
import FeatureTour from './FeatureTour'
import { hasSeenTour } from './helpers'

const StepContent = styled.span`
    padding-right: 18px;
    line-height: 1.5;
`

const createTourSteps = (steps) =>
    steps.map((step) => {
        const style = { backgroundColor: colors.redLighter, ...step.style }

        return { ...step, style }
    })

const STEPS_STATUS = createTourSteps([
    {
        selector: '.tour-status',
        content: (
            <StepContent>
                Here you can keep your clients updated on their tax return quickly and easily. Updating the client's
                status sends out an automated email on your behalf letting the customer know where they are in the
                process.
                <br />
                <br />
                For instance, updating the status from <b>Submitted</b> to <b>In Review</b> prompts the system to
                automatically send a message letting the client know that you've started looking at their return and
                that you'll be in touch if you were to require any further information.
            </StepContent>
        ),
        observe: '.tour-status > div',
    },
])

const STEPS_REQUEST_DOCS = createTourSteps([
    {
        selector: '.tour-request-docs-modal',
        content: (
            <StepContent>
                Here you can request any information by selecting the check boxes.
                <br />
                Based on these selections, TaxScouts will then automatically draft an email which can be further edited
                before sending.
            </StepContent>
        ),
        resizeObservables: ['.tour-request-docs-modal'],
    },
    {
        selector: '.tour-request-docs-continue',
        content: (
            <StepContent>
                Clicking here you'll see an email template ready to be edited and sent.
                <br />
                <br />
                After sending, this message can be later viewed in the Messages tab.
            </StepContent>
        ),
        resizeObservables: ['.tour-request-docs-modal'],
    },
])

const STEPS_UTR = createTourSteps([
    {
        selector: '.tour-utr',
        content: (
            <StepContent>
                Here you'll find all the information that's needed to register the client for Self Assessment with HMRC.
                <br />
                <br />
                We offer this as a value-add service for clients charging £25.
                <br />
                £15 of this goes directly to you, don't forget to add these clients to your invoice.
            </StepContent>
        ),
    },
    {
        selector: '.tour-utr-status',
        content: (
            <StepContent>
                Once you've applied for the client's UTR with HMRC, update the status to <b>Submitted to HMRC</b> to let
                the client know it's been completed, and upload the UTR receipt.
            </StepContent>
        ),
        observe: '.tour-utr-status > div',
        highlightedSelectors: ['.tour-utr-status > div'],
    },
    {
        selector: '.tour-utr-receipt',
        content: <StepContent>Here you can upload the UTR receipt, once the process has been completed.</StepContent>,
        observe: '.tour-utr-receipt-highlight > div:first-child',
        resizeObservables: ['.tour-utr-receipt-highlight > div:first-child'],
    },
    {
        selector: '.tour-utr-tab',
        content: (
            <StepContent>
                You can also find any clients who have requested you to register them for a UTR in the UTR registrations
                tab, or ...
            </StepContent>
        ),
    },
    {
        selector: '.tour-utr-nav-overview',
        content: <StepContent>... or in the Overview tab of the client's profile.</StepContent>,
    },
])

const STEPS_AGENT_AUTH = createTourSteps([
    {
        selector: '.tour-agent-auth',
        content: (
            <StepContent>
                Here you can request Agent Authorisation for a client. Typically, clients will upload all the
                information that's needed and agent authorisation won't be necessary.
                <br />
                <br />
                We advocate requesting authorisation in situations where the client is unable to access their previous
                employment information or there is a historic tax issue that requires further information.
                <br />
                <br />
                Alternatively, you can request agent authorisation once the tax return has been filed if you'd like
                additional information regarding the client.
            </StepContent>
        ),
    },
])

const isFirstTaxReturnTourEnabled = !!featureFlags.featureTourFirstTaxReturn

const FEATURE_TOUR_STATUS = 'FEATURE_TOUR_STATUS'
const FEATURE_TOUR_REQUEST_DOCS = 'FEATURE_TOUR_REQUEST_DOCS'
const FEATURE_TOUR_AGENT_AUTH = 'FEATURE_TOUR_AGENT_AUTH'
const FEATURE_TOUR_UTR = 'FEATURE_TOUR_UTR'
const FEATURE_BEACON_OVERVIEW = 'FEATURE_BEACON_OVERVIEW'
const FEATURE_BEACON_TAX_RETURN = 'FEATURE_BEACON_TAX_RETURN'

export const TourTaxReturnStatus = () => {
    if (!isFirstTaxReturnTourEnabled) return null

    return <FeatureTour id={FEATURE_TOUR_STATUS} hasBeaconTooltip steps={STEPS_STATUS} />
}

export const TourRequestDocs = ({ onBeaconClick }) => {
    if (!isFirstTaxReturnTourEnabled) return null

    return <FeatureTour id={FEATURE_TOUR_REQUEST_DOCS} onBeaconClick={onBeaconClick} steps={STEPS_REQUEST_DOCS} />
}
TourRequestDocs.propTypes = {
    onBeaconClick: PropTypes.func.isRequired,
}

export const TourAgentAuth = () => {
    if (!isFirstTaxReturnTourEnabled) return null

    return <FeatureTour id={FEATURE_TOUR_AGENT_AUTH} steps={STEPS_AGENT_AUTH} />
}

export const TourUTR = () => {
    if (!isFirstTaxReturnTourEnabled) return null

    return <FeatureTour id={FEATURE_TOUR_UTR} hasBeaconTooltip steps={STEPS_UTR} />
}

export const TourLinkOverview = () => {
    if (!isFirstTaxReturnTourEnabled) return null

    if (hasSeenTour(FEATURE_TOUR_AGENT_AUTH) && hasSeenTour(FEATURE_TOUR_UTR)) {
        return null
    }

    return <FeatureBeacon id={FEATURE_BEACON_OVERVIEW} />
}

export const TourLinkTaxReturn = () => {
    if (!isFirstTaxReturnTourEnabled) return null

    if (hasSeenTour(FEATURE_TOUR_STATUS) && hasSeenTour(FEATURE_TOUR_REQUEST_DOCS)) {
        return null
    }

    return <FeatureBeacon id={FEATURE_BEACON_TAX_RETURN} style={{ top: '25px', right: '-25px' }} />
}
