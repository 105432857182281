import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { AccountantPortalChrome, Button, ButtonLink, Checkbox, DatePicker, Form, Input, Select } from '@scouts/ui'

import { receiveErrorAlerts, receiveSuccessAlerts, saveCustomer } from '@/actions'
import {
    CUSTOMER_TITLE_OTHER,
    CUSTOMER_TITLES,
    MARITAL_STATUS_MARRIED,
    MARITAL_STATUS_SINGLE,
    MARITAL_STATUS_TITLES,
    MARITAL_STATUS_WITH_PARTNER,
    PATH_CUSTOMERS,
} from '@/constants'
import { copyTextToClipboard, isValidNino, isValidUtr } from '@/helpers'

import CustomerProfileEditEmailModal from './CustomerProfileEditEmailModal'

class CustomerProfileEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customer: null,
            updatedDateUTC: null,
            customerTitle: '',
            customerTitleOther: '',
            isEmailChangeModalOpen: false,
        }

        _bindAll(this, ['handleChange', 'handleChangeTitle', 'save', 'openEmailChangePopup', 'closeEmailChangePopup'])
    }

    componentDidMount() {
        const { customer } = this.props
        let { title: customerTitle } = customer
        let customerTitleOther = ''

        if (customerTitle && !CUSTOMER_TITLES.includes(customerTitle)) {
            customerTitleOther = customerTitle
            customerTitle = CUSTOMER_TITLE_OTHER
        }

        this.setState({
            customer,
            customerTitleOther,
            customerTitle,
        })
    }

    static getDerivedStateFromProps({ customer }) {
        const { updatedDateUTC } = customer

        return { updatedDateUTC }
    }

    handleChangeTitle({ name, value }) {
        this.setState({ [name]: value })
    }

    handleChange({ name, value }) {
        const { customer, updatedDateUTC } = this.state

        const newValue = this.formatValue(name, value)
        const updatedCustomer = { ...customer, [name]: newValue, updatedDateUTC }

        this.setState({ customer: updatedCustomer })
    }

    alertInvalidInputs() {
        const { dispatch } = this.props
        const { customer } = this.state
        const { hasNoNationalInsuranceNumber, nationalInsuranceNumber, uniqueTaxpayerReference } = customer

        const alerts = []
        if (!hasNoNationalInsuranceNumber && !!nationalInsuranceNumber && !isValidNino(nationalInsuranceNumber)) {
            alerts.push('NI number is in an invalid format')
        }

        if (!!uniqueTaxpayerReference && !isValidUtr(uniqueTaxpayerReference)) {
            alerts.push('UTR number should be exactly 10 digits long')
        }

        if (alerts.length) {
            dispatch(receiveErrorAlerts(alerts))
        }

        return { wasUserAlerted: alerts.length > 0 }
    }

    formatValue(name, value) {
        return name === 'uniqueTaxpayerReference' || name === 'nationalInsuranceNumber'
            ? value.replace(/\s/g, '')
            : value
    }

    save() {
        const { dispatch, history } = this.props
        const { customer, customerTitle, customerTitleOther } = this.state

        const { id: customerId } = customer

        const title = customerTitle === CUSTOMER_TITLE_OTHER ? customerTitleOther : customerTitle

        const updatedCustomer = { ...customer, title }

        const { wasUserAlerted } = this.alertInvalidInputs()
        if (wasUserAlerted) return

        dispatch(
            saveCustomer(customerId, updatedCustomer, {
                onSuccess: () => {
                    history.push(`${PATH_CUSTOMERS}/${customerId}`)
                },
            })
        )
    }

    copyToClipboard(value) {
        const { dispatch } = this.props

        copyTextToClipboard(value, () => dispatch(receiveSuccessAlerts('Copied to clipboard')))
    }

    openEmailChangePopup() {
        this.setState({ isEmailChangeModalOpen: true })
    }

    closeEmailChangePopup() {
        const { customer } = this.props
        this.handleChange({ name: 'email', value: customer.email })

        this.setState({ isEmailChangeModalOpen: false })
    }

    render() {
        const { customer, isEmailChangeModalOpen, customerTitle, customerTitleOther } = this.state

        if (!customer) return null

        const {
            id: customerId,
            addressCity,
            addressCountry,
            addressMovedInDate,
            addressPostcode,
            addressStreetAddress,
            dateOfBirth,
            email,
            firstName,
            hasNoNationalInsuranceNumber,
            lastName,
            maritalStatus,
            nationalInsuranceNumber,
            phoneNumber,
            uniqueTaxpayerReference,
            hasTaxRegistration,
        } = customer

        const shouldShowTitle = hasTaxRegistration

        return (
            <AccountantPortalChrome.Customer.Page.Container>
                <AccountantPortalChrome.Customer.Page.Header>
                    <AccountantPortalChrome.Customer.Page.Title>
                        Edit profile
                    </AccountantPortalChrome.Customer.Page.Title>
                </AccountantPortalChrome.Customer.Page.Header>
                <Form onSubmit={this.save}>
                    {shouldShowTitle && (
                        <Form.Row>
                            <Form.Columns>
                                <Form.Column width="49%">
                                    <Form.Column.Title>Title</Form.Column.Title>
                                    <Form.Column.Content>
                                        <Select
                                            name="customerTitle"
                                            value={customerTitle || ''}
                                            onChange={this.handleChangeTitle}
                                            width="100%"
                                            options={[
                                                { title: 'Choose…' },
                                                ...CUSTOMER_TITLES.map((option) => ({
                                                    value: option,
                                                    title: option,
                                                })),
                                            ]}
                                        />
                                    </Form.Column.Content>
                                </Form.Column>
                                {customerTitle === CUSTOMER_TITLE_OTHER && (
                                    <Form.Column width="49%">
                                        <Form.Column.Title>Other title</Form.Column.Title>
                                        <Form.Column.Content>
                                            <Input
                                                name="customerTitleOther"
                                                value={customerTitleOther || ''}
                                                onChange={this.handleChangeTitle}
                                                maxLength="20"
                                            />
                                        </Form.Column.Content>
                                    </Form.Column>
                                )}
                            </Form.Columns>
                        </Form.Row>
                    )}
                    <Form.Row>
                        <Form.Columns>
                            <Form.Column width="49%">
                                <Form.Column.Title>First name</Form.Column.Title>
                                <Form.Column.Content>
                                    <Input
                                        name="firstName"
                                        value={firstName || ''}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Form.Column.Content>
                            </Form.Column>
                            <Form.Column width="49%">
                                <Form.Column.Title>
                                    Last name
                                    <Form.Row.Action>
                                        <ButtonLink
                                            type="button"
                                            onClick={() => this.copyToClipboard(`${firstName} ${lastName}`)}
                                        >
                                            Copy full name
                                        </ButtonLink>
                                    </Form.Row.Action>
                                </Form.Column.Title>
                                <Form.Column.Content>
                                    <Input
                                        name="lastName"
                                        value={lastName || ''}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Form.Column.Content>
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>
                            Email
                            {email && (
                                <Form.Row.Action>
                                    <ButtonLink type="button" onClick={() => this.copyToClipboard(email)}>
                                        Copy
                                    </ButtonLink>
                                </Form.Row.Action>
                            )}
                        </Form.Row.Title>

                        <Form.Row.Content>
                            <Input name="email" value={email || ''} disabled onChange={() => {}} />
                        </Form.Row.Content>

                        <Form.Row.Description>
                            <ButtonLink onClick={this.openEmailChangePopup}>Change email</ButtonLink>
                        </Form.Row.Description>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>
                            Phone number
                            {phoneNumber && (
                                <Form.Row.Action>
                                    <ButtonLink type="button" onClick={() => this.copyToClipboard(phoneNumber)}>
                                        Copy
                                    </ButtonLink>
                                </Form.Row.Action>
                            )}
                        </Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="phoneNumber"
                                maxLength="20"
                                value={phoneNumber || ''}
                                onChange={this.handleChange}
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>Marital status</Form.Row.Title>
                        <Form.Row.Content>
                            <Select
                                name="maritalStatus"
                                options={[
                                    { title: 'Not set' },
                                    { disabled: true },
                                    {
                                        value: MARITAL_STATUS_SINGLE,
                                        title: MARITAL_STATUS_TITLES[MARITAL_STATUS_SINGLE],
                                    },
                                    {
                                        value: MARITAL_STATUS_MARRIED,
                                        title: MARITAL_STATUS_TITLES[MARITAL_STATUS_MARRIED],
                                    },
                                    {
                                        value: MARITAL_STATUS_WITH_PARTNER,
                                        title: MARITAL_STATUS_TITLES[MARITAL_STATUS_WITH_PARTNER],
                                    },
                                ]}
                                value={maritalStatus || ''}
                                onChange={this.handleChange}
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>Date of birth</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker name="dateOfBirth" value={dateOfBirth} onChange={this.handleChange} />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Columns>
                            <Form.Column width="49%">
                                <Form.Column.Title>Street address</Form.Column.Title>
                                <Form.Column.Content>
                                    <Input
                                        name="addressStreetAddress"
                                        value={addressStreetAddress || ''}
                                        onChange={this.handleChange}
                                    />
                                </Form.Column.Content>
                            </Form.Column>
                            <Form.Column width="49%">
                                <Form.Column.Title>City</Form.Column.Title>
                                <Form.Column.Content>
                                    <Input name="addressCity" value={addressCity || ''} onChange={this.handleChange} />
                                </Form.Column.Content>
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>

                    <Form.Row>
                        <Form.Columns>
                            <Form.Column width="49%">
                                <Form.Column.Title>Post code</Form.Column.Title>
                                <Form.Column.Content>
                                    <Input
                                        name="addressPostcode"
                                        value={addressPostcode || ''}
                                        onChange={this.handleChange}
                                    />
                                </Form.Column.Content>
                            </Form.Column>
                            <Form.Column width="49%">
                                <Form.Column.Title>Country</Form.Column.Title>
                                <Form.Column.Content>
                                    <Input
                                        name="addressCountry"
                                        value={addressCountry || ''}
                                        onChange={this.handleChange}
                                    />
                                </Form.Column.Content>
                            </Form.Column>
                        </Form.Columns>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>Moved in date</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker
                                disabled
                                name="addressMovedInDate"
                                value={addressMovedInDate}
                                onChange={this.handleChange}
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>UTR</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="uniqueTaxpayerReference"
                                value={uniqueTaxpayerReference || ''}
                                onChange={this.handleChange}
                                maxLength="10"
                                hasError={!!uniqueTaxpayerReference && !isValidUtr(uniqueTaxpayerReference)}
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>NI number</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="nationalInsuranceNumber"
                                value={nationalInsuranceNumber || ''}
                                onChange={this.handleChange}
                                maxLength="9"
                                hasError={
                                    !hasNoNationalInsuranceNumber &&
                                    !!nationalInsuranceNumber &&
                                    !isValidNino(nationalInsuranceNumber)
                                }
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Content>
                            <Checkbox
                                checked={hasNoNationalInsuranceNumber}
                                isSmall
                                label="No NI number (eg non-UK)"
                                name="hasNoNationalInsuranceNumber"
                                onChange={({ name, checked }) => this.handleChange({ name, value: checked })}
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Actions>
                        <Button type="submit">Save</Button>
                        <Button
                            isSecondary
                            onClick={() => {
                                const { history } = this.props
                                history.push(`${PATH_CUSTOMERS}/${customerId}`)
                            }}
                        >
                            Back to profile
                        </Button>
                    </Form.Actions>
                </Form>

                {isEmailChangeModalOpen && (
                    <CustomerProfileEditEmailModal customerId={customerId} handleClose={this.closeEmailChangePopup} />
                )}
            </AccountantPortalChrome.Customer.Page.Container>
        )
    }
}

CustomerProfileEdit.propTypes = {
    customer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default connect()(withRouter(CustomerProfileEdit))
