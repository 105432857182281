import { localStorageGet, localStorageRemove, localStorageSet } from '@scouts/helpers'

import { DraftMessage } from '@/models/draft-message'

import { apiSlice } from '../api-slice'
import { Tags } from '../tags'

interface DraftMessageIdParams {
    customerId: number
    userId: number
}
type StoredDraftMessage = Omit<DraftMessage, 'files'>

export const getMsgId = (params: { userId: number; customerId: number }) => {
    return `msg_${params.userId}_${params.customerId}`
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDraftMessage: builder.query<StoredDraftMessage, DraftMessageIdParams>({
            queryFn: (params) => {
                const dataString = localStorageGet(getMsgId(params))

                return { data: dataString ? JSON.parse(dataString) : undefined }
            },
            providesTags: (_0, _1, params) => [{ type: Tags.DraftMessages, id: getMsgId(params) }],
        }),
        updateDraftMessage: builder.mutation<StoredDraftMessage, StoredDraftMessage & DraftMessageIdParams>({
            queryFn: (params) => {
                const { customerId, userId, ...msg } = params
                localStorageSet(getMsgId({ customerId, userId }), JSON.stringify(msg))
                return { data: msg }
            },
            invalidatesTags: (_0, _1, params) => [{ type: Tags.DraftMessages, id: getMsgId(params) }],
        }),
        removeDraftMessage: builder.mutation<boolean, DraftMessageIdParams>({
            queryFn: (params) => {
                localStorageRemove(getMsgId(params))
                return { data: true }
            },
            invalidatesTags: (_0, _1, params) => [{ type: Tags.DraftMessages, id: getMsgId(params) }],
        }),
    }),
})

export const { useGetDraftMessageQuery, useUpdateDraftMessageMutation, useRemoveDraftMessageMutation } = extendedApi
