import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, select, takeLatest } from 'redux-saga/effects'

import { receiveSuccessAlerts, receiveUser, REQUEST_USER, SAVE_USER } from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* userSagas() {
    yield takeLatest(REQUEST_USER, fetchUser)
    yield takeLatest(SAVE_USER, saveUser)
}

function* fetchUser(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall('accountants/loggedin')
        yield put(receiveUser(response.data))

        const { onSuccess } = action
        if (onSuccess) onSuccess(response.data)
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveUser(action) {
    try {
        yield put(showLoading())

        const newUserData = action.data
        const currentUser = yield select((state) => state.user)
        const response = yield apiCall(`accountants/${currentUser.id}`, 'put', { ...currentUser, ...newUserData })
        yield put(receiveUser(response.data))
        yield put(receiveSuccessAlerts('Profile saved'))

        const { onSuccess } = action.meta
        if (onSuccess) onSuccess(response.data)
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
