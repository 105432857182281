import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const PropertyEarnings = ({ propertyIncome }: { propertyIncome: SelfAssessmentDTO['propertyIncome'] }) => {
    if (!propertyIncome) return null

    const {
        additionalNotes,
        hasHolidayLettings,
        hasHolidayLettingsExpenses,
        hasMortgageInterest,
        hasPropertyRentalExpenses,
        hasRentedProperty,
        hasRentedRoom,
        holidayLettingsExpenses,
        holidayLettingsIncome,
        homeRentalIncome,
        mortgageInterestAmount,
        mortgageType,
        numberOfProperties,
        propertyJointlyOwned,
        propertyRentalExpenses,
        propertyRentalIncome,
    } = propertyIncome

    return (
        <SectionData>
            <SectionDataRow label="Number of properties" value={numberOfProperties} />

            <SectionDataRow label="Rented room/home" value={hasRentedRoom} boolean highlight />
            <SectionDataRow label="Room/home rental income" value={formatCurrencyNotSure(homeRentalIncome)} />

            <SectionDataRow label="Rented full property" value={hasRentedProperty} boolean highlight />
            <SectionDataRow label="Property rental income" value={formatCurrencyNotSure(propertyRentalIncome)} />
            <SectionDataRow label="Has property rental expenses" value={hasPropertyRentalExpenses} boolean />

            {hasPropertyRentalExpenses && (
                <SectionDataRow
                    label="Property rental expenses"
                    value={formatCurrencyNotSure(propertyRentalExpenses)}
                />
            )}

            <SectionDataRow label="Has mortgage interest" value={hasMortgageInterest} boolean />
            {hasMortgageInterest && (
                <>
                    <SectionDataRow label="Type of mortgage" value={mortgageType} />
                    <SectionDataRow
                        label="Mortgage interest amount"
                        value={formatCurrencyNotSure(mortgageInterestAmount)}
                    />
                </>
            )}

            <SectionDataRow label="Holiday lettings" value={hasHolidayLettings} boolean highlight />

            {hasHolidayLettings && (
                <>
                    <SectionDataRow
                        label="Holiday lettings income"
                        value={formatCurrencyNotSure(holidayLettingsIncome)}
                    />
                    <SectionDataRow label="Has holiday lettings expenses" value={hasHolidayLettingsExpenses} boolean />
                    {hasHolidayLettingsExpenses && (
                        <SectionDataRow
                            label="Holiday lettings expenses"
                            value={formatCurrencyNotSure(holidayLettingsExpenses)}
                        />
                    )}
                </>
            )}

            <SectionDataRow label="Property jointly owned" value={propertyJointlyOwned} boolean highlight />

            {additionalNotes && <SectionDataRow label="Notes" value={additionalNotes} />}
        </SectionData>
    )
}
