import { useToggle } from '@scouts/helpers'
import { ButtonLink } from '@scouts/ui'

import { CustomerDTO, SelfAssessmentSummaryDTO } from '@/models'

import { CreateSelfAssessmentModal } from './CreateSelfAssessmentModal'

interface CreateSelfAssessmentProps {
    customer: CustomerDTO
    customerSelfAssessments: SelfAssessmentSummaryDTO[]
}

export const CreateSelfAssessment = ({ customer, customerSelfAssessments }: CreateSelfAssessmentProps) => {
    const [isModalOpen, open, close] = useToggle()

    return (
        <>
            <ButtonLink icon="plus" onClick={open}>
                Add new
            </ButtonLink>

            {isModalOpen && (
                <CreateSelfAssessmentModal
                    closeModal={close}
                    customer={customer}
                    customerSelfAssessments={customerSelfAssessments}
                />
            )}
        </>
    )
}
