import { createSelector } from '@reduxjs/toolkit'
import _orderBy from 'lodash/orderBy'

import {
    createCustomerActiveSelfAssessments,
    createCustomerActiveTaxConsultations,
    createCustomerCancelledSelfAssessments,
    createCustomerCancelledTaxConsultations,
    createCustomerCompletedSelfAssessments,
    createCustomerCompletedTaxConsultations,
} from './customer-services-creators'

export const getCustomers = (state) => state.customers.data || []

const getCustomer = (state, { customerId }) => {
    const customers = getCustomers(state)
    return customers.find((o) => o.id === customerId)
}

const getCustomerTableProductBundles = (state, { customerId }) => {
    const customer = getCustomer(state, { customerId })
    return customer?.productBundles || []
}

const getCustomerTableSelfAssessments = (state, { customerId }) => {
    const item = getCustomer(state, { customerId })
    return item?.selfAssessments || []
}

const getCustomerTableTaxConsultations = (state, { customerId }) => {
    const item = getCustomer(state, { customerId })
    return item?.taxConsultations || []
}

export const getCustomerTableActiveSelfAssessments = createCustomerActiveSelfAssessments(
    getCustomerTableSelfAssessments
)

export const getCustomerTableCancelledSelfAssessments = createCustomerCancelledSelfAssessments(
    getCustomerTableSelfAssessments
)

export const getCustomerTableCompletedSelfAssessments = createCustomerCompletedSelfAssessments(
    getCustomerTableSelfAssessments
)

export const getCustomerTableAllSelfAssessments = createSelector(
    [
        getCustomerTableActiveSelfAssessments,
        getCustomerTableCancelledSelfAssessments,
        getCustomerTableCompletedSelfAssessments,
    ],
    (activeSelfAssessments, cancelledSelfAssessments, completedSelfAssessments) => ({
        activeSelfAssessments,
        cancelledSelfAssessments,
        completedSelfAssessments,
    })
)

export const getCustomerTableActiveTaxConsultations = createCustomerActiveTaxConsultations(
    getCustomerTableTaxConsultations
)

export const getCustomerTableCancelledTaxConsultations = createCustomerCancelledTaxConsultations(
    getCustomerTableTaxConsultations
)

export const getCustomerTableCompletedTaxConsultations = createCustomerCompletedTaxConsultations(
    getCustomerTableTaxConsultations
)

export const getCustomerTableAllTaxConsultations = createSelector(
    [
        getCustomerTableActiveTaxConsultations,
        getCustomerTableCancelledTaxConsultations,
        getCustomerTableCompletedTaxConsultations,
    ],
    (activeTaxConsultations, cancelledTaxConsultations, completedTaxConsultations) => ({
        activeTaxConsultations,
        cancelledTaxConsultations,
        completedTaxConsultations,
    })
)

export const getCustomerTableCompletedCount = createSelector(
    [getCustomerTableCompletedSelfAssessments, getCustomerTableCompletedTaxConsultations],
    (completedSelfAssessments, completedTaxConsultations) =>
        completedSelfAssessments.length + completedTaxConsultations.length
)

export const getCustomerTableActiveProductBundles = createSelector(
    [getCustomerTableProductBundles, getCustomerTableActiveTaxConsultations, getCustomerTableActiveSelfAssessments],
    (customerProductBundles, customerActiveTaxConsultations, customerActiveSelfAssessments) => {
        const customerActiveProductBundles = customerProductBundles.filter((bundle) => {
            const { hasPaid, selfAssessmentId, taxConsultationId } = bundle

            if (!hasPaid) return null

            const hasActiveLinkedTaxConsultation = customerActiveTaxConsultations.some(
                (tc) => tc.id === taxConsultationId
            )
            const hasNoLinkedTaxConsultation = !taxConsultationId
            const hasActiveLinkedSelfAssessment = customerActiveSelfAssessments.some((sa) => sa.id === selfAssessmentId)
            const hasNoLinkedSelfAssessment = !selfAssessmentId

            return (
                hasActiveLinkedTaxConsultation ||
                hasNoLinkedTaxConsultation ||
                hasActiveLinkedSelfAssessment ||
                hasNoLinkedSelfAssessment
            )
        })

        return _orderBy(customerActiveProductBundles, ['id'], ['desc'])
    }
)
