import axios from 'axios'
import { useCallback } from 'react'

import { generateApiPath, saveBlobAsFile } from '@scouts/helpers'
import { addErrorToast } from '@scouts/ui'

import { ERROR_MESSAGE_SAVING } from '@/constants'
import { getRequestConfig } from '@/helpers/api'
import { ENDPOINTS } from '@/store/endpoints'

import { GetTaxReliefClaimFilesParams } from '../api'

export const useDownloadTaxReliefClaimFilesZip = () => {
    const downloadTaxReliefClaimFilesZip = useCallback(async ({ taxReliefClaimId }: GetTaxReliefClaimFilesParams) => {
        const config = await getRequestConfig({
            url: generateApiPath(ENDPOINTS.TAX_RELIEF_CLAIMS.GET.DOWNLOAD_FILES_ZIP, { taxReliefClaimId }),
            responseType: 'blob',
        })

        return axios(config)
            .then(({ data: blob }) => {
                saveBlobAsFile({ blob, fileName: 'tax-relief-claim-files.zip' })
            })
            .catch(() => {
                addErrorToast({ body: ERROR_MESSAGE_SAVING })
            })
    }, [])

    return {
        downloadTaxReliefClaimFilesZip,
    }
}
