import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { Auth } from '@scouts/auth'
import { ChunkLoadErrorBoundary, Locale } from '@scouts/helpers'
import { TranslationProvider } from '@scouts/i18n'
import { AccountantPortalChrome, Toaster, TooltipProvider } from '@scouts/ui'

import { PATH_LOGOUT } from '@/constants'
import { initSentry } from '@/helpers/sentry'

import { authConfig } from './auth/auth-config'
import { AuthenticationBoundary } from './auth/AuthenticationBoundary'
import { Logout } from './auth/Logout'
import { ChunkLoadError } from './ChunkLoadError'
import ErrorBoundary from './ErrorBoundary'
import { GlobalNotices } from './global-notices/GlobalNotices'
import { InboxConfigurationProvider } from './inbox-configuration'
import { LocationObserver } from './LocationObserver'
import Main from './Main'
import Loader from './ui/Loader'

const App = () => {
    useEffect(() => {
        initSentry()
    }, [])

    return (
        <ErrorBoundary>
            <Loader />
            <TooltipProvider />
            <Router>
                <TranslationProvider locale={Locale.GB}>
                    <Auth.Provider config={authConfig}>
                        <LocationObserver>
                            <>
                                <Toaster />
                                <Switch>
                                    <Route path={PATH_LOGOUT} component={Logout} />
                                    <AuthenticationBoundary>
                                        <ChunkLoadErrorBoundary renderError={() => <ChunkLoadError />}>
                                            <InboxConfigurationProvider>
                                                <GlobalNotices />
                                                <AccountantPortalChrome>
                                                    <Main />
                                                </AccountantPortalChrome>
                                            </InboxConfigurationProvider>
                                        </ChunkLoadErrorBoundary>
                                    </AuthenticationBoundary>
                                </Switch>
                            </>
                        </LocationObserver>
                    </Auth.Provider>
                </TranslationProvider>
            </Router>
        </ErrorBoundary>
    )
}

export default App
