import { useSelector } from 'react-redux'

import { IconAlertTriangle, IconChartColumn, IconStar, IconTrophy } from '@scouts/ui'

import { PATH_ACCOUNTANTS, PATH_ESCALATIONS, PATH_REWARDS, PATH_SUPPORT_DASHBOARD } from '@/constants'
import { getUser } from '@/selectors'

export const useSidebarMenuItems = () => {
    const { isAdminUser } = useSelector(getUser)

    return [
        {
            title: 'Accountants',
            path: PATH_ACCOUNTANTS,
            IconComponent: IconChartColumn,
            shouldHide: !isAdminUser,
        },
        {
            title: 'Dashboard',
            path: PATH_SUPPORT_DASHBOARD,
            IconComponent: IconStar,
            shouldHide: !isAdminUser,
        },
        {
            title: 'Escalations',
            path: PATH_ESCALATIONS,
            IconComponent: IconAlertTriangle,
            shouldHide: !isAdminUser,
        },
        {
            title: 'Rewards',
            path: PATH_REWARDS,
            IconComponent: IconTrophy,
            shouldHide: !isAdminUser,
        },
    ]
}
