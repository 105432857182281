import { getIsBetaTestingChatScouts } from '@/helpers/chatscouts-beta-testers'

import { useUser } from '@/hooks/use-user'

export const useChatScoutsBetaTesters = () => {
    const { id: userId, isAdminUser } = useUser()
    const isUserBetaTester = isAdminUser || getIsBetaTestingChatScouts(userId)

    return {
        isUserBetaTester,
    }
}
