import { useEffect } from 'react'

import { colors, Container, Flexer, Skeleton, SkeletonLine } from '@scouts/ui'

import { requestCustomerDiscounts } from '@/actions'
import { CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'
import { useCustomersTaxConsultations, useTaxConsultation } from '@/store/tax-consultations'

import { useTaxConsultationIdFromPath } from '../hooks/use-tax-consultation-id-from-path'

import { TaxConsultation } from './TaxConsultation'

export const TaxConsultationContainer = ({ customer }: { customer: CustomerDTO }) => {
    const dispatch = useAppDispatch()
    const activeTaxConsultationId = useTaxConsultationIdFromPath()
    const { defaultTaxConsultationId, isLoading: isLoadingCustomerTaxConsultations } = useCustomersTaxConsultations({
        customerId: customer.id,
    })
    const taxConsultationId = activeTaxConsultationId || defaultTaxConsultationId || 0

    const { taxConsultation, isLoading, isFetching } = useTaxConsultation({ taxConsultationId })

    useEffect(() => {
        if (taxConsultationId) {
            dispatch(requestCustomerDiscounts({ customerId: customer.id }))
        }
    }, [customer.id, dispatch, taxConsultationId])

    if (taxConsultationId === 0 || !taxConsultation || isLoading || isFetching || isLoadingCustomerTaxConsultations)
        return (
            <Container>
                <Skeleton padding="24px" gap="24px" background={colors.white}>
                    <SkeletonLine height="42px" width="calc(50% - 24px)" />
                    <Flexer gap="48px" tabletRow>
                        <SkeletonLine height="240px" repeat={2} />
                    </Flexer>
                    <SkeletonLine height="220px" />
                    <SkeletonLine height="240px" />
                </Skeleton>
            </Container>
        )

    return <TaxConsultation customer={customer} taxConsultation={taxConsultation} />
}
