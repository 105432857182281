import { generateApiPath } from '@scouts/helpers'

import { CustomerAddressDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerAddresses: builder.query<CustomerAddressDTO[], { customerId: number }>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.CUSTOMER_ADDRESSES.GET, { customerId }),
            }),
        }),
    }),
})

export const { useGetCustomerAddressesQuery } = extendedApi
