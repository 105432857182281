import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { ResponseWithPagination } from '@/models'

export const mapPaginatedResponse = <T>(response: T[], meta: FetchBaseQueryMeta) => {
    const result: ResponseWithPagination<T[]> = {
        data: response,
        pagination: {
            currentPage: Number(meta?.response?.headers.get('x-pagination-current-page')),
            pageCount: Number(meta?.response?.headers.get('x-pagination-page-count')),
            perPage: Number(meta?.response?.headers.get('x-pagination-per-page')),
            totalCount: Number(meta?.response?.headers.get('x-pagination-total-count')),
        },
    }
    return result
}
