import styled from 'styled-components'

import { colors, font, Form, Input, InputOnChangeParams, Radio, TextArea } from '@scouts/ui'

import { isAdmin } from '@/helpers'
import { MessageTemplateType, MessageTemplateTypeTypes } from '@/models/message-template'

import { useUser } from '@/hooks/use-user'

interface MessageTemplateFormProps {
    templateContent: string
    templateName: string
    templateType: MessageTemplateTypeTypes
    handleChange: (params: InputOnChangeParams) => void
}

export const MessageTemplateForm = ({
    templateContent,
    templateName,
    templateType,
    handleChange,
}: MessageTemplateFormProps) => {
    const user = useUser()
    const isAdminUser = isAdmin(user)
    const templateCreatorFullName = `${user.firstName} ${user.lastName}`

    return (
        <>
            <Form.Row>
                <Form.Row.Content>
                    <MessageContainer>
                        <MessageLine>Hi [client name],</MessageLine>
                        <TextArea
                            name="templateContent"
                            value={templateContent}
                            placeholder="Your message…"
                            onChange={handleChange}
                            height="320px"
                            required
                            autoFocus
                        />
                        <MessageLine>&mdash; {templateCreatorFullName}</MessageLine>
                    </MessageContainer>
                </Form.Row.Content>
            </Form.Row>

            <Form.Row>
                <Form.Row.Title>Template name</Form.Row.Title>
                <Form.Row.Content>
                    <Input
                        name="templateName"
                        value={templateName}
                        placeholder="Add a descriptive name for the template…"
                        onChange={handleChange}
                        required
                        maxLength={255}
                    />
                </Form.Row.Content>
            </Form.Row>

            {isAdminUser && (
                <Form.Row>
                    <Form.Row.Title>Shared with</Form.Row.Title>
                    <Form.Row.Content>
                        <Form.InlineControl>
                            <Radio
                                checked={templateType === MessageTemplateType.Private}
                                label="Just for me"
                                name="templateType"
                                value={MessageTemplateType.Private}
                                onChange={handleChange}
                            />
                        </Form.InlineControl>
                        <Form.InlineControl>
                            <Radio
                                checked={templateType === MessageTemplateType.Support}
                                label="With support"
                                name="templateType"
                                value={MessageTemplateType.Support}
                                onChange={handleChange}
                            />
                        </Form.InlineControl>
                        <Form.InlineControl>
                            <Radio
                                checked={templateType === MessageTemplateType.Shared}
                                label="With accountants and support"
                                name="templateType"
                                value={MessageTemplateType.Shared}
                                onChange={handleChange}
                            />
                        </Form.InlineControl>
                    </Form.Row.Content>
                </Form.Row>
            )}
        </>
    )
}

const MessageContainer = styled.div`
    position: relative;
    background: ${colors.redLighter};
    padding: 0 36px;
`

const MessageLine = styled.div`
    display: block;
    padding: 18px 0;
    font-size: ${font.normal};
`
