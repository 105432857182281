import { useCustomersAccountantMeetings } from '@/store/accountant-meetings/hooks/use-customers-accountant-meetings'

export const useCustomerAccountantMeetings = ({ customerId }: { customerId: number }) => {
    const { accountantMeetings, isFetching } = useCustomersAccountantMeetings({ customerId })

    if (!accountantMeetings) return {}

    const hasAccountantMeetings = !!accountantMeetings && accountantMeetings?.length > 0

    const defaultAccountantMeetingId = accountantMeetings?.[0]?.id || null

    return { accountantMeetings, defaultAccountantMeetingId, hasAccountantMeetings, isFetching }
}
