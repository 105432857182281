import { generateApiPath } from '@scouts/helpers'

import { DiscountSchemeCreateDTO, DiscountSchemeDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

export interface AddDiscountSchemeParams {
    data: DiscountSchemeCreateDTO
}

export interface UpdateDiscountSchemeParams {
    discountSchemeId: DiscountSchemeDTO['id']
    data: DiscountSchemeDTO
}

export interface DeleteDiscountSchemeParams {
    discountSchemeId: DiscountSchemeDTO['id']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDiscountSchemes: builder.query<DiscountSchemeDTO[], void>({
            query: () => ({
                method: 'GET',
                url: ENDPOINTS.DISCOUNT_SCHEMES.GET,
            }),
            providesTags: [Tags.DiscountSchemes],
        }),
        addDiscountScheme: builder.mutation<void, AddDiscountSchemeParams>({
            query: ({ data }) => ({
                method: 'POST',
                url: ENDPOINTS.DISCOUNT_SCHEMES.POST,
                body: data,
            }),
            invalidatesTags: [Tags.DiscountSchemes],
        }),
        updateDiscountScheme: builder.mutation<void, UpdateDiscountSchemeParams>({
            query: ({ data, discountSchemeId }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.DISCOUNT_SCHEMES.PUT, { discountSchemeId }),
                body: data,
            }),
            invalidatesTags: [Tags.DiscountSchemes],
        }),
        deleteDiscountScheme: builder.mutation<void, DeleteDiscountSchemeParams>({
            query: ({ discountSchemeId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.DISCOUNT_SCHEMES.DELETE, {
                    discountSchemeId,
                }),
            }),
            invalidatesTags: [Tags.DiscountSchemes],
        }),
    }),
})

export const {
    useGetDiscountSchemesQuery,
    useDeleteDiscountSchemeMutation,
    useAddDiscountSchemeMutation,
    useUpdateDiscountSchemeMutation,
} = extendedApi
