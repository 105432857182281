import _orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { useGetCustomerLoggedEventsQuery } from '../api'

export const useCustomerLoggedEvents = ({ customerId }: { customerId: number }) => {
    const { data, isLoading, isError } = useGetCustomerLoggedEventsQuery({ customerId })

    const loggedEvents = useMemo(() => _orderBy(data, ['eventDateUTC'], ['desc']), [data])

    const getSelfAssessmentLoggedEvents = ({ selfAssessmentId }: { selfAssessmentId: number }) => {
        return loggedEvents.filter(
            (loggedEvent) =>
                loggedEvent.notify &&
                (!loggedEvent.selfAssessmentId || loggedEvent.selfAssessmentId === selfAssessmentId)
        )
    }

    return { loggedEvents, isLoading, isError, getSelfAssessmentLoggedEvents }
}
