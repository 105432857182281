import { ComponentType } from 'react'

import {
    CustomerDTO,
    IdentityVerificationDTO,
    IdentityVerificationStatus,
    IdentityVerificationStatusTypes,
} from '@/models'

import { Cancelled } from './status/Cancelled'
import { Failed } from './status/Failed'
import { Requested } from './status/Requested'
import { Retry } from './status/Retry'
import { Started } from './status/Started'
import { Submitted } from './status/Submitted'
import { Verified } from './status/Verified'
import { VerificationCreate } from './VerificationCreate'

interface VerificationStatusProps {
    customer: CustomerDTO
    identityVerification: IdentityVerificationDTO | null
}

const IdentityVerificationStatusComponents: Record<
    IdentityVerificationStatusTypes,
    ComponentType<{ customer: CustomerDTO; identityVerification: IdentityVerificationDTO }>
> = {
    [IdentityVerificationStatus.Retry]: Retry,
    [IdentityVerificationStatus.Requested]: Requested,
    [IdentityVerificationStatus.Submitted]: Submitted,
    [IdentityVerificationStatus.Verified]: Verified,
    [IdentityVerificationStatus.Cancelled]: Cancelled,
    [IdentityVerificationStatus.Failed]: Failed,
    [IdentityVerificationStatus.Started]: Started,
}

export const VerificationStatus = ({ customer, identityVerification }: VerificationStatusProps) => {
    const hasIdentityVerification = identityVerification !== null

    if (!hasIdentityVerification) return <VerificationCreate customer={customer} />

    const { status } = identityVerification

    const Component = IdentityVerificationStatusComponents[status]

    return <Component customer={customer} identityVerification={identityVerification} />
}
