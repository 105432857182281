import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

interface ForeignIncomeProps {
    foreignIncomes: SelfAssessmentDTO['foreignIncomes']
    foreignIncomeSummary: SelfAssessmentDTO['foreignIncomeSummary']
}

export const ForeignIncome = ({ foreignIncomes, foreignIncomeSummary }: ForeignIncomeProps) => {
    const {
        foreignTaxResident,
        foreignDomicileRemittanceBasis,
        hasRemittedFunds,
        notes,
        daysSpentInUK,
        ownedHomeInUK,
        ownedHomeAbroad,
        familyIsUKTaxResidents,
        spentTimeInUK,
        hasWorkedInUK,
        daysWorkedInUK,
        hasWorkedAbroad,
        daysWorkedAbroad,
    } = foreignIncomeSummary || {}

    const residencies = []

    if (ownedHomeInUK) residencies.push('Owned home in UK')
    if (ownedHomeAbroad) residencies.push('Owned home abroad')
    if (familyIsUKTaxResidents) residencies.push('Family is UK tax resident')
    if (spentTimeInUK) residencies.push('Spent more than 3 months in UK')

    return (
        <>
            <SectionData>
                <SectionDataRow
                    label="Is foreign tax resident"
                    value={foreignTaxResident === null ? '?' : foreignTaxResident}
                    boolean={foreignTaxResident !== null}
                />
                <SectionDataRow label="Days spent in UK" value={daysSpentInUK} />
                {residencies.length > 0 && <SectionDataRow label="Residency" value={residencies.join(', ')} />}
                <SectionDataRow label="Has worked in UK" value={hasWorkedInUK} boolean />
                <SectionDataRow label="Days worked in UK" value={daysWorkedInUK} />
                <SectionDataRow label="Has worked abroad" value={hasWorkedAbroad} boolean />
                <SectionDataRow label="Days worked abroad" value={daysWorkedAbroad} />
                {!foreignTaxResident && (
                    <>
                        <SectionDataRow
                            label="Non-dom claiming remittance basis"
                            value={foreignDomicileRemittanceBasis}
                            boolean
                        />

                        <SectionDataRow label="Has remitted funds" value={hasRemittedFunds} boolean />
                    </>
                )}
                {!!notes && <SectionDataRow label="Tax residence and domicile notes" value={notes} />}
            </SectionData>

            {foreignIncomes?.map((foreignIncome) => (
                <SectionData key={foreignIncome.id}>
                    <SectionDataRow label="Income type" value={foreignIncome.foreignIncomeType} highlight />
                    <SectionDataRow label="Country" value={foreignIncome.country.name} />
                    <SectionDataRow label="Amount" value={formatCurrencyNotSure(foreignIncome.incomeAmount)} />
                    <SectionDataRow
                        label="Tax paid"
                        value={formatCurrencyNotSure(foreignIncome.foreignTaxPaidAmount)}
                    />
                    {foreignIncome.additionalNotes && (
                        <SectionDataRow label="Notes" value={foreignIncome.additionalNotes} />
                    )}
                </SectionData>
            ))}
        </>
    )
}
