import { createSelector } from '@reduxjs/toolkit'
import _orderBy from 'lodash/orderBy'

/**
 * @param {import('@/store').RootState} _state
 * @param {{customerId?: number | null}} props
 */
const pluckCustomerId = (_state, { customerId }) => customerId
/**
 * @param {import('@/store').RootState} _state
 * @param {{selfAssessmentId?: number | null}} props
 */
const pluckSelfAssessmentId = (_state, { selfAssessmentId }) => selfAssessmentId
/**
 * @param {import('@/store').RootState} _state
 * @param {{taxConsultationId?: number | null}} props
 */
const pluckTaxConsultationId = (_state, { taxConsultationId }) => taxConsultationId
/**
 * @param {import('@/store').RootState} _state
 * @param {{productBundleId?: number | null}} props
 */
const pluckProductBundleId = (_state, { productBundleId }) => productBundleId

const getProductBundlesSlice = (state) => state.productBundles
const getProductBundlesList = (state) => getProductBundlesSlice(state).list

export const getCustomerProductBundles = createSelector(
    [getProductBundlesList, pluckCustomerId],
    (productBundlesList, customerId) => {
        // @TODO: userId -> customerId when backend changes
        const customerProductBundlesList = productBundlesList.filter(({ userId }) => userId === customerId)
        return _orderBy(customerProductBundlesList, ['id'], ['desc'])
    }
)

export const getProductBundleById = createSelector(
    [getProductBundlesList, pluckProductBundleId],
    (productBundlesList, productBundleId) => {
        const productBundle = productBundlesList.find(({ id }) => id === productBundleId)
        return productBundle
    }
)

export const getIsSelfAssessmentPartOfProductBundle = createSelector(
    [getProductBundlesList, pluckSelfAssessmentId],
    (productBundlesList, selfAssessmentId) => {
        const customerProductBundlesList = productBundlesList.find((item) => item.selfAssessmentId === selfAssessmentId)
        return !!customerProductBundlesList
    }
)

export const getIsTaxConsultationPartOfProductBundle = createSelector(
    [getProductBundlesList, pluckTaxConsultationId],
    (productBundlesList, taxConsultationId) => {
        const customerProductBundlesList = productBundlesList.find(
            (item) => item.taxConsultationId === taxConsultationId
        )
        return !!customerProductBundlesList
    }
)

export const getProductBundlesApplicableForDiscount = createSelector(
    [getCustomerProductBundles],
    (customerProductBundlesList) => {
        const unpaidBundles = customerProductBundlesList.filter((productBundle) => !productBundle.hasPaid)
        const orderedBundles = _orderBy(unpaidBundles, 'id', 'desc')
        return orderedBundles
    }
)
