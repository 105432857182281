import {
    Button,
    Line,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { DiscountSchemeDTO } from '@/models'

interface DiscountSchemesUniqueCodesModalProps {
    discountScheme: DiscountSchemeDTO
    onClose: () => void
}

export const DiscountSchemesUniqueCodesModal = ({ onClose, discountScheme }: DiscountSchemesUniqueCodesModalProps) => {
    const { name, uniqueCodeVariants } = discountScheme
    return (
        <Modal onClose={onClose}>
            <ModalHeader>
                <ModalTitle>Unique codes for {name}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {uniqueCodeVariants && (
                    <>
                        {uniqueCodeVariants.map((uniqueCode) => (
                            <Line key={uniqueCode}>{uniqueCode}</Line>
                        ))}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button onClick={onClose} isSecondary>
                        Close
                    </Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}
