import { PAGINATION_DEFAULT_PAGE, PAGINATION_VIEW_ALL_PAGE_SIZE } from '@/constants'
import { TaxRegistrationsRequestOptions } from '@/models'

export const getTaxRegistrationQueryParams = (options?: TaxRegistrationsRequestOptions) => ({
    pageSize: options?.pageSize || PAGINATION_VIEW_ALL_PAGE_SIZE,
    page: options?.page || PAGINATION_DEFAULT_PAGE,
    ...(options?.accountantId && { accountantId: options.accountantId }),
    ...(options?.status && options?.status !== 'All' && { status: options.status }),
    ...((options?.includeUnpaid !== null || options?.includeUnpaid !== undefined) && {
        includeUnpaid: options?.includeUnpaid,
    }),
})
