import _find from 'lodash/find'
import { useState } from 'react'

import { compareFilenameWithCustomerName, openFileDialog } from '@scouts/helpers'
import { DropdownButton } from '@scouts/ui'

import { deleteFile, requestSelfAssessment, requestSelfAssessmentFiles, uploadFile } from '@/actions'
import { FILING_AREA_GENERAL } from '@/constants'
import { FileType, FileTypeTitles, FileTypeTypes, FilingAreaTypes, SelfAssessmentFileDTO } from '@/models'
import { useAppDispatch } from '@/store'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { SelfAssessmentUploadsConfirmationModal } from './SelfAssessmentUploadsConfirmationModal'

interface UploadType {
    filingArea: FilingAreaTypes | null
    fileType: FileTypeTypes | null
    allowMultipleUploads?: boolean
}

interface FileUpload {
    file: File
    uploadType: UploadType
}

interface SelfAssessmentUploadsButtonProps {
    selfAssessmentId: number
    selfAssessmentFiles: SelfAssessmentFileDTO[]
    employmentId?: number
    uploadTypes: UploadType[]
    shouldVerify?: boolean
}

const SelfAssessmentUploadsButton = ({
    selfAssessmentId,
    selfAssessmentFiles,
    employmentId,
    uploadTypes,
    shouldVerify,
}: SelfAssessmentUploadsButtonProps) => {
    const dispatch = useAppDispatch()
    const { customer } = useCustomerFromPath()

    const [filesToCheck, setFilesToCheck] = useState<FileUpload[]>([])

    const removeFileToCheck = (fileToRemove: FileUpload) => {
        setFilesToCheck((current) => current.filter((f) => f !== fileToRemove))
    }

    const selectFile = ({ filingArea, fileType, allowMultipleUploads = true }: UploadType) => {
        openFileDialog({
            multiple: allowMultipleUploads,
            onChange: (files) => checkFilenamesAndCustomerName({ files, filingArea, fileType }),
        })
    }

    const checkFilenamesAndCustomerName = ({ files, fileType, filingArea }: { files: File[] } & UploadType) => {
        if (shouldVerify) {
            const hasAnyFilenameMismatch = files.some(
                (file) =>
                    !compareFilenameWithCustomerName({
                        fileName: file.name,
                        customerFirstName: customer.firstName ?? '',
                        customerLastName: customer.lastName ?? '',
                    })
            )
            if (hasAnyFilenameMismatch)
                return setFilesToCheck(files.map((file) => ({ file, uploadType: { fileType, filingArea } })))
        }

        files.forEach((file) => upload({ file, uploadType: { fileType, filingArea } }))
    }

    const upload = ({ file, uploadType: { fileType, filingArea } }: FileUpload) => {
        dispatch(
            uploadFile(
                {
                    selfAssessmentId,
                    fileType,
                    filingArea,
                    employmentId,
                    file,
                },
                {
                    onSuccess: () => {
                        if (filingArea === FILING_AREA_GENERAL && fileType !== FileType.Other) {
                            const existingFile = _find(selfAssessmentFiles, {
                                selfAssessmentId,
                                fileType,
                                filingArea,
                            })
                            if (existingFile) {
                                dispatch(deleteFile({ file: existingFile }))
                            }
                        }

                        dispatch(requestSelfAssessmentFiles({ selfAssessmentId }))
                        dispatch(requestSelfAssessment(selfAssessmentId))
                    },
                }
            )
        )
    }

    const actions = uploadTypes.map(({ fileType, filingArea, allowMultipleUploads }) => ({
        title: fileType ? FileTypeTitles[fileType] : '',
        onClick: () => selectFile({ filingArea, fileType, allowMultipleUploads }),
    }))

    const currentFileToCheck: FileUpload | null = filesToCheck[0] ?? null

    return (
        <>
            <DropdownButton alignRight actions={actions}>
                Upload
            </DropdownButton>

            {currentFileToCheck && (
                <SelfAssessmentUploadsConfirmationModal
                    file={currentFileToCheck.file}
                    onConfirm={() => {
                        upload(currentFileToCheck)
                        removeFileToCheck(currentFileToCheck)
                    }}
                    onClose={() => removeFileToCheck(currentFileToCheck)}
                />
            )}
        </>
    )
}

export default SelfAssessmentUploadsButton
