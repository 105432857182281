const config = {
    apiUrl: import.meta.env.REACT_APP_API_URL,
    apiUrlBookkeeping: import.meta.env.REACT_APP_API_URL_BOOKKEEPING,
    callbackUriExternalEmail: import.meta.env.REACT_APP_EXTERNAL_EMAIL_CALLBACK_URI,
    callbackUriNylas: import.meta.env.REACT_APP_CALLBACK_URI_NYLAS,
    callbackUriNylasBookingSuccess: import.meta.env.REACT_APP_CALLBACK_URI_NYLAS_BOOKING_SUCCESS,
    cgtApiUrl: import.meta.env.REACT_APP_CGT_API_URL,
    environment: import.meta.env.REACT_APP_ENVIRONMENT,
    featureChatScoutsBetaTesterIds: import.meta.env.REACT_APP_FEATURE_CHATSCOUTS_BETA_TESTERS,
    firebaseApiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
    firebaseAuthDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    firebaseAuthTenantId: import.meta.env.REACT_APP_FIREBASE_AUTH_TENANT_ID,
    hmrcCallback: import.meta.env.REACT_APP_HMRC_CALLBACK_URI,
    homePageUrl: import.meta.env.REACT_APP_HOMEPAGE_URL,
    isProduction: import.meta.env.REACT_APP_ENVIRONMENT === 'Production',
    isStaging: import.meta.env.REACT_APP_ENVIRONMENT === 'Staging',
    logoutUrl: import.meta.env.REACT_APP_LOGOUT_URL,
    sentryDsn: import.meta.env.REACT_APP_SENTRY_DSN,
}

export const getPdfSettings = (settings) => ({
    filename: 'file.pdf',
    html2canvas: { dpi: 192, letterRendering: true, scrollY: 0 },
    image: { type: 'jpeg', quality: 0.98 },
    jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
    margin: 1.5,
    pagebreak: { mode: ['avoid-all'] },
    ...settings,
})

export default config
