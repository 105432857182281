import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { File } from '@scouts/ui'

import { deleteCustomerFile, deleteFile, downloadCustomerFile, downloadFile } from '@/actions'
import { useAppDispatch } from '@/store'
import { useDeleteTaxConsultationFile, useDownloadTaxConsultationFile } from '@/store/tax-consultation-files/hooks'

const FileWithActions = ({ label, file, isDeletable, background, isCustomerFile, isTaxConsultationFile }) => {
    const dispatch = useAppDispatch()

    const { id } = file

    const { downloadTaxConsultationFile } = useDownloadTaxConsultationFile()
    const { deleteTaxConsultationFile } = useDeleteTaxConsultationFile()

    const handleDownloadFile = () => {
        if (isCustomerFile) {
            dispatch(downloadCustomerFile({ id }))
        } else if (isTaxConsultationFile) {
            downloadTaxConsultationFile({ taxConsultationFileId: id })
        } else {
            dispatch(downloadFile({ id }))
        }
    }

    const handleDeleteFile = () => {
        if (!window.confirm('Are you sure you want to delete the file?')) return

        if (isCustomerFile) {
            dispatch(deleteCustomerFile({ file }))
        } else if (isTaxConsultationFile) {
            deleteTaxConsultationFile({ taxConsultationFileId: id })
        } else {
            dispatch(deleteFile({ file }))
        }
    }

    return (
        <File
            onClick={handleDownloadFile}
            onDelete={isDeletable ? handleDeleteFile : undefined}
            background={background}
        >
            {label || file.fileName}
        </File>
    )
}

FileWithActions.propTypes = {
    dispatch: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    isCustomerFile: PropTypes.bool,
    isDeletable: PropTypes.bool.isRequired,
    isTaxConsultationFile: PropTypes.bool,
    label: PropTypes.string,
    background: PropTypes.string,
}

FileWithActions.defaultProps = {
    label: undefined,
    background: undefined,
    isCustomerFile: false,
    isTaxConsultationFile: false,
}

export default connect()(FileWithActions)
