import styled from 'styled-components'

import { colors, font, File, Notice, Spacer } from '@scouts/ui'

import { MESSAGE_ATTACHMENT_FILE_SIZE_SUM_LIMIT } from '@/constants'
import { getDraftMessageValidatedFiles } from '@/domain/draft-message'
import { useDraftMessage } from '@/store/draft-messages'

const List = ({
    files,
    onDelete,
    isRejectedFiles,
}: {
    files: File[]
    onDelete?: (file: File) => void
    isRejectedFiles?: boolean
}) => (
    <>
        {files.map((file) => {
            const handleDelete = onDelete ? () => onDelete(file) : undefined

            return (
                <FileWrapper key={file.name}>
                    <File
                        background={isRejectedFiles ? colors.redLighter : colors.mintLighter}
                        onDelete={handleDelete}
                        onClick={() => {}}
                    >
                        {file.name}
                    </File>
                </FileWrapper>
            )
        })}
    </>
)

export const MessageComposerFiles = ({ customerId, isSendLoading }: { customerId: number; isSendLoading: boolean }) => {
    const { draftMessage, updateDraftMessage } = useDraftMessage({ customerId })
    const { filesValidated, filesRejectedBySize, filesRejectedByType } = getDraftMessageValidatedFiles(
        draftMessage.files
    )

    const fileSizeLimitInMegabytes = Math.round(MESSAGE_ATTACHMENT_FILE_SIZE_SUM_LIMIT / 1024 / 1024)

    const removeDraftMessageFile = (fileToRemove: File) =>
        updateDraftMessage({ ...draftMessage, files: draftMessage.files.filter((file) => file != fileToRemove) })

    const handleDelete = isSendLoading ? undefined : removeDraftMessageFile

    return (
        <Container>
            <List files={filesValidated} onDelete={handleDelete} />

            {filesRejectedByType.length > 0 && (
                <>
                    <Spacer margin="24px 0 12px">
                        <Notice background={colors.red}>
                            <p>The following files are either in an unsupported format or empty and can't be sent</p>
                        </Notice>
                    </Spacer>

                    <Spacer margin="9px 0">
                        <Paragraph>Following files will not be sent</Paragraph>
                    </Spacer>

                    <Spacer margin="9px 0">
                        <List files={filesRejectedByType} onDelete={handleDelete} isRejectedFiles />
                    </Spacer>
                </>
            )}

            {filesRejectedBySize.length > 0 && (
                <>
                    <Spacer margin="24px 0 12px">
                        <Notice background={colors.red}>
                            <p>
                                The following files have exceeded the {fileSizeLimitInMegabytes}MB total attachment size
                                limit. Please send them via email.
                            </p>
                        </Notice>
                    </Spacer>

                    <Spacer margin="9px 0">
                        <Paragraph>Following files will not be sent</Paragraph>
                    </Spacer>

                    <Spacer margin="9px 0">
                        <List files={filesRejectedBySize} onDelete={handleDelete} isRejectedFiles />
                    </Spacer>
                </>
            )}
        </Container>
    )
}

const Container = styled.div`
    overflow: auto;
    max-height: 40vh; /* Has to be least some sane max-height limit */
`

const FileWrapper = styled.div`
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Paragraph = styled.p`
    font-size: ${font.small};
`
