import queryString from 'query-string'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, select, takeLatest } from 'redux-saga/effects'

import {
    CREATE_INTEGRATION_ACCESS_TOKEN,
    CREATE_PAYE_ACCESS_TOKEN,
    DELETE_INTEGRATION_ACCESS_TOKEN,
    DELETE_PAYE_ACCESS_TOKEN,
    REQUEST_INTEGRATION_ACCESS_TOKEN,
    REQUEST_INTEGRATION_AUTHORISATION_URL,
    REQUEST_PAYE_ACCESS_TOKEN,
    REQUEST_PAYE_AUTH,
} from '../actions'
import config from '../config'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'
import { getUser } from '../selectors/user'

export default function* integrationsSagas() {
    yield takeLatest(REQUEST_PAYE_ACCESS_TOKEN, requestPayeAccessToken)
    yield takeLatest(REQUEST_PAYE_AUTH, requestPayeAuthorisation)
    yield takeLatest(DELETE_PAYE_ACCESS_TOKEN, deletePayeAccessToken)
    yield takeLatest(CREATE_PAYE_ACCESS_TOKEN, createPayeAccessToken)
    yield takeLatest(REQUEST_INTEGRATION_AUTHORISATION_URL, requestIntegrationAuthorisationUrl)
    yield takeLatest(REQUEST_INTEGRATION_ACCESS_TOKEN, requestIntegrationAccessToken)
    yield takeLatest(DELETE_INTEGRATION_ACCESS_TOKEN, deleteIntegrationAccessToken)
    yield takeLatest(CREATE_INTEGRATION_ACCESS_TOKEN, createIntegrationAccessToken)
}

function* requestPayeAccessToken(action) {
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())
        const response = yield apiCall('hmrc/accesstoken')
        const { id: externalAccessTokenId, refreshTokenExpiresOnUTC } = response.data

        if (onSuccess) {
            onSuccess({ externalAccessTokenId, refreshTokenExpiresOnUTC })
        }
    } catch (error) {
        yield put(handleError(error))

        if (onError) {
            onError(error)
        }
    } finally {
        yield put(hideLoading())
    }
}

function* requestPayeAuthorisation(action) {
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())

        const response = yield apiCall(`hmrc/authorisationuri?redirectUri=${config.hmrcCallback}`)
        const url = response.data

        if (onSuccess) {
            onSuccess({ url })
        }
    } catch (error) {
        yield put(handleError(error))

        if (onError) {
            onError(error)
        }
    } finally {
        yield put(hideLoading())
    }
}

function* deletePayeAccessToken(action) {
    const { externalAccessTokenId } = action.data
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())

        yield apiCall(`hmrc/accesstoken/${externalAccessTokenId}`, 'delete')

        if (onSuccess) {
            onSuccess()
        }
    } catch (error) {
        yield put(handleError(error))

        if (onError) {
            onError(error)
        }
    } finally {
        yield put(hideLoading())
    }
}

function* createPayeAccessToken(action) {
    const { code } = action.data
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())

        yield apiCall(`hmrc/accesstoken/?code=${code}&redirectUri=${config.hmrcCallback}`, 'post')

        if (onSuccess) onSuccess()
    } catch (error) {
        yield put(handleError(error))

        if (onError) onError(error)
    } finally {
        yield put(hideLoading())
    }
}

function* requestIntegrationAuthorisationUrl(action) {
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())

        const urlComponents = queryString.stringify(action.payload)
        const response = yield apiCall(`oauth/authorisationuri?${urlComponents}`)
        const url = response.data

        if (onSuccess) onSuccess({ url })
    } catch (error) {
        yield put(handleError(error))

        if (onError) onError(error)
    } finally {
        yield put(hideLoading())
    }
}

function* requestIntegrationAccessToken(action) {
    const { externalApi } = action.payload
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())
        const response = yield apiCall(`oauth/accesstoken?externalApi=${externalApi}`)

        if (onSuccess) {
            onSuccess(response.data)
        }
    } catch (error) {
        yield put(handleError(error))

        if (onError) onError(error)
    } finally {
        yield put(hideLoading())
    }
}

function* deleteIntegrationAccessToken(action) {
    const { externalApi } = action.payload
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())

        const { id: accountantId } = yield select(getUser)
        const urlComponents = queryString.stringify({ externalApi, accountantId })

        yield apiCall(`oauth/accesstoken?${urlComponents}`, 'delete')

        if (onSuccess) onSuccess()
    } catch (error) {
        yield put(handleError(error))

        if (onError) onError(error)
    } finally {
        yield put(hideLoading())
    }
}

function* createIntegrationAccessToken(action) {
    const { externalApi, code, redirectUri } = action.payload
    const { onSuccess, onError } = action.meta

    try {
        yield put(showLoading())

        const requestBody = { code, redirectUri, externalApi }
        yield apiCall(`oauth/accesstoken`, 'post', requestBody)

        if (onSuccess) onSuccess()
    } catch (error) {
        yield put(handleError(error))

        if (onError) onError(error)
    } finally {
        yield put(hideLoading())
    }
}
