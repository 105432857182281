import { lazy, useEffect, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loadable, Page } from '@scouts/ui'

import { requestUser, saveUser } from '../../actions'
import { getUser } from '../../selectors/user'
import ProfilePageWithSidebar from '../ProfilePageWithSidebar'

const MyProfileForm = lazy(() => import('./MyProfileForm'))

const MyProfile = () => {
    const dispatch = useDispatch()
    const user = useSelector(getUser)

    useEffect(() => {
        dispatch(requestUser())
    }, [dispatch])

    const handleSave = (data) => {
        dispatch(saveUser(data))
    }

    return (
        <ProfilePageWithSidebar>
            <Page.Heading>My profile</Page.Heading>
            <Page.Section>
                <Suspense fallback={<Loadable isLoading />}>
                    <MyProfileForm user={user} handleSave={handleSave} />
                </Suspense>
            </Page.Section>
        </ProfilePageWithSidebar>
    )
}

export default MyProfile
