import { ReactNode } from 'react'

import { colors, radius, Container, Flexer, Spacer } from '@scouts/ui'

interface VerificationWidgetProps {
    actions: ReactNode
    background?: string
    children: ReactNode
}

export const VerificationWidget = ({ actions, background, children }: VerificationWidgetProps) => {
    return (
        <Container radius={radius.medium} background={background || colors.neutralLightest} padding="24px">
            <Flexer column tabletRow>
                <Spacer flexFill marginBottom="18px" tabletMargin="0">
                    {children}
                </Spacer>
                <Flexer column gap="12px">
                    {actions}
                </Flexer>
            </Flexer>
        </Container>
    )
}
