import { generatePath, Link } from 'react-router-dom'

import { colors, font, Flexer, Label, Line, Spacer, Table } from '@scouts/ui'

import { PATH_CUSTOMER_TAX_CONSULTATION, TAX_CONSULTATION_STATUS_STARTED } from '@/constants'
import { formatDateWithTime, formatTimeFromNow } from '@/helpers'
import { TaxConsultationSummaryDTO, TaxConsultationTopicTitles } from '@/models'

import { useUser } from '@/hooks/use-user'

import { AccountantStatusBubble } from '../accountants/AccountantStatusBubble'
import NotesIndicator from '../NotesIndicator'
import { TaxConsultationStatusLabel } from './TaxConsultationStatusLabel'

interface TaxConsultationsTableRowProps {
    taxConsultation: TaxConsultationSummaryDTO
    triggerTableUpdate: () => void
}

export const TaxConsultationsTableRow = ({ taxConsultation, triggerTableUpdate }: TaxConsultationsTableRowProps) => {
    const { isAdminUser } = useUser()
    const {
        accountantFullName,
        accountantStatus,
        customerId,
        customerFullName,
        customerEmail,
        id,
        hasPaid,
        priorityUnresolvedNotes,
        status,
        statusChangedDateUTC,
        topics,
        unresolvedNoteCount,
        scheduledDateUtc,
    } = taxConsultation

    const statusUpdatedAgo = formatTimeFromNow(statusChangedDateUTC)
    const hasTopics = topics && topics.length > 0
    const customerPath = generatePath(PATH_CUSTOMER_TAX_CONSULTATION, { customerId, taxConsultationId: id })

    return (
        <Table.Body.Row key={id}>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer column gap="6px">
                        <Line weight={font.weight.medium} lineHeight="1">
                            {customerFullName}
                        </Line>
                        <Line size={font.small} color={colors.neutralDarker} lineHeight="1">
                            {customerEmail}
                        </Line>

                        {isAdminUser && accountantFullName && (
                            <Flexer gap="6px" alignCenter>
                                <Line size={font.small} color={colors.neutralDarker}>
                                    {accountantFullName}
                                </Line>

                                <AccountantStatusBubble status={accountantStatus} />
                            </Flexer>
                        )}
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="16%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer gap="6px" flexWrap>
                        <TaxConsultationStatusLabel status={status || ''} />
                        {hasPaid && <Label background={colors.mintLighter}>Paid</Label>}
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="12%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    {status !== TAX_CONSULTATION_STATUS_STARTED ? (
                        <Line size={font.small}>
                            {scheduledDateUtc ? formatDateWithTime(scheduledDateUtc) : 'Booked off-platform'}
                        </Line>
                    ) : (
                        ''
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="22%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Spacer marginRight="18px">
                        <Line size={font.small}>
                            {hasTopics &&
                                topics.map(({ topicType }) => TaxConsultationTopicTitles[topicType]).join(', ')}
                        </Line>
                    </Spacer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="20%">
                <Table.Body.Cell.Content>
                    <NotesIndicator
                        notePreviews={priorityUnresolvedNotes}
                        numberOfNotes={unresolvedNoteCount || 0}
                        customerId={customerId}
                        customerFullName={customerFullName}
                        triggerTableUpdate={triggerTableUpdate}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.Content alignRight>
                    <Line size={font.small}>{statusUpdatedAgo}</Line>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}
