import { useCreateCompanyMutation } from '../api'

export const useCreateCompany = () => {
    const [createCompany, { isLoading }] = useCreateCompanyMutation()

    return {
        createCompany,
        isLoading,
    }
}
