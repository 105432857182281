import React from 'react'

import { DATA_SOURCE_PORTIFY } from '@/constants'
import { formatCurrency, formatCurrencyNotSure, formatDate } from '@/helpers'
import { SelfAssessmentDTO, SelfEmploymentProfileDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

interface SelfEmploymentProps {
    selfEmployment: SelfAssessmentDTO['selfEmployment']
    selfEmploymentProfile: SelfEmploymentProfileDTO
    uberData: SelfAssessmentDTO['uberData']
    userIncomePlatformIncomeReportsFinished: SelfAssessmentDTO['userIncomePlatformIncomeReportsFinished']
    userIncomePlatformIncomeReportsInProgress: SelfAssessmentDTO['userIncomePlatformIncomeReportsInProgress']
}

export const SelfEmployment = ({
    selfEmployment,
    selfEmploymentProfile,
    uberData,
    userIncomePlatformIncomeReportsFinished,
    userIncomePlatformIncomeReportsInProgress,
}: SelfEmploymentProps) => {
    const businessName = selfEmploymentProfile?.businessName || ''
    const businessDescription = selfEmploymentProfile?.businessDescription || ''
    const businessAddressStreetAddress = selfEmploymentProfile?.businessAddressStreetAddress || ''
    const businessAddressPostcode = selfEmploymentProfile?.businessAddressPostcode || ''
    const businessAddressCity = selfEmploymentProfile?.businessAddressCity || ''
    const selfEmployedStartDate = selfEmploymentProfile?.selfEmployedStartDate || ''

    const hasAnsweredCovidSupport = selfEmployment?.hasReceivedCovidSupportPayments !== null

    const hasConfirmedIncomePlatformData = !!userIncomePlatformIncomeReportsFinished?.find(
        (item) => !!item?.data?.confirmedByUser
    )

    const hasIncomePlatformData =
        hasConfirmedIncomePlatformData ||
        (userIncomePlatformIncomeReportsInProgress && userIncomePlatformIncomeReportsInProgress?.length > 0)

    return (
        <>
            <SectionData>
                <SectionDataRow label="Business name" value={businessName} />
                <SectionDataRow label="Business description" value={businessDescription} />
                <SectionDataRow
                    label="Business address"
                    value={`${businessAddressStreetAddress || ''} ${businessAddressPostcode || ''} ${
                        businessAddressCity || ''
                    }`}
                />
                <SectionDataRow label="Self-employment category" value={selfEmployment?.selfEmploymentCategory} />

                <SectionDataRow
                    label={selfEmployment?.incomeAmountConfirmed ? 'Income' : 'Approximate income'}
                    value={formatCurrencyNotSure(selfEmployment?.approximateIncome)}
                    highlight
                    isVerified={
                        selfEmployment?.dataSource === DATA_SOURCE_PORTIFY && selfEmployment?.incomeAmountConfirmed
                    }
                />

                <SectionDataRow
                    label="Using data from Bookkeeping"
                    value={selfEmployment?.useBookkeepingDataAsSource}
                    isVerified={selfEmployment?.useBookkeepingDataAsSource}
                />

                {selfEmployment?.dataSource && (
                    <SectionDataRow label="Income synchronised from" value={selfEmployment?.dataSource} />
                )}

                {selfEmployment?.incomeAmountConfirmed && (
                    <SectionDataRow
                        label="Income amount confirmed"
                        value={selfEmployment?.incomeAmountConfirmed}
                        boolean
                    />
                )}

                {uberData && (
                    <SectionDataRow label="Income from Uber" value={formatCurrency(uberData.totalAmount)} isVerified />
                )}

                {hasAnsweredCovidSupport && (
                    <SectionDataRow
                        label="Received Coronavirus support payments"
                        value={selfEmployment?.hasReceivedCovidSupportPayments}
                        boolean
                    />
                )}

                {hasAnsweredCovidSupport && !!selfEmployment?.hasReceivedCovidSupportPayments && (
                    <SectionDataRow
                        label="Coronavirus support payment amount"
                        value={formatCurrencyNotSure(selfEmployment.covidSupportPaymentsAmount)}
                    />
                )}

                <SectionDataRow label="Registered with HMRC" value={selfEmployment?.registeredWithHmrc} boolean />

                <SectionDataRow
                    label="Start of self-employment"
                    value={selfEmployedStartDate ? formatDate(selfEmployedStartDate) : '?'}
                />

                {selfEmployment?.additionalNotes && (
                    <SectionDataRow label="Notes" value={selfEmployment.additionalNotes} />
                )}
            </SectionData>

            {hasIncomePlatformData && (
                <SectionData>
                    <SectionDataRow label="From gig platforms" highlight />

                    {userIncomePlatformIncomeReportsFinished?.map(({ slug, name, data }) => {
                        const { confirmedByUser, confirmationNotes, total } = data || {}

                        if (!confirmedByUser) return null

                        return (
                            <React.Fragment key={slug}>
                                <SectionDataRow
                                    label={`Total income from ${name}`}
                                    value={formatCurrencyNotSure(total)}
                                    isVerified
                                />

                                {!!confirmationNotes && (
                                    <SectionDataRow label={`Notes for ${name}`} value={confirmationNotes} />
                                )}
                            </React.Fragment>
                        )
                    })}

                    {userIncomePlatformIncomeReportsInProgress?.map(({ slug, name, data }) => (
                        <SectionDataRow
                            key={`inprogress-${slug}`}
                            label={`Total income from ${name}`}
                            value="In progress…"
                            isVerified={data?.confirmedByUser}
                        />
                    ))}
                </SectionData>
            )}
        </>
    )
}
