import { PaymentStatuses } from '@/constants'

import { RefundReasonDTO } from './refunds'
import { UserType } from './user'

export const TaxReliefClaimStatus = {
    Started: 'Started',
    Submitted: 'Submitted',
    InformationRequested: 'InformationRequested',
    AwaitingSignature: 'AwaitingSignature',
    SignatureReceived: 'SignatureReceived',
    ReadyToSend: 'ReadyToSend',
    Sent: 'Sent',
    Cancelled: 'Cancelled',
} as const

export const TaxReliefClaimType = {
    EIS: 'EIS',
} as const

export const TaxReliefClaimFileType = {
    TaxReliefClaimCertificate: 'TaxReliefClaimCertificate',
    TaxReliefClaimCompletedForm: 'TaxReliefClaimCompletedForm',
    TaxReliefClaimProofOfPostage: 'TaxReliefClaimProofOfPostage',
} as const

export const TaxReliefClaimAccountantFileTypes = {
    TaxReliefClaimCompletedForm: 'TaxReliefClaimCompletedForm',
    TaxReliefClaimProofOfPostage: 'TaxReliefClaimProofOfPostage',
} as const

export interface TaxReliefClaimDTO {
    id: number
    certificateCount: number
    customerId: number
    customerFullName: string | null
    customerEmail: string | null
    supportUserId: number | null
    supportUserFullName: string | null
    hasPaid: boolean
    price: number
    refundThroughTaxCode: boolean | null
    claimType: keyof typeof TaxReliefClaimType
    status: keyof typeof TaxReliefClaimStatus
    statusChangedDateUTC: string
    submittedDateUTC: string | null
    awaitingSignatureDateUTC: string | null
    signatureReceivedDateUTC: string | null
    readyToSendDateUTC: string | null
    sentDateUTC: string | null
    cancelledDateUTC: string | null
    refundDateUTC: string | null
    refundReason: RefundReasonDTO
    paymentDateUTC: string | null
    paymentStatus: PaymentStatuses | null
}

export interface TaxReliefClaimEditDTO {
    status?: keyof typeof TaxReliefClaimStatus | null
    supportUserId?: number | null
    refundThroughTaxCode?: boolean | null
}

export interface TaxReliefClaimFileDTO {
    id: number
    taxReliefClaimId: number
    isClaimForPreviousYear: boolean
    fileDescription: string | null
    fileName: string
    fileOwnerType: keyof typeof UserType
    fileType: keyof typeof TaxReliefClaimFileType
    contentType: string | null
    uploadedDateUTC: string
}

export interface TaxReliefClaimOptions {
    sortOrder?: string
    sortType?: string
    supportUserId?: TaxReliefClaimDTO['supportUserId'] | null
    status?: TaxReliefClaimDTO['status'] | null
    submittedDate?: {
        start: string
        end: string
    }
    awaitingSignatureDate?: {
        start: string
        end: string
    }
    signatureReceivedDate?: {
        start: string
        end: string
    }
    readyToSendDate?: {
        start: string
        end: string
    }
    sentDate?: {
        start: string
        end: string
    }
    cancelledDate?: {
        start: string
        end: string
    }
    page?: number
    pageSize?: number
}

export enum TaxReliefClaimOptionsFilter {
    AWAITING_SIGNATURE_DATE = 'awaitingSignatureDate',
    CANCELLED_DATE = 'cancelledDate',
    READY_TO_SEND_DATE = 'readyToSendDate',
    SENT_DATE = 'sentDate',
    SIGNATURE_RECEIVED_DATE = 'signatureReceivedDate',
    STATUS = 'status',
    SUBMITTED_DATE = 'submittedDate',
    SUPPORT_USER_ID = 'supportUserId',
}

export const TaxReliefClaimOptionsFilters = [
    TaxReliefClaimOptionsFilter.STATUS,
    TaxReliefClaimOptionsFilter.SUPPORT_USER_ID,
    TaxReliefClaimOptionsFilter.SUBMITTED_DATE,
    TaxReliefClaimOptionsFilter.AWAITING_SIGNATURE_DATE,
    TaxReliefClaimOptionsFilter.SIGNATURE_RECEIVED_DATE,
    TaxReliefClaimOptionsFilter.READY_TO_SEND_DATE,
    TaxReliefClaimOptionsFilter.SENT_DATE,
    TaxReliefClaimOptionsFilter.CANCELLED_DATE,
] as const
