import { useCallback } from 'react'

import { useLazyDownloadCompanyReturnFileQuery, DownloadCompanyReturnFileParams } from '../api'

export const useDownloadCompanyReturnFile = () => {
    const [action, { error, isLoading }] = useLazyDownloadCompanyReturnFileQuery()

    const downloadCompanyReturnFile = useCallback(
        ({ companyId, companyReturnId, customerId, fileId }: DownloadCompanyReturnFileParams) =>
            action({ companyId, companyReturnId, customerId, fileId }).then((response) => {
                if (response.data) window.location.assign(response.data)
            }),
        [action]
    )

    return {
        downloadCompanyReturnFile,
        error,
        isLoading,
    }
}
