import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { colors, font, media, Button } from '@scouts/ui'

import { clickedOnScrollbar } from '../../helpers'
import { FilterDropdown } from '../ui/filter'

const DropdownContainer = styled.div`
    position: relative;
`

const Item = styled.button`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: left;
    display: block;
    width: 100%;
    padding: 9px 0;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }

    &:first-child {
        margin-top: -9px;
    }

    &:last-child {
        margin-bottom: -9px;
    }
`

const propTypes = {
    replaceFilters: PropTypes.func.isRequired,
}

const RewardsFilterSet = (props) => {
    const { replaceFilters } = props

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const openDropdown = () => {
        setIsDropdownOpen(true)
    }

    const closeDropdown = () => {
        setIsDropdownOpen(false)
    }

    const closeDropdownOnClick = (e) => {
        if (!clickedOnScrollbar(e.pageX)) {
            closeDropdown()
        }
    }

    const ref = useRef()
    useOnClickOutside(ref, closeDropdownOnClick)

    const addFilter = (filter) => {
        replaceFilters(filter)
        closeDropdown()
    }

    const filters = [
        {
            defaultFilter: {
                referredByUserHasBankDetails: true,
                referredUserHasPaid: true,
                referredByUserRewarded: false,
                ignored: false,
            },
            label: 'Ready to pay',
        },
    ].filter((o) => !o.shouldHide)

    return (
        <>
            <Button isSecondary size="small" onClick={openDropdown}>
                Shortcuts
            </Button>

            <DropdownContainer ref={ref}>
                {isDropdownOpen && (
                    <FilterDropdown>
                        {filters.map(({ defaultFilter, label }) => (
                            <Item key={label} onClick={() => addFilter(defaultFilter)}>
                                {label}
                            </Item>
                        ))}
                    </FilterDropdown>
                )}
            </DropdownContainer>
        </>
    )
}

RewardsFilterSet.propTypes = propTypes

export default RewardsFilterSet
