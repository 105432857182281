import { useState } from 'react'

import {
    Button,
    DatePicker,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Notice,
    TextArea,
} from '@scouts/ui'

import { createNote } from '@/actions'
import { CURRENT_TAX_YEAR } from '@/constants'
import { isDatePast } from '@/helpers'
import { CompanyReturnDTO, CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'
import { useUpdateCompanyReturn } from '@/store/company-returns/hooks'

interface CompanyReturnOnHoldModalProps {
    onClose: () => void
    customer: CustomerDTO
    companyReturn: CompanyReturnDTO
}

export const CompanyReturnOnHoldModal = ({ customer, companyReturn, onClose }: CompanyReturnOnHoldModalProps) => {
    const dispatch = useAppDispatch()

    const [errorMessage, setErrorMessage] = useState('')
    const [onHoldUntilDate, setOnHoldUntilDate] = useState(companyReturn.onHoldUntilDate || '')
    const [noteText, setNoteText] = useState('')

    const { updateCompanyReturn, isLoading: isLoadingUpdateCompany } = useUpdateCompanyReturn()

    const handleSubmit = () => {
        if (!isDatePast(onHoldUntilDate)) {
            if (noteText && noteText.trim().length > 0) {
                dispatch(createNote({ noteText, customerId: customer.id }))
            }

            updateCompanyReturn({
                companyReturnId: companyReturn.id,
                companyId: companyReturn.companyId,
                customerId: customer.id,
                data: { ...companyReturn, onHoldUntilDate },
            })

            setErrorMessage('')

            onClose()
        } else {
            setErrorMessage('Please choose a date in the future')
        }
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Set as 'On hold'</ModalTitle>
                    <ModalSubtitle>{customer?.fullName || 'This customer'}'s company tax return</ModalSubtitle>
                </ModalHeader>

                {errorMessage && <Notice>{errorMessage}</Notice>}

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Until</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker
                                name="onHoldUntilDate"
                                value={onHoldUntilDate}
                                onChange={({ value }) => setOnHoldUntilDate(value)}
                                minDate={`${CURRENT_TAX_YEAR}-01-01`}
                                maxDate={`${CURRENT_TAX_YEAR + 1}-12-31`}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Add a note (optional)</Form.Row.Title>
                        <Form.Row.Content>
                            <TextArea name="noteText" value={noteText} onChange={({ value }) => setNoteText(value)} />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onClose} isSecondary>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={isLoadingUpdateCompany}>
                            Set as 'On hold'
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
