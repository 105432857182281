import { generateApiPath } from '@scouts/helpers'

import { CompanyDTO, CustomerDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

export interface GetCompanyParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
}

export interface GetCompaniesParams {
    customerId: CustomerDTO['id']
}

export interface UpdateCompanyParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companiesHouseAuthCode: string | null
    uniqueTaxpayerReference: string | null
}

export interface CreateCompanyParams {
    customerId: CustomerDTO['id']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCompany: builder.query<CompanyDTO, GetCompanyParams>({
            query: ({ customerId, companyId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANIES.GET.SINGLE, { customerId, companyId }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.Company, id: arg.companyId }],
        }),
        getCompanies: builder.query<CompanyDTO[], GetCompaniesParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANIES.GET.ALL, { customerId }),
            }),
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: Tags.Company, id })), Tags.Company] : [Tags.Company],
        }),
        updateCompany: builder.mutation<CompanyDTO, UpdateCompanyParams>({
            query: ({ companiesHouseAuthCode, uniqueTaxpayerReference, customerId, companyId }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.COMPANIES.PUT.SINGLE, { customerId, companyId }),
                body: {
                    companiesHouseAuthCode,
                    uniqueTaxpayerReference,
                },
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.Company, id: arg.companyId }],
        }),
        createCompany: builder.mutation<CompanyDTO, CreateCompanyParams>({
            query: ({ customerId }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.COMPANIES.POST, { customerId }),
            }),
            invalidatesTags: (result) =>
                result ? [{ type: Tags.Company, id: result.id }, Tags.Company] : [Tags.Company],
        }),
    }),
})

export const { useGetCompaniesQuery, useGetCompanyQuery, useUpdateCompanyMutation, useCreateCompanyMutation } =
    extendedApi
