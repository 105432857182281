import { useState } from 'react'

import { matchErrorResponse, matchSuccessResponse, useToggle } from '@scouts/helpers'
import {
    Button,
    ButtonLink,
    Flexer,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Radio,
    Spacer,
} from '@scouts/ui'

import { getCompanyNameNumberedPlaceholder } from '@/domain'
import { useCompanies, useCreateCompany } from '@/store/companies/hooks'
import { useCreateCompanyReturn } from '@/store/company-returns/hooks'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

export const CreateCompanyReturn = () => {
    const customerId = useCustomerIdFromPath()

    const { companies, isLoading: isLoadingCompanies } = useCompanies({ customerId })
    const { createCompany, isLoading: isLoadingCreateCompany } = useCreateCompany()
    const { createCompanyReturn, isLoading: isLoadingCreateCompanyReturn } = useCreateCompanyReturn()

    const isSubmitLoading = isLoadingCompanies || isLoadingCreateCompany || isLoadingCreateCompanyReturn

    const [selectedCompanyId, setSelectedCompanyId] = useState<number | 'new' | null>(null)
    const [isModalOpen, openModal, closeModal] = useToggle()

    const handleClose = () => {
        setSelectedCompanyId(null)
        closeModal()
    }

    const handleCreate = () => {
        if (isSubmitLoading) return

        if (!selectedCompanyId) return

        if (selectedCompanyId === 'new') {
            createCompany({ customerId })
                .then(matchErrorResponse(handleClose))
                .then(
                    matchSuccessResponse((createdCompany) => {
                        createCompanyReturn({ customerId, companyId: createdCompany.id })
                            .then(matchErrorResponse(handleClose))
                            .then(matchSuccessResponse(handleClose))
                    })
                )
        } else {
            createCompanyReturn({ customerId, companyId: selectedCompanyId }).then(matchSuccessResponse(handleClose))
        }
    }

    return (
        <>
            <ButtonLink icon="plus" onClick={openModal}>
                Add new
            </ButtonLink>

            {isModalOpen && (
                <Modal onClose={handleClose}>
                    <ModalHeader>
                        <ModalTitle>Add a new company tax return</ModalTitle>
                        <ModalSubtitle>Select the company</ModalSubtitle>
                    </ModalHeader>

                    <ModalBody>
                        <Flexer column gap="12px">
                            {!companies
                                ? null
                                : companies.map((company) => {
                                      return (
                                          <Spacer key={company.id}>
                                              <Radio
                                                  checked={selectedCompanyId === company.id}
                                                  label={
                                                      company.name ||
                                                      getCompanyNameNumberedPlaceholder(company, companies)
                                                  }
                                                  name="companyId"
                                                  onChange={({ value }) => setSelectedCompanyId(Number(value))}
                                                  value={company.id}
                                              />
                                          </Spacer>
                                      )
                                  })}

                            <Spacer>
                                <Radio
                                    checked={selectedCompanyId === 'new'}
                                    label="Create new company"
                                    name="companyId"
                                    onChange={() => setSelectedCompanyId('new')}
                                    value="new"
                                />
                            </Spacer>
                        </Flexer>
                    </ModalBody>

                    <ModalFooter>
                        <ModalActions>
                            <Button isSecondary onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button onClick={handleCreate} disabled={!selectedCompanyId || isSubmitLoading}>
                                Create
                            </Button>
                        </ModalActions>
                    </ModalFooter>
                </Modal>
            )}
        </>
    )
}
