import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, media, shadows, Flexer, Line } from '@scouts/ui'

import { PATH_CUSTOMERS } from '../../constants'
import QuickSearchResultsServices from './QuickSearchResultsServices'

const Container = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 320px;
    padding-top: 6px;
    background: ${colors.white};
    box-shadow: ${shadows.high};

    ${media.tablet} {
        max-height: 480px;
    }
`

const Header = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    margin: 12px 0 12px;
    padding: 0 18px;
`

const CustomerContainer = styled.div`
    padding: 6px 0;
    border-left: 2px solid ${colors.white};

    &:not(:last-child) {
        border-bottom: 1px solid ${colors.neutralLightest};
    }

    ${media.pointer} {
        &:hover {
            border-color: ${colors.blue};
            outline: 0;

            &:not(:last-child) {
                border-bottom: 1px solid ${colors.neutralLightest};
            }
        }
    }

    &:focus {
        border-color: ${colors.blue};
        outline: 0;

        &:not(:last-child) {
            border-bottom: 1px solid ${colors.neutralLightest};
        }
    }
`

const Customer = styled(Link)`
    text-decoration: none;
    display: block;
    padding: 9px 18px;
`

const QuickSearchResults = ({ customers, onClick, title }) => (
    <Container>
        {title && <Header>{title}</Header>}
        {customers.map((customer) => {
            const { id: customerId, fullName, email, companies } = customer
            const firstCompanyName = companies?.[0]?.name

            return (
                <CustomerContainer key={customerId}>
                    <Customer to={`${PATH_CUSTOMERS}/${customerId}`} onClick={onClick}>
                        <Flexer column>
                            {firstCompanyName && (
                                <Line color={colors.line.neutral} size={font.body.small} weight={font.weight.medium}>
                                    {firstCompanyName}{' '}
                                    {companies.length > 1 && (
                                        <>
                                            <br />+{companies.length - 1} more
                                        </>
                                    )}
                                </Line>
                            )}
                            <Line color={colors.line.base} size={font.body.medium} weight={font.weight.medium}>
                                {fullName}
                            </Line>
                            <Line color={colors.line.base} size={font.body.medium}>
                                {email}
                            </Line>
                        </Flexer>
                    </Customer>

                    <QuickSearchResultsServices customer={customer} />
                </CustomerContainer>
            )
        })}
    </Container>
)

QuickSearchResults.propTypes = {
    customers: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

export default QuickSearchResults
