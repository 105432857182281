import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Table } from '@scouts/ui'

import ReturningCustomersTableRow from './ReturningCustomersTableRow'

const Container = styled.div`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const ReturningCustomersTable = (props) => {
    const {
        handleCustomerSelection,
        selectedItems,
        selectAll,
        customers,
        deselectAll,
        isFetching,
        triggerTableUpdate,
    } = props

    const hasSelectedItems = selectedItems.length > 0

    return (
        <Container isFetching={isFetching}>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell width="25%">Client</Table.Header.Cell>
                        <Table.Header.Cell width="16%" />
                        <Table.Header.Cell width="17%">Tax year</Table.Header.Cell>
                        <Table.Header.Cell width="12%">Updated</Table.Header.Cell>
                        <Table.Header.Cell width="calc(15%)" alignRight>
                            Notes
                        </Table.Header.Cell>
                        <Table.Header.Cell width="calc(15% - 96px)" alignRight>
                            Messages
                        </Table.Header.Cell>
                        <Table.Header.Cell width="96px" alignRight action={hasSelectedItems ? deselectAll : selectAll}>
                            {hasSelectedItems ? 'Deselect all' : 'Select all'}
                        </Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>
                <Table.Body>
                    {customers.map((customer) => {
                        const { id } = customer
                        const isSelected = selectedItems.findIndex((o) => o.customerId === id) > -1
                        return (
                            <ReturningCustomersTableRow
                                key={id}
                                handleSelect={handleCustomerSelection}
                                isSelected={isSelected}
                                customer={customer}
                                triggerTableUpdate={triggerTableUpdate}
                            />
                        )
                    })}
                </Table.Body>
            </Table>
        </Container>
    )
}

ReturningCustomersTable.propTypes = {
    customers: PropTypes.array.isRequired,
    deselectAll: PropTypes.func.isRequired,
    handleCustomerSelection: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    selectAll: PropTypes.func.isRequired,
    selectedItems: PropTypes.array.isRequired,
    triggerTableUpdate: PropTypes.func.isRequired,
}

export default connect()(ReturningCustomersTable)
