import { useUpdateTaxConsultationStatusMutation } from '../api'

export const useUpdateTaxConsultationStatus = () => {
    const [updateTaxConsultationStatus, { error, isLoading }] = useUpdateTaxConsultationStatusMutation()

    return {
        updateTaxConsultationStatus,
        error,
        isLoading,
    }
}
