import { useIsTablet, Select, Tabs } from '@scouts/ui'

import { TaxConsultationDomain } from '@/domain'
import { TaxConsultationStatusFilters, TaxConsultationStatusFilterTypes } from '@/models'
import { useTaxConsultationsStatistics } from '@/store/tax-consultations'

import { useFilters } from './hooks/use-filters'

import { usePagination } from '../pagination'

export const TaxConsultationsFilters = () => {
    const { activeFilters: paginationFilters } = usePagination()
    const { activeFilters, applyFilter } = useFilters()
    const isTablet = useIsTablet()

    const { status: filterStatus } = activeFilters

    const updateFilter = (status: TaxConsultationStatusFilterTypes | null) => {
        applyFilter({ updatedFilter: { status, page: 1 } })
    }

    const { taxConsultationsStatistics } = useTaxConsultationsStatistics({
        options: { ...activeFilters, ...paginationFilters },
    })

    const countPerFilter = (status: TaxConsultationStatusFilterTypes | null) => {
        if (status === null) {
            return taxConsultationsStatistics?.reduce((sum, item) => sum + item.count, 0) || 0
        }

        let count = 0

        TaxConsultationStatusFilters[status]?.forEach((item) => {
            const itemValue = taxConsultationsStatistics?.find(({ status }) => status === item)?.count || 0

            return (count += itemValue)
        })

        return count
    }

    if (!isTablet)
        return (
            <Select
                name="filterStatus"
                options={[{ title: 'All' }, ...TaxConsultationDomain.filterOptions]}
                value={filterStatus || ''}
                onChange={({ value }) => updateFilter(value as TaxConsultationStatusFilterTypes)}
            />
        )

    return (
        <Tabs>
            <Tabs.Item onClick={() => updateFilter(null)} isActive={filterStatus === null}>
                All
                <Tabs.Item.Counter>{countPerFilter(null)}</Tabs.Item.Counter>
            </Tabs.Item>
            {TaxConsultationDomain.filterOptions.map(({ value, title }) => (
                <Tabs.Item key={value} onClick={() => updateFilter(value)} isActive={filterStatus === value}>
                    {title}
                    <Tabs.Item.Counter>{countPerFilter(value)}</Tabs.Item.Counter>
                </Tabs.Item>
            ))}
        </Tabs>
    )
}
