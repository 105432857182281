export const DRAFT_MESSAGES_STORAGE_KEY = 'draftMessages'

export enum EmailProvider {
    Gmail = 'Gmail',
    Outlook = 'Outlook',
}

// @TODO: Remove once all usages are updated to TypeScript enums
export const EMAIL_PROVIDER_OUTLOOK = EmailProvider.Outlook
export const EMAIL_PROVIDER_GMAIL = EmailProvider.Gmail

export const MESSAGE_TYPE_EMAIL = 'Email'
export const MESSAGE_TYPE_SMS = 'Sms'

export const MESSAGE_SMS_LENGTH_CAP = 160

export const MESSAGE_TEXT_TYPE_GENERAL = 'MESSAGE_TEXT_TYPE_GENERAL'
export const MESSAGE_TEXT_TYPE_START_RETURN = 'MESSAGE_TEXT_TYPE_START_RETURN'
export const MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL = 'MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL'

export const MESSAGE_TEXT_TYPE_GENERAL_SUBJECT = 'Message from TaxScouts'
export const MESSAGE_TEXT_TYPE_GENERAL_ACCOUNTANT_SUBJECT = 'Message from TaxScouts accountant'
export const MESSAGE_TEXT_TYPE_START_RETURN_SUBJECT = 'Your tax return has been drafted - ready to start'
export const MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_SUBJECT = 'Agent authorisation was unsuccessful'

export const MESSAGE_USER_TYPE_ADMIN_SIGNATURE = 'Your TaxScouts Team'

export const MESSAGE_TEXT_TYPE_GENERAL_CONTENT = ''

export const MESSAGE_TEXT_TYPE_START_RETURN_CONTENT = `Just to let you know.

We've drafted your Self Assessment tax return for 2019/20.

It's ready for you to review whenever you want your tax return done.

It should take just a few minutes.

You can do it by logging in to TaxScouts.

And as always I’m here for any questions you might have.`

export const MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_CONTENT = `I’ve tried to authorise myself as your HMRC agent but was unsuccessful.

This tends to happen when the details you’ve uploaded to TaxScouts (such as your address and postcode) don’t match the details on record with HMRC.

Can you please check that the postcode and the UTR number you’ve added to your TaxScouts profile are correct?

If they are correct, you can update your details with HMRC by calling them on 0300 200 3310 or online at https://www.gov.uk/tell-hmrc-change-of-details.`

// Extension Allowed by Sendinblue
export const MESSAGE_ATTACHMENT_FILE_EXTENSIONS =
    '.xlsx, .xls, .ods, .docx, .docm, .doc, .csv, .pdf, .txt, .gif, .heic, .jpf, .jpg, .jpeg, .png, .tif, .tiff, .rtf, .bmp, .cgm, .css, .shtml, .html, .htm, .zip, .xml, .ppt, .pptx, .tar, .ez, .ics, .mobi, .msg, .pub, .eps, .odt'

const KILOBYTE = 1024
const MEGABYTE = 1024 * KILOBYTE

const RESERVED_MESSAGE_CONTENT_BUFFER = 75 * KILOBYTE

export const MESSAGE_ATTACHMENT_FILE_SIZE_SUM_LIMIT = 10 * MEGABYTE - RESERVED_MESSAGE_CONTENT_BUFFER

export const MESSAGE_ATTACHMENT_FILE_MIME_TYPES = [
    'text/plain', // .txt
    'application/vnd.oasis.opendocument.spreadsheet', // .ods
    'application/msword', // .doc, .dot
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //  .docx
    'application/vnd.ms-word.document.macroEnabled.12', // .docm
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xslx
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'text/x-csv', // .csv,
    'text/csv', // .csv,
    'text/css', // .css
    'application/pdf', // .pdf
    'image/gif', // .gif
    'image/jpeg', // .jpg, .jpeg
    'image/png', // .png,
    'image/tiff', // .tif, .tiff
    'application/rtf', // .rtf
    'image/bmp', // .bmp
    'text/html', // .htm, .html
    'application/zip', // .zip,
    'application/xml', // .xml
    'text/xml', // .xml
    'application/x-tar', // .tar
    'text/calendar', // .ics
    'image/cgm', // .cgm
    'wwwserver/html-ssi', // .shtml
    'application/x-mobipocket-ebook', // .mobi
    'image/eps', // .eps
    'image/x-eps', // .eps
]
