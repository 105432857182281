import { ReactNode } from 'react'

import { MessagesContext } from './messages-context'

interface MessagesContextProviderProps {
    children: ReactNode
    customerId: number
}

export const MessagesContextProvider = ({ children, customerId }: MessagesContextProviderProps) => {
    return <MessagesContext.Provider value={{ customerId }}>{children}</MessagesContext.Provider>
}
