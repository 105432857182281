import { createSelector } from '@reduxjs/toolkit'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'

import { RootState } from '@/store'

const pluckCustomerId = (_: RootState, { customerId }: { customerId: number }) => customerId
const pluckSelfAssessmentId = (_: RootState, { selfAssessmentId }: { selfAssessmentId: number }) => selfAssessmentId

const getSelfAssessments = (state: RootState) => state.selfAssessments
const getSelfAssessmentsList = (state: RootState) => getSelfAssessments(state).list
// const getSelfAssessmentsDetails = (state) => getSelfAssessments(state).details

export const getCustomerSelfAssessments = createSelector(
    [getSelfAssessmentsList, pluckCustomerId],
    (selfAssessmentsList, customerId) => {
        const customerSelfAssessments = _filter(selfAssessmentsList, { customerId })
        return _orderBy(customerSelfAssessments, 'taxYear', 'desc')
    }
)

export const getSelfAssessmentById = createSelector(
    [getSelfAssessmentsList, pluckSelfAssessmentId],
    (selfAssessmentsList, selfAssessmentId) => _find(selfAssessmentsList, ['id', Number(selfAssessmentId)])
)

export const getSelfAssessmentsApplicableForDiscount = createSelector(
    [getCustomerSelfAssessments],
    (customerSelfAssessments) => {
        const unpaidSelfAssessments = customerSelfAssessments.filter((assessment) => !assessment.hasPaid)
        const orderedSelfAssessments = _orderBy(unpaidSelfAssessments, 'taxYear', 'desc')

        return orderedSelfAssessments
    }
)
