import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonLink } from '@scouts/ui'

const Container = styled.div`
    flex: 0 0 100%;
    display: block;
    padding-bottom: 18px;
`

const PageBackButton = ({ children, to }) => (
    <Container role="navigation">
        <Link to={to}>
            <ButtonLink as="span">{children}</ButtonLink>
        </Link>
    </Container>
)

PageBackButton.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
}

export default PageBackButton
