import _sortBy from 'lodash/sortBy'
import { useCallback, useMemo, useState } from 'react'

import {
    useGetAccountantStatisticsQuery,
    useGetAccountantStatusStatisticsQuery,
    GetAccountantStatisticsParams,
} from '../api'

export const useFetchAccountantStatistics = () => {
    const [params, setParams] = useState<GetAccountantStatisticsParams>({})
    const shouldSkipInitialFetch = !Object.keys(params).length

    const {
        data: responseWithPagination,
        isFetching: isFetchingAccountants,
        refetch: refetchAccountants,
        isLoading: isLoadingAccountants,
    } = useGetAccountantStatisticsQuery(params, { skip: shouldSkipInitialFetch })

    const { data: accountantStatistics = [], pagination = {} } = responseWithPagination || {}

    const {
        data: accountantStatusStatistics = [],
        isFetching: isFetchingAccountantStatuses,
        refetch: refetchAccountantStatuses,
        isLoading: isLoadingAccountantStatuses,
    } = useGetAccountantStatusStatisticsQuery()

    const refetch = useCallback(() => {
        refetchAccountants()
        refetchAccountantStatuses()
    }, [refetchAccountants, refetchAccountantStatuses])

    const fetchAccountantStatistics = useCallback(
        (updatedParams: GetAccountantStatisticsParams, shouldRefetch: boolean) => {
            setParams(updatedParams)
            if (shouldRefetch) refetch()
        },
        [refetch]
    )

    const orderedAccountants = useMemo(
        () =>
            _sortBy(accountantStatistics, [
                ({ accountantFullName }) => (accountantFullName ? String(accountantFullName).toLowerCase() : ''),
            ]),
        [accountantStatistics]
    )

    return {
        fetchAccountantStatistics,
        accountantStatistics: orderedAccountants,
        accountantStatusStatistics,
        pagination,
        isFetching: isFetchingAccountantStatuses || isFetchingAccountants,
        isLoading: isLoadingAccountantStatuses || isLoadingAccountants,
    }
}
