import { Container } from '@scouts/ui'

import { formatCurrency, formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const Employment = ({ employments }: { employments: SelfAssessmentDTO['employments'] }) => {
    if (!employments) return null

    return (
        <Container>
            {employments?.map((employment, i) => (
                <SectionData key={employment.id}>
                    <SectionDataRow label={`Employer #${i + 1}`} value={employment.employerName} highlight />

                    {employment.synchedFromHmrc ? (
                        <>
                            <SectionDataRow label="Employer reference" value={employment.employerReference} />

                            <SectionDataRow label="Synched from HMRC" value={employment.synchedFromHmrc} boolean />

                            <SectionDataRow
                                label="Pay amount (from HMRC)"
                                value={formatCurrency(employment.payAmountFromHmrc)}
                                rawValue={employment.payAmountFromHmrc}
                                isVerified={employment.payAmountConfirmed}
                            />

                            <SectionDataRow
                                label="Tax paid (from HMRC)"
                                value={formatCurrency(employment.taxPaid)}
                                rawValue={employment.taxPaid}
                                isVerified={employment.payAmountConfirmed}
                            />

                            {!!employment.studentLoanPaid && employment.studentLoanPaid > 0 && (
                                <SectionDataRow
                                    label="Student loan repaid (from HMRC)"
                                    value={formatCurrency(employment.studentLoanPaid)}
                                    rawValue={employment.studentLoanPaid}
                                />
                            )}

                            <SectionDataRow
                                label="Pay amount from HMRC confirmed"
                                value={employment.payAmountConfirmed}
                                boolean
                            />

                            {!employment.payAmountConfirmed && (
                                <SectionDataRow
                                    label="Approximate income"
                                    value={formatCurrencyNotSure(employment.approximateIncome)}
                                />
                            )}

                            {!!employment.employmentBenefitsTotal && employment.employmentBenefitsTotal > 0 && (
                                <>
                                    <SectionDataRow
                                        label="Employment benefits (from HMRC)"
                                        value={formatCurrencyNotSure(employment.employmentBenefitsTotal)}
                                        isVerified={employment.benefitsAmountConfirmed}
                                    />

                                    {employment.employmentBenefits &&
                                        employment.employmentBenefits.length > 0 &&
                                        employment.employmentBenefits.map((benefit) => (
                                            <SectionDataRow
                                                key={benefit.id}
                                                label=""
                                                value={`${benefit.benefitType} – ${formatCurrency(benefit.amount)}`}
                                            />
                                        ))}

                                    <SectionDataRow
                                        label="Employment benefits from HMRC confirmed"
                                        value={employment.benefitsAmountConfirmed}
                                        boolean
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <SectionDataRow
                                label="Approximate income"
                                value={formatCurrencyNotSure(employment.approximateIncome)}
                            />
                        </>
                    )}

                    {!employment.payAmountConfirmed && (
                        <SectionDataRow
                            label="Employed at the end of the tax year"
                            value={employment.employedAtEndOfTaxYear}
                            boolean
                        />
                    )}

                    <SectionDataRow label="Is director" value={employment.isDirector} boolean />

                    {employment.additionalNotes && <SectionDataRow label="Notes" value={employment.additionalNotes} />}
                </SectionData>
            ))}
        </Container>
    )
}
