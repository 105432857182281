import { generateApiPath } from '@scouts/helpers'

import {
    CustomerProductSearchDTO,
    CustomerProductSearchProductStatus,
    CustomerProductSearchProductTypes,
    CustomerProductStatisticsDTO,
    ResponseWithPagination,
} from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'

export interface GetCustomersProductSearchParams {
    accountantId?: number
    productType?: CustomerProductSearchProductTypes | null
    productStatus?: CustomerProductSearchProductStatus | null
    page?: number
    pageSize?: number
}

export interface GetCustomersProductStatisticsParams {
    accountantId?: number
    productStatus?: CustomerProductSearchProductStatus | null
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerProductSearch: builder.query<
            ResponseWithPagination<CustomerProductSearchDTO[]>,
            GetCustomersProductSearchParams
        >({
            query: ({ accountantId, productType, productStatus, page, pageSize }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.CUSTOMERS_PRODUCT_SEARCH.GET, undefined, {
                    queryParameters: {
                        ...(accountantId && { accountantId }),
                        ...(productType && { productType }),
                        ...(productStatus && { productStatus }),
                        ...(page && { page }),
                        ...(pageSize && { pageSize }),
                    },
                }),
            }),
            providesTags: [Tags.CustomerProductSearch],
            transformResponse: mapPaginatedResponse,
        }),
        getCustomerProductStatistics: builder.query<CustomerProductStatisticsDTO, GetCustomersProductStatisticsParams>({
            query: ({ accountantId, productStatus }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.CUSTOMERS_STATISTICS.GET, undefined, {
                    queryParameters: {
                        ...(accountantId && { accountantId }),
                        ...(productStatus && { productStatus }),
                    },
                }),
            }),
            providesTags: [Tags.CustomerProductStatistics],
        }),
    }),
})

export const { useGetCustomerProductSearchQuery, useGetCustomerProductStatisticsQuery } = extendedApi
