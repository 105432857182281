import { useHistory } from 'react-router-dom'

import { ButtonLink, Notice } from '@scouts/ui'

import { PATH_INTEGRATIONS } from '@/constants'

export const PayeApiNotice = () => {
    const history = useHistory()

    return (
        <Notice>
            To automatically pull PAYE income information from HMRC connect your TaxScouts account to PAYE API. &nbsp;
            <ButtonLink
                onClick={() => {
                    history.push(PATH_INTEGRATIONS)
                }}
            >
                Start here
            </ButtonLink>
        </Notice>
    )
}
