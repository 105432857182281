import { colors, font, Flexer, Line } from '@scouts/ui'

import { CustomerDTO, IdentityVerificationDTO } from '@/models'

import { MarkAsCancelled } from '../actions/MarkAsCancelled'
import { ReviewContainer } from '../actions/ReviewContainer'
import { VerificationUploadModalLink } from '../VerificationUploadModalLink'
import { VerificationWidget } from '../VerificationWidget'

interface SubmittedProps {
    customer: CustomerDTO
    identityVerification: IdentityVerificationDTO
}

export const Submitted = ({ identityVerification, customer }: SubmittedProps) => {
    return (
        <VerificationWidget
            background={colors.blueLighter}
            actions={
                <>
                    <ReviewContainer
                        buttonLabel="Review now"
                        customer={customer}
                        identityVerification={identityVerification}
                    />

                    <MarkAsCancelled buttonLabel="Cancel request" identityVerification={identityVerification} />
                </>
            }
        >
            <Flexer column gap="9px" justifyStart>
                <Line weight={font.weight.medium}>Review provided documents</Line>

                <Line size={font.small}>Client has uploaded their documents, please review</Line>

                <VerificationUploadModalLink customerId={customer.id} identityVerification={identityVerification} />
            </Flexer>
        </VerificationWidget>
    )
}
