import { TaxReliefClaimOptions } from '@/models'

import { useGetTaxReliefClaimsQuery } from '../api'

export const useTaxReliefClaims = ({ options }: { options?: TaxReliefClaimOptions }) => {
    const { data: fetchedData, isLoading, isError } = useGetTaxReliefClaimsQuery({ options })
    const { data, pagination } = fetchedData || {}

    return {
        taxReliefClaims: data,
        isError,
        isLoading,
        pagination,
    }
}
