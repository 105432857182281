import { CompanyDTO } from '@/models'

/**
 * Use numbered company names, if there are multiple companies
 *
 * @example
 * const companies = [{ name: 'Company A' }, { name: 'Company B' }]
 * getCompanyNamePlaceholder(companies[0], companies) // 'Company 1'
 * getCompanyNamePlaceholder(companies[1], companies) // 'Company 2'
 *
 * // with a single company
 * const companies = [{ name: 'Company A' }]
 * getCompanyNamePlaceholder(companies[0], companies) // 'Company'
 */
export const getCompanyNameNumberedPlaceholder = (company?: CompanyDTO, companies?: CompanyDTO[]): string => {
    if (!company || !companies) return 'Company'

    const index = companies.indexOf(company)
    const companySequentialNumber = !companies || companies.length === 1 ? '' : ` ${index + 1}`
    return `Company${companySequentialNumber}`
}

/**
 * Create a numbered label to show company number out of the total companies.
 *
 * @example
 * const companies = [{ name: 'Company A' }, { name: 'Company B' }]
 * getCompanyNameNumberedLabel(companies[0], companies) // 'Company 1 of 2'
 * getCompanyNameNumberedLabel(companies[1], companies) // 'Company 2 of 2'
 *
 * // with a single company
 * const companies = [{ name: 'Company A' }]
 * getCompanyNameNumberedLabel(companies[0], companies) // 'Company'
 */
export const getCompanyNameNumberedLabel = (company?: CompanyDTO, companies?: CompanyDTO[]): string => {
    if (!company || !companies) return 'Company'

    const index = companies.indexOf(company)
    const companySequentialNumber = !companies || companies.length === 1 ? '' : ` ${index + 1}`
    const companyOutOfTotal = companies && companies.length > 1 ? ` of ${companies.length}` : ''
    return `Company${companySequentialNumber}${companyOutOfTotal}`
}
