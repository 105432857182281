import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
    font,
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    TextArea,
} from '@scouts/ui'

import { saveSelfAssessmentStatus } from '../actions'
import { PATH_CUSTOMERS } from '../constants'
import { MessageTemplateSelect } from './message-templates/MessageTemplateSelect'

const LinkToProfile = styled.div`
    font-size: ${font.small};
`

class SelfAssessmentStatusMessage extends Component {
    constructor() {
        super()

        this.state = { customMessage: '', messageTemplateId: '', isSubmitDisabled: false }

        _bindAll(this, ['handleCancel', 'handleSend', 'handleChange', 'handleMessageTemplateChange'])
    }

    handleMessageTemplateChange({ value: selectedTemplateId, template: selectedTemplate }) {
        const { messageContent, hasEditedTemplateMessage } = this.state

        if (messageContent && hasEditedTemplateMessage) {
            const isConfirmed = window.confirm('Are you sure?\n\nYou will lose the current message')
            if (!isConfirmed) return
        }

        if (selectedTemplateId) {
            this.setState({
                messageTemplateId: selectedTemplateId,
                customMessage: selectedTemplate?.content ?? '',
                hasEditedTemplateMessage: false,
            })
        } else {
            this.setState({
                messageTemplateId: '',
                customMessage: '',
                hasEditedTemplateMessage: false,
            })
        }
    }

    handleChange({ name, value }) {
        this.setState({
            [name]: value,
            hasEditedTemplateMessage: name === 'customMessage',
        })
    }

    handleCancel() {
        const { closeModal } = this.props
        const { customMessage } = this.state

        if (customMessage) {
            const shouldSendWithoutMessage = window.confirm(
                'Are you sure? The message you typed will not be sent to the client.'
            )
            if (!shouldSendWithoutMessage) return
        }

        this.save()

        closeModal()
    }

    handleSend() {
        const { customMessage } = this.state

        this.save(customMessage)
    }

    save(customMessage = null) {
        const { closeModal, dispatch, status, selfAssessment } = this.props

        const { id: selfAssessmentId } = selfAssessment

        const onSuccess = () => closeModal()
        const onError = () => this.setState({ isSubmitDisabled: false })

        this.setState({ isSubmitDisabled: true })
        dispatch(saveSelfAssessmentStatus({ selfAssessmentId, status, customMessage, onSuccess, onError }))
    }

    renderLinkToProfile() {
        const { selfAssessment } = this.props
        const { id: selfAssessmentId, customerId } = selfAssessment

        return (
            <LinkToProfile>
                <Link to={`${PATH_CUSTOMERS}/${customerId}/${selfAssessmentId}`} target="_blank">
                    Open profile in separate tab
                </Link>
            </LinkToProfile>
        )
    }

    render() {
        const { customMessage, messageTemplateId, isSubmitDisabled } = this.state

        return (
            <Modal onClose={this.handleCancel}>
                <Form onSubmit={this.handleSend}>
                    <ModalHeader>
                        <ModalTitle>Any notes or comments you'd want to send to the client with the return?</ModalTitle>
                        <ModalSubtitle>{this.renderLinkToProfile()}</ModalSubtitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Form.Row.Content>
                                Message template: &nbsp;&nbsp;
                                <MessageTemplateSelect
                                    name="messageTemplateId"
                                    value={messageTemplateId}
                                    onChange={this.handleMessageTemplateChange}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                        <TextArea
                            name="customMessage"
                            value={customMessage}
                            placeholder="Notes…"
                            onChange={this.handleChange}
                            height="144px"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ModalActions>
                            <Button onClick={this.handleCancel} isSecondary>
                                No, thank you
                            </Button>
                            <Button type="submit" disabled={isSubmitDisabled}>
                                Send
                            </Button>
                        </ModalActions>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

SelfAssessmentStatusMessage.propTypes = {
    closeModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    selfAssessment: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
}

export default connect()(SelfAssessmentStatusMessage)
