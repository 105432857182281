import { useHistory } from 'react-router-dom'

import { colors, Button, Flexer, Line, Page, Skeleton, SkeletonLine } from '@scouts/ui'

import { PATH_USERS_CREATE } from '@/constants'
import { useSupportUsers } from '@/store/support-users/hooks'

import ProfilePageWithSidebar from '../ProfilePageWithSidebar'
import { UsersTable } from './UsersTable'

export const UsersContainer = () => {
    const history = useHistory()

    const { supportUsers, isLoading, isError } = useSupportUsers()

    return (
        <ProfilePageWithSidebar>
            <Page.Heading>TaxScouts users</Page.Heading>
            <Page.Section>
                <Button
                    onClick={() => {
                        history.push(`${PATH_USERS_CREATE}#support`)
                    }}
                >
                    Add new
                </Button>
            </Page.Section>

            {isError && (
                <Flexer alignCenter justifyCenter>
                    <Line color={colors.neutralDarker}>Error getting users</Line>
                </Flexer>
            )}

            {isLoading && (
                <Skeleton gap="1px" padding="0" background={colors.white}>
                    <SkeletonLine repeat={8} height="72px" />
                </Skeleton>
            )}

            {supportUsers && <UsersTable users={supportUsers} />}
        </ProfilePageWithSidebar>
    )
}
