import styled from 'styled-components'

import { colors, Tooltip } from '@scouts/ui'

import { AccountantStatus } from '@/models'

const getBackgroundColor = (status?: string) => {
    switch (status) {
        case AccountantStatus.Active:
            return colors.mintDarker
        case AccountantStatus.Paused:
            return colors.orange
        default:
            return colors.red
    }
}

export const AccountantStatusBubble = ({ status }: { status?: string }) => {
    if (!status) return <Container background={getBackgroundColor()} />

    return (
        <Tooltip content={status}>
            <Container background={getBackgroundColor(status)} />
        </Tooltip>
    )
}

const Container = styled.div<{ background?: string }>`
    height: 9px;
    width: 9px;
    background: ${({ background }) => background};
    border-radius: 50%;
`
