import { createContext, Dispatch, SetStateAction } from 'react'

import { ConfigurationBaseOptions, ContextData } from '@scouts/bookkeeping'
import { CountryCode, CurrencyCode, CurrencySymbol, Locale, TimeZone } from '@scouts/helpers'

import config from '@/config'
import { PATH_CUSTOMER_BOOKKEEPING } from '@/constants'
import { featureFlags } from '@/feature-flags'
import { getAccessTokenAsync } from '@/helpers/access-token'

export interface BookkeepingConfigurationContextProps {
    config: ConfigurationBaseOptions
    data: ContextData | null
}

export const DEFAULT_CONTEXT: BookkeepingConfigurationContextProps = {
    config: {
        defaultCurrency: CurrencyCode.GBP,
        defaultCurrencySymbol: CurrencySymbol.GBP,
        featureFlags,
        countryCode: CountryCode.GB,
        locale: Locale.GB,
        timeZone: TimeZone.GB,
        rootPath: PATH_CUSTOMER_BOOKKEEPING,
        availableDateRangeFilterShortcuts: [],
        client: {
            url: config.apiUrlBookkeeping,
            token: getAccessTokenAsync,
        },
        isAccountant: true,
    },
    data: null,
}

export const BookkeepingConfigurationContext = createContext<
    [BookkeepingConfigurationContextProps, Dispatch<SetStateAction<BookkeepingConfigurationContextProps>>]
>([DEFAULT_CONTEXT, () => {}])
