import { colors, Indicator, Tooltip } from '@scouts/ui'

export const CustomerIndicatorsTaxCode = ({
    payThroughTaxCodeRequested,
}: {
    payThroughTaxCodeRequested?: boolean | null
}) => {
    const title = () => {
        switch (payThroughTaxCodeRequested) {
            case true:
                return 'Requested to pay through tax code'
            case false:
                return 'Requested NOT to pay through tax code'
            default:
                return 'Not specified payments through tax code'
        }
    }

    const tooltip = title()

    const background = () => {
        switch (payThroughTaxCodeRequested) {
            case true:
                return colors.mintLighter
            case false:
                return colors.red
            default:
                return colors.white
        }
    }

    return (
        <Indicator
            border={colors.neutralLightest}
            color={payThroughTaxCodeRequested === null ? colors.neutralDarker : colors.black}
            background={background()}
        >
            <Tooltip content={tooltip}>TAX</Tooltip>
        </Indicator>
    )
}
