import { generatePath, useRouteMatch } from 'react-router-dom'

import {
    PATH_CUSTOMER,
    PATH_CUSTOMER_ACCOUNTANT_MEETINGS,
    PATH_CUSTOMER_BOOKKEEPING,
    PATH_CUSTOMER_COMPANY_RETURN,
    PATH_CUSTOMER_CONSULTATIONS,
    PATH_CUSTOMER_DISCOUNTS,
    PATH_CUSTOMER_FILES,
    PATH_CUSTOMER_HISTORY,
    PATH_CUSTOMER_MESSAGES,
    PATH_CUSTOMER_SELF_ASSESSMENTS,
    PATH_CUSTOMER_TAX_RELIEF_CLAIMS,
} from '@/constants'
import { featureFlags } from '@/feature-flags'
import { useCustomersTaxConsultations } from '@/store/tax-consultations'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'
import { useUser } from '@/hooks/use-user'

import { useAccountantMeetingIdFromPath } from './use-accountant-meeting-id-from-path'
import { useCompanyIdFromPath } from './use-company-id-from-path'
import { useCompanyReturnIdFromPath } from './use-company-return-id-from-path'
import { useCustomerAccountantMeetings } from './use-customer-accountant-meetings'
import { useCustomerSelfAssessments } from './use-customer-self-assessments'
import { useCustomerTaxReliefClaims } from './use-customer-tax-relief-claims'
import { useCustomersCompanyReturns } from './use-customers-company-returns'
import { useSelfAssessmentIdFromPath } from './use-self-assessment-id-from-path'
import { useTaxConsultationIdFromPath } from './use-tax-consultation-id-from-path'
import { useTaxReliefClaimIdFromPath } from './use-tax-relief-claim-id-from-path'

export const useCustomerNav = () => {
    const { customer } = useCustomerFromPath()
    const { id: customerId } = customer

    const { isAdminUser } = useUser()

    const { defaultSelfAssessmentId } = useCustomerSelfAssessments({ customerId })
    const { defaultTaxConsultationId, hasTaxConsultations } = useCustomersTaxConsultations({ customerId })
    const { defaultAccountantMeetingId, hasAccountantMeetings } = useCustomerAccountantMeetings({ customerId })
    const {
        companyId: defaultCompanyId,
        defaultCompanyReturnId,
        hasCompanyReturns,
    } = useCustomersCompanyReturns({ customerId })
    const { defaultTaxReliefClaimId, hasTaxReliefClaims } = useCustomerTaxReliefClaims({
        customerId,
        skip: !isAdminUser,
    })

    const activeTaxConsultationId = useTaxConsultationIdFromPath()
    const activeSelfAssessmentId = useSelfAssessmentIdFromPath()
    const activeAccountantMeetingId = useAccountantMeetingIdFromPath()
    const activeTaxReliefClaimId = useTaxReliefClaimIdFromPath()
    const activeCompanyReturnId = useCompanyReturnIdFromPath()
    const activeCompanyId = useCompanyIdFromPath()

    const taxConsultationId = activeTaxConsultationId || defaultTaxConsultationId
    const selfAssessmentId = activeSelfAssessmentId || defaultSelfAssessmentId
    const accountantMeetingId = activeAccountantMeetingId || defaultAccountantMeetingId
    const taxReliefClaimId = activeTaxReliefClaimId || defaultTaxReliefClaimId
    const companyReturnId = activeCompanyReturnId || defaultCompanyReturnId
    const companyId = activeCompanyId || defaultCompanyId

    const pathOverview = generatePath(PATH_CUSTOMER, { customerId })
    const pathConsultations = generatePath(PATH_CUSTOMER_CONSULTATIONS, {
        customerId,
        taxConsultationId: Number(taxConsultationId),
    })
    const pathSelfAssessments = generatePath(PATH_CUSTOMER_SELF_ASSESSMENTS, { customerId, selfAssessmentId })
    const pathFiles = generatePath(PATH_CUSTOMER_FILES, { customerId, selfAssessmentId })
    const pathMessages = generatePath(PATH_CUSTOMER_MESSAGES, { customerId, selfAssessmentId })
    const pathHistory = generatePath(PATH_CUSTOMER_HISTORY, { customerId, selfAssessmentId })
    const pathDiscounts = generatePath(PATH_CUSTOMER_DISCOUNTS, { customerId, selfAssessmentId })
    const pathBookkeeping = generatePath(PATH_CUSTOMER_BOOKKEEPING, { customerId })
    const pathAccountantMeetings = generatePath(PATH_CUSTOMER_ACCOUNTANT_MEETINGS, {
        customerId,
        accountantMeetingId: accountantMeetingId || undefined,
    })
    const pathTaxReliefClaims = generatePath(PATH_CUSTOMER_TAX_RELIEF_CLAIMS, {
        customerId,
        taxReliefClaimId: taxReliefClaimId || undefined,
    })
    const pathCompanyReturns = generatePath(PATH_CUSTOMER_COMPANY_RETURN, {
        customerId,
        companyId: companyId || undefined,
        companyReturnId: companyReturnId || undefined,
    })

    const isOverviewRouteActive = useRouteMatch({ path: PATH_CUSTOMER, exact: true })
    const isSelfAssessmentsRouteActive = useRouteMatch({ path: PATH_CUSTOMER_SELF_ASSESSMENTS })
    const isConsultationsRouteActive = useRouteMatch({ path: PATH_CUSTOMER_CONSULTATIONS })
    const isFilesRouteActive = useRouteMatch({ path: PATH_CUSTOMER_FILES })
    const isMessagesRouteActive = useRouteMatch({ path: PATH_CUSTOMER_MESSAGES })
    const isBookkeepingRouteActive = useRouteMatch({ path: PATH_CUSTOMER_BOOKKEEPING })
    const isAccountantMeetingsRouteActive = useRouteMatch({ path: PATH_CUSTOMER_ACCOUNTANT_MEETINGS })
    const isTaxReliefClaimsRouteActive = useRouteMatch({ path: PATH_CUSTOMER_TAX_RELIEF_CLAIMS })
    const isCompanyReturnsRouteActive = useRouteMatch({ path: PATH_CUSTOMER_COMPANY_RETURN })
    const isHistoryRouteActive = useRouteMatch({ path: PATH_CUSTOMER_HISTORY })

    const shouldDisplaySelfAssessmentsLink = !!selfAssessmentId
    const shouldDisplayTaxConsultationsLink = hasTaxConsultations && !!taxConsultationId
    const shouldDisplayAccountantMeetingsLink = hasAccountantMeetings && !!accountantMeetingId
    const shouldDisplayTaxReliefClaimsLink = hasTaxReliefClaims && isAdminUser
    const shouldDisplayCompanyReturnsLink =
        hasCompanyReturns && companyReturnId && featureFlags.featureCompanyTaxReturns && companyId

    return [
        {
            title: 'Overview',
            to: pathOverview,
            className: `tour-utr-nav-overview${isOverviewRouteActive ? ' is-active' : ''}`,
        },
        {
            title: 'Tax return',
            to: pathSelfAssessments,
            path: pathSelfAssessments,
            className: isSelfAssessmentsRouteActive ? 'is-active' : '',
            shouldHide: !shouldDisplaySelfAssessmentsLink,
        },
        {
            title: 'Bookkeeping',
            to: pathBookkeeping,
            path: pathBookkeeping,
            className: isBookkeepingRouteActive ? 'is-active' : '',
            // shouldHide: !selfEmployedPlatformEnabled,
        },
        {
            title: 'Company',
            to: pathCompanyReturns,
            path: pathCompanyReturns,
            className: isCompanyReturnsRouteActive ? 'is-active' : '',
            shouldHide: !shouldDisplayCompanyReturnsLink,
        },
        {
            title: 'Consultations',
            to: pathConsultations,
            path: pathConsultations,
            className: isConsultationsRouteActive ? 'is-active' : '',
            shouldHide: !shouldDisplayTaxConsultationsLink,
        },
        {
            title: 'Meetings',
            to: pathAccountantMeetings,
            path: pathAccountantMeetings,
            className: isAccountantMeetingsRouteActive ? 'is-active' : '',
            shouldHide: !shouldDisplayAccountantMeetingsLink,
        },
        {
            title: 'EIS',
            to: pathTaxReliefClaims,
            path: pathTaxReliefClaims,
            className: isTaxReliefClaimsRouteActive ? 'is-active' : '',
            shouldHide: !shouldDisplayTaxReliefClaimsLink,
        },
        {
            title: 'Files',
            to: pathFiles,
            path: pathFiles,
            className: isFilesRouteActive ? 'is-active' : '',
        },
        {
            title: 'Messages',
            to: pathMessages,
            path: pathMessages,
            className: isMessagesRouteActive ? 'is-active' : '',
        },
        {
            title: 'History',
            to: pathHistory,
            path: pathHistory,
            className: isHistoryRouteActive ? 'is-active' : '',
        },
        {
            title: 'Discounts',
            to: pathDiscounts,
            path: pathDiscounts,
        },
    ].filter((o) => !o.shouldHide)
}
