import _isString from 'lodash/isString'

import { addErrorToast, addSuccessToast } from '@scouts/ui'

const ALERT_TYPE_SUCCESS = 'Success'
const ALERT_TYPE_ERROR = 'Error'

const createAlert = ({ type, body }) => ({
    body,
    id: type === ALERT_TYPE_SUCCESS ? addSuccessToast({ body }) : addErrorToast({ body }),
    timestamp: Date.now(),
    type,
})

const receiveAlerts = (type, messages) => {
    if (_isString(messages)) {
        const items = [createAlert({ type, body: messages })]
        return {
            type: 'RECEIVE_ALERTS',
            data: { items },
        }
    }
    const items = messages.map((body) => createAlert({ type, body }))

    return {
        type: 'RECEIVE_ALERTS',
        data: { items },
    }
}

/** @deprecated */
export const receiveSuccessAlerts = (messages) => receiveAlerts(ALERT_TYPE_SUCCESS, messages)
/** @deprecated */
export const receiveErrorAlerts = (messages) => receiveAlerts(ALERT_TYPE_ERROR, messages)
