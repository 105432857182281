import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { colors, font, media, Button, Form, Page } from '@scouts/ui'

import { receiveErrorAlerts, startNylasIntegration } from '@/actions'
import { PATH_INTEGRATIONS } from '@/constants'

import imageGoogleButton from '@/assets/images/google-button.png'

import ProfilePageWithSidebar from '../../ProfilePageWithSidebar'
import PageBackButton from '../../ui/PageBackButton'
import IntegrationsNylasEmailHintModal from './IntegrationsNylasEmailHintModal'

const ERROR_MESSAGE_NYLAS_START_AUTH = 'An error occurred while integrating scheduling calendar'

const IntegrationsNylasCreatePage = ({ isReauthentication = false }) => {
    const dispatch = useDispatch()

    const [isPending, setPending] = useState(false)
    const [isEmailHintModalOpen, setIsEmailHintModalOpen] = useState(false)

    const openEmailHintModal = () => setIsEmailHintModalOpen(true)
    const closeEmailHintModal = () => setIsEmailHintModalOpen(false)

    const createIntegration = (emailHint: string) => {
        if (isPending) return

        setPending(true)

        dispatch(
            startNylasIntegration(
                { emailHint },
                {
                    onSuccess: ({ url }: { url: string }) => {
                        window.location.assign(url)
                    },
                    onError: () => {
                        setPending(false)
                        dispatch(receiveErrorAlerts(ERROR_MESSAGE_NYLAS_START_AUTH))
                    },
                }
            )
        )
    }

    return (
        <>
            <ProfilePageWithSidebar>
                <PageBackButton to={PATH_INTEGRATIONS}>Back to integrations</PageBackButton>

                <Page.PreHeading>
                    <Page.PreHeading.Meta>Powered by Nylas</Page.PreHeading.Meta>
                </Page.PreHeading>

                <Page.Heading>Scheduling calendar</Page.Heading>

                <Page.SubHeading>
                    Nylas is our scheduling provider, which enables TaxScouts to schedule tax consultations with your
                    clients, automatically creating calendar events for each tax consultation.
                </Page.SubHeading>

                {isReauthentication ? (
                    <Page.Section background={colors.yellow}>
                        <SectionBody>
                            <p>Nylas requires you to sign in again</p>
                        </SectionBody>
                        <Form.Actions alignRight>
                            <Button onClick={openEmailHintModal} isLoading={isPending}>
                                Reconnect
                            </Button>
                        </Form.Actions>
                    </Page.Section>
                ) : (
                    <Form.Actions background={colors.blueLighter} alignRight>
                        <GoogleWrap>
                            <GoogleLabel>Integrate with</GoogleLabel>{' '}
                            <GoogleButton onClick={openEmailHintModal}>Google</GoogleButton>
                            {' or'}
                        </GoogleWrap>

                        <Button onClick={openEmailHintModal} isLoading={isPending}>
                            Other calendar
                        </Button>
                    </Form.Actions>
                )}
            </ProfilePageWithSidebar>

            {isEmailHintModalOpen && (
                <IntegrationsNylasEmailHintModal onClose={closeEmailHintModal} onSubmit={createIntegration} />
            )}
        </>
    )
}

export default IntegrationsNylasCreatePage

const GoogleWrap = styled.div`
    font-size: ${font.small};
    display: flex;
    align-items: center;
`

const GoogleLabel = styled.div`
    display: none;

    ${media.tablet} {
        display: block;
    }
`

const GoogleButton = styled.button`
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background: url(${imageGoogleButton});
    background-size: 100% auto;
    width: 112px;
    height: 46px;
    margin: 0 9px 0 6px;
`

const SectionBody = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    margin-bottom: 18px;
`
