import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AccountantPortalChrome, Button, Form, Input, InputProps } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { CustomerDTO, PartnerDTO } from '@/models'
import { useUpdateCustomersPartner } from '@/store/partner/hooks'

interface PartnerDetailsProps {
    partner: PartnerDTO | null
    customerId: CustomerDTO['id']
}

export const PartnerDetails = ({ partner: initialPartner, customerId }: PartnerDetailsProps) => {
    const history = useHistory()
    const { updateCustomersPartner, isLoading: isLoadingUpdate } = useUpdateCustomersPartner()

    const initialState = {
        customerId,
        firstName: initialPartner?.firstName || null,
        lastName: initialPartner?.lastName || null,
        email: initialPartner?.email || null,
        inviteSentDateUTC: initialPartner?.inviteSentDateUTC || null,
    }

    const [partner, setPartner] = useState(initialState)

    const handleChange: InputProps['onChange'] = ({ name, value }) => {
        const updatedPartner = { ...partner, [name]: value }

        setPartner(updatedPartner)
    }

    const handleSave = () => {
        updateCustomersPartner({ customerId, data: partner })
    }

    const { firstName, lastName, email } = partner

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.Title>
                    Partner / Property co-owner details
                </AccountantPortalChrome.Customer.Page.Title>
            </AccountantPortalChrome.Customer.Page.Header>

            <Form onSubmit={handleSave}>
                <Form.Row>
                    <Form.Row.Title>First name</Form.Row.Title>
                    <Form.Row.Content>
                        <Input name="firstName" value={firstName || ''} onChange={handleChange} maxLength={255} />
                    </Form.Row.Content>
                </Form.Row>

                <Form.Row>
                    <Form.Row.Title>Last name</Form.Row.Title>
                    <Form.Row.Content>
                        <Input name="lastName" value={lastName || ''} onChange={handleChange} maxLength={255} />
                    </Form.Row.Content>
                </Form.Row>

                <Form.Row>
                    <Form.Row.Title>Email</Form.Row.Title>
                    <Form.Row.Content>
                        <Input name="email" value={email || ''} onChange={handleChange} maxLength={255} />
                    </Form.Row.Content>
                </Form.Row>

                <Form.Actions>
                    <Button type="submit" isLoading={isLoadingUpdate}>
                        Save
                    </Button>
                    <Button isSecondary onClick={() => history.push(`${PATH_CUSTOMERS}/${customerId}`)}>
                        Back to profile
                    </Button>
                </Form.Actions>
            </Form>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
