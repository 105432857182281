import { colors, radius, Notice } from '@scouts/ui'

export const SelfAssessmentNoticeOutstandingPayments = ({ shouldDisplay }: { shouldDisplay: boolean }) => {
    if (!shouldDisplay) return null

    return (
        <Notice background={colors.yellow} borderRadius={radius.medium}>
            Client has outstanding payments
        </Notice>
    )
}
