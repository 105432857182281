import styled from 'styled-components'

import { Table } from '@scouts/ui'

import { SelectedCustomer, TaxRegistrationDTO } from '@/models'

import { TaxRegistrationsTableRow } from './TaxRegistrationsTableRow'

interface TaxRegistrationsTableProps {
    deselectAll: () => void
    handleCustomerSelection: ({ customerId, fullName, unsubscribeFromBulkEmails }: SelectedCustomer) => void
    isFetching: boolean
    selectAll: () => void
    selectedItems: SelectedCustomer[]
    taxRegistrations: TaxRegistrationDTO[]
    triggerTableUpdate: () => void
}

export const TaxRegistrationsTable = ({
    taxRegistrations,
    selectAll,
    deselectAll,
    selectedItems,
    isFetching,
    handleCustomerSelection,
    triggerTableUpdate,
}: TaxRegistrationsTableProps) => {
    const hasSelectedItems = selectedItems.length > 0

    return (
        <Container isFetching={isFetching}>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell width="25%">Client</Table.Header.Cell>
                        <Table.Header.Cell width="10%">&nbsp;</Table.Header.Cell>
                        <Table.Header.Cell width="10%">Type</Table.Header.Cell>
                        <Table.Header.Cell width="15%">Status</Table.Header.Cell>
                        <Table.Header.Cell width="15%">Status last updated</Table.Header.Cell>
                        <Table.Header.Cell width="calc(25% - 96px)" alignRight>
                            Notes
                        </Table.Header.Cell>
                        <Table.Header.Cell width="96px" alignRight action={hasSelectedItems ? deselectAll : selectAll}>
                            {hasSelectedItems ? 'Deselect all' : 'Select all'}
                        </Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>
                <Table.Body>
                    {taxRegistrations.map((taxRegistration) => {
                        const { customerId } = taxRegistration
                        const isSelected = selectedItems.findIndex((o) => o.customerId === customerId) > -1
                        return (
                            <TaxRegistrationsTableRow
                                key={taxRegistration.customerId}
                                handleSelect={handleCustomerSelection}
                                isSelected={isSelected}
                                taxRegistration={taxRegistration}
                                triggerTableUpdate={triggerTableUpdate}
                            />
                        )
                    })}
                </Table.Body>
            </Table>
        </Container>
    )
}

const Container = styled.div<{ isFetching?: boolean }>`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`
