import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { addSuccessToast, AccountantPortalChrome, Button, ButtonLink, Form, Input, InputProps } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { copyTextToClipboard } from '@/helpers'
import { BankAccountDTO, CustomerDTO } from '@/models'
import { useUpdateCustomersBankAccount } from '@/store/bank-account'

interface BankDetailsProps {
    bankAccount: BankAccountDTO | null
    customerId: CustomerDTO['id']
}

export const BankDetails = ({ bankAccount: initialBankAccount, customerId }: BankDetailsProps) => {
    const history = useHistory()
    const { updateCustomersBankAccount, isLoading: isLoadingUpdate } = useUpdateCustomersBankAccount()

    const initialState = {
        customerId,
        sortCode: initialBankAccount?.sortCode || null,
        accountNumber: initialBankAccount?.accountNumber || null,
        bankName: initialBankAccount?.bankName || null,
    }

    const [bankAccount, setBankAccount] = useState(initialState)

    const { bankName, sortCode, accountNumber } = bankAccount

    const copyToClipboard = (value: string | number) => {
        copyTextToClipboard(value, () => addSuccessToast({ body: 'Copied to clipboard' }))
    }

    const handleChange: InputProps['onChange'] = ({ name, value }) => {
        const updatedBankAccount = { ...bankAccount, [name]: value }

        setBankAccount(updatedBankAccount)
    }

    const handleSave = () => {
        updateCustomersBankAccount({ customerId, data: bankAccount })
    }

    const formatSortCode = () => {
        const { sortCode } = bankAccount || {}

        if (!sortCode) {
            return ''
        }
        const parts = sortCode.match(/.{1,2}/g) || []
        return parts.join('-')
    }

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.Title>Bank details</AccountantPortalChrome.Customer.Page.Title>
            </AccountantPortalChrome.Customer.Page.Header>

            <Form onSubmit={handleSave}>
                <Form.Row>
                    <Form.Row.Title>
                        Bank name
                        {bankName && <ButtonLink onClick={() => copyToClipboard(bankName)}>Copy</ButtonLink>}
                    </Form.Row.Title>
                    <Form.Row.Content>
                        <Input name="bankName" value={bankName || ''} onChange={handleChange} maxLength={255} />
                    </Form.Row.Content>
                </Form.Row>
                <Form.Row>
                    <Form.Row.Title>
                        Sort code
                        {sortCode && <ButtonLink onClick={() => copyToClipboard(formatSortCode())}>Copy</ButtonLink>}
                    </Form.Row.Title>
                    <Form.Row.Content>
                        <Input name="sortCode" value={sortCode || ''} onChange={handleChange} maxLength={6} />
                    </Form.Row.Content>
                </Form.Row>
                <Form.Row>
                    <Form.Row.Title>
                        Account number
                        {accountNumber && <ButtonLink onClick={() => copyToClipboard(accountNumber)}>Copy</ButtonLink>}
                    </Form.Row.Title>
                    <Form.Row.Content>
                        <Input name="accountNumber" value={accountNumber || ''} onChange={handleChange} maxLength={8} />
                    </Form.Row.Content>
                </Form.Row>

                <Form.Actions>
                    <Button type="submit" isLoading={isLoadingUpdate}>
                        Save
                    </Button>
                    <Button
                        isSecondary
                        onClick={() => {
                            history.push(`${PATH_CUSTOMERS}/${customerId}`)
                        }}
                    >
                        Back to profile
                    </Button>
                </Form.Actions>
            </Form>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
