import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Checkbox, IconCommentSlash, Table } from '@scouts/ui'

import { PATH_CUSTOMERS, TAX_YEARS } from '../../constants'
import { SelfAssessmentStatusLabel } from '../self-assessments/SelfAssessmentStatusLabel'
import { TaxConsultationStatusLabel } from '../tax-consultations/TaxConsultationStatusLabel'

const Customer = styled.div``

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    font-size: ${font.small};
`

const Product = styled.div``

const OnHold = styled.span`
    font-size: ${font.small};
`

const TaxYear = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const TaxConsultationTitle = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Phone = styled.div`
    font-size: ${font.small};
`

const Address = styled.div`
    font-size: ${font.small};
`

const AddressLine = styled.div`
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const IconUnsubscribed = styled.div`
    margin-right: 18px;
`

const SearchResultsRow = (props) => {
    const { data, isSelected, handleSelect } = props

    const {
        addressCity,
        addressCountry,
        addressPostcode,
        addressStreetAddress,
        customerId,
        email,
        fullName,
        phoneNumber,
        selfAssessmentId,
        selfAssessmentOnHold,
        selfAssessmentStatus,
        selfAssessmentTaxYear,
        taxConsultationId,
        taxConsultationStatus,
        unsubscribeFromBulkEmails,
    } = data

    const customerUrl = `${PATH_CUSTOMERS}/${customerId}`

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="30%">
                <Table.Body.Cell.ContentLink verticalCenter Link={Link} to={customerUrl}>
                    <Customer>
                        <Name>{fullName}</Name>
                        <Email>{email}</Email>
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink verticalCenter Link={Link} to={customerUrl}>
                    <Phone>{phoneNumber}</Phone>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink verticalCenter Link={Link} to={customerUrl}>
                    <Address>
                        {addressStreetAddress && <AddressLine>{addressStreetAddress}</AddressLine>}
                        {(addressPostcode || addressCity) && (
                            <AddressLine>
                                {addressPostcode} {addressCity}
                            </AddressLine>
                        )}
                        {addressCountry && <AddressLine>{addressCountry}</AddressLine>}
                    </Address>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="20%">
                {!!selfAssessmentId && (
                    <Table.Body.Cell.ContentLink
                        verticalCenter
                        Link={Link}
                        to={`${PATH_CUSTOMERS}/${customerId}/assessment/${selfAssessmentId}`}
                    >
                        <Product>
                            <TaxYear>Tax return {TAX_YEARS[selfAssessmentTaxYear]}</TaxYear>
                            <SelfAssessmentStatusLabel status={selfAssessmentStatus} />
                            <OnHold>{selfAssessmentOnHold ? ' (On hold)' : ''}</OnHold>
                        </Product>
                    </Table.Body.Cell.ContentLink>
                )}
                {!!taxConsultationId && (
                    <Table.Body.Cell.ContentLink
                        verticalCenter
                        Link={Link}
                        to={`${PATH_CUSTOMERS}/${customerId}/consultations/${taxConsultationId}`}
                    >
                        <Product>
                            <TaxConsultationTitle>Consultation</TaxConsultationTitle>
                            <TaxConsultationStatusLabel status={taxConsultationStatus} />
                        </Product>
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>

            <Table.Body.Cell width="10%">
                <Table.Body.Cell.Content verticalCenter alignRight>
                    {unsubscribeFromBulkEmails && (
                        <IconUnsubscribed title="Unsubscribed from bulk emails">
                            <IconCommentSlash color={colors.neutralDarker} />
                        </IconUnsubscribed>
                    )}

                    <Checkbox
                        isLabelless
                        label="Select"
                        name={`${customerId}${selfAssessmentId || taxConsultationId || ''}`}
                        value={String(customerId)}
                        onChange={() =>
                            handleSelect({
                                customerId,
                                fullName,
                                selfAssessmentId,
                                taxConsultationId,
                                status: selfAssessmentStatus || null,
                                unsubscribeFromBulkEmails,
                                phoneNumber,
                            })
                        }
                        checked={isSelected}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

SearchResultsRow.propTypes = {
    data: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
}

export default SearchResultsRow
