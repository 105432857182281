import PropTypes from 'prop-types'
import { useState } from 'react'

import { ButtonLink } from '@scouts/ui'

import SendMessagesContainer from '../SendMessagesContainer'
import CustomerReassignModal from './CustomerReassignModal'

const CustomerReassign = ({ customer, currentAccountantId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)

    const { id: customerId, fullName, phoneNumber } = customer
    const messagableCustomer = [{ customerId: String(customerId), fullName, phoneNumber }]

    return (
        <>
            <ButtonLink type="button" onClick={() => setIsModalOpen(true)}>
                Reassign
            </ButtonLink>

            {isModalOpen && (
                <CustomerReassignModal
                    customer={customer}
                    currentAccountantId={currentAccountantId}
                    closeModal={() => setIsModalOpen(false)}
                    onReassignmentSuccess={() => setIsMessageModalOpen(true)}
                />
            )}

            {isMessageModalOpen && (
                <SendMessagesContainer
                    customers={messagableCustomer}
                    closeModal={() => setIsMessageModalOpen(false)}
                    openByDefault
                />
            )}
        </>
    )
}

CustomerReassign.propTypes = {
    customer: PropTypes.object.isRequired,
    currentAccountantId: PropTypes.number.isRequired,
}

export default CustomerReassign
