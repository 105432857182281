import { createSelector } from '@reduxjs/toolkit'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import { EXTERNAL_API_GOOGLE, EXTERNAL_API_HMRC, EXTERNAL_API_MICROSOFT, EXTERNAL_API_NYLAS } from '@/constants'
import { AccountantStatus } from '@/models'

/**
 * @param {import('@/store').RootState} _state
 * @param {{accountantId?: number | null}} props
 */
const pluckAccountantId = (_state, { accountantId }) => accountantId
const pluckActiveFilter = (_state, { activeFilter }) => activeFilter
/**
 * @param {import('@/store').RootState} _state
 * @param {{currentAccountantId?: number | null}} props
 */
const pluckCurrentAccountantId = (_state, { currentAccountantId }) => currentAccountantId
/**
 * @param {import('@/store').RootState} state
 * @param {{customerId: number }} props
 */
const pluckCustomerId = (_state, { customerId }) => customerId

export const getAccountantsSlice = (state) => state.accountants
export const getAccountantItems = (state) => getAccountantsSlice(state)?.items || []
export const getSuitableAccountants = (state) => getAccountantsSlice(state)?.suitableAccountantsByCustomerId || []

export const getAccountantById = createSelector(
    [getAccountantItems, pluckAccountantId],
    (accountants, accountantId) => {
        const accountant = _find(accountants, ['id', accountantId], null)

        if (!accountant) return accountant

        const { externalApis = [] } = accountant || {}

        return {
            ...accountant,
            hasGoogleConnection: externalApis.some((api) => api === EXTERNAL_API_GOOGLE),
            hasHmrcPayeApiConnection: externalApis.some((api) => api === EXTERNAL_API_HMRC),
            hasMicrosoftConnection: externalApis.some((api) => api === EXTERNAL_API_MICROSOFT),
            hasNylasApiConnection: externalApis.some((api) => api === EXTERNAL_API_NYLAS),
        }
    }
)

export const getAccountantsOrderedByName = createSelector([getAccountantItems], (items) =>
    _orderBy(items, [
        ({ firstName, lastName }) => {
            const fullName = `${firstName || ''} ${lastName || ''}`.trim()
            return fullName.toLowerCase()
        },
    ])
)

export const getAccountantsWithFullNameAndStatus = createSelector([getAccountantsOrderedByName], (items) =>
    items.map(({ id, firstName, lastName, status }) => {
        const fullName = `${`${firstName || ''} ${lastName || ''}`.trim()} (${status})`
        return { value: id, title: fullName }
    })
)

export const getAssignableAccountantsWithFullNameAndStatus = createSelector(
    [getAccountantsOrderedByName],
    (accountants) => {
        const activeAccountants = accountants.filter(
            ({ status }) => status !== AccountantStatus.Terminated && status !== AccountantStatus.Draft
        )
        return activeAccountants.map(({ id, firstName, lastName, status }) => {
            const fullName = `${`${firstName || ''} ${lastName || ''}`.trim()} (${status})`
            return { value: id, title: fullName }
        })
    }
)

/**
 * @param state {import('@/store').RootState}
 * @param props {{customerId: number}}
 */
const getSuitableAccountantsForCustomer = (state, props) => {
    const customerId = pluckCustomerId(state, props)
    const suitableAccountants = getSuitableAccountants(state)
    return _get(suitableAccountants, [customerId], [])
}

export const getReassignableAccountantsWithFullNameAndStatus = createSelector(
    [getSuitableAccountantsForCustomer, pluckCurrentAccountantId],
    (items, currentAccountantId) => {
        const activeAccountants = items.filter(
            ({ status, id }) =>
                status !== AccountantStatus.Terminated &&
                status !== AccountantStatus.Draft &&
                id !== currentAccountantId
        )
        return activeAccountants.map(({ id, firstName, lastName, status, taxConsultationStatus }) => {
            const isActive = status === AccountantStatus.Active
            const showConsultationContext =
                isActive && taxConsultationStatus && taxConsultationStatus !== AccountantStatus.Active
            const statusMessage = showConsultationContext
                ? `${status}, but ${taxConsultationStatus} for consultations`
                : status
            const fullName = `${`${firstName || ''} ${lastName || ''}`.trim()} (${statusMessage})`
            return { value: id, title: fullName }
        })
    }
)

export const getAccountantsForRadios = createSelector(
    [getAccountantsOrderedByName, pluckActiveFilter],
    (accountants, activeFilter) =>
        accountants.map(({ id, firstName, lastName }) => {
            const fullName = `${firstName || ''} ${lastName || ''}`.trim()
            return {
                value: id,
                label: fullName,
                checked: activeFilter === id,
            }
        })
)
