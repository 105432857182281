import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { generateApiPath } from '@scouts/helpers'

import { AccountantMeetingSummaryDTO, ResponseWithPagination } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'

interface GetAccountantMeetingParams {
    accountantMeetingId: AccountantMeetingSummaryDTO['id']
}

export interface GetAccountantMeetingsParams {
    accountantId?: AccountantMeetingSummaryDTO['accountantId']
    status?: AccountantMeetingSummaryDTO['status'] | null
    page?: number
    pageSize?: number
}

interface GetCustomersAccountantMeetingParams {
    customerId: number
}

interface UpdateAccountantMeetingStatusParams {
    accountantMeetingId: AccountantMeetingSummaryDTO['id']
    status: AccountantMeetingSummaryDTO['status']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAccountantMeeting: builder.query<AccountantMeetingSummaryDTO, GetAccountantMeetingParams>({
            query: ({ accountantMeetingId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_MEETINGS.GET.SINGLE, { accountantMeetingId }),
            }),
            providesTags: [Tags.AccountantMeetings],
        }),
        getAccountantMeetings: builder.query<
            ResponseWithPagination<AccountantMeetingSummaryDTO[]>,
            GetAccountantMeetingsParams
        >({
            query: ({ accountantId, page, pageSize, status }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_MEETINGS.GET.LIST, undefined, {
                    queryParameters: {
                        ...(accountantId && { accountantId }),
                        ...(page && { page }),
                        ...(pageSize && { pageSize }),
                        ...(status && { status }),
                    },
                }),
            }),
            providesTags: [Tags.AccountantMeetings],
            transformResponse: (response: AccountantMeetingSummaryDTO[], meta: FetchBaseQueryMeta) =>
                mapPaginatedResponse(response, meta),
        }),
        getCustomersAccountantMeeting: builder.query<
            AccountantMeetingSummaryDTO[],
            GetCustomersAccountantMeetingParams
        >({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_MEETINGS.GET.CUSTOMER, { customerId }),
            }),
            providesTags: [Tags.AccountantMeetings],
        }),
        updateAccountantMeetingStatus: builder.mutation<
            AccountantMeetingSummaryDTO,
            UpdateAccountantMeetingStatusParams
        >({
            query: ({ accountantMeetingId, status }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_MEETINGS.PUT.UPDATE_STATUS, {
                    accountantMeetingId,
                    value: status,
                }),
            }),
            invalidatesTags: [Tags.AccountantMeetings],
        }),
    }),
})

export const {
    useGetAccountantMeetingQuery,
    useGetAccountantMeetingsQuery,
    useGetCustomersAccountantMeetingQuery,
    useUpdateAccountantMeetingStatusMutation,
} = extendedApi
