import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { generateApiPath } from '@scouts/helpers'

import {
    OffPlatformServicesRequestDTO,
    OffPlatformServicesRequestEditAccountantDTO,
    OffPlatformServicesRequestEditStatusDTO,
    OffPlatformServicesRequestOptions,
    ResponseWithPagination,
} from '@/models'
import { ENDPOINTS } from '@/store/endpoints'
import { Tags } from '@/store/tags'

import { apiSlice } from '../api-slice'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { getOffPlatformServicesRequestQueryParams } from './queryParams'

export interface GetOffPlatformServicesRequestsParams {
    options?: OffPlatformServicesRequestOptions
}

export const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOffPlatformServicesRequests: builder.query<
            ResponseWithPagination<OffPlatformServicesRequestDTO[]>,
            GetOffPlatformServicesRequestsParams
        >({
            query: ({ options }) => ({
                url: generateApiPath(ENDPOINTS.OFF_PLATFORM_SERVICES_REQUESTS.GET.ALL, undefined, {
                    queryParameters: getOffPlatformServicesRequestQueryParams(options),
                }),
                method: 'GET',
            }),
            providesTags: (result: ResponseWithPagination<OffPlatformServicesRequestDTO[]> | undefined) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: Tags.OffPlatformServicesRequests, id })),
                          Tags.OffPlatformServicesRequests,
                      ]
                    : [Tags.OffPlatformServicesRequests],
            transformResponse: (response: OffPlatformServicesRequestDTO[], meta: FetchBaseQueryMeta) =>
                mapPaginatedResponse(response, meta),
        }),
        updateOffPlatformServicesRequestStatus: builder.mutation<
            OffPlatformServicesRequestDTO,
            OffPlatformServicesRequestEditStatusDTO
        >({
            query: ({ id, status }) => ({
                url: generateApiPath(ENDPOINTS.OFF_PLATFORM_SERVICES_REQUESTS.PUT.SINGLE, { id }),
                body: { status },
                method: 'PUT',
            }),
            invalidatesTags: [Tags.OffPlatformServicesRequests],
        }),
        updateOffPlatformServicesRequestAccountant: builder.mutation<
            OffPlatformServicesRequestDTO,
            OffPlatformServicesRequestEditAccountantDTO
        >({
            query: ({ id, accountantId, status }) => ({
                url: generateApiPath(ENDPOINTS.OFF_PLATFORM_SERVICES_REQUESTS.PUT.SINGLE, { id }),
                body: { accountantId, status },
                method: 'PUT',
            }),
            invalidatesTags: [Tags.OffPlatformServicesRequests],
        }),
    }),
})

export const {
    useGetOffPlatformServicesRequestsQuery,
    useUpdateOffPlatformServicesRequestStatusMutation,
    useUpdateOffPlatformServicesRequestAccountantMutation,
} = extendedApi
