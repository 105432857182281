import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, ChipButton, Flexer, Grid, GridItem, Label, Line } from '@scouts/ui'

import { PATH_CUSTOMERS, REFERRAL_DISCOUNT_NAME, REFUND_REASONS_TITLES } from '@/constants'
import { formatCurrency, formatDate, formatDateWithTime } from '@/helpers'
import {
    PaymentStatus,
    TaxConsultationContactPreference,
    TaxConsultationContactPreferenceTitles,
    TaxConsultationDTO,
    TaxConsultationStatus,
} from '@/models'
import { getDiscountByTaxConsultationId, getIsTaxConsultationPartOfProductBundle, getUser } from '@/selectors'

import { TaxConsultationStatusDropdown } from './TaxConsultationStatusDropdown'

interface TaxConsultationSummaryProps {
    taxConsultation: TaxConsultationDTO
}

export const TaxConsultationSummary = ({ taxConsultation }: TaxConsultationSummaryProps) => {
    const { isAdminUser } = useSelector(getUser)

    const {
        contactPreferenceType,
        revenueDateUTC,
        customerId,
        status,
        scheduledDateUtc,
        hasPaid,
        paymentStatus,
        paymentDateUTC,
        meetingLink,
        refundReason,
        id: taxConsultationId,
        price,
        refundDateUTC,
    } = taxConsultation

    const discount = useSelector((state) => getDiscountByTaxConsultationId(state, { customerId, taxConsultationId }))

    const isPartOfProductBundle = useSelector((state) =>
        getIsTaxConsultationPartOfProductBundle(state, { taxConsultationId })
    )

    const consultationPaymentStatus = useMemo(() => {
        switch (true) {
            case hasPaid || paymentStatus === PaymentStatus.Refunded:
                return paymentDateUTC && formatDate(paymentDateUTC)
            default:
                return 'Not paid'
        }
    }, [hasPaid, paymentDateUTC, paymentStatus])

    const bookedTime = useMemo(() => {
        if (status === TaxConsultationStatus.Started) return '-'
        if (scheduledDateUtc) return formatDateWithTime(scheduledDateUtc)
        return 'Booked off-platform'
    }, [scheduledDateUtc, status])

    const getConsultationPrice = () => {
        const {
            refundReasonDescription = null,
            refundReasonType = null,
            supportAccountantFullName = null,
        } = refundReason || {}

        const {
            discountSchemeDiscountCode = '',
            customerReferralSource = '',
            discountSchemePercentageDiscount = '',
            referredByCustomerId = '',
            referredByCustomerFullName = '',
        } = discount || {}

        switch (true) {
            case paymentStatus === PaymentStatus.Refunded:
                return (
                    <>
                        <Label background={colors.red}>Refunded</Label>
                        {price === 0 && (
                            <>
                                {' '}
                                <Label background={colors.mintLighter}>Free</Label>
                                {isPartOfProductBundle && <FieldDescription>Bundled</FieldDescription>}
                            </>
                        )}
                        {!!refundReasonType && (
                            <FieldDescription>
                                <strong>{REFUND_REASONS_TITLES[refundReasonType]}</strong>
                            </FieldDescription>
                        )}
                        {!!refundReasonDescription && <FieldDescription>{refundReasonDescription}</FieldDescription>}
                        {!!refundDateUTC && (
                            <FieldDescription>Refunded on {formatDate(refundDateUTC)}</FieldDescription>
                        )}
                        {!!supportAccountantFullName && (
                            <FieldDescription>Refunded by {supportAccountantFullName}</FieldDescription>
                        )}
                    </>
                )
            case hasPaid && price === 0:
                return (
                    <>
                        <Label background={colors.mintLighter}>Free</Label>
                        {isPartOfProductBundle && <FieldDescription>Bundled</FieldDescription>}
                    </>
                )
            default:
                return (
                    <>
                        <Label background={colors.neutralLightest}>{formatCurrency(price)}</Label>
                        {discount && (
                            <>
                                <FieldDescription>
                                    Used{' '}
                                    <strong>
                                        {discountSchemeDiscountCode === REFERRAL_DISCOUNT_NAME
                                            ? customerReferralSource
                                            : discountSchemeDiscountCode}
                                    </strong>{' '}
                                    for {discountSchemePercentageDiscount}% discount
                                </FieldDescription>
                                {referredByCustomerFullName && referredByCustomerId && (
                                    <FieldDescription>
                                        Referred by{' '}
                                        {isAdminUser && (
                                            <Link to={`${PATH_CUSTOMERS}/${referredByCustomerId}`}>
                                                {referredByCustomerFullName}
                                            </Link>
                                        )}
                                        {!isAdminUser && <>{referredByCustomerFullName}</>}
                                    </FieldDescription>
                                )}
                            </>
                        )}
                    </>
                )
        }
    }

    return (
        <Flexer gap="12px" column>
            <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                <GridItem>
                    <Line size={font.small} weight={font.weight.medium}>
                        Status
                    </Line>
                </GridItem>
                <GridItem>
                    <TaxConsultationStatusDropdown taxConsultation={taxConsultation} />
                </GridItem>
            </Grid>

            <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                <GridItem>
                    <Line size={font.small} weight={font.weight.medium}>
                        Time
                    </Line>
                </GridItem>
                <GridItem>
                    <Line size={font.small}>{bookedTime}</Line>
                </GridItem>
            </Grid>

            {contactPreferenceType && (
                <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                    <GridItem>
                        <Line size={font.small} weight={font.weight.medium}>
                            Contact preference
                        </Line>
                    </GridItem>
                    <GridItem>
                        <Flexer alignCenter gap="6px" flexWrap>
                            <Line size={font.small}>
                                {TaxConsultationContactPreferenceTitles[contactPreferenceType]}
                            </Line>

                            {!!meetingLink && contactPreferenceType === TaxConsultationContactPreference.Video && (
                                <ChipButton
                                    size="small"
                                    variant="highlightSoft"
                                    onClick={() => {
                                        window.open(meetingLink, '_blank', 'noopener noreferrer')
                                    }}
                                >
                                    Join meeting
                                </ChipButton>
                            )}
                        </Flexer>
                    </GridItem>
                </Grid>
            )}

            <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px">
                <GridItem>
                    <Line size={font.small} weight={font.weight.medium}>
                        Has paid
                    </Line>
                </GridItem>
                <GridItem>
                    <Line size={font.small}>
                        {consultationPaymentStatus} {getConsultationPrice()}
                    </Line>
                </GridItem>
            </Grid>

            {isAdminUser && revenueDateUTC && (
                <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                    <GridItem>
                        <Line size={font.small} weight={font.weight.medium}>
                            Revenue recognised on
                        </Line>
                    </GridItem>
                    <GridItem>
                        <Label color={colors.black} background={colors.blueLighter}>
                            {formatDate(revenueDateUTC)}
                        </Label>
                    </GridItem>
                </Grid>
            )}

            {isAdminUser && (
                <Grid columns={1} desktopLargeColumns="2fr 3fr" gap="12px" alignCenter>
                    <GridItem>
                        <Line size={font.small} weight={font.weight.medium}>
                            Accountant
                        </Line>
                    </GridItem>
                    <GridItem>
                        <Line size={font.small}>{taxConsultation.accountantFullName}</Line>
                    </GridItem>
                </Grid>
            )}
        </Flexer>
    )
}

const FieldDescription = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
    margin-top: 6px;
`
