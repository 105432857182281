import { colors } from '@scouts/ui'

import { SelfAssessmentStatus, SelfAssessmentStatusTitles } from '@/models'

const statuses = {
    [SelfAssessmentStatus.Created]: {
        color: undefined,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.Created],
    },
    [SelfAssessmentStatus.Drafted]: {
        color: undefined,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.Drafted],
    },
    [SelfAssessmentStatus.Started]: {
        color: undefined,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.Started],
    },
    [SelfAssessmentStatus.UploadingDocuments]: {
        color: undefined,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.UploadingDocuments],
    },
    [SelfAssessmentStatus.Submitted]: {
        color: colors.blueLighter,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.Submitted],
    },
    [SelfAssessmentStatus.AccountantReview]: {
        color: colors.blueLighter,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.AccountantReview],
    },
    [SelfAssessmentStatus.InformationRequired]: {
        color: colors.yellow,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.InformationRequired],
    },
    [SelfAssessmentStatus.AwaitingCustomerApproval]: {
        color: colors.yellow,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.AwaitingCustomerApproval],
    },
    [SelfAssessmentStatus.CustomerCorrections]: {
        color: colors.yellow,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.CustomerCorrections],
    },
    [SelfAssessmentStatus.Cancelled]: {
        color: colors.red,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.Cancelled],
    },
    [SelfAssessmentStatus.ReadyToFile]: {
        color: colors.blueLighter,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.ReadyToFile],
    },
    [SelfAssessmentStatus.FiledAwaitingRefund]: {
        color: colors.yellow,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.FiledAwaitingRefund],
    },
    [SelfAssessmentStatus.Filed]: {
        color: colors.mintLighter,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.Filed],
    },
    [SelfAssessmentStatus.CompletedNotFiled]: {
        color: colors.mintLighter,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.CompletedNotFiled],
    },
    [SelfAssessmentStatus.ProcessingDocuments]: {
        color: colors.red,
        title: SelfAssessmentStatusTitles[SelfAssessmentStatus.ProcessingDocuments],
    },
}

export const SelfAssessmentDomain = {
    statuses,
}
