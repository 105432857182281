import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components'

import { formatTimeFromNow } from '@scouts/helpers'
import { colors, font, Container, Flexer, Line, Spacer, Table, Tooltip } from '@scouts/ui'

import { PATH_CUSTOMER } from '@/constants'
import { BusinessServicesLabels, OffPlatformServicesRequestDTO, PersonalServicesLabels } from '@/models'

import { OffPlatformServicesRequestAccountant } from './OffPlatformServicesRequestAccountant'
import { OffPlatformServicesRequestStatus } from './OffPlatformServicesRequestStatus'

type TableRowProps = {
    offPlatformServicesRequest: OffPlatformServicesRequestDTO
}

export const OffPlatformServicesRequestsTableRow = ({ offPlatformServicesRequest }: TableRowProps) => {
    const {
        customerFullName,
        customerId,
        email,
        additionalNotes,
        statusChangedDateUTC,
        businessServices,
        personalServices,
        phoneNumber,
        contactPreferenceType,
    } = offPlatformServicesRequest

    const customerUrl = generatePath(PATH_CUSTOMER, { customerId })

    const businessServicesList = businessServices?.map((serviceType) => BusinessServicesLabels[serviceType])?.join(', ')
    const personalServicesList = personalServices?.map((serviceType) => PersonalServicesLabels[serviceType])?.join(', ')

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerUrl}>
                    <Spacer width="calc(100% - 24px)">
                        <Line weight={font.weight.medium}>{customerFullName}</Line>
                        <Line size={font.small} color={colors.neutralDarker}>
                            <Ellipsis>{email}</Ellipsis>
                        </Line>
                    </Spacer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.Content>
                    <OffPlatformServicesRequestStatus offPlatformServicesRequest={offPlatformServicesRequest} />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.Content>
                    <OffPlatformServicesRequestAccountant offPlatformServicesRequest={offPlatformServicesRequest} />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.Content>
                    {contactPreferenceType ? (
                        <Line size={font.small}>
                            <Tooltip shouldDisplay={Boolean(phoneNumber)} content={phoneNumber || ''}>
                                {contactPreferenceType}
                            </Tooltip>
                        </Line>
                    ) : (
                        <></>
                    )}
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="26%">
                <Table.Body.Cell.Content>
                    <Flexer gap="6px" column width="100%">
                        {businessServicesList && <Line size={font.small}>{businessServicesList}</Line>}
                        {personalServicesList && <Line size={font.small}>{personalServicesList}</Line>}

                        {additionalNotes ? (
                            <Spacer>
                                <Container background={colors.redLighter} padding="8px 10px">
                                    <Line size={font.small}>
                                        <Ellipsis title={additionalNotes}>{additionalNotes}</Ellipsis>
                                    </Line>
                                </Container>
                            </Spacer>
                        ) : (
                            <></>
                        )}
                    </Flexer>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
            <Table.Body.Cell width="9%">
                <Table.Body.Cell.Content alignRight>
                    <Spacer marginLeft="12px">
                        <Line size={font.small}>{formatTimeFromNow(statusChangedDateUTC)}</Line>
                    </Spacer>
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Ellipsis = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`
