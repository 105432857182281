import { localStorageGet, localStorageSet } from '@scouts/helpers'

const getSeenTours = () => {
    try {
        const fromStorage = localStorageGet('seenTours') || '[]'
        return JSON.parse(fromStorage)
    } catch (e) {
        return []
    }
}
export const hasSeenTour = (tourId) => getSeenTours().indexOf(tourId) >= 0

export const storeSeenTour = (tourId) => {
    const seenTours = getSeenTours()
    seenTours.push(tourId)
    localStorageSet('seenTours', JSON.stringify(seenTours))
}
