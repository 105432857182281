import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { createRef, Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { DropdownButton } from '@scouts/ui'

import { requestCustomerFiles, uploadCustomerFile } from '@/actions'
import { FileTypeTitles } from '@/models'

const Container = styled.div``

class UploadFilesCustomer extends Component {
    constructor() {
        super()

        this.state = {
            fileType: null,
        }

        this.dropzoneRef = createRef()

        _bindAll(this, ['upload', 'selectFile'])
    }

    selectFile({ fileType }) {
        this.setState({ fileType })

        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open()
        }
    }

    upload(file) {
        const { dispatch, customerId, identityVerificationId } = this.props
        const { fileType } = this.state

        dispatch(
            uploadCustomerFile(
                {
                    customerId,
                    fileType,
                    file,
                    identityVerificationId,
                },
                {
                    onSuccess: () => {
                        dispatch(requestCustomerFiles({ customerId }))
                    },
                }
            )
        )
    }

    render() {
        const { uploadTypes } = this.props

        const actions = uploadTypes.map(({ title, fileType, filingArea }) => ({
            title: title || FileTypeTitles[fileType],
            onClick: () => this.selectFile({ filingArea, fileType }),
        }))

        return (
            <Container>
                <DropdownButton alignRight actions={actions}>
                    Upload
                </DropdownButton>

                <div style={{ display: 'none' }}>
                    <Dropzone
                        ref={this.dropzoneRef}
                        onDrop={(uploadedFiles) => {
                            uploadedFiles.forEach(this.upload)
                        }}
                    />
                </div>
            </Container>
        )
    }
}

UploadFilesCustomer.propTypes = {
    customerId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    uploadTypes: PropTypes.array.isRequired,
    identityVerificationId: PropTypes.number,
}

UploadFilesCustomer.defaultProps = {
    identityVerificationId: undefined,
}

export default connect()(UploadFilesCustomer)
