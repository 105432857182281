import _orderBy from 'lodash/orderBy'

import { useGetAccountantReviewsQuery, GetAccountantReviewsParams } from '../api'

export const useAccountantReviews = ({ accountantId, skip }: GetAccountantReviewsParams & { skip?: boolean }) => {
    const { data, isError, isLoading } = useGetAccountantReviewsQuery({ accountantId }, { skip })

    const orderedAccountantReviews = _orderBy(data, ['reviewDate'], ['desc'])

    return {
        accountantReviews: data,
        orderedAccountantReviews,
        isError,
        isLoading,
    }
}
