import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { formatTimeFromNow } from '@scouts/helpers'
import { font, Line, Spacer, Table } from '@scouts/ui'

import { FILING_AREA_TITLES, PATH_CUSTOMERS, TAX_YEARS } from '@/constants'
import { FilingArea, SelfAssessmentSummaryDTO } from '@/models'

import { SelfAssessmentStatusLabel } from '@/components/self-assessments/SelfAssessmentStatusLabel'

interface CustomerOverviewSelfAssessmentProps {
    selfAssessment: SelfAssessmentSummaryDTO
}

export const CustomerOverviewSelfAssessment = ({ selfAssessment }: CustomerOverviewSelfAssessmentProps) => {
    const {
        id: selfAssessmentId,
        customerId,
        activeFilingAreas,
        isOnHold,
        status,
        taxYear,
        latestEventDateUTC,
    } = selfAssessment

    const filingAreas = activeFilingAreas
        ?.filter((filingArea) => filingArea !== FilingArea.General)
        .map((filingArea) => FILING_AREA_TITLES[filingArea])
        .join(', ')

    const selfAssessmentUrl = `${PATH_CUSTOMERS}/${customerId}/assessment/${selfAssessmentId}`

    return (
        <Table.Body.Row key={selfAssessmentId}>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={selfAssessmentUrl}>
                    <Spacer>
                        <TaxYear>{TAX_YEARS[Number(taxYear) as keyof typeof TAX_YEARS]}</TaxYear>
                        <SelfAssessmentStatusLabel status={status} />
                        {isOnHold && <OnHold>(On hold)</OnHold>}
                    </Spacer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="50%">
                <Table.Body.Cell.ContentLink Link={Link} to={selfAssessmentUrl}>
                    <Line size={font.small}>{filingAreas}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={selfAssessmentUrl} alignRight>
                    <Line size={font.small}>{formatTimeFromNow(latestEventDateUTC)}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const TaxYear = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const OnHold = styled.span`
    font-size: ${font.small};
    margin-left: 6px;
`
