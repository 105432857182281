import { PaginationQuery } from '@/models'

import { useGetSubscriptionsQuery } from '../api'

interface UseSubscriptionsParams {
    options: PaginationQuery
}

export const useSubscriptions = ({ options }: UseSubscriptionsParams) => {
    const { data: fetchedData, isError, isFetching, isLoading } = useGetSubscriptionsQuery({ options })
    const { data, pagination } = fetchedData || {}

    return {
        subscriptions: data,
        isError,
        isFetching,
        isLoading,
        pagination,
    }
}
