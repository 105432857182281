import { Page, Table } from '@scouts/ui'

import { MessageTemplateDTO } from '@/models/message-template'

import { MessageTemplateListItem } from './MessageTemplateListItem'

export const MessageTemplateList = ({ messageTemplates }: { messageTemplates: MessageTemplateDTO[] }) => {
    return (
        <Page.Section>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell width="70%">Template</Table.Header.Cell>
                        <Table.Header.Cell width="30%">Last updated</Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>

                <Table.Body>
                    {messageTemplates.map((template) => (
                        <MessageTemplateListItem key={template.id} template={template} />
                    ))}
                </Table.Body>
            </Table>
        </Page.Section>
    )
}
