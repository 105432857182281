import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Grid, GridItem, Line, Spacer } from '@scouts/ui'

import { PATH_TAX_CONSULTATIONS } from '@/constants'
import { TaxConsultationDomain } from '@/domain'
import { generatePathWithFilter } from '@/helpers/generate-path-with-filter'
import { AccountantDetailsDTO, TaxConsultationStatusFilter } from '@/models'
import { getUser } from '@/selectors/user'
import { useTaxConsultations } from '@/store/tax-consultations'

import { DashboardConsultationsItem } from './DashboardConsultationsItem'

const DISPLAY_COUNT = 8

export const DashboardConsultations = ({ accountantId }: { accountantId?: AccountantDetailsDTO['id'] }) => {
    const history = useHistory()
    const { id, isAdminUser } = useSelector(getUser)

    const requestedAccountantId: number = isAdminUser ? accountantId : id

    const { taxConsultations } = useTaxConsultations({
        options: { accountantId: requestedAccountantId, status: TaxConsultationStatusFilter.Booked },
    })

    const accountantConsultations = TaxConsultationDomain.getAccountantBookedConsultations(
        taxConsultations,
        requestedAccountantId
    )

    const consultationsCount = accountantConsultations?.length || 0
    const hasConsultations = accountantConsultations && consultationsCount > 0
    const hasMoreConsultations = consultationsCount > DISPLAY_COUNT

    if (!hasConsultations) return null

    const setOfConsultations = hasMoreConsultations
        ? accountantConsultations.slice(0, DISPLAY_COUNT)
        : accountantConsultations

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Booked consultations
                    </Line>
                    <ButtonLink
                        color={colors.neutralDarker}
                        onClick={() =>
                            history.push(
                                generatePathWithFilter(PATH_TAX_CONSULTATIONS, {
                                    accountantId: isAdminUser ? requestedAccountantId : null,
                                    status: TaxConsultationStatusFilter.Booked,
                                })
                            )
                        }
                    >
                        {hasMoreConsultations ? `View all (${consultationsCount - DISPLAY_COUNT} more)` : 'View all'}
                    </ButtonLink>
                </Flexer>
            </Spacer>
            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                {setOfConsultations.map((consultation) => (
                    <GridItem key={consultation.id}>
                        <DashboardConsultationsItem consultation={consultation} />
                    </GridItem>
                ))}
            </Grid>
        </Container>
    )
}
