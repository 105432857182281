export const REQUEST_CUSTOMER_CONVERSATIONS = 'REQUEST_CUSTOMER_CONVERSATIONS'
export const RECEIVE_CONVERSATIONS = 'RECEIVE_CONVERSATIONS'

export const requestCustomerConversations = (customerId) => ({
    type: REQUEST_CUSTOMER_CONVERSATIONS,
    data: { customerId },
})

export const receiveConversations = (conversations, customerId) => ({
    type: RECEIVE_CONVERSATIONS,
    data: { conversations, customerId },
})
