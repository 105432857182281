import { colors } from '@scouts/ui'

import {
    SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_CANCELLED,
    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_FILED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    TAX_CONSULTATION_STATUS_AWAITING_APPROVAL,
    TAX_CONSULTATION_STATUS_AWAITING_SUMMARY,
    TAX_CONSULTATION_STATUS_BOOKED,
    TAX_CONSULTATION_STATUS_CANCELLED,
    TAX_CONSULTATION_STATUS_COMPLETED,
    TAX_CONSULTATION_STATUS_STARTED,
} from '@/constants'
import { TaxRegistrationStatus } from '@/models'

export const backgroundSelfAssessment = (status) => {
    switch (status) {
        case SELF_ASSESSMENT_STATUS_SUBMITTED:
        case SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW:
        case SELF_ASSESSMENT_STATUS_READY_TO_FILE:
            return colors.blueLighter
        case SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED:
        case SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND:
        case SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS:
        case SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL:
            return colors.yellow
        case SELF_ASSESSMENT_STATUS_FILED:
        case SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED:
            return colors.mintLighter
        case SELF_ASSESSMENT_STATUS_CANCELLED:
            return colors.red
        default:
            return colors.neutralLightest
    }
}

export const backgroundConsultation = (status) => {
    switch (status) {
        case TAX_CONSULTATION_STATUS_STARTED:
            return colors.neutralLightest
        case TAX_CONSULTATION_STATUS_BOOKED:
        case TAX_CONSULTATION_STATUS_AWAITING_SUMMARY:
            return colors.blueLighter
        case TAX_CONSULTATION_STATUS_AWAITING_APPROVAL:
            return colors.yellow
        case TAX_CONSULTATION_STATUS_COMPLETED:
            return colors.mintLighter
        case TAX_CONSULTATION_STATUS_CANCELLED:
            return colors.red
        default:
            return colors.neutralLightest
    }
}

export const backgroundTaxRegistration = (status) => {
    switch (status) {
        case TaxRegistrationStatus.Submitted:
            return colors.blueLighter
        case TaxRegistrationStatus.InformationRequested:
        case TaxRegistrationStatus.Complete:
            return colors.yellow
        case TaxRegistrationStatus.Cancelled:
            return colors.red
        default:
            return colors.neutralLightest
    }
}
