import { generateApiPath } from '@scouts/helpers'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        syncAccountantExternalMessages: builder.mutation<number, { redirectUri: string; emailProvider: string }>({
            query: (params) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.EXTERNAL_MESSAGES.SYNC_ACCOUNTANT, undefined, {
                    queryParameters: params,
                }),
            }),
            invalidatesTags: [Tags.Messages],
        }),
        syncCustomerExternalMessages: builder.mutation<
            number,
            { redirectUri: string; emailProvider: string; customerId: number }
        >({
            query: ({ customerId, ...queryParameters }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.EXTERNAL_MESSAGES.SYNC_CUSTOMER, { customerId }, { queryParameters }),
            }),
            invalidatesTags: (result, _1, { customerId }) => (result ? [{ type: Tags.Messages, id: customerId }] : []),
        }),
    }),
})

export const { useSyncAccountantExternalMessagesMutation, useSyncCustomerExternalMessagesMutation } = extendedApi
