import { Flexer, Skeleton, SkeletonLine } from '@scouts/ui'

import { useFetchAccountantStatistics } from '@/store/accountant-statistics/hooks/use-fetch-accountant-statistics'

import Accountants from './Accountants'

export const AccountantStatistics = () => {
    const {
        fetchAccountantStatistics,
        accountantStatistics,
        accountantStatusStatistics,
        pagination,
        isFetching,
        isLoading,
    } = useFetchAccountantStatistics()

    if (isLoading) {
        return (
            <Skeleton gap="24px" padding="24px">
                <Flexer column gap="12px">
                    <SkeletonLine height="35px" width="80%" />
                </Flexer>
                <Flexer column gap="1px">
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="72px" repeat={10} />
                </Flexer>
            </Skeleton>
        )
    }

    return (
        <Accountants
            accountants={accountantStatistics}
            accountantStatusStatistics={accountantStatusStatistics}
            fetchAccountantStatistics={fetchAccountantStatistics}
            pagination={pagination}
            isFetching={isFetching}
        />
    )
}
