import PropTypes from 'prop-types'

import { AGENT_AUTH_STATUS_TITLES, AGENT_AUTH_STATUSES } from '../../constants'
import { FilterItem, FilterItemDropdownCheckboxes } from '../ui/filter'

const SearchFilterAgentAuthStatuses = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter.length > 0

    const activeValues = hasActiveFilter ? activeFilter.map((status) => AGENT_AUTH_STATUS_TITLES[status]) : ['Any']

    const values = AGENT_AUTH_STATUSES.map((status) => ({
        checked: activeFilter.findIndex((o) => o === status) > -1,
        label: AGENT_AUTH_STATUS_TITLES[status],
        value: status,
    }))

    const name = 'agentAuthorisationStatuses'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Agent auth status"
            activeValues={activeValues}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownCheckboxes
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterAgentAuthStatuses.propTypes = {
    activeFilter: PropTypes.array.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterAgentAuthStatuses
