import { generateApiPath } from '@scouts/helpers'

import { AccountantDetailsDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSupportUsers: builder.query<AccountantDetailsDTO[], void>({
            query: () => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.SUPPORT_USERS.GET.LIST),
            }),
            providesTags: [Tags.SupportUsers],
        }),
    }),
})

export const { useGetSupportUsersQuery } = extendedApi
