import { getMinutesToDate } from '@scouts/helpers'
import { radius, ButtonLink, Flexer, Notice, NoticeAction, Spacer } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { CompanyReturnDTO } from '@/models'
import { useUpdateCompanyReturn } from '@/store/company-returns/hooks'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

interface CompanyReturnOnHoldNoticeProps {
    companyReturn: CompanyReturnDTO
}

export const CompanyReturnOnHoldNotice = ({ companyReturn }: CompanyReturnOnHoldNoticeProps) => {
    const customerId = useCustomerIdFromPath()
    const { updateCompanyReturn, isLoading: isLoadingUpdateCompany } = useUpdateCompanyReturn()

    const { onHoldUntilDate } = companyReturn
    const isOnHold = onHoldUntilDate ? getMinutesToDate(onHoldUntilDate) / 60 > 0 : false

    const removeOnHold = () => {
        updateCompanyReturn({
            customerId,
            companyId: companyReturn.companyId,
            companyReturnId: companyReturn.id,
            data: { ...companyReturn, onHoldUntilDate: null },
        })
    }

    if (!isOnHold) return null

    return (
        <Spacer marginBottom="30px">
            <Flexer column gap="12px">
                <Notice borderRadius={radius.medium}>
                    Set as 'On hold' until {formatDate(companyReturn.onHoldUntilDate)}
                    <NoticeAction>
                        <ButtonLink onClick={removeOnHold} disabled={isLoadingUpdateCompany}>
                            Remove
                        </ButtonLink>
                    </NoticeAction>
                </Notice>
            </Flexer>
        </Spacer>
    )
}
