import { generateApiPath } from '@scouts/helpers'

import { AccountantStatisticsDTO, AccountantStatusStatisticsDTO, ResponseWithPagination } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'

export interface GetAccountantStatisticsParams {
    status?: string
    isOnTrial?: boolean
    allowTaxConsultation?: boolean
    textFilter?: string
    page?: number
    pageSize?: number
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAccountantStatistics: builder.query<
            ResponseWithPagination<AccountantStatisticsDTO[]>,
            GetAccountantStatisticsParams
        >({
            query: ({ status, isOnTrial, allowTaxConsultation, textFilter, page, pageSize }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_STATISTICS.GET.ALL, undefined, {
                    queryParameters: {
                        ...(status && { status }),
                        ...(isOnTrial && { isOnTrial }),
                        ...(allowTaxConsultation && { allowTaxConsultation }),
                        ...(textFilter && { textFilter }),
                        ...(page && { page }),
                        ...(pageSize && { pageSize }),
                    },
                }),
            }),
            providesTags: [Tags.AccountantStatistics],
            transformResponse: mapPaginatedResponse,
        }),
        getAccountantStatusStatistics: builder.query<AccountantStatusStatisticsDTO[], void>({
            query: () => ({
                method: 'GET',
                url: ENDPOINTS.ACCOUNTANT_STATISTICS.GET.STATUS,
            }),
            providesTags: [Tags.AccountantStatusStatistics],
        }),
    }),
})

export const { useGetAccountantStatisticsQuery, useGetAccountantStatusStatisticsQuery } = extendedApi
