import { colors, Label } from '@scouts/ui'

import { AccountantMeetingDomain } from '@/domain'
import { AccountantMeetingSummaryDTO } from '@/models'

export const AccountantMeetingsStatusLabel = ({ status }: { status: AccountantMeetingSummaryDTO['status'] }) => {
    const currentStatus = AccountantMeetingDomain.statuses[status]

    if (!currentStatus) return null

    return (
        <Label background={currentStatus.color} color={colors.black}>
            {currentStatus.title}
        </Label>
    )
}
