import '@scouts/ui/dist/reset.css'

import isPropValid from '@emotion/is-prop-valid'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { StyleSheetManager } from 'styled-components'

import App from './components/App'
import { store } from './store'

const container = document.getElementById('root')

if (!container) throw new Error('Failed to find the `#root` element')

const root = createRoot(container)

root.render(
    <Provider store={store}>
        <StyleSheetManager shouldForwardProp={isPropValid}>
            <App />
        </StyleSheetManager>
    </Provider>
)
