import { colors, Indicator, Tooltip } from '@scouts/ui'

import { CustomerDTO } from '@/models'

export const CustomerIndicatorsUTR = ({
    uniqueTaxpayerReference,
}: {
    uniqueTaxpayerReference: CustomerDTO['uniqueTaxpayerReference']
}) => {
    return (
        <Indicator background={uniqueTaxpayerReference ? colors.mintLighter : colors.neutralLightest}>
            <Tooltip content={uniqueTaxpayerReference ? 'UTR provided' : 'UTR not provided'}>UTR</Tooltip>
        </Indicator>
    )
}
