import { Flexer, Tabs } from '@scouts/ui'

import { PRODUCT_STATUS_CANCELLED, PRODUCT_STATUS_COMPLETED, PRODUCT_STATUS_INCOMPLETE } from '@/constants'
import {
    CustomerProductSearchProductStatus,
    CustomerProductSearchProductTypes,
    CustomerProductStatisticsDTO,
    ProductType,
} from '@/models'

import { useFilters } from './hooks'

interface CustomersFilterProps {
    customersStatistics: CustomerProductStatisticsDTO | undefined
    isAdminUser: boolean
}

export const CustomersFilter = ({ customersStatistics, isAdminUser }: CustomersFilterProps) => {
    const { activeFilters, applyFilter } = useFilters()

    const { productStatus: filterProductStatus, productType: filterProductType } = activeFilters

    const updateTypeFilter = (productType: CustomerProductSearchProductTypes | null) => {
        applyFilter({ updatedFilter: { productType, page: 1 } })
    }

    const updateStatusFilter = (productStatus: CustomerProductSearchProductStatus | null) => {
        applyFilter({ updatedFilter: { productStatus, page: 1 } })
    }

    const countPerFilter = (productType: CustomerProductSearchProductTypes | null): number => {
        const countMap: Partial<Record<CustomerProductSearchProductTypes, keyof CustomerProductStatisticsDTO>> = {
            [ProductType.SelfAssessment]: 'selfAssessments',
            [ProductType.TaxConsultation]: 'taxConsultations',
            [ProductType.ProductBundle]: 'productBundles',
        }

        const countProperty = countMap[(productType as CustomerProductSearchProductTypes) ?? ''] ?? 'all'

        return customersStatistics?.[countProperty] || 0
    }

    return (
        <Flexer spaceBetween column tabletRow gap="12px">
            <Tabs>
                <Tabs.Item onClick={() => updateTypeFilter(null)} isActive={!filterProductType}>
                    All
                    <Tabs.Item.Counter>{countPerFilter(null)}</Tabs.Item.Counter>
                </Tabs.Item>

                <Tabs.Item
                    onClick={() => updateTypeFilter(ProductType.SelfAssessment)}
                    isActive={filterProductType === ProductType.SelfAssessment}
                >
                    Tax return
                    <Tabs.Item.Counter>{countPerFilter(ProductType.SelfAssessment)}</Tabs.Item.Counter>
                </Tabs.Item>

                <Tabs.Item
                    onClick={() => updateTypeFilter(ProductType.TaxConsultation)}
                    isActive={filterProductType === ProductType.TaxConsultation}
                >
                    Consultation
                    <Tabs.Item.Counter>{countPerFilter(ProductType.TaxConsultation)}</Tabs.Item.Counter>
                </Tabs.Item>

                <Tabs.Item
                    onClick={() => updateTypeFilter(ProductType.ProductBundle)}
                    isActive={filterProductType === ProductType.ProductBundle}
                >
                    Bundle
                    <Tabs.Item.Counter>{countPerFilter(ProductType.ProductBundle)}</Tabs.Item.Counter>
                </Tabs.Item>
            </Tabs>

            <Tabs>
                <Tabs.Item onClick={() => updateStatusFilter(null)} isActive={!filterProductStatus}>
                    All
                </Tabs.Item>

                <Tabs.Item
                    onClick={() => updateStatusFilter(PRODUCT_STATUS_COMPLETED)}
                    isActive={filterProductStatus === PRODUCT_STATUS_COMPLETED}
                >
                    Completed
                </Tabs.Item>

                <Tabs.Item
                    onClick={() => updateStatusFilter(PRODUCT_STATUS_INCOMPLETE)}
                    isActive={filterProductStatus === PRODUCT_STATUS_INCOMPLETE}
                >
                    Not completed
                </Tabs.Item>

                {isAdminUser && (
                    <Tabs.Item
                        onClick={() => updateStatusFilter(PRODUCT_STATUS_CANCELLED)}
                        isActive={filterProductStatus === PRODUCT_STATUS_CANCELLED}
                    >
                        Cancelled
                    </Tabs.Item>
                )}
            </Tabs>
        </Flexer>
    )
}
