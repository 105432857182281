import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { AccountantPortalChrome, Button, ButtonLink, Form, Input } from '@scouts/ui'

import { receiveSuccessAlerts, saveCustomer } from '@/actions'
import { PATH_CUSTOMERS } from '@/constants'
import { copyTextToClipboard } from '@/helpers'

class PreviousAccountantDetailsContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customer: null,
            updatedDateUTC: null,
        }

        _bindAll(this, ['handleChange', 'save'])
    }

    componentDidMount() {
        const { customer } = this.props

        this.setState({ customer })
    }

    static getDerivedStateFromProps({ customer }) {
        const { updatedDateUTC } = customer

        return { updatedDateUTC }
    }

    handleChange({ name, value }) {
        const { customer, updatedDateUTC } = this.state

        const updatedCustomer = { ...customer, [name]: value, updatedDateUTC }

        this.setState({ customer: updatedCustomer })
    }

    copyToClipboard(value) {
        const { dispatch } = this.props

        copyTextToClipboard(value, () => dispatch(receiveSuccessAlerts('Copied to clipboard')))
    }

    save() {
        const { dispatch, history } = this.props
        const { customer } = this.state

        const { id: customerId } = customer

        dispatch(
            saveCustomer(customerId, customer, {
                onSuccess: () => {
                    history.push(`${PATH_CUSTOMERS}/${customerId}`)
                },
            })
        )
    }

    render() {
        const { customer } = this.state

        if (!customer) return null

        const { id: customerId, previousAccountantFullName, previousAccountantEmail } = customer

        return (
            <AccountantPortalChrome.Customer.Page.Container>
                <AccountantPortalChrome.Customer.Page.Header>
                    <AccountantPortalChrome.Customer.Page.Title>
                        Edit previous accountant details
                    </AccountantPortalChrome.Customer.Page.Title>
                </AccountantPortalChrome.Customer.Page.Header>

                <Form onSubmit={this.save}>
                    <Form.Row>
                        <Form.Row.Title>
                            Name
                            {previousAccountantFullName && (
                                <Form.Row.Action>
                                    <ButtonLink
                                        type="button"
                                        onClick={() => this.copyToClipboard(previousAccountantFullName)}
                                    >
                                        Copy
                                    </ButtonLink>
                                </Form.Row.Action>
                            )}
                        </Form.Row.Title>

                        <Form.Row.Content>
                            <Input
                                name="previousAccountantFullName"
                                value={previousAccountantFullName || ''}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>
                            Email
                            {previousAccountantEmail && (
                                <Form.Row.Action>
                                    <ButtonLink
                                        type="button"
                                        onClick={() => this.copyToClipboard(previousAccountantEmail)}
                                    >
                                        Copy
                                    </ButtonLink>
                                </Form.Row.Action>
                            )}
                        </Form.Row.Title>

                        <Form.Row.Content>
                            <Input
                                name="previousAccountantEmail"
                                value={previousAccountantEmail || ''}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Actions>
                        <Button type="submit">Save</Button>
                        <Button
                            isSecondary
                            onClick={() => {
                                const { history } = this.props
                                history.push(`${PATH_CUSTOMERS}/${customerId}`)
                            }}
                        >
                            Back to profile
                        </Button>
                    </Form.Actions>
                </Form>
            </AccountantPortalChrome.Customer.Page.Container>
        )
    }
}

PreviousAccountantDetailsContainer.propTypes = {
    customer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
}

export default connect()(withRouter(PreviousAccountantDetailsContainer))
