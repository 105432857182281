import {
    REASSIGNMENT_REASON_ACCOUNTANT_EMERGENCY,
    REASSIGNMENT_REASON_ACCOUNTANT_MISTAKE,
    REASSIGNMENT_REASON_ACCOUNTANT_PAUSED,
    REASSIGNMENT_REASON_ACCOUNTANT_REQUESTED_REASSIGNMENT,
    REASSIGNMENT_REASON_CUSTOMER_REQUESTED_REASSIGNMENT,
    REASSIGNMENT_REASON_ACCOUNTANT_OFFBOARDED,
    REASSIGNMENT_REASON_OPERATIONS_ACTION,
    REASSIGNMENT_REASONS_TITLES,
    REASSIGNMENT_REASON_ACCOUNTANT_UNRESPONSIVE,
} from '@/constants'

const defaultOptions = [
    {
        value: REASSIGNMENT_REASON_ACCOUNTANT_MISTAKE,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_ACCOUNTANT_MISTAKE],
    },
    {
        value: REASSIGNMENT_REASON_CUSTOMER_REQUESTED_REASSIGNMENT,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_CUSTOMER_REQUESTED_REASSIGNMENT],
    },
    {
        value: REASSIGNMENT_REASON_ACCOUNTANT_REQUESTED_REASSIGNMENT,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_ACCOUNTANT_REQUESTED_REASSIGNMENT],
    },
    {
        value: REASSIGNMENT_REASON_ACCOUNTANT_PAUSED,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_ACCOUNTANT_PAUSED],
    },
    {
        value: REASSIGNMENT_REASON_ACCOUNTANT_EMERGENCY,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_ACCOUNTANT_EMERGENCY],
    },
    {
        value: REASSIGNMENT_REASON_ACCOUNTANT_OFFBOARDED,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_ACCOUNTANT_OFFBOARDED],
    },
    {
        value: REASSIGNMENT_REASON_OPERATIONS_ACTION,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_OPERATIONS_ACTION],
    },
    {
        value: REASSIGNMENT_REASON_ACCOUNTANT_UNRESPONSIVE,
        title: REASSIGNMENT_REASONS_TITLES[REASSIGNMENT_REASON_ACCOUNTANT_UNRESPONSIVE],
    },
]

export const getReassignmentReasonsOptions = (reassignmentReasonType: keyof typeof REASSIGNMENT_REASONS_TITLES) => {
    const isOptionListed = defaultOptions.some(({ value }) => value === reassignmentReasonType)
    const shouldDisplayUnlistedOption = !isOptionListed && !!reassignmentReasonType

    return [
        {
            value: '',
            title: 'Choose…',
        },
        ...defaultOptions,
        ...(shouldDisplayUnlistedOption
            ? [
                  {
                      value: reassignmentReasonType,
                      title: REASSIGNMENT_REASONS_TITLES[reassignmentReasonType] || reassignmentReasonType,
                  },
              ]
            : []),
    ]
}
