import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CustomerEscalationsListItem from './CustomerEscalationsListItem'

const CustomerEscalationsListItemWrapper = styled.div`
    & + & {
        margin-top: 32px;
    }
`

const CustomerEscalationsList = ({ escalations }) => (
    <>
        {escalations.map((escalation) => (
            <CustomerEscalationsListItemWrapper key={escalation.id}>
                <CustomerEscalationsListItem escalation={escalation} />
            </CustomerEscalationsListItemWrapper>
        ))}
    </>
)

CustomerEscalationsList.propTypes = {
    escalations: PropTypes.array.isRequired,
}

export default connect()(CustomerEscalationsList)
