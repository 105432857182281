import { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import { matchSuccessResponse } from '@scouts/helpers'
import {
    addSuccessToast,
    colors,
    font,
    media,
    shadows,
    Button,
    ButtonLink,
    Checkbox,
    Chip,
    Flexer,
    Icon,
    IconLoader,
    Line,
    Loadable,
    Spacer,
    TextArea,
    Tooltip,
    UploadChip,
} from '@scouts/ui'

import {
    MESSAGE_SMS_LENGTH_CAP,
    MESSAGE_TEXT_TYPE_GENERAL_ACCOUNTANT_SUBJECT,
    MESSAGE_TEXT_TYPE_GENERAL_SUBJECT,
    MESSAGE_TYPE_EMAIL,
    MESSAGE_TYPE_SMS,
} from '@/constants'
import { getDraftMessageValidatedFiles } from '@/domain/draft-message'
import { featureFlags } from '@/feature-flags'
import { useDraftMessage } from '@/store/draft-messages'
import { useMessageSuggestion } from '@/store/message-suggestions/hooks/use-message-suggestion'
import { useSendCustomerMessage } from '@/store/messages'

import { useUser } from '@/hooks/use-user'

import { useAutosizeTextArea } from '../hooks/use-autosize-textarea'
import { useChatScoutsBetaTesters } from '../hooks/use-chatscouts-beta-testers'
import { useDraftMessageValidation } from '../hooks/use-draft-message-validation'

import { useMessagesContext } from '../context/messages-context'
import { MessagePreviewModal } from '../MessagePreviewModal'
import { MessageTemplatesPopoverProvider, MESSAGE_TEMPLATES_POPOVER_TRIGGER } from '../MessageTemplatesPopoverProvider'
import { MessageComposerFiles } from './MessageComposerFiles'

interface MessageComposerProps {
    onSuccessfulSend?: () => void
}

export const MessageComposer = ({ onSuccessfulSend }: MessageComposerProps) => {
    const { customerId } = useMessagesContext()
    const { isAdminUser } = useUser()
    const [isMessagePreviewModalOpen, setIsMessagePreviewModalOpen] = useState(false)
    const messageRef = useRef('')

    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    useAutosizeTextArea(textAreaRef)

    const { sendCustomerMessage, isLoading: isSendLoading } = useSendCustomerMessage()
    const { getMessageSuggestion, isFetching: isFetchingMessageSuggestion } = useMessageSuggestion()
    const { draftMessage, updateDraftMessage, hasFiles, getNotDuplicateFiles, removeDraftMessage } = useDraftMessage({
        customerId,
    })

    const [draftMessageContent, setDraftMessageContent] = useState(draftMessage.content)

    useEffect(() => {
        setDraftMessageContent(draftMessage.content)
    }, [draftMessage])

    const handleChangeMessageContent = ({ value }: { value: string }) => {
        updateDraftMessage({ ...draftMessage, content: value })
        setDraftMessageContent(value)
    }

    const handleChangeMessageType = ({ checked }: { checked: boolean }) =>
        updateDraftMessage({ ...draftMessage, messageType: checked ? MESSAGE_TYPE_SMS : MESSAGE_TYPE_EMAIL })

    const handleSend = () => {
        sendCustomerMessage({
            attachments:
                draftMessage.messageType === MESSAGE_TYPE_EMAIL
                    ? getDraftMessageValidatedFiles(draftMessage.files).filesValidated
                    : undefined,
            customerId,
            messageContent: draftMessage.content,
            messageType: draftMessage.messageType,
            subject: isAdminUser ? MESSAGE_TEXT_TYPE_GENERAL_SUBJECT : MESSAGE_TEXT_TYPE_GENERAL_ACCOUNTANT_SUBJECT,
        }).then(
            matchSuccessResponse(() => {
                if (onSuccessfulSend) onSuccessfulSend()
                addSuccessToast({
                    body:
                        draftMessage.messageType === MESSAGE_TYPE_SMS
                            ? 'SMS successfully sent'
                            : 'Message successfully sent',
                })
                removeDraftMessage()
            })
        )
    }

    const handleGetMessageSuggestion = () => {
        let starterAnswer = undefined
        if (draftMessage?.content !== messageRef.current) {
            starterAnswer = draftMessage?.content
        }

        getMessageSuggestion({ customerId, starterAnswer })?.then((response) => {
            if (response.error) return

            const messageSuggestion = response?.data
            if (messageSuggestion?.content && messageSuggestion.content?.length > 0) {
                updateDraftMessage({ ...draftMessage, content: messageSuggestion.content })
                messageRef.current = messageSuggestion.content
            }
        })
    }

    const handleAddFiles = (files: File[]) => {
        updateDraftMessage({ ...draftMessage, files: [...draftMessage.files, ...getNotDuplicateFiles(files)] })
    }

    const handleCloseMessageTemplatesPopover = () => {
        if (textAreaRef.current) textAreaRef.current.focus()
    }

    const {
        isFileUploadDisabled,
        isSendDisabled,
        isSms,
        shouldDisplayFiles,
        shouldDisplayPhoneNumberNotice,
        shouldDisplaySmsCharacterLimit,
        shouldDisplaySmsCharactersRemaining,
        shouldDisplaySmsOption,
    } = useDraftMessageValidation({ customerId, draftMessage })

    const popoverTrigger = { [MESSAGE_TEMPLATES_POPOVER_TRIGGER]: true }

    const { isUserBetaTester } = useChatScoutsBetaTesters()
    const shouldDisplayChatScoutsButton = featureFlags.featureMessageSuggestions && isUserBetaTester

    return (
        <Container>
            <MessageTemplatesPopoverProvider customerId={customerId} onClose={handleCloseMessageTemplatesPopover} />

            <Spacer marginBottom="12px">
                <Flexer alignStart column gap="12px" spaceBetween tabletAlignCenter tabletGap="24px" tabletRow>
                    <Flexer alignBaseline column gap="12px" tabletGap="24px" tabletRow tabletAlignCenter>
                        <Title>Send message</Title>

                        {shouldDisplaySmsOption && (
                            <Flexer alignBaseline column gap="12px" tabletRow tabletAlignCenter>
                                <Spacer>
                                    <Tooltip shouldDisplay={hasFiles} content="Attachments will be removed from SMS">
                                        <Checkbox
                                            checked={isSms}
                                            isSmall
                                            label="Send as SMS"
                                            name="isSms"
                                            onChange={handleChangeMessageType}
                                        />
                                    </Tooltip>
                                </Spacer>

                                {shouldDisplaySmsCharacterLimit && (
                                    <Spacer>
                                        <Chip size="small" background={colors.red} color={colors.white}>
                                            SMS character limit {MESSAGE_SMS_LENGTH_CAP} exceeded
                                        </Chip>
                                    </Spacer>
                                )}

                                {shouldDisplaySmsCharactersRemaining && (
                                    <Spacer>
                                        <Chip size="small" background={colors.white} color={colors.black}>
                                            {MESSAGE_SMS_LENGTH_CAP - draftMessageContent.length} characters left
                                        </Chip>
                                    </Spacer>
                                )}

                                {shouldDisplayPhoneNumberNotice && (
                                    <Spacer>
                                        <Chip size="small" background={colors.red} color={colors.white}>
                                            Make sure client has a valid phone number
                                        </Chip>
                                    </Spacer>
                                )}
                            </Flexer>
                        )}
                    </Flexer>

                    <Flexer alignBaseline gap="24px" spaceBetween width="100%" tabletWidth="auto">
                        <ButtonLink {...popoverTrigger} disabled={isSendLoading}>
                            Use template
                        </ButtonLink>

                        {shouldDisplayChatScoutsButton &&
                            (isFetchingMessageSuggestion ? (
                                <MessageSuggestionsWrapper>
                                    <ButtonLink onClick={handleGetMessageSuggestion}>
                                        <Flexer alignCenter gap="6px">
                                            <Line size={font.small}>ChatScouts it!</Line>
                                            <Icon Component={IconLoader} color={colors.blue} />
                                        </Flexer>
                                    </ButtonLink>
                                </MessageSuggestionsWrapper>
                            ) : (
                                <ButtonLink onClick={handleGetMessageSuggestion}>
                                    <Flexer alignCenter gap="6px">
                                        <Line size={font.small}>ChatScouts it!</Line>
                                        <Icon Component={IconLoader} color={colors.blue} />
                                    </Flexer>
                                </ButtonLink>
                            ))}
                    </Flexer>
                </Flexer>
            </Spacer>

            <Spacer margin="12px 0">
                <TextAreaContainer>
                    <Loadable isLoading={isFetchingMessageSuggestion}>
                        <TextArea
                            disabled={isSendLoading}
                            onChange={handleChangeMessageContent}
                            placeholder="Your message…"
                            ref={textAreaRef}
                            rows={1}
                            value={draftMessageContent || ''}
                        />
                    </Loadable>
                </TextAreaContainer>
            </Spacer>

            {shouldDisplayFiles && <MessageComposerFiles customerId={customerId} isSendLoading={isSendLoading} />}

            <Spacer marginTop="18px">
                <Flexer alignCenter spaceBetween>
                    <Spacer>
                        <UploadChip
                            variant="inverted"
                            disabled={isFileUploadDisabled || isSendLoading}
                            label="+ Add file"
                            onUpload={handleAddFiles}
                        />
                    </Spacer>
                    <Flexer gap="24px" alignCenter>
                        <PreviewMessage>
                            {isSms ? (
                                <>
                                    Greeting and sign-off will <em>NOT</em> be added to SMS messages
                                </>
                            ) : (
                                'Greeting and sign-off will be added automatically'
                            )}{' '}
                            <PreviewMessageAction type="button" onClick={() => setIsMessagePreviewModalOpen(true)}>
                                See how it looks
                            </PreviewMessageAction>
                        </PreviewMessage>

                        <Button isLoading={isSendLoading} disabled={isSendDisabled} onClick={handleSend}>
                            Send
                        </Button>
                    </Flexer>
                </Flexer>
            </Spacer>

            {isMessagePreviewModalOpen && (
                <MessagePreviewModal
                    customerId={customerId}
                    isSms={isSms}
                    messageContent={draftMessageContent}
                    onClose={() => setIsMessagePreviewModalOpen(false)}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    padding: 18px;
    background-color: ${colors.blueLighter};
    border-radius: 12px;
    box-shadow: ${shadows.high};

    ${media.tablet} {
        padding: 24px 36px;
    }
`

const Title = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
`

const PreviewMessage = styled.div`
    display: none;

    ${media.desktop} {
        color: ${colors.black};
        font-size: ${font.smaller};
        display: block;
        margin-left: 12px;

        em {
            font-weight: ${font.weight.medium};
            font-style: italic;
        }
    }
`

const PreviewMessageAction = styled.button`
    color: ${colors.blue};
    text-decoration: underline;

    ${media.pointer} {
        &:hover {
            color: ${colors.black};
        }
    }
`

const TextAreaContainer = styled.div`
    textarea {
        resize: none;
        max-height: 216px;
        min-height: 48px;
        transition: min-height 0.15s linear;

        &:focus {
            min-height: 84px;
        }

        ${media.tablet} {
            min-height: 84px;
        }
    }
`

const textClip = keyframes`
     to {
         background-position: 200% center;
     }
 `

const MessageSuggestionsWrapper = styled.div`
    background-image: linear-gradient(
        -225deg,
        ${colors.blueLighter} 0%,
        ${colors.blue} 20%,
        ${colors.purple} 40%,
        ${colors.red} 60%,
        ${colors.orange} 80%,
        ${colors.yellow} 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${textClip} 2s linear infinite;

    &:hover button {
        opacity: 1;
    }
`
