export const REQUEST_SELF_ASSESSMENT_FILES = 'REQUEST_SELF_ASSESSMENT_FILES'
export const RECEIVE_SELF_ASSESSMENT_FILES = 'RECEIVE_SELF_ASSESSMENT_FILES'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const REMOVE_FILE = 'REMOVE_FILE'
export const DELETE_FILE = 'DELETE_FILE'
export const UPLOAD_ACCOUNTANT_FILE = 'UPLOAD_ACCOUNTANT_FILE'
export const DELETE_ACCOUNTANT_FILE = 'DELETE_ACCOUNTANT_FILE'

export const requestSelfAssessmentFiles = (data) => ({ type: REQUEST_SELF_ASSESSMENT_FILES, data })

export const receiveSelfAssessmentFiles = (data) => ({ type: RECEIVE_SELF_ASSESSMENT_FILES, data })

export const uploadFile = (data, options = {}) => ({ type: UPLOAD_FILE, data, options })

export const downloadFile = (data) => ({ type: DOWNLOAD_FILE, data })

export const removeFile = (data) => ({ type: REMOVE_FILE, data })

export const deleteFile = (data) => ({ type: DELETE_FILE, data })

export const uploadAccountantFile = (data, options = {}) => ({ type: UPLOAD_ACCOUNTANT_FILE, data, options })

export const deleteAccountantFile = (data, options = {}) => ({ type: DELETE_ACCOUNTANT_FILE, data, options })
