import { NavLink } from 'react-router-dom'

import { useAccountantPortalChrome, AccountantPortalChrome } from '@scouts/ui'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { useCustomerNav } from './hooks/use-customer-nav'

import { TourLinkOverview, TourLinkTaxReturn } from '../feature-tour/TourFirstTaxReturn'

export const CustomerNav = () => {
    const { customer } = useCustomerFromPath()
    const { isCustomerSidebarVisible, setIsCustomerSidebarVisible, visibleCustomerMenuItemsCount } =
        useAccountantPortalChrome()
    const menuItems = useCustomerNav()

    const { isDemoCustomer } = customer

    const visibleMainMenuItems = menuItems.slice(0, visibleCustomerMenuItemsCount)
    const visibleSecondaryMenuItems = menuItems.slice(visibleCustomerMenuItemsCount, menuItems.length)

    return (
        <>
            <AccountantPortalChrome.Customer.Nav.Container>
                <AccountantPortalChrome.Customer.Nav.Title
                    onClick={() => setIsCustomerSidebarVisible(!isCustomerSidebarVisible)}
                >
                    {customer.fullName}
                </AccountantPortalChrome.Customer.Nav.Title>

                <AccountantPortalChrome.Customer.Nav.Items>
                    {visibleMainMenuItems.map(({ to, title, className }) => (
                        <AccountantPortalChrome.Customer.Nav.Item
                            as={NavLink}
                            to={to}
                            className={className}
                            key={title}
                        >
                            {title === 'Overview' && className === 'is-active' && isDemoCustomer && (
                                <TourLinkOverview />
                            )}
                            {title === 'Tax return' && className === 'is-active' && isDemoCustomer && (
                                <TourLinkTaxReturn />
                            )}
                            <span>{title}</span>
                        </AccountantPortalChrome.Customer.Nav.Item>
                    ))}

                    {visibleSecondaryMenuItems.length > 0 && (
                        <AccountantPortalChrome.Customer.Nav.Secondary items={visibleSecondaryMenuItems} />
                    )}
                </AccountantPortalChrome.Customer.Nav.Items>
            </AccountantPortalChrome.Customer.Nav.Container>
        </>
    )
}
