export const REQUEST_REWARDS = 'REQUEST_REWARDS'
export const RECEIVE_REWARDS = 'RECEIVE_REWARDS'
export const RECEIVE_REWARD = 'RECEIVE_REWARD'
export const MARK_REWARD_AS_PAID = 'MARK_REWARD_AS_PAID'
export const MARK_REWARD_AS_IGNORED = 'MARK_REWARD_AS_IGNORED'
export const TRIGGER_REWARD_PAYMENT = 'TRIGGER_REWARD_PAYMENT'
export const RECEIVE_REWARD_PAYMENT_ERROR = 'RECEIVE_REWARD_PAYMENT_ERROR'

export const requestRewards = (data) => ({ type: REQUEST_REWARDS, data })

export const receiveRewards = (data, meta) => ({ type: RECEIVE_REWARDS, data, meta })

export const receiveReward = (data) => ({ type: RECEIVE_REWARD, data })

export const markRewardAsPaid = (data) => ({ type: MARK_REWARD_AS_PAID, data })

export const markRewardAsIgnored = (data) => ({ type: MARK_REWARD_AS_IGNORED, data })

export const triggerRewardPayment = (data) => ({ type: TRIGGER_REWARD_PAYMENT, data })

export const receiveRewardPaymentError = () => ({ type: RECEIVE_REWARD_PAYMENT_ERROR })
