import { AccountantStatusTypes } from '../accountant'
import { AgentAuthorisationStatusTypes } from '../agent-authorisation'
import { IdentityVerificationStatusTypes } from '../identity-verification'
import { IssueAdminDTO } from '../issue'
import { PriorityUnresolvedNoteDTO } from '../note'
import { PaymentStatusTypes } from '../payment-status'
import { RefundReasonDTO } from '../refunds'
import { SelfEmploymentProfileDTO } from '../self-employment-profile'
import { TaxRegistrationStatusTypes } from '../tax-registration'
import { UserTypeTypes } from '../user'
import { CapitalGainsDTO } from './capital-gains'
import { CharitableDonationsDTO } from './charitable-donations'
import { DividendsAndInterestDTO } from './dividends-interest'
import { EmploymentAdminDTO } from './employment'
import { ForeignIncomeDTO, ForeignIncomeSummaryDTO } from './foreign-income'
import { InvestmentSchemesDTO } from './investment-schemes'
import { KoinlyReportSummaryDTO } from './koinly-report'
import { OtherIncomeDTO } from './other-income'
import { PensionsAndBenefitsDTO } from './pension-benefits'
import { PensionsContributionsDTO } from './pension-contributions'
import { PropertyIncomeDTO } from './property-income'
import { SelfAssessmentFileDTO } from './self-assessment-file'
import { SelfAssessmentUserIncomePlatformDTO } from './self-assessment-user-income-platform'
import { SelfEmploymentDTO, SelfEmploymentExpensesDTO } from './self-employment'
import { StudentLoanDTO } from './student-loan'
import { UberDataDTO } from './uber-data'
import { WorkFromHomeAllowanceDTO } from './work-home-allowance'

export const SelfAssessmentReason = {
    EarningsLimit: 'EarningsLimit',
    SelfEmployment: 'SelfEmployment',
    PropertyIncome: 'PropertyIncome',
    DividendsAndInterest: 'DividendsAndInterest',
    CapitalGains: 'CapitalGains',
    CompanyDirector: 'CompanyDirector',
    HmrcLetter: 'HmrcLetter',
    TaxRefund: 'TaxRefund',
    Other: 'Other',
    ForeignIncome: 'ForeignIncome',
} as const

export type SelfAssessmentReasonTypes = (typeof SelfAssessmentReason)[keyof typeof SelfAssessmentReason]

export interface SelfAssessmentDTO {
    files: SelfAssessmentFileDTO[] | null
    issues: IssueAdminDTO | null
    reasons: SelfAssessmentReasonTypes[] | null
    selfAssessmentReasonDescription: string | null
    employments: EmploymentAdminDTO[] | null
    foreignIncomes: ForeignIncomeDTO[] | null
    foreignIncomeSummary: ForeignIncomeSummaryDTO | null
    selfEmployment: SelfEmploymentDTO | null
    selfEmploymentExpenses: SelfEmploymentExpensesDTO[] | null
    charitableDonations: CharitableDonationsDTO | null
    pensionsContributions: PensionsContributionsDTO | null
    propertyIncome: PropertyIncomeDTO | null
    dividendsAndInterest: DividendsAndInterestDTO | null
    pensionsAndBenefits: PensionsAndBenefitsDTO | null
    otherIncome: OtherIncomeDTO | null
    studentLoan: StudentLoanDTO | null
    capitalGains: CapitalGainsDTO | null
    koinlyReportSummary: KoinlyReportSummaryDTO | null
    investmentSchemes: InvestmentSchemesDTO | null
    uberData: UberDataDTO | null
    selfEmploymentProfile: SelfEmploymentProfileDTO
    workFromHomeAllowance: WorkFromHomeAllowanceDTO | null
    userIncomePlatformIncomeReportsFinished: SelfAssessmentUserIncomePlatformDTO[] | null
    userIncomePlatformIncomeReportsInProgress: SelfAssessmentUserIncomePlatformDTO[] | null
    id: number
    customerId: number
    submittedDateUTC: string | null
    taxYear: string
    status: SelfAssessmentStatusTypes
    statusChangedDateUTC: string
    latestEventDateUTC: string
    refundDateUTC: string | null
    hasEmployment: boolean
    hasSelfEmployment: boolean
    hasPropertyEarnings: boolean
    hasCapitalGains: boolean
    hasDividendsOrInterest: boolean
    hasPensionsOrBenefits: boolean
    hasForeignIncome: boolean
    hasOtherIncome: boolean
    hasCharitableDonations: boolean
    hasPensionsContributions: boolean
    hasStudentLoans: boolean
    hasInvestmentSchemes: boolean
    hasWorkFromHomeAllowance: boolean
    partnerIncomeUnderAllowanceLimit: boolean | null
    price: number
    hasPaid: boolean
    isOnHold: boolean
    lockedForUserEditing: boolean
    paymentStatus: PaymentStatusTypes
    paymentDateUTC: string | null
    payAfterFiling: boolean
    payThroughTaxCodeRequested: boolean | null
    payThroughTaxCodeAllowed: boolean
    firstTimeSelfAssessment: boolean | null
    hasLastYearPaymentsOnAccount: boolean | null
    lastYearPaymentsOnAccountAmount: number | null
    lastYearFirstPaymentOnAccount: number | null
    lastYearSecondPaymentOnAccount: number | null
    lastYearTotalPaymentOnAccount: number | null
    taxLiability: number | null
    activeFilingAreas: FilingAreaTypes[] | null
    lateFiling: boolean
    defaultsFromPreviousReturn: boolean | null
    oneClickTaxReturn: boolean | null
    createdByUserType: UserTypeTypes
    totalIncome: number | null
    totalTaxableIncome: number | null
    totalTaxDue: number | null
    taxDue: number | null
    paymentOnAccount: number | null
    customerApprovalDateUTC: string | null
    filedDateUTC: string | null
    accountantEmail: string | null
    accountantFullName: string | null
    onHoldUntilDate: string | null
    refundReason: RefundReasonDTO
    isMissingAgreement: boolean
    revenueDateUTC: string | null
    refilingStatus: SelfAssessmentRefilingStatusTypes
}

export interface SelfAssessmentSummaryDTO {
    id: number
    accountantId: number | null
    accountantEmail: string | null
    accountantFullName: string | null
    accountantStatus: AccountantStatusTypes | null
    customerId: number
    customerAgentAuthorisationStatus: AgentAuthorisationStatusTypes | null
    customerEmail: string | null
    customerFullName: string | null
    customerIdentityVerificationStatus: IdentityVerificationStatusTypes | null
    customerHasOutstandingPayments: boolean
    customerPhoneNumber: string | null
    customerTaxRegistrationStatus: TaxRegistrationStatusTypes | null
    customerUniqueTaxpayerReference: string | null
    customerUnresolvedNoteCount: number
    customerUnsubscribeFromBulkEmails: boolean
    filedDateUTC: string | null
    hasPaid: boolean
    isOnHold: boolean
    latestEventDateUTC: string | null
    lockedForUserEditing: boolean
    numberOfFiles: number
    numberOfNotificationEvents: number
    oneClickTaxReturn: boolean | null
    onHoldUntilDate: string | null
    payAfterFiling: boolean
    payThroughTaxCodeRequested: boolean | null
    priorityUnresolvedNotes: PriorityUnresolvedNoteDTO | null
    revenueDateUTC: string | null
    status: SelfAssessmentStatusTypes
    statusChangedDateUTC: string
    taxYear: string
    unresolvedNoteCount: number
    activeFilingAreas: FilingAreaTypes[] | null
    refilingStatus: SelfAssessmentRefilingStatusTypes
}

export const SelfAssessmentStatus = {
    Created: 'Created',
    Started: 'Started',
    UploadingDocuments: 'UploadingDocuments',
    Submitted: 'Submitted',
    ProcessingDocuments: 'ProcessingDocuments',
    AccountantReview: 'AccountantReview',
    InformationRequired: 'InformationRequired',
    AwaitingCustomerApproval: 'AwaitingCustomerApproval',
    CustomerCorrections: 'CustomerCorrections',
    ReadyToFile: 'ReadyToFile',
    Filed: 'Filed',
    Drafted: 'Drafted',
    FiledAwaitingRefund: 'FiledAwaitingRefund',
    Cancelled: 'Cancelled',
    CompletedNotFiled: 'CompletedNotFiled',
} as const

export type SelfAssessmentStatusTypes = (typeof SelfAssessmentStatus)[keyof typeof SelfAssessmentStatus]

export const SelfAssessmentStatusTitles: Record<SelfAssessmentStatusTypes, string> = {
    Created: 'Created',
    Drafted: 'Drafted',
    Started: 'Answering questions',
    UploadingDocuments: 'Uploading documents',
    Submitted: 'Submitted',
    AccountantReview: 'In review',
    InformationRequired: 'Info requested',
    AwaitingCustomerApproval: 'Awaiting approval',
    CustomerCorrections: 'Making corrections',
    Cancelled: 'Cancelled',
    ReadyToFile: 'Ready to file',
    FiledAwaitingRefund: 'Filed & awaiting refund',
    Filed: 'Filed',
    CompletedNotFiled: 'Completed, not filed',
    ProcessingDocuments: 'Processing documents',
}

export const FilingArea = {
    General: 'General',
    Employment: 'Employment',
    SelfEmployment: 'SelfEmployment',
    CapitalGains: 'CapitalGains',
    Property: 'Property',
    DividendsAndInterest: 'DividendsAndInterest',
    PensionsAndBenefits: 'PensionsAndBenefits',
    ForeignIncome: 'ForeignIncome',
    OtherIncome: 'OtherIncome',
    PensionsContributions: 'PensionsContributions',
    CharitableGiving: 'CharitableGiving',
    StudentLoans: 'StudentLoans',
    InvestmentScheme: 'InvestmentScheme',
} as const

export type FilingAreaTypes = (typeof FilingArea)[keyof typeof FilingArea]

const SelfAssessmentRefilingStatus = {
    None: 'None',
    NeedsRefiling: 'NeedsRefiling',
    Refiled: 'Refiled',
} as const

export type SelfAssessmentRefilingStatusTypes =
    (typeof SelfAssessmentRefilingStatus)[keyof typeof SelfAssessmentRefilingStatus]
