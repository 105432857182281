import { useState } from 'react'

import { matchErrorResponse, matchSuccessResponse } from '@scouts/helpers'
import {
    addErrorToast,
    Button,
    Checkbox,
    CheckboxProps,
    Form,
    Input,
    InputProps,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Select,
} from '@scouts/ui'

import { saveCustomer } from '@/actions'
import { AGENT_AUTH_REASON_TYPE_TITLES } from '@/constants'
import { isValidPostCode, isValidUtr } from '@/helpers'
import { AgentAuthorisationDTO, AgentAuthorisationReasonType, CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'
import { useRequestHmrcAgentAuthorisation } from '@/store/agent-authorisations'

import { useUser } from '@/hooks/use-user'

interface AgentAuthRequestModalProps {
    agentAuthorisation?: AgentAuthorisationDTO
    taxScoutsReference: string
    customer: CustomerDTO
    onError: () => void
    closeModal: () => void
}

export const AgentAuthRequestModal = ({
    agentAuthorisation,
    taxScoutsReference: initialTaxScoutsReference,
    customer,
    onError,
    closeModal,
}: AgentAuthRequestModalProps) => {
    const dispatch = useAppDispatch()

    const { id: accountantId } = useUser()
    const {
        id: customerId,
        uniqueTaxpayerReference: customerUniqueTaxpayerReference,
        addressPostcode: customerAddressPostCode,
        fullName,
    } = customer

    const initialState = {
        hasAddedReasonType: false,
        reasonType: null,
        addressPostcode: customerAddressPostCode || '',
        customerAbroad: false,
        taxScoutsReference: initialTaxScoutsReference || String(customerId),
        uniqueTaxpayerReference: customerUniqueTaxpayerReference || '',
        isRequesting: false,
    }

    const [state, setState] = useState(initialState)

    const handleChange: InputProps['onChange'] = ({ name, value }) => {
        let updatedValue = value

        if (name === 'uniqueTaxpayerReference' || name === 'addressPostcode') {
            updatedValue = value.toUpperCase()
        }

        setState({ ...state, [name]: updatedValue })
    }

    const handleCheckboxChange: CheckboxProps['onChange'] = ({ name, checked }) => {
        setState({ ...state, [name]: checked })
    }

    const { requestHmrcAgentAuthorisation, isLoading: isLoadingRequest } = useRequestHmrcAgentAuthorisation()

    const requestAgentAuth = () => {
        const { reasonType, customerAbroad, taxScoutsReference } = state

        requestHmrcAgentAuthorisation({
            data: {
                agentAuthorisationId: agentAuthorisation?.id || null,
                customerId,
                accountantId: Number(accountantId),
                customerAbroad,
                taxScoutsReference,
                reasonType,
            },
        })
            .then(matchErrorResponse(onError))
            .then(matchSuccessResponse(closeModal))
    }

    const handleSubmit = () => {
        const { addressPostcode, customerAbroad, uniqueTaxpayerReference } = state

        const updatedAddressPostCode = addressPostcode.trim()

        if (!isValidUtr(uniqueTaxpayerReference)) {
            addErrorToast({ body: 'UTR number should be exactly 10 digits long' })
            return
        }

        if (!(customerAbroad || isValidPostCode(updatedAddressPostCode))) {
            addErrorToast({ body: 'Please check the format of the post code' })
            return
        }

        const updatedCustomer = { ...customer, uniqueTaxpayerReference }

        if (!customerAbroad) {
            updatedCustomer.addressPostcode = updatedAddressPostCode
        }

        setState({ ...state, isRequesting: true })

        dispatch(
            saveCustomer(customer.id, updatedCustomer, {
                hideMessage: true,
                onSuccess: () => requestAgentAuth(),
            })
        )
    }

    const {
        reasonType,
        hasAddedReasonType,
        addressPostcode,
        customerAbroad,
        isRequesting,
        taxScoutsReference,
        uniqueTaxpayerReference,
    } = state

    return (
        <Modal onClose={closeModal}>
            {!hasAddedReasonType && (
                <>
                    <ModalHeader>
                        <ModalTitle>Let us know why you need agent authorisation</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Form.Row.Title>Agent authorisation reason</Form.Row.Title>
                            <Form.Row.Content>
                                <Select
                                    width="100%"
                                    name="reasonType"
                                    onChange={handleChange}
                                    value={reasonType || ''}
                                    options={[
                                        { title: 'Choose reason…' },
                                        {
                                            value: AgentAuthorisationReasonType.Required,
                                            title: AGENT_AUTH_REASON_TYPE_TITLES[AgentAuthorisationReasonType.Required],
                                        },
                                        {
                                            value: AgentAuthorisationReasonType.PostFilingSupport,
                                            title: AGENT_AUTH_REASON_TYPE_TITLES[
                                                AgentAuthorisationReasonType.PostFilingSupport
                                            ],
                                        },
                                    ]}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                    </ModalBody>
                    <ModalFooter>
                        <ModalActions>
                            <Button isSecondary onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    setState({ ...state, hasAddedReasonType: true })
                                }}
                                disabled={!reasonType}
                            >
                                Continue
                            </Button>
                        </ModalActions>
                    </ModalFooter>
                </>
            )}
            {hasAddedReasonType && (
                <Form onSubmit={handleSubmit}>
                    <ModalHeader>
                        <ModalTitle>Request HMRC agent authorisation</ModalTitle>
                        <ModalSubtitle>Review details and request authorisation</ModalSubtitle>
                    </ModalHeader>

                    <ModalBody>
                        <Form.Row isCompact>
                            <Form.Row.Title>Client name</Form.Row.Title>
                            <Form.Row.Content>{fullName}</Form.Row.Content>
                        </Form.Row>
                        <Form.Row isCompact>
                            <Form.Row.Title>Reference</Form.Row.Title>
                            <Form.Row.Content>
                                <Input name="taxScoutsReference" value={taxScoutsReference} onChange={handleChange} />
                            </Form.Row.Content>
                        </Form.Row>
                        <Form.Row isCompact>
                            <Form.Row.Title>UTR number</Form.Row.Title>
                            <Form.Row.Content>
                                <Input
                                    name="uniqueTaxpayerReference"
                                    value={uniqueTaxpayerReference}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Row.Content>
                        </Form.Row>
                        <Form.Row isCompact>
                            <Form.Row.Title>Post code</Form.Row.Title>
                            <Form.Row.Content>
                                <Form.InlineControl>
                                    <Input
                                        name="addressPostcode"
                                        value={addressPostcode}
                                        onChange={handleChange}
                                        required={!customerAbroad}
                                        disabled={customerAbroad}
                                    />
                                </Form.InlineControl>
                                <Form.InlineControl>
                                    <Checkbox
                                        isSmall
                                        name="customerAbroad"
                                        label="Living abroad"
                                        onChange={handleCheckboxChange}
                                        checked={customerAbroad}
                                    />
                                </Form.InlineControl>
                            </Form.Row.Content>
                        </Form.Row>
                    </ModalBody>

                    <ModalFooter>
                        <ModalActions>
                            <Button
                                isSecondary
                                onClick={() => {
                                    setState({ ...state, hasAddedReasonType: false })
                                }}
                            >
                                Back
                            </Button>
                            <Button type="submit" disabled={isRequesting} isLoading={isLoadingRequest}>
                                Continue
                            </Button>
                        </ModalActions>
                    </ModalFooter>
                </Form>
            )}
        </Modal>
    )
}
