import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getCustomerUnresolvedPriorityEscalationNotes } from '@/selectors'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { CustomerEscalationsBannerNote } from './CustomerEscalationsBannerNote'

export const CustomerEscalationsBanner = () => {
    const { customer } = useCustomerFromPath()
    const { id: customerId } = customer

    const escalationNotes = useSelector((state) => getCustomerUnresolvedPriorityEscalationNotes(state, { customerId }))

    const hasEscalationNotes = escalationNotes.length > 0

    if (!hasEscalationNotes) return null

    return (
        <Container>
            {escalationNotes.map((note) => (
                <CustomerEscalationsBannerNote key={note.id} note={note} />
            ))}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 24px;
`
