export const EXTERNAL_API_HMRC = 'Hmrc'
export const EXTERNAL_API_MICROSOFT = 'Microsoft'
export const EXTERNAL_API_GOOGLE = 'Google'
export const EXTERNAL_API_NYLAS = 'Nylas'

export const ACTION_NEEDED_TYPES_AGENT_AUTHORISATION = 'AgentAuthorisation'
export const ACTION_NEEDED_TYPES_SELF_ASSESSMENT = 'SelfAssessment'
export const ACTION_NEEDED_TYPES_TAX_REGISTRATION = 'TaxRegistration'
export const ACTION_NEEDED_TYPES_IDENTITY_VERIFICATION = 'IdentityVerifications'

const SPECIALISM_TYPE_GENERAL_SELF_ASSESMENT_TAX_RETURNS = 'GeneralSelfAssessmentTaxReturns'
const SPECIALISM_TYPE_GENERAL_TAX_PLANNING_AND_COMPANY_FORMATION = 'GeneralTaxPlanningAndCompanyFormation'
const SPECIALISM_TYPE_SELF_EMPLOYMENT_INCOME = 'SelfEmploymentIncome'
const SPECIALISM_TYPE_TRUSTS = 'Trusts'
const SPECIALISM_TYPE_PROPERTY_INCOME = 'PropertyIncome'
const SPECIALISM_TYPE_PARTNERSHIPS = 'Partnerships'
const SPECIALISM_TYPE_CAPITAL_GAINS_FROM_PROPERTY = 'CapitalGainsFromProperty'
const SPECIALISM_TYPE_LIMITED_COMPANY_RETURNS = 'LimitedCompanyReturns'
const SPECIALISM_TYPE_FOREIGN_INCOME = 'ForeignIncome'
const SPECIALISM_TYPE_INHERITANCE_TAX = 'InheritanceTax'
const SPECIALISM_TYPE_EMPLOYEE_SHARE_OPTIONS = 'EmployeeShareOptions'
export const SPECIALISM_TYPE_OTHER = 'Other'

export const SPECIALISM_TYPE_LIST = [
    SPECIALISM_TYPE_GENERAL_SELF_ASSESMENT_TAX_RETURNS,
    SPECIALISM_TYPE_GENERAL_TAX_PLANNING_AND_COMPANY_FORMATION,
    SPECIALISM_TYPE_SELF_EMPLOYMENT_INCOME,
    SPECIALISM_TYPE_TRUSTS,
    SPECIALISM_TYPE_PROPERTY_INCOME,
    SPECIALISM_TYPE_PARTNERSHIPS,
    SPECIALISM_TYPE_CAPITAL_GAINS_FROM_PROPERTY,
    SPECIALISM_TYPE_LIMITED_COMPANY_RETURNS,
    SPECIALISM_TYPE_FOREIGN_INCOME,
    SPECIALISM_TYPE_INHERITANCE_TAX,
    SPECIALISM_TYPE_EMPLOYEE_SHARE_OPTIONS,
    SPECIALISM_TYPE_OTHER,
]

export const SPECIALISM_TYPE_TITLES = {
    [SPECIALISM_TYPE_GENERAL_SELF_ASSESMENT_TAX_RETURNS]: 'General Self Assessment tax returns',
    [SPECIALISM_TYPE_GENERAL_TAX_PLANNING_AND_COMPANY_FORMATION]: 'General tax planning & company formation',
    [SPECIALISM_TYPE_SELF_EMPLOYMENT_INCOME]: 'Self-employment income',
    [SPECIALISM_TYPE_TRUSTS]: 'Trusts',
    [SPECIALISM_TYPE_PROPERTY_INCOME]: 'Property income',
    [SPECIALISM_TYPE_PARTNERSHIPS]: 'Partnerships',
    [SPECIALISM_TYPE_CAPITAL_GAINS_FROM_PROPERTY]: 'Capital gains from property',
    [SPECIALISM_TYPE_LIMITED_COMPANY_RETURNS]: 'Limited company returns',
    [SPECIALISM_TYPE_FOREIGN_INCOME]: 'Foreign income',
    [SPECIALISM_TYPE_INHERITANCE_TAX]: 'Inheritance tax',
    [SPECIALISM_TYPE_EMPLOYEE_SHARE_OPTIONS]: 'Employee share options',
    [SPECIALISM_TYPE_OTHER]: 'Other',
}
