import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { font, Button, Container } from '@scouts/ui'

import { requestUser } from '@/actions'
import config from '@/config'
import { EmailProvider, EMAIL_PROVIDER_GMAIL, EMAIL_PROVIDER_OUTLOOK, EXTERNAL_API_MICROSOFT } from '@/constants'
import { apiRequest } from '@/helpers/axios'
import { useSyncAccountantExternalMessages } from '@/store/external-messages'

import { useUser } from '@/hooks/use-user'

export const IntegrationsEmail = ({
    emailProvider,
    externalApi,
    isActive,
}: {
    emailProvider: EmailProvider
    externalApi: string
    isActive: boolean
}) => {
    const dispatch = useDispatch()
    const user = useUser()
    const { syncAccountantExternalMessages } = useSyncAccountantExternalMessages()

    const grantAccess = () => {
        apiRequest(
            `externalmessages/authorisationuri?redirectUri=${
                config.callbackUriExternalEmail || null
            }&emailProvider=${emailProvider}`
        )
            .then((response: { data: string }) => {
                window.open(response.data, '_blank')
            })
            .catch(() => {})
    }

    const revokeAccess = () => {
        const { id: accountantId } = user

        apiRequest(`externalmessages/accesstoken?accountantId=${accountantId}&externalApi=${externalApi}`, 'delete')
            .then(() => {
                dispatch(requestUser())
            })
            .catch(() => {})
    }

    const syncMessages = () => {
        syncAccountantExternalMessages({ emailProvider, redirectUri: config.callbackUriExternalEmail })
    }

    const notionLink = () => {
        switch (emailProvider) {
            case EMAIL_PROVIDER_GMAIL:
                return 'https://www.notion.so/taxscouts/Gmail-Integration-ed33701b3b5a4413ae82f99ae93fc8be'
            case EMAIL_PROVIDER_OUTLOOK:
                return 'https://www.notion.so/taxscouts/Outlook-Integration-eae47e29e398491e9e59d26c64687610'
            default:
                return ''
        }
    }

    return (
        <Container>
            <Heading>{emailProvider} email</Heading>

            {!isActive && <Button onClick={grantAccess}>Grant access</Button>}

            {isActive && (
                <>
                    {externalApi === EXTERNAL_API_MICROSOFT && (
                        <Action>
                            <ActionTitle>Sync all messages between you and your clients</ActionTitle>
                            <Button onClick={syncMessages}>Sync messages</Button>
                        </Action>
                    )}

                    <Action>
                        <ActionTitle>Remove the connection to your email provider</ActionTitle>
                        <Button onClick={revokeAccess}>Revoke access</Button>
                    </Action>
                </>
            )}

            <Body>
                View our{' '}
                <a href={notionLink()} target="_blank" rel="noreferrer noopener">
                    {emailProvider} guide
                </a>{' '}
                or catch us in Slack for any additional questions
            </Body>
        </Container>
    )
}

const Heading = styled.div`
    font-size: ${font.normal};
    margin-bottom: 18px;
`

const Body = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    margin-top: 18px;
`

const Action = styled.div`
    &:not(:last-child) {
        margin-bottom: 18px;
    }
`

const ActionTitle = styled.div`
    margin-bottom: 12px;
`
