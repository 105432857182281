import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useDeleteTaxConsultationMutation, DeleteTaxConsultationParams } from '../api'

export const useDeleteTaxConsultation = () => {
    const [action, { isLoading }] = useDeleteTaxConsultationMutation()

    const deleteTaxConsultation = useCallback(
        (params: DeleteTaxConsultationParams) =>
            action(params).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Tax consultation deleted successfully' })
                })
            ),
        [action]
    )

    return {
        deleteTaxConsultation,
        isLoading,
    }
}
