import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import {
    receiveReward,
    receiveRewards,
    receiveRewardPaymentError,
    receiveSuccessAlerts,
    MARK_REWARD_AS_IGNORED,
    MARK_REWARD_AS_PAID,
    REQUEST_REWARDS,
    TRIGGER_REWARD_PAYMENT,
} from '../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE } from '../constants'
import { handleError } from '../handlers'
import { apiCall, getPaginationHeaders } from '../helpers'

export default function* rewardsSagas() {
    yield takeLatest(REQUEST_REWARDS, fetchRewards)
    yield takeLatest(MARK_REWARD_AS_PAID, markRewardAsPaid)
    yield takeLatest(MARK_REWARD_AS_IGNORED, markRewardAsIgnored)
    yield takeLatest(TRIGGER_REWARD_PAYMENT, triggerRewardPayment)
}

function* fetchRewards({ data }) {
    const filter = _get(data, ['filter'], {})
    const page = _get(data, ['page'], PAGINATION_DEFAULT_PAGE)
    const pageSize = _get(data, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE)

    const url = 'rewards'
    const body = { ...filter, page, pageSize }

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'post', body)
        const pagination = getPaginationHeaders(response.headers)
        yield put(receiveRewards(response.data, { pagination }))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* markRewardAsPaid({ data }) {
    const { id } = data

    try {
        yield put(showLoading())
        const response = yield apiCall(`rewards/${id}/payment`, 'put')
        yield put(receiveReward(response.data))
        yield put(receiveSuccessAlerts('Reward marked as paid'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* markRewardAsIgnored({ data }) {
    const { id, ignored } = data

    try {
        yield put(showLoading())
        const response = yield apiCall(`rewards/${id}/ignore/${ignored}`, 'put')
        yield put(receiveReward(response.data))
        yield put(receiveSuccessAlerts('Reward marked as ignored'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* triggerRewardPayment({ data }) {
    const { id } = data

    try {
        const response = yield apiCall(`rewards/${id}/processpayment`, 'put')
        yield put(receiveReward(response.data))
        yield put(receiveSuccessAlerts(`Reward payment ${response.data.paymentStatus.toLowerCase()}`))
    } catch (e) {
        yield put(handleError(e))
        yield put(receiveRewardPaymentError())
    } finally {
        yield put(hideLoading())
    }
}
