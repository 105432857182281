import _get from 'lodash/get'
import queryString from 'query-string'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { delay } from 'redux-saga'
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    receiveNotes,
    receiveNotesNotifications,
    receiveSuccessAlerts,
    removeNote,
    saveNote as saveNoteAction,
    CREATE_NOTE,
    DELETE_NOTE,
    REQUEST_CUSTOMER_NOTES,
    REQUEST_ESCALATION_NOTES,
    REQUEST_NOTES_NOTIFICATIONS,
    RESOLVE_NOTE_NOTIFICATION,
    SAVE_NOTE,
} from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'
import { getNotesNotificationById } from '../selectors'

export default function* notesSagas() {
    yield takeEvery(CREATE_NOTE, createNote)
    yield takeEvery(DELETE_NOTE, deleteNote)
    yield takeEvery(RESOLVE_NOTE_NOTIFICATION, resolveNoteNotification)
    yield takeEvery(SAVE_NOTE, saveNote)
    yield takeLatest(REQUEST_CUSTOMER_NOTES, fetchCustomerNotes)
    yield takeLatest(REQUEST_NOTES_NOTIFICATIONS, fetchNotesNotifications)
    yield takeLatest(REQUEST_ESCALATION_NOTES, fetchEscalationNotes)
}

function* fetchCustomerNotes(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${action.data.customerId}/notes`)
        yield put(receiveNotes(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchEscalationNotes(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`escalations/${action.data.escalationId}/notes`)
        yield put(receiveNotes(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* createNote(action) {
    const meta = _get(action, 'meta', {})
    const { onSuccess } = meta

    try {
        yield put(showLoading())
        const response = yield apiCall('notes', 'post', action.data)
        yield put(receiveNotes([response.data]))
        yield put(receiveSuccessAlerts('New note added'))
        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveNote(action) {
    const meta = _get(action, 'meta', {})
    const { customSuccessMessage, onSuccess, onError } = meta

    try {
        yield put(showLoading())
        const response = yield apiCall(`notes/${action.data.id}`, 'put', action.data)
        yield put(receiveNotes([response.data]))
        yield put(receiveSuccessAlerts(customSuccessMessage || 'Note updated'))
        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))
        if (onError) onError()
    } finally {
        yield put(hideLoading())
    }
}

function* deleteNote(action) {
    const meta = _get(action, 'meta', {})
    const { onSuccess } = meta
    try {
        yield put(showLoading())
        yield apiCall(`notes/${action.data.noteId}`, 'delete')
        yield put(removeNote({ noteId: action.data.noteId }))
        yield put(receiveSuccessAlerts('Note deleted'))
        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* resolveNoteNotification(action) {
    const { noteId } = action.data
    const { onError, onSuccess } = action.meta

    try {
        const note = yield select(getNotesNotificationById, { noteId })

        if (note) {
            const newResolvedState = !note.isResolved
            const response = yield put(
                saveNoteAction(
                    { ...note, isResolved: newResolvedState },
                    { customSuccessMessage: newResolvedState ? 'Note resolved' : 'Note unresolved' }
                )
            )
            yield put(receiveNotesNotifications([response.data]))
            if (onSuccess) onSuccess()

            yield delay(500)
        }
    } catch (e) {
        if (onError) onError()
        yield put(handleError(e))
    }
}

function* fetchNotesNotifications(action) {
    const accountantId = _get(action, ['data', 'accountantId'])

    try {
        yield put(showLoading())
        const response = yield apiCall(`notes?${queryString.stringify({ accountantId })}`)
        yield put(receiveNotesNotifications(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
