import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { matchSuccessResponse, useToggle } from '@scouts/helpers'
import {
    colors,
    Button,
    ButtonLink,
    ConfirmationModal,
    Flexer,
    Form,
    InputOnChangeParams,
    Loadable,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { PATH_TEMPLATES } from '@/constants'
import { MessageTemplateDTO, MessageTemplateType } from '@/models/message-template'
import { useDeleteMessageTemplate, useUpdateMessageTemplate } from '@/store/message-templates'

import { useUser } from '@/hooks/use-user'

import { MessageTemplateForm } from './MessageTemplateForm'

export const MessageTemplateEditModal = ({ template }: { template: MessageTemplateDTO }) => {
    const user = useUser()
    const history = useHistory()
    const { isLoading, updateMessageTemplate } = useUpdateMessageTemplate()
    const { isLoading: isDeleteLoading, deleteMessageTemplate } = useDeleteMessageTemplate()
    const [state, setState] = useState({
        templateName: template?.name || '',
        templateContent: template?.content || '',
        templateType: template?.type || MessageTemplateType.Private,
        hasUnsavedChanges: false,
    })
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useToggle(false)

    const handleCancel = () => {
        const { hasUnsavedChanges } = state

        if (hasUnsavedChanges) {
            const hasConfirmed = window.confirm('You have unsaved changes. Are you sure you want to go back?')
            if (!hasConfirmed) return
        }

        goToTemplatesView()
    }

    const handleChange = ({ name, value }: InputOnChangeParams) => {
        setState((current) => ({ ...current, [name]: value, hasUnsavedChanges: true }))
    }

    const handleSubmit = () => {
        const { templateName, templateContent, templateType } = state

        const editedTemplate = {
            id: template.id,
            name: templateName,
            content: templateContent,
            type: templateType,
            accountantId: user.id,
        }

        updateMessageTemplate(editedTemplate).then(matchSuccessResponse(goToTemplatesView))
    }

    const handleDelete = () => {
        deleteMessageTemplate(template).then(matchSuccessResponse(goToTemplatesView))
    }

    const goToTemplatesView = () => {
        history.replace({ pathname: PATH_TEMPLATES, search: `type=${state.templateType}` })
    }

    const { templateName, templateContent, templateType } = state

    return (
        <>
            <Modal isWide onClose={handleCancel}>
                <Form onSubmit={handleSubmit}>
                    <ModalHeader>
                        <ModalTitle>Edit template</ModalTitle>
                    </ModalHeader>

                    <ModalBody>
                        <Loadable isLoading={isLoading}>
                            <MessageTemplateForm
                                handleChange={handleChange}
                                templateContent={templateContent}
                                templateName={templateName}
                                templateType={templateType}
                            />
                        </Loadable>
                    </ModalBody>

                    <ModalFooter>
                        <Flexer spaceBetween alignCenter>
                            <ButtonLink color={colors.red} onClick={openDeleteModal}>
                                Delete template
                            </ButtonLink>
                            <ModalActions>
                                <Button isSecondary onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button type="submit" disabled={isLoading}>
                                    Save
                                </Button>
                            </ModalActions>
                        </Flexer>
                    </ModalFooter>
                </Form>
            </Modal>

            {isDeleteModalOpen && (
                <ConfirmationModal onCancel={closeDeleteModal} onConfirm={handleDelete} isLoading={isDeleteLoading}>
                    Please confirm that you wish to delete message template "{templateName}"
                </ConfirmationModal>
            )}
        </>
    )
}
