import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    TextArea,
} from '@scouts/ui'

import { createNote } from '../../actions'

class UnsubscribeNoteModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            noteText: '',
        }

        _bindAll(this, ['handleChange', 'handleSubmit'])
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value })
    }

    handleSubmit() {
        const { dispatch, customer, closeModal } = this.props
        const { noteText } = this.state

        if (noteText && noteText.length > 0) {
            dispatch(createNote({ noteText, customerId: customer.id }))
            closeModal()
        }
    }

    render() {
        const { closeModal, customer } = this.props
        const { noteText } = this.state

        return (
            <Modal onClose={closeModal}>
                <Form onSubmit={this.handleSubmit}>
                    <ModalHeader>
                        <ModalTitle>Successfully unsubscribed {customer.fullName} from future bulk emails</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Form.Row.Title>Optionally you can add a note for future reference</Form.Row.Title>
                            <Form.Row.Content>
                                <TextArea
                                    name="noteText"
                                    value={noteText}
                                    onChange={this.handleChange}
                                    height="96px"
                                    required
                                />
                            </Form.Row.Content>
                        </Form.Row>
                    </ModalBody>
                    <ModalFooter>
                        <ModalActions>
                            <Button isSecondary onClick={closeModal}>
                                No, thanks
                            </Button>
                            <Button type="submit">Add note</Button>
                        </ModalActions>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

UnsubscribeNoteModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
}

export default connect()(UnsubscribeNoteModal)
