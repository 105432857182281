import { useState } from 'react'

import { Locale } from '@scouts/helpers'
import { TypeSelect, TypeSelectProps } from '@scouts/ui'

import { TaxReliefClaimDTO } from '@/models'
import { useSupportUsers } from '@/store/support-users/hooks'
import { useUpdateTaxReliefClaim } from '@/store/tax-relief-claims'

export const TaxReliefClaimSupportUserSelect = ({ taxReliefClaim }: { taxReliefClaim: TaxReliefClaimDTO }) => {
    const { activeSupportUsersWithFullName: supportUsersWithFullName } = useSupportUsers()
    const { updateTaxReliefClaim } = useUpdateTaxReliefClaim()

    const initialTaxReliefClaimSupportUserId = taxReliefClaim.supportUserId || null

    const [taxReliefClaimSupportUserId, setTaxReliefClaimSupportUserId] = useState(initialTaxReliefClaimSupportUserId)

    const handleSelect: TypeSelectProps['onChange'] = ({ value }) => {
        if (value === initialTaxReliefClaimSupportUserId) return

        const updatedValue = value ? Number(value) : null

        setTaxReliefClaimSupportUserId(updatedValue)

        updateTaxReliefClaim({
            taxReliefClaimId: taxReliefClaim.id,
            data: {
                supportUserId: updatedValue,
                status: taxReliefClaim.status,
            },
        })
    }

    if (!supportUsersWithFullName || supportUsersWithFullName.length === 0)
        return <>{taxReliefClaim.supportUserFullName}</>

    return (
        <TypeSelect
            name="taxReliefClaimSupportUserId"
            value={Number(taxReliefClaimSupportUserId) || ''}
            options={[{ title: 'Choose…', value: '' }, ...supportUsersWithFullName]}
            onChange={handleSelect}
            locale={Locale.GB}
            autoClear
            isRequired
            showOptions
        />
    )
}
