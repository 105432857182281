import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import styled from 'styled-components'

import { colors, media, IconPlus } from '@scouts/ui'

import CustomerNotesCreate from './customer/CustomerNotesCreate'
import CustomerNotesListModal from './customer/CustomerNotesListModal'
import CustomerNotesListPopup from './customer/CustomerNotesListPopup'
import NotePreviews from './NotePreviews'

const Container = styled.div`
    flex: 1;
    position: relative;
    min-width: 0;
`

const Wrapper = styled.div`
    color: ${colors.black};
    flex: 1;
    position: relative;
    min-width: 0;
`

const IconCreateNote = styled.div`
    float: right;
    box-sizing: content-box;
    width: 24px;
    height: 24px;
    border: 2px solid ${colors.white};
    border-radius: 9999px;
    cursor: pointer;

    circle {
        fill: ${colors.neutralLightest};
    }

    ${media.pointer} {
        &:hover {
            circle {
                fill: ${colors.blue};
            }
        }
    }
`

class NotesIndicator extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isPopupOpen: false,
            isModalOpen: false,
            shouldTriggerTableUpdate: false,
        }

        _bindAll(this, [
            'handleClosePopup',
            'handleOpenPopup',
            'handleNotesUpdate',
            'handleOpenModal',
            'handleCloseModal',
        ])
    }

    handleNotesUpdate() {
        this.setState({ shouldTriggerTableUpdate: true })
    }

    handleOpenModal() {
        this.setState({ isModalOpen: true, isPopupOpen: false })
    }

    handleCloseModal() {
        const { triggerTableUpdate } = this.props
        const { shouldTriggerTableUpdate } = this.state
        if (shouldTriggerTableUpdate) {
            triggerTableUpdate()
        }

        this.setState({ isModalOpen: false, shouldTriggerTableUpdate: false })
    }

    handleOpenPopup() {
        this.setState({ isPopupOpen: true })
    }

    handleClosePopup() {
        const { triggerTableUpdate } = this.props
        const { shouldTriggerTableUpdate } = this.state
        if (shouldTriggerTableUpdate) {
            triggerTableUpdate()
        }

        this.setState({ isPopupOpen: false, shouldTriggerTableUpdate: false })
    }

    render() {
        const { isPopupOpen, isModalOpen } = this.state
        const { customerId, customerFullName, numberOfNotes, notePreviews, triggerTableUpdate } = this.props

        const shouldShowNewNoteIcon = !numberOfNotes

        return (
            <Container>
                <Wrapper>
                    {shouldShowNewNoteIcon ? (
                        <CustomerNotesCreate customerId={customerId} onNoteCreate={triggerTableUpdate}>
                            <IconCreateNote>
                                <IconPlus />
                            </IconCreateNote>
                        </CustomerNotesCreate>
                    ) : (
                        <NotePreviews
                            items={notePreviews}
                            numberOfNotes={numberOfNotes}
                            onOpenNoteListPopup={this.handleOpenPopup}
                        />
                    )}
                </Wrapper>

                {isPopupOpen && (
                    <CustomerNotesListPopup
                        customerId={customerId}
                        onNotesUpdate={this.handleNotesUpdate}
                        onClosePopup={this.handleClosePopup}
                        onOpenModal={this.handleOpenModal}
                    />
                )}

                {isModalOpen && (
                    <CustomerNotesListModal
                        customerId={customerId}
                        customerFullName={customerFullName}
                        onNotesUpdate={this.handleNotesUpdate}
                        onCloseModal={this.handleCloseModal}
                    />
                )}
            </Container>
        )
    }
}

NotesIndicator.defaultProps = {
    notePreviews: [],
    triggerTableUpdate: () => {},
}

NotesIndicator.propTypes = {
    customerId: PropTypes.number.isRequired,
    customerFullName: PropTypes.string.isRequired,
    notePreviews: PropTypes.array,
    numberOfNotes: PropTypes.number.isRequired,
    triggerTableUpdate: PropTypes.func,
}

export default NotesIndicator
