import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Table } from '@scouts/ui'

import SelfAssessmentsTableRow from './SelfAssessmentsTableRow'

const Container = styled.div`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const SelfAssessmentsTable = (props) => {
    const {
        selfAssessments,
        selectAll,
        deselectAll,
        selectedItems,
        isFetching,
        handleCustomerSelection,
        triggerTableUpdate,
    } = props

    const hasSelectedItems = selectedItems.length > 0

    return (
        <Container isFetching={isFetching}>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell width="25%">Client</Table.Header.Cell>
                        <Table.Header.Cell width="18%" />
                        <Table.Header.Cell width="15%">Tax year</Table.Header.Cell>
                        <Table.Header.Cell width="42px" />
                        <Table.Header.Cell width="calc(12% - 42px)">Updated</Table.Header.Cell>
                        <Table.Header.Cell width="5%">Files</Table.Header.Cell>
                        <Table.Header.Cell width="calc(25% - 96px)" alignRight>
                            Notes
                        </Table.Header.Cell>
                        <Table.Header.Cell width="96px" alignRight action={hasSelectedItems ? deselectAll : selectAll}>
                            {hasSelectedItems ? 'Deselect all' : 'Select all'}
                        </Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>

                <Table.Body>
                    {selfAssessments.map((selfAssessment) => {
                        const { customerId, id } = selfAssessment
                        const isSelected =
                            selectedItems.findIndex((o) => o.customerId === customerId && o.selfAssessmentId === id) >
                            -1
                        return (
                            <SelfAssessmentsTableRow
                                key={selfAssessment.id}
                                handleSelect={handleCustomerSelection}
                                isSelected={isSelected}
                                selfAssessment={selfAssessment}
                                triggerTableUpdate={triggerTableUpdate}
                            />
                        )
                    })}
                </Table.Body>
            </Table>
        </Container>
    )
}

SelfAssessmentsTable.propTypes = {
    selfAssessments: PropTypes.array.isRequired,
    deselectAll: PropTypes.func.isRequired,
    handleCustomerSelection: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    selectAll: PropTypes.func.isRequired,
    selectedItems: PropTypes.array.isRequired,
    triggerTableUpdate: PropTypes.func.isRequired,
}

export default connect()(SelfAssessmentsTable)
