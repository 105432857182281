import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useSyncAccountantExternalMessagesMutation } from '../api'

export const useSyncAccountantExternalMessages = () => {
    const [action, { isLoading }] = useSyncAccountantExternalMessagesMutation()

    const syncAccountantExternalMessages = (params: { redirectUri: string; emailProvider: string }) => {
        action(params).then(
            matchSuccessResponse((numberOfNewMessages) => {
                addSuccessToast({
                    body: `Found ${numberOfNewMessages} new email ${
                        numberOfNewMessages === 1 ? 'message' : 'messages'
                    }`,
                })
            })
        )
    }

    return { syncAccountantExternalMessages, isLoading }
}
