import { Tabs } from '@scouts/ui'

import {
    TaxRegistrationStatusFilter,
    TaxRegistrationStatusFiltersTitles,
    TaxRegistrationStatusFiltersTypes,
} from '@/models'
import { useTaxRegistrationsStatistics } from '@/store/tax-registrations'

import { useFilters } from './hooks/use-filters'

import { usePagination } from '../pagination'

export const TaxRegistrationsFilters = () => {
    const { activeFilters: paginationFilters } = usePagination()
    const { activeFilters, applyFilter } = useFilters()

    const { taxRegistrationsStatistics } = useTaxRegistrationsStatistics({
        options: { ...activeFilters, ...paginationFilters },
    })

    const { status: filterStatus } = activeFilters

    const countPerFilter = (status: TaxRegistrationStatusFiltersTypes) => {
        if (status !== TaxRegistrationStatusFilter.All)
            return taxRegistrationsStatistics?.find((item) => item.status === status)?.count || 0

        let allCount = 0

        taxRegistrationsStatistics?.forEach((item) => (allCount += item.count))

        return allCount
    }

    const updateFilter = (status: TaxRegistrationStatusFiltersTypes) => {
        applyFilter({ updatedFilter: { status, page: 1 } })
    }

    return (
        <Tabs>
            <Tabs.Item
                onClick={() => updateFilter(TaxRegistrationStatusFilter.All)}
                isActive={filterStatus === TaxRegistrationStatusFilter.All}
            >
                {TaxRegistrationStatusFiltersTitles[TaxRegistrationStatusFilter.All]}
                <Tabs.Item.Counter>{countPerFilter(TaxRegistrationStatusFilter.All)}</Tabs.Item.Counter>
            </Tabs.Item>

            <Tabs.Item
                onClick={() => updateFilter(TaxRegistrationStatusFilter.Started)}
                isActive={filterStatus === TaxRegistrationStatusFilter.Started}
            >
                {TaxRegistrationStatusFiltersTitles[TaxRegistrationStatusFilter.Started]}
                <Tabs.Item.Counter>{countPerFilter(TaxRegistrationStatusFilter.Started)}</Tabs.Item.Counter>
            </Tabs.Item>

            <Tabs.Item
                onClick={() => updateFilter(TaxRegistrationStatusFilter.Submitted)}
                isActive={filterStatus === TaxRegistrationStatusFilter.Submitted}
            >
                {TaxRegistrationStatusFiltersTitles[TaxRegistrationStatusFilter.Submitted]}
                <Tabs.Item.Counter>{countPerFilter(TaxRegistrationStatusFilter.Submitted)}</Tabs.Item.Counter>
            </Tabs.Item>

            <Tabs.Item
                onClick={() => updateFilter(TaxRegistrationStatusFilter.InformationRequested)}
                isActive={filterStatus === TaxRegistrationStatusFilter.InformationRequested}
            >
                {TaxRegistrationStatusFiltersTitles[TaxRegistrationStatusFilter.InformationRequested]}
                <Tabs.Item.Counter>
                    {countPerFilter(TaxRegistrationStatusFilter.InformationRequested)}
                </Tabs.Item.Counter>
            </Tabs.Item>

            <Tabs.Item
                onClick={() => updateFilter(TaxRegistrationStatusFilter.Complete)}
                isActive={filterStatus === TaxRegistrationStatusFilter.Complete}
            >
                {TaxRegistrationStatusFiltersTitles[TaxRegistrationStatusFilter.Complete]}
                <Tabs.Item.Counter>{countPerFilter(TaxRegistrationStatusFilter.Complete)}</Tabs.Item.Counter>
            </Tabs.Item>

            <Tabs.Item
                onClick={() => updateFilter(TaxRegistrationStatusFilter.Cancelled)}
                isActive={filterStatus === TaxRegistrationStatusFilter.Cancelled}
            >
                {TaxRegistrationStatusFiltersTitles[TaxRegistrationStatusFilter.Cancelled]}
                <Tabs.Item.Counter>{countPerFilter(TaxRegistrationStatusFilter.Cancelled)}</Tabs.Item.Counter>
            </Tabs.Item>
        </Tabs>
    )
}
