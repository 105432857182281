import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, font, media, Label } from '@scouts/ui'

import { formatDateWithTime } from '@/helpers'

export const ConversationCard = ({ conversation, navigateToConversation }) => (
    <ListItem key={conversation.id} onClick={() => navigateToConversation(conversation.id)}>
        <ListItemMeta>
            <StatusContainer>
                <Label background={conversation.status === 'open' ? colors.mintLighter : colors.neutralLightest}>
                    <StatusText>{conversation.status}</StatusText>
                </Label>
            </StatusContainer>
            <Count>
                {conversation.count} {conversation.count === 1 ? 'message' : 'messages'}
            </Count>
        </ListItemMeta>
        <ListItemBody>{conversation.body}</ListItemBody>
        <ListItemDate>
            <span title={conversation.lastMessageDateUTC}>{formatDateWithTime(conversation.lastMessageDateUTC)}</span>
        </ListItemDate>
    </ListItem>
)

ConversationCard.propTypes = {
    conversation: PropTypes.object.isRequired,
    navigateToConversation: PropTypes.func.isRequired,
}

const ListItemBody = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 12px 0;
`

const ListItem = styled.div`
    padding: 12px 12px;
    background: ${colors.white};
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 1px;
    }

    ${media.pointer} {
        &:hover {
            ${ListItemBody} {
                color: ${colors.blue};
            }
        }
    }
`

const ListItemMeta = styled.div`
    font-size: ${font.smaller};
    display: flex;
    align-items: center;
`

const ListItemDate = styled.div`
    font-size: ${font.smaller};
    display: flex;
    justify-content: flex-end;
`

const StatusContainer = styled.span`
    margin-right: 12px;
`

const Count = styled.span`
    flex: 1 1 auto;
`

const StatusText = styled.span`
    text-transform: capitalize;
`
