import { createSelector } from '@reduxjs/toolkit'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'

/** @param {{customerId: number}} props */
const pluckCustomerId = (_state, props) => props.customerId
/** @param {{supportUserId: number}} props */
const pluckSupportUserId = (_state, props) => props.supportUserId
/** @param {{escalationId: number}} props */
const pluckEscalationId = (_state, props) => props.escalationId

export const getEscalations = (state) => state.escalations
export const getEscalationsList = (state) => getEscalations(state).list
const getEscalationsDetails = (state) => getEscalations(state).details

export const isFetchingCustomerEscalations = (state) => !!state.escalations.isFetchingCustomerEscalations

export const getCustomerEscalations = createSelector(
    [getEscalationsDetails, pluckCustomerId],
    (escalationsDetails, customerId) => {
        const customerEscalations = _filter(escalationsDetails, { customerId })
        return _orderBy(customerEscalations, ['id'], ['desc'])
    }
)

export const getEscalationsBySupportUserId = createSelector(
    [getEscalationsList, pluckSupportUserId],
    (escalationsList, supportUserId) => {
        const escalations = _filter(escalationsList, { supportUserId })
        return _orderBy(escalations, ['id'], ['desc'])
    }
)

export const getEscalationById = createSelector(
    [getEscalationsDetails, pluckEscalationId],
    (escalationDetails, escalationId) => _find(escalationDetails, ['id', Number(escalationId)])
)
