import { ReactNode } from 'react'
import styled from 'styled-components'

import { colors, font, media, shadows, ButtonLink, Notice, NoticeAction, NoticeProps } from '@scouts/ui'

export type GlobalNoticeProps = {
    background?: NoticeProps['background']
    buttonText?: string
    children: ReactNode
    closeText?: string
    onButtonClick: () => void
    onClose?: () => void
}

export const GlobalNotice = (props: GlobalNoticeProps) => {
    const { background, onClose, onButtonClick, children, closeText = 'Close', buttonText = 'Click here' } = props
    return (
        <NoticeWrapper>
            <Notice background={background}>
                {onClose && (
                    <CloseButton onClick={onClose}>
                        <span>{closeText}&nbsp;</span>&times;
                    </CloseButton>
                )}
                {children}
                <NoticeAction>
                    <ButtonLink onClick={onButtonClick}>{buttonText}</ButtonLink>
                </NoticeAction>
            </Notice>
        </NoticeWrapper>
    )
}

const NoticeWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    box-shadow: ${shadows.medium};

    ${media.tablet} {
        bottom: 12px;
        left: 50%;
        width: 420px;
        transform: translateX(-50%);
    }
`

const CloseButton = styled.button`
    color: ${colors.blue};
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 18px;

    ${media.tablet} {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        padding: 6px;
    }

    ${media.tablet} {
        span {
            display: none;
        }
    }

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }
`
