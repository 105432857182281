import { useUpdateAccountantReviewMutation } from '../api'

export const useUpdateAccountantReview = () => {
    const [updateAccountantReview, { isLoading }] = useUpdateAccountantReviewMutation()

    return {
        updateAccountantReview,
        isLoading,
    }
}
