import { useMemo } from 'react'

import { MONTH_NAMES_LONG } from '@/constants'

export const useReportCardDates = () => {
    const date = new Date()

    const currentMonth = date.getMonth() + 1
    const currentMonthYear = date.getFullYear()

    date.setMonth(date.getMonth() - 1)

    const previousMonth = date.getMonth() + 1
    const previousMonthYear = date.getFullYear()

    date.setMonth(date.getMonth() - 1)

    const prevPrevMonth = date.getMonth() + 1
    const prevPrevMonthYear = date.getFullYear()

    const prevPrevMonthName = MONTH_NAMES_LONG[date.getMonth()]

    return useMemo(
        () => ({
            prevPrevMonth: {
                month: prevPrevMonth,
                year: prevPrevMonthYear,
                title: `Download ${prevPrevMonthName}'s report`,
            },
            previousMonth: {
                month: previousMonth,
                year: previousMonthYear,
                title: "Download last month's report",
            },
            currentMonth: {
                month: currentMonth,
                year: currentMonthYear,
                title: "Download this month's report",
            },
            allTimeMonth: {
                month: '',
                year: '',
                title: 'Download all time report',
            },
        }),
        [
            currentMonth,
            currentMonthYear,
            prevPrevMonth,
            prevPrevMonthName,
            prevPrevMonthYear,
            previousMonth,
            previousMonthYear,
        ]
    )
}
