import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { Page } from '@scouts/ui'

import { requestUser, saveUser } from '../../actions'
import ProfilePageWithSidebar from '../ProfilePageWithSidebar'
import MyIntegrations from './MyIntegrations'

class MyIntegrationsContainer extends Component {
    constructor() {
        super()

        _bindAll(this, ['save'])
    }

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(requestUser())
    }

    save(data) {
        const { dispatch } = this.props

        dispatch(saveUser(data))
    }

    render() {
        const { user } = this.props

        if (!user) return null

        return (
            <ProfilePageWithSidebar>
                <Page.Heading>My integrations</Page.Heading>

                <Page.Section>
                    <MyIntegrations user={user} handleSave={this.save} />
                </Page.Section>
            </ProfilePageWithSidebar>
        )
    }
}

MyIntegrationsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(MyIntegrationsContainer)
