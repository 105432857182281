import { useToggle } from '@scouts/helpers'
import { ButtonLink, Table } from '@scouts/ui'

import { TaxConsultationDomain } from '@/domain'
import { CustomerDTO } from '@/models'
import { useCustomersTaxConsultations } from '@/store/tax-consultations'

import { useUser } from '@/hooks/use-user'

import { CreateTaxConsultation } from './CreateTaxConsultation'
import { CustomerOverviewTaxConsultation } from './CustomerOverviewTaxConsultation'

interface CustomerOverviewTaxConsultationsProps {
    customerId: CustomerDTO['id']
}

export const CustomerOverviewTaxConsultations = ({ customerId }: CustomerOverviewTaxConsultationsProps) => {
    const { isAdminUser } = useUser()

    const [shouldDisplayAll, , , toggleDisplayAll] = useToggle()

    const { taxConsultations: customerTaxConsultations, hasTaxConsultations } = useCustomersTaxConsultations({
        customerId,
    })

    const hasUnbookedTaxConsultations = TaxConsultationDomain.getHasUnbookedTaxConsultations(customerTaxConsultations)

    const shouldDisplayCreateButton = isAdminUser && (!hasTaxConsultations || !hasUnbookedTaxConsultations)
    const shouldLimitItems = hasTaxConsultations && customerTaxConsultations.length > 3

    const renderableTaxConsultations =
        shouldLimitItems && !shouldDisplayAll ? customerTaxConsultations.slice(0, 1) : customerTaxConsultations

    if (!isAdminUser && !hasTaxConsultations) return null

    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="75%">
                        {hasTaxConsultations
                            ? 'Tax consultations'
                            : "This client hasn't started any tax consultations yet"}
                    </Table.Header.Cell>
                    <Table.Header.Cell width="25%" alignRight>
                        {shouldDisplayCreateButton && <CreateTaxConsultation customerId={customerId} />}
                    </Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>

            {hasTaxConsultations && (
                <Table.Body>
                    {renderableTaxConsultations.map((taxConsultation) => (
                        <CustomerOverviewTaxConsultation key={taxConsultation.id} taxConsultation={taxConsultation} />
                    ))}
                </Table.Body>
            )}

            {shouldLimitItems && (
                <Table.Footer>
                    <Table.Footer.Row>
                        <Table.Footer.Cell width="100%" alignRight>
                            <ButtonLink onClick={toggleDisplayAll}>
                                {shouldDisplayAll ? 'View less' : `View more (${customerTaxConsultations.length})`}
                            </ButtonLink>
                        </Table.Footer.Cell>
                    </Table.Footer.Row>
                </Table.Footer>
            )}
        </Table>
    )
}
