import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import {
    colors,
    font,
    Button,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import {
    EMAIL_PROVIDER_GMAIL,
    EMAIL_PROVIDER_OUTLOOK,
    EXTERNAL_API_GOOGLE,
    EXTERNAL_API_MICROSOFT,
    PATH_INTEGRATIONS_NYLAS,
    PATH_INTEGRATIONS_PAYE,
} from '@/constants'
import { getUser } from '@/selectors/user'

import { IntegrationsEmail } from './integrations/IntegrationsEmail'

const Section = styled.div`
    &:not(:last-child) {
        margin-bottom: 36px;
    }
`

const SectionTitle = styled.div`
    font-size: ${font.large};
    line-height: 1.5;
    margin-bottom: 18px;
`

const SectionBody = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    margin-bottom: 24px;
`

const Connection = styled.div`
    margin-bottom: 2px;
    padding: 24px;
    background: ${({ background }) => background || colors.neutralLightest};
`

const ConnectionTitle = styled.div`
    font-size: ${font.normal};
    margin-bottom: 18px;
`

const ConnectionBody = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    margin-bottom: 18px;
`

const ConnectionActions = styled.div``

class MyIntegrations extends Component {
    constructor(props) {
        super(props)

        const { user } = this.props

        const { hmrcAgentReference, hmrcUserId, hasHmrcPassword, hmrcPassword } = user

        this.state = {
            hasHmrcPassword,
            hmrcAgentReference,
            hmrcPassword,
            hmrcUserId,
            isAgentAuthDetailsModalOpen: false,
        }

        _bindAll(this, [
            'closeAgentAuthDetailsModal',
            'handleChange',
            'handleSaveAgentAuthDetails',
            'openAgentAuthDetailsModal',
        ])
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value })
    }

    handleSaveAgentAuthDetails() {
        const { handleSave } = this.props
        const { hmrcAgentReference, hmrcPassword, hmrcUserId } = this.state

        handleSave({
            hmrcAgentReference,
            hmrcPassword,
            hmrcUserId,
        })

        this.hidePassword()
        this.closeAgentAuthDetailsModal()
    }

    hidePassword() {
        this.setState({ hmrcPassword: '' })
    }

    openAgentAuthDetailsModal() {
        this.setState({ isAgentAuthDetailsModalOpen: true })
    }

    closeAgentAuthDetailsModal() {
        this.setState({ isAgentAuthDetailsModalOpen: false })
    }

    render() {
        const {
            hasGoogleConnection,
            hasHmrcPayeApiConnection,
            hasMicrosoftConnection,
            hasNylasApiConnection,
            history,
        } = this.props
        const { hasHmrcPassword, hmrcAgentReference, hmrcPassword, hmrcUserId, isAgentAuthDetailsModalOpen } =
            this.state

        const hasAgentAuthConnection = hmrcAgentReference && hmrcUserId && hasHmrcPassword

        return (
            <>
                <Section>
                    <SectionTitle>HMRC</SectionTitle>

                    <SectionBody>
                        By setting up your HMRC agent services details and PAYE integration we can automate many
                        recurring tasks and save you time.
                    </SectionBody>

                    <Connection background={hasAgentAuthConnection ? colors.mintLighter : colors.neutralLightest}>
                        <ConnectionTitle>
                            {hasAgentAuthConnection
                                ? 'HMRC agent services details'
                                : 'Set up HMRC agent services details'}
                        </ConnectionTitle>
                        <ConnectionBody>
                            Once done you can request agent authorisation directly through the TaxScouts platform. We'll
                            guide clients through the whole process of receiving and submitting their code.
                        </ConnectionBody>
                        <ConnectionActions>
                            <Button onClick={this.openAgentAuthDetailsModal}>
                                {hasAgentAuthConnection ? 'Edit' : 'Set up'}
                            </Button>
                        </ConnectionActions>
                    </Connection>

                    {isAgentAuthDetailsModalOpen && (
                        <Modal onClose={this.closeAgentAuthDetailsModal}>
                            <Form onSubmit={this.handleSaveAgentAuthDetails}>
                                <ModalHeader>
                                    <ModalTitle>Details for HMRC agent authorisation</ModalTitle>
                                </ModalHeader>
                                <ModalBody>
                                    <Form.Row>
                                        <Form.Row.Title>HMRC agent reference</Form.Row.Title>
                                        <Form.Row.Content>
                                            <Input
                                                maxLength="6"
                                                name="hmrcAgentReference"
                                                value={hmrcAgentReference || ''}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Row.Content>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Row.Title>HMRC user ID</Form.Row.Title>
                                        <Form.Row.Content>
                                            <Input
                                                maxLength="25"
                                                name="hmrcUserId"
                                                value={hmrcUserId || ''}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Row.Content>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Row.Title>
                                            {hasHmrcPassword ? 'Amend ' : 'Set '}
                                            HMRC password
                                        </Form.Row.Title>
                                        <Form.Row.Content>
                                            <Input
                                                name="hmrcPassword"
                                                value={hmrcPassword || ''}
                                                onChange={this.handleChange}
                                                type="password"
                                            />
                                        </Form.Row.Content>
                                    </Form.Row>
                                </ModalBody>
                                <ModalFooter>
                                    <ModalActions>
                                        <Button isSecondary onClick={this.closeAgentAuthDetailsModal}>
                                            Cancel
                                        </Button>
                                        <Button type="submit">Save</Button>
                                    </ModalActions>
                                </ModalFooter>
                            </Form>
                        </Modal>
                    )}

                    <Connection background={hasHmrcPayeApiConnection ? colors.mintLighter : colors.neutralLightest}>
                        <ConnectionTitle>
                            {hasHmrcPayeApiConnection ? 'HMRC PAYE integration' : 'Set up HMRC PAYE integration'}
                        </ConnectionTitle>
                        <ConnectionBody>
                            With HMRC PAYE integration we can try and get clients employment income information directly
                            from HMRC. For more information see our{' '}
                            <a
                                href="https://www.notion.so/taxscouts/HMRC-PAYE-Integration-377a0c86b246480180aeb0bc1c4502f6"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                PAYE integration guide
                            </a>
                            .
                        </ConnectionBody>
                        <ConnectionActions>
                            <Button onClick={() => history.push(PATH_INTEGRATIONS_PAYE)}>
                                {hasHmrcPayeApiConnection ? 'View details' : 'Set up'}
                            </Button>
                        </ConnectionActions>
                    </Connection>
                </Section>

                <Section>
                    <SectionTitle>Email</SectionTitle>

                    <SectionBody>
                        Lots of communication with clients happens over email. If your email provider is either Outlook
                        or Gmail we can make all client emails available directly on the TaxScouts platform so you can
                        see all the messages in one place.
                    </SectionBody>

                    <Connection background={hasMicrosoftConnection ? colors.mintLighter : colors.neutralLightest}>
                        <IntegrationsEmail
                            emailProvider={EMAIL_PROVIDER_OUTLOOK}
                            externalApi={EXTERNAL_API_MICROSOFT}
                            isActive={hasMicrosoftConnection}
                        />
                    </Connection>

                    <Connection background={hasGoogleConnection ? colors.mintLighter : colors.neutralLightest}>
                        <IntegrationsEmail
                            emailProvider={EMAIL_PROVIDER_GMAIL}
                            externalApi={EXTERNAL_API_GOOGLE}
                            isActive={hasGoogleConnection}
                        />
                    </Connection>
                </Section>

                <Section>
                    <SectionTitle>Scheduling</SectionTitle>

                    <Connection background={hasNylasApiConnection ? colors.mintLighter : colors.neutralLightest}>
                        <ConnectionTitle>Scheduling for tax consultations</ConnectionTitle>
                        <ConnectionBody>
                            With this integration, your clients can schedule tax consultations with you
                        </ConnectionBody>
                        <ConnectionActions>
                            <Button onClick={() => history.push(PATH_INTEGRATIONS_NYLAS)}>
                                {hasNylasApiConnection ? 'View details' : 'Set up'}
                            </Button>
                        </ConnectionActions>
                    </Connection>
                </Section>
            </>
        )
    }
}

MyIntegrations.propTypes = {
    handleSave: PropTypes.func.isRequired,
    hasGoogleConnection: PropTypes.bool.isRequired,
    hasHmrcPayeApiConnection: PropTypes.bool.isRequired,
    hasMicrosoftConnection: PropTypes.bool.isRequired,
    hasNylasApiConnection: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    const { hasGoogleConnection, hasHmrcPayeApiConnection, hasMicrosoftConnection, hasNylasApiConnection } =
        getUser(state)
    return { hasGoogleConnection, hasHmrcPayeApiConnection, hasMicrosoftConnection, hasNylasApiConnection }
}

export default connect(mapStateToProps)(withRouter(MyIntegrations))
