import { useLayoutEffect, useRef } from 'react'
import { useLocation, Route, Switch } from 'react-router-dom'

import { useAccountantPortalChrome, AccountantPortalChrome } from '@scouts/ui'

import {
    PATH_CUSTOMER,
    PATH_CUSTOMER_ACCOUNTANT_MEETINGS,
    PATH_CUSTOMER_BANK_DETAILS,
    PATH_CUSTOMER_BOOKKEEPING,
    PATH_CUSTOMER_COMPANY_PROFILE,
    PATH_CUSTOMER_COMPANY_RETURN,
    PATH_CUSTOMER_COMPANY_RETURN_AGREEMENT,
    PATH_CUSTOMER_CONSULTATIONS,
    PATH_CUSTOMER_CONSULTATIONS_AGREEMENT,
    PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM,
    PATH_CUSTOMER_DISCOUNTS,
    PATH_CUSTOMER_EDIT,
    PATH_CUSTOMER_FILES,
    PATH_CUSTOMER_HISTORY,
    PATH_CUSTOMER_MESSAGES,
    PATH_CUSTOMER_PARTNER,
    PATH_CUSTOMER_PREVIOUS_ACCOUNTANT_DETAILS,
    PATH_CUSTOMER_SELF_ASSESSMENTS,
    PATH_CUSTOMER_SELF_ASSESSMENTS_AGREEMENT,
    PATH_CUSTOMER_TAX_REGISTRATION_AGREEMENT,
    PATH_CUSTOMER_TAX_RELIEF_CLAIMS,
} from '@/constants'
import { scrollElementToTop } from '@/helpers/dom'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'
import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useCustomerSelfAssessments } from './hooks/use-customer-self-assessments'

import { AccountantMeetingContainer } from './accountant-meeting/AccountantMeetingContainer'
import { BankDetailsContainer } from './bank-details/BankDetailsContainer'
import { CustomerBookkeeping } from './bookkeeping'
import { CompanyProfileDetails } from './company-profile/CompanyProfileDetails'
import { CompanyReturnAgreementContainer } from './company-return/CompanyReturnAgreementContainer'
import { CompanyReturnContainer } from './company-return/CompanyReturnContainer'
import { CustomerEscalationsBanner } from './CustomerEscalationsBanner'
import { CustomerHistoryLog } from './CustomerHistoryLog'
import { CustomerNav } from './CustomerNav'
import CustomerProfileEdit from './CustomerProfileEdit'
import { CustomerDiscountsContainer } from './discounts/CustomerDiscountsContainer'
import FilesContainer from './FilesContainer'
import { ComposerButton } from './messages/composer/ComposerButton'
import { MessageComposerContainer } from './messages/composer/MessageComposerContainer'
import { CustomerMessages } from './messages/CustomerMessages'
import { CustomerOverview } from './overview/CustomerOverview'
import { PartnerDetailsContainer } from './partner/PartnerDetailsContainer'
import PreviousAccountantDetailsContainer from './PreviousAccountantDetailsContainer'
import { SelfAssessmentAgreementContainer } from './self-assessment/SelfAssessmentAgreementContainer'
import SelfAssessmentContainer from './self-assessment/SelfAssessmentContainer'
import { CustomerSidebar } from './sidebar/CustomerSidebar'
import { TaxConsultationAgreementContainer } from './tax-consultation/TaxConsultationAgreementContainer'
import { TaxConsultationContainer } from './tax-consultation/TaxConsultationContainer'
import { TaxConsultationSummaryFormContainer } from './tax-consultation/TaxConsultationSummaryFormContainer'
import { TaxRegistrationAgreementContainer } from './tax-registration/TaxRegistrationAgreementContainer'
import { TaxReliefClaimContainer } from './tax-relief-claims/TaxReliefClaimContainer'

export const Customer = () => {
    const { isCustomerSidebarVisible } = useAccountantPortalChrome()
    const contentAreaRef = useRef(null)
    const { customer } = useCustomerFromPath()
    const customerId = useCustomerIdFromPath()
    const { selfAssessments } = useCustomerSelfAssessments({ customerId })

    const { pathname } = useLocation()

    useLayoutEffect(() => {
        const scrollableElement = contentAreaRef?.current
        if (scrollableElement) scrollElementToTop(scrollableElement)
    }, [pathname])

    return (
        <AccountantPortalChrome.Customer.Container>
            <AccountantPortalChrome.Customer.Sidebar.Container tabletIsVisible={isCustomerSidebarVisible}>
                <CustomerSidebar />
            </AccountantPortalChrome.Customer.Sidebar.Container>

            <AccountantPortalChrome.Customer.ContentContainer>
                <AccountantPortalChrome.Customer.Content ref={contentAreaRef}>
                    <CustomerEscalationsBanner />

                    <CustomerNav />

                    <Switch>
                        <Route exact path={PATH_CUSTOMER}>
                            <CustomerOverview />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_EDIT}>
                            <CustomerProfileEdit customer={customer} />
                        </Route>

                        <Route path={PATH_CUSTOMER_BOOKKEEPING}>
                            <CustomerBookkeeping />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_BANK_DETAILS}>
                            <BankDetailsContainer customerId={customer.id} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_PREVIOUS_ACCOUNTANT_DETAILS}>
                            <PreviousAccountantDetailsContainer customer={customer} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_PARTNER}>
                            <PartnerDetailsContainer customerId={customer.id} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_COMPANY_PROFILE}>
                            <CompanyProfileDetails customerId={customer.id} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_TAX_REGISTRATION_AGREEMENT}>
                            <TaxRegistrationAgreementContainer />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_SELF_ASSESSMENTS}>
                            <SelfAssessmentContainer customer={customer} customerSelfAssessments={selfAssessments} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_COMPANY_RETURN}>
                            <CompanyReturnContainer customerId={customerId} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_COMPANY_RETURN_AGREEMENT}>
                            <CompanyReturnAgreementContainer />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_SELF_ASSESSMENTS_AGREEMENT}>
                            <SelfAssessmentAgreementContainer />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_CONSULTATIONS}>
                            <TaxConsultationContainer customer={customer} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_ACCOUNTANT_MEETINGS}>
                            <AccountantMeetingContainer customerId={customer.id} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_TAX_RELIEF_CLAIMS}>
                            <TaxReliefClaimContainer customerId={customer.id} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_CONSULTATIONS_AGREEMENT}>
                            <TaxConsultationAgreementContainer />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM}>
                            <TaxConsultationSummaryFormContainer />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_FILES}>
                            <FilesContainer customer={customer} customerSelfAssessments={selfAssessments} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_MESSAGES}>
                            <CustomerMessages customerId={customerId} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_HISTORY}>
                            <CustomerHistoryLog customer={customer} />
                        </Route>

                        <Route exact path={PATH_CUSTOMER_DISCOUNTS}>
                            <CustomerDiscountsContainer />
                        </Route>
                    </Switch>
                </AccountantPortalChrome.Customer.Content>

                <MessageComposerContainer customerId={customerId}>{() => <ComposerButton />}</MessageComposerContainer>
            </AccountantPortalChrome.Customer.ContentContainer>
        </AccountantPortalChrome.Customer.Container>
    )
}
