import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { hexToRgbA } from '@scouts/helpers'
import { colors, font } from '@scouts/ui'

import { formatDateWithTime } from '@/helpers'
import { getCustomerConversationsById } from '@/selectors'

import { ConversationMessageType } from './ConversationMessageType'

const messageModifierFromType = (type) => {
    switch (type) {
        case 'close':
        case 'open':
            return 'action'
        case 'note':
            return 'note'
        case 'message':
        case 'comment':
        case 'assignment':
        default:
            return null
    }
}

const getMessageBackground = ({ type, isAdminMessage }) => {
    if (type === 'note') return colors.redLighter
    if (isAdminMessage) return hexToRgbA(colors.white, 0.35)
    return colors.white
}

export const ConversationMessages = ({ conversationId }) => {
    const conversation = useSelector((state) => getCustomerConversationsById(state, { conversationId }))

    return (
        <>
            {conversation.messages.map((message) => (
                <Message
                    key={message.id}
                    isAdminMessage={message.author === 'admin' || message.author === 'bot'}
                    type={messageModifierFromType(message.type)}
                >
                    <Body>
                        <ConversationMessageType type={message.type} />
                        {message.subject && (
                            <>
                                <span>{message.subject}</span>
                                <br />
                            </>
                        )}
                        {message.body &&
                            message.body.split('\n').map((item, key) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Fragment key={key}>
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <span dangerouslySetInnerHTML={{ __html: `${item}` }} />
                                    <br />
                                </Fragment>
                            ))}
                    </Body>
                    <Meta>
                        <span>
                            {message.author === 'user' ? 'Client' : 'TaxScouts'}
                            {message.type === 'note' ? ' note' : ''}
                        </span>{' '}
                        <span>∙</span>{' '}
                        <span title={message.createdDateUTC}>{formatDateWithTime(message.createdDateUTC)}</span>
                    </Meta>
                </Message>
            ))}
        </>
    )
}

ConversationMessages.propTypes = {
    conversationId: PropTypes.number.isRequired,
}

const Message = styled.div`
    color: ${colors.black};
    margin-left: ${({ isAdminMessage }) => (isAdminMessage ? '24px' : 0)};
    margin-right: ${({ isAdminMessage }) => (!isAdminMessage ? '24px' : 0)};
    padding: 12px;
    background: ${getMessageBackground};
    border-radius: 2px;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Body = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
`

const Meta = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.smaller};
    text-align: right;
    margin-top: 12px;
`
