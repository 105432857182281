import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, media } from '@scouts/ui'

import { FILING_AREA_GENERAL, PATH_CUSTOMERS } from '@/constants'
import { FileType, FileTypeTitles } from '@/models'

import SelfAssessmentUploadsButton from './SelfAssessmentUploadsButton'
import { SelfAssessmentUploadsFile } from './SelfAssessmentUploadsFile'

const SelfAssessmentUploads = ({ selfAssessment, selfAssessmentFiles }) => {
    const { id: selfAssessmentId, customerId, paymentOnAccount } = selfAssessment

    const files = selfAssessmentFiles.filter((o) => o.filingArea === FILING_AREA_GENERAL)
    const hasFiles = files.length > 0

    const hasUploadedFinalReturn =
        selfAssessmentFiles.findIndex(({ fileType }) => fileType === FileType.FinalReturn) >= 0
    const hasUploadedTaxSummary = files.findIndex(({ fileType }) => fileType === FileType.TaxSummary) >= 0
    const hasPaymentOnAccount = !!paymentOnAccount

    const shouldRemindAboutTaxSummary = hasPaymentOnAccount && hasUploadedFinalReturn && !hasUploadedTaxSummary
    const shouldAdviseAboutDraftReturn = !hasUploadedFinalReturn

    const customerPath = `${PATH_CUSTOMERS}/${customerId}`
    const messagesPath = `${customerPath}/messages${selfAssessmentId ? `/${selfAssessmentId}` : ''}`

    return (
        <Container>
            {hasFiles && (
                <FilesContainer>
                    {files.map((file) => (
                        <FileContainer key={file.id}>
                            <FileTitle>{FileTypeTitles[file.fileType]}</FileTitle>
                            <SelfAssessmentUploadsFile file={file} />
                        </FileContainer>
                    ))}
                </FilesContainer>
            )}

            {!hasFiles && <NoFiles>Final tax return not yet uploaded</NoFiles>}

            {shouldRemindAboutTaxSummary && (
                <TaxSummaryReminder>
                    Please upload a tax summary document as well to show the payments on account
                </TaxSummaryReminder>
            )}

            <Actions>
                <SelfAssessmentUploadsButton
                    shouldVerify
                    selfAssessmentId={selfAssessmentId}
                    selfAssessmentFiles={selfAssessmentFiles}
                    uploadTypes={[
                        {
                            filingArea: FILING_AREA_GENERAL,
                            fileType: FileType.FinalReturn,
                            allowMultipleUploads: false,
                        },
                        {
                            filingArea: FILING_AREA_GENERAL,
                            fileType: FileType.TaxCalculation,
                            allowMultipleUploads: false,
                        },
                        {
                            filingArea: FILING_AREA_GENERAL,
                            fileType: FileType.SubmissionResponse,
                            allowMultipleUploads: false,
                        },
                        {
                            filingArea: FILING_AREA_GENERAL,
                            fileType: FileType.TaxSchedule,
                            allowMultipleUploads: false,
                        },
                        {
                            filingArea: FILING_AREA_GENERAL,
                            fileType: FileType.TaxSummary,
                            allowMultipleUploads: false,
                        },
                        {
                            filingArea: FILING_AREA_GENERAL,
                            fileType: FileType.CapitalGainsComputation,
                            allowMultipleUploads: false,
                        },
                        {
                            filingArea: FILING_AREA_GENERAL,
                            fileType: FileType.Other,
                            allowMultipleUploads: true,
                        },
                    ]}
                />
            </Actions>

            {shouldAdviseAboutDraftReturn && (
                <DraftReturnAdvice>
                    To ask the client questions regarding a draft tax return, feel free to share it via{' '}
                    <Link to={messagesPath}>messages</Link>
                </DraftReturnAdvice>
            )}
        </Container>
    )
}

SelfAssessmentUploads.propTypes = {
    selfAssessment: PropTypes.object.isRequired,
    selfAssessmentFiles: PropTypes.array.isRequired,
}

export default SelfAssessmentUploads

const Container = styled.div``

const FilesContainer = styled.div`
    margin-bottom: 12px;

    ${media.tablet} {
        margin-bottom: 0;
    }
`

const FileTitle = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    line-height: 1.3;
    margin-bottom: 6px;
`

const FileContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 18px;
    }
`

const NoFiles = styled.div`
    font-size: ${font.small};
    text-align: center;
    line-height: 1.3;
    margin-bottom: 12px;
    padding: 24px;
    background: ${colors.redLighter};
    border-radius: 4px;

    ${media.tablet} {
        margin-bottom: 0;
    }
`
const TaxSummaryReminder = styled.div`
    font-size: ${font.small};
    text-align: center;
    line-height: 1.3;
    margin: 12px 0;
    padding: 24px;
    background: ${colors.yellow};
    border-radius: 2px;
`

const DraftReturnAdvice = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.smaller};
    text-align: center;
    line-height: 1.5;
    margin-top: 24px;
    padding: 0 36px;

    a {
        color: ${colors.neutralDarker};

        ${media.pointer} {
            &:hover {
                color: ${colors.black};
            }
        }
    }
`

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
`
