import { useEffect } from 'react'

import { useQueryParams } from '@scouts/helpers'
import { colors, Container, Flexer, Line, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { requestAccountants } from '@/actions'
import { useAppDispatch } from '@/store'
import { invalidateTaxRegistrations, useTaxRegistrations } from '@/store/tax-registrations'

import { useUser } from '@/hooks/use-user'

import { useFilters, useSelection } from './hooks'

import { usePagination } from '../pagination'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { TaxRegistrationsBulkActions } from './TaxRegistrationsBulkActions'
import { TaxRegistrationsFilters } from './TaxRegistrationsFilters'
import { TaxRegistrationsSupportFilters } from './TaxRegistrationsSupportFilters'
import { TaxRegistrationsTable } from './TaxRegistrationsTable'

export const TaxRegistrations = () => {
    const dispatch = useAppDispatch()
    const { queryParams } = useQueryParams()
    const { activeFilters, applyFilter, DEFAULT_FILTER } = useFilters()
    const { activeFilters: paginationFilters } = usePagination()
    const { isAdminUser } = useUser()

    useEffect(() => {
        const { filter } = queryParams

        if (!filter) {
            applyFilter({ updatedFilter: { ...DEFAULT_FILTER } })
        }
    }, [DEFAULT_FILTER, applyFilter, queryParams])

    const { taxRegistrations, isLoading, isError, pagination, isFetching } = useTaxRegistrations({
        options: { ...activeFilters, ...paginationFilters },
    })

    const { deselectAll, selectAll, selectedCustomers, handleSelectCustomer } = useSelection({ taxRegistrations })

    const itemsCount = taxRegistrations?.length || 0
    const hasItems = itemsCount > 0

    const triggerTableUpdate = () => {
        dispatch(invalidateTaxRegistrations())
    }

    useEffect(() => {
        if (isAdminUser) dispatch(requestAccountants())
    }, [dispatch, isAdminUser])

    if (isError)
        return (
            <Flexer alignCenter justifyCenter>
                <Line color={colors.neutralDarker}>Error getting tax registrations</Line>
            </Flexer>
        )

    if (isLoading && !hasItems)
        return (
            <Skeleton gap="24px" padding="24px">
                <Flexer column gap="12px">
                    {isAdminUser && <SkeletonLine height="35px" width="50%" />}
                    <SkeletonLine height="35px" width="80%" />
                </Flexer>
                <Flexer column gap="1px">
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="84px" repeat={10} />
                </Flexer>
            </Skeleton>
        )

    return (
        <Container padding="24px 24px 192px">
            <Spacer marginBottom="24px">
                <Flexer column gap="12px">
                    {isAdminUser && <TaxRegistrationsSupportFilters />}
                    <TaxRegistrationsFilters />
                </Flexer>
            </Spacer>

            {!isLoading && !hasItems && <Line color={colors.neutralDarker}>No tax registrations found</Line>}

            {taxRegistrations && taxRegistrations?.length > 0 && (
                <>
                    <TaxRegistrationsTable
                        taxRegistrations={taxRegistrations}
                        deselectAll={deselectAll}
                        handleCustomerSelection={handleSelectCustomer}
                        isFetching={isFetching}
                        selectAll={selectAll}
                        selectedItems={selectedCustomers}
                        triggerTableUpdate={triggerTableUpdate}
                    />

                    <Spacer marginTop="24px">
                        <PaginationContainer pagination={pagination} />
                    </Spacer>

                    <TaxRegistrationsBulkActions selectedCustomers={selectedCustomers} deselectAll={deselectAll} />
                </>
            )}
        </Container>
    )
}
