import _find from 'lodash/find'
import { useState } from 'react'

import {
    Button,
    Flexer,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Radio,
    Spacer,
} from '@scouts/ui'

import { createSelfAssessment, requestCustomer, requestCustomerSelfAssessments } from '@/actions'
import { TAX_YEARS } from '@/constants'
import { CustomerDTO, SelfAssessmentSummaryDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface CreateSelfAssessmentModalProps {
    closeModal: () => void
    customer: CustomerDTO
    customerSelfAssessments: SelfAssessmentSummaryDTO[]
}

export const CreateSelfAssessmentModal = ({
    customer,
    closeModal,
    customerSelfAssessments,
}: CreateSelfAssessmentModalProps) => {
    const dispatch = useAppDispatch()
    const [taxYear, setTaxYear] = useState<number | null>(null)

    const handleSubmit = () => {
        const payload = {
            customerId: customer.id,
            taxYear,
            requestForTaxDetails: false,
        }
        const meta = {
            onSuccess: () => {
                dispatch(requestCustomer(customer.id))
                dispatch(requestCustomerSelfAssessments(customer.id))
                closeModal()
            },
        }

        dispatch(createSelfAssessment(payload, meta))
    }

    const availableTaxYears = Object.keys(TAX_YEARS)
        .filter((taxYear) => !_find(customerSelfAssessments, { taxYear: String(taxYear) }))
        .map((taxYear) => Number(taxYear))
        .sort((a, b) => b - a)

    return (
        <Modal onClose={closeModal}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Add a new tax return</ModalTitle>
                    <ModalSubtitle>Select the tax year</ModalSubtitle>
                </ModalHeader>

                <ModalBody>
                    <Flexer column gap="12px">
                        {availableTaxYears.map((itemTaxYear) => {
                            return (
                                <Spacer key={itemTaxYear}>
                                    <Radio
                                        checked={itemTaxYear === taxYear}
                                        label={TAX_YEARS[itemTaxYear as keyof typeof TAX_YEARS]}
                                        name="taxYearSomething"
                                        onChange={({ value }) => setTaxYear(Number(value))}
                                        value={itemTaxYear}
                                    />
                                </Spacer>
                            )
                        })}
                    </Flexer>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button type="submit">Add</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
