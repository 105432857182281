import { generatePath, Link } from 'react-router-dom'

import { colors, font, Flexer, Line, Table, Tooltip } from '@scouts/ui'

import { PATH_CUSTOMER_COMPANY_RETURN } from '@/constants'
import { formatDate } from '@/helpers'
import { CompanyReturnSummaryDTO } from '@/models'

import { useUser } from '@/hooks/use-user'

import { AccountantStatusBubble } from '../accountants/AccountantStatusBubble'
import { CompanyReturnFilingLabel } from './CompanyReturnFilingLabel'
import { CompanyReturnStatusLabel } from './CompanyReturnStatusLabel'

export const CompanyReturnTableRow = ({ companyReturn }: { companyReturn: CompanyReturnSummaryDTO }) => {
    const { isAdminUser } = useUser()
    const {
        accountantStatus,
        accountantFullName,
        companyId,
        id: companyReturnId,
        companyName,
        status,
        accountsDueDate,
        accountingPeriodStart,
        accountingPeriodEnd,
        customer,
        isOnHold,
        onHoldUntilDate,
    } = companyReturn
    const { current, updatedDateUTC } = status
    const { email: customerEmail, id: customerId } = customer

    const customerPath = generatePath(PATH_CUSTOMER_COMPANY_RETURN, { customerId, companyId, companyReturnId })

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="30%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer column gap="6px">
                        <Line weight={font.weight.medium} lineHeight="1">
                            {companyName || companyId}
                        </Line>
                        <Line size={font.small} color={colors.neutralDarker} lineHeight="1">
                            {customerEmail}
                        </Line>

                        {isAdminUser && accountantFullName && accountantStatus && (
                            <Flexer gap="6px" alignCenter>
                                <Line size={font.small} color={colors.neutralDarker}>
                                    {accountantFullName}
                                </Line>
                                <AccountantStatusBubble status={accountantStatus} />
                            </Flexer>
                        )}
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer gap="3px">
                        <CompanyReturnStatusLabel status={current} />

                        {isOnHold && (
                            <Tooltip content={`On hold until ${formatDate(onHoldUntilDate)}`}>
                                <Line size={font.small}>(On hold)</Line>
                            </Tooltip>
                        )}

                        {/* {hasPaid && <Label background={colors.mintLighter}>Paid</Label>} */}
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="30%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer gap="12px" alignCenter>
                        {!!accountingPeriodEnd && !!accountingPeriodStart && (
                            <Line size={font.small}>
                                {formatDate(accountingPeriodStart)} - {formatDate(accountingPeriodEnd)}{' '}
                            </Line>
                        )}
                        {!!accountsDueDate && (
                            <CompanyReturnFilingLabel accountsDueDate={accountsDueDate} status={current} />
                        )}
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath} alignRight>
                    {!!updatedDateUTC && <Line size={font.small}>{formatDate(updatedDateUTC)}</Line>}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}
