import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, media, Badge } from '@scouts/ui'

import { isAdmin } from '@/helpers'
import { FileSubType, FileSubTypeTitles, FileType, FileTypeTitles, UserType } from '@/models'

import FileWithActions from './FileWithActions'
import SelfAssessmentUploadsButton from './self-assessment/SelfAssessmentUploadsButton'
import UploadFilesCustomer from './UploadFilesCustomer'

const Container = styled.div``

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex: 1 1 auto;
    height: 60px;
`

const Title = styled.div`
    color: ${({ hasFiles }) => (hasFiles ? colors.blue : colors.neutralDarker)};
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    padding-left: 30px;
    position: relative;

    &:not(:only-child) {
        margin-right: 9px;
    }

    &:before {
        content: ${({ isOpen }) => (isOpen ? '"▾"' : '"▸"')};
        color: ${({ hasFiles }) => (hasFiles ? colors.blue : colors.neutralLightest)};
        position: absolute;
        left: 0;
        top: -1px;
        cursor: pointer;
        pointer-events: none;
    }
`

const TitleContainer = styled.div`
    flex: 0 0 calc(100% - 144px);
    display: flex;
    align-items: center;
    height: 100%;

    ${media.pointer} {
        ${({ hasFiles }) =>
            hasFiles &&
            `
            &:hover ${Title} {
                color: ${colors.black};
            }
        `};
    }
`

const UploadContainer = styled.span`
    flex: 0 0 auto;
`

const Body = styled.div`
    padding: 6px 0 30px 30px;
`

const List = styled.div`
    &:not(:last-child) {
        margin-bottom: 18px;
    }
`

const FileContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: 12px 0;
    &:not(:last-child) {
        border-bottom: 1px solid ${colors.neutralLightest};
    }
`

const Type = styled.span`
    font-size: ${font.small};
    flex: 0 0 30%;
`

const DownloadContainer = styled.div`
    flex: 0 0 50%;
    min-width: 0;
`

const Time = styled.span`
    font-size: ${font.small};
    flex: 0 0 20%;
    text-align: right;
`

const Description = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
    flex: 0 0 100%;
    margin-top: 12px;
    padding: 6px 9px;
    background: ${colors.redLighter};
`

class FilesSection extends Component {
    constructor(props) {
        super(props)

        const { isOpenByDefault } = this.props

        this.state = {
            isOpen: isOpenByDefault,
        }

        _bindAll(this, ['toggleIsOpen'])
    }

    toggleIsOpen() {
        const { files } = this.props
        const { isOpen } = this.state

        const hasFiles = files.length > 0

        if (hasFiles) {
            this.setState({ isOpen: !isOpen })
        }
    }

    renderFile(file) {
        const { user, selfAssessment } = this.props
        const { id, fileType, fileOwnerType, fileSubType, fileName, uploadedDateUTC, fileDescription } = file

        const isAdminUser = isAdmin(user)
        const hasFileOwnerRights = [UserType.Accountant, UserType.System].includes(fileOwnerType)
        const isDeletable = isAdminUser || hasFileOwnerRights

        const shouldDisplaySubType =
            fileSubType && fileSubType !== FileSubType.KoinlyReport && fileType !== FileType.IncomePlatformReport

        return (
            <FileContainer key={id}>
                <Type>
                    {FileTypeTitles[fileType]} {shouldDisplaySubType ? `- ${FileSubTypeTitles[fileSubType]}` : ''}
                </Type>

                <DownloadContainer>
                    <FileWithActions
                        background={colors.white}
                        file={file}
                        isCustomerFile={!selfAssessment}
                        isDeletable={isDeletable}
                        label={fileName}
                    />
                </DownloadContainer>

                <Time>{moment.utc(uploadedDateUTC).fromNow()}</Time>

                {fileDescription && <Description>{fileDescription}</Description>}
            </FileContainer>
        )
    }

    renderUpload() {
        const { customer, files, selfAssessment, employment, uploadTypes, identityVerification } = this.props

        const customerId = _get(customer, ['id'], null)
        const identityVerificationId = _get(identityVerification, ['id'], null)
        const selfAssessmentId = _get(selfAssessment, ['id'], null)
        const employmentId = _get(employment, ['id'], null)

        if (selfAssessmentId) {
            return (
                <SelfAssessmentUploadsButton
                    employmentId={employmentId}
                    selfAssessmentFiles={files}
                    selfAssessmentId={selfAssessmentId}
                    uploadTypes={uploadTypes}
                />
            )
        }

        if (customerId) {
            return (
                <UploadFilesCustomer
                    customerId={customerId}
                    uploadTypes={uploadTypes}
                    identityVerificationId={identityVerificationId}
                />
            )
        }

        return null
    }

    render() {
        const { files, title } = this.props
        const { isOpen } = this.state

        const hasFiles = files.length > 0

        return (
            <Container>
                <Header>
                    <TitleContainer hasFiles={hasFiles} onClick={this.toggleIsOpen}>
                        <Title isOpen={isOpen} hasFiles={hasFiles}>
                            {title}
                        </Title>
                        {hasFiles && (
                            <Badge color={colors.black} background={colors.neutralLightest}>
                                {files.length}
                            </Badge>
                        )}
                    </TitleContainer>
                    <UploadContainer>{this.renderUpload()}</UploadContainer>
                </Header>

                {isOpen && hasFiles && (
                    <Body>
                        <List>
                            {_orderBy(files, ['fileType', 'uploadedDateUTC'], ['asc', 'desc']).map((file) =>
                                this.renderFile(file)
                            )}
                        </List>
                    </Body>
                )}
            </Container>
        )
    }
}

FilesSection.propTypes = {
    customer: PropTypes.object,
    employment: PropTypes.object,
    files: PropTypes.array.isRequired,
    identityVerification: PropTypes.object,
    isOpenByDefault: PropTypes.bool,
    selfAssessment: PropTypes.object,
    title: PropTypes.string.isRequired,
    uploadTypes: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
}

FilesSection.defaultProps = {
    customer: undefined,
    employment: undefined,
    identityVerification: undefined,
    isOpenByDefault: false,
    selfAssessment: undefined,
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(withRouter(FilesSection))
