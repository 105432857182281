/* eslint-disable no-bitwise */
import _compose from 'lodash/fp/compose'
import _toNumber from 'lodash/toNumber'
import _toString from 'lodash/toString'
import moment from 'moment-timezone'

import { localStorageGet, localStorageSet } from '@scouts/helpers'
import { colors } from '@scouts/ui'

import config from '@/config'
import {
    DEFAULT_CURRENCY_SIGN,
    MONTH_NAMES_SHORT,
    SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_CANCELLED,
    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_FILED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_ORDER,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    SELF_EMPLOYMENT_CATEGORY_CONSTRUCTION,
} from '@/constants'

export { removeItemsById, updateOrAddItemsById } from './redux'
export { apiCall, apiCallCancelable, createAxiosCancelSource, getPaginationHeaders, isCancelledApiCall } from './sagas'

export const formatCurrency = (value) => {
    if (value !== 0 && !value) return '-'

    let numberValue = _toNumber(value)

    if (_toString(numberValue).indexOf('.') > -1) {
        numberValue = numberValue.toFixed(2)
    }

    if (numberValue) {
        numberValue = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return `${DEFAULT_CURRENCY_SIGN}${numberValue}`
}

export const formatCurrencyNotSure = (value) => (value !== 0 && !value ? '?' : formatCurrency(value))

/** @returns {string} a date in the format "1 Jan 2000" */
export const formatDate = (dateTimeUTC) => {
    if (!dateTimeUTC) return null
    const date = new Date(dateTimeUTC)
    const month = MONTH_NAMES_SHORT[date.getMonth()]
    return `${date.getDate()} ${month} ${date.getFullYear()}`
}

/** @returns {string} a date in the format "1 Jan 2000 12:00" */
export const formatDateWithTime = (dateTimeUTC) => {
    if (!dateTimeUTC) return null

    return moment.tz(moment.utc(dateTimeUTC), 'Europe/London').format('D MMM YYYY HH:mm')
}

export const formatTimeTodayAndDateIfLater = (dateTimeUTC) => {
    if (!dateTimeUTC) return null

    const isToday = moment(dateTimeUTC).isSame(moment(), 'day')

    if (isToday) {
        return moment.utc(dateTimeUTC).fromNow()
    }

    return moment.tz(moment.utc(dateTimeUTC), 'Europe/London').format(`D MMM Y`)
}

export const formatDateWeekdayWithTime = (dateTimeUTC) => {
    if (!dateTimeUTC) return null

    const isToday = moment(dateTimeUTC).isSame(moment(), 'day')
    const isTomorrow = moment(dateTimeUTC).isSame(moment().add(1, 'days'), 'day')

    if (isToday) return moment.tz(moment.utc(dateTimeUTC), 'Europe/London').format('[Today at] HH:mm')
    if (isTomorrow) return moment.tz(moment.utc(dateTimeUTC), 'Europe/London').format('[Tomorrow at] HH:mm')

    return moment.tz(moment.utc(dateTimeUTC), 'Europe/London').format(`ddd, D MMM [at] HH:mm`)
}

export const formatTimeFromNow = (dateTimeUTC, withoutSuffix) => moment.utc(dateTimeUTC).fromNow(withoutSuffix)

export const formatDateYYYYMMDD = (date = new Date()) => {
    const day = String(date.getDate())
    const month = String(date.getMonth() + 1)
    const year = String(date.getFullYear())
    return `${year}-${month.length > 1 ? month : `0${month}`}-${day.length > 1 ? day : `0${day}`}`
}

export const formatDateDDMMYYYY = (dateTimeUTC) => {
    if (!dateTimeUTC) return dateTimeUTC
    const date = new Date(dateTimeUTC)
    const day = String(date.getDate())
    const month = String(date.getMonth() + 1)
    const year = String(date.getFullYear())

    return `${day.length > 1 ? day : `0${day}`}/${month.length > 1 ? month : `0${month}`}/${year}`
}

export const formatTimeHHmm = (date = new Date()) => moment(date).format('HH:mm')

/**
 * Merges a date and a time into an ISOString
 * @param {string|Date} dateString '2021-12-24' | new Date('2021-12-24')
 * @param {string} timeString '12:34'
 * @returns {string} dateTimeUTC - '2021-12-24T10:34:00.000Z'
 */
export const getUTCfromDateAndTime = (dateString, timeString) => {
    if (!dateString) return dateString

    return new Date(`${formatDateYYYYMMDD(new Date(dateString))}T${timeString}`).toISOString()
}

/**
 *
 * @param {Date} dateStartUTC new Date('1995-12-24 00:00')
 * @param {Date} dateEndUTC new Date('1995-12-27 00:00')
 * @param {boolean} [withoutSuffix=true] Defaults to True. If True, returns "3 days". If False, returns "in 3 days".
 * @returns {string} a date in the format of "3 days"
 */
export const formatDateGap = (dateStartUTC, dateEndUTC = moment(), withoutSuffix = true) => {
    if (!dateStartUTC && !dateEndUTC) return null

    return moment(dateStartUTC).to(moment(dateEndUTC), withoutSuffix)
}

export const isDateDue = (dateTimeUTC, offsetDays = 0) =>
    dateTimeUTC && moment().startOf('day').diff(dateTimeUTC, 'days') >= offsetDays

export const isDatePast = (date) => moment() >= moment(date)

export const hasSelfEmploymentAllowances = (selfAssessment) =>
    selfAssessment.hasSelfEmployment &&
    selfAssessment.selfEmployment &&
    (selfAssessment.selfEmployment.previousYearsLosses > 0 ||
        selfAssessment.selfEmployment.approximateExpenses > 0 ||
        selfAssessment.selfEmployment.businessVehicle ||
        selfAssessment.selfEmployment.selfEmploymentCategory === SELF_EMPLOYMENT_CATEGORY_CONSTRUCTION ||
        selfAssessment.selfEmployment.lowExpensesUseTradingAllowance)

export const hasCapitalGainsAllowances = (selfAssessment) =>
    selfAssessment.hasCapitalGains &&
    selfAssessment.capitalGains &&
    (selfAssessment.capitalGains.propertyLosses ||
        selfAssessment.capitalGains.shareLosses ||
        selfAssessment.capitalGains.otherLosses ||
        selfAssessment.capitalGains.cryptoLosses ||
        selfAssessment.capitalGains.hasEmploymentOptionSchemeAllowance ||
        selfAssessment.capitalGains.hasHmrcInvestmentSchemeAllowance)

const hasPaymentOnAccount = (selfAssessment) => selfAssessment.hasLastYearPaymentsOnAccount

export const hasAllowances = (selfAssessment) =>
    hasSelfEmploymentAllowances(selfAssessment) ||
    hasCapitalGainsAllowances(selfAssessment) ||
    hasPaymentOnAccount(selfAssessment)

export const hasGeneralAllowances = (selfAssessment) =>
    selfAssessment.hasLastYearPaymentsOnAccount ||
    selfAssessment.hasCharitableDonations ||
    selfAssessment.hasInvestmentSchemes ||
    selfAssessment.hasPensionsContributions ||
    selfAssessment.hasStudentLoans ||
    selfAssessment.partnerIncomeUnderAllowanceLimit ||
    selfAssessment.hasWorkFromHomeAllowance

export const generateKeyFromValue = (value) => {
    if (!value) return 0
    let hash = 0
    let i
    let chr
    if (value.length === 0) return hash
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export const colorBySelfAssessmentStatus = (status) => {
    switch (status) {
        case SELF_ASSESSMENT_STATUS_SUBMITTED:
            return colors.blueLighter
        case SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW:
            return colors.blueLighter
        case SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED:
            return colors.yellow
        case SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL:
            return colors.yellow
        case SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS:
            return colors.yellow
        case SELF_ASSESSMENT_STATUS_READY_TO_FILE:
            return colors.blueLighter
        case SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND:
        case SELF_ASSESSMENT_STATUS_FILED:
        case SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED:
            return colors.mintLighter
        case SELF_ASSESSMENT_STATUS_CANCELLED:
            return colors.red
        default:
            return colors.neutralLightest
    }
}

export const isSelfAssessmentStatusAtLeast = (currentStatus, neededStatus) =>
    SELF_ASSESSMENT_STATUS_ORDER[currentStatus] >= SELF_ASSESSMENT_STATUS_ORDER[neededStatus]

export const copyTextToClipboard = (text, callback) => {
    if (!navigator.clipboard) {
        const textArea = document.createElement('textArea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)
        return
    }
    navigator.clipboard.writeText(text).then(() => {
        if (callback) {
            callback()
        }
    })
}

// eslint-disable-next-line react/no-array-index-key
export const splitEventText = (eventText) => eventText.split(', ').map((line, i) => <p key={i}>{line}</p>)

export const isAdmin = (user) => !!user.isTaxScoutsSupport

const scrollBarWidth = 25
export const clickedOnScrollbar = (mouseX) => {
    if (document.scrollingElement) {
        return window.outerWidth - scrollBarWidth + document.scrollingElement.scrollLeft <= mouseX
    }
    return window.outerWidth - scrollBarWidth <= mouseX
}

export const isNumeric = (num) => !Number.isNaN(parseFloat(num)) && Number.isFinite(num)

export const updateRecentCustomers = (customerId) => {
    let recentCustomers = JSON.parse(localStorageGet('recentCustomers'))

    if (Array.isArray(recentCustomers)) {
        const key = recentCustomers.indexOf(customerId)

        if (key > -1) {
            recentCustomers.splice(key, 1)
        }

        if (isNumeric(customerId)) {
            recentCustomers.unshift(customerId)
        }
    } else if (isNumeric(customerId)) {
        recentCustomers = [customerId]
    }

    recentCustomers.splice(20)

    localStorageSet('recentCustomers', JSON.stringify(recentCustomers))
}

export const isValidNino = (nino) =>
    /^[ABCEGHJKLMNOPRSTWXYZ][ABCEGHJKLMNPRSTWXYZ][0-9]{6}[A-D ]$/.test(String(nino).toUpperCase())

export const isValidUtr = (utr) => /^[0-9]{10}$/.test(String(utr))

export const isValidPostCode = (code) =>
    /^(GIR 0AA)|((([A-Z][0-9][0-9]?)|(([A-Z][A-HJ-Y][0-9][0-9]?)|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2})$/.test(
        String(code)
    )

/**
 *
 * Formats post code to a useable format
 * @param {string} postCode
 * @returns {string} a post code in the format of "AAAA AAA" / "AAA AAA" / "AA AAA" / "A AAA"
 */
export const formatPostCode = (postCode) => {
    const trimmedPostCode = postCode.replace(/\s/g, '')
    const codeLength = trimmedPostCode.length

    if (codeLength < 4) return postCode
    const formattedPostCode = trimmedPostCode.replace(/(?=.{3}$)/, ' ')

    return formattedPostCode.toUpperCase()
}

export const handleBooleanStringValue = (v) => {
    if (v === 'true') return true
    if (v === 'false') return false
    return v
}

export const isValidAuthorisationCode = (code) => code[0] === 'S' && code[1] === 'A' && code.length === 10

const formatBracketsToNegativeValue = (value) => {
    const matches = value.match(/\((.*?)\)/)

    if (matches) {
        return `-${matches[1]}`
    }

    return value
}

const formatRemoveCurrencyAndWhiteSpace = (value) => {
    // eslint-disable-next-line prefer-regex-literals
    const regexCurrencyAndWhitespace = new RegExp(`£|€|\\s`)
    const sanitizedValue = value.replace(regexCurrencyAndWhitespace, '')

    return sanitizedValue
}

export const removeCurrencyAndWhiteSpaceAndFormatBracketsToNegativeValue = _compose(
    formatRemoveCurrencyAndWhiteSpace,
    formatBracketsToNegativeValue
)

export const shouldAllowEditingEscalationNote = ({ note, isAdminUser }) => (!note.escalationId ? true : isAdminUser)

export const getFirstName = (fullName = '') => fullName.trim().split(/\s+/)[0]

export const getAccountantImageUrl = (accountantId) => `${config.apiUrl}accountants/${accountantId}/image`

export const formatTaxYear = (taxYear) => `${taxYear - 1}/${String(taxYear).slice(-2)}`
