import { Button } from '@scouts/ui'

import { IdentityVerificationDTO, IdentityVerificationStatus } from '@/models'
import { useUpdateIdentityVerification } from '@/store/identity-verifications'

interface MarkAsCancelledProps {
    identityVerification: IdentityVerificationDTO
    buttonLabel: string
}

export const MarkAsCancelled = ({ identityVerification, buttonLabel }: MarkAsCancelledProps) => {
    const { updateIdentityVerification, isLoading } = useUpdateIdentityVerification()

    const markAsCancelled = () => {
        updateIdentityVerification({
            identityVerificationId: identityVerification.id,
            data: { ...identityVerification, status: IdentityVerificationStatus.Cancelled },
        })
    }

    return (
        <Button isSecondary onClick={markAsCancelled} isLoading={isLoading}>
            {buttonLabel}
        </Button>
    )
}
