import { AccountantStatusTypes } from './accountant'
import { PaymentStatusTypes } from './payment-status'
import { RefundReasonDTO } from './refunds'

export interface CompanyReturnDTO {
    accountsDueDate: string | null
    accountingPeriodEnd: string | null
    accountingPeriodStart: string | null
    bookkeepingMethod: CompanyBookkeepingMethodTypes | null
    companyId: number
    companyName: string | null
    hasAssetActivity: boolean | null
    hasLoanActivity: boolean | null
    hasPayrollActivity: boolean | null
    id: number
    inactivityStatus: CompanyReturnInactivityStatus | null
    isMissingAgreement: boolean
    isOnHold: boolean
    isVatRegistered: boolean | null
    onHoldUntilDate: string | null
    payment: CompanyReturnPaymentDTO
    previousAccountantName: string | null
    previousAccountantEmail: string | null
    previouslyFiledByType: CompanyPreviouslyFiledByTypeTypes | null
    status: CompanyReturnStatusDTO
    transactionCountCategory: CompanyTransactionCountCategoryTypes | null
    turnoverCategory: CompanyTurnoverCategoryTypes | null
    totalProfit: number | null
    totalTaxableProfit: number | null
    totalTaxDue: number | null
    userId: number
}

export interface CompanyReturnUpdateDTO {
    accountingPeriodStart: string | null
    accountingPeriodEnd: string | null
    bookkeepingMethod: CompanyBookkeepingMethodTypes | null
    inactivityStatus: CompanyReturnInactivityStatus | null
    isVatRegistered: boolean | null
    onHoldUntilDate: string | null
    previouslyFiledByType: CompanyPreviouslyFiledByTypeTypes | null
    transactionCountCategory: CompanyTransactionCountCategoryTypes | null
    turnoverCategory: CompanyTurnoverCategoryTypes | null
    totalProfit: number | null
    totalTaxableProfit: number | null
    totalTaxDue: number | null
}

export interface CompanyReturnSummaryDTO {
    id: number
    userId: number
    accountantId: number | null
    accountantEmail: string | null
    accountantFullName: string | null
    accountantStatus: AccountantStatusTypes | null
    companyId: number
    companyName: string | null
    customer: CompanyReturnCustomerDTO
    accountsDueDate: string | null
    accountingPeriodEnd: string | null
    accountingPeriodStart: string | null
    status: CompanyReturnStatusDTO
    isOnHold: boolean
    onHoldUntilDate: string | null
}

export interface CompanyReturnCustomerDTO {
    id: number
    email: string | null
    fullName: string | null
}

export interface CompanyReturnStatusDTO {
    current: CompanyReturnStatusTypes
    updatedDateUTC: string
    submittedDateUTC: string | null
    customerApprovalDateUTC: string | null
    filedDateUTC: string | null
}

export interface CompanyReturnPaymentDTO {
    hasPaid: boolean
    isFree: boolean
    paymentDateUTC: string | null
    price: number
    refundDateUTC: string | null
    refundReason: RefundReasonDTO | null
    revenueDateUTC: string | null
    status: PaymentStatusTypes
}

export const CompanyReturnStatus = {
    Created: 'Created',
    Started: 'Started',
    UploadingDocuments: 'UploadingDocuments',
    Submitted: 'Submitted',
    AccountantReview: 'AccountantReview',
    InformationRequired: 'InformationRequired',
    AwaitingCustomerApproval: 'AwaitingCustomerApproval',
    ReadyToFile: 'ReadyToFile',
    Filed: 'Filed',
    Cancelled: 'Cancelled',
    CompletedNotFiled: 'CompletedNotFiled',
} as const

export type CompanyReturnStatusTypes = (typeof CompanyReturnStatus)[keyof typeof CompanyReturnStatus]

export const CompanyReturnStatusTitles: Record<CompanyReturnStatusTypes, string> = {
    Created: 'Created',
    Started: 'Started',
    UploadingDocuments: 'Uploading documents',
    Submitted: 'Submitted',
    AccountantReview: 'Accountant review',
    InformationRequired: 'Information required',
    AwaitingCustomerApproval: 'Awaiting customer approval',
    ReadyToFile: 'Ready to file',
    Filed: 'Filed',
    Cancelled: 'Cancelled',
    CompletedNotFiled: 'Completed not filed',
}

export const CompanyBookkeepingMethod = {
    None: 'None',
    Spreadsheet: 'Spreadsheet',
    Software: 'Software',
    Bookkeeper: 'Bookkeeper',
} as const

export type CompanyBookkeepingMethodTypes = (typeof CompanyBookkeepingMethod)[keyof typeof CompanyBookkeepingMethod]

export const CompanyBookkeepingMethodTitles: Record<CompanyBookkeepingMethodTypes, string> = {
    None: 'None',
    Spreadsheet: 'Spreadsheet',
    Software: 'Software',
    Bookkeeper: 'Bookkeeper',
}

const CompanyPreviouslyFiledByType = {
    NeverFiled: 'NeverFiled',
    Myself: 'Myself',
    Accountant: 'Accountant',
} as const

export type CompanyPreviouslyFiledByTypeTypes =
    (typeof CompanyPreviouslyFiledByType)[keyof typeof CompanyPreviouslyFiledByType]

export const CompanyPreviouslyFiledByTypeTitles: Record<CompanyPreviouslyFiledByTypeTypes, string> = {
    NeverFiled: 'Never filed',
    Myself: 'Myself',
    Accountant: 'Accountant',
}

export const CompanyTransactionCountCategory = {
    Unknown: 'Unknown',
    Under50: 'Under50',
    Between50And300: 'Between50And300',
    Over300: 'Over300',
} as const

export type CompanyTransactionCountCategoryTypes =
    (typeof CompanyTransactionCountCategory)[keyof typeof CompanyTransactionCountCategory]

export const CompanyTransactionCountCategoryTitles: Record<CompanyTransactionCountCategoryTypes, string> = {
    Unknown: 'Unknown',
    Under50: 'Under 50',
    Between50And300: 'Between 50 and 300',
    Over300: 'Over 300',
}

export const CompanyTurnoverCategory = {
    NoTurnover: 'NoTurnover',
    Under20k: 'Under20k',
    Between20kAnd85k: 'Between20kAnd85k',
    Between85kAnd200k: 'Between85kAnd200k',
    Over200k: 'Over200k',
} as const

export type CompanyTurnoverCategoryTypes = (typeof CompanyTurnoverCategory)[keyof typeof CompanyTurnoverCategory]

export const CompanyTurnoverCategoryTitles: Record<CompanyTurnoverCategoryTypes, string> = {
    NoTurnover: 'No turnover',
    Under20k: 'Under £20,000',
    Between20kAnd85k: 'Between £20,000 and £85,000',
    Between85kAnd200k: 'Between £85,000 and £200,000',
    Over200k: 'Over £200,000',
}

export interface CompanyReturnSearchOptions {
    accountantId?: number | null
    status?: CompanyReturnStatusFilterTypes | null
    page?: number
    pageSize?: number
    includeUnpaid?: boolean
    returnOnHold?: boolean
}

export const CompanyReturnStatusFilter = {
    Assigned: 'Assigned',
    Submitted: 'Submitted',
    InReview: 'InReview',
    InformationRequired: 'InformationRequired',
    AwaitingApproval: 'AwaitingApproval',
    ReadyToFile: 'ReadyToFile',
    Filed: 'Filed',
    CompletedNotFiled: 'CompletedNotFiled',
    Cancelled: 'Cancelled',
    All: 'All',
    OnHold: 'OnHold',
} as const

export const CompanyReturnStatusFilters = {
    Assigned: [CompanyReturnStatus.Created, CompanyReturnStatus.Started, CompanyReturnStatus.UploadingDocuments],
    Submitted: [CompanyReturnStatus.Submitted],
    InReview: [CompanyReturnStatus.AccountantReview],
    InformationRequired: [CompanyReturnStatus.InformationRequired],
    AwaitingApproval: [CompanyReturnStatus.AwaitingCustomerApproval],
    ReadyToFile: [CompanyReturnStatus.ReadyToFile],
    Filed: [CompanyReturnStatus.Filed],
    CompletedNotFiled: [CompanyReturnStatus.CompletedNotFiled],
    Cancelled: [CompanyReturnStatus.Cancelled],
    OnHold: [CompanyReturnStatusFilter.OnHold],
    All: [
        CompanyReturnStatus.Created,
        CompanyReturnStatus.Started,
        CompanyReturnStatus.UploadingDocuments,
        CompanyReturnStatus.Submitted,
        CompanyReturnStatus.AccountantReview,
        CompanyReturnStatus.InformationRequired,
        CompanyReturnStatus.AwaitingCustomerApproval,
        CompanyReturnStatus.ReadyToFile,
        CompanyReturnStatus.Filed,
        CompanyReturnStatus.Cancelled,
        CompanyReturnStatus.CompletedNotFiled,
    ],
} as const

export type CompanyReturnStatusFilterTypes = (typeof CompanyReturnStatusFilter)[keyof typeof CompanyReturnStatusFilter]

export const CompanyReturnStatusFilterTitles: Record<CompanyReturnStatusFilterTypes, string> = {
    Assigned: 'Assigned',
    Submitted: 'Ready to start',
    InReview: 'In review',
    InformationRequired: 'Info requested',
    AwaitingApproval: 'Awaiting approval',
    ReadyToFile: 'Ready to file',
    Filed: 'Filed',
    CompletedNotFiled: 'Completed',
    Cancelled: 'Cancelled',
    OnHold: 'On hold',
    All: 'All',
}

export const CompanyReturnInactivityStatuses = {
    Dormant: 'Dormant',
    NonTrading: 'NonTrading',
    DormantOrNonTrading: 'DormantOrNonTrading',
} as const

export type CompanyReturnInactivityStatus =
    (typeof CompanyReturnInactivityStatuses)[keyof typeof CompanyReturnInactivityStatuses]

export const CompanyReturnInactivityStatusTitles: Record<CompanyReturnInactivityStatus | 'None', string> = {
    Dormant: 'Dormant',
    NonTrading: 'Non-trading',
    DormantOrNonTrading: 'Dormant or non-trading',
    None: 'None',
}
