import config from '../config'
import { getAccessTokenAsync } from './access-token'

/** @returns {import('axios').AxiosRequestConfig} */
const composeRequestConfig = ({
    idToken,
    url = '',
    method = 'get',
    data = {},
    headers = {},
    cancelToken = null,
    responseType = 'json',
}) => ({
    method,
    baseURL: config.apiUrl,
    url,
    data,
    headers: {
        Authorization: `Bearer ${idToken}`,
        ...headers,
    },
    responseType,
    cancelToken,
})

/** @param {import('axios').AxiosRequestConfig} requestConfigExtraParams */
export const getRequestConfig = (requestConfigExtraParams) =>
    getAccessTokenAsync().then((idToken) => composeRequestConfig({ idToken, ...requestConfigExtraParams }))
