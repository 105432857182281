import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { MessageTemplateEditDTO } from '@/models/message-template'

import { useUpdateMessageTemplateMutation } from '../api'

export const useUpdateMessageTemplate = () => {
    const [action, { isLoading }] = useUpdateMessageTemplateMutation()

    const updateMessageTemplate = (params: MessageTemplateEditDTO & { id: number }) =>
        action(params).then(
            matchSuccessResponse((response) => {
                addSuccessToast({ body: `Saved message template "${response.name}"` })
            })
        )

    return { isLoading, updateMessageTemplate }
}
