import { ReactNode } from 'react'
import styled from 'styled-components'

import { addSuccessToast, Container } from '@scouts/ui'

import { copyTextToClipboard } from '@/helpers'

interface ClickToCopyProviderProps {
    children: ReactNode
    shouldHideOverflow?: boolean
}

export const ClickToCopyProvider = ({ children, shouldHideOverflow }: ClickToCopyProviderProps) => {
    const copyToClipboard = (value: ReactNode) => {
        copyTextToClipboard(value, () => addSuccessToast({ body: 'Copied to clipboard' }))
    }

    return (
        <Container onClick={() => copyToClipboard(children)}>
            {shouldHideOverflow ? <OverflowWrapper>{children}</OverflowWrapper> : children}
        </Container>
    )
}

const OverflowWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
