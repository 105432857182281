import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { colors, Notice } from '@scouts/ui'

import config from '../config'
import { EXTERNAL_API_GOOGLE, EXTERNAL_API_MICROSOFT, PATH_INTEGRATIONS } from '../constants'
import { apiRequest } from '../helpers/axios'

const Container = styled.div`
    max-width: 720px;
    margin: 0 auto;
    padding: 24px 36px 36px;
`

const Heading = styled.div`
    margin-bottom: 24px;
`

class ExternalEmailAuthenticated extends Component {
    constructor(props) {
        super(props)

        this.state = {
            responseError: false,
            responseErrorMessage: null,
        }

        _bindAll(this, ['storeAccessToken'])
    }

    componentDidMount() {
        const { location } = this.props

        const parsed = queryString.parse(location.search)

        const code = _get(parsed, ['code'], null)
        const state = _get(parsed, ['state'], null)

        if (parsed && code) {
            this.storeAccessToken(code, state === 'gmail' ? EXTERNAL_API_GOOGLE : EXTERNAL_API_MICROSOFT)
        }
    }

    storeAccessToken(code, externalApi) {
        const data = {
            code,
            redirectUri: config.callbackUriExternalEmail || null,
            externalApi,
        }

        apiRequest('externalmessages/accesstoken', 'post', data)
            .then((response) => {
                const externalAccessTokenId = _get(response, ['data', 'id'], null)

                if (externalAccessTokenId) {
                    window.location.assign(`${PATH_INTEGRATIONS}`)
                } else {
                    this.setState({
                        responseError: true,
                        responseErrorMessage: "Couldn't get externalAccessTokenId",
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    responseError: true,
                    responseErrorMessage: error.message,
                })
            })
    }

    render() {
        const { responseError, responseErrorMessage } = this.state

        return (
            <Container>
                <Heading>Connecting…</Heading>

                {responseError && <Notice background={colors.red}>{responseErrorMessage}</Notice>}
            </Container>
        )
    }
}

ExternalEmailAuthenticated.propTypes = {
    location: PropTypes.object.isRequired,
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps)(withRouter(ExternalEmailAuthenticated))
