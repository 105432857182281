import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { scrollPageToTop } from '@scouts/helpers'

interface LocationObserverProps {
    children: React.ReactElement
}

export const LocationObserver = ({ children }: LocationObserverProps) => {
    const { pathname } = useLocation()

    useEffect(() => {
        scrollPageToTop()
    }, [pathname])

    return children
}
