export const REQUEST_CUSTOMER_FILES = 'REQUEST_CUSTOMER_FILES'
export const RECEIVE_CUSTOMER_FILES = 'RECEIVE_CUSTOMER_FILES'
export const UPLOAD_CUSTOMER_FILE = 'UPLOAD_CUSTOMER_FILE'
export const DOWNLOAD_CUSTOMER_FILE = 'DOWNLOAD_CUSTOMER_FILE'
export const REMOVE_CUSTOMER_FILE = 'REMOVE_CUSTOMER_FILE'
export const DELETE_CUSTOMER_FILE = 'DELETE_CUSTOMER_FILE'

export const requestCustomerFiles = (data) => ({ type: REQUEST_CUSTOMER_FILES, data })

export const receiveCustomerFiles = (data) => ({ type: RECEIVE_CUSTOMER_FILES, data })

export const uploadCustomerFile = (data, options = {}) => ({ type: UPLOAD_CUSTOMER_FILE, data, options })

export const downloadCustomerFile = (data) => ({ type: DOWNLOAD_CUSTOMER_FILE, data })

export const removeCustomerFile = (data) => ({ type: REMOVE_CUSTOMER_FILE, data })

export const deleteCustomerFile = (data) => ({ type: DELETE_CUSTOMER_FILE, data })
