import { useState } from 'react'

import { useToggle } from '@scouts/helpers'
import {
    colors,
    ConfirmationModal,
    DropdownSelect,
    DropdownSelectOption,
    DropdownSelectProps,
    Flexer,
    Paragraph,
} from '@scouts/ui'

import { OffPlatformServicesRequestDTO } from '@/models'
import { useUpdateOffPlatformServicesRequestStatus } from '@/store/off-platform-services-requests'

type Props = { offPlatformServicesRequest: OffPlatformServicesRequestDTO }

const getStatusDropdownOptions = (status: OffPlatformServicesRequestDTO['status']): DropdownSelectOption[] => [
    {
        value: 'Started' as OffPlatformServicesRequestDTO['status'],
        title: 'Started' as OffPlatformServicesRequestDTO['status'],
        disabled: true,
    },
    {
        value: 'Submitted' as OffPlatformServicesRequestDTO['status'],
        title: 'Submitted' as OffPlatformServicesRequestDTO['status'],
        color: colors.blueLighter,
    },
    {
        value: 'Claimed' as OffPlatformServicesRequestDTO['status'],
        title: 'Claimed' as OffPlatformServicesRequestDTO['status'],
        color: colors.mintLighter,
        disabled: true,
    },
    {
        value: 'Cancelled' as OffPlatformServicesRequestDTO['status'],
        title: 'Cancelled' as OffPlatformServicesRequestDTO['status'],
        color: colors.red,
    },
    {
        value: 'Paid' as OffPlatformServicesRequestDTO['status'],
        title: 'Paid' as OffPlatformServicesRequestDTO['status'],
        color: colors.mintLighter,
        disabled: status !== 'Claimed',
    },
    {
        value: 'Referred' as OffPlatformServicesRequestDTO['status'],
        title: 'Referred' as OffPlatformServicesRequestDTO['status'],
        color: colors.mintLighter,
    },
]

export const OffPlatformServicesRequestStatus = ({ offPlatformServicesRequest }: Props) => {
    const { id, status, accountantId } = offPlatformServicesRequest

    const hasAccountantAssigned = Boolean(accountantId)

    const { updateStatus, isLoading: isStatusUpdateLoading } = useUpdateOffPlatformServicesRequestStatus(id)

    const [updatedStatus, setUpdatedStatus] = useState<OffPlatformServicesRequestDTO['status'] | undefined>()

    const [isStatusConfirmationModalOpen, openStatusConfirmationModal, closeStatusConfirmationModal] = useToggle()

    const handleUpdateStatus = () => {
        if (updatedStatus) {
            updateStatus(updatedStatus).then(closeStatusConfirmationModal)
        }
    }

    const askStatusConfirmation: DropdownSelectProps['onChange'] = ({ value }) => {
        const hasStatusChanged = value !== status
        if (isStatusUpdateLoading || !hasStatusChanged) {
            return
        }

        setUpdatedStatus(value as OffPlatformServicesRequestDTO['status'])
        openStatusConfirmationModal()
    }

    return (
        <Flexer gap="2px">
            <DropdownSelect
                isSmall
                name="status"
                value={status}
                options={getStatusDropdownOptions(status)}
                onChange={askStatusConfirmation}
            />

            {isStatusConfirmationModalOpen && (
                <ConfirmationModal
                    isLoading={isStatusUpdateLoading}
                    onCancel={closeStatusConfirmationModal}
                    onConfirm={handleUpdateStatus}
                >
                    <Paragraph>Are you sure you want to change the status to {updatedStatus}?</Paragraph>

                    {updatedStatus === 'Submitted' && hasAccountantAssigned && (
                        <Paragraph>Setting the status to Submitted will also unassign the accountant</Paragraph>
                    )}
                </ConfirmationModal>
            )}
        </Flexer>
    )
}
