import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Line, Spacer } from '@scouts/ui'

import { requestCustomersLastYearStatistics } from '@/actions'
import {
    CUSTOMER_VIEW_COMPLETED,
    CUSTOMER_VIEW_DRAFTED,
    CUSTOMER_VIEW_IN_PROGRESS,
    CUSTOMER_VIEW_NOT_STARTED,
    CUSTOMER_VIEW_TOTAL,
    PATH_RETURNING_CUSTOMERS,
} from '@/constants'
import { AccountantDetailsDTO } from '@/models'
import { getFullStatistics } from '@/selectors'
import { useAppDispatch } from '@/store'

import { Card } from './Card'

export const DashboardReturningCustomers = ({ accountantId }: { accountantId: AccountantDetailsDTO['id'] }) => {
    const dispatch = useAppDispatch()
    const history = useHistory()

    const statistics = useSelector(getFullStatistics)

    useEffect(() => {
        dispatch(requestCustomersLastYearStatistics({ accountantId }))
    }, [accountantId, dispatch])

    const {
        totalLastYearClients,
        totalNotInProgress,
        totalDrafted,
        taxYear,
        totalInProgress,
        totalCompleted,
        totalCompletedPercentage,
    } = statistics

    const shouldShowDraftedCustomers = totalDrafted > 0
    const shouldShowReturningCustomersSection = !!taxYear

    if (!shouldShowReturningCustomersSection) return null

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Returning customers
                    </Line>

                    <ButtonLink
                        onClick={() => history.push(`${PATH_RETURNING_CUSTOMERS}?accountantId=${accountantId}`)}
                        color={colors.neutralDarker}
                    >
                        View all
                    </ButtonLink>
                </Flexer>
            </Spacer>

            <Flexer column gap="12px">
                <Card
                    linkTo={`${PATH_RETURNING_CUSTOMERS}?accountantId=${accountantId}&view=${CUSTOMER_VIEW_TOTAL}`}
                    title="Total last year"
                    value={totalLastYearClients}
                />

                <Card
                    linkTo={`${PATH_RETURNING_CUSTOMERS}?accountantId=${accountantId}&view=${CUSTOMER_VIEW_NOT_STARTED}`}
                    title={`${taxYear} not started`}
                    value={totalNotInProgress}
                />

                {shouldShowDraftedCustomers && (
                    <Card
                        linkTo={`${PATH_RETURNING_CUSTOMERS}?accountantId=${accountantId}&view=${CUSTOMER_VIEW_DRAFTED}`}
                        title="Drafted"
                        value={totalDrafted}
                    />
                )}

                <Card
                    linkTo={`${PATH_RETURNING_CUSTOMERS}?accountantId=${accountantId}&view=${CUSTOMER_VIEW_IN_PROGRESS}`}
                    title={`${taxYear} in progress`}
                    value={totalInProgress}
                />

                <Card
                    linkTo={`${PATH_RETURNING_CUSTOMERS}?accountantId=${accountantId}&view=${CUSTOMER_VIEW_COMPLETED}`}
                    background={colors.mintLighter}
                    title={`${taxYear} completed`}
                    value={totalCompleted}
                    valueSecondary={
                        totalCompletedPercentage > 0 ? `${Math.round(totalCompletedPercentage)}%` : undefined
                    }
                />
            </Flexer>
        </Container>
    )
}
