import { useHistory, useRouteMatch, NavLink } from 'react-router-dom'

import {
    useAccountantPortalChrome,
    useIsDesktop,
    useIsDesktopLarge,
    useIsTablet,
    AccountantPortalChrome,
    IconGrid05,
} from '@scouts/ui'

import { PATH_CUSTOMER, PATH_ROOT } from '@/constants'
import { getAccountantImageUrl } from '@/helpers'

import { useMainMenuItems } from '@/hooks/use-main-menu-items'
import { useMainMenuSecondaryItems } from '@/hooks/use-main-menu-secondary-items'
import { useSidebarMenuItems } from '@/hooks/use-sidebar-menu-items'
import { useUser } from '@/hooks/use-user'
import { useUserMenuItems } from '@/hooks/use-user-menu-items'

import { MfaEnforcer } from './auth/MfaEnforcer'
import QuickSearch from './header/QuickSearch'
import Routes from './Routes'

const Main = () => {
    const { isCustomerSidebarVisible, setIsCustomerSidebarVisible, visibleMainMenuItemsCount } =
        useAccountantPortalChrome()
    const isTablet = useIsTablet()
    const isDesktop = useIsDesktop()
    const isDesktopLarge = useIsDesktopLarge()
    const { push } = useHistory()
    const { id, firstName, imageFileId } = useUser()

    const mainMenuItems = useMainMenuItems()
    const mainMenuSecondaryItems = useMainMenuSecondaryItems()

    const sidebarMenuItems = useSidebarMenuItems()
    const userMenuItems = useUserMenuItems()

    const imageUrl = imageFileId !== 0 ? getAccountantImageUrl(id) : ''

    const isCustomerRoute = useRouteMatch({ path: PATH_CUSTOMER })

    const visibleMainMenuItems = mainMenuItems.slice(0, visibleMainMenuItemsCount)
    const visibleSecondaryMainMenuItems = mainMenuSecondaryItems.slice(visibleMainMenuItemsCount, mainMenuItems.length)

    return (
        <AccountantPortalChrome.Container>
            <AccountantPortalChrome.Top>
                <AccountantPortalChrome.Logo onClick={() => push(PATH_ROOT)} />
                <AccountantPortalChrome.Header>
                    {isTablet && (
                        <AccountantPortalChrome.HeaderGroup isDesktopOnly>
                            <AccountantPortalChrome.MainMenu items={visibleMainMenuItems} itemsAs={NavLink} />
                            {visibleSecondaryMainMenuItems.length > 0 && (
                                <AccountantPortalChrome.MainMenuSecondary items={visibleSecondaryMainMenuItems} />
                            )}
                        </AccountantPortalChrome.HeaderGroup>
                    )}
                    <AccountantPortalChrome.HeaderGroup>
                        <QuickSearch />
                        <AccountantPortalChrome.UserMenu
                            items={userMenuItems}
                            buttonLabel={isDesktop ? firstName : ''}
                            imageUrl={imageUrl}
                        />
                    </AccountantPortalChrome.HeaderGroup>
                </AccountantPortalChrome.Header>
            </AccountantPortalChrome.Top>
            <AccountantPortalChrome.Main>
                <AccountantPortalChrome.Sidebar>
                    <AccountantPortalChrome.SidebarContent>
                        {!isDesktopLarge && isCustomerRoute && (
                            <AccountantPortalChrome.SidebarGroup>
                                <AccountantPortalChrome.SidebarMenuAction
                                    onClick={() => setIsCustomerSidebarVisible(!isCustomerSidebarVisible)}
                                    IconComponent={IconGrid05}
                                    title="Toggle customer sidebar"
                                    isActive={isCustomerSidebarVisible}
                                />
                            </AccountantPortalChrome.SidebarGroup>
                        )}
                        <AccountantPortalChrome.SidebarGroup>
                            <AccountantPortalChrome.SidebarMenu items={sidebarMenuItems} itemsAs={NavLink} />
                        </AccountantPortalChrome.SidebarGroup>
                    </AccountantPortalChrome.SidebarContent>
                </AccountantPortalChrome.Sidebar>
                <AccountantPortalChrome.Content>
                    <MfaEnforcer>
                        <Routes />
                    </MfaEnforcer>
                </AccountantPortalChrome.Content>
            </AccountantPortalChrome.Main>
        </AccountantPortalChrome.Container>
    )
}

export default Main
