import { formatCurrency, formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const InvestmentSchemes = ({
    investmentSchemes,
}: {
    investmentSchemes: SelfAssessmentDTO['investmentSchemes']
}) => {
    if (!investmentSchemes) return null

    const {
        additionalNotes,
        amountInvestedEIS,
        amountInvestedSEIS,
        amountInvestedSITR,
        amountInvestedVCT,
        crowdcube,
        crowdfundingPlatform,
        gainAmount,
        hasEIS,
        hasSEIS,
        hasSITR,
        hasVCT,
        lossAmount,
        madeLossOnSale,
        otherCrowdfunding,
        seedrs,
        soldInvestments,
    } = investmentSchemes

    return (
        <>
            <SectionData>
                <SectionDataRow label="Has EIS" value={hasEIS} boolean />
                {hasEIS && <SectionDataRow label="EIS amount" value={formatCurrencyNotSure(amountInvestedEIS)} />}
                <SectionDataRow label="Has SEIS" value={hasSEIS} boolean />
                {hasSEIS && <SectionDataRow label="SEIS amount" value={formatCurrencyNotSure(amountInvestedSEIS)} />}
                <SectionDataRow label="Has SITR" value={hasSITR} boolean />
                {hasSITR && <SectionDataRow label="SITR amount" value={formatCurrencyNotSure(amountInvestedSITR)} />}
                <SectionDataRow label="Has VCT" value={hasVCT} boolean />
                {hasVCT && <SectionDataRow label="VCT amount" value={formatCurrencyNotSure(amountInvestedVCT)} />}

                <SectionDataRow label="Used crowdfunding platform" value={crowdfundingPlatform} boolean />
                {crowdfundingPlatform && (
                    <>
                        <SectionDataRow label="Used Crowdcube" value={crowdcube} boolean />
                        <SectionDataRow label="Used Seedrs" value={seedrs} boolean />
                        <SectionDataRow label="Other crowdfunding " value={otherCrowdfunding} boolean />
                    </>
                )}
                <SectionDataRow label="Sold investments" value={soldInvestments} boolean />
                {soldInvestments && (
                    <>
                        <SectionDataRow label="Made loss on sale" value={madeLossOnSale} boolean />
                        {madeLossOnSale ? (
                            <SectionDataRow label="Loss amount" value={formatCurrency(lossAmount)} />
                        ) : (
                            <SectionDataRow label="Gain amount" value={formatCurrency(gainAmount)} />
                        )}
                    </>
                )}
                {additionalNotes && <SectionDataRow label="Notes" value={additionalNotes} />}
            </SectionData>
        </>
    )
}
