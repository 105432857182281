import { useCustomerIdFromPath } from '../../hooks/use-customer-id-from-path'

import { BookkeepingConfigurationProvider } from '../bookkeeping-configuration'
import { Customer } from './Customer'
import { CustomerLoaderBookkeeping } from './CustomerBookkeepingLoader'
import { CustomerLoader } from './CustomerLoader'

export const CustomerContainer = () => {
    const customerId = useCustomerIdFromPath()
    return (
        <BookkeepingConfigurationProvider>
            <CustomerLoader customerId={customerId}>
                <CustomerLoaderBookkeeping>
                    <Customer />
                </CustomerLoaderBookkeeping>
            </CustomerLoader>
        </BookkeepingConfigurationProvider>
    )
}
