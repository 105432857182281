import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { formatCurrency, CurrencyCode, Locale } from '@scouts/helpers'
import { font, Container, Flexer, Line, Spacer } from '@scouts/ui'

import { requestRevenueStats } from '@/actions'
import { PATH_SELF_ASSESSMENTS } from '@/constants'
import { AccountantDetailsDTO } from '@/models'
import { getFullStatistics } from '@/selectors'
import { useAppDispatch } from '@/store'

import { Card } from './Card'

export const DashboardEarnings = ({ accountantId }: { accountantId: AccountantDetailsDTO['id'] }) => {
    const dispatch = useAppDispatch()
    const statistics = useSelector(getFullStatistics)

    useEffect(() => {
        dispatch(requestRevenueStats({ accountantId }))
    }, [accountantId, dispatch])

    const {
        thisMonthsEarnings,
        thisMonthsFiledReturns,
        lastMonthsEarnings,
        lastMonthsFiledReturns,
        inProcessEarnings,
        inProcessReturns,
    } = statistics

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Earnings
                    </Line>
                </Flexer>
            </Spacer>

            <Flexer column gap="12px">
                <Card
                    linkTo={PATH_SELF_ASSESSMENTS}
                    title="This month"
                    value={formatCurrency(thisMonthsEarnings, { currency: CurrencyCode.GBP, locale: Locale.GB })}
                    valueSecondary={thisMonthsFiledReturns}
                />
                <Card
                    linkTo={PATH_SELF_ASSESSMENTS}
                    title="Last month"
                    value={formatCurrency(lastMonthsEarnings, { currency: CurrencyCode.GBP, locale: Locale.GB })}
                    valueSecondary={lastMonthsFiledReturns}
                />
                <Card
                    linkTo={PATH_SELF_ASSESSMENTS}
                    title="In progress"
                    value={formatCurrency(inProcessEarnings, { currency: CurrencyCode.GBP, locale: Locale.GB })}
                    valueSecondary={inProcessReturns}
                />
            </Flexer>
        </Container>
    )
}
