import { useCallback } from 'react'

import { useLogout } from '@scouts/auth'

export const useResetSessionAndLogout = () => {
    const { logout, isSuccess } = useLogout()

    const resetSessionAndLogout = useCallback(() => {
        logout()
    }, [logout])

    return { resetSessionAndLogout, isSuccess }
}
