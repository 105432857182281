import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { matchSuccessResponse } from '@scouts/helpers'
import {
    Button,
    Form,
    InputOnChangeParams,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { PATH_TEMPLATES } from '@/constants'
import { MessageTemplateType } from '@/models/message-template'
import { useCreateMessageTemplate } from '@/store/message-templates'

import { useUser } from '@/hooks/use-user'

import { MessageTemplateForm } from './MessageTemplateForm'

export const MessageTemplateCreateModal = () => {
    const user = useUser()
    const { createMessageTemplate, isLoading: isSubmitting } = useCreateMessageTemplate()
    const history = useHistory()

    const [state, setState] = useState({
        templateContent: '',
        templateName: '',
        templateType: MessageTemplateType.Private,
    })

    const handleCancel = () => {
        const { templateName, templateContent } = state
        const hasUnsavedChanges = templateName.trim() !== '' || templateContent.trim() !== ''
        if (hasUnsavedChanges) {
            const hasConfirmed = window.confirm('You have unsaved changes. Are you sure you want to go back?')
            if (!hasConfirmed) return
        }

        goToTemplatesView()
    }

    const handleChange = ({ name, value }: InputOnChangeParams) => {
        setState((current) => ({ ...current, [name]: value }))
    }

    const handleSubmit = () => {
        // check if template with same name exists
        const { templateName, templateContent, templateType } = state
        const newTemplate = { name: templateName, content: templateContent, type: templateType }

        createMessageTemplate({ ...newTemplate, accountantId: user.id }).then(matchSuccessResponse(goToTemplatesView))
    }

    const goToTemplatesView = () => {
        history.replace({ pathname: PATH_TEMPLATES, search: `type=${state.templateType}` })
    }

    const { templateName, templateContent, templateType } = state

    return (
        <Modal isWide onClose={handleCancel}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Add new template</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <MessageTemplateForm
                        templateContent={templateContent}
                        templateName={templateName}
                        templateType={templateType}
                        handleChange={handleChange}
                    />
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                            Add
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
