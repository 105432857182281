import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { radius, ConfirmationModal, Spacer } from '@scouts/ui'

export const SelfAssessmentUploadsConfirmationModal = ({
    file,
    onClose,
    onConfirm,
}: {
    file: File
    onClose: () => void
    onConfirm: () => void
}) => {
    const [url, setUrl] = useState<string | null>(null)

    useEffect(() => {
        const fileUrl = URL.createObjectURL(file)
        setUrl(fileUrl)
        return () => {
            URL.revokeObjectURL(fileUrl)
        }
    }, [file])

    return (
        <ConfirmationModal
            onConfirm={onConfirm}
            onCancel={onClose}
            labelConfirm="Upload"
            title="Are you sure you want to upload this file?"
            isFullScreen
        >
            <Spacer marginBottom="18px">{file.name}</Spacer>
            {url && <FilePreview src={url} />}
        </ConfirmationModal>
    )
}

const FilePreview = styled.iframe`
    width: 100%;
    min-height: 600px;
    border: 0;
    border-radius: ${radius.large};
`
