import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { ErrorHandlerOptions } from './types'

type ResponseTransformerReturnType = FetchBaseQueryError & { errorHandlerOptions: ErrorHandlerOptions }
type ResponseTransformer = (error: FetchBaseQueryError) => ResponseTransformerReturnType

export const ResponseTransformers = {
    /** Extend error response with meta, providing errorHandling middleware with a custom error message */
    customMessage:
        (customMessage: string): ResponseTransformer =>
        (error) => {
            return { ...error, errorHandlerOptions: { customMessage } }
        },
    /** Extend error response with meta, telling errorHandling middleware to not render a toast */
    hideAlert: (): ResponseTransformer => (error) => {
        return { ...error, errorHandlerOptions: { hideAlert: true } }
    },
}
