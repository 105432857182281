export const ADD_PRODUCT_BUNDLE_REFUND = 'ADD_PRODUCT_BUNDLE_REFUND'
export const RECEIVE_CUSTOMER_PRODUCT_BUNDLES = 'RECEIVE_CUSTOMER_PRODUCT_BUNDLES'
export const REQUEST_CUSTOMER_PRODUCT_BUNDLES = 'REQUEST_CUSTOMER_PRODUCT_BUNDLES'
export const UPDATE_PRODUCT_BUNDLE = 'UPDATE_PRODUCT_BUNDLE'
export const DELETE_PRODUCT_BUNDLE = 'DELETE_PRODUCT_BUNDLE'
export const REMOVE_PRODUCT_BUNDLE = 'REMOVE_PRODUCT_BUNDLE'
export const SAVE_PRODUCT_BUNDLE = 'SAVE_PRODUCT_BUNDLE'

export const requestCustomerProductBundles = (data) => ({
    type: REQUEST_CUSTOMER_PRODUCT_BUNDLES,
    data,
})

export const receiveCustomerProductBundles = (data) => ({ type: RECEIVE_CUSTOMER_PRODUCT_BUNDLES, data })

export const updateProductBundle = (data) => ({ type: UPDATE_PRODUCT_BUNDLE, data })

export const addProductBundleRefund = (data) => ({ type: ADD_PRODUCT_BUNDLE_REFUND, data })

export const deleteProductBundle = (data, meta = {}) => ({ type: DELETE_PRODUCT_BUNDLE, data, meta })

export const removeProductBundle = (data) => ({ type: REMOVE_PRODUCT_BUNDLE, data })

export const saveProductBundle = (productBundleId, data = {}, meta = {}) => ({
    type: SAVE_PRODUCT_BUNDLE,
    productBundleId,
    data,
    meta,
})
