import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import {
    receiveCustomerDiscounts,
    receiveSuccessAlerts,
    removeCustomerDiscount,
    CREATE_CUSTOMER_DISCOUNT,
    DELETE_CUSTOMER_DISCOUNT,
    REQUEST_CUSTOMER_DISCOUNTS,
} from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* customerDiscountsSagas() {
    yield takeLatest(CREATE_CUSTOMER_DISCOUNT, createCustomerDiscount)
    yield takeLatest(REQUEST_CUSTOMER_DISCOUNTS, fetchCustomerDiscounts)
    yield takeLatest(DELETE_CUSTOMER_DISCOUNT, deleteCustomerDiscount)
}

function* fetchCustomerDiscounts({ data }) {
    const customerId = _get(data, ['customerId'])
    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${customerId}/discounts`)
        yield put(receiveCustomerDiscounts(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* createCustomerDiscount({ data }) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`customerdiscounts`, 'post', data)
        yield put(receiveCustomerDiscounts([response.data]))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* deleteCustomerDiscount({ data: id }) {
    try {
        yield put(showLoading())
        yield apiCall(`customerdiscounts/${id}`, 'delete')
        yield put(removeCustomerDiscount(id))
        yield put(receiveSuccessAlerts('Discount code removed'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
