import { useCallback } from 'react'

import { OffPlatformServicesRequestDTO } from '@/models'

import { useUpdateOffPlatformServicesRequestAccountantMutation } from '../api'

export const useUpdateOffPlatformServicesRequestAccountant = (id: OffPlatformServicesRequestDTO['id']) => {
    const [action, { isLoading: isLoading }] = useUpdateOffPlatformServicesRequestAccountantMutation()

    const handleUpdateAccountant = useCallback(
        (
            updatedAccountantId: OffPlatformServicesRequestDTO['accountantId'],
            status: OffPlatformServicesRequestDTO['status']
        ) => action({ id, accountantId: updatedAccountantId, status }),
        [action, id]
    )

    return {
        handleUpdateAccountant,
        isLoading,
    }
}
