import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
    colors,
    Button,
    ButtonLink,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Spinner,
} from '@scouts/ui'

import { requestCustomerNotes } from '../../actions'
import { isFetchingNotes, shouldFetchCustomerNotes } from '../../selectors'
import CustomerNotesCreate from './CustomerNotesCreate'
import CustomerNotesList from './CustomerNotesList'

const ModalContentWrapper = styled.div`
    background: ${colors.redLighter};
`

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 18px;
`
const FlexSpacer = styled.div`
    flex: 1;
`

class CustomerNotesListModal extends PureComponent {
    componentDidMount() {
        const { customerId, dispatch, shouldFetch } = this.props
        if (shouldFetch) {
            dispatch(requestCustomerNotes(customerId))
        }
    }

    render() {
        const { onNotesUpdate, onCloseModal, customerFullName, customerId, isFetching } = this.props

        return (
            <Modal onClose={onCloseModal}>
                <ModalContentWrapper>
                    <ModalHeader>
                        <ModalActions>
                            <ModalTitle>Notes for {customerFullName}</ModalTitle>
                            <FlexSpacer />
                            <CustomerNotesCreate customerId={customerId} onNoteCreate={onNotesUpdate}>
                                <ButtonLink icon="plus">New note</ButtonLink>
                            </CustomerNotesCreate>
                        </ModalActions>
                    </ModalHeader>

                    {isFetching ? (
                        <SpinnerContainer>
                            <Spinner isSmall color={colors.black} />
                        </SpinnerContainer>
                    ) : (
                        <>
                            <ModalBody>
                                <CustomerNotesList customerId={customerId} onNotesUpdate={onNotesUpdate} />
                            </ModalBody>

                            <ModalFooter>
                                <ModalActions>
                                    <Button type="submit" onClick={onCloseModal}>
                                        Close
                                    </Button>
                                </ModalActions>
                            </ModalFooter>
                        </>
                    )}
                </ModalContentWrapper>
            </Modal>
        )
    }
}

CustomerNotesListModal.propTypes = {
    customerFullName: PropTypes.string.isRequired,
    customerId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onNotesUpdate: PropTypes.func.isRequired,
    shouldFetch: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
    isFetching: isFetchingNotes(state),
    shouldFetch: shouldFetchCustomerNotes(state, ownProps),
})

export default connect(mapStateToProps)(CustomerNotesListModal)
