import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { matchErrorResponse, matchSuccessResponse } from '@scouts/helpers'
import { addErrorToast, addSuccessToast, colors, ActionButton, Loadable, Page } from '@scouts/ui'

import { requestUser } from '@/actions'
import config from '@/config'
import { PATH_INTEGRATIONS_NYLAS } from '@/constants'
import { useCreateNylasSchedulingPage } from '@/store/nylas'

const ERROR_MESSAGE_HAS_NYLAS_SCHEDULE = 'You already have a schedule set up'
const ERROR_MESSAGE_CREATE_SCHEDULE_FAILED = 'An error occurred when creating your schedule'

const IntegrationsNylasCreateSchedule = () => {
    const dispatch = useDispatch()
    const { createNylasSchedulingPage } = useCreateNylasSchedulingPage()
    const history = useHistory()

    const [errorMessage, setErrorMessage] = useState<string>()

    const goToIntegrationsNylas = useCallback(() => {
        dispatch(requestUser())
        history.replace(PATH_INTEGRATIONS_NYLAS)
    }, [dispatch, history])

    useEffect(() => {
        createNylasSchedulingPage({ redirectUrl: config.callbackUriNylasBookingSuccess })
            .then(
                matchErrorResponse(() => {
                    const responseErrorMessage = ERROR_MESSAGE_CREATE_SCHEDULE_FAILED
                    setErrorMessage(responseErrorMessage)
                    addErrorToast({ body: responseErrorMessage })
                })
            )
            .then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Schedule created' })
                    goToIntegrationsNylas()
                })
            )
    }, [dispatch, history, goToIntegrationsNylas, createNylasSchedulingPage])

    useEffect(() => {
        if (errorMessage && errorMessage === ERROR_MESSAGE_HAS_NYLAS_SCHEDULE) {
            addErrorToast({ body: ERROR_MESSAGE_HAS_NYLAS_SCHEDULE })
            goToIntegrationsNylas()
        }
    }, [dispatch, errorMessage, goToIntegrationsNylas])

    if (errorMessage) {
        return (
            <Page>
                <Page.Heading>Failed to create schedule</Page.Heading>
                <Page.Section>
                    <p>{errorMessage}</p>
                </Page.Section>
                <Page.Actions background={colors.redLighter}>
                    <ActionButton onClick={goToIntegrationsNylas}>Back</ActionButton>
                </Page.Actions>
            </Page>
        )
    }

    return (
        <Page>
            <Page.Heading>Integrating your calendar…</Page.Heading>
            <Page.Section>
                <Loadable isLoading />
            </Page.Section>
        </Page>
    )
}

export default IntegrationsNylasCreateSchedule
