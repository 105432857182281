import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Label, Table } from '@scouts/ui'

import { PATH_USERS } from '@/constants'
import { getAccountantImageUrl } from '@/helpers'
import { AccountantDetailsDTO } from '@/models'

export const UsersTableRow = ({ user }: { user: AccountantDetailsDTO }) => {
    const { email, firstName, id, imageFileId, isBlocked, lastName } = user

    const editUserUrl = `${PATH_USERS}/${id}`

    const imageSrc = imageFileId !== 0 ? getAccountantImageUrl(id) : ''

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="66px">
                <Table.Body.Cell.ContentLink Link={Link} to={editUserUrl}>
                    <ImageContainer>{imageSrc && <Image src={imageSrc} alt="" />}</ImageContainer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(75% - 66px)">
                <Table.Body.Cell.ContentLink Link={Link} to={editUserUrl}>
                    <UserInfo>
                        <FullName>
                            {firstName} {lastName}
                        </FullName>
                        <Email>{email}</Email>
                    </UserInfo>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="25%">
                {isBlocked && (
                    <Table.Body.Cell.ContentLink Link={Link} to={editUserUrl} alignRight>
                        <Label color={colors.black} background={colors.red}>
                            Blocked
                        </Label>
                    </Table.Body.Cell.ContentLink>
                )}
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const ImageContainer = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 9999px;
    background: ${colors.neutralLightest};
    border: 1px solid ${colors.neutralLightest};
`

const Image = styled.img`
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 9999px;
`

const UserInfo = styled.div``

const FullName = styled.div`
    font-weight: ${font.weight.medium};
`

const Email = styled.div`
    margin-top: 6px;
`
