import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import { receiveIssues, ADD_ISSUE, REQUEST_CUSTOMER_ISSUES, RESOLVE_ISSUE, UNRESOLVE_ISSUE } from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* issuesSagas() {
    yield takeEvery(ADD_ISSUE, addIssue)
    yield takeEvery(RESOLVE_ISSUE, resolveIssue)
    yield takeEvery(UNRESOLVE_ISSUE, unresolveIssue)
    yield takeLatest(REQUEST_CUSTOMER_ISSUES, fetchCustomerIssues)
}

function* fetchCustomerIssues(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${action.data.customerId}/issues`)
        yield put(receiveIssues(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* resolveIssue(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`issues/${action.data.issueId}/resolve`, 'put')
        yield put(receiveIssues([response.data]))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* unresolveIssue(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`issues/${action.data.issueId}/unresolve`, 'put')
        yield put(receiveIssues([response.data]))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* addIssue(action) {
    const meta = { onSuccess: null, ...(action?.meta || {}) }
    const { onSuccess } = meta
    try {
        yield put(showLoading())
        const response = yield apiCall('issues', 'post', action.data)
        yield put(receiveIssues([response.data]))

        if (onSuccess) {
            onSuccess()
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
