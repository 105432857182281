import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownInputs } from '../ui/filter'

const SearchFilterCustomerName = (props) => {
    const { customerFirstName, customerLastName, applyFilter, removeFilter, isOpenByDefault } = props

    const hasValues = customerFirstName.length > 0 || customerLastName.length > 0

    const activeValues = [hasValues ? [customerFirstName, customerLastName].join(' ') : 'Any']

    const values = [
        { name: 'customerFirstName', value: customerFirstName, label: 'First name' },
        { name: 'customerLastName', value: customerLastName, label: 'Last name' },
    ]

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Client name"
            activeValues={activeValues}
            removeFilter={() => removeFilter(values.map(({ name }) => name))}
            isNeutral={!hasValues}
        >
            <FilterItemDropdownInputs
                applyFilter={applyFilter}
                values={values}
                removeFilter={() => removeFilter(values.map(({ name }) => name))}
            />
        </FilterItem>
    )
}

SearchFilterCustomerName.propTypes = {
    customerFirstName: PropTypes.string.isRequired,
    customerLastName: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterCustomerName
