import { useHistory, Route } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonLink, Loadable, Page } from '@scouts/ui'

import { PATH_TEMPLATES_TEMPLATE, PATH_TEMPLATES_TEMPLATE_CREATE } from '@/constants'
import { isAdmin } from '@/helpers'
import { MessageTemplateType, MessageTemplateTypeTypes } from '@/models/message-template'
import { useMessageTemplates } from '@/store/message-templates'

import { useUser } from '@/hooks/use-user'

import ProfilePageWithSidebar from '../../ProfilePageWithSidebar'
import { MessageTemplateCreateModal } from './MessageTemplateCreateModal'
import { MessageTemplateEditModal } from './MessageTemplateEditModal'
import { MessageTemplateList } from './MessageTemplateList'
import { MessageTemplateTabs } from './MessageTemplateTabs'
import { MessageTemplateViewModal } from './MessageTemplateViewModal'

export const MessageTemplates = () => {
    const history = useHistory<{ isFromCreateNewOption?: boolean }>()
    const hasBackButton = history.location.state?.isFromCreateNewOption

    const { isLoading, getMessageTemplateById, messageTemplates, getMessageTemplatesSortedByUpdateTime } =
        useMessageTemplates()
    const user = useUser()

    const search = new URLSearchParams(history.location.search)
    const filteredMessageTemplates = getMessageTemplatesSortedByUpdateTime(
        (search.get('type') ?? MessageTemplateType.Private) as MessageTemplateTypeTypes
    )

    return (
        <ProfilePageWithSidebar>
            {hasBackButton && (
                <BackButton>
                    <ButtonLink onClick={() => history.goBack()}>Back</ButtonLink>
                </BackButton>
            )}

            <Header>
                <Page.Heading>Message templates</Page.Heading>
            </Header>

            <Page.Section>
                <MessageTemplateTabs messageTemplates={messageTemplates} />
            </Page.Section>

            <Loadable isLoading={isLoading}>
                <MessageTemplateList messageTemplates={filteredMessageTemplates} />
            </Loadable>

            <Route
                path={PATH_TEMPLATES_TEMPLATE}
                render={({ match }) => {
                    const template = getMessageTemplateById(Number(match.params.id))
                    if (!template) return null
                    const isTemplateEditAllowed = template.type === MessageTemplateType.Private || isAdmin(user)
                    return isTemplateEditAllowed ? (
                        <MessageTemplateEditModal template={template} />
                    ) : (
                        <MessageTemplateViewModal template={template} />
                    )
                }}
            />

            <Route path={PATH_TEMPLATES_TEMPLATE_CREATE}>
                <MessageTemplateCreateModal />
            </Route>
        </ProfilePageWithSidebar>
    )
}

const BackButton = styled.div`
    flex: 0 0 100%;
    display: block;
    padding-bottom: 18px;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`
