import { TaxReliefClaimDTO } from '@/models'

import { useGetTaxReliefClaimQuery } from '../api'

interface UseTaxReliefClaimProps {
    taxReliefClaimId: TaxReliefClaimDTO['id']
}

export const useTaxReliefClaim = ({ taxReliefClaimId }: UseTaxReliefClaimProps) => {
    const { data, isError, isLoading, isFetching } = useGetTaxReliefClaimQuery(
        { taxReliefClaimId },
        { refetchOnMountOrArgChange: 1 }
    )

    return {
        taxReliefClaim: data,
        isError,
        isLoading,
        isFetching,
    }
}
