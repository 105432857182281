import _every from 'lodash/every'
import _some from 'lodash/some'
import _sum from 'lodash/sum'
import _sumBy from 'lodash/sumBy'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Notice } from '@scouts/ui'

import {
    DATA_SOURCE_PORTIFY,
    DATA_SOURCE_UBER,
    FILING_AREA_TITLES,
    PATH_TOOLS_CGT,
    SECTION_PAYMENT_ON_ACCOUNT,
} from '@/constants'
import {
    formatCurrencyNotSure,
    hasAllowances,
    hasCapitalGainsAllowances,
    hasGeneralAllowances,
    hasSelfEmploymentAllowances,
} from '@/helpers'
import { FilingArea, SelfAssessmentDTO, SelfAssessmentFileDTO, SelfEmploymentCategory } from '@/models'

import { CapitalGains } from './sections/CapitalGains'
import { CapitalGainsAllowances } from './sections/CapitalGainsAllowances'
import { CharitableDonations } from './sections/CharitableDonations'
import { DividendsAndInterest } from './sections/DividendsAndInterest'
import { Employment } from './sections/Employment'
import { ForeignIncome } from './sections/ForeignIncome'
import { InvestmentSchemes } from './sections/InvestmentSchemes'
import { OtherIncome } from './sections/OtherIncome'
import { PaymentOnAccount } from './sections/PaymentOnAccount'
import { PensionsAndBenefits } from './sections/PensionsAndBenefits'
import { PensionsContributions } from './sections/PensionsContributions'
import { PropertyEarnings } from './sections/PropertyEarnings'
import { SelfEmployment } from './sections/SelfEmployment'
import { SelfEmploymentAllowances } from './sections/SelfEmploymentAllowances'
import { StudentLoans } from './sections/StudentLoans'
import { WorkFromHomeAllowance } from './sections/WorkFromHomeAllowance'
import { SelfAssessmentSection } from './SelfAssessmentSection'

const capitalGainsSummary = (capitalGains: SelfAssessmentDTO['capitalGains']) => {
    const types = []
    if (!capitalGains) {
        return '?'
    }

    const { propertyGains, shareGains, cryptoGains } = capitalGains

    if (propertyGains) {
        types.push('Property')
    }

    if (shareGains) {
        types.push('Shares')
    }

    if (cryptoGains) {
        types.push('Cryptocurrency')
    }

    if (!types.length) return ''

    return ` – ${types.join(', ')}`
}

const capitalGainsAllowancesSummary = (capitalGains: SelfAssessmentDTO['capitalGains']) => {
    const allowances = []
    const {
        propertyLosses,
        shareLosses,
        otherLosses,
        cryptoLosses,
        hasEmploymentOptionSchemeAllowance,
        hasHmrcInvestmentSchemeAllowance,
    } = capitalGains || {}

    if (propertyLosses || shareLosses || otherLosses || cryptoLosses) {
        allowances.push('Incurred a loss')
    }

    if (hasEmploymentOptionSchemeAllowance) {
        allowances.push('Options scheme')
    }

    if (hasHmrcInvestmentSchemeAllowance) {
        allowances.push('Investment scheme')
    }

    return allowances.join(', ')
}

const selfEmploymentAllowancesSummary = (selfEmployment: SelfAssessmentDTO['selfEmployment']) => {
    const allowances = []
    const { selfEmploymentCategory, lowExpensesUseTradingAllowance, approximateExpenses, businessVehicle } =
        selfEmployment || {}

    const isConstruction = selfEmploymentCategory === SelfEmploymentCategory.Construction

    if (isConstruction) {
        allowances.push('CIS payments')
    }

    if (lowExpensesUseTradingAllowance) {
        allowances.push('Use Trading Allowance')
        return allowances.join(', ')
    }

    if (approximateExpenses && approximateExpenses > 0) {
        allowances.push('Expenses')
    }
    if (businessVehicle) {
        allowances.push('Vehicle')
    }

    return allowances.join(', ')
}

const propertyIncomeSummary = (propertyIncome: SelfAssessmentDTO['propertyIncome']) => {
    const fields = []

    const {
        hasRentedRoom,
        homeRentalIncome,
        hasRentedProperty,
        propertyRentalIncome,
        hasHolidayLettings,
        holidayLettingsIncome,
    } = propertyIncome || {}

    if (propertyIncome) {
        if (hasRentedRoom && homeRentalIncome && homeRentalIncome > 0) {
            fields.push(propertyIncome.homeRentalIncome)
        }

        if (hasRentedProperty && propertyRentalIncome && propertyRentalIncome > 0) {
            fields.push(propertyIncome.propertyRentalIncome)
        }

        if (hasHolidayLettings && holidayLettingsIncome && holidayLettingsIncome > 0) {
            fields.push(propertyIncome.holidayLettingsIncome)
        }
    }

    return fields.length === 0 ? '?' : formatCurrencyNotSure(_sum(fields))
}

interface SelfAssessmentSectionsProps {
    selfAssessment: SelfAssessmentDTO
    selfAssessmentFiles: SelfAssessmentFileDTO[]
}

export const SelfAssessmentSections = ({ selfAssessment, selfAssessmentFiles }: SelfAssessmentSectionsProps) => {
    if (!selfAssessment) return null

    const {
        capitalGains,
        charitableDonations,
        dividendsAndInterest,
        employments,
        foreignIncomes,
        foreignIncomeSummary,
        hasCapitalGains,
        hasCharitableDonations,
        hasDividendsOrInterest,
        hasEmployment,
        hasForeignIncome,
        hasInvestmentSchemes,
        hasLastYearPaymentsOnAccount,
        hasOtherIncome,
        hasPensionsContributions,
        hasPensionsOrBenefits,
        hasPropertyEarnings,
        hasSelfEmployment,
        hasStudentLoans,
        hasWorkFromHomeAllowance,
        investmentSchemes,
        koinlyReportSummary,
        otherIncome,
        partnerIncomeUnderAllowanceLimit,
        pensionsAndBenefits,
        pensionsContributions,
        propertyIncome,
        selfEmployment,
        selfEmploymentProfile,
        studentLoan,
        uberData,
        userIncomePlatformIncomeReportsFinished,
        userIncomePlatformIncomeReportsInProgress,
        workFromHomeAllowance,
    } = selfAssessment

    const isEmploymentDataVerified = () => {
        const employments = selfAssessment.employments || []
        return (
            _every(employments, (o) => o.synchedFromHmrc && o.payAmountConfirmed) &&
            employments &&
            employments.length > 0
        )
    }

    const isSelfEmploymentDataVerified = () => {
        const hasUberData = selfAssessment.selfEmployment?.dataSource === DATA_SOURCE_UBER && selfAssessment.uberData
        const hasPortifyData =
            selfAssessment.selfEmployment?.dataSource === DATA_SOURCE_PORTIFY &&
            selfAssessment.selfEmployment?.incomeAmountConfirmed
        return !!hasUberData || !!hasPortifyData
    }

    const shouldDisplayPensionsOrBenefits =
        hasPensionsOrBenefits || typeof pensionsAndBenefits?.receivedChildBenefit === 'boolean'

    const shouldDisplayCGTTool =
        capitalGains?.shareGains || capitalGains?.shareLosses || capitalGains?.cryptoGains || capitalGains?.cryptoLosses

    return (
        <>
            <Subheading>Income</Subheading>

            {hasEmployment && (
                <SelfAssessmentSection
                    name={FilingArea.Employment}
                    title={FILING_AREA_TITLES[FilingArea.Employment]}
                    summary={`${
                        _some(employments, ({ approximateIncome }) => approximateIncome !== 0 && !approximateIncome)
                            ? '?'
                            : formatCurrencyNotSure(_sumBy(employments, 'approximateIncome'))
                    } – ${
                        employments && employments.length > 0
                            ? `${employments.length} employment${employments.length > 1 ? 's' : ''}`
                            : '?'
                    }  `}
                    selfAssessment={selfAssessment}
                    selfAssessmentFiles={selfAssessmentFiles}
                    filingArea={FilingArea.Employment}
                    isVerified={isEmploymentDataVerified()}
                >
                    <Employment employments={employments || []} />
                </SelfAssessmentSection>
            )}

            {hasSelfEmployment && selfEmployment && (
                <SelfAssessmentSection
                    name={FilingArea.SelfEmployment}
                    title={FILING_AREA_TITLES[FilingArea.SelfEmployment]}
                    summary={`${formatCurrencyNotSure(selfEmployment ? selfEmployment.approximateIncome : null)} – ${
                        selfEmployment && selfEmployment.selfEmploymentCategory
                            ? selfEmployment.selfEmploymentCategory
                            : '?'
                    }`}
                    selfAssessment={selfAssessment}
                    selfAssessmentFiles={selfAssessmentFiles}
                    filingArea={FilingArea.SelfEmployment}
                    isVerified={isSelfEmploymentDataVerified()}
                >
                    <SelfEmployment
                        selfEmployment={selfEmployment}
                        selfEmploymentProfile={selfEmploymentProfile}
                        uberData={uberData}
                        userIncomePlatformIncomeReportsFinished={userIncomePlatformIncomeReportsFinished}
                        userIncomePlatformIncomeReportsInProgress={userIncomePlatformIncomeReportsInProgress}
                    />
                </SelfAssessmentSection>
            )}

            {hasPropertyEarnings && (
                <SelfAssessmentSection
                    name={FilingArea.Property}
                    title={FILING_AREA_TITLES[FilingArea.Property]}
                    summary={propertyIncomeSummary(propertyIncome)}
                    selfAssessment={selfAssessment}
                    selfAssessmentFiles={selfAssessmentFiles}
                    filingArea={FilingArea.Property}
                >
                    <PropertyEarnings propertyIncome={propertyIncome} />
                </SelfAssessmentSection>
            )}

            {hasCapitalGains && (
                <>
                    <SelfAssessmentSection
                        name={FilingArea.CapitalGains}
                        title={FILING_AREA_TITLES[FilingArea.CapitalGains]}
                        summary={`${formatCurrencyNotSure(
                            capitalGains ? capitalGains.totalCapitalGainsAmount : null
                        )} ${capitalGainsSummary(capitalGains)}`}
                        selfAssessment={selfAssessment}
                        selfAssessmentFiles={selfAssessmentFiles}
                        filingArea={FilingArea.CapitalGains}
                    >
                        <CapitalGains capitalGains={capitalGains} koinlyReportSummary={koinlyReportSummary} />
                    </SelfAssessmentSection>

                    {shouldDisplayCGTTool && (
                        <Notice background={colors.white}>
                            To calculate Capital Gains from a summary of transactions try our{' '}
                            <Link to={PATH_TOOLS_CGT}>Capital Gains Calculator (beta)</Link>. Share your feedback in
                            Slack.
                        </Notice>
                    )}
                </>
            )}

            {hasDividendsOrInterest && (
                <SelfAssessmentSection
                    name={FilingArea.DividendsAndInterest}
                    title={FILING_AREA_TITLES[FilingArea.DividendsAndInterest]}
                    summary={formatCurrencyNotSure(
                        _sum([
                            dividendsAndInterest ? dividendsAndInterest.ukDividendAmount : 0,
                            dividendsAndInterest ? dividendsAndInterest.foreignDividendAmount : 0,
                            dividendsAndInterest ? dividendsAndInterest.taxedUkInterestAmount : 0,
                            dividendsAndInterest ? dividendsAndInterest.untaxedUkInterestAmount : 0,
                            dividendsAndInterest ? dividendsAndInterest.foreignInterestAmount : 0,
                        ]) || null
                    )}
                    selfAssessment={selfAssessment}
                    selfAssessmentFiles={selfAssessmentFiles}
                    filingArea={FilingArea.DividendsAndInterest}
                >
                    <DividendsAndInterest dividendsAndInterest={dividendsAndInterest} />
                </SelfAssessmentSection>
            )}

            {shouldDisplayPensionsOrBenefits && (
                <SelfAssessmentSection
                    name={FilingArea.PensionsAndBenefits}
                    title={FILING_AREA_TITLES[FilingArea.PensionsAndBenefits]}
                    summary={formatCurrencyNotSure(
                        _sum([
                            pensionsAndBenefits ? pensionsAndBenefits.statePensionLumpSumAmount : 0,
                            pensionsAndBenefits ? pensionsAndBenefits.statePensionRegularPaymentsAmount : 0,
                            pensionsAndBenefits ? pensionsAndBenefits.privatePensionAmount : 0,
                            pensionsAndBenefits ? pensionsAndBenefits.jobseekerAllowanceAmount : 0,
                            pensionsAndBenefits ? pensionsAndBenefits.incapacityBenefitAmount : 0,
                            pensionsAndBenefits ? pensionsAndBenefits.childBenefitAmount : 0,
                        ]) || null
                    )}
                    selfAssessment={selfAssessment}
                    selfAssessmentFiles={selfAssessmentFiles}
                    filingArea={FilingArea.PensionsAndBenefits}
                >
                    <PensionsAndBenefits pensionsAndBenefits={pensionsAndBenefits} />
                </SelfAssessmentSection>
            )}

            {hasForeignIncome && (
                <SelfAssessmentSection
                    name={FilingArea.ForeignIncome}
                    title={FILING_AREA_TITLES[FilingArea.ForeignIncome]}
                    summary={
                        _some(foreignIncomes, ({ incomeAmount }) => incomeAmount !== 0 && !incomeAmount)
                            ? '?'
                            : formatCurrencyNotSure(_sumBy(foreignIncomes, 'incomeAmount'))
                    }
                    selfAssessment={selfAssessment}
                    selfAssessmentFiles={selfAssessmentFiles}
                    filingArea={FilingArea.ForeignIncome}
                >
                    <ForeignIncome foreignIncomes={foreignIncomes} foreignIncomeSummary={foreignIncomeSummary} />
                </SelfAssessmentSection>
            )}

            {hasOtherIncome && (
                <SelfAssessmentSection
                    name={FilingArea.OtherIncome}
                    title={FILING_AREA_TITLES[FilingArea.OtherIncome]}
                    summary={formatCurrencyNotSure(otherIncome ? otherIncome.incomeAmount : 0)}
                    selfAssessment={selfAssessment}
                    selfAssessmentFiles={selfAssessmentFiles}
                    filingArea={FilingArea.OtherIncome}
                >
                    <OtherIncome otherIncome={otherIncome} />
                </SelfAssessmentSection>
            )}

            {hasAllowances(selfAssessment) && (
                <>
                    <Subheading>Allowances</Subheading>

                    {hasSelfEmploymentAllowances(selfAssessment) && (
                        <SelfAssessmentSection
                            name={`${FilingArea.SelfEmployment}Allowances`}
                            title={FILING_AREA_TITLES[FilingArea.SelfEmployment]}
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                            summary={selfEmploymentAllowancesSummary(selfEmployment)}
                        >
                            <SelfEmploymentAllowances
                                selfAssessment={selfAssessment}
                                selfEmploymentProfile={selfEmploymentProfile}
                            />
                        </SelfAssessmentSection>
                    )}

                    {hasCapitalGainsAllowances(selfAssessment) && (
                        <SelfAssessmentSection
                            name={`${FilingArea.CapitalGains}Allowances`}
                            title={FILING_AREA_TITLES[FilingArea.CapitalGains]}
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                            summary={capitalGainsAllowancesSummary(capitalGains)}
                        >
                            <CapitalGainsAllowances capitalGains={capitalGains} />
                        </SelfAssessmentSection>
                    )}
                </>
            )}

            {hasGeneralAllowances(selfAssessment) && (
                <>
                    <Subheading>General & Allowances</Subheading>

                    {hasLastYearPaymentsOnAccount && (
                        <SelfAssessmentSection
                            name="paymentOnAccount"
                            title="Payment on Account"
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                            sectionId={SECTION_PAYMENT_ON_ACCOUNT}
                        >
                            <PaymentOnAccount selfAssessment={selfAssessment} />
                        </SelfAssessmentSection>
                    )}

                    {hasCharitableDonations && (
                        <SelfAssessmentSection
                            name={FilingArea.CharitableGiving}
                            title={FILING_AREA_TITLES[FilingArea.CharitableGiving]}
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                            filingArea={FilingArea.CharitableGiving}
                        >
                            <CharitableDonations charitableDonations={charitableDonations} />
                        </SelfAssessmentSection>
                    )}

                    {hasInvestmentSchemes && (
                        <SelfAssessmentSection
                            name={FilingArea.InvestmentScheme}
                            title={FILING_AREA_TITLES[FilingArea.InvestmentScheme]}
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                            filingArea={FilingArea.InvestmentScheme}
                        >
                            <InvestmentSchemes investmentSchemes={investmentSchemes} />
                        </SelfAssessmentSection>
                    )}

                    {hasPensionsContributions && (
                        <SelfAssessmentSection
                            name={FilingArea.PensionsContributions}
                            title={FILING_AREA_TITLES[FilingArea.PensionsContributions]}
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                            filingArea={FilingArea.PensionsContributions}
                        >
                            <PensionsContributions pensionsContributions={pensionsContributions} />
                        </SelfAssessmentSection>
                    )}

                    {hasStudentLoans && (
                        <SelfAssessmentSection
                            name={FilingArea.StudentLoans}
                            title={FILING_AREA_TITLES[FilingArea.StudentLoans]}
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                            filingArea={FilingArea.StudentLoans}
                        >
                            <StudentLoans studentLoan={studentLoan} />
                        </SelfAssessmentSection>
                    )}

                    {partnerIncomeUnderAllowanceLimit && (
                        <SelfAssessmentSection
                            name="MarriedCouplesAllowance"
                            title="Married Couples Allowance"
                            summary="Partner Income under Limit"
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                        />
                    )}

                    {hasWorkFromHomeAllowance && (
                        <SelfAssessmentSection
                            name="WorkFromHomeAllowance"
                            title="Working from home"
                            selfAssessment={selfAssessment}
                            selfAssessmentFiles={selfAssessmentFiles}
                        >
                            <WorkFromHomeAllowance workFromHomeAllowance={workFromHomeAllowance} />
                        </SelfAssessmentSection>
                    )}
                </>
            )}
        </>
    )
}

const Subheading = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    line-height: 1.3;
    margin-bottom: 12px;

    &:not(:first-child) {
        margin-top: 24px;
    }
`
