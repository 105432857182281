import { useCustomer } from '@/hooks/use-customer'

import { useGetCustomersBankAccountQuery, GetCustomersBankAccountParams } from '../api'

export const useCustomersBankAccount = ({ customerId }: GetCustomersBankAccountParams) => {
    const { customer } = useCustomer(customerId)
    const { data, isError, isLoading } = useGetCustomersBankAccountQuery(
        { customerId },
        { skip: customer?.hasBankAccountDetails === false }
    )

    return {
        bankAccount: data,
        isError,
        isLoading,
    }
}
