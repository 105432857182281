import { generateApiPath } from '@scouts/helpers'

import { AccountantDetailsDTO, AccountantReviewDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

export interface GetAccountantReviewsParams {
    accountantId: AccountantDetailsDTO['id']
}

export interface CreateAccountantReviewParams {
    data: Omit<AccountantReviewDTO, 'id'>
}

export interface UpdateAccountantReviewParams {
    reviewId: AccountantReviewDTO['id']
    data: AccountantReviewDTO
}

export interface DeleteAccountantReviewParams {
    reviewId: AccountantReviewDTO['id']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAccountantReviews: builder.query<AccountantReviewDTO[], GetAccountantReviewsParams>({
            query: ({ accountantId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_REVIEWS.GET, { accountantId }),
            }),
            providesTags: [Tags.AccountantReviews],
        }),
        createAccountantReview: builder.mutation<AccountantReviewDTO, CreateAccountantReviewParams>({
            query: ({ data }) => ({
                method: 'POST',
                url: ENDPOINTS.ACCOUNTANT_REVIEWS.POST,
                body: data,
            }),
            invalidatesTags: [Tags.AccountantReviews],
        }),
        updateAccountantReview: builder.mutation<AccountantReviewDTO, UpdateAccountantReviewParams>({
            query: ({ reviewId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_REVIEWS.PUT, { reviewId }),
                body: data,
            }),
            invalidatesTags: [Tags.AccountantReviews],
        }),
        deleteAccountantReview: builder.mutation<void, DeleteAccountantReviewParams>({
            query: ({ reviewId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.ACCOUNTANT_REVIEWS.DELETE, { reviewId }),
            }),
            invalidatesTags: [Tags.AccountantReviews],
        }),
    }),
})

export const {
    useGetAccountantReviewsQuery,
    useCreateAccountantReviewMutation,
    useUpdateAccountantReviewMutation,
    useDeleteAccountantReviewMutation,
} = extendedApi
