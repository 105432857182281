import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'

import { requestCustomer } from '@/actions'
import { useAppDispatch } from '@/store/store'

import { useUpdateTaxRegistrationStatusMutation, UpdateTaxRegistrationStatusParams } from '../api'

export const useUpdateTaxRegistrationStatus = () => {
    const dispatch = useAppDispatch()
    const [action, { isLoading: isLoading }] = useUpdateTaxRegistrationStatusMutation()

    const updateTaxRegistrationStatus = useCallback(
        ({ customerId, status }: UpdateTaxRegistrationStatusParams) =>
            action({ customerId, status }).then(
                matchSuccessResponse(() => {
                    dispatch(requestCustomer(customerId))
                })
            ),
        [action, dispatch]
    )

    return {
        updateTaxRegistrationStatus,
        isLoading,
    }
}
