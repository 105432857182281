import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useFirebase } from '@scouts/auth'
import { colors, Loadable } from '@scouts/ui'

import { authenticate } from '@/actions/auth'
import { PATH_RESET_PASSWORD } from '@/constants'
import { initSentryUser } from '@/helpers/sentry'
import { useAppDispatch } from '@/store'

import { useResetSessionAndLogout } from './hooks/use-reset-session-and-logout'

import { Login } from './Login'
import { ResetPassword } from './ResetPassword'

const useAuthentication = () => {
    const dispatch = useAppDispatch()

    const { user, isInitialized } = useFirebase()
    const { resetSessionAndLogout } = useResetSessionAndLogout()

    const [shouldAttemptAuthentication, setShouldAttemptAuthentication] = useState(true)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // Do nothing if Firebase is not initialized

        if (!isInitialized) return

        // Reset state if the user is not logged in with Firebase

        if (!user) {
            setIsAuthenticated(false)
            setShouldAttemptAuthentication(true)
            return
        }

        // Skip authentication against API if we've already tried

        if (!shouldAttemptAuthentication) return

        // Attempt authentication against API

        setIsLoading(true)
        setShouldAttemptAuthentication(false)

        dispatch(
            authenticate({
                onError: () => {
                    setIsLoading(false)
                    setIsAuthenticated(false)
                    resetSessionAndLogout()
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onSuccess: (profile: any) => {
                    setIsAuthenticated(true)
                    setIsLoading(false)

                    if (profile?.id) {
                        initSentryUser(profile)
                    }
                },
            })
        )
    }, [dispatch, isInitialized, resetSessionAndLogout, shouldAttemptAuthentication, user])

    return { isAuthenticated, isLoading, isInitialized, user }
}

export const AuthenticationBoundary = ({ children }: { children: React.ReactNode }) => {
    const { isAuthenticated, isLoading, isInitialized, user } = useAuthentication()

    // #1 Render loading state while Firebase is initializing

    if (!isInitialized) {
        // Spinners in this component have different colours to observe the loading state of the app – we can remove this down the line
        return <Loadable isLoading isFullViewportHeight />
    }

    // #2 Render login/signup/reset password pages if not authenticated with Firebase

    if (!user)
        return (
            <Switch>
                <Route path={PATH_RESET_PASSWORD} component={ResetPassword} />
                <Login />
            </Switch>
        )

    // #3 Render loading state while we check if the user is authenticated against our API

    if (isLoading) {
        return <Loadable isLoading isFullViewportHeight />
    }

    // #4 Render children if the user is authenticated against our API

    if (isAuthenticated) {
        return <>{children}</>
    }

    // #5 Render loading state for any other case as a fallback

    return <Loadable spinnerColor={colors.red} isLoading isFullViewportHeight />
}
