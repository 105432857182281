export const ESCALATIONS_TYPE_ESCALATION = 'Escalation'
export const ESCALATIONS_TYPE_NON_ESCALATION = 'NonEscalation'
export const ESCALATIONS_TYPE_COMPLAINT = 'Complaint'

export const ESCALATIONS_TYPES = [
    ESCALATIONS_TYPE_ESCALATION,
    ESCALATIONS_TYPE_COMPLAINT,
    ESCALATIONS_TYPE_NON_ESCALATION,
]

export const ESCALATIONS_TYPE_TITLES = {
    [ESCALATIONS_TYPE_ESCALATION]: 'Escalation',
    [ESCALATIONS_TYPE_COMPLAINT]: 'Complaint',
    [ESCALATIONS_TYPE_NON_ESCALATION]: 'Non-escalation',
}

export const ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT = 'SelfAssessment'
export const ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION = 'TaxConsultation'
export const ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION = 'TaxRegistration'
export const ESCALATIONS_PRODUCT_TYPE_COMPANY_RETURN = 'CompanyReturn'
export const ESCALATIONS_PRODUCT_TYPE_SUBSCRIPTION = 'Subscription'
export const ESCALATIONS_PRODUCT_TYPE_TAX_RELIEF_CLAIM = 'TaxReliefClaim'
export const ESCALATIONS_PRODUCT_TYPE_OTHER = 'Other'

export const ESCALATIONS_PRODUCT_TYPES = [
    ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT,
    ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION,
    ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION,
    ESCALATIONS_PRODUCT_TYPE_COMPANY_RETURN,
    ESCALATIONS_PRODUCT_TYPE_SUBSCRIPTION,
    ESCALATIONS_PRODUCT_TYPE_TAX_RELIEF_CLAIM,
    ESCALATIONS_PRODUCT_TYPE_OTHER,
]

export const ESCALATIONS_PRODUCT_TYPE_TITLES = {
    [ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT]: 'Tax return',
    [ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION]: 'Tax consultation',
    [ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION]: 'Tax registration',
    [ESCALATIONS_PRODUCT_TYPE_COMPANY_RETURN]: 'Company return',
    [ESCALATIONS_PRODUCT_TYPE_SUBSCRIPTION]: 'Subscription',
    [ESCALATIONS_PRODUCT_TYPE_TAX_RELIEF_CLAIM]: 'EIS',
    [ESCALATIONS_PRODUCT_TYPE_OTHER]: 'Other',
}

export const ESCALATIONS_REASON_TYPE_COMMUNICATION = 'CommunicationDelayOrBreakdown'
export const ESCALATIONS_REASON_TYPE_CONFUSED = 'ConfusedByProductOrProcess'
export const ESCALATIONS_REASON_TYPE_EXPECTATIONS = 'ServiceDidNotMeetExpectations'
export const ESCALATIONS_REASON_TYPE_MISTAKE = 'AccountantMistake'
export const ESCALATIONS_REASON_TYPE_OTHER = 'Other'

export const ESCALATIONS_REASON_TYPES = [
    ESCALATIONS_REASON_TYPE_COMMUNICATION,
    ESCALATIONS_REASON_TYPE_CONFUSED,
    ESCALATIONS_REASON_TYPE_EXPECTATIONS,
    ESCALATIONS_REASON_TYPE_MISTAKE,
    ESCALATIONS_REASON_TYPE_OTHER,
]

export const ESCALATIONS_REASON_TYPE_TITLES = {
    [ESCALATIONS_REASON_TYPE_COMMUNICATION]: 'Communication delay or breakdown',
    [ESCALATIONS_REASON_TYPE_CONFUSED]: 'Confused by product or process',
    [ESCALATIONS_REASON_TYPE_EXPECTATIONS]: 'Service did not meet expectations',
    [ESCALATIONS_REASON_TYPE_MISTAKE]: 'Accountant mistake',
    [ESCALATIONS_REASON_TYPE_OTHER]: 'Other',
}
export const ESCALATIONS_REASON_TYPE_SHORT_TITLES = {
    [ESCALATIONS_REASON_TYPE_COMMUNICATION]: 'Communication',
    [ESCALATIONS_REASON_TYPE_CONFUSED]: 'Confused',
    [ESCALATIONS_REASON_TYPE_EXPECTATIONS]: 'Expectations',
    [ESCALATIONS_REASON_TYPE_MISTAKE]: 'Accountant mistake',
    [ESCALATIONS_REASON_TYPE_OTHER]: 'Other',
}

export const ESCALATION_STATUS_OPEN = 'Open'
export const ESCALATION_STATUS_CLOSED = 'Closed'

export const ESCALATION_SORT_TYPE_ACCOUNTANT_NAME = 'AccountantName'
export const ESCALATION_SORT_TYPE_OCCURRED_DATE = 'OccurredDate'
export const ESCALATION_SORT_TYPE_RESOLVED_DATE = 'ResolvedDate'
export const ESCALATION_SORT_TYPE_SUPPORT_USER_NAME = 'SupportUserName'
