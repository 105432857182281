import { AccountantDetailsDTO } from './accountant'

export const BusinessService = {
    LimitedCompanyTaxReturns: 'LimitedCompanyTaxReturns',
    AccountsAndBookkeeping: 'AccountsAndBookkeeping',
    CompanyFormation: 'CompanyFormation',
    VAT: 'VAT',
    Taxation: 'Taxation',
    Payroll: 'Payroll',
    FinancialReporting: 'FinancialReporting',
    Other: 'Other',
} as const

export type BusinessServiceTypes = (typeof BusinessService)[keyof typeof BusinessService]

export const PersonalService = {
    TaxPlanning: 'TaxPlanning',
    PensionsTax: 'PensionsTax',
    CGT: 'CGT',
    InvestorTaxRelief: 'InvestorTaxRelief',
    IncomeTax: 'IncomeTax',
    Other: 'Other',
} as const

export type PersonalServiceTypes = (typeof PersonalService)[keyof typeof PersonalService]

export const OffPlatformServicesRequestStatus = {
    Started: 'Started',
    Submitted: 'Submitted',
    Claimed: 'Claimed',
    Cancelled: 'Cancelled',
    Paid: 'Paid',
    Referred: 'Referred',
} as const

export type OffPlatformServicesRequestStatusTypes =
    (typeof OffPlatformServicesRequestStatus)[keyof typeof OffPlatformServicesRequestStatus]

export interface OffPlatformServicesRequestDTO {
    customerId: number
    accountantId: number | null
    accountantFullName: string | null
    id: number
    customerFullName: string
    additionalNotes: string | null
    businessServices: BusinessServiceTypes[] | null
    personalServices: PersonalServiceTypes[] | null
    contactPreferenceType: 'Phone' | 'Email'
    phoneNumber: string | null
    email: string | null
    status: OffPlatformServicesRequestStatusTypes
    statusChangedDateUTC: string
}

export interface OffPlatformServicesRequestEditStatusDTO {
    id: OffPlatformServicesRequestDTO['id']
    status: OffPlatformServicesRequestDTO['status']
}
export interface OffPlatformServicesRequestEditAccountantDTO {
    id: OffPlatformServicesRequestDTO['id']
    accountantId: OffPlatformServicesRequestDTO['accountantId']
    status: OffPlatformServicesRequestDTO['status']
}

export const PersonalServicesLabels: Record<PersonalServiceTypes, string> = {
    TaxPlanning: 'Tax planning',
    PensionsTax: 'Pensions tax',
    CGT: 'CGT',
    InvestorTaxRelief: '(S)EIS, VCT tax relief',
    IncomeTax: 'Income tax',
    Other: 'Other',
}

export const BusinessServicesLabels: Record<BusinessServiceTypes, string> = {
    LimitedCompanyTaxReturns: 'Limited company tax returns',
    AccountsAndBookkeeping: 'Accounts & Bookkeeping',
    CompanyFormation: 'Company formation',
    VAT: 'VAT / VAT returns',
    Taxation: 'Taxation & Tax Planning',
    Payroll: 'Payroll',
    FinancialReporting: 'Financial reporting',
    Other: 'Other - please clarify below',
}

interface DateModel {
    start: string
    end: string
}

export interface OffPlatformServicesRequestOptions {
    sortOrder?: string
    sortType?: string
    accountantId?: AccountantDetailsDTO['id'] | null
    status?: OffPlatformServicesRequestDTO['status'] | null
    submittedDate?: DateModel
    claimedDate?: DateModel
    cancelledDate?: DateModel
    paidDate?: DateModel
    referredDate?: DateModel
    page?: number
    pageSize?: number
}

export enum OffPlatformServicesRequestOptionsFilter {
    ACCOUNTANT_ID = 'accountantId',
    CANCELLED_DATE = 'cancelledDate',
    CLAIMED_DATE = 'claimedDate',
    PAID_DATE = 'paidDate',
    REFERRED_DATE = 'referredDate',
    STATUS = 'status',
    SUBMITTED_DATE = 'submittedDate',
}

export const OffPlatformServicesRequestOptionsFilters = [
    OffPlatformServicesRequestOptionsFilter.STATUS,
    OffPlatformServicesRequestOptionsFilter.ACCOUNTANT_ID,
    OffPlatformServicesRequestOptionsFilter.SUBMITTED_DATE,
    OffPlatformServicesRequestOptionsFilter.CLAIMED_DATE,
    OffPlatformServicesRequestOptionsFilter.CANCELLED_DATE,
    OffPlatformServicesRequestOptionsFilter.PAID_DATE,
    OffPlatformServicesRequestOptionsFilter.REFERRED_DATE,
] as const
