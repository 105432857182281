import styled from 'styled-components'

import { colors, shadows } from '@scouts/ui'

import { ProductBundleDetailsDTO, SelfAssessmentDTO, TaxConsultationDTO, TaxRegistrationDTO } from '@/models'

import { CustomersTableServices } from './CustomersTableServices'

interface CustomersTableServicesPopupProps {
    customerId: number
    onClosePopup: VoidFunction
    productBundles: Pick<ProductBundleDetailsDTO, 'id'>[]
    selfAssessments: Pick<SelfAssessmentDTO, 'id' | 'status' | 'taxYear'>[]
    taxConsultations: Pick<TaxConsultationDTO, 'id' | 'status'>[]
    taxRegistrations: Pick<TaxRegistrationDTO, 'id' | 'status'>[]
}

export const CustomersTableServicesPopup = ({
    customerId,
    onClosePopup,
    productBundles,
    selfAssessments,
    taxConsultations,
    taxRegistrations,
}: CustomersTableServicesPopupProps) => (
    <Container onMouseLeave={onClosePopup}>
        <Content>
            <Wrapper>
                <CustomersTableServices
                    selfAssessments={selfAssessments}
                    taxConsultations={taxConsultations}
                    taxRegistrations={taxRegistrations}
                    productBundles={productBundles}
                    customerId={customerId}
                />
            </Wrapper>
        </Content>
    </Container>
)

const Container = styled.div`
    width: 400px;
    z-index: 1000;
    padding: 0 18px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`

const Content = styled.div`
    cursor: default;
    background: ${colors.neutralLightest};
    box-shadow: ${shadows.medium};
`

const Wrapper = styled.div`
    padding: 12px 18px;
    max-height: 380px;
    overflow: auto;
`
