import { Paragraph, Radio, RadioOnChangeParams, Spacer } from '@scouts/ui'

import { CustomerDTO } from '@/models'

interface CustomerSelfAssessmentsMigrationBodyProps {
    isFetching: boolean
    isMigrationSuccessful: boolean
    sourceCustomerEmail: string
    sourceCustomerFullName: string
    targetCustomerEmail: string
    targetCustomerId: number
    customers: CustomerDTO[]
    handleTargetCustomerIdChange: (e: RadioOnChangeParams) => void
}

export const CustomerSelfAssessmentsMigrationBody = ({
    customers,
    handleTargetCustomerIdChange,
    targetCustomerId,
    sourceCustomerFullName,
    isFetching,
    isMigrationSuccessful,
    sourceCustomerEmail,
    targetCustomerEmail,
}: CustomerSelfAssessmentsMigrationBodyProps) => {
    if (isFetching) return null

    if (isMigrationSuccessful) {
        return (
            <Paragraph>
                All tax returns from {sourceCustomerEmail} have been migrated to {targetCustomerEmail}
            </Paragraph>
        )
    }

    if (!isFetching && customers.length === 0)
        return (
            <Paragraph>
                Tax returns can only be migrated between clients with the same name. There seems to be no other clients
                with the exact same name <strong>{sourceCustomerFullName}</strong>.
            </Paragraph>
        )

    return (
        <>
            <Paragraph>
                By continuing all the tax returns from this client will be moved to the following client:
            </Paragraph>
            <Spacer margin="12px 0">
                {customers.map(({ id, fullName, email }) => (
                    <Radio
                        key={id}
                        checked={id === targetCustomerId}
                        label={`${fullName} (${email})`}
                        name="targetCustomerId"
                        onChange={handleTargetCustomerIdChange}
                        value={id}
                    />
                ))}
            </Spacer>
            <Paragraph>Also make sure they haven't started the same tax years.</Paragraph>
        </>
    )
}
