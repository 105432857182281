import { MutableRefObject } from 'react'

import { colors, Container, Label, Message, Spacer, Tooltip } from '@scouts/ui'

import { MESSAGE_TYPE_SMS } from '@/constants'
import { getMessageDate } from '@/domain/message'
import { formatDateWithTime, getAccountantImageUrl } from '@/helpers'
import { MessageDTO, UserType } from '@/models'

import defaultProfileImage from '@/assets/images/default-accountant.png'

import MessageAttachments from './MessageAttachments'
import MessageContent from './MessageContent'

const getMessageBackgroundColor = ({
    customerAccountantId,
    message,
}: {
    customerAccountantId: number
    message: MessageDTO
}) => {
    const { sentByUserType, accountantId: messageAccountantId } = message
    const isSentByCustomerAccountant = customerAccountantId === messageAccountantId

    switch (sentByUserType) {
        case UserType.Accountant: {
            if (isSentByCustomerAccountant) return colors.blueLighter
            return colors.neutralLightest
        }
        case UserType.Customer:
            return colors.neutralLightest
        case UserType.System:
            return colors.redLighter
        default:
            return colors.neutralLightest
    }
}

const getHeaderText = (message: MessageDTO) => {
    const { sentByUserType, senderFullName } = message
    const from = () => {
        if (sentByUserType === UserType.Customer) return senderFullName || 'Client'
        if (sentByUserType === UserType.System) return 'TaxScouts'
        if (sentByUserType === UserType.Accountant) return senderFullName || 'Accountant'

        return sentByUserType
    }

    const date = getMessageDate(message)

    return sentByUserType === UserType.Customer
        ? `${formatDateWithTime(date)}  ·  ${from()}`
        : `${from()}  ·  ${formatDateWithTime(date)}`
}

const getHeaderLabelText = ({ messageType, messageProvider, messageFailure }: MessageDTO) => {
    if (messageType === MESSAGE_TYPE_SMS) return 'SMS'
    if (messageProvider) return messageProvider
    if (messageFailure) return 'Email send failed'
    return ''
}

const getHeaderLabelBackground = ({ messageFailure }: MessageDTO) => {
    if (messageFailure) return colors.red
    return colors.neutralLightest
}

interface MessagesListItemProps {
    message: MessageDTO
    messageListRef: MutableRefObject<HTMLDivElement[]>
    customerAccountantId: number
}

export const MessagesListItem = ({ message, customerAccountantId, messageListRef }: MessagesListItemProps) => {
    if (!message) return null

    const { accountantId, attachments, hasAttachment, sentByUserType } = message

    const isSentByCustomer = sentByUserType === UserType.Customer
    const isAttachmentsListEmpty = !attachments || attachments?.length === 0

    const shouldDisplayAttachmentsList = !isAttachmentsListEmpty
    const shouldDisplayAttachmentsTooltip = hasAttachment && isAttachmentsListEmpty && isSentByCustomer
    const shouldDisplayProfileImage = sentByUserType === UserType.Accountant || sentByUserType === UserType.System

    return (
        <Container
            ref={(element: HTMLDivElement) => {
                if (element) messageListRef.current[message.id] = element
            }}
        >
            <Message alignLeft={isSentByCustomer}>
                <Message.Content>
                    <Message.Content.Header
                        alignLeft={isSentByCustomer}
                        hasImage={shouldDisplayProfileImage}
                        labelText={getHeaderLabelText(message)}
                        labelBackground={getHeaderLabelBackground(message)}
                        text={getHeaderText(message)}
                    />

                    <Message.Content.Body background={getMessageBackgroundColor({ customerAccountantId, message })}>
                        <MessageContent message={message} />

                        {shouldDisplayAttachmentsList && (
                            <Spacer marginTop="18px">
                                <MessageAttachments attachments={attachments} />
                            </Spacer>
                        )}

                        {shouldDisplayAttachmentsTooltip && (
                            <Spacer marginTop="18px">
                                <Tooltip content="Attachments are currently only available in the original email and not visible on the platform">
                                    <Label background={colors.black} color={colors.white}>
                                        Please check your email to download the attachments
                                    </Label>
                                </Tooltip>
                            </Spacer>
                        )}
                    </Message.Content.Body>
                </Message.Content>

                {shouldDisplayProfileImage && (
                    <Message.Image
                        desktopSize="48px"
                        withBorder
                        src={
                            sentByUserType === UserType.Accountant && accountantId
                                ? getAccountantImageUrl(accountantId)
                                : defaultProfileImage
                        }
                    />
                )}
            </Message>
        </Container>
    )
}
