import { Button } from '@scouts/ui'

import { CustomerDTO, IdentityVerificationDTO, IdentityVerificationStatus } from '@/models'
import { useCreateIdentityVerification, useUpdateIdentityVerification } from '@/store/identity-verifications'

interface MarkAsVerifiedProps {
    customerId: CustomerDTO['id']
    identityVerification: IdentityVerificationDTO | null
    buttonLabel: string
}

export const MarkAsVerified = ({ customerId, identityVerification, buttonLabel }: MarkAsVerifiedProps) => {
    const { createIdentityVerification, isLoading: isLoadingCreate } = useCreateIdentityVerification()
    const { updateIdentityVerification, isLoading: isLoadingUpdate } = useUpdateIdentityVerification()

    const markAsVerified = () => {
        if (identityVerification?.id) {
            updateIdentityVerification({
                identityVerificationId: identityVerification.id,
                data: {
                    ...identityVerification,
                    status: IdentityVerificationStatus.Verified,
                },
            })
        } else {
            createIdentityVerification({
                customerId,
            })
        }
    }

    return (
        <Button onClick={markAsVerified} isLoading={isLoadingCreate || isLoadingUpdate}>
            {buttonLabel}
        </Button>
    )
}
