import { Loadable } from '@scouts/ui'

import { CustomerDTO } from '@/models'
import { useCustomersBankAccount } from '@/store/bank-account'

import { BankDetails } from './BankDetails'

export const BankDetailsContainer = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const { bankAccount, isLoading: isLoadingBankAccount } = useCustomersBankAccount({ customerId })

    if (isLoadingBankAccount) return <Loadable isLoading />

    return <BankDetails customerId={customerId} bankAccount={bankAccount || null} />
}
