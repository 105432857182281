import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { colors } from '@scouts/ui'

export interface MobilePreviewTextMessageProps {
    children: ReactNode
}

export const MobilePreviewTextMessage = ({ children }: MobilePreviewTextMessageProps) => (
    <Container>
        <Content>
            <Message>{children}</Message>
        </Content>
    </Container>
)

const Container = styled.div`
    position: relative;
    width: 360px;
    height: 640px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
    background: ${colors.white};

    /* The horizontal line on the top of the device */
    &:before {
        content: '';
        display: block;
        width: 60px;
        height: 5px;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 10px;
    }

    /* The circle on the bottom of the device */
    &:after {
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        bottom: -65px;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 50%;
    }
`

const Content = styled.div`
    max-height: 520px;
    overflow: auto;

    background-color: ${colors.white};
    border: 1px solid #e5e5ea;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.5rem 1.5rem;
    border-bottom: 0;

    p {
        margin: 0.5rem 0;
        width: fit-content;
    }
`

const Message = styled.p`
    border-radius: 1.15rem;
    line-height: 1.3;
    max-width: 85%;
    padding: 0.5rem 0.875rem;
    position: relative;
    word-wrap: break-word;
    align-items: flex-start;
    background-color: #e5e5ea;
    color: ${colors.black};

    &:before,
    &:after {
        bottom: -0.1rem;
        content: '';
        height: 1rem;
        position: absolute;
    }

    &:before {
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid #e5e5ea;
        left: -0.35rem;
        transform: translate(0, -0.1rem);
    }

    &:after {
        background-color: ${colors.white};
        border-bottom-right-radius: 0.5rem;
        left: 20px;
        transform: translate(-30px, -2px);
        width: 10px;
    }
`
