import { useState } from 'react'

import {
    Button,
    Checkbox,
    CheckboxOnChangeParams,
    Flexer,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { saveSelfAssessment } from '@/actions'
import { SelfAssessmentDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface SelfAssessmentEditIncomeModalProps {
    selfAssessment: SelfAssessmentDTO
    onClose: () => void
}

export const SelfAssessmentEditIncomeModal = ({
    selfAssessment: initialSelfAssessment,
    onClose,
}: SelfAssessmentEditIncomeModalProps) => {
    const dispatch = useAppDispatch()
    const [selfAssessment, setSelfAssessment] = useState(initialSelfAssessment)

    const handleChange = ({ name, checked }: CheckboxOnChangeParams) => {
        const updatedSelfAssessment = { ...selfAssessment, [name]: checked }

        setSelfAssessment(updatedSelfAssessment)
    }

    const handleSubmit = () => {
        dispatch(saveSelfAssessment(selfAssessment))

        onClose()
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Edit income sources</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <Flexer column gap="18px">
                        <Checkbox
                            name="hasEmployment"
                            onChange={handleChange}
                            label="Employment"
                            checked={!!selfAssessment.hasEmployment}
                        />

                        <Checkbox
                            name="hasSelfEmployment"
                            onChange={handleChange}
                            label="Self-employment"
                            checked={!!selfAssessment.hasSelfEmployment}
                        />

                        <Checkbox
                            name="hasCapitalGains"
                            onChange={handleChange}
                            label="Capital Gains"
                            checked={!!selfAssessment.hasCapitalGains}
                        />

                        <Checkbox
                            name="hasPropertyEarnings"
                            onChange={handleChange}
                            label="Property Earnings"
                            checked={!!selfAssessment.hasPropertyEarnings}
                        />

                        <Checkbox
                            name="hasDividendsOrInterest"
                            onChange={handleChange}
                            label="Dividends and Interest"
                            checked={!!selfAssessment.hasDividendsOrInterest}
                        />

                        <Checkbox
                            name="hasPensionsOrBenefits"
                            onChange={handleChange}
                            label="Pensions or Benefits"
                            checked={!!selfAssessment.hasPensionsOrBenefits}
                        />

                        <Checkbox
                            name="hasForeignIncome"
                            onChange={handleChange}
                            label="Foreign Income"
                            checked={!!selfAssessment.hasForeignIncome}
                        />

                        <Checkbox
                            name="hasOtherIncome"
                            onChange={handleChange}
                            label="Other Income"
                            checked={!!selfAssessment.hasOtherIncome}
                        />
                    </Flexer>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
