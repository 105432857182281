import { useState } from 'react'
import styled from 'styled-components'

import { colors, Badge } from '@scouts/ui'

import { ProductBundleDetailsDTO, SelfAssessmentDTO, TaxConsultationDTO, TaxRegistrationDTO } from '@/models'

import { CustomersTableServicesPopup } from './CustomersTableServicesPopup'

interface CompletedServicesIndicatorProps {
    customerId: number
    numberOfCompletedServices: number
    productBundles?: Pick<ProductBundleDetailsDTO, 'id'>[]
    selfAssessments?: Pick<SelfAssessmentDTO, 'id' | 'status' | 'taxYear'>[]
    taxConsultations?: Pick<TaxConsultationDTO, 'id' | 'status'>[]
    taxRegistrations?: Pick<TaxRegistrationDTO, 'id' | 'status'>[]
}

export const CompletedServicesIndicator = ({
    customerId,
    numberOfCompletedServices,
    productBundles = [],
    selfAssessments = [],
    taxConsultations = [],
    taxRegistrations = [],
}: CompletedServicesIndicatorProps) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const hasItems = numberOfCompletedServices > 0

    return (
        <Container>
            <BadgeWrapper onMouseEnter={() => setIsPopupOpen(true)}>
                <BadgeBorder>
                    <Badge
                        background={hasItems ? colors.mintLighter : colors.neutralLightest}
                        color={hasItems ? colors.black : colors.neutralDarker}
                    >
                        {numberOfCompletedServices}
                    </Badge>
                </BadgeBorder>
            </BadgeWrapper>

            {isPopupOpen && hasItems && (
                <CustomersTableServicesPopup
                    selfAssessments={selfAssessments}
                    taxConsultations={taxConsultations}
                    taxRegistrations={taxRegistrations}
                    productBundles={productBundles}
                    customerId={customerId}
                    onClosePopup={() => setIsPopupOpen(false)}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    min-width: 0;
    flex: 1;
`

const BadgeWrapper = styled.div``

const BadgeBorder = styled.div`
    display: inline-block;
    border-radius: 9999px;
    border: 2px solid ${colors.white};
`
