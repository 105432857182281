import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { requestCustomer } from '@/actions'
import { useAppDispatch } from '@/store/store'

import { useUpdateCustomersPartnerMutation, UpdateCustomersPartnerParams } from '../api'

export const useUpdateCustomersPartner = () => {
    const dispatch = useAppDispatch()
    const [action, { isLoading }] = useUpdateCustomersPartnerMutation()

    const updateCustomersPartner = useCallback(
        ({ customerId, data }: UpdateCustomersPartnerParams) =>
            action({ customerId, data }).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Partner details saved' })
                    dispatch(requestCustomer(customerId))
                })
            ),
        [action, dispatch]
    )

    return { updateCustomersPartner, isLoading }
}
