import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
    colors,
    media,
    Button,
    Checkbox,
    DatePicker,
    DropdownSelect,
    Input,
    Label,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    TextArea,
    TypeSelect,
} from '@scouts/ui'

import { useSupportUsers } from '@/store/support-users/hooks'

import { receiveErrorAlerts } from '../../actions'
import {
    ESCALATION_STATUS_CLOSED,
    ESCALATION_STATUS_OPEN,
    ESCALATIONS_PRODUCT_TYPE_COMPANY_RETURN,
    ESCALATIONS_PRODUCT_TYPE_OTHER,
    ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT,
    ESCALATIONS_PRODUCT_TYPE_SUBSCRIPTION,
    ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION,
    ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION,
    ESCALATIONS_PRODUCT_TYPE_TAX_RELIEF_CLAIM,
    ESCALATIONS_PRODUCT_TYPE_TITLES,
    ESCALATIONS_REASON_TYPE_COMMUNICATION,
    ESCALATIONS_REASON_TYPE_CONFUSED,
    ESCALATIONS_REASON_TYPE_EXPECTATIONS,
    ESCALATIONS_REASON_TYPE_MISTAKE,
    ESCALATIONS_REASON_TYPE_OTHER,
    ESCALATIONS_REASON_TYPE_TITLES,
    ESCALATIONS_TYPE_COMPLAINT,
    ESCALATIONS_TYPE_ESCALATION,
    ESCALATIONS_TYPE_NON_ESCALATION,
    ESCALATIONS_TYPE_TITLES,
} from '../../constants'
import { formatTimeHHmm, getUTCfromDateAndTime } from '../../helpers'
import { getAccountantsWithFullNameAndStatus } from '../../selectors'
import CustomerEscalationsModalNotes from './CustomerEscalationsModalNotes'
import CustomerEscalationsModalNotesCreate from './CustomerEscalationsModalNotesCreate'

const Columns = styled.div`
    ${media.tablet} {
        display: flex;
        justify-content: space-between;
    }
`

const Column = styled.div`
    margin-bottom: 24px;

    ${media.tablet} {
        flex: 0 0 calc(50% - 24px);
        margin-bottom: 0;
    }
`

const FormRow = styled.div`
    display: ${({ isStacked }) => (isStacked ? 'block' : 'flex')};
    align-items: center;
    margin-bottom: 24px;
`

const FormRowTitle = styled.div`
    flex: 0 0 180px;
    margin: ${({ isStacked }) => (isStacked ? '0 0 12px' : '0 24px 0 0')};
`

const FormRowItem = styled.div`
    flex: 1;
`

const Status = styled.div`
    margin-left: 24px;
    display: inline-block;
    vertical-align: text-bottom;
`

const InputTimeWrapper = styled.div`
    input:disabled,
    input:read-only {
        opacity: 0.7;
        border-color: ${colors.neutralDarker};
    }
`

const escalationReasonOptions = [
    { title: 'Choose a reason…', value: '' },
    {
        value: ESCALATIONS_REASON_TYPE_COMMUNICATION,
        title: ESCALATIONS_REASON_TYPE_TITLES[ESCALATIONS_REASON_TYPE_COMMUNICATION],
    },
    {
        value: ESCALATIONS_REASON_TYPE_CONFUSED,
        title: ESCALATIONS_REASON_TYPE_TITLES[ESCALATIONS_REASON_TYPE_CONFUSED],
    },
    {
        value: ESCALATIONS_REASON_TYPE_EXPECTATIONS,
        title: ESCALATIONS_REASON_TYPE_TITLES[ESCALATIONS_REASON_TYPE_EXPECTATIONS],
    },
    {
        value: ESCALATIONS_REASON_TYPE_MISTAKE,
        title: ESCALATIONS_REASON_TYPE_TITLES[ESCALATIONS_REASON_TYPE_MISTAKE],
    },
    {
        value: ESCALATIONS_REASON_TYPE_OTHER,
        title: ESCALATIONS_REASON_TYPE_TITLES[ESCALATIONS_REASON_TYPE_OTHER],
    },
]

const escalationTypeOptions = [
    { title: 'Choose a type…', value: '' },
    {
        value: ESCALATIONS_TYPE_ESCALATION,
        title: ESCALATIONS_TYPE_TITLES[ESCALATIONS_TYPE_ESCALATION],
    },
    {
        value: ESCALATIONS_TYPE_COMPLAINT,
        title: ESCALATIONS_TYPE_TITLES[ESCALATIONS_TYPE_COMPLAINT],
    },
    {
        value: ESCALATIONS_TYPE_NON_ESCALATION,
        title: ESCALATIONS_TYPE_TITLES[ESCALATIONS_TYPE_NON_ESCALATION],
    },
]

const escalationProductTypeOptions = [
    { title: 'Choose a type…', value: '' },
    {
        value: ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT,
        title: ESCALATIONS_PRODUCT_TYPE_TITLES[ESCALATIONS_PRODUCT_TYPE_SELF_ASSESSMENT],
    },
    {
        value: ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION,
        title: ESCALATIONS_PRODUCT_TYPE_TITLES[ESCALATIONS_PRODUCT_TYPE_TAX_CONSULTATION],
    },
    {
        value: ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION,
        title: ESCALATIONS_PRODUCT_TYPE_TITLES[ESCALATIONS_PRODUCT_TYPE_TAX_REGISTRATION],
    },
    {
        value: ESCALATIONS_PRODUCT_TYPE_COMPANY_RETURN,
        title: ESCALATIONS_PRODUCT_TYPE_TITLES[ESCALATIONS_PRODUCT_TYPE_COMPANY_RETURN],
    },
    {
        value: ESCALATIONS_PRODUCT_TYPE_SUBSCRIPTION,
        title: ESCALATIONS_PRODUCT_TYPE_TITLES[ESCALATIONS_PRODUCT_TYPE_SUBSCRIPTION],
    },
    {
        value: ESCALATIONS_PRODUCT_TYPE_TAX_RELIEF_CLAIM,
        title: ESCALATIONS_PRODUCT_TYPE_TITLES[ESCALATIONS_PRODUCT_TYPE_TAX_RELIEF_CLAIM],
    },
    {
        value: ESCALATIONS_PRODUCT_TYPE_OTHER,
        title: ESCALATIONS_PRODUCT_TYPE_TITLES[ESCALATIONS_PRODUCT_TYPE_OTHER],
    },
]

const CustomerEscalationsItemModal = ({
    customerId,
    customerFullName,
    customerAccountantId,
    escalation,
    handleCancel,
    handleSave,
}) => {
    const { activeSupportUsersWithFullName: supportUsersWithFullName } = useSupportUsers()
    const accountantsWithFullName = useSelector(getAccountantsWithFullNameAndStatus)
    const supportUserId = useSelector((state) => state.user.id)

    const { id: escalationId } = escalation
    const isEditing = !!escalation.id

    const escalationCustomerId = _get(escalation, ['customerId'], customerId)
    const escalationCustomerName = _get(escalation, ['customerFullName'], customerFullName)

    const initialEscalationAccountantId = _get(escalation, ['accountantId'], customerAccountantId)
    const initialEscalationNewAccountantId = _get(escalation, ['newAccountantId'], null)
    const initialEscalationOccuredDate = _get(escalation, ['occurredDateUTC'], new Date())
    const initialEscalationOccuredTime = formatTimeHHmm(initialEscalationOccuredDate)
    const initialEscalationReasonDescription = _get(escalation, ['reasonDescription'], null)
    const initialEscalationReasonType = _get(escalation, ['reasonType'], null)
    const initialEscalationClosedDate = _get(escalation, ['resolvedDateUTC'], '')
    const initialEscalationClosedTime = !initialEscalationClosedDate ? '' : formatTimeHHmm(initialEscalationClosedDate)
    const initialEscalationSummary = _get(escalation, ['summary'], null)
    const initialEscalationSupportUserId = _get(escalation, ['supportUserId'], supportUserId)
    const initialEscalationType = _get(escalation, ['type'], ESCALATIONS_TYPE_ESCALATION)
    const initialEscalationProductType = _get(escalation, ['productType'], null)

    const [escalationAccountantId, setEscalationAccountantId] = useState(initialEscalationAccountantId)
    const [escalationHasReassigned, setEscalationHasReassigned] = useState(!!initialEscalationNewAccountantId)
    const [escalationNewAccountantId, setEscalationNewAccountantId] = useState(initialEscalationNewAccountantId)
    const [escalationOccurredDate, setEscalationOccurredDate] = useState(initialEscalationOccuredDate)
    const [escalationOccurredTime, setEscalationOccurredTime] = useState(initialEscalationOccuredTime)
    const [escalationReasonDescription, setEscalationReasonDescription] = useState(initialEscalationReasonDescription)
    const [escalationReasonType, setEscalationReasonType] = useState(initialEscalationReasonType)
    const [escalationClosedDate, setEscalationClosedDate] = useState(initialEscalationClosedDate)
    const [escalationClosedTime, setEscalationClosedTime] = useState(initialEscalationClosedTime)
    const [escalationClosedStatus, setEscalationClosedStatus] = useState(!!initialEscalationClosedDate)
    const [escalationSummary, setEscalationSummary] = useState(initialEscalationSummary)
    const [escalationSupportUserId, setEscalationSupportUserId] = useState(initialEscalationSupportUserId)
    const [escalationType, setEscalationType] = useState(initialEscalationType)
    const [escalationProductType, setEscalationProductType] = useState(initialEscalationProductType)

    const status = escalationClosedStatus ? ESCALATION_STATUS_CLOSED : ESCALATION_STATUS_OPEN

    const dispatch = useDispatch()
    const handleErrorsBeforeSubmit = (data) => {
        const errorMessages = []

        if (!data.occurredDateUTC) errorMessages.push('Please add the occurred date')
        if (!data.supportUserId) errorMessages.push('Please assign a support user')
        if (!data.accountantId) errorMessages.push('Please assign an accountant')
        if (!data.reasonType) errorMessages.push('Please choose a reason')
        if (!data.summary) errorMessages.push('Please add a summary')
        if (!data.type) errorMessages.push('Please choose a type for the escalation')
        if (!data.productType) errorMessages.push('Please choose a product type for the escalation')

        if (!data.reasonDescription && data.reasonType === ESCALATIONS_REASON_TYPE_OTHER)
            errorMessages.push('Please describe the reason')

        if (data.occurredDateUTC && data.resolvedDateUTC) {
            const hasResolvedBeforeOccurred =
                new Date(data.occurredDateUTC).getTime() > new Date(data.resolvedDateUTC).getTime()
            if (hasResolvedBeforeOccurred) errorMessages.push('The closed date cannot be before occurred date')
        }

        const hasErrors = !!errorMessages.length
        if (hasErrors) dispatch(receiveErrorAlerts(errorMessages))

        return hasErrors
    }

    const handleSubmit = ({ shouldOpenEditModal = false } = {}) => {
        const data = {
            accountantId: escalationAccountantId,
            customerId: escalationCustomerId,
            id: escalationId,
            newAccountantId: escalationHasReassigned ? escalationNewAccountantId : null,
            occurredDateUTC: getUTCfromDateAndTime(escalationOccurredDate, escalationOccurredTime),
            resolvedDateUTC: escalationClosedStatus
                ? getUTCfromDateAndTime(escalationClosedDate, escalationClosedTime)
                : null,
            summary: escalationSummary,
            supportUserId: escalationSupportUserId,
            type: escalationType,
            reasonType: escalationReasonType,
            reasonDescription:
                escalationReasonType === ESCALATIONS_REASON_TYPE_OTHER ? escalationReasonDescription : null,
            productType: escalationProductType,
        }

        const hasErrors = handleErrorsBeforeSubmit(data)

        if (!hasErrors) handleSave(data, shouldOpenEditModal)
    }

    const handleSilentCreateAndOpenEditModal = () => {
        handleSubmit({ shouldOpenEditModal: true })
    }

    const handleChangeDate = ({ name, value }) => {
        const dateValue = value ? new Date(value) : value

        if (name === 'escalationOccurredDate') setEscalationOccurredDate(dateValue)
        if (name === 'escalationClosedDate') setEscalationClosedDate(dateValue)
    }

    const handleChangeEscalationOccurredTime = ({ value }) => {
        const now = formatTimeHHmm()
        setEscalationOccurredTime(value || escalationOccurredTime || now)
    }

    const handleChangeEscalationClosedTime = ({ value }) => {
        const now = formatTimeHHmm()
        setEscalationClosedTime(value || escalationClosedTime || now)
    }

    const handleChangeClosedStatus = ({ checked }) => {
        setEscalationClosedStatus(checked)

        if (checked && !escalationClosedDate) setEscalationClosedDate(new Date())
        if (checked && !escalationClosedTime) setEscalationClosedTime(formatTimeHHmm())
    }

    return (
        <Modal onClose={handleCancel} width="90%">
            <ModalHeader>
                <ModalTitle>
                    {isEditing
                        ? `Edit ${ESCALATIONS_TYPE_TITLES[escalationType]?.toLowerCase() ?? 'escalation'}`
                        : 'Add escalation or complaint'}
                    {isEditing && (
                        <Status>
                            <Label
                                background={status === ESCALATION_STATUS_CLOSED ? colors.mintLighter : colors.yellow}
                            >
                                {status}
                            </Label>
                        </Status>
                    )}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Columns>
                    <Column>
                        <FormRow>
                            <FormRowTitle>Customer</FormRowTitle>
                            <FormRowItem>{escalationCustomerName}</FormRowItem>
                        </FormRow>
                        <FormRow>
                            <FormRowTitle>Type</FormRowTitle>
                            <FormRowItem>
                                <DropdownSelect
                                    name="escalationType"
                                    value={escalationType || ''}
                                    options={escalationTypeOptions}
                                    width="100%"
                                    onChange={({ value }) => {
                                        setEscalationType(value)
                                    }}
                                />
                            </FormRowItem>
                        </FormRow>
                        <FormRow>
                            <FormRowTitle>Reason</FormRowTitle>
                            <FormRowItem>
                                <DropdownSelect
                                    name="escalationReasonType"
                                    value={escalationReasonType || ''}
                                    options={escalationReasonOptions}
                                    width="100%"
                                    onChange={({ value }) => {
                                        setEscalationReasonType(value)
                                    }}
                                />
                            </FormRowItem>
                        </FormRow>

                        {escalationReasonType === ESCALATIONS_REASON_TYPE_OTHER && (
                            <FormRow>
                                <FormRowTitle>Describe reason</FormRowTitle>
                                <FormRowItem>
                                    <Input
                                        name="escalationReasonDescription"
                                        value={escalationReasonDescription || ''}
                                        onChange={({ value }) => {
                                            setEscalationReasonDescription(value)
                                        }}
                                    />
                                </FormRowItem>
                            </FormRow>
                        )}

                        <FormRow>
                            <FormRowTitle>Product</FormRowTitle>
                            <FormRowItem>
                                <DropdownSelect
                                    name="escalationProductType"
                                    value={escalationProductType || ''}
                                    options={escalationProductTypeOptions}
                                    width="100%"
                                    onChange={({ value }) => {
                                        setEscalationProductType(value)
                                    }}
                                />
                            </FormRowItem>
                        </FormRow>

                        <FormRow>
                            <FormRowTitle>Date occurred</FormRowTitle>
                            <FormRowItem>
                                <DatePicker
                                    name="escalationOccurredDate"
                                    value={escalationOccurredDate}
                                    onChange={handleChangeDate}
                                    defaultDate={new Date()}
                                    minDate="2020-01-01"
                                />
                            </FormRowItem>
                            <InputTimeWrapper>
                                <Input
                                    isSecondary
                                    type="time"
                                    name="escalationOccurredTime"
                                    value={escalationOccurredTime}
                                    onChange={handleChangeEscalationOccurredTime}
                                />
                            </InputTimeWrapper>
                        </FormRow>
                        <FormRow isStacked>
                            <FormRowTitle isStacked>Situation summary</FormRowTitle>
                            <FormRowItem>
                                <TextArea
                                    name="escalationSummary"
                                    value={escalationSummary || ''}
                                    onChange={({ value }) => {
                                        setEscalationSummary(value)
                                    }}
                                    height="180px"
                                />
                            </FormRowItem>
                        </FormRow>
                        <FormRow>
                            <FormRowTitle>Assigned to</FormRowTitle>
                            <FormRowItem>
                                <TypeSelect
                                    name="escalationSupportUserId"
                                    value={Number(escalationSupportUserId) || ''}
                                    options={[{ title: 'Choose…', value: '' }, ...supportUsersWithFullName]}
                                    onChange={({ value }) => setEscalationSupportUserId(Number(value))}
                                    autoClear
                                    isRequired
                                    showOptions
                                />
                            </FormRowItem>
                        </FormRow>
                    </Column>
                    <Column>
                        <FormRow>
                            <FormRowTitle>Accountant</FormRowTitle>
                            <FormRowItem>
                                <TypeSelect
                                    name="escalationAccountantId"
                                    value={Number(escalationAccountantId) || ''}
                                    options={[{ title: 'Choose…', value: '' }, ...accountantsWithFullName]}
                                    onChange={({ value }) => setEscalationAccountantId(Number(value))}
                                    autoClear
                                    isRequired
                                    showOptions
                                />
                            </FormRowItem>
                        </FormRow>

                        <FormRow>
                            <FormRowTitle>
                                <Checkbox
                                    label="Has been reassigned"
                                    name="escalationHasReassigned"
                                    checked={escalationHasReassigned}
                                    onChange={({ checked }) => setEscalationHasReassigned(checked)}
                                    isSmall
                                />
                            </FormRowTitle>
                            {escalationHasReassigned && (
                                <FormRowItem>
                                    <TypeSelect
                                        name="escalationNewAccountantId"
                                        value={Number(escalationNewAccountantId) || ''}
                                        options={[{ title: 'Choose…', value: '' }, ...accountantsWithFullName]}
                                        onChange={({ value }) => setEscalationNewAccountantId(Number(value))}
                                        autoClear
                                        isRequired
                                        showOptions
                                    />
                                </FormRowItem>
                            )}
                        </FormRow>

                        <FormRow>
                            <FormRowTitle>
                                <Checkbox
                                    label="Mark as closed"
                                    name="escalationClosedStatus"
                                    checked={escalationClosedStatus}
                                    onChange={handleChangeClosedStatus}
                                    isSmall
                                />
                            </FormRowTitle>
                            <FormRowItem>
                                <DatePicker
                                    name="escalationClosedDate"
                                    value={escalationClosedDate}
                                    onChange={handleChangeDate}
                                    defaultDate={new Date()}
                                    minDate="2020-01-01"
                                    disabled={!escalationClosedStatus}
                                />
                            </FormRowItem>
                            <InputTimeWrapper>
                                <Input
                                    isSecondary
                                    type="time"
                                    name="escalationClosedTime"
                                    value={escalationClosedTime}
                                    onChange={handleChangeEscalationClosedTime}
                                    disabled={!escalationClosedStatus}
                                />
                            </InputTimeWrapper>
                        </FormRow>

                        {!isEditing && (
                            <CustomerEscalationsModalNotesCreate handleClick={handleSilentCreateAndOpenEditModal} />
                        )}

                        {isEditing && (
                            <CustomerEscalationsModalNotes
                                customerId={escalationCustomerId}
                                escalationId={escalationId}
                            />
                        )}
                    </Column>
                </Columns>
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button isSecondary onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit}>{isEditing ? 'Save' : 'Add'}</Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}

CustomerEscalationsItemModal.propTypes = {
    customerAccountantId: PropTypes.number,
    customerFullName: PropTypes.string,
    customerId: PropTypes.number,
    escalation: PropTypes.object,
    handleCancel: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
}

CustomerEscalationsItemModal.defaultProps = {
    customerAccountantId: undefined,
    customerFullName: undefined,
    customerId: undefined,
    escalation: {},
}

export default CustomerEscalationsItemModal
