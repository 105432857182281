import PropTypes from 'prop-types'

import { Page } from '@scouts/ui'

import ProfileSidebar from './ProfileSidebar'

const ProfilePageWithSidebar = ({ children }) => (
    <Page withSidebar>
        <ProfileSidebar />
        <Page.MainColumn>
            <Page.Main withPadding>{children}</Page.Main>
        </Page.MainColumn>
    </Page>
)

ProfilePageWithSidebar.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ProfilePageWithSidebar
