import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Checkbox, IconCommentSlash, Label, Spacer, Table } from '@scouts/ui'

import {
    PATH_CUSTOMER_SELF_ASSESSMENTS,
    PATH_CUSTOMERS,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    TAX_YEARS,
} from '../../constants'
import { isAdmin, isDateDue } from '../../helpers'
import { AccountantStatusBubble } from '../accountants/AccountantStatusBubble'
import { CustomerIndicators } from '../customer-indicators'
import NotesIndicator from '../NotesIndicator'
import { SelfAssessmentsTableNotifications } from './SelfAssessmentsTableNotifications'
import { SelfAssessmentStatusLabel } from './SelfAssessmentStatusLabel'

const Customer = styled.div``

const AccountantName = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    line-height: 1.3;
    display: flex;
    align-items: center;
    margin-top: 6px;
`

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
`

const SelfAssessment = styled.div``

const TaxYear = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const OnHold = styled.span`
    font-size: ${font.small};
`

const LastUpdated = styled.div`
    font-size: ${font.small};
`

const Files = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
`

const IconUnsubscribed = styled.div`
    margin-right: 18px;
`

const SelfAssessmentsTableRow = (props) => {
    const { selfAssessment, isSelected, handleSelect, triggerTableUpdate, isAdminUser } = props

    const {
        accountantFullName,
        accountantStatus,
        customerAgentAuthorisationStatus,
        customerEmail,
        customerFullName,
        customerHasOutstandingPayments,
        customerId,
        customerIdentityVerificationStatus,
        customerPhoneNumber,
        customerTaxRegistrationStatus,
        customerUniqueTaxpayerReference,
        customerUnresolvedNoteCount,
        customerUnsubscribeFromBulkEmails,
        filedDateUTC,
        hasPaid,
        id: selfAssessmentId,
        isOnHold,
        latestEventDateUTC,
        numberOfFiles,
        payAfterFiling,
        priorityUnresolvedNotes,
        status,
        taxYear,
        payThroughTaxCodeRequested,
    } = selfAssessment

    const selfAssessmentUrl = generatePath(PATH_CUSTOMER_SELF_ASSESSMENTS, { customerId, selfAssessmentId })

    const hasBeenWaitingLong =
        hasPaid === false && status === SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND && isDateDue(filedDateUTC, 30)

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={selfAssessmentUrl}>
                    <Customer>
                        <Name>{customerFullName}</Name>
                        <Email>{customerEmail}</Email>
                        {isAdminUser && accountantFullName && (
                            <AccountantName>
                                {accountantFullName}{' '}
                                <Spacer marginLeft="6px">
                                    <AccountantStatusBubble status={accountantStatus} />
                                </Spacer>
                            </AccountantName>
                        )}
                    </Customer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="18%">
                <Table.Body.Cell.ContentLink Link={Link} to={selfAssessmentUrl}>
                    <CustomerIndicators
                        agentAuthorisationStatus={customerAgentAuthorisationStatus}
                        taxRegistrationStatus={customerTaxRegistrationStatus}
                        uniqueTaxpayerReference={customerUniqueTaxpayerReference}
                        identityVerificationStatus={customerIdentityVerificationStatus}
                        payThroughTaxCodeRequested={payThroughTaxCodeRequested}
                    />
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={selfAssessmentUrl}>
                    <SelfAssessment>
                        <TaxYear>{TAX_YEARS[taxYear]}</TaxYear>
                        <SelfAssessmentStatusLabel status={status} />
                        {hasBeenWaitingLong && (
                            <>
                                {' '}
                                <Label color={colors.white} background={colors.black}>
                                    30+ days
                                </Label>
                            </>
                        )}
                        {isOnHold && <OnHold> (On hold)</OnHold>}
                        {payAfterFiling && (
                            <>
                                {' '}
                                <Label color={colors.black} background={colors.purpleLighter}>
                                    Pay later
                                </Label>
                            </>
                        )}
                        {customerHasOutstandingPayments && (
                            <>
                                {' '}
                                <Label color={colors.black} background={colors.yellow}>
                                    Has outstanding payments
                                </Label>
                            </>
                        )}
                    </SelfAssessment>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="48px">
                <Table.Body.Cell.Content>
                    <SelfAssessmentsTableNotifications selfAssessment={selfAssessment} />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(12% - 48px)">
                <Table.Body.Cell.ContentLink
                    Link={Link}
                    to={`${PATH_CUSTOMERS}/${customerId}/history/${selfAssessmentId}`}
                >
                    <LastUpdated title={latestEventDateUTC}>{moment.utc(latestEventDateUTC).fromNow()}</LastUpdated>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="5%">
                <Table.Body.Cell.ContentLink
                    Link={Link}
                    to={`${PATH_CUSTOMERS}/${customerId}/files/${selfAssessmentId}`}
                >
                    <Files>{numberOfFiles || ''}</Files>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>

            <Table.Body.Cell width="calc(25% - 96px)">
                <Table.Body.Cell.Content>
                    <NotesIndicator
                        notePreviews={priorityUnresolvedNotes}
                        numberOfNotes={customerUnresolvedNoteCount || 0}
                        customerId={customerId}
                        customerFullName={customerFullName}
                        triggerTableUpdate={triggerTableUpdate}
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>

            <Table.Body.Cell width="96px">
                <Table.Body.Cell.Content alignRight>
                    {customerUnsubscribeFromBulkEmails && (
                        <IconUnsubscribed title="Unsubscribed from bulk emails">
                            <IconCommentSlash color={colors.neutralDarker} />
                        </IconUnsubscribed>
                    )}
                    <Checkbox
                        isLabelless
                        label="Select"
                        name={`${customerId}${selfAssessmentId || ''}`}
                        value={String(customerId)}
                        checked={isSelected}
                        onChange={() =>
                            handleSelect({
                                customerId,
                                fullName: customerFullName,
                                selfAssessmentId,
                                status,
                                unsubscribeFromBulkEmails: customerUnsubscribeFromBulkEmails,
                                customerPhoneNumber,
                            })
                        }
                    />
                </Table.Body.Cell.Content>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

SelfAssessmentsTableRow.propTypes = {
    handleSelect: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    selfAssessment: PropTypes.object.isRequired,
    triggerTableUpdate: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => ({
    isAdminUser: isAdmin(user),
})

export default connect(mapStateToProps)(SelfAssessmentsTableRow)
