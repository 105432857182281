import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useQueryParams } from '@scouts/helpers'

import { CompanyReturnSearchOptions, CompanyReturnStatusFilter } from '@/models'

import { usePagination } from '@/components/pagination'

// @TODO: add filter + create a single useFilters file for all tables
const DEFAULT_FILTER = {
    includeUnpaid: false,
    returnOnHold: false,
    status: CompanyReturnStatusFilter.Submitted,
}

export const useFilters = () => {
    const history = useHistory()
    const { defaultPaginationFilter } = usePagination()
    const { queryParams, updateQueryParams } = useQueryParams()

    const activeFilters = useMemo(() => {
        const { filter } = queryParams

        if (!filter) {
            return { ...DEFAULT_FILTER, ...defaultPaginationFilter }
        }

        try {
            const parsedFilter = JSON.parse(atob(filter as string))
            return parsedFilter
        } catch {
            return { ...DEFAULT_FILTER, ...defaultPaginationFilter }
        }
    }, [queryParams, defaultPaginationFilter])

    const applyFilter = ({ updatedFilter }: { updatedFilter: CompanyReturnSearchOptions }) => {
        const filter = btoa(JSON.stringify({ ...activeFilters, ...updatedFilter, ...defaultPaginationFilter }))
        history.replace(updateQueryParams({ filter }))
    }

    const clearFilters = () => {
        const filter = btoa(JSON.stringify({ ...DEFAULT_FILTER, ...defaultPaginationFilter }))
        history.replace(updateQueryParams({ filter }))
    }

    const removeFilter = ({ updatedFilter }: { updatedFilter: CompanyReturnSearchOptions }) => {
        const currentlyActiveFilters = { ...activeFilters }
        Object.keys(updatedFilter).forEach((filter) => delete currentlyActiveFilters[filter])
        const filter = btoa(JSON.stringify({ ...currentlyActiveFilters, ...defaultPaginationFilter }))

        history.replace(updateQueryParams({ filter }))
    }

    return {
        DEFAULT_FILTER,
        activeFilters,
        applyFilter,
        clearFilters,
        removeFilter,
    }
}
