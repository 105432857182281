import PropTypes from 'prop-types'

import { Flexer, Spacer } from '@scouts/ui'

import SendMessagesContainer from './SendMessagesContainer'

const BulkActions = (props) => {
    const { selectedItems, deselectAll } = props

    const numberOfItems = selectedItems.length

    const selectedCustomers = selectedItems.reduce((unique, o) => {
        if (!unique.some((obj) => obj.customerId === o.customerId)) {
            unique.push(o)
        }
        return unique
    }, [])

    return (
        <Flexer alignCenter justifyEnd gap="24px">
            <Spacer>{numberOfItems} selected</Spacer>
            <SendMessagesContainer customers={selectedCustomers} onSuccess={deselectAll} />
        </Flexer>
    )
}

BulkActions.propTypes = {
    deselectAll: PropTypes.func.isRequired,
    selectedItems: PropTypes.array.isRequired,
}

export default BulkActions
