import LoadingBar from 'react-redux-loading-bar'
import styled, { keyframes } from 'styled-components'

import { colors } from '@scouts/ui'

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`

const Loader = styled(LoadingBar)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    height: 3px;
    background: ${colors.blue};
    animation: ${pulse} 1s linear infinite;
`

export default Loader
