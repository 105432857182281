import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _some from 'lodash/some'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'

import {
    colors,
    font,
    media,
    Button,
    ButtonLink,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Notice,
    Radio,
    TextArea,
} from '@scouts/ui'

import {
    MESSAGE_ATTACHMENT_FILE_EXTENSIONS,
    MESSAGE_ATTACHMENT_FILE_MIME_TYPES,
    MESSAGE_ATTACHMENT_FILE_SIZE_SUM_LIMIT,
    MESSAGE_SMS_LENGTH_CAP,
    MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL,
    MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_CONTENT,
    MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_SUBJECT,
    MESSAGE_TEXT_TYPE_GENERAL,
    MESSAGE_TEXT_TYPE_GENERAL_ACCOUNTANT_SUBJECT,
    MESSAGE_TEXT_TYPE_GENERAL_CONTENT,
    MESSAGE_TEXT_TYPE_GENERAL_SUBJECT,
    MESSAGE_TEXT_TYPE_START_RETURN,
    MESSAGE_TEXT_TYPE_START_RETURN_CONTENT,
    MESSAGE_TEXT_TYPE_START_RETURN_SUBJECT,
    MESSAGE_TYPE_EMAIL,
    MESSAGE_TYPE_SMS,
    MESSAGE_USER_TYPE_ADMIN_SIGNATURE,
    PATH_CUSTOMERS,
    PATH_TEMPLATES,
    SELF_ASSESSMENT_STATUS_LIST_FOR_SMS,
} from '@/constants'
import { isAdmin } from '@/helpers'

import { MessageTemplateSelect } from './message-templates/MessageTemplateSelect'
import SendMessagesFileInput from './SendMessagesFileInput'

const Subtitle = styled.div`
    margin-bottom: 12px;
    padding-bottom: 9px;
    border-bottom: 1px solid ${colors.neutralLightest};

    ${media.tablet} {
        font-size: ${font.normal};
    }
`

const MessageContainer = styled.div`
    position: relative;
    background: ${colors.redLighter};
    padding: 6px 24px;
`

const TextCounter = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    text-align: right;
    align-self: center;
    padding: 4px 6px;
`

const MessageLine = styled.div`
    display: block;
    padding: 9px;
    font-size: ${font.normal};
    margin: 6px 0;
`

const FooterAligner = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
`

const MessageTypeContainer = styled.div`
    margin: 24px 0;
`

const MessageType = styled.div`
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 12px;

    &:last-child {
        margin-right: 0;
    }
`

const MessageTextType = styled.div`
    &:not(:last-child) {
        margin-bottom: 15px;
    }
`

const File = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 6px;
`

const FileName = styled.div`
    color: ${({ isError }) => (isError ? colors.red : colors.blue)};
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 180px;
    text-overflow: ellipsis;
    padding: 6px 12px;
    background: ${({ isError }) => (isError ? colors.redLighter : colors.blueLighter)};
    border: 0;
    border-radius: 9999px;
`

const FileAction = styled.div`
    margin-left: 12px;
`

const FilesContainer = styled.div`
    margin: 18px 0;
`
const FileUploadContainer = styled.div`
    margin: 18px 0;
`

const Title = styled.div`
    margin: 18px 0;
    font-size: ${font.small};
`

const LinkToProfile = styled.div`
    font-size: ${font.small};
`
class SendMessages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            combinedFileSizes: 0,
            messageContent: '',
            messageTextType: MESSAGE_TEXT_TYPE_GENERAL,
            messageType: MESSAGE_TYPE_EMAIL,
            shouldSkipSelection: false,
            subject: '',
            supportedFiles: [],
            textTypeSelected: false,
            unsupportedFiles: [],
            messageTemplateId: '',
        }

        _bindAll(this, [
            'checkAvailableFields',
            'handleChange',
            'handleFileChange',
            'handleMessageContentChange',
            'handleMessageTemplateChange',
            'handleMessageTextTypeChange',
            'handleSubmit',
            'openManageTemplates',
            'removeSupportedFile',
            'removeUnSupportedFile',
            'setMessageContent',
        ])
    }

    componentDidMount() {
        this.checkAvailableFields()
    }

    handleSubmit() {
        const { messageContent, subject, supportedFiles, messageType } = this.state
        const { onSubmit } = this.props

        onSubmit({ messageContent, subject, attachments: supportedFiles, messageType })
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value })
    }

    handleMessageContentChange({ value }) {
        this.setState({
            messageContent: value,
            hasEditedTemplateMessage: true,
        })
    }

    handleMessageTemplateChange({ value: selectedTemplateId, template: selectedTemplate }) {
        const { messageContent, hasEditedTemplateMessage } = this.state

        if (messageContent && hasEditedTemplateMessage) {
            const isConfirmed = window.confirm('Are you sure?\n\nYou will lose the current message')
            if (!isConfirmed) return
        }

        if (selectedTemplateId) {
            this.setState({
                messageTemplateId: selectedTemplateId,
                messageContent: selectedTemplate?.content ?? '',
                hasEditedTemplateMessage: false,
            })
        } else {
            this.setState({
                messageTemplateId: '',
                messageContent: '',
                hasEditedTemplateMessage: false,
            })
        }
    }

    handleFileChange(files) {
        const { supportedFiles, unsupportedFiles, combinedFileSizes } = this.state
        let fileSizes = combinedFileSizes

        if (files && files.length) {
            files.forEach((file) => {
                const { type, name, size } = file

                fileSizes += size

                if (
                    !MESSAGE_ATTACHMENT_FILE_MIME_TYPES.includes(type) ||
                    fileSizes > MESSAGE_ATTACHMENT_FILE_SIZE_SUM_LIMIT
                ) {
                    const unsupportedReason = !MESSAGE_ATTACHMENT_FILE_MIME_TYPES.includes(type) ? 'type' : 'size'

                    if (!unsupportedFiles.some((item) => item.file.name === name)) {
                        unsupportedFiles.push({ file, unsupportedReason })
                    }
                } else if (!supportedFiles.some((item) => item.name === name)) {
                    supportedFiles.push(file)
                }
            })
        }

        this.setState({
            combinedFileSizes: fileSizes,
            supportedFiles,
            unsupportedFiles,
        })
    }

    handleMessageTextTypeChange({ value }) {
        this.setState({ messageTextType: value })
    }

    setMessageContent() {
        const { messageTextType } = this.state
        let subject = ''
        let messageContent = ''

        if (messageTextType === MESSAGE_TEXT_TYPE_GENERAL) {
            messageContent = MESSAGE_TEXT_TYPE_GENERAL_CONTENT
            subject = MESSAGE_TEXT_TYPE_GENERAL_SUBJECT
        } else if (messageTextType === MESSAGE_TEXT_TYPE_START_RETURN) {
            messageContent = MESSAGE_TEXT_TYPE_START_RETURN_CONTENT
            subject = MESSAGE_TEXT_TYPE_START_RETURN_SUBJECT
        } else if (messageTextType === MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL) {
            messageContent = MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_CONTENT
            subject = MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_SUBJECT
        }

        this.setState({ messageContent, subject })
    }

    checkAvailableFields() {
        const { availableTypes, customers, user } = this.props
        const shouldShowReturningCustomerMessageOption =
            customers.length > 0 ? typeof customers[0].currentSelfAssessmentOneClickTaxReturn !== 'undefined' : false
        const hasOthersSelectedBesidesOneClick = _some(customers, ['currentSelfAssessmentOneClickTaxReturn', null])
        const availableTypesCount = availableTypes.length

        let textTypeSelected = false
        let shouldSkipSelection = false
        let messageTextType = MESSAGE_TEXT_TYPE_GENERAL
        let messageContent = MESSAGE_TEXT_TYPE_GENERAL_CONTENT
        let subject = isAdmin(user) ? MESSAGE_TEXT_TYPE_GENERAL_SUBJECT : MESSAGE_TEXT_TYPE_GENERAL_ACCOUNTANT_SUBJECT

        if (availableTypesCount === 1) {
            shouldSkipSelection = true
            ;[messageTextType] = availableTypes
        } else if (availableTypesCount > 1 && !shouldShowReturningCustomerMessageOption) {
            shouldSkipSelection = true
        } else if (availableTypesCount > 1 && shouldShowReturningCustomerMessageOption) {
            messageTextType = MESSAGE_TEXT_TYPE_START_RETURN

            if (hasOthersSelectedBesidesOneClick) {
                shouldSkipSelection = true
                messageTextType = MESSAGE_TEXT_TYPE_GENERAL
            }
        }

        if (messageTextType === MESSAGE_TEXT_TYPE_START_RETURN) {
            messageContent = MESSAGE_TEXT_TYPE_START_RETURN_CONTENT
            subject = MESSAGE_TEXT_TYPE_START_RETURN_SUBJECT
        } else if (messageTextType === MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL) {
            messageContent = MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_CONTENT
            subject = MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL_SUBJECT
        }

        if (shouldSkipSelection) {
            textTypeSelected = true
        }

        this.setState({ messageTextType, textTypeSelected, messageContent, subject, shouldSkipSelection })
    }

    openManageTemplates() {
        const { messageContent, hasEditedTemplateMessage } = this.state

        if (messageContent && hasEditedTemplateMessage) {
            const isConfirmed = window.confirm('Are you sure?\n\nYou will lose the current message')
            if (!isConfirmed) return
        }

        const { history } = this.props
        history.push({ pathname: PATH_TEMPLATES, state: { isFromCreateNewOption: true } })
    }

    removeSupportedFile(name, size) {
        const { supportedFiles, combinedFileSizes } = this.state

        const newCombinedFileSizes = combinedFileSizes - size
        const filteredFiles = supportedFiles.filter((item) => item.name !== name)

        this.setState({ supportedFiles: filteredFiles, combinedFileSizes: newCombinedFileSizes })
    }

    removeUnSupportedFile(name, size) {
        const { unsupportedFiles, combinedFileSizes } = this.state

        const newCombinedFileSizes = combinedFileSizes - size
        const filteredFiles = unsupportedFiles.filter((item) => item.file.name !== name)

        this.setState({ unsupportedFiles: filteredFiles, combinedFileSizes: newCombinedFileSizes })
    }

    goToView(view) {
        this.setMessageContent()
        this.setState({ messageTextType: view, textTypeSelected: true })
    }

    canSendSms() {
        const { customers, user } = this.props

        return (
            customers.length > 0 &&
            (isAdmin(user) || customers.every(({ status }) => SELF_ASSESSMENT_STATUS_LIST_FOR_SMS.includes(status)))
        )
    }

    renderProfileLink() {
        const { customers } = this.props
        const customersCount = customers.length

        if (customersCount !== 1) return null

        const customer = _get(customers, [0])

        return (
            <LinkToProfile>
                <Link to={`${PATH_CUSTOMERS}/${customer.customerId}/`} target="_blank">
                    Open profile in separate tab
                </Link>
            </LinkToProfile>
        )
    }

    render() {
        const {
            messageContent,
            messageTextType,
            messageType,
            shouldSkipSelection,
            subject,
            supportedFiles,
            textTypeSelected,
            unsupportedFiles,
            messageTemplateId,
        } = this.state

        const {
            availableTypes,
            customers,
            isSubmitPending,
            isSuccess,
            onCancel,
            successResponse,
            unsubscribedCustomers,
            user,
        } = this.props

        const { fullName: userFullName } = user
        const senderFullName = isAdmin(user) ? MESSAGE_USER_TYPE_ADMIN_SIGNATURE : userFullName

        const shouldShowReturningCustomerMessageOption =
            customers.length > 0 ? typeof customers[0].currentSelfAssessmentOneClickTaxReturn !== 'undefined' : false

        const hasOthersSelectedBesidesOneClick = _some(customers, ['currentSelfAssessmentOneClickTaxReturn', null])

        const customersCount = customers.length
        const unsubscribedCount = unsubscribedCustomers.length

        const showTypeSelection = this.canSendSms()

        const customersWithoutPhoneCount = customers.filter(
            ({ phoneNumber }) => !phoneNumber || phoneNumber.length < 7
        ).length

        const messageLength = messageContent.length
        const isSubmitDisabled =
            customers.length === 0 || (messageLength > MESSAGE_SMS_LENGTH_CAP && messageType === MESSAGE_TYPE_SMS)

        let smsLabel = 'Text (SMS)'

        if (customersCount === customersWithoutPhoneCount) {
            smsLabel = 'Text (SMS) (no phone number set)'
        } else if (customersCount > 1 && customersWithoutPhoneCount > 0 && messageType === MESSAGE_TYPE_SMS) {
            const clientLabel = customersWithoutPhoneCount === 1 ? 'client' : 'clients'
            const numberLabel = customersWithoutPhoneCount === 1 ? 'number' : 'numbers'

            smsLabel = `Text (SMS) (excluding ${customersWithoutPhoneCount} ${clientLabel} without phone ${numberLabel})`
        }

        let successfulSentCount = 0

        if (customersCount > 1 || (customersCount === 1 && messageType === MESSAGE_TYPE_SMS)) {
            successfulSentCount = _get(successResponse, [0, 'success'], 0)
        } else {
            successfulSentCount = _find(successResponse, ['messageFailure', false]) ? 1 : 0
        }

        const hasLargeFiles = _find(unsupportedFiles, { unsupportedReason: 'size' })
        const hasUnsupportedTypes = _find(unsupportedFiles, { unsupportedReason: 'type' })

        return (
            <Modal isWide onClose={onCancel}>
                {!isSuccess && (
                    <Form onSubmit={this.handleSubmit}>
                        <ModalHeader>
                            <ModalTitle>
                                {customersCount === 1 && <>Message to {_get(customers, [0, 'fullName'], null)}</>}
                                {customersCount > 1 && <>Message to {customersCount} clients</>}
                            </ModalTitle>
                            {customersCount === 1 && <ModalSubtitle>{this.renderProfileLink()}</ModalSubtitle>}
                            {unsubscribedCount > 0 && (
                                <ModalSubtitle>
                                    {unsubscribedCount} {unsubscribedCount === 1 ? 'client is ' : 'clients are '}
                                    unsubscribed from bulk messages
                                </ModalSubtitle>
                            )}
                        </ModalHeader>
                        {!textTypeSelected && (
                            <ModalBody>
                                <MessageTypeContainer>
                                    <Subtitle>Message</Subtitle>
                                    {availableTypes.includes(MESSAGE_TEXT_TYPE_GENERAL) && (
                                        <MessageTextType>
                                            <Radio
                                                name="messageTextType"
                                                value={MESSAGE_TEXT_TYPE_GENERAL}
                                                label="General enquiry & other"
                                                onChange={this.handleMessageTextTypeChange}
                                                checked={messageTextType === MESSAGE_TEXT_TYPE_GENERAL}
                                            />
                                        </MessageTextType>
                                    )}
                                    {availableTypes.includes(MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL) && (
                                        <MessageTextType>
                                            <Radio
                                                name="messageTextType"
                                                value={MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL}
                                                label="Agent authorisation failure"
                                                onChange={this.handleMessageTextTypeChange}
                                                checked={messageTextType === MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL}
                                            />
                                        </MessageTextType>
                                    )}
                                    {shouldShowReturningCustomerMessageOption &&
                                        availableTypes.includes(MESSAGE_TEXT_TYPE_START_RETURN) && (
                                            <MessageTextType>
                                                <Radio
                                                    name="messageTextType"
                                                    value={MESSAGE_TEXT_TYPE_START_RETURN}
                                                    label="Your tax return has been drafted – ready to start"
                                                    onChange={this.handleMessageTextTypeChange}
                                                    checked={messageTextType === MESSAGE_TEXT_TYPE_START_RETURN}
                                                    disabled={hasOthersSelectedBesidesOneClick}
                                                />
                                            </MessageTextType>
                                        )}
                                </MessageTypeContainer>
                            </ModalBody>
                        )}
                        {textTypeSelected && (
                            <ModalBody>
                                {showTypeSelection && (
                                    <MessageTypeContainer>
                                        <MessageType>Send as</MessageType>
                                        <MessageType>
                                            <Radio
                                                name="messageType"
                                                value={MESSAGE_TYPE_EMAIL}
                                                label="Email"
                                                onChange={this.handleChange}
                                                checked={messageType === MESSAGE_TYPE_EMAIL}
                                            />
                                        </MessageType>
                                        <MessageType>
                                            <Radio
                                                name="messageType"
                                                value={MESSAGE_TYPE_SMS}
                                                label={smsLabel}
                                                onChange={this.handleChange}
                                                checked={messageType === MESSAGE_TYPE_SMS}
                                                disabled={customersCount === customersWithoutPhoneCount}
                                            />
                                        </MessageType>
                                    </MessageTypeContainer>
                                )}
                                <Subtitle>
                                    Message template: &nbsp;&nbsp;
                                    <MessageTemplateSelect
                                        name="messageTemplateId"
                                        value={messageTemplateId}
                                        onChange={this.handleMessageTemplateChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <ButtonLink type="button" onClick={this.openManageTemplates}>
                                        Manage templates
                                    </ButtonLink>
                                </Subtitle>

                                {!showTypeSelection && <Subtitle>Subject: {subject}</Subtitle>}

                                <MessageContainer>
                                    <MessageLine>
                                        Hi {customersCount === 1 && <>{customers[0].fullName}</>}
                                        {customersCount > 1 && <>[client name]</>},
                                    </MessageLine>
                                    <TextArea
                                        autoFocus
                                        placeholder="Your message..."
                                        name="messageContent"
                                        value={messageContent}
                                        onChange={this.handleMessageContentChange}
                                        required
                                        height={messageType === MESSAGE_TYPE_SMS ? '190px' : '320px'}
                                        disabled={isSubmitPending}
                                        hasError={
                                            messageLength > MESSAGE_SMS_LENGTH_CAP && messageType === MESSAGE_TYPE_SMS
                                        }
                                    />

                                    <FooterAligner>
                                        <MessageLine>— {senderFullName}</MessageLine>
                                        {messageType === MESSAGE_TYPE_SMS && (
                                            <TextCounter>
                                                {messageLength <= MESSAGE_SMS_LENGTH_CAP
                                                    ? MESSAGE_SMS_LENGTH_CAP - messageLength
                                                    : `Text (SMS) messages are limited to ${MESSAGE_SMS_LENGTH_CAP} characters`}
                                            </TextCounter>
                                        )}
                                    </FooterAligner>
                                </MessageContainer>

                                {customersCount === 1 && messageType === MESSAGE_TYPE_EMAIL && (
                                    <>
                                        {supportedFiles.length > 0 && (
                                            <FilesContainer>
                                                {supportedFiles.map((file) => (
                                                    <File key={file.name}>
                                                        <FileName isError={file.unsupportedType}>{file.name}</FileName>
                                                        <FileAction>
                                                            <Button
                                                                isSecondary
                                                                onClick={() => {
                                                                    this.removeSupportedFile(file.name, file.size)
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </FileAction>
                                                    </File>
                                                ))}
                                            </FilesContainer>
                                        )}

                                        {(hasLargeFiles || hasUnsupportedTypes) && (
                                            <>
                                                <Notice background={colors.red}>
                                                    {hasLargeFiles && (
                                                        <p>
                                                            The attachments are over 10MB in size. Please send them to
                                                            your client via email.
                                                        </p>
                                                    )}
                                                    {hasUnsupportedTypes && (
                                                        <p>
                                                            Some of the files are in an unsupported format and can’t be
                                                            currently sent
                                                        </p>
                                                    )}
                                                </Notice>
                                                <Title>Following files will not be sent</Title>
                                            </>
                                        )}

                                        {unsupportedFiles.length > 0 && (
                                            <FilesContainer>
                                                {unsupportedFiles.map((item) => (
                                                    <File key={item.file.name}>
                                                        <FileName isError>{item.file.name}</FileName>
                                                        <FileAction>
                                                            <Button
                                                                isSecondary
                                                                onClick={() => {
                                                                    this.removeUnSupportedFile(
                                                                        item.file.name,
                                                                        item.file.size
                                                                    )
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </FileAction>
                                                    </File>
                                                ))}
                                            </FilesContainer>
                                        )}

                                        <FileUploadContainer>
                                            <SendMessagesFileInput
                                                label="Add attachments"
                                                onChange={this.handleFileChange}
                                                accept={MESSAGE_ATTACHMENT_FILE_EXTENSIONS}
                                            />
                                        </FileUploadContainer>
                                    </>
                                )}
                            </ModalBody>
                        )}

                        <ModalFooter>
                            <ModalActions>
                                {textTypeSelected && !shouldSkipSelection && (
                                    <Button isSecondary onClick={() => this.setState({ textTypeSelected: false })}>
                                        Back
                                    </Button>
                                )}

                                <Button isSecondary onClick={onCancel}>
                                    Cancel
                                </Button>

                                {!textTypeSelected && (
                                    <Button
                                        onClick={() => this.goToView(messageTextType)}
                                        disabled={customers.length === 0}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {textTypeSelected && (
                                    <Button type="submit" isLoading={isSubmitPending} disabled={isSubmitDisabled}>
                                        Send message
                                    </Button>
                                )}
                            </ModalActions>
                        </ModalFooter>
                    </Form>
                )}

                {isSuccess && (
                    <>
                        <ModalHeader>
                            {successfulSentCount > 1 && (
                                <ModalTitle>{successfulSentCount} messages successfully sent</ModalTitle>
                            )}
                            {successfulSentCount === 1 && <ModalTitle>Message successfully sent</ModalTitle>}
                            {successfulSentCount === 0 && <ModalTitle>Message sent</ModalTitle>}
                        </ModalHeader>
                        <ModalFooter>
                            <ModalActions>
                                <Button onClick={onCancel}>Got it</Button>
                            </ModalActions>
                        </ModalFooter>
                    </>
                )}
            </Modal>
        )
    }
}

SendMessages.propTypes = {
    availableTypes: PropTypes.array,
    customers: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    isSubmitPending: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    successResponse: PropTypes.array,
    unsubscribedCustomers: PropTypes.array,
    user: PropTypes.object.isRequired,
}

SendMessages.defaultProps = {
    availableTypes: [MESSAGE_TEXT_TYPE_GENERAL, MESSAGE_TEXT_TYPE_START_RETURN],
    successResponse: [],
    unsubscribedCustomers: [],
}

export default withRouter(SendMessages)
