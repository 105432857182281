import { useState } from 'react'

import { ButtonLink, Flexer, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { generateKeyFromValue } from '@/helpers'
import { AgentAuthorisationStatus, CustomerDTO } from '@/models'
import { useCustomersAgentAuthorisations } from '@/store/agent-authorisations'

import { AgentAuthAuthorised } from './AgentAuthAuthorised'
import { AgentAuthHeader } from './AgentAuthHeader'
import { AgentAuthItem } from './AgentAuthItem'
import { AgentAuthReminderNotice } from './AgentAuthReminderNotice'
import { AgentAuthStart } from './AgentAuthStart'

interface AgentAuthProps {
    customer: CustomerDTO
}

export const AgentAuth = ({ customer }: AgentAuthProps) => {
    const { id: customerId } = customer
    const { agentAuthorisations, isLoading } = useCustomersAgentAuthorisations({ customerId })
    const isAuthorised = customer.agentAuthorisationStatus === AgentAuthorisationStatus.ClientAuthorised

    const [isOpen, setIsOpen] = useState(!isAuthorised)

    const shouldDisplayAuthStart =
        !customer.agentAuthorisationStatus ||
        customer.agentAuthorisationStatus === AgentAuthorisationStatus.RequestExpired ||
        customer.agentAuthorisationStatus === AgentAuthorisationStatus.RequestFailed ||
        customer.agentAuthorisationStatus === AgentAuthorisationStatus.IncorrectCode ||
        customer.agentAuthorisationStatus === AgentAuthorisationStatus.UnableToIssue

    const hasAgentAuthorisations = !!agentAuthorisations && agentAuthorisations.length > 0

    const renderHeader = () => {
        switch (true) {
            case shouldDisplayAuthStart:
                return <AgentAuthStart customer={customer} customerAuthorisations={agentAuthorisations} />
            case isAuthorised:
                return <AgentAuthAuthorised customer={customer} />
            default:
                return <AgentAuthHeader status={customer.agentAuthorisationStatus} />
        }
    }

    if (isLoading)
        return (
            <Skeleton padding="24px">
                <SkeletonLine height="86px" />
            </Skeleton>
        )

    return (
        <>
            {renderHeader()}

            {hasAgentAuthorisations && (!isAuthorised || (isAuthorised && isOpen)) && (
                <Spacer marginTop="6px">
                    <Flexer column gap="6px">
                        {agentAuthorisations?.map((item) => (
                            <AgentAuthItem
                                customer={customer}
                                customerAuthorisations={agentAuthorisations}
                                agentAuthorisation={item}
                                key={generateKeyFromValue(item.updatedDateUTC)}
                            />
                        ))}
                    </Flexer>
                </Spacer>
            )}

            {!isOpen && (
                <Spacer marginTop="12px">
                    <Flexer justifyEnd>
                        <ButtonLink onClick={() => setIsOpen(true)}>View all agent authorisations</ButtonLink>
                    </Flexer>
                </Spacer>
            )}

            <AgentAuthReminderNotice status={customer.agentAuthorisationStatus} />
        </>
    )
}
