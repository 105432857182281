const PRODUCT_BUNDLE_REASON_COMPANY_SETUP = 'CompanySetup'
const PRODUCT_BUNDLE_REASON_LIMITED_COMPANY_TAXES = 'LimitedCompanyTaxes'
const PRODUCT_BUNDLE_REASON_FOREIGN_INCOME = 'ForeignIncomeOrTaxes'
const PRODUCT_BUNDLE_REASON_INHERITANCE_TAX = 'InheritanceTax'
const PRODUCT_BUNDLE_REASON_OTHER = 'Other'
const PRODUCT_BUNDLE_REASON_PROPERTY_CAPITAL_GAINS = 'PropertyCapitalGains'
const PRODUCT_BUNDLE_REASON_SELF_ASSESSMENT = 'SelfAssessment'
const PRODUCT_BUNDLE_REASON_SHARES_CRYPTO_CAPITAL_GAINS = 'SharesOrCryptoCapitalGains'
const PRODUCT_BUNDLE_REASON_TAX_PLANNING = 'TaxPlanning'

export const PRODUCT_BUNDLE_REASONS_TITLES = {
    [PRODUCT_BUNDLE_REASON_COMPANY_SETUP]: 'Company setup',
    [PRODUCT_BUNDLE_REASON_LIMITED_COMPANY_TAXES]: 'Limited company taxes',
    [PRODUCT_BUNDLE_REASON_FOREIGN_INCOME]: 'Foreign income',
    [PRODUCT_BUNDLE_REASON_INHERITANCE_TAX]: 'Inheritance tax',
    [PRODUCT_BUNDLE_REASON_OTHER]: 'Other',
    [PRODUCT_BUNDLE_REASON_PROPERTY_CAPITAL_GAINS]: 'Property capital gains',
    [PRODUCT_BUNDLE_REASON_SELF_ASSESSMENT]: 'Self Assessment',
    [PRODUCT_BUNDLE_REASON_SHARES_CRYPTO_CAPITAL_GAINS]: 'Shares or crypto capital gains',
    [PRODUCT_BUNDLE_REASON_TAX_PLANNING]: 'Tax planning',
}
