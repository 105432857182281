import { useHistory } from 'react-router-dom'

import { useToggle } from '@scouts/helpers'
import { ButtonLink, ConfirmationModal } from '@scouts/ui'

import { deleteCustomer } from '@/actions'
import { PATH_ROOT } from '@/constants'
import { CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'

export const DeleteCustomerData = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const [isModalOpen, open, close] = useToggle()
    const dispatch = useAppDispatch()
    const history = useHistory()

    const handleDeleteCustomer = () => {
        dispatch(
            deleteCustomer({
                id: customerId,
                onSuccess: () => {
                    history.push(PATH_ROOT)
                },
            })
        )
    }

    return (
        <>
            <ButtonLink onClick={open}>Delete client</ButtonLink>
            {isModalOpen && (
                <ConfirmationModal
                    title="Are you sure you want to delete this client?"
                    onCancel={close}
                    onConfirm={handleDeleteCustomer}
                />
            )}
        </>
    )
}
