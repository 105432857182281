import config from '../config'
import { EXTERNAL_API_NYLAS } from '../constants'

export const REQUEST_PAYE_ACCESS_TOKEN = 'REQUEST_PAYE_ACCESS_TOKEN'
export const REQUEST_PAYE_AUTH = 'REQUEST_PAYE_AUTH'
export const DELETE_PAYE_ACCESS_TOKEN = 'DELETE_PAYE_ACCESS_TOKEN'
export const CREATE_PAYE_ACCESS_TOKEN = 'CREATE_PAYE_ACCESS_TOKEN'
export const REQUEST_INTEGRATION_AUTHORISATION_URL = 'REQUEST_INTEGRATION_AUTHORISATION_URL'
export const REQUEST_INTEGRATION_ACCESS_TOKEN = 'REQUEST_INTEGRATION_ACCESS_TOKEN'
export const DELETE_INTEGRATION_ACCESS_TOKEN = 'DELETE_INTEGRATION_ACCESS_TOKEN'
export const CREATE_INTEGRATION_ACCESS_TOKEN = 'CREATE_INTEGRATION_ACCESS_TOKEN'

const requestIntegrationAuthorisationUrl = (payload, meta = {}) => ({
    type: REQUEST_INTEGRATION_AUTHORISATION_URL,
    payload,
    meta,
})

const createIntegrationAccessToken = (payload, meta = {}) => ({
    type: CREATE_INTEGRATION_ACCESS_TOKEN,
    payload,
    meta,
})

export const requestIntegrationAccessToken = (payload, meta = {}) => ({
    type: REQUEST_INTEGRATION_ACCESS_TOKEN,
    payload,
    meta,
})

const deleteIntegrationAccessToken = (payload, meta = {}) => ({
    type: DELETE_INTEGRATION_ACCESS_TOKEN,
    payload,
    meta,
})

export const requestPayeAccessToken = ({ onSuccess = null, onError = null }) => ({
    type: REQUEST_PAYE_ACCESS_TOKEN,
    meta: { onSuccess, onError },
})

export const requestPayeAuthorisation = ({ onSuccess = null, onError = null }) => ({
    type: REQUEST_PAYE_AUTH,
    meta: { onSuccess, onError },
})

export const deletePayeAccessToken = ({ externalAccessTokenId, onSuccess = null, onError = null }) => ({
    type: DELETE_PAYE_ACCESS_TOKEN,
    data: { externalAccessTokenId },
    meta: { onSuccess, onError },
})

export const createPayeAccessToken = ({ code, onSuccess = null, onError = null }) => ({
    type: CREATE_PAYE_ACCESS_TOKEN,
    data: { code },
    meta: { onSuccess, onError },
})

export const startNylasIntegration = ({ emailHint } = {}, meta) =>
    requestIntegrationAuthorisationUrl(
        { externalApi: EXTERNAL_API_NYLAS, redirectUri: config.callbackUriNylas, emailHint },
        meta
    )

export const createNylasAccessToken = ({ code }, meta) =>
    createIntegrationAccessToken({ code, externalApi: EXTERNAL_API_NYLAS, redirectUri: config.callbackUriNylas }, meta)

export const deleteNylasIntegration = (_, meta) =>
    deleteIntegrationAccessToken({ externalApi: EXTERNAL_API_NYLAS }, meta)
