import { colors, Label } from '@scouts/ui'

import { SelfAssessmentDomain } from '@/domain'
import { SelfAssessmentStatusTypes } from '@/models'

export const SelfAssessmentStatusLabel = ({ status }: { status: SelfAssessmentStatusTypes }) => {
    const currentStatus = SelfAssessmentDomain.statuses[status]

    if (!currentStatus) return null

    return (
        <Label background={currentStatus.color} color={colors.black}>
            {currentStatus.title}
        </Label>
    )
}
