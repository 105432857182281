export const RepaymentPlanType = {
    PlanType1: 'PlanType1',
    PlanType2: 'PlanType2',
    PlanType1And2: 'PlanType1And2',
    PlanTypeOther: 'PlanTypeOther',
} as const

export type RepaymentPlanTypeTypes = (typeof RepaymentPlanType)[keyof typeof RepaymentPlanType]

export interface StudentLoanDTO {
    selfAssessmentId: number
    repaymentBegan: boolean
    repaymentFromSalary: boolean | null
    additionalNotes: string | null
    amountRepaidLastYear: number | null
    totalRepaidFromHmrc: number | null
    synchedFromHmrc: boolean | null
    repaymentPlanType: RepaymentPlanTypeTypes
}

export const RepaymentPlanTypeTitles: Record<RepaymentPlanTypeTypes, string> = {
    PlanType1: 'Plan 1',
    PlanType2: 'Plan 2',
    PlanType1And2: 'Both plans',
    PlanTypeOther: 'Other',
}
