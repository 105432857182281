import { Container } from '@scouts/ui'

import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

interface CapitalGainsProps {
    capitalGains: SelfAssessmentDTO['capitalGains']
    koinlyReportSummary: SelfAssessmentDTO['koinlyReportSummary']
}

export const CapitalGains = ({ capitalGains, koinlyReportSummary }: CapitalGainsProps) => {
    const {
        additionalNotes,
        cryptoGains,
        numberOfCryptoTransactions,
        numberOfOtherTransactions,
        numberOfPropertyTransactions,
        numberOfShareTransactions,
        onlyInvestmentSchemeGains,
        otherGains,
        privateCompanyShares,
        propertyGains,
        propertyJointlyOwned,
        publicCompanyShares,
        residentialProperty,
        shareGains,
        totalCapitalGainsAmount,
        usedRealTimeCapitalGainsService,
    } = capitalGains || {}

    const { confirmedByCustomer, totalCapitalGains, totalCosts, totalExpenses, totalIncome, totalProceeds } =
        koinlyReportSummary || {}

    const shouldDisplayKoinlySummary = !!koinlyReportSummary && !!confirmedByCustomer

    return (
        <Container>
            <SectionData>
                <SectionDataRow label="Only investment scheme gains" value={onlyInvestmentSchemeGains} boolean />
                <SectionDataRow label="Total capital gains" value={formatCurrencyNotSure(totalCapitalGainsAmount)} />
                <SectionDataRow
                    label="Used real-time capital gains service"
                    value={usedRealTimeCapitalGainsService}
                    boolean
                />
            </SectionData>

            {shouldDisplayKoinlySummary && (
                <SectionData>
                    <SectionDataRow label="From Koinly" highlight />
                    <SectionDataRow
                        label="Disposable proceeds"
                        value={formatCurrencyNotSure(totalProceeds)}
                        rawValue={totalProceeds}
                        isVerified={confirmedByCustomer}
                    />
                    <SectionDataRow
                        label="Allowable costs (including purchase price)"
                        value={formatCurrencyNotSure(totalCosts)}
                        rawValue={totalCosts}
                        isVerified={confirmedByCustomer}
                    />
                    <SectionDataRow
                        label="Capital gains/losses (gains - losses)"
                        value={formatCurrencyNotSure(totalCapitalGains)}
                        rawValue={totalCapitalGains}
                        isVerified={confirmedByCustomer}
                    />

                    <SectionDataRow
                        label="Total income"
                        value={formatCurrencyNotSure(totalIncome)}
                        rawValue={totalIncome}
                        isVerified={confirmedByCustomer}
                    />
                    <SectionDataRow
                        label="Total expenses"
                        value={formatCurrencyNotSure(totalExpenses)}
                        rawValue={totalExpenses}
                        isVerified={confirmedByCustomer}
                    />
                </SectionData>
            )}

            <SectionData>
                <SectionDataRow label="Property gains" value={propertyGains} boolean highlight />
                <SectionDataRow
                    label="Number of property transactions"
                    value={
                        numberOfPropertyTransactions !== 0 && !numberOfPropertyTransactions
                            ? '?'
                            : numberOfPropertyTransactions
                    }
                />
                <SectionDataRow label="Residential property" value={residentialProperty} boolean />
                <SectionDataRow label="Property jointly owned" value={propertyJointlyOwned} boolean highlight />
            </SectionData>

            <SectionData>
                <SectionDataRow label="Share gains" value={shareGains} boolean highlight />
                <SectionDataRow label="Listed shares" value={publicCompanyShares} boolean />
                <SectionDataRow label="Unlisted shares" value={privateCompanyShares} boolean />
                <SectionDataRow
                    label="Number of share transactions"
                    value={
                        numberOfShareTransactions !== 0 && !numberOfShareTransactions ? '?' : numberOfShareTransactions
                    }
                />
            </SectionData>

            <SectionData>
                <SectionDataRow label="Cryptocurrency gains" value={cryptoGains} boolean highlight />
                <SectionDataRow
                    label="Number of crypto transactions"
                    value={
                        numberOfCryptoTransactions !== 0 && !numberOfCryptoTransactions
                            ? '?'
                            : numberOfCryptoTransactions
                    }
                />
            </SectionData>
            <SectionData>
                <SectionDataRow label="Other gains" value={otherGains} boolean highlight />
                <SectionDataRow
                    label="Number of other transactions"
                    value={
                        numberOfOtherTransactions !== 0 && !numberOfOtherTransactions ? '?' : numberOfOtherTransactions
                    }
                />
            </SectionData>

            {additionalNotes && (
                <SectionData>
                    <SectionDataRow label="Notes" value={additionalNotes} />
                </SectionData>
            )}
        </Container>
    )
}
