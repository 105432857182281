import { colors } from '@scouts/ui'

import { COMPANY_RETURN_FINAL_RETURN_FILE_TYPE_TITLE } from '@/constants/company-return'
import { formatDate } from '@/helpers'
import {
    CompanyReturnDTO,
    CompanyReturnStatus,
    CompanyReturnStatusFilter,
    CompanyReturnStatusFilterTitles,
    CompanyReturnStatusFilterTypes,
    CompanyReturnStatusTitles,
    FileTypeTitles,
    FileTypeTypes,
    PaymentStatus,
} from '@/models'

const statuses = {
    [CompanyReturnStatus.Created]: {
        color: colors.neutralLightest,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.Created],
    },
    [CompanyReturnStatus.Started]: {
        color: colors.neutralLightest,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.Started],
    },
    [CompanyReturnStatus.UploadingDocuments]: {
        color: colors.neutralLightest,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.UploadingDocuments],
    },
    [CompanyReturnStatus.Submitted]: {
        color: colors.blueLighter,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.Submitted],
    },
    [CompanyReturnStatus.AccountantReview]: {
        color: colors.blueLighter,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.AccountantReview],
    },
    [CompanyReturnStatus.InformationRequired]: {
        color: colors.yellow,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.InformationRequired],
    },
    [CompanyReturnStatus.AwaitingCustomerApproval]: {
        color: colors.yellow,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.AwaitingCustomerApproval],
    },
    [CompanyReturnStatus.ReadyToFile]: {
        color: colors.blueLighter,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.ReadyToFile],
    },
    [CompanyReturnStatus.Filed]: {
        color: colors.mintLighter,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.Filed],
    },
    [CompanyReturnStatus.CompletedNotFiled]: {
        color: colors.mintLighter,
        title: CompanyReturnStatusTitles[CompanyReturnStatus.CompletedNotFiled],
    },
    [CompanyReturnStatus.Cancelled]: {
        color: colors.redLighter,
        title: CompanyReturnStatus.Cancelled,
    },
}

const filterOptions: { value: CompanyReturnStatusFilterTypes; title: string }[] = [
    {
        value: CompanyReturnStatusFilter.Assigned,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.Assigned],
    },
    {
        value: CompanyReturnStatusFilter.Submitted,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.Submitted],
    },
    {
        value: CompanyReturnStatusFilter.InReview,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.InReview],
    },
    {
        value: CompanyReturnStatusFilter.InformationRequired,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.InformationRequired],
    },
    {
        value: CompanyReturnStatusFilter.AwaitingApproval,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.AwaitingApproval],
    },
    {
        value: CompanyReturnStatusFilter.ReadyToFile,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.ReadyToFile],
    },
    {
        value: CompanyReturnStatusFilter.Filed,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.Filed],
    },
    {
        value: CompanyReturnStatusFilter.CompletedNotFiled,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.CompletedNotFiled],
    },
    {
        value: CompanyReturnStatusFilter.Cancelled,
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.Cancelled],
    },
]

const filterOptionsMobile = [
    {
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.All],
    },
    ...filterOptions,
    {
        title: CompanyReturnStatusFilterTitles[CompanyReturnStatusFilter.OnHold],
        value: CompanyReturnStatusFilter.OnHold,
    },
]

export const CompanyReturnDomain = {
    statuses,
    filterOptions,
    filterOptionsMobile: filterOptionsMobile,
}

export const getFileTypeTitle = (fileType: FileTypeTypes) =>
    fileType === 'FinalReturn' ? COMPANY_RETURN_FINAL_RETURN_FILE_TYPE_TITLE : FileTypeTitles[fileType]

export const getIsCompanyReturnDeletable = (companyReturn: CompanyReturnDTO) => {
    return (
        !companyReturn.payment.hasPaid &&
        !companyReturn.payment.refundReason &&
        companyReturn.payment.status !== PaymentStatus.Pending &&
        !(
            companyReturn.status.current === CompanyReturnStatus.Filed ||
            companyReturn.status.current === CompanyReturnStatus.ReadyToFile
        )
    )
}

export const getCompanyReturnAccountingPeriodString = (companyReturn: CompanyReturnDTO) => {
    const hasAccountingPeriod = companyReturn.accountingPeriodStart && companyReturn.accountingPeriodEnd
    if (!hasAccountingPeriod) return ''

    const start = formatDate(companyReturn.accountingPeriodStart)
    const end = formatDate(companyReturn.accountingPeriodEnd)
    return `${start} - ${end}`
}
