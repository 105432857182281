import { useUploadTaxReliefClaimFileMutation } from '../api'

export const useUploadTaxReliefClaimFile = () => {
    const [uploadTaxReliefFile, { isError, isLoading }] = useUploadTaxReliefClaimFileMutation()

    return {
        uploadTaxReliefFile,
        isError,
        isLoading,
    }
}
