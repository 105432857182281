import { useToggle } from '@scouts/helpers'
import { colors, Button, Container, Flexer, Line, Loadable, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { useDiscountSchemes } from '@/store/discount-schemes/hooks'

import { DiscountSchemesAddModal } from './DiscountSchemesAddModal'
import { DiscountSchemesTable } from './DiscountSchemesTable'

export const DiscountSchemes = () => {
    const { discountSchemes, isLoading, isError, isFetching } = useDiscountSchemes()

    const [isAddModalOpen, openAddModal, closeAddModal] = useToggle()

    if (isError)
        return (
            <Container>
                <Flexer alignCenter justifyCenter>
                    <Line color={colors.neutralDarker}>Error getting discount codes</Line>
                </Flexer>
            </Container>
        )

    if (isLoading)
        return (
            <Skeleton gap="24px" padding="24px">
                <Flexer justifyEnd>
                    <SkeletonLine height="24px" width="120px" />
                </Flexer>
                <Flexer column gap="1px">
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="46px" repeat={10} />
                </Flexer>
            </Skeleton>
        )

    return (
        <>
            <Loadable isLoading={isFetching}>
                <Container padding="24px 24px 192px">
                    <Spacer marginBottom="24px">
                        <Flexer justifyEnd>
                            <Button size="small" onClick={openAddModal}>
                                Add new
                            </Button>
                        </Flexer>
                    </Spacer>

                    <DiscountSchemesTable discountSchemes={discountSchemes} />
                </Container>
            </Loadable>

            {isAddModalOpen && <DiscountSchemesAddModal onClose={closeAddModal} />}
        </>
    )
}
