export const APP_TITLE = 'TaxScouts Accountant'

export const INFO_EMAIL = 'info@taxscouts.com'
export const SUPPORT_EMAIL = 'support@taxscouts.com'

export const DEFAULT_CURRENCY_SIGN = '£'

export const CURRENT_TAX_YEAR = 2024
export const NEXT_TAX_YEAR = CURRENT_TAX_YEAR + 1

export const ACTIVE_TAX_YEARS = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013]

export const TAX_YEARS = {
    2024: '2023/24',
    2023: '2022/23',
    2022: '2021/22',
    2021: '2020/21',
    2020: '2019/20',
    2019: '2018/19',
    2018: '2017/18',
    2017: '2016/17',
    2016: '2015/16',
    2015: '2014/15',
    2014: '2013/14',
    2013: '2012/13',
}

export const MARITAL_STATUS_SINGLE = 'Single'
export const MARITAL_STATUS_MARRIED = 'Married'
export const MARITAL_STATUS_WITH_PARTNER = 'WithPartner'

export const MARITAL_STATUS_TITLES = {
    [MARITAL_STATUS_SINGLE]: 'Single',
    [MARITAL_STATUS_MARRIED]: 'Married',
    [MARITAL_STATUS_WITH_PARTNER]: 'With partner',
}

export const MONTH_NAMES_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const MONTH_NAMES_LONG = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const LINK_NOTION_DRAFTED_RETURNS =
    'https://www.notion.so/taxscouts/Drafted-returns-One-click-tax-returns-0d6ffa79971443859fb2ddaac85f0291'

export const REFERRAL_DISCOUNT_NAME = 'REFERRAL10'

export const CUSTOMER_TITLES = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Rev', 'Other']
export const CUSTOMER_TITLE_OTHER = 'Other'

export const SORT_ORDER_ASC = 'ASC'
export const SORT_ORDER_DESC = 'DESC'

export const SHARE_TRANSACTIONS_TEMPLATE_LINK =
    'https://taxscoutsassets.blob.core.windows.net/documents/Share_transactions_template.csv'
export const CRYPTO_TRANSACTIONS_TEMPLATE_LINK =
    'https://taxscoutsassets.blob.core.windows.net/documents/Crypto_transactions_template.csv'

export const SECTION_PAYMENT_ON_ACCOUNT = 'SECTION_PAYMENT_ON_ACCOUNT'

export const PRODUCT_STATUS_COMPLETED = 'Completed'
export const PRODUCT_STATUS_CANCELLED = 'Cancelled'
export const PRODUCT_STATUS_INCOMPLETE = 'Incomplete'

export const TAX_YEAR_START_MONTH = '04'
export const TAX_YEAR_START_DAY = '06'
export const TAX_YEAR_END_MONTH = TAX_YEAR_START_MONTH
export const TAX_YEAR_END_DAY = '05'

export const PAGINATION_VIEW_ALL_PAGE_SIZE = 999999999
export const PAGINATION_MINIMUM_PAGE_SIZE = 25
export const PAGINATION_DEFAULT_PAGE_SIZE = 50
export const PAGINATION_DEFAULT_PAGE = 1

export const LOGIN_TYPE_FACEBOOK = 'Facebook'
export const LOGIN_TYPE_GOOGLE = 'Google'
export const LOGIN_TYPE_EMAIL = 'EmailAndPassword'
// export const LOGIN_TYPE_OTHER = 'Other'

const COUNTRY_UK = 'United Kingdom'
export const COUNTRY_SCOTLAND = 'Scotland'
export const COUNTRY_WALES = 'Wales'
export const COUNTRY_NORTHERN_IRELAND = 'Northern Ireland'
export const COUNTRIES_UK = [COUNTRY_UK, COUNTRY_SCOTLAND, COUNTRY_WALES, COUNTRY_NORTHERN_IRELAND]

export const POSTCODES_SCOTLAND_REGEX = /^(ZE|KW|IV|HS|PH|AB|DD|PA|FK|G|KY|KA|DG|TD|EH|ML)[0-9]/i
export const POSTCODES_WALES_REGEX = /^(LL|SY|LD|HR|NP|CF|SA)[0-9]/i
export const POSTCODES_NORTHERN_IRELAND_REGEX = /^(BT)[0-9]/i
