import { useGetCustomerUnresolvedEscalationsCountQuery } from '@/store/escalations/api'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

export const useEscalationsCount = () => {
    const customerId = useCustomerIdFromPath()

    const { data: unresolvedEscalationsCount } = useGetCustomerUnresolvedEscalationsCountQuery({ customerId })

    return { unresolvedEscalationsCount, hasUnresolvedEscalations: unresolvedEscalationsCount > 0 }
}
