export const REQUEST_CUSTOMER_DISCOUNTS = 'REQUEST_CUSTOMER_DISCOUNTS'
export const RECEIVE_CUSTOMER_DISCOUNTS = 'RECEIVE_CUSTOMER_DISCOUNTS'
export const CREATE_CUSTOMER_DISCOUNT = 'CREATE_CUSTOMER_DISCOUNT'
export const REMOVE_CUSTOMER_DISCOUNT = 'REMOVE_CUSTOMER_DISCOUNT'
export const DELETE_CUSTOMER_DISCOUNT = 'DELETE_CUSTOMER_DISCOUNT'

export const requestCustomerDiscounts = ({ customerId }) => ({ type: REQUEST_CUSTOMER_DISCOUNTS, data: { customerId } })

export const receiveCustomerDiscounts = (data) => ({ type: RECEIVE_CUSTOMER_DISCOUNTS, data })

export const createCustomerDiscount = (data) => ({ type: CREATE_CUSTOMER_DISCOUNT, data })

export const deleteCustomerDiscount = (id) => ({ type: DELETE_CUSTOMER_DISCOUNT, data: id })

export const removeCustomerDiscount = (id) => ({ type: REMOVE_CUSTOMER_DISCOUNT, data: id })
