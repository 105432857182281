import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { exportEscalations, requestEscalations } from '../../../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE, PATH_ESCALATIONS } from '../../../constants'
import { createAxiosCancelSource } from '../../../helpers'
import { sendDecodingErrorsToSentry } from '../../../helpers/sentry'
import EscalationsSearchFilter from './EscalationsSearchFilter'

const Container = styled.div`
    margin-bottom: 24px;
`

class EscalationsSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: {},
            filterToken: null,
            page: 1,
            pageSize: 100,
        }

        _bindAll(this, ['applyFilter', 'replaceFilters', 'clearFilter', 'removeFilter', 'handleExport'])
    }

    componentDidMount() {
        const { location } = this.props

        const { filterToken } = this.parseFilterSettingsFromUrl(location)

        if (!filterToken) {
            this.applyFilter({ isResolved: false }, true)
        } else {
            this.update()
        }
    }

    componentDidUpdate() {
        this.update()
    }

    handleExport() {
        const { dispatch, location } = this.props

        const { filterToken, page, pageSize } = this.parseFilterSettingsFromUrl(location)
        const filter = this.parseFilterFromToken(filterToken)

        dispatch(exportEscalations({ filter, page, pageSize }))
    }

    applyFilter(updatedFilter, replace = false) {
        const { history } = this.props
        const { filter } = this.state

        const string = btoa(JSON.stringify({ ...filter, ...updatedFilter }))

        const query = queryString.stringify({ filter: string })

        const location = {
            pathname: PATH_ESCALATIONS,
            search: query,
        }

        if (replace) {
            history.replace(location)
        } else {
            history.push(location)
        }
    }

    replaceFilters(updatedFilter) {
        const { history } = this.props

        const string = btoa(JSON.stringify(updatedFilter))

        const query = queryString.stringify({ filter: string })

        const location = {
            pathname: PATH_ESCALATIONS,
            search: query,
        }

        history.push(location)
    }

    removeFilter(filterName) {
        const { history } = this.props
        const { filter } = this.state

        const updatedFilter = { ...filter }

        let filterNames = []
        if (!Array.isArray(filterName)) {
            filterNames.push(filterName)
        } else {
            filterNames = filterName
        }

        filterNames.forEach((name) => {
            if (updatedFilter[name] !== undefined) {
                delete updatedFilter[name]
            }
        })

        if (filter !== updatedFilter) {
            const string = btoa(JSON.stringify(updatedFilter))

            const query = queryString.stringify({ filter: string })

            history.push({
                pathname: PATH_ESCALATIONS,
                search: query,
            })
        }
    }

    clearFilter() {
        const { history } = this.props

        history.push({
            pathname: PATH_ESCALATIONS,
            search: '',
        })
    }

    update() {
        const { location } = this.props
        const { filterToken: prevFilterToken, page: prevPage, pageSize: prevPageSize } = this.state

        const { filterToken, page, pageSize } = this.parseFilterSettingsFromUrl(location)

        const hasUpdated = filterToken !== prevFilterToken || page !== prevPage || pageSize !== prevPageSize

        if (hasUpdated) {
            const filter = this.parseFilterFromToken(filterToken)

            this.fetch({ filter, page, pageSize })

            this.setState({ filterToken, filter, page, pageSize })
        }
    }

    parseFilterSettingsFromUrl(location) {
        const { search } = location

        const query = queryString.parse(search)

        const filterToken = _get(query, ['filter'], '')
        const page = Number(_get(query, ['page'], PAGINATION_DEFAULT_PAGE))
        const pageSize = Number(_get(query, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE))

        return { filterToken, page, pageSize }
    }

    parseFilterFromToken(filterToken) {
        if (!filterToken) return {}

        try {
            return JSON.parse(atob(filterToken))
        } catch (exception) {
            sendDecodingErrorsToSentry(exception, filterToken, 'EscalationsSearch')
            return {}
        }
    }

    fetch({ filter, page, pageSize }) {
        const { dispatch } = this.props

        this.requestEscalationsCancelSource = createAxiosCancelSource()

        const data = { filter, page, pageSize }
        const meta = { cancelToken: this.requestEscalationsCancelSource?.token }

        dispatch(requestEscalations(data, meta))
    }

    render() {
        const { itemsCount, pagination } = this.props
        const { filter } = this.state

        return (
            <Container>
                <EscalationsSearchFilter
                    applyFilter={this.applyFilter}
                    clearFilter={this.clearFilter}
                    filter={filter}
                    itemsCount={itemsCount}
                    onExport={this.handleExport}
                    pagination={pagination}
                    removeFilter={this.removeFilter}
                    replaceFilters={this.replaceFilters}
                />
            </Container>
        )
    }
}

EscalationsSearch.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    itemsCount: PropTypes.number.isRequired,
}

export default withRouter(connect()(EscalationsSearch))
