import { useCallback } from 'react'

import { addErrorToast } from '@scouts/ui'

import { ERROR_MESSAGE_SAVING } from '@/constants'

import { useUpdateTaxReliefClaimMutation, UpdateTaxReliefClaimParams } from '../api'

export const useUpdateTaxReliefClaim = () => {
    const [updateTaxReliefClaimAction, { error, isLoading }] = useUpdateTaxReliefClaimMutation()

    const updateTaxReliefClaim = useCallback(
        async ({ taxReliefClaimId, data }: UpdateTaxReliefClaimParams) =>
            updateTaxReliefClaimAction({ taxReliefClaimId, data })
                .unwrap()
                .then((response) => response)
                .catch(() => {
                    addErrorToast({ body: ERROR_MESSAGE_SAVING })
                }),
        [updateTaxReliefClaimAction]
    )

    return {
        updateTaxReliefClaim,
        error,
        isLoading,
    }
}
