import { useCallback } from 'react'

import { IdentityVerificationStatus } from '@/models'

import { useCreateIdentityVerification } from './use-create-identity-verification'
import { useCustomersIdentityVerifications } from './use-customers-identity-verifications'
import { useUpdateIdentityVerification } from './use-update-identity-verification'

export const useRestartIdentityVerification = ({ customerId }: { customerId: number }) => {
    const { identityVerifications, isLoading } = useCustomersIdentityVerifications({ customerId })

    const { createIdentityVerification } = useCreateIdentityVerification()
    const { updateIdentityVerification } = useUpdateIdentityVerification()

    const restartIdentityVerification = useCallback(() => {
        const [latestIdentityVerification] = identityVerifications
        if (!latestIdentityVerification) return createIdentityVerification({ customerId })
        return updateIdentityVerification({
            identityVerificationId: latestIdentityVerification.id,
            data: {
                ...latestIdentityVerification,
                status: IdentityVerificationStatus.Requested,
            },
        })
    }, [createIdentityVerification, customerId, identityVerifications, updateIdentityVerification])

    return { restartIdentityVerification, isLoading }
}
