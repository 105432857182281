import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

import { useSupportUsers } from '@/store/support-users/hooks'

import { FilterItem, FilterItemDropdownRadios } from '../ui/filter'
import SearchTypeahead, { useTypeahead } from './SearchTypeahead'

const SearchFilterSupportUser = ({ activeFilter, applyFilter, removeFilter, isOpenByDefault }) => {
    const { activeSupportUsersForSearchRadios } = useSupportUsers({ activeFilter })

    const activeValue = _get(_find(activeSupportUsersForSearchRadios, { value: activeFilter }), ['label'], null)
    const name = 'supportUserId'

    const [searchString, onSearchChange] = useTypeahead()
    const applyAndClearSearch = (filter) => {
        onSearchChange({ value: '' })
        applyFilter(filter)
    }

    const visibleValues = activeSupportUsersForSearchRadios?.filter(({ label }) => {
        if (!searchString) return true
        return label.toLowerCase().includes(searchString.toLowerCase())
    })

    const handleKeyDown = (closeDropdown) => (event) => {
        if (event.key === 'Enter' && visibleValues.length === 1) {
            const filter = { [name]: visibleValues[0].value }
            applyAndClearSearch(filter)
            if (closeDropdown) closeDropdown()
        }
    }

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Assigned to"
            activeValues={[activeValue || 'Anyone']}
            removeFilter={() => removeFilter(name)}
            isNeutral={!activeValue}
        >
            <SearchTypeahead value={searchString} onChange={onSearchChange} onKeyDown={handleKeyDown} />
            <FilterItemDropdownRadios
                applyFilter={applyAndClearSearch}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={visibleValues}
            />
        </FilterItem>
    )
}

SearchFilterSupportUser.propTypes = {
    activeFilter: PropTypes.number,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

SearchFilterSupportUser.defaultProps = {
    activeFilter: null,
}

export default SearchFilterSupportUser
