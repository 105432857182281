import styled from 'styled-components'

import { Table } from '@scouts/ui'

import { SubscriptionDTO } from '@/models'

import { SubscriptionTableRow } from './SubscriptionsTableRow'

interface SubscriptionsTableProps {
    isFetching: boolean
    subscriptions: SubscriptionDTO[]
}

export const SubscriptionsTable = ({ isFetching, subscriptions }: SubscriptionsTableProps) => (
    <Container isFetching={isFetching}>
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="30%">Client</Table.Header.Cell>
                    <Table.Header.Cell width="10%">Price</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Status</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Start date</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Cancellation date</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Expiry date</Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>
            <Table.Body>
                {subscriptions?.map((subscription) => (
                    <SubscriptionTableRow key={subscription.id} subscription={subscription} />
                ))}
            </Table.Body>
        </Table>
    </Container>
)

const Container = styled.div<{ isFetching?: boolean }>`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`
