import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
    Button,
    ButtonLink,
    Checkbox,
    DatePicker,
    Flexer,
    Form,
    Loadable,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Radio,
    Select,
    TextArea,
} from '@scouts/ui'

import { receiveErrorAlerts, requestAccountant, saveAccountant, saveAccountantPausedHistory } from '@/actions'
import { PATH_USERS } from '@/constants'
import { AccountantDomain } from '@/domain'
import { AccountantStatus, PausedStatus, UserType } from '@/models'

const accountantCapacityOptions = [
    { value: 0, title: '0' },
    { value: 1, title: '1' },
    { value: 2, title: '2' },
    { value: 3, title: '3' },
    { value: 4, title: '4' },
    { value: 5, title: '5' },
]

const TODAY = new Date()
const NEXT_YEAR = TODAY.getFullYear() + 1
const TOMORROW = new Date()
TOMORROW.setDate(TOMORROW.getDate() + 1)
class AccountantQuickSettingsModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            accountant: null,
            hasChanged: false,
            hasPausedData: false,
            isLoading: true,
            pausedDescription: '',
            pausedStatusType: PausedStatus.Busy,
            scheduledEndDate: null,
        }

        _bindAll(this, [
            'handleAccountantChange',
            'handleCancel',
            'handleChange',
            'handleCapacityChange',
            'handleCheckboxChange',
            'handlePausedStatusTypeChange',
            'handleSave',
            'handleStateChange',
        ])
    }

    componentDidMount() {
        const { dispatch, accountantId } = this.props

        const onSuccess = (accountant) => {
            const { currentPausedDescription, currentPausedScheduledEndDate } = accountant
            const hasPausedData = !!currentPausedDescription || !!currentPausedScheduledEndDate

            const pausedDescription = currentPausedDescription || ''
            const scheduledEndDate = currentPausedScheduledEndDate || null
            const pausedStatusType = hasPausedData ? PausedStatus.Unavailable : PausedStatus.Busy

            this.setState({
                accountant,
                hasPausedData,
                isLoading: false,
                pausedDescription,
                pausedStatusType,
                scheduledEndDate,
            })
        }

        dispatch(requestAccountant(accountantId, { onSuccess }))
    }

    handleCancel() {
        const { onClose } = this.props
        onClose()
    }

    handleCheckboxChange({ name, checked: value }) {
        this.handleAccountantChange({ name, value })
    }

    handleCapacityChange({ name, value }) {
        this.handleAccountantChange({ name, value: Number(value) })
    }

    handlePausedStatusTypeChange({ name, value }) {
        if (value === PausedStatus.Unavailable) {
            this.handleStateChange({ [name]: value, scheduledEndDate: TOMORROW })
        } else {
            this.handleStateChange({
                [name]: value,
                scheduledEndDate: null,
                pausedDescription: '',
                hasPausedData: false,
            })
        }
    }

    handleChange({ name, value }) {
        this.handleStateChange({ [name]: value })
    }

    handleStateChange(newState) {
        this.setState({ ...newState, hasChanged: true })
    }

    handleAccountantChange({ name, value }) {
        const { accountant } = this.state
        const updatedAccountant = { ...accountant, [name]: value }

        this.handleStateChange({ accountant: updatedAccountant })
    }

    handleSave() {
        const { dispatch, accountantId, onClose } = this.props
        const { accountant, hasChanged, hasPausedData, pausedStatusType, scheduledEndDate, pausedDescription } =
            this.state
        const { status } = accountant
        const pausedData = { accountantId, pausedStatusType, scheduledEndDate, pausedDescription }

        const onSuccess = () => {
            if (status === AccountantStatus.Paused && !hasPausedData) {
                dispatch(saveAccountantPausedHistory(pausedData, { userType: UserType.System }))
            }
            onClose(true)
        }

        if (pausedStatusType === PausedStatus.Unavailable && !scheduledEndDate) {
            dispatch(receiveErrorAlerts(`Please choose the 'Paused until' date`))
        } else if (hasChanged) {
            dispatch(saveAccountant(accountantId, accountant, { onSuccess }))
        } else {
            this.handleCancel()
        }
    }

    renderLoader() {
        return (
            <Modal onClose={this.handleCancel}>
                <Loadable isLoading />
            </Modal>
        )
    }

    render() {
        const { accountantId } = this.props
        const { accountant, hasPausedData, isLoading, pausedDescription, pausedStatusType, scheduledEndDate } =
            this.state

        if (isLoading) {
            return this.renderLoader()
        }

        const {
            allowTaxConsultation,
            capacity,
            firstName,
            isOnTrial,
            lastName,
            status,
            taxConsultationStatus,
            allowCompany,
            companyStatus,
        } = accountant

        return (
            <Modal onClose={this.handleCancel}>
                <Form onSubmit={this.handleSave}>
                    <ModalHeader>
                        <ModalTitle>
                            {firstName} {lastName}
                        </ModalTitle>
                    </ModalHeader>

                    <ModalBody>
                        <Form.Row>
                            <Form.Row.Content>
                                <Checkbox
                                    label="Is on trial"
                                    name="isOnTrial"
                                    onChange={this.handleCheckboxChange}
                                    checked={!!isOnTrial}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                        <Form.Row>
                            <Form.Row.Title>Status</Form.Row.Title>
                            <Form.Row.Content>
                                <Select
                                    name="status"
                                    width="100%"
                                    value={status}
                                    onChange={this.handleAccountantChange}
                                    options={AccountantDomain.accountantStatusOptionsBinary}
                                />
                            </Form.Row.Content>
                        </Form.Row>

                        {allowTaxConsultation && (
                            <Form.Row>
                                <Form.Row.Title>Accept new tax consultation clients</Form.Row.Title>
                                <Form.Row.Content>
                                    <Select
                                        name="taxConsultationStatus"
                                        width="100%"
                                        value={taxConsultationStatus}
                                        onChange={this.handleAccountantChange}
                                        options={AccountantDomain.accountantStatusOptionsBinary}
                                    />
                                </Form.Row.Content>
                            </Form.Row>
                        )}

                        {allowCompany && (
                            <Form.Row>
                                <Form.Row.Title>Accept new company return clients</Form.Row.Title>
                                <Form.Row.Content>
                                    <Select
                                        name="companyStatus"
                                        width="100%"
                                        value={companyStatus}
                                        onChange={this.handleAccountantChange}
                                        options={AccountantDomain.accountantStatusOptionsBinary}
                                    />
                                </Form.Row.Content>
                            </Form.Row>
                        )}

                        {status === AccountantStatus.Paused && (
                            <>
                                <Form.Row>
                                    <Form.Row.Title>Pause type</Form.Row.Title>
                                    <Form.Control>
                                        <Radio
                                            checked={pausedStatusType === PausedStatus.Busy}
                                            label="A little busy at the moment - pause new customer assignments"
                                            name="pausedStatusType"
                                            onChange={this.handlePausedStatusTypeChange}
                                            value={PausedStatus.Busy}
                                        />
                                    </Form.Control>
                                    <Form.Control>
                                        <Radio
                                            checked={pausedStatusType === PausedStatus.Unavailable}
                                            label="Unavailable - pause new & returning customer assignments"
                                            name="pausedStatusType"
                                            onChange={this.handlePausedStatusTypeChange}
                                            value={PausedStatus.Unavailable}
                                        />
                                    </Form.Control>
                                </Form.Row>
                                {pausedStatusType === PausedStatus.Unavailable && (
                                    <>
                                        <Form.Row>
                                            <Form.Row.Title>Paused until</Form.Row.Title>
                                            <Form.Row.Content>
                                                <DatePicker
                                                    name="scheduledEndDate"
                                                    value={scheduledEndDate}
                                                    onChange={this.handleChange}
                                                    minDate={TOMORROW}
                                                    maxDate={`${NEXT_YEAR}-12-31`}
                                                    disabled={hasPausedData}
                                                />
                                            </Form.Row.Content>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Row.Title>
                                                Add a note to let the support team know why {firstName} is unavailable
                                                (optional)
                                            </Form.Row.Title>
                                            <Form.Row.Content>
                                                <TextArea
                                                    name="pausedDescription"
                                                    value={pausedDescription}
                                                    onChange={this.handleChange}
                                                    height="96px"
                                                    disabled={hasPausedData}
                                                />
                                            </Form.Row.Content>
                                        </Form.Row>
                                    </>
                                )}
                            </>
                        )}
                        <Form.Row>
                            <Form.Row.Title>Capacity</Form.Row.Title>
                            <Form.Row.Content>
                                <Select
                                    name="capacity"
                                    width="100%"
                                    value={capacity || ''}
                                    onChange={this.handleCapacityChange}
                                    options={accountantCapacityOptions}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                    </ModalBody>

                    <ModalFooter>
                        <Flexer spaceBetween alignCenter>
                            <ModalActions>
                                <ButtonLink as={Link} to={`${PATH_USERS}/${accountantId}`}>
                                    Edit profile
                                </ButtonLink>
                            </ModalActions>
                            <ModalActions>
                                <Button onClick={this.handleCancel} isSecondary>
                                    Cancel
                                </Button>
                                <Button type="submit">Save</Button>
                            </ModalActions>
                        </Flexer>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

AccountantQuickSettingsModal.propTypes = {
    accountantId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default connect()(AccountantQuickSettingsModal)
