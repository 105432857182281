import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { deleteNote, resolveNote, saveNote } from '../../actions'
import { isAdmin, shouldAllowEditingEscalationNote } from '../../helpers'
import { getCustomerNotes } from '../../selectors'
import CustomerNotesItemModal from './CustomerNotesItemModal'
import CustomerNotesListItem from './CustomerNotesListItem'

const CustomerNotesListItemWrapper = styled.div`
    & + & {
        margin-top: 32px;
    }
`

class CustomerNotesList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editableNote: null,
        }

        _bindAll(this, ['openEditModal', 'closeEditModal', 'handleDeleteNote', 'handleSaveNote', 'handleResolve'])
    }

    handleDeleteNote() {
        const { dispatch, onNotesUpdate } = this.props
        const { editableNote } = this.state

        if (!window.confirm('Are you sure?')) return

        dispatch(deleteNote({ noteId: editableNote.id }, { onSuccess: onNotesUpdate }))

        this.closeEditModal()
    }

    handleSaveNote(updatedNote) {
        const { dispatch, onNotesUpdate } = this.props

        dispatch(saveNote(updatedNote, { onSuccess: onNotesUpdate }))

        this.closeEditModal()
    }

    handleResolve(note) {
        const { dispatch, onNotesUpdate } = this.props

        const updatedNote = { ...note, isResolved: !note.isResolved }
        dispatch(resolveNote(updatedNote, { onSuccess: onNotesUpdate }))
    }

    closeEditModal() {
        this.setState({ editableNote: null })
    }

    openEditModal(editableNote) {
        this.setState({ editableNote })
    }

    renderNotes() {
        const { items, isAdminUser } = this.props

        return items.map((note) => {
            const isNoteEditAllowed = shouldAllowEditingEscalationNote({ note, isAdminUser })

            return (
                <CustomerNotesListItemWrapper key={note.id}>
                    <CustomerNotesListItem
                        note={note}
                        handleResolve={isNoteEditAllowed ? this.handleResolve : null}
                        openEditModal={isNoteEditAllowed ? this.openEditModal : null}
                    />
                </CustomerNotesListItemWrapper>
            )
        })
    }

    render() {
        const { editableNote } = this.state
        return (
            <>
                {this.renderNotes()}
                {editableNote && (
                    <CustomerNotesItemModal
                        customerId={editableNote.customerId}
                        note={editableNote}
                        handleSave={this.handleSaveNote}
                        handleDelete={this.handleDeleteNote}
                        handleCancel={this.closeEditModal}
                    />
                )}
            </>
        )
    }
}

CustomerNotesList.defaultProps = {
    onNotesUpdate: () => {},
}

CustomerNotesList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onNotesUpdate: PropTypes.func,
    items: PropTypes.array.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const { user } = state
    const { customerId } = ownProps

    const items = getCustomerNotes(state, { customerId, shouldIgnoreResolved: false })

    return { items, isAdminUser: isAdmin(user) }
}

export default connect(mapStateToProps)(CustomerNotesList)
