import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { requestCustomerFiles, requestSelfAssessment, requestSelfAssessmentFiles } from '@/actions'

import Files from './Files'

class FilesContainer extends Component {
    componentDidMount() {
        const { match } = this.props

        const selfAssessmentId = _get(match, ['params', 'selfAssessmentId'], null)

        this.fetch(Number(selfAssessmentId))
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props
        const { match: prevMatch } = prevProps

        const selfAssessmentId = _get(match, ['params', 'selfAssessmentId'], null)
        const prevSelfAssessmentId = _get(prevMatch, ['params', 'selfAssessmentId'], null)

        if (selfAssessmentId !== prevSelfAssessmentId) {
            this.fetch(Number(selfAssessmentId))
        }
    }

    fetch(selfAssessmentId) {
        const { dispatch, customer } = this.props

        const { id: customerId } = customer

        if (selfAssessmentId) {
            dispatch(requestSelfAssessment(selfAssessmentId))
            dispatch(requestSelfAssessmentFiles({ selfAssessmentId }))
        }

        dispatch(requestCustomerFiles({ customerId }))
    }

    selfAssessment(selfAssessmentId) {
        const { selfAssessments } = this.props

        const selfAssessment = _find(selfAssessments.details, ['id', Number(selfAssessmentId)])

        return selfAssessment
    }

    selfAssessmentFiles(selfAssessmentId) {
        const { files } = this.props

        const selfAssessmentFiles = _get(files, ['data'], []).filter((o) => o.selfAssessmentId === selfAssessmentId)

        return selfAssessmentFiles
    }

    render() {
        const { match, customer, customerSelfAssessments, customerFiles } = this.props

        const selfAssessmentId = _get(match, ['params', 'selfAssessmentId'], null)

        const selfAssessment = this.selfAssessment(Number(selfAssessmentId))
        const selfAssessmentFiles = this.selfAssessmentFiles(Number(selfAssessmentId))

        return (
            <Files
                customer={customer}
                customerFiles={customerFiles}
                customerSelfAssessments={customerSelfAssessments}
                selfAssessment={selfAssessment}
                selfAssessmentFiles={selfAssessmentFiles}
            />
        )
    }
}

FilesContainer.propTypes = {
    customer: PropTypes.object.isRequired,
    customerFiles: PropTypes.object.isRequired,
    customerSelfAssessments: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    files: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selfAssessments: PropTypes.object.isRequired,
}

const mapStateToProps = ({ selfAssessments, files, customerFiles }) => ({ selfAssessments, files, customerFiles })

export default connect(mapStateToProps)(withRouter(FilesContainer))
