import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import { receiveReturningCustomers, REQUEST_RETURNING_CUSTOMERS } from '../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE } from '../constants'
import { handleError } from '../handlers'
import { apiCall, getPaginationHeaders } from '../helpers'

export default function* customersSagas() {
    yield takeLatest(REQUEST_RETURNING_CUSTOMERS, fetchReturningCustomers)
}

function* fetchReturningCustomers({ data }) {
    const view = _get(data, ['view'], '')
    const accountantId = _get(data, ['accountantId'], '')
    const page = _get(data, ['page'], PAGINATION_DEFAULT_PAGE)
    const pageSize = _get(data, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE)

    const url = `customers/statussearch?view=${view}&accountantId=${
        accountantId || ''
    }&page=${page}&pageSize=${pageSize}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url)
        const pagination = getPaginationHeaders(response.headers)
        yield put(receiveReturningCustomers(response.data, { pagination }))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
