import { useToggle } from '@scouts/helpers'
import { addErrorToast, colors, font, radius, Button, Container, Flexer, Line, Spacer } from '@scouts/ui'

import { CustomerDTO } from '@/models'

import { useUser } from '@/hooks/use-user'

import { AgentAuthHmrcDataPreviewModal } from './AgentAuthHmrcDataPreviewModal'

interface AgentAuthAuthorisedProps {
    customer: CustomerDTO
}

export const AgentAuthAuthorised = ({ customer }: AgentAuthAuthorisedProps) => {
    const [isModalOpen, openModal, closeModal] = useToggle()
    const { hasHmrcPayeApiConnection } = useUser()
    const { nationalInsuranceNumber, uniqueTaxpayerReference } = customer

    const previewData = () => {
        if (!nationalInsuranceNumber) {
            addErrorToast({
                body: "Client's National Insurance number is missing",
            })
            return
        }

        openModal()
    }

    return (
        <>
            <Container radius={radius.medium} background={colors.mintLighter} padding="24px">
                <Flexer column tabletRow>
                    <Spacer flexFill marginBottom="18px" tabletMargin="0">
                        <Flexer column gap="9px" justifyStart>
                            <Line weight={font.weight.medium}>Agent authorisation completed</Line>
                            <Line size={font.small}>You are successfully authorised as agent by HMRC</Line>
                        </Flexer>
                    </Spacer>
                    <Flexer column gap="12px">
                        {hasHmrcPayeApiConnection && <Button onClick={previewData}>Preview HMRC data</Button>}
                    </Flexer>
                </Flexer>
            </Container>

            {isModalOpen && (
                <AgentAuthHmrcDataPreviewModal onClose={closeModal} uniqueTaxpayerReference={uniqueTaxpayerReference} />
            )}
        </>
    )
}
