import { Loadable } from '@scouts/ui'

import { CustomerDTO } from '@/models'
import { useCustomersPartner } from '@/store/partner/hooks'

import { PartnerDetails } from './PartnerDetails'

export const PartnerDetailsContainer = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const { partner, isLoading } = useCustomersPartner({ customerId })

    if (isLoading) return <Loadable isLoading />

    return <PartnerDetails customerId={customerId} partner={partner || null} />
}
