import { colors } from '@scouts/ui'

import { OffPlatformServicesRequestStatus } from '@/models'

const statuses = {
    [OffPlatformServicesRequestStatus.Started]: {
        color: colors.neutralLightest,
        title: OffPlatformServicesRequestStatus.Started,
    },
    [OffPlatformServicesRequestStatus.Submitted]: {
        color: colors.blueLighter,
        title: OffPlatformServicesRequestStatus.Submitted,
    },
    [OffPlatformServicesRequestStatus.Claimed]: {
        color: colors.mintLighter,
        title: OffPlatformServicesRequestStatus.Claimed,
    },
    [OffPlatformServicesRequestStatus.Paid]: {
        color: colors.mintLighter,
        title: OffPlatformServicesRequestStatus.Paid,
    },
    [OffPlatformServicesRequestStatus.Referred]: {
        color: colors.mintLighter,
        title: OffPlatformServicesRequestStatus.Referred,
    },
    [OffPlatformServicesRequestStatus.Cancelled]: {
        color: colors.redLighter,
        title: OffPlatformServicesRequestStatus.Cancelled,
    },
}
export const OffPlatformServicesRequestDomain = {
    statuses,
}
