import { useEffect } from 'react'

import { useToggle } from '@scouts/helpers'
import {
    colors,
    font,
    radius,
    ButtonLink,
    Container,
    Flexer,
    Grid,
    GridItem,
    Label,
    Line,
    Spacer,
    Spinner,
} from '@scouts/ui'

import { MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL } from '@/constants'
import { formatDate } from '@/helpers'
import { AgentAuthorisationDTO, AgentAuthorisationStatus, CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'
import {
    invalidateAgentAuthorisations,
    usePollHmrcAgentAuthorisation,
    useRequestStatusHmrcAgentAuthorisation,
} from '@/store/agent-authorisations'

import SendMessagesContainer from '../../SendMessagesContainer'
import { AgentAuthCodeEditModal } from './AgentAuthCodeEditModal'
import { AgentAuthCodeSubmitModal } from './AgentAuthCodeSubmitModal'
import { AgentAuthErrorModal } from './AgentAuthErrorModal'
import { AgentAuthRequest } from './AgentAuthRequest'
import { AgentAuthStatus } from './AgentAuthStatus'

const getLastTaxScoutsReference = (customerAuthorisations: AgentAuthorisationDTO[]) => {
    if (customerAuthorisations.length > 0) {
        return customerAuthorisations[0].taxScoutsReference || ''
    }

    return ''
}

interface AgentAuthItemProps {
    agentAuthorisation: AgentAuthorisationDTO
    customer: CustomerDTO
    customerAuthorisations: AgentAuthorisationDTO[]
}

export const AgentAuthItem = ({ customer, agentAuthorisation, customerAuthorisations }: AgentAuthItemProps) => {
    const dispatch = useAppDispatch()
    const [isCodeSubmitModalOpen, openCodeSubmitModal, closeCodeSubmitModal] = useToggle()
    const [isCodeEditModalOpen, openCodeEditModal, closeCodeEditModal] = useToggle()
    const [isPollingErrorModalOpen, openPollingErrorModal, closePollingErrorModal] = useToggle()
    const [isMessageModalOpen, openMessageModal] = useToggle()

    const { pollHmrcAgentAuthorisation } = usePollHmrcAgentAuthorisation()
    const { requestStatusHmrcAgentAuthorisation } = useRequestStatusHmrcAgentAuthorisation()

    const {
        pollInterval,
        correlationId,
        authorisationRequestID,
        status,
        authorisationCode,
        id: agentAuthorisationId,
        requestedDateUTC,
        updatedDateUTC,
    } = agentAuthorisation

    useEffect(() => {
        if (pollInterval && pollInterval > 0 && correlationId) {
            const pollOptions = {
                onError: openPollingErrorModal,
            }

            setTimeout(() => {
                pollHmrcAgentAuthorisation({
                    agentAuthorisation,
                    pollOptions,
                })
            }, pollInterval * 1000)
        } else if (authorisationRequestID && status === AgentAuthorisationStatus.Submitted) {
            requestStatusHmrcAgentAuthorisation({
                agentAuthorisationId: agentAuthorisation.id,
            })
        }
    }, [
        agentAuthorisation,
        authorisationRequestID,
        correlationId,
        dispatch,
        openPollingErrorModal,
        pollHmrcAgentAuthorisation,
        pollInterval,
        requestStatusHmrcAgentAuthorisation,
        status,
    ])

    const openMessagesModal = () => {
        closePollingErrorModal()
        openMessageModal()
    }

    const handleClosePollingErrorModal = () => {
        closePollingErrorModal()
        dispatch(invalidateAgentAuthorisations())
    }

    const canRequestAgain =
        status === AgentAuthorisationStatus.RequestFailed || status === AgentAuthorisationStatus.UnableToIssue
    const isCodeEditable =
        status !== AgentAuthorisationStatus.Submitted && status !== AgentAuthorisationStatus.ClientAuthorised
    const isCodeSubmittable =
        !!authorisationRequestID &&
        status !== AgentAuthorisationStatus.ClientAuthorised &&
        status !== AgentAuthorisationStatus.RequestExpired

    return (
        <Container
            background={colors.white}
            radius={radius.medium}
            padding="18px 22px"
            border={`1px solid ${colors.neutralLightest}`}
        >
            <Grid
                gap="24px"
                alignCenter
                desktopLargeColumns="260px 2fr 2fr 1fr"
                desktopColumns="1fr 1fr"
                columns={1}
                width="100%"
            >
                <GridItem>
                    <AgentAuthStatus agentAuthorisation={agentAuthorisation} />
                </GridItem>
                <GridItem>
                    <Flexer column gap="6px" alignStart>
                        {!!authorisationCode && (
                            <Spacer>
                                <Label background={colors.blueLighter}>{authorisationCode}</Label>
                            </Spacer>
                        )}

                        {isCodeEditable && (
                            <ButtonLink onClick={openCodeEditModal}>
                                {authorisationCode ? 'Edit code' : 'Provide code'}
                            </ButtonLink>
                        )}

                        {isCodeSubmittable && <ButtonLink onClick={openCodeSubmitModal}>Send to HMRC</ButtonLink>}

                        {isCodeEditModalOpen && (
                            <AgentAuthCodeEditModal
                                closeModal={closeCodeEditModal}
                                agentAuthorisation={agentAuthorisation}
                            />
                        )}

                        {isCodeSubmitModalOpen && (
                            <AgentAuthCodeSubmitModal
                                closeModal={closeCodeSubmitModal}
                                code={authorisationCode}
                                customer={customer}
                                agentAuthorisationId={agentAuthorisationId}
                            />
                        )}
                    </Flexer>
                </GridItem>
                <GridItem>
                    <Flexer>
                        {pollInterval ? (
                            <Spinner isSmall color={colors.blue} />
                        ) : (
                            <Flexer column>
                                <Line size={font.small}>{formatDate(requestedDateUTC)}</Line>
                                <Line size={font.small} color={colors.neutralDarker}>
                                    (updated {formatDate(updatedDateUTC)})
                                </Line>
                            </Flexer>
                        )}

                        {isPollingErrorModalOpen && (
                            <AgentAuthErrorModal
                                closeModal={handleClosePollingErrorModal}
                                openMessagesModal={openMessagesModal}
                            />
                        )}

                        {isMessageModalOpen && (
                            <SendMessagesContainer
                                openByDefault={isMessageModalOpen}
                                customers={[{ customerId: String(customer.id), fullName: customer.fullName }]}
                                availableTypes={[MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL]}
                                onSuccess={() => dispatch(invalidateAgentAuthorisations())}
                            />
                        )}
                    </Flexer>
                </GridItem>
                <GridItem>
                    {canRequestAgain && (
                        <AgentAuthRequest
                            agentAuthorisation={agentAuthorisation}
                            customer={customer}
                            label="Re-request"
                            taxScoutsReference={getLastTaxScoutsReference(customerAuthorisations)}
                        />
                    )}
                </GridItem>
            </Grid>
        </Container>
    )
}
