import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'

import { colors, font, media, ButtonLink } from '@scouts/ui'

import { INFO_EMAIL } from '@/constants'

const findFirstSubstring = (string, matches) => {
    const regex = new RegExp(matches.join('|'), 'gim')
    const regexMatch = string.match(regex)
    return regexMatch ? regexMatch[0] : matches[0]
}

/**
 * NOTE: We need to remove history of email.
 * History starts with line (example): 'On Thu, Apr 30, 2020 at 8:29 PM John Doe <john.doe@example.com> wrote:'

 * We also don't know who wrote the last message in history, so we use the email that
 * we meet first: 'fromEmail' and 'toEmail'
 * @param {string} messageContent
 * @param {string} fromEmail
 * @param {string} toEmail
 * @returns {Object} result
 * @returns {string} result.messageContentWithoutThread - First part of the message, with the thread removed
 * @returns {string} result.messageThreadContent - The thread that was removed
 */
const getLastMessageFromThread = (messageContent, fromEmail, toEmail) => {
    let text = messageContent

    const infoEmailWithArrows = `<${INFO_EMAIL}>`
    const fromEmailWithArrows = `<${fromEmail}>`
    const toEmailWithArrows = `<${toEmail}>`

    // NOTE: First history email with arrows
    const historyEmailWithArrows = findFirstSubstring(text, [
        fromEmailWithArrows,
        toEmailWithArrows,
        infoEmailWithArrows,
    ])
    const indexOfHistoryEmailWithArrows = text.indexOf(historyEmailWithArrows)

    if (indexOfHistoryEmailWithArrows > -1) {
        // NOTE: Remove everything after `<${fromEmail}>`
        text = text.substring(0, indexOfHistoryEmailWithArrows + historyEmailWithArrows.length)
        // NOTE: Remove line that contains `<${fromEmail}>`
        const fromRegExp = new RegExp(`^.*${historyEmailWithArrows}.*$`, 'mgi')
        text = text.replace(fromRegExp, '')
    }

    text = text.trim()

    return { messageContentWithoutThread: text, messageThreadContent: messageContent.replace(text, '') }
}

const MessageContent = ({ message }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const toggleIsExpanded = () => setIsExpanded(!isExpanded)

    const { messageContent: messageContentRaw, emailFrom, emailTo } = message
    const messageContent = messageContentRaw.trim()

    const { messageContentWithoutThread, messageThreadContent } = getLastMessageFromThread(
        messageContent,
        emailFrom,
        emailTo
    )

    const hasHiddenContent = messageContentWithoutThread !== messageContent
    if (!hasHiddenContent) {
        return <Container>{messageContent}</Container>
    }

    return (
        <Container>
            {messageContentWithoutThread}
            {isExpanded && <PreviousMessageThread>{messageThreadContent}</PreviousMessageThread>}
            <ActionsWrapper>
                <ButtonLink type="button" isSmall onClick={toggleIsExpanded}>
                    {isExpanded ? 'Hide full message' : 'Show full message'}
                </ButtonLink>
            </ActionsWrapper>
        </Container>
    )
}

MessageContent.propTypes = {
    message: PropTypes.object.isRequired,
}

export default MessageContent

const Container = styled.div`
    line-height: 1.5;
    font-size: ${font.smaller};
    white-space: pre-line;
    word-wrap: break-word;
    overflow-wrap: anywhere;

    ${media.tablet} {
        font-size: ${font.small};
    }
`

const PreviousMessageThread = styled.blockquote`
    padding-left: 12px;
    border-left: 2px solid ${colors.neutralDarker};
    margin-top: 12px;
    font-style: italic;
`

const ActionsWrapper = styled.div`
    margin-top: 12px;
`
