import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Grid, Line, Spacer } from '@scouts/ui'

import { ESCALATIONS_TYPE_TITLES, PATH_ESCALATIONS } from '@/constants'
import { formatDate } from '@/helpers'
import { EscalationDTO } from '@/models'

import { Card } from './_ui/Card'

const DISPLAY_COUNT = 4

export const SupportDashboardEscalations = ({ escalations }: { escalations: EscalationDTO[] }) => {
    const history = useHistory()

    const count = escalations?.length || 0
    const visibleItems = escalations?.slice(0, DISPLAY_COUNT)

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Latest escalations assigned to me
                    </Line>
                    <ButtonLink color={colors.neutralDarker} onClick={() => history.push(PATH_ESCALATIONS)}>
                        View all {count > DISPLAY_COUNT ? `(${count})` : ''}
                    </ButtonLink>
                </Flexer>
            </Spacer>
            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                {visibleItems?.map((escalation) => {
                    const url = `/customers/${escalation.customerId}?escalationId=${escalation.id}&view=escalation-edit#escalations`
                    return (
                        <Card
                            key={escalation.id}
                            background={colors.blueLighter}
                            linkTo={url}
                            title={escalation.customerFullName || ''}
                            value={formatDate(escalation.occurredDateUTC) || ''}
                            valueSecondary={ESCALATIONS_TYPE_TITLES[escalation.type]}
                        />
                    )
                })}
            </Grid>
        </Container>
    )
}
