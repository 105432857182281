import _orderBy from 'lodash/orderBy'

import { useGetDiscountSchemesQuery } from '../api'

export const useDiscountSchemes = () => {
    const { data, isError, isLoading, isFetching } = useGetDiscountSchemesQuery()

    const discountSchemes = _orderBy(data, ['userDiscountsCount'], ['desc'])

    return {
        discountSchemes,
        isError,
        isLoading,
        isFetching,
    }
}
