import { useDeleteCompanyReturnFileMutation } from '../api'

export const useDeleteCompanyReturnFile = () => {
    const [deleteCompanyReturnFile, { isLoading }] = useDeleteCompanyReturnFileMutation()

    return {
        deleteCompanyReturnFile,
        isLoading,
    }
}
