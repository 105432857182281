import _orderBy from 'lodash/orderBy'

import { colors } from '@scouts/ui'

import {
    TaxConsultationShortDTO,
    TaxConsultationStatus,
    TaxConsultationStatusFilter,
    TaxConsultationStatusFilterTitles,
    TaxConsultationStatusFilterTypes,
    TaxConsultationStatusTitles,
    TaxConsultationSummaryDTO,
} from '@/models'

const statuses = {
    [TaxConsultationStatus.Started]: {
        color: colors.neutralLightest,
        title: TaxConsultationStatusTitles[TaxConsultationStatus.Started],
    },
    [TaxConsultationStatus.Booked]: {
        color: colors.blueLighter,
        title: TaxConsultationStatusTitles[TaxConsultationStatus.Booked],
    },
    [TaxConsultationStatus.AwaitingSummary]: {
        color: colors.blueLighter,
        title: TaxConsultationStatusTitles[TaxConsultationStatus.AwaitingSummary],
    },
    [TaxConsultationStatus.AwaitingApproval]: {
        color: colors.yellow,
        title: TaxConsultationStatusTitles[TaxConsultationStatus.AwaitingApproval],
    },
    [TaxConsultationStatus.Completed]: {
        color: colors.mintLighter,
        title: TaxConsultationStatusTitles[TaxConsultationStatus.Completed],
    },
    [TaxConsultationStatus.Cancelled]: {
        color: colors.red,
        title: TaxConsultationStatusTitles[TaxConsultationStatus.Cancelled],
    },
}

const filterOptions: { value: TaxConsultationStatusFilterTypes; title: string }[] = [
    {
        value: TaxConsultationStatusFilter.Started,
        title: TaxConsultationStatusFilterTitles[TaxConsultationStatusFilter.Started],
    },
    {
        value: TaxConsultationStatusFilter.Booked,
        title: TaxConsultationStatusFilterTitles[TaxConsultationStatusFilter.Booked],
    },
    {
        value: TaxConsultationStatusFilter.AwaitingSummary,
        title: TaxConsultationStatusFilterTitles[TaxConsultationStatusFilter.AwaitingSummary],
    },
    {
        value: TaxConsultationStatusFilter.AwaitingApproval,
        title: TaxConsultationStatusFilterTitles[TaxConsultationStatusFilter.AwaitingApproval],
    },
    {
        value: TaxConsultationStatusFilter.Completed,
        title: TaxConsultationStatusFilterTitles[TaxConsultationStatusFilter.Completed],
    },
    {
        value: TaxConsultationStatusFilter.Cancelled,
        title: TaxConsultationStatusFilterTitles[TaxConsultationStatusFilter.Cancelled],
    },
]

const getActiveTaxConsultations = (taxConsultations: TaxConsultationShortDTO[]) =>
    taxConsultations.filter(
        ({ hasPaid, status }) =>
            !!hasPaid && status !== TaxConsultationStatus.Completed && status !== TaxConsultationStatus.Cancelled
    )

const getCompletedTaxConsultations = (taxConsultations: TaxConsultationShortDTO[]) =>
    taxConsultations.filter(({ hasPaid, status }) => !!hasPaid && status === TaxConsultationStatus.Completed)

const getCancelledTaxConsultations = (taxConsultations: TaxConsultationShortDTO[]) =>
    taxConsultations.filter(({ hasPaid, status }) => !!hasPaid && status === TaxConsultationStatus.Cancelled)

const getDiscountApplicableTaxConsultations = (taxConsultations: TaxConsultationShortDTO[]) =>
    taxConsultations.filter((taxConsultation) => !taxConsultation.hasPaid)

const getHasUnbookedTaxConsultations = (taxConsultations: TaxConsultationShortDTO[]) =>
    taxConsultations.some(({ status }) => status === TaxConsultationStatus.Started)

const getAccountantBookedConsultations = (
    taxConsultations: TaxConsultationSummaryDTO[] | undefined,
    accountantId: number
) => {
    const accountantConsultations = taxConsultations?.filter(
        ({ status, accountantId: taxConsultationAccountantId }) =>
            status === TaxConsultationStatus.Booked && accountantId === taxConsultationAccountantId
    )

    return _orderBy(accountantConsultations, ['scheduledDateUtc'], ['asc'])
}

export const TaxConsultationDomain = {
    getActiveTaxConsultations,
    getCompletedTaxConsultations,
    getCancelledTaxConsultations,
    getDiscountApplicableTaxConsultations,
    getHasUnbookedTaxConsultations,
    getAccountantBookedConsultations,
    statuses,
    filterOptions,
}
