import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

import { PAYMENT_STATUSES } from '../../constants'
import { FilterItem, FilterItemDropdownRadios } from '../ui/filter'

const SearchFilterPaymentStatus = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter !== null

    const values = PAYMENT_STATUSES.map((item) => ({
        value: item,
        label: item,
        checked: activeFilter === item,
    }))

    const activeValue = _get(_find(values, { value: activeFilter }), ['label'], null)

    const name = 'paymentStatus'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Payment status"
            activeValues={[activeValue]}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownRadios
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterPaymentStatus.propTypes = {
    activeFilter: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
}

export default SearchFilterPaymentStatus
