import { generateApiPath } from '@scouts/helpers'

import { MessageTemplateDTO, MessageTemplateEditDTO } from '@/models/message-template'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMessageTemplates: builder.query<MessageTemplateDTO[], void>({
            query: () => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.MESSAGE_TEMPLATES.GET),
            }),
            providesTags: [Tags.MessageTemplates],
        }),
        createMessageTemplate: builder.mutation<MessageTemplateDTO, MessageTemplateEditDTO>({
            query: (body) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.MESSAGE_TEMPLATES.POST),
                body,
            }),
            invalidatesTags: [Tags.MessageTemplates],
        }),
        updateMessageTemplate: builder.mutation<MessageTemplateDTO, MessageTemplateEditDTO & { id: number }>({
            query: ({ id, ...body }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.MESSAGE_TEMPLATES.PUT, { id }),
                body,
            }),
            invalidatesTags: [Tags.MessageTemplates],
        }),
        deleteMessageTemplate: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.MESSAGE_TEMPLATES.DELETE, { id }),
            }),
            invalidatesTags: [Tags.MessageTemplates],
        }),
    }),
})

export const {
    useGetMessageTemplatesQuery,
    useCreateMessageTemplateMutation,
    useDeleteMessageTemplateMutation,
    useUpdateMessageTemplateMutation,
} = extendedApi
