import { formatCurrencyNotSure } from '@/helpers'
import { SelfAssessmentDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

export const CharitableDonations = ({
    charitableDonations,
}: {
    charitableDonations: SelfAssessmentDTO['charitableDonations']
}) => {
    if (!charitableDonations) return null

    const { additionalNotes, amountDonated, donatedThroughGiftAid, receiptsForDonations } = charitableDonations

    return (
        <SectionData>
            <SectionDataRow label="Amount donated" value={formatCurrencyNotSure(amountDonated)} />
            <SectionDataRow label="Donated through gift aid" value={donatedThroughGiftAid} boolean />
            <SectionDataRow label="Receipts for donations" value={receiptsForDonations} boolean />

            {additionalNotes && <SectionDataRow label="Notes" value={additionalNotes} />}
        </SectionData>
    )
}
