import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { colors, font, media, Button, Container } from '@scouts/ui'

import {
    AGENT_AUTH_STATUS_INCORRECT_CODE,
    AGENT_AUTH_STATUS_REQUEST_EXPIRED,
    AGENT_AUTH_STATUS_REQUEST_FAILED,
    AGENT_AUTH_STATUS_UNABLE_TO_ISSUE,
    PAYMENT_STATUS_REFUNDED,
    PAYMENT_STATUS_SUCCEEDED,
    SEARCH_RESULT_TYPE_PRODUCT_BUNDLES,
    SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
    SELF_ASSESSMENT_STATUS_FILED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    TAX_CONSULTATION_STATUS_COMPLETED,
} from '../../constants'
import { clickedOnScrollbar, formatDateYYYYMMDD } from '../../helpers'
import { FilterDropdown } from '../ui/filter'

const DropdownContainer = styled.div`
    position: relative;
`

const Item = styled.button`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: left;
    display: block;
    width: 100%;
    padding: 9px 0;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }

    &:first-child {
        margin-top: -9px;
    }

    &:last-child {
        margin-bottom: -9px;
    }
`

const propTypes = {
    replaceFilters: PropTypes.func.isRequired,
}

const SearchFilterSet = (props) => {
    const { replaceFilters } = props

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const openDropdown = () => {
        setIsDropdownOpen(true)
    }

    const closeDropdown = () => {
        setIsDropdownOpen(false)
    }

    const closeDropdownOnClick = (e) => {
        if (!clickedOnScrollbar(e.pageX)) {
            closeDropdown()
        }
    }

    const ref = useRef()
    useOnClickOutside(ref, closeDropdownOnClick)

    const addFilter = (filter) => {
        replaceFilters(filter)
        closeDropdown()
    }

    const getDates = () => {
        const now = new Date()
        const prevMonthLastDate = new Date(now.getFullYear(), now.getMonth(), 0)
        const prevMonthFirstDate = new Date(
            now.getFullYear() - (now.getMonth() > 0 ? 0 : 1),
            (now.getMonth() - 1 + 12) % 12,
            1
        )

        return {
            previousMonthStart: formatDateYYYYMMDD(prevMonthFirstDate),
            previousMonthEnd: formatDateYYYYMMDD(prevMonthLastDate),
        }
    }

    const dates = getDates()

    const filters = [
        {
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentPayAfterFiling: true,
                agentAuthorisationStatuses: [
                    AGENT_AUTH_STATUS_UNABLE_TO_ISSUE,
                    AGENT_AUTH_STATUS_INCORRECT_CODE,
                    AGENT_AUTH_STATUS_REQUEST_FAILED,
                    AGENT_AUTH_STATUS_REQUEST_EXPIRED,
                ],
            },
            label: 'Pay later & No AA',
        },
        {
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentPayAfterFiling: false,
                selfAssessmentStatuses: [SELF_ASSESSMENT_STATUS_FILED, SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED],
                filedDateEnd: dates.previousMonthEnd,
                filedDateStart: dates.previousMonthStart,
            },
            label: "Last month's clients",
        },
        {
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentPayAfterFiling: true,
                selfAssessmentStatuses: [SELF_ASSESSMENT_STATUS_FILED],
                paymentDateEnd: dates.previousMonthEnd,
                paymentDateStart: dates.previousMonthStart,
            },
            label: 'Last month & Pay later',
        },
        {
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentPayAfterFiling: true,
                selfAssessmentStatuses: [SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND],
                filedDateEnd: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            },
            label: 'Awaiting refund over 30 days',
        },
        {
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_PRODUCT_BUNDLES,
                paymentStatus: PAYMENT_STATUS_REFUNDED,
            },
            label: 'Refunded bundles',
        },
        {
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_PRODUCT_BUNDLES,
                selfAssessmentStatuses: [SELF_ASSESSMENT_STATUS_FILED],
                taxConsultationStatuses: [TAX_CONSULTATION_STATUS_COMPLETED],
            },
            label: 'Completed bundles',
        },
        {
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_PRODUCT_BUNDLES,
                paymentStatus: PAYMENT_STATUS_SUCCEEDED,
                paymentDateStart: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                paymentDateEnd: moment().format('YYYY-MM-DD'),
            },
            label: 'Bundles paid in last 30 days',
        },
    ].filter((o) => !o.shouldHide)

    return (
        <Container>
            <Button isSecondary size="small" onClick={openDropdown}>
                Shortcuts
            </Button>

            <DropdownContainer ref={ref}>
                {isDropdownOpen && (
                    <FilterDropdown>
                        {filters.map(({ defaultFilter, label }) => (
                            <Item key={label} onClick={() => addFilter(defaultFilter)}>
                                {label}
                            </Item>
                        ))}
                    </FilterDropdown>
                )}
            </DropdownContainer>
        </Container>
    )
}

SearchFilterSet.propTypes = propTypes

export default SearchFilterSet
