export const FileType = {
    P60: 'P60',
    P45: 'P45',
    Payslip: 'Payslip',
    IncomeRecord: 'IncomeRecord',
    ExpenseRecord: 'ExpenseRecord',
    BankStatement: 'BankStatement',
    Spreadsheet: 'Spreadsheet',
    PensionsDocument: 'PensionsDocument',
    Other: 'Other',
    InterestCertificate: 'InterestCertificate',
    DividendVoucher: 'DividendVoucher',
    PaymentSchedule: 'PaymentSchedule',
    AnnualSummary: 'AnnualSummary',
    Certificate: 'Certificate',
    P11D: 'P11D',
    InvestmentSchemeCertificate: 'InvestmentSchemeCertificate',
    FinalReturn: 'FinalReturn',
    TaxCalculation: 'TaxCalculation',
    SubmissionResponse: 'SubmissionResponse',
    CisPaymentStatement: 'CisPaymentStatement',
    Image: 'Image',
    PreviousReturn: 'PreviousReturn',
    TaxSchedule: 'TaxSchedule',
    TaxSummary: 'TaxSummary',
    IdDocument: 'IdDocument',
    ProofOfAddress: 'ProofOfAddress',
    TaxRegistrationSubmission: 'TaxRegistrationSubmission',
    PAYECodingNotice: 'PAYECodingNotice',
    CapitalGainsReport: 'CapitalGainsReport',
    ShareAwardAgreement: 'ShareAwardAgreement',
    SaleSummary: 'SaleSummary',
    TaxConsultationSummary: 'TaxConsultationSummary',
    TaxConsultationCustomerDocument: 'TaxConsultationCustomerDocument',
    CapitalGainsComputation: 'CapitalGainsComputation',
    IncomePlatformReport: 'IncomePlatformReport',
    IdentityVerificationVideo: 'IdentityVerificationVideo',
    TaxReliefClaimCertificate: 'TaxReliefClaimCertificate',
    TaxReliefClaimCompletedForm: 'TaxReliefClaimCompletedForm',
    TaxReliefClaimProofOfPostage: 'TaxReliefClaimProofOfPostage',
    BookkeepingRecords: 'BookkeepingRecords',
    VATRecords: 'VATRecords',
    Payroll: 'Payroll',
    LoanAndCreditAgreement: 'LoanAndCreditAgreement',
    AssetRegister: 'AssetRegister',
    FullAccounts: 'FullAccounts',
    AbridgedAccounts: 'AbridgedAccounts',
    FilletedAccounts: 'FilletedAccounts',
    TaxScoutsInvoice: 'TaxScoutsInvoice',
} as const

export type FileTypeTypes = (typeof FileType)[keyof typeof FileType]

export const FileTypeTitles: Record<FileTypeTypes, string> = {
    P60: 'P60',
    P45: 'P45',
    Payslip: 'Payslip',
    IncomeRecord: 'Income record',
    ExpenseRecord: 'Expense record',
    BankStatement: 'Bank statement',
    Spreadsheet: 'Spreadsheet',
    PensionsDocument: 'Pensions document',
    Other: 'Other',
    InterestCertificate: 'Interest certificate',
    DividendVoucher: 'Dividend voucher',
    PaymentSchedule: 'Payment schedule',
    AnnualSummary: 'Annual summary',
    Certificate: 'Certificate',
    P11D: 'P11D',
    InvestmentSchemeCertificate: 'Investment scheme certificate',
    FinalReturn: 'Final return',
    TaxCalculation: 'Tax calculation',
    SubmissionResponse: 'Submission response',
    CisPaymentStatement: 'CIS payment statement',
    Image: 'Image',
    PreviousReturn: 'Previous return',
    TaxSchedule: 'Tax schedule',
    TaxSummary: 'Tax summary',
    IdDocument: 'ID document',
    ProofOfAddress: 'Proof of address',
    TaxRegistrationSubmission: 'Tax registration submission',
    PAYECodingNotice: 'PAYE coding notice',
    CapitalGainsReport: 'Capital gains report',
    ShareAwardAgreement: 'Share award agreement',
    SaleSummary: 'Sale summary',
    TaxConsultationSummary: 'Tax consultation summary',
    TaxConsultationCustomerDocument: 'Tax consultation customer document',
    CapitalGainsComputation: 'CGT computation',
    IncomePlatformReport: 'Income platform report',
    IdentityVerificationVideo: 'ID video',
    TaxReliefClaimCertificate: 'EIS certificate',
    TaxReliefClaimCompletedForm: 'EIS completed form',
    TaxReliefClaimProofOfPostage: 'EIS proof of postage',
    BookkeepingRecords: 'Bookkeeping records',
    VATRecords: 'VAT records',
    Payroll: 'Payroll',
    LoanAndCreditAgreement: 'Loan and credit agreement',
    AssetRegister: 'Asset register',
    FullAccounts: 'Full accounts',
    AbridgedAccounts: 'Abridged accounts',
    FilletedAccounts: 'Filleted accounts',
    TaxScoutsInvoice: 'TaxScouts invoice',
}

export const FileSubType = {
    DrivingExpenses: 'DrivingExpenses',
    CarRentOrLeasePayments: 'CarRentOrLeasePayments',
    MileageLog: 'MileageLog',
    NonDrivingExpenses: 'NonDrivingExpenses',
    CarPurchaseReceipt: 'CarPurchaseReceipt',
    KoinlyReport: 'KoinlyReport',
} as const

export type FileSubTypeTypes = (typeof FileSubType)[keyof typeof FileSubType]

export const FileSubTypeTitles: Record<FileSubTypeTypes, string> = {
    DrivingExpenses: 'Driving expenses',
    CarRentOrLeasePayments: 'Car rent / Lease payments',
    MileageLog: 'Mileage log',
    NonDrivingExpenses: 'Non-driving expenses',
    CarPurchaseReceipt: 'Car purchase receipt',
    KoinlyReport: 'Koinly report',
}
