import { ConfirmationModal } from '@scouts/ui'

import { deleteProductBundle } from '@/actions'
import { ProductBundleDetailsDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface ProductBundleDeleteModalProps {
    productBundleId: ProductBundleDetailsDTO['id']
    handleClose: () => void
}

export const ProductBundleDeleteModal = ({ productBundleId, handleClose }: ProductBundleDeleteModalProps) => {
    const dispatch = useAppDispatch()

    const handleDelete = () => {
        dispatch(deleteProductBundle({ id: productBundleId }, { onSuccess: handleClose }))
    }

    return (
        <ConfirmationModal onCancel={handleClose} onConfirm={handleDelete}>
            Are you sure you want to delete product bundle {productBundleId}?
        </ConfirmationModal>
    )
}
