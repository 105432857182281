import { useUploadCompanyReturnFileMutation } from '../api'

export const useUploadCompanyReturnFile = () => {
    const [uploadCompanyReturnFile, { isError, isLoading }] = useUploadCompanyReturnFileMutation()

    return {
        uploadCompanyReturnFile,
        isError,
        isLoading,
    }
}
