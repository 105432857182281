import { useGetOffPlatformServicesRequestsQuery, GetOffPlatformServicesRequestsParams } from '../api'

export const useOffPlatformServicesRequests = ({ options }: GetOffPlatformServicesRequestsParams) => {
    const { data: fetchedData, isLoading, isError } = useGetOffPlatformServicesRequestsQuery({ options })
    const { data, pagination } = fetchedData || {}

    return {
        offPlatformServicesRequests: data,
        isError,
        isLoading,
        pagination,
    }
}
