import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useQueryParams } from '@scouts/helpers'

import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE } from '@/constants'
import { PaginationQuery } from '@/models'

export type PaginationFilters = {
    page?: number
    pageSize?: number
}

const DEFAULT_FILTER: PaginationFilters = {
    page: PAGINATION_DEFAULT_PAGE,
    pageSize: PAGINATION_DEFAULT_PAGE_SIZE,
}

export const usePagination = () => {
    const history = useHistory()
    const { queryParams, updateQueryParams } = useQueryParams()

    const activeFilters = useMemo(() => {
        const { filter } = queryParams

        if (!filter) {
            return DEFAULT_FILTER
        }

        try {
            const parsedFilter = JSON.parse(atob(filter as string))
            return parsedFilter as PaginationFilters
        } catch {
            return DEFAULT_FILTER
        }
    }, [queryParams])

    const applyFilter = ({ updatedFilter }: { updatedFilter: PaginationQuery }) => {
        const filter = btoa(JSON.stringify({ ...activeFilters, ...updatedFilter }))
        history.replace(updateQueryParams({ filter }))
    }

    return {
        activeFilters,
        applyFilter,
        defaultPaginationFilter: DEFAULT_FILTER,
    }
}
