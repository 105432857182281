import { RECEIVE_CUSTOMER_DISCOUNTS, REMOVE_CUSTOMER_DISCOUNT } from '../actions'
import { removeItemsById, updateOrAddItemsById } from '../helpers'

export default (state = { items: [] }, action) => {
    const { type, data } = action

    switch (type) {
        case RECEIVE_CUSTOMER_DISCOUNTS: {
            return { ...state, items: updateOrAddItemsById({ currentItems: state.items, receivedItems: data }) }
        }
        case REMOVE_CUSTOMER_DISCOUNT: {
            return { ...state, items: removeItemsById({ currentItems: state.items, id: data }) }
        }
        default:
            return state
    }
}
