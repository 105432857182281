import { useHistory } from 'react-router-dom'

import { ButtonLink, Flexer, Tabs } from '@scouts/ui'

import { PATH_TEMPLATES_TEMPLATE_CREATE } from '@/constants'
import { isAdmin } from '@/helpers'
import { MessageTemplateDTO, MessageTemplateType, MessageTemplateTypeTypes } from '@/models/message-template'

import { useUser } from '@/hooks/use-user'

export const MessageTemplateTabs = ({ messageTemplates }: { messageTemplates: MessageTemplateDTO[] }) => {
    const history = useHistory()
    const user = useUser()

    const searchParams = new URLSearchParams(history.location.search)

    const activeSearchType = searchParams.get('type')

    const handleItemCreate = () => {
        history.replace(PATH_TEMPLATES_TEMPLATE_CREATE)
    }

    const handleChangeSearchType = (searchType: MessageTemplateTypeTypes) => {
        const params = new URLSearchParams({ type: searchType })
        history.replace({ pathname: location.pathname, search: params.toString() })
    }

    const isAdminUser = isAdmin(user)
    const accountantTabTitle = isAdminUser ? 'For accountants' : 'TaxScouts templates'
    const getTemplateCount = (type: MessageTemplateTypeTypes) =>
        messageTemplates.filter((template) => template.type === type).length

    return (
        <Flexer spaceBetween alignCenter>
            <Tabs>
                <Tabs.Item
                    onClick={() => handleChangeSearchType(MessageTemplateType.Private)}
                    isActive={!activeSearchType || activeSearchType === MessageTemplateType.Private}
                >
                    My templates
                    <Tabs.Item.Counter>{getTemplateCount(MessageTemplateType.Private)}</Tabs.Item.Counter>
                </Tabs.Item>

                {isAdminUser && (
                    <Tabs.Item
                        onClick={() => handleChangeSearchType(MessageTemplateType.Support)}
                        isActive={activeSearchType === MessageTemplateType.Support}
                    >
                        For support
                        <Tabs.Item.Counter>{getTemplateCount(MessageTemplateType.Support)}</Tabs.Item.Counter>
                    </Tabs.Item>
                )}

                <Tabs.Item
                    onClick={() => handleChangeSearchType(MessageTemplateType.Shared)}
                    isActive={activeSearchType === MessageTemplateType.Shared}
                >
                    {accountantTabTitle}
                    <Tabs.Item.Counter>{getTemplateCount(MessageTemplateType.Shared)}</Tabs.Item.Counter>
                </Tabs.Item>
            </Tabs>

            <ButtonLink icon="plus" onClick={handleItemCreate}>
                Add new
            </ButtonLink>
        </Flexer>
    )
}
