import { ProductBundleDetailsDTO, SelfAssessmentDTO, TaxConsultationShortDTO } from '@/models'

interface GetActiveProductBundlesParams {
    productBundles: ProductBundleDetailsDTO[]
    activeTaxConsultations?: TaxConsultationShortDTO[]
    activeSelfAssessments?: SelfAssessmentDTO[]
}

const getActiveProductBundles = ({
    productBundles,
    activeTaxConsultations,
    activeSelfAssessments,
}: GetActiveProductBundlesParams) =>
    productBundles.filter(
        (bundle) =>
            !!bundle.hasPaid &&
            (activeTaxConsultations?.some((tc) => tc.id === bundle.taxConsultationId) ||
                activeSelfAssessments?.some((sa) => sa.id === bundle.selfAssessmentId))
    )

export const ProductBundleDomain = {
    getActiveProductBundles,
}
