import { colors, font, Line, Spacer, Table } from '@scouts/ui'

import { CompanyReturnSummaryDTO } from '@/models'

import { CompanyReturnTableRow } from './CompanyReturnTableRow'

export const CompanyReturnsTable = ({ companyReturns }: { companyReturns: CompanyReturnSummaryDTO[] | undefined }) => {
    const itemsCount = companyReturns?.length || 0
    const hasCompanyReturns = itemsCount > 0

    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="30%">Company</Table.Header.Cell>
                    <Table.Header.Cell width="20%">Status</Table.Header.Cell>
                    <Table.Header.Cell width="30%">Period</Table.Header.Cell>
                    <Table.Header.Cell width="20%" alignRight>
                        Updated
                    </Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>

            <Table.Body>
                {!hasCompanyReturns && (
                    <Spacer marginTop="12px">
                        <Line color={colors.neutralDarker} size={font.small}>
                            No company returns found
                        </Line>
                    </Spacer>
                )}
                {hasCompanyReturns && (
                    <>
                        {companyReturns?.map((companyReturn) => (
                            <CompanyReturnTableRow key={companyReturn.id} companyReturn={companyReturn} />
                        ))}
                    </>
                )}
            </Table.Body>
        </Table>
    )
}
