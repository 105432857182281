import { matchPath, useLocation, useRouteMatch } from 'react-router-dom'

import {
    PATH_CUSTOMER_DISCOUNTS,
    PATH_CUSTOMER_FILES,
    PATH_CUSTOMER_HISTORY,
    PATH_CUSTOMER_MESSAGES,
    PATH_CUSTOMER_SELF_ASSESSMENTS,
} from '@/constants'

const routesPotentiallyWithSelfAssessmentId = [
    PATH_CUSTOMER_SELF_ASSESSMENTS,
    PATH_CUSTOMER_MESSAGES,
    PATH_CUSTOMER_HISTORY,
    PATH_CUSTOMER_FILES,
    PATH_CUSTOMER_DISCOUNTS,
]

export const useSelfAssessmentIdFromPath = () => {
    const { pathname } = useLocation()

    const currentPathMatch = routesPotentiallyWithSelfAssessmentId.find((potentialPath) =>
        matchPath(pathname, { path: potentialPath })
    )
    const potentialParams = useRouteMatch(currentPathMatch)?.params

    const selfAssessmentId = Number(potentialParams?.selfAssessmentId)

    return selfAssessmentId
}
