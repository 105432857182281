import { getAuth, signOut } from 'firebase/auth'

export const triggerLogout = () => {
    const auth = getAuth()

    if (!auth) {
        return Promise.reject('Logout failed because Auth instance is not available.')
    }

    return signOut(auth)
}
