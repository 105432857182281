import { apiSlice } from '@/store/api-slice'
import { Tags } from '@/store/tags'

import { useGetTaxRegistrationsQuery, GetTaxRegistrationsParams } from '../api'

export const useTaxRegistrations = ({ options }: GetTaxRegistrationsParams) => {
    const { data: fetchedData, isLoading, isFetching, isError } = useGetTaxRegistrationsQuery({ options })
    const { data, pagination } = fetchedData || {}

    return {
        taxRegistrations: data,
        isError,
        isLoading,
        isFetching,
        pagination,
    }
}

export const invalidateTaxRegistrations = () => apiSlice.util.invalidateTags([Tags.TaxRegistrations])
