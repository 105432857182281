import PropTypes from 'prop-types'
import { lazy, Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import { Loadable, Page } from '@scouts/ui'

import { requestAccountant } from '../../actions'
import { PATH_ACCOUNTANTS, PATH_ROOT, PATH_USERS } from '../../constants'
import { isAdmin } from '../../helpers'
import { getAccountantById } from '../../selectors'
import PageBackButton from '../ui/PageBackButton'

const UserProfileForm = lazy(() => import('./UserProfileForm'))
const UserConnections = lazy(() => import('./UserConnections'))
class UserProfile extends Component {
    componentDidMount() {
        const { dispatch, accountantId } = this.props
        dispatch(requestAccountant(accountantId))
    }

    render() {
        const { accountant, user } = this.props

        if (!isAdmin(user)) return <Redirect to={PATH_ROOT} />

        if (!accountant || !accountant.id) return null

        const { isTaxScoutsSupport } = accountant

        const backPath = isTaxScoutsSupport ? PATH_USERS : PATH_ACCOUNTANTS
        const backTitle = `Back to ${isTaxScoutsSupport ? 'users' : 'accountants'}`

        return (
            <Page>
                <PageBackButton to={backPath}>{backTitle}</PageBackButton>

                <Page.Heading>Edit user details</Page.Heading>

                <Page.Main>
                    <Suspense fallback={<Loadable isLoading />}>
                        <UserProfileForm accountant={accountant} user={user} />
                        {!isTaxScoutsSupport && <UserConnections accountant={accountant} />}
                    </Suspense>
                </Page.Main>
            </Page>
        )
    }
}

UserProfile.propTypes = {
    accountantId: PropTypes.number.isRequired,
    accountant: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.object.isRequired,
}
UserProfile.defaultProps = {
    accountant: null,
}

const mapStateToProps = (state, ownProps) => {
    const { user } = state
    const { match } = ownProps

    const accountantId = Number(match.params.accountantId)

    return {
        accountantId,
        accountant: getAccountantById(state, { accountantId }),
        user,
    }
}

export default withRouter(connect(mapStateToProps)(UserProfile))
