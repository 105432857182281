import PropTypes from 'prop-types'
import styled from 'styled-components'

import SelfAssessmentsFilterUnpaid from './SelfAssessmentsFilterUnpaid'

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const Filter = styled.div``

const SelfAssessmentsAccountantFilters = (props) => {
    const { hasLoaded, filterIncludeUnpaid, toggleUnpaid } = props

    return (
        <Container>
            <Filter>
                <SelfAssessmentsFilterUnpaid
                    filterIncludeUnpaid={filterIncludeUnpaid}
                    toggleUnpaid={toggleUnpaid}
                    disabled={!hasLoaded}
                />
            </Filter>
        </Container>
    )
}

SelfAssessmentsAccountantFilters.propTypes = {
    filterIncludeUnpaid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    toggleUnpaid: PropTypes.func.isRequired,
    hasLoaded: PropTypes.bool.isRequired,
}

SelfAssessmentsAccountantFilters.defaultProps = {
    filterIncludeUnpaid: false,
}

export default SelfAssessmentsAccountantFilters
