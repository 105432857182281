import { useSelector } from 'react-redux'

import { Container, Flexer, Grid, GridItem } from '@scouts/ui'

import { featureFlags } from '@/feature-flags'
import { getDraftedReturnsCount } from '@/selectors/dashboard'

import { useUser } from '@/hooks/use-user'

import BannerDraftedReturns from './banners/BannerDraftedReturns'
import { BannerHoliday } from './banners/BannerHoliday'
import { BannerTrialWelcome } from './banners/BannerTrialWelcome'
import { DashboardEscalations } from './DashboardEscalations'
import { DashboardDownloadsRow } from './downloads/DashboardDownloadsRow'
import { DashboardAccountantMeetings } from './products/DashboardAccountantMeetings'
import { DashboardConsultations } from './products/DashboardConsultations'
import { DashboardProfile } from './profile/DashboardProfile'
import { DashboardStatsRow } from './stats/DashboardStatsRow'
import { DashboardStatsSelfAssessments } from './stats/DashboardStatsSelfAssessments'
import { DashboardStatsTaxConsultations } from './stats/DashboardStatsTaxConsultations'
import { DashboardStatsTaxRegistrations } from './stats/DashboardStatsTaxRegistrations'

export const Dashboard = () => {
    const user = useUser()

    const { allowSubscription, allowTaxConsultation, isOnTrial } = user

    const draftedReturnsCount = useSelector(getDraftedReturnsCount)

    const renderBanner = () => {
        const shouldShowDraftedCustomerBanner =
            featureFlags.featureReturningCustomersBanner &&
            draftedReturnsCount > 0 &&
            BannerDraftedReturns.isBannerHidden() === false

        if (shouldShowDraftedCustomerBanner) return <BannerDraftedReturns draftedReturnsCount={draftedReturnsCount} />

        const shouldShowTrialBanner = featureFlags.featureTourFirstTaxReturn && isOnTrial

        if (shouldShowTrialBanner) return <BannerTrialWelcome />

        return null
    }

    return (
        <Container desktopPadding="36px 48px 192px" padding="24px 24px 192px">
            <Grid columns={1} desktopColumns="1fr 300px" desktopGap="48px" gap="24px">
                <GridItem desktopColumn="1" desktopRow="1" row="2">
                    <Flexer column gap="24px" desktopGap="48px">
                        <BannerHoliday />

                        {renderBanner()}

                        <DashboardEscalations accountantId={user.id} />

                        {allowTaxConsultation && <DashboardConsultations accountantId={user.id} />}

                        {allowSubscription && <DashboardAccountantMeetings accountantId={user.id} />}

                        <DashboardStatsSelfAssessments accountantId={user.id} isOnTrial={isOnTrial} />

                        {!isOnTrial && <DashboardStatsTaxRegistrations accountantId={user.id} />}

                        {allowTaxConsultation && <DashboardStatsTaxConsultations accountantId={user.id} />}

                        <DashboardStatsRow accountantId={user.id} isOnTrial={isOnTrial} />
                        <DashboardDownloadsRow accountantId={user.id} />
                    </Flexer>
                </GridItem>
                <GridItem desktopColumn="2" row="1">
                    <DashboardProfile accountant={user} />
                </GridItem>
            </Grid>
        </Container>
    )
}
