import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import config from '@/config'
import { getAccessTokenAsync } from '@/helpers/access-token'

import { Tags } from './tags'

export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: async (headers) => {
            const token = await getAccessTokenAsync()
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        },
    }),
    endpoints: () => ({}),
    tagTypes: Object.values(Tags),
    reducerPath: 'api-slice',
})
