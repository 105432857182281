import { useState } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { useToggle } from '@scouts/helpers'
import {
    Button,
    ConfirmationModal,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    TextArea,
    TextAreaOnChangeParams,
} from '@scouts/ui'

import { PATH_CUSTOMER } from '@/constants'
import { CompanyReturnStatusTypes } from '@/models'
import { MessageTemplateDTO } from '@/models/message-template'
import { useUpdateCompanyReturnStatus } from '@/store/company-returns/hooks'

import { MessageTemplateSelect } from '@/components/message-templates/MessageTemplateSelect'

import { useGetCompanyReturnParams } from './hooks/use-get-params'

interface CompanyReturnStatusMessageProps {
    onClose: () => void
    status: CompanyReturnStatusTypes | null
}

export const CompanyReturnStatusMessage = ({ onClose, status }: CompanyReturnStatusMessageProps) => {
    const [customMessage, setCustomMessage] = useState('')
    const [messageTemplateId, setMessageTemplateId] = useState<string>('')
    const [hasEditedTemplateMessage, setHasEditedTemplateMessage] = useState(false)

    const [isCancelConfirmationModalOpen, openCancelConfirmationModal, closeCancelConfirmationModal] = useToggle()

    const { customerId, companyId, companyReturnId, hasAllCompanyReturnParams } = useGetCompanyReturnParams()
    const { updateCompanyReturnStatus, isLoading } = useUpdateCompanyReturnStatus()

    const handleMessageTemplateChange = ({
        value: selectedTemplateId,
        template: selectedTemplate,
    }: {
        value: string
        template?: MessageTemplateDTO
    }) => {
        if (customMessage && hasEditedTemplateMessage) {
            // @TODO: use ConfirmationModal
            const isConfirmed = window.confirm('Are you sure?\n\nYou will lose the current message')
            if (!isConfirmed) return
        }

        if (selectedTemplateId) {
            setCustomMessage(selectedTemplate?.content ?? '')
            setMessageTemplateId(selectedTemplateId)
            setHasEditedTemplateMessage(false)
        } else {
            setCustomMessage('')
            setMessageTemplateId('')
            setHasEditedTemplateMessage(false)
        }
    }

    const handleChange = ({ value }: TextAreaOnChangeParams) => {
        setCustomMessage(value)
        setHasEditedTemplateMessage(true)
    }

    const handleSendWithMessage = () => {
        handleSave(customMessage)
    }

    const handleSendWithoutMessage = () => {
        handleSave()
    }

    const handleSave = (message = '') => {
        if (hasAllCompanyReturnParams && status)
            updateCompanyReturnStatus({
                companyId,
                companyReturnId,
                customerId,
                status,
                customMessage: message,
            }).then(onClose)
    }

    return (
        <>
            <Modal onClose={customMessage ? openCancelConfirmationModal : handleSendWithoutMessage}>
                <Form onSubmit={handleSendWithMessage}>
                    <ModalHeader>
                        <ModalTitle>Any notes or comments you'd want to send to the client with the return?</ModalTitle>
                        <ModalSubtitle>
                            <Link to={generatePath(PATH_CUSTOMER, { customerId })} target="_blank">
                                Open profile in separate tab
                            </Link>
                        </ModalSubtitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Form.Row.Content>
                                Message template: &nbsp;&nbsp;
                                <MessageTemplateSelect
                                    name="messageTemplateId"
                                    value={messageTemplateId}
                                    onChange={handleMessageTemplateChange}
                                />
                            </Form.Row.Content>
                        </Form.Row>
                        <TextArea
                            name="customMessage"
                            value={customMessage}
                            placeholder="Notes…"
                            onChange={handleChange}
                            height="144px"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ModalActions>
                            <Button
                                onClick={customMessage ? openCancelConfirmationModal : handleSendWithoutMessage}
                                isSecondary
                                isLoading={isLoading && !customMessage}
                                disabled={isLoading}
                            >
                                No, thank you
                            </Button>
                            <Button type="submit" isLoading={isLoading && !!customMessage} disabled={isLoading}>
                                Send
                            </Button>
                        </ModalActions>
                    </ModalFooter>
                </Form>
            </Modal>

            {isCancelConfirmationModalOpen && (
                <ConfirmationModal
                    onConfirm={handleSendWithoutMessage}
                    onCancel={closeCancelConfirmationModal}
                    isLoading={isLoading}
                >
                    Are you sure? The message you typed will not be sent to the client.
                </ConfirmationModal>
            )}
        </>
    )
}
