import { AccountantMeetingSummaryDTO } from '@/models'

import { useGetAccountantMeetingQuery } from '../api'

interface UseAccountantMeetingProps {
    accountantMeetingId: AccountantMeetingSummaryDTO['id']
}

export const useAccountantMeeting = ({ accountantMeetingId }: UseAccountantMeetingProps) => {
    const { data, isError, isFetching } = useGetAccountantMeetingQuery({
        accountantMeetingId,
    })

    return {
        accountantMeeting: data,
        isError,
        isFetching,
    }
}
