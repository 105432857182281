import { DropdownSelect, DropdownSelectProps } from '@scouts/ui'

import { AccountantMeetingDomain } from '@/domain'
import { AccountantMeetingSummaryDTO } from '@/models'
import { useUpdateAccountantMeeting } from '@/store/accountant-meetings/hooks/use-update-accountant-meeting'

export const AccountantMeetingStatusSelect = ({
    accountantMeeting,
}: {
    accountantMeeting: AccountantMeetingSummaryDTO
}) => {
    const { updateAccountantMeetingStatus } = useUpdateAccountantMeeting()
    const { id, status } = accountantMeeting

    const options = Object.entries(AccountantMeetingDomain.statuses).map(([key, value]) => ({
        value: key,
        title: value.title,
        color: value.color,
    }))

    const handleChange: DropdownSelectProps['onChange'] = ({ value }) => {
        updateAccountantMeetingStatus({ id, status: value as AccountantMeetingSummaryDTO['status'] })
    }

    return <DropdownSelect name="status" value={status} options={options} onChange={handleChange} width="100%" />
}
