// src/arrays/getRange.ts
var getRange = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => i + start);
};

// src/constants.ts
var DATE_FORMAT = "YYYY-MM-DD";
var ES_MONTH_NAMES_LONG = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre"
];
var EN_MONTH_NAMES_LONG = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
var ES_MONTH_NAMES_SHORT = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
var EN_MONTH_NAMES_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var STORAGE_KEY_PREFIX = "taxscouts_";
var ADDRESS_TAXSCOUTS_SPAIN = {
  COMPANY_STREET_ADDRESS: "Carrera de San Jer\xF3nimo 15, Oficina 103",
  COMPANY_CITY: "Madrid",
  COMPANY_POSTCODE: "28014",
  COMPANY_COUNTRY: "Espa\xF1a",
  COMPANY_CIF: "B01886308",
  SUPPORT_PHONE: "+34 932 20 75 12"
};

// src/dates/addMonths.ts
import moment from "moment-timezone";
var addMonths = (date, months) => moment(date).add(months, "months").toDate();

// src/dates/addYears.ts
import dayjs from "dayjs";
var addYears = (date, years) => dayjs(date).add(years, "year").toDate();

// src/dates/dateDiff.ts
import moment2 from "moment-timezone";
var getMonthDiff = (fromDate, toDate) => {
  const from = moment2(fromDate);
  const to = moment2(toDate);
  return to.diff(from, "months", false);
};

// src/dates/formatDateUTC.ts
import dayjs2 from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// src/models/country.ts
var CountryCode = /* @__PURE__ */ ((CountryCode2) => {
  CountryCode2["GB"] = "GB";
  CountryCode2["ES"] = "ES";
  return CountryCode2;
})(CountryCode || {});

// src/models/currency.ts
var CurrencyCode = /* @__PURE__ */ ((CurrencyCode2) => {
  CurrencyCode2["EUR"] = "EUR";
  CurrencyCode2["GBP"] = "GBP";
  CurrencyCode2["USD"] = "USD";
  return CurrencyCode2;
})(CurrencyCode || {});
var CurrencySymbol = /* @__PURE__ */ ((CurrencySymbol2) => {
  CurrencySymbol2["EUR"] = "\u20AC";
  CurrencySymbol2["GBP"] = "\xA3";
  CurrencySymbol2["USD"] = "$";
  return CurrencySymbol2;
})(CurrencySymbol || {});

// src/models/locale.ts
var Locale = /* @__PURE__ */ ((Locale2) => {
  Locale2["GB"] = "en-GB";
  Locale2["ES"] = "es-ES";
  Locale2["CA"] = "ca-ES";
  Locale2["GL"] = "gl-ES";
  Locale2["EU"] = "eu-ES";
  Locale2["IT"] = "it-IT";
  Locale2["FR"] = "fr-FR";
  Locale2["DE"] = "de-DE";
  Locale2["PT"] = "pt-PT";
  return Locale2;
})(Locale || {});

// src/models/timezone.ts
var TimeZone = /* @__PURE__ */ ((TimeZone2) => {
  TimeZone2["GB"] = "Europe/London";
  TimeZone2["ES"] = "Europe/Madrid";
  return TimeZone2;
})(TimeZone || {});

// src/dates/formatDateUTC.ts
var formatDateUTC = (date, pattern, locale = "en-GB" /* GB */) => {
  dayjs2.extend(utc);
  dayjs2.extend(timezone);
  if (!date)
    return null;
  const utcDate = dayjs2.utc(date);
  return dayjs2.tz(utcDate).locale(locale).format(pattern);
};

// src/dates/formatDateWithShortMonthNames.ts
var shortMonthNamesByLocale = {
  ["es-ES" /* ES */]: ES_MONTH_NAMES_SHORT,
  ["en-GB" /* GB */]: EN_MONTH_NAMES_SHORT
};
var formatDateWithShortMonthNames = (dateTimeUTC, locale = "en-GB" /* GB */) => {
  const date = new Date(dateTimeUTC);
  const shortMonthNames = shortMonthNamesByLocale[locale];
  const month = shortMonthNames[date.getMonth()];
  return `${date.getDate()} ${month} ${date.getFullYear()}`;
};

// src/dates/formatDateQuery.ts
import moment3 from "moment-timezone";
var formatDateQuery = (date) => {
  if (!date)
    return date ?? null;
  return moment3.utc(date).format(DATE_FORMAT);
};

// src/dates/formatDateDayAndMonth.ts
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
var formatDateDayAndMonth = (dateTimeUTC, locale = "en-GB" /* GB */) => {
  const date = new Date(dateTimeUTC);
  const month = locale === "en-GB" /* GB */ ? EN_MONTH_NAMES_LONG[date.getMonth()] : ES_MONTH_NAMES_LONG[date.getMonth()];
  const day = date.getDate();
  return locale === "en-GB" /* GB */ ? `${month} ${day}${getDaySuffix(day)}` : `${day} de ${month}`;
};

// src/dates/formatTimeTodayAndDateIfLater.ts
import dayjs3 from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc2 from "dayjs/plugin/utc";
var formatTimeTodayAndDateIfLater = (dateTimeUTC) => {
  dayjs3.extend(utc2);
  dayjs3.extend(relativeTime);
  if (!dateTimeUTC)
    return null;
  const isToday = dayjs3(dateTimeUTC).isSame(dayjs3(), "day");
  if (isToday) {
    return dayjs3.utc(dateTimeUTC).fromNow();
  }
  return dayjs3.utc(dateTimeUTC).format("D MMM\u30FBHH:mm");
};

// src/dates/formatTimePeriod.ts
import dayjs4 from "dayjs";
var formatTimePeriod = (dateTimeUTC, durationInMinutes, locale = "en-GB" /* GB */) => {
  const timeZone = locale === "es-ES" /* ES */ ? "Europe/Madrid" /* ES */ : "Europe/London" /* GB */;
  const utcDate = dayjs4.utc(dateTimeUTC);
  const date = dayjs4.tz(utcDate, timeZone);
  const endDate = date.clone().add(durationInMinutes, "minute");
  return `${date.format("HH:mm")} ${locale === "en-GB" /* GB */ ? "to" : "a"} ${endDate.format("HH:mm")}`;
};

// src/dates/formatTimeFromNow.ts
import "dayjs/locale/es";
import dayjs5 from "dayjs";
import relativeTime2 from "dayjs/plugin/relativeTime";
import timezone2 from "dayjs/plugin/timezone";
import utc3 from "dayjs/plugin/utc";
var formatTimeFromNow = (date, locale = "en-GB" /* GB */, withoutSuffix) => {
  dayjs5.extend(utc3);
  dayjs5.extend(relativeTime2);
  dayjs5.extend(timezone2);
  const utcDate = dayjs5.utc(date);
  const timeZone = locale === "es-ES" /* ES */ ? "Europe/Madrid" /* ES */ : "Europe/London" /* GB */;
  return dayjs5.tz(utcDate, timeZone).locale(locale).fromNow(withoutSuffix);
};

// src/dates/getDate.ts
import moment4 from "moment-timezone";
var getDate = (date = moment4()) => moment4(date, DATE_FORMAT);
var getStringDate = (date) => getDate(moment4(date)).format(DATE_FORMAT);

// src/dates/getDaysToDate.ts
import dayjs6 from "dayjs";
var getDaysToDate = (toDate) => {
  const now = dayjs6().startOf("day");
  return dayjs6(toDate).diff(now, "day", false);
};

// src/dates/getIsAfterDate.ts
import dayjs7 from "dayjs";
var getIsAfterDate = (date, referenceDate) => {
  const dateObject = dayjs7(date);
  const dateReferenceObject = dayjs7(referenceDate);
  return dateObject.isAfter(dateReferenceObject);
};

// src/dates/getNextYear.ts
import dayjs8 from "dayjs";
var getNextYear = () => {
  return dayjs8().year() + 1;
};

// src/dates/getDaysInCurrentMonth.ts
import dayjs9 from "dayjs";
var getDaysInCurrentMonth = () => {
  return dayjs9().daysInMonth();
};

// src/dates/getDateFromTimeAgo.ts
import dayjs10 from "dayjs";
var getDateFromTimeAgo = ({ amount, unit, referenceDate }) => {
  const date = referenceDate ? dayjs10(referenceDate) : dayjs10();
  return date.subtract(amount, unit).toDate();
};

// src/dates/getDifferenceInDays.ts
var getDifferenceInDays = (fromDate, toDate) => {
  const from = new Date(fromDate);
  const to = new Date(toDate);
  const diffTime = Math.abs(to.getTime() - from.getTime());
  const diffDays = Math.ceil(diffTime / (1e3 * 60 * 60 * 24));
  return diffDays;
};

// src/dates/getMinutesToDate.ts
import dayjs11 from "dayjs";
import utc4 from "dayjs/plugin/utc";
var getMinutesToDate = (date) => {
  dayjs11.extend(utc4);
  const then = dayjs11.utc(date);
  const now = dayjs11.utc();
  return then.diff(now, "minute");
};

// src/dates/getMonth.ts
var getMonth = (date) => {
  const newDate = date || getDate();
  return getDate(newDate).month() + 1;
};

// src/dates/getPreviousDate.ts
import dayjs12 from "dayjs";
var getPreviousDate = (currentDate, subtractCount = 1, unit = "day") => {
  const previousDayDate = dayjs12(currentDate).subtract(subtractCount, unit).format(DATE_FORMAT);
  return previousDayDate;
};

// src/dates/getStartOfQuarter.ts
import dayjs13 from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs13.extend(quarterOfYear);
var getStartOfQuarter = () => {
  const startOfCurrentQuarter = dayjs13().startOf("quarter");
  const startOfLastQuarter = startOfCurrentQuarter.subtract(3, "month");
  return { startOfCurrentQuarter, startOfLastQuarter };
};

// src/dates/getYear.ts
import moment5 from "moment-timezone";
var getYear = (date) => {
  const newDate = moment5.utc(date) || getDate();
  return getDate(newDate).year();
};

// src/dates/isBetweenDates.ts
var isBetweenDates = (date, startDate, endDate) => {
  const normalizedDate = date ? new Date(date) : null;
  const normalizedStartDate = startDate ? new Date(startDate) : null;
  const normalizedEndDate = endDate ? new Date(endDate) : null;
  const dateToCompare = normalizedDate?.getTime();
  const dateStart = normalizedStartDate?.getTime();
  const dateEnd = normalizedEndDate?.getTime();
  if (!dateToCompare || !dateStart || !dateEnd)
    return false;
  if (dateToCompare >= dateStart && dateToCompare <= dateEnd) {
    return true;
  }
  return false;
};

// src/dates/isDayFridayToSunday.ts
var isDateFridayToSunday = (date) => {
  const day = (date ?? /* @__PURE__ */ new Date()).getDay();
  return day === 6 || day === 0 || day === 5;
};

// src/dates/isSameDate.ts
var isSameDate = (date, anotherDate) => {
  const normalizedDate = date ? new Date(date) : null;
  const normalizedAnotherDate = anotherDate ? new Date(anotherDate) : null;
  return normalizedDate?.getTime() === normalizedAnotherDate?.getTime();
};

// src/dates/isSameDay.ts
var isSameDay = (date = getDate(), anotherDate = getDate()) => getDate(date).isSame(getDate(anotherDate), "day");

// src/dates/isValidStringDate.ts
var isValidStringDate = (date) => {
  if (!date || date.length <= 0)
    return false;
  return !Number.isNaN(new Date(date).getTime());
};

// src/dates/subtractYears.ts
import dayjs14 from "dayjs";
var subtractYears = (date, years) => dayjs14(date).subtract(years, "year").toDate();

// src/debounce/debounce.ts
import _debounce from "lodash/debounce";
var debounce = _debounce;

// src/error-boundaries/ChunkLoadErrorBoundary.ts
import { Component } from "react";
var ChunkLoadErrorBoundary = class extends Component {
  constructor(props) {
    super(props);
    this.state = { isChunkLoadError: false };
  }
  static getDerivedStateFromError(error) {
    const isChunkLoadError = error?.name === "TypeError" && (/Failed to fetch dynamically imported module/.test(error?.message) || /error loading dynamically imported module/.test(error?.message) || /Importing a module script failed/.test(error?.message));
    return { isChunkLoadError, error };
  }
  render() {
    const { children, renderError } = this.props;
    const { isChunkLoadError, error } = this.state;
    if (isChunkLoadError) {
      return renderError();
    }
    if (error) {
      throw error;
    }
    return children;
  }
};

// src/feature-flags/override-feature-flags-with-query-params.ts
var overrideFeatureFlagsWithQueryParams = (isProduction, configFlags) => {
  if (isProduction)
    return configFlags;
  const urlQueryParams = new URLSearchParams(window.location.search);
  const queryFlags = {};
  Object.keys(configFlags).forEach((key) => {
    if (urlQueryParams.get(key) !== null) {
      queryFlags[key] = urlQueryParams.get(key) === "true";
    }
  });
  return {
    ...configFlags,
    ...queryFlags
  };
};

// src/feature-flags/use-experiment-feature-flag-variant.ts
import { useFeatureFlagVariantKey } from "posthog-js/react";
var useExperimentFeatureFlagVariant = (key) => {
  const variant = useFeatureFlagVariantKey(key);
  return variant;
};

// src/feature-flags/use-init-feature-flags.ts
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
var useInitFeatureFlags = () => {
  const posthog = usePostHog();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!isLoading)
      return;
    posthog.onFeatureFlags(() => {
      setIsLoading(false);
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 3e3);
  }, [isLoading, posthog]);
  return { isLoading };
};

// src/files/getIsFileType.ts
var getIsFileType = (filename, fileTypes) => !!filename && fileTypes.map((ft) => ft.toLowerCase()).includes(filename.toLowerCase().slice(filename.lastIndexOf(".") + 1, filename.length));

// src/files/saveBlobAsFile.ts
var saveBlobAsFile = ({ blob, fileName }) => {
  const url = URL.createObjectURL(blob);
  const anchorElement = document.createElement("a");
  anchorElement.href = url;
  anchorElement.download = fileName;
  anchorElement.click();
  URL.revokeObjectURL(url);
};

// src/files/openFileDialog.ts
var openFileDialog = ({ multiple = false, onChange, accept }) => {
  const inputElement = document.createElement("input");
  inputElement.hidden = true;
  inputElement.type = "file";
  inputElement.multiple = multiple;
  if (accept)
    inputElement.accept = accept;
  inputElement.onchange = (event) => {
    const target = event.target;
    if (!target)
      return;
    onChange(Array.from(target.files ?? []));
    target.value = "";
  };
  inputElement.click();
};

// src/hash/sha1.ts
var stringToSHA1Hash = async (text) => {
  const textBuffer = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest("SHA-1", textBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  return hash;
};

// src/hash/sha256.ts
var stringToSHA256Hash = async (text) => {
  const textBuffer = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest("SHA-256", textBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  return hash;
};

// src/hooks/use-component-did-mount.ts
import { useCallback, useRef } from "react";
var useComponentDidMount = () => {
  const isComponentBeingMountRef = useRef(true);
  const setComponentDidMount = useCallback(() => {
    isComponentBeingMountRef.current = false;
  }, []);
  return [isComponentBeingMountRef, setComponentDidMount];
};

// src/hooks/use-cookie-consent.ts
import Cookies from "js-cookie";
import { useCallback as useCallback2, useEffect as useEffect2, useState as useState2 } from "react";
var useCookieConsent = () => {
  const [isVisible, setIsVisible] = useState2(false);
  const hasAcceptedCookiesAlready = Cookies.get("OptanonAlertBoxClosed");
  const cookieConsentCookieData = Cookies.get("OptanonConsent") ?? "";
  const cookieParams = new URLSearchParams(cookieConsentCookieData);
  const awaitingReconsent = cookieParams.get("AwaitingReconsent");
  const listenForCookieChange = useCallback2(() => {
    const hasAcceptedOrRejectedCookies = !!Cookies.get("OptanonAlertBoxClosed");
    return new Promise((resolve) => {
      if (hasAcceptedOrRejectedCookies) {
        setIsVisible(false);
      } else {
        setTimeout(() => {
          resolve(listenForCookieChange());
        }, 1e3);
      }
    });
  }, []);
  const waitForOneTrust = useCallback2(
    (waitCount = 0) => {
      return new Promise((resolve) => {
        if (window.OneTrust) {
          resolve();
          const shouldRejectTracking = new URLSearchParams(window.location.search).get("shouldRejectTracking");
          if (shouldRejectTracking === "true") {
            rejectCookies();
            return;
          }
          setIsVisible(true);
          listenForCookieChange();
        } else if (waitCount < 5) {
          setTimeout(() => {
            resolve(waitForOneTrust(waitCount + 1));
          }, 2e3);
        }
      });
    },
    [listenForCookieChange]
  );
  const openPreferences = () => window.OneTrust?.ToggleInfoDisplay();
  const acceptCookies = () => {
    setIsVisible(false);
    window.OneTrust?.AllowAll();
  };
  const rejectCookies = () => {
    if (typeof window.OneTrust?.RejectAll === "function")
      window.OneTrust?.RejectAll();
    setIsVisible(false);
  };
  useEffect2(() => {
    if (!hasAcceptedCookiesAlready) {
      waitForOneTrust();
    }
  }, [hasAcceptedCookiesAlready, waitForOneTrust]);
  useEffect2(() => {
    if (awaitingReconsent === "true") {
      Cookies.remove("OptanonAlertBoxClosed");
    }
  }, [awaitingReconsent]);
  return {
    isVisible,
    openPreferences,
    acceptCookies,
    rejectCookies
  };
};

// src/hooks/use-local-storage.ts
import { useCallback as useCallback3, useEffect as useEffect3, useState as useState3 } from "react";

// src/storage/local-storage.ts
var isMissingLocalStorageExceptionHandled = false;
var localStorageSet = (name, value) => {
  try {
    localStorage.setItem(name, value);
  } catch (error) {
    handleLocalStorageError(error);
  }
};
var localStorageRemove = (name, prefix) => {
  try {
    localStorage.removeItem(prefix ? prefix + name : name);
  } catch (error) {
    handleLocalStorageError(error);
  }
};
var localStorageGet = (name) => {
  try {
    return localStorage.getItem(name);
  } catch (error) {
    handleLocalStorageError(error);
    return null;
  }
};
var handleLocalStorageError = (error) => {
  if (typeof window.localStorage !== "object") {
    if (isMissingLocalStorageExceptionHandled)
      return;
    isMissingLocalStorageExceptionHandled = true;
    alert(
      'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". In other cases please try using a different browser.'
    );
  } else {
    throw error;
  }
};

// src/storage/session-storage.ts
var isMissingSessionStorageExceptionHandled = false;
var sessionStorageSet = (name, value) => {
  try {
    sessionStorage.setItem(name, value);
  } catch (error) {
    handleSessionStorageError(error);
  }
};
var sessionStorageRemove = (name) => {
  try {
    sessionStorage.removeItem(name);
  } catch (error) {
    handleSessionStorageError(error);
  }
};
var sessionStorageGet = (name) => {
  try {
    return sessionStorage.getItem(name);
  } catch (error) {
    handleSessionStorageError(error);
    return false;
  }
};
var handleSessionStorageError = (error) => {
  if (typeof window.sessionStorage !== "object") {
    if (isMissingSessionStorageExceptionHandled)
      return;
    isMissingSessionStorageExceptionHandled = true;
    alert(
      'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". In other cases please try using a different browser.'
    );
  } else {
    throw error;
  }
};

// src/hooks/use-local-storage.ts
var getInitialStorageValue = (key, defaultValue) => {
  if (!key)
    throw new Error(`"key" must be a non-empty string, but "${key}" was passed.`);
  try {
    const storedString = localStorageGet(key);
    if (!storedString) {
      return defaultValue;
    }
    return JSON.parse(storedString);
  } catch (err) {
    console.warn(`Error reading storage key \u201C${key}\u201D:`, err);
    return defaultValue;
  }
};
var useLocalStorage = (key, defaultValue) => {
  const storageKey = STORAGE_KEY_PREFIX + key;
  const [value, setValue] = useState3(() => getInitialStorageValue(storageKey, defaultValue));
  const removeValue = useCallback3(() => {
    try {
      localStorageRemove(storageKey);
      setValue(void 0);
    } catch (error) {
      console.warn(`Error removing storage key \u201C${storageKey}\u201D:`, error);
    }
  }, [storageKey]);
  useEffect3(() => {
    if (value === void 0)
      return;
    localStorageSet(storageKey, JSON.stringify(value));
  }, [storageKey, value]);
  return [value, setValue, removeValue];
};

// src/hooks/use-on-mount.ts
import { useEffect as useEffect4 } from "react";
var useOnMount = (effect) => {
  useEffect4(effect, []);
};

// src/hooks/use-query-params.ts
import _omit from "lodash/omit";
import queryString from "query-string";
import { useCallback as useCallback4, useMemo } from "react";
import { useLocation } from "react-router-dom";
var useQueryParams = () => {
  const location = useLocation();
  const currentQueryParams = useMemo(() => queryString.parse(location.search), [location.search]);
  const overwriteQueryParams = useCallback4(
    (queryParamsObject) => ({
      ...location,
      search: queryString.stringify(queryParamsObject)
    }),
    [location]
  );
  const updateQueryParams = useCallback4(
    (queryParamsObject, pathname) => ({
      ...location,
      ...pathname && {
        pathname
      },
      search: queryString.stringify({ ...currentQueryParams, ...queryParamsObject })
    }),
    [currentQueryParams, location]
  );
  const removeQueryParams = useCallback4(
    (omittedPropertyList) => {
      const cleanQueryParams = _omit(currentQueryParams, omittedPropertyList);
      return { ...location, search: queryString.stringify(cleanQueryParams) };
    },
    [currentQueryParams, location]
  );
  return {
    queryParams: currentQueryParams,
    overwriteQueryParams,
    updateQueryParams,
    removeQueryParams
  };
};

// src/hooks/use-resize-observer.ts
import { useEffect as useEffect5, useMemo as useMemo2 } from "react";
var useResizeObserver = (ref, callback) => {
  const observer = useMemo2(() => new ResizeObserver((entries) => callback(entries[0].target)), [callback]);
  useEffect5(() => {
    if (!ref.current)
      return;
    const element = ref.current;
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [observer, ref]);
};

// src/hooks/use-same-origin-post-message-listener.ts
import { useCallback as useCallback5, useEffect as useEffect6 } from "react";
var getAreUrlsWithSameOrigin = (url1, url2) => {
  try {
    return new URL(url1).origin === new URL(url2).origin;
  } catch (ex) {
    return false;
  }
};
var useSameOriginPostMessageListener = (handleGivenCallback, targetOrigin) => {
  const handleSafely = useCallback5(
    (event) => {
      const isSameOriginMessage = getAreUrlsWithSameOrigin(event.origin, targetOrigin);
      if (!isSameOriginMessage) {
        console.warn("Rejected message from", event.origin, "expected", targetOrigin);
        return;
      }
      handleGivenCallback(event);
    },
    [handleGivenCallback, targetOrigin]
  );
  useEffect6(() => {
    window.addEventListener("message", handleSafely);
    return () => window.removeEventListener("message", handleSafely);
  }, [handleSafely]);
};

// src/hooks/use-scroll-to-hash-element.ts
import { useCallback as useCallback6, useEffect as useEffect7, useState as useState4 } from "react";
import { useLocation as useLocation2 } from "react-router-dom";
var scroll = (element, options = { isSmooth: false }) => {
  const { isSmooth } = options;
  element?.scrollIntoView({ behavior: isSmooth ? "smooth" : "auto", block: "start" });
};
var removeHashCharacter = (hash) => {
  const result = hash.slice(1);
  return result;
};
var useScrollToHashElement = (options) => {
  const { skipAutoScroll = false } = options || {};
  const location = useLocation2();
  const [hashElement, setHashElement] = useState4(null);
  const scrollToHashElement = useCallback6(
    (options2) => scroll(hashElement, options2),
    [hashElement]
  );
  useEffect7(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(removeHashCharacter(hash));
      setHashElement(element ?? void 0);
      if (!skipAutoScroll)
        scroll(element, { isSmooth: true });
    } else {
      setHashElement(void 0);
    }
  }, [location.hash, skipAutoScroll]);
  return {
    hashElement,
    scrollToHashElement
  };
};

// src/hooks/use-session-storage.ts
import { useEffect as useEffect8, useState as useState5 } from "react";
var getInitialStorageValue2 = (key, defaultValue) => {
  if (!key)
    throw new Error(`"key" must be a non-empty string, but "${key}" was passed.`);
  try {
    const storedString = sessionStorageGet(key);
    if (!storedString) {
      return defaultValue;
    }
    return JSON.parse(storedString);
  } catch (err) {
    console.warn(`Error reading storage key \u201C${key}\u201D:`, err);
    return defaultValue;
  }
};
var useSessionStorage = (key, defaultValue) => {
  const storageKey = STORAGE_KEY_PREFIX + key;
  const [value, setValue] = useState5(() => getInitialStorageValue2(storageKey, defaultValue));
  const removeValue = () => sessionStorageRemove(storageKey);
  useEffect8(() => {
    sessionStorageSet(storageKey, JSON.stringify(value));
  }, [storageKey, value]);
  return [value, setValue, removeValue];
};

// src/hooks/use-set-title.ts
import { useEffect as useEffect9 } from "react";
var useSetTitle = (title, rootTitle) => {
  useEffect9(() => {
    if (title && rootTitle) {
      document.title = `${title} - ${rootTitle}`;
      return () => {
        document.title = rootTitle;
      };
    }
    if (title) {
      document.title = title;
    }
    return;
  }, [title, rootTitle]);
};

// src/hooks/use-toggle.ts
import { useCallback as useCallback7, useState as useState6 } from "react";
var useToggle = (isOpenDefault = false) => {
  const [isOpen, setIsOpen] = useState6(isOpenDefault);
  const open = useCallback7(() => setIsOpen(true), []);
  const close = useCallback7(() => setIsOpen(false), []);
  const toggle = useCallback7(() => {
    setIsOpen((state) => !state);
  }, []);
  return [isOpen, open, close, toggle];
};

// src/input-formatters/onlyAlphaNumerical.ts
var toAlphaNumerical = (value) => typeof value === "string" ? value.replace(/[^A-Za-z0-9]/g, "") : value;

// src/input-formatters/toUpperCase.ts
var toUpperCase = (value) => typeof value === "string" ? value.toUpperCase() : value;

// src/match-response/match-response.ts
var matchSuccessResponse = (callback) => (response) => {
  if (!("error" in response))
    callback(response.data);
  return response;
};
var matchErrorResponse = (callback) => (response) => {
  if ("error" in response)
    callback(response.error);
  return response;
};
var isResponseError = (response) => {
  return "error" in response;
};
var unwrapResponse = (response) => {
  if ("error" in response)
    return [null, response.error];
  return [response.data, null];
};

// src/mobile/getExpoPushToken.ts
import Cookies2 from "js-cookie";
var getExpoPushToken = () => {
  const expoPushToken = Cookies2.get("expo-push-token");
  return expoPushToken;
};

// src/mobile/getIsMobileAppMode.ts
import Cookies3 from "js-cookie";
var getIsMobileAppMode = () => {
  const isMobileAppMode = Cookies3.get("is-mobile-app-mode") === "true";
  return isMobileAppMode;
};

// src/numbers/formatCurrency.ts
var currencySymbols = {
  ["EUR" /* EUR */]: "\u20AC" /* EUR */,
  ["GBP" /* GBP */]: "\xA3" /* GBP */,
  ["USD" /* USD */]: "$" /* USD */
};
var getCurrencySymbol = (currency) => currencySymbols[CurrencyCode[currency]] ?? currency;
var DECIMAL_COMMA_LOCALES = ["es-ES" /* ES */];
var getRemovedMeaninglessDecimals = (value, { locale }) => {
  if (DECIMAL_COMMA_LOCALES.includes(locale))
    return value.replace(",00", "");
  return value.replace(".00", "");
};
var getDecimalSeparator = (locale) => DECIMAL_COMMA_LOCALES.includes(locale) ? "," : ".";
var formatCurrency = (value, { currency, locale, removeSpaces = true, removeMeaninglessDecimals = true }) => {
  let intlFormatter;
  try {
    intlFormatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol"
    });
  } catch (e) {
    intlFormatter = {
      format: (number) => `${(number ?? 0).toFixed(2).replace(".", getDecimalSeparator(locale))}${getCurrencySymbol(currency)}`
    };
  }
  let result = intlFormatter.format(value);
  if (removeSpaces)
    result = result.replace(/\s/g, "");
  if (removeMeaninglessDecimals)
    result = getRemovedMeaninglessDecimals(result, { locale });
  return result;
};

// src/numbers/formatPercentage.ts
var formatPercentage = (value, options) => {
  const intlFormatter = new Intl.NumberFormat(options.locale, { style: "percent", maximumFractionDigits: 2 });
  return intlFormatter.format((value || 0) / 100).replace(/\s/, "");
};

// src/numbers/padWithZeroes.ts
var padWithZeroes = (number, places = 2) => String(number).padStart(places, "0");

// src/paths/generateApiPath.ts
import queryString2 from "query-string";
var generateApiPath = (url, urlParameters, options) => {
  let normalizedUrl = url;
  if (urlParameters) {
    Object.entries(urlParameters).forEach(([paramName, paramValue]) => {
      const replacePattern = new RegExp(`:${paramName}`, "g");
      normalizedUrl = normalizedUrl.replace(replacePattern, `${paramValue}`);
    });
  }
  if (options?.queryParameters) {
    normalizedUrl += `?${queryString2.stringify(options.queryParameters)}`;
  }
  return normalizedUrl;
};

// src/paths/getFullPath.ts
var getFullPath = (location) => {
  return location.pathname + location.search + location.hash;
};

// src/scroll/getScrollableAncestor.ts
var getScrollableAncestor = (element) => {
  if (!element)
    return null;
  if (element.scrollHeight > element.clientHeight) {
    return element;
  }
  return getScrollableAncestor(element.parentElement);
};

// src/scroll/scrollPageToTop.ts
var scrollPageToTop = (behavior) => {
  if (behavior) {
    window.scrollTo({ top: 0, left: 0, behavior });
  } else {
    window.scrollTo(0, 0);
  }
};

// src/scroll/scrollToTarget.ts
var scrollToTarget = (scrollTarget, buffer = 0, behavior = "smooth") => {
  if (!scrollTarget)
    return;
  const scrollableParent = getScrollableAncestor(scrollTarget);
  if (!scrollableParent)
    return;
  const scrollTargetParent = scrollTarget.offsetParent;
  const top = scrollTarget.offsetTop + (scrollTargetParent?.offsetTop || 0) + buffer;
  scrollableParent.scrollTo({ top, behavior });
};

// src/strings/compareStrings.ts
import Fuse from "fuse.js";
var compareStrings = (a, b, options = { treshold: 0.1 }) => {
  const fuse = new Fuse([a], { includeScore: true, threshold: 1, ignoreLocation: true });
  const searchResult = fuse.search(b);
  if (!searchResult[0] || !searchResult[0].score)
    return { score: 0, matches: false };
  const { score } = searchResult[0];
  return { score, matches: score <= options.treshold };
};
var compareFilenameWithCustomerName = ({
  fileName,
  customerFirstName,
  customerLastName
}) => {
  const normalizedCustomerName = `${customerFirstName}${customerLastName}`.replaceAll(" ", "").toLowerCase();
  const normalizedReversedCustomerName = `${customerLastName}${customerFirstName}`.replaceAll(" ", "").toLowerCase();
  const normalizedFilename = fileName.replaceAll(" ", "").replaceAll("-", "").replaceAll("_", "").toLowerCase();
  return normalizedFilename.includes(normalizedCustomerName) || normalizedFilename.includes(normalizedReversedCustomerName) || compareStrings(normalizedFilename, normalizedCustomerName).matches || compareStrings(normalizedFilename, normalizedReversedCustomerName).matches;
};

// src/strings/formatE164PhoneNumber.ts
var formatE164PhoneNumber = (phoneNumber, options) => {
  const phoneNumberNoSpaces = phoneNumber.split("").filter((char) => char !== " ").join("");
  if (phoneNumberNoSpaces.charAt(0) === "+")
    return phoneNumberNoSpaces;
  return `${options?.countryPrefix ?? ""}${phoneNumberNoSpaces}`;
};

// src/strings/splitStringAtUpperCase.ts
var splitStringAtUppercase = (string = "") => {
  return string.split(/(?=[A-Z])/).join(" ");
};

// src/strings/stringToTitleCase.ts
var stringToTitleCase = (text) => text.toLowerCase().split(" ").map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ");

// src/strings/truncateTextMiddle.ts
var truncateTextMiddle = (string, outputLength = 18, separator = "...") => {
  if (string.length <= outputLength)
    return string;
  const charsToShow = outputLength - separator.length;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);
  return string.substr(0, frontChars) + separator + string.substr(string.length - backChars);
};

// src/styles/getIsOverflowActive.ts
var getIsOverflowActive = (e) => {
  if (!e || !("offsetWidth" in e) || !("scrollWidth" in e))
    return false;
  return e.offsetWidth < e.scrollWidth;
};

// src/styles/hexToRgbA.ts
var hexToRgbA = (hex, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    return `rgba(${[c >> 16 & 255, c >> 8 & 255, c & 255].join(",")},${opacity})`;
  }
  throw new Error("Bad Hex");
};

// src/telemetry/mixpanel.ts
import mixpanel from "mixpanel-browser";
var hasMixpanelInitialized = false;
var mixpanelInit = ({ token, apiHost }) => {
  if (!token)
    return;
  const config = {
    loaded: () => {
      hasMixpanelInitialized = true;
    }
  };
  if (apiHost) {
    config.api_host = apiHost;
  }
  mixpanel.init(token, config);
};
var mixpanelIdentify = (userId) => {
  if (!hasMixpanelInitialized)
    return;
  mixpanel.identify(userId);
};
var mixpanelPeopleSet = (data = {}) => {
  if (!hasMixpanelInitialized)
    return;
  mixpanel.people.set(data);
};
var mixpanelTrack = (event, data = {}) => {
  if (!hasMixpanelInitialized)
    return;
  const isMobileAppMode = getIsMobileAppMode();
  mixpanel.track(event, { ...data, "Is Mobile App": isMobileAppMode });
};
var mixpanelReset = () => {
  if (!hasMixpanelInitialized)
    return;
  mixpanel.reset();
};

// src/telemetry/use-posthog-tracking.ts
import { usePostHog as usePostHog2 } from "posthog-js/react";
import { useCallback as useCallback8 } from "react";
var usePostHogTracking = () => {
  const posthog = usePostHog2();
  const identifyUser = useCallback8(
    (id, properties) => {
      posthog.identify(String(id), properties);
    },
    [posthog]
  );
  const capturePageView = useCallback8(
    (properties) => {
      posthog.capture("$pageview", properties);
    },
    [posthog]
  );
  const captureEvent = useCallback8(
    (name, properties) => {
      posthog.capture(String(name), properties || void 0);
    },
    [posthog]
  );
  const reset = useCallback8(() => {
    posthog.reset();
  }, [posthog]);
  return { identifyUser, capturePageView, captureEvent, reset };
};

// src/telemetry/use-data-layer-tracking.ts
import { useCallback as useCallback9 } from "react";
var useDataLayerTracking = () => {
  const identifyUser = useCallback9((id) => {
    if (typeof window === "undefined")
      return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: id,
      // To remove once GTM is updated for both apps
      user_id: id
    });
  }, []);
  const captureEvent = useCallback9((name, properties) => {
    if (typeof window === "undefined")
      return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: name, ...properties });
  }, []);
  return { identifyUser, captureEvent };
};

// src/throttle/throttle.ts
import _throttle from "lodash/throttle";
var throttle = _throttle;

// src/types/isDefined.ts
var isDefined = (value) => {
  return value !== null && value !== void 0;
};
export {
  ADDRESS_TAXSCOUTS_SPAIN,
  ChunkLoadErrorBoundary,
  CountryCode,
  CurrencyCode,
  CurrencySymbol,
  DATE_FORMAT,
  EN_MONTH_NAMES_LONG,
  EN_MONTH_NAMES_SHORT,
  ES_MONTH_NAMES_LONG,
  ES_MONTH_NAMES_SHORT,
  Locale,
  STORAGE_KEY_PREFIX,
  TimeZone,
  addMonths,
  addYears,
  compareFilenameWithCustomerName,
  compareStrings,
  debounce,
  formatCurrency,
  formatDateDayAndMonth,
  formatDateQuery,
  formatDateUTC,
  formatDateWithShortMonthNames,
  formatE164PhoneNumber,
  formatPercentage,
  formatTimeFromNow,
  formatTimePeriod,
  formatTimeTodayAndDateIfLater,
  generateApiPath,
  getDate,
  getDateFromTimeAgo,
  getDaysInCurrentMonth,
  getDaysToDate,
  getDifferenceInDays,
  getExpoPushToken,
  getFullPath,
  getIsAfterDate,
  getIsFileType,
  getIsMobileAppMode,
  getIsOverflowActive,
  getMinutesToDate,
  getMonth,
  getMonthDiff,
  getNextYear,
  getPreviousDate,
  getRange,
  getScrollableAncestor,
  getStartOfQuarter,
  getStringDate,
  getYear,
  hexToRgbA,
  isBetweenDates,
  isDateFridayToSunday,
  isDefined,
  isResponseError,
  isSameDate,
  isSameDay,
  isValidStringDate,
  localStorageGet,
  localStorageRemove,
  localStorageSet,
  matchErrorResponse,
  matchSuccessResponse,
  mixpanelIdentify,
  mixpanelInit,
  mixpanelPeopleSet,
  mixpanelReset,
  mixpanelTrack,
  openFileDialog,
  overrideFeatureFlagsWithQueryParams,
  padWithZeroes,
  saveBlobAsFile,
  scrollPageToTop,
  scrollToTarget,
  sessionStorageGet,
  sessionStorageRemove,
  sessionStorageSet,
  splitStringAtUppercase,
  stringToSHA1Hash,
  stringToSHA256Hash,
  stringToTitleCase,
  subtractYears,
  throttle,
  toAlphaNumerical,
  toUpperCase,
  truncateTextMiddle,
  unwrapResponse,
  useComponentDidMount,
  useCookieConsent,
  useDataLayerTracking,
  useExperimentFeatureFlagVariant,
  useInitFeatureFlags,
  useLocalStorage,
  useOnMount,
  usePostHogTracking,
  useQueryParams,
  useResizeObserver,
  useSameOriginPostMessageListener,
  useScrollToHashElement,
  useSessionStorage,
  useSetTitle,
  useToggle
};
