import { generatePath, Link } from 'react-router-dom'

import { colors, font, Flexer, Label, Line, Table } from '@scouts/ui'

import { PATH_CUSTOMER_TAX_RELIEF_CLAIMS } from '@/constants'
import { formatDate } from '@/helpers'
import { TaxReliefClaimDTO } from '@/models'

import { SupportAvatar } from '../ui/SupportAvatar'
import { TaxReliefClaimsStatusLabel } from './TaxReliefClaimsStatusLabel'

export const TaxReliefClaimsTableRow = ({ taxReliefClaim }: { taxReliefClaim: TaxReliefClaimDTO }) => {
    const {
        id: taxReliefClaimId,
        certificateCount,
        customerEmail,
        customerFullName,
        customerId,
        hasPaid,
        status,
        supportUserId,
        supportUserFullName,
        statusChangedDateUTC,
    } = taxReliefClaim

    const customerPath = generatePath(PATH_CUSTOMER_TAX_RELIEF_CLAIMS, { customerId, taxReliefClaimId })

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="30%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer column gap="6px">
                        <Line weight={font.weight.medium} lineHeight="1">
                            {customerFullName}
                        </Line>
                        <Line size={font.small} color={colors.neutralDarker} lineHeight="1">
                            {customerEmail}
                        </Line>
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer gap="3px">
                        <TaxReliefClaimsStatusLabel status={status} />

                        {hasPaid && <Label background={colors.mintLighter}>Paid</Label>}
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="25%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer gap="12px" alignCenter>
                        <SupportAvatar supportUserId={supportUserId} />
                        <Line size={font.small} color={colors.black}>
                            {supportUserFullName || '-'}
                        </Line>
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Flexer gap="3px" alignCenter>
                        <Line color={colors.black}>{certificateCount}</Line>
                    </Flexer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="20%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath} alignRight>
                    {!!statusChangedDateUTC && <Line size={font.small}>{formatDate(statusChangedDateUTC)}</Line>}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}
