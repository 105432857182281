import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Table } from '@scouts/ui'

import { addProductBundleRefund } from '@/actions'
import { CustomerDTO, ProductBundleDetailsDTO, RefundCreateDTO } from '@/models'
import { getCustomerProductBundles, getProductBundleById } from '@/selectors/product-bundles'
import { useAppDispatch } from '@/store'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { RefundModal } from '../../_shared/RefundModal'
import { CustomerOverviewProductBundle } from './CustomerOverviewProductBundle'
import { ProductBundleDeleteModal } from './ProductBundleDeleteModal'
import { ProductBundleDetailsModal } from './ProductBundleDetailsModal'
import { ProductBundleLinkedSelfAssessmentModal } from './ProductBundleLinkedSelfAssessmentModal'

export const CustomerOverviewProductBundles = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const dispatch = useAppDispatch()

    const { customer } = useCustomerFromPath()
    const { fullName: customerFullName } = customer

    const customerProductBundles = useSelector((state) => getCustomerProductBundles(state, { customerId }))
    const hasProductBundles = customerProductBundles.length > 0

    const [selectedProductBundleId, setSelectedProductBundleId] = useState<number | null>(null)
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isLinkedSelfAssessmentModal, setIsLinkedSelfAssessmentModal] = useState(false)

    const { price: selectedProductBundlePrice } = useSelector((state) =>
        getProductBundleById(state, { productBundleId: selectedProductBundleId })
    ) || { price: null }

    const openDetailsModal = (productBundleId: ProductBundleDetailsDTO['id']) => {
        setIsDetailsModalOpen(true)
        setSelectedProductBundleId(productBundleId)
    }

    const openRefundModal = (productBundleId: ProductBundleDetailsDTO['id']) => {
        setIsRefundModalOpen(true)
        setSelectedProductBundleId(productBundleId)
    }

    const openDeleteModal = (productBundleId: ProductBundleDetailsDTO['id']) => {
        setIsDeleteModalOpen(true)
        setSelectedProductBundleId(productBundleId)
    }

    const openLinkedSelfAssessmentModal = (productBundleId: ProductBundleDetailsDTO['id']) => {
        setIsLinkedSelfAssessmentModal(true)
        setSelectedProductBundleId(productBundleId)
    }

    const handleRefundProductBundle = (payload: RefundCreateDTO) => {
        dispatch(
            addProductBundleRefund({
                productBundleId: selectedProductBundleId,
                ...payload,
            })
        )
        setIsRefundModalOpen(false)
    }

    if (!hasProductBundles) return null

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Header.Row>
                        <Table.Header.Cell>Bundle purchases</Table.Header.Cell>
                    </Table.Header.Row>
                </Table.Header>

                <Table.Body>
                    {customerProductBundles.map((productBundle) => (
                        <CustomerOverviewProductBundle
                            productBundle={productBundle}
                            key={productBundle.id}
                            openDeleteModal={openDeleteModal}
                            openDetailsModal={openDetailsModal}
                            openLinkedSelfAssessmentModal={openLinkedSelfAssessmentModal}
                            openRefundModal={openRefundModal}
                        />
                    ))}
                </Table.Body>
            </Table>

            {isDetailsModalOpen && selectedProductBundleId && (
                <ProductBundleDetailsModal
                    customerId={customerId}
                    productBundleId={selectedProductBundleId}
                    handleClose={() => {
                        setIsDetailsModalOpen(false)
                    }}
                />
            )}

            {isRefundModalOpen && (
                <RefundModal
                    itemName="product bundle"
                    itemPrice={selectedProductBundlePrice}
                    onClose={() => setIsRefundModalOpen(false)}
                    onRefundItem={handleRefundProductBundle}
                    title={`Refund ${customerFullName}'s product bundle`}
                />
            )}

            {isDeleteModalOpen && selectedProductBundleId && (
                <ProductBundleDeleteModal
                    productBundleId={selectedProductBundleId}
                    handleClose={() => {
                        setIsDeleteModalOpen(false)
                    }}
                />
            )}

            {isLinkedSelfAssessmentModal && selectedProductBundleId && (
                <ProductBundleLinkedSelfAssessmentModal
                    productBundleId={selectedProductBundleId}
                    customerId={customerId}
                    handleClose={() => {
                        setIsLinkedSelfAssessmentModal(false)
                    }}
                />
            )}
        </>
    )
}
