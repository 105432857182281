import { createContext, useContext } from 'react'

interface IMessagesContext {
    customerId: number
}

export const MessagesContext = createContext<IMessagesContext | null>(null)

export const useMessagesContext = () => {
    const context = useContext(MessagesContext)

    if (context === null) throw new Error('`useMessagesContext` should be used inside a `MessagesContextProvider`')

    return context
}
