export const SubscriptionStatus = {
    Started: 'Started',
    Active: 'Active',
    Cancelled: 'Cancelled',
    Failed: 'Failed',
    Incomplete: 'Incomplete',
    IncompleteExpired: 'IncompleteExpired',
    PastDue: 'PastDue',
    Pending: 'Pending',
    Unpaid: 'Unpaid',
} as const

export interface SubscriptionDTO {
    cancellationDateUTC: string | null
    cancellationRequestedDateUTC: string | null
    customerEmail: string
    customerFullName: string
    customerId: number
    id: number
    price: number
    startDateUTC: string
    status: keyof typeof SubscriptionStatus
}
