import _get from 'lodash/get'

import { updateOrAddItemsById } from '@/helpers'

import { RECEIVE_SELF_ASSESSMENT_FILES, REMOVE_FILE } from '../actions'

const defaultState = { data: [] }

export default (state = defaultState, action) => {
    const { type, data } = action

    switch (type) {
        case RECEIVE_SELF_ASSESSMENT_FILES: {
            const items = updateOrAddItemsById({ currentItems: state.data, receivedItems: data })

            return { ...state, data: items }
        }

        case REMOVE_FILE: {
            const items = [..._get(state, ['data'], [])]

            const key = items.findIndex((o) => o.id === data.fileId)

            if (key > -1 && items[key]) {
                items.splice(key, 1)
            }

            return { ...state, data: items }
        }

        default:
            return state
    }
}
