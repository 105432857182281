import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { font, Button, Flexer, Line } from '@scouts/ui'

import SearchFilterAccountant from './SearchFilterAccountant'
import SearchFilterAdd from './SearchFilterAdd'
import SearchFilterAgentAuthStatuses from './SearchFilterAgentAuthStatuses'
import SearchFilterAllowances from './SearchFilterAllowances'
import SearchFilterCustomerEmail from './SearchFilterCustomerEmail'
import SearchFilterCustomerName from './SearchFilterCustomerName'
import SearchFilterFiledDate from './SearchFilterFiledDate'
import SearchFilterIncomeSources from './SearchFilterIncomeSources'
import SearchFilterMissingUtr from './SearchFilterMissingUtr'
import SearchFilterOnHold from './SearchFilterOnHold'
import SearchFilterPaymentDate from './SearchFilterPaymentDate'
import SearchFilterPaymentStatus from './SearchFilterPaymentStatus'
import SearchFilterReferralSource from './SearchFilterReferralSource'
import SearchFilterRefundReason from './SearchFilterRefundReason'
import SearchFilterPaymentType from './SearchFilterResultPaymentType'
import SearchFilterResultType from './SearchFilterResultType'
import SearchFilterSet from './SearchFilterSet'
import SearchFilterSignedUpDate from './SearchFilterSignedUpDate'
import SearchFilterSubmittedDate from './SearchFilterSubmittedDate'
import SearchFilterTaxConsultationCompletedDate from './SearchFilterTaxConsultationCompletedDate'
import SearchFilterTaxConsultationStatuses from './SearchFilterTaxConsultationStatuses'
import SearchFilterTaxConsultationTopics from './SearchFilterTaxConsultationTopics'
import SearchFilterTaxRegistrationPaymentStatus from './SearchFilterTaxRegistrationPaymentStatus'
import SearchFilterTaxRegistrationRefundReason from './SearchFilterTaxRegistrationRefundReason'
import SearchFilterTaxRegistrationStatuses from './SearchFilterTaxRegistrationStatuses'
import SearchFilterTaxReturnStatuses from './SearchFilterTaxReturnStatuses'
import SearchFilterTaxYears from './SearchFilterTaxYears'

const filterFields = {
    customerFirstName: 'customerName',
    customerLastName: 'customerName',
    filedDateStart: 'filedDate',
    filedDateEnd: 'filedDate',
    paymentDateStart: 'paymentDate',
    paymentDateEnd: 'paymentDate',
    signedUpDateStart: 'signedUpDate',
    signedUpDateEnd: 'signedUpDate',
    submittedDateStart: 'submittedDate',
    submittedDateEnd: 'submittedDate',
    taxConsultationCompletedDateStart: 'taxConsultationCompletedDate',
    taxConsultationCompletedDateEnd: 'taxConsultationCompletedDate',
}

const parseFilterFields = (filters = []) => {
    const result = []

    filters.forEach((field) => {
        const name = filterFields[field] ? filterFields[field] : field

        if (!result.includes(name)) {
            result.push(name)
        }
    })

    return result
}

class SearchFilter extends Component {
    constructor() {
        super()

        this.state = { activeFilters: [], hasActiveFilters: null, recentFilters: [] }
        _bindAll(this, ['addFilter'])
    }

    static getDerivedStateFromProps({ filter }) {
        const activeFilters = parseFilterFields(Object.keys(filter))
        const hasActiveFilters = activeFilters.length > 0

        return {
            activeFilters,
            hasActiveFilters,
        }
    }

    addFilter(updatedFilter, replace) {
        const recentFilters = parseFilterFields(Object.keys(updatedFilter))
        this.setState({ recentFilters })

        const { applyFilter } = this.props
        applyFilter(updatedFilter, replace)
    }

    render() {
        const { applyFilter, clearFilter, download, filter, itemsCount, pagination, removeFilter, replaceFilters } =
            this.props

        const { activeFilters, hasActiveFilters, recentFilters } = this.state

        const { currentPage, perPage, totalCount } = pagination

        const currentPageStarts = perPage * (currentPage - 1) + 1
        const currentPageEnds = currentPageStarts + itemsCount - 1

        const hasMatches = totalCount > 0

        const filters = [
            {
                name: 'resultType',
                component: (isOpenByDefault) => (
                    <SearchFilterResultType
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['resultType'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'accountantId',
                component: (isOpenByDefault) => (
                    <SearchFilterAccountant
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['accountantId'], 0)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'customerName',
                component: (isOpenByDefault) => (
                    <SearchFilterCustomerName
                        isOpenByDefault={isOpenByDefault}
                        customerFirstName={_get(filter, ['customerFirstName'], '')}
                        customerLastName={_get(filter, ['customerLastName'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'customerEmail',
                component: (isOpenByDefault) => (
                    <SearchFilterCustomerEmail
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['customerEmail'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'selfAssessmentStatuses',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxReturnStatuses
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['selfAssessmentStatuses'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'onHold',
                component: (isOpenByDefault) => (
                    <SearchFilterOnHold
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['onHold'], false)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'selfAssessmentTaxYears',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxYears
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['selfAssessmentTaxYears'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'selfAssessmentPayAfterFiling',
                component: (isOpenByDefault) => (
                    <SearchFilterPaymentType
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['selfAssessmentPayAfterFiling'], false)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'referralSource',
                component: (isOpenByDefault) => (
                    <SearchFilterReferralSource
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['referralSource'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'missingUtr',
                component: (isOpenByDefault) => (
                    <SearchFilterMissingUtr
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['missingUtr'], false)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'taxRegistrationStatuses',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxRegistrationStatuses
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['taxRegistrationStatuses'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'agentAuthorisationStatuses',
                component: (isOpenByDefault) => (
                    <SearchFilterAgentAuthStatuses
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['agentAuthorisationStatuses'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'signedUpDate',
                component: (isOpenByDefault) => (
                    <SearchFilterSignedUpDate
                        isOpenByDefault={isOpenByDefault}
                        signedUpDateStart={_get(filter, ['signedUpDateStart'], '')}
                        signedUpDateEnd={_get(filter, ['signedUpDateEnd'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'paymentDate',
                component: (isOpenByDefault) => (
                    <SearchFilterPaymentDate
                        isOpenByDefault={isOpenByDefault}
                        paymentDateStart={_get(filter, ['paymentDateStart'], '')}
                        paymentDateEnd={_get(filter, ['paymentDateEnd'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'filedDate',
                component: (isOpenByDefault) => (
                    <SearchFilterFiledDate
                        isOpenByDefault={isOpenByDefault}
                        filedDateStart={_get(filter, ['filedDateStart'], '')}
                        filedDateEnd={_get(filter, ['filedDateEnd'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'submittedDate',
                component: (isOpenByDefault) => (
                    <SearchFilterSubmittedDate
                        isOpenByDefault={isOpenByDefault}
                        submittedDateStart={_get(filter, ['submittedDateStart'], '')}
                        submittedDateEnd={_get(filter, ['submittedDateEnd'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'selfAssessmentIncomeSources',
                component: (isOpenByDefault) => (
                    <SearchFilterIncomeSources
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['selfAssessmentIncomeSources'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'selfAssessmentApplicableAllowances',
                component: (isOpenByDefault) => (
                    <SearchFilterAllowances
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['selfAssessmentApplicableAllowances'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'paymentStatus',
                component: (isOpenByDefault) => (
                    <SearchFilterPaymentStatus
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['paymentStatus'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'refundReasonType',
                component: (isOpenByDefault) => (
                    <SearchFilterRefundReason
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['refundReasonType'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'taxRegistrationPaymentStatus',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxRegistrationPaymentStatus
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['taxRegistrationPaymentStatus'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'taxRegistrationRefundReasonType',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxRegistrationRefundReason
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['taxRegistrationRefundReasonType'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'taxConsultationStatuses',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxConsultationStatuses
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['taxConsultationStatuses'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'taxConsultationCompletedDate',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxConsultationCompletedDate
                        isOpenByDefault={isOpenByDefault}
                        taxConsultationCompletedDateStart={_get(filter, ['taxConsultationCompletedDateStart'], '')}
                        taxConsultationCompletedDateEnd={_get(filter, ['taxConsultationCompletedDateEnd'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'taxConsultationTopics',
                component: (isOpenByDefault) => (
                    <SearchFilterTaxConsultationTopics
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['taxConsultationTopics'], [])}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
        ]

        return (
            <Flexer spaceBetween flexWrap gap="12px">
                <Flexer flexWrap gap="12px" alignCenter>
                    {hasActiveFilters && (
                        <Flexer flexWrap gap="12px">
                            {activeFilters.map((name) => {
                                const getComponent = _get(_find(filters, { name }), ['component'], () => null)
                                const isOpenByDefault = recentFilters.includes(name)
                                return <div key={name}>{getComponent(isOpenByDefault)}</div>
                            })}
                        </Flexer>
                    )}

                    <SearchFilterAdd applyFilter={this.addFilter} activeFilters={activeFilters} />

                    <SearchFilterSet replaceFilters={replaceFilters} />
                </Flexer>
                <Flexer gap="12px" alignCenter>
                    {hasMatches && (
                        <Line size={font.small}>
                            {currentPageStarts}–{currentPageEnds} of {totalCount}{' '}
                            {totalCount === 1 ? 'match' : 'matches'}
                        </Line>
                    )}

                    {!hasMatches && <Line size={font.small}>No matches found</Line>}

                    {hasActiveFilters && (
                        <Button isSecondary size="small" onClick={clearFilter}>
                            Clear
                        </Button>
                    )}

                    {hasMatches && (
                        <Button size="small" onClick={download}>
                            Export
                        </Button>
                    )}
                </Flexer>
            </Flexer>
        )
    }
}

SearchFilter.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    itemsCount: PropTypes.number.isRequired,
    pagination: PropTypes.object.isRequired,
    removeFilter: PropTypes.func.isRequired,
    replaceFilters: PropTypes.func.isRequired,
}

export default withRouter(SearchFilter)
