import { colors, font, Flexer, Line } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { CustomerDTO, IdentityVerificationDTO } from '@/models'

import { MarkAsCancelled } from '../actions/MarkAsCancelled'
import { MarkAsVerified } from '../actions/MarkAsVerified'
import { VerificationNotes } from '../VerificationNotes'
import { VerificationUploadModalLink } from '../VerificationUploadModalLink'
import { VerificationWidget } from '../VerificationWidget'

interface RequestedProps {
    customer: CustomerDTO
    identityVerification: IdentityVerificationDTO
}

export const Requested = ({ identityVerification, customer }: RequestedProps) => {
    const { notes, updatedDateUTC } = identityVerification

    return (
        <VerificationWidget
            background={colors.yellow}
            actions={
                <>
                    <MarkAsVerified
                        buttonLabel="Mark as verified"
                        customerId={customer.id}
                        identityVerification={identityVerification}
                    />

                    <MarkAsCancelled buttonLabel="Cancel request" identityVerification={identityVerification} />
                </>
            }
        >
            <Flexer column gap="9px" justifyStart>
                <Line weight={font.weight.medium}>Verification requested - {formatDate(updatedDateUTC)}</Line>

                <Line size={font.small}>Request sent, waiting for the client to upload their documents</Line>

                <VerificationNotes notes={notes} />

                <VerificationUploadModalLink customerId={customer.id} identityVerification={identityVerification} />
            </Flexer>
        </VerificationWidget>
    )
}
