import FileSaver from 'file-saver'
import _get from 'lodash/get'
import queryString from 'query-string'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    receiveCustomerSelfAssessments,
    receiveErrorAlerts,
    receiveSelfAssessment,
    receiveSelfAssessments,
    receiveSuccessAlerts,
    removeSelfAssessment,
    updateSelfAssessment,
    ADD_SELF_ASSESSMENT_PAYMENT,
    ADD_SELF_ASSESSMENT_REFUND,
    CREATE_SELF_ASSESSMENT,
    DELETE_SELF_ASSESSMENT,
    DOWNLOAD_SELF_ASSESSMENT_FILES,
    MIGRATE_SELF_ASSESSMENTS,
    REQUEST_CUSTOMER_SELF_ASSESSMENTS,
    REQUEST_SELF_ASSESSMENT,
    REQUEST_SELF_ASSESSMENTS,
    SAVE_SELF_ASSESSMENT,
    SAVE_SELF_ASSESSMENT_FINAL_RETURN,
    SAVE_SELF_ASSESSMENT_REFILING_STATUS,
    SAVE_SELF_ASSESSMENT_SECTION_STATUS,
    SAVE_SELF_ASSESSMENT_STATUS,
} from '../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE, SUPPORT_EMAIL } from '../constants'
import { handleError } from '../handlers'
import { apiCall, apiCallCancelable, getPaginationHeaders, isCancelledApiCall } from '../helpers'

export default function* selfAssessmentsSagas() {
    yield takeEvery(REQUEST_SELF_ASSESSMENTS, fetchSelfAssessments)
    yield takeLatest(REQUEST_CUSTOMER_SELF_ASSESSMENTS, fetchCustomerSelfAssessments)
    yield takeLatest(REQUEST_SELF_ASSESSMENT, fetchSelfAssessment)
    yield takeLatest(SAVE_SELF_ASSESSMENT, saveSelfAssessment)
    yield takeLatest(SAVE_SELF_ASSESSMENT_FINAL_RETURN, saveSelfAssessmentFinalReturn)
    yield takeLatest(SAVE_SELF_ASSESSMENT_REFILING_STATUS, saveSelfAssessmentRefilingStatus)
    yield takeLatest(SAVE_SELF_ASSESSMENT_STATUS, saveSelfAssessmentStatus)
    yield takeLatest(ADD_SELF_ASSESSMENT_PAYMENT, addSelfAssessmentPayment)
    yield takeLatest(ADD_SELF_ASSESSMENT_REFUND, addSelfAssessmentRefund)
    yield takeLatest(DELETE_SELF_ASSESSMENT, deleteSelfAssessment)
    yield takeLatest(DOWNLOAD_SELF_ASSESSMENT_FILES, downloadSelfAssessmentFiles)
    yield takeLatest(CREATE_SELF_ASSESSMENT, createSelfAssessment)
    yield takeLatest(MIGRATE_SELF_ASSESSMENTS, migrateSelfAssessments)
    yield takeLatest(SAVE_SELF_ASSESSMENT_SECTION_STATUS, saveSelfAssessmentSectionStatus)
}

function* fetchSelfAssessments({ data, meta }) {
    const statuses = _get(data, ['statuses'], [])
        .map((status, i) => `status[${i}]=${status}`)
        .join('&')

    const accountantId = _get(data, ['accountantId'], '')
    const returnOnHold = _get(data, ['returnOnHold'], false)
    const includeUnpaid = _get(data, ['includeUnpaid'])
    const daysOverdue = _get(data, ['daysOverdue'], null)

    const page = _get(data, ['page'], PAGINATION_DEFAULT_PAGE)
    const pageSize = _get(data, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE)

    const urlComponents = queryString.stringify({
        accountantId,
        returnOnHold,
        daysOverdue,
        ...(includeUnpaid === 'true' ? { includeUnpaid: true } : { includeUnpaid: false }),
        ...(page && { page }),
        ...(pageSize && { pageSize }),
    })

    const url = `selfassessments?${statuses}&${urlComponents}`

    const cancelToken = _get(meta, ['cancelToken'], null)

    try {
        yield put(showLoading())
        const response = yield apiCallCancelable({ url, cancelToken })
        const pagination = getPaginationHeaders(response.headers)
        yield put(receiveSelfAssessments(response.data, { pagination }))
    } catch (e) {
        if (!isCancelledApiCall(e)) {
            yield put(handleError(e))
        }
    } finally {
        yield put(hideLoading())
    }
}

function* fetchCustomerSelfAssessments(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${action.data.customerId}/selfassessments`)
        yield put(receiveCustomerSelfAssessments(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchSelfAssessment(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`selfassessments/${action.data.selfAssessmentId}`)
        yield put(receiveSelfAssessment(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveSelfAssessmentFinalReturn(action) {
    const url = `selfassessments/${action.data.selfAssessmentId}/finalreturn`

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'put', action.data.finalReturn)
        yield put(updateSelfAssessment(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveSelfAssessmentRefilingStatus(action) {
    const url = `selfassessments/${action.data.selfAssessmentId}/refilingstatus/${action.data.refilingStatus}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'put')
        yield put(updateSelfAssessment(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveSelfAssessmentStatus({ data }) {
    const { selfAssessmentId, status, onSuccess, onError, customMessage } = data

    const url = `selfassessments/${selfAssessmentId}/status/${status}`

    try {
        yield put(showLoading())

        const response = yield apiCall(url, 'put', `${customMessage || ''}`, {
            'Content-Type': 'application/json',
        })

        yield put(updateSelfAssessment(response.data))

        if (onSuccess) onSuccess(status)
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError()
    } finally {
        yield put(hideLoading())
    }
}

function* saveSelfAssessment(action) {
    try {
        yield put(showLoading())
        const response = yield apiCall(`selfassessments/${action.data.id}`, 'put', action.data)
        yield put(updateSelfAssessment(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* addSelfAssessmentPayment(action) {
    const { onSuccess, selfAssessmentId } = action.data
    const url = `selfassessments/${selfAssessmentId}/payment`

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'post')
        yield put(updateSelfAssessment(response.data))
        yield put(receiveSuccessAlerts('Payment recorded'))

        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* addSelfAssessmentRefund(action) {
    const { data } = action
    const { selfAssessmentId } = data

    const url = `selfassessments/${selfAssessmentId}/refund`

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'post', data)
        yield put(updateSelfAssessment(response.data))
        yield put(receiveSuccessAlerts('Customer refunded'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* deleteSelfAssessment(action) {
    const id = _get(action, ['data', 'id'], null)
    const { onSuccess } = action.data

    try {
        yield put(showLoading())
        yield apiCall(`selfassessments/${id}`, 'delete')
        yield put(removeSelfAssessment({ id }))

        if (onSuccess) onSuccess()

        yield put(receiveSuccessAlerts('Tax return deleted'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* downloadSelfAssessmentFiles({ data }) {
    const selfAssessmentId = _get(data, ['selfAssessmentId'], null)

    try {
        yield put(showLoading())
        const url = `selfassessments/${selfAssessmentId}/files/zip`
        const responseType = 'blob'
        const response = yield apiCall(url, undefined, undefined, undefined, undefined, responseType)
        if (response.data) {
            const file = new Blob([response.data], {})
            FileSaver.saveAs(file, `self-assessment-${selfAssessmentId}.zip`)
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* createSelfAssessment({ data, options }) {
    const { onSuccess } = options
    try {
        yield put(showLoading())
        const response = yield apiCall('selfassessments', 'post', data)

        if (onSuccess) onSuccess(response.data)

        yield put(receiveSuccessAlerts('Tax return successfully created'))
    } catch (e) {
        yield put(handleError(e))
        yield put(receiveErrorAlerts(`There was a problem creating the tax return, please contact ${SUPPORT_EMAIL}`))
    } finally {
        yield put(hideLoading())
    }
}

function* migrateSelfAssessments({ data, options }) {
    const { targetCustomerId, sourceCustomerId } = data
    const { onSuccess, onError } = options

    try {
        yield put(showLoading())
        yield apiCall(`selfassessments/${targetCustomerId}/migrate/${sourceCustomerId}`, 'post')

        if (onSuccess) onSuccess()

        yield put(receiveSuccessAlerts('Tax returns successfully migrated'))
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError()

        yield put(receiveErrorAlerts(`There was a problem migrating tax returns`))
    } finally {
        yield put(hideLoading())
    }
}

function* saveSelfAssessmentSectionStatus({ data, meta }) {
    const { selfAssessmentId, sectionStatus } = data
    const { onSuccess } = meta

    try {
        yield put(showLoading())
        yield apiCall(`selfassessments/${selfAssessmentId}/sectionstatuses/${sectionStatus}`, 'put')
        yield put(receiveSuccessAlerts('Self Assessment sections marked as completed'))

        if (onSuccess) onSuccess()
    } catch (e) {
        yield put(receiveErrorAlerts(`There was a problem marking Self Assessment sections as completed`))
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
