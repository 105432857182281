import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { colors, font, media, Button } from '@scouts/ui'

import { ESCALATIONS_TYPE_ESCALATION } from '../../../constants'
import { clickedOnScrollbar, formatDateYYYYMMDD } from '../../../helpers'
import { FilterDropdown } from '../../ui/filter'

const DropdownContainer = styled.div`
    position: relative;
`

const Item = styled.button`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: left;
    display: block;
    width: 100%;
    padding: 9px 0;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }

    &:first-child {
        margin-top: -9px;
    }

    &:last-child {
        margin-bottom: -9px;
    }
`

const EscalationsSearchFilterSet = ({ replaceFilters }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const openDropdown = () => {
        setIsDropdownOpen(true)
    }

    const closeDropdown = () => {
        setIsDropdownOpen(false)
    }

    const closeDropdownOnClick = (e) => {
        if (!clickedOnScrollbar(e.pageX)) {
            closeDropdown()
        }
    }

    const ref = useRef()
    useOnClickOutside(ref, closeDropdownOnClick)

    const addFilter = (filter) => {
        replaceFilters(filter)
        closeDropdown()
    }

    const supportUserId = useSelector((state) => state.user.id)

    const today = formatDateYYYYMMDD()

    const filters = [
        {
            defaultFilter: {
                supportUserId,
                isResolved: false,
            },
            label: 'Assigned to me',
        },
        {
            defaultFilter: {
                isResolved: false,
                occurredDateStart: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                occurredDateEnd: today,
            },
            label: 'Unresolved in last 30 days',
        },
        {
            defaultFilter: {
                isResolved: true,
                resolvedDateStart: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                resolvedDateEnd: today,
            },
            label: 'Resolved in last 30 days',
        },
        {
            defaultFilter: {
                isResolved: false,
                occurredDateStart: moment().startOf('month').format('YYYY-MM-DD'),
                occurredDateEnd: moment().endOf('month').format('YYYY-MM-DD'),
            },
            label: 'Opened this month',
        },
        {
            defaultFilter: {
                isResolved: true,
                occurredDateStart: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                occurredDateEnd: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                resolvedDateStart: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                resolvedDateEnd: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                type: ESCALATIONS_TYPE_ESCALATION,
            },
            label: 'Closed last month',
        },
    ].filter((o) => !o.shouldHide)

    return (
        <>
            <Button isSecondary size="small" onClick={openDropdown}>
                Shortcuts
            </Button>

            <DropdownContainer ref={ref}>
                {isDropdownOpen && (
                    <FilterDropdown>
                        {filters.map(({ defaultFilter, label }) => (
                            <Item key={label} onClick={() => addFilter(defaultFilter)}>
                                {label}
                            </Item>
                        ))}
                    </FilterDropdown>
                )}
            </DropdownContainer>
        </>
    )
}

EscalationsSearchFilterSet.propTypes = {
    replaceFilters: PropTypes.func.isRequired,
}

export default EscalationsSearchFilterSet
