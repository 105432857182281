import { DropdownSelect, DropdownSelectProps } from '@scouts/ui'

import {
    CompanyReturnDTO,
    CompanyReturnInactivityStatus,
    CompanyReturnInactivityStatuses,
    CompanyReturnInactivityStatusTitles,
} from '@/models'

interface CompanyReturnInactivityStatusDropdownProps {
    onChange: (value: CompanyReturnDTO['inactivityStatus']) => void
    inactivityStatus: CompanyReturnDTO['inactivityStatus']
}

const statuses: (CompanyReturnInactivityStatus | 'None')[] = [
    CompanyReturnInactivityStatuses.Dormant,
    CompanyReturnInactivityStatuses.NonTrading,
    CompanyReturnInactivityStatuses.DormantOrNonTrading,
    'None',
]

export const CompanyReturnInactivityStatusDropdown = ({
    inactivityStatus,
    onChange,
}: CompanyReturnInactivityStatusDropdownProps) => {
    const status = inactivityStatus ?? 'None'

    const availableStatuses = statuses.filter(
        (item) => item !== status && item !== CompanyReturnInactivityStatuses.DormantOrNonTrading
    )

    const options = [
        {
            value: status,
            title: CompanyReturnInactivityStatusTitles[status ?? 'None'],
            disabled: true,
        },
        ...availableStatuses.map((statusOption) => ({
            value: statusOption,
            title: CompanyReturnInactivityStatusTitles[statusOption],
        })),
    ]

    const handleChange: DropdownSelectProps['onChange'] = ({ value }) => {
        const mappedValue = value === 'None' ? null : (value as CompanyReturnInactivityStatus)
        onChange(mappedValue)
    }

    return (
        <DropdownSelect
            name="inactivityStatus"
            value={status}
            options={options}
            onChange={handleChange}
            width="100%"
            isSmall
        />
    )
}
