import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useCreateCompanyReturnMutation, CreateCompanyReturnParams } from '../api'

export const useCreateCompanyReturn = () => {
    const [action, { isLoading }] = useCreateCompanyReturnMutation()

    const createCompanyReturn = useCallback(
        (params: CreateCompanyReturnParams) =>
            action(params).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Company return created successfully' })
                })
            ),
        [action]
    )

    return {
        createCompanyReturn,
        isLoading,
    }
}
