import { useEffect, useRef } from 'react'

const useIsMounted = () => {
    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true

        const handleUnmount = () => {
            isMounted.current = false
        }
        return handleUnmount
    }, [])
    return isMounted.current
}

export default useIsMounted
