export const AgentAuthorisationStatus = {
    Submitted: 'Submitted',
    AwaitingCode: 'AwaitingCode',
    CodeSentToCustomer: 'CodeSentToCustomer',
    CodeReceived: 'CodeReceived',
    CodeSubmitted: 'CodeSubmitted',
    RequestExpiring: 'RequestExpiring',
    ClientAuthorised: 'ClientAuthorised',
    RequestExpired: 'RequestExpired',
    RequestFailed: 'RequestFailed',
    IncorrectCode: 'IncorrectCode',
    UnableToIssue: 'UnableToIssue',
} as const

export type AgentAuthorisationStatusTypes = (typeof AgentAuthorisationStatus)[keyof typeof AgentAuthorisationStatus]

export const AgentAuthorisationReasonType = {
    Required: 'Required',
    PostFilingSupport: 'PostFilingSupport',
} as const

export type AgentAuthorisationReasonTypeTypes =
    (typeof AgentAuthorisationReasonType)[keyof typeof AgentAuthorisationReasonType]

export interface AgentAuthorisationDTO {
    id: number
    customerId: number
    accountantId: number
    status: AgentAuthorisationStatusTypes
    reasonType: AgentAuthorisationReasonTypeTypes
    requestedDateUTC: string | null
    correlationId: string | null
    authorisationRequestID: string | null
    authorisationCode: string | null
    taxScoutsReference: string | null
    authorisationNote: string | null
    createdDateUTC: string
    updatedDateUTC: string
    codeIssuedDate: string | null
    pollInterval: number | null
}

export interface AgentAuthorisationSubmitDTO {
    agentAuthorisationId: AgentAuthorisationDTO['id'] | null
    customerId: AgentAuthorisationDTO['customerId']
    accountantId: AgentAuthorisationDTO['accountantId']
    taxScoutsReference: AgentAuthorisationDTO['taxScoutsReference']
    customerAbroad: boolean
    reasonType: AgentAuthorisationDTO['reasonType'] | null
}
