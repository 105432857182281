import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useRefundTaxConsultationMutation, RefundTaxConsultationParams } from '../api'

export const useRefundTaxConsultation = () => {
    const [action, { error, isLoading }] = useRefundTaxConsultationMutation()

    const refundTaxConsultation = useCallback(
        ({ taxConsultationId, data }: RefundTaxConsultationParams) =>
            action({ taxConsultationId, data }).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Refund was successfully submitted' })
                })
            ),
        [action]
    )

    return {
        refundTaxConsultation,
        error,
        isLoading,
    }
}
