import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import styled from 'styled-components'

import { colors, font, media, Flexer, Spacer, Spinner, Tabs } from '@scouts/ui'

import { PATH_ACCOUNTANTS } from '@/constants'
import { AccountantStatusFilter, AccountantStatusFilters, AccountantStatusFiltersTitles } from '@/models'

class AccountantsFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterText: '',
            isThrottling: false,
        }

        this.filterTextTimeout = null

        _bindAll(this, ['updateFilterText', 'triggerSearch'])
    }

    componentWillUnmount() {
        if (this.filterTextTimeout) clearTimeout(this.filterTextTimeout)
    }

    updateStatus(status) {
        const { history } = this.props

        this.setState({ isThrottling: false, filterText: '' })

        history.push({
            pathname: PATH_ACCOUNTANTS,
            search: queryString.stringify({
                ...(status === '' ? {} : { status }),
            }),
        })
    }

    updateFilterText(e) {
        if (this.filterTextTimeout) clearTimeout(this.filterTextTimeout)

        const filterText = e.target.value

        if (!filterText) {
            this.setState({ isThrottling: false, filterText }, () => {
                this.updateStatus(AccountantStatusFilter.Active)
            })
        } else if (filterText && filterText.length >= 2) {
            this.setState({ isThrottling: true, filterText }, () => {
                this.filterTextTimeout = setTimeout(() => {
                    this.triggerSearch()
                }, 500)
            })
        } else {
            this.setState({ isThrottling: false, filterText })
        }
    }

    triggerSearch() {
        const { history } = this.props
        const { filterText } = this.state

        this.setState({ isThrottling: false })

        history.push({
            pathname: PATH_ACCOUNTANTS,
            search: queryString.stringify({
                status: AccountantStatusFilter.All,
                ...(filterText ? { filterText } : {}),
            }),
        })
    }

    countPerFilter(filterStatus) {
        const { accountantStatusStatistics } = this.props

        if (!AccountantStatusFilters[filterStatus]) {
            return '?'
        }

        return AccountantStatusFilters[filterStatus].reduce(
            (total, status) => total + _get(_find(accountantStatusStatistics, { status }), ['count'], 0),
            0
        )
    }

    render() {
        const { filterStatus, isFetching } = this.props
        const { filterText, isThrottling } = this.state

        const isSearching = (isThrottling || isFetching) && !!filterText

        const isAllTabActive = !filterText && (!filterStatus || filterStatus === AccountantStatusFilter.All)

        return (
            <Flexer alignCenter>
                <Tabs>
                    <Tabs.Item onClick={() => this.updateStatus(AccountantStatusFilter.All)} isActive={isAllTabActive}>
                        {AccountantStatusFiltersTitles[AccountantStatusFilter.All]}
                        <Tabs.Item.Counter>{this.countPerFilter(AccountantStatusFilter.All)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateStatus(AccountantStatusFilter.Active)}
                        isActive={filterStatus === AccountantStatusFilter.Active}
                    >
                        {AccountantStatusFiltersTitles[AccountantStatusFilter.Active]}
                        <Tabs.Item.Counter>{this.countPerFilter(AccountantStatusFilter.Active)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateStatus(AccountantStatusFilter.Paused)}
                        isActive={filterStatus === AccountantStatusFilter.Paused}
                    >
                        {AccountantStatusFiltersTitles[AccountantStatusFilter.Paused]}
                        <Tabs.Item.Counter>{this.countPerFilter(AccountantStatusFilter.Paused)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateStatus(AccountantStatusFilter.Draft)}
                        isActive={filterStatus === AccountantStatusFilter.Draft}
                    >
                        {AccountantStatusFiltersTitles[AccountantStatusFilter.Draft]}
                        <Tabs.Item.Counter>{this.countPerFilter(AccountantStatusFilter.Draft)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateStatus(AccountantStatusFilter.Terminated)}
                        isActive={filterStatus === AccountantStatusFilter.Terminated}
                    >
                        {AccountantStatusFiltersTitles[AccountantStatusFilter.Terminated]}
                        <Tabs.Item.Counter>{this.countPerFilter(AccountantStatusFilter.Terminated)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Spacer margin="12px 0" tabletMargin="0 12px" />

                    <Tabs.Item
                        onClick={() => this.updateStatus(AccountantStatusFilter.OnTrial)}
                        isActive={filterStatus === AccountantStatusFilter.OnTrial}
                    >
                        {AccountantStatusFiltersTitles[AccountantStatusFilter.OnTrial]}
                        <Tabs.Item.Counter>{this.countPerFilter(AccountantStatusFilter.OnTrial)}</Tabs.Item.Counter>
                    </Tabs.Item>

                    <Tabs.Item
                        onClick={() => this.updateStatus(AccountantStatusFilter.AllowTaxConsultation)}
                        isActive={filterStatus === AccountantStatusFilter.AllowTaxConsultation}
                    >
                        {AccountantStatusFiltersTitles[AccountantStatusFilter.AllowTaxConsultation]}
                        <Tabs.Item.Counter>
                            {this.countPerFilter(AccountantStatusFilter.AllowTaxConsultation)}
                        </Tabs.Item.Counter>
                    </Tabs.Item>

                    <Spacer margin="12px 0" tabletMargin="0 12px" />

                    <SearchContainer>
                        <SearchInput
                            value={filterText}
                            onChange={this.updateFilterText}
                            placeholder="Find accountants…"
                        />
                        {isSearching && (
                            <SearchSpinner>
                                <Spinner isSmall color={colors.blue} />
                            </SearchSpinner>
                        )}
                    </SearchContainer>
                </Tabs>
            </Flexer>
        )
    }
}

AccountantsFilter.propTypes = {
    accountantStatusStatistics: PropTypes.array.isRequired,
    filterStatus: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
}

AccountantsFilter.defaultProps = {
    filterStatus: '',
}

export default AccountantsFilter

const SearchContainer = styled.div`
    position: relative;
`

const SearchInput = styled.input`
    font-size: ${font.small};
    width: 100%;
    padding: 7px 32px 7px 15px;
    background: ${colors.neutralLightest};
    border: 2px solid ${colors.neutralLightest};
    border-radius: 2px;

    ${media.pointer} {
        &:hover {
            border-color: ${colors.neutralLight};
        }
    }

    &:focus {
        outline: 0;
        border-color: ${colors.blue};
        background: ${colors.white};
    }

    &::placeholder {
        color: ${colors.neutralDarker};
    }

    ${media.tablet} {
        padding: 7px 32px 7px 12px;
    }
`

const SearchSpinner = styled.div`
    position: absolute;
    right: 7px;
    top: 7px;
`
