import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, Input } from '@scouts/ui'

const Form = styled.form``

const Actions = styled.div`
    display: flex;
    margin-top: 12px;
`

const Action = styled.div`
    margin-right: 6px;
`

const Fields = styled.div``

const Field = styled.div`
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const propTypes = {
    applyFilter: PropTypes.func.isRequired,
    closeDropdown: PropTypes.func,
    removeFilter: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
}

const defaultProps = {
    closeDropdown: null,
}

const FilterItemDropdownInputs = (props) => {
    const { values, applyFilter, removeFilter, closeDropdown } = props

    const [localValues, setLocalValues] = useState('')

    useEffect(() => {
        setLocalValues(values)
    }, [values])

    const handleSubmit = (e) => {
        e.preventDefault()

        const filter = {}

        localValues.forEach(({ name, value }) => {
            filter[name] = value
        })

        applyFilter(filter)

        if (closeDropdown) closeDropdown()
    }

    const handleChange = ({ name, value }) => {
        const updatedValues = [...localValues]

        const index = _findIndex(updatedValues, { name })

        if (index > -1) {
            updatedValues[index] = { ...updatedValues[index], value }
            setLocalValues(updatedValues)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Fields>
                {values.map(({ name, label }, index) => (
                    <Field key={name}>
                        <Input
                            name={name}
                            autoFocus={index === 0}
                            value={_get(_find(localValues, { name }), ['value'], '')}
                            onChange={handleChange}
                            placeholder={label}
                        />
                    </Field>
                ))}
            </Fields>

            <Actions>
                <Action>
                    <Button type="submit" size="small">
                        Apply
                    </Button>
                </Action>
                <Action>
                    <Button isSecondary size="small" onClick={removeFilter}>
                        Remove
                    </Button>
                </Action>
            </Actions>
        </Form>
    )
}

FilterItemDropdownInputs.propTypes = propTypes

FilterItemDropdownInputs.defaultProps = defaultProps

export default FilterItemDropdownInputs
