import { useState } from 'react'

import {
    addErrorToast,
    Button,
    DatePicker,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Radio,
    RadioOnChangeParams,
    TextArea,
} from '@scouts/ui'

import { AccountantPausedHistoryCreateDTO, PausedStatus, PausedStatusTypes } from '@/models'

const TODAY = new Date()
const NEXT_YEAR = TODAY.getFullYear() + 1
const TOMORROW = new Date()
TOMORROW.setDate(TOMORROW.getDate() + 1)

interface AccountantStatusModalProps {
    onSubmit: ({
        pausedStatusType,
        scheduledEndDate,
        pausedDescription,
    }: {
        pausedStatusType: AccountantPausedHistoryCreateDTO['pausedStatusType']
        scheduledEndDate: AccountantPausedHistoryCreateDTO['scheduledEndDate']
        pausedDescription: AccountantPausedHistoryCreateDTO['pausedDescription']
    }) => void
    onCancel: () => void
}

export const AccountantStatusModal = ({ onSubmit, onCancel }: AccountantStatusModalProps) => {
    const [pausedStatusType, setPausedStatusType] = useState<PausedStatusTypes>(PausedStatus.Busy)
    const [scheduledEndDate, setScheduledEndDate] = useState<string | Date | null>(null)
    const [pausedDescription, setPausedDescription] = useState<string>('')

    const handlePausedChange = ({ value }: RadioOnChangeParams) => {
        if (value === PausedStatus.Unavailable) {
            setPausedStatusType(PausedStatus.Unavailable)
            setScheduledEndDate(TOMORROW)
        } else {
            setPausedStatusType(PausedStatus.Busy)
        }
    }

    const handleSubmit = () => {
        if (pausedStatusType === PausedStatus.Unavailable && !scheduledEndDate) {
            addErrorToast({ body: `Please choose the 'Paused until' date` })
        } else {
            onSubmit({ pausedStatusType, scheduledEndDate, pausedDescription })
        }
    }

    return (
        <Modal onClose={onCancel}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Pause assignments</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Form.Control>
                            <Radio
                                checked={pausedStatusType === PausedStatus.Busy}
                                label="A little busy at the moment - pause new customer assignments"
                                name="pausedStatusType"
                                onChange={handlePausedChange}
                                value={PausedStatus.Busy}
                            />
                        </Form.Control>
                        <Form.Control>
                            <Radio
                                checked={pausedStatusType === PausedStatus.Unavailable}
                                label="Unavailable - pause new & returning customer assignments"
                                name="pausedStatusType"
                                onChange={handlePausedChange}
                                value={PausedStatus.Unavailable}
                            />
                        </Form.Control>
                    </Form.Row>

                    {pausedStatusType === PausedStatus.Unavailable && (
                        <>
                            <Form.Row>
                                <Form.Row.Title>Paused until</Form.Row.Title>
                                <Form.Row.Content>
                                    <DatePicker
                                        name="scheduledEndDate"
                                        value={scheduledEndDate || ''}
                                        onChange={({ value }) => setScheduledEndDate(value)}
                                        minDate={TOMORROW}
                                        maxDate={`${NEXT_YEAR}-12-31`}
                                    />
                                </Form.Row.Content>
                            </Form.Row>

                            <Form.Row>
                                <Form.Row.Title>
                                    Add a note to let the support team know why you're unavailable (optional)
                                </Form.Row.Title>
                                <Form.Row.Content>
                                    <TextArea
                                        name="pausedDescription"
                                        value={pausedDescription}
                                        onChange={({ value }) => setPausedDescription(value)}
                                        height="96px"
                                    />
                                </Form.Row.Content>
                            </Form.Row>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onCancel} isSecondary>
                            Cancel
                        </Button>
                        <Button type="submit">Pause</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
