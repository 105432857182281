import { useUpdateCompanyReturnMutation } from '../api'

export const useUpdateCompanyReturn = () => {
    const [updateCompanyReturn, { error, isLoading }] = useUpdateCompanyReturnMutation()

    return {
        updateCompanyReturn,
        error,
        isLoading,
    }
}
