import { generateApiPath } from '@scouts/helpers'

import { CustomerDTO, PartnerDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

export interface GetCustomersPartnerParams {
    customerId: CustomerDTO['id']
}

export interface UpdateCustomersPartnerParams {
    customerId: CustomerDTO['id']
    data: PartnerDTO
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomersPartner: builder.query<PartnerDTO, GetCustomersPartnerParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.PARTNERS.GET, { customerId }),
            }),
            providesTags: [Tags.Partner],
        }),
        updateCustomersPartner: builder.mutation<PartnerDTO, UpdateCustomersPartnerParams>({
            query: ({ customerId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.PARTNERS.PUT, { customerId }),
                body: data,
            }),
            invalidatesTags: [Tags.Partner],
        }),
    }),
})

export const { useGetCustomersPartnerQuery, useUpdateCustomersPartnerMutation } = extendedApi
