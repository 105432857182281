export const PATH_ACCOUNTANT_MEETINGS = '/accountantMeetings'
export const PATH_ACCOUNTANTS = '/accountants'
export const PATH_ACCOUNTANT = `${PATH_ACCOUNTANTS}/:accountantId(\\d+)`
export const PATH_CUSTOMERS = '/customers'
export const PATH_CUSTOMER = `${PATH_CUSTOMERS}/:customerId(\\d+)`
export const PATH_CUSTOMER_BANK_DETAILS = `${PATH_CUSTOMER}/bankdetails`
export const PATH_CUSTOMER_BOOKKEEPING = `${PATH_CUSTOMERS}/:customerId(\\d+)/bookkeeping`
export const PATH_CUSTOMER_CONSULTATIONS = `${PATH_CUSTOMER}/consultations/:taxConsultationId(\\d+)?`
export const PATH_CUSTOMER_ACCOUNTANT_MEETINGS = `${PATH_CUSTOMER}/meetings/:accountantMeetingId(\\d+)?`
export const PATH_CUSTOMER_TAX_RELIEF_CLAIMS = `${PATH_CUSTOMER}/taxReliefClaims/:taxReliefClaimId(\\d+)?`
export const PATH_CUSTOMER_TAX_CONSULTATION = `${PATH_CUSTOMER}/consultations/:taxConsultationId(\\d+)?`
export const PATH_CUSTOMER_CONSULTATIONS_AGREEMENT = `${PATH_CUSTOMER_CONSULTATIONS}/agreement`
export const PATH_CUSTOMER_CONSULTATIONS_SUMMARY_FORM = `${PATH_CUSTOMER_CONSULTATIONS}/summarize`
export const PATH_CUSTOMER_DISCOUNTS = `${PATH_CUSTOMER}/discounts/:selfAssessmentId(\\d+)?`
export const PATH_CUSTOMER_EDIT = `${PATH_CUSTOMER}/edit`
export const PATH_CUSTOMER_FILES = `${PATH_CUSTOMER}/files/:selfAssessmentId(\\d+)?`
export const PATH_CUSTOMER_HISTORY = `${PATH_CUSTOMER}/history/:selfAssessmentId(\\d+)?`
export const PATH_CUSTOMER_MESSAGES = `${PATH_CUSTOMER}/messages/:selfAssessmentId(\\d+)?`
export const PATH_CUSTOMER_PARTNER = `${PATH_CUSTOMER}/partner`
export const PATH_CUSTOMER_PREVIOUS_ACCOUNTANT_DETAILS = `${PATH_CUSTOMER}/previousaccountantdetails`
export const PATH_CUSTOMER_SELF_ASSESSMENTS = `${PATH_CUSTOMER}/assessment/:selfAssessmentId?`
export const PATH_CUSTOMER_SELF_ASSESSMENTS_AGREEMENT = `${PATH_CUSTOMER_SELF_ASSESSMENTS}/agreement`
export const PATH_CUSTOMER_TAX_REGISTRATION_AGREEMENT = `${PATH_CUSTOMER}/taxRegistration/agreement`
export const PATH_CUSTOMER_COMPANY_PROFILE = `${PATH_CUSTOMER}/company/:companyId(\\d+)?/profile`
export const PATH_CUSTOMER_COMPANY_RETURN = `${PATH_CUSTOMER}/company/:companyId(\\d+)?/companyReturn/:companyReturnId(\\d+)?`
export const PATH_CUSTOMER_COMPANY_RETURN_AGREEMENT = `${PATH_CUSTOMER_COMPANY_RETURN}/agreement`
export const PATH_DASHBOARD = '/dashboard'
export const PATH_ESCALATIONS = '/escalations'
export const PATH_EXTERNAL_EMAIL_AUTHENTICATED = '/externalemailauthenticated'
export const PATH_INBOX = '/inbox/:notificationId?'
export const PATH_LOGOUT = '/logout'
export const PATH_OFF_PLATFORM_SERVICES_REQUESTS = '/additionalServices'
export const PATH_PROFILE = '/profile'
export const PATH_RETURNING_CUSTOMERS = '/returningCustomers'
export const PATH_REWARDS = '/rewards'
export const PATH_ROOT = '/'
export const PATH_SEARCH = '/search'
export const PATH_SELF_ASSESSMENTS = '/selfAssessments'
export const PATH_SUBSCRIPTIONS = '/subscriptions'
export const PATH_TAX_REGISTRATION = '/taxRegistrations'
export const PATH_TAX_CONSULTATIONS = '/taxConsultations'
export const PATH_USERS = '/users'
export const PATH_USERS_CREATE = `${PATH_USERS}/create`
export const PATH_USER = `${PATH_USERS}/:accountantId(\\d+)`
export const PATH_USER_REVIEWS = `${PATH_USER}/reviews`
export const PATH_DISCOUNT_SCHEMES = '/discountSchemes'
export const PATH_INTEGRATIONS = '/integrations'
export const PATH_INTEGRATIONS_NYLAS = '/integrations/nylas'
export const PATH_INTEGRATIONS_NYLAS_SETTINGS = '/integrations/nylas/settings'
export const PATH_INTEGRATIONS_NYLAS_CALLBACK = '/authenticated/nylas'
export const PATH_INTEGRATIONS_NYLAS_CREATE_SCHEDULE = '/authenticated/nylas/schedule'
export const PATH_INTEGRATIONS_NYLAS_EDIT_SCHEDULE = '/integrations/nylas/schedule'
export const PATH_INTEGRATIONS_PAYE = '/integrations/paye'
export const PATH_INTEGRATIONS_PAYE_CALLBACK = '/hmrcauthenticated'
export const PATH_TEMPLATES = '/templates'
export const PATH_TEMPLATES_TEMPLATE = '/templates/:id(\\d+)'
export const PATH_TEMPLATES_TEMPLATE_CREATE = '/templates/create'
export const PATH_TOOLS = '/tools'
export const PATH_TOOLS_CGT = '/tools/cgt'
export const PATH_SECURITY = '/security'
export const PATH_TAX_RELIEF_CLAIMS = '/taxReliefClaims'
export const PATH_RESET_PASSWORD = '/resetPassword'
export const PATH_SUPPORT_DASHBOARD = '/supportDashboard'
export const PATH_COMPANY_RETURNS = '/companyReturns'

export const PATH_HASH_CHATS = '#chats'
export const PATH_HASH_ESCALATIONS = '#escalations'
export const PATH_HASH_NOTES = '#notes'

export const QUERY_PARAM_ESCALATION_CREATE = 'escalation-create'
export const QUERY_PARAM_ESCALATION_EDIT = 'escalation-edit'
export const QUERY_PARAM_NOTE_CREATE = 'note-create'
export const QUERY_PARAM_NOTE_EDIT = 'note-edit'
