import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Button } from '@scouts/ui'

import RewardsFilterAdd from './RewardsFilterAdd'
import RewardsFilterBankDetails from './RewardsFilterBankDetails'
import RewardsFilterIgnored from './RewardsFilterIgnored'
import RewardsFilterNameOrEmail from './RewardsFilterNameOrEmail'
import RewardsFilterPaidStatus from './RewardsFilterPaidStatus'
import RewardsFilterReferrerRewarded from './RewardsFilterReferrerRewarded'
import RewardsFilterSet from './RewardsFilterSet'

const Container = styled.div`
    display: flex;
`

const Filters = styled.div`
    display: flex;
    flex: 0 0 auto;
    margin-right: 12px;
`

const Actions = styled.div`
    display: flex;
    flex: 1 0 auto;
    align-items: center;
`

const ActionsWrap = styled.div`
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    margin-right: 12px;
`

const Action = styled.div`
    flex: 0 0 auto;

    &:not(:last-child) {
        margin-right: 12px;
    }
`

const Total = styled.div`
    color: ${colors.black};
    font-size: ${font.small};
    &:not(:last-child) {
        margin-right: 24px;
    }
`

const filterFields = {
    referredByUserHasBankDetails: 'referredByUserHasBankDetails',
    referredUserHasPaid: 'referredUserHasPaid',
    referredByUserRewarded: 'referredByUserRewarded',
    nameOrEmail: 'nameOrEmail',
    ignored: 'ignored',
}

const parseFilterFields = (filters = []) => {
    const result = []

    filters.forEach((field) => {
        const name = filterFields[field] ? filterFields[field] : field

        if (!result.includes(name)) {
            result.push(name)
        }
    })

    return result
}

class RewardsFilter extends Component {
    constructor() {
        super()

        this.state = { activeFilters: [], hasActiveFilters: null, recentFilters: [] }
        _bindAll(this, ['addFilter'])
    }

    static getDerivedStateFromProps({ filter }) {
        const activeFilters = parseFilterFields(Object.keys(filter))
        const hasActiveFilters = activeFilters.length > 0

        return {
            activeFilters,
            hasActiveFilters,
        }
    }

    addFilter(updatedFilter, replace) {
        const recentFilters = parseFilterFields(Object.keys(updatedFilter))
        this.setState({ recentFilters })

        const { applyFilter } = this.props
        applyFilter(updatedFilter, replace)
    }

    render() {
        const { applyFilter, clearFilter, filter, itemsCount, pagination, removeFilter, replaceFilters } = this.props

        const { activeFilters, hasActiveFilters, recentFilters } = this.state

        const { currentPage, perPage, totalCount } = pagination

        const currentPageStarts = perPage * (currentPage - 1) + 1
        const currentPageEnds = currentPageStarts + itemsCount - 1
        const hasMatches = totalCount > 0

        const filters = [
            {
                name: 'referredByUserHasBankDetails',
                component: (isOpenByDefault) => (
                    <RewardsFilterBankDetails
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['referredByUserHasBankDetails'], false)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'referredUserHasPaid',
                component: (isOpenByDefault) => (
                    <RewardsFilterPaidStatus
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['referredUserHasPaid'], false)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'nameOrEmail',
                component: (isOpenByDefault) => (
                    <RewardsFilterNameOrEmail
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['nameOrEmail'], '')}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'referredByUserRewarded',
                component: (isOpenByDefault) => (
                    <RewardsFilterReferrerRewarded
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['referredByUserRewarded'], false)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
            {
                name: 'ignored',
                component: (isOpenByDefault) => (
                    <RewardsFilterIgnored
                        isOpenByDefault={isOpenByDefault}
                        activeFilter={_get(filter, ['ignored'], false)}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                ),
            },
        ]

        return (
            <Container>
                {hasActiveFilters && (
                    <Filters>
                        {activeFilters.map((name) => {
                            const getComponent = _get(_find(filters, { name }), ['component'], () => null)
                            const isOpenByDefault = recentFilters.includes(name)
                            return <div key={name}>{getComponent(isOpenByDefault)}</div>
                        })}
                    </Filters>
                )}
                <Actions>
                    <ActionsWrap>
                        <Action>
                            <RewardsFilterAdd applyFilter={this.addFilter} activeFilters={activeFilters} />
                        </Action>

                        <Action>
                            <RewardsFilterSet replaceFilters={replaceFilters} />
                        </Action>
                    </ActionsWrap>

                    {hasMatches && (
                        <Total>
                            {currentPageStarts}–{currentPageEnds} of {totalCount}{' '}
                            {totalCount === 1 ? 'match' : 'matches'}
                        </Total>
                    )}

                    {!hasMatches && <Total>No matches found</Total>}

                    {hasActiveFilters && (
                        <Action>
                            <Button isSecondary size="small" onClick={clearFilter}>
                                Clear
                            </Button>
                        </Action>
                    )}
                </Actions>
            </Container>
        )
    }
}

RewardsFilter.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    itemsCount: PropTypes.number.isRequired,
    pagination: PropTypes.object.isRequired,
    removeFilter: PropTypes.func.isRequired,
    replaceFilters: PropTypes.func.isRequired,
}

export default withRouter(RewardsFilter)
