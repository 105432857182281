import { useCallback } from 'react'

import { OffPlatformServicesRequestDTO } from '@/models'

import { useUpdateOffPlatformServicesRequestStatusMutation } from '../api'

export const useUpdateOffPlatformServicesRequestStatus = (id: OffPlatformServicesRequestDTO['id']) => {
    const [action, { isLoading }] = useUpdateOffPlatformServicesRequestStatusMutation()

    const updateStatus = useCallback(
        (status: OffPlatformServicesRequestDTO['status']) => action({ id, status }),
        [action, id]
    )

    return {
        updateStatus,
        isLoading,
    }
}
