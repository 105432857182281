import { colors } from '@scouts/ui'

import {
    PATH_SELF_ASSESSMENTS,
    SELF_ASSESSMENT_STATUS_FILTER_HOLD,
    SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW,
    SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED,
} from '@/constants'

import { Card } from '../_ui/Card'

interface SelfAssessmentStatisticsProps {
    accountantId: number | null
    statistics: {
        selfAssessmentsSubmitted: number | null
        selfAssessmentsInProgress: number | null
        selfAssessmentsReadyToFile: number | null
        selfAssessmentsOnHold: number | null
    }
}

export const SelfAssessmentStatistics = ({ accountantId, statistics }: SelfAssessmentStatisticsProps) => {
    const baseUrl = accountantId
        ? `${PATH_SELF_ASSESSMENTS}?accountantId=${accountantId}&`
        : `${PATH_SELF_ASSESSMENTS}?`

    return (
        <>
            <Card
                background={colors.blueLighter}
                linkTo={`${baseUrl}status=${SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED}`}
                title="Ready to start"
                value={statistics.selfAssessmentsSubmitted || 0}
            />

            <Card
                linkTo={`${baseUrl}status=${SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW}`}
                title="In progress"
                background={colors.yellow}
                value={statistics.selfAssessmentsInProgress || 0}
            />

            <Card
                linkTo={`${baseUrl}status=${SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE}`}
                title="Ready to file"
                background={colors.mintLighter}
                value={statistics.selfAssessmentsReadyToFile || 0}
            />

            <Card
                linkTo={`${baseUrl}status=${SELF_ASSESSMENT_STATUS_FILTER_HOLD}`}
                title="On hold"
                value={statistics.selfAssessmentsOnHold || 0}
            />
        </>
    )
}
