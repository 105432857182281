import styled from 'styled-components'

import { colors, font, media, radius, shadows, Flexer, IconSend2, Spacer } from '@scouts/ui'

import { useMessageComposerContext } from './context/message-composer-context'

export const ComposerButton = () => {
    const { openComposer, isComposerOpen } = useMessageComposerContext()

    if (isComposerOpen) return null

    return (
        <Container onClick={openComposer}>
            <Flexer alignCenter>
                <IconSend2 color={colors.white} />
            </Flexer>
            <Spacer>Send a message</Spacer>
        </Container>
    )
}

const Container = styled.button`
    color: ${colors.white};
    font-weight: ${font.weight.medium};
    display: flex;
    align-items: center;
    gap: 12px;
    position: fixed;
    bottom: 18px;
    right: 18px;
    padding: 12px 24px;
    background: ${colors.blue};
    border-radius: ${radius.full};
    box-shadow: ${shadows.high};
    cursor: pointer;

    ${media.tablet} {
        bottom: 36px;
        right: 36px;
    }

    ${media.pointer} {
        &:hover {
            background: ${colors.black};
        }
    }
`
