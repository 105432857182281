import { useState } from 'react'
import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, ConfirmationModal, DropdownButton, File, Folder, Line, Spinner } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { FileType, FileTypeTitles, FileTypeTypes, TaxConsultationDTO, TaxConsultationFileDTO } from '@/models'
import {
    useDeleteTaxConsultationFile,
    useDownloadTaxConsultationFile,
    useTaxConsultationFiles,
} from '@/store/tax-consultation-files/hooks'

import { UploadButton } from './UploadButton'

interface TaxConsultationCustomerFilesProps {
    title: string
    fileTypes?: FileTypeTypes[]
    shouldDisplayUpload?: boolean
    taxConsultationId: TaxConsultationDTO['id']
}

interface DeletableFileState {
    fileId: TaxConsultationFileDTO['id']
    fileName: TaxConsultationFileDTO['fileName']
}

export const TaxConsultationCustomerFiles = ({ title, taxConsultationId }: TaxConsultationCustomerFilesProps) => {
    const { taxConsultationCustomerFiles, isLoading } = useTaxConsultationFiles({
        taxConsultationId,
    })
    const { downloadTaxConsultationFile, isLoading: isLoadingDownload } = useDownloadTaxConsultationFile()
    const { deleteTaxConsultationFile, isLoading: isLoadingDelete } = useDeleteTaxConsultationFile()

    const [isDeleteConfirmationModalOpen, openDeleteConfirmationModal, closeDeleteConfirmationModal] = useToggle()

    const [deletableFile, setDeletableFile] = useState<DeletableFileState | null>()

    const handleOpenDeleteConfirmation = ({ fileId, fileName }: DeletableFileState) => {
        setDeletableFile({ fileId, fileName })
        openDeleteConfirmationModal()
    }

    const handleConfirmDeleteFile = () => {
        if (deletableFile?.fileId) {
            deleteTaxConsultationFile({ taxConsultationFileId: deletableFile.fileId })
            closeDeleteConfirmationModal()
        }
    }

    const count = taxConsultationCustomerFiles?.length || 0
    const hasFiles = count > 0

    return (
        <>
            <Folder>
                <Folder.Header>
                    <Folder.Header.Title fileCount={count}>{title}</Folder.Header.Title>

                    <UploadButton
                        taxConsultationId={taxConsultationId}
                        fileTypes={[FileType.TaxConsultationCustomerDocument]}
                    />
                </Folder.Header>
                <Folder.Content>
                    {hasFiles ? (
                        <Folder.Content.List>
                            <Folder.Content.List.Header
                                key="header"
                                columns="1fr 2fr 1fr 30px"
                                columnItems={['Type', 'File', 'Uploaded at', '']}
                            />
                            {taxConsultationCustomerFiles?.map((file) => (
                                <Folder.Content.List.Item columns={1} desktopColumns="1fr 2fr 1fr 30px" key={file.id}>
                                    <Line size={font.small}>{file.fileType ? FileTypeTitles[file.fileType] : ''}</Line>
                                    <FileWrapper>
                                        <File
                                            background={colors.blueLightest}
                                            onClick={() =>
                                                downloadTaxConsultationFile({ taxConsultationFileId: file.id })
                                            }
                                        >
                                            {file.fileName}
                                        </File>
                                    </FileWrapper>
                                    <Line size={font.small}>{formatDate(file.uploadedDateUTC)}</Line>
                                    <DropdownButton
                                        isLoading={isLoadingDownload || isLoadingDelete}
                                        alignRight
                                        actions={[
                                            {
                                                title: 'Download',
                                                onClick: () =>
                                                    downloadTaxConsultationFile({ taxConsultationFileId: file.id }),
                                            },
                                            {
                                                title: 'Delete',
                                                color: colors.red,
                                                onClick: () =>
                                                    handleOpenDeleteConfirmation({
                                                        fileId: file.id,
                                                        fileName: file.fileName,
                                                    }),
                                            },
                                        ]}
                                    />
                                </Folder.Content.List.Item>
                            ))}
                        </Folder.Content.List>
                    ) : (
                        <>
                            {isLoading && (
                                <Folder.Content.Empty>
                                    <Spinner isSmall />
                                </Folder.Content.Empty>
                            )}

                            {!isLoading && <Folder.Content.Empty />}
                        </>
                    )}
                </Folder.Content>
            </Folder>

            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal
                    onConfirm={handleConfirmDeleteFile}
                    onCancel={closeDeleteConfirmationModal}
                    isLoading={isLoadingDelete}
                >
                    Are you sure you want to delete {deletableFile?.fileName} ?
                </ConfirmationModal>
            )}
        </>
    )
}

const FileWrapper = styled.div`
    min-width: 0;
`
