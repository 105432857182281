import { colors } from '@scouts/ui'

import { AccountantStatus } from '@/models'

const accountantStatusOptions = [
    { value: AccountantStatus.Draft, title: 'Draft' },
    { value: AccountantStatus.Active, title: 'Active' },
    { value: AccountantStatus.Paused, title: 'Paused' },
    { value: AccountantStatus.Terminated, title: 'Terminated' },
]

const accountantStatusOptionsBinary = [
    { value: AccountantStatus.Active, title: 'Active' },
    { value: AccountantStatus.Paused, title: 'Paused' },
]

const accountantStatusDropdownOptionsBinary = [
    {
        value: AccountantStatus.Active,
        title: 'Active',
        color: colors.mintLighter,
    },
    {
        value: AccountantStatus.Paused,
        title: 'Paused',
        color: colors.red,
    },
]

export const AccountantDomain = {
    accountantStatusOptions,
    accountantStatusOptionsBinary,
    accountantStatusDropdownOptionsBinary,
}
