import PropTypes from 'prop-types'
import { useRef } from 'react'
import styled from 'styled-components'

import { colors, font, shadows, PopoverProvider } from '@scouts/ui'

import { useDraftMessage } from '@/store/draft-messages'

import { MessageTemplateSelect } from '../../message-templates/MessageTemplateSelect'

export const MESSAGE_TEMPLATES_POPOVER_TRIGGER = `data-popover-message-templates`

const PopoverContainer = styled.div`
    background: ${colors.white};
    border-radius: 4px;
    box-shadow: ${shadows.medium};
    padding: 12px 16px;
    min-width: 300px;
`

const Title = styled.h4`
    font-weight: ${font.weight.medium};
    font-size: ${font.small};
    margin-bottom: 12px;
`

const MessageTemplatesPopover = ({ customerId, onClose }) => {
    const { draftMessage, updateDraftMessage } = useDraftMessage({ customerId })

    const handleMessageTemplateChange = ({ value: selectedTemplateId, template: selectedTemplate }) => {
        if (draftMessage.content) {
            const isConfirmed = window.confirm('Are you sure?\n\nYou will lose the current message')
            if (!isConfirmed) return
        }

        if (selectedTemplateId) {
            updateDraftMessage({ ...draftMessage, content: selectedTemplate?.content ?? '' })
        } else {
            updateDraftMessage({ ...draftMessage, content: '' })
        }

        onClose()
    }

    return (
        <PopoverContainer>
            <Title>Choose a template</Title>

            <MessageTemplateSelect
                defaultValueTitle="Select"
                name="messageTemplateId"
                onChange={handleMessageTemplateChange}
                value={''}
                width="100%"
            />
        </PopoverContainer>
    )
}

MessageTemplatesPopover.propTypes = {
    customerId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
}

export const MessageTemplatesPopoverProvider = ({ customerId, onClose }) => {
    const popoverRef = useRef()
    const handleClosePopover = () => {
        if (popoverRef.current) popoverRef.current.toggleHint()
        onClose()
    }

    return (
        <PopoverProvider
            ref={popoverRef}
            attribute={MESSAGE_TEMPLATES_POPOVER_TRIGGER}
            onRenderContent={() => <MessageTemplatesPopover customerId={customerId} onClose={handleClosePopover} />}
        />
    )
}

MessageTemplatesPopoverProvider.propTypes = {
    customerId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
}
