export const SELF_ASSESSMENT_STATUS_CREATED = 'Created'
export const SELF_ASSESSMENT_STATUS_DRAFTED = 'Drafted'
export const SELF_ASSESSMENT_STATUS_STARTED = 'Started'
export const SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS = 'UploadingDocuments'
export const SELF_ASSESSMENT_STATUS_SUBMITTED = 'Submitted'
export const SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW = 'AccountantReview'
export const SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED = 'InformationRequired'
export const SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL = 'AwaitingCustomerApproval'
export const SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS = 'CustomerCorrections'
export const SELF_ASSESSMENT_STATUS_READY_TO_FILE = 'ReadyToFile'
export const SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED = 'CompletedNotFiled'
export const SELF_ASSESSMENT_STATUS_FILED = 'Filed'
export const SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND = 'FiledAwaitingRefund'
export const SELF_ASSESSMENT_STATUS_CANCELLED = 'Cancelled'
export const SELF_ASSESSMENT_STATUS_ON_HOLD = 'OnHold'
export const SELF_ASSESSMENT_STATUS_OVERDUE_SUBMITTED = 'OverdueSubmitted'
export const SELF_ASSESSMENT_REFILING_STATUS_NONE = 'None'
export const SELF_ASSESSMENT_REFILING_STATUS_NEEDS_REFILING = 'NeedsRefiling'
export const SELF_ASSESSMENT_REFILING_STATUS_REFILED = 'Refiled'

export const SELF_ASSESSMENT_STATUSES = [
    SELF_ASSESSMENT_STATUS_DRAFTED,
    SELF_ASSESSMENT_STATUS_CREATED,
    SELF_ASSESSMENT_STATUS_STARTED,
    SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_CANCELLED,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
    SELF_ASSESSMENT_STATUS_FILED,
]

export const SELF_ASSESSMENT_STATUS_TITLES = {
    [SELF_ASSESSMENT_STATUS_CREATED]: 'Created',
    [SELF_ASSESSMENT_STATUS_DRAFTED]: 'Drafted',
    [SELF_ASSESSMENT_STATUS_STARTED]: 'Answering questions',
    [SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS]: 'Uploading documents',
    [SELF_ASSESSMENT_STATUS_SUBMITTED]: 'Submitted',
    [SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW]: 'In review',
    [SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED]: 'Info requested',
    [SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL]: 'Awaiting approval',
    [SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS]: 'Making corrections',
    [SELF_ASSESSMENT_STATUS_CANCELLED]: 'Cancelled',
    [SELF_ASSESSMENT_STATUS_READY_TO_FILE]: 'Ready to file',
    [SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND]: 'Filed & awaiting refund',
    [SELF_ASSESSMENT_STATUS_FILED]: 'Filed',
    [SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED]: 'Completed, not filed',
}

export const SELF_ASSESSMENT_REFILING_STATUS_TITLES = {
    [SELF_ASSESSMENT_REFILING_STATUS_NONE]: 'None',
    [SELF_ASSESSMENT_REFILING_STATUS_NEEDS_REFILING]: 'Needs Refiling',
    [SELF_ASSESSMENT_REFILING_STATUS_REFILED]: 'Refiled',
}

export const SELF_ASSESSMENT_STATUS_ORDER = {
    [SELF_ASSESSMENT_STATUS_CREATED]: 0,
    [SELF_ASSESSMENT_STATUS_DRAFTED]: 5,
    [SELF_ASSESSMENT_STATUS_STARTED]: 10,
    [SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS]: 20,
    [SELF_ASSESSMENT_STATUS_SUBMITTED]: 30,
    // [SELF_ASSESSMENT_STATUS_PROCESSING_DOCUMENTS]: 40,
    [SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW]: 50,
    [SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED]: 60,
    [SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL]: 70,
    [SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS]: 80,
    [SELF_ASSESSMENT_STATUS_CANCELLED]: 85,
    [SELF_ASSESSMENT_STATUS_READY_TO_FILE]: 90,
    [SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED]: 93,
    [SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND]: 95,
    [SELF_ASSESSMENT_STATUS_FILED]: 100,
}

export const SELF_ASSESSMENT_STATUS_LIST_FOR_SMS = [
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
]

export const SELF_ASSESSMENT_STATUS_FILTER_ALL = 'all'
export const SELF_ASSESSMENT_STATUS_FILTER_HOLD = 'onHold'
export const SELF_ASSESSMENT_STATUS_FILTER_ASSIGNED = 'assigned'
export const SELF_ASSESSMENT_STATUS_FILTER_FILED = 'filed'
export const SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND = 'waitingRefund'
export const SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE = 'readyToFile'
export const SELF_ASSESSMENT_STATUS_FILTER_IN_PROGRESS = 'inProgress'
export const SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW = 'inReview'
export const SELF_ASSESSMENT_STATUS_FILTER_INFORMATION_REQUIRED = 'informationRequired'
export const SELF_ASSESSMENT_STATUS_FILTER_CUSTOMER_CORRECTIONS = 'customerCorrections'
export const SELF_ASSESSMENT_STATUS_FILTER_AWAITING_CUSTOMER_APPROVAL = 'awaitingApproval'
export const SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED = 'submitted'
const SELF_ASSESSMENT_STATUS_FILTER_COMPLETED_NOT_FILED = 'completedNotFiled'

export const SELF_ASSESSMENT_STATUS_FILTER_TITLES = {
    [SELF_ASSESSMENT_STATUS_FILTER_ALL]: 'All',
    [SELF_ASSESSMENT_STATUS_FILTER_HOLD]: 'On hold',
    [SELF_ASSESSMENT_STATUS_FILTER_ASSIGNED]: 'Preparing docs',
    [SELF_ASSESSMENT_STATUS_FILTER_FILED]: 'Filed',
    [SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND]: 'Filed & awaiting refund',
    [SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE]: 'Ready to file',
    [SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW]: 'In review',
    [SELF_ASSESSMENT_STATUS_FILTER_INFORMATION_REQUIRED]: 'Info requested',
    [SELF_ASSESSMENT_STATUS_FILTER_CUSTOMER_CORRECTIONS]: 'Correcting',
    [SELF_ASSESSMENT_STATUS_FILTER_AWAITING_CUSTOMER_APPROVAL]: 'Awaiting approval',
    [SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED]: 'Ready to start',
    [SELF_ASSESSMENT_STATUS_FILTER_COMPLETED_NOT_FILED]: 'Completed',
}

export const SELF_ASSESSMENT_STATUS_FILTERS = {
    [SELF_ASSESSMENT_STATUS_FILTER_ASSIGNED]: [
        SELF_ASSESSMENT_STATUS_CREATED,
        SELF_ASSESSMENT_STATUS_DRAFTED,
        SELF_ASSESSMENT_STATUS_STARTED,
        SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS,
    ],
    [SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED]: [SELF_ASSESSMENT_STATUS_SUBMITTED],
    [SELF_ASSESSMENT_STATUS_FILTER_IN_REVIEW]: [SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW],
    [SELF_ASSESSMENT_STATUS_FILTER_INFORMATION_REQUIRED]: [SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED],
    [SELF_ASSESSMENT_STATUS_FILTER_CUSTOMER_CORRECTIONS]: [SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS],
    [SELF_ASSESSMENT_STATUS_FILTER_AWAITING_CUSTOMER_APPROVAL]: [SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL],
    [SELF_ASSESSMENT_STATUS_FILTER_READY_TO_FILE]: [SELF_ASSESSMENT_STATUS_READY_TO_FILE],
    [SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND]: [SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND],
    [SELF_ASSESSMENT_STATUS_FILTER_FILED]: [SELF_ASSESSMENT_STATUS_FILED],
    [SELF_ASSESSMENT_STATUS_FILTER_IN_PROGRESS]: [
        SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
        SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
        SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
        SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
        SELF_ASSESSMENT_STATUS_READY_TO_FILE,
        SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    ],
    [SELF_ASSESSMENT_STATUS_FILTER_COMPLETED_NOT_FILED]: [SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED],
    [SELF_ASSESSMENT_STATUS_FILTER_ALL]: [
        SELF_ASSESSMENT_STATUS_CREATED,
        SELF_ASSESSMENT_STATUS_DRAFTED,
        SELF_ASSESSMENT_STATUS_STARTED,
        SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS,
        SELF_ASSESSMENT_STATUS_SUBMITTED,
        SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
        SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
        SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
        SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
        SELF_ASSESSMENT_STATUS_CANCELLED,
        SELF_ASSESSMENT_STATUS_READY_TO_FILE,
        SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
        SELF_ASSESSMENT_STATUS_FILED,
    ],
    [SELF_ASSESSMENT_STATUS_FILTER_HOLD]: [SELF_ASSESSMENT_STATUS_ON_HOLD],
}

export const SELF_ASSESSMENT_SECTION_STATUS_QUESTIONS_COMPLETED = 'QuestionsCompleted'
export const SELF_ASSESSMENT_SECTION_STATUS_UPLOADS_COMPLETED = 'UploadsCompleted'

const ISSUE_TYPE_GENERAL = 'General'
const ISSUE_TYPE_PERSONAL_DETAILS_MISSING = 'PersonalDetailsMissing'
const ISSUE_TYPE_PHONE_NUMBER_MISSING = 'PhoneNumberMissing'
const ISSUE_TYPE_NATIONAL_INSURANCE_NUMBER_MISSING = 'NationalInsuranceNumberMissing'
const ISSUE_TYPE_UNIQUE_TAX_PAYER_REFERENCE_MISSING = 'UniqueTaxpayerReferenceMissing'
const ISSUE_TYPE_ADDRESS_DETAILS_MISSING = 'AddressDetailsMissing'
export const ISSUE_TYPE_BANK_DETAILS_MISSING = 'BankDetailsMissing'
const ISSUE_TYPE_DIRECTOR_DETAILS_MISSING = 'DirectorDetailsMissing'
const ISSUE_TYPE_SELF_EMPLOYMENT_BUSINESS_DETAILS_MISSING = 'SelfEmploymentBusinessDetailsMissing'
const ISSUE_TYPE_NOT_REGISTERED_SELF_EMPLOYMENT = 'NotRegisteredSelfEmployment'
const ISSUE_TYPE_PENSIONS_CONTRIBUTIONS_FILES_MISSING = 'PensionsContributionsFilesMissing'
const ISSUE_TYPE_CHARITABLE_DONATIONS_FILES_MISSING = 'CharitableDonationsFilesMissing'
const ISSUE_TYPE_INVESTMENT_SCHEME_FILES_MISSING = 'InvestmentSchemeFilesMissing'
const ISSUE_TYPE_STUDENT_LOANS_FILES_MISSING = 'StudentLoansFilesMissing'
const ISSUE_TYPE_EMPLOYMENT_FILES_MISSING = 'EmploymentFilesMissing'
const ISSUE_TYPE_SELF_EMPLOYMENT_FILES_MISSING = 'SelfEmploymentFilesMissing'
const ISSUE_TYPE_PROPERTY_FILES_MISSING = 'PropertyFilesMissing'
const ISSUE_TYPE_CAPITAL_GAINS_FILES_MISSING = 'CapitalGainsFilesMissing'
const ISSUE_TYPE_PENSIONS_BENEFITS_FILES_MISSING = 'PensionsBenefitsFilesMissing'
const ISSUE_TYPE_FOREIGN_INCOME_FILES_MISSING = 'ForeignIncomeFilesMissing'
const ISSUE_TYPE_OTHER_INCOME_FILES_MISSING = 'OtherIncomeFilesMissing'
const ISSUE_TYPE_DIVIDENDS_FILES_MISSING = 'DividendsFilesMissing'
const ISSUE_TYPE_INTEREST_FILES_MISSING = 'InterestFilesMissing'
const ISSUE_TYPE_CIS_FILES_MISSING = 'CisFilesMissing'
export const ISSUE_TYPE_GENERAL_FILES_REQUESTED = 'GeneralFilesRequested'
export const ISSUE_TYPE_PENSIONS_CONTRIBUTIONS_FILES_REQUESTED = 'PensionsContributionsFilesRequested'
export const ISSUE_TYPE_CHARITABLE_DONATIONS_FILES_REQUESTED = 'CharitableDonationsFilesRequested'
export const ISSUE_TYPE_INVESTMENT_SCHEME_FILES_REQUESTED = 'InvestmentSchemeFilesRequested'
export const ISSUE_TYPE_STUDENT_LOANS_FILES_REQUESTED = 'StudentLoansFilesRequested'
export const ISSUE_TYPE_EMPLOYMENT_FILES_REQUESTED = 'EmploymentFilesRequested'
export const ISSUE_TYPE_SELF_EMPLOYMENT_FILES_REQUESTED = 'SelfEmploymentFilesRequested'
export const ISSUE_TYPE_PROPERTY_FILES_REQUESTED = 'PropertyFilesRequested'
export const ISSUE_TYPE_CAPITAL_GAINS_FILES_REQUESTED = 'CapitalGainsFilesRequested'
export const ISSUE_TYPE_PENSIONS_AND_BENEFITS_FILES_REQUESTED = 'PensionsBenefitsFilesRequested'
export const ISSUE_TYPE_FOREIGN_INCOME_FILES_REQUESTED = 'ForeignIncomeFilesRequested'
export const ISSUE_TYPE_OTHER_INCOME_FILES_REQUESTED = 'OtherIncomeFilesRequested'
export const ISSUE_TYPE_DIVIDENDS_AND_INTEREST_FILES_REQUESTED = 'DividendsAndInterestFilesRequested'

export const SELF_ASSESSMENT_ISSUE_TYPE_TITLES = {
    [ISSUE_TYPE_GENERAL]: 'General',
    [ISSUE_TYPE_PERSONAL_DETAILS_MISSING]: 'Personal details missing',
    [ISSUE_TYPE_PHONE_NUMBER_MISSING]: 'Phone number missing',
    [ISSUE_TYPE_NATIONAL_INSURANCE_NUMBER_MISSING]: 'National Insurance number missing',
    [ISSUE_TYPE_UNIQUE_TAX_PAYER_REFERENCE_MISSING]: 'Unique Taxpayer Reference missing',
    [ISSUE_TYPE_ADDRESS_DETAILS_MISSING]: 'Address details missing',
    [ISSUE_TYPE_BANK_DETAILS_MISSING]: 'Bank details missing',
    [ISSUE_TYPE_DIRECTOR_DETAILS_MISSING]: 'Director details missing',
    [ISSUE_TYPE_SELF_EMPLOYMENT_BUSINESS_DETAILS_MISSING]: 'Self employment business details missing',
    [ISSUE_TYPE_NOT_REGISTERED_SELF_EMPLOYMENT]: 'Not registered for self employment',
    [ISSUE_TYPE_PENSIONS_CONTRIBUTIONS_FILES_MISSING]: 'Pensions contributions files missing',
    [ISSUE_TYPE_CHARITABLE_DONATIONS_FILES_MISSING]: 'Charitable donations files missing',
    [ISSUE_TYPE_INVESTMENT_SCHEME_FILES_MISSING]: 'Investment scheme files missing',
    [ISSUE_TYPE_STUDENT_LOANS_FILES_MISSING]: 'Student loans files missing',
    [ISSUE_TYPE_EMPLOYMENT_FILES_MISSING]: 'Employment files missing',
    [ISSUE_TYPE_SELF_EMPLOYMENT_FILES_MISSING]: 'Self employment files missing',
    [ISSUE_TYPE_PROPERTY_FILES_MISSING]: 'Property files missing',
    [ISSUE_TYPE_CAPITAL_GAINS_FILES_MISSING]: 'Capital gains files missing',
    [ISSUE_TYPE_PENSIONS_BENEFITS_FILES_MISSING]: 'Pensions benefits files missing',
    [ISSUE_TYPE_FOREIGN_INCOME_FILES_MISSING]: 'Foreign income files missing',
    [ISSUE_TYPE_OTHER_INCOME_FILES_MISSING]: 'Other income files missing',
    [ISSUE_TYPE_DIVIDENDS_FILES_MISSING]: 'Dividends files missing',
    [ISSUE_TYPE_INTEREST_FILES_MISSING]: 'Interest files missing',
    [ISSUE_TYPE_CIS_FILES_MISSING]: 'CIS files missing',
    [ISSUE_TYPE_GENERAL_FILES_REQUESTED]: 'GeneralFilesRequested',
    [ISSUE_TYPE_PENSIONS_CONTRIBUTIONS_FILES_REQUESTED]: 'PensionsContributionsFilesRequested',
    [ISSUE_TYPE_CHARITABLE_DONATIONS_FILES_REQUESTED]: 'CharitableDonationsFilesRequested',
    [ISSUE_TYPE_INVESTMENT_SCHEME_FILES_REQUESTED]: 'InvestmentSchemeFilesRequested',
    [ISSUE_TYPE_STUDENT_LOANS_FILES_REQUESTED]: 'StudentLoansFilesRequested',
    [ISSUE_TYPE_EMPLOYMENT_FILES_REQUESTED]: 'EmploymentFilesRequested',
    [ISSUE_TYPE_SELF_EMPLOYMENT_FILES_REQUESTED]: 'SelfEmploymentFilesRequested',
    [ISSUE_TYPE_PROPERTY_FILES_REQUESTED]: 'PropertyFilesRequested',
    [ISSUE_TYPE_CAPITAL_GAINS_FILES_REQUESTED]: 'CapitalGainsFilesRequested',
    [ISSUE_TYPE_PENSIONS_AND_BENEFITS_FILES_REQUESTED]: 'PensionsBenefitsFilesRequested',
    [ISSUE_TYPE_FOREIGN_INCOME_FILES_REQUESTED]: 'ForeignIncomeFilesRequested',
    [ISSUE_TYPE_OTHER_INCOME_FILES_REQUESTED]: 'OtherIncomeFilesRequested',
    [ISSUE_TYPE_DIVIDENDS_AND_INTEREST_FILES_REQUESTED]: 'DividendsAndInterestFilesRequested',
}

export const FILING_AREA_GENERAL = 'General'
export const FILING_AREA_EMPLOYMENT = 'Employment'
export const FILING_AREA_SELF_EMPLOYMENT = 'SelfEmployment'
export const FILING_AREA_CAPITAL_GAINS = 'CapitalGains'
export const FILING_AREA_PROPERTY = 'Property'
export const FILING_AREA_DIVIDENDS_AND_INTEREST = 'DividendsAndInterest'
export const FILING_AREA_PENSIONS_AND_BENEFITS = 'PensionsAndBenefits'
export const FILING_AREA_FOREIGN_INCOME = 'ForeignIncome'
export const FILING_AREA_OTHER_INCOME = 'OtherIncome'
export const FILING_AREA_PENSIONS_CONTRIBUTIONS = 'PensionsContributions'
export const FILING_AREA_CHARITABLE_GIVING = 'CharitableGiving'
export const FILING_AREA_STUDENT_LOANS = 'StudentLoans'
export const FILING_AREA_INVESTMENT_SCHEME = 'InvestmentScheme'

export const FILING_AREA_TITLES = {
    [FILING_AREA_GENERAL]: 'Tax returns & calculations',
    [FILING_AREA_EMPLOYMENT]: 'Employment',
    [FILING_AREA_SELF_EMPLOYMENT]: 'Self-employment',
    [FILING_AREA_CAPITAL_GAINS]: 'Capital Gains',
    [FILING_AREA_PROPERTY]: 'Property Earnings',
    [FILING_AREA_DIVIDENDS_AND_INTEREST]: 'Dividends and Interest',
    [FILING_AREA_PENSIONS_AND_BENEFITS]: 'Pensions or Benefits',
    [FILING_AREA_FOREIGN_INCOME]: 'Foreign Income',
    [FILING_AREA_OTHER_INCOME]: 'Other Income',
    [FILING_AREA_PENSIONS_CONTRIBUTIONS]: 'Pensions Contributions',
    [FILING_AREA_CHARITABLE_GIVING]: 'Charitable Donations',
    [FILING_AREA_STUDENT_LOANS]: 'Student Loans',
    [FILING_AREA_INVESTMENT_SCHEME]: 'Investment Schemes',
}

export const INCOME_SOURCES_FILTERS = [
    FILING_AREA_EMPLOYMENT,
    FILING_AREA_SELF_EMPLOYMENT,
    FILING_AREA_CAPITAL_GAINS,
    FILING_AREA_PROPERTY,
    FILING_AREA_DIVIDENDS_AND_INTEREST,
    FILING_AREA_PENSIONS_AND_BENEFITS,
    FILING_AREA_FOREIGN_INCOME,
    FILING_AREA_OTHER_INCOME,
]
export const ALLOWANCES_FILTERS = [
    FILING_AREA_PENSIONS_CONTRIBUTIONS,
    FILING_AREA_CHARITABLE_GIVING,
    FILING_AREA_STUDENT_LOANS,
    FILING_AREA_INVESTMENT_SCHEME,
]

export const FILING_AREA_ORDER = {
    [FILING_AREA_GENERAL]: 10,
    [FILING_AREA_EMPLOYMENT]: 20,
    [FILING_AREA_SELF_EMPLOYMENT]: 30,
    [FILING_AREA_CAPITAL_GAINS]: 40,
    [FILING_AREA_PROPERTY]: 50,
    [FILING_AREA_DIVIDENDS_AND_INTEREST]: 60,
    [FILING_AREA_PENSIONS_AND_BENEFITS]: 70,
    [FILING_AREA_FOREIGN_INCOME]: 80,
    [FILING_AREA_OTHER_INCOME]: 90,
    [FILING_AREA_PENSIONS_CONTRIBUTIONS]: 100,
    [FILING_AREA_CHARITABLE_GIVING]: 110,
    [FILING_AREA_STUDENT_LOANS]: 120,
    [FILING_AREA_INVESTMENT_SCHEME]: 130,
}

export const FILING_AREA_REQUESTED_ISSUE_TYPES = {
    [FILING_AREA_CAPITAL_GAINS]: ISSUE_TYPE_CAPITAL_GAINS_FILES_REQUESTED,
    [FILING_AREA_CHARITABLE_GIVING]: ISSUE_TYPE_CHARITABLE_DONATIONS_FILES_REQUESTED,
    [FILING_AREA_DIVIDENDS_AND_INTEREST]: ISSUE_TYPE_DIVIDENDS_AND_INTEREST_FILES_REQUESTED,
    [FILING_AREA_EMPLOYMENT]: ISSUE_TYPE_EMPLOYMENT_FILES_REQUESTED,
    [FILING_AREA_FOREIGN_INCOME]: ISSUE_TYPE_FOREIGN_INCOME_FILES_REQUESTED,
    [FILING_AREA_INVESTMENT_SCHEME]: ISSUE_TYPE_INVESTMENT_SCHEME_FILES_REQUESTED,
    [FILING_AREA_OTHER_INCOME]: ISSUE_TYPE_OTHER_INCOME_FILES_REQUESTED,
    [FILING_AREA_PENSIONS_AND_BENEFITS]: ISSUE_TYPE_PENSIONS_AND_BENEFITS_FILES_REQUESTED,
    [FILING_AREA_PENSIONS_CONTRIBUTIONS]: ISSUE_TYPE_PENSIONS_CONTRIBUTIONS_FILES_REQUESTED,
    [FILING_AREA_PROPERTY]: ISSUE_TYPE_PROPERTY_FILES_REQUESTED,
    [FILING_AREA_SELF_EMPLOYMENT]: ISSUE_TYPE_SELF_EMPLOYMENT_FILES_REQUESTED,
    [FILING_AREA_STUDENT_LOANS]: ISSUE_TYPE_STUDENT_LOANS_FILES_REQUESTED,
}

// export const SELF_EMPLOYMENT_CATEGORY_FREELANCER = 'Freelancer'
export const SELF_EMPLOYMENT_CATEGORY_CONSTRUCTION = 'Construction'
// export const SELF_EMPLOYMENT_CATEGORY_DRIVER = 'Driver'
// export const SELF_EMPLOYMENT_CATEGORY_OTHER = 'Other'

const SELF_EMPLOYMENT_EXPENSE_TOOLS = 'Tools'
const SELF_EMPLOYMENT_EXPENSE_PHONE = 'PhoneAndInternet'
const SELF_EMPLOYMENT_EXPENSE_TRANSPORT = 'PublicTransport'
const SELF_EMPLOYMENT_EXPENSE_OTHER = 'Other'

export const SELF_EMPLOYMENT_EXPENSE_TITLES = {
    [SELF_EMPLOYMENT_EXPENSE_TOOLS]: 'Tools',
    [SELF_EMPLOYMENT_EXPENSE_PHONE]: 'Phone and internet',
    [SELF_EMPLOYMENT_EXPENSE_TRANSPORT]: 'Public transport',
    [SELF_EMPLOYMENT_EXPENSE_OTHER]: 'Other',
}

export const OWNERSHIP_OWNS = 'Owns'
// export const OWNERSHIP_RENTS = 'Rents'
// export const OWNERSHIP_LEASES = 'Leases'

// export const DATA_SOURCE_ACCOUNTANT = 'Accountant'
// export const DATA_SOURCE_HMRC = 'HMRC'
export const DATA_SOURCE_UBER = 'Uber'
export const DATA_SOURCE_PORTIFY = 'Portify'
