import { useHistory } from 'react-router-dom'

import { Auth } from '@scouts/auth'
import { colors, ButtonLink, Heading, Line, Spacer } from '@scouts/ui'

import { PATH_ROOT } from '@/constants'

const Header = () => {
    return (
        <>
            <Heading>Reset your password</Heading>
            <Line textAlign="center">We'll send you an email with instructions to reset your password</Line>
        </>
    )
}

const Footer = () => {
    const history = useHistory()

    return (
        <Spacer>
            <ButtonLink onClick={() => history.push(PATH_ROOT)}>Back</ButtonLink>
        </Spacer>
    )
}

export const ResetPassword = () => {
    return (
        <Auth.LockscreenLayout background={colors.neutralLightest} header={<Header />} footer={<Footer />}>
            <Auth.ResetPasswordForm />
        </Auth.LockscreenLayout>
    )
}
