import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'

import { RECEIVE_CONVERSATIONS, REQUEST_CUSTOMER_CONVERSATIONS } from '../actions'

const parseConversationMessages = (conversation) => {
    const message = {
        id: _get(conversation, ['conversationMessage', 'id'], null),
        type: 'message',
        author: _get(conversation, ['conversationMessage', 'author', 'type'], null),
        body: _get(conversation, ['conversationMessage', 'body'], null),
        subject: _get(conversation, ['conversationMessage', 'subject'], null),
        createdDateUTC: _get(conversation, ['createdDateUTC'], null),
    }

    const parts = _get(conversation, ['conversationParts', 'conversationParts'], []).map((part) => ({
        id: _get(part, ['id'], null),
        type: _get(part, ['partType'], null),
        author: _get(part, ['author', 'type'], null),
        body: _get(part, ['body'], null),
        subject: _get(part, ['subject'], null),
        createdDateUTC: _get(part, ['createdDateUTC'], null),
    }))

    return [message, ...parts]
}

const parseConversations = (conversations) => {
    const filteredConversations = conversations.filter((conversation) => {
        const conversationParts = _get(conversation, ['conversationParts', 'conversationParts'], [])
        const initiator = _get(conversation, ['conversationMessage', 'author', 'type'], null)
        return conversationParts.length > 0 || initiator === 'user'
    })

    const parsedConversations = filteredConversations.map((item) => {
        const id = _get(item, ['conversationMessage', 'id'], null)
        const status = _get(item, ['state'], null)
        const body = _get(item, ['conversationMessage', 'body'], null)

        const createdDateUTC = _get(item, ['createdDateUTC'], null)
        const lastMessageDateUTC = _get(item, ['lastMessageDateUTC'], createdDateUTC)

        const messages = parseConversationMessages(item)

        return {
            id,
            status,
            lastMessageDateUTC,
            body,
            messages,
            count: messages.length,
        }
    })

    return _sortBy(parsedConversations, ({ lastMessageDateUTC }) => new Date(lastMessageDateUTC) * -1)
}

const defaultState = {
    isFetching: false,
    data: [],
}

export default (state = defaultState, action) => {
    const { type, data } = action

    switch (type) {
        case REQUEST_CUSTOMER_CONVERSATIONS:
            return { ...state, isFetching: true }

        case RECEIVE_CONVERSATIONS: {
            const newData = [...state.data]

            const { conversations, customerId } = data

            const parsedConversations = parseConversations(conversations)

            parsedConversations.forEach((conversation) => {
                const key = _findIndex(state.data, ['id', conversation.id])

                const updatedConversation = { ...conversation, customerId }

                if (state.data[key]) {
                    newData[key] = updatedConversation
                } else {
                    newData.push(updatedConversation)
                }
            })

            return { ...state, isFetching: false, data: Object.assign([], newData) }
        }
        default:
            return state
    }
}
