import { useGetTaxConsultationsStatisticsQuery, GetTaxConsultationsParams } from '../api'

export const useTaxConsultationsStatistics = ({ options }: GetTaxConsultationsParams) => {
    const { data, isError, isFetching } = useGetTaxConsultationsStatisticsQuery(
        { options },
        { refetchOnMountOrArgChange: true }
    )

    return {
        taxConsultationsStatistics: data,
        isError,
        isFetching,
    }
}
