import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getAccountantsWithFullNameAndStatus } from '@/selectors'

import { useAccountants } from './use-accountants'

export const useTypeSelectAccountantOptions = () => {
    useAccountants()

    const accountants = useSelector(getAccountantsWithFullNameAndStatus)

    const options = useMemo(() => [{ value: '', title: 'All accountants' }, ...accountants], [accountants])

    return options
}
