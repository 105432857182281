import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownDateRange } from '../ui/filter'

const SearchFilterPaymentDate = (props) => {
    const { paymentDateStart, paymentDateEnd, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = paymentDateStart || paymentDateEnd

    const activeValues = [
        paymentDateStart ? `From ${paymentDateStart}` : '',
        paymentDateEnd ? `To ${paymentDateEnd}` : '',
    ]

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Payment date"
            activeValues={activeValues}
            removeFilter={() => removeFilter(['paymentDateStart', 'paymentDateEnd'])}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownDateRange
                dateFromName="paymentDateStart"
                dateToName="paymentDateEnd"
                applyFilter={applyFilter}
                removeFilter={() => removeFilter(['paymentDateStart', 'paymentDateEnd'])}
                dateFrom={paymentDateStart}
                dateTo={paymentDateEnd}
            />
        </FilterItem>
    )
}

SearchFilterPaymentDate.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    paymentDateEnd: PropTypes.string.isRequired,
    paymentDateStart: PropTypes.string.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterPaymentDate
