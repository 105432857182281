import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

import {
    colors,
    font,
    AccountantPortalChrome,
    ButtonLink,
    Checkbox,
    Flexer,
    IconCalendarNumber,
    Label,
    Spacer,
} from '@scouts/ui'

import { saveCustomer } from '@/actions'
import {
    COUNTRIES_UK,
    COUNTRY_NORTHERN_IRELAND,
    COUNTRY_SCOTLAND,
    COUNTRY_WALES,
    LOGIN_TYPE_EMAIL,
    LOGIN_TYPE_FACEBOOK,
    LOGIN_TYPE_GOOGLE,
    MARITAL_STATUS_TITLES,
    PATH_ACCOUNTANTS,
    PATH_CUSTOMERS,
    POSTCODES_NORTHERN_IRELAND_REGEX,
    POSTCODES_SCOTLAND_REGEX,
    POSTCODES_WALES_REGEX,
} from '@/constants'
import { featureFlags } from '@/feature-flags'
import { formatDateDDMMYYYY, formatDateWithTime, formatPostCode, formatTimeFromNow } from '@/helpers'
import { getAccountantById, getUser } from '@/selectors'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { ClickToCopyProvider } from '@/components/ClickToCopyProvider'

import CustomerReassign from '../CustomerReassign'
import UnsubscribeNoteModal from '../UnsubscribeNoteModal'
import CustomerProfileBankDetails from './CustomerProfileBankDetails'
import { CustomerProfileCompanyDetails } from './CustomerProfileCompanyDetails'
import CustomerProfileIndicators from './CustomerProfileIndicators'
import { CustomerProfilePartnerDetails } from './CustomerProfilePartnerDetails'
import CustomerProfileServices from './CustomerProfileServices'
import { CustomerProfileSubscription } from './CustomerProfileSubscription'

export const CustomerProfile = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { customer } = useCustomerFromPath()

    const { isAdminUser } = useSelector(getUser)

    const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false)

    const {
        accountantFullName,
        accountantId,
        addressCity,
        addressCountry,
        addressMovedInDate,
        addressPostcode,
        addressStreetAddress,
        agentAuthorisationStatus,
        createdDateUTC,
        dateOfBirth,
        email,
        fullName,
        hasNoNationalInsuranceNumber,
        hasTaxRegistration,
        id: customerId,
        identityVerificationStatus,
        lastLoggedInDateUTC,
        lastUpdatedAddressUTC,
        latestSubscription,
        loginType,
        maritalStatus,
        nationalInsuranceNumber,
        phoneNumber,
        previousAccountantEmail,
        previousAccountantFullName,
        taxRegistrationStatus,
        title,
        uniqueTaxpayerReference,
        unsubscribeFromBulkEmails,
        selfEmployedPlatformEnabled,
    } = customer

    const { taxConsultationSchedulingLink, allowTaxConsultation } = useSelector(
        (state) => getAccountantById(state, { accountantId }) || {}
    )

    const handleUpdateUnsubscribeFromBulkEmails = ({ name, checked }) => {
        const updatedCustomer = { ...customer, [name]: checked }

        dispatch(
            saveCustomer(customer.id, updatedCustomer, {
                onSuccess: () => {
                    if (checked) setIsUnsubscribeModalOpen(true)
                },
            })
        )
    }

    const renderLoginType = () => {
        if (!isAdminUser) return null

        switch (loginType) {
            case LOGIN_TYPE_EMAIL:
                return <Label background={colors.neutralLightest}>Signed up with email</Label>
            case LOGIN_TYPE_FACEBOOK:
                return (
                    <Label color={colors.white} background="#4267B2">
                        Signed up with Facebook
                    </Label>
                )
            case LOGIN_TYPE_GOOGLE:
                return (
                    <Label color={colors.white} background="#DB4437">
                        Signed up with Google
                    </Label>
                )
            default:
                return <Label background={colors.red}>Unknown account type</Label>
        }
    }

    const postcodeArea = useMemo(() => {
        if (POSTCODES_SCOTLAND_REGEX.test(addressPostcode)) return COUNTRY_SCOTLAND
        if (POSTCODES_NORTHERN_IRELAND_REGEX.test(addressPostcode)) return COUNTRY_NORTHERN_IRELAND
        if (POSTCODES_WALES_REGEX.test(addressPostcode)) return COUNTRY_WALES
        return null
    }, [addressPostcode])

    const isNonUkAddress = useMemo(() => !!addressCountry && !COUNTRIES_UK.includes(addressCountry), [addressCountry])

    const editProfileUrl = `${PATH_CUSTOMERS}/${customerId}/edit`
    const shouldDisplayPreviousAccountant = !!previousAccountantFullName && !!previousAccountantEmail
    const hasAccountant = !!accountantId
    const shouldDisplayCompanyDetails = featureFlags.featureCompanyTaxReturns

    return (
        <AccountantPortalChrome.Customer.Sidebar.Profile.Container>
            <AccountantPortalChrome.Customer.Sidebar.Profile.Head>
                <Name to={editProfileUrl}>
                    {hasTaxRegistration && title && <NameTitle>{title}</NameTitle>}
                    {fullName}
                </Name>
                <Email>
                    <ClickToCopyProvider shouldHideOverflow>{email}</ClickToCopyProvider>
                </Email>
                <Phone>
                    {!phoneNumber && (
                        <Link to={editProfileUrl}>
                            <Label color={colors.black} background={colors.yellow}>
                                Phone not set
                            </Label>
                        </Link>
                    )}
                    {phoneNumber && <ClickToCopyProvider>{phoneNumber}</ClickToCopyProvider>}
                </Phone>
            </AccountantPortalChrome.Customer.Sidebar.Profile.Head>

            {shouldDisplayCompanyDetails && <CustomerProfileCompanyDetails customerId={customerId} />}

            {(!!latestSubscription || selfEmployedPlatformEnabled) && (
                <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                    <Flexer column gap="6px">
                        {!!latestSubscription && <CustomerProfileSubscription subscription={latestSubscription} />}
                        {selfEmployedPlatformEnabled && (
                            <Spacer>
                                <Label color={colors.black} background={colors.purpleLighter}>
                                    Bookkeeping user
                                </Label>
                            </Spacer>
                        )}
                    </Flexer>
                </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
            )}

            <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                <CustomerProfileIndicators
                    agentAuthorisationStatus={agentAuthorisationStatus}
                    taxRegistrationStatus={taxRegistrationStatus}
                    uniqueTaxpayerReference={uniqueTaxpayerReference}
                    identityVerificationStatus={identityVerificationStatus}
                />
            </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>

            <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                <CustomerProfileServices customerId={customerId} hasTaxRegistration={hasTaxRegistration} />
            </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>

            <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        <Link to={editProfileUrl}>UTR</Link>
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                    {!uniqueTaxpayerReference && (
                        <Link to={editProfileUrl}>
                            <Label color={colors.black} background={colors.yellow}>
                                UTR not set
                            </Label>
                        </Link>
                    )}
                    {uniqueTaxpayerReference && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{uniqueTaxpayerReference}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>

                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        <Link to={editProfileUrl}>NI number</Link>
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                    {!nationalInsuranceNumber && !hasNoNationalInsuranceNumber && (
                        <Link to={editProfileUrl}>
                            <Label color={colors.black} background={colors.yellow}>
                                NI number not set
                            </Label>
                        </Link>
                    )}
                    {nationalInsuranceNumber && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{nationalInsuranceNumber}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                    {hasNoNationalInsuranceNumber && (
                        <Label background={colors.redLighter}>Has no NI number (eg non-UK)</Label>
                    )}
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>

                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        <Link to={editProfileUrl}>Marital status</Link>
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                    {!maritalStatus && (
                        <Link to={editProfileUrl}>
                            <Label color={colors.black} background={colors.yellow}>
                                Marital status not set
                            </Label>
                        </Link>
                    )}
                    {maritalStatus && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{MARITAL_STATUS_TITLES[maritalStatus]}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>

                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        <Link to={editProfileUrl}>Date of birth</Link>
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                    {!dateOfBirth && (
                        <Link to={editProfileUrl}>
                            <Label color={colors.black} background={colors.yellow}>
                                Date of birth not set
                            </Label>
                        </Link>
                    )}
                    {dateOfBirth && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{formatDateDDMMYYYY(dateOfBirth)}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>

                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        <Link to={editProfileUrl}>Address</Link>
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>

                    {!addressStreetAddress && !addressCity && !addressPostcode && !addressCountry && (
                        <Link to={editProfileUrl}>
                            <Label color={colors.black} background={colors.yellow}>
                                Address not set
                            </Label>
                        </Link>
                    )}

                    {addressStreetAddress && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{addressStreetAddress}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                    {addressCity && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{addressCity}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                    {addressPostcode && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{formatPostCode(addressPostcode)}</ClickToCopyProvider>{' '}
                            {postcodeArea && <Label background={colors.neutralLightest}>{postcodeArea}</Label>}
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                    {addressCountry && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{addressCountry}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}

                    {isNonUkAddress && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <Label background={colors.redLighter}>Non-UK address</Label>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}

                    {lastUpdatedAddressUTC && (
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <Label background={colors.neutralLightest} color={colors.neutralDarker}>
                                Updated {formatTimeFromNow(lastUpdatedAddressUTC)}{' '}
                                <span aria-label="Snail emoji" role="img">
                                    🐌
                                </span>
                            </Label>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    )}
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>

                {addressMovedInDate && (
                    <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            <Link to={editProfileUrl}>Moved in date</Link>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ClickToCopyProvider>{formatDateDDMMYYYY(addressMovedInDate)}</ClickToCopyProvider>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                )}
            </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>

            <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <CustomerProfilePartnerDetails customer={customer} />
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
            </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>

            <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <CustomerProfileBankDetails customer={customer} />
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
            </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>

            <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        Signed up
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        {formatDateWithTime(createdDateUTC)}
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        {renderLoginType()}
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        Last active
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        {formatDateWithTime(lastLoggedInDateUTC)}
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
            </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
            <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        <Checkbox
                            checked={unsubscribeFromBulkEmails || false}
                            isSmall
                            label="Unsubscribe from bulk emails"
                            name="unsubscribeFromBulkEmails"
                            onChange={handleUpdateUnsubscribeFromBulkEmails}
                        />
                    </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
            </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>

            {shouldDisplayPreviousAccountant && (
                <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            Previous accountant
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                            <ButtonLink
                                onClick={() =>
                                    history.push(`${PATH_CUSTOMERS}/${customerId}/previousaccountantdetails`)
                                }
                            >
                                View
                            </ButtonLink>
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                    </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
            )}

            {isAdminUser && (
                <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
                    <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            Accountant
                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                        {hasAccountant ? (
                            <AccountantField>
                                <ButtonLink onClick={() => history.push(`${PATH_ACCOUNTANTS}/${accountantId}`)}>
                                    {accountantFullName}
                                </ButtonLink>

                                {allowTaxConsultation && taxConsultationSchedulingLink && (
                                    <AccountantCalendarLink
                                        href={taxConsultationSchedulingLink}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <IconCalendarNumber color={colors.neutralDarker} />
                                    </AccountantCalendarLink>
                                )}
                            </AccountantField>
                        ) : (
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                No accountant assigned
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        )}
                        <CustomerReassign customer={customer} currentAccountantId={accountantId} />
                    </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
            )}

            {isUnsubscribeModalOpen && (
                <UnsubscribeNoteModal customer={customer} closeModal={() => setIsUnsubscribeModalOpen(false)} />
            )}
        </AccountantPortalChrome.Customer.Sidebar.Profile.Container>
    )
}

const Name = styled(Link)`
    color: ${colors.black};
    font-size: ${font.larger};
    font-weight: ${font.weight.medium};
    text-decoration: none;
    display: block;
    position: relative;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const NameTitle = styled.span`
    color: ${colors.neutralDarker};
    font-size: ${font.smaller};
    font-weight: ${font.weight.normal};
    display: block;
    position: absolute;
    top: -18px;
`

const Email = styled.div`
    font-size: ${font.small};
`

const Phone = styled.div`
    font-size: ${font.small};
    margin-top: 6px;
`

const AccountantField = styled.div`
    display: flex;
    margin-top: 6px;
    margin-bottom: 9px;
`

const AccountantCalendarLink = styled.a`
    text-decoration: none;
    display: inline-block;
    margin: 1px 0 0 12px;
`
