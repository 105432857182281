import styled from 'styled-components'

import { colors, font, shadows, Spinner } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { useCustomerMessages } from '@/store/messages'

export const ReturningCustomersTableMessagesCounterPopup = ({ customerId }: { customerId: number }) => {
    const { isFetching, orderedCustomerMessages } = useCustomerMessages({ customerId })

    return (
        <Popup>
            <PopupContainer>
                {!isFetching ? (
                    <>
                        {orderedCustomerMessages.map((message) => (
                            <Message key={message.id}>
                                <MessageTitle>{message.subject}</MessageTitle>
                                <MessageBody>{message.messageContent}</MessageBody>
                                <MessageDate>{formatDate(message.createdDateUTC)}</MessageDate>
                            </Message>
                        ))}
                    </>
                ) : (
                    <SpinnerContainer>
                        <Spinner isSmall />
                    </SpinnerContainer>
                )}
            </PopupContainer>
        </Popup>
    )
}

const Popup = styled.div`
    position: absolute;
    bottom: 7px;
    right: 7px;
    z-index: 1000;
    width: 320px;
    max-height: 320px;
    overflow: auto;
    border-top: 6px solid ${colors.neutralLightest};
    border-bottom: 6px solid ${colors.neutralLightest};
    background: ${colors.neutralLightest};
    box-shadow: ${shadows.medium};
`

const PopupContainer = styled.div`
    padding: 1px 6px;
`

const Message = styled.div`
    text-align: left;
    font-size: ${font.small};
    line-height: 1.3;
    padding: 9px 12px;
    background: ${colors.white};
    border-radius: 2px;

    &:not(:last-child) {
        margin-bottom: 2px;
    }
`

const MessageTitle = styled.div`
    color: ${colors.black};
`

const MessageBody = styled.div`
    color: ${colors.neutralDarker};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
`

const MessageDate = styled.div`
    text-align: right;
    color: ${colors.neutralDarker};
`
const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
`
