export const REQUEST_CUSTOMER_ISSUES = 'REQUEST_CUSTOMER_ISSUES'
export const RESET_ISSUES = 'RESET_ISSUES'
export const RECEIVE_ISSUES = 'RECEIVE_ISSUES'
export const RESOLVE_ISSUE = 'RESOLVE_ISSUE'
export const UNRESOLVE_ISSUE = 'UNRESOLVE_ISSUE'
export const REQUEST_GENERATE_SELF_ASSESSMENT_ISSUES = 'REQUEST_GENERATE_SELF_ASSESSMENT_ISSUES'
export const ADD_ISSUE = 'ADD_ISSUE'

export const requestCustomerIssues = (customerId) => ({
    type: REQUEST_CUSTOMER_ISSUES,
    data: { customerId },
})

export const resetIssues = () => ({ type: RESET_ISSUES })

export const receiveIssues = (data) => ({ type: RECEIVE_ISSUES, data })

export const resolveIssue = (issueId) => ({ type: RESOLVE_ISSUE, data: { issueId } })

export const unresolveIssue = (issueId) => ({ type: UNRESOLVE_ISSUE, data: { issueId } })

export const addIssue = (data, meta = {}) => ({ type: ADD_ISSUE, data, meta })
