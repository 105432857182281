import { useState } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import {
    addErrorToast,
    Button,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { isValidAuthorisationCode } from '@/helpers'
import { AgentAuthorisationDTO } from '@/models'
import { useUpdateAgentAuthorisation } from '@/store/agent-authorisations'

interface AgentAuthCodeEditModalProps {
    agentAuthorisation: AgentAuthorisationDTO
    closeModal: () => void
}

export const AgentAuthCodeEditModal = ({ agentAuthorisation, closeModal }: AgentAuthCodeEditModalProps) => {
    const [code, setCode] = useState(agentAuthorisation.authorisationCode || '')
    const { updateAgentAuthorisation } = useUpdateAgentAuthorisation()

    const handleSubmit = () => {
        if (!isValidAuthorisationCode(code)) {
            addErrorToast({
                body: 'Please double check the code format. It should start with SA and be followed by 8 numbers.',
            })
            return
        }

        updateAgentAuthorisation({
            agentAuthorisationId: agentAuthorisation.id,
            data: {
                ...agentAuthorisation,
                authorisationCode: code,
            },
        }).then(matchSuccessResponse(closeModal))
    }

    return (
        <Modal onClose={closeModal}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Agent authorisation code</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Content>
                            <Input name="code" value={code} onChange={({ value }) => setCode(value)} autoFocus />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button type="submit">Continue</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
