import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { generateApiPath } from '@scouts/helpers'

import {
    CompanyDTO,
    CompanyReturnDTO,
    CompanyReturnInactivityStatus,
    CompanyReturnSearchOptions,
    CompanyReturnStatusTypes,
    CompanyReturnSummaryDTO,
    CompanyReturnUpdateDTO,
    CustomerDTO,
    RefundCreateDTO,
    ResponseWithPagination,
    StatisticsDTO,
    UserAgreementDTO,
} from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'
import { getCompanyReturnsSearchQueryParams } from './queryParams'

export interface GetCompanyReturnParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
}

export interface GetCompanyReturnsParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
}

export interface UpdateCompanyReturnParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
    data: CompanyReturnUpdateDTO
}

export interface DeleteCompanyReturnParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
}

export interface RefundCompanyReturnParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
    data: RefundCreateDTO
}

export interface UpdateCompanyReturnStatusParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
    status: CompanyReturnStatusTypes
    customMessage?: string
}

export interface UpdateCompanyReturnInactivityStatusParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
    inactivityStatus: CompanyReturnInactivityStatus | null
}

export interface GetCompanyReturnSearchParams {
    options?: CompanyReturnSearchOptions
}

export interface GeCompanyReturnsStatisticsParams {
    options?: Pick<CompanyReturnSearchOptions, 'accountantId' | 'includeUnpaid'>
}

export interface GetCompanyReturnAgreementParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
}

export interface CreateCompanyReturnParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCompanyReturn: builder.query<CompanyReturnDTO, GetCompanyReturnParams>({
            query: ({ customerId, companyId, companyReturnId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.GET.SINGLE, { customerId, companyId, companyReturnId }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.CompanyReturns, id: arg.companyReturnId }],
        }),
        getCompanyReturnSearch: builder.query<
            ResponseWithPagination<CompanyReturnSummaryDTO[]>,
            GetCompanyReturnSearchParams
        >({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.GET.LIST, undefined, {
                    queryParameters: getCompanyReturnsSearchQueryParams(options),
                }),
            }),
            providesTags: (result: ResponseWithPagination<CompanyReturnSummaryDTO[]> | undefined) =>
                result
                    ? [...result.data.map(({ id }) => ({ type: Tags.CompanyReturns, id })), Tags.CompanyReturns]
                    : [Tags.CompanyReturns],
            transformResponse: (response: CompanyReturnSummaryDTO[], meta: FetchBaseQueryMeta) =>
                mapPaginatedResponse(response, meta),
        }),
        getCompanyReturns: builder.query<CompanyReturnDTO[], GetCompanyReturnsParams>({
            query: ({ customerId, companyId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.GET.ALL, { customerId, companyId }),
            }),
            providesTags: [Tags.CompanyReturns],
        }),
        updateCompanyReturn: builder.mutation<CompanyReturnDTO, UpdateCompanyReturnParams>({
            query: ({ customerId, companyId, companyReturnId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.PUT.UPDATE, { customerId, companyId, companyReturnId }),
                body: data,
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.CompanyReturns, id: arg.companyReturnId }],
        }),
        deleteCompanyReturn: builder.mutation<void, DeleteCompanyReturnParams>({
            query: ({ customerId, companyId, companyReturnId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.DELETE, { customerId, companyId, companyReturnId }),
            }),
            invalidatesTags: (_1, _2, arg) => [
                Tags.CompanyReturns,
                { type: Tags.CompanyReturns, id: arg.companyReturnId },
            ],
        }),
        updateCompanyReturnStatus: builder.mutation<CompanyReturnDTO, UpdateCompanyReturnStatusParams>({
            query: ({ customerId, companyId, companyReturnId, status, customMessage }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.PUT.UPDATE_STATUS, {
                    customerId,
                    companyId,
                    companyReturnId,
                    status: String(status),
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                body: customMessage ? `"${customMessage}"` : '',
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.CompanyReturns, id: arg.companyReturnId }],
        }),
        refundCompanyReturn: builder.mutation<CompanyReturnDTO, RefundCompanyReturnParams>({
            query: ({ customerId, companyId, companyReturnId, data }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.POST.REFUND, { customerId, companyId, companyReturnId }),
                body: data,
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.CompanyReturns, id: arg.companyReturnId }],
        }),
        getCompanyReturnsStatistics: builder.query<StatisticsDTO[], GeCompanyReturnsStatisticsParams>({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.GET.STATISTICS, undefined, {
                    queryParameters: getCompanyReturnsSearchQueryParams(options),
                }),
            }),
            providesTags: [Tags.CompanyReturnsStatistics],
        }),
        getCompanyReturnAgreement: builder.query<UserAgreementDTO, GetCompanyReturnAgreementParams>({
            query: ({ customerId, companyId, companyReturnId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.GET.AGREEMENT, {
                    customerId,
                    companyId,
                    companyReturnId,
                }),
            }),
            providesTags: [Tags.UserAgreement],
        }),
        createCompanyReturn: builder.mutation<CompanyReturnDTO, CreateCompanyReturnParams>({
            query: ({ customerId, companyId }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURNS.POST.CREATE, { customerId, companyId }),
            }),
            invalidatesTags: (result) =>
                result ? [{ type: Tags.CompanyReturns, id: result.id }, Tags.CompanyReturns] : [],
        }),
    }),
})

export const {
    useGetCompanyReturnQuery,
    useGetCompanyReturnsQuery,
    useUpdateCompanyReturnMutation,
    useDeleteCompanyReturnMutation,
    useUpdateCompanyReturnStatusMutation,
    useGetCompanyReturnSearchQuery,
    useGetCompanyReturnsStatisticsQuery,
    useGetCompanyReturnAgreementQuery,
    useRefundCompanyReturnMutation,
    useCreateCompanyReturnMutation,
} = extendedApi
