import { RECEIVE_USER } from '../actions'

export default (state = {}, action) => {
    const { type, data } = action

    switch (type) {
        case RECEIVE_USER:
            return { ...state, ...data }

        default:
            return state
    }
}
