import styled from 'styled-components'

import { colors, font, Loadable } from '@scouts/ui'

import { useCustomerMessages } from '@/store/messages'

import { useExternalMessages } from './hooks/use-external-messages'

import { MessagesContextProvider } from './context/MessagesContextProvider'
import { MessagesList } from './MessagesList'

interface CustomerMessagesListProps {
    customerId: number
}

export const CustomerMessagesList = ({ customerId }: CustomerMessagesListProps) => {
    useExternalMessages({ customerId })
    const {
        hasMessages,
        orderedCustomerMessages,
        isLoading: isLoadingCustomerMessages,
    } = useCustomerMessages({ customerId })

    const shouldDisplayNoMessages = !hasMessages && !isLoadingCustomerMessages
    const shouldDisplayLoadable = !hasMessages && isLoadingCustomerMessages
    const shouldDisplayMessagesList = hasMessages

    return (
        <MessagesContextProvider customerId={customerId}>
            {shouldDisplayNoMessages && <NoMessages>There are currently no messages here</NoMessages>}
            {shouldDisplayLoadable && <Loadable isLoading shouldHideContentWhenLoading />}

            {shouldDisplayMessagesList && <MessagesList messages={orderedCustomerMessages} />}
        </MessagesContextProvider>
    )
}

const NoMessages = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.smaller};
    text-align: center;
    margin: 48px 0;
`
