import { useSelector } from 'react-redux'

import { Loadable } from '@scouts/ui'

import { getUser } from '@/selectors/user'

import { useHasExpiredNylasAccessToken } from '@/hooks/nylas'

import IntegrationsNylasCreatePage from './IntegrationsNylasCreatePage'
import { IntegrationsNylasEditPage } from './IntegrationsNylasEditPage'

const IntegrationsNylas = () => {
    const { hasNylasApiConnection } = useSelector(getUser)

    const { isExpired } = useHasExpiredNylasAccessToken()

    if (isExpired === null) return <Loadable isLoading />

    if (hasNylasApiConnection) {
        return isExpired ? <IntegrationsNylasCreatePage isReauthentication /> : <IntegrationsNylasEditPage />
    }

    return <IntegrationsNylasCreatePage />
}

export default IntegrationsNylas
