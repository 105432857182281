import { useEffect, ReactNode } from 'react'

import { Loadable } from '@scouts/ui'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'
import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useBookkeepingConfiguration } from '../bookkeeping-configuration'

export const CustomerLoaderBookkeeping = ({ children }: { children: ReactNode }) => {
    const { setCustomer, getIsCustomerReady } = useBookkeepingConfiguration()
    const customerId = useCustomerIdFromPath()
    const { customer } = useCustomerFromPath()

    useEffect(() => {
        if (!customer) return

        setCustomer(customer)
    }, [setCustomer, customer])

    if (!getIsCustomerReady(customerId)) return <Loadable isLoading />

    return <>{children}</>
}
