import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { formatTimeFromNow, matchErrorResponse, matchSuccessResponse, useToggle } from '@scouts/helpers'
import {
    colors,
    font,
    AccountantPortalChrome,
    Button,
    ConfirmationModal,
    Container,
    DropdownButton,
    DropdownButtonProps,
    DropdownSelect,
    Flexer,
    Spacer,
} from '@scouts/ui'

import { PATH_CUSTOMER, PATH_CUSTOMER_COMPANY_RETURN_AGREEMENT } from '@/constants'
import { getIsCompanyReturnDeletable } from '@/domain'
import { formatDate } from '@/helpers'
import { CompanyReturnDTO, PaymentStatus, RefundCreateDTO } from '@/models'
import { useDownloadCompanyReturnFilesZip } from '@/store/company-return-files/hooks'
import { useDeleteCompanyReturn, useRefundCompanyReturn } from '@/store/company-returns/hooks'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'
import { useUser } from '@/hooks/use-user'

import { CompanyReturnFilingLabel } from '@/components/company-returns/CompanyReturnFilingLabel'

import { RefundModal } from '../_shared/RefundModal'
import { CompanyReturnAccountingPeriodModal } from './CompanyReturnAccountingPeriodModal'
import { CompanyReturnCompanyDropdown } from './CompanyReturnCompanyDropdown'
import { CompanyReturnDocuments } from './CompanyReturnDocuments'
import { CompanyReturnOnHoldModal } from './CompanyReturnOnHoldModal'
import { CompanyReturnOnHoldNotice } from './CompanyReturnOnHoldNotice'
import { FilesAccountant } from './sections/FilesAccountant'
import { ReturnSummary } from './sections/ReturnSummary'
import { TaxSummary } from './sections/TaxSummary'

export const CompanyReturn = ({ companyReturn }: { companyReturn: CompanyReturnDTO }) => {
    const { isAdminUser } = useUser()
    const history = useHistory()
    const { customer } = useCustomerFromPath()
    const {
        accountsDueDate,
        accountingPeriodStart,
        accountingPeriodEnd,
        id: companyReturnId,
        status,
        companyId,
        payment,
    } = companyReturn
    const { updatedDateUTC: statusUpdatedDateUTC } = status
    const { status: paymentStatus, price } = payment
    const { id: customerId, fullName } = customer

    const [isOnHoldModalOpen, openOnHoldModal, closeOnHoldModal] = useToggle()

    const { downloadCompanyReturnFilesZip, isLoading: isLoadingDownloadCompanyReturnFilesZip } =
        useDownloadCompanyReturnFilesZip()
    const { refundCompanyReturn, isLoading: isLoadingRefundCompanyReturn } = useRefundCompanyReturn()
    const [isRefundModalOpen, openRefundModal, closeRefundModal] = useToggle()

    const { deleteCompanyReturn, isLoading: isLoadingDeleteCompanyReturn } = useDeleteCompanyReturn()
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useToggle()

    const [isAccountingPeriodModalOpen, openAccountingPeriodModal, closeAccountingPeriodModal] = useToggle()

    const hasAccountingPeriods = !!(accountingPeriodStart && accountingPeriodEnd)
    const hasAccountsDueDate = !!accountsDueDate

    const handleRefundCompanyReturn = (payload: RefundCreateDTO) => {
        refundCompanyReturn({
            companyId: companyReturn.companyId,
            companyReturnId: companyReturn.id,
            customerId,
            data: payload,
        }).then(matchSuccessResponse(closeRefundModal))
    }

    const handleDeleteCompanyReturn = () => {
        deleteCompanyReturn({ customerId, companyId, companyReturnId })
            .then(matchSuccessResponse(() => history.replace(generatePath(PATH_CUSTOMER, { customerId }))))
            .then(matchErrorResponse(closeDeleteModal))
    }

    const actions: DropdownButtonProps['actions'] = [
        {
            title: "Set as 'On hold'",
            onClick: () => {
                openOnHoldModal()
            },
        },
        {
            title: 'Edit accounting period',
            onClick: () => {
                openAccountingPeriodModal()
            },
        },
        {
            title: 'View letter of engagement',
            onClick: () => {
                history.push(
                    generatePath(PATH_CUSTOMER_COMPANY_RETURN_AGREEMENT, { customerId, companyId, companyReturnId })
                )
            },
        },
    ]

    if (isAdminUser) {
        actions.push({
            title: 'Refund',
            onClick: openRefundModal,
            disabled: paymentStatus === PaymentStatus.Refunded,
        })

        actions.push({
            title: 'Delete',
            onClick: openDeleteModal,
            color: colors.text.alert,
            disabled: !getIsCompanyReturnDeletable(companyReturn),
        })
    }

    return (
        <>
            <AccountantPortalChrome.Customer.Page.Container
                padding="24px"
                tabletPadding="0 36px 48px"
                desktopLargePadding="0 48px 48px"
            >
                <AccountantPortalChrome.Customer.Page.Header>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <AccountantPortalChrome.Customer.Page.Title>
                            Company return
                        </AccountantPortalChrome.Customer.Page.Title>
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <DropdownButton alignRight actions={actions} />
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                </AccountantPortalChrome.Customer.Page.Header>

                <Spacer marginBottom="30px">
                    <Flexer gap="12px" flexWrap>
                        <Flexer gap="12px" flexWrap>
                            <CompanyReturnCompanyDropdown />

                            {hasAccountingPeriods && (
                                <DropdownSelect
                                    name="companyReturnId"
                                    value={companyReturnId}
                                    options={[
                                        {
                                            value: companyReturnId,
                                            title: `${formatDate(accountingPeriodStart)} - ${formatDate(
                                                accountingPeriodEnd
                                            )}`,
                                        },
                                    ]}
                                    onChange={() => {}}
                                />
                            )}
                        </Flexer>

                        <Flexer gap="12px" flexWrap alignCenter>
                            {hasAccountsDueDate && (
                                <Spacer>
                                    <CompanyReturnFilingLabel
                                        accountsDueDate={companyReturn.accountsDueDate}
                                        status={status.current}
                                    />
                                </Spacer>
                            )}

                            <LastUpdated>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Status changed {formatTimeFromNow(statusUpdatedDateUTC)}
                                </span>
                            </LastUpdated>
                        </Flexer>
                    </Flexer>
                </Spacer>

                <CompanyReturnOnHoldNotice companyReturn={companyReturn} />

                <Container>
                    <Flexer column spaceBetween desktopRow desktopGap="48px" gap="30px">
                        <Spacer desktopWidth="50%">
                            <TaxSummary companyReturn={companyReturn} />
                        </Spacer>
                        <Spacer desktopWidth="50%">
                            <FilesAccountant companyReturn={companyReturn} />
                        </Spacer>
                    </Flexer>
                    <Spacer marginTop="30px">
                        <ReturnSummary companyReturn={companyReturn} />
                    </Spacer>
                </Container>
            </AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Container
                backgroundColor={colors.neutralLightest}
                radius="12px 0 0 0"
                tabletPadding="24px 36px 40vh"
                desktopLargePadding="24px 48px 40vh"
            >
                <AccountantPortalChrome.Customer.Page.Header>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <AccountantPortalChrome.Customer.Page.SubTitle>
                            Documents
                        </AccountantPortalChrome.Customer.Page.SubTitle>
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.HeaderGroup>
                        <Button
                            size="small"
                            onClick={() => downloadCompanyReturnFilesZip({ companyId, companyReturnId, customerId })}
                            isLoading={isLoadingDownloadCompanyReturnFilesZip}
                        >
                            Download all
                        </Button>
                    </AccountantPortalChrome.Customer.Page.HeaderGroup>
                </AccountantPortalChrome.Customer.Page.Header>

                <CompanyReturnDocuments companyReturn={companyReturn} />
            </AccountantPortalChrome.Customer.Page.Container>

            {isRefundModalOpen && (
                <RefundModal
                    isLoading={isLoadingRefundCompanyReturn}
                    itemName="company return"
                    itemPrice={price}
                    onClose={closeRefundModal}
                    onRefundItem={handleRefundCompanyReturn}
                    title={`Refund ${fullName}'s company return`}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmationModal
                    isLoading={isLoadingDeleteCompanyReturn}
                    onCancel={closeDeleteModal}
                    onConfirm={handleDeleteCompanyReturn}
                >
                    Are you sure you want to delete the company return?
                </ConfirmationModal>
            )}

            {isOnHoldModalOpen && (
                <CompanyReturnOnHoldModal
                    customer={customer}
                    companyReturn={companyReturn}
                    onClose={closeOnHoldModal}
                />
            )}

            {isAccountingPeriodModalOpen && (
                <CompanyReturnAccountingPeriodModal
                    customer={customer}
                    companyReturn={companyReturn}
                    onClose={closeAccountingPeriodModal}
                />
            )}
        </>
    )
}

const LastUpdated = styled.div`
    font-size: ${font.smaller};
    line-height: 1.3;
    flex: 1 1 auto;
    overflow: hidden;
    max-width: 240px;
`
