import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Locale } from '@scouts/helpers'
import { Checkbox, Flexer, TypeSelect, TypeSelectProps } from '@scouts/ui'

import { getAccountantsWithFullNameAndStatus } from '@/selectors'

import { useFilters } from './hooks/use-filters'

export const TaxRegistrationsSupportFilters = () => {
    const accountants = useSelector(getAccountantsWithFullNameAndStatus)
    const hasAccountants = accountants.length > 0
    const { activeFilters, applyFilter } = useFilters()

    const { accountantId, includeUnpaid } = activeFilters

    const handleAccountantChange: TypeSelectProps['onChange'] = ({ value }) => {
        applyFilter({ updatedFilter: { accountantId: Number(value), page: 1 } })
    }

    const handleIncludeUnpaidChange = ({ checked }: { checked: boolean }) => {
        applyFilter({ updatedFilter: { includeUnpaid: checked, page: 1 } })
    }

    return (
        <Flexer gap="12px" alignCenter>
            {hasAccountants && (
                <Accountants>
                    <TypeSelect
                        locale={Locale.GB}
                        name="filterAccountant"
                        value={Number(accountantId) || ''}
                        options={[{ value: '', title: 'All accountants' }, ...accountants]}
                        onChange={handleAccountantChange}
                        autoClear
                        isRequired
                        showOptions
                    />
                </Accountants>
            )}

            <Checkbox
                isSmall
                name="includeUnpaid"
                label="Include unpaid"
                value={includeUnpaid || ''}
                checked={includeUnpaid}
                onChange={handleIncludeUnpaidChange}
            />
        </Flexer>
    )
}

const Accountants = styled.div`
    flex: 0 1 320px;
    height: 35px;
`
