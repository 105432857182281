import { colors, Indicator, Tooltip } from '@scouts/ui'

import { ProductType } from '@/models'

const DiscountSchemes = {
    [ProductType.SelfAssessment]: { abbreviation: 'SA', productName: 'tax returns' },
    [ProductType.TaxConsultation]: { abbreviation: 'TC', productName: 'consultations' },
    [ProductType.Subscription]: { abbreviation: 'SUB', productName: 'subscriptions' },
    [ProductType.CompanyReturn]: { abbreviation: 'CR', productName: 'company returns' },
    [ProductType.ProductBundle]: { abbreviation: 'PB', productName: 'product bundles' },
} as const

type DiscountSchemesIndicatorProps = {
    isApplicable: boolean
    productType: keyof typeof DiscountSchemes
}

export const DiscountSchemesIndicator = ({ productType, isApplicable }: DiscountSchemesIndicatorProps) => {
    const { abbreviation, productName } = DiscountSchemes[productType]

    const tooltipContent = `${isApplicable ? 'Applicable to' : 'Not applicable to'} ${productName}`

    return (
        <Indicator
            background={isApplicable ? colors.mintLighter : colors.white}
            border={isApplicable ? colors.mintLighter : colors.neutralLightest}
            color={isApplicable ? colors.black : colors.neutralDarker}
        >
            <Tooltip content={tooltipContent}>{abbreviation}</Tooltip>
        </Indicator>
    )
}
