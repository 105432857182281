import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Line, Spacer, Table } from '@scouts/ui'

import { PATH_CUSTOMER } from '@/constants'
import { formatCurrency, formatDate } from '@/helpers'
import { SubscriptionDTO } from '@/models'

export const SubscriptionTableRow = ({ subscription }: { subscription: SubscriptionDTO }) => {
    const {
        cancellationDateUTC,
        cancellationRequestedDateUTC,
        customerEmail,
        customerFullName,
        customerId,
        price,
        startDateUTC,
        status,
    } = subscription

    const customerPath = generatePath(PATH_CUSTOMER, { customerId })

    return (
        <Table.Body.Row>
            <Table.Body.Cell width="30%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Spacer>
                        <Name>{customerFullName}</Name>
                        <Email>{customerEmail}</Email>
                    </Spacer>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="10%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Line size={font.small}>{formatCurrency(price)}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Line size={font.small}>{status}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    <Line size={font.small}>{formatDate(startDateUTC)}</Line>
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    {!!cancellationRequestedDateUTC && (
                        <Line size={font.small}>{formatDate(cancellationRequestedDateUTC)}</Line>
                    )}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
            <Table.Body.Cell width="15%">
                <Table.Body.Cell.ContentLink Link={Link} to={customerPath}>
                    {!!cancellationDateUTC && <Line size={font.small}>{formatDate(cancellationDateUTC)}</Line>}
                </Table.Body.Cell.ContentLink>
            </Table.Body.Cell>
        </Table.Body.Row>
    )
}

const Name = styled.div`
    font-weight: ${font.weight.medium};
    margin-bottom: 6px;
`

const Email = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
`
