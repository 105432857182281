import { font, Container, DropdownSelect, DropdownSelectProps, Line, Spacer } from '@scouts/ui'

import { saveUser } from '@/actions'
import { AccountantDomain } from '@/domain'
import { AccountantDetailsDTO } from '@/models'
import { useAppDispatch } from '@/store'

export const CompanyReturnStatusDropdown = ({ accountant }: { accountant: AccountantDetailsDTO }) => {
    const dispatch = useAppDispatch()
    const { allowCompany, companyStatus } = accountant

    if (!allowCompany) return null

    const handleCompanyReturnStatusChange: DropdownSelectProps['onChange'] = ({
        value: updatedCompanyReturnStatus,
    }) => {
        if (updatedCompanyReturnStatus === companyStatus) return

        dispatch(saveUser({ companyStatus: updatedCompanyReturnStatus }))
    }

    return (
        <Container>
            <Spacer marginBottom="6px">
                <Line weight={font.weight.medium} size={font.small}>
                    Accept new company return clients
                </Line>
            </Spacer>
            <DropdownSelect
                name="companyStatus"
                value={companyStatus}
                options={AccountantDomain.accountantStatusDropdownOptionsBinary}
                onChange={handleCompanyReturnStatusChange}
                width="100%"
            />
        </Container>
    )
}
