import { lazy, Suspense } from 'react'

import { colors, AccountantPortalChrome, Loadable } from '@scouts/ui'

import { useTaxConsultation } from '@/store/tax-consultations'

import { useCustomer } from '@/hooks/use-customer'
import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useTaxConsultationIdFromPath } from '../hooks/use-tax-consultation-id-from-path'

import { useGetTaxConsultationSummaryContent } from './summary-form/use-get-summary-content'

const TaxConsultationSummaryForm = lazy(() => import('./summary-form/TaxConsultationSummaryForm'))

export const TaxConsultationSummaryFormContainer = () => {
    const customerId = useCustomerIdFromPath()
    const taxConsultationId = useTaxConsultationIdFromPath()

    const { customer } = useCustomer(customerId)
    const { taxConsultation } = useTaxConsultation({ taxConsultationId })
    const { isLoading: isLoadingSummaryContent } = useGetTaxConsultationSummaryContent(taxConsultationId)

    const isLoading = !taxConsultation || !customer || isLoadingSummaryContent
    if (isLoading) return <Loadable isLoading />

    return (
        <AccountantPortalChrome.Customer.Page.Container backgroundColor={colors.neutralLightest}>
            <Suspense fallback={<Loadable isLoading />}>
                <TaxConsultationSummaryForm customer={customer} taxConsultation={taxConsultation} />
            </Suspense>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
