import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, radius, ChipButton, Container, Line, Spacer } from '@scouts/ui'

import { PATH_PROFILE } from '@/constants'
import { getAccountantImageUrl } from '@/helpers'

import { useUser } from '@/hooks/use-user'

export const SupportDashboardProfile = () => {
    const user = useUser()
    const history = useHistory()

    const { id, fullName, email, imageFileId } = user

    const avatarImageSrc = imageFileId !== 0 ? getAccountantImageUrl(id) : ''

    return (
        <Container style={{ position: 'sticky', top: '36px' }}>
            <Container
                padding="18px 24px"
                border={`1px solid ${colors.neutralLightest}`}
                radius={radius.large}
                relative
            >
                <AvatarContainer>{avatarImageSrc && <Avatar src={avatarImageSrc} alt="" />}</AvatarContainer>
                <Spacer marginBottom="18px">
                    <ChipButton onClick={() => history.push(PATH_PROFILE)} withBorder variant="inverted">
                        My profile →
                    </ChipButton>
                </Spacer>
                <Spacer>
                    <Wrapper>
                        <Line weight={font.weight.medium}>{fullName}</Line>
                        <Line size={font.small}>{email}</Line>
                    </Wrapper>
                </Spacer>
            </Container>
        </Container>
    )
}

const Wrapper = styled.div`
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`
const AvatarContainer = styled.div`
    width: 72px;
    height: 72px;
    background: ${colors.neutralLightest};
    border-radius: ${radius.full};
    position: absolute;
    top: -24px;
    right: 24px;
`
const Avatar = styled.img`
    width: 100%;
    height: 100%;
    border-radius: ${radius.full};
`
