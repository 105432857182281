import PropTypes from 'prop-types'
import { Component } from 'react'

import Beacon from './Beacon'
import { hasSeenTour, storeSeenTour } from './helpers'

class FeatureBeacon extends Component {
    constructor(props) {
        super(props)

        this.state = { hasSeen: hasSeenTour(props.id) }
    }

    handleClick = (event) => {
        const { id, onBeaconClick } = this.props
        this.setState({ hasSeen: true })
        storeSeenTour(id)
        onBeaconClick(event)
    }

    render() {
        const { hasSeen } = this.state
        if (hasSeen) return null

        const { style } = this.props

        return <Beacon style={style} onClick={this.handleClick} />
    }
}

FeatureBeacon.propTypes = {
    id: PropTypes.string.isRequired,
    style: PropTypes.object,
    onBeaconClick: PropTypes.func,
}
FeatureBeacon.defaultProps = {
    onBeaconClick: () => {},
    style: {},
}

export default FeatureBeacon
