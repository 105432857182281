import { SelfAssessmentStatusTypes } from './self-assessment'
import { UserTypeTypes } from './user'

export interface LoggedEventDTO {
    /** @format int32 */
    id: number
    /** @format int32 */
    customerId: number
    /** @format int32 */
    selfAssessmentId?: number | null
    loggedEventType: LoggedEventTypeTypes
    /** @format date-time */
    eventDateUTC: string
    eventText?: string | null
    userType: UserTypeTypes
    notify: boolean
    selfAssessmentStatus?: SelfAssessmentStatusTypes
    selfAssessmentTaxYear?: string
    customerFullName?: string | null
}

export const LoggedEventType = {
    SelfAssessmentStatusChange: 'SelfAssessmentStatusChange',
    IssueResolved: 'IssueResolved',
    FileUploaded: 'FileUploaded',
    MessageSent: 'MessageSent',
    AgentAuthorisation: 'AgentAuthorisation',
    Delete: 'Delete',
    IdentityVerification: 'IdentityVerification',
    General: 'General',
    TemplateEmailSent: 'TemplateEmailSent',
    TaxRegistrationStatusChange: 'TaxRegistrationStatusChange',
    SelfAssessmentPayment: 'SelfAssessmentPayment',
    TaxRegistrationPayment: 'TaxRegistrationPayment',
    SelfAssessmentMigration: 'SelfAssessmentMigration',
    PriorityNote: 'PriorityNote',
    ProfileUpdate: 'ProfileUpdate',
    SelfAssessmentSectionStatusForce: 'SelfAssessmentSectionStatusForce',
    TaxConsultationStatusChange: 'TaxConsultationStatusChange',
    TaxConsultationPayment: 'TaxConsultationPayment',
    ProductBundlePayment: 'ProductBundlePayment',
    SubscriptionPayment: 'SubscriptionPayment',
    SubscriptionCancellation: 'SubscriptionCancellation',
    SubscriptionTierChange: 'SubscriptionTierChange',
    AccountantMeetingStatusChange: 'AccountantMeetingStatusChange',
    AccountantMeetingCreation: 'AccountantMeetingCreation',
    SubscriptionCreation: 'SubscriptionCreation',
    SubscriptionTrialPayment: 'SubscriptionTrialPayment',
    TaxReliefClaimCreation: 'TaxReliefClaimCreation',
    TaxReliefClaimStatusChange: 'TaxReliefClaimStatusChange',
    TaxReliefClaimPayment: 'TaxReliefClaimPayment',
    SelfAssessmentNeedsRefiling: 'SelfAssessmentNeedsRefiling',
    CompanyReturnStatusChange: 'CompanyReturnStatusChange',
    CompanyReturnPayment: 'CompanyReturnPayment',
    UnregisteredUserMigration: 'UnregisteredUserMigration',
} as const

export type LoggedEventTypeTypes = (typeof LoggedEventType)[keyof typeof LoggedEventType]

export const LoggedEventTypeTitles: Partial<Record<LoggedEventTypeTypes, string>> = {
    [LoggedEventType.AgentAuthorisation]: 'Agent authorisation',
    [LoggedEventType.CompanyReturnStatusChange]: 'Company return status changed',
    [LoggedEventType.CompanyReturnPayment]: 'Payment',
    [LoggedEventType.Delete]: 'Deleted',
    [LoggedEventType.FileUploaded]: 'File uploaded',
    [LoggedEventType.General]: 'General',
    [LoggedEventType.IdentityVerification]: 'Identity verification',
    [LoggedEventType.IssueResolved]: 'Issue resolved',
    [LoggedEventType.MessageSent]: 'Message sent',
    [LoggedEventType.SelfAssessmentStatusChange]: 'Self Assessment status changed',
    [LoggedEventType.SelfAssessmentPayment]: 'Payment',
    [LoggedEventType.TaxRegistrationPayment]: 'Payment',
    [LoggedEventType.TaxRegistrationStatusChange]: 'UTR registration status changed',
    [LoggedEventType.TemplateEmailSent]: 'Automatic email sent',
    [LoggedEventType.SelfAssessmentMigration]: 'Migration',
    [LoggedEventType.PriorityNote]: 'Priority note',
    [LoggedEventType.SelfAssessmentSectionStatusForce]: 'Section status changed',
    [LoggedEventType.ProfileUpdate]: 'Profile updated',
    [LoggedEventType.TaxConsultationPayment]: 'Payment',
    [LoggedEventType.TaxConsultationStatusChange]: 'Tax consultation status changed',
    [LoggedEventType.ProductBundlePayment]: 'Payment',
}
