import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar'
import createSagaMiddleware from 'redux-saga'

import { apiSlice as BookkeepingApiSlice } from '@scouts/bookkeeping'
import { apiSlice as InboxApiSlice } from '@scouts/inbox'

import accountants from '@/reducers/accountants'
import conversations from '@/reducers/conversations'
import customerDiscounts from '@/reducers/customer-discounts'
import customerFiles from '@/reducers/customer-files'
import customers from '@/reducers/customers'
import escalations from '@/reducers/escalations'
import files from '@/reducers/files'
import issues from '@/reducers/issues'
import notes from '@/reducers/notes'
import productBundles from '@/reducers/product-bundles'
import quickSearch from '@/reducers/quick-search'
import returningCustomers from '@/reducers/returning-customers'
import rewards from '@/reducers/rewards'
import search from '@/reducers/search'
import selfAssessments from '@/reducers/self-assessments'
import statistics from '@/reducers/statistics'
import user from '@/reducers/user'
import sagas from '@/sagas'

import { apiSlice } from './api-slice'
import { CustomMiddleware } from './error-handling'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        accountants,
        conversations,
        customerDiscounts,
        customerFiles,
        customers,
        escalations,
        files,
        issues,
        loadingBar,
        notes,
        productBundles,
        quickSearch,
        returningCustomers,
        rewards,
        search,
        selfAssessments,
        statistics,
        user,
        // RTKQ slice
        [apiSlice.reducerPath]: apiSlice.reducer,
        // Bookkeeping RTKQ slice
        [BookkeepingApiSlice.reducerPath]: BookkeepingApiSlice.reducer,
        [InboxApiSlice.reducerPath]: InboxApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // Silences warnings about non-serializable values used in actions (e.g. onSuccess/onError functions in our case)
            // @TODO: Something to look at later: https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
            serializableCheck: false,

            // Silences warnings about immutable values
            // @TODO: Something to look at later: https://redux.js.org/style-guide/#do-not-mutate-state
            immutableCheck: false,
        })
            .concat(sagaMiddleware)
            .concat(apiSlice.middleware)
            .concat(BookkeepingApiSlice.middleware)
            .concat(InboxApiSlice.middleware)
            .concat(CustomMiddleware.SentryReporter)
            .concat(CustomMiddleware.ErrorToaster)
            .concat(CustomMiddleware.LogoutDetector),
})

sagaMiddleware.run(sagas)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
