import styled from 'styled-components'

import { colors, font, radius, Container, Flexer, Grid, GridItem, Label, Line, Spacer } from '@scouts/ui'

import { REFUND_REASONS_TITLES } from '@/constants'
import { formatCurrency, formatDate } from '@/helpers'
import {
    CompanyBookkeepingMethodTitles,
    CompanyPreviouslyFiledByTypeTitles,
    CompanyReturnDTO,
    CompanyTransactionCountCategoryTitles,
    CompanyTurnoverCategoryTitles,
    PaymentStatus,
} from '@/models'

import { useUser } from '@/hooks/use-user'

import { BooleanLabel } from '../ui/BooleanLabel'

const getPaymentStatus = (companyReturn: CompanyReturnDTO) => {
    const { status, price, isFree } = companyReturn.payment
    switch (true) {
        case status === PaymentStatus.Refunded && price === 0:
            return (
                <>
                    <Label background={colors.red}>Refunded</Label> <Label background={colors.mintLighter}>Free</Label>
                </>
            )
        case isFree:
            return (
                <Line size={font.small} color={colors.neutralDarker}>
                    Free
                </Line>
            )
        case status === PaymentStatus.Refunded:
            return <Label background={colors.red}>Refunded</Label>
        default:
            return (
                <Line size={font.small} color={colors.neutralDarker}>
                    Not paid
                </Line>
            )
    }
}

export const ReturnSummary = ({ companyReturn }: { companyReturn: CompanyReturnDTO }) => {
    const { isAdminUser } = useUser()
    const {
        turnoverCategory,
        transactionCountCategory,
        bookkeepingMethod,
        isVatRegistered,
        hasLoanActivity,
        hasPayrollActivity,
        status,
        previousAccountantEmail,
        previousAccountantName,
        previouslyFiledByType,
        payment,
    } = companyReturn

    const { submittedDateUTC, filedDateUTC } = status
    const { hasPaid, price, paymentDateUTC, isFree, revenueDateUTC, refundDateUTC, refundReason } = payment
    const { refundReasonType, refundReasonDescription } = refundReason || {}

    const shouldDisplayPreviousAccountant = !!previousAccountantEmail && !!previousAccountantName

    return (
        <Container border={`1px solid ${colors.neutralLightest}`} radius={radius.large} padding="24px">
            <Grid desktopLargeColumns={3} desktopColumns={2} gap="18px">
                <GridItem>
                    <Flexer column gap="18px">
                        <Row>
                            <RowLabel>Previously filed</RowLabel>
                            <RowValue>
                                {previouslyFiledByType
                                    ? CompanyPreviouslyFiledByTypeTitles[previouslyFiledByType]
                                    : '-'}
                            </RowValue>
                        </Row>
                        <Row>
                            <RowLabel>Turnover</RowLabel>
                            <RowValue>
                                {turnoverCategory ? CompanyTurnoverCategoryTitles[turnoverCategory] : '-'}
                            </RowValue>
                        </Row>
                        <Row>
                            <RowLabel>Bookkeeping</RowLabel>
                            <RowValue>
                                {bookkeepingMethod ? CompanyBookkeepingMethodTitles[bookkeepingMethod] : '-'}
                            </RowValue>
                        </Row>
                        <Row>
                            <RowLabel>Transactions</RowLabel>
                            <RowValue>
                                {transactionCountCategory
                                    ? CompanyTransactionCountCategoryTitles[transactionCountCategory]
                                    : '-'}
                            </RowValue>
                        </Row>
                    </Flexer>
                </GridItem>
                <GridItem>
                    <Flexer column gap="18px">
                        <Row>
                            <RowLabel>VAT registered</RowLabel>
                            <RowValue>
                                <BooleanLabel value={isVatRegistered} />
                            </RowValue>
                        </Row>
                        <Row>
                            <RowLabel>Payroll</RowLabel>
                            <RowValue>
                                <BooleanLabel value={hasPayrollActivity} />
                            </RowValue>
                        </Row>
                        <Row>
                            <RowLabel>Loans</RowLabel>
                            <RowValue>
                                <BooleanLabel value={hasLoanActivity} />
                            </RowValue>
                        </Row>
                    </Flexer>
                </GridItem>
                <GridItem>
                    <Flexer column gap="18px">
                        <Row>
                            <RowLabel>Paid</RowLabel>
                            {!hasPaid && <RowValue>{getPaymentStatus(companyReturn)}</RowValue>}
                            {hasPaid && (
                                <RowValue>
                                    <Line size={font.small}>{paymentDateUTC ? formatDate(paymentDateUTC) : ''}</Line>
                                    <Label background={colors.mintLighter}>
                                        {isFree ? 'Free' : formatCurrency(price)}
                                    </Label>
                                </RowValue>
                            )}
                        </Row>

                        {isAdminUser && !!refundDateUTC && (
                            <Row>
                                <RowLabel>Refunded</RowLabel>
                                <RowValue>{formatDate(refundDateUTC)}</RowValue>
                            </Row>
                        )}

                        {isAdminUser && !!refundReasonType && (
                            <Row>
                                <RowLabel>Refund reason</RowLabel>
                                <RowValue>{REFUND_REASONS_TITLES[refundReasonType]}</RowValue>
                            </Row>
                        )}

                        {isAdminUser && !!refundReasonDescription && (
                            <Row>
                                <RowLabel>Refund details</RowLabel>
                                <RowValue>{refundReasonDescription}</RowValue>
                            </Row>
                        )}

                        {isAdminUser && !!revenueDateUTC && (
                            <Row>
                                <RowLabel>Revenue recognised</RowLabel>
                                <RowValue>{formatDate(revenueDateUTC)}</RowValue>
                            </Row>
                        )}

                        <Row>
                            <RowLabel>Submitted</RowLabel>
                            <RowValue>{submittedDateUTC ? formatDate(submittedDateUTC) : '-'}</RowValue>
                        </Row>
                        <Row>
                            <RowLabel>Filed</RowLabel>
                            <RowValue>{filedDateUTC ? formatDate(filedDateUTC) : '-'}</RowValue>
                        </Row>
                    </Flexer>
                </GridItem>
            </Grid>

            {shouldDisplayPreviousAccountant && (
                <Spacer marginTop="24px">
                    <Container background={colors.yellow} padding="12px 18px" radius={radius.medium}>
                        <Flexer column desktopRow desktopGap="24px" gap="12px">
                            <Line size={font.small}>Previous accountant</Line>
                            <Line size={font.small}>{previousAccountantName}</Line>
                            <Line size={font.small}>{previousAccountantEmail}</Line>
                        </Flexer>
                    </Container>
                </Spacer>
            )}
        </Container>
    )
}

const Row = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
`

const RowLabel = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    flex: 0 0 50%;
`

const RowValue = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: ${font.small};
    line-height: 1.5;
    flex: 0 0 50%;
`
