import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useQueryParams } from '@scouts/helpers'
import { colors, font, media, ButtonLink, Loadable } from '@scouts/ui'

import { createEscalation, requestAccountants, updateEscalation } from '@/actions'
import { QUERY_PARAM_ESCALATION_CREATE, QUERY_PARAM_ESCALATION_EDIT, QUERY_PARAM_NOTE_CREATE } from '@/constants'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { useCustomerEscalations } from '../hooks/use-customer-escalations'

import CustomerEscalationsItemModal from '../CustomerEscalationsItemModal'
import CustomerEscalationsList from '../CustomerEscalationsList'

export const CustomerSidebarEscalations = () => {
    const { customer } = useCustomerFromPath()
    const { escalations, isLoading } = useCustomerEscalations({ customerId: customer.id })

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(requestAccountants())
    }, [dispatch])

    const history = useHistory()
    const { queryParams, overwriteQueryParams, removeQueryParams } = useQueryParams()
    const { view, escalationId: escalationIdFromUrl } = queryParams
    const isCreateModalOpen = view === QUERY_PARAM_ESCALATION_CREATE
    const editableEscalationId = Number(escalationIdFromUrl)
    const editableEscalation = escalations.find(({ id: escalationId }) => escalationId === editableEscalationId)
    const isEditModalOpen = view === QUERY_PARAM_ESCALATION_EDIT && !!editableEscalationId && !!editableEscalation

    const handleCloseModals = () => {
        const newLocation = removeQueryParams(['view', 'escalationId'])
        history.push(newLocation)
    }
    const handleOpenCreateEscalationModal = () => {
        const newLocation = overwriteQueryParams({ view: QUERY_PARAM_ESCALATION_CREATE })
        history.push(newLocation)
    }
    const handleOpenEditEscalationModalWithOpenedCreateNoteModal = (escalationId) => {
        const newLocation = overwriteQueryParams({
            view: QUERY_PARAM_ESCALATION_EDIT,
            escalationId,
            subview: QUERY_PARAM_NOTE_CREATE,
        })
        history.push(newLocation)
    }

    const isCreateEscalationPending = useRef(false)

    const handleCreateEscalationSilently = (data) => {
        if (!isCreateEscalationPending.current) {
            dispatch(
                createEscalation(data, {
                    onSuccess: (createdEscalation) => {
                        isCreateEscalationPending.current = false
                        handleOpenEditEscalationModalWithOpenedCreateNoteModal(createdEscalation.id)
                    },
                    onError: () => {
                        isCreateEscalationPending.current = false
                    },
                })
            )

            isCreateEscalationPending.current = true
        }
    }

    const handleCreateEscalation = (data, shouldOpenEditModal) => {
        if (shouldOpenEditModal) {
            handleCreateEscalationSilently(data)
        } else {
            dispatch(createEscalation(data))
            handleCloseModals()
        }
    }

    const handleEditEscalation = (data) => {
        dispatch(updateEscalation(data))
        handleCloseModals()
    }

    return (
        <Container>
            <Header>
                <Title>Escalations</Title>
                <Action>
                    <ButtonLink icon="plus" onClick={handleOpenCreateEscalationModal}>
                        Add
                    </ButtonLink>
                </Action>
            </Header>

            <Loadable isLoading={isLoading} />

            <CustomerEscalationsList escalations={escalations} />

            {isCreateModalOpen && (
                <CustomerEscalationsItemModal
                    customerAccountantId={customer.accountantId}
                    customerFullName={customer.fullName}
                    customerId={customer.id}
                    handleSave={handleCreateEscalation}
                    handleCancel={handleCloseModals}
                />
            )}

            {isEditModalOpen && (
                <CustomerEscalationsItemModal
                    escalation={editableEscalation}
                    handleSave={handleEditEscalation}
                    handleCancel={handleCloseModals}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    padding: 21px 18px;
    background: ${colors.yellow};

    ${media.tablet} {
        min-height: 100%;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`

const Action = styled.div``

const Title = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    align-self: center;
`
