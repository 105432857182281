import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Grid, Line, Spacer } from '@scouts/ui'

import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE, PATH_TAX_RELIEF_CLAIMS } from '@/constants'
import { formatDate } from '@/helpers'
import { TaxReliefClaimStatus } from '@/models'
import { useTaxReliefClaims } from '@/store/tax-relief-claims'

import { Card } from './_ui/Card'

const DISPLAY_COUNT = 4

export const SupportDashboardTaxReliefClaims = () => {
    const history = useHistory()

    const { taxReliefClaims } = useTaxReliefClaims({
        options: {
            status: TaxReliefClaimStatus.Submitted,
            page: PAGINATION_DEFAULT_PAGE,
            pageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        },
    })

    const count = taxReliefClaims?.length || 0
    const visibleItems = taxReliefClaims?.slice(0, DISPLAY_COUNT)

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Latest submitted tax relief claims
                    </Line>
                    <ButtonLink color={colors.neutralDarker} onClick={() => history.push(PATH_TAX_RELIEF_CLAIMS)}>
                        View all {count > DISPLAY_COUNT ? `(${count})` : ''}
                    </ButtonLink>
                </Flexer>
            </Spacer>
            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                {visibleItems?.map((taxReliefClaim) => {
                    const url = `customers/${taxReliefClaim.customerId}/taxReliefClaims/${taxReliefClaim.id}`
                    return (
                        <Card
                            key={taxReliefClaim.id}
                            background={colors.purpleLighter}
                            title={taxReliefClaim.customerFullName || ''}
                            linkTo={url}
                            value={formatDate(taxReliefClaim.submittedDateUTC) || ''}
                        />
                    )
                })}
            </Grid>
        </Container>
    )
}
