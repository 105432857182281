import { useState } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import {
    addErrorToast,
    Button,
    DatePicker,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    TextArea,
} from '@scouts/ui'

import { AccountantReviewDTO } from '@/models'
import { useUpdateAccountantReview } from '@/store/accountant-reviews/hooks'

interface EditReviewModalProps {
    accountantReview: AccountantReviewDTO
    onClose: () => void
}

export const EditReviewModal = ({ accountantReview, onClose }: EditReviewModalProps) => {
    const [reviewBody, setReviewBody] = useState(accountantReview?.review || '')
    const [reviewer, setReviewer] = useState(accountantReview?.reviewer || '')
    const [rating, setRating] = useState(accountantReview?.rating || '')
    const [reviewDate, setReviewDate] = useState(accountantReview?.reviewDate || new Date())

    const { updateAccountantReview, isLoading } = useUpdateAccountantReview()

    const handleSubmit = () => {
        if (!reviewBody) {
            addErrorToast({ body: 'Please add review text' })
            return
        }

        const data = {
            ...accountantReview,
            review: reviewBody,
            reviewer,
            reviewDate: String(reviewDate),
            rating: Number(rating),
        }

        updateAccountantReview({ reviewId: accountantReview.id, data }).then(matchSuccessResponse(onClose))
    }

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Edit review</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Content>
                            <TextArea
                                name="reviewBody"
                                value={reviewBody}
                                onChange={({ value }) => setReviewBody(value)}
                                height="120px"
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>Review date</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker
                                name="reviewDate"
                                value={reviewDate}
                                onChange={({ value }) => setReviewDate(value)}
                                minDate="2010-01-01"
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>Reviewer</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="reviewer"
                                value={reviewer}
                                onChange={({ value }) => setReviewer(value)}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    <Form.Row>
                        <Form.Row.Title>Rating</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="rating"
                                type="number"
                                min="1"
                                max="5"
                                value={rating}
                                onChange={({ value }) => setRating(value)}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button onClick={onClose} isSecondary disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={isLoading}>
                            Save
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
