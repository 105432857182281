import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, ButtonLink } from '@scouts/ui'

const NotesWrapper = styled.div`
    padding: 24px 30px;
    background: ${({ background }) => background};

    &:not(:last-child) {
        margin-bottom: 18px;
    }
`

const NotesHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

const NotesTitle = styled.div``

const CustomerEscalationsModalNotesCreate = ({ handleClick }) => (
    <NotesWrapper background={colors.blueLighter}>
        <NotesHeader>
            <NotesTitle>Notes and actions</NotesTitle>
            <ButtonLink icon="plus" onClick={handleClick}>
                Add notes
            </ButtonLink>
        </NotesHeader>
    </NotesWrapper>
)

CustomerEscalationsModalNotesCreate.propTypes = {
    handleClick: PropTypes.func.isRequired,
}

export default CustomerEscalationsModalNotesCreate
