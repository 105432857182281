import { ReactNode } from 'react'
import styled from 'styled-components'

import {
    radius,
    ModalBodyLegacy as ModalBody,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

export const SelfAssessmentUploadsFileModal = ({
    onClose,
    fileUrl,
    title,
    isImage,
}: {
    onClose: () => void
    fileUrl: string
    title: ReactNode
    isImage: boolean
}) => {
    return (
        <Modal onClose={onClose} isFullScreen>
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>{isImage ? <ImagePreview src={fileUrl} /> : <FilePreview src={fileUrl} />}</ModalBody>
        </Modal>
    )
}

const FilePreview = styled.iframe`
    width: 100%;
    min-height: 780px;
    border: 0;
    border-radius: ${radius.large};
`

const ImagePreview = styled.img`
    width: 100%;
`
