import { useGetCustomerProductSearchQuery, GetCustomersProductSearchParams } from '../api'

interface UseCustomersProductSearchProps {
    params: GetCustomersProductSearchParams
    skip?: boolean
}

export const useCustomersProductSearch = ({ params, skip = false }: UseCustomersProductSearchProps) => {
    const shouldSkipInitialFetch = !Object.keys(params).length

    const {
        data: customerProductSearch,
        isFetching,
        refetch,
    } = useGetCustomerProductSearchQuery(params, { skip: shouldSkipInitialFetch || !!skip })

    const { data = [], pagination } = customerProductSearch || {}

    return {
        data,
        pagination,
        isFetching,
        refetch,
    }
}
