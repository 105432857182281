import { Container, Flexer } from '@scouts/ui'

import { CompanyReturnDTO, FileType } from '@/models'
import { useCompanyReturnFiles } from '@/store/company-return-files/hooks'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'
import { useUser } from '@/hooks/use-user'

import { DocumentContainer } from './ui/DocumentContainer'

export const CompanyReturnDocuments = ({ companyReturn }: { companyReturn: CompanyReturnDTO }) => {
    const { isAdminUser } = useUser()
    const { id: companyReturnId, companyId } = companyReturn
    const customerId = useCustomerIdFromPath()

    const { companyReturnFilesByType, isLoading } = useCompanyReturnFiles({
        customerId,
        companyId,
        companyReturnId,
    })

    return (
        <Container>
            <Flexer column gap="24px">
                <DocumentContainer
                    isLoading={isLoading}
                    title="Bank statements"
                    files={companyReturnFilesByType[FileType.BankStatement]}
                    fileTypes={[FileType.BankStatement]}
                />
                <DocumentContainer
                    isLoading={isLoading}
                    title="Bookkeeping"
                    files={[
                        ...(companyReturnFilesByType[FileType.BookkeepingRecords] || []),
                        ...(companyReturnFilesByType[FileType.Spreadsheet] || []),
                    ]}
                    fileTypes={[FileType.BookkeepingRecords, FileType.Spreadsheet]}
                />

                <DocumentContainer
                    isLoading={isLoading}
                    title="VAT records"
                    files={companyReturnFilesByType[FileType.VATRecords]}
                    fileTypes={[FileType.VATRecords]}
                />

                <DocumentContainer
                    isLoading={isLoading}
                    title="Payroll records"
                    files={companyReturnFilesByType[FileType.Payroll]}
                    fileTypes={[FileType.Payroll]}
                />

                <DocumentContainer
                    isLoading={isLoading}
                    title="Funding, grants, investments, loans, credit agreements"
                    files={companyReturnFilesByType[FileType.LoanAndCreditAgreement]}
                    fileTypes={[FileType.LoanAndCreditAgreement]}
                />

                <DocumentContainer
                    isLoading={isLoading}
                    title="Previous returns"
                    files={companyReturnFilesByType[FileType.PreviousReturn]}
                    fileTypes={[FileType.PreviousReturn]}
                />

                <DocumentContainer
                    isLoading={isLoading}
                    title="TaxScouts invoices"
                    files={companyReturnFilesByType[FileType.TaxScoutsInvoice]}
                    fileTypes={[FileType.TaxScoutsInvoice]}
                    shouldDisplayUpload={isAdminUser}
                />
            </Flexer>
        </Container>
    )
}
