import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import _sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
    colors,
    font,
    media,
    Badge,
    Button,
    Checkbox,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Radio,
    TextArea,
} from '@scouts/ui'

import { requestSelfAssessment } from '@/actions'
import config from '@/config'
import {
    FILING_AREA_CAPITAL_GAINS,
    FILING_AREA_CHARITABLE_GIVING,
    FILING_AREA_DIVIDENDS_AND_INTEREST,
    FILING_AREA_EMPLOYMENT,
    FILING_AREA_FOREIGN_INCOME,
    FILING_AREA_GENERAL,
    FILING_AREA_INVESTMENT_SCHEME,
    FILING_AREA_ORDER,
    FILING_AREA_OTHER_INCOME,
    FILING_AREA_PENSIONS_AND_BENEFITS,
    FILING_AREA_PENSIONS_CONTRIBUTIONS,
    FILING_AREA_PROPERTY,
    FILING_AREA_REQUESTED_ISSUE_TYPES,
    FILING_AREA_SELF_EMPLOYMENT,
    FILING_AREA_STUDENT_LOANS,
    FILING_AREA_TITLES,
    ISSUE_TYPE_BANK_DETAILS_MISSING,
    ISSUE_TYPE_CAPITAL_GAINS_FILES_REQUESTED,
    ISSUE_TYPE_CHARITABLE_DONATIONS_FILES_REQUESTED,
    ISSUE_TYPE_DIVIDENDS_AND_INTEREST_FILES_REQUESTED,
    ISSUE_TYPE_EMPLOYMENT_FILES_REQUESTED,
    ISSUE_TYPE_FOREIGN_INCOME_FILES_REQUESTED,
    ISSUE_TYPE_GENERAL_FILES_REQUESTED,
    ISSUE_TYPE_INVESTMENT_SCHEME_FILES_REQUESTED,
    ISSUE_TYPE_OTHER_INCOME_FILES_REQUESTED,
    ISSUE_TYPE_PENSIONS_AND_BENEFITS_FILES_REQUESTED,
    ISSUE_TYPE_PENSIONS_CONTRIBUTIONS_FILES_REQUESTED,
    ISSUE_TYPE_PROPERTY_FILES_REQUESTED,
    ISSUE_TYPE_SELF_EMPLOYMENT_FILES_REQUESTED,
    ISSUE_TYPE_STUDENT_LOANS_FILES_REQUESTED,
    MESSAGE_SMS_LENGTH_CAP,
    MESSAGE_TYPE_EMAIL,
    MESSAGE_TYPE_SMS,
    PATH_CUSTOMERS,
    SELF_ASSESSMENT_ISSUE_TYPE_TITLES,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_LIST_FOR_SMS,
    TAX_YEARS,
} from '@/constants'
import { isAdmin } from '@/helpers'
import { FileType } from '@/models'

import RequestDocumentsStatus from './RequestDocumentsStatus'

const LinkToProfile = styled.div`
    font-size: ${font.small};
`

const TaxYear = styled.div`
    margin-bottom: 6px;
    padding: 9px 12px;
    background: ${(props) => (props.isActive ? colors.blueLighter : 'transparent')};
    border: 1px solid ${(props) => (props.isActive ? colors.blueLighter : 'transparent')};
    border-radius: 2px;

    ${media.tablet} {
        margin-bottom: 0;
        &:not(:first-child) {
            margin-left: 9px;
        }
    }
`

const RequestTypeContainer = styled.div`
    margin: 24px 0;
`

const RequestType = styled.div`
    &:not(:last-child) {
        margin-bottom: 15px;
    }
`

const DocumentsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
`

const ProfileContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Sections = styled.div`
    flex: 0 0 35%;
`

const Section = styled.div`
    color: ${colors.black};
    display: flex;
    align-items: center;
    min-height: 42px;
    padding: 9px 15px;
    background: ${(props) => (props.isActive ? colors.blueLighter : 'transparent')};
    border: 1px solid ${(props) => (props.isActive ? colors.blueLighter : 'transparent')};
    border-radius: 2px;
    cursor: pointer;
    &:last-of-type {
        margin-bottom: 12px;
    }
`

const SectionLabel = styled.div`
    &:not(:only-child) {
        margin-right: 9px;
    }
`

const Documents = styled.div`
    flex: 0 0 60%;
`

const DocumentsGroup = styled.div`
    margin-bottom: 24px;
`

const DocumentsSubtitle = styled.div`
    margin-bottom: 12px;
    padding-bottom: 9px;
    border-bottom: 1px solid ${colors.neutralLightest};

    ${media.tablet} {
        font-size: ${font.normal};
    }
`

const Document = styled.div`
    padding: 9px 0;
`

const IssueCustomText = styled.div`
    margin-top: 9px;
`

const MessageContainer = styled.div`
    background: ${colors.redLighter};
    padding: 32px 24px;
`

const RequestedFiles = styled.div`
    margin-bottom: 24px;
`

const Subtitle = styled.div`
    margin-bottom: 12px;
    padding-bottom: 9px;
    border-bottom: 1px solid ${colors.neutralLightest};

    ${media.tablet} {
        font-size: ${font.normal};
    }
`

const Issues = styled.div`
    margin-bottom: 12px;
`

const Issue = styled.div`
    color: ${colors.black};
    line-height: 1.3;
    margin-bottom: 6px;
`

const SubtitleAction = styled.button`
    color: ${colors.blue};
    font-size: ${font.small};
    display: inline-block;
    margin-left: 12px;
    border: 0;
    -webkit-appearance: none;

    ${media.pointer} {
        &:hover {
            text-decoration: underline;
        }
    }
`

const Status = styled.div`
    margin-top: 24px;
`

const Row = styled.div`
    ${media.tablet} {
        display: flex;
        justify-content: space-between;
    }
`

const PrimaryColumn = styled.div`
    ${media.tablet} {
        width: 50%;
        order: 1;
    }
`

const SecondaryColumn = styled.div`
    ${media.tablet} {
        width: 50%;
        order: 2;
    }
`

const RadioContainer = styled.div`
    margin-top: 12px;
    margin-bottom: 9px;

    ${media.tablet} {
        display: flex;
        align-items: center;
        margin-left: ${({ withMargin }) => withMargin && `36px;`};
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    padding: 9px 12px;
    background: 'transparent';
    border-radius: 2px;
    width: auto;

    ${media.tablet} {
        margin-bottom: 0;

        &:not(:first-child) {
            margin-left: 9px;
        }
    }
`

const TextCounter = styled.div`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    text-align: right;
    padding: 4px 6px;
`

const VIEW_REQUEST_TYPE = 'requestType'
const VIEW_REQUEST_DETAILS = 'requestDetails'
const VIEW_MESSAGE = 'message'

const REQUEST_TYPE_REMINDER = 'reminder'
const REQUEST_TYPE_DOCUMENTS = 'documents'
const REQUEST_TYPE_PROFILE = 'profile'
const REQUEST_TYPE_GENERAL = 'general'
const REQUEST_TYPE_IDENTITY_VERIFICATION = 'identityVerification'

const getDefaultMessage = ({ customerName, accountantName }) => `Hi ${customerName}



All the best,
${accountantName}`

function getComponentDefaultState(props) {
    return {
        activeView: VIEW_REQUEST_TYPE,
        activeIssueType: null,
        issues: [],
        isDocumentsRequest: false,
        isGeneralEnquiry: true,
        isIdentityVerification: false,
        isReminderMessage: false,
        isTaxProfileRequest: false,
        messageType: MESSAGE_TYPE_EMAIL,
        messageContent: getDefaultMessage({
            customerName: _get(props.customer, ['firstName'], ''),
            accountantName: _get(props.user, ['firstName'], ''),
        }),
        onlyActiveFilingAreas: true,
        requestAddress: false,
        requestBankDetails: false,
        requestDateOfBirth: false,
        requestNI: false,
        requestOther: false,
        requestOtherCustomText: '',
        requestPhoneNumber: false,
        requestUTR: false,
        selfAssessmentId: props.selfAssessmentId,
        selfAssessmentStatus: null,
    }
}

class RequestDocuments extends Component {
    sections = [
        {
            issueType: ISSUE_TYPE_EMPLOYMENT_FILES_REQUESTED,
            filingArea: FILING_AREA_EMPLOYMENT,
            fileTypes: [
                [FileType.P60, 'P60 certificate'],
                [FileType.P45, 'P45 certificate'],
                [FileType.Payslip, 'Final payslip of previous tax year'],
                [FileType.P11D, 'P11D certificate'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_SELF_EMPLOYMENT_FILES_REQUESTED,
            filingArea: FILING_AREA_SELF_EMPLOYMENT,
            fileTypes: [
                [FileType.IncomeRecord, 'Income records'],
                [FileType.ExpenseRecord, 'Expense records'],
                [FileType.Spreadsheet, 'Summary of your income and/or expenses in a spreadsheet'],
                [FileType.BankStatement, 'Bank statement with your income and/or expenses'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_PROPERTY_FILES_REQUESTED,
            filingArea: FILING_AREA_PROPERTY,
            fileTypes: [
                [FileType.IncomeRecord, 'Income records for each property'],
                [FileType.ExpenseRecord, 'Any expense documents regarding the property'],
                [FileType.PaymentSchedule, 'Your mortgage payment schedule or annual report (if mortgaged)'],
                [FileType.Spreadsheet, 'Summary of your income and/or expenses in a spreadsheet'],
                [FileType.BankStatement, 'Bank statement containing your income and/or expenses'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_CAPITAL_GAINS_FILES_REQUESTED,
            filingArea: FILING_AREA_CAPITAL_GAINS,
            fileTypes: [
                [FileType.Certificate, 'Receipt/Certificate for each individual sale transaction of your assets'],
                [FileType.InvestmentSchemeCertificate, 'EIS3/SEIS3 certificate'],
                [FileType.Spreadsheet, 'Summary of the transactions'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_DIVIDENDS_AND_INTEREST_FILES_REQUESTED,
            filingArea: FILING_AREA_DIVIDENDS_AND_INTEREST,
            fileTypes: [
                [FileType.InterestCertificate, 'Annual interest summary from your bank'],
                [FileType.DividendVoucher, 'Dividend vouchers'],
                [FileType.Spreadsheet, 'Summary of the transactions'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_PENSIONS_AND_BENEFITS_FILES_REQUESTED,
            filingArea: FILING_AREA_PENSIONS_AND_BENEFITS,
            fileTypes: [
                [FileType.AnnualSummary, 'Year end summary letter from pension service provider'],
                [
                    FileType.PaymentSchedule,
                    'Copy of your pension payments schedule or document, spreadsheet or screenshot that summarises the payments you’ve received',
                ],
                [FileType.PensionsDocument, 'Government annual pension summary letter'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_FOREIGN_INCOME_FILES_REQUESTED,
            filingArea: FILING_AREA_FOREIGN_INCOME,
            fileTypes: [[FileType.Other]],
        },
        {
            issueType: ISSUE_TYPE_OTHER_INCOME_FILES_REQUESTED,
            filingArea: FILING_AREA_OTHER_INCOME,
            fileTypes: [[FileType.Other]],
        },
        {
            issueType: ISSUE_TYPE_CHARITABLE_DONATIONS_FILES_REQUESTED,
            filingArea: FILING_AREA_CHARITABLE_GIVING,
            fileTypes: [[FileType.Certificate, 'Records of your donations'], [FileType.Other]],
        },
        {
            issueType: ISSUE_TYPE_PENSIONS_CONTRIBUTIONS_FILES_REQUESTED,
            filingArea: FILING_AREA_PENSIONS_CONTRIBUTIONS,
            fileTypes: [
                [FileType.PensionsDocument, 'Annual pension contributions summary'],
                [FileType.Payslip, 'Last payslip of the tax year'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_STUDENT_LOANS_FILES_REQUESTED,
            filingArea: FILING_AREA_STUDENT_LOANS,
            fileTypes: [
                [FileType.AnnualSummary, 'Annual/repayments summary document from your student loan company'],
                [FileType.Payslip, 'Last payslip of the tax year'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_INVESTMENT_SCHEME_FILES_REQUESTED,
            filingArea: FILING_AREA_INVESTMENT_SCHEME,
            fileTypes: [
                [FileType.InvestmentSchemeCertificate, 'SEIS3 or EIS3 certificate'],
                [FileType.Certificate, 'Share certificate or investor contract made with the purchase'],
                [FileType.Other],
            ],
        },
        {
            issueType: ISSUE_TYPE_GENERAL_FILES_REQUESTED,
            filingArea: FILING_AREA_GENERAL,
            fileTypes: [[FileType.PreviousReturn, "Previous year's tax return"]],
        },
    ]

    constructor(props) {
        super(props)

        this.state = getComponentDefaultState(props)

        _bindAll(this, [
            'canSendReminder',
            'canSendSms',
            'handleChange',
            'handleChangeCheckbox',
            'handleContinue',
            'handleIssueCustomTextChange',
            'handleRequestTypeChange',
            'handleRequestTypeChangeCheckbox',
            'handleStatusChange',
            'handleSubmit',
            'handleTaxYearChange',
            'resetState',
            'showAllFilingAreas',
            'toggleFileType',
        ])
    }

    componentDidMount() {
        const { selfAssessmentId } = this.props
        this.setTaxYearStatus(selfAssessmentId)
        this.setFirstActiveSection(selfAssessmentId)
        this.fetchEmploymentData()
    }

    handleChange({ name, value }) {
        this.setState({ [name]: value })
    }

    handleChangeCheckbox({ name, checked }) {
        this.setState({ [name]: checked })
    }

    handleTaxYearChange({ value }) {
        const { customerSelfAssessments } = this.props
        const selfAssessmentId = Number(value)
        const selfAssessmentStatus = _get(_find(customerSelfAssessments, { id: selfAssessmentId }), ['status'], null)
        this.setState({ selfAssessmentId, issues: [], onlyActiveFilingAreas: true, selfAssessmentStatus })
        this.setFirstActiveSection(selfAssessmentId)
    }

    handleSubmit() {
        const { onSubmit } = this.props
        const {
            isIdentityVerification,
            issues,
            messageContent,
            messageType,
            requestAddress,
            requestBankDetails,
            requestDateOfBirth,
            requestNI,
            requestOther,
            requestPhoneNumber,
            requestUTR,
            selfAssessmentId,
            selfAssessmentStatus,
        } = this.state

        if (!selfAssessmentId) {
            alert('Please specify a tax year')
            return
        }

        const hideLinkToTaxscouts = !(
            requestBankDetails ||
            requestUTR ||
            requestNI ||
            requestAddress ||
            requestOther ||
            requestDateOfBirth ||
            requestPhoneNumber ||
            issues.length > 0 ||
            isIdentityVerification
        )

        if (requestBankDetails) {
            issues.push({
                issueType: ISSUE_TYPE_BANK_DETAILS_MISSING,
                customIssueText: SELF_ASSESSMENT_ISSUE_TYPE_TITLES[ISSUE_TYPE_BANK_DETAILS_MISSING],
            })
        }

        onSubmit({
            hideLinkToTaxscouts,
            isIdentityVerification,
            issues,
            messageContent,
            selfAssessmentId,
            selfAssessmentStatus,
            messageType,
        })
    }

    handleContinue() {
        const { issues } = this.state

        if (_find(issues, { fileType: 'Other', customIssueText: '' })) {
            alert('Please specify text for Other documents')
            return
        }

        this.goToView(VIEW_MESSAGE)
    }

    handleRequestTypeChange({ value }) {
        this.resetState()

        if (value === REQUEST_TYPE_GENERAL) {
            this.setState({ isGeneralEnquiry: true, isReminderMessage: false })
        } else if (value === REQUEST_TYPE_REMINDER) {
            this.setState({ isReminderMessage: true, isGeneralEnquiry: false })
        }
    }

    handleRequestTypeChangeCheckbox({ name, checked }) {
        if (name === REQUEST_TYPE_DOCUMENTS) {
            this.setState({ isDocumentsRequest: checked })
            if (!checked) {
                this.setState({ issues: [] })
            }
        } else if (name === REQUEST_TYPE_PROFILE) {
            this.setState({ isTaxProfileRequest: checked })
            if (!checked) {
                this.setState({
                    requestAddress: false,
                    requestBankDetails: false,
                    requestDateOfBirth: false,
                    requestNI: false,
                    requestOther: false,
                    requestOtherCustomText: '',
                    requestPhoneNumber: false,
                    requestUTR: false,
                })
            }
        } else if (name === REQUEST_TYPE_IDENTITY_VERIFICATION) {
            this.setState({ isIdentityVerification: checked })
        }
    }

    handleIssueCustomTextChange({ name, value }) {
        const { issues, activeIssueType } = this.state

        const [fileType, employmentId] = name.split('-')

        const index = _findIndex(issues, {
            issueType: activeIssueType,
            fileType,
            employmentId: employmentId ? Number(employmentId) : null,
        })

        if (index === -1) return

        const updatedIssues = [...issues]
        updatedIssues[index].customIssueText = value

        this.setState({ issues: updatedIssues })
    }

    handleStatusChange(selfAssessmentStatus) {
        this.setState({ selfAssessmentStatus })
    }

    setMessageContent() {
        const { customer, user } = this.props
        const {
            isIdentityVerification,
            isReminderMessage,
            issues,
            requestAddress,
            requestBankDetails,
            requestDateOfBirth,
            requestNI,
            requestOther,
            requestOtherCustomText,
            requestPhoneNumber,
            requestUTR,
        } = this.state

        const accountantName = _get(user, ['firstName'], '')
        const customerName = _get(customer, ['firstName'], '')

        const documentsIssues = issues.map((issue) => {
            const issueTypeTitle = this.issueTypeTitle({ issueType: issue.issueType })
            return `- ${issueTypeTitle}: ${this.issueTitle(issue)}`
        })

        const documentsContent =
            issues.length > 0
                ? `\nCan you please provide the following documents:\n\n${documentsIssues.join('\n')}\n`
                : ''

        const profileIssues = []
        if (requestNI) profileIssues.push('- National Insurance Number')
        if (requestUTR) profileIssues.push('- UTR number')
        if (requestAddress) profileIssues.push('- Address')
        if (requestBankDetails) profileIssues.push('- Bank details')
        if (requestDateOfBirth) profileIssues.push('- Date of birth')
        if (requestPhoneNumber) profileIssues.push('- Phone number')
        if (requestOther && requestOtherCustomText) profileIssues.push(`- ${requestOtherCustomText}`)

        let profileContent = ''

        if (profileIssues.length > 0) {
            profileContent =
                issues.length > 0
                    ? `\nAlso can you fill out the details below in your TaxScouts profile:\n\n${profileIssues.join(
                          '\n'
                      )}\n`
                    : `\nBefore I can get started, I need you to fill out the details below in your TaxScouts profile:\n\n${profileIssues.join(
                          '\n'
                      )}\n`
        }

        const identityVerificationContent = isIdentityVerification
            ? `\nAs your accountant I will need to verify your identity. To do that I'll need:\n
- Picture or copy of your ID (passport, driving license)
- Document proving your address (utility bill, bank statement)\n`
            : ''

        const defaultContent = `Hi ${customerName}

I’m ${accountantName}. I’ll be your accountant for doing your taxes. I need some additional information from you to get started.
${documentsContent}${profileContent}${identityVerificationContent}
Please log in to TaxScouts to provide the above.

Thanks, ${accountantName}`

        const messageContent = isReminderMessage
            ? this.getReminderMessageContent({ customerName, accountantName })
            : defaultContent

        this.setState({ messageContent })
    }

    getReminderMessageContent({ customerName, accountantName }) {
        const { selfAssessmentId, customerSelfAssessments } = this.props
        const currentSelfAssessmentStatus = _get(
            _find(customerSelfAssessments, { id: selfAssessmentId }),
            ['status'],
            null
        )

        switch (currentSelfAssessmentStatus) {
            case SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS:
            case SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED:
                return `Hi ${customerName}

Your tax return is almost ready. Please log in to your account to correct the information you sent. Any questions, please let me know.
${config.homePageUrl}/assessment

Thanks,
${accountantName}`
            case SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL:
                return `Hi ${customerName}

Your tax return is ready. Please log in to your account to review and approve it. Any questions please let me know.
${config.homePageUrl}/assessment

Thanks,
${accountantName}`
            default:
                return getDefaultMessage({ customerName, accountantName })
        }
    }

    setTaxYearStatus(selfAssessmentId) {
        const { customerSelfAssessments } = this.props
        const selfAssessmentStatus = _get(_find(customerSelfAssessments, { id: selfAssessmentId }), ['status'], null)
        this.setState({ selfAssessmentStatus })
    }

    setFirstActiveSection(selfAssessmentId) {
        const activeFilingArea = _get(this.activeFilingAreas(selfAssessmentId), [0], null)
        this.setState({ selfAssessmentId, activeIssueType: FILING_AREA_REQUESTED_ISSUE_TYPES[activeFilingArea] })
    }

    resetState() {
        const { selfAssessmentId } = this.props
        this.setState(getComponentDefaultState(this.props))
        this.setTaxYearStatus(selfAssessmentId)
        this.setFirstActiveSection(selfAssessmentId)
    }

    canSendReminder() {
        const { customerSelfAssessments, user } = this.props
        const { selfAssessmentId } = this.state
        const currentSelfAssessment = customerSelfAssessments.find((s) => s.id === selfAssessmentId)

        return SELF_ASSESSMENT_STATUS_LIST_FOR_SMS.includes(currentSelfAssessment.status) || isAdmin(user)
    }

    canSendSms() {
        const { customer } = this.props
        const customerPhoneNumber = _get(customer, ['phoneNumber'], '')
        const hasPhoneNumber = customerPhoneNumber && customerPhoneNumber.length >= 7

        return hasPhoneNumber
    }

    canSubmitMessage() {
        const { messageType, messageContent } = this.state
        if (messageType === MESSAGE_TYPE_SMS) {
            return messageContent.length < MESSAGE_SMS_LENGTH_CAP
        }

        return true
    }

    employmentsFromSelfAssessment(selfAssessmentId) {
        const { selfAssessments } = this.props
        const selfAssessment = _find(selfAssessments.details, { id: selfAssessmentId })
        return _get(selfAssessment, ['employments'], [])
    }

    openSection(issueType) {
        this.setState({ activeIssueType: issueType })
    }

    defaultCustomIssueText({ issueType, fileType }) {
        const section = _find(this.sections, { issueType })
        const sectionFileType = _find(section.fileTypes, (o) => o[0] === fileType)
        return _get(sectionFileType, [1], '')
    }

    issueTypeTitle({ issueType }) {
        const section = _find(this.sections, { issueType })
        const filingArea = _get(section, ['filingArea'], '')
        return FILING_AREA_TITLES[filingArea]
    }

    issueTitle(issue) {
        const { selfAssessmentId } = this.state

        let employerName

        if (issue.employmentId) {
            const selfAssessmentEmployments = this.employmentsFromSelfAssessment(selfAssessmentId)
            const employment = _find(selfAssessmentEmployments, { id: issue.employmentId })
            employerName = _get(employment, ['employerName'], '')
        }

        if (issue.customIssueText) return issue.customIssueText + (employerName ? ` (${employerName})` : '')

        return (
            SELF_ASSESSMENT_ISSUE_TYPE_TITLES[issue.issueType] +
            (issue.fileType ? `: ${issue.fileType}` : '') +
            (employerName ? ` (${employerName})` : '')
        )
    }

    addIssue({ issueType, fileType, employmentId }) {
        const { issues } = this.state

        const defaultCustomIssueText = this.defaultCustomIssueText({ issueType, fileType })

        const customIssueText = defaultCustomIssueText ? `${defaultCustomIssueText}` : ''

        const issue = { issueType, fileType, customIssueText, employmentId }
        if (_findIndex(issues, issue) > -1) return
        issues.push(issue)
        this.setState({ issues })
    }

    removeIssue({ issueType, fileType, employmentId }) {
        const { issues } = this.state
        const index = _findIndex(issues, { issueType, fileType, employmentId })
        if (index === -1) return
        issues.splice(index, 1)
        this.setState({ issues })
    }

    goToView(view) {
        this.setMessageContent()
        this.setState({ activeView: view })
    }

    toggleFileType({ fileType, employmentId, checked }) {
        const { activeIssueType } = this.state
        if (checked) {
            this.addIssue({ issueType: activeIssueType, fileType, employmentId })
        } else {
            this.removeIssue({ issueType: activeIssueType, fileType, employmentId })
        }
    }

    fetchEmploymentData() {
        const { dispatch, customerSelfAssessments } = this.props
        customerSelfAssessments.forEach((selfAssessment) => {
            dispatch(requestSelfAssessment(selfAssessment.id))
        })
    }

    activeFilingAreas(selfAssessmentId) {
        const { customerSelfAssessments } = this.props

        const filingAreas = _get(
            _find(customerSelfAssessments, { id: Number(selfAssessmentId) }),
            ['activeFilingAreas'],
            []
        ).filter((v) => v !== FILING_AREA_GENERAL)

        return _sortBy(filingAreas, (filingArea) => FILING_AREA_ORDER[filingArea])
    }

    showAllFilingAreas() {
        this.setState({ onlyActiveFilingAreas: false })
    }

    renderFileType([fileType, label], employmentId = null) {
        const { issues, activeIssueType } = this.state
        const issue = _find(issues, {
            issueType: activeIssueType,
            fileType,
            employmentId,
        })
        const isActive = !!issue

        return (
            <Document key={fileType}>
                <Checkbox
                    name={`${fileType}${employmentId || ''}`}
                    value={fileType}
                    label={label || fileType}
                    onChange={({ checked }) => this.toggleFileType({ fileType, employmentId, checked })}
                    checked={isActive}
                />
                {isActive && fileType === FileType.Other && (
                    <IssueCustomText>
                        <Input
                            name={`${fileType}${employmentId ? `-${employmentId}` : ''}`}
                            placeholder="Please specify here…"
                            value={_get(issue, ['customIssueText'], '')}
                            maxLength={255}
                            onChange={this.handleIssueCustomTextChange}
                        />
                    </IssueCustomText>
                )}
            </Document>
        )
    }

    renderLinkToProfile() {
        const { customer } = this.props
        const { selfAssessmentId } = this.state
        return (
            <LinkToProfile>
                <Link to={`${PATH_CUSTOMERS}/${customer.id}/${selfAssessmentId}`} target="_blank">
                    Open profile in separate tab
                </Link>
            </LinkToProfile>
        )
    }

    renderIssueCount(issueType) {
        const { issues } = this.state
        const count = issues.filter((issue) => issue.issueType === issueType).length
        return count ? <Badge background={colors.black}>{count}</Badge> : null
    }

    render() {
        const { customer, customerSelfAssessments, onCancel, isSubmitPending, isSuccess, sendResponse } = this.props
        const {
            activeIssueType,
            activeView,
            isDocumentsRequest,
            isGeneralEnquiry,
            isIdentityVerification,
            isReminderMessage,
            issues,
            isTaxProfileRequest,
            messageContent,
            messageType,
            onlyActiveFilingAreas,
            requestAddress,
            requestBankDetails,
            requestDateOfBirth,
            requestNI,
            requestOther,
            requestOtherCustomText,
            requestPhoneNumber,
            requestUTR,
            selfAssessmentId,
            selfAssessmentStatus,
        } = this.state

        const activeFilingAreaFileTypes = _get(_find(this.sections, { issueType: activeIssueType }), ['fileTypes'], [])
        const selfAssessmentEmployments = this.employmentsFromSelfAssessment(selfAssessmentId)

        const hasMessageFailed = _get(sendResponse, ['messageFailure'], true)
        const successfulSentCount = hasMessageFailed === false ? 1 : 0

        const tourClassNames = `tour-request-docs-modal ${
            activeView === VIEW_MESSAGE ? 'tour-request-docs-continue' : ''
        }`

        return (
            <Modal isWide onClose={onCancel}>
                {!isSuccess && (
                    <Form onSubmit={this.handleSubmit}>
                        <div className={tourClassNames}>
                            {activeView === VIEW_REQUEST_TYPE && (
                                <>
                                    <ModalHeader>
                                        <ModalTitle>Message to {customer.fullName}</ModalTitle>
                                        <ModalSubtitle>{this.renderLinkToProfile()}</ModalSubtitle>
                                    </ModalHeader>
                                    <ModalBody>
                                        <RequestTypeContainer>
                                            <Subtitle>Message</Subtitle>
                                            <RequestType>
                                                <Radio
                                                    name="requestMessageType"
                                                    value={REQUEST_TYPE_GENERAL}
                                                    label="General enquiry & other"
                                                    onChange={this.handleRequestTypeChange}
                                                    checked={isGeneralEnquiry}
                                                />
                                            </RequestType>

                                            {this.canSendReminder() && (
                                                <RequestType>
                                                    <Radio
                                                        name="requestMessageType"
                                                        value={REQUEST_TYPE_REMINDER}
                                                        label="Reminder"
                                                        onChange={this.handleRequestTypeChange}
                                                        checked={isReminderMessage}
                                                    />
                                                </RequestType>
                                            )}
                                        </RequestTypeContainer>

                                        {isGeneralEnquiry && (
                                            <RequestTypeContainer>
                                                <Subtitle>Request missing information</Subtitle>
                                                <RequestType>
                                                    <Checkbox
                                                        name={REQUEST_TYPE_DOCUMENTS}
                                                        label="Documents"
                                                        onChange={this.handleRequestTypeChangeCheckbox}
                                                        checked={isDocumentsRequest}
                                                    />
                                                    {isDocumentsRequest && (
                                                        <RadioContainer withMargin>
                                                            {_orderBy(
                                                                customerSelfAssessments,
                                                                ['taxYear'],
                                                                ['desc']
                                                            ).map(({ id, taxYear }) => (
                                                                <TaxYear
                                                                    key={id}
                                                                    isActive={id === Number(selfAssessmentId)}
                                                                >
                                                                    <Radio
                                                                        name="selfAssessmentId"
                                                                        value={id}
                                                                        label={TAX_YEARS[taxYear]}
                                                                        onChange={this.handleTaxYearChange}
                                                                        checked={id === Number(selfAssessmentId)}
                                                                    />
                                                                </TaxYear>
                                                            ))}
                                                        </RadioContainer>
                                                    )}
                                                </RequestType>
                                                <RequestType>
                                                    <Checkbox
                                                        name={REQUEST_TYPE_PROFILE}
                                                        label="Tax profile (UTR, NI, address, bank details, etc)"
                                                        onChange={this.handleRequestTypeChangeCheckbox}
                                                        checked={isTaxProfileRequest}
                                                    />
                                                </RequestType>
                                                <RequestType>
                                                    <Checkbox
                                                        name={REQUEST_TYPE_IDENTITY_VERIFICATION}
                                                        label="KYC documents (ID + Proof of Address)"
                                                        onChange={this.handleRequestTypeChangeCheckbox}
                                                        checked={isIdentityVerification}
                                                    />
                                                </RequestType>
                                            </RequestTypeContainer>
                                        )}
                                    </ModalBody>

                                    <ModalFooter>
                                        <ModalActions>
                                            <Button onClick={onCancel} isSecondary>
                                                Cancel
                                            </Button>
                                            <Button
                                                className="tour-request-docs-continue"
                                                onClick={() =>
                                                    this.goToView(
                                                        isDocumentsRequest || isTaxProfileRequest
                                                            ? VIEW_REQUEST_DETAILS
                                                            : VIEW_MESSAGE
                                                    )
                                                }
                                            >
                                                Continue
                                            </Button>
                                        </ModalActions>
                                    </ModalFooter>
                                </>
                            )}

                            {activeView === VIEW_REQUEST_DETAILS && (
                                <>
                                    <ModalHeader>
                                        <ModalTitle>Request missing information</ModalTitle>
                                        <ModalSubtitle>{this.renderLinkToProfile()}</ModalSubtitle>
                                    </ModalHeader>
                                    {isDocumentsRequest && (
                                        <ModalBody>
                                            <Subtitle>Documents</Subtitle>
                                            <DocumentsContainer>
                                                <Sections>
                                                    {this.sections
                                                        .filter(
                                                            ({ filingArea }) =>
                                                                !onlyActiveFilingAreas ||
                                                                this.activeFilingAreas(selfAssessmentId).includes(
                                                                    filingArea
                                                                )
                                                        )
                                                        .map(({ filingArea, issueType }) => (
                                                            <Section
                                                                key={`${filingArea}-${issueType}`}
                                                                onClick={() => this.openSection(issueType)}
                                                                isActive={activeIssueType === issueType}
                                                            >
                                                                <SectionLabel>
                                                                    {FILING_AREA_TITLES[filingArea]}
                                                                </SectionLabel>
                                                                {this.renderIssueCount(issueType)}
                                                            </Section>
                                                        ))}
                                                    {onlyActiveFilingAreas && (
                                                        <Button isSecondary onClick={() => this.showAllFilingAreas()}>
                                                            More documents
                                                        </Button>
                                                    )}
                                                </Sections>
                                                <Documents>
                                                    {activeIssueType === ISSUE_TYPE_EMPLOYMENT_FILES_REQUESTED && (
                                                        <>
                                                            {selfAssessmentEmployments.map((employment) => (
                                                                <DocumentsGroup key={employment.id}>
                                                                    <DocumentsSubtitle>
                                                                        {employment.employerName}
                                                                    </DocumentsSubtitle>
                                                                    {activeFilingAreaFileTypes.map((fileType) =>
                                                                        this.renderFileType(fileType, employment.id)
                                                                    )}
                                                                </DocumentsGroup>
                                                            ))}
                                                            {selfAssessmentEmployments.length > 0 && (
                                                                <DocumentsSubtitle>
                                                                    Any other employer
                                                                </DocumentsSubtitle>
                                                            )}
                                                        </>
                                                    )}
                                                    {activeFilingAreaFileTypes.map((fileType) =>
                                                        this.renderFileType(fileType)
                                                    )}
                                                </Documents>
                                            </DocumentsContainer>
                                        </ModalBody>
                                    )}

                                    {isTaxProfileRequest && (
                                        <ModalBody>
                                            <Subtitle>Tax profile</Subtitle>
                                            <ProfileContainer>
                                                <Sections>
                                                    <Section isActive>
                                                        <SectionLabel>General</SectionLabel>
                                                    </Section>
                                                </Sections>
                                                <Documents>
                                                    <Document>
                                                        <Checkbox
                                                            name="requestNI"
                                                            label="NI number"
                                                            onChange={this.handleChangeCheckbox}
                                                            checked={requestNI}
                                                        />
                                                    </Document>
                                                    <Document>
                                                        <Checkbox
                                                            name="requestUTR"
                                                            label="UTR number"
                                                            onChange={this.handleChangeCheckbox}
                                                            checked={requestUTR}
                                                        />
                                                    </Document>
                                                    <Document>
                                                        <Checkbox
                                                            name="requestAddress"
                                                            label="Address"
                                                            onChange={this.handleChangeCheckbox}
                                                            checked={requestAddress}
                                                        />
                                                    </Document>
                                                    <Document>
                                                        <Checkbox
                                                            name="requestBankDetails"
                                                            label="Bank details"
                                                            onChange={this.handleChangeCheckbox}
                                                            checked={requestBankDetails}
                                                        />
                                                    </Document>
                                                    <Document>
                                                        <Checkbox
                                                            name="requestDateOfBirth"
                                                            label="Date of birth"
                                                            onChange={this.handleChangeCheckbox}
                                                            checked={requestDateOfBirth}
                                                        />
                                                    </Document>
                                                    <Document>
                                                        <Checkbox
                                                            name="requestPhoneNumber"
                                                            label="Phone number"
                                                            onChange={this.handleChangeCheckbox}
                                                            checked={requestPhoneNumber}
                                                        />
                                                    </Document>
                                                    <Document>
                                                        <Checkbox
                                                            name="requestOther"
                                                            label="Other"
                                                            onChange={this.handleChangeCheckbox}
                                                            checked={requestOther}
                                                        />
                                                        {requestOther && (
                                                            <IssueCustomText>
                                                                <Input
                                                                    name="requestOtherCustomText"
                                                                    placeholder="Please specify here…"
                                                                    value={requestOtherCustomText}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </IssueCustomText>
                                                        )}
                                                    </Document>
                                                </Documents>
                                            </ProfileContainer>
                                        </ModalBody>
                                    )}

                                    <ModalFooter>
                                        <ModalActions>
                                            <Button onClick={() => this.goToView(VIEW_REQUEST_TYPE)} isSecondary>
                                                Back
                                            </Button>

                                            <Button onClick={onCancel} isSecondary>
                                                Cancel
                                            </Button>

                                            <Button
                                                onClick={this.handleContinue}
                                                className="tour-request-docs-continue"
                                            >
                                                Continue
                                            </Button>
                                        </ModalActions>
                                    </ModalFooter>
                                </>
                            )}

                            {activeView === VIEW_MESSAGE && (
                                <>
                                    <ModalHeader>
                                        <ModalTitle>Message to {customer.fullName}</ModalTitle>
                                        <ModalSubtitle>{this.renderLinkToProfile()}</ModalSubtitle>
                                    </ModalHeader>

                                    <ModalBody>
                                        {messageType === MESSAGE_TYPE_EMAIL && (
                                            <RequestedFiles>
                                                <Subtitle>
                                                    {issues.length > 0
                                                        ? 'Requested documents'
                                                        : 'No documents specified'}{' '}
                                                    <SubtitleAction
                                                        type="button"
                                                        onClick={() =>
                                                            this.goToView(
                                                                isDocumentsRequest
                                                                    ? VIEW_REQUEST_DETAILS
                                                                    : VIEW_REQUEST_TYPE
                                                            )
                                                        }
                                                    >
                                                        {issues.length > 0 ? 'Edit' : 'Add'}
                                                    </SubtitleAction>
                                                </Subtitle>
                                                <Issues>
                                                    {issues.map((issue) => (
                                                        <Issue key={`${issue.issueType}-${issue.fileType}`}>
                                                            <span>✓ </span>
                                                            {this.issueTypeTitle({ issueType: issue.issueType })}:{' '}
                                                            {this.issueTitle(issue)}
                                                        </Issue>
                                                    ))}
                                                </Issues>
                                            </RequestedFiles>
                                        )}
                                        <Subtitle>
                                            Your {messageType === MESSAGE_TYPE_EMAIL ? 'email' : 'text'} message
                                        </Subtitle>
                                        <MessageContainer>
                                            <TextArea
                                                name="messageContent"
                                                value={messageContent}
                                                onChange={this.handleChange}
                                                required
                                                disabled={isSubmitPending}
                                                height={messageType === MESSAGE_TYPE_SMS ? '190px' : '320px'}
                                                hasError={
                                                    messageContent.length >= MESSAGE_SMS_LENGTH_CAP &&
                                                    messageType === MESSAGE_TYPE_SMS
                                                }
                                            />
                                            {messageType === MESSAGE_TYPE_SMS && (
                                                <TextCounter>
                                                    {MESSAGE_SMS_LENGTH_CAP - messageContent.length > 0
                                                        ? MESSAGE_SMS_LENGTH_CAP - messageContent.length
                                                        : `Text (SMS) messages are limited to ${MESSAGE_SMS_LENGTH_CAP} characters`}
                                                </TextCounter>
                                            )}
                                        </MessageContainer>

                                        <Row>
                                            <PrimaryColumn>
                                                <Status>
                                                    <Subtitle>Set clients status to</Subtitle>
                                                    <RequestDocumentsStatus
                                                        status={selfAssessmentStatus}
                                                        handleStatusChange={this.handleStatusChange}
                                                    />
                                                </Status>
                                            </PrimaryColumn>

                                            {isReminderMessage && (
                                                <SecondaryColumn>
                                                    <Status>
                                                        <Subtitle>Send as </Subtitle>
                                                        <RadioContainer>
                                                            <Content>
                                                                <Radio
                                                                    name="messageType"
                                                                    value={MESSAGE_TYPE_EMAIL}
                                                                    label="Email"
                                                                    onChange={this.handleChange}
                                                                    checked={messageType === MESSAGE_TYPE_EMAIL}
                                                                />
                                                            </Content>

                                                            <Content>
                                                                <Radio
                                                                    name="messageType"
                                                                    value={MESSAGE_TYPE_SMS}
                                                                    label={
                                                                        this.canSendSms()
                                                                            ? 'SMS'
                                                                            : 'SMS (no phone number set)'
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    disabled={!this.canSendSms()}
                                                                    checked={messageType === MESSAGE_TYPE_SMS}
                                                                />
                                                            </Content>
                                                        </RadioContainer>
                                                    </Status>
                                                </SecondaryColumn>
                                            )}
                                        </Row>
                                    </ModalBody>

                                    <ModalFooter>
                                        <ModalActions>
                                            <Button
                                                onClick={() =>
                                                    this.goToView(
                                                        isDocumentsRequest || isTaxProfileRequest
                                                            ? VIEW_REQUEST_DETAILS
                                                            : VIEW_REQUEST_TYPE
                                                    )
                                                }
                                                isSecondary
                                            >
                                                Back
                                            </Button>

                                            <Button onClick={onCancel} isSecondary>
                                                Cancel
                                            </Button>

                                            <Button
                                                type="submit"
                                                isLoading={isSubmitPending}
                                                disabled={!this.canSubmitMessage()}
                                            >
                                                Send message
                                            </Button>
                                        </ModalActions>
                                    </ModalFooter>
                                </>
                            )}
                        </div>
                    </Form>
                )}

                {isSuccess && (
                    <div className={tourClassNames}>
                        <ModalHeader>
                            {successfulSentCount === 1 && <ModalTitle>Message successfully sent</ModalTitle>}
                            {successfulSentCount === 0 && <ModalTitle>Message sent</ModalTitle>}
                        </ModalHeader>
                        <ModalFooter>
                            <ModalActions>
                                <ModalActions>
                                    <Button onClick={onCancel}>Got it</Button>
                                </ModalActions>
                            </ModalActions>
                        </ModalFooter>
                    </div>
                )}
            </Modal>
        )
    }
}

RequestDocuments.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selfAssessments: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    customerSelfAssessments: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selfAssessmentId: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    isSubmitPending: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    sendResponse: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    selfAssessments: state.selfAssessments,
    user: state.user,
})

export default connect(mapStateToProps)(RequestDocuments)
