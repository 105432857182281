import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, Radio } from '@scouts/ui'

const Form = styled.form``

const Item = styled.div`
    margin: 6px 0;
`

const Actions = styled.div`
    display: flex;
    margin-top: 12px;
`

const Action = styled.div`
    margin-right: 6px;
`

const propTypes = {
    applyFilter: PropTypes.func.isRequired,
    closeDropdown: PropTypes.func,
    name: PropTypes.string.isRequired,
    removeFilter: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
}

const defaultProps = {
    closeDropdown: null,
}

const fromString = (string) => {
    if (string === 'null') return null
    if (string === 'true') return true
    if (string === 'false') return false
    if (Number(string) > 0 || Number(string) === 0) return Number(string)
    return string
}

const toString = (string) => {
    if (string === true) return 'true'
    if (string === false) return 'false'
    return String(string)
}

const FilterItemDropdownRadios = (props) => {
    const { name, values, applyFilter, removeFilter, closeDropdown } = props

    const [localValue, setLocalValue] = useState(null)

    useEffect(() => {
        const value = _get(
            values.find((o) => o.checked),
            ['value'],
            null
        )

        setLocalValue(toString(value))
    }, [values])

    const handleSubmit = (e) => {
        e.preventDefault()

        const filter = {
            [name]: fromString(localValue),
        }

        applyFilter(filter)

        if (closeDropdown) closeDropdown()
    }

    const handleChange = ({ value }) => {
        setLocalValue(value)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {values.map(({ label, value }) => (
                <Item key={value}>
                    <Radio
                        isSmall
                        name={name}
                        value={toString(value)}
                        label={label}
                        checked={toString(value) === localValue}
                        onChange={handleChange}
                    />
                </Item>
            ))}

            <Actions>
                <Action>
                    <Button type="submit" size="small">
                        Apply
                    </Button>
                </Action>
                <Action>
                    <Button isSecondary size="small" onClick={removeFilter}>
                        Remove
                    </Button>
                </Action>
            </Actions>
        </Form>
    )
}

FilterItemDropdownRadios.propTypes = propTypes

FilterItemDropdownRadios.defaultProps = defaultProps

export default FilterItemDropdownRadios
