export const REQUEST_SEARCH_RESULTS = 'REQUEST_SEARCH_RESULTS'
export const DOWNLOAD_SEARCH_RESULTS = 'DOWNLOAD_SEARCH_RESULTS'
export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS'
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS'

export const requestSearchResults = (data) => ({ type: REQUEST_SEARCH_RESULTS, data })

export const receiveSearchResults = (data, meta) => ({ type: RECEIVE_SEARCH_RESULTS, data, meta })

export const resetSearchResults = () => ({ type: RESET_SEARCH_RESULTS })

export const downloadSearchResults = (data) => ({ type: DOWNLOAD_SEARCH_RESULTS, data })
