import { useState } from 'react'

import {
    colors,
    font,
    ConfirmationModal,
    Container,
    File,
    Flexer,
    Line,
    Paragraph,
    Skeleton,
    SkeletonLine,
    Spacer,
} from '@scouts/ui'

import { TaxReliefClaimFileDTO } from '@/models'
import {
    useDeleteTaxReliefClaimFile,
    useDownloadTaxReliefClaimFile,
    useDownloadTaxReliefClaimFilesZip,
    useTaxReliefClaimFiles,
} from '@/store/tax-relief-claims'

export const TaxReliefClaimCustomerFiles = ({
    taxReliefClaimId,
}: {
    taxReliefClaimId: TaxReliefClaimFileDTO['id']
}) => {
    const { taxReliefClaimCustomerFiles, hasTaxReliefClaimCustomerFiles, isLoading } =
        useTaxReliefClaimFiles(taxReliefClaimId)
    const { downloadTaxReliefClaimFile } = useDownloadTaxReliefClaimFile()
    const { downloadTaxReliefClaimFilesZip } = useDownloadTaxReliefClaimFilesZip()
    const { deleteTaxReliefClaimFile } = useDeleteTaxReliefClaimFile()
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
    const [deletableFileId, setDeletableFileId] = useState<number | null>(null)

    const handleFileDownload = (taxReliefClaimFileId: TaxReliefClaimFileDTO['id']) => {
        downloadTaxReliefClaimFile({ taxReliefClaimId, taxReliefClaimFileId })
    }

    const handleZipDownload = () => {
        downloadTaxReliefClaimFilesZip({ taxReliefClaimId })
    }

    const confirmDeleteFile = (taxReliefClaimFileId: TaxReliefClaimFileDTO['id']) => {
        setDeletableFileId(taxReliefClaimFileId)
        setIsDeleteConfirmationModalOpen(true)
    }

    const handleCloseModal = () => {
        setDeletableFileId(null)
        setIsDeleteConfirmationModalOpen(false)
    }

    const handleDeleteFile = () => {
        if (deletableFileId)
            deleteTaxReliefClaimFile({ taxReliefClaimId, taxReliefClaimFileId: deletableFileId }).then(() =>
                handleCloseModal()
            )
    }

    if (isLoading)
        return (
            <Skeleton gap="12px">
                <SkeletonLine width="60%" height="21px" />
                <SkeletonLine height="30px" />
            </Skeleton>
        )

    return (
        <>
            <Container>
                <Spacer marginBottom="18px">
                    <Line weight={font.weight.medium} size={font.small}>
                        Uploaded files
                    </Line>
                </Spacer>
                {hasTaxReliefClaimCustomerFiles ? (
                    <Flexer alignStart column gap="6px">
                        <File background={colors.blueLighter} onClick={handleZipDownload}>
                            Download all
                        </File>
                        {taxReliefClaimCustomerFiles?.map((file: TaxReliefClaimFileDTO) => (
                            <Flexer gap="24px" key={file.id} width="100%" alignCenter>
                                <File
                                    background={colors.blueLighter}
                                    onClick={() => handleFileDownload(file.id)}
                                    onDelete={() => confirmDeleteFile(file.id)}
                                >
                                    {file.fileName}
                                </File>

                                {file.isClaimForPreviousYear && (
                                    <Line size={font.small}>
                                        ✓ Instead of the investment year, claim for the year before
                                    </Line>
                                )}
                            </Flexer>
                        ))}
                    </Flexer>
                ) : (
                    <Paragraph>No files uploaded</Paragraph>
                )}
            </Container>
            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal onCancel={handleCloseModal} onConfirm={handleDeleteFile}>
                    Are you sure you want to delete this file?
                </ConfirmationModal>
            )}
        </>
    )
}
