import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { colors, font, media, Button, Container } from '@scouts/ui'

import {
    AGENT_AUTH_STATUS_SUBMITTED,
    CURRENT_TAX_YEAR,
    PAYMENT_STATUS_REFUNDED,
    REFUND_REASON_MISTAKE,
    SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
    SEARCH_RESULT_TYPE_TAX_CONSULTATIONS,
    SEARCH_RESULT_TYPE_USERS,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    TAX_CONSULTATION_STATUS_BOOKED,
} from '@/constants'
import { clickedOnScrollbar, formatDateYYYYMMDD, isAdmin } from '@/helpers'
import { TaxRegistrationStatus } from '@/models'

import { FilterDropdown } from '../ui/filter'

const DropdownContainer = styled.div`
    position: relative;
`

const Item = styled.button`
    ${({ isActive }) =>
        isActive &&
        `
        color: ${colors.neutralDarker};
        pointer-events: none;
        &:after {
            content: '\\002713';
            margin-left: 6px;
        }
    `}

    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: left;
    display: block;
    width: 100%;
    padding: 9px 0;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }

    &:first-child {
        margin-top: -9px;
    }

    &:last-child {
        margin-bottom: -9px;
    }
`

const propTypes = {
    activeFilters: PropTypes.array.isRequired,
    applyFilter: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

const SearchFilterAdd = (props) => {
    const { applyFilter, activeFilters, user } = props

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const openDropdown = () => {
        setIsDropdownOpen(true)
    }

    const closeDropdown = () => {
        setIsDropdownOpen(false)
    }

    const closeDropdownOnClick = (e) => {
        if (!clickedOnScrollbar(e.pageX)) {
            closeDropdown()
        }
    }

    const ref = useRef()
    useOnClickOutside(ref, closeDropdownOnClick)

    const addFilter = (filter) => {
        applyFilter(filter)
        closeDropdown()
    }

    const today = formatDateYYYYMMDD()

    const filters = [
        {
            isActive: activeFilters.includes('resultType'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_USERS,
            },
            label: 'Result type',
        },
        {
            isActive: activeFilters.includes('accountantId'),
            defaultFilter: {
                accountantId: null,
            },
            label: 'Accountant',
            shouldHide: !isAdmin(user),
        },
        {
            isActive: activeFilters.includes('customerName'),
            defaultFilter: {
                customerFirstName: '',
                customerLastName: '',
            },
            label: 'Client name',
        },
        {
            isActive: activeFilters.includes('customerEmail'),
            defaultFilter: {
                customerEmail: '',
            },
            label: 'Client email',
        },
        {
            isActive: activeFilters.includes('selfAssessmentStatuses'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentStatuses: [SELF_ASSESSMENT_STATUS_SUBMITTED],
            },
            label: 'Tax return status',
        },
        {
            isActive: activeFilters.includes('onHold'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                onHold: true,
            },
            label: 'On hold',
        },
        {
            isActive: activeFilters.includes('selfAssessmentTaxYears'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentTaxYears: [CURRENT_TAX_YEAR],
            },
            label: 'Tax year',
        },
        {
            isActive: activeFilters.includes('selfAssessmentPayAfterFiling'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentPayAfterFiling: true,
            },
            label: 'Pay type',
        },
        {
            isActive: activeFilters.includes('referralSource'),
            defaultFilter: {
                referralSource: user.referralCode || '',
            },
            label: 'Referral code',
        },
        {
            isActive: activeFilters.includes('onHold'),
            defaultFilter: {
                missingUtr: true,
            },
            label: 'UTR status',
        },
        {
            isActive: activeFilters.includes('taxRegistrationStatuses'),
            defaultFilter: {
                taxRegistrationStatuses: [TaxRegistrationStatus.Submitted],
            },
            label: 'UTR registration status',
        },
        {
            isActive: activeFilters.includes('agentAuthorisationStatuses'),
            defaultFilter: {
                agentAuthorisationStatuses: [AGENT_AUTH_STATUS_SUBMITTED],
            },
            label: 'Agent authorisation status',
        },
        {
            isActive: activeFilters.includes('signedUpDate'),
            defaultFilter: {
                signedUpDateStart: today,
                signedUpDateEnd: today,
            },
            label: 'Signed up date',
        },
        {
            isActive: activeFilters.includes('paymentDate'),
            defaultFilter: {
                paymentDateStart: today,
                paymentDateEnd: today,
            },
            label: 'Payment date',
        },
        {
            isActive: activeFilters.includes('filedDate'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                filedDateStart: today,
                filedDateEnd: today,
            },
            label: 'Filed date',
        },
        {
            isActive: activeFilters.includes('submittedDate'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                submittedDateStart: today,
                submittedDateEnd: today,
            },
            label: 'Submitted date',
        },
        {
            isActive: activeFilters.includes('selfAssessmentIncomeSources'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentIncomeSources: [],
            },
            label: 'Income sources',
        },
        {
            isActive: activeFilters.includes('selfAssessmentApplicableAllowances'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_SELF_ASSESSMENTS,
                selfAssessmentApplicableAllowances: [],
            },
            label: 'Allowances',
        },
        {
            isActive: activeFilters.includes('paymentStatus'),
            defaultFilter: {
                paymentStatus: PAYMENT_STATUS_REFUNDED,
            },
            label: 'Payment status',
        },
        {
            isActive: activeFilters.includes('refundReasonType'),
            defaultFilter: {
                refundReasonType: REFUND_REASON_MISTAKE,
            },
            label: 'Refund reason',
        },
        {
            isActive: activeFilters.includes('taxRegistrationPaymentStatus'),
            defaultFilter: {
                taxRegistrationPaymentStatus: PAYMENT_STATUS_REFUNDED,
            },
            label: 'UTR registration payment status',
        },
        {
            isActive: activeFilters.includes('taxRegistrationRefundReasonType'),
            defaultFilter: {
                taxRegistrationRefundReasonType: REFUND_REASON_MISTAKE,
            },
            label: 'UTR registration refund reason',
        },
        {
            isActive: activeFilters.includes('taxConsultationStatuses'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_TAX_CONSULTATIONS,
                taxConsultationStatuses: [TAX_CONSULTATION_STATUS_BOOKED],
            },
            label: 'Consultation status',
        },
        {
            isActive: activeFilters.includes('taxConsultationCompletedDate'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_TAX_CONSULTATIONS,
                taxConsultationCompletedDateStart: today,
                taxConsultationCompletedDateEnd: today,
            },
            label: 'Consultation completed date',
        },
        {
            isActive: activeFilters.includes('taxConsultationTopics'),
            defaultFilter: {
                resultType: SEARCH_RESULT_TYPE_TAX_CONSULTATIONS,
                taxConsultationTopics: [],
            },
            label: 'Consultation topics',
        },
    ].filter((o) => !o.shouldHide)

    return (
        <Container>
            <Button isSecondary size="small" onClick={openDropdown}>
                Add filter
            </Button>

            <DropdownContainer ref={ref}>
                {isDropdownOpen && (
                    <FilterDropdown>
                        {filters.map(({ isActive, defaultFilter, label }) => (
                            <Item key={label} isActive={isActive} onClick={() => addFilter(defaultFilter)}>
                                {label}
                            </Item>
                        ))}
                    </FilterDropdown>
                )}
            </DropdownContainer>
        </Container>
    )
}

SearchFilterAdd.propTypes = propTypes

const mapState = ({ user }) => ({ user })

export default connect(mapState)(SearchFilterAdd)
