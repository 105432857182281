import { getCompanyNameNumberedLabel } from '@/domain'
import { CustomerDTO } from '@/models'
import { useCompanies } from '@/store/companies/hooks'

import { useCompanyIdFromPath } from './use-company-id-from-path'

export const useCustomersCompany = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const companyIdFromPath = useCompanyIdFromPath()
    const { companies, isLoading } = useCompanies({ customerId })

    const company = (() => {
        if (!companies) return undefined
        if (!companyIdFromPath) return companies[0]
        return companies.find((company) => company.id === companyIdFromPath)
    })()

    const companyNameLabel = getCompanyNameNumberedLabel(company, companies)

    return {
        company,
        companyNameLabel,
        isLoading,
    }
}
