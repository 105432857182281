import { createSelector } from '@reduxjs/toolkit'
import _orderBy from 'lodash/orderBy'

import {
    SELF_ASSESSMENT_STATUS_CANCELLED,
    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
    SELF_ASSESSMENT_STATUS_FILED,
    TAX_CONSULTATION_STATUS_CANCELLED,
    TAX_CONSULTATION_STATUS_COMPLETED,
} from '../constants'

// Self Assessments

export const createCustomerActiveSelfAssessments = (getCustomerSelfAssessments) =>
    createSelector([getCustomerSelfAssessments], (customerSelfAssessments) => {
        const customerActiveSelfAssessments = customerSelfAssessments.filter(
            (o) =>
                (!!o.hasPaid || !!o.payAfterFiling) &&
                !!o.status &&
                ![
                    SELF_ASSESSMENT_STATUS_FILED,
                    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
                    SELF_ASSESSMENT_STATUS_CANCELLED,
                ].includes(o.status)
        )

        return _orderBy(customerActiveSelfAssessments, 'taxYear', 'desc')
    })

export const createCustomerCancelledSelfAssessments = (getCustomerSelfAssessments) =>
    createSelector([getCustomerSelfAssessments], (customerSelfAssessments) => {
        const customerCancelledSelfAssessments = customerSelfAssessments.filter(
            (o) => (!!o.hasPaid || !!o.payAfterFiling) && !!o.status && o.status === SELF_ASSESSMENT_STATUS_CANCELLED
        )

        return _orderBy(customerCancelledSelfAssessments, 'taxYear', 'desc')
    })

export const createCustomerCompletedSelfAssessments = (getCustomerSelfAssessments) =>
    createSelector([getCustomerSelfAssessments], (customerSelfAssessments) => {
        const customerCompletedSelfAssessments = customerSelfAssessments.filter(
            (o) =>
                (!!o.hasPaid || !!o.payAfterFiling) &&
                !!o.status &&
                [SELF_ASSESSMENT_STATUS_FILED, SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED].includes(o.status)
        )
        return _orderBy(customerCompletedSelfAssessments, 'taxYear', 'desc')
    })

// Tax consultations

export const createCustomerActiveTaxConsultations = (getCustomerTaxConsultations) =>
    createSelector([getCustomerTaxConsultations], (customerTaxConsultations) => {
        const customerActiveTaxConsultations = customerTaxConsultations.filter(
            (o) =>
                !!o.hasPaid &&
                ![TAX_CONSULTATION_STATUS_COMPLETED, TAX_CONSULTATION_STATUS_CANCELLED].includes(o.status)
        )

        return _orderBy(customerActiveTaxConsultations, ['id'], ['desc'])
    })

export const createCustomerCancelledTaxConsultations = (getCustomerTaxConsultations) =>
    createSelector([getCustomerTaxConsultations], (customerTaxConsultations) => {
        const customerCancelledTaxConsultations = customerTaxConsultations.filter(
            (o) => !!o.hasPaid && o.status === TAX_CONSULTATION_STATUS_CANCELLED
        )

        return _orderBy(customerCancelledTaxConsultations, ['id'], ['desc'])
    })

export const createCustomerCompletedTaxConsultations = (getCustomerTaxConsultations) =>
    createSelector([getCustomerTaxConsultations], (customerTaxConsultations) => {
        const customerCompletedTaxConsultations = customerTaxConsultations.filter(
            (o) => !!o.hasPaid && o.status === TAX_CONSULTATION_STATUS_COMPLETED
        )

        return _orderBy(customerCompletedTaxConsultations, ['id'], ['desc'])
    })

// Product bundles

// Active when paid and at least one service is in progress (either TC or SA)
export const createCustomerActiveProductBundles = (
    getCustomerProductBundles,
    getCustomerActiveTaxConsultations,
    getCustomerActiveSelfAssessments
) =>
    createSelector(
        [getCustomerProductBundles, getCustomerActiveTaxConsultations, getCustomerActiveSelfAssessments],
        (customerProductBundles, customerActiveTaxConsultations, customerActiveSelfAssessments) => {
            const customerActiveProductBundles = customerProductBundles.filter(
                (bundle) =>
                    !!bundle.hasPaid &&
                    (customerActiveTaxConsultations.some((tc) => tc.id === bundle.taxConsultationId) ||
                        customerActiveSelfAssessments.some((sa) => sa.id === bundle.selfAssessmentId))
            )

            return _orderBy(customerActiveProductBundles, ['id'], ['desc'])
        }
    )
