export const REQUEST_QUICK_SEARCH_RESULTS = 'REQUEST_QUICK_SEARCH_RESULTS'
export const REQUEST_QUICK_SEARCH_RECENT_CUSTOMERS = 'REQUEST_QUICK_SEARCH_RECENT_CUSTOMERS'
export const RECEIVE_QUICK_SEARCH_RESULTS = 'RECEIVE_QUICK_SEARCH_RESULTS'
export const RESET_QUICK_SEARCH = 'RESET_QUICK_SEARCH'

export const requestQuickSearchResults = ({ filter }, meta = {}) => ({
    type: REQUEST_QUICK_SEARCH_RESULTS,
    data: { filter },
    meta,
})

export const requestQuickSearchRecentCustomers = ({ customerIds }) => ({
    type: REQUEST_QUICK_SEARCH_RECENT_CUSTOMERS,
    data: { customerIds },
})

export const receiveQuickSearchResults = (data) => ({ type: RECEIVE_QUICK_SEARCH_RESULTS, data })

export const resetQuickSearch = () => ({ type: RESET_QUICK_SEARCH })
