import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import {
    receiveQuickSearchResults,
    REQUEST_QUICK_SEARCH_RECENT_CUSTOMERS,
    REQUEST_QUICK_SEARCH_RESULTS,
} from '../actions/quick-search'
import { handleError } from '../handlers'
import { apiCall, apiCallCancelable, isCancelledApiCall } from '../helpers'

export default function* searchSagas() {
    yield takeLatest(REQUEST_QUICK_SEARCH_RESULTS, fetchQuickSearchResults)
    yield takeLatest(REQUEST_QUICK_SEARCH_RECENT_CUSTOMERS, fetchQuickSearchRecentCustomers)
}

function* fetchQuickSearchResults({ data, meta }) {
    const filter = data?.filter || ''
    const cancelToken = meta?.cancelToken

    try {
        yield put(showLoading())

        const url = `customers/textsearch?filter=${encodeURIComponent(filter)}`
        const response = yield apiCallCancelable({ url, cancelToken })
        const items = Array.isArray(response.data) ? _orderBy(response.data, ['fullName']) : []

        yield put(receiveQuickSearchResults({ items, filter }))
    } catch (e) {
        if (isCancelledApiCall(e)) {
            // Ignore
        } else {
            yield put(handleError(e))
            yield put(receiveQuickSearchResults({ items: [], filter }))
        }
    } finally {
        yield put(hideLoading())
    }
}

function* fetchQuickSearchRecentCustomers({ data }) {
    const customerIds = data?.customerIds || []

    try {
        yield put(showLoading())

        const urlComponents = customerIds
            .filter((x) => !!x)
            .map((id, i) => `customerIds[${i}]=${id}`)
            .join('&')

        const response = yield apiCall(`customers/idsearch?${urlComponents}`)
        const items = []

        if (Array.isArray(response.data)) {
            customerIds.forEach((id) => {
                const customer = _find(response.data, { id })
                if (customer) {
                    items.push(customer)
                }
            })
        }

        yield put(receiveQuickSearchResults({ items }))
    } catch (e) {
        yield put(handleError(e))

        yield put(receiveQuickSearchResults({ items: [] }))
    } finally {
        yield put(hideLoading())
    }
}
