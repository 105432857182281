import { generateApiPath } from '@scouts/helpers'

import { ERROR_MESSAGE_DOWNLOADING } from '@/constants'
import { CompanyDTO, CompanyReturnDTO, CompanyReturnFileDTO, CustomerDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { ResponseTransformers } from '../error-handling/responseTransformers'
import { Tags } from '../tags'

export interface GetCompanyReturnFilesParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
}

export interface UploadCompanyReturnFileParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
    fileType: CompanyReturnFileDTO['fileType']
}

export interface DeleteCompanyReturnFileParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
    fileId: CompanyReturnFileDTO['id']
}

export interface DownloadCompanyReturnFileParams {
    customerId: CustomerDTO['id']
    companyId: CompanyDTO['id']
    companyReturnId: CompanyReturnDTO['id']
    fileId: CompanyReturnFileDTO['id']
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCompanyReturnFiles: builder.query<CompanyReturnFileDTO[], GetCompanyReturnFilesParams>({
            query: ({ customerId, companyId, companyReturnId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURN_FILES.GET.ALL, {
                    customerId,
                    companyId,
                    companyReturnId,
                }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.CompanyReturnFiles, id: arg.companyReturnId }],
        }),
        uploadCompanyReturnFile: builder.mutation<void, { file: File; params: UploadCompanyReturnFileParams }>({
            query: ({ file, params }) => {
                const { fileType, companyId, companyReturnId, customerId } = params
                const formData = new FormData()
                formData.append('file', file)

                return {
                    method: 'POST',
                    url: generateApiPath(
                        ENDPOINTS.COMPANY_RETURN_FILES.POST,
                        { companyId, companyReturnId, customerId },
                        { queryParameters: { fileType } }
                    ),
                    body: formData,
                    formData: true,
                }
            },
            invalidatesTags: [Tags.CompanyReturnFiles],
        }),
        deleteCompanyReturnFile: builder.mutation<void, DeleteCompanyReturnFileParams>({
            query: ({ companyId, customerId, companyReturnId, fileId }) => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURN_FILES.DELETE, {
                    companyId,
                    customerId,
                    companyReturnId,
                    fileId,
                }),
            }),
            invalidatesTags: [Tags.CompanyReturnFiles],
        }),
        downloadCompanyReturnFile: builder.query<string, DownloadCompanyReturnFileParams>({
            query: ({ companyId, customerId, companyReturnId, fileId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.COMPANY_RETURN_FILES.GET.DOWNLOAD_FILE, {
                    companyId,
                    customerId,
                    companyReturnId,
                    fileId,
                }),
                responseHandler: 'text',
            }),
            transformErrorResponse: ResponseTransformers.customMessage(ERROR_MESSAGE_DOWNLOADING),
        }),
    }),
})

export const {
    useGetCompanyReturnFilesQuery,
    useUploadCompanyReturnFileMutation,
    useDeleteCompanyReturnFileMutation,
    useLazyDownloadCompanyReturnFileQuery,
} = extendedApi
