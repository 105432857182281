import type { AnyAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'

export type SentryErrorData = {
    endpointName: string
    method: string
    status: number
    params: Record<string, unknown>
    responsePayload: Record<string, unknown>
    aborted: boolean
}

/**
 * Report API errors from RTK-Query to Sentry.
 *
 * Avoid using this function directly. This is used by Redux middleware that has access to the rejected action, and can pluck relevant data from it.
 */
export const reportRejectedApiCallToSentry = (action: AnyAction) => {
    try {
        const { payload, meta } = action
        const { request, response } = meta?.baseQueryMeta || {}

        const endpointName = meta?.arg?.endpointName // "uploadCompanyReturnFile" -> name of the action, created with .injectEndpoints
        const params = meta?.arg?.originalArgs?.params // All params: queryParams, json body, etc.
        const method = request?.method // POST
        const statusCode = response?.status // 400
        const aborted = meta?.aborted // false

        const errorData = {
            endpointName,
            method,
            status: statusCode,
            params,
            responsePayload: payload,
            aborted,
        }

        const isBadRequest = statusCode === 400
        const isServerError5XX = String(statusCode)[0] === '5'
        const isNetworkError = statusCode === 0 || !statusCode

        const shouldReportToSentry = (isBadRequest || isServerError5XX) && !isNetworkError
        if (shouldReportToSentry) {
            reportApiCallToSentry(errorData)
        }
    } catch (exception) {
        reportExceptionToSentry(exception)
    }
}

const reportApiCallToSentry = (error: SentryErrorData) => {
    Sentry.withScope((scope) => {
        const { endpointName, status, method, params, responsePayload, aborted } = error

        scope.setContext('apiCall', {
            requestData: JSON.stringify({ status, method, params }),
            responseData: JSON.stringify(responsePayload),
            aborted,
        })

        Sentry.captureMessage(`${method} ${endpointName || 'Unknown endpoint'} [${status || ''}]`, {
            level: 'info',
            fingerprint: ['{{ default }}', String(status), String(method), String(endpointName)],
            extra: {
                status,
                method,
                endpointName,
            },
        })
    })
}

const reportExceptionToSentry = (error: unknown) => {
    Sentry.captureException(error)
}
