import { font, Container, Flexer, Line, Spacer } from '@scouts/ui'

import { downloadKYCReport } from '@/actions'
import { AccountantDetailsDTO } from '@/models'
import { useAppDispatch } from '@/store'

import { useReportCardDates } from '../hooks/use-report-card-dates'

import { ReportCard } from './ReportCard'

export const DashboardDownloadsKyc = ({ accountantId }: { accountantId: AccountantDetailsDTO['id'] }) => {
    const dispatch = useAppDispatch()

    const { allTimeMonth, currentMonth } = useReportCardDates()
    const kycReportCardDates = [currentMonth, allTimeMonth]

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        KYC reports
                    </Line>
                </Flexer>
            </Spacer>

            <ReportCard
                dates={kycReportCardDates}
                onReportClick={({ month, year }) => dispatch(downloadKYCReport({ accountantId, year, month }))}
            />
        </Container>
    )
}
