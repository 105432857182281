import _bindAll from 'lodash/bindAll'
import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { colors, ButtonLink, Notice } from '@scouts/ui'

import { requestAccountants, requestSelfAssessments, requestSelfAssessmentsStatistics } from '../../actions'
import {
    PAGINATION_DEFAULT_PAGE,
    PATH_SELF_ASSESSMENTS,
    SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_FILTER_ALL,
    SELF_ASSESSMENT_STATUS_FILTER_ASSIGNED,
    SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_FILTER_HOLD,
    SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED,
    SELF_ASSESSMENT_STATUS_FILTERS,
    SELF_ASSESSMENT_STATUS_OVERDUE_SUBMITTED,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    SELF_ASSESSMENT_STATUS_TITLES,
} from '../../constants'
import { createAxiosCancelSource, isAdmin, isDateDue } from '../../helpers'
import { getAccountantsWithFullNameAndStatus } from '../../selectors'
import BulkActions from '../BulkActions'
import PaginationContainer from '../PaginationContainer'
import SelfAssessmentsAccountantFilters from './SelfAssessmentsAccountantFilters'
import SelfAssessmentsFilters from './SelfAssessmentsFilters'
import SelfAssessmentsSupportFilters from './SelfAssessmentsSupportFilters'
import SelfAssessmentsTable from './SelfAssessmentsTable'

const Container = styled.div`
    padding: 24px 24px 192px;
`

const FilterContainer = styled.div`
    margin-bottom: 12px;

    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const PaginationContainerWrap = styled.div`
    margin-top: 24px;
`

const BulkActionsContainer = styled.div`
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 9000;
    width: calc(100% - 36px);
    padding: 18px;
    background: ${colors.blueLighter};
`

class SelfAssessments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterAccountant: null,
            filterStatus: null,
            filterIncludeUnpaid: null,
            filterDaysOverdue: null,
            page: null,
            pageSize: null,
            selectedItems: [],
        }

        this.requestSelfAssessmentsCancelSource = null

        _bindAll(this, [
            'deselectAll',
            'handleAccountantChange',
            'handleDaysOverdueChange',
            'selectAll',
            'toggleSelection',
            'triggerTableUpdate',
            'toggleUnpaid',
        ])
    }

    componentDidMount() {
        const { dispatch, location, history } = this.props

        const { filterStatus, filterAccountant } = this.parseFilterAndPaginationFromUrl(location)

        if (!filterStatus) {
            const query = { status: SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED }

            if (filterAccountant) {
                query.accountantId = filterAccountant
            }

            history.replace({
                pathname: PATH_SELF_ASSESSMENTS,
                search: queryString.stringify(query),
            })
        } else {
            this.update()
        }

        dispatch(requestAccountants())
    }

    componentDidUpdate() {
        this.update()
    }

    componentWillUnmount() {
        if (this.requestSelfAssessmentsCancelSource) {
            this.requestSelfAssessmentsCancelSource.cancel()
        }
    }

    handleAccountantChange({ value: accountantId }) {
        const { history, location } = this.props

        const { filterStatus, filterIncludeUnpaid, filterDaysOverdue } = this.parseFilterAndPaginationFromUrl(location)
        const query = { status: filterStatus, includeUnpaid: filterIncludeUnpaid, daysOverdue: filterDaysOverdue }

        if (accountantId) {
            query.accountantId = accountantId
        }

        history.push({
            pathname: PATH_SELF_ASSESSMENTS,
            search: queryString.stringify(query),
        })
    }

    handleDaysOverdueChange() {
        const { history } = this.props
        const { filterAccountant, filterIncludeUnpaid } = this.state

        history.push({
            pathname: PATH_SELF_ASSESSMENTS,
            search: queryString.stringify({
                status: SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED,
                ...(filterAccountant && { accountantId: filterAccountant }),
                ...(filterIncludeUnpaid && { includeUnpaid: filterIncludeUnpaid }),
                ...{ daysOverdue: this.getDaysSinceSubmittedThreshold() },
            }),
        })

        this.update()
    }

    getDaysSinceSubmittedThreshold() {
        const { isAdminUser, location } = this.props

        const { filterDaysOverdue } = this.parseFilterAndPaginationFromUrl(location)

        if (filterDaysOverdue) return filterDaysOverdue

        return isAdminUser ? 5 : 2
    }

    update() {
        const { dispatch, location } = this.props
        const {
            filterAccountant: prevFilterAccountant,
            filterStatus: prevFilterStatus,
            filterIncludeUnpaid: prevFilterIncludeUnpaid,
            filterDaysOverdue: prevDaysOverdue,
            page: prevPage,
            pageSize: prevPageSize,
        } = this.state

        const { filterStatus, filterAccountant, filterIncludeUnpaid, filterDaysOverdue, page, pageSize } =
            this.parseFilterAndPaginationFromUrl(location)

        const hasUpdated =
            prevFilterStatus !== filterStatus ||
            prevFilterAccountant !== filterAccountant ||
            prevFilterIncludeUnpaid !== filterIncludeUnpaid ||
            prevDaysOverdue !== filterDaysOverdue ||
            page !== prevPage ||
            pageSize !== prevPageSize

        if (hasUpdated) {
            this.fetch({
                status: filterStatus,
                accountantId: filterAccountant,
                daysOverdue: filterDaysOverdue,
                includeUnpaid: filterIncludeUnpaid,
                page,
                pageSize,
            })
            this.deselectAll()

            this.setState({ filterStatus, filterAccountant, filterIncludeUnpaid, filterDaysOverdue, page, pageSize })
        }

        if (
            prevFilterAccountant !== filterAccountant ||
            prevFilterIncludeUnpaid !== filterIncludeUnpaid ||
            prevDaysOverdue !== filterDaysOverdue
        ) {
            dispatch(
                requestSelfAssessmentsStatistics({
                    accountantId: filterAccountant,
                    daysOverdue: this.getDaysSinceSubmittedThreshold(),
                    includeUnpaid: filterIncludeUnpaid,
                    returnOnHold: false,
                })
            )
        }
    }

    triggerTableUpdate() {
        const { location } = this.props
        const { filterStatus, filterAccountant, filterIncludeUnpaid, filterDaysOverdue, page, pageSize } =
            this.parseFilterAndPaginationFromUrl(location)

        this.fetch({
            status: filterStatus,
            accountantId: filterAccountant,
            daysOverdue: filterDaysOverdue,
            includeUnpaid: filterIncludeUnpaid,
            page,
            pageSize,
        })
    }

    parseFilterAndPaginationFromUrl(location) {
        const { search } = location

        const query = queryString.parse(search)

        const filterStatus = _get(query, ['status'], '')
        const filterAccountant = _get(query, ['accountantId'], '')
        const filterIncludeUnpaid = _get(query, ['includeUnpaid'])
        const filterDaysOverdue = _get(query, ['daysOverdue'])

        const page = Number(_get(query, ['page'], PAGINATION_DEFAULT_PAGE))

        const pageSize = Number(
            _get(
                query,
                ['pageSize'],
                filterStatus === SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED ||
                    filterStatus === SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND
                    ? 100
                    : 25
            )
        )

        return { filterStatus, filterAccountant, filterIncludeUnpaid, filterDaysOverdue, page, pageSize }
    }

    fetch({ status, accountantId, includeUnpaid, daysOverdue, page, pageSize }) {
        if (daysOverdue > 0) {
            const statuses = SELF_ASSESSMENT_STATUS_FILTERS[SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED]
            this.fetchSelfAssessments({
                statuses,
                accountantId,
                includeUnpaid,
                daysOverdue,
                page,
                pageSize,
            })

            return
        }

        if (status === SELF_ASSESSMENT_STATUS_FILTER_ALL || !status) {
            this.fetchSelfAssessments({ accountantId, includeUnpaid, page, pageSize, returnOnHold: null })
        } else if (status === SELF_ASSESSMENT_STATUS_FILTER_HOLD) {
            this.fetchSelfAssessments({ accountantId, includeUnpaid, page, pageSize, returnOnHold: true })
        } else if (SELF_ASSESSMENT_STATUS_FILTERS[status]) {
            const statuses = SELF_ASSESSMENT_STATUS_FILTERS[status]
            this.fetchSelfAssessments({ statuses, accountantId, includeUnpaid, page, pageSize })
        }
    }

    fetchSelfAssessments(data) {
        const { dispatch } = this.props

        this.requestSelfAssessmentsCancelSource = createAxiosCancelSource()
        const meta = { cancelToken: this.requestSelfAssessmentsCancelSource?.token }
        dispatch(requestSelfAssessments(data, meta))
    }

    toggleSelection({
        customerId,
        fullName,
        selfAssessmentId,
        status,
        unsubscribeFromBulkEmails,
        customerPhoneNumber,
    }) {
        const { selectedItems } = this.state

        const isSelected =
            selectedItems.findIndex((o) => o.customerId === customerId && o.selfAssessmentId === selfAssessmentId) > -1

        let updatedSelectedItems = [...selectedItems]

        if (isSelected) {
            updatedSelectedItems = selectedItems.filter(
                (o) => !(o.customerId === customerId && o.selfAssessmentId === selfAssessmentId)
            )
        } else {
            updatedSelectedItems.push({
                customerId,
                fullName,
                selfAssessmentId,
                status,
                unsubscribeFromBulkEmails,
                phoneNumber: customerPhoneNumber,
            })
        }

        this.setState({
            selectedItems: updatedSelectedItems,
        })
    }

    selectAll() {
        const { selfAssessments } = this.props

        const selectedItems = []

        selfAssessments.forEach(
            ({ customerId, customerFullName, id, customerUnsubscribeFromBulkEmails, status, customerPhoneNumber }) => {
                if (selectedItems.findIndex((o) => o.customerId === customerId && o.selfAssessmentId === id) > -1)
                    return

                selectedItems.push({
                    customerId,
                    fullName: customerFullName,
                    selfAssessmentId: id,
                    status: status || null,
                    unsubscribeFromBulkEmails: customerUnsubscribeFromBulkEmails,
                    phoneNumber: customerPhoneNumber || null,
                })
            }
        )

        this.setState({ selectedItems })
    }

    deselectAll() {
        this.setState({ selectedItems: [] })
    }

    toggleUnpaid() {
        const { history } = this.props
        const { filterStatus, filterAccountant, filterIncludeUnpaid, filterDaysOverdue } = this.state

        history.push({
            pathname: PATH_SELF_ASSESSMENTS,
            search: queryString.stringify({
                status: filterStatus,
                ...(filterAccountant && { accountantId: filterAccountant }),
                ...(filterDaysOverdue && { daysOverdue: filterDaysOverdue }),
                ...(filterIncludeUnpaid === 'true' ? { includeUnpaid: 'false' } : { includeUnpaid: 'true' }),
            }),
        })
    }

    render() {
        const {
            accountantsWithFullName,
            isAdminUser,
            isFetching,
            selfAssessments,
            user,
            selfAssessmentStatistics,
            pagination,
            location,
            history,
        } = this.props

        const { filterAccountant, filterStatus, filterIncludeUnpaid, selectedItems } = this.state

        const { filterDaysOverdue } = this.parseFilterAndPaginationFromUrl(location)

        const hasSelfAssessments = selfAssessments.length > 0
        const hasSelectedItems = selectedItems.length > 0
        const hasLoadedAccountantDropdown = accountantsWithFullName.length > 0

        const shouldDisplayAssignedNotice = filterStatus === SELF_ASSESSMENT_STATUS_FILTER_ASSIGNED
        const daysSinceSubmittedThreshold = this.getDaysSinceSubmittedThreshold()

        const numberOfClientsSubmittedOverdue = _get(
            _find(selfAssessmentStatistics, { status: SELF_ASSESSMENT_STATUS_OVERDUE_SUBMITTED }),
            ['count'],
            0
        )

        let numberOfClientsAwaitingOverdue = 0
        let shouldDisplayAwaitingOverdueNotice = false
        let shouldDisplaySubmittedOverdueNotice = false
        let shouldDisplaySubmittedOverdueFilterMessage = false

        if (hasSelfAssessments && filterStatus === SELF_ASSESSMENT_STATUS_FILTER_SUBMITTED) {
            shouldDisplaySubmittedOverdueFilterMessage =
                filterDaysOverdue > 0 && numberOfClientsSubmittedOverdue > 0 && !isFetching

            shouldDisplaySubmittedOverdueNotice =
                numberOfClientsSubmittedOverdue > 0 && !shouldDisplaySubmittedOverdueFilterMessage && !isFetching
        }

        if (hasSelfAssessments && filterStatus === SELF_ASSESSMENT_STATUS_FILTER_FILED_AWAITING_REFUND) {
            numberOfClientsAwaitingOverdue = selfAssessments.filter(
                ({ hasPaid, status, filedDateUTC }) =>
                    hasPaid === false &&
                    status === SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND &&
                    isDateDue(filedDateUTC, 30)
            ).length
            shouldDisplayAwaitingOverdueNotice = numberOfClientsAwaitingOverdue > 0
        }

        return (
            <Container>
                {isAdminUser && (
                    <FilterContainer isFetching={isFetching}>
                        <SelfAssessmentsSupportFilters
                            accountants={accountantsWithFullName}
                            handleAccountantChange={this.handleAccountantChange}
                            filterAccountant={filterAccountant}
                            filterIncludeUnpaid={filterIncludeUnpaid}
                            toggleUnpaid={this.toggleUnpaid}
                            hasLoaded={!isFetching && hasLoadedAccountantDropdown}
                        />
                    </FilterContainer>
                )}

                {!isAdminUser && (
                    <FilterContainer isFetching={isFetching}>
                        <SelfAssessmentsAccountantFilters
                            filterIncludeUnpaid={filterIncludeUnpaid}
                            toggleUnpaid={this.toggleUnpaid}
                            hasLoaded={!isFetching && hasLoadedAccountantDropdown}
                        />
                    </FilterContainer>
                )}

                <FilterContainer isFetching={isFetching}>
                    <SelfAssessmentsFilters
                        filterAccountant={filterAccountant}
                        filterIncludeUnpaid={filterIncludeUnpaid}
                        filterStatus={filterStatus}
                        selfAssessmentStatistics={selfAssessmentStatistics}
                        history={history}
                        isAdminUser={isAdminUser}
                        shouldHideAwaitingRefundStatus={user.allowPayLater === false}
                    />
                </FilterContainer>

                {shouldDisplayAssignedNotice && (
                    <Notice background={colors.redLighter}>
                        These clients are still preparing their documents and haven't yet signed the Letter of
                        Agreement. Once they "Submit" you can start with the work.
                    </Notice>
                )}

                {shouldDisplaySubmittedOverdueNotice && (
                    <Notice>
                        <span role="img" aria-label="Hey!">
                            👋
                        </span>{' '}
                        <ButtonLink type="button" onClick={this.handleDaysOverdueChange}>
                            {numberOfClientsSubmittedOverdue}{' '}
                            {numberOfClientsSubmittedOverdue === 1 ? 'client' : 'clients'}
                        </ButtonLink>{' '}
                        {numberOfClientsSubmittedOverdue === 1 ? 'has' : 'have'} stayed in "
                        {SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_SUBMITTED]}" for over{' '}
                        {daysSinceSubmittedThreshold} days. Put them at ease by moving them to "
                        {SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW]}" so they know work has
                        started.
                    </Notice>
                )}

                {shouldDisplaySubmittedOverdueFilterMessage && (
                    <Notice>
                        You are viewing the {<strong>{numberOfClientsSubmittedOverdue}</strong>}{' '}
                        {numberOfClientsSubmittedOverdue === 1 ? 'client' : 'clients'} that{' '}
                        {numberOfClientsSubmittedOverdue === 1 ? 'has' : 'have'} stayed in "
                        {SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_SUBMITTED]}" for over{' '}
                        {daysSinceSubmittedThreshold} days. Put them at ease by moving them to "
                        {SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW]}" so they know work has
                        started.
                    </Notice>
                )}

                {shouldDisplayAwaitingOverdueNotice && (
                    <Notice>
                        <span role="img" aria-label="Hey!">
                            👋
                        </span>{' '}
                        <strong>
                            {numberOfClientsAwaitingOverdue}{' '}
                            {numberOfClientsAwaitingOverdue === 1 ? 'client' : 'clients'}
                        </strong>{' '}
                        {numberOfClientsAwaitingOverdue === 1 ? 'has' : 'have'} stayed in "
                        {SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND]}" for over 30 days
                    </Notice>
                )}

                {hasSelfAssessments && (
                    <>
                        <SelfAssessmentsTable
                            selfAssessments={selfAssessments}
                            deselectAll={this.deselectAll}
                            handleCustomerSelection={this.toggleSelection}
                            isFetching={isFetching}
                            selectAll={this.selectAll}
                            selectedItems={selectedItems}
                            triggerTableUpdate={this.triggerTableUpdate}
                        />

                        <PaginationContainerWrap>
                            <PaginationContainer
                                pagination={pagination}
                                pathname={location.pathname}
                                search={location.search}
                            />
                        </PaginationContainerWrap>
                    </>
                )}

                {hasSelectedItems && (
                    <BulkActionsContainer>
                        <BulkActions selectedItems={selectedItems} deselectAll={this.deselectAll} />
                    </BulkActionsContainer>
                )}
            </Container>
        )
    }
}

SelfAssessments.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    accountantsWithFullName: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    selfAssessments: PropTypes.array.isRequired,
    selfAssessmentStatistics: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
    const { selfAssessments, statistics, user } = state
    const { isFetching, list, pagination } = selfAssessments

    return {
        accountantsWithFullName: getAccountantsWithFullNameAndStatus(state),
        isAdminUser: isAdmin(user),
        isFetching,
        pagination,
        selfAssessments: list,
        selfAssessmentStatistics: _get(statistics, ['selfAssessmentStatistics'], []),
        user,
    }
}

export default connect(mapStateToProps)(withRouter(SelfAssessments))
