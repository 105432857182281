import { formatCurrencyNotSure } from '@/helpers'
import { RepaymentPlanTypeTitles, SelfAssessmentDTO, StudentLoanDTO } from '@/models'

import { SectionData } from './_ui/SectionData'
import { SectionDataRow } from './_ui/SectionDataRow'

const planTitleFromType = (type: StudentLoanDTO['repaymentPlanType']) => RepaymentPlanTypeTitles[type] || '?'

export const StudentLoans = ({ studentLoan }: { studentLoan: SelfAssessmentDTO['studentLoan'] }) => {
    if (!studentLoan) return null

    const { additionalNotes, amountRepaidLastYear, repaymentBegan, repaymentFromSalary, repaymentPlanType } =
        studentLoan

    const planValue = planTitleFromType(repaymentPlanType)

    return (
        <SectionData>
            <SectionDataRow label="Repayment began" value={repaymentBegan} boolean />
            <SectionDataRow label="Student loan plan" value={planValue} />
            <SectionDataRow label="Repayment from salary" value={repaymentFromSalary} boolean />
            <SectionDataRow label="Amount repaid last year" value={formatCurrencyNotSure(amountRepaidLastYear)} />

            {additionalNotes && <SectionDataRow label="Notes" value={additionalNotes} />}
        </SectionData>
    )
}
