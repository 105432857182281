import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font } from '@scouts/ui'

import { ReturningCustomersTableMessagesCounterPopup } from './ReturningCustomersTableMessagesCounterPopup'

const ReturningCustomersTableMessagesCounter = ({
    customerId,
    numberOfSentMessages,
}: {
    customerId: number
    numberOfSentMessages: number
}) => {
    const [isPopupOpen, openPopup, closePopup] = useToggle()

    if (!numberOfSentMessages) return null

    return (
        <Container onMouseEnter={openPopup} onMouseLeave={closePopup}>
            <MessageCount>{numberOfSentMessages} sent</MessageCount>

            {isPopupOpen && <ReturningCustomersTableMessagesCounterPopup customerId={customerId} />}
        </Container>
    )
}

const Container = styled.span`
    position: relative;
`

const MessageCount = styled.span`
    color: ${colors.black};
    font-size: ${font.small};
    white-space: nowrap;
    padding: 3px 9px;
    background: ${colors.neutralLightest};
    border-radius: 2px;
`

export default ReturningCustomersTableMessagesCounter
