import { generateApiPath } from '@scouts/helpers'

import { CustomerDTO, MessageSuggestionDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { ResponseTransformers } from '../error-handling/responseTransformers'

export interface GetMessageSuggestionParams {
    customerId: CustomerDTO['id']
    starterAnswer?: string
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMessageSuggestion: builder.query<MessageSuggestionDTO, GetMessageSuggestionParams>({
            query: ({ customerId, starterAnswer }) => ({
                method: 'GET',
                url: generateApiPath(
                    ENDPOINTS.MESSAGE_SUGGESTIONS.GET.SINGLE,
                    { customerId },
                    { queryParameters: { starterAnswer } }
                ),
            }),
            transformErrorResponse: ResponseTransformers.customMessage(
                'Could not generate a message suggestion based on the last message'
            ),
        }),
    }),
})

export const { useLazyGetMessageSuggestionQuery } = extendedApi
