import { generateApiPath } from '@scouts/helpers'

import {
    AccountantSchedulingPageCalendarDTO,
    AccountantSchedulingPageDTO,
    AccountantSchedulingPageEditDTO,
} from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNylasSchedulingPages: builder.query<AccountantSchedulingPageDTO[], void>({
            query: () => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.NYLAS_SCHEDULING_PAGES.GET),
            }),
            providesTags: [Tags.Nylas],
        }),
        createNylasSchedulingPage: builder.mutation<AccountantSchedulingPageDTO, { redirectUrl: string }>({
            query: ({ redirectUrl }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.NYLAS_SCHEDULING_PAGES.POST, undefined, {
                    queryParameters: { redirectUrl },
                }),
            }),
            invalidatesTags: [Tags.Nylas],
        }),
        updateNylasSchedulingPage: builder.mutation<
            AccountantSchedulingPageDTO,
            AccountantSchedulingPageEditDTO & { id: number }
        >({
            query: ({ id, ...data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.NYLAS_SCHEDULING_PAGES.PUT, { id }),
                body: data,
            }),
            invalidatesTags: [Tags.Nylas],
        }),
        deleteNylasSchedulingPage: builder.mutation<void, void>({
            query: () => ({
                method: 'DELETE',
                url: generateApiPath(ENDPOINTS.NYLAS_SCHEDULING_PAGES.DELETE),
            }),
            invalidatesTags: [Tags.Nylas],
        }),
        getNylasCalendars: builder.query<AccountantSchedulingPageCalendarDTO[], { id: number }>({
            query: ({ id }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.NYLAS_SCHEDULING_PAGES.GET_CALENDARS, { id }),
            }),
            providesTags: [Tags.Nylas],
        }),
    }),
})

export const {
    useGetNylasSchedulingPagesQuery,
    useCreateNylasSchedulingPageMutation,
    useUpdateNylasSchedulingPageMutation,
    useDeleteNylasSchedulingPageMutation,
    useGetNylasCalendarsQuery,
} = extendedApi
