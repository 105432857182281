import PropTypes from 'prop-types'
import { cloneElement, useRef, useState, Children } from 'react'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { colors, font, media } from '@scouts/ui'

import { clickedOnScrollbar } from '../../../helpers'
import FilterDropdown from './FilterDropdown'
import FilterItemValue from './FilterItemValue'

const Remove = styled.div`
    color: ${colors.black};
    font-size: ${font.smaller};
    font-weight: ${font.weight.medium};
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2000;
    width: 24px;
    height: 24px;
    cursor: pointer;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }
`

const Container = styled.div`
    padding: 15px 15px;
    padding-right: 24px;
    border: 1px solid ${colors.neutralLightest};
    cursor: pointer;
`

const Wrapper = styled.div`
    margin-right: 6px;
    position: relative;

    ${media.pointer} {
        &:hover {
            ${Remove} {
                display: flex;
            }
            ${Container} {
                border-color: ${colors.neutralLight};
            }
        }
    }
`

const Label = styled.div`
    color: ${colors.black};
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    margin-bottom: 9px;
`

const Values = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 240px;
    margin-bottom: -3px;
`

const DropdownContainer = styled.div`
    position: relative;
    top: -12px;
    left: 18px;
`

const handleEnterKeyPress =
    (onClick) =>
    ({ key }) => {
        if (key === 'Enter') {
            onClick()
        }
    }

const propTypes = {
    activeValues: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    isNeutral: PropTypes.bool,
    label: PropTypes.string.isRequired,
    isOpenByDefault: PropTypes.bool,
    removeFilter: PropTypes.func,
}

const defaultProps = {
    isOpenByDefault: false,
    removeFilter: null,
    isNeutral: false,
}

const FilterItem = (props) => {
    const { children, label, activeValues, removeFilter, isNeutral, isOpenByDefault } = props

    const [isDropdownOpen, setIsDropdownOpen] = useState(isOpenByDefault)

    const openDropdown = () => {
        setIsDropdownOpen(true)
    }

    const closeDropdownOnClick = (e) => {
        if (!clickedOnScrollbar(e.pageX)) {
            setIsDropdownOpen(false)
        }
    }

    const closeDropdown = () => {
        setIsDropdownOpen(false)
    }

    const ref = useRef()
    useOnClickOutside(ref, closeDropdownOnClick)

    return (
        <Wrapper>
            <Container
                isNeutral={isNeutral}
                onClick={openDropdown}
                onKeyPress={handleEnterKeyPress(openDropdown)}
                tabIndex="0"
            >
                <Label>{label}</Label>
                <Values>
                    {activeValues.map((value) => {
                        if (!value) return null
                        return (
                            <FilterItemValue isNeutral={isNeutral} key={value}>
                                {value}
                            </FilterItemValue>
                        )
                    })}
                </Values>
            </Container>
            <DropdownContainer ref={ref}>
                {isDropdownOpen && (
                    <FilterDropdown>
                        {Children.map(children, (child) => cloneElement(child, { closeDropdown }))}
                    </FilterDropdown>
                )}
            </DropdownContainer>
            {removeFilter && <Remove onClick={removeFilter}>✕</Remove>}
        </Wrapper>
    )
}

FilterItem.propTypes = propTypes
FilterItem.defaultProps = defaultProps

export default FilterItem
