import { RECEIVE_QUICK_SEARCH_RESULTS, REQUEST_QUICK_SEARCH_RESULTS, RESET_QUICK_SEARCH } from '../actions/quick-search'

const defaultState = {
    items: [],
    isFetching: false,
}

export default (state = defaultState, action) => {
    const { type, data } = action

    switch (type) {
        case REQUEST_QUICK_SEARCH_RESULTS: {
            return { ...state, isFetching: true }
        }

        case RECEIVE_QUICK_SEARCH_RESULTS: {
            const { items } = data

            return { ...state, isFetching: false, items }
        }

        case RESET_QUICK_SEARCH: {
            return { ...state, isFetching: false, items: [] }
        }

        default:
            return state
    }
}
