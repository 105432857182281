import { colors, font, Flexer, Line } from '@scouts/ui'

import { CustomerDTO, IdentityVerificationDTO } from '@/models'

import { MarkAsVerified } from '../actions/MarkAsVerified'
import { RequestContainer } from '../actions/RequestContainer'
import { VerificationUploadModalLink } from '../VerificationUploadModalLink'
import { VerificationWidget } from '../VerificationWidget'

interface StartedProps {
    customer: CustomerDTO
    identityVerification: IdentityVerificationDTO
}

export const Started = ({ identityVerification, customer }: StartedProps) => {
    return (
        <VerificationWidget
            background={colors.neutralLightest}
            actions={
                <>
                    <RequestContainer
                        buttonLabel="Send request"
                        customer={customer}
                        identityVerification={identityVerification}
                    />
                    <MarkAsVerified
                        buttonLabel="Mark as verified"
                        customerId={customer.id}
                        identityVerification={identityVerification}
                    />
                </>
            }
        >
            <Flexer column gap="9px" justifyStart>
                <Line weight={font.weight.medium}>Identity not yet verified</Line>

                <Line size={font.small}>Start the KYC process by sending an automatic verification request</Line>

                <VerificationUploadModalLink customerId={customer.id} identityVerification={identityVerification} />
            </Flexer>
        </VerificationWidget>
    )
}
