import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { formatTimeFromNow } from '@scouts/helpers'
import {
    colors,
    font,
    radius,
    ChipButton,
    Container,
    Flexer,
    IconCalendarNumber,
    Label,
    Line,
    Spacer,
} from '@scouts/ui'

import { PATH_USERS } from '@/constants'
import { formatCurrency, getAccountantImageUrl } from '@/helpers'
import { AccountantDetailsDTO } from '@/models'

import { AccountantStatusLabel } from './AccountantStatusLabel'
import { IntegrationIndicators } from './IntegrationIndicators'

export const AccountantDetailsProfile = ({ accountant }: { accountant: AccountantDetailsDTO }) => {
    const history = useHistory()

    const {
        allowCompany,
        allowTaxConsultation,
        allowSubscription,
        allowPayLater,
        capacity,
        companyName,
        companyStatus,
        currentPausedDescription,
        currentPausedScheduledEndDate,
        email,
        fullName,
        id,
        imageFileId,
        isOnTrial,
        lastLoggedInDateUTC,
        payLaterSelfAssessmentFee,
        standardSelfAssessmentFee,
        status,
        taxConsultationFee,
        taxConsultationSchedulingLink,
        taxConsultationStatus,
        taxRegistrations,
    } = accountant

    const avatarImageSrc = imageFileId !== 0 ? getAccountantImageUrl(id) : ''

    const loggedInText = lastLoggedInDateUTC
        ? `Last seen ${formatTimeFromNow(lastLoggedInDateUTC)}`
        : "Hasn't logged in recently"

    return (
        <>
            <Container style={{ position: 'sticky', top: '36px' }}>
                <Container
                    padding="18px 24px"
                    border={`1px solid ${colors.neutralLightest}`}
                    radius={radius.large}
                    relative
                >
                    <AvatarContainer>{avatarImageSrc && <Avatar src={avatarImageSrc} alt="" />}</AvatarContainer>
                    <Spacer marginBottom="18px">
                        <ChipButton onClick={() => history.push(`${PATH_USERS}/${id}`)} withBorder variant="inverted">
                            Edit profile →
                        </ChipButton>
                    </Spacer>
                    <Spacer marginBottom="12px">
                        <Wrapper>
                            <Line weight={font.weight.medium}>{fullName}</Line>
                            <Line size={font.small}>{email}</Line>
                            <Line size={font.small}>{companyName}</Line>
                        </Wrapper>
                    </Spacer>
                    <Spacer marginBottom="12px">
                        <Flexer column tabletRow tabletAlignCenter flexWrap gap="6px">
                            {isOnTrial && <Label background={colors.blueLighter}>On trial</Label>}

                            {status && (
                                <AccountantStatusLabel
                                    accountantId={id}
                                    allowTaxConsultation={allowTaxConsultation}
                                    capacity={capacity}
                                    currentPausedDescription={currentPausedDescription}
                                    currentPausedScheduledEndDate={currentPausedScheduledEndDate}
                                    status={status}
                                    taxConsultationStatus={taxConsultationStatus}
                                    allowCompany={allowCompany}
                                    companyStatus={companyStatus}
                                />
                            )}

                            {allowTaxConsultation && taxConsultationSchedulingLink && (
                                <AccountantCalendarLink
                                    href={taxConsultationSchedulingLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <IconCalendarNumber color={colors.neutralDarker} />
                                </AccountantCalendarLink>
                            )}

                            <Label background={colors.neutralLightest}>{loggedInText}</Label>
                        </Flexer>
                    </Spacer>
                    <Spacer marginBottom="12px">
                        <IntegrationIndicators
                            externalApis={accountant.externalApis}
                            hasAgentAuthorisationCredentials={accountant.hasAgentAuthorisationCredentials}
                        />
                    </Spacer>
                    <Spacer marginBottom="12px">
                        <Flexer column>
                            <Setting checked={!!standardSelfAssessmentFee}>
                                Fee {formatCurrency(standardSelfAssessmentFee)}
                            </Setting>

                            <Setting checked={!!allowPayLater}>
                                Pay later {formatCurrency(payLaterSelfAssessmentFee)}
                            </Setting>

                            <Setting checked={!!allowTaxConsultation}>
                                Consultation {taxConsultationFee && <>{formatCurrency(taxConsultationFee)}</>}
                            </Setting>

                            <Setting checked={taxRegistrations}>UTR registrations</Setting>

                            <Setting checked={allowSubscription}>Subscriptions</Setting>
                            <Setting checked={allowCompany}>Company returns</Setting>
                        </Flexer>
                    </Spacer>
                </Container>
            </Container>
        </>
    )
}

const AccountantCalendarLink = styled.a`
    text-decoration: none;
    display: inline-block;
    margin: 1px 12px 0 0;
`

const Wrapper = styled.div`
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

const AvatarContainer = styled.div`
    width: 72px;
    height: 72px;
    background: ${colors.neutralLightest};
    border-radius: ${radius.full};
    position: absolute;
    top: -24px;
    right: 24px;
`
const Avatar = styled.img`
    width: 100%;
    height: 100%;
    border-radius: ${radius.full};
`

const Setting = styled.div<{ checked: boolean }>`
    font-size: ${font.small};
    line-height: 1.5;

    &:before {
        content: '✓';
        margin-right: 6px;
    }

    ${({ checked }) =>
        !checked &&
        `
        color: ${colors.neutralDarker};
        &:before {
            content: '×';
        }
    `}
`
