import axios from 'axios'
import { call } from 'redux-saga/effects'

import { getRequestConfig } from './api'

export function* apiCall(url, method, data, headers, cancelToken, responseType) {
    const requestConfig = yield getRequestConfig({ url, method, data, headers, cancelToken, responseType })
    const apiCallEffect = yield call(axios, requestConfig)

    return apiCallEffect
}

export const apiCallCancelable = ({ url, method, data, headers, cancelToken }) =>
    apiCall(url, method, data, headers, cancelToken)

export const createAxiosCancelSource = () => axios.CancelToken.source()

export const isCancelledApiCall = (e) => axios.isCancel(e)

export const getPaginationHeaders = (headers) => {
    const currentPage = Number(headers['x-pagination-current-page'])
    const pageCount = Number(headers['x-pagination-page-count'])
    const perPage = Number(headers['x-pagination-per-page'])
    const totalCount = Number(headers['x-pagination-total-count'])
    return {
        currentPage,
        pageCount,
        perPage,
        totalCount,
    }
}
