import styled from 'styled-components'

import { colors, File } from '@scouts/ui'

import { downloadCustomerFile } from '@/actions'
import { CustomerFileDTO } from '@/models'
import { useAppDispatch } from '@/store'

export const VerificationFile = ({ file }: { file: CustomerFileDTO }) => {
    const dispatch = useAppDispatch()

    const download = () => {
        dispatch(downloadCustomerFile({ id: file.id }))
    }

    return (
        <Container>
            <File onClick={download} background={colors.neutralLightest}>
                {file.fileName}
            </File>
        </Container>
    )
}

const Container = styled.div`
    max-width: 300px;
`
