import { PAGINATION_DEFAULT_PAGE, PAGINATION_VIEW_ALL_PAGE_SIZE } from '@/constants'
import { OffPlatformServicesRequestOptions } from '@/models'

export const getOffPlatformServicesRequestQueryParams = (options?: OffPlatformServicesRequestOptions) => ({
    pageSize: options?.pageSize || PAGINATION_VIEW_ALL_PAGE_SIZE,
    page: options?.page || PAGINATION_DEFAULT_PAGE,
    ...(options?.sortOrder && { sortOrder: options.sortOrder }),
    ...(options?.sortType && { sortType: options.sortType }),
    ...(options?.accountantId && { accountantId: options.accountantId }),
    ...(options?.status && { status: options.status }),
    ...(options?.submittedDate?.start && {
        'submittedDate.Start': options.submittedDate.start,
    }),
    ...(options?.submittedDate?.end && {
        'submittedDate.End': options.submittedDate.end,
    }),
    ...(options?.claimedDate?.start && {
        'claimedDate.Start': options.claimedDate.start,
    }),
    ...(options?.claimedDate?.end && {
        'claimedDate.End': options.claimedDate.end,
    }),
    ...(options?.cancelledDate?.start && {
        'cancelledDate.Start': options.cancelledDate.start,
    }),
    ...(options?.cancelledDate?.end && {
        'cancelledDate.End': options.cancelledDate.end,
    }),
    ...(options?.paidDate?.start && {
        'paidDate.Start': options.paidDate.start,
    }),
    ...(options?.paidDate?.end && {
        'paidDate.End': options.paidDate.end,
    }),
    ...(options?.referredDate?.start && {
        'referredDate.Start': options.referredDate.start,
    }),
    ...(options?.referredDate?.end && {
        'referredDate.End': options.referredDate.end,
    }),
})
