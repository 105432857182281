import { useState } from 'react'

import { useToggle } from '@scouts/helpers'
import { colors, font, radius, ConfirmationModal, Container, File, Flexer, Line, Spacer } from '@scouts/ui'

import { getFileTypeTitle } from '@/domain'
import { CompanyReturnDTO, CompanyReturnFileDTO, FileType, FileTypeTypes } from '@/models'
import { useCompanyReturnFiles } from '@/store/company-return-files/hooks'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

import { useCompanyFileHandlers } from '../hooks/use-company-file-handlers'

import { UploadButton } from '../ui/UploadButton'

interface DeletableFileState {
    fileId: CompanyReturnFileDTO['id']
    fileName: CompanyReturnFileDTO['fileName']
}

const accountantFileTypes: FileTypeTypes[] = [
    FileType.FullAccounts,
    FileType.AbridgedAccounts,
    FileType.FilletedAccounts,
    FileType.FinalReturn,
    FileType.TaxCalculation,
    FileType.SubmissionResponse,
]

export const FilesAccountant = ({ companyReturn }: { companyReturn: CompanyReturnDTO }) => {
    const customerId = useCustomerIdFromPath()
    const [isDeleteConfirmationModalOpen, openDeleteConfirmationModal, closeDeleteConfirmationModal] = useToggle()
    const { id: companyReturnId, companyId } = companyReturn

    const { companyReturnFiles } = useCompanyReturnFiles({
        customerId,
        companyId,
        companyReturnId,
    })

    const { handleDeleteFile, handleDownload, isLoadingDelete } = useCompanyFileHandlers()

    const [deletableFile, setDeletableFile] = useState<DeletableFileState | null>()

    const handleOpenDeleteConfirmation = ({ fileId, fileName }: DeletableFileState) => {
        setDeletableFile({ fileId, fileName })
        openDeleteConfirmationModal()
    }

    const handleConfirmDeleteFile = () => {
        if (deletableFile?.fileId) {
            handleDeleteFile({ fileId: deletableFile.fileId })
            closeDeleteConfirmationModal()
        }
    }

    const accountantFiles = companyReturnFiles?.filter(({ fileType }) => accountantFileTypes.includes(fileType)) || []
    const hasAccountantFiles = accountantFiles?.length > 0

    return (
        <Container>
            {hasAccountantFiles ? (
                <Container radius={radius.large} padding="24px" background={colors.blueLightest}>
                    <Flexer column gap="12px">
                        {accountantFiles.map(({ fileName, id: fileId, fileType }) => (
                            <Spacer key={fileId}>
                                <Spacer marginBottom="6px">
                                    <Line weight={font.weight.medium} size={font.small}>
                                        {getFileTypeTitle(fileType)}
                                    </Line>
                                </Spacer>
                                <File
                                    background={colors.blueLighter}
                                    onClick={() => handleDownload({ fileId })}
                                    onDelete={() => handleOpenDeleteConfirmation({ fileId, fileName })}
                                    disabled={isLoadingDelete}
                                >
                                    {fileName}
                                </File>
                            </Spacer>
                        ))}
                    </Flexer>
                </Container>
            ) : (
                <Container radius={radius.large} padding="24px" background={colors.redLighter}>
                    <Line size={font.small} textAlign="center">
                        Final tax return and full accounts not yet uploaded
                    </Line>
                </Container>
            )}

            <Spacer marginTop="18px">
                <Flexer justifyEnd>
                    <UploadButton fileTypes={accountantFileTypes} />
                </Flexer>
            </Spacer>

            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal
                    onConfirm={handleConfirmDeleteFile}
                    onCancel={closeDeleteConfirmationModal}
                    isLoading={isLoadingDelete}
                >
                    Are you sure you want to delete {deletableFile?.fileName}?
                </ConfirmationModal>
            )}
        </Container>
    )
}
