import { generateApiPath } from '@scouts/helpers'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomerUnresolvedEscalationsCount: builder.query<number, { customerId?: number }>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.CUSTOMERS_ESCALATIONS.GET.COUNT, { customerId }),
            }),
            providesTags: [Tags.CustomerUnresolvedEscalationsCount],
        }),
    }),
})

export const { useGetCustomerUnresolvedEscalationsCountQuery } = extendedApi

export const invalidateUnresolvedEscalationsCount = () =>
    apiSlice.util.invalidateTags([Tags.CustomerUnresolvedEscalationsCount])
