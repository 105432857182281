import { useSelector } from 'react-redux'

import { Spacer, Table } from '@scouts/ui'

import { CustomerDTO, SelfAssessmentSummaryDTO } from '@/models'
import { getUser } from '@/selectors/user'

import { CreateSelfAssessment } from './CreateSelfAssessment'
import { CustomerOverviewSelfAssessment } from './CustomerOverviewSelfAssessment'
import { CustomerSelfAssessmentsMigration } from './CustomerSelfAssessmentsMigration'

interface CustomerOverviewSelfAssessmentsProps {
    customer: CustomerDTO
    customerSelfAssessments: SelfAssessmentSummaryDTO[]
}

export const CustomerOverviewSelfAssessments = ({
    customer,
    customerSelfAssessments,
}: CustomerOverviewSelfAssessmentsProps) => {
    const { isAdminUser } = useSelector(getUser)

    const hasSelfAssessments = customerSelfAssessments.length > 0
    const hasMigrateOption = hasSelfAssessments && isAdminUser

    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width={hasSelfAssessments ? '25%' : '75%'}>
                        {hasSelfAssessments ? 'Tax returns' : "This client hasn't started any tax returns yet"}
                    </Table.Header.Cell>
                    {hasSelfAssessments && <Table.Header.Cell width="50%">&nbsp;</Table.Header.Cell>}
                    <Table.Header.Cell width="25%" alignRight>
                        <CreateSelfAssessment customer={customer} customerSelfAssessments={customerSelfAssessments} />
                    </Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>

            {hasSelfAssessments && (
                <Table.Body>
                    {customerSelfAssessments.map((selfAssessment) => (
                        <CustomerOverviewSelfAssessment selfAssessment={selfAssessment} key={selfAssessment.id} />
                    ))}
                </Table.Body>
            )}

            {hasMigrateOption && (
                <Spacer marginTop="12px">
                    <CustomerSelfAssessmentsMigration customer={customer} />
                </Spacer>
            )}
        </Table>
    )
}
