import { useEffect } from 'react'

import { useToggle } from '@scouts/helpers'
import { Button } from '@scouts/ui'

import { requestCustomerFiles } from '@/actions'
import { CustomerDTO, IdentityVerificationDTO, IdentityVerificationStatus } from '@/models'
import { useAppDispatch } from '@/store'
import {
    useCreateIdentityVerification,
    useCustomersIdentityVerifications,
    useUpdateIdentityVerification,
} from '@/store/identity-verifications'

import { useUser } from '@/hooks/use-user'

import { Review } from './Review'
import { ReviewRequestAgain } from './ReviewRequestAgain'

interface ReviewContainerProps {
    identityVerification: IdentityVerificationDTO
    buttonLabel: string
    customer: CustomerDTO
    isNeutral?: boolean
}

export const ReviewContainer = ({ identityVerification, customer, buttonLabel, isNeutral }: ReviewContainerProps) => {
    const dispatch = useAppDispatch()
    const { id: userId } = useUser()
    const [isModalOpen, openModal, closeModal] = useToggle()
    const [isRequestAgainModalOpen, openRequestAgainModal, closeRequestAgainModal] = useToggle()

    const { identityVerifications, isLoading: isLoadingIdentityVerifications } = useCustomersIdentityVerifications({
        customerId: customer.id,
    })
    const { updateIdentityVerification, isLoading: isLoadingUpdate } = useUpdateIdentityVerification()
    const { createIdentityVerification, isLoading: isLoadingCreate } = useCreateIdentityVerification()

    const myIdentityVerification = identityVerifications.find(
        (identityVerification) => identityVerification.accountantId === userId
    )

    const { customerId, accountantId, status } = identityVerification

    const isMarkAsVerifiedAllowed = status !== IdentityVerificationStatus.Verified

    useEffect(() => {
        dispatch(requestCustomerFiles({ customerId }))
    }, [customerId, dispatch])

    const handleCancel = () => {
        closeModal()
        closeRequestAgainModal()
    }

    const handleRequestNewDocuments = () => {
        const isSameAccountant = accountantId === userId
        if (!isSameAccountant) {
            if (myIdentityVerification) {
                updateIdentityVerification({
                    identityVerificationId: myIdentityVerification.id,
                    data: {
                        ...myIdentityVerification,
                        status: IdentityVerificationStatus.Requested,
                    },
                }).then(closeModal)
            } else {
                createIdentityVerification({ customerId: customer.id }).then(closeModal)
            }
        } else {
            openRequestAgainModal()
        }
    }

    const handleMarkAsVerified = () => {
        updateIdentityVerification({
            identityVerificationId: identityVerification.id,
            data: {
                ...identityVerification,
                status: IdentityVerificationStatus.Verified,
            },
        }).then(closeModal)
    }

    const handleRequestAgain = (notes: IdentityVerificationDTO['notes']) => {
        updateIdentityVerification({
            identityVerificationId: identityVerification.id,
            data: {
                ...identityVerification,
                notes,
                status: IdentityVerificationStatus.Retry,
            },
        }).then(() => {
            closeModal()
            closeRequestAgainModal()
        })
    }

    return (
        <>
            <Button isSecondary={isNeutral} onClick={openModal} isLoading={isLoadingIdentityVerifications}>
                {buttonLabel}
            </Button>

            {isModalOpen && (
                <Review
                    customer={customer}
                    handleCancel={handleCancel}
                    handleMarkAsVerified={isMarkAsVerifiedAllowed ? handleMarkAsVerified : undefined}
                    handleRequestNewDocuments={handleRequestNewDocuments}
                    identityVerification={identityVerification}
                />
            )}

            {isRequestAgainModalOpen && (
                <ReviewRequestAgain
                    handleCancel={handleCancel}
                    handleRequestAgain={handleRequestAgain}
                    isLoading={isLoadingCreate || isLoadingUpdate}
                />
            )}
        </>
    )
}
