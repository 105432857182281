import { useMemo, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, media, Label } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { FilingAreaTypes, SelfAssessmentDTO, SelfAssessmentFileDTO } from '@/models'

interface SelfAssessmentSectionProps {
    children?: ReactNode
    filingArea?: FilingAreaTypes
    isVerified?: boolean
    name: string
    sectionId?: string
    selfAssessment: SelfAssessmentDTO
    selfAssessmentFiles: SelfAssessmentFileDTO[]
    summary?: string
    title: string
}

export const SelfAssessmentSection = ({
    children,
    filingArea,
    isVerified,
    name,
    sectionId,
    selfAssessment,
    selfAssessmentFiles,
    summary = '',
    title,
}: SelfAssessmentSectionProps) => {
    const [isOpen, , , toggleOpen] = useToggle()

    const filesLink = `${PATH_CUSTOMERS}/${selfAssessment.customerId}/files/${selfAssessment.id}#${name}`

    const numberOfFiles = useMemo(
        () => selfAssessmentFiles.filter((item) => item.filingArea === filingArea).length,
        [selfAssessmentFiles, filingArea]
    )

    return (
        <Container id={sectionId}>
            <Header>
                <Toggle onClick={toggleOpen}>
                    <Title isOpen={isOpen}>{title}</Title>
                    <Summary>
                        {summary} &nbsp;
                        {isVerified && <Label background={colors.mintLighter}>✓ Verified</Label>}
                    </Summary>
                </Toggle>
                <FilesLink to={filesLink}>
                    {numberOfFiles} {numberOfFiles === 1 ? 'file' : 'files'}
                </FilesLink>
            </Header>

            {isOpen && <Body>{children}</Body>}
        </Container>
    )
}

const Container = styled.div`
    margin: 1px 0;
    background: ${colors.neutralLightest};
    border-radius: 4px;
`

const Title = styled.div<{ isOpen?: boolean }>`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    flex: 0 0 calc(33.33% + 40px);
    position: relative;
    padding-left: 42px;

    &::before {
        content: ${({ isOpen }) => (isOpen ? '"▾"' : '"▸"')};
        position: absolute;
        left: 21px;
        top: -1px;
        cursor: pointer;
        pointer-events: none;
    }
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
`

const Toggle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 calc(100% - 120px);
    padding: 18px 0;
    cursor: pointer;

    ${media.pointer} {
        &:hover ${Title} {
            color: ${colors.blue};
        }
    }
`

const Summary = styled.div`
    font-size: ${font.small};
    line-height: 1.3;
    flex: 1 1 auto;
`

const FilesLink = styled(Link)`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    text-align: right;
    text-decoration: none;
    display: block;
    flex: 0 0 120px;
    padding: 0 24px;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }
`

const Body = styled.div`
    padding: 6px 24px 30px;
`
