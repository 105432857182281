import { addErrorToast } from '@scouts/ui'

import { resetSession } from './actions'
import { sendApiErrorsToSentry } from './helpers/sentry'

const logoutStatuses = [401, 403]

/**
 * Generic error handler for "old-style" Axios error object. Used in Sagas.
 *
 * @deprecated Prefer using `RTK-Query`, because API call errors automatically are handled by middleware.
 * @param {import('axios').AxiosError} error
 */
export const handleError = (error) => {
    const data = error?.data || error?.response?.data || null
    const status = error?.status || error?.response?.status || null

    const isServerError5XX = String(status)[0] === '5'

    if (logoutStatuses.indexOf(status) > -1) {
        return resetSession()
    }

    if (status === 400 || isServerError5XX) {
        sendApiErrorsToSentry(error)
    }

    const errorMessages = []

    if (data) {
        if (data.message) {
            errorMessages.push(data.message)
        }

        if (data.exceptionMessage) {
            errorMessages.push(data.exceptionMessage)
        }

        if (data.modelState) {
            const key = Object.keys(data.modelState)[0]
            data.modelState[key].forEach((errorMessage) => {
                errorMessages.push(errorMessage)
            })
        }
    }

    if (isServerError5XX) {
        errorMessages.push(`An error occurred (${status})`)
    }

    const errorsCount = errorMessages.length

    errorsCount <= 3
        ? errorMessages.map((message) => addErrorToast({ body: message }))
        : addErrorToast({ body: errorMessages.join(', ') })

    return { type: 'HANDLED_ERRORS', data: {} }
}
