import moment from 'moment-timezone'
import { useState } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import {
    addSuccessToast,
    Button,
    DatePicker,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Select,
} from '@scouts/ui'

import { AGENT_AUTH_STATUS_TITLES } from '@/constants'
import { AgentAuthorisationDTO, AgentAuthorisationStatus, CustomerDTO } from '@/models'
import { useCreateAgentAuthorisation } from '@/store/agent-authorisations'

import { useUser } from '@/hooks/use-user'

interface AgentAuthManualAddModalProps {
    customer: CustomerDTO
    closeModal: () => void
}

export const AgentAuthManualAddModal = ({ customer, closeModal }: AgentAuthManualAddModalProps) => {
    const { createAgentAuthorisation } = useCreateAgentAuthorisation()
    const user = useUser()
    const statuses = [
        AgentAuthorisationStatus.AwaitingCode,
        AgentAuthorisationStatus.CodeReceived,
        AgentAuthorisationStatus.ClientAuthorised,
    ]

    const [requestedDateUTC, setRequestedDateUTC] = useState(moment().format('YYYY-MM-DD'))
    const [status, setStatus] = useState<AgentAuthorisationDTO['status']>(AgentAuthorisationStatus.AwaitingCode)

    const handleSubmit = () => {
        const data = {
            customerId: customer.id,
            accountantId: user.id,
            status,
            requestedDateUTC,
        }

        createAgentAuthorisation({ data }).then(
            matchSuccessResponse(() => {
                addSuccessToast({ body: 'HMRC agent authorisation created' })
                closeModal()
            })
        )
    }

    return (
        <Modal onClose={closeModal}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Manually add authorisation record</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Client name</Form.Row.Title>
                        <Form.Row.Content>{customer.fullName}</Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>When was the authorisation request sent to HMRC?</Form.Row.Title>
                        <Form.Row.Content>
                            <DatePicker
                                name="requestedDateUTC"
                                value={requestedDateUTC}
                                onChange={({ value }) => setRequestedDateUTC(value)}
                                minDate="2010-01-01"
                            />
                        </Form.Row.Content>
                    </Form.Row>
                    <Form.Row>
                        <Form.Row.Title>Authorisation status</Form.Row.Title>
                        <Form.Row.Content>
                            <Select
                                name="status"
                                value={status}
                                onChange={({ value }) => setStatus(value as AgentAuthorisationDTO['status'])}
                                options={[
                                    { title: 'Choose…' },
                                    ...statuses.map((s) => ({
                                        value: s,
                                        title: `${AGENT_AUTH_STATUS_TITLES[s]}`,
                                    })),
                                ]}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>
                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button type="submit">Continue</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
