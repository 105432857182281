import _get from 'lodash/get'
import { useState } from 'react'

import { colors, radius, ButtonLink, Container, Flexer, Spacer } from '@scouts/ui'

import {
    AddressType,
    CustomerDTO,
    TaxRegistrationDTO,
    TaxRegistrationStatus as TaxRegistrationStatusModel,
} from '@/models'
import { useCustomerAddresses } from '@/store/addresses'

import { useCustomerTaxRegistrationFiles } from '@/components/tax-registrations/hooks/use-customer-tax-registration-files'

import { TaxRegistrationActions } from './TaxRegistrationActions'
import { TaxRegistrationFields } from './TaxRegistrationFields'
import { TaxRegistrationHeader } from './TaxRegistrationHeader'
import { TaxRegistrationUploadNotice } from './TaxRegistrationUploadNotice'

interface TaxRegistrationProps {
    taxRegistration: TaxRegistrationDTO
    customer: CustomerDTO
}

export const TaxRegistration = ({ taxRegistration, customer }: TaxRegistrationProps) => {
    const { uniqueTaxpayerReference } = customer
    const { status, customerId } = taxRegistration

    const [areDetailsVisible, setAreDetailsVisible] = useState(false)
    const { hasFiles } = useCustomerTaxRegistrationFiles({ customerId })

    const { addresses } = useCustomerAddresses({ customerId: customer.id })

    const isCompleted = status === TaxRegistrationStatusModel.Complete
    const isCancelled = status === TaxRegistrationStatusModel.Cancelled

    const hasUTR = !!(uniqueTaxpayerReference && uniqueTaxpayerReference.length > 0)

    const previousAddresses = addresses.filter(
        ({ addressType, customerId }) => addressType === AddressType.Previous && customerId === customer.id
    )

    const lastPreviousAddress = _get(previousAddresses, [previousAddresses.length - 1], undefined)

    const shouldDisplayUpload =
        (status === TaxRegistrationStatusModel.Submitted ||
            status === TaxRegistrationStatusModel.InformationRequested ||
            status === TaxRegistrationStatusModel.Complete) &&
        !hasFiles

    const shouldDisplayDetails = (!hasUTR || !isCompleted || !hasFiles) && !isCancelled

    if (!taxRegistration) return null

    return (
        <Container className="tour-utr">
            <TaxRegistrationHeader
                hasFiles={hasFiles}
                hasUTR={hasUTR}
                status={status}
                actions={
                    <>
                        {!shouldDisplayDetails && (
                            <ButtonLink onClick={() => setAreDetailsVisible(!areDetailsVisible)}>
                                {areDetailsVisible ? 'Hide' : 'Show'} details
                            </ButtonLink>
                        )}
                    </>
                }
            />

            {(shouldDisplayDetails || areDetailsVisible) && (
                <Spacer marginTop="6px">
                    <Container border={`1px solid ${colors.neutralLightest}`} padding="24px" radius={radius.medium}>
                        <Flexer column gap="18px">
                            <TaxRegistrationActions taxRegistration={taxRegistration} customer={customer} />

                            {shouldDisplayUpload && <TaxRegistrationUploadNotice />}

                            <TaxRegistrationFields
                                customer={customer}
                                taxRegistration={taxRegistration}
                                address={lastPreviousAddress}
                            />
                        </Flexer>
                    </Container>
                </Spacer>
            )}
        </Container>
    )
}
