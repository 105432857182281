import { lazy, Suspense } from 'react'

import { colors, AccountantPortalChrome, Loadable } from '@scouts/ui'

import { useCustomerIdFromPath } from '@/hooks/use-customer-id-from-path'

const TaxRegistrationAgreement = lazy(() => import('./TaxRegistrationAgreement'))

export const TaxRegistrationAgreementContainer = () => {
    const customerId = useCustomerIdFromPath()

    return (
        <AccountantPortalChrome.Customer.Page.Container backgroundColor={colors.neutralLightest}>
            <Suspense fallback={<Loadable isLoading />}>
                <TaxRegistrationAgreement customerId={customerId} />
            </Suspense>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
