import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Flexer, TypeSelect, TypeSelectProps } from '@scouts/ui'

import { getAccountantsWithFullNameAndStatus } from '@/selectors'

import { useFilters } from './hooks'

export const TaxConsultationsSupportFilters = () => {
    const accountants = useSelector(getAccountantsWithFullNameAndStatus)
    const hasAccountants = accountants.length > 0
    const { activeFilters, applyFilter } = useFilters()

    const { accountantId } = activeFilters

    const handleAccountantChange: TypeSelectProps['onChange'] = ({ value }) => {
        applyFilter({ updatedFilter: { accountantId: Number(value), page: 1 } })
    }

    if (!hasAccountants) return null

    return (
        <Flexer gap="12px" alignCenter>
            <Accountants>
                <TypeSelect
                    name="filterAccountant"
                    value={Number(accountantId) || ''}
                    options={[{ value: '', title: 'All accountants' }, ...accountants]}
                    onChange={handleAccountantChange}
                    autoClear
                    isRequired
                    showOptions
                />
            </Accountants>
        </Flexer>
    )
}

const Accountants = styled.div`
    flex: 0 1 320px;
    height: 35px;
`
