import { Table } from '@scouts/ui'

import { OffPlatformServicesRequestDTO } from '@/models'

import { OffPlatformServicesRequestsTableRow } from './OffPlatformServicesRequestsTableRow'

export const OffPlatformServicesRequestsTable = ({
    offPlatformServicesRequests,
}: {
    offPlatformServicesRequests: OffPlatformServicesRequestDTO[] | undefined
}) => {
    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="25%">Client</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Status</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Accountant</Table.Header.Cell>
                    <Table.Header.Cell width="10%">Contact</Table.Header.Cell>
                    <Table.Header.Cell width="26%">Topics</Table.Header.Cell>
                    <Table.Header.Cell width="9%" alignRight>
                        Updated
                    </Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>
            <Table.Body>
                {offPlatformServicesRequests?.map((offPlatformServicesRequest) => (
                    <OffPlatformServicesRequestsTableRow
                        key={offPlatformServicesRequest.id}
                        offPlatformServicesRequest={offPlatformServicesRequest}
                    />
                ))}
            </Table.Body>
        </Table>
    )
}
