import _find from 'lodash/find'
import _get from 'lodash/get'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useToggle } from '@scouts/helpers'
import {
    colors,
    Button,
    ButtonLink,
    ConfirmationModal,
    Flexer,
    Label,
    Loadable,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    RadioProps,
} from '@scouts/ui'

import { migrateSelfAssessments, removeCustomerSelfAssessments, searchCustomers } from '@/actions'
import { PATH_CUSTOMERS } from '@/constants'
import { CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'

import { CustomerSelfAssessmentsMigrationBody } from './CustomerSelfAssessmentsMigrationBody'

const STATE_DEFAULT = {
    isFetching: true,
    isMigrationPending: false,
    isMigrationSuccessful: false,
    customers: [] as CustomerDTO[],
    targetCustomerId: 0,
}

export const CustomerSelfAssessmentsMigration = ({ customer }: { customer: CustomerDTO }) => {
    const dispatch = useAppDispatch()
    const history = useHistory()

    const [isModalOpen, open, close] = useToggle()
    const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useToggle()
    const [state, setState] = useState(STATE_DEFAULT)

    const { id: sourceCustomerId, email: sourceCustomerEmail, fullName: sourceCustomerFullName } = customer
    const { targetCustomerId, isFetching, isMigrationPending, isMigrationSuccessful, customers } = state
    const targetCustomerEmail = _get(_find(customers, ['id', targetCustomerId]), 'email')

    const handleTargetCustomerIdChange: RadioProps['onChange'] = ({ value }) => {
        setState({ ...state, targetCustomerId: Number(value) })
    }

    const searchForCustomers = () => {
        const onSuccess = ({ customers: searchResults }: { customers: CustomerDTO[] }) => {
            const targetCustomers = searchResults.filter(({ id }) => id !== customer.id)
            setState({ ...state, isFetching: false, customers: targetCustomers })
        }

        const onError = () => {
            setState({ ...state, isFetching: false })
        }

        setState({ ...state, isFetching: true })
        dispatch(searchCustomers({ filter: sourceCustomerFullName }, { onSuccess, onError }))
    }

    const openNewProfile = () => {
        close()

        history.push(`${PATH_CUSTOMERS}/${targetCustomerId}`)
    }

    const openModal = () => {
        open()
        searchForCustomers()
    }

    const closeModal = () => {
        if (isMigrationSuccessful) {
            dispatch(removeCustomerSelfAssessments({ customerId: sourceCustomerId }))
        }

        close()
        setState(STATE_DEFAULT)
    }

    const migrate = () => {
        setState({ ...state, isMigrationPending: true })

        const onSuccess = () => {
            setState({ ...state, isMigrationSuccessful: true })
            closeConfirmationModal()
        }
        const onError = () => {
            setState({ ...state, isMigrationPending: false })
            closeConfirmationModal()
        }

        dispatch(migrateSelfAssessments({ sourceCustomerId, targetCustomerId }, { onSuccess, onError }))
    }

    return (
        <>
            <Flexer justifyEnd>
                <ButtonLink color={colors.neutralDarker} onClick={openModal}>
                    Migrate to a different client
                </ButtonLink>
            </Flexer>

            {isModalOpen && (
                <Modal onClose={closeModal}>
                    <Loadable isLoading={isFetching}>
                        <ModalHeader>
                            <ModalTitle>Migrate tax returns</ModalTitle>
                        </ModalHeader>

                        <ModalBody>
                            <CustomerSelfAssessmentsMigrationBody
                                isFetching={isFetching}
                                isMigrationSuccessful={isMigrationSuccessful}
                                sourceCustomerEmail={sourceCustomerEmail || ''}
                                sourceCustomerFullName={sourceCustomerFullName || ''}
                                targetCustomerEmail={targetCustomerEmail || ''}
                                targetCustomerId={targetCustomerId}
                                customers={customers}
                                handleTargetCustomerIdChange={handleTargetCustomerIdChange}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <ModalActions>
                                <Button isSecondary onClick={closeModal}>
                                    Close
                                </Button>
                                {isMigrationSuccessful && <Button onClick={openNewProfile}>Open new profile</Button>}
                                {!isMigrationSuccessful && (
                                    <Button
                                        onClick={openConfirmationModal}
                                        disabled={!targetCustomerId}
                                        isLoading={isMigrationPending}
                                    >
                                        Migrate
                                    </Button>
                                )}
                            </ModalActions>
                        </ModalFooter>
                    </Loadable>
                </Modal>
            )}

            {isConfirmationModalOpen && (
                <ConfirmationModal onCancel={closeConfirmationModal} onConfirm={migrate} isLoading={isMigrationPending}>
                    Please confirm that you wish to migrate all tax returns from{' '}
                    <Label background={colors.neutralLightest}>{sourceCustomerEmail}</Label> to{' '}
                    <Label background={colors.neutralLightest}>{targetCustomerEmail}</Label>
                </ConfirmationModal>
            )}
        </>
    )
}
