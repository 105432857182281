import { colors, Container, Flexer, Line, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { useSubscriptions } from '@/store/subscriptions'

import { usePagination } from '../pagination'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { SubscriptionsTable } from './SubscriptionsTable'

export const Subscriptions = () => {
    const { activeFilters } = usePagination()
    const { pagination, subscriptions, isLoading, isError, isFetching } = useSubscriptions({ options: activeFilters })

    const itemsCount = subscriptions?.length || 0
    const hasItems = itemsCount > 0

    if (isError)
        return (
            <Flexer alignCenter justifyCenter>
                <Line color={colors.neutralDarker}>Error getting subscriptions</Line>
            </Flexer>
        )

    if (isLoading && !hasItems)
        return (
            <Skeleton gap="1px" padding="24px">
                <SkeletonLine height="38px" />
                <SkeletonLine height="60px" repeat={10} />
            </Skeleton>
        )

    return (
        <Container padding="24px 24px 192px">
            {!isLoading && !hasItems && <Line color={colors.neutralDarker}>No subscriptions found</Line>}

            {subscriptions && subscriptions.length > 0 && (
                <>
                    <SubscriptionsTable subscriptions={subscriptions} isFetching={isFetching} />

                    <Spacer marginTop="24px">
                        <PaginationContainer pagination={pagination} />
                    </Spacer>
                </>
            )}
        </Container>
    )
}
