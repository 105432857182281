import { ReactNode } from 'react'

import { colors, Indicator, Indicators } from '@scouts/ui'

import { AccountantDetailsDTO, ExternalApis } from '@/models'

const Item = ({ children, isActive }: { children: ReactNode; isActive?: boolean }) => (
    <Indicator
        color={isActive ? colors.black : colors.neutralDarker}
        border={isActive ? undefined : colors.neutralLightest}
        background={isActive ? colors.mintLighter : undefined}
    >
        {children}
    </Indicator>
)

export const IntegrationIndicators = ({
    externalApis,
    hasAgentAuthorisationCredentials,
}: {
    externalApis: AccountantDetailsDTO['externalApis']
    hasAgentAuthorisationCredentials: AccountantDetailsDTO['hasAgentAuthorisationCredentials']
}) => {
    const hasGoogleConnection = externalApis?.includes(ExternalApis.Google)
    const hasMicrosoftConnection = externalApis?.includes(ExternalApis.Microsoft)
    const hasHmrcPayeApiConnection = externalApis?.includes(ExternalApis.Hmrc)

    const emailLabel = (() => {
        switch (true) {
            case !hasGoogleConnection && hasMicrosoftConnection:
                return 'OL'
            case hasGoogleConnection && !hasMicrosoftConnection:
                return 'GM'
            case hasGoogleConnection || hasMicrosoftConnection:
                return 'EM'
            default:
                return 'EM'
        }
    })()

    const emailTooltip = (() => {
        switch (true) {
            case hasGoogleConnection && hasMicrosoftConnection:
                return 'Gmail and Outlook integrations active'
            case hasGoogleConnection && !hasMicrosoftConnection:
                return 'Gmail integration active'
            case !hasGoogleConnection && hasMicrosoftConnection:
                return 'Outlook integration active'
            default:
                return 'Email integration not active'
        }
    })()

    return (
        <Indicators>
            <Item isActive={hasGoogleConnection || hasMicrosoftConnection}>
                <span title={emailTooltip}>{emailLabel}</span>
            </Item>
            <Item isActive={hasAgentAuthorisationCredentials}>
                <span title="Agent Authorisation">AA</span>
            </Item>
            <Item isActive={hasHmrcPayeApiConnection}>
                <span title="PAYE API integration">API</span>
            </Item>
        </Indicators>
    )
}
