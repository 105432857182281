import { useState } from 'react'

import { useToggle, Locale } from '@scouts/helpers'
import {
    colors,
    font,
    Button,
    ButtonLink,
    ChipButton,
    ConfirmationModal,
    Flexer,
    Form,
    Line,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Paragraph,
    TypeSelect,
    TypeSelectProps,
} from '@scouts/ui'

import { OffPlatformServicesRequestDTO } from '@/models'
import { useUpdateOffPlatformServicesRequestAccountant } from '@/store/off-platform-services-requests/hooks/use-update-off-platform-services-request-accountant'

import { useAssignableAccountants } from '@/components/off-platform-services-requests/hooks/use-assignable-accountants'

type Props = { offPlatformServicesRequest: OffPlatformServicesRequestDTO }

type ModalProps = {
    offPlatformServicesRequest: OffPlatformServicesRequestDTO
    closeModal: () => void
}

type AccountantNameComponentProps = {
    accountantFullName: OffPlatformServicesRequestDTO['accountantFullName']
    onClick: () => void
    isAssignAllowed: boolean
}

const AccountantName = ({ accountantFullName, onClick, isAssignAllowed }: AccountantNameComponentProps) => {
    if (isAssignAllowed && accountantFullName) {
        return <ButtonLink onClick={onClick}>{accountantFullName}</ButtonLink>
    }

    if (isAssignAllowed) {
        return (
            <ChipButton withBorder variant="inverted" onClick={onClick}>
                Assign
            </ChipButton>
        )
    }

    return <Line size={font.small}>{accountantFullName}</Line>
}

export const OffPlatformServicesRequestAccountant = ({ offPlatformServicesRequest }: Props) => {
    const { accountantFullName, status } = offPlatformServicesRequest

    const [isModalOpen, open, close] = useToggle()

    const isAssignAllowed = status != 'Started'

    return (
        <>
            <AccountantName accountantFullName={accountantFullName} onClick={open} isAssignAllowed={isAssignAllowed} />

            {isModalOpen && isAssignAllowed && (
                <AssignAccountantModal offPlatformServicesRequest={offPlatformServicesRequest} closeModal={close} />
            )}
        </>
    )
}

const AssignAccountantModal = ({ offPlatformServicesRequest, closeModal }: ModalProps) => {
    const { id, accountantId: currentAccountantId, accountantFullName, customerFullName } = offPlatformServicesRequest

    const assignableAccountantOptions = useAssignableAccountants()

    const { handleUpdateAccountant, isLoading } = useUpdateOffPlatformServicesRequestAccountant(id)

    const [updatedAccountantId, setUpdatedAccountantId] = useState(currentAccountantId)

    const handleUpdatedAccountantIdChange: TypeSelectProps['onChange'] = ({ value }) =>
        setUpdatedAccountantId(Number(value))

    const handleSubmit = () => {
        handleUpdateAccountant(updatedAccountantId, 'Claimed').then(closeModal)
    }

    const [isUnassignModalOpen, openUnassignModal, closeUnassignModal] = useToggle()
    const handleUnassign = () => {
        handleUpdateAccountant(null, 'Submitted').then(closeModal)
    }

    const isSubmitDisabled = !updatedAccountantId || updatedAccountantId === currentAccountantId

    return (
        <>
            <Modal onClose={closeModal}>
                <ModalHeader>
                    <ModalTitle>Assign {customerFullName} to a new accountant</ModalTitle>
                    <ModalSubtitle>This will also mark the status as Claimed</ModalSubtitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Content>
                            <TypeSelect
                                name="accountant"
                                value={updatedAccountantId || ''}
                                onChange={handleUpdatedAccountantIdChange}
                                options={assignableAccountantOptions}
                                autoClear
                                isRequired
                                showOptions
                                locale={Locale.GB}
                            />
                        </Form.Row.Content>
                    </Form.Row>
                </ModalBody>
                <ModalFooter>
                    <Flexer spaceBetween alignCenter>
                        {currentAccountantId && (
                            <ModalActions>
                                <ButtonLink color={colors.red} onClick={openUnassignModal} disabled={isLoading}>
                                    Unassign
                                </ButtonLink>
                            </ModalActions>
                        )}
                        <ModalActions>
                            <Button isSecondary onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                disabled={isSubmitDisabled}
                            >
                                Assign
                            </Button>
                        </ModalActions>
                    </Flexer>
                </ModalFooter>
            </Modal>

            {isUnassignModalOpen && (
                <ConfirmationModal onCancel={closeUnassignModal} onConfirm={handleUnassign} isLoading={isLoading}>
                    <Paragraph>
                        Are you sure you want to unassign {accountantFullName} from {customerFullName}?
                    </Paragraph>

                    <Paragraph>The status will also be set to Submitted.</Paragraph>
                </ConfirmationModal>
            )}
        </>
    )
}
