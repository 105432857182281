import {
    Button,
    ModalActionsLegacy as ModalActions,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

interface AgentAuthErrorModalProps {
    closeModal: () => void
    openMessagesModal: () => void
}

export const AgentAuthErrorModal = ({ closeModal, openMessagesModal }: AgentAuthErrorModalProps) => (
    <Modal onClose={closeModal}>
        <ModalHeader>
            <ModalTitle>HMRC agent authorisation failed</ModalTitle>
            <ModalSubtitle>
                Do you want to send a follow-up message to the client requesting they update their details with HMRC?
            </ModalSubtitle>
        </ModalHeader>
        <ModalFooter>
            <ModalActions>
                <Button isSecondary onClick={closeModal}>
                    No, thank you
                </Button>
                <Button type="submit" onClick={openMessagesModal}>
                    Yes, please
                </Button>
            </ModalActions>
        </ModalFooter>
    </Modal>
)
