import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { colors, DropdownSelect } from '@scouts/ui'

import {
    SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_DRAFTED,
    SELF_ASSESSMENT_STATUS_FILED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    SELF_ASSESSMENT_STATUS_TITLES,
} from '../../constants'

class RequestDocumentsStatus extends Component {
    constructor() {
        super()

        this.statuses = [
            {
                value: SELF_ASSESSMENT_STATUS_DRAFTED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_DRAFTED],
            },
            {
                value: SELF_ASSESSMENT_STATUS_SUBMITTED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_SUBMITTED],
                color: colors.blueLighter,
            },
            {
                value: SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW],
                color: colors.blueLighter,
            },
            {
                value: SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_READY_TO_FILE,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_READY_TO_FILE],
                color: colors.blueLighter,
            },
            {
                value: SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_FILED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_FILED],
                color: colors.mintLighter,
            },
        ]

        _bindAll(this, ['handleChange'])
    }

    handleChange({ value }) {
        const { handleStatusChange } = this.props

        handleStatusChange(value)
    }

    render() {
        const { status } = this.props
        return <DropdownSelect name="status" value={status} options={this.statuses} onChange={this.handleChange} />
    }
}

RequestDocumentsStatus.propTypes = {
    status: PropTypes.string.isRequired,
    handleStatusChange: PropTypes.func.isRequired,
}

export default connect()(RequestDocumentsStatus)
