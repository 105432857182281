import { Table } from '@scouts/ui'

import { TaxReliefClaimDTO } from '@/models'

import { TaxReliefClaimsTableRow } from './TaxReliefClaimsTableRow'

export const TaxReliefClaimsTable = ({ taxReliefClaims }: { taxReliefClaims: TaxReliefClaimDTO[] | undefined }) => (
    <Table>
        <Table.Header>
            <Table.Header.Row>
                <Table.Header.Cell width="30%">Client</Table.Header.Cell>
                <Table.Header.Cell width="15%">Status</Table.Header.Cell>
                <Table.Header.Cell width="25%">Assigned to</Table.Header.Cell>
                <Table.Header.Cell width="10%">Certificates</Table.Header.Cell>
                <Table.Header.Cell width="20%" alignRight>
                    Updated
                </Table.Header.Cell>
            </Table.Header.Row>
        </Table.Header>
        <Table.Body>
            {taxReliefClaims?.map((taxReliefClaim) => (
                <TaxReliefClaimsTableRow key={taxReliefClaim.id} taxReliefClaim={taxReliefClaim} />
            ))}
        </Table.Body>
    </Table>
)
