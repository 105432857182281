import { useDeleteAccountantReviewMutation } from '../api'

export const useDeleteAccountantReview = () => {
    const [deleteAccountantReview, { isLoading }] = useDeleteAccountantReviewMutation()

    return {
        deleteAccountantReview,
        isLoading,
    }
}
