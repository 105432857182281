import { useEffect, useState, ReactElement } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { toAlphaNumerical } from '@scouts/helpers'
import {
    colors,
    font,
    radius,
    AccountantPortalChrome,
    Button,
    Container,
    Flexer,
    Form,
    Grid,
    GridItem,
    InlineLink,
    Input,
    Line,
    Skeleton,
    SkeletonLine,
    Spacer,
} from '@scouts/ui'

import { PATH_CUSTOMER } from '@/constants'
import { formatDate } from '@/helpers'
import { CompanyDTO, CustomerDTO } from '@/models'
import { useUpdateCompany } from '@/store/companies/hooks'

import { useCustomersCompany } from '../hooks/use-customers-company'

const PATTERN_COMPANY_AUTH_CODE = '[A-Za-z0-9]{0,6}'
const PATTERN_COMPANY_UTR = '[0-9]{0,10}'

/**
 * @param {string} "ScottishPartnership"
 * @returns {string} "Scottish Partnership"
 */
const splitStringAtUppercase = (string = '') => {
    return string.split(/(?=[A-Z])/).join(' ')
}

export const CompanyProfileDetails = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const history = useHistory()
    const { company, isLoading: isLoadingCompany } = useCustomersCompany({ customerId })
    const { updateCompany, isLoading: isLoadingUpdate } = useUpdateCompany()

    const {
        companiesHouseAuthCode,
        uniqueTaxpayerReference,

        setCompaniesHouseAuthCode,
        setUniqueTaxpayerReference,
    } = useCompanyState(company)

    if (!company) return null

    if (isLoadingCompany) {
        return (
            <Skeleton padding="24px" gap="12px">
                <SkeletonLine height="34px" />
                <SkeletonLine height="38px" />
                <SkeletonLine height="72px" repeat={8} />
            </Skeleton>
        )
    }

    const { accountsNextDueDate, registeredOfficeAddress, name, companyNumber, companyType, sicCodeDescriptions } =
        company

    const { streetAddress, postcode, city, country } = registeredOfficeAddress || {}

    const sicCodes = Object.keys(sicCodeDescriptions || {}) || []
    const sicCodesString = sicCodeDescriptions
        ? sicCodes.map((code) => `${code} - ${sicCodeDescriptions[Number(code)]}`).join(', \n')
        : '-'

    const addressString = [streetAddress, city, postcode, country].filter(Boolean).join('\n')

    const isSubmitDisabled =
        companiesHouseAuthCode == company?.companiesHouseAuthCode &&
        uniqueTaxpayerReference == company?.uniqueTaxpayerReference

    const handleSubmit = () => {
        updateCompany({
            customerId,
            companyId: company.id,
            companiesHouseAuthCode,
            uniqueTaxpayerReference,
        })
    }

    return (
        <AccountantPortalChrome.Customer.Page.Container
            padding="24px"
            tabletPadding="0 36px 48px"
            desktopLargePadding="0 48px 48px"
        >
            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.Title>Company details</AccountantPortalChrome.Customer.Page.Title>
            </AccountantPortalChrome.Customer.Page.Header>

            <Container border={`1px solid ${colors.neutralLightest}`} radius={radius.large} padding="24px">
                <Form onSubmit={handleSubmit}>
                    <Grid desktopLargeColumns={2} desktopColumns={1} gap="18px">
                        <GridItem>
                            <Flexer column gap="12px">
                                <Item label="Company" value={name} />

                                <Item label="Company number" value={companyNumber} />

                                <Item
                                    label="Address"
                                    value={<span style={{ whiteSpace: 'pre-line' }}>{addressString || '-'}</span>}
                                />

                                <Flexer column gap="4px">
                                    <Line size={font.smaller} tabletSize={font.small} color={colors.text.neutral}>
                                        Companies House auth code
                                    </Line>

                                    <Input
                                        value={companiesHouseAuthCode || ''}
                                        onChange={({ value }) => setCompaniesHouseAuthCode(value)}
                                        name="companiesHouseAuthCode"
                                        formatter={toAlphaNumerical}
                                        pattern={PATTERN_COMPANY_AUTH_CODE}
                                        title="Companies House auth code must be a 6 digit code"
                                    />
                                </Flexer>

                                <Flexer column gap="4px">
                                    <Line size={font.smaller} tabletSize={font.small} color={colors.text.neutral}>
                                        Company UTR
                                    </Line>

                                    <Input
                                        value={uniqueTaxpayerReference || ''}
                                        onChange={({ value }) => setUniqueTaxpayerReference(value)}
                                        name="companyUniqueTaxpayerReference"
                                        formatter={toAlphaNumerical}
                                        pattern={PATTERN_COMPANY_UTR}
                                        title="Unique Taxpayer Reference must be a 10 digit number"
                                    />
                                </Flexer>
                            </Flexer>
                        </GridItem>
                        <GridItem>
                            <Flexer column gap="12px">
                                <Item label="Company type" value={splitStringAtUppercase(companyType || '')} />
                                <Item label="Accounts due next" value={formatDate(accountsNextDueDate)} />
                                <Item
                                    label="Nature of business (SIC)"
                                    value={<span style={{ whiteSpace: 'pre-line' }}>{sicCodesString || '-'}</span>}
                                />
                                {companyNumber && (
                                    <Item
                                        label="More details"
                                        value={
                                            <InlineLink
                                                target="_blank"
                                                href={`https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`}
                                            >
                                                View more in Companies House
                                            </InlineLink>
                                        }
                                    />
                                )}
                            </Flexer>
                        </GridItem>
                    </Grid>

                    <Spacer marginTop="24px">
                        <Flexer gap="12px">
                            <Button type="submit" disabled={isSubmitDisabled} isLoading={isLoadingUpdate}>
                                Save
                            </Button>
                            <Button
                                onClick={() => history.push(generatePath(PATH_CUSTOMER, { customerId }))}
                                isSecondary
                            >
                                Back to profile
                            </Button>
                        </Flexer>
                    </Spacer>
                </Form>
            </Container>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}

const Item = ({ label, value }: { label: string; value?: string | number | null | ReactElement }) => (
    <Flexer column gap="4px">
        <Line size={font.smaller} tabletSize={font.small} color={colors.text.neutral}>
            {label}
        </Line>
        <Line>{value ? value : '-'}</Line>
    </Flexer>
)

function useCompanyState(company?: CompanyDTO | null) {
    const [companiesHouseAuthCode, setCompaniesHouseAuthCode] = useState<string | null>(null)
    const [uniqueTaxpayerReference, setUniqueTaxpayerReference] = useState<string | null>(null)

    // Update state when company is loaded
    useEffect(() => {
        if (company && company.companiesHouseAuthCode !== null) {
            setCompaniesHouseAuthCode(company.companiesHouseAuthCode)
        }

        if (company && company.uniqueTaxpayerReference !== null) {
            setUniqueTaxpayerReference(company.uniqueTaxpayerReference)
        }
    }, [company])

    return { companiesHouseAuthCode, setCompaniesHouseAuthCode, uniqueTaxpayerReference, setUniqueTaxpayerReference }
}
