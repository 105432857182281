import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, Badge, ButtonLink, Line } from '@scouts/ui'

import { PATH_CUSTOMERS } from '@/constants'
import { CustomerDTO } from '@/models'

import { ClickToCopyProvider } from '@/components/ClickToCopyProvider'

import { PartnerHelpModal } from '../partner/PartnerHelpModal'

export const CustomerProfilePartnerDetails = ({ customer }: { customer: CustomerDTO }) => {
    const history = useHistory()

    const [isPartnerHelpModalOpen, openPartnerHelpModal, closePartnerHelpModal] = useToggle()

    const { id: customerId, partnerFullName, partnerEmail } = customer

    const shouldDisplayPartnerHelp = !!partnerEmail || !!partnerFullName

    return (
        <>
            <Container>
                <Line size={font.smaller} color={colors.neutralDarker}>
                    Partner / Property co-owner{' '}
                    {shouldDisplayPartnerHelp && (
                        <ButtonLink isSmall onClick={openPartnerHelpModal}>
                            <Badge>?</Badge>
                        </ButtonLink>
                    )}
                </Line>

                <ButtonLink isSmall onClick={() => history.push(`${PATH_CUSTOMERS}/${customerId}/partner`)}>
                    {partnerFullName ? 'Edit' : 'Add'}
                </ButtonLink>
            </Container>

            {partnerFullName && (
                <Field>
                    <ClickToCopyProvider>{partnerFullName}</ClickToCopyProvider>
                </Field>
            )}

            {partnerEmail && (
                <Field>
                    <ClickToCopyProvider>{partnerEmail}</ClickToCopyProvider>
                </Field>
            )}

            {isPartnerHelpModalOpen && (
                <PartnerHelpModal closeModal={closePartnerHelpModal} hasEmail={!!partnerEmail} />
            )}
        </>
    )
}

const Container = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`

const Field = styled.div`
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 6px;
    font-size: ${font.small};
`
