import { useAccountantMeeting } from '@/store/accountant-meetings/hooks/use-accountant-meeting'

import { useAccountantMeetingIdFromPath } from '../hooks/use-accountant-meeting-id-from-path'
import { useCustomerAccountantMeetings } from '../hooks/use-customer-accountant-meetings'

import { AccountantMeeting } from './AccountantMeeting'

export const AccountantMeetingContainer = ({ customerId }: { customerId: number }) => {
    const activeAccountantMeetingId = useAccountantMeetingIdFromPath()
    const { defaultAccountantMeetingId, isFetching } = useCustomerAccountantMeetings({ customerId })

    const accountantMeetingId = activeAccountantMeetingId || defaultAccountantMeetingId || 0

    const { accountantMeeting } = useAccountantMeeting({ accountantMeetingId })

    if (activeAccountantMeetingId === 0 || !accountantMeeting || isFetching) return null

    return <AccountantMeeting accountantMeeting={accountantMeeting} customerId={customerId} />
}
