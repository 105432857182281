import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeLatest } from 'redux-saga/effects'

import { PausedStatus, UserType } from '@/models'

import {
    receiveAccountant,
    receiveAccountants,
    receiveAccountantsSuitableFor,
    receiveSuccessAlerts,
    requestAccountant,
    requestUser,
    CREATE_ACCOUNTANT,
    REQUEST_ACCOUNTANT,
    REQUEST_ACCOUNTANTS,
    REQUEST_ACCOUNTANTS_SUITABLE_FOR,
    SAVE_ACCOUNTANT,
    SAVE_ACCOUNTANTS_PAUSED_HISTORY,
} from '../actions'
import { handleError } from '../handlers'
import { apiCall, formatDate } from '../helpers'

export default function* accountantsSagas() {
    yield takeLatest(REQUEST_ACCOUNTANTS, fetchAccountants)
    yield takeLatest(REQUEST_ACCOUNTANTS_SUITABLE_FOR, fetchAccountantsSuitableFor)
    yield takeLatest(REQUEST_ACCOUNTANT, fetchAccountant)
    yield takeLatest(SAVE_ACCOUNTANT, saveAccountant)
    yield takeLatest(CREATE_ACCOUNTANT, createAccountant)
    yield takeLatest(SAVE_ACCOUNTANTS_PAUSED_HISTORY, saveAccountantsPausedHistory)
}

function* fetchAccountants() {
    try {
        yield put(showLoading())
        const response = yield apiCall(`accountants`)
        yield put(receiveAccountants(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchAccountantsSuitableFor(action) {
    const customerId = _get(action, ['data', 'customerId'])
    const onSuccess = _get(action, ['meta', 'onSuccess'])
    const onError = _get(action, ['meta', 'onError'])

    try {
        yield put(showLoading())
        const response = yield apiCall(`accountants/suitableFor?customerId=${customerId}`)
        yield put(receiveAccountantsSuitableFor({ customerId, items: response.data }))

        if (onSuccess) onSuccess(response.data)
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError()
    } finally {
        yield put(hideLoading())
    }
}

function* fetchAccountant(action) {
    const onSuccess = _get(action, ['meta', 'onSuccess'])
    try {
        yield put(showLoading())
        const response = yield apiCall(`accountants/${action.data.accountantId}`)
        yield put(receiveAccountant(response.data))

        if (onSuccess) onSuccess(response.data)
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* saveAccountant(action) {
    const onSuccess = _get(action, ['meta', 'onSuccess'])
    try {
        yield put(showLoading())
        const response = yield apiCall(`accountants/${action.data.accountantId}`, 'put', action.data.accountant)
        yield put(receiveAccountant(response.data))
        if (!action.meta.hideMessage) {
            yield put(receiveSuccessAlerts('Accountant profile saved'))
        }

        if (onSuccess) onSuccess(response.data)
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* createAccountant(action) {
    const { onSuccess, onError } = action.meta

    const isTaxScoutsSupport = _get(action, ['data', 'accountant', 'isTaxScoutsSupport'], false)

    try {
        yield put(showLoading())
        const response = yield apiCall(`accountants`, 'post', action.data.accountant)
        yield put(receiveAccountant(response.data))

        if (onSuccess) onSuccess(response.data)

        if (isTaxScoutsSupport) {
            yield put(receiveSuccessAlerts('Support user created'))
        } else {
            yield put(receiveSuccessAlerts('Accountant created'))
        }
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError(e)
    } finally {
        yield put(hideLoading())
    }
}

function* saveAccountantsPausedHistory(action) {
    try {
        yield put(showLoading())
        const userType = _get(action, ['meta', 'userType'])
        const response = yield apiCall(`accountantpausedhistories`, 'post', action.data)
        const scheduledEndDate = _get(response, ['data', 'scheduledEndDate'])
        const pausedStatusType = _get(response, ['data', 'pausedStatusType'])
        const accountantId = _get(response, ['data', 'accountantId'])

        if (pausedStatusType === PausedStatus.Unavailable) {
            yield put(receiveSuccessAlerts(`Status set to Paused until ${formatDate(scheduledEndDate)}`))
        } else {
            yield put(receiveSuccessAlerts(`Status set to Paused`))
        }

        if (userType === UserType.System) {
            yield put(requestAccountant(accountantId))
        } else {
            yield put(requestUser())
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}
