import { createSelector } from '@reduxjs/toolkit'

import { EXTERNAL_API_GOOGLE, EXTERNAL_API_HMRC, EXTERNAL_API_MICROSOFT, EXTERNAL_API_NYLAS } from '../constants'
import { isAdmin } from '../helpers'

const getUserSlice = (state) => state.user

export const getUser = createSelector([getUserSlice], (user) => {
    const { firstName, lastName, externalApis } = user

    const fullName = `${(firstName || '').trim()} ${(lastName || '').trim()}`.trim()

    const hasGoogleConnection = externalApis?.some((api) => api === EXTERNAL_API_GOOGLE)
    const hasHmrcPayeApiConnection = externalApis?.some((api) => api === EXTERNAL_API_HMRC)
    const hasMicrosoftConnection = externalApis?.some((api) => api === EXTERNAL_API_MICROSOFT)
    const hasNylasApiConnection = externalApis?.some((api) => api === EXTERNAL_API_NYLAS)

    return {
        ...user,
        fullName,
        hasGoogleConnection,
        hasHmrcPayeApiConnection,
        hasMicrosoftConnection,
        hasNylasApiConnection,
        isAdminUser: isAdmin(user),
    }
})
