import { useDeleteTaxReliefClaimFileMutation } from '../api'

export const useDeleteTaxReliefClaimFile = () => {
    const [deleteTaxReliefClaimFile, { isLoading }] = useDeleteTaxReliefClaimFileMutation()

    return {
        deleteTaxReliefClaimFile,
        isLoading,
    }
}
