import { useState } from 'react'
import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, ConfirmationModal, DropdownButton, File, Folder, Line, Spinner } from '@scouts/ui'

import { getFileTypeTitle } from '@/domain'
import { formatDate } from '@/helpers'
import { CompanyReturnFileDTO, FileTypeTypes } from '@/models'

import { useCompanyFileHandlers } from '../hooks/use-company-file-handlers'

import { UploadButton } from './UploadButton'

interface DocumentContainerProps {
    title: string
    files?: CompanyReturnFileDTO[]
    fileTypes?: FileTypeTypes[]
    isLoading: boolean
    shouldDisplayUpload?: boolean
}

interface DeletableFileState {
    fileId: CompanyReturnFileDTO['id']
    fileName: CompanyReturnFileDTO['fileName']
}

export const DocumentContainer = ({
    title,
    files,
    fileTypes,
    isLoading,
    shouldDisplayUpload = true,
}: DocumentContainerProps) => {
    const { handleDeleteFile, handleDownload, isLoadingDelete } = useCompanyFileHandlers()

    const [isDeleteConfirmationModalOpen, openDeleteConfirmationModal, closeDeleteConfirmationModal] = useToggle()

    const [deletableFile, setDeletableFile] = useState<DeletableFileState | null>()

    const handleOpenDeleteConfirmation = ({ fileId, fileName }: DeletableFileState) => {
        setDeletableFile({ fileId, fileName })
        openDeleteConfirmationModal()
    }

    const handleConfirmDeleteFile = () => {
        if (deletableFile?.fileId) {
            handleDeleteFile({ fileId: deletableFile.fileId })
            closeDeleteConfirmationModal()
        }
    }

    const count = files?.length || 0
    const hasFiles = count > 0

    return (
        <>
            <Folder>
                <Folder.Header>
                    <Folder.Header.Title fileCount={count}>{title}</Folder.Header.Title>

                    {shouldDisplayUpload && <UploadButton fileTypes={fileTypes} />}
                </Folder.Header>
                <Folder.Content>
                    {hasFiles ? (
                        <Folder.Content.List>
                            <Folder.Content.List.Header
                                key="header"
                                columns="1fr 2fr 1fr 30px"
                                columnItems={['Type', 'File', 'Uploaded at', '']}
                            />
                            {files?.map((file) => (
                                <Folder.Content.List.Item columns={1} desktopColumns="1fr 2fr 1fr 30px" key={file.id}>
                                    <Line size={font.small}>
                                        {file.fileType ? getFileTypeTitle(file.fileType) : ''}
                                    </Line>
                                    <FileWrapper>
                                        <File
                                            background={colors.blueLightest}
                                            onClick={() => handleDownload({ fileId: file.id })}
                                        >
                                            {file.fileName}
                                        </File>
                                    </FileWrapper>
                                    <Line size={font.small}>{formatDate(file.uploadedDateUTC)}</Line>
                                    <DropdownButton
                                        alignRight
                                        actions={[
                                            {
                                                title: 'Download',
                                                onClick: () => handleDownload({ fileId: file.id }),
                                            },
                                            {
                                                title: 'Delete',
                                                color: colors.red,
                                                onClick: () =>
                                                    handleOpenDeleteConfirmation({
                                                        fileId: file.id,
                                                        fileName: file.fileName,
                                                    }),
                                            },
                                        ]}
                                    />
                                </Folder.Content.List.Item>
                            ))}
                        </Folder.Content.List>
                    ) : (
                        <>
                            {isLoading && (
                                <Folder.Content.Empty>
                                    <Spinner isSmall />
                                </Folder.Content.Empty>
                            )}

                            {!isLoading && <Folder.Content.Empty />}
                        </>
                    )}
                </Folder.Content>
            </Folder>

            {isDeleteConfirmationModalOpen && (
                <ConfirmationModal
                    onConfirm={handleConfirmDeleteFile}
                    onCancel={closeDeleteConfirmationModal}
                    isLoading={isLoadingDelete}
                >
                    Are you sure you want to delete {deletableFile?.fileName} ?
                </ConfirmationModal>
            )}
        </>
    )
}

const FileWrapper = styled.div`
    min-width: 0;
`
