import { font, Line } from '@scouts/ui'

import { IdentityVerificationDTO } from '@/models'

interface VerificationNotesProps {
    notes: IdentityVerificationDTO['notes']
}

export const VerificationNotes = ({ notes }: VerificationNotesProps) => {
    if (!notes) return null

    return (
        <Line size={font.small}>
            Note added: <i>"{notes}"</i>
        </Line>
    )
}
