import { generatePath, Redirect } from 'react-router-dom'

import { PATH_CUSTOMER_COMPANY_RETURN } from '@/constants'
import { useCompanyReturns } from '@/store/company-returns/hooks'

import { useGetCompanyReturnParams } from './hooks/use-get-params'

import { CompanyReturnSkeleton } from './CompanyReturnSkeleton'

export const CompanyReturnRouter = () => {
    const { customerId, companyId, companyReturnId, hasAllCompanyReturnParams } = useGetCompanyReturnParams()

    const { companyReturns, isLoading: isLoadingCompanyReturns } = useCompanyReturns({
        customerId,
        companyId,
        skip: hasAllCompanyReturnParams,
    })

    if (hasAllCompanyReturnParams) {
        return (
            <Redirect
                push={false}
                to={generatePath(PATH_CUSTOMER_COMPANY_RETURN, { customerId, companyId, companyReturnId })}
            />
        )
    }

    if (isLoadingCompanyReturns) return <CompanyReturnSkeleton />

    // If we don't have the company return id, redirect to the first company return
    if (companyReturns && companyReturns.length) {
        const firstCompanyReturnId = companyReturns?.[0].id
        return (
            <Redirect
                push={false}
                to={generatePath(PATH_CUSTOMER_COMPANY_RETURN, {
                    customerId,
                    companyId,
                    companyReturnId: firstCompanyReturnId,
                })}
            />
        )
    }

    return null
}
