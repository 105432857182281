import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { AccountantPortalChrome, Flexer } from '@scouts/ui'

import { requestCustomerDiscounts } from '@/actions'
import { getUser } from '@/selectors'
import { useAppDispatch } from '@/store'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { useCustomerSelfAssessments } from '../hooks/use-customer-self-assessments'

import { AgentAuth } from '../agent-auth/AgentAuth'
import { Verification } from '../verification/Verification'
import { CustomerOverviewCompanyReturns } from './company-returns/CustomerOverviewCompanyReturns'
import { CustomerOverviewAccountantMeetings } from './CustomerOverviewAccountantMeetings'
import { CustomerOverviewTaxRegistration } from './CustomerOverviewTaxRegistration'
import { DeleteCustomerData } from './DeleteCustomerData'
import { CustomerOverviewProductBundles } from './product-bundles/CustomerOverviewProductBundles'
import { CustomerOverviewSelfAssessments } from './self-assessments/CustomerOverviewSelfAssessments'
import { CustomerOverviewTaxConsultations } from './tax-consultations/CustomerOverviewTaxConsultations'

export const CustomerOverview = () => {
    const dispatch = useAppDispatch()

    const { customer } = useCustomerFromPath()
    const { id: customerId } = customer

    const { selfAssessments, hasSelfAssessments } = useCustomerSelfAssessments({ customerId })

    const { isAdminUser } = useSelector(getUser)

    useEffect(() => {
        dispatch(requestCustomerDiscounts({ customerId }))
    }, [dispatch, customerId])

    const shouldDisplayDeleteUser = isAdminUser && !hasSelfAssessments

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.Title>Overview</AccountantPortalChrome.Customer.Page.Title>
            </AccountantPortalChrome.Customer.Page.Header>

            <Flexer column gap="24px" tabletGap="48px">
                <CustomerOverviewCompanyReturns />

                <CustomerOverviewSelfAssessments customer={customer} customerSelfAssessments={selfAssessments} />

                <CustomerOverviewAccountantMeetings customerId={customer.id} />

                <CustomerOverviewTaxConsultations customerId={customerId} />

                <CustomerOverviewProductBundles customerId={customerId} />

                <CustomerOverviewTaxRegistration />

                <Verification customer={customer} />

                <AgentAuth customer={customer} />

                {shouldDisplayDeleteUser && <DeleteCustomerData customerId={customer.id} />}
            </Flexer>
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
