import { useState } from 'react'
import { useSelector } from 'react-redux'

import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Select,
} from '@scouts/ui'

import { saveProductBundle } from '@/actions'
import { TAX_YEARS } from '@/constants'
import { CustomerDTO, ProductBundleDetailsDTO } from '@/models'
import { getCustomerSelfAssessments, getProductBundleById, getSelfAssessmentById } from '@/selectors'
import { useAppDispatch, RootState } from '@/store'

interface ProductBundleLinkedSelfAssessmentModalProps {
    customerId: CustomerDTO['id']
    handleClose: () => void
    productBundleId: ProductBundleDetailsDTO['id']
}

export const ProductBundleLinkedSelfAssessmentModal = ({
    customerId,
    handleClose,
    productBundleId,
}: ProductBundleLinkedSelfAssessmentModalProps) => {
    const dispatch = useAppDispatch()
    const [newLinkedSelfAssessmentId, setNewLinkedSelfAssessmentId] = useState('')
    const { selfAssessmentId } = useSelector((state) => getProductBundleById(state, { productBundleId }))

    const { taxYear: currentSelfAssessmentTaxYear, id: currentSelfAssessmentId } = useSelector(
        (state: RootState) => getSelfAssessmentById(state, { selfAssessmentId }) || {}
    )

    const customerSelfAssessments = useSelector((state: RootState) => getCustomerSelfAssessments(state, { customerId }))

    const customerSelfAssessmentsNotInBundle = customerSelfAssessments.filter(
        ({ id }) => id !== currentSelfAssessmentId
    )

    const handleSubmit = () => {
        if (!newLinkedSelfAssessmentId) return

        const updatedSelfAssessmentId = newLinkedSelfAssessmentId === 'none' ? null : Number(newLinkedSelfAssessmentId)

        dispatch(
            saveProductBundle(
                productBundleId,
                { selfAssessmentId: updatedSelfAssessmentId },
                {
                    onSuccess: () => {
                        handleClose()
                    },
                }
            )
        )
    }

    return (
        <Modal onClose={handleClose}>
            <ModalHeader>
                <ModalTitle>Edit linked tax return</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Form.Row>
                    <Form.Row.Title>Current tax return</Form.Row.Title>
                    <Form.Row.Content>
                        <strong>
                            {selfAssessmentId ? (
                                <>Tax year {TAX_YEARS[currentSelfAssessmentTaxYear as keyof typeof TAX_YEARS]}</>
                            ) : (
                                'None'
                            )}
                        </strong>
                    </Form.Row.Content>
                </Form.Row>
                <Form.Row>
                    <Form.Row.Title>Change to</Form.Row.Title>
                    <Form.Row.Content>
                        <Select
                            name="newLinkedSelfAssessmentId"
                            value={newLinkedSelfAssessmentId}
                            onChange={({ value }) => setNewLinkedSelfAssessmentId(value)}
                            options={[
                                { title: 'Choose…' },
                                { title: 'None', value: 'none' },
                                ...customerSelfAssessmentsNotInBundle.map((assessment) => ({
                                    value: assessment.id,
                                    title: TAX_YEARS[assessment.taxYear as keyof typeof TAX_YEARS],
                                })),
                            ]}
                        />
                    </Form.Row.Content>
                </Form.Row>
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button isSecondary onClick={handleClose}>
                        Close
                    </Button>
                    <Button onClick={handleSubmit}>Change</Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}
