import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { hexToRgbA, useQueryParams } from '@scouts/helpers'
import { colors, font, shadows, Badge, ButtonLink } from '@scouts/ui'

import { QUERY_PARAM_ESCALATION_EDIT } from '../../constants'
import { formatDateWithTime, formatTimeFromNow, formatTimeTodayAndDateIfLater } from '../../helpers'
import { getHasPriorityEscalationNotesByEscalationId } from '../../selectors/notes'

const Container = styled.div`
    position: relative;
`

const IconPriority = styled.div`
    cursor: default;
    display: inline-block;
    position: absolute;
    top: -11px;
    left: -11px;
    font-weight: ${font.weight.medium};
`

const Content = styled.div`
    font-size: ${font.small};
    line-height: 1.5;
    white-space: pre-line;
    overflow-wrap: anywhere;
    background: ${colors.white};
    padding: 18px;
    box-shadow: ${shadows.low};

    ${({ isPriority, isClosed }) => isPriority && !isClosed && `border: 2px solid ${colors.red};`}
    ${({ isClosed }) => isClosed && `background: ${hexToRgbA(colors.white, 0.25)};`}
`

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${font.smaller};
    margin-top: 9px;
    padding: 0 2px;
`

const FooterAction = styled.div``

const FooterText = styled.div``

const Status = styled.div`
    color: ${colors.mint};
    font-size: ${font.smaller};
    margin-top: 12px;
`

const CustomerEscalationsListItem = ({ escalation }) => {
    const history = useHistory()
    const { id: escalationId, occurredDateUTC, resolvedDateUTC, summary, supportUserFullName } = escalation
    const isClosed = !!resolvedDateUTC

    const hasPriorityNotes = useSelector((state) =>
        getHasPriorityEscalationNotesByEscalationId(state, { escalationId })
    )

    const { overwriteQueryParams } = useQueryParams()

    const handleOpenEditEscalationModal = () => {
        const newLocation = overwriteQueryParams({ view: QUERY_PARAM_ESCALATION_EDIT, escalationId: escalation.id })
        history.push(newLocation)
    }

    return (
        <Container>
            {hasPriorityNotes && !isClosed && (
                <IconPriority>
                    <Badge background={colors.red}>!</Badge>
                </IconPriority>
            )}

            <Content isClosed={isClosed} isPriority={hasPriorityNotes}>
                {summary}
                {isClosed && <Status>✓ Closed {formatTimeFromNow(resolvedDateUTC)}</Status>}
            </Content>
            <Footer>
                <FooterAction>
                    <ButtonLink isSmall onClick={handleOpenEditEscalationModal}>
                        Details
                    </ButtonLink>
                </FooterAction>
                <FooterText title={formatDateWithTime(occurredDateUTC)}>
                    {formatTimeTodayAndDateIfLater(occurredDateUTC, ' ')} · {supportUserFullName}
                </FooterText>
            </Footer>
        </Container>
    )
}

CustomerEscalationsListItem.propTypes = {
    escalation: PropTypes.object.isRequired,
}

export default CustomerEscalationsListItem
