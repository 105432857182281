import { Indicators } from '@scouts/ui'

import {
    AgentAuthorisationStatusTypes,
    CustomerDTO,
    IdentityVerificationStatusTypes,
    TaxRegistrationStatusTypes,
} from '@/models'

import { CustomerIndicatorsAgentAuth } from './CustomerIndicatorsAgentAuth'
import { CustomerIndicatorsIdentityVerification } from './CustomerIndicatorsIdentityVerification'
import { CustomerIndicatorsTaxCode } from './CustomerIndicatorsTaxCode'
import { CustomerIndicatorsTaxRegistration } from './CustomerIndicatorsTaxRegistration'
import { CustomerIndicatorsUTR } from './CustomerIndicatorsUTR'

interface CustomerIndicatorsProps {
    agentAuthorisationStatus?: AgentAuthorisationStatusTypes
    identityVerificationStatus?: IdentityVerificationStatusTypes
    taxRegistrationStatus?: TaxRegistrationStatusTypes
    uniqueTaxpayerReference: CustomerDTO['uniqueTaxpayerReference']
    payThroughTaxCodeRequested?: boolean | null
    hideAgentAuthorisation?: boolean
    hideTaxRegistration?: boolean
    hidePayThroughTaxCodeRequested?: boolean
}

export const CustomerIndicators = ({
    agentAuthorisationStatus,
    identityVerificationStatus,
    taxRegistrationStatus,
    uniqueTaxpayerReference,
    payThroughTaxCodeRequested,
    hideAgentAuthorisation,
    hidePayThroughTaxCodeRequested,
    hideTaxRegistration,
}: CustomerIndicatorsProps) => {
    return (
        <Indicators>
            <CustomerIndicatorsUTR uniqueTaxpayerReference={uniqueTaxpayerReference} />
            {!hideTaxRegistration && (
                <CustomerIndicatorsTaxRegistration taxRegistrationStatus={taxRegistrationStatus} />
            )}
            {!hideAgentAuthorisation && (
                <CustomerIndicatorsAgentAuth agentAuthorisationStatus={agentAuthorisationStatus} />
            )}
            <CustomerIndicatorsIdentityVerification identityVerificationStatus={identityVerificationStatus} />
            {!hidePayThroughTaxCodeRequested && (
                <CustomerIndicatorsTaxCode payThroughTaxCodeRequested={payThroughTaxCodeRequested} />
            )}
        </Indicators>
    )
}
