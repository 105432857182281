import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AccountantPortalChrome, Spacer } from '@scouts/ui'

import { deleteCustomerDiscount, requestCustomerDiscounts } from '@/actions'
import { CustomerDiscountDTO } from '@/models'
import { getDiscountsByCustomerId, getUser } from '@/selectors'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { CustomerDiscountsAdd } from './CustomerDiscountsAdd'
import { CustomerDiscountsTable } from './CustomerDiscountsTable'

export const CustomerDiscountsContainer = () => {
    const dispatch = useDispatch()

    const { customer } = useCustomerFromPath()
    const { id: customerId } = customer

    useEffect(() => {
        dispatch(requestCustomerDiscounts({ customerId }))
    }, [dispatch, customerId])

    const customerDiscounts = useSelector((state) => getDiscountsByCustomerId(state, { customerId }))
    const hasDiscounts = customerDiscounts.length > 0

    const { isAdminUser } = useSelector(getUser)

    const handleDeleteDiscount = (discountId: CustomerDiscountDTO['id']) => {
        dispatch(deleteCustomerDiscount(discountId))
    }

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Header>
                <AccountantPortalChrome.Customer.Page.Title>Discounts</AccountantPortalChrome.Customer.Page.Title>
                {isAdminUser && (
                    <Spacer>
                        <CustomerDiscountsAdd customerId={customerId} />
                    </Spacer>
                )}
            </AccountantPortalChrome.Customer.Page.Header>

            {hasDiscounts && (
                <Spacer margin="30px 0">
                    <CustomerDiscountsTable
                        customerDiscounts={customerDiscounts}
                        handleDeleteDiscount={handleDeleteDiscount}
                    />
                </Spacer>
            )}
        </AccountantPortalChrome.Customer.Page.Container>
    )
}
