export const ENDPOINTS = {
    ACCOUNTANT_MEETINGS: {
        GET: {
            LIST: '/accountantmeetings',
            SINGLE: '/accountantmeetings/:accountantMeetingId',
            CUSTOMER: 'customers/:customerId/accountantmeetings',
        },
        DELETE: '/accountantmeetings/:accountantMeetingId',
        PUT: {
            UPDATE_STATUS: '/accountantmeetings/:accountantMeetingId/status/:value',
        },
    },
    ACCOUNTANT_STATISTICS: {
        GET: {
            ALL: '/accountantstatistics',
            STATUS: '/accountantstatistics/status',
        },
    },
    ACCOUNTANT_REVIEWS: {
        GET: '/accountants/:accountantId/reviews',
        POST: '/accountantreviews',
        PUT: '/accountantreviews/:reviewId',
        DELETE: '/accountantreviews/:reviewId',
    },
    AGENT_AUTHORISATION: {
        GET: {
            CUSTOMERS: 'customers/:customerId/agentauthorisations',
            SINGLE: 'agentauthorisations/:agentAuthorisationId',
        },
        PUT: 'agentauthorisations/:agentAuthorisationId',
        POST: {
            CREATE: 'agentauthorisations',
            REQUEST_HMRC: 'agentauthorisations/request',
            POLL_HMRC: 'agentauthorisations/:agentAuthorisationId/poll',
            STATUS_HMRC: 'agentauthorisations/:agentAuthorisationId/status',
            CODE_HMRC: 'agentauthorisations/:agentAuthorisationId/code',
        },
    },
    BANK_ACCOUNTS: {
        GET: 'customers/:customerId/bankaccount',
        PUT: 'customers/:customerId/bankaccount',
    },
    COMPANIES: {
        GET: {
            SINGLE: 'customers/:customerId/companies/:companyId',
            ALL: 'customers/:customerId/companies',
        },
        POST: 'customers/:customerId/companies',
        PUT: {
            SINGLE: 'customers/:customerId/companies/:companyId',
        },
    },
    COMPANY_RETURNS: {
        POST: {
            CREATE: 'customers/:customerId/companies/:companyId/returns',
            REFUND: 'customers/:customerId/companies/:companyId/returns/:companyReturnId/refund',
        },
        GET: {
            LIST: 'companyreturns/search',
            ALL: 'customers/:customerId/companies/:companyId/returns',
            SINGLE: 'customers/:customerId/companies/:companyId/returns/:companyReturnId',
            STATISTICS: 'companyreturns/statistics',
            AGREEMENT: 'customers/:customerId/companies/:companyId/returns/:companyReturnId/agreement',
        },
        PUT: {
            UPDATE: 'customers/:customerId/companies/:companyId/returns/:companyReturnId',
            UPDATE_STATUS: 'customers/:customerId/companies/:companyId/returns/:companyReturnId/status/:status',
        },
        DELETE: 'customers/:customerId/companies/:companyId/returns/:companyReturnId',
    },
    COMPANY_RETURN_FILES: {
        POST: 'customers/:customerId/companies/:companyId/returns/:companyReturnId/files',
        GET: {
            ALL: 'customers/:customerId/companies/:companyId/returns/:companyReturnId/files',
            DOWNLOAD_FILE:
                'customers/:customerId/companies/:companyId/returns/:companyReturnId/files/:fileId/downloadlink',
            DOWNLOAD_ZIP: 'customers/:customerId/companies/:companyId/returns/:companyReturnId/files/zip',
        },
        DELETE: 'customers/:customerId/companies/:companyId/returns/:companyReturnId/files/:fileId',
    },
    CUSTOMER_ADDRESSES: {
        GET: '/customers/:customerId/addresses',
    },
    CUSTOMERS_ESCALATIONS: {
        GET: {
            COUNT: '/customers/:customerId/escalations/count',
        },
    },
    CUSTOMERS_PRODUCT_SEARCH: {
        GET: '/customers/productsearch',
    },
    CUSTOMERS_STATISTICS: {
        GET: '/customers/customerstatistics',
    },
    DISCOUNT_SCHEMES: {
        GET: 'discountschemes',
        POST: 'discountschemes',
        PUT: 'discountschemes/:discountSchemeId',
        DELETE: 'discountschemes/:discountSchemeId',
    },
    EXTERNAL_MESSAGES: {
        SYNC_ACCOUNTANT: '/externalmessages',
        SYNC_CUSTOMER: '/externalmessages/customers/:customerId',
    },
    IDENTITY_VERIFICATIONS: {
        GET: {
            CUSTOMERS: 'customers/:customerId/identityverifications',
            SINGLE: 'identityverifications/:identityVerificationId',
        },
        PUT: 'identityverifications/:identityVerificationId',
        POST: 'identityverifications',
    },
    LOGGED_EVENTS: {
        GET: '/customers/:customerId/loggedEvents',
        PUT: '/selfassessments/:selfAssessmentId/loggedEvents',
    },
    MESSAGES: {
        GET: '/customers/:customerId/messages',
        POST: '/messages',
        POST_BULK: '/messages/bulk',
        GET_ATTACHMENT_LINK: '/emailmessageattachments/:fileId/downloadlink',
    },
    MESSAGE_SUGGESTIONS: {
        GET: {
            SINGLE: 'customers/:customerId/messages/suggestions',
        },
    },
    MESSAGE_TEMPLATES: {
        GET: '/messagetemplates',
        POST: '/messagetemplates',
        PUT: '/messagetemplates/:id',
        DELETE: '/messagetemplates/:id',
    },
    NYLAS_SCHEDULING_PAGES: {
        GET: '/accountantschedulingpages',
        POST: '/accountantschedulingpages',
        PUT: '/accountantschedulingpages/:id',
        DELETE: '/accountantschedulingpages',
        GET_CALENDARS: '/accountantschedulingpages/:id/calendars',
    },
    PARTNERS: {
        GET: '/partners/:customerId',
        PUT: '/partners/:customerId',
    },
    SUBSCRIPTIONS: {
        GET: {
            LIST: '/subscriptions',
        },
    },
    SUPPORT_USERS: {
        GET: {
            LIST: '/support',
        },
    },
    TAX_CONSULTATIONS: {
        GET: {
            SINGLE: '/taxconsultations/:taxConsultationId',
            LIST: '/taxconsultations/',
            STATISTICS: '/taxconsultations/statistics',
            SUMMARY_CONTENT: '/taxconsultations/:taxConsultationId/summarycontent',
            CUSTOMER: '/customers/:customerId/taxconsultations',
            AGREEMENT: '/taxconsultations/:taxConsultationId/agreement',
        },
        POST: {
            CREATE: '/taxconsultations',
            REFUND: '/taxconsultations/:taxConsultationId/refund',
        },
        PUT: {
            UPDATE_STATUS: '/taxconsultations/:taxConsultationId/status/:status',
            SUMMARY_CONTENT: '/taxconsultations/:taxConsultationId/summarycontent',
        },
        DELETE: '/taxconsultations/:taxConsultationId',
    },
    TAX_CONSULTATION_FILES: {
        GET: {
            ALL: '/taxconsultations/:taxConsultationId/files',
            DOWNLOAD_FILE: '/taxconsultationfiles/:taxConsultationFileId/downloadlink',
        },
        POST: '/taxconsultationfiles',
        DELETE: '/taxconsultationfiles/:taxConsultationFileId',
    },
    TAX_RELIEF_CLAIMS: {
        GET: {
            LIST: '/taxreliefclaims',
            SINGLE: '/taxreliefclaims/:taxReliefClaimId',
            FILES: '/taxreliefclaims/:taxReliefClaimId/files',
            DOWNLOAD_FILE: '/taxreliefclaims/:taxReliefClaimId/files/:taxReliefClaimFileId/downloadlink',
            DOWNLOAD_FILES_ZIP: '/taxreliefclaims/:taxReliefClaimId/files/zip',
            CUSTOMER: '/customers/:customerId/taxreliefclaims',
        },
        POST: {
            UPLOAD_FILE: '/taxreliefclaims/:taxReliefClaimId/files',
            REFUND: '/taxreliefclaims/:taxReliefClaimId/refund',
        },
        DELETE: {
            FILE: '/taxreliefclaims/:taxReliefClaimId/files/:taxReliefClaimFileId/',
            SINGLE: '/taxreliefclaims/:taxReliefClaimId',
        },
        PUT: '/taxreliefclaims/:taxReliefClaimId',
    },
    TAX_REGISTRATIONS: {
        GET: {
            LIST: '/taxregistrations/',
            SINGLE: '/taxregistrations/:customerId',
            AGREEMENT: '/taxregistrations/:customerId/agreement',
            STATISTICS: '/taxregistrations/statistics',
        },
        PUT: '/taxregistrations/:customerId/status/:status',
        POST: {
            REFUND: '/taxregistrations/:customerId/refund',
        },
    },
    SELF_ASSESSMENTS: {
        GET: {
            AGREEMENT: '/selfassessments/:selfAssessmentId/agreement',
        },
    },
    OFF_PLATFORM_SERVICES_REQUESTS: {
        GET: {
            ALL: '/offplatformservicerequests',
        },
        PUT: {
            SINGLE: '/offplatformservicerequests/:id',
        },
    },
} as const
