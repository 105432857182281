import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { createNote, requestCustomerSelfAssessments } from '../../actions'
import CustomerNotesItemModal from './CustomerNotesItemModal'

class CustomerNotesCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isModalOpen: false,
        }

        _bindAll(this, ['openModal', 'closeModal', 'handleNoteCreate'])
    }

    handleNoteCreate(note) {
        const { dispatch, customerId, onNoteCreate } = this.props

        dispatch(createNote({ ...note, customerId }, { onSuccess: onNoteCreate }))
        this.setState({ isModalOpen: false })
    }

    openModal(e) {
        e.stopPropagation()

        const { dispatch, customerId } = this.props
        dispatch(requestCustomerSelfAssessments(customerId))

        this.setState({ isModalOpen: true })
    }

    closeModal(e) {
        e.stopPropagation()

        this.setState({ isModalOpen: false })
    }

    render() {
        const { customerId, children, escalationId, isPriorityInitially } = this.props
        const { isModalOpen } = this.state
        return (
            <>
                <span onClick={this.openModal}>{children}</span>

                {isModalOpen && (
                    <CustomerNotesItemModal
                        customerId={customerId}
                        escalationId={escalationId}
                        handleSave={this.handleNoteCreate}
                        handleCancel={this.closeModal}
                        isPriorityInitially={isPriorityInitially}
                    />
                )}
            </>
        )
    }
}

CustomerNotesCreate.propTypes = {
    children: PropTypes.node.isRequired,
    customerId: PropTypes.number.isRequired,
    escalationId: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    onNoteCreate: PropTypes.func,
    isPriorityInitially: PropTypes.bool,
}

CustomerNotesCreate.defaultProps = {
    escalationId: undefined,
    isPriorityInitially: false,
    onNoteCreate: () => {},
}

export default connect()(CustomerNotesCreate)
