import { generateApiPath } from '@scouts/helpers'

import { BankAccountDTO, CustomerDTO } from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { Tags } from '../tags'

export interface GetCustomersBankAccountParams {
    customerId: CustomerDTO['id']
}

export interface UpdateCustomersBankAccountParams {
    customerId: CustomerDTO['id']
    data: BankAccountDTO
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomersBankAccount: builder.query<BankAccountDTO, GetCustomersBankAccountParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.BANK_ACCOUNTS.GET, { customerId }),
            }),
            providesTags: [Tags.BankAccount],
        }),
        updateCustomersBankAccount: builder.mutation<BankAccountDTO, UpdateCustomersBankAccountParams>({
            query: ({ customerId, data }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.BANK_ACCOUNTS.PUT, { customerId }),
                body: data,
            }),
            invalidatesTags: [Tags.BankAccount],
        }),
    }),
})

export const { useGetCustomersBankAccountQuery, useUpdateCustomersBankAccountMutation } = extendedApi
