import { createSelector } from '@reduxjs/toolkit'
import _get from 'lodash/get'

import { FileType } from '@/models'

/**
 * @param {import('@/store').RootState} state
 * @param {{customerId: number }} props
 */
const pluckCustomerId = (_state, { customerId }) => customerId

const getCustomerFileItems = (state) => _get(state, ['customerFiles', 'items'], [])

export const getCustomerFilesByCustomer = createSelector(
    [getCustomerFileItems, pluckCustomerId],
    (customerFiles, customerId) => customerFiles.filter((o) => o.customerId === customerId)
)

export const getCustomerAddressVerificationFiles = (state, props) => {
    const customerFiles = getCustomerFileItems(state)
    const { identityVerificationId: givenId } = props

    return customerFiles.filter(
        ({ fileType, identityVerificationId }) =>
            fileType === FileType.ProofOfAddress && identityVerificationId === givenId
    )
}

export const getCustomerIdentityVerificationFiles = (state, props) => {
    const customerFiles = getCustomerFileItems(state)
    const { identityVerificationId: givenId } = props

    return customerFiles.filter(
        ({ fileType, identityVerificationId }) => fileType === FileType.IdDocument && identityVerificationId === givenId
    )
}

export const getCustomerIdentityVerificationVideoFiles = (state, props) => {
    const customerFiles = getCustomerFileItems(state)
    const { identityVerificationId: givenId } = props

    return customerFiles.filter(
        ({ fileType, identityVerificationId }) =>
            fileType === FileType.IdentityVerificationVideo && identityVerificationId === givenId
    )
}
