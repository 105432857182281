import { useGetCustomersAccountantMeetingQuery } from '../api'

interface UseAccountantMeetingsProps {
    customerId: number
}

export const useCustomersAccountantMeetings = ({ customerId }: UseAccountantMeetingsProps) => {
    const { data, isError, isFetching } = useGetCustomersAccountantMeetingQuery({
        customerId,
    })

    return {
        accountantMeetings: data,
        isError,
        isFetching,
    }
}
