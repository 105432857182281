const AGENT_AUTH_STATUS_NOT_STARTED = 'NotStarted'
export const AGENT_AUTH_STATUS_SUBMITTED = 'Submitted'
export const AGENT_AUTH_STATUS_AWAITING_CODE = 'AwaitingCode'
export const AGENT_AUTH_STATUS_CODE_SENT_TO_CUSTOMER = 'CodeSentToCustomer'
export const AGENT_AUTH_STATUS_CODE_RECEIVED = 'CodeReceived'
export const AGENT_AUTH_STATUS_CODE_SUBMITTED = 'CodeSubmitted'
export const AGENT_AUTH_STATUS_REQUEST_EXPIRING = 'RequestExpiring'
export const AGENT_AUTH_STATUS_CLIENT_AUTHORISED = 'ClientAuthorised'
export const AGENT_AUTH_STATUS_REQUEST_EXPIRED = 'RequestExpired'
export const AGENT_AUTH_STATUS_REQUEST_FAILED = 'RequestFailed'
export const AGENT_AUTH_STATUS_INCORRECT_CODE = 'IncorrectCode'
export const AGENT_AUTH_STATUS_UNABLE_TO_ISSUE = 'UnableToIssue'

export const AGENT_AUTH_STATUSES = [
    AGENT_AUTH_STATUS_SUBMITTED,
    AGENT_AUTH_STATUS_AWAITING_CODE,
    AGENT_AUTH_STATUS_CODE_SENT_TO_CUSTOMER,
    AGENT_AUTH_STATUS_CODE_RECEIVED,
    AGENT_AUTH_STATUS_CODE_SUBMITTED,
    AGENT_AUTH_STATUS_REQUEST_EXPIRING,
    AGENT_AUTH_STATUS_CLIENT_AUTHORISED,
    AGENT_AUTH_STATUS_REQUEST_EXPIRED,
    AGENT_AUTH_STATUS_REQUEST_FAILED,
    AGENT_AUTH_STATUS_INCORRECT_CODE,
    AGENT_AUTH_STATUS_UNABLE_TO_ISSUE,
]

export const AGENT_AUTH_STATUS_TITLES = {
    [AGENT_AUTH_STATUS_NOT_STARTED]: 'Not started',
    [AGENT_AUTH_STATUS_SUBMITTED]: 'Requested (HMRC processing)',
    [AGENT_AUTH_STATUS_AWAITING_CODE]: 'Requested',
    [AGENT_AUTH_STATUS_CODE_SENT_TO_CUSTOMER]: 'Requested (code sent)',
    [AGENT_AUTH_STATUS_REQUEST_EXPIRING]: 'Requested (code expiring)',
    [AGENT_AUTH_STATUS_CODE_RECEIVED]: 'Code received',
    [AGENT_AUTH_STATUS_CODE_SUBMITTED]: 'Code submitted',
    [AGENT_AUTH_STATUS_CLIENT_AUTHORISED]: 'Client authorised',
    [AGENT_AUTH_STATUS_REQUEST_EXPIRED]: 'Expired',
    [AGENT_AUTH_STATUS_REQUEST_FAILED]: 'Failed (HMRC rejected)',
    [AGENT_AUTH_STATUS_INCORRECT_CODE]: 'Failed (wrong code)',
    [AGENT_AUTH_STATUS_UNABLE_TO_ISSUE]: 'Failed (unable to issue)',
}

export const AGENT_AUTH_REASON_TYPE_REQUIRED = 'Required'
export const AGENT_AUTH_REASON_TYPE_POST_FILING_SUPPORT = 'PostFilingSupport'

export const AGENT_AUTH_REASON_TYPE_TITLES = {
    [AGENT_AUTH_REASON_TYPE_REQUIRED]: 'Needed in order to complete the return',
    [AGENT_AUTH_REASON_TYPE_POST_FILING_SUPPORT]:
        'Needed for post-filing support (not for the completion of the return)',
}
