import _get from 'lodash/get'

import { RECEIVE_SEARCH_RESULTS, REQUEST_SEARCH_RESULTS, RESET_SEARCH_RESULTS } from '../actions'

const defaultState = {
    items: [],
    isFetching: false,
    pagination: {
        currentPage: null,
        pageCount: null,
        perPage: null,
        totalCount: null,
    },
}

export default (state = defaultState, action) => {
    const { type, data, meta } = action

    switch (type) {
        case REQUEST_SEARCH_RESULTS: {
            return { ...state, isFetching: true }
        }

        case RECEIVE_SEARCH_RESULTS: {
            const items = Object.assign([], data)
            const pagination = _get(meta, ['pagination'], {})

            return { ...state, isFetching: false, items, pagination }
        }

        case RESET_SEARCH_RESULTS: {
            return defaultState
        }

        default:
            return state
    }
}
