import { matchSuccessResponse } from '@scouts/helpers'
import { colors, DropdownSelect, DropdownSelectProps } from '@scouts/ui'

import { requestCustomer } from '@/actions'
import { AGENT_AUTH_STATUS_TITLES } from '@/constants'
import { AgentAuthorisationDTO, AgentAuthorisationStatus, AgentAuthorisationStatusTypes } from '@/models'
import { useAppDispatch } from '@/store'
import { useUpdateAgentAuthorisation } from '@/store/agent-authorisations'

interface AgentAuthStatusProps {
    agentAuthorisation: AgentAuthorisationDTO
}

const statuses = [
    AgentAuthorisationStatus.AwaitingCode,
    AgentAuthorisationStatus.ClientAuthorised,
    AgentAuthorisationStatus.CodeReceived,
    AgentAuthorisationStatus.IncorrectCode,
    AgentAuthorisationStatus.RequestExpired,
]

const getColor = (status: AgentAuthorisationDTO['status']) => {
    switch (status) {
        case AgentAuthorisationStatus.Submitted:
        case AgentAuthorisationStatus.AwaitingCode:
        case AgentAuthorisationStatus.CodeSentToCustomer:
        case AgentAuthorisationStatus.RequestExpiring:
            return colors.yellow
        case AgentAuthorisationStatus.CodeReceived:
        case AgentAuthorisationStatus.CodeSubmitted:
            return colors.blueLighter
        case AgentAuthorisationStatus.ClientAuthorised:
            return colors.mintLighter
        case AgentAuthorisationStatus.RequestExpired:
        case AgentAuthorisationStatus.RequestFailed:
        case AgentAuthorisationStatus.IncorrectCode:
        case AgentAuthorisationStatus.UnableToIssue:
            return colors.redLighter
        default:
            return colors.neutralLightest
    }
}

export const AgentAuthStatus = ({ agentAuthorisation }: AgentAuthStatusProps) => {
    const dispatch = useAppDispatch()
    const { status } = agentAuthorisation

    const { updateAgentAuthorisation } = useUpdateAgentAuthorisation()

    const availableStatuses = statuses.filter((item) => item !== status)

    const options = [
        {
            value: status,
            title: AGENT_AUTH_STATUS_TITLES[status],
            color: getColor(status),
            disabled: true,
        },
        ...availableStatuses.map((status) => ({
            value: status,
            title: AGENT_AUTH_STATUS_TITLES[status],
            color: getColor(status),
        })),
    ]

    const handleChange: DropdownSelectProps['onChange'] = ({ value }) => {
        if (status === AgentAuthorisationStatus.RequestFailed) return
        if (status === AgentAuthorisationStatus.UnableToIssue) return

        updateAgentAuthorisation({
            agentAuthorisationId: agentAuthorisation.id,
            data: { ...agentAuthorisation, status: value as AgentAuthorisationStatusTypes },
        }).then(
            matchSuccessResponse((response) => {
                dispatch(requestCustomer(response.customerId))
            })
        )
    }

    return (
        <DropdownSelect name="status" value={status} options={options} onChange={handleChange} width="100%" isSmall />
    )
}
