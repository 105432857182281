import styled from 'styled-components'

import { colors, font, media, radius } from '@scouts/ui'

interface QuickSearchInputProps {
    onChange: () => void
    onFocus: () => void
    onClick: () => void
    value: string
    placeholder: string
}

export const QuickSearchInput = ({ onChange, onClick, value, placeholder, onFocus }: QuickSearchInputProps) => (
    <Input placeholder={placeholder} value={value} onChange={onChange} onClick={onClick} onFocus={onFocus} />
)

const Input = styled.input`
    width: 100%;
    padding: 9px 15px;
    background: ${colors.white};
    border: 2px solid ${colors.white};
    border-radius: ${radius.small};

    ${media.pointer} {
        font-size: ${font.small};

        &:hover {
            border-color: ${colors.neutralLight};
        }
    }

    &:focus {
        outline: 0;
        border-color: ${colors.blue};
        background: ${colors.white};
    }

    &::placeholder {
        color: ${colors.neutralDarker};
    }

    ${media.tablet} {
        padding: 9px 12px;
    }
`
