import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Grid, GridItem, Line, Spacer } from '@scouts/ui'

import { PATH_ACCOUNTANT_MEETINGS } from '@/constants'
import { AccountantMeetingStatus } from '@/models'
import { useAccountantMeetings } from '@/store/accountant-meetings/hooks/use-accountant-meetings'

import { useUser } from '@/hooks/use-user'

import { DashboardAccountantMeetingsItem } from './DashboardAccountantMeetingsItem'

interface DashboardAccountantMeetingsProps {
    accountantId?: number
}

const DISPLAY_COUNT = 8

export const DashboardAccountantMeetings = ({ accountantId }: DashboardAccountantMeetingsProps) => {
    const history = useHistory()

    const { id, isAdminUser } = useUser()

    const requestedAccountantId = isAdminUser ? accountantId : id

    const { accountantMeetings } = useAccountantMeetings({
        accountantId: requestedAccountantId,
        status: AccountantMeetingStatus.Booked,
        pageSize: 50,
    })

    const accountantMeetingsCount = accountantMeetings?.length || 0
    const hasAccountantMeetings = accountantMeetingsCount > 0
    const hasMoreAccountantMeetings = accountantMeetingsCount > DISPLAY_COUNT

    if (!requestedAccountantId || !hasAccountantMeetings) return null

    const setOfAccountantMeetings = hasMoreAccountantMeetings
        ? accountantMeetings?.slice(0, DISPLAY_COUNT)
        : accountantMeetings

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Booked meetings
                    </Line>
                    <ButtonLink
                        color={colors.neutralDarker}
                        onClick={() =>
                            history.push({
                                pathname: PATH_ACCOUNTANT_MEETINGS,
                                search: isAdminUser
                                    ? `?accountantId=${requestedAccountantId}&status=${AccountantMeetingStatus.Started}`
                                    : '',
                            })
                        }
                    >
                        {hasMoreAccountantMeetings
                            ? `View all (${accountantMeetingsCount - DISPLAY_COUNT} more)`
                            : 'View all'}
                    </ButtonLink>
                </Flexer>
            </Spacer>
            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                {(setOfAccountantMeetings || []).map((accountantMeeting) => (
                    <GridItem key={accountantMeeting.id}>
                        <DashboardAccountantMeetingsItem accountantMeeting={accountantMeeting} />
                    </GridItem>
                ))}
            </Grid>
        </Container>
    )
}
