import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownDateRange } from '../ui/filter'

const SearchFilterFiledDate = (props) => {
    const { filedDateStart, filedDateEnd, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = filedDateStart || filedDateEnd

    const activeValues = [filedDateStart ? `From ${filedDateStart}` : '', filedDateEnd ? `To ${filedDateEnd}` : '']

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Filed date"
            activeValues={activeValues}
            removeFilter={() => removeFilter(['filedDateStart', 'filedDateEnd'])}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownDateRange
                dateFromName="filedDateStart"
                dateToName="filedDateEnd"
                applyFilter={applyFilter}
                removeFilter={() => removeFilter(['filedDateStart', 'filedDateEnd'])}
                dateFrom={filedDateStart}
                dateTo={filedDateEnd}
            />
        </FilterItem>
    )
}

SearchFilterFiledDate.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    filedDateEnd: PropTypes.string.isRequired,
    filedDateStart: PropTypes.string.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterFiledDate
