import _get from 'lodash/get'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery } from 'redux-saga/effects'

import {
    receiveSelfAssessmentFiles,
    receiveSuccessAlerts,
    removeFile,
    requestSelfAssessment,
    requestSelfAssessmentFiles,
    DELETE_ACCOUNTANT_FILE,
    DELETE_FILE,
    DOWNLOAD_FILE,
    REQUEST_SELF_ASSESSMENT_FILES,
    UPLOAD_ACCOUNTANT_FILE,
    UPLOAD_FILE,
} from '../actions'
import { handleError } from '../handlers'
import { apiCall } from '../helpers'

export default function* filesSagas() {
    yield takeEvery(REQUEST_SELF_ASSESSMENT_FILES, fetchSelfAssessmentFiles)
    yield takeEvery(UPLOAD_FILE, uploadFile)
    yield takeEvery(DOWNLOAD_FILE, downloadFile)
    yield takeEvery(DELETE_FILE, deleteFile)
    yield takeEvery(UPLOAD_ACCOUNTANT_FILE, uploadAccountantFile)
    yield takeEvery(DELETE_ACCOUNTANT_FILE, deleteAccountantFile)
}

function* fetchSelfAssessmentFiles({ data }) {
    const selfAssessmentId = _get(data, ['selfAssessmentId'], null)

    try {
        yield put(showLoading())
        const response = yield apiCall(`selfassessments/${selfAssessmentId}/files`)
        yield put(receiveSelfAssessmentFiles(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* uploadFile({ data, options }) {
    const { onSuccess } = options

    const selfAssessmentId = _get(data, ['selfAssessmentId'], null)
    const fileType = _get(data, ['fileType'], null)
    const filingArea = _get(data, ['filingArea'], null)
    const employmentId = _get(data, ['employmentId'], null)
    const file = _get(data, ['file'], null)

    const url = `selfassessmentfiles?selfAssessmentId=${selfAssessmentId}&fileType=${fileType}&filingArea=${filingArea}${
        employmentId ? `&employmentId=${employmentId}` : ''
    }`

    const formData = new FormData()
    formData.append('file', file)

    try {
        yield put(showLoading())
        yield apiCall(url, 'post', formData)

        if (onSuccess) onSuccess()

        yield put(receiveSuccessAlerts('File successfully uploaded'))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* downloadFile({ data }) {
    const id = _get(data, ['id'], null)
    const inlineContentDisposition = _get(data, ['inlineContentDisposition'], false)

    const queryParams = inlineContentDisposition ? '?inlineContentDisposition=true' : ''

    try {
        yield put(showLoading())
        const response = yield apiCall(`selfassessmentfiles/${id}/downloadlink${queryParams}`)

        if (response.data) {
            if (data?.meta?.onSuccess) {
                data.meta.onSuccess(response.data)
            } else {
                window.location.assign(response.data)
            }
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* deleteFile({ data }) {
    const id = _get(data, ['file', 'id'], null)
    const selfAssessmentId = _get(data, ['file', 'selfAssessmentId'], null)

    try {
        yield put(showLoading())
        yield apiCall(`selfassessmentfiles/${id}`, 'delete')
        yield put(removeFile({ fileId: id }))

        yield put(requestSelfAssessmentFiles({ selfAssessmentId }))
        yield put(requestSelfAssessment(selfAssessmentId))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* uploadAccountantFile({ data, options }) {
    const { onSuccess } = options
    const { accountantId, fileType, file, fileName } = data

    const formData = new FormData()
    formData.append('file', file, fileName)

    const url = `accountantfiles?accountantId=${accountantId}&fileType=${fileType}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url, 'post', formData)

        if (onSuccess) onSuccess(response)
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* deleteAccountantFile({ data }) {
    const { fileId } = data

    try {
        yield apiCall(`accountantfiles/${fileId}`, 'delete')
    } catch (e) {
        yield put(handleError(e))
    }
}
