import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { call, put, takeLatest } from 'redux-saga/effects'

import { receiveUser, AUTHENTICATE, RESET_SESSION } from '../actions'
import { PATH_ROOT } from '../constants'
import { apiCall } from '../helpers'

function windowLocationAssign(destination) {
    window.location.assign(destination)
}

export default function* authSagas() {
    yield takeLatest(AUTHENTICATE, authenticate)
    yield takeLatest(RESET_SESSION, resetSession)
}

function* authenticate({ onSuccess, onError }) {
    try {
        yield put(showLoading())
        const response = yield apiCall('accountants/loggedin')
        yield put(receiveUser(response.data))

        if (onSuccess) onSuccess(response.data)
    } catch (e) {
        if (onError) onError(e)
    } finally {
        yield put(hideLoading())
    }
}

function* resetSession(action) {
    const { destination = PATH_ROOT } = action?.data || {}

    yield call(windowLocationAssign, destination)
}
