import { useEffect, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSetTitle } from '@scouts/helpers'
import { Loadable } from '@scouts/ui'

import {
    requestAccountant,
    requestCustomer,
    requestCustomerConversations,
    requestCustomerIssues,
    requestCustomerNotes,
    requestCustomerProductBundles,
    requestCustomerSelfAssessments,
    resetIssues,
} from '@/actions'
import { APP_TITLE } from '@/constants'
import { updateRecentCustomers } from '@/helpers'
import { getUser } from '@/selectors'

import { useCustomer } from '@/hooks/use-customer'

interface CustomerLoaderProps {
    children: ReactNode
    customerId: number
}

export const CustomerLoader = ({ customerId, children }: CustomerLoaderProps) => {
    const dispatch = useDispatch()

    const { isAdminUser } = useSelector(getUser)
    const { customer } = useCustomer(customerId)

    useSetTitle(customer?.fullName || '', APP_TITLE)

    useEffect(() => {
        const fetch = () => {
            dispatch(requestCustomer(customerId))
            dispatch(requestCustomerConversations(customerId))
            dispatch(requestCustomerIssues(customerId))
            dispatch(requestCustomerNotes(customerId))
            dispatch(requestCustomerSelfAssessments(customerId))
            dispatch(requestCustomerProductBundles({ customerId }))
            dispatch(resetIssues())
        }
        updateRecentCustomers(Number(customerId))
        fetch()
    }, [dispatch, isAdminUser, customerId])

    useEffect(() => {
        if (isAdminUser && customer?.accountantId) {
            dispatch(requestAccountant(customer?.accountantId))
        }
    }, [customer?.accountantId, dispatch, isAdminUser])

    useEffect(
        () => () => {
            document.title = APP_TITLE
        },
        []
    )

    const hasAllDataLoaded = !!customer

    if (!hasAllDataLoaded) return <Loadable isLoading />

    return <>{children}</>
}
