import { DataSourceTypes, SectionStatusTypes } from './self-assessment-sections'

export const SelfEmploymentCategory = {
    Freelancer: 'Freelancer',

    Construction: 'Construction',
    Driver: 'Driver',
    SecurityWorker: 'SecurityWorker',
    Other: 'Other',
} as const

export type SelfEmploymentCategoryTypes = (typeof SelfEmploymentCategory)[keyof typeof SelfEmploymentCategory]

export const BusinessVehicleType = {
    Car: 'Car',
    Van: 'Van',
    Motorbike: 'Motorbike',
    Bicycle: 'Bicycle',
} as const

export type BusinessVehicleTypeTypes = (typeof BusinessVehicleType)[keyof typeof BusinessVehicleType]

export const OwnershipStatus = {
    Owns: 'Owns',
    Rents: 'Rents',
    Leases: 'Leases',
} as const

export type OwnershipStatusTypes = (typeof OwnershipStatus)[keyof typeof OwnershipStatus]

export const ExpenseType = {
    Tools: 'Tools',
    PhoneAndInternet: 'PhoneAndInternet',
    PublicTransport: 'PublicTransport',
    Other: 'Other',
} as const

export type ExpenseTypeTypes = (typeof ExpenseType)[keyof typeof ExpenseType]

export interface SelfEmploymentDTO {
    selfAssessmentId: number
    selfEmploymentCategory: SelfEmploymentCategoryTypes
    approximateIncome: number | null
    registeredWithHmrc: boolean | null
    usedHomeOffice: boolean | null
    percentHomeOffice: number | null
    hoursPerWeekHomeOffice: number | null
    homeOfficeRent: number | null
    businessVehicle: boolean | null
    businessVehicleType: BusinessVehicleTypeTypes
    businessMiles: number | null
    businessMilesLogged: boolean | null
    approximateExpenses: number | null
    hasExpenseDocumentation: boolean | null
    status: SectionStatusTypes
    additionalNotes: string | null
    homeOfficeOwnsHome: boolean | null
    businessVehicleHasReceipts: boolean | null
    hasCisPayments: boolean | null
    hasCisStatements: boolean | null
    cisHasUnpaidTax: boolean | null
    cisRegisteredSubcontractor: boolean | null
    dataSource: DataSourceTypes
    incomeAmountConfirmed: boolean | null
    expenseAmountConfirmed: boolean | null
    hasReceivedCovidSupportPayments: boolean | null
    covidSupportPaymentsAmount: number | null
    businessVehicleMonthlyLeaseCosts: number | null
    hasNonDrivingExpenses: boolean | null
    nonDrivingExpensesAmount: number | null
    businessVehicleUsingMileage: boolean | null
    rentsOrLeasesBusinessVehicle: boolean | null
    businessVehicleExpenses: number | null
    businessVehicleOwnership: OwnershipStatusTypes
    businessVehiclePurchaseYear: number | null
    businessVehiclePurchasePrice: number | null
    hasFuelDrivingExpense: boolean | null
    hasMaintenanceDrivingExpense: boolean | null
    hasInsuranceDrivingExpense: boolean | null
    hasCleaningDrivingExpense: boolean | null
    lowExpensesUseTradingAllowance: boolean | null
    useBookkeepingDataAsSource: boolean | null
}

export interface SelfEmploymentExpensesDTO {
    id: number
    selfEmploymentId: number
    expenseType: ExpenseTypeTypes
    amount: number | null
}
