import _groupBy from 'lodash/groupBy'
import { memo } from 'react'

import { Select, SelectOnChangeParams } from '@scouts/ui'

import { MessageTemplateDTO, MessageTemplateType } from '@/models/message-template'
import { useMessageTemplates } from '@/store/message-templates'

import { useUser } from '@/hooks/use-user'

const useMessageTemplateOptions = ({
    defaultValueTitle,
    messageTemplates,
}: {
    defaultValueTitle: string
    messageTemplates: MessageTemplateDTO[]
}) => {
    const { isAdminUser } = useUser()

    const templatesByType = _groupBy(messageTemplates, 'type')
    const mapOption = (item: MessageTemplateDTO) => ({ value: String(item.id), title: item.name ?? '' })
    const privateTemplates = (templatesByType[MessageTemplateType.Private] || []).map(mapOption)
    const supportTemplates = (templatesByType[MessageTemplateType.Support] || []).map(mapOption)
    const sharedTemplates = (templatesByType[MessageTemplateType.Shared] || []).map(mapOption)

    let options: Array<{ title: string; value?: string; disabled?: boolean }> = [
        { title: defaultValueTitle },
        ...privateTemplates,
    ]
    if (isAdminUser && supportTemplates.length > 0) {
        options = [
            ...options,
            { title: '-', value: 'dash-for-support', disabled: true },
            { title: 'For support', value: 'for-support', disabled: true },
            ...supportTemplates,
        ]
    }
    if (sharedTemplates.length > 0) {
        options = [
            ...options,
            { title: '-', value: 'dash-for-accountant', disabled: true },
            { title: isAdminUser ? 'For accountant' : 'TaxScouts templates', value: 'for-accountant', disabled: true },
            ...sharedTemplates,
        ]
    }

    return options
}

export const MessageTemplateSelect = memo(
    ({
        name,
        value,
        onChange,
        width = '200px',
        defaultValueTitle = 'New message',
    }: {
        name: string
        value: string
        onChange: (params: { value: string; template?: MessageTemplateDTO }) => void
        width?: string
        defaultValueTitle?: string
    }) => {
        const { getMessageTemplatesSortedByName } = useMessageTemplates()
        const messageTemplatesSortedByName = getMessageTemplatesSortedByName()

        const handleTemplateChange = ({ value: selectedTemplateId }: SelectOnChangeParams) => {
            const template = messageTemplatesSortedByName.find(({ id }) => id === Number(selectedTemplateId))
            onChange({ value: selectedTemplateId, template })
        }

        const options = useMessageTemplateOptions({ defaultValueTitle, messageTemplates: messageTemplatesSortedByName })

        return <Select name={name} value={value} onChange={handleTemplateChange} options={options} width={width} />
    }
)
