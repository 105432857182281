import {
    Button,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Paragraph,
} from '@scouts/ui'

interface PartnerHelpModalProps {
    closeModal: () => void
    hasEmail: boolean
}

export const PartnerHelpModal = ({ closeModal, hasEmail }: PartnerHelpModalProps) => {
    return (
        <Modal onClose={closeModal}>
            <ModalHeader>
                <ModalTitle>Partner / Property co-owner</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Paragraph>The client has let us know that the property is jointly owned with someone.</Paragraph>
                {hasEmail && (
                    <Paragraph>
                        If the specified co-owner signs up, they will be automatically assigned to the same accountant.
                    </Paragraph>
                )}
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button onClick={closeModal}>Got it</Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}
