export const AccountantStatus = {
    Draft: 'Draft',
    Active: 'Active',
    Paused: 'Paused',
    Terminated: 'Terminated',
} as const

export type AccountantStatusTypes = (typeof AccountantStatus)[keyof typeof AccountantStatus]

export const ExternalApis = {
    Hmrc: 'Hmrc',
    Uber: 'Uber',
    Microsoft: 'Microsoft',
    Google: 'Google',
    Nylas: 'Nylas',
    Rollee: 'Rollee',
} as const

export type ExternalApisTypes = (typeof ExternalApis)[keyof typeof ExternalApis]

export const Specialisms = {
    Other: 'Other',
    GeneralSelfAssessmentTaxReturns: 'GeneralSelfAssessmentTaxReturns',
    GeneralTaxPlanningAndCompanyFormation: 'GeneralTaxPlanningAndCompanyFormation',
    SelfEmploymentIncome: 'SelfEmploymentIncome',
    Trusts: 'Trusts',
    PropertyIncome: 'PropertyIncome',
    Partnerships: 'Partnerships',
    CapitalGainsFromProperty: 'CapitalGainsFromProperty',
    LimitedCompanyReturns: 'LimitedCompanyReturns',
    ForeignIncome: 'ForeignIncome',
    InheritanceTax: 'InheritanceTax',
    EmployeeShareOptions: 'EmployeeShareOptions',
} as const

export type SpecialismsTypes = (typeof Specialisms)[keyof typeof Specialisms]

export interface AccountantDetailsDTO {
    professionalBody: string | null
    certifications: string | null
    profile: string | null
    companyURL: string | null
    companyNumber: string | null
    phoneNumber: string | null
    address: string | null
    officePhoneNumber: string | null
    schedulingLink: string | null
    accountantMeetingSchedulingLink: string | null
    taxConsultationSchedulingLink: string | null
    hmrcAgentReference: string | null
    hmrcUserId: string | null
    hasHmrcPassword: boolean
    hasAgentAuthorisationCredentials: boolean
    referralCode: string | null
    useIdentityVerification: boolean
    useEnhancedIdentityVerification: boolean
    capacity: number
    isTaxScoutsSupport: boolean
    isBlocked: boolean
    isOnTrial: boolean
    vatNumber: string | null
    allowCapitalGains: boolean
    allowForeignIncome: boolean
    allowPayLater: boolean
    allowSubscription: boolean
    allowTaxConsultation: boolean
    allowCompany: boolean
    hasTaxConsultations: boolean
    taxRegistrations: boolean
    standardSelfAssessmentFee: number | null
    payLaterSelfAssessmentFee: number | null
    taxConsultationFee: number | null
    externalId: string | null
    imageFileId: number
    externalApis: ExternalApisTypes | null
    lastLoggedInDateUTC: string | null
    specialisms: SpecialismsTypes[] | null
    otherSpecialismDescription: string | null
    sendTaxConsultationReminderEmail: boolean
    id: number
    email: string
    firstName: string | null
    lastName: string | null
    fullName: string | null
    companyName: string | null
    status: AccountantStatusTypes
    taxConsultationStatus: AccountantStatusTypes
    companyStatus: AccountantStatusTypes
    currentPausedDescription: string | null
    currentPausedScheduledEndDate: string | null
}

export const PausedStatus = {
    Busy: 'Busy',
    Unavailable: 'Unavailable',
} as const

export type PausedStatusTypes = (typeof PausedStatus)[keyof typeof PausedStatus]

export interface AccountantPausedHistoryCreateDTO {
    accountantId: number
    pausedStatusType: PausedStatusTypes
    scheduledEndDate: string | Date | null
    pausedDescription: string | null
}

/** Filters */

export const AccountantStatusFilter = {
    All: 'All',
    OnTrial: 'OnTrial',
    AllowTaxConsultation: 'AllowTaxConsultation',
    Draft: 'Draft',
    Active: 'Active',
    Paused: 'Paused',
    Terminated: 'Terminated',
} as const

export const AccountantStatusFilters = {
    Draft: [AccountantStatus.Draft],
    Active: [AccountantStatus.Active],
    Paused: [AccountantStatus.Paused],
    Terminated: [AccountantStatus.Terminated],
    OnTrial: [AccountantStatusFilter.OnTrial],
    AllowTaxConsultation: [AccountantStatusFilter.AllowTaxConsultation],
    All: [AccountantStatus.Draft, AccountantStatus.Active, AccountantStatus.Paused, AccountantStatus.Terminated],
} as const

export type AccountantStatusFiltersTypes = keyof typeof AccountantStatusFilter

export const AccountantStatusFiltersTitles: Record<AccountantStatusFiltersTypes, string> = {
    All: 'All',
    OnTrial: 'On trial',
    AllowTaxConsultation: 'Accepts consultations',
    Draft: 'Draft',
    Active: 'Active',
    Paused: 'Paused',
    Terminated: 'Terminated',
}
