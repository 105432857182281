import { useMemo } from 'react'

import { openFileDialog } from '@scouts/helpers'
import { DropdownButton } from '@scouts/ui'

import { getFileTypeTitle } from '@/domain'
import { FileTypeTypes, TaxConsultationDTO } from '@/models'
import { useUploadTaxConsultationFile } from '@/store/tax-consultation-files/hooks'

export const UploadButton = ({
    fileTypes,
    taxConsultationId,
}: {
    fileTypes?: FileTypeTypes[]
    taxConsultationId: TaxConsultationDTO['id']
}) => {
    const { uploadTaxConsultationFile, isLoading } = useUploadTaxConsultationFile()

    const actions = useMemo(() => {
        return (fileTypes || []).map((type) => ({
            title: getFileTypeTitle(type),
            onClick: () =>
                openFileDialog({
                    onChange: (files) =>
                        uploadTaxConsultationFile({
                            file: files[0],
                            params: { taxConsultationId, fileType: type },
                        }),
                }),
        }))
    }, [fileTypes, taxConsultationId, uploadTaxConsultationFile])

    const hasActions = actions.length > 0

    if (!hasActions) return null

    return (
        <DropdownButton alignRight actions={actions} isLoading={isLoading}>
            Upload
        </DropdownButton>
    )
}
