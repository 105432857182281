import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

import { ESCALATIONS_REASON_TYPE_TITLES, ESCALATIONS_REASON_TYPES } from '../../../constants'
import { FilterItem, FilterItemDropdownRadios } from '../../ui/filter'

const mapFilterValue = (value, activeFilter) => ({
    value,
    label: ESCALATIONS_REASON_TYPE_TITLES[value],
    checked: activeFilter === value,
})

const EscalationsSearchFilterReasonType = ({ activeFilter, applyFilter, removeFilter, isOpenByDefault }) => {
    const hasActiveFilter = activeFilter !== null

    const values = ESCALATIONS_REASON_TYPES.map((reasonType) => mapFilterValue(reasonType, activeFilter))

    const activeValue = _get(_find(values, { value: activeFilter }), ['label'], null)

    const name = 'reasonType'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Reason"
            activeValues={[activeValue]}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownRadios
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

EscalationsSearchFilterReasonType.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    activeFilter: PropTypes.string.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default EscalationsSearchFilterReasonType
