import { useToggle } from '@scouts/helpers'
import { Button } from '@scouts/ui'

import { MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL } from '@/constants'
import { AgentAuthorisationDTO, CustomerDTO } from '@/models'

import SendMessagesContainer from '@/components/SendMessagesContainer'

import { AgentAuthErrorModal } from './AgentAuthErrorModal'
import { AgentAuthRequestModal } from './AgentAuthRequestModal'

interface AgentAuthRequestProps {
    label: string
    customer: CustomerDTO
    taxScoutsReference: string
    agentAuthorisation?: AgentAuthorisationDTO
}

export const AgentAuthRequest = ({
    agentAuthorisation,
    label,
    customer,
    taxScoutsReference,
}: AgentAuthRequestProps) => {
    const [isErrorModalOpen, openErrorModal, closeErrorModal] = useToggle()
    const [isMessageModalOpen, openMessageModal] = useToggle()
    const [isModalOpen, openModal, closeModal] = useToggle()

    const { id, fullName } = customer
    const availableMessageTypes = [MESSAGE_TEXT_TYPE_AGENT_AUTH_FAIL]
    const customers = [{ customerId: String(id), fullName }]

    const onAuthError = () => {
        closeModal()
        openErrorModal()
    }

    const openMessagesModal = () => {
        closeModal()
        closeErrorModal()
        openMessageModal()
    }

    return (
        <>
            <Button onClick={openModal} isFullWidth>
                {label}
            </Button>

            {isModalOpen && (
                <AgentAuthRequestModal
                    closeModal={closeModal}
                    customer={customer}
                    onError={onAuthError}
                    taxScoutsReference={taxScoutsReference}
                    agentAuthorisation={agentAuthorisation}
                />
            )}

            {isErrorModalOpen && (
                <AgentAuthErrorModal closeModal={closeErrorModal} openMessagesModal={openMessagesModal} />
            )}

            {isMessageModalOpen && (
                <SendMessagesContainer
                    availableTypes={availableMessageTypes}
                    customers={customers}
                    openByDefault={isMessageModalOpen}
                />
            )}
        </>
    )
}
