import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, font } from '@scouts/ui'

const Container = styled.div`
    color: ${({ isNeutral }) => (isNeutral ? colors.black : colors.white)};
    font-size: ${font.small};
    white-space: nowrap;
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 4px 6px;
    background: ${({ isNeutral }) => (isNeutral ? colors.neutralLightest : colors.blue)};
    border-radius: 2px;
`

const propTypes = {
    children: PropTypes.node.isRequired,
    isNeutral: PropTypes.bool,
}

const defaultProps = {
    isNeutral: false,
}

const FilterItemValue = (props) => {
    const { children, isNeutral } = props

    return <Container isNeutral={isNeutral}>{children}</Container>
}

FilterItemValue.propTypes = propTypes
FilterItemValue.defaultProps = defaultProps

export default FilterItemValue
