import { colors, font, radius, Container, Line } from '@scouts/ui'

import { AgentAuthorisationDTO, AgentAuthorisationStatus } from '@/models'

const getColor = (status: AgentAuthorisationDTO['status'] | null) => {
    switch (status) {
        case AgentAuthorisationStatus.AwaitingCode:
        case AgentAuthorisationStatus.RequestExpiring:
            return colors.yellow
        case AgentAuthorisationStatus.CodeReceived:
            return colors.blueLighter
        default:
            return colors.neutralLightest
    }
}

export const AgentAuthHeader = ({ status }: { status: AgentAuthorisationDTO['status'] | null }) => {
    return (
        <Container radius={radius.medium} padding="24px" background={getColor(status)}>
            <Line weight={font.weight.medium}>Agent authorisation</Line>
        </Container>
    )
}
