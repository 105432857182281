import { PATH_CUSTOMERS } from '@/constants'
import { AccountantMeetingSummaryDTO } from '@/models'

import { CardTime } from './CardTime'

export const DashboardAccountantMeetingsItem = ({
    accountantMeeting,
}: {
    accountantMeeting: AccountantMeetingSummaryDTO
}) => {
    const { customerFullName, customerId, id: accountantMeetingId, scheduledDateUTC } = accountantMeeting

    const linkDestination = `${PATH_CUSTOMERS}/${customerId}/meetings/${accountantMeetingId}`

    return <CardTime date={scheduledDateUTC} name={customerFullName} linkDestination={linkDestination} />
}
