import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { MessageTemplateEditDTO } from '@/models/message-template'

import { useCreateMessageTemplateMutation } from '../api'

export const useCreateMessageTemplate = () => {
    const [action, { isLoading }] = useCreateMessageTemplateMutation()

    const createMessageTemplate = (params: MessageTemplateEditDTO) =>
        action(params).then(
            matchSuccessResponse((response) => {
                addSuccessToast({ body: `Saved message template "${response.name}"` })
            })
        )

    return { isLoading, createMessageTemplate }
}
