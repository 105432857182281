import PropTypes from 'prop-types'

import { TAX_YEARS } from '../../constants'
import { FilterItem, FilterItemDropdownCheckboxes } from '../ui/filter'

const SearchFilterTaxYears = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter.length > 0

    const activeValues = hasActiveFilter ? activeFilter.map((taxYear) => TAX_YEARS[taxYear]) : ['Any']

    const values = Object.keys(TAX_YEARS)
        .reverse()
        .map((taxYear) => ({
            value: String(taxYear),
            label: TAX_YEARS[taxYear],
            checked: activeFilter.findIndex((o) => String(o) === String(taxYear)) > -1,
        }))

    const name = 'selfAssessmentTaxYears'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Tax year"
            activeValues={activeValues}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownCheckboxes
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterTaxYears.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    activeFilter: PropTypes.array.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterTaxYears
