import { useGetAccountantMeetingsQuery, GetAccountantMeetingsParams } from '../api'

export const useAccountantMeetings = ({ accountantId, status, page, pageSize }: GetAccountantMeetingsParams) => {
    const {
        data: fetchedData,
        isError,
        isFetching,
        isLoading,
    } = useGetAccountantMeetingsQuery({
        accountantId,
        status,
        page,
        pageSize,
    })
    const { data, pagination } = fetchedData || {}

    return {
        accountantMeetings: data,
        isError,
        isFetching,
        isLoading,
        pagination,
    }
}
