import { DropdownSelect, DropdownSelectProps, Loadable } from '@scouts/ui'

import { TaxReliefClaimDomain } from '@/domain'
import { TaxReliefClaimDTO } from '@/models'
import { useUpdateTaxReliefClaim } from '@/store/tax-relief-claims'

export const TaxReliefClaimStatusSelect = ({ taxReliefClaim }: { taxReliefClaim: TaxReliefClaimDTO }) => {
    const { updateTaxReliefClaim, isLoading } = useUpdateTaxReliefClaim()

    const { status } = taxReliefClaim

    const options = Object.entries(TaxReliefClaimDomain.statuses).map(([key, value]) => ({
        value: key,
        title: value.title,
        color: value.color,
    }))

    const handleChange: DropdownSelectProps['onChange'] = ({ value }) => {
        updateTaxReliefClaim({
            taxReliefClaimId: taxReliefClaim.id,
            data: {
                supportUserId: taxReliefClaim.supportUserId,
                status: value as TaxReliefClaimDTO['status'],
            },
        })
    }

    return (
        <Loadable isLoading={isLoading} isSmall>
            <DropdownSelect name="status" value={status} options={options} onChange={handleChange} width="100%" />
        </Loadable>
    )
}
