export const REQUEST_CUSTOMER_NOTES = 'REQUEST_CUSTOMER_NOTES'
export const REQUEST_ESCALATION_NOTES = 'REQUEST_ESCALATION_NOTES'
export const RECEIVE_ESCALATION_NOTES = 'RECEIVE_ESCALATION_NOTES'
export const RECEIVE_NOTES = 'RECEIVE_NOTES'
export const CREATE_NOTE = 'CREATE_NOTE'
export const SAVE_NOTE = 'SAVE_NOTE'
export const REMOVE_NOTE = 'REMOVE_NOTE'
export const DELETE_NOTE = 'DELETE_NOTE'
export const RESOLVE_NOTE_NOTIFICATION = 'RESOLVE_NOTE_NOTIFICATION'
export const REQUEST_NOTES_NOTIFICATIONS = 'REQUEST_NOTES_NOTIFICATIONS'
export const RECEIVE_NOTES_NOTIFICATIONS = 'RECEIVE_NOTES_NOTIFICATIONS'
export const RESET_NOTES_NOTIFICATIONS = 'RESET_NOTES_NOTIFICATIONS'

export const requestCustomerNotes = (customerId) => ({ type: REQUEST_CUSTOMER_NOTES, data: { customerId } })

export const requestEscalationNotes = (escalationId) => ({ type: REQUEST_ESCALATION_NOTES, data: { escalationId } })

export const receiveNotes = (data) => ({ type: RECEIVE_NOTES, data })

export const createNote = (note, meta = {}) => {
    const data = {
        noteText: note.noteText,
        customerId: note.customerId,
        escalationId: note.escalationId,
        isPriority: note.isPriority,
        selfAssessmentId: note.selfAssessmentId,
    }
    return { type: CREATE_NOTE, data, meta }
}

export const saveNote = (data, meta = {}) => ({ type: SAVE_NOTE, data, meta })

export const resolveNote = (updatedNote, meta = {}) => {
    const customSuccessMessage = updatedNote.isResolved ? 'Note resolved' : 'Note unresolved'
    return saveNote(updatedNote, { ...meta, customSuccessMessage })
}

export const removeNote = (data) => ({ type: REMOVE_NOTE, data })

export const deleteNote = (data, meta = {}) => ({ type: DELETE_NOTE, data, meta })

export const resolveNoteNotification = ({ noteId }, meta = {}) => ({
    type: RESOLVE_NOTE_NOTIFICATION,
    data: { noteId },
    meta,
})

export const requestNotesNotifications = (data) => ({ type: REQUEST_NOTES_NOTIFICATIONS, data })

export const receiveNotesNotifications = (data) => ({ type: RECEIVE_NOTES_NOTIFICATIONS, data })

export const resetNotesNotifications = () => ({ type: RESET_NOTES_NOTIFICATIONS })
