import { useCallback } from 'react'

import { useLazyDownloadTaxReliefClaimFileQuery, DownloadTaxReliefClaimFileParams } from '../api'

export const useDownloadTaxReliefClaimFile = () => {
    const [downloadTaxReliefClaimFileAction, { error, isLoading }] = useLazyDownloadTaxReliefClaimFileQuery()

    const downloadTaxReliefClaimFile = useCallback(
        ({ taxReliefClaimId, taxReliefClaimFileId }: DownloadTaxReliefClaimFileParams) =>
            downloadTaxReliefClaimFileAction({ taxReliefClaimId, taxReliefClaimFileId }).then((response) => {
                if (response.data) window.location.assign(response.data)
            }),
        [downloadTaxReliefClaimFileAction]
    )

    return {
        downloadTaxReliefClaimFile,
        error,
        isLoading,
    }
}
