import { CustomerDTO } from '@/models'

import { useGetCustomersTaxReliefClaimsQuery } from '../api'

interface UseCustomersTaxReliefClaimsProps {
    customerId: CustomerDTO['id']
    skip?: boolean
}

export const useCustomersTaxReliefClaims = ({ customerId, skip }: UseCustomersTaxReliefClaimsProps) => {
    const { data, isError, isLoading } = useGetCustomersTaxReliefClaimsQuery({ customerId }, { skip })

    return {
        taxReliefClaims: data,
        isError,
        isLoading,
    }
}
