import { overrideFeatureFlagsWithQueryParams } from '@scouts/helpers'

import config from './config'

export const featureFlags = overrideFeatureFlagsWithQueryParams(config.isProduction, {
    featureBookkeepingDeductibility: import.meta.env.REACT_APP_FEATURE_BOOKKEEPING_DEDUCTIBILITY === 'true',
    featureStandaloneBookkeepingExporting:
        import.meta.env.REACT_APP_FEATURE_STANDALONE_BOOKKEEPING_EXPORTING === 'true',
    featureFirebaseEnforcedMFA: import.meta.env.REACT_APP_FEATURE_FIREBASE_ENFORCED_MFA === 'true',
    featureHolidayBanner: import.meta.env.REACT_APP_FEATURE_HOLIDAY_BANNER === 'true',
    featureMessageSuggestions: import.meta.env.REACT_APP_FEATURE_MESSAGE_SUGGESTIONS === 'true',
    featureOffPlatformServices: import.meta.env.REACT_APP_FEATURE_OFF_PLATFORM_SERVICES === 'true',
    featureReturningCustomersBanner: import.meta.env.REACT_APP_FEATURE_RETURNING_CUSTOMERS_BANNER === 'true',
    featureTourFirstTaxReturn: import.meta.env.REACT_APP_FEATURE_TOUR_FIRST_TAX_RETURN === 'true',
    featureTotpMfaEnroll: import.meta.env.REACT_APP_FEATURE_TOTP_MFA_ENROLL === 'true',
    featureCompanyTaxReturns: import.meta.env.REACT_APP_FEATURE_COMPANY_TAX_RETURNS === 'true',
})
