import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { formatDateUTC, getYear } from '@scouts/helpers'
import { colors, font, media, radius, shadows, Flexer, Icon, IconTime, Line, Spacer } from '@scouts/ui'

interface CardTimeProps {
    date: string | Date | null
    name: string | null
    linkDestination: string
}

export const CardTime = ({ date, name, linkDestination }: CardTimeProps) => {
    const history = useHistory()

    const currentYear = getYear()
    const year = getYear(date || undefined)

    const hasDifferentYear = currentYear !== year
    const datePattern = hasDifferentYear ? 'ddd, D MMM YYYY' : 'ddd, D MMM'

    return (
        <Container onClick={() => history.push(linkDestination)}>
            <IconWrapper>
                <Icon Component={IconTime} color={colors.neutralLight} />
            </IconWrapper>
            <Flexer column justifyStart>
                {date ? (
                    <Time title={formatDateUTC(date, 'D MMM YYYY HH:mm') || ''}>
                        <Line weight={font.weight.medium}>{formatDateUTC(date, datePattern)}</Line>
                        <Line size={font.small}>{formatDateUTC(date, 'HH:mm')}</Line>
                    </Time>
                ) : (
                    <Spacer marginBottom="9px">
                        <Line size={font.small}>Check</Line>
                        <Line size={font.small}>your calendar</Line>
                    </Spacer>
                )}
                <Line size={font.small} weight={font.weight.medium} color={colors.blue}>
                    {name || 'Customer'}
                </Line>
            </Flexer>
        </Container>
    )
}

const Time = styled.div`
    margin-bottom: 9px;
`

const IconWrapper = styled.div`
    position: absolute;
    top: 18px;
    right: 24px;
`

const Container = styled.button`
    text-align: left;
    position: relative;
    width: 100%;
    padding: 18px 24px;
    border: 2px solid ${colors.neutralLightest};
    border-radius: ${radius.medium};

    ${media.pointer} {
        cursor: pointer;

        &:hover {
            box-shadow: ${shadows.medium};
        }

        &:active {
            box-shadow: none;
        }
    }
`
