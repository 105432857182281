import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { requestCustomerFiles } from '@/actions'
import { CustomerDTO, CustomerFileDTO, FileType } from '@/models'
import { getCustomerFilesByCustomer } from '@/selectors'
import { useAppDispatch } from '@/store'

export const useCustomerTaxRegistrationFiles = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const dispatch = useAppDispatch()
    const customerFiles = useSelector((state) => getCustomerFilesByCustomer(state, { customerId }))

    useEffect(() => {
        dispatch(requestCustomerFiles({ customerId }))
    }, [dispatch, customerId])

    const taxRegistrationFiles: CustomerFileDTO[] = customerFiles?.filter(
        ({ fileType, customerId: fileCustomerId }: CustomerFileDTO) =>
            fileType === FileType.TaxRegistrationSubmission && fileCustomerId === customerId
    )

    const hasFiles = taxRegistrationFiles.length > 0

    return {
        taxRegistrationFiles,
        hasFiles,
    }
}
