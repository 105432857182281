import _orderBy from 'lodash/orderBy'

import { useGetCustomersIdentityVerificationsQuery, GetCustomersIdentityVerificationsParams } from '../api'

export const useCustomersIdentityVerifications = ({ customerId }: GetCustomersIdentityVerificationsParams) => {
    const { data, isError, isLoading } = useGetCustomersIdentityVerificationsQuery({ customerId })

    const identityVerifications = _orderBy(data, ['updatedDateUTC'], ['desc'])

    return {
        identityVerifications,
        isError,
        isLoading,
    }
}
