import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

import { matchSuccessResponse } from '@scouts/helpers'
import { colors, ActionButton, ButtonLink, Form, Page } from '@scouts/ui'

import { deleteNylasIntegration } from '@/actions'
import {
    PATH_INTEGRATIONS,
    PATH_INTEGRATIONS_NYLAS_CREATE_SCHEDULE,
    PATH_INTEGRATIONS_NYLAS_EDIT_SCHEDULE,
    PATH_PROFILE,
} from '@/constants'
import { AccountantStatus } from '@/models'
import { getUser } from '@/selectors/user'
import { useDeleteNylasSchedulingPage, useNylasSchedulingPages } from '@/store/nylas'

import ProfilePageWithSidebar from '../../ProfilePageWithSidebar'
import PageBackButton from '../../ui/PageBackButton'

const Body = styled.div`
    line-height: 1.5;

    p {
        margin-bottom: 12px;
    }
`

export const IntegrationsNylasEditPage = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { allowTaxConsultation, taxConsultationStatus } = useSelector(getUser)
    const { isFetching: isFetchingSchedulingPages, latestSchedulingPage } = useNylasSchedulingPages()
    const { deleteNylasSchedulingPage } = useDeleteNylasSchedulingPage()

    const { id: schedulingPageId, calendarEmail } = latestSchedulingPage || {}

    const handleDeleteNylasIntegration = () => {
        dispatch(deleteNylasIntegration({}, { onSuccess: () => window.location.reload() }))
    }
    const handleDeleteNylasSchedulingPage = () => {
        if (schedulingPageId) {
            deleteNylasSchedulingPage().then(matchSuccessResponse(handleDeleteNylasIntegration))
        } else {
            handleDeleteNylasIntegration()
        }
    }

    const handleDisconnect = () => {
        const hasConfirmed = window.confirm('Are you sure you want to delete your scheduling calendar?')
        if (hasConfirmed) {
            handleDeleteNylasSchedulingPage()
        }
    }

    const goToCreateDefaultSchedule = () => history.push(PATH_INTEGRATIONS_NYLAS_CREATE_SCHEDULE)
    const goToEditSchedule = () => history.push(PATH_INTEGRATIONS_NYLAS_EDIT_SCHEDULE)

    const hasTaxConsultationsEnabled = allowTaxConsultation && taxConsultationStatus === AccountantStatus.Active
    const isDisconnectDisabled = isFetchingSchedulingPages || hasTaxConsultationsEnabled
    const shouldDisplaySetUpSchedule = !isFetchingSchedulingPages && !latestSchedulingPage

    return (
        <ProfilePageWithSidebar>
            <PageBackButton to={PATH_INTEGRATIONS}>Back to integrations</PageBackButton>

            <Page.PreHeading>
                <Page.PreHeading.Meta>Powered by Nylas</Page.PreHeading.Meta>
            </Page.PreHeading>

            <Page.Heading>Scheduling calendar</Page.Heading>

            <Page.SubHeading>
                <Body>
                    <p>
                        A scheduling calendar enables TaxScouts to schedule tax consultations with your clients,
                        automatically creating calendar events for each consultation.
                    </p>

                    {calendarEmail && (
                        <p>
                            You are using <strong>{calendarEmail}</strong> for your calendar.
                        </p>
                    )}
                </Body>
            </Page.SubHeading>

            {shouldDisplaySetUpSchedule ? (
                <Page.Section background={colors.yellow}>
                    <Body>
                        <p>It seems your integration is not set up yet</p>
                    </Body>
                    <Form.Actions alignRight>
                        <ActionButton type="button" onClick={goToCreateDefaultSchedule}>
                            Finish setup
                        </ActionButton>
                    </Form.Actions>
                </Page.Section>
            ) : (
                <Page.Section background={colors.mintLighter}>
                    <Form.Actions alignRight>
                        <ActionButton type="button" onClick={goToEditSchedule} disabled={isFetchingSchedulingPages}>
                            Edit your schedule
                        </ActionButton>
                    </Form.Actions>
                </Page.Section>
            )}

            <Page.Section background={colors.redLighter}>
                {hasTaxConsultationsEnabled && (
                    <Body>
                        <p>You must pause tax consultations before disconnecting your calendar.</p>
                        <p>
                            You can do this from <Link to={PATH_PROFILE}>My Profile</Link>.
                        </p>
                    </Body>
                )}
                <ButtonLink color={colors.red} onClick={handleDisconnect} disabled={isDisconnectDisabled}>
                    Disconnect
                </ButtonLink>
            </Page.Section>
        </ProfilePageWithSidebar>
    )
}
