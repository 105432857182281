import autosize from 'autosize'
import { useLayoutEffect, useRef } from 'react'

const smartAutosize = (element: HTMLTextAreaElement) => {
    if (!element.dataset.autosized) {
        autosize(element)
        element.setAttribute('data-autosized', 'true')
    }
    autosize.update(element)
}

export const useAutosizeTextArea = (ref: React.RefObject<HTMLTextAreaElement>) => {
    const previousValueRef = useRef(ref.current?.value)

    useLayoutEffect(() => {
        if (!ref.current) return

        const previousValue = previousValueRef.current
        const currentValue = ref.current?.value

        if (currentValue !== previousValue) {
            if (!currentValue) ref.current.style.removeProperty('height')
            if (currentValue) smartAutosize(ref.current)
        }

        previousValueRef.current = currentValue
    })
}
