import { useState, ReactNode } from 'react'

import Inbox from '@scouts/inbox'

import { DEFAULT_CONTEXT, InboxConfigurationContext, InboxConfigurationContextProps } from './InboxConfigurationContext'

interface InboxConfigurationProviderProps {
    children: ReactNode
}

export const InboxConfigurationProvider = ({ children }: InboxConfigurationProviderProps) => {
    const contextData = useState<InboxConfigurationContextProps>(DEFAULT_CONTEXT)
    const [state] = contextData

    const { config } = state

    return (
        <InboxConfigurationContext.Provider value={contextData}>
            <Inbox.Providers config={config}>{children}</Inbox.Providers>
        </InboxConfigurationContext.Provider>
    )
}
