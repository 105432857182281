import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, Input } from '@scouts/ui'

const Form = styled.form``

const Actions = styled.div`
    display: flex;
    margin-top: 12px;
`

const Action = styled.div`
    margin-right: 6px;
`

const propTypes = {
    applyFilter: PropTypes.func.isRequired,
    closeDropdown: PropTypes.func,
    name: PropTypes.string.isRequired,
    removeFilter: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}

const defaultProps = {
    closeDropdown: null,
}

const FilterItemDropdownInput = (props) => {
    const { name, value, applyFilter, removeFilter, closeDropdown } = props

    const [localValue, setLocalValue] = useState('')

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    const handleSubmit = (e) => {
        e.preventDefault()

        const filter = {
            [name]: localValue,
        }

        applyFilter(filter)

        if (closeDropdown) closeDropdown()
    }

    const handleChange = ({ value: targetValue }) => {
        setLocalValue(targetValue)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Input autoFocus name={name} value={localValue} onChange={handleChange} />

            <Actions>
                <Action>
                    <Button type="submit" size="small">
                        Apply
                    </Button>
                </Action>
                <Action>
                    <Button isSecondary size="small" onClick={removeFilter}>
                        Remove
                    </Button>
                </Action>
            </Actions>
        </Form>
    )
}

FilterItemDropdownInput.propTypes = propTypes

FilterItemDropdownInput.defaultProps = defaultProps

export default FilterItemDropdownInput
