import { useHistory } from 'react-router-dom'

import { PATH_INTEGRATIONS_NYLAS } from '@/constants'

import { GlobalNotice } from './GlobalNotice'

const NoticeNylasReauthentication = () => {
    const history = useHistory()

    return (
        <GlobalNotice
            buttonText="Fix here"
            onButtonClick={() => {
                history.push(PATH_INTEGRATIONS_NYLAS)
            }}
        >
            Your calendar is no longer connected. Please reconnect your calendar.
        </GlobalNotice>
    )
}

export default NoticeNylasReauthentication
