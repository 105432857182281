import { useState } from 'react'

import { SelectedCustomer, TaxRegistrationDTO } from '@/models'

export const useSelection = ({ taxRegistrations }: { taxRegistrations: TaxRegistrationDTO[] | undefined }) => {
    const [selectedCustomers, setSelectedCustomers] = useState<SelectedCustomer[]>([])

    const deselectAll = () => {
        setSelectedCustomers([])
    }

    const selectAll = () => {
        const updatedSelectedCustomers: SelectedCustomer[] = []

        taxRegistrations?.forEach(({ customerId, customerFirstName, customerLastName, unsubscribeFromBulkEmails }) => {
            if (updatedSelectedCustomers.findIndex((o) => o.customerId === customerId) > -1) return

            const fullName = `${customerFirstName} ${customerLastName}`.trim()

            updatedSelectedCustomers.push({
                customerId,
                fullName,
                unsubscribeFromBulkEmails,
            })
        })

        setSelectedCustomers(updatedSelectedCustomers)
    }

    const handleSelectCustomer = ({ customerId, fullName, unsubscribeFromBulkEmails }: SelectedCustomer) => {
        const isSelected = selectedCustomers.findIndex((o) => o.customerId === customerId) > -1

        let updatedSelectedCustomers = [...selectedCustomers]

        if (isSelected) {
            updatedSelectedCustomers = selectedCustomers.filter((o) => !(o.customerId === customerId))
        } else {
            updatedSelectedCustomers.push({
                customerId,
                fullName,
                unsubscribeFromBulkEmails,
            })
        }

        setSelectedCustomers(updatedSelectedCustomers)
    }

    return {
        handleSelectCustomer,
        selectAll,
        selectedCustomers,
        deselectAll,
    }
}
