import { colors } from '@scouts/ui'

import { PATH_TAX_CONSULTATIONS } from '@/constants'
import { generatePathWithFilter } from '@/helpers/generate-path-with-filter'
import { TaxConsultationStatusFilter } from '@/models'

import { Card } from '../_ui/Card'

interface TaxConsultationStatisticsProps {
    accountantId: number | null
    statistics: {
        taxConsultationsBooked: number | null
        taxConsultationsInProgress: number | null
    }
}

export const TaxConsultationStatistics = ({ accountantId, statistics }: TaxConsultationStatisticsProps) => (
    <>
        <Card
            background={colors.blueLighter}
            linkTo={generatePathWithFilter(PATH_TAX_CONSULTATIONS, {
                accountantId: accountantId ? accountantId : null,
                status: TaxConsultationStatusFilter.Booked,
            })}
            title="Booked"
            value={statistics.taxConsultationsBooked || 0}
        />
        <Card
            background={colors.neutralLightest}
            linkTo={generatePathWithFilter(PATH_TAX_CONSULTATIONS, {
                accountantId: accountantId ? accountantId : null,
                status: TaxConsultationStatusFilter.AwaitingSummary,
            })}
            title="Awaiting summary"
            value={statistics.taxConsultationsInProgress || 0}
        />
    </>
)
