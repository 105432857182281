import { Container, Grid } from '@scouts/ui'

import { AccountantDetailsDTO } from '@/models'

import { DashboardDownloadsKyc } from './DashboardDownloadsKyc'
import { DashboardDownloadsRevenue } from './DashboardDownloadsRevenue'

export const DashboardDownloadsRow = ({ accountantId }: { accountantId: AccountantDetailsDTO['id'] }) => {
    return (
        <Container>
            <Grid tabletColumns={2} columns={1} gap="12px">
                <DashboardDownloadsRevenue accountantId={accountantId} />
                <DashboardDownloadsKyc accountantId={accountantId} />
            </Grid>
        </Container>
    )
}
