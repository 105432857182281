import { useState } from 'react'

import {
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Paragraph,
    TextArea,
    TextAreaOnChangeParams,
} from '@scouts/ui'

import { MessageTemplateDTO } from '@/models/message-template'

import { MessageTemplateSelect } from '../../../message-templates/MessageTemplateSelect'

interface ReviewRequestAgainProps {
    handleCancel: () => void
    handleRequestAgain: (content: string) => void
    isLoading: boolean
}

export const ReviewRequestAgain = ({ handleCancel, handleRequestAgain, isLoading }: ReviewRequestAgainProps) => {
    const [hasEditedTemplateMessage, setHasEditedTemplateMessage] = useState(false)
    const [messageTemplateId, setMessageTemplateId] = useState('')
    const [messageContent, setMessageContent] = useState('')

    const handleMessageTemplateChange = ({
        value: selectedTemplateId,
        template: selectedTemplate,
    }: {
        value: string
        template?: MessageTemplateDTO
    }) => {
        if (messageContent && hasEditedTemplateMessage) {
            const isConfirmed = window.confirm('Are you sure?\n\nYou will lose the current message')
            if (!isConfirmed) return
        }

        if (selectedTemplateId) {
            setMessageContent(selectedTemplate?.content ?? '')
            setMessageTemplateId(selectedTemplateId)
            setHasEditedTemplateMessage(false)
        } else {
            setMessageContent('')
            setMessageTemplateId('')
            setHasEditedTemplateMessage(false)
        }
    }

    const handleMessageContentChange = ({ value }: TextAreaOnChangeParams) => {
        setHasEditedTemplateMessage(true)
        setMessageContent(value)
    }

    const handleSubmit = () => {
        handleRequestAgain(messageContent)
    }

    return (
        <Modal isWide onClose={handleCancel}>
            <ModalHeader>
                <ModalTitle>Review identity verification</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Form.Row>
                    <Form.Row.Content>
                        <Paragraph>
                            Message template: &nbsp;&nbsp;
                            <MessageTemplateSelect
                                name="messageTemplateId"
                                value={messageTemplateId}
                                onChange={handleMessageTemplateChange}
                            />
                        </Paragraph>
                    </Form.Row.Content>
                </Form.Row>
                <Paragraph>Additional note to explain what you need (will be sent as an email)</Paragraph>
                <TextArea
                    name="messageContent"
                    value={messageContent}
                    placeholder="Notes…"
                    onChange={handleMessageContentChange}
                    height="144px"
                />
            </ModalBody>
            <ModalFooter>
                <ModalActions>
                    <Button isSecondary onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} isLoading={isLoading}>
                        Request new docs
                    </Button>
                </ModalActions>
            </ModalFooter>
        </Modal>
    )
}
