import _get from 'lodash/get'

import { RECEIVE_ISSUES, RESET_ISSUES, RESOLVE_ISSUE, UNRESOLVE_ISSUE } from '../actions'
import { updateOrAddItemsById } from '../helpers'

export default (state = { data: [] }, action) => {
    const { type, data } = action

    switch (type) {
        case RECEIVE_ISSUES: {
            const items = updateOrAddItemsById({ currentItems: state.data, receivedItems: data })

            return { ...state, data: items }
        }

        case RESOLVE_ISSUE: {
            const items = [..._get(state, ['data'], [])]

            const key = items.findIndex((o) => o.id === data.issueId)

            if (key > -1 && items[key]) {
                items[key] = { ...items[key], resolved: true }
            }

            return { ...state, data: items }
        }

        case UNRESOLVE_ISSUE: {
            const items = [..._get(state, ['data'], [])]

            const key = items.findIndex((o) => o.id === data.issueId)

            if (key > -1 && items[key]) {
                items[key] = { ...items[key], resolved: false }
            }

            return { ...state, data: items }
        }

        case RESET_ISSUES: {
            return { ...state, data: [] }
        }

        default:
            return state
    }
}
