import styled from 'styled-components'

import { colors } from '@scouts/ui'

import { SelectedCustomer } from '@/models'

import BulkActions from '../BulkActions'

interface TaxRegistrationsBulkActionsProps {
    selectedCustomers: SelectedCustomer[]
    deselectAll: () => void
}

export const TaxRegistrationsBulkActions = ({ selectedCustomers, deselectAll }: TaxRegistrationsBulkActionsProps) => {
    if (selectedCustomers?.length === 0) return null

    return (
        <BulkActionsContainer>
            <BulkActions selectedItems={selectedCustomers} deselectAll={deselectAll} />
        </BulkActionsContainer>
    )
}

const BulkActionsContainer = styled.div`
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 9000;
    width: calc(100% - 36px);
    padding: 18px;
    background: ${colors.blueLighter};
`
