import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { Badge } from '@scouts/ui'

import { SelfAssessmentSummaryDTO } from '@/models'

import { SelfAssessmentsTableNotificationsPopup } from './SelfAssessmentsTableNotificationsPopup'

export const SelfAssessmentsTableNotifications = ({ selfAssessment }: { selfAssessment: SelfAssessmentSummaryDTO }) => {
    const [isPopupOpen, openPopup, closePopup] = useToggle(false)

    const hasNotifications = selfAssessment.numberOfNotificationEvents > 0

    if (!hasNotifications) return null

    return (
        <Container>
            {isPopupOpen && (
                <SelfAssessmentsTableNotificationsPopup selfAssessment={selfAssessment} onClose={closePopup} />
            )}

            <NotificationsContainer onMouseEnter={openPopup}>
                {hasNotifications && <Badge>{selfAssessment.numberOfNotificationEvents}</Badge>}
            </NotificationsContainer>
        </Container>
    )
}

const Container = styled.div`
    display: inline-block;
    position: relative;
    margin-right: 12px;
`

const NotificationsContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`
