import { FileType } from '@/models'

import { useGetTaxConsultationFilesQuery, GetTaxConsultationFilesParams } from '../api'

export const useTaxConsultationFiles = ({ taxConsultationId }: GetTaxConsultationFilesParams) => {
    const { data, isError, isLoading } = useGetTaxConsultationFilesQuery({ taxConsultationId })

    const taxConsultationCustomerFiles = data?.filter(
        ({ fileType }) => fileType === FileType.TaxConsultationCustomerDocument
    )
    const taxConsultationAccountantFiles = data?.filter(({ fileType }) => fileType === FileType.TaxConsultationSummary)

    return {
        taxConsultationFiles: data,
        taxConsultationCustomerFiles,
        taxConsultationAccountantFiles,
        hasTaxConsultationFiles: !!data?.length,
        isError,
        isLoading,
    }
}
