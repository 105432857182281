import FileSaver from 'file-saver'
import _get from 'lodash/get'
import _mapKeys from 'lodash/mapKeys'
import queryString from 'query-string'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import { addSuccessToast } from '@scouts/ui'

import { invalidateUnresolvedEscalationsCount } from '@/store/escalations/api'

import {
    receiveCustomerEscalations,
    receiveEscalations,
    removeCustomerEscalation,
    CREATE_ESCALATION,
    DELETE_ESCALATION,
    EXPORT_ESCALATIONS,
    REQUEST_CUSTOMER_ESCALATIONS,
    REQUEST_ESCALATIONS,
    UPDATE_ESCALATION,
} from '../actions'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_PAGE_SIZE } from '../constants'
import { handleError } from '../handlers'
import { apiCall, apiCallCancelable, getPaginationHeaders, isCancelledApiCall } from '../helpers'

export default function* escalationsSagas() {
    yield takeEvery(REQUEST_ESCALATIONS, fetchEscalations)
    yield takeEvery(EXPORT_ESCALATIONS, exportEscalations)
    yield takeLatest(CREATE_ESCALATION, createEscalation)
    yield takeLatest(UPDATE_ESCALATION, updateEscalation)
    yield takeLatest(DELETE_ESCALATION, deleteEscalation)
    yield takeLatest(REQUEST_CUSTOMER_ESCALATIONS, fetchCustomerEscalations)
}

const escalationSearchFilterMap = {
    occurredDateStart: 'occurredDate.Start',
    occurredDateEnd: 'occurredDate.End',
    resolvedDateStart: 'resolvedDate.Start',
    resolvedDateEnd: 'resolvedDate.End',
}

const renameEscalationSearchFilters = (filter) => _mapKeys(filter, (_, key) => escalationSearchFilterMap[key] || key)

function* fetchEscalations({ data, meta }) {
    const filter = _get(data, ['filter'], {})

    const page = _get(data, ['page'], PAGINATION_DEFAULT_PAGE)
    const pageSize = _get(data, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE)

    const urlComponents = queryString.stringify({
        ...renameEscalationSearchFilters(filter),
        ...(page && { page }),
        ...(pageSize && { pageSize }),
    })

    const url = `escalations?${urlComponents}`

    const cancelToken = _get(meta, ['cancelToken'], null)

    try {
        yield put(showLoading())
        const response = yield apiCallCancelable({ url, cancelToken })
        const pagination = getPaginationHeaders(response.headers)
        yield put(receiveEscalations(response.data, { pagination }))
    } catch (e) {
        if (!isCancelledApiCall(e)) {
            yield put(handleError(e))
        }
    } finally {
        yield put(hideLoading())
    }
}

function* exportEscalations({ data }) {
    const filter = _get(data, ['filter'], {})
    const page = _get(data, ['page'], PAGINATION_DEFAULT_PAGE)
    const pageSize = _get(data, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE)

    const escalationFiltersModel = {
        ...renameEscalationSearchFilters(filter),
        ...(page && { page }),
        ...(pageSize && { pageSize }),
    }

    const urlComponents = queryString.stringify(escalationFiltersModel)
    const url = `escalations/export?${urlComponents}`

    try {
        yield put(showLoading())
        const response = yield apiCall(url)
        if (response.data) {
            const file = new Blob([response.data], {})
            FileSaver.saveAs(file, `escalations.csv`)
        }
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* fetchCustomerEscalations({ data }) {
    const customerId = _get(data, ['customerId'], '')

    try {
        yield put(showLoading())
        const response = yield apiCall(`customers/${customerId}/escalations`)
        yield put(receiveCustomerEscalations(response.data))
    } catch (e) {
        yield put(handleError(e))
    } finally {
        yield put(hideLoading())
    }
}

function* createEscalation({ data, meta }) {
    const { onSuccess, onError } = meta

    try {
        yield put(showLoading())
        const response = yield apiCall(`escalations`, 'post', data)
        yield put(receiveCustomerEscalations([response.data]))
        yield put(invalidateUnresolvedEscalationsCount())

        if (onSuccess) onSuccess(response.data)
        addSuccessToast({ body: 'Escalation created' })
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError(e)
    } finally {
        yield put(hideLoading())
    }
}

function* updateEscalation({ data, meta }) {
    const { onSuccess, onError } = meta
    const escalationId = _get(data, ['id'], '')

    try {
        yield put(showLoading())
        const response = yield apiCall(`escalations/${escalationId}`, 'put', data)
        yield put(receiveCustomerEscalations([response.data]))
        yield put(invalidateUnresolvedEscalationsCount())

        if (onSuccess) onSuccess(response.data)
        addSuccessToast({ body: 'Escalation updated' })
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError(e)
    } finally {
        yield put(hideLoading())
    }
}

function* deleteEscalation({ data, meta }) {
    const { onSuccess, onError } = meta
    const escalationId = _get(data, ['escalationId'], '')

    try {
        yield put(showLoading())
        const response = yield apiCall(`escalations/${escalationId}`, 'delete', data)
        yield put(removeCustomerEscalation({ escalationId }))
        yield put(invalidateUnresolvedEscalationsCount())

        if (onSuccess) onSuccess(response.data)
        addSuccessToast({ body: 'Escalation deleted' })
    } catch (e) {
        yield put(handleError(e))

        if (onError) onError(e)
    } finally {
        yield put(hideLoading())
    }
}
