import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { requestCustomer } from '@/actions'
import { useAppDispatch } from '@/store/store'

import { useUpdateCustomersBankAccountMutation, UpdateCustomersBankAccountParams } from '../api'

export const useUpdateCustomersBankAccount = () => {
    const dispatch = useAppDispatch()
    const [action, { isLoading }] = useUpdateCustomersBankAccountMutation()

    const updateCustomersBankAccount = useCallback(
        ({ customerId, data }: UpdateCustomersBankAccountParams) =>
            action({ customerId, data }).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Bank details saved' })
                    dispatch(requestCustomer(customerId))
                })
            ),
        [action, dispatch]
    )

    return { updateCustomersBankAccount, isLoading }
}
