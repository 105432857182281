import {
    createCustomerActiveProductBundles,
    createCustomerActiveSelfAssessments,
    createCustomerActiveTaxConsultations,
    createCustomerCompletedSelfAssessments,
    createCustomerCompletedTaxConsultations,
} from './customer-services-creators'

export const getQuickSearchResults = (state) => state.quickSearch.items || []
export const getIsFetchingQuickSearch = (state) => state.quickSearch.isFetching

const getQuickSearchResultItem = (state, { id }) => {
    const quickSearchResults = getQuickSearchResults(state)
    return quickSearchResults.find((o) => o.id === id)
}

const getQuickSearchProductBundles = (state, { customerId }) => {
    const item = getQuickSearchResultItem(state, { id: customerId })
    return item?.productBundles || []
}

const getQuickSearchSelfAssessments = (state, { customerId }) => {
    const item = getQuickSearchResultItem(state, { id: customerId })
    return item?.selfAssessments || []
}

const getQuickSearchTaxConsultations = (state, { customerId }) => {
    const item = getQuickSearchResultItem(state, { id: customerId })
    return item?.taxConsultations || []
}

export const getQuickSearchActiveSelfAssessments = createCustomerActiveSelfAssessments(getQuickSearchSelfAssessments)
export const getQuickSearchCompletedSelfAssessments =
    createCustomerCompletedSelfAssessments(getQuickSearchSelfAssessments)

export const getQuickSearchActiveTaxConsultations = createCustomerActiveTaxConsultations(getQuickSearchTaxConsultations)
export const getQuickSearchCompletedTaxConsultations =
    createCustomerCompletedTaxConsultations(getQuickSearchTaxConsultations)

export const getQuickSearchActiveProductBundles = createCustomerActiveProductBundles(
    getQuickSearchProductBundles,
    getQuickSearchActiveTaxConsultations,
    getQuickSearchActiveSelfAssessments
)
