import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Table } from '@scouts/ui'

import { markRewardAsIgnored, markRewardAsPaid, triggerRewardPayment } from '../../actions'
import RewardsRow from './RewardsRow'

const Container = styled.div`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

class Rewards extends Component {
    constructor() {
        super()

        _bindAll(this, ['markAsPaid', 'toggleIgnored', 'triggerPayment'])
    }

    markAsPaid(reward) {
        const { dispatch } = this.props

        if (
            !window.confirm(
                'Are you sure you want to mark as paid?\r\nNOTE: This WILL NOT trigger the full payment flow via TransferWise.'
            )
        )
            return

        const updatedReward = { ...reward, referredByUserRewarded: true }

        dispatch(markRewardAsPaid(updatedReward))
    }

    toggleIgnored(event) {
        const { dispatch } = this.props

        dispatch(markRewardAsIgnored({ id: event.name, ignored: event.checked }))
    }

    triggerPayment(reward) {
        const { dispatch } = this.props

        if (
            !window.confirm(
                'Are you sure you want to pay this reward?\r\nNOTE: This WILL trigger the full payment flow via TransferWise.'
            )
        )
            return

        dispatch(triggerRewardPayment(reward))
    }

    render() {
        const { items, isFetching } = this.props

        return (
            <Container isFetching={isFetching}>
                <Table>
                    <Table.Header>
                        <Table.Header.Row>
                            <Table.Header.Cell width="25%">Earned by</Table.Header.Cell>
                            <Table.Header.Cell width="7%">Amount</Table.Header.Cell>
                            <Table.Header.Cell width="10%">Bank details</Table.Header.Cell>
                            <Table.Header.Cell width="20%">Referred client</Table.Header.Cell>
                            <Table.Header.Cell width="10%">Client has paid</Table.Header.Cell>
                            <Table.Header.Cell width="10%">Updated</Table.Header.Cell>
                            <Table.Header.Cell width="calc(18% - 96px)">Payment status</Table.Header.Cell>
                            <Table.Header.Cell width="96px" alignRight>
                                Ignore
                            </Table.Header.Cell>
                        </Table.Header.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((item) => {
                            const { id } = item
                            return (
                                <RewardsRow
                                    key={id}
                                    data={item}
                                    markAsPaid={this.markAsPaid}
                                    toggleIgnored={this.toggleIgnored}
                                    triggerPayment={this.triggerPayment}
                                />
                            )
                        })}
                    </Table.Body>
                </Table>
            </Container>
        )
    }
}

Rewards.propTypes = {
    dispatch: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
}

export default connect()(Rewards)
