import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import { Component } from 'react'
import styled from 'styled-components'

import { colors, font, Button } from '@scouts/ui'

import { SUPPORT_EMAIL } from '@/constants'

const Wrapper = styled.div`
    margin: 24px;
    line-height: 1.5;
`

const ErrorInfo = styled.pre`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    white-space: pre-wrap;
`

const StrictCookieError = () => (
    <>
        <Wrapper>Your browser has cookies disabled. Please check your settings and try again.</Wrapper>

        <Wrapper>
            <Button
                onClick={() => {
                    window.location.assign('/')
                }}
            >
                Try again
            </Button>
        </Wrapper>
    </>
)
const GenericError = ({ errorMessage }) => (
    <>
        <Wrapper>Oops, something unexpected happened. Please reload the page or contact {SUPPORT_EMAIL}</Wrapper>

        <Wrapper>
            <Button
                onClick={() => {
                    window.location.assign('/')
                }}
            >
                Reload
            </Button>
        </Wrapper>

        <Wrapper>
            <ErrorInfo>{errorMessage}</ErrorInfo>
        </Wrapper>
    </>
)
GenericError.propTypes = {
    errorMessage: PropTypes.string.isRequired,
}

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { errorMessage: '' }
    }

    static getDerivedStateFromError(error) {
        if (error.name === 'SecurityError' && error.code === 18) return { isStrictCookieError: true }

        return { errorMessage: error?.message }
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
    }

    render() {
        const { children } = this.props
        const { errorMessage, isStrictCookieError } = this.state

        if (isStrictCookieError) return <StrictCookieError />
        if (errorMessage) return <GenericError errorMessage={errorMessage} />

        return children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ErrorBoundary
