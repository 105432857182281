import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, Label, Tooltip } from '@scouts/ui'

import {
    PATH_CUSTOMERS,
    SELF_ASSESSMENT_STATUS_CANCELLED,
    TAX_CONSULTATION_STATUS_CANCELLED,
    TAX_CONSULTATION_STATUS_TITLES,
    TAX_YEARS,
} from '@/constants'
import { backgroundConsultation, backgroundSelfAssessment, backgroundTaxRegistration } from '@/helpers/colors'
import {
    ProductBundleDetailsDTO,
    SelfAssessmentDTO,
    SelfAssessmentStatusTitles,
    TaxConsultationDTO,
    TaxRegistrationDTO,
    TaxRegistrationStatus,
    TaxRegistrationStatusTitles,
} from '@/models'

interface CustomersTableServices {
    customerId: number | undefined
    productBundles: Pick<ProductBundleDetailsDTO, 'id'>[]
    selfAssessments: Pick<SelfAssessmentDTO, 'id' | 'status' | 'taxYear'>[]
    taxConsultations: Pick<TaxConsultationDTO, 'id' | 'status'>[]
    taxRegistrations: Pick<TaxRegistrationDTO, 'id' | 'status'>[]
    hideCancelledServices?: boolean
}

export const CustomersTableServices = ({
    customerId = undefined,
    hideCancelledServices = false,
    productBundles,
    selfAssessments,
    taxConsultations,
    taxRegistrations,
}: CustomersTableServices) => {
    const history = useHistory()

    const activeCount =
        selfAssessments.length + productBundles.length + taxConsultations.length + taxRegistrations.length

    const hasActiveProcesses = activeCount > 0

    const customerOverviewUrl = `${PATH_CUSTOMERS}/${customerId}`

    const renderSelfAssessment = (item: Pick<SelfAssessmentDTO, 'id' | 'status' | 'taxYear'>) => {
        const { id, status, taxYear } = item
        const selfAssessmentUrl = `${PATH_CUSTOMERS}/${customerId}/assessment/${id}`

        const taxYearNumber = Number(taxYear) as keyof typeof TAX_YEARS

        if (status === SELF_ASSESSMENT_STATUS_CANCELLED && hideCancelledServices) return null

        return (
            <ProcessRow key={`selfAssessment-${id}`} onClick={() => history.push(selfAssessmentUrl)}>
                <Tooltip content={SelfAssessmentStatusTitles[status]}>
                    <Label background={backgroundSelfAssessment(status)} color={colors.black}>
                        {TAX_YEARS[taxYearNumber]}
                    </Label>
                </Tooltip>
            </ProcessRow>
        )
    }

    const renderProductBundle = (item: Pick<ProductBundleDetailsDTO, 'id'>) => {
        const { id } = item
        return (
            <ProcessRow key={`bundle-${id}`} onClick={() => history.push(customerOverviewUrl)}>
                <Label background={colors.mintLighter}>Bundle</Label>
            </ProcessRow>
        )
    }

    const renderTaxConsultation = (item: Pick<TaxConsultationDTO, 'id' | 'status'>) => {
        const { id, status } = item
        const taxConsultationUrl = `${PATH_CUSTOMERS}/${customerId}/consultations/${id}`

        if (status === TAX_CONSULTATION_STATUS_CANCELLED && hideCancelledServices) return null

        return (
            <ProcessRow key={`taxConsultation-${id}`} onClick={() => history.push(taxConsultationUrl)}>
                <Tooltip content={TAX_CONSULTATION_STATUS_TITLES[status]}>
                    <Label background={backgroundConsultation(status)} color={colors.black}>
                        Consultation
                    </Label>
                </Tooltip>
            </ProcessRow>
        )
    }

    const renderTaxRegistration = (item: Pick<TaxRegistrationDTO, 'id' | 'status'>) => {
        const { id, status } = item

        if (status === TaxRegistrationStatus.Cancelled && hideCancelledServices) return null

        return (
            <ProcessRow key={`taxRegistration-${id}`} onClick={() => history.push(customerOverviewUrl)}>
                <Tooltip content={TaxRegistrationStatusTitles[status]}>
                    <Label background={backgroundTaxRegistration(status)} color={colors.black}>
                        UTR
                    </Label>
                </Tooltip>
            </ProcessRow>
        )
    }

    return (
        <Container>
            {hasActiveProcesses && (
                <Processes>
                    {productBundles.map((item) => renderProductBundle(item))}
                    {selfAssessments.map((item) => renderSelfAssessment(item))}
                    {taxConsultations.map((item) => renderTaxConsultation(item))}
                    {taxRegistrations.map((item) => renderTaxRegistration(item))}
                </Processes>
            )}
        </Container>
    )
}

const Container = styled.div`
    font-size: ${font.smaller};
`

const Processes = styled.div`
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
`

const ProcessRow = styled.button`
    margin: 0 6px 6px 0;

    &:last-child {
        margin-right: 0;
    }
`
