import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
    colors,
    Button,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
} from '@scouts/ui'

import { requestCustomerFiles } from '@/actions'
import {
    CustomerDTO,
    CustomerFileDTO,
    FileType,
    FileTypeTitles,
    IdentityVerificationDTO,
    IdentityVerificationStatus,
} from '@/models'
import { getCustomerAddressVerificationFiles, getCustomerIdentityVerificationFiles } from '@/selectors'
import { useAppDispatch } from '@/store'
import { useUpdateIdentityVerification } from '@/store/identity-verifications'

import FileWithActions from '../FileWithActions'
import UploadFilesCustomer from '../UploadFilesCustomer'

const IDENTITY_VERIFICATION_UPLOAD_TYPES = [{ fileType: FileType.IdDocument }, { fileType: FileType.ProofOfAddress }]

interface VerificationFileUploadModalProps {
    customerId: CustomerDTO['id']
    closeModal: () => void
    identityVerification: IdentityVerificationDTO
}

export const VerificationFileUploadModal = ({
    customerId,
    closeModal,
    identityVerification,
}: VerificationFileUploadModalProps) => {
    const dispatch = useAppDispatch()
    const { updateIdentityVerification, isLoading } = useUpdateIdentityVerification()
    const { id: identityVerificationId } = identityVerification

    const identityVerificationFiles = useSelector((state) =>
        getCustomerIdentityVerificationFiles(state, { identityVerificationId })
    )
    const addressVerificationFiles = useSelector((state) =>
        getCustomerAddressVerificationFiles(state, { identityVerificationId })
    )

    useEffect(() => {
        dispatch(requestCustomerFiles({ customerId }))
    }, [customerId, dispatch])

    const handleSubmit = () => {
        updateIdentityVerification({
            identityVerificationId: identityVerification.id,
            data: {
                ...identityVerification,
                status: IdentityVerificationStatus.Submitted,
            },
        }).then(() => {
            closeModal()
        })
    }

    return (
        <Modal onClose={closeModal}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Identity verification documents</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <Form.Row>
                        <Form.Row.Content>
                            <UploadWrapper>
                                <UploadFilesCustomer
                                    customerId={customerId}
                                    identityVerificationId={identityVerificationId}
                                    uploadTypes={IDENTITY_VERIFICATION_UPLOAD_TYPES}
                                />
                            </UploadWrapper>
                        </Form.Row.Content>
                    </Form.Row>

                    {identityVerificationFiles.length > 0 && (
                        <Form.Row>
                            <Form.Row.Title>{FileTypeTitles[FileType.IdDocument]}</Form.Row.Title>
                            {identityVerificationFiles.map((file: CustomerFileDTO) => (
                                <Form.Row.Content key={file.id}>
                                    <FileWithActions
                                        background={colors.neutralLightest}
                                        file={file}
                                        isCustomerFile
                                        isDeletable
                                        label={file.fileName}
                                    />
                                </Form.Row.Content>
                            ))}
                        </Form.Row>
                    )}

                    {addressVerificationFiles.length > 0 && (
                        <Form.Row>
                            <Form.Row.Title>{FileTypeTitles[FileType.ProofOfAddress]}</Form.Row.Title>
                            {addressVerificationFiles.map((file: CustomerFileDTO) => (
                                <Form.Row.Content key={file.id}>
                                    <FileWithActions
                                        background={colors.neutralLightest}
                                        file={file}
                                        isCustomerFile
                                        isDeletable
                                        label={file.fileName}
                                    />
                                </Form.Row.Content>
                            ))}
                        </Form.Row>
                    )}
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={closeModal} disabled={isLoading}>
                            Close
                        </Button>
                        <Button type="submit" isLoading={isLoading}>
                            Submit for review
                        </Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const UploadWrapper = styled.div`
    display: inline-block;
`
