import { useCallback } from 'react'

import { addErrorToast } from '@scouts/ui'

import { ERROR_MESSAGE_SAVING } from '@/constants'
import { AccountantMeetingSummaryDTO } from '@/models'

import { useUpdateAccountantMeetingStatusMutation } from '../api'

interface UpdateAccountantMeetingStatusParams {
    id: AccountantMeetingSummaryDTO['id']
    status: AccountantMeetingSummaryDTO['status']
}

export const useUpdateAccountantMeeting = () => {
    const [updateAccountantMeetingStatusAction, { error, isLoading }] = useUpdateAccountantMeetingStatusMutation()

    const updateAccountantMeetingStatus = useCallback(
        async ({ id, status }: UpdateAccountantMeetingStatusParams) =>
            updateAccountantMeetingStatusAction({ accountantMeetingId: id, status })
                .unwrap()
                .then((response) => response)
                .catch(() => {
                    addErrorToast({ body: ERROR_MESSAGE_SAVING })
                }),
        [updateAccountantMeetingStatusAction]
    )

    return {
        updateAccountantMeetingStatus,
        error,
        isLoading,
    }
}
