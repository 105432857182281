import { useSelector } from 'react-redux'

import { CustomerDTO } from '@/models'
import { getCustomerById } from '@/selectors'

export const useCustomer = (customerId: number): { customer: CustomerDTO } => {
    const customer = useSelector((state) => getCustomerById(state, { customerId }))

    return { customer }
}
