import { useGetCustomerProductStatisticsQuery, GetCustomersProductStatisticsParams } from '../api'

interface UseCustomersProductStatisticsProps {
    params: GetCustomersProductStatisticsParams
    skip?: boolean
}

export const useCustomersProductStatistics = ({ params, skip = false }: UseCustomersProductStatisticsProps) => {
    const shouldSkipInitialFetch = !Object.keys(params).length

    const { data, isFetching, refetch } = useGetCustomerProductStatisticsQuery(params, {
        skip: shouldSkipInitialFetch || !!skip,
    })

    return {
        data,
        isFetching,
        refetch,
    }
}
