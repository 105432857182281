import { useHistory } from 'react-router-dom'

import { colors, font, ButtonLink, Container, Flexer, Grid, Line, Spacer } from '@scouts/ui'

import { PAGINATION_DEFAULT_PAGE, PATH_OFF_PLATFORM_SERVICES_REQUESTS } from '@/constants'
import { formatDate } from '@/helpers'
import { useOffPlatformServicesRequests } from '@/store/off-platform-services-requests'

import { Card } from './_ui/Card'

const DISPLAY_COUNT = 4

export const SupportDashboardOffPlatformServices = () => {
    const history = useHistory()
    const { offPlatformServicesRequests } = useOffPlatformServicesRequests({
        options: { status: 'Submitted', page: PAGINATION_DEFAULT_PAGE, pageSize: 100 },
    })

    const count = offPlatformServicesRequests?.length || 0
    const visibleItems = offPlatformServicesRequests?.slice(0, DISPLAY_COUNT)

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Latest submitted service requests
                    </Line>
                    <ButtonLink
                        color={colors.neutralDarker}
                        onClick={() => history.push(PATH_OFF_PLATFORM_SERVICES_REQUESTS)}
                    >
                        View all {count > DISPLAY_COUNT ? `(${count})` : ''}
                    </ButtonLink>
                </Flexer>
            </Spacer>
            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                {visibleItems?.map((offPlatformServicesRequest) => {
                    return (
                        <Card
                            key={offPlatformServicesRequest.id}
                            background={colors.redLighter}
                            title={offPlatformServicesRequest.customerFullName || ''}
                            value={formatDate(offPlatformServicesRequest.statusChangedDateUTC) || ''}
                        />
                    )
                })}
            </Grid>
        </Container>
    )
}
