import { colors, font, Line, Spacer, Table } from '@scouts/ui'

import { DiscountSchemeDTO } from '@/models'

import { DiscountSchemesTableRow } from './DiscountSchemesTableRow'

interface DiscountSchemesTableProps {
    discountSchemes: DiscountSchemeDTO[] | undefined
}

export const DiscountSchemesTable = ({ discountSchemes }: DiscountSchemesTableProps) => {
    const totalNumberOfDiscountSchemes = discountSchemes?.length || 0
    const hasDiscountSchemes = totalNumberOfDiscountSchemes > 0

    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="20%">Name</Table.Header.Cell>
                    <Table.Header.Cell width="8%">Discount</Table.Header.Cell>
                    <Table.Header.Cell width="8%">Duration</Table.Header.Cell>
                    <Table.Header.Cell width="10%">Code</Table.Header.Cell>
                    <Table.Header.Cell width="16%">Applies to</Table.Header.Cell>
                    <Table.Header.Cell width="8%">Used count</Table.Header.Cell>
                    <Table.Header.Cell width="10%">Usage limit</Table.Header.Cell>
                    <Table.Header.Cell width="10%">&nbsp;</Table.Header.Cell>
                    <Table.Header.Cell width="10%">&nbsp;</Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>

            <Table.Body>
                {!hasDiscountSchemes && (
                    <Spacer marginTop="12px">
                        <Line color={colors.neutralDarker} size={font.small}>
                            No discount codes found
                        </Line>
                    </Spacer>
                )}

                {hasDiscountSchemes && (
                    <>
                        {discountSchemes?.map((discountScheme) => (
                            <DiscountSchemesTableRow key={discountScheme.id} discountScheme={discountScheme} />
                        ))}
                    </>
                )}
            </Table.Body>

            <Table.Footer>
                <Table.Footer.Row>
                    <Table.Footer.Cell width="100%">{totalNumberOfDiscountSchemes} total</Table.Footer.Cell>
                </Table.Footer.Row>
            </Table.Footer>
        </Table>
    )
}
