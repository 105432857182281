import { apiSlice } from '@/store/api-slice'
import { Tags } from '@/store/tags'

import { useGetTaxConsultationsQuery, GetTaxConsultationsParams } from '../api'

export const useTaxConsultations = ({ options }: GetTaxConsultationsParams) => {
    const { data: fetchedData, isLoading, isError, isFetching } = useGetTaxConsultationsQuery({ options })
    const { data, pagination } = fetchedData || {}

    return {
        taxConsultations: data,
        isError,
        isLoading,
        isFetching,
        pagination,
    }
}

export const invalidateTaxConsultations = () => apiSlice.util.invalidateTags([Tags.TaxConsultations])
