import { createContext, Dispatch, SetStateAction } from 'react'

import { Locale, TimeZone } from '@scouts/helpers'
import { ConfigurationBaseOptions } from '@scouts/inbox'

import config from '@/config'
import {
    PATH_CUSTOMER,
    PATH_CUSTOMER_BOOKKEEPING,
    PATH_CUSTOMER_COMPANY_RETURN,
    PATH_CUSTOMER_SELF_ASSESSMENTS,
    PATH_INBOX,
} from '@/constants'
import { getAccessTokenAsync } from '@/helpers/access-token'

export interface InboxConfigurationContextProps {
    config: ConfigurationBaseOptions
}

export const DEFAULT_CONTEXT: InboxConfigurationContextProps = {
    config: {
        locale: Locale.GB,
        timeZone: TimeZone.GB,
        paths: {
            root: PATH_INBOX,
            customer: {
                root: PATH_CUSTOMER,
                bookkeeping: PATH_CUSTOMER_BOOKKEEPING,
                selfAssessment: PATH_CUSTOMER_SELF_ASSESSMENTS,
                companyReturn: PATH_CUSTOMER_COMPANY_RETURN,
            },
        },
        client: {
            url: config.apiUrl,
            token: getAccessTokenAsync,
        },
    },
}

export const InboxConfigurationContext = createContext<
    [InboxConfigurationContextProps, Dispatch<SetStateAction<InboxConfigurationContextProps>>]
>([DEFAULT_CONTEXT, () => {}])
