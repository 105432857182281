import { Link } from 'react-router-dom'

import { colors, font, radius, ButtonLink, Container, Flexer, Line, Spacer } from '@scouts/ui'

import { PATH_INTEGRATIONS } from '@/constants'
import { AgentAuthorisationDTO, CustomerDTO } from '@/models'

import { useUser } from '@/hooks/use-user'

import { TourAgentAuth } from '../../feature-tour/TourFirstTaxReturn'
import { AgentAuthManualAdd } from './AgentAuthManualAdd'
import { AgentAuthRequest } from './AgentAuthRequest'

const getLastTaxScoutsReference = (customerAuthorisations: AgentAuthorisationDTO[]) => {
    if (customerAuthorisations.length > 0) {
        return customerAuthorisations[0].taxScoutsReference || ''
    }

    return ''
}

interface AgentAuthStartProps {
    customer: CustomerDTO
    customerAuthorisations: AgentAuthorisationDTO[]
}

export const AgentAuthStart = ({ customer, customerAuthorisations }: AgentAuthStartProps) => {
    const { hmrcAgentReference } = useUser()

    const hasMissingFields = !hmrcAgentReference

    return (
        <Container
            radius={radius.medium}
            background={colors.neutralLightest}
            padding="24px"
            className="tour-agent-auth"
        >
            <Flexer column desktopRow>
                <Spacer flexFill marginBottom="18px" desktopMargin="0">
                    <Flexer column gap="9px" justifyStart>
                        <Line weight={font.weight.medium}>Request agent authorisation from HMRC</Line>

                        <Line size={font.small}>TaxScouts will chase the client for authorisation code</Line>
                        <Line size={font.small}>
                            TaxScouts will notify you when client have provided us with the code
                        </Line>
                        <Line size={font.small}>
                            TaxScouts will let you know when the authorisation code has expired
                        </Line>

                        {hasMissingFields && (
                            <Spacer marginTop="12px">
                                <Line color={colors.red} size={font.small}>
                                    HMRC agent reference is needed to start the authorisation process:{' '}
                                    <Link to={PATH_INTEGRATIONS}>
                                        <ButtonLink as="span">Click to add</ButtonLink>
                                    </Link>
                                </Line>
                            </Spacer>
                        )}
                    </Flexer>
                </Spacer>

                <Flexer column gap="12px">
                    {customer.isDemoCustomer && <TourAgentAuth />}

                    {!hasMissingFields && (
                        <AgentAuthRequest
                            customer={customer}
                            label="Request authorisation"
                            taxScoutsReference={getLastTaxScoutsReference(customerAuthorisations)}
                        />
                    )}

                    <AgentAuthManualAdd customer={customer} />
                </Flexer>
            </Flexer>
        </Container>
    )
}
