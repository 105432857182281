import PropTypes from 'prop-types'

import { TAX_CONSULTATION_STATUS_TITLES, TAX_CONSULTATION_STATUSES } from '../../constants'
import { FilterItem, FilterItemDropdownCheckboxes } from '../ui/filter'

const SearchFilterTaxConsultationStatuses = (props) => {
    const { activeFilter, applyFilter, removeFilter, isOpenByDefault } = props

    const hasActiveFilter = activeFilter.length > 0

    const activeValues = hasActiveFilter
        ? activeFilter.map((status) => TAX_CONSULTATION_STATUS_TITLES[status])
        : ['Any']

    const values = TAX_CONSULTATION_STATUSES.map((status) => ({
        checked: activeFilter.findIndex((o) => o === status) > -1,
        label: TAX_CONSULTATION_STATUS_TITLES[status],
        value: status,
    }))

    const name = 'taxConsultationStatuses'

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Consultation status"
            activeValues={activeValues}
            removeFilter={() => removeFilter(name)}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownCheckboxes
                applyFilter={applyFilter}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={values}
            />
        </FilterItem>
    )
}

SearchFilterTaxConsultationStatuses.propTypes = {
    activeFilter: PropTypes.array.isRequired,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

export default SearchFilterTaxConsultationStatuses
