import { FileType, TaxReliefClaimDTO } from '@/models'

import { useGetTaxReliefClaimFilesQuery } from '../api'

// EIS tax relief claims
export const useTaxReliefClaimFiles = (taxReliefClaimId: TaxReliefClaimDTO['id']) => {
    const { data, isError, isLoading } = useGetTaxReliefClaimFilesQuery({ taxReliefClaimId })

    const taxReliefClaimCustomerFiles = data?.filter((item) => item.fileType === FileType.TaxReliefClaimCertificate)
    const hasTaxReliefClaimCustomerFiles = taxReliefClaimCustomerFiles && taxReliefClaimCustomerFiles?.length > 0

    const taxReliefClaimAccountantFiles = data?.filter(
        (item) =>
            item.fileType === FileType.TaxReliefClaimCompletedForm ||
            item.fileType === FileType.TaxReliefClaimProofOfPostage
    )

    const hasTaxReliefClaimAccountantFiles = taxReliefClaimAccountantFiles && taxReliefClaimAccountantFiles?.length > 0

    return {
        hasTaxReliefClaimAccountantFiles,
        hasTaxReliefClaimCustomerFiles,
        taxReliefClaimCustomerFiles,
        taxReliefClaimAccountantFiles,
        taxReliefClaimFiles: data,
        isError,
        isLoading,
    }
}
