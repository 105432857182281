import { generatePath } from 'react-router-dom'

import { PATH_CUSTOMER_TAX_CONSULTATION } from '@/constants'
import { TaxConsultationSummaryDTO } from '@/models'

import { CardTime } from './CardTime'

export const DashboardConsultationsItem = ({ consultation }: { consultation: TaxConsultationSummaryDTO }) => {
    const { customerFullName, customerId, id: taxConsultationId, scheduledDateUtc } = consultation

    const linkDestination = generatePath(PATH_CUSTOMER_TAX_CONSULTATION, { customerId, taxConsultationId })

    return <CardTime date={scheduledDateUtc} name={customerFullName} linkDestination={linkDestination} />
}
