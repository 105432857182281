import _bindAll from 'lodash/bindAll'
import _isNumber from 'lodash/isNumber'
import _remove from 'lodash/remove'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { colors, DropdownSelect } from '@scouts/ui'

import { receiveErrorAlerts, requestCustomerIssues, saveSelfAssessmentStatus } from '@/actions'
import {
    SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
    SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
    SELF_ASSESSMENT_STATUS_CANCELLED,
    SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
    SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
    SELF_ASSESSMENT_STATUS_FILED,
    SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
    SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
    SELF_ASSESSMENT_STATUS_READY_TO_FILE,
    SELF_ASSESSMENT_STATUS_STARTED,
    SELF_ASSESSMENT_STATUS_SUBMITTED,
    SELF_ASSESSMENT_STATUS_TITLES,
    SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS,
} from '@/constants'
import { isAdmin } from '@/helpers'
import { FileType, FileTypeTitles } from '@/models'
import { getAccountantById } from '@/selectors'
import { getSelfAssessmentFilesBySelfAssessmentId } from '@/selectors/self-assessment-files'

import { TourTaxReturnStatus } from '@/components/feature-tour/TourFirstTaxReturn'

import SelfAssessmentStatusCompletedNote from './SelfAssessmentStatusCompletedNote'
import SelfAssessmentStatusMessage from './SelfAssessmentStatusMessage'

const ERROR_MESSAGE_TAX_DUE_MISMATCH =
    'The tax due (balancing payment) should equal the tax liability minus the total previous payments on account that have been paid'
const ERROR_MESSAGE_MISSING_FINAL_NUMBERS = `is required for ${SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL]}`
const ERROR_MESSAGE_FINAL_RETURN_DOCUMENT_REQUIRED = `Cannot set status to ${
    SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL]
} without ${FileTypeTitles[FileType.FinalReturn]} document`

class SelfAssessmentStatus extends Component {
    constructor(props) {
        super(props)

        const { isAdminUser, shouldHideAwaitingRefundStatus, selfAssessment } = props
        const { status } = selfAssessment

        const shouldHideCompletedStatus = status !== SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED && !isAdminUser
        const shouldHideCancelledStatus = status !== SELF_ASSESSMENT_STATUS_CANCELLED && !isAdminUser

        this.statuses = [
            {
                value: SELF_ASSESSMENT_STATUS_STARTED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_STARTED],
                disabled: !isAdminUser,
            },
            {
                value: SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_UPLOADING_DOCUMENTS],
                disabled: !isAdminUser,
            },
            {
                value: SELF_ASSESSMENT_STATUS_SUBMITTED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_SUBMITTED],
                color: colors.blueLighter,
                disabled: !isAdminUser,
            },
            {
                value: SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_ACCOUNTANT_REVIEW],
                color: colors.blueLighter,
            },
            {
                value: SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_INFORMATION_REQUIRED],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_CUSTOMER_CORRECTIONS],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_CANCELLED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_CANCELLED],
                color: colors.red,
                disabled: !isAdminUser,
            },
            {
                value: SELF_ASSESSMENT_STATUS_READY_TO_FILE,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_READY_TO_FILE],
                color: colors.blueLighter,
            },
            {
                value: SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND],
                color: colors.yellow,
            },
            {
                value: SELF_ASSESSMENT_STATUS_FILED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_FILED],
                color: colors.mintLighter,
            },
            {
                value: SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED,
                title: SELF_ASSESSMENT_STATUS_TITLES[SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED],
                color: colors.mintLighter,
            },
        ]

        if (shouldHideCompletedStatus) {
            _remove(this.statuses, ({ value }) => value === SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED)
        }

        if (shouldHideAwaitingRefundStatus) {
            _remove(this.statuses, ({ value }) => value === SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND)
        }

        if (shouldHideCancelledStatus) {
            _remove(this.statuses, ({ value }) => value === SELF_ASSESSMENT_STATUS_CANCELLED)
        }

        this.state = {
            isCompletedModalOpen: false,
            isMessageModalOpen: false,
            newStatus: null,
        }

        _bindAll(this, [
            'closeCompletedModal',
            'closeMessageModal',
            'handleStatusChange',
            'openCompletedModal',
            'openMessageModal',
            'validateStatusChange',
        ])
    }

    componentDidMount() {
        const { selfAssessment, dispatch } = this.props

        const { customerId } = selfAssessment

        dispatch(requestCustomerIssues(customerId))
    }

    handleStatusChange({ value: status }) {
        const { selfAssessment, dispatch, isAdminUser, onChange } = this.props

        const { id: selfAssessmentId } = selfAssessment

        if (!this.validateStatusChange(status)) {
            return
        }

        if (status === SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL) {
            this.openMessageModal()
            this.setState({ newStatus: status })
        } else if (status === SELF_ASSESSMENT_STATUS_COMPLETED_NOT_FILED && isAdminUser) {
            this.openCompletedModal()
        } else {
            dispatch(saveSelfAssessmentStatus({ selfAssessmentId, status }))
        }

        if (onChange) {
            onChange(status)
        }
    }

    validateStatusChange(status) {
        const { dispatch, hasUploadedFinalReturnDocument, selfAssessment } = this.props
        const { taxLiability, lastYearPaymentsOnAccountAmount, taxDue, totalTaxableIncome } = selfAssessment

        switch (status) {
            case SELF_ASSESSMENT_STATUS_AWAITING_CUSTOMER_APPROVAL: {
                const errorMessages = []
                const missingFields = []

                if (!_isNumber(totalTaxableIncome)) missingFields.push('Taxable income')
                if (!_isNumber(taxDue)) missingFields.push('Tax due')

                if (missingFields.length > 0)
                    errorMessages.push(`${missingFields.join(', ')} ${ERROR_MESSAGE_MISSING_FINAL_NUMBERS}`)

                if (_isNumber(taxLiability) && _isNumber(lastYearPaymentsOnAccountAmount)) {
                    if (Number((taxLiability - lastYearPaymentsOnAccountAmount).toFixed(2)) !== taxDue) {
                        errorMessages.push(ERROR_MESSAGE_TAX_DUE_MISMATCH)
                    }
                }

                if (!hasUploadedFinalReturnDocument) errorMessages.push(ERROR_MESSAGE_FINAL_RETURN_DOCUMENT_REQUIRED)

                if (errorMessages.length > 0) {
                    dispatch(receiveErrorAlerts(errorMessages))
                    return false
                }

                return true
            }
            default:
                return true
        }
    }

    openMessageModal() {
        this.setState({ isMessageModalOpen: true })
    }

    closeMessageModal() {
        this.setState({ isMessageModalOpen: false })
    }

    openCompletedModal() {
        this.setState({ isCompletedModalOpen: true })
    }

    closeCompletedModal() {
        this.setState({ isCompletedModalOpen: false })
    }

    render() {
        const { customer, selfAssessment } = this.props
        const { isMessageModalOpen, isCompletedModalOpen, newStatus } = this.state
        const { status } = selfAssessment
        const { fullName } = customer

        return (
            <>
                {customer.isDemoCustomer && <TourTaxReturnStatus />}
                <span className="tour-status">
                    <DropdownSelect
                        name="status"
                        value={status}
                        options={this.statuses}
                        onChange={this.handleStatusChange}
                        width="100%"
                    />
                </span>

                {isMessageModalOpen && (
                    <SelfAssessmentStatusMessage
                        selfAssessment={selfAssessment}
                        status={newStatus}
                        closeModal={this.closeMessageModal}
                    />
                )}

                {isCompletedModalOpen && (
                    <SelfAssessmentStatusCompletedNote
                        selfAssessment={selfAssessment}
                        customerName={fullName}
                        closeModal={this.closeCompletedModal}
                    />
                )}
            </>
        )
    }
}

SelfAssessmentStatus.propTypes = {
    customer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    hasUploadedFinalReturnDocument: PropTypes.bool.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    selfAssessment: PropTypes.object.isRequired,
    shouldHideAwaitingRefundStatus: PropTypes.bool.isRequired,
}

SelfAssessmentStatus.defaultProps = {
    onChange: undefined,
}

const mapStateToProps = (state, ownProps) => {
    const { user } = state
    const { customer, selfAssessment } = ownProps

    const { id: selfAssessmentId, status } = selfAssessment

    const selfAssessmentFiles = getSelfAssessmentFilesBySelfAssessmentId(state, { selfAssessmentId })
    const hasUploadedFinalReturnDocument = selfAssessmentFiles.some(({ fileType }) => fileType === FileType.FinalReturn)

    const isAdminUser = isAdmin(user)

    const accountantId = customer.accountantId
    const isAccountantPayLater = isAdminUser
        ? accountantId && getAccountantById(state, { accountantId })?.allowPayLater
        : user?.allowPayLater

    const shouldHideAwaitingRefundStatus =
        isAccountantPayLater !== true && status !== SELF_ASSESSMENT_STATUS_FILED_AWAITING_REFUND

    return {
        isAdminUser,
        hasUploadedFinalReturnDocument,
        shouldHideAwaitingRefundStatus,
    }
}

export default connect(mapStateToProps)(SelfAssessmentStatus)
