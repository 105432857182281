import { MESSAGE_TYPE_EMAIL } from '@/constants'
import { DraftMessage } from '@/models/draft-message'

import { useUser } from '@/hooks/use-user'

import { getMsgId, useGetDraftMessageQuery, useRemoveDraftMessageMutation, useUpdateDraftMessageMutation } from '../api'

const inMemoryDraftMessageFiles = new Map<string, File[]>()

export const useDraftMessage = ({ customerId }: { customerId: number }) => {
    const { id: userId } = useUser()
    const msgId = getMsgId({ customerId, userId })
    const { data } = useGetDraftMessageQuery({ customerId, userId })
    const [updateDraftMessageAction] = useUpdateDraftMessageMutation()
    const [removeDraftMessageAction] = useRemoveDraftMessageMutation()

    const dataWithFiles: DraftMessage = data
        ? {
              ...data,
              files: inMemoryDraftMessageFiles.get(msgId) ?? [],
          }
        : { content: '', files: [], messageType: MESSAGE_TYPE_EMAIL }

    const updateDraftMessage = (msg: DraftMessage) => {
        const { files, ...restMsg } = msg
        inMemoryDraftMessageFiles.set(msgId, files)
        updateDraftMessageAction({ ...restMsg, customerId, userId })
    }

    const removeDraftMessage = () => {
        inMemoryDraftMessageFiles.delete(msgId)
        removeDraftMessageAction({ customerId, userId })
    }

    const getNotDuplicateFiles = (newFiles: File[]) => {
        return newFiles.filter((newFile) => {
            const isDuplicate = dataWithFiles?.files.some(
                (file: File) => file.name === newFile.name && file.size === newFile.size
            )

            return !isDuplicate
        })
    }

    const hasDraftMessage = !!dataWithFiles?.content || (dataWithFiles?.files.length ?? 0) > 0

    return {
        draftMessage: dataWithFiles,
        hasDraftMessage,
        updateDraftMessage,
        removeDraftMessage,
        getNotDuplicateFiles,
        hasFiles: (dataWithFiles?.files.length ?? 0) > 0,
    }
}
