import { useLazyGetMessageAttachmentLinkQuery } from '../api'

export const useDownloadMessageAttachment = () => {
    const [getMessageAttachmentLink, { isLoading }] = useLazyGetMessageAttachmentLinkQuery()

    const downloadMessageAttachment = ({ fileId }: { fileId: number }) => {
        return getMessageAttachmentLink({ fileId }).then((response) => {
            if (response.data) window.location.assign(response.data)
        })
    }

    return { downloadMessageAttachment, isLoading }
}
