import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Locale } from '@scouts/helpers'
import {
    colors,
    font,
    radius,
    ButtonLink,
    Container,
    Flexer,
    Grid,
    Line,
    Spacer,
    TypeSelect,
    TypeSelectProps,
} from '@scouts/ui'

import { requestRevenueStats, requestSelfAssessmentsStatistics, requestTaxConsultationsStatistics } from '@/actions'
import { PATH_ACCOUNTANTS, PATH_USERS } from '@/constants'
import { getAccountantsWithFullNameAndStatus, getFullStatistics } from '@/selectors'
import { useAppDispatch } from '@/store'

import { EarningsStatistics } from './stats/EarningsStatistics'
import { SelfAssessmentStatistics } from './stats/SelfAssessmentStatistics'
import { TaxConsultationStatistics } from './stats/TaxConsultationStatistics'

export const SupportAccountantStatistics = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const [accountantId, setAccountantId] = useState<number | null>(null)

    const accountantsWithFullName = useSelector(getAccountantsWithFullNameAndStatus)
    const statistics = useSelector(getFullStatistics)

    const handleAccountantChange: TypeSelectProps['onChange'] = ({ value }) => {
        setAccountantId(Number(value))
    }

    useEffect(() => {
        if (accountantId) dispatch(requestRevenueStats({ accountantId }))
        dispatch(requestTaxConsultationsStatistics({ accountantId }))
        dispatch(requestSelfAssessmentsStatistics({ accountantId }))
    }, [accountantId, dispatch])

    return (
        <Container>
            <Spacer marginBottom="12px">
                <Flexer justifyStart gap="18px">
                    <Line size={font.small} weight={font.weight.medium}>
                        Accountant statistics
                    </Line>
                    <ButtonLink color={colors.neutralDarker} onClick={() => history.push(PATH_ACCOUNTANTS)}>
                        View all accountants
                    </ButtonLink>
                </Flexer>
            </Spacer>

            <Spacer marginBottom="24px">
                <TypeSelect
                    name="accountantId"
                    value={Number(accountantId) || ''}
                    options={[{ value: '', title: 'All accountants' }, ...accountantsWithFullName]}
                    onChange={handleAccountantChange}
                    autoClear
                    isRequired
                    showOptions
                    locale={Locale.GB}
                />
            </Spacer>

            <Grid desktopLargeColumns={4} tabletColumns={2} columns={1} gap="12px">
                <Spacer>
                    <Flexer column gap="6px">
                        <Spacer marginBottom="12px">
                            <Line size={font.small} weight={font.weight.medium}>
                                Self assessments
                            </Line>
                        </Spacer>

                        <SelfAssessmentStatistics accountantId={accountantId} statistics={statistics} />
                    </Flexer>
                </Spacer>

                <Spacer>
                    <Flexer column gap="6px">
                        <Spacer marginBottom="12px">
                            <Line size={font.small} weight={font.weight.medium}>
                                Tax consultations
                            </Line>
                        </Spacer>
                        <TaxConsultationStatistics accountantId={accountantId} statistics={statistics} />
                    </Flexer>
                </Spacer>
                <Spacer>
                    <Flexer column gap="6px">
                        <Spacer marginBottom="12px">
                            <Line size={font.small} weight={font.weight.medium}>
                                Earnings
                            </Line>
                        </Spacer>
                        <EarningsStatistics accountantId={accountantId} statistics={statistics} />
                    </Flexer>
                </Spacer>
                {accountantId && (
                    <Spacer>
                        <Flexer column gap="6px">
                            <Spacer marginBottom="12px">
                                <Line size={font.small} weight={font.weight.medium}>
                                    Quick links
                                </Line>
                            </Spacer>

                            <Container background={colors.neutralLightest} padding="18px 24px" radius={radius.medium}>
                                <Flexer column gap="12px" alignStart>
                                    <ButtonLink onClick={() => history.push(`${PATH_ACCOUNTANTS}/${accountantId}`)}>
                                        View details
                                    </ButtonLink>

                                    <ButtonLink onClick={() => history.push(`${PATH_USERS}/${accountantId}`)}>
                                        Edit profile
                                    </ButtonLink>
                                </Flexer>
                            </Container>
                        </Flexer>
                    </Spacer>
                )}
            </Grid>
        </Container>
    )
}
