import { colors, Container, Flexer, Line, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { useAccountantMeetings } from '@/store/accountant-meetings/hooks/use-accountant-meetings'

import { useUser } from '@/hooks/use-user'

import { usePagination } from '../pagination'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { AccountantMeetingsTable } from './AccountantMeetingsTable'

export const AccountantMeetings = () => {
    const { id, isAdminUser } = useUser()
    const { activeFilters } = usePagination()

    const accountantId = !isAdminUser ? id : null
    const { accountantMeetings, pagination, isLoading, isError } = useAccountantMeetings({
        accountantId,
        page: activeFilters.page,
        pageSize: activeFilters.pageSize,
    })

    const itemsCount = accountantMeetings?.length || 0
    const hasAccountantMeetings = itemsCount > 0

    if (isError)
        return (
            <Flexer alignCenter justifyCenter>
                <Line color={colors.neutralDarker}>Error getting meetings</Line>
            </Flexer>
        )

    if (isLoading && !hasAccountantMeetings)
        return (
            <Skeleton gap="1px" padding="24px">
                <SkeletonLine height="38px" />
                <SkeletonLine height="84px" repeat={10} />
            </Skeleton>
        )

    return (
        <Container padding="24px 24px 192px">
            {/* @TODO: Filters */}

            {!isLoading && !hasAccountantMeetings && (
                <Flexer alignCenter justifyCenter>
                    <Line color={colors.neutralDarker}>No meetings found</Line>
                </Flexer>
            )}

            {hasAccountantMeetings && (
                <>
                    <AccountantMeetingsTable accountantMeetings={accountantMeetings} />
                    <Spacer marginTop="24px">
                        <PaginationContainer pagination={pagination} shouldDisplayCount itemsCount={itemsCount} />
                    </Spacer>
                </>
            )}
        </Container>
    )
}
