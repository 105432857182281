import React, { useEffect, useRef, ReactNode } from 'react'
import styled from 'styled-components'

import { colors, font, media } from '@scouts/ui'

import { useMessageComposer } from '../hooks/use-message-composer'

import { MessagesContextProvider } from '../context/MessagesContextProvider'
import { MessageComposerProvider } from './context/MessageComposerProvider'
import { MessageComposer } from './MessageComposer'

const useFocusTextAreaOnOpen = (isOpen: boolean, messageComposerRef: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
        if (isOpen) messageComposerRef?.current?.querySelector('textarea')?.focus()
    }, [isOpen, messageComposerRef])
}

interface MessageComposerContainerChildrenProps {
    openComposer: () => void
}

interface MessageComposerContainerProps {
    customerId: number
    onSuccessfulSend?: () => void
    children: (props: MessageComposerContainerChildrenProps) => ReactNode
}

export const MessageComposerContainer = ({ customerId, children, onSuccessfulSend }: MessageComposerContainerProps) => {
    const messageComposerRef = useRef(null)

    const { isComposerOpen, openComposer, closeComposer } = useMessageComposer({ customerId })

    const props = { openComposer }

    useFocusTextAreaOnOpen(isComposerOpen, messageComposerRef)

    return (
        <MessageComposerProvider value={{ isComposerOpen, openComposer, closeComposer }}>
            <MessagesContextProvider customerId={customerId}>
                <Wrapper ref={messageComposerRef} isVisible={isComposerOpen}>
                    <Container>
                        <CloseButton onClick={closeComposer}>Hide</CloseButton>
                        <MessageComposer onSuccessfulSend={onSuccessfulSend} />
                    </Container>
                </Wrapper>

                {children(props)}
            </MessagesContextProvider>
        </MessageComposerProvider>
    )
}

interface WrapperProps {
    isVisible: boolean
}

const Wrapper = styled.div<WrapperProps>`
    position: fixed;
    left: 18px;
    right: 18px;
    bottom: 18px;
    z-index: 9000; // @TODO: Our Tooltip component has z-index: 10000 (coming from react-hint library) – easy to miss, need a better way of maintaining the order
    transition: all 0.15s ease-out;

    ${({ isVisible }) =>
        !isVisible &&
        `
        transition: all 0.15s ease-in;
        bottom: -100%;
    `}

    ${media.tablet} {
        right: 48px;
        left: calc(60px + 360px + 48px);
        bottom: 36px;

        ${({ isVisible }: WrapperProps) =>
            !isVisible &&
            `
            bottom: -100%;
        `}
    }

    ${media.desktopLarge} {
        ${({ isVisible }: WrapperProps) =>
            !isVisible &&
            `
            bottom: -100%;
        `}
    }
`

const Container = styled.div`
    position: relative;

    ${media.tablet} {
        max-width: 960px;
        margin: 0 auto;
    }
`

const CloseButton = styled.button`
    color: ${colors.neutralDarker};
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: center;
    position: absolute;
    right: 0;
    top: -42px;
    z-index: 1000;
    padding: 9px 12px;
    background: ${colors.neutralLightest};
    border-radius: 9999px;

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }
`
