import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, radius, ConfirmationModal, Container, Line, Spacer } from '@scouts/ui'

import { formatDate } from '@/helpers'
import { AccountantReviewDTO } from '@/models'
import { useDeleteAccountantReview } from '@/store/accountant-reviews/hooks'

import { EditReviewModal } from './EditReviewModal'

const getStars = (rating: AccountantReviewDTO['rating']) => {
    if (rating === 2) return '⭑⭑'
    if (rating === 3) return '⭑⭑⭑'
    if (rating === 4) return '⭑⭑⭑⭑'
    if (rating === 5) return '⭑⭑⭑⭑⭑'

    return '⭑'
}

export const UserReviewsItem = ({ review }: { review: AccountantReviewDTO }) => {
    const [isModalOpen, openModal, closeModal] = useToggle()
    const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useToggle()

    const { deleteAccountantReview, isLoading } = useDeleteAccountantReview()

    const handleDelete = () => {
        deleteAccountantReview({ reviewId: review.id })
    }

    return (
        <>
            <Container border={`1px solid ${colors.neutralLight}`} radius={radius.large} padding="18px 24px" relative>
                <TextContainer onClick={openModal}>
                    {review.rating && (
                        <Spacer marginBottom="9px">
                            <Line color={colors.mintDarker}>{getStars(review.rating)}</Line>
                        </Spacer>
                    )}
                    <Spacer marginBottom="6px">
                        <Line size={font.small}>{review.review}</Line>
                    </Spacer>
                    <Line size={font.small} color={colors.neutralDarker}>
                        {review.reviewer && (
                            <Author>
                                by <strong>{review.reviewer}</strong>
                                &nbsp;
                            </Author>
                        )}
                        {review.reviewDate && <Date>on {formatDate(review.reviewDate)}</Date>}
                    </Line>
                </TextContainer>
                <Actions>
                    <Action title="Delete" onClick={openConfirmationModal}>
                        ×
                    </Action>
                </Actions>
            </Container>

            {isModalOpen && <EditReviewModal accountantReview={review} onClose={closeModal} />}

            {isConfirmationModalOpen && (
                <ConfirmationModal
                    onConfirm={handleDelete}
                    onClose={closeConfirmationModal}
                    title="Are you sure you want to delete this review?"
                    labelConfirm="Delete"
                    isLoading={isLoading}
                />
            )}
        </>
    )
}

const TextContainer = styled.div`
    cursor: pointer;
    text-decoration: none;
    width: 80%;
`

const Author = styled.div`
    display: inline-block;
`

const Date = styled.div`
    display: inline-block;
`

const Actions = styled.div`
    position: absolute;
    top: 17px;
    right: 9px;
`

const Action = styled.button`
    color: ${colors.neutralDarker};
    font-size: ${font.normal};
    font-weight: ${font.weight.medium};
    text-align: center;
    display: flex;
    align-items: center;
    padding: 2px 7px 3px;
    border-radius: 2px;

    &:hover {
        color: ${colors.blue} !important;
    }
`
