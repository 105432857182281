import { Table } from '@scouts/ui'

import { CustomerDiscountDTO } from '@/models'

import { CustomerDiscountsTableRow } from './CustomerDiscountsTableRow'

interface CustomerDiscountsTableProps {
    customerDiscounts: CustomerDiscountDTO[]
    handleDeleteDiscount: (id: CustomerDiscountDTO['id']) => void
}

export const CustomerDiscountsTable = ({ customerDiscounts, handleDeleteDiscount }: CustomerDiscountsTableProps) => {
    return (
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="25%">Scheme</Table.Header.Cell>
                    <Table.Header.Cell width="25%">Code</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Percentage</Table.Header.Cell>
                    <Table.Header.Cell width="15%">Applied to</Table.Header.Cell>
                    <Table.Header.Cell width="20%">Referred by</Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>

            <Table.Body>
                {customerDiscounts.map((discount) => (
                    <CustomerDiscountsTableRow
                        discount={discount}
                        key={discount.id}
                        handleDeleteDiscount={handleDeleteDiscount}
                    />
                ))}
            </Table.Body>
        </Table>
    )
}
