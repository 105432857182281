import { createSelector } from '@reduxjs/toolkit'

/**
 * @param {import('@/store').RootState} state
 * @param {{customerId: number }} props
 */
const pluckCustomerId = (_state, { customerId }) => customerId
/**
 * @param {import('@/store').RootState} state
 * @param {{taxConsultationId: number }} props
 */
const pluckTaxConsultationId = (_state, { taxConsultationId }) => taxConsultationId
const pluckProductBundleId = (_state, { productBundleId }) => productBundleId

const getCustomerDiscountsItems = (state) => state.customerDiscounts.items

export const getDiscountsByCustomerId = createSelector(
    [getCustomerDiscountsItems, pluckCustomerId],
    (customerDiscounts, customerId) => customerDiscounts.filter((discount) => discount.customerId === customerId)
)

export const getDiscountByTaxConsultationId = createSelector(
    [getCustomerDiscountsItems, pluckCustomerId, pluckTaxConsultationId],
    (customerDiscounts, customerId, taxConsultationId) =>
        customerDiscounts.find((o) => o.customerId === customerId && o.taxConsultationId === taxConsultationId)
)

export const getDiscountByProductBundleId = createSelector(
    [getCustomerDiscountsItems, pluckCustomerId, pluckProductBundleId],
    (customerDiscounts, customerId, productBundleId) =>
        customerDiscounts.find((o) => o.customerId === customerId && o.productBundleId === productBundleId)
)
