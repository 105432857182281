import { matchSuccessResponse } from '@scouts/helpers'
import { colors, DropdownSelect, DropdownSelectProps } from '@scouts/ui'

import { requestCustomer } from '@/actions'
import {
    TaxConsultationDTO,
    TaxConsultationStatus,
    TaxConsultationStatusTitles,
    TaxConsultationStatusTypes,
} from '@/models'
import { useAppDispatch } from '@/store'
import { useUpdateTaxConsultationStatus } from '@/store/tax-consultations'

import { useUser } from '@/hooks/use-user'

interface TaxConsultationStatusDropdown {
    taxConsultation: TaxConsultationDTO
}

interface Status {
    value: TaxConsultationStatusTypes
    title: string
    color: string
    disabled?: boolean
}

export const TaxConsultationStatusDropdown = ({ taxConsultation }: TaxConsultationStatusDropdown) => {
    const dispatch = useAppDispatch()
    const { isAdminUser } = useUser()
    const { customerId, id: taxConsultationId, status, hasPaid } = taxConsultation

    const { updateTaxConsultationStatus } = useUpdateTaxConsultationStatus()

    const statuses: Status[] = [
        {
            value: TaxConsultationStatus.Started,
            title: TaxConsultationStatusTitles[TaxConsultationStatus.Started],
            color: colors.neutralLightest,
        },
        {
            value: TaxConsultationStatus.Booked,
            title: TaxConsultationStatusTitles[TaxConsultationStatus.Booked],
            color: colors.blueLighter,
            disabled: !hasPaid && !isAdminUser,
        },
        {
            value: TaxConsultationStatus.AwaitingSummary,
            title: TaxConsultationStatusTitles[TaxConsultationStatus.AwaitingSummary],
            color: colors.blueLighter,
            disabled: !hasPaid && !isAdminUser,
        },
        {
            value: TaxConsultationStatus.AwaitingApproval,
            title: TaxConsultationStatusTitles[TaxConsultationStatus.AwaitingApproval],
            color: colors.yellow,
            disabled: !hasPaid && !isAdminUser,
        },
        {
            value: TaxConsultationStatus.Completed,
            title: TaxConsultationStatusTitles[TaxConsultationStatus.Completed],
            color: colors.mintLighter,
            disabled: !hasPaid && !isAdminUser,
        },
        {
            value: TaxConsultationStatus.Cancelled,
            title: TaxConsultationStatusTitles[TaxConsultationStatus.Cancelled],
            color: colors.red,
            disabled: !hasPaid && !isAdminUser,
        },
    ]

    const handleChange: DropdownSelectProps['onChange'] = ({ value }) => {
        updateTaxConsultationStatus({ taxConsultationId, status: value as TaxConsultationStatusTypes }).then(
            matchSuccessResponse(() => {
                dispatch(requestCustomer(customerId))
            })
        )
    }

    return <DropdownSelect name="status" value={status} options={statuses} onChange={handleChange} width="100%" />
}
