import { matchPath, useLocation, useRouteMatch } from 'react-router-dom'

import { PATH_CUSTOMER_COMPANY_PROFILE, PATH_CUSTOMER_COMPANY_RETURN } from '@/constants'

const routesPotentiallyWithCompanyId = [PATH_CUSTOMER_COMPANY_PROFILE, PATH_CUSTOMER_COMPANY_RETURN]

export const useCompanyIdFromPath = () => {
    const { pathname } = useLocation()

    const currentPathMatch = routesPotentiallyWithCompanyId.find((potentialPath) =>
        matchPath(pathname, { path: potentialPath })
    )

    const potentialParams = useRouteMatch<{ companyId?: string }>(currentPathMatch || '')?.params

    return Number(potentialParams?.companyId)
}
