import { colors } from '@scouts/ui'

import { UserReferralPaymentStatus, UserReferralPaymentStatusTitles } from '@/models'

const statuses = {
    [UserReferralPaymentStatus.Created]: {
        color: colors.neutralLightest,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.Created],
    },
    [UserReferralPaymentStatus.Submitted]: {
        color: colors.neutralLightest,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.Submitted],
    },
    [UserReferralPaymentStatus.Processing]: {
        color: colors.neutralLightest,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.Processing],
    },
    [UserReferralPaymentStatus.Completed]: {
        color: colors.mintLighter,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.Completed],
    },
    [UserReferralPaymentStatus.Cancelled]: {
        color: colors.red,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.Cancelled],
    },
    [UserReferralPaymentStatus.Refunded]: {
        color: colors.yellow,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.Refunded],
    },
    [UserReferralPaymentStatus.ChargedBack]: {
        color: colors.yellow,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.ChargedBack],
    },
    [UserReferralPaymentStatus.Unknown]: {
        color: colors.red,
        title: UserReferralPaymentStatusTitles[UserReferralPaymentStatus.Unknown],
    },
}

export const RewardDomain = {
    statuses,
}
