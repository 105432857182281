import { useState } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { styled } from 'styled-components'

import { formatTimeFromNow } from '@scouts/helpers'
import {
    colors,
    font,
    AccountantPortalChrome,
    ConfirmationModal,
    DropdownAction,
    DropdownButton,
    DropdownSelect,
    DropdownSelectProps,
    Flexer,
    Grid,
    GridItem,
} from '@scouts/ui'

import {
    PATH_CUSTOMER,
    PATH_CUSTOMER_TAX_RELIEF_CLAIMS,
    PAYMENT_STATUS_REFUNDED,
    PAYMENT_STATUS_SUCCEEDED,
} from '@/constants'
import { CustomerDTO, RefundCreateDTO, TaxReliefClaimDTO } from '@/models'
import { getCustomerById, getUser } from '@/selectors'
import {
    useCustomersTaxReliefClaims,
    useDeleteTaxReliefClaim,
    useRefundTaxReliefClaim,
} from '@/store/tax-relief-claims'

import { RefundModal } from '../_shared/RefundModal'
import { TaxReliefClaimAccountantUploads } from './TaxReliefClaimAccountantUploads'
import { TaxReliefClaimCustomerFiles } from './TaxReliefClaimCustomerFiles'
import { TaxReliefClaimPaymentStatus } from './TaxReliefClaimPaymentStatus'
import { TaxReliefClaimStatusSelect } from './TaxReliefClaimStatusSelect'
import { TaxReliefClaimSupportUserSelect } from './TaxReliefClaimSupportUserSelect'

interface TaxReliefClaimProps {
    customerId: CustomerDTO['id']
    taxReliefClaim: TaxReliefClaimDTO
}

export const TaxReliefClaim = ({ taxReliefClaim, customerId }: TaxReliefClaimProps) => {
    const history = useHistory()

    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const {
        statusChangedDateUTC,
        id: taxReliefClaimId,
        refundThroughTaxCode,
        paymentDateUTC,
        paymentStatus,
        price,
        refundDateUTC,
        refundReason,
    } = taxReliefClaim
    const { taxReliefClaims } = useCustomersTaxReliefClaims({ customerId })
    const { refundTaxReliefClaim, isLoading: isLoadingRefundMutation } = useRefundTaxReliefClaim()
    const { deleteTaxReliefClaim, isLoading: isLoadingDelete } = useDeleteTaxReliefClaim()

    const customer = useSelector((state) => getCustomerById(state, { customerId }))
    const { isAdminUser } = useSelector(getUser)

    const handleChangeTaxReliefClaim: DropdownSelectProps['onChange'] = ({ value: updatedTaxReliefClaimId }) => {
        history.push(
            generatePath(PATH_CUSTOMER_TAX_RELIEF_CLAIMS, { customerId, taxReliefClaimId: updatedTaxReliefClaimId })
        )
    }

    const handleConfirmDelete = () => {
        deleteTaxReliefClaim({ taxReliefClaimId }).then(() => {
            history.replace(generatePath(PATH_CUSTOMER, { customerId }))
        })
    }

    const handleRefundTaxReliefClaim = (payload: RefundCreateDTO) => {
        refundTaxReliefClaim({
            taxReliefClaimId,
            ...payload,
        }).then(() => setIsRefundModalOpen(false))
    }

    const dropdownActions: DropdownAction[] = []

    if (isAdminUser) {
        dropdownActions.push({
            title: 'Refund',
            onClick: () => setIsRefundModalOpen(true),
            disabled: !!taxReliefClaim.refundDateUTC,
        })

        dropdownActions.push({
            title: 'Delete',
            onClick: () => setIsDeleteModalOpen(true),
            color: colors.text.alert,
            disabled: [PAYMENT_STATUS_REFUNDED, PAYMENT_STATUS_SUCCEEDED].includes(String(paymentStatus)),
        })
    }

    return (
        <AccountantPortalChrome.Customer.Page.Container>
            <AccountantPortalChrome.Customer.Page.Header isSticky={false}>
                <AccountantPortalChrome.Customer.Page.HeaderGroup>
                    <AccountantPortalChrome.Customer.Page.Title>
                        Tax relief claim
                    </AccountantPortalChrome.Customer.Page.Title>

                    <DropdownSelect
                        name="taxReliefClaimId"
                        value={taxReliefClaimId}
                        options={(taxReliefClaims || []).map(({ id: optionTaxReliefClaimId }) => ({
                            value: optionTaxReliefClaimId,
                            title: String(optionTaxReliefClaimId),
                        }))}
                        onChange={handleChangeTaxReliefClaim}
                    />

                    <LastUpdated>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Status changed {formatTimeFromNow(statusChangedDateUTC)}
                        </span>
                    </LastUpdated>
                </AccountantPortalChrome.Customer.Page.HeaderGroup>
                <AccountantPortalChrome.Customer.Page.HeaderGroup>
                    {dropdownActions.length && <DropdownButton alignRight actions={dropdownActions} />}
                </AccountantPortalChrome.Customer.Page.HeaderGroup>
            </AccountantPortalChrome.Customer.Page.Header>

            <Flexer column gap="30px">
                <Grid gap="48px" desktopColumns={2} columns={1}>
                    <GridItem>
                        <SummaryRow>
                            <SummaryRowLabel>Status</SummaryRowLabel>
                            <SummaryRowField>
                                <TaxReliefClaimStatusSelect taxReliefClaim={taxReliefClaim} />
                            </SummaryRowField>
                        </SummaryRow>
                        <SummaryRow>
                            <SummaryRowLabel>Assigned to</SummaryRowLabel>
                            <SummaryRowField>
                                <TaxReliefClaimSupportUserSelect taxReliefClaim={taxReliefClaim} />
                            </SummaryRowField>
                        </SummaryRow>
                        <SummaryRow>
                            <SummaryRowLabel>Refund through tax code</SummaryRowLabel>
                            <SummaryRowField>{refundThroughTaxCode ? 'Yes' : 'No'}</SummaryRowField>
                        </SummaryRow>
                        <SummaryRow>
                            <SummaryRowLabel>Paid</SummaryRowLabel>
                            <SummaryRowField>
                                <TaxReliefClaimPaymentStatus
                                    paymentDateUTC={paymentDateUTC}
                                    paymentStatus={paymentStatus}
                                    price={price}
                                    refundDateUTC={refundDateUTC}
                                    refundReason={refundReason}
                                />
                            </SummaryRowField>
                        </SummaryRow>
                    </GridItem>

                    <GridItem>
                        {taxReliefClaimId && <TaxReliefClaimAccountantUploads taxReliefClaimId={taxReliefClaimId} />}
                    </GridItem>
                </Grid>

                <TaxReliefClaimCustomerFiles taxReliefClaimId={taxReliefClaimId} />
            </Flexer>

            {isRefundModalOpen && (
                <RefundModal
                    isLoading={isLoadingRefundMutation}
                    itemName="tax relief claim"
                    itemPrice={taxReliefClaim.price}
                    onClose={() => setIsRefundModalOpen(false)}
                    onRefundItem={handleRefundTaxReliefClaim}
                    title={`Refund ${customer.fullName}'s tax relief claim`}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmationModal
                    onConfirm={handleConfirmDelete}
                    onCancel={() => setIsDeleteModalOpen(false)}
                    isLoading={isLoadingDelete}
                >
                    Are you sure you want to delete this tax relief claim?
                </ConfirmationModal>
            )}
        </AccountantPortalChrome.Customer.Page.Container>
    )
}

const SummaryRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 37px;

    &:not(:last-child) {
        margin-bottom: 9px;
    }
`

const SummaryRowLabel = styled.div`
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    line-height: 1.3;
    flex: 0 1 45%;
    padding-right: 18px;
`

const SummaryRowField = styled.div`
    font-size: ${font.small};
    flex: 0 0 55%;
    min-width: 192px;
`

const LastUpdated = styled.div`
    font-size: ${font.smaller};
    line-height: 1.3;
    flex: 1 1 auto;
    overflow: hidden;
    max-width: 240px;
`
