import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { localStorageGet } from '@scouts/helpers'
import { colors, media, Spinner } from '@scouts/ui'

import { createAxiosCancelSource } from '@/helpers'

import {
    requestQuickSearchRecentCustomers,
    requestQuickSearchResults,
    resetQuickSearch,
} from '../../actions/quick-search'
import { getIsFetchingQuickSearch, getQuickSearchResults } from '../../selectors/customer-services-quicksearch'
import { QuickSearchInput } from './QuickSearchInput'
import QuickSearchResults from './QuickSearchResults'

const Container = styled.div`
    text-align: left;
    position: relative;

    width: 100%;

    transition: width 0.3s;

    ${media.tablet} {
        width: ${({ isActive }) => (isActive ? '306px' : '192px')};
    }
`

const SpinnerContainer = styled.div`
    position: absolute;
    top: 7px;
    right: 12px;
`

const INITIAL_STATE = { filter: '', placeholder: 'Jump to…' }

const QuickSearch = () => {
    const dispatch = useDispatch()
    const customers = useSelector(getQuickSearchResults)
    const isFetching = useSelector(getIsFetchingQuickSearch)

    const [state, setState] = useState(INITIAL_STATE)
    const [isActive, setIsActive] = useState(false)
    const updateState = (newState) => setState((prevState) => ({ ...prevState, ...newState }))
    const { filter, placeholder } = state

    const timerRef = useRef()
    const containerRef = useRef()
    /** @type {import('react').MutableRefObject<import('axios').CancelTokenSource>} */
    const cancelSourceRef = useRef()

    const getRecentCustomers = () => {
        const recentCustomerIds = JSON.parse(localStorageGet('recentCustomers'))

        if (!Array.isArray(recentCustomerIds)) return []

        return recentCustomerIds.filter((id) => id !== null)
    }

    const handleOutsideClick = useCallback(() => {
        dispatch(resetQuickSearch())
        updateState(INITIAL_STATE)
        setIsActive(false)
    }, [dispatch])

    useOnClickOutside(containerRef, handleOutsideClick)

    const fetchResults = useCallback(
        (searchString) => {
            dispatch(
                requestQuickSearchResults({ filter: searchString }, { cancelToken: cancelSourceRef.current.token })
            )
        },
        [dispatch]
    )

    const fetchRecentCustomers = (customerIds) => {
        dispatch(requestQuickSearchRecentCustomers({ customerIds }))
    }

    useEffect(() => {
        cancelSourceRef.current = createAxiosCancelSource()
        if (filter.length >= 2) {
            timerRef.current = setTimeout(() => {
                fetchResults(filter)
            }, 300)
        } else {
            dispatch(resetQuickSearch())
        }

        return () => {
            if (cancelSourceRef.current) cancelSourceRef.current.cancel()
            clearTimeout(timerRef.current)
        }
    }, [dispatch, fetchResults, filter])

    const handleInputClick = () => {
        const recentCustomers = getRecentCustomers()

        if (recentCustomers.length > 0) {
            fetchRecentCustomers(recentCustomers)
            updateState({ placeholder: 'Type to search…' })
        }
    }

    const handleChange = (e) => {
        const { value } = e.target
        updateState({ filter: value })

        clearTimeout(timerRef.current)
    }

    const handleResultClick = () => {
        dispatch(resetQuickSearch())

        setIsActive(false)
        updateState(INITIAL_STATE)
    }

    const hasResults = customers.length > 0
    const hasRecentCustomers = customers.length > 0 && filter.length === 0

    return (
        <Container ref={containerRef} isActive={isActive}>
            <QuickSearchInput
                placeholder={placeholder}
                value={filter}
                onChange={handleChange}
                onClick={handleInputClick}
                onFocus={() => setIsActive(true)}
            />
            {isActive && hasResults && (
                <QuickSearchResults
                    title={hasRecentCustomers ? 'Recently viewed' : ''}
                    customers={customers}
                    onClick={handleResultClick}
                />
            )}
            {isFetching && (
                <SpinnerContainer>
                    <Spinner isSmall color={colors.blue} />
                </SpinnerContainer>
            )}
        </Container>
    )
}

export default QuickSearch
