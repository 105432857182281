import { AccountantSchedulingPageOpeningHoursDTO } from '@/models'

export const NYLAS_WEEK_DAYS = ['M', 'T', 'W', 'R', 'F', 'S', 'U'] as const

export const NYLAS_WEEK_DAYS_TITLES = {
    M: 'Mon',
    T: 'Tue',
    W: 'Wed',
    R: 'Thu',
    F: 'Fri',
    S: 'Sat',
    U: 'Sun',
} as const

export const getRemainingDaysOfNylasWeek = (openingHours = [] as AccountantSchedulingPageOpeningHoursDTO[]) => {
    const usedDays: string[] = []
    openingHours.map((row) => {
        if (row.days) usedDays.push(...row.days)
    })

    return NYLAS_WEEK_DAYS.filter((x) => !usedDays.includes(x))
}

const timeStringToNumber = (time: string) => Number(time.replace(':', ''))

export const validateNylasOpeningHours = (openingHours: AccountantSchedulingPageOpeningHoursDTO[]) => {
    const validHours = openingHours.filter(
        (row) => timeStringToNumber(row?.start ?? '') < timeStringToNumber(row?.end ?? '')
    )

    return validHours.length > 0
}

export const validateNylasMeetingLink = (link: string) =>
    link.startsWith('https://') &&
    (link.includes('meet.google.com') || link.includes('zoom.us') || link.includes('teams.microsoft.com'))
