import { useDismissSelfAssessmentEventsNotificationsMutation } from '../api'

export const useDismissSelfAssessmentEventsNotifications = () => {
    const [action, { isLoading }] = useDismissSelfAssessmentEventsNotificationsMutation()

    const dismissSelfAssessmentEventsNotifications = (params: { customerId: number; selfAssessmentId: number }) => {
        return action(params)
    }

    return { dismissSelfAssessmentEventsNotifications, isLoading }
}
