import { MESSAGE_TYPE_EMAIL } from '@/constants'
import { MessageDTO } from '@/models'
import { SendCustomerMessageParams } from '@/store/messages/api'

export const getMessageDate = (message: MessageDTO) => {
    return message.messageDateUTC
}

export const compareByMessageDate = (a: MessageDTO, b: MessageDTO) => {
    const dateA = getMessageDate(a)
    const dateB = getMessageDate(b)

    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
}

export const generateMessageFormData = ({
    attachments,
    customerId,
    messageContent,
    messageType = MESSAGE_TYPE_EMAIL,
    subject,
}: SendCustomerMessageParams) => {
    const formData = new FormData()
    formData.append('customerId', String(customerId))
    formData.append('messageContent', messageContent)
    formData.append('messageType', messageType)
    formData.append('subject', subject)

    if (attachments && attachments.length > 0) {
        attachments.forEach((attachment) => {
            formData.append('attachment', attachment)
        })
    }

    return formData
}
