import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { AccountantSchedulingPageEditDTO } from '@/models'

import { useUpdateNylasSchedulingPageMutation } from '../api'

export const useUpdateNylasSchedulingPage = () => {
    const [action, { isLoading }] = useUpdateNylasSchedulingPageMutation()

    const updateNylasSchedulingPage = (params: AccountantSchedulingPageEditDTO & { id: number }) => {
        return action(params).then(
            matchSuccessResponse(() => {
                addSuccessToast({ body: 'Schedule saved' })
            })
        )
    }

    return { updateNylasSchedulingPage, isLoading }
}
