import { colors } from '@scouts/ui'

import { TaxReliefClaimFileType, TaxReliefClaimStatus } from '@/models'

const statuses = {
    [TaxReliefClaimStatus.Started]: {
        color: colors.neutralLightest,
        title: TaxReliefClaimStatus.Started,
    },
    [TaxReliefClaimStatus.Submitted]: {
        color: colors.blueLighter,
        title: TaxReliefClaimStatus.Submitted,
    },
    [TaxReliefClaimStatus.InformationRequested]: {
        color: colors.yellow,
        title: 'Information requested',
    },
    [TaxReliefClaimStatus.AwaitingSignature]: {
        color: colors.yellow,
        title: 'Awaiting signature',
    },
    [TaxReliefClaimStatus.SignatureReceived]: {
        color: colors.blueLighter,
        title: 'Signature received',
    },
    [TaxReliefClaimStatus.ReadyToSend]: {
        color: colors.blueLighter,
        title: 'Ready to send',
    },
    [TaxReliefClaimStatus.Sent]: {
        color: colors.mintLighter,
        title: TaxReliefClaimStatus.Sent,
    },
    [TaxReliefClaimStatus.Cancelled]: {
        color: colors.redLighter,
        title: TaxReliefClaimStatus.Cancelled,
    },
}

const fileTypeTitles = {
    [TaxReliefClaimFileType.TaxReliefClaimCertificate]: {
        title: 'EIS certificate',
    },
    [TaxReliefClaimFileType.TaxReliefClaimCompletedForm]: {
        title: 'EIS completed form',
    },
    [TaxReliefClaimFileType.TaxReliefClaimProofOfPostage]: {
        title: 'EIS proof of postage',
    },
}

export const TaxReliefClaimDomain = {
    statuses,
    fileTypeTitles,
}
