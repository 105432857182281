import { useSelector } from 'react-redux'

import { getHasUnreadCustomerConversations, getSidebarConversationsCount } from '@/selectors'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

export const useConversationsCount = () => {
    const { customer } = useCustomerFromPath()
    const { id: customerId, accountantLastViewedConversationsUTC } = customer

    const conversationsCount = useSelector((state) => getSidebarConversationsCount(state, { customerId }))

    const hasUnreadConversations = useSelector((state) =>
        getHasUnreadCustomerConversations(state, {
            customerId,
            accountantLastViewedConversationsUTC,
        })
    )

    return { conversationsCount, hasUnreadConversations }
}
