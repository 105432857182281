import styled from 'styled-components'

import { Table } from '@scouts/ui'

import { TaxConsultationSummaryDTO } from '@/models'

import { TaxConsultationsTableRow } from './TaxConsultationsTableRow'

interface TaxConsultationsTableProps {
    isFetching: boolean
    taxConsultations: TaxConsultationSummaryDTO[]
    triggerTableUpdate: () => void
}

export const TaxConsultationsTable = ({
    isFetching,
    taxConsultations,
    triggerTableUpdate,
}: TaxConsultationsTableProps) => (
    <Container isFetching={isFetching}>
        <Table>
            <Table.Header>
                <Table.Header.Row>
                    <Table.Header.Cell width="20%">Client</Table.Header.Cell>
                    <Table.Header.Cell width="16%">Status</Table.Header.Cell>
                    <Table.Header.Cell width="12%">Scheduled</Table.Header.Cell>
                    <Table.Header.Cell width="22%">Topics</Table.Header.Cell>
                    <Table.Header.Cell width="20%" alignRight>
                        Notes
                    </Table.Header.Cell>
                    <Table.Header.Cell width="10%" alignRight>
                        Updated
                    </Table.Header.Cell>
                </Table.Header.Row>
            </Table.Header>
            <Table.Body>
                {taxConsultations.map((taxConsultation) => (
                    <TaxConsultationsTableRow
                        key={taxConsultation.id}
                        taxConsultation={taxConsultation}
                        triggerTableUpdate={triggerTableUpdate}
                    />
                ))}
            </Table.Body>
        </Table>
    </Container>
)

const Container = styled.div<{ isFetching?: boolean }>`
    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`
