import { formatCurrency, CurrencyCode, Locale } from '@scouts/helpers'

import { PATH_SELF_ASSESSMENTS } from '@/constants'

import { Card } from '../_ui/Card'

interface EarningsStatisticsProps {
    accountantId: number | null
    statistics: {
        thisMonthsFiledReturns: number | null
        lastMonthsFiledReturns: number | null
        thisMonthsEarnings: number | null
        lastMonthsEarnings: number | null
        inProcessEarnings: number | null
        inProcessReturns: number | null
    }
}

export const EarningsStatistics = ({ accountantId, statistics }: EarningsStatisticsProps) => {
    return (
        <>
            <Card
                linkTo={PATH_SELF_ASSESSMENTS}
                title="This month"
                value={
                    accountantId
                        ? formatCurrency(statistics?.thisMonthsEarnings || 0, {
                              currency: CurrencyCode.GBP,
                              locale: Locale.GB,
                          })
                        : 0
                }
                valueSecondary={accountantId ? statistics?.thisMonthsFiledReturns || 0 : 0}
            />
            <Card
                linkTo={PATH_SELF_ASSESSMENTS}
                title="Last month"
                value={
                    accountantId
                        ? formatCurrency(statistics?.lastMonthsEarnings || 0, {
                              currency: CurrencyCode.GBP,
                              locale: Locale.GB,
                          })
                        : 0
                }
                valueSecondary={accountantId ? statistics?.lastMonthsFiledReturns || 0 : 0}
            />
            <Card
                linkTo={PATH_SELF_ASSESSMENTS}
                title="In progress"
                value={
                    accountantId
                        ? formatCurrency(statistics?.inProcessEarnings || 0, {
                              currency: CurrencyCode.GBP,
                              locale: Locale.GB,
                          })
                        : 0
                }
                valueSecondary={accountantId ? statistics?.inProcessReturns || 0 : 0}
            />
        </>
    )
}
