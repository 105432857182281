import { useState } from 'react'
import styled from 'styled-components'

import { Locale } from '@scouts/helpers'
import { colors, Spacer, TypeSelect } from '@scouts/ui'

import { CustomerProductSearchSelectedItem } from '@/models'
import { useCustomersProductSearch, useCustomersProductStatistics } from '@/store/customers-statistics/hooks'

import { useFilters } from './hooks'

import BulkActions from '../BulkActions'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { CustomersFilter } from './CustomersFilter'
import { CustomersTable } from './CustomersTable'

interface CustomersProps {
    isAdminUser: boolean
    accountantsWithFullName: { value: string | number; title: string }[]
}

export const Customers = ({ isAdminUser, accountantsWithFullName }: CustomersProps) => {
    const { activeFilters, applyFilter } = useFilters()
    const { accountantId, productStatus, productType, page, pageSize } = activeFilters || {}

    const [selectedItems, setSelectedItems] = useState<CustomerProductSearchSelectedItem[]>([])

    const shouldSkipFetching = isAdminUser && !accountantId

    const {
        data: customersProductSearchResults,
        pagination: customersProductSearchPagination,
        isFetching,
        refetch: refetchCustomers,
    } = useCustomersProductSearch({
        params: {
            accountantId,
            productStatus,
            productType,
            page,
            pageSize,
        },
        skip: shouldSkipFetching,
    })
    const { data: customersProductStatistics, refetch: refetchCustomersStatistics } = useCustomersProductStatistics({
        params: {
            ...activeFilters,
            accountantId,
        },
        skip: shouldSkipFetching,
    })

    // Prevent "old" data from being displayed in the Customers screen when
    // admin user clicks "Clients" (in navbar) and force empty existing values
    const customers = shouldSkipFetching ? [] : customersProductSearchResults
    const pagination = shouldSkipFetching ? undefined : customersProductSearchPagination
    const customersStatistics = shouldSkipFetching ? undefined : customersProductStatistics

    const handleAccountantChange = ({ value: selectedAccountantId }: { name: string; value: string | number }) => {
        applyFilter({ updatedFilter: { accountantId: Number(selectedAccountantId) } })
    }

    const handleTableUpdate = () => {
        refetchCustomers()
        refetchCustomersStatistics()
    }

    const toggleSelection = ({
        customerId,
        fullName,
        unsubscribeFromBulkEmails,
    }: CustomerProductSearchSelectedItem) => {
        const isSelected = selectedItems.findIndex((o) => o.customerId === customerId) > -1

        let updatedSelectedItems = [...selectedItems]

        if (isSelected) {
            updatedSelectedItems = selectedItems.filter((o) => !(o.customerId === customerId))
        } else {
            updatedSelectedItems.push({
                customerId,
                fullName,
                unsubscribeFromBulkEmails,
            })
        }

        setSelectedItems(updatedSelectedItems)
    }

    const selectAll = () => {
        const selectedItems: CustomerProductSearchSelectedItem[] = []

        customers.forEach(({ id, fullName, unsubscribeFromBulkEmails }) => {
            if (selectedItems.findIndex((o) => o.customerId === id) > -1) return

            selectedItems.push({
                customerId: id,
                fullName,
                unsubscribeFromBulkEmails,
            })
        })

        setSelectedItems(selectedItems)
    }

    const deselectAll = () => {
        setSelectedItems([])
    }

    const hasCustomers = customers.length > 0
    const hasSelectedItems = selectedItems.length > 0
    const hasLoadedAccountantDropdown = accountantsWithFullName.length > 0

    return (
        <Container>
            {isAdminUser && (
                <AccountantFilterContainer isFetching={isFetching && !hasLoadedAccountantDropdown}>
                    {!isFetching && hasLoadedAccountantDropdown && (
                        <TypeSelect
                            name="filterAccountant"
                            value={Number(accountantId) || ''}
                            options={[{ value: '', title: 'Choose an accountant...' }, ...accountantsWithFullName]}
                            onChange={handleAccountantChange}
                            autoClear
                            isRequired
                            showOptions
                            locale={Locale.GB}
                        />
                    )}
                </AccountantFilterContainer>
            )}

            <FilterContainer isFetching={isFetching}>
                <CustomersFilter customersStatistics={customersStatistics} isAdminUser={isAdminUser} />
            </FilterContainer>

            {hasCustomers && (
                <>
                    <CustomersTable
                        customers={customers}
                        onDeselectAll={deselectAll}
                        handleCustomerSelection={toggleSelection}
                        onSelectAll={selectAll}
                        selectedItems={selectedItems}
                        isFetching={isFetching}
                        onTableUpdate={handleTableUpdate}
                    />

                    {!!pagination && (
                        <Spacer marginTop="24px">
                            <PaginationContainer pagination={pagination} />
                        </Spacer>
                    )}
                </>
            )}

            {hasSelectedItems && (
                <BulkActionsContainer>
                    <BulkActions selectedItems={selectedItems} deselectAll={deselectAll} />
                </BulkActionsContainer>
            )}
        </Container>
    )
}

const Container = styled.div`
    padding: 24px 24px 192px;
`

const AccountantFilterContainer = styled.div<{ isFetching?: boolean }>`
    margin-bottom: 12px;
    max-width: 320px;

    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        height: 35px;
        border: 1px solid ${colors.black};
        pointer-events: none;
    `};
`

const FilterContainer = styled.div<{ isFetching?: boolean }>`
    margin-bottom: 12px;

    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const BulkActionsContainer = styled.div`
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 9000;
    width: calc(100% - 36px);
    padding: 18px;
    background: ${colors.blueLighter};
`
