import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, font, Indicator } from '@scouts/ui'

import {
    AGENT_AUTH_STATUS_AWAITING_CODE,
    AGENT_AUTH_STATUS_CLIENT_AUTHORISED,
    AGENT_AUTH_STATUS_CODE_RECEIVED,
    AGENT_AUTH_STATUS_CODE_SENT_TO_CUSTOMER,
    AGENT_AUTH_STATUS_CODE_SUBMITTED,
    AGENT_AUTH_STATUS_INCORRECT_CODE,
    AGENT_AUTH_STATUS_REQUEST_EXPIRED,
    AGENT_AUTH_STATUS_REQUEST_EXPIRING,
    AGENT_AUTH_STATUS_REQUEST_FAILED,
    AGENT_AUTH_STATUS_SUBMITTED,
    AGENT_AUTH_STATUS_UNABLE_TO_ISSUE,
    IDENTITY_VERIFICATION_STATUS_CANCELLED,
    IDENTITY_VERIFICATION_STATUS_FAILED,
    IDENTITY_VERIFICATION_STATUS_REQUESTED,
    IDENTITY_VERIFICATION_STATUS_RETRIED,
    IDENTITY_VERIFICATION_STATUS_STARTED,
    IDENTITY_VERIFICATION_STATUS_SUBMITTED,
    IDENTITY_VERIFICATION_STATUS_VERIFIED,
} from '@/constants'

const CustomerProfileIndicators = ({ agentAuthorisationStatus, identityVerificationStatus }) => {
    const renderAgentAuthorisationIndicator = () => {
        if (!agentAuthorisationStatus) {
            return (
                <IndicatorRow>
                    <Indicator color={colors.neutralDarker} background={colors.white} border={colors.neutralLightest}>
                        AA
                    </Indicator>{' '}
                    <IndicatorDescription color={colors.neutralDarker}>
                        Agent authorisation not started
                    </IndicatorDescription>
                </IndicatorRow>
            )
        }

        const background = () => {
            switch (agentAuthorisationStatus) {
                case AGENT_AUTH_STATUS_CODE_RECEIVED:
                case AGENT_AUTH_STATUS_CODE_SUBMITTED:
                    return colors.blueLighter
                case AGENT_AUTH_STATUS_CLIENT_AUTHORISED:
                    return colors.mintLighter
                case AGENT_AUTH_STATUS_AWAITING_CODE:
                case AGENT_AUTH_STATUS_CODE_SENT_TO_CUSTOMER:
                case AGENT_AUTH_STATUS_REQUEST_EXPIRING:
                case AGENT_AUTH_STATUS_SUBMITTED:
                    return colors.yellow
                case AGENT_AUTH_STATUS_INCORRECT_CODE:
                case AGENT_AUTH_STATUS_REQUEST_EXPIRED:
                case AGENT_AUTH_STATUS_REQUEST_FAILED:
                case AGENT_AUTH_STATUS_UNABLE_TO_ISSUE:
                    return colors.red
                default:
                    return ''
            }
        }

        const title = () => {
            switch (agentAuthorisationStatus) {
                case AGENT_AUTH_STATUS_CODE_RECEIVED:
                case AGENT_AUTH_STATUS_CODE_SUBMITTED:
                    return 'Agent authorisation in process'
                case AGENT_AUTH_STATUS_CLIENT_AUTHORISED:
                    return 'Agent authorisation is completed'
                case AGENT_AUTH_STATUS_AWAITING_CODE:
                case AGENT_AUTH_STATUS_CODE_SENT_TO_CUSTOMER:
                case AGENT_AUTH_STATUS_REQUEST_EXPIRING:
                case AGENT_AUTH_STATUS_SUBMITTED:
                    return 'Agent authorisation in process'
                case AGENT_AUTH_STATUS_INCORRECT_CODE:
                case AGENT_AUTH_STATUS_REQUEST_EXPIRED:
                case AGENT_AUTH_STATUS_REQUEST_FAILED:
                case AGENT_AUTH_STATUS_UNABLE_TO_ISSUE:
                    return 'Agent authorisation has failed'
                default:
                    return ''
            }
        }

        return (
            <IndicatorRow>
                <Indicator background={background()}>AA</Indicator>{' '}
                <IndicatorDescription>{title()}</IndicatorDescription>
            </IndicatorRow>
        )
    }

    const renderIdentityVerificationIndicator = () => {
        if (!identityVerificationStatus) {
            return (
                <IndicatorRow>
                    <Indicator color={colors.neutralDarker} background={colors.white} border={colors.neutralLightest}>
                        KYC
                    </Indicator>{' '}
                    <IndicatorDescription color={colors.neutralDarker}>
                        Identity verification not started
                    </IndicatorDescription>
                </IndicatorRow>
            )
        }

        const background = () => {
            switch (identityVerificationStatus) {
                case IDENTITY_VERIFICATION_STATUS_STARTED:
                    return colors.neutralLightest
                case IDENTITY_VERIFICATION_STATUS_VERIFIED:
                    return colors.mintLighter
                case IDENTITY_VERIFICATION_STATUS_SUBMITTED:
                    return colors.blueLighter
                case IDENTITY_VERIFICATION_STATUS_REQUESTED:
                case IDENTITY_VERIFICATION_STATUS_RETRIED:
                    return colors.yellow
                case IDENTITY_VERIFICATION_STATUS_FAILED:
                case IDENTITY_VERIFICATION_STATUS_CANCELLED:
                    return colors.red
                default:
                    return ''
            }
        }

        const title = () => {
            switch (identityVerificationStatus) {
                case IDENTITY_VERIFICATION_STATUS_STARTED:
                    return 'Identity verification started'
                case IDENTITY_VERIFICATION_STATUS_VERIFIED:
                    return 'Identity marked as verified'
                case IDENTITY_VERIFICATION_STATUS_SUBMITTED:
                    return 'Identity documents need reviewing'
                case IDENTITY_VERIFICATION_STATUS_REQUESTED:
                case IDENTITY_VERIFICATION_STATUS_RETRIED:
                    return 'Identity documents requested'
                case IDENTITY_VERIFICATION_STATUS_FAILED:
                case IDENTITY_VERIFICATION_STATUS_CANCELLED:
                    return 'Identity verification marked as failed'
                default:
                    return ''
            }
        }

        return (
            <IndicatorRow>
                <Indicator background={background()}>KYC</Indicator>{' '}
                <IndicatorDescription>{title()}</IndicatorDescription>
            </IndicatorRow>
        )
    }

    return (
        <Indicators>
            {renderAgentAuthorisationIndicator()}
            {renderIdentityVerificationIndicator()}
        </Indicators>
    )
}

CustomerProfileIndicators.propTypes = {
    agentAuthorisationStatus: PropTypes.string,
    identityVerificationStatus: PropTypes.string,
}

CustomerProfileIndicators.defaultProps = {
    agentAuthorisationStatus: null,
    identityVerificationStatus: null,
}

export default CustomerProfileIndicators

const Indicators = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;
`

const IndicatorRow = styled.div`
    display: flex;
    align-items: center;
    gap: 9px;
`

const IndicatorDescription = styled.div`
    color: ${({ color }) => color || colors.black};
    font-size: ${font.smaller};
    line-height: 1.3;
`
