import { colors, Label } from '@scouts/ui'

import { TaxReliefClaimDomain } from '@/domain'
import { TaxReliefClaimDTO } from '@/models'

export const TaxReliefClaimsStatusLabel = ({ status }: { status: TaxReliefClaimDTO['status'] }) => {
    const currentStatus = TaxReliefClaimDomain.statuses[status]

    if (!currentStatus) return null

    return (
        <Label background={currentStatus.color} color={colors.black}>
            {currentStatus.title}
        </Label>
    )
}
