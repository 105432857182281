import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useRefundTaxRegistrationMutation, RefundTaxRegistrationParams } from '../api'

export const useRefundTaxRegistration = () => {
    const [action, { error, isLoading }] = useRefundTaxRegistrationMutation()

    const refundTaxRegistration = useCallback(
        async (params: RefundTaxRegistrationParams) =>
            action(params).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: 'Refund was successfully submitted' })
                })
            ),
        [action]
    )

    return {
        refundTaxRegistration,
        error,
        isLoading,
    }
}
