import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { colors, font } from '@scouts/ui'

const pulsate = keyframes`
    0% {
        transform: scale(0.1);
        opacity: 1;
    }
    70% {
        transform: scale(2.5);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`

const BlinkerWrap = styled.span`
    position: relative;
`
const Blinker = styled.span`
    position: absolute;
    background-color: ${colors.red};
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: ${({ size }) => size};
        width: ${({ size }) => size};
        background-color: transparent;
        border-radius: 50%;
        box-shadow: 0px 0px 2px 2px ${colors.red};
        animation: ${pulsate} 2.5s infinite linear;
    }

    &:after {
        animation-delay: 500ms;
    }
`

const LearnMoreTooltip = styled.div`
    position: relative;
    top: -24px;
    left: -160px;
    width: 130px;
    background: ${colors.red};
    padding: 18px 12px;
    border-radius: 5px;
    font-size: ${font.normal};
    line-height: 1.3;
    text-align: center;

    &:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-left-color: ${colors.red};
        border-width: 10px;
        margin-top: -10px;
    }
`

const defaultPosition = { top: 0, right: 0 }

const Beacon = ({ style, size, onClick, hasTooltip }) => (
    <BlinkerWrap>
        <Blinker size={size} onClick={onClick} style={{ ...defaultPosition, ...style }}>
            {hasTooltip && <LearnMoreTooltip>Click here to learn more</LearnMoreTooltip>}
        </Blinker>
    </BlinkerWrap>
)
Beacon.propTypes = {
    hasTooltip: PropTypes.bool,
    size: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func.isRequired,
}
Beacon.defaultProps = {
    hasTooltip: false,
    size: '2em',
    style: {},
}

export default Beacon
