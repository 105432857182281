import { useCallback } from 'react'

import { useLazyDownloadTaxConsultationFileQuery, DownloadTaxConsultationFileParams } from '../api'

export const useDownloadTaxConsultationFile = () => {
    const [action, { error, isLoading }] = useLazyDownloadTaxConsultationFileQuery()

    const downloadTaxConsultationFile = useCallback(
        ({ taxConsultationFileId }: DownloadTaxConsultationFileParams) =>
            action({ taxConsultationFileId }).then((response) => {
                if (response.data) window.location.assign(response.data)
            }),
        [action]
    )

    return {
        downloadTaxConsultationFile,
        error,
        isLoading,
    }
}
