import { useNylasReauthenticationNotice, usePayeApiNotice } from './hooks/use-global-notices'

import NoticeNylasReauthentication from './NoticeNylasReauthentication'
import NoticePayeIntegration from './NoticePayeIntegration'

export const GlobalNotices = () => {
    const { shouldDisplay: shouldDisplayNylasReauthenticationNotice } = useNylasReauthenticationNotice()

    const { shouldDisplay: shouldDisplayPayeApiNotice, onHide: onHidePayeApiNotice } = usePayeApiNotice()

    if (shouldDisplayNylasReauthenticationNotice) {
        return <NoticeNylasReauthentication />
    }

    if (shouldDisplayPayeApiNotice) {
        return <NoticePayeIntegration onClose={onHidePayeApiNotice} />
    }

    return null
}
