import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, Label } from '@scouts/ui'

export const ConversationMessageType = ({ type }) => {
    switch (type) {
        case 'close':
            return (
                <Action>
                    <Label color={colors.white} background={colors.black}>
                        Conversation closed
                    </Label>
                </Action>
            )
        case 'open':
            return (
                <Action>
                    <Label background={colors.white}>Conversation opened</Label>
                </Action>
            )
        default:
            return null
    }
}

ConversationMessageType.propTypes = {
    type: PropTypes.string.isRequired,
}

const Action = styled.div`
    margin-bottom: 12px;
`
