import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Button,
    Flexer,
    Form,
    Input,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalSubtitleLegacy as ModalSubtitle,
    ModalTitleLegacy as ModalTitle,
    Radio,
    RadioProps,
} from '@scouts/ui'

import { createCustomerDiscount } from '@/actions'
import { TAX_YEARS } from '@/constants'
import { TaxConsultationDomain } from '@/domain'
import { CustomerDTO } from '@/models'
import { getSelfAssessmentsApplicableForDiscount } from '@/selectors'
import { RootState } from '@/store'
import { useCustomersTaxConsultations } from '@/store/tax-consultations'

interface CustomerDiscountsAddModalProps {
    closeModal: () => void
    customerId: CustomerDTO['id']
}

export const CustomerDiscountsAddModal = ({ closeModal, customerId }: CustomerDiscountsAddModalProps) => {
    const dispatch = useDispatch()
    const [discountCode, setDiscountCode] = useState('')
    const [selfAssessmentId, setSelfAssessmentId] = useState<number | null>(null)
    const [taxConsultationId, setTaxConsultationId] = useState<number | null>(null)

    const selfAssessmentsApplicableForDiscount = useSelector((state: RootState) =>
        getSelfAssessmentsApplicableForDiscount(state, { customerId })
    )

    const { taxConsultations } = useCustomersTaxConsultations({ customerId })
    const taxConsultationsApplicableForDiscount =
        TaxConsultationDomain.getDiscountApplicableTaxConsultations(taxConsultations)

    const hasApplicableSelfAssessments = selfAssessmentsApplicableForDiscount?.length > 0
    const hasApplicableTaxConsultations =
        taxConsultationsApplicableForDiscount && taxConsultationsApplicableForDiscount.length > 0

    const handleSelfAssessmentIdChange: RadioProps['onChange'] = ({ value }) => {
        setSelfAssessmentId(Number(value))
        setTaxConsultationId(null)
    }

    const handleTaxConsultationIdChange: RadioProps['onChange'] = ({ value }) => {
        setSelfAssessmentId(null)
        setTaxConsultationId(Number(value))
    }

    const handleSubmit = () => {
        dispatch(
            createCustomerDiscount({
                customerId,
                discountCode,
                selfAssessmentId,
                taxConsultationId,
            })
        )

        closeModal()
    }

    const subTitle = useMemo(() => {
        const hasMoreApplicableServices =
            [hasApplicableSelfAssessments, hasApplicableTaxConsultations].filter(Boolean).length >= 2

        if (hasMoreApplicableServices) {
            return 'And attach to any existing service if necessary'
        }

        if (hasApplicableTaxConsultations) {
            return 'And attach to an existing tax consultation if necessary'
        }

        if (hasApplicableSelfAssessments) {
            return 'And attach to an existing tax return if necessary'
        }

        return ''
    }, [hasApplicableSelfAssessments, hasApplicableTaxConsultations])

    return (
        <Modal onClose={closeModal}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Apply discount code</ModalTitle>
                    {subTitle && <ModalSubtitle>{subTitle}</ModalSubtitle>}
                </ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Form.Row.Title>Discount code</Form.Row.Title>
                        <Form.Row.Content>
                            <Input
                                name="discountCode"
                                value={discountCode}
                                onChange={({ value }) => setDiscountCode(value)}
                                required
                            />
                        </Form.Row.Content>
                    </Form.Row>

                    {hasApplicableSelfAssessments && (
                        <Form.Row>
                            <Form.Row.Title>Apply to tax return:</Form.Row.Title>
                            <Form.Row.Content>
                                {selfAssessmentsApplicableForDiscount.map((assessment) => (
                                    <Flexer column gap="6px" key={assessment.id}>
                                        <Radio
                                            name="selfAssessmentId"
                                            value={assessment.id}
                                            label={TAX_YEARS[assessment.taxYear as keyof typeof TAX_YEARS]}
                                            onChange={handleSelfAssessmentIdChange}
                                            checked={assessment.id === Number(selfAssessmentId)}
                                        />
                                    </Flexer>
                                ))}
                            </Form.Row.Content>
                        </Form.Row>
                    )}
                    {hasApplicableTaxConsultations && (
                        <Form.Row>
                            <Form.Row.Title>Apply to tax consultation:</Form.Row.Title>
                            <Form.Row.Content>
                                {taxConsultationsApplicableForDiscount.map((taxConsultation) => (
                                    <Flexer column gap="6px" key={taxConsultation.id}>
                                        <Radio
                                            name="taxConsultationId"
                                            value={taxConsultation.id}
                                            label={String(taxConsultation.id)}
                                            onChange={handleTaxConsultationIdChange}
                                            checked={taxConsultation.id === Number(taxConsultationId)}
                                        />
                                    </Flexer>
                                ))}
                            </Form.Row.Content>
                        </Form.Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button type="submit">Add</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
