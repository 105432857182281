import { useEffect } from 'react'

import { useResetSessionAndLogout } from './hooks/use-reset-session-and-logout'

export const Logout = () => {
    const { resetSessionAndLogout, isSuccess } = useResetSessionAndLogout()

    useEffect(() => {
        resetSessionAndLogout()
    }, [resetSessionAndLogout])

    useEffect(() => {
        if (!isSuccess) return

        window.location.replace('/')
    }, [isSuccess])

    return null
}
