import { useCallback, useContext } from 'react'

import { BookkeepingPeriodOption, User } from '@scouts/bookkeeping'
import { isSameDate } from '@scouts/helpers'

import { BookkeepingConfigurationContext } from './BookkeepingConfigurationContext'

export const useBookkeepingConfiguration = () => {
    const [{ config, data }, setContext] = useContext(BookkeepingConfigurationContext)

    const getIsCustomerReady = useCallback((customerId: number) => data?.user.id === customerId, [data?.user.id])

    const setCustomer = useCallback(
        // @TODO:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (customer: any) => {
            if (!customer) {
                setContext({
                    config,
                    data: null,
                })
                return
            }

            if (getIsCustomerReady(customer.id)) return

            // @TODO: Double check if this is correct
            // UK customer uses different kind of address than ES customer
            const address = customer.addresses?.[0] ?? {}
            const user: User = {
                address: {
                    city: address?.city ?? '',
                    postcode: address?.postcode ?? '',
                    street: address?.streetAddress ?? '',
                },
                id: customer.id,
                idNumber: customer.uniqueTaxpayerReference ?? '',
                name: customer.fullName ?? '',
            }

            setContext({
                config,
                data: { user },
            })
        },
        [config, setContext, getIsCustomerReady]
    )

    const setBookkeepingPeriod = useCallback(
        (bookkeepingPeriod: BookkeepingPeriodOption) => {
            const { startDate: currentStartDate, endDate: currentEndDate } = config.bookkeepingPeriod ?? {}
            if (
                isSameDate(currentStartDate, bookkeepingPeriod.startDate) &&
                isSameDate(currentEndDate, bookkeepingPeriod.endDate)
            )
                return

            setContext((currentContext) => ({
                ...currentContext,
                config: {
                    ...currentContext.config,
                    bookkeepingPeriod,
                },
            }))
        },
        [config.bookkeepingPeriod, setContext]
    )

    return {
        config,
        data,
        getIsCustomerReady,
        setCustomer,
        setBookkeepingPeriod,
    }
}
