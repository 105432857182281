import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, ButtonLink, Spacer, Table } from '@scouts/ui'

import { PAGINATION_DEFAULT_PAGE, PAGINATION_MINIMUM_PAGE_SIZE, PATH_ACCOUNTANTS, PATH_USERS_CREATE } from '@/constants'
import { AccountantStatus, AccountantStatusFilter, AccountantStatusFilters } from '@/models'

import PaginationContainer from '../PaginationContainer'
import AccountantsFilter from './AccountantsFilter'
import { AccountantsRow } from './AccountantsRow'

class Accountants extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterStatus: '',
            filterText: '',
            page: null,
            pageSize: null,
        }

        _bindAll(this, ['reloadData', 'update', 'handleCloseQuickSettings'])
    }

    componentDidMount() {
        const { location, history } = this.props

        const { filterStatus, filterText } = this.parseFilterAndPaginationFromUrl(location)

        if (!filterStatus || filterText) {
            history.replace({
                pathname: PATH_ACCOUNTANTS,
                search: queryString.stringify({ status: AccountantStatus.Active }),
            })
        } else {
            this.update()
        }
    }

    componentDidUpdate() {
        this.update()
    }

    handleCloseQuickSettings(hasChanged) {
        if (hasChanged) {
            this.reloadData()
        }
    }

    update(shouldRefetch) {
        const { location } = this.props
        const {
            filterStatus: prevFilterStatus,
            filterText: prevFilterText,
            page: prevPage,
            pageSize: prevPageSize,
        } = this.state

        const { filterStatus, filterText, page, pageSize } = this.parseFilterAndPaginationFromUrl(location)

        const hasUpdated =
            prevFilterStatus !== filterStatus ||
            prevFilterText !== filterText ||
            page !== prevPage ||
            pageSize !== prevPageSize

        if (hasUpdated || shouldRefetch) {
            this.fetch({ filterStatus, filterText, page, pageSize }, shouldRefetch)
            this.setState({ filterStatus, filterText, page, pageSize })
        }
    }

    fetch({ filterStatus, filterText, page, pageSize }, shouldRefetch) {
        const { fetchAccountantStatistics } = this.props

        const isPropertyBasedFilter =
            filterStatus === AccountantStatusFilter.All ||
            filterStatus === AccountantStatusFilter.OnTrial ||
            filterStatus === AccountantStatusFilter.AllowTaxConsultation

        const status = isPropertyBasedFilter ? '' : filterStatus
        const isOnTrial = filterStatus === AccountantStatusFilter.OnTrial
        const allowTaxConsultation = filterStatus === AccountantStatusFilter.AllowTaxConsultation
        const textFilter = filterText

        fetchAccountantStatistics(
            { status, isOnTrial, allowTaxConsultation, textFilter, page, pageSize },
            shouldRefetch
        )
    }

    parseFilterAndPaginationFromUrl(location) {
        const { search } = location

        const query = queryString.parse(search)

        let filterStatus = _get(query, ['status'], '')
        const filterText = _get(query, ['filterText'], '')
        const page = Number(_get(query, ['page'], PAGINATION_DEFAULT_PAGE))
        const pageSize = Number(_get(query, ['pageSize'], PAGINATION_MINIMUM_PAGE_SIZE))

        if (filterStatus && !AccountantStatusFilters[filterStatus]) {
            filterStatus = ''
        }

        return { filterStatus, filterText, page, pageSize }
    }

    reloadData() {
        this.update(true)
    }

    render() {
        const { filterStatus, filterText } = this.state

        const { accountantStatusStatistics, history, location, accountants, pagination, isFetching } = this.props

        const totalNumberOfAccountants = accountants.length

        const isEmptySearchResults = totalNumberOfAccountants === 0
        const showNoResults = !isFetching && isEmptySearchResults

        return (
            <Container isFetching={isFetching}>
                <Actions>
                    <ActionsItem>
                        <AccountantsFilter
                            accountantStatusStatistics={accountantStatusStatistics}
                            filterStatus={filterStatus}
                            isFetching={isFetching}
                            history={history}
                        />
                    </ActionsItem>
                    <ActionsItem>
                        <Link to={`${PATH_USERS_CREATE}#accountant`}>
                            <ButtonLink icon="plus" as="span">
                                Add
                            </ButtonLink>
                        </Link>
                    </ActionsItem>
                </Actions>

                {showNoResults && (
                    <NoResults>
                        No {!!filterStatus && filterStatus !== AccountantStatusFilter.All ? filterStatus : ''}{' '}
                        accountants found {filterText ? `for "${filterText}"` : ''}
                    </NoResults>
                )}

                {totalNumberOfAccountants > 0 && (
                    <Table>
                        <Table.Header>
                            <Table.Header.Row>
                                <Table.Header.Cell width="23%">Accountant</Table.Header.Cell>
                                <Table.Header.Cell width="11%" />
                                <Table.Header.Cell width="10%">Active returns</Table.Header.Cell>
                                <Table.Header.Cell width="9%">Needs action</Table.Header.Cell>
                                <Table.Header.Cell width="10%">Returning</Table.Header.Cell>
                                <Table.Header.Cell width="10%">This month</Table.Header.Cell>
                                <Table.Header.Cell width="10%">Last month</Table.Header.Cell>
                                <Table.Header.Cell width="calc(17% - 130px)">Total clients</Table.Header.Cell>
                                <Table.Header.Cell width="130px" />
                            </Table.Header.Row>
                        </Table.Header>

                        <Table.Body>
                            {accountants.map((accountant) => (
                                <AccountantsRow
                                    accountant={accountant}
                                    key={accountant.accountantId}
                                    onCloseQuickSettings={this.handleCloseQuickSettings}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                )}

                {totalNumberOfAccountants > 0 && (
                    <Spacer marginTop="24px">
                        <PaginationContainer
                            pagination={pagination}
                            pathname={location.pathname}
                            search={location.search}
                        />
                    </Spacer>
                )}
            </Container>
        )
    }
}

Accountants.propTypes = {
    fetchAccountantStatistics: PropTypes.func.isRequired,
    accountantStatusStatistics: PropTypes.array.isRequired,
    accountants: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
}

export default withRouter(Accountants)

const Container = styled.div`
    padding: 24px 24px 192px;

    ${({ isFetching }) =>
        isFetching &&
        `
        opacity: 0.2;
        pointer-events: none;
    `};
`

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`

const ActionsItem = styled.div`
    font-size: ${font.small};
    flex: 0 0 auto;
    align-self: center;

    &:first-child {
        flex: 1 0 auto;
    }

    &:not(:last-child) {
        margin-right: 36px;
    }
`

const NoResults = styled.div`
    color: ${colors.neutralDarker};
`
