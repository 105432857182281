import { ReactNode } from 'react'
import styled from 'styled-components'

export const SectionData = ({ children }: { children: ReactNode }) => <Container>{children}</Container>

const Container = styled.div`
    &:not(:last-child) {
        margin-bottom: 18px;
    }
`
