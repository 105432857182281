import { matchSuccessResponse, useToggle } from '@scouts/helpers'
import { ButtonLink, ConfirmationModal } from '@scouts/ui'

import { requestCustomer } from '@/actions'
import { CustomerDTO } from '@/models'
import { useAppDispatch } from '@/store'
import { useCreateTaxConsultation } from '@/store/tax-consultations'

export const CreateTaxConsultation = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const dispatch = useAppDispatch()
    const [isModalOpen, open, close] = useToggle()

    const { createTaxConsultation, isLoading } = useCreateTaxConsultation()

    const handleCreate = () => {
        createTaxConsultation({ customerId }).then(
            matchSuccessResponse(() => {
                dispatch(requestCustomer(customerId))
                close()
            })
        )
    }

    return (
        <>
            <ButtonLink icon="plus" onClick={open}>
                Add new
            </ButtonLink>

            {isModalOpen && (
                <ConfirmationModal onCancel={close} onConfirm={handleCreate} isLoading={isLoading}>
                    Are you sure you want to create a new consultation for this customer?
                </ConfirmationModal>
            )}
        </>
    )
}
