import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { generateApiPath } from '@scouts/helpers'

import {
    CustomerDTO,
    RefundCreateDTO,
    ResponseWithPagination,
    StatisticsDTO,
    TaxRegistrationsRequestOptions,
    TaxRegistrationDTO,
    UserAgreementDTO,
} from '@/models'

import { apiSlice } from '../api-slice'
import { ENDPOINTS } from '../endpoints'
import { mapPaginatedResponse } from '../mapPaginatedResponse'
import { Tags } from '../tags'
import { getTaxRegistrationQueryParams } from './queryParams'

export interface GetTaxRegistrationParams {
    customerId: CustomerDTO['id']
}

export interface GetTaxRegistrationsParams {
    options?: TaxRegistrationsRequestOptions
}

export interface GetTaxRegistrationsStatisticsParams {
    options?: Pick<TaxRegistrationsRequestOptions, 'accountantId' | 'includeUnpaid'>
}

export interface UpdateTaxRegistrationStatusParams {
    customerId: CustomerDTO['id']
    status: TaxRegistrationDTO['status']
}

export interface GetTaxRegistrationAgreementParams {
    customerId: CustomerDTO['id']
}

export interface RefundTaxRegistrationParams {
    customerId: CustomerDTO['id']
    data: RefundCreateDTO
}

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTaxRegistration: builder.query<TaxRegistrationDTO, GetTaxRegistrationParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_REGISTRATIONS.GET.SINGLE, { customerId }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.TaxRegistrations, id: arg.customerId }],
        }),
        getTaxRegistrations: builder.query<ResponseWithPagination<TaxRegistrationDTO[]>, GetTaxRegistrationsParams>({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_REGISTRATIONS.GET.LIST, undefined, {
                    queryParameters: getTaxRegistrationQueryParams(options),
                }),
            }),
            providesTags: (result: ResponseWithPagination<TaxRegistrationDTO[]> | undefined) =>
                result
                    ? [...result.data.map(({ id }) => ({ type: Tags.TaxRegistrations, id })), Tags.TaxRegistrations]
                    : [Tags.TaxRegistrations],
            transformResponse: (response: TaxRegistrationDTO[], meta: FetchBaseQueryMeta) =>
                mapPaginatedResponse(response, meta),
        }),
        updateTaxRegistrationStatus: builder.mutation<TaxRegistrationDTO, UpdateTaxRegistrationStatusParams>({
            query: ({ customerId, status }) => ({
                method: 'PUT',
                url: generateApiPath(ENDPOINTS.TAX_REGISTRATIONS.PUT, { customerId, status }),
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.TaxRegistrations, id: arg.customerId }],
        }),
        refundTaxRegistration: builder.mutation<TaxRegistrationDTO, RefundTaxRegistrationParams>({
            query: ({ customerId, data }) => ({
                method: 'POST',
                url: generateApiPath(ENDPOINTS.TAX_REGISTRATIONS.POST.REFUND, { customerId }),
                body: data,
            }),
            invalidatesTags: (_1, _2, arg) => [{ type: Tags.TaxRegistrations, id: arg.customerId }],
        }),
        getTaxRegistrationAgreement: builder.query<UserAgreementDTO, GetTaxRegistrationAgreementParams>({
            query: ({ customerId }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_REGISTRATIONS.GET.AGREEMENT, { customerId }),
            }),
            providesTags: (_1, _2, arg) => [{ type: Tags.UserAgreement, id: arg.customerId }],
        }),
        getTaxRegistrationsStatistics: builder.query<StatisticsDTO[], GetTaxRegistrationsStatisticsParams>({
            query: ({ options }) => ({
                method: 'GET',
                url: generateApiPath(ENDPOINTS.TAX_REGISTRATIONS.GET.STATISTICS, undefined, {
                    queryParameters: getTaxRegistrationQueryParams(options),
                }),
            }),
            providesTags: [Tags.TaxRegistrationsStatistics],
        }),
    }),
})

export const {
    useGetTaxRegistrationQuery,
    useGetTaxRegistrationsQuery,
    useGetTaxRegistrationsStatisticsQuery,
    useGetTaxRegistrationAgreementQuery,
    useUpdateTaxRegistrationStatusMutation,
    useRefundTaxRegistrationMutation,
} = extendedApi
