import { generatePath, Link } from 'react-router-dom'

import { colors, radius, AccountantPortalChrome, Container, Flexer, Label, Spacer } from '@scouts/ui'

import { PATH_CUSTOMER_COMPANY_PROFILE } from '@/constants'
import { formatDate } from '@/helpers'
import { CustomerDTO } from '@/models'

import { ClickToCopyProvider } from '@/components/ClickToCopyProvider'

import { useCustomersCompany } from '../hooks/use-customers-company'

export const CustomerProfileCompanyDetails = ({ customerId }: { customerId: CustomerDTO['id'] }) => {
    const { company, companyNameLabel, isLoading } = useCustomersCompany({ customerId })

    if (!company || isLoading) return null

    const {
        id: companyId,
        registeredOfficeAddress,
        name,
        companyNumber,
        uniqueTaxpayerReference,
        companiesHouseAuthCode,
    } = company

    const { streetAddress, postcode, city, country } = registeredOfficeAddress || {}
    const hasAddedAddress = !!streetAddress || !!postcode || !!city || !!country

    const linkToCompanyProfile = generatePath(PATH_CUSTOMER_COMPANY_PROFILE, { customerId, companyId })

    return (
        <AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
            <AccountantPortalChrome.Customer.Sidebar.Profile.Field>
                <Container radius={radius.large} background={colors.neutralLightest} padding="18px">
                    <Flexer column gap="12px">
                        <Spacer>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                                <Link to={linkToCompanyProfile}>{companyNameLabel}</Link>
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                {name ? (
                                    <ClickToCopyProvider>{name}</ClickToCopyProvider>
                                ) : (
                                    <Label color={colors.black} background={colors.yellow}>
                                        Company name not set
                                    </Label>
                                )}
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        </Spacer>
                        <Spacer>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                                <Link to={linkToCompanyProfile}>Company number</Link>
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                {companyNumber ? (
                                    <ClickToCopyProvider>{companyNumber}</ClickToCopyProvider>
                                ) : (
                                    <Label color={colors.black} background={colors.yellow}>
                                        Company number not set
                                    </Label>
                                )}
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        </Spacer>
                        <Spacer>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                                <Link to={linkToCompanyProfile}>Companies House auth code</Link>
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                {companiesHouseAuthCode ? (
                                    <ClickToCopyProvider>{companiesHouseAuthCode}</ClickToCopyProvider>
                                ) : (
                                    <Label color={colors.black} background={colors.yellow}>
                                        Auth code not set
                                    </Label>
                                )}
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        </Spacer>

                        <Spacer>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                                <Link to={linkToCompanyProfile}>Company UTR</Link>
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                {uniqueTaxpayerReference ? (
                                    <ClickToCopyProvider>{uniqueTaxpayerReference}</ClickToCopyProvider>
                                ) : (
                                    <Label color={colors.black} background={colors.yellow}>
                                        Company UTR not set
                                    </Label>
                                )}
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        </Spacer>

                        <Spacer>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                                <Link to={linkToCompanyProfile}>Accounts due next</Link>
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                {company.accountsNextDueDate ? (
                                    <ClickToCopyProvider>{formatDate(company.accountsNextDueDate)}</ClickToCopyProvider>
                                ) : (
                                    '-'
                                )}
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                        </Spacer>

                        <Spacer>
                            <AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                                <Link to={linkToCompanyProfile}>Address</Link>
                            </AccountantPortalChrome.Customer.Sidebar.Profile.FieldLabel>
                            {hasAddedAddress ? (
                                <>
                                    {streetAddress && (
                                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                            <ClickToCopyProvider>{streetAddress}</ClickToCopyProvider>
                                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                    )}
                                    {city && (
                                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                            <ClickToCopyProvider>{city}</ClickToCopyProvider>
                                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                    )}
                                    {postcode && (
                                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                            <ClickToCopyProvider>{postcode}</ClickToCopyProvider>
                                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                    )}
                                    {country && (
                                        <AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                            <ClickToCopyProvider>{country}</ClickToCopyProvider>
                                        </AccountantPortalChrome.Customer.Sidebar.Profile.FieldValue>
                                    )}
                                </>
                            ) : (
                                <Label color={colors.black} background={colors.yellow}>
                                    Address not set
                                </Label>
                            )}
                        </Spacer>
                    </Flexer>
                </Container>
            </AccountantPortalChrome.Customer.Sidebar.Profile.Field>
        </AccountantPortalChrome.Customer.Sidebar.Profile.Fieldset>
    )
}
