import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useQueryParams } from '@scouts/helpers'
import { colors, ActionButton, Loadable, Page } from '@scouts/ui'

import { createNylasAccessToken, receiveErrorAlerts, receiveSuccessAlerts } from '../../../actions'
import { PATH_INTEGRATIONS_NYLAS, PATH_INTEGRATIONS_NYLAS_CREATE_SCHEDULE } from '../../../constants'

const ERROR_MESSAGE_NYLAS_CODE_NOT_FOUND = 'Integrating your calendar failed - code not found'
const ERROR_MESSAGE_NYLAS_CONNECTING_FAILED = 'Integrating your calendar failed'

const IntegrationsNylasCallback = () => {
    const { queryParams } = useQueryParams()
    const { code } = queryParams

    const history = useHistory()
    const dispatch = useDispatch()

    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(() => {
        if (!code) {
            setErrorMessage(ERROR_MESSAGE_NYLAS_CODE_NOT_FOUND)
            dispatch(receiveErrorAlerts(ERROR_MESSAGE_NYLAS_CODE_NOT_FOUND))
            return
        }

        const meta = {
            onSuccess: () => {
                dispatch(receiveSuccessAlerts('Calendar integrated successfully'))
                history.replace(PATH_INTEGRATIONS_NYLAS_CREATE_SCHEDULE)
            },
            onError: () => {
                setErrorMessage(ERROR_MESSAGE_NYLAS_CONNECTING_FAILED)
                dispatch(receiveErrorAlerts(ERROR_MESSAGE_NYLAS_CONNECTING_FAILED))
            },
        }

        dispatch(createNylasAccessToken({ code }, meta))
    }, [dispatch, code, history])

    const goToIntegrationsNylas = () => history.replace(PATH_INTEGRATIONS_NYLAS)

    if (errorMessage)
        return (
            <Page>
                <Page.Heading>Failed to integrate your calendar</Page.Heading>
                <Page.Section>
                    <p>An error occurred</p>
                    <p>{errorMessage}</p>
                </Page.Section>
                <Page.Actions background={colors.redLighter}>
                    <ActionButton onClick={goToIntegrationsNylas}>Back</ActionButton>
                </Page.Actions>
            </Page>
        )

    return (
        <Page>
            <Page.Heading>Integrating your calendar…</Page.Heading>
            <Page.Section>
                <Loadable isLoading />
            </Page.Section>
        </Page>
    )
}

export default IntegrationsNylasCallback
