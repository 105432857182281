import _bindAll from 'lodash/bindAll'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { colors } from '@scouts/ui'

import { downloadSearchResults, requestSearchResults } from '../../actions'
import {
    PAGINATION_DEFAULT_PAGE,
    PAGINATION_DEFAULT_PAGE_SIZE,
    PAGINATION_VIEW_ALL_PAGE_SIZE,
    PATH_SEARCH,
    SEARCH_RESULT_TYPE_USERS,
} from '../../constants'
import { sendDecodingErrorsToSentry } from '../../helpers/sentry'
import BulkActions from '../BulkActions'
import PaginationContainer from '../PaginationContainer'
import SearchFilter from './SearchFilter'
import SearchResults from './SearchResults'

const Container = styled.div`
    padding: 24px 24px 192px;
`

const FilterContainer = styled.div`
    margin-bottom: 24px;
`

const PaginationContainerWrap = styled.div`
    margin-top: 24px;
`

const BulkActionsContainer = styled.div`
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 9000;
    width: calc(100% - 36px);
    padding: 18px;
    background: ${colors.blueLighter};
`

class SearchContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: {},
            filterToken: null,
            page: 1,
            pageSize: 100,
            selectedItems: [],
        }

        _bindAll(this, [
            'applyFilter',
            'clearFilter',
            'deselectAll',
            'download',
            'removeFilter',
            'selectAll',
            'toggleSelection',
        ])
    }

    componentDidMount() {
        const { location } = this.props

        const { filterToken } = this.parseFilterSettingsFromUrl(location)

        if (!filterToken) {
            this.applyFilter(
                {
                    resultType: SEARCH_RESULT_TYPE_USERS,
                    selfAssessmentStatuses: [],
                    selfAssessmentTaxYears: [],
                    taxRegistrationStatuses: [],
                    agentAuthorisationStatuses: [],
                },
                true
            )
        } else {
            this.update()
        }
    }

    componentDidUpdate() {
        this.update()
    }

    applyFilter = (updatedFilter, replace = false) => {
        const { history } = this.props
        const { filter } = this.state

        const string = btoa(JSON.stringify({ ...filter, ...updatedFilter }))

        const query = queryString.stringify({ filter: string })

        const location = {
            pathname: PATH_SEARCH,
            search: query,
        }

        if (replace) {
            history.replace(location)
        } else {
            history.push(location)
        }
    }

    replaceFilters = (updatedFilter) => {
        const { history } = this.props

        const string = btoa(JSON.stringify(updatedFilter))

        const query = queryString.stringify({ filter: string })

        const location = {
            pathname: PATH_SEARCH,
            search: query,
        }

        history.push(location)
    }

    removeFilter(filterName) {
        const { history } = this.props
        const { filter } = this.state

        const updatedFilter = { ...filter }

        let filterNames = []
        if (!Array.isArray(filterName)) {
            filterNames.push(filterName)
        } else {
            filterNames = filterName
        }

        filterNames.forEach((name) => {
            if (updatedFilter[name] !== undefined) {
                delete updatedFilter[name]
            }
        })

        if (filter !== updatedFilter) {
            const string = btoa(JSON.stringify(updatedFilter))

            const query = queryString.stringify({ filter: string })

            history.push({
                pathname: PATH_SEARCH,
                search: query,
            })
        }
    }

    clearFilter() {
        const { history } = this.props

        history.push({
            pathname: PATH_SEARCH,
            search: '',
        })
    }

    update() {
        const { location } = this.props
        const { filterToken: prevFilterToken, page: prevPage, pageSize: prevPageSize } = this.state

        const { filterToken, page, pageSize } = this.parseFilterSettingsFromUrl(location)

        const hasUpdated = filterToken !== prevFilterToken || page !== prevPage || pageSize !== prevPageSize

        if (hasUpdated) {
            const filter = this.parseFilterFromToken(filterToken)

            this.fetch({ filter, page, pageSize })
            this.deselectAll()

            this.setState({ filterToken, filter, page, pageSize })
        }
    }

    parseFilterSettingsFromUrl(location) {
        const { search } = location

        const query = queryString.parse(search)

        const filterToken = _get(query, ['filter'], '')
        const page = Number(_get(query, ['page'], PAGINATION_DEFAULT_PAGE))
        const pageSize = Number(_get(query, ['pageSize'], PAGINATION_DEFAULT_PAGE_SIZE))

        return { filterToken, page, pageSize }
    }

    parseFilterFromToken(filterToken) {
        if (!filterToken) return {}

        try {
            return JSON.parse(atob(filterToken))
        } catch (exception) {
            sendDecodingErrorsToSentry(exception, filterToken, 'SearchContainer')
            return {}
        }
    }

    fetch({ filter, page, pageSize }) {
        const { dispatch } = this.props

        dispatch(requestSearchResults({ filter, page, pageSize }))
    }

    toggleSelection({
        customerId,
        fullName,
        selfAssessmentId,
        status,
        taxConsultationId,
        unsubscribeFromBulkEmails,
        phoneNumber,
    }) {
        const { selectedItems } = this.state

        const isSelected =
            selectedItems.findIndex(
                (o) =>
                    o.customerId === customerId &&
                    o.selfAssessmentId === selfAssessmentId &&
                    o.taxConsultationId === taxConsultationId
            ) > -1

        let updatedSelectedItems = [...selectedItems]

        if (isSelected) {
            updatedSelectedItems = selectedItems.filter(
                (o) =>
                    !(
                        o.customerId === customerId &&
                        o.selfAssessmentId === selfAssessmentId &&
                        o.taxConsultationId === taxConsultationId
                    )
            )
        } else {
            updatedSelectedItems.push({
                customerId,
                fullName,
                selfAssessmentId,
                taxConsultationId,
                status,
                unsubscribeFromBulkEmails,
                phoneNumber,
            })
        }

        this.setState({
            selectedItems: updatedSelectedItems,
        })
    }

    selectAll() {
        const { items } = this.props

        const selectedItems = []

        items.forEach(
            ({
                customerId,
                fullName,
                phoneNumber,
                selfAssessmentId,
                selfAssessmentStatus,
                taxConsultationId,
                unsubscribeFromBulkEmails,
            }) => {
                if (
                    selectedItems.findIndex(
                        (o) =>
                            o.customerId === customerId &&
                            o.selfAssessmentId === selfAssessmentId &&
                            o.taxConsultationId === taxConsultationId
                    ) > -1
                )
                    return

                selectedItems.push({
                    customerId,
                    fullName,
                    selfAssessmentId,
                    taxConsultationId,
                    status: selfAssessmentStatus || null,
                    unsubscribeFromBulkEmails,
                    phoneNumber,
                })
            }
        )

        this.setState({ selectedItems })
    }

    deselectAll() {
        this.setState({ selectedItems: [] })
    }

    download() {
        const { dispatch } = this.props
        const { filter } = this.state

        const body = { ...filter, pageSize: PAGINATION_VIEW_ALL_PAGE_SIZE }

        dispatch(downloadSearchResults(body))
    }

    render() {
        const { items, isFetching, pagination, location } = this.props
        const { filter, selectedItems } = this.state

        const hasSelectedItems = selectedItems.length > 0
        const itemsCount = items.length
        const hasItems = itemsCount > 0

        return (
            <Container>
                <FilterContainer>
                    <SearchFilter
                        applyFilter={this.applyFilter}
                        clearFilter={this.clearFilter}
                        download={this.download}
                        filter={filter}
                        itemsCount={itemsCount}
                        pagination={pagination}
                        removeFilter={this.removeFilter}
                        replaceFilters={this.replaceFilters}
                    />
                </FilterContainer>

                {hasItems && (
                    <>
                        <SearchResults
                            deselectAll={this.deselectAll}
                            isFetching={isFetching}
                            items={items}
                            selectAll={this.selectAll}
                            selectedItems={selectedItems}
                            toggleSelection={this.toggleSelection}
                        />

                        <PaginationContainerWrap>
                            <PaginationContainer
                                pagination={pagination}
                                pathname={location.pathname}
                                search={location.search}
                            />
                        </PaginationContainerWrap>
                    </>
                )}

                {hasSelectedItems && (
                    <BulkActionsContainer>
                        <BulkActions selectedItems={selectedItems} deselectAll={this.deselectAll} />
                    </BulkActionsContainer>
                )}
            </Container>
        )
    }
}

SearchContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
}

const mapStateToProps = ({ search }) => {
    const { isFetching, items, pagination } = search

    return { isFetching, items, pagination }
}

export default withRouter(connect(mapStateToProps)(SearchContainer))
