import { useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { DropdownSelect, DropdownSelectOption, DropdownSelectProps } from '@scouts/ui'

import { PATH_CUSTOMER_COMPANY_RETURN } from '@/constants'
import { getCompanyNameNumberedPlaceholder } from '@/domain'
import { useCompanies } from '@/store/companies/hooks'

import { useGetCompanyReturnParams } from './hooks/use-get-params'

export const CompanyReturnCompanyDropdown = () => {
    const history = useHistory()
    const { customerId, companyId: activeCompanyId } = useGetCompanyReturnParams()

    const { companies } = useCompanies({ customerId })

    const options = useMemo(
        () =>
            companies?.map(
                (company) =>
                    ({
                        value: company.id,
                        disabled: company.id === activeCompanyId,
                        title: company.name || getCompanyNameNumberedPlaceholder(company, companies),
                    }) as DropdownSelectOption
            ) || [],
        [activeCompanyId, companies]
    )

    const handleChange: DropdownSelectProps['onChange'] = ({ value: companyId }) => {
        history.push(generatePath(PATH_CUSTOMER_COMPANY_RETURN, { customerId, companyId }))
    }

    return <DropdownSelect name="companyId" value={activeCompanyId} options={options} onChange={handleChange} />
}
