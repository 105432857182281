import _bindAll from 'lodash/bindAll'
import PropTypes from 'prop-types'
import { Component } from 'react'
import Dropzone from 'react-dropzone'
import styled, { keyframes } from 'styled-components'

import { hexToRgbA } from '@scouts/helpers'
import { colors, font, media } from '@scouts/ui'

const pulse = keyframes`
    0% {
        background-color: ${colors.blueLighter};
    }
    50% {
        background-color: ${hexToRgbA(colors.blueLighter, 0.5)};
    }
    100% {
        background-color: ${colors.blueLighter};
    }
`

const Container = styled.div``

const DropzoneContainer = styled.div`
    color: ${colors.blue};
    font-size: ${font.small};
    font-weight: ${font.weight.medium};
    text-align: center;
    background: ${colors.blueLighter};
    border: 3px dashed ${colors.blue};
    cursor: pointer;
    animation-name: ${pulse};
    animation-duration: 2s;
    animation-iteration-count: infinite;

    ${media.tablet} {
        font-size: ${font.normal};
    }

    ${media.pointer} {
        &:hover {
            border-color: ${colors.blue};
        }
    }

    p:not(:last-child) {
        margin-bottom: 0;
    }

    p:not(:last-of-type) {
        margin-bottom: 12px;
    }
`

const Label = styled.button`
    color: ${colors.blue};
    font-size: ${font.small};

    ${media.pointer} {
        &:hover {
            border-color: ${colors.black};
        }
    }
`

class SendMessagesFileInput extends Component {
    constructor() {
        super()

        this.state = {
            isOpen: false,
        }

        _bindAll(this, ['onDrop', 'toggleUpload'])
    }

    onDrop(files) {
        const { onChange } = this.props

        onChange(files)
    }

    toggleUpload() {
        this.setState({ isOpen: true })
    }

    render() {
        const { label, accept } = this.props
        const { isOpen } = this.state

        const style = {
            width: '100%',
            padding: '28px',
        }

        return (
            <Container>
                {!isOpen && <Label onClick={this.toggleUpload}>{label}</Label>}
                {isOpen && (
                    <DropzoneContainer>
                        <Dropzone onDrop={this.onDrop} style={style} acceptedFiles={accept}>
                            <p>Drag & drop files here to upload (or click to select)</p>
                        </Dropzone>
                    </DropzoneContainer>
                )}
            </Container>
        )
    }
}

SendMessagesFileInput.propTypes = {
    accept: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default SendMessagesFileInput
