export const ProductType = {
    SelfAssessment: 'SelfAssessment',
    TaxConsultation: 'TaxConsultation',
    TaxRegistration: 'TaxRegistration',
    Other: 'Other',
    ProductBundle: 'ProductBundle',
    Subscription: 'Subscription',
    CompanyReturn: 'CompanyReturn',
} as const

export type ProductTypeTypes = (typeof ProductType)[keyof typeof ProductType]
