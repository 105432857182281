import { createSelector } from '@reduxjs/toolkit'
import _get from 'lodash/get'

const pluckSelfAssessmentId = (_state, { selfAssessmentId }) => selfAssessmentId

const getSelfAssessmentFileItems = (state) => _get(state, ['files', 'data'], [])

export const getSelfAssessmentFilesBySelfAssessmentId = createSelector(
    [getSelfAssessmentFileItems, pluckSelfAssessmentId],
    (selfAssessmentFiles, selfAssessmentId) =>
        selfAssessmentFiles.filter((o) => o.selfAssessmentId === selfAssessmentId)
)
