import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { requestIntegrationAccessToken } from '../actions'
import { EXTERNAL_API_NYLAS } from '../constants'
import { getUser } from '../selectors/user'

export const useHasExpiredNylasAccessToken = () => {
    const user = useSelector(getUser)
    const { hasNylasApiConnection } = user
    const [isExpired, setIsExpired] = useState(null)

    const dispatch = useDispatch()
    useEffect(() => {
        if (hasNylasApiConnection) {
            dispatch(
                requestIntegrationAccessToken(
                    { externalApi: EXTERNAL_API_NYLAS },
                    {
                        onSuccess: (data) => {
                            const { hasExpired } = data
                            setIsExpired(hasExpired)
                        },
                    }
                )
            )
        } else {
            setIsExpired(false)
        }
    }, [dispatch, hasNylasApiConnection])

    return {
        isExpired,
    }
}
