import styled from 'styled-components'

import { useToggle } from '@scouts/helpers'
import { colors, font, media, Table } from '@scouts/ui'

import { AccountantDetailsDTO } from '@/models'

import { UsersTableRow } from './UsersTableRow'

export const UsersTable = ({ users }: { users?: AccountantDetailsDTO[] }) => {
    const [shouldDisplayAllUsers, , , toggleDisplayAllUsers] = useToggle()

    if (!users || users?.length === 0) return null

    const filteredUsers = !shouldDisplayAllUsers ? users.filter(({ isBlocked }) => !isBlocked) : users
    const totalNumberOfUsers = users.length
    const totalNumberOfHiddenUsers = totalNumberOfUsers - filteredUsers.length

    return (
        <Table>
            <Table.Body>
                {filteredUsers.map((user) => (
                    <UsersTableRow key={user.id} user={user} />
                ))}
            </Table.Body>

            <Toggle onClick={toggleDisplayAllUsers}>
                {!shouldDisplayAllUsers ? (
                    <>
                        Hiding {totalNumberOfHiddenUsers} blocked {totalNumberOfHiddenUsers === 1 ? 'user' : 'users'},
                        click to show
                    </>
                ) : (
                    <>Hide blocked users</>
                )}
            </Toggle>

            <Table.Footer>
                <Table.Footer>
                    <Table.Footer.Cell width="75%">{totalNumberOfUsers} total</Table.Footer.Cell>
                    <Table.Footer.Cell width="25%" />
                </Table.Footer>
            </Table.Footer>
        </Table>
    )
}

const Toggle = styled.div`
    cursor: pointer;
    font-size: ${font.small};
    text-align: center;
    width: 100%;
    padding: 12px;
    background: ${colors.neutralLightest};

    ${media.pointer} {
        &:hover {
            color: ${colors.blue};
        }
    }
`
