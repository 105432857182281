import _noop from 'lodash/noop'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
    colors,
    font,
    radius,
    ActionButton,
    ButtonLink,
    Container,
    File,
    Flexer,
    Heading,
    List,
    Page,
    Paragraph,
    Spacer,
    UploadChip,
} from '@scouts/ui'

import { requestCapitalGainsCalculation } from '@/actions'
import { CRYPTO_TRANSACTIONS_TEMPLATE_LINK, SHARE_TRANSACTIONS_TEMPLATE_LINK } from '@/constants'

export const ToolsCGT = () => {
    const dispatch = useDispatch()
    const [uploadedFile, setUploadedFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const uploadFile = (files: File[]) => {
        const file = files[0]
        setUploadedFile(file)
    }

    const deleteUploadedFile = () => {
        setUploadedFile(null)
    }

    const handleRequestCompleted = () => {
        setIsLoading(false)
    }

    const downloadCalculationsFile = () => {
        const formData = new FormData()

        if (uploadedFile) {
            formData.append('transactionsFile', uploadedFile)
        }

        setIsLoading(true)
        dispatch(
            requestCapitalGainsCalculation(formData, {
                onSuccess: handleRequestCompleted,
                onError: handleRequestCompleted,
            })
        )
    }

    const hasFiles = !!uploadedFile

    return (
        <Page>
            <Page.Heading>Capital Gains Calculator</Page.Heading>
            <Page.SubHeading>
                This calculator can be used to work out the capital gain from a series of share or crypto transactions.
                It applies HMRC rules to work out the gain or loss from the disposals.
            </Page.SubHeading>

            <List isNumbered itemsBackground={colors.surface.highlight}>
                <List.Item>
                    <Heading size={font.normal}>Download a template</Heading>
                    <Spacer margin="24px 0 36px">
                        <Paragraph>
                            There are two templates, one for shares and one for crypto which have slightly different
                            rules. Fill in with all the relevant transactions. You will need all transactions even those
                            before the relevant tax year.
                        </Paragraph>
                        <Spacer marginTop="18px">
                            <Flexer column gap="18px">
                                <ButtonLink as="a" href={SHARE_TRANSACTIONS_TEMPLATE_LINK}>
                                    Download Share transactions template
                                </ButtonLink>

                                <ButtonLink as="a" href={CRYPTO_TRANSACTIONS_TEMPLATE_LINK}>
                                    Download Crypto transactions template
                                </ButtonLink>
                            </Flexer>
                        </Spacer>
                    </Spacer>
                </List.Item>
                <List.Item>
                    <Heading size={font.normal}>Upload template</Heading>
                    <Spacer margin="24px 0 36px">
                        {!hasFiles && <Paragraph>Once you have filled in the template upload here</Paragraph>}
                        <Spacer marginTop="18px">
                            <Flexer column gap="6px">
                                {!hasFiles && (
                                    <Spacer>
                                        <UploadChip
                                            acceptMultiple={false}
                                            onUpload={uploadFile}
                                            label="+ Add a transactions file"
                                        />
                                    </Spacer>
                                )}

                                {hasFiles && (
                                    <File background={colors.blueLighter} onDelete={deleteUploadedFile} onClick={_noop}>
                                        {uploadedFile.name}
                                    </File>
                                )}
                            </Flexer>
                        </Spacer>
                    </Spacer>
                </List.Item>
                <List.Item>
                    <Heading size={font.normal}>Calculate capital gains</Heading>
                    <Spacer margin="24px 0 36px">
                        <Paragraph>
                            Click calculate and the tool will calculate the gain or loss from the transaction
                        </Paragraph>
                        <Spacer marginTop="18px">
                            <Container
                                background={hasFiles ? colors.mintLighter : colors.neutralLightest}
                                padding="24px"
                                radius={radius.medium}
                            >
                                <ActionButton
                                    onClick={downloadCalculationsFile}
                                    disabled={!hasFiles}
                                    isLoading={isLoading}
                                >
                                    Calculate
                                </ActionButton>
                            </Container>
                        </Spacer>
                    </Spacer>
                </List.Item>
            </List>
        </Page>
    )
}
