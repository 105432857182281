import { TaxRegistrationStatus } from '@/models'

import { useGetTaxRegistrationQuery, GetTaxRegistrationParams } from '../api'

export const useTaxRegistration = ({ customerId, skip }: GetTaxRegistrationParams & { skip?: boolean }) => {
    const { data, isError, isLoading, isFetching } = useGetTaxRegistrationQuery({ customerId }, { skip })

    const isActive =
        data &&
        !!data.hasPaid &&
        data.status !== TaxRegistrationStatus.Complete &&
        data.status !== TaxRegistrationStatus.Cancelled

    const isCompleted = data && !!data.hasPaid && data.status === TaxRegistrationStatus.Complete

    return {
        taxRegistration: data,
        isActive,
        isCompleted,
        isError,
        isLoading,
        isFetching,
    }
}
