import _noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'

import { font, Input } from '@scouts/ui'

const SearchInput = styled(Input)`
    font-size: ${font.small};
    margin-bottom: 12px;
`

const useTypeahead = () => {
    const [searchString, setSearchString] = useState('')
    const onSearchChange = ({ value }) => setSearchString(value)
    return [searchString, onSearchChange]
}

const SearchTypeahead = ({ closeDropdown, value, onChange, onKeyDown }) => (
    <SearchInput
        autoFocus
        placeholder="Type to search…"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown(closeDropdown)}
    />
)
SearchTypeahead.defaultProps = {
    closeDropdown: _noop,
    value: '',
}
SearchTypeahead.propTypes = {
    closeDropdown: PropTypes.func,
    onKeyDown: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
}

export { useTypeahead }
export default SearchTypeahead
