import { useState } from 'react'

import {
    Button,
    Flexer,
    Form,
    ModalActionsLegacy as ModalActions,
    ModalBodyLegacy as ModalBody,
    ModalFooterLegacy as ModalFooter,
    ModalHeaderLegacy as ModalHeader,
    ModalLegacy as Modal,
    ModalTitleLegacy as ModalTitle,
    Paragraph,
    Radio,
} from '@scouts/ui'

import { saveSelfAssessment } from '@/actions'
import { ACTIVE_TAX_YEARS, TAX_YEARS } from '@/constants'
import { SelfAssessmentDTO, SelfAssessmentSummaryDTO } from '@/models'
import { useAppDispatch } from '@/store'

interface SelfAssessmentEditTaxYearModalProps {
    selfAssessment: SelfAssessmentDTO
    onClose: () => void
    customerSelfAssessments: SelfAssessmentSummaryDTO[]
}

export const SelfAssessmentEditTaxYearModal = ({
    selfAssessment,
    onClose,
    customerSelfAssessments,
}: SelfAssessmentEditTaxYearModalProps) => {
    const dispatch = useAppDispatch()
    const [taxYear, setTaxYear] = useState<string | null>(null)

    const handleSubmit = () => {
        const currentTaxYear = Number(selfAssessment.taxYear)
        const selectedTaxYear = Number(taxYear)

        if (selectedTaxYear && currentTaxYear !== selectedTaxYear) {
            const newSelfAssessment = { ...selfAssessment, taxYear: selectedTaxYear }
            dispatch(saveSelfAssessment(newSelfAssessment))
        }

        onClose()
    }

    const notStartedTaxYears = ACTIVE_TAX_YEARS.filter(
        (taxYear) =>
            !customerSelfAssessments.find(({ taxYear: assessmentTaxYear }) => assessmentTaxYear === String(taxYear))
    )

    const currentTaxYear = Number(selfAssessment.taxYear || null)

    if (!currentTaxYear) return null

    const selectedTaxYear = Number(taxYear)

    return (
        <Modal onClose={onClose}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>Assign tax return to different tax year</ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <Flexer column gap="18px">
                        {notStartedTaxYears.map((year) => (
                            <Radio
                                key={year}
                                checked={year === selectedTaxYear}
                                label={`Tax year ${TAX_YEARS[Number(year) as keyof typeof TAX_YEARS]}`}
                                name="taxYear"
                                onChange={({ value }) => setTaxYear(value)}
                                value={year}
                            />
                        ))}

                        {!notStartedTaxYears.length && <Paragraph>There are no unstarted tax years</Paragraph>}
                    </Flexer>
                </ModalBody>

                <ModalFooter>
                    <ModalActions>
                        <Button isSecondary onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </ModalActions>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
