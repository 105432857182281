import _find from 'lodash/find'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { requestAccountants } from '../../actions'
import { getAccountantsForRadios } from '../../selectors'
import { FilterItem, FilterItemDropdownRadios } from '../ui/filter'
import SearchTypeahead, { useTypeahead } from './SearchTypeahead'

const SearchFilterAccountant = ({ activeFilter, applyFilter, removeFilter, isOpenByDefault }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(requestAccountants())
    }, [dispatch])

    const values = useSelector((state) => getAccountantsForRadios(state, { activeFilter }))

    const activeValue = _get(_find(values, { value: activeFilter }), ['label'], null)
    const name = 'accountantId'

    const [searchString, onSearchChange] = useTypeahead()
    const applyAndClearSearch = (filter) => {
        onSearchChange({ value: '' })
        applyFilter(filter)
    }

    const visibleValues = values.filter(({ label }) => {
        if (!searchString) return true
        return label.toLowerCase().includes(searchString.toLowerCase())
    })

    const handleKeyDown = (closeDropdown) => (event) => {
        if (event.key === 'Enter' && visibleValues.length === 1) {
            const filter = { [name]: visibleValues[0].value }
            applyAndClearSearch(filter)
            if (closeDropdown) closeDropdown()
        }
    }

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label="Accountant"
            activeValues={[activeValue || 'Any']}
            removeFilter={() => removeFilter(name)}
            isNeutral={!activeValue}
        >
            <SearchTypeahead value={searchString} onChange={onSearchChange} onKeyDown={handleKeyDown} />
            <FilterItemDropdownRadios
                applyFilter={applyAndClearSearch}
                name={name}
                removeFilter={() => removeFilter(name)}
                values={visibleValues}
            />
        </FilterItem>
    )
}

SearchFilterAccountant.propTypes = {
    activeFilter: PropTypes.number,
    applyFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
}

SearchFilterAccountant.defaultProps = {
    activeFilter: null,
}

export default SearchFilterAccountant
