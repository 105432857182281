import { useCallback } from 'react'

import { matchSuccessResponse } from '@scouts/helpers'
import { addSuccessToast } from '@scouts/ui'

import { useDeleteTaxReliefClaimMutation, DeleteTaxReliefClaimParams } from '../api'

export const useDeleteTaxReliefClaim = () => {
    const [action, { error, isLoading }] = useDeleteTaxReliefClaimMutation()

    const deleteTaxReliefClaim = useCallback(
        async (params: DeleteTaxReliefClaimParams) =>
            action(params).then(
                matchSuccessResponse(() => {
                    addSuccessToast({ body: `Tax relief claim #${params.taxReliefClaimId} was successfully deleted` })
                })
            ),
        [action]
    )

    return {
        deleteTaxReliefClaim,
        error,
        isLoading,
    }
}
