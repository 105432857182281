import { useGetCustomersPartnerQuery, GetCustomersPartnerParams } from '../api'

export const useCustomersPartner = ({ customerId }: GetCustomersPartnerParams) => {
    const { data, isError, isLoading } = useGetCustomersPartnerQuery({ customerId })

    return {
        partner: data,
        isError,
        isLoading,
    }
}
