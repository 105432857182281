import { useState } from 'react'

import { sessionStorageGet, sessionStorageSet } from '@scouts/helpers'

import { useHasExpiredNylasAccessToken } from '@/hooks/nylas'
import { useUser } from '@/hooks/use-user'

const PAYE_NOTICE_STORAGE_KEY = 'hidePayeApiNotice'

export const useNylasReauthenticationNotice = () => {
    const { isExpired } = useHasExpiredNylasAccessToken()

    return {
        shouldDisplay: isExpired,
    }
}

export const usePayeApiNotice = () => {
    const { hasHmrcPayeApiConnection, isOnTrial, isAdminUser } = useUser()

    const [isHidden, setIsHidden] = useState(sessionStorageGet(PAYE_NOTICE_STORAGE_KEY) === 'true' || false)

    const shouldDisplay = isAdminUser ? false : !isHidden && !hasHmrcPayeApiConnection && !isOnTrial

    const handleHide = () => {
        setIsHidden(true)
        sessionStorageSet(PAYE_NOTICE_STORAGE_KEY, 'true')
    }

    return {
        shouldDisplay,
        onHide: handleHide,
    }
}
