import PropTypes from 'prop-types'

import { Checkbox } from '@scouts/ui'

const SelfAssessmentsFilterUnpaid = ({ disabled, filterIncludeUnpaid, toggleUnpaid }) => (
    <Checkbox
        isSmall
        name="includeUnpaid"
        label="Include unpaid"
        value={filterIncludeUnpaid || ''}
        checked={filterIncludeUnpaid === 'true'}
        onChange={toggleUnpaid}
        disabled={disabled}
    />
)

SelfAssessmentsFilterUnpaid.propTypes = {
    filterIncludeUnpaid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    toggleUnpaid: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

SelfAssessmentsFilterUnpaid.defaultProps = {
    filterIncludeUnpaid: false,
}

export default SelfAssessmentsFilterUnpaid
