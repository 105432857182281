import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'

import {
    MARK_REWARD_AS_IGNORED,
    MARK_REWARD_AS_PAID,
    RECEIVE_REWARD,
    RECEIVE_REWARD_PAYMENT_ERROR,
    RECEIVE_REWARDS,
    REQUEST_REWARDS,
    TRIGGER_REWARD_PAYMENT,
} from '../actions'

const defaultState = {
    items: [],
    isFetching: false,
    pagination: {
        currentPage: null,
        pageCount: null,
        perPage: null,
        totalCount: null,
    },
}

export default (state = defaultState, action) => {
    const { type, data, meta } = action

    switch (type) {
        case MARK_REWARD_AS_PAID:
        case MARK_REWARD_AS_IGNORED:
        case REQUEST_REWARDS:
        case TRIGGER_REWARD_PAYMENT: {
            return { ...state, isFetching: true }
        }

        case RECEIVE_REWARD_PAYMENT_ERROR: {
            return { ...state, isFetching: false }
        }

        case RECEIVE_REWARDS: {
            const items = Object.assign([], data)
            const pagination = _get(meta, ['pagination'], {})

            return { ...state, isFetching: false, items, pagination }
        }

        case RECEIVE_REWARD: {
            const { items } = state

            const updatedItems = [...items]

            const key = _findIndex(updatedItems, ['id', data.id])

            if (updatedItems[key]) {
                updatedItems[key] = data
            } else {
                updatedItems.push(data)
            }

            return { ...state, isFetching: false, items: updatedItems }
        }

        default:
            return state
    }
}
