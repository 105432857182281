import { Skeleton, SkeletonLine } from '@scouts/ui'

import { useTaxRegistration } from '@/store/tax-registrations'

import { useCustomerFromPath } from '@/hooks/use-customer-from-path'

import { TaxRegistration } from '../tax-registration/TaxRegistration'

export const CustomerOverviewTaxRegistration = () => {
    const { customer } = useCustomerFromPath()
    const { hasTaxRegistration } = customer || {}
    const { taxRegistration, isLoading } = useTaxRegistration({ customerId: customer.id, skip: !hasTaxRegistration })

    if (!taxRegistration && !hasTaxRegistration) return null

    if (!taxRegistration || isLoading)
        return (
            <Skeleton padding="24px">
                <SkeletonLine height="86px" />
            </Skeleton>
        )

    return <TaxRegistration customer={customer} taxRegistration={taxRegistration} />
}
