import { useEffect } from 'react'

import { requestAccountants } from '@/actions'
import { useAppDispatch } from '@/store'

/**
 * @description For Support users to fetch all accountants. Mostly used for rendering a Select component for choosing an accountant.
 */
export const useAccountants = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(requestAccountants())
    }, [dispatch])
}
