import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import config from '@/config'
import { EmailProvider } from '@/constants'
import { getUser } from '@/selectors'
import { useSyncCustomerExternalMessages } from '@/store/external-messages'

export const useExternalMessages = ({
    customerId,
    shouldSkipInitialFetch,
}: {
    customerId: number
    shouldSkipInitialFetch?: boolean
}) => {
    const { hasGoogleConnection, hasMicrosoftConnection, isAdminUser } = useSelector(getUser)
    const { syncCustomerExternalMessages, isLoading } = useSyncCustomerExternalMessages()

    const hasFetched = useRef(false)

    const fetchExternalMessages = useCallback(
        (emailProvider: EmailProvider) => {
            return syncCustomerExternalMessages({
                customerId,
                emailProvider,
                redirectUri: config.callbackUriExternalEmail,
            })
        },
        [syncCustomerExternalMessages, customerId]
    )

    useEffect(() => {
        if (shouldSkipInitialFetch || isLoading || isAdminUser || hasFetched.current) return

        if (hasGoogleConnection) fetchExternalMessages(EmailProvider.Gmail)
        if (hasMicrosoftConnection) fetchExternalMessages(EmailProvider.Outlook)

        hasFetched.current = true
    }, [
        customerId,
        fetchExternalMessages,
        hasGoogleConnection,
        hasMicrosoftConnection,
        isAdminUser,
        isLoading,
        shouldSkipInitialFetch,
    ])

    return { fetchExternalMessages, isLoading }
}
