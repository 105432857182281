import { font, Container, DropdownSelect, DropdownSelectProps, Line, Spacer } from '@scouts/ui'

import { saveUser } from '@/actions'
import { AccountantDomain } from '@/domain'
import { AccountantDetailsDTO } from '@/models'
import { useAppDispatch } from '@/store'

export const TaxConsultationStatusDropdown = ({ accountant }: { accountant: AccountantDetailsDTO }) => {
    const dispatch = useAppDispatch()
    const { allowTaxConsultation, taxConsultationStatus } = accountant

    if (!allowTaxConsultation) return null

    const handleTaxConsultationStatusChange: DropdownSelectProps['onChange'] = ({
        value: updatedTaxConsultationStatus,
    }) => {
        if (updatedTaxConsultationStatus === taxConsultationStatus) return

        dispatch(saveUser({ taxConsultationStatus: updatedTaxConsultationStatus }))
    }

    return (
        <Container>
            <Spacer marginBottom="6px">
                <Line weight={font.weight.medium} size={font.small}>
                    Accept new consultation clients
                </Line>
            </Spacer>
            <DropdownSelect
                name="taxConsultationStatus"
                value={taxConsultationStatus}
                options={AccountantDomain.accountantStatusDropdownOptionsBinary}
                onChange={handleTaxConsultationStatusChange}
                width="100%"
            />
        </Container>
    )
}
