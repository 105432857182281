export const MessageTemplateType = {
    Private: 'Private',
    Support: 'Support',
    Shared: 'Shared',
} as const

export type MessageTemplateTypeTypes = (typeof MessageTemplateType)[keyof typeof MessageTemplateType]

export interface MessageTemplateEditDTO {
    /** @format int32 */
    accountantId: number
    /**
     * @minLength 1
     * @maxLength 255
     */
    name: string
    /** @maxLength 255 */
    /** @minLength 1 */
    content: string
    type: MessageTemplateTypeTypes
}

export interface MessageTemplateDTO {
    /** @format int32 */
    id: number
    /** @format int32 */
    accountantId: number
    name?: string | null
    content?: string | null
    type: MessageTemplateTypeTypes
    /** @format int32 */
    lastUpdatedByAccountantId: number
    lastUpdatedByAccountantFullName?: string | null
    /** @format date-time */
    createdDateUTC: string
    /** @format date-time */
    updatedDateUTC: string
}
