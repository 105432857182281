import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { getEscalations, getEscalationsList } from '../../selectors/escalations'
import PaginationContainer from '../PaginationContainer'
import EscalationsTable from './EscalationsTable'
import EscalationsSearch from './search/EscalationsSearch'

const Container = styled.div`
    padding: 24px 24px 192px;
`

const PaginationContainerWrap = styled.div`
    margin-top: 24px;
`

const Escalations = () => {
    const location = useLocation()
    const { isFetching, pagination } = useSelector(getEscalations)
    const escalations = useSelector(getEscalationsList)

    const itemsCount = escalations.length
    const hasEscalations = itemsCount > 0

    return (
        <Container>
            <EscalationsSearch itemsCount={itemsCount} pagination={pagination} />

            {hasEscalations && (
                <>
                    <EscalationsTable escalations={escalations} isFetching={isFetching} />

                    <PaginationContainerWrap>
                        <PaginationContainer
                            pagination={pagination}
                            pathname={location.pathname}
                            search={location.search}
                        />
                    </PaginationContainerWrap>
                </>
            )}
        </Container>
    )
}

export default Escalations
