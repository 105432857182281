const PAYMENT_STATUS_FAILED = 'Failed'
const PAYMENT_STATUS_PENDING = 'Pending'
export const PAYMENT_STATUS_REFUNDED = 'Refunded'
export const PAYMENT_STATUS_SUCCEEDED = 'Succeeded'
const PAYMENT_STATUS_UNKNOWN = 'Unknown'

export const PAYMENT_STATUSES = [
    PAYMENT_STATUS_FAILED,
    PAYMENT_STATUS_PENDING,
    PAYMENT_STATUS_REFUNDED,
    PAYMENT_STATUS_SUCCEEDED,
    PAYMENT_STATUS_UNKNOWN,
] as const

export type PaymentStatuses = (typeof PAYMENT_STATUSES)[number]
