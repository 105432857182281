import { AccountantStatusTypes } from './accountant'
import { CustomerDTO } from './customer'
import { PriorityUnresolvedNoteDTO } from './note'
import { PaymentStatusTypes } from './payment-status'
import { RefundReasonDTO } from './refunds'
import { TaxConsultationFileDTO } from './tax-consultation-file'

export const TaxConsultationStatus = {
    Started: 'Started',
    Booked: 'Booked',
    AwaitingSummary: 'AwaitingSummary',
    AwaitingApproval: 'AwaitingApproval',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
} as const

export type TaxConsultationStatusTypes = (typeof TaxConsultationStatus)[keyof typeof TaxConsultationStatus]

export const TaxConsultationStatusTitles: Record<TaxConsultationStatusTypes, string> = {
    Started: 'Started',
    Booked: 'Booked',
    AwaitingSummary: 'Awaiting summary',
    AwaitingApproval: 'Awaiting approval',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
}

export const TaxConsultationContactPreference = {
    Video: 'Video',
    Phone: 'Phone',
    Email: 'Email',
} as const

export type TaxConsultationContactPreferenceTypes =
    (typeof TaxConsultationContactPreference)[keyof typeof TaxConsultationContactPreference]

export const TaxConsultationContactPreferenceTitles: Record<TaxConsultationContactPreferenceTypes, string> = {
    Video: 'Video call and emails',
    Phone: 'Phone call and emails',
    Email: 'Emails',
}

export const TaxConsultationTopic = {
    SelfAssessment: 'SelfAssessment',
    ForeignIncomeOrTaxes: 'ForeignIncomeOrTaxes',
    PropertyCapitalGains: 'PropertyCapitalGains',
    SharesOrCryptoCapitalGains: 'SharesOrCryptoCapitalGains',
    TaxPlanning: 'TaxPlanning',
    LimitedCompanyTaxes: 'LimitedCompanyTaxes',
    CompanySetup: 'CompanySetup',
    InheritanceTax: 'InheritanceTax',
    Other: 'Other',
} as const

export type TaxConsultationTopicTypes = (typeof TaxConsultationTopic)[keyof typeof TaxConsultationTopic]

export const TaxConsultationTopicTitles: Record<TaxConsultationTopicTypes, string> = {
    SelfAssessment: 'Self assessment',
    ForeignIncomeOrTaxes: 'Foreign income or taxes',
    PropertyCapitalGains: 'Capital gains - property',
    SharesOrCryptoCapitalGains: 'Capital gains - shares, options, crypto',
    TaxPlanning: 'General tax planning',
    LimitedCompanyTaxes: 'Limited company taxes',
    CompanySetup: 'Setting up a company',
    InheritanceTax: 'Inheritance tax',
    Other: 'Other',
}

interface TaxConsultationTopicDTO {
    id: number
    taxConsultationId: TaxConsultationDTO['id']
    topicType: TaxConsultationTopicTypes
}

export interface TaxConsultationListItemDTO {
    id: number
    accountantFullName: string | null
    accountantStatus: AccountantStatusTypes | null
    customerEmail: CustomerDTO['email'] | null
    customerFullName: CustomerDTO['fullName'] | null
    customerId: CustomerDTO['id'] | null
    hasPaid: boolean
    priorityUnresolvedNotes: PriorityUnresolvedNoteDTO[] | null
    scheduledDateUtc: string | null
    status: TaxConsultationStatusTypes
    statusChangedDateUTC: string | null
    topics: TaxConsultationTopicDTO[]
    unresolvedNoteCount: number | null
}

/** Smaller version of the more detailed TaxConsultationDTO */
export interface TaxConsultationShortDTO {
    id: number
    customerId: CustomerDTO['id']
    contactPreferenceType: TaxConsultationContactPreferenceTypes
    hasPaid: boolean
    scheduledDateUtc: string | null
    status: TaxConsultationStatusTypes
    statusChangedDateUTC: string
    topics: TaxConsultationTopicDTO[] | null
}

/** Single view DTO */
export interface TaxConsultationDTO {
    paymentDateUTC: string | null
    paymentStatus: PaymentStatusTypes
    refundDateUTC: string | null
    refundReason: RefundReasonDTO
    files: TaxConsultationFileDTO[] | null
    accountantStatus: AccountantStatusTypes
    customerId: number
    customerFullName: string | null
    customerEmail: string | null
    statusChangedDateUTC: string
    unresolvedNoteCount: number | null
    priorityUnresolvedNotes: PriorityUnresolvedNoteDTO[] | null
    revenueDateUTC: string | null
    id: number
    accountantId: number | null
    accountantFullName: string | null
    additionalNotes: string | null
    editToken: string | null
    externalId: string | null
    meetingLink: string | null
    hasPaid: boolean
    price: number
    isMissingAgreement: boolean
    completedDateUtc: string | null
    scheduledDateUtc: string | null
    contactPreferenceType: TaxConsultationContactPreferenceTypes
    status: TaxConsultationStatusTypes
    topics: TaxConsultationTopicDTO[]
}

/** Table view DTO */
export interface TaxConsultationSummaryDTO {
    accountantStatus: AccountantStatusTypes
    customerId: number
    customerFullName: string | null
    customerEmail: string | null
    statusChangedDateUTC: string
    unresolvedNoteCount: number | null
    priorityUnresolvedNotes: PriorityUnresolvedNoteDTO[] | null
    revenueDateUTC: string | null
    id: number
    accountantId: number | null
    accountantFullName: string | null
    additionalNotes: string | null
    editToken: string | null
    externalId: string | null
    meetingLink: string | null
    hasPaid: boolean
    price: number
    isMissingAgreement: boolean
    completedDateUtc: string | null
    scheduledDateUtc: string | null
    contactPreferenceType: TaxConsultationContactPreferenceTypes
    status: TaxConsultationStatusTypes
    topics: TaxConsultationTopicDTO[] | null
}

export interface TaxConsultationOptions {
    accountantId?: number | null
    status?: TaxConsultationStatusFilterTypes | null
    page?: number
    pageSize?: number
}

export const TaxConsultationStatusFilter = {
    Started: 'Started',
    Booked: 'Booked',
    AwaitingApproval: 'AwaitingApproval',
    AwaitingSummary: 'AwaitingSummary',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
    All: 'All',
} as const

export const TaxConsultationStatusFilters = {
    Started: [TaxConsultationStatus.Started],
    Booked: [TaxConsultationStatus.Booked],
    AwaitingSummary: [TaxConsultationStatus.AwaitingSummary],
    AwaitingApproval: [TaxConsultationStatus.AwaitingApproval],
    Completed: [TaxConsultationStatus.Completed],
    Cancelled: [TaxConsultationStatus.Cancelled],
    All: [
        TaxConsultationStatus.Started,
        TaxConsultationStatus.Booked,
        TaxConsultationStatus.AwaitingSummary,
        TaxConsultationStatus.AwaitingApproval,
        TaxConsultationStatus.Completed,
        TaxConsultationStatus.Cancelled,
    ],
} as const

export type TaxConsultationStatusFilterTypes =
    (typeof TaxConsultationStatusFilter)[keyof typeof TaxConsultationStatusFilter]

export const TaxConsultationStatusFilterTitles: Record<TaxConsultationStatusFilterTypes, string> = {
    All: 'All',
    Started: 'Started',
    Booked: 'Booked',
    AwaitingSummary: 'Awaiting summary',
    AwaitingApproval: 'Awaiting approval',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
}
