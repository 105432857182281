import PropTypes from 'prop-types'

import { FilterItem, FilterItemDropdownDateRange } from '../ui/filter'

const SearchFilterDateRange = (props) => {
    const { dateFromName, dateFromValue, dateToName, dateToValue, label, applyFilter, removeFilter, isOpenByDefault } =
        props

    const hasActiveFilter = dateFromValue || dateToValue

    const activeValues = [dateFromValue ? `From ${dateFromValue}` : '', dateToValue ? `To ${dateToValue}` : '']

    return (
        <FilterItem
            isOpenByDefault={isOpenByDefault}
            label={label}
            activeValues={activeValues}
            removeFilter={() => removeFilter([dateFromName, dateToName])}
            isNeutral={!hasActiveFilter}
        >
            <FilterItemDropdownDateRange
                applyFilter={applyFilter}
                dateFrom={dateFromValue}
                dateFromName={dateFromName}
                dateTo={dateToValue}
                dateToName={dateToName}
                removeFilter={() => removeFilter([dateFromName, dateToName])}
            />
        </FilterItem>
    )
}

SearchFilterDateRange.propTypes = {
    applyFilter: PropTypes.func.isRequired,
    dateFromName: PropTypes.string.isRequired,
    dateFromValue: PropTypes.string.isRequired,
    dateToName: PropTypes.string.isRequired,
    dateToValue: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isOpenByDefault: PropTypes.bool.isRequired,
    removeFilter: PropTypes.func.isRequired,
}

export default SearchFilterDateRange
