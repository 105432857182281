import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { colors, font, radius, ChipButton, Container, Flexer, Line, Spacer } from '@scouts/ui'

import { PATH_PROFILE } from '@/constants'
import { isAdmin } from '@/helpers'
import { AccountantDetailsDTO, AccountantStatus } from '@/models'

import { AccountantStatusDropdown } from './AccountantStatusDropdown'
import { CompanyReturnStatusDropdown } from './CompanyReturnStatusDropdown'
import { TaxConsultationStatusDropdown } from './TaxConsultationStatusDropdown'

interface DashboardProfileProps {
    accountant: AccountantDetailsDTO
}

export const DashboardProfile = ({ accountant }: DashboardProfileProps) => {
    const history = useHistory()

    const { email, phoneNumber, fullName, status } = accountant

    const shouldDisplayStatusSelection = status !== AccountantStatus.Terminated && status !== AccountantStatus.Draft

    const isAdminUser = isAdmin(accountant)

    return (
        <Container style={{ position: 'sticky', top: '36px' }}>
            <Container padding="18px 24px" border={`1px solid ${colors.neutralLightest}`} radius={radius.large}>
                <Spacer marginBottom="18px">
                    <ChipButton onClick={() => history.push(PATH_PROFILE)} withBorder variant="inverted">
                        My profile →
                    </ChipButton>
                </Spacer>
                <Spacer>
                    <Wrapper>
                        <Line weight={font.weight.medium}>{fullName}</Line>
                        <Line size={font.small}>{email}</Line>
                        <Line size={font.small}>{phoneNumber}</Line>
                    </Wrapper>
                </Spacer>

                {!isAdminUser && shouldDisplayStatusSelection && (
                    <Statuses>
                        <Flexer column gap="18px">
                            <AccountantStatusDropdown accountant={accountant} />
                            <TaxConsultationStatusDropdown accountant={accountant} />
                            <CompanyReturnStatusDropdown accountant={accountant} />
                        </Flexer>
                    </Statuses>
                )}
            </Container>
        </Container>
    )
}

const Wrapper = styled.div`
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

const Statuses = styled.div`
    border-top: 1px solid ${colors.neutralLightest};
    margin-top: 18px;
    padding: 18px 0 9px;
`
