import { colors, Container, Flexer, Line, Loadable, Notice, Skeleton, SkeletonLine, Spacer } from '@scouts/ui'

import { CompanyReturnStatusFilter } from '@/models'
import { useCompanyReturnsSearch } from '@/store/company-returns/hooks'

import { useUser } from '@/hooks/use-user'

import { useFilters } from './hooks'

import { usePagination } from '../pagination'
import { PaginationContainer } from '../pagination/PaginationContainer'
import { CompanyReturnsFilters } from './CompanyReturnsFilters'
import { CompanyReturnsSupportFilters } from './CompanyReturnsSupportFilters'
import { CompanyReturnsTable } from './CompanyReturnsTable'

export const CompanyReturns = () => {
    const { isAdminUser } = useUser()
    const { activeFilters: activePagination } = usePagination()
    const { activeFilters } = useFilters()

    const { status: filterStatus } = activeFilters

    const { companyReturns, isError, isFetching, isLoading, pagination } = useCompanyReturnsSearch({
        options: { ...activeFilters, ...activePagination },
    })

    if (isError)
        return (
            <Container>
                <Flexer alignCenter justifyCenter>
                    <Line color={colors.neutralDarker}>Error getting company returns</Line>
                </Flexer>
            </Container>
        )

    if (isLoading)
        return (
            <Skeleton gap="24px" padding="24px">
                <Flexer column gap="12px">
                    {isAdminUser && <SkeletonLine height="35px" width="50%" />}
                    <SkeletonLine height="35px" width="80%" />
                </Flexer>
                <Flexer column gap="1px">
                    <SkeletonLine height="38px" />
                    <SkeletonLine height="84px" repeat={10} />
                </Flexer>
            </Skeleton>
        )

    const shouldDisplayAssignedNotice = filterStatus === CompanyReturnStatusFilter.Assigned

    return (
        <Loadable isLoading={isFetching}>
            <Container padding="24px 24px 192px">
                <Spacer marginBottom="24px">
                    <Flexer column gap="18px" tabletGap="12px">
                        {isAdminUser && <CompanyReturnsSupportFilters />}
                        <CompanyReturnsFilters />
                    </Flexer>
                </Spacer>

                {shouldDisplayAssignedNotice && (
                    <Notice background={colors.redLighter}>
                        These clients are still preparing their documents and haven't yet signed the Letter of
                        Agreement. Once they "Submit" you can start with the work.
                    </Notice>
                )}

                <CompanyReturnsTable companyReturns={companyReturns} />

                <Spacer marginTop="24px">
                    <PaginationContainer pagination={pagination} />
                </Spacer>
            </Container>
        </Loadable>
    )
}
