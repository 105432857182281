import { PAGINATION_DEFAULT_PAGE, PAGINATION_VIEW_ALL_PAGE_SIZE } from '@/constants'
import { TaxReliefClaimOptions } from '@/models'

export const getTaxReliefClaimQueryParams = (options?: TaxReliefClaimOptions) => ({
    pageSize: options?.pageSize || PAGINATION_VIEW_ALL_PAGE_SIZE,
    page: options?.page || PAGINATION_DEFAULT_PAGE,
    ...(options?.sortOrder && { sortOrder: options.sortOrder }),
    ...(options?.sortType && { sortType: options.sortType }),
    ...(options?.supportUserId && { supportUserId: options.supportUserId }),
    ...(options?.status && { status: options.status }),
    ...(options?.submittedDate?.start && {
        'submittedDate.Start': options.submittedDate.start,
    }),
    ...(options?.submittedDate?.end && {
        'submittedDate.End': options.submittedDate.end,
    }),
    ...(options?.awaitingSignatureDate?.start && {
        'awaitingSignatureDate.Start': options.awaitingSignatureDate.start,
    }),
    ...(options?.awaitingSignatureDate?.end && {
        'awaitingSignatureDate.End': options.awaitingSignatureDate.end,
    }),
    ...(options?.readyToSendDate?.start && {
        'readyToSendDate.Start': options.readyToSendDate.start,
    }),
    ...(options?.readyToSendDate?.end && {
        'readyToSendDate.End': options.readyToSendDate.end,
    }),
    ...(options?.sentDate?.start && {
        'sentDate.Start': options.sentDate.start,
    }),
    ...(options?.sentDate?.end && {
        'sentDate.End': options.sentDate.end,
    }),
    ...(options?.cancelledDate?.start && {
        'cancelledDate.Start': options.cancelledDate.start,
    }),
    ...(options?.cancelledDate?.end && {
        'cancelledDate.End': options.cancelledDate.end,
    }),
})
